import styled from "styled-components";
import Bars from "./Bars";
import { colorPalette, GrayColorPalette } from "../../styles/MainList.styled";

export const StyledBars = styled(Bars)`
   {
    
  }
`;

export const FullBars = styled.div`
   {
    background-color: ${colorPalette.ratingGreen};
    width: 21px;
    height: 21px;
    border-right: 1px solid ${GrayColorPalette.Gray6};
  }
`;

export const HalfBars = styled.div`
{
    background-image: conic-gradient(${GrayColorPalette.Gray8} 180deg, ${colorPalette.ratingGreen} 180deg);
    width: 21px;
    height: 21px;
    border-right: 1px solid ${GrayColorPalette.Gray6};
}`;

export const RemainingBars = styled.div`
{
    background-color: ${GrayColorPalette.Gray8};
    width: 21px;
    height: 21px;
    border-right: 1px solid ${GrayColorPalette.Gray6};
}`;
