import * as React from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import MainLists from "./MainLists";
import { Block, Phase, Script, Section } from "../../models/ScriptInterfaces";
import {
  AddClickEventHandlerForReadOnlyInput,
  RemoveClickEventHandlerForReadOnlyInput,
  isNullOrUndefined,
} from "../../utils/utilityfunctions";
import { Match } from "../../models/RouterProps";
import { Casualty } from "../../models/CasualtyInterfaces";
import { BloodType, Sex } from "../../models/Enums";
import { getScriptWithId } from "../../api/Script/ApiGet";
import { getAllCasualty } from "../../api/Casualty/ApiGet";
import { setAlertMessage } from "../../actions/alertMessage";
import { SystemMessage } from "../../models/Management";
import { v4 as uuidv4 } from "uuid";
import { MainDiv } from "../../styles/ScriptBuilder.styled";
import ScriptDetailsView from "./EditComponents/ScriptDetailsView/ScriptDetailsView";
import {
  LeftPaneViewTypes,
  ScriptViewTypes,
} from "./EditComponents/ScriptDetailsView/constants";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  populatePhaseWithTreatment,
  SetCasualty,
  SetIsReadOnly,
  SetPhases,
  SetScriptId,
} from "./MainlistSlice";
import {
  INSULT_INJURY_RESET_APPLIED_INJURIES,
  INSULT_INJURY_RESET_APPLIED_INSULTS,
} from "../../store/CasualtyCreator/InsultInjury/actionType";
import { useFormik } from "formik";
import {
  SetCasualties,
  SetIsReadOnlyInScriptView,
  SetSelectedCasualtyId,
} from "../../store/ScriptBuilder/scriptBuilderSlice";
import ScriptTitleHeader from "./EditComponents/ScriptDetailsView/ScriptTitleHeader";

interface Props {
  match: Match;
  SystemMessage: SystemMessage;
  setAlertMessage: Function;
}

type ErrorType = {
  [key: string]: string;
};

const defaultCasualty = {
  Id: 0,
  Gender: Sex.Male,
  Height: 121,
  Weight: 0,
  WeightUnit: "lbs",
  Age: 0,
  BloodType: BloodType.ABPositive,
};

const ScriptBuilding = (props: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: any) => state.MainlistReducer);
  const phases: Phase[] = state.phases;
  const isReadOnly: boolean = state.isReadOnly;
  const history = useHistory();
  const urlParam: any = useParams();
  // 1nd hook is for loading hook
  const [loading, setLoading] = React.useState<boolean>(true);

  // 2rd hook is for Id hook
  const [Id, setId] = React.useState<number>(0);

  // 3th hook is for Casuality hook
  const [Casualty, setCasualty] = React.useState<Casualty>(defaultCasualty);

  // 5th hook is for Phase hook
  const [Phase, setPhase] = React.useState<Phase[]>([]);

  // 6th hook is for ScriptView Visiblity hook
  const [isScriptViewVisible, setIsScriptViewVisible] =
    React.useState<number>(-1);

  const [view, setView] = React.useState(ScriptViewTypes.SCRIPT);

  const [leftPaneView, setLeftPaneView] = React.useState(
    LeftPaneViewTypes.CASUALTY_FORM,
    // LeftPaneViewTypes.CASUALTY_TABLE,
  );

  const [isCasultyEditMode, setIsCasualtyEditMode] = React.useState(false);

  const [scriptData, setScriptData] = useState<any>(null);
  const error = (data: any) => {
    console.error("Error", data);
    setLoading(false);
  };

  const success = (data: any) => undefined;
  const getScriptSucess = (data: Script) => {
    if (!isNullOrUndefined(data.Phases)) {
      console.log(data.Phases);
      data.Phases.sort((a: Phase, b: Phase) => {
        return a.Order > b.Order ? 1 : b.Order > a.Order ? -1 : 0;
      });
      for (let i = 0; i < data.Phases.length; i++) {
        data.Phases[i].UUID = uuidv4();
        if (!isNullOrUndefined(data.Phases[i].Sections)) {
          for (let j = 0; j < data.Phases[i].Sections!.length; j++) {
            data.Phases[i].Sections![j].UUID = uuidv4();
            if (!isNullOrUndefined(data.Phases[i].Sections![j].Blocks)) {
              for (
                let k = 0;
                k < data.Phases[i].Sections![j].Blocks!.length;
                k++
              ) {
                data.Phases[i].Sections![j].Blocks![k].UUID = uuidv4();
              }
              data.Phases[i].Sections![j].Blocks!.sort((a: Block, b: Block) => {
                return a.Order > b.Order ? 1 : b.Order > a.Order ? -1 : 0;
              });
            }
          }
          data.Phases[i].Sections!.sort((a: Section, b: Section) => {
            return a.Order > b.Order ? 1 : b.Order > a.Order ? -1 : 0;
          });
        }
      }
    }
    dispatch(SetScriptId(data.Id || 0));
    if (!isNullOrUndefined(data.Casualty)) {
      setCasualty(data.Casualty);
    }
    populatePhaseWithTreatment(data.Phases || [], dispatch, state);
    setLoading(false);
  };
  /**
   * Submits and creates a Scenario entry in the Database
   * @param e
   */

  // if (input.value.length! > 0) {

  const postError = () => {
    console.log("THIS IS THE POST ERROR FUNCTION!!");
  };
  const getError = (data: any) => {
    console.log(data);
    history.push("/ViewScript"); // Redirect to script table if any error occur during fetching script record.
    setLoading(false);
  };

  /**
   * callback function that is used when onSubmit it clicked
   * @param data the information required to create A scenario
   */
  const postSuccess = (data: Script, redirectOrReload = true) => {
    // If you continue editing on create window. It causes some issues because it is missing some data. So this is just a fix for that
    // setIsScriptViewVisible(false);
    if (
      redirectOrReload &&
      window.location.href.substring(window.location.href.lastIndexOf("/")) ===
        "/CreateScript"
    ) {
      window.location.href = "ScriptBuilder/" + data.Id;
    } else if (
      redirectOrReload &&
      window.location.href.includes("EditScript")
    ) {
      window.location.href = "ScriptBuilder/" + data.Id;
    } else if (
      redirectOrReload &&
      window.location.href.includes("ScriptBuilder") &&
      urlParam.id &&
      urlParam.id > 0
    ) {
      // Reload window when saving script with edit casualty/script option
      // window.location.reload();
      window.location.href = "ScriptBuilder/" + urlParam.id;
    } else {
      if (isReadOnly) {
        if (!data.Casualty) {
          window.location.href = "ScriptBuilder/" + (data.Id || 0);
        }
        setLeftPaneView(LeftPaneViewTypes.CASUALTY_FORM);
      }
      dispatch(SetScriptId(data.Id || 0));
      setCasualty(data.Casualty);
      setLoading(false);
      setView(ScriptViewTypes.CASUALTY); // change view to casualty list
    }
  };
  /**
   * callback function that is used when onSubmit it clicked for creating new script
   * @param data the information required to create A scenario
   */
  const postCreateSuccess = (data: Script, redirectOrReload = true) => {
    setScriptData(data); // set script data
    postSuccess(data, redirectOrReload); // update state data
  };

  const loadingToggle = () => {
    setLoading((prevLoading) => (prevLoading ? false : true));
    console.log("loading toggle was called");
  };
  const getCasualtySuccess = (data: any) => {
    dispatch(SetCasualties(data));
    if (!isNullOrUndefined(props.match.params.id)) {
      console.log("props.match.params.id", props.match.params.id);
      console.log("props.match.params.id", props.match.params.id);
      getScriptWithId(props.match.params.id, getScriptSucess, getError);
    } else {
      setLoading(false);
    }
    // display select casualty table when url contains view=select-casualty
    if (
      window.location.href.includes("/ScriptBuilder") &&
      urlParam.id &&
      urlParam.id > 0 &&
      window.location.href.includes("?view=select-casualty")
    ) {
      showEditCasualtyPane();
    }
  };

  const getAllCasualtySuccess = (data: any) => {
    dispatch(SetCasualties(data));
  };
  const showEditCasualtyPane = () => {
    setIsScriptViewVisible(1);
    setView(ScriptViewTypes.CASUALTY);
    // if casualty attached with script then display casualty form
    // else display casualty table
    if (scriptData?.CasualtyId) {
      setLeftPaneView(LeftPaneViewTypes.CASUALTY_FORM);
    } else {
      setLeftPaneView(LeftPaneViewTypes.CASUALTY_FORM);
      // setLeftPaneView(LeftPaneViewTypes.CASUALTY_TABLE);
      dispatch(SetSelectedCasualtyId(0));
      dispatch({ type: INSULT_INJURY_RESET_APPLIED_INSULTS });
      dispatch({ type: INSULT_INJURY_RESET_APPLIED_INJURIES });
    }
    setIsCasualtyEditMode(true);
  };
  const setCurrentCasualty = (data: Casualty) => {
    setCasualty(data);
  };

  // handle read-only input click event
  React.useEffect(() => {
    AddClickEventHandlerForReadOnlyInput();
    return () => {
      RemoveClickEventHandlerForReadOnlyInput();
    };
  }, []);

  React.useEffect(() => {
    // for edit casualty option from script builder
    if (
      window.location.href.includes("/ScriptBuilder") &&
      urlParam.id &&
      urlParam.id > 0
    ) {
      getScriptWithId(parseInt(urlParam.id), getEditSuccess, getError);
    }
    if (!window.location.href.includes("/ScriptBuilder")) {
      if (urlParam !== null && urlParam.id) {
        getScriptWithId(parseInt(urlParam.id), getEditSuccess, getError);
      } else {
        setLoading(false);
      }
      getAllCasualty(getAllCasualtySuccess, error);
      setIsScriptViewVisible(1);
    } else {
      // getAllCasualty(getCasualtySuccess, error); <- Might need this in future when all casualty table is enabled in script builder edit casualty view.
      getScriptWithId(parseInt(urlParam.id), getScriptSucess, getError);
      setIsScriptViewVisible(0);
    }
    return () => {
      // reset isReadOnly Flag
      dispatch(SetIsReadOnly(false));
      dispatch(SetIsReadOnlyInScriptView(false));
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParam]);

  const getEditSuccess = (data: any) => {
    setScriptData(data);
    setPhase(data.Phases);
    dispatch(SetPhases(data.Phases));
    // set isReadOnly flag
    dispatch(SetIsReadOnly(data.IsReadOnly)); // mainListSlice
    dispatch(SetIsReadOnlyInScriptView(data.IsReadOnly)); // scriptBuilderSlice
    dispatch(SetCasualty(data));
    setLoading(false);
  };

  const scriptFormik = useFormik({
    initialValues: {
      id: 0,
      public: false,
      isSeed: false,
      title: "",
      description: "",
      casualtyId: 0,
      search: "",
      clonable: false,
      IsArchived: false,
    },
    validate: (values) => {
      const errors: ErrorType = {};

      if (values.title && !values.title.trim()) {
        errors["title"] = "Please enter a Script Title";
      } else if (values.title.length > 70) {
        errors["title"] = "Max 70 chars allowed";
      }

      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  React.useEffect(() => {
    if (scriptData) {
      console.log(scriptData);
      scriptFormik.values.id = scriptData.Id;
      scriptFormik.values.casualtyId = scriptData.CasualtyId;
      scriptFormik.values.title = scriptData.Title;
      scriptFormik.values.description = scriptData.Description;
      scriptFormik.values.public = scriptData.Public;
      scriptFormik.values.isSeed = scriptData.IsSeed;
      const casualtiesInScript: any = [scriptData.Casualty];
    }
  }, [scriptData]);
  const setScriptTitle = (title: string) => {
    scriptFormik.setFieldValue("title", title);
  };

  return (
    <MainDiv>
      {isScriptViewVisible === -1 ? null : isScriptViewVisible === 0 ? (
        <ScriptTitleHeader
          isReadOnly={isReadOnly}
          scriptTitle={scriptFormik.values.title}
          setScriptTitle={setScriptTitle}
          scriptFormik={scriptFormik}
          postSuccess={postSuccess}
          postCreateSuccess={postCreateSuccess}
          postError={postError}
          displayGoToScript={false}
        />
      ) : null}
      <Spin className="spin" spinning={loading}>
        {isScriptViewVisible === -1 ? null : isScriptViewVisible === 0 ? (
          <MainLists
            {...props}
            loadingToggle={loadingToggle}
            ScriptId={Id}
            Phase={phases}
            Casualty={Casualty}
            postError={error}
            postSuccess={success}
            showEditCasualtyPane={showEditCasualtyPane}
          />
        ) : (
          <ScriptDetailsView
            casualty={Casualty}
            SetCurrentCasualty={setCurrentCasualty}
            view={view}
            setView={setView}
            leftPaneView={leftPaneView}
            setLeftPaneView={setLeftPaneView}
            postSuccess={postSuccess}
            postCreateSuccess={postCreateSuccess}
            postError={postError}
            scriptData={scriptData}
            isCasultyEditMode={isCasultyEditMode}
            setIsCasualtyEditMode={setIsCasualtyEditMode}
          />
        )}
      </Spin>
    </MainDiv>
  );
};

function mapToStateToProps(state: any) {
  return {
    SystemMessage: state.SystemMessage,
  };
}

export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(ScriptBuilding);
