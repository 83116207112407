import { DownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./Dropmenu.scss";

interface PropTypes {
  label: string;
  params: string[];
  contents: any[];
  contentNames?: any[];
  setParams: (prev: any) => void;
  disabled: boolean;
}

function Dropmenu({
  label,
  params,
  contents,
  contentNames,
  setParams,
  disabled,
}: PropTypes) {
  const [show, setShow] = useState(false);
  const wrapperRef = useRef<any>(null);
  const btnRef = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        btnRef?.current?.classList?.value?.includes("show") &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setShow(false);
      }
    }
    document.addEventListener("click", (e: any) => handleClickOutside(e), true);
    return () => {
      document.removeEventListener(
        "click",
        (e: any) => handleClickOutside(e),
        true
      );
    };
  }, []);

  return (
    <div
      data-testid="dropdownmenu"
      className={`dropmenu ${label}`}
      ref={wrapperRef}
    >
      <Button
        className={`drop-btn ${show && "show"}`}
        disabled={disabled}
        onClick={() => setShow((s) => !s)}
        ref={btnRef}
      >
        {label} <DownOutlined className={show ? "show" : ""} />
      </Button>
      <div className={`dropmenu-content ${show && "show"}`}>
        <div className="dropmenu-flex-column">
          {contents.map((content, index) => (
            <label className="checkbox-container" key={content + "_" + index}>
              <input
                type="checkbox"
                style={{ marginRight: "5px" }}
                checked={params.includes(content)}
                disabled={disabled}
                onChange={() => {
                  const check = params.includes(content);
                  setParams((prev: any) =>
                    check
                      ? prev.filter((param: any) => param !== content)
                      : [...prev, content]
                  );
                }}
              />
              <span>{!contentNames ? content : contentNames[index]}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(Dropmenu);
