import React, { useEffect } from "react";
import AppLayout from "./Layout";
import AppRouter from "../router";
import { ThemeProvider } from "styled-components";
import { theme } from "../styles/theme";
import GlobalStyle from "../styles/global";
import "./App.less";
import Particles from "../components/Particles/Particles";
import { useMarkerIO } from "../hooks/useMarkerIO";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import ConfirmDialog from "../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../components/ConfirmDialog/useConfirmDialog";
import { logout } from "../actions/authActions";
import { handleAxiosUnauthorizedRequest } from "../api/axiosConfig";
import { getCurrentSelectedCompanyInfo } from "../api/Company/ApiGet";
import { getCurrentLoggedInUserInfo } from "../api/User/ApiGet";
import { Company } from "../models/Company";
import User from "../models/User";
import { setUser, setCompany, setUserCompanyId } from "../store/authSlice";

const App = () => {
  const { markerIOAPI } = useMarkerIO();
  const dispatch = useAppDispatch();
  const { user, isAuthenticated } = useAppSelector(
    (state) => state.authReducer,
  );

  if (isAuthenticated) {
    handleAxiosUnauthorizedRequest();
  }

  useEffect(() => {
    if (isAuthenticated) {
      getCurrentLoggedInUserInfo((data: User) => {
        dispatch(setUser(data));
      }, logout);
      getCurrentSelectedCompanyInfo((data: Company) => {
        dispatch(setCompany(data));
      }, logout);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!markerIOAPI || !isAuthenticated) {
      return;
    }

    if (user) {
      markerIOAPI.setReporter({
        email: user.Email,
        fullName: `${user.FirstName} ${user.LastName}`,
      });
    }
  }, [user, isAuthenticated, markerIOAPI]);

  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  // Auto log-out when user is inactive for more then an hour. ----->
  let isLogOutAfterInactivityExecuted = false;
  // Logout user after inactivity.
  const logOutAfterInactivity = () => {
    isLogOutAfterInactivityExecuted = true;
    logout(); // <- Clear tokens from local storage so if user refresh instead of clicking ok; it redirects them to login page.
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "Session expired.",
      content: "Your session is expired. Please login again.",
      okText: "Ok",
      onOk: () => {
        window.location.href = "/"; // <- Redirect to login page
      },
      type: "error",
    });
  };

  // Check for user inactivity.
  const checkForInactivity = () => {
    const expireTime = sessionStorage.getItem("expireTime");

    if (expireTime && Number(expireTime) < Date.now()) {
      // Log out user after 5 minutes if user do not respond.
      const timeout = setTimeout(() => {
        logOutAfterInactivity();
      }, 300000);
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "Session is about to expire.",
        content:
          "Your session is about to expire in 5 minutes. \nPress continue to continue your current session.",
        okText: "Continue",
        onOk: () => {
          updateExpireTime();
          setIsOpenConfirmDialog(false);
          clearTimeout(timeout);
        },
        type: "warning",
      });
    }
  };

  // Function to update expire time.
  const updateExpireTime = () => {
    const expireTime = Date.now() + 3600000; // 3600000ms = 1Hr
    sessionStorage.setItem("expireTime", expireTime.toString());
  };

  // UseEffect to check for inactivity.
  useEffect(() => {
    if (!isAuthenticated || isLogOutAfterInactivityExecuted) return;

    // Check for inactivity every 1 minutes.
    const interval = setInterval(() => {
      checkForInactivity();
    }, 60000);

    return () => clearInterval(interval);
  }, [isAuthenticated]);

  // Update expire time on any user activity.
  useEffect(() => {
    if (!isAuthenticated) return;

    // Set expire time for the first time.
    updateExpireTime();

    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, [isAuthenticated]);
  // ------------------------------------------------------------------>

  return (
    <ThemeProvider theme={theme}>
      <Particles />
      <GlobalStyle />
      <AppLayout>
        <AppRouter />
        <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
      </AppLayout>
    </ThemeProvider>
  );
};
export default App;
