import styled from "styled-components";
import { colorPalette } from "../../../../../../styles/MainList.styled";

export const PaneDiv = styled.div`
  border-bottom: 1px solid ${colorPalette.gray75};
  border-right: 1px solid ${colorPalette.gray75};
  display: flex;
  flex-direction: column;
  & + & {
    border-left: none;
  }
  &#left-pane {
    width: 575px;
    //responsive left pane for screen less than 1440px and larger than 1900px
    @media (max-width: 1440px) {
      min-width: 500px;
      width: calc(100% - 730px);
    }
    @media (min-width: 1900px) {
      width: calc(100% - 730px - 30%);
    }
  }

  &#center-pane {
    #center-pane-detail-panel-body {
      display: flex;

      // center panel with model
      #center-panel-model {
        width: 100%;
  }
`;
