import React, { useCallback, useState } from "react";
import "./SupportForm.scss";
import { connect } from "react-redux";
import { Auth } from "./modules";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { formatPhoneNumber } from "../utils/formatUtils";
import { Button } from "antd";

interface PropTypes {
  auth: Auth;
}

function SupportForm({ auth }: PropTypes) {
  const [inputs, setInputs] = useState({
    name: `${auth.user.FirstName} ${auth.user.LastName}`,
    organization: auth.company.Name,
    phone: "",
    email: auth.user.Email,
    message: "",
  });
  const handleOnChange = useCallback(
    ({ target: { name, value } }) =>
      setInputs((state) => ({ ...state, [name]: value })),
    [],
  );

  const phoneRegex = /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/i;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  function handlePhoneInput(phone: string) {
    const format = formatPhoneNumber(phone);
    console.log(format);
    setInputs((state) => ({ ...state, phone: format }));
  }

  function validateForm(e: React.SyntheticEvent) {
    e.preventDefault();
    // Guard clause
    if (!inputs.name || !inputs.email || !inputs.phone || !inputs.organization)
      return;

    if (!inputs.email) {
      return "Please Enter Your Email";
    } else if (!emailRegex.test(inputs.email)) {
      return "Invalid Email Address";
    }

    if (!phoneRegex.test(inputs.phone) && inputs.phone !== "") {
      return "Invalid Phone Number";
    }
  }

  return (
    <div>
      <form className="support-form w-100" onSubmit={(e) => validateForm(e)}>
        <p className="support-text">Call Us for Immediate Help</p>
        <div className="support-phone">
          <PhoneOutlined /> <span>(720) 779-8676</span>
        </div>

        <div className="floating-label">
          <input
            required
            name="name"
            placeholder="Name"
            value={inputs.name}
            onChange={handleOnChange}
          />
          <label htmlFor="name">Name</label>
        </div>
        <div className="floating-label">
          <input
            required
            name="organization"
            placeholder="Organization"
            value={inputs.organization}
            onChange={handleOnChange}
          />
          <label htmlFor="organization">Organization</label>
        </div>
        <div className="floating-label">
          <input
            required
            name="phone"
            placeholder="Phone"
            maxLength={15}
            value={inputs.phone}
            onChange={(e) => handlePhoneInput(e.target.value)}
          />
          <label htmlFor="phone">Phone</label>
        </div>
        <div className="floating-label">
          <input
            required
            name="email"
            placeholder="Email"
            type="email"
            value={inputs.email}
            onChange={handleOnChange}
          />
          <label htmlFor="email">Email</label>
        </div>

        <div className="floating-label">
          <textarea
            name="message"
            value={inputs.message}
            placeholder="Message"
            onChange={handleOnChange}
            maxLength={500}
          />
          <label htmlFor="message">Message</label>
        </div>

        <Button
          icon={<MailOutlined />}
          className="support-btn"
          htmlType="submit"
        >
          Contact Support
        </Button>
      </form>
    </div>
  );
}

function mapToStateToProps(state: any) {
  return {
    auth: state.authReducer,
  };
}

export default connect(mapToStateToProps)(SupportForm);
