export const updateTextDescription = (textTitle: string) => {
  return (dispatch: any) => {
    dispatch({
      type: "textTitle",
      payload: textTitle,
    });
  };
};

export const updateTextTitle = (textTitle: string) => {
  return (dispatch: any) => {
    dispatch({
      type: "textTitle",
      payload: textTitle,
    });
  };
};

export const updateWhatToSay = (whatToSay: string) => {
  return (dispatch: any) => {
    dispatch({
      type: "whatToSay",
      payload: whatToSay,
    });
  };
};

export const updateSupportActionText = (supportAction: string) => {
  return (dispatch: any) => {
    dispatch({
      type: "supportAction",
      payload: supportAction,
    });
  };
};

export const SetIsFocused = (isFocusedOption: {}) => {
  return (dispatch: any) => {
    dispatch({
      type: "isFocused",
      payload: isFocusedOption,
    });
  };
};

export const SetLastFocused = (lastFocusedOption: {}) => {
  return (dispatch: any) => {
    dispatch({
      type: "option",
      payload: lastFocusedOption,
    });
  };
};
