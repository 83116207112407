import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import InlineEditTextArea from "../../../../../../components/InlineEdit/InlineEditTextArea";
import { DeleteIcon } from "../../../../../../IconsTheme/Icons.global";
import {
  ResearchQuestionRadioAnswer,
  ResearchQuestionSelectAnswer,
} from "../../../../../../models/ScriptInterfaces";
import {
  IndexSpan,
  ListContainer,
  StyledListTitleItem,
  TitleSpan,
  Ul,
} from "../../../ShowList/ShowList.styled";
import InlineEditTextInput from "../../../../../../components/InlineEdit/InlineEditTextInput";
interface Props {
  list: ResearchQuestionSelectAnswer[] | ResearchQuestionRadioAnswer[];
  handleOptionDelete: Function;
  handleOrderUpdate: Function;
  handleTitleUpdate: Function;
  disabled: boolean;
}
export default function ListItems(props: Props) {
  const onDragEnd = (result: DropResult) => {
    console.log(result);
    if (!result.destination) {
      // dropped outside of a drop-enabled area
      return;
    }

    const sourceSectionUUID = result.source.droppableId;
    const sourceIndex = result.source.index;
    const destinationSectionUUID = result.destination!.droppableId;
    const destinationIndex = result.destination!.index;

    if (sourceSectionUUID === destinationSectionUUID) {
      // this is a drag reordering inside a single phase
      if (sourceIndex === destinationIndex) {
        // user dropped it right where they picked it up.  No reordering necessary
        return;
      }
    }

    let reorderedList = [...props.list!];
    const [removedItem] = reorderedList.splice(sourceIndex, 1);
    reorderedList.splice(destinationIndex, 0, removedItem);

    reorderedList = reorderedList.map(
      (
        option: ResearchQuestionSelectAnswer | ResearchQuestionRadioAnswer,
        index,
      ) => {
        return {
          ...option,
          Order: index + 1,
        };
      },
    );

    props.handleOrderUpdate(reorderedList);
  };

  const onValueChange = (newTitle: string, index: number) => {
    console.log("onValueChange inlineInputChange");
    const id = props.list[index].Id;
    props.handleTitleUpdate(Number(id), newTitle);
  };
  return (
    <ListContainer style={{ padding: 0 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`options-list`} key={`sections-list`}>
          {(provided: any, snapshot: any) => (
            <div ref={provided.innerRef} {...snapshot.isDraggingOver}>
              <Ul>
                {props.list.map(
                  (
                    option:
                      | ResearchQuestionSelectAnswer
                      | ResearchQuestionRadioAnswer,
                    Index: number,
                  ) => (
                    <Draggable
                      draggableId={`${option?.Id}`}
                      key={`option-${option?.Id || Index}`}
                      index={Index}
                      isDragDisabled={props.disabled}
                    >
                      {(provided, snapshot) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                          }}
                        >
                          <StyledListTitleItem className="titleIndexDiv">
                            <IndexSpan>{Index + 1}</IndexSpan>
                            <TitleSpan>
                              <InlineEditTextInput
                                wrapperClassName="option-value word-wrap cursor-pointer"
                                uniqueId={`option_${option?.Id}`}
                                onChange={(id: string, value: string) =>
                                  onValueChange(value, Index)
                                }
                                value={option.Answer}
                                valueToDisplay={option.Answer}
                                isReadOnly={props.disabled}
                              />
                            </TitleSpan>
                          </StyledListTitleItem>
                          {props.disabled ? null : (
                            <button
                              onClick={() =>
                                props.handleOptionDelete(option.Id)
                              }
                              className="deletebtn"
                            >
                              <DeleteIcon className="delete-icon listItem always-display-delete" />
                            </button>
                          )}
                        </li>
                      )}
                    </Draggable>
                  ),
                )}
              </Ul>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </ListContainer>
  );
}
