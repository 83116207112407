import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
  },
};
interface StackedAreaDataset {
  fill: boolean;
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
}
interface StackedAreaData {
  labels: string[];
  datasets: StackedAreaDataset[];
}
interface Props {
  data: StackedAreaData;
}
export default function CustomStackedAreaChart({ data }: Props) {
  return <Line options={options} data={data} />;
}
