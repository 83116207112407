import React, { useEffect, useState } from "react";
import { CustomStyleTable, CustomTableWrapper } from "./CustomTable.styled";
import { TableProps } from "antd";

interface PropTypes extends TableProps<any> {
  data: any[];
  expandedRowRender?: any;
  dynamicRowClass?: Function;
  WrapperStyle?: any;
}

export default function CustomTable({
  data,
  expandedRowRender,
  dynamicRowClass,
  WrapperStyle,
  ...props
}: PropTypes) {
  const viewHeight = document.documentElement.clientHeight;
  const additiveLength = viewHeight <= 2160 ? 20 : 40;
  const [currentLength, setCurrentLength] = useState(additiveLength);
  const [loading, setLoading] = useState(false);

  // Lazy loading and infinite scrolling starts here
  useEffect(() => {
    window.addEventListener("scroll", handleLazy);
    return () => window.removeEventListener("scroll", handleLazy);
  }, []);

  useEffect(() => {
    setCurrentLength(additiveLength);
    return () => setCurrentLength(0);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!loading) return;
    setCurrentLength((prev) => (prev += additiveLength));
    setLoading(false);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleLazy = () => {
    if (
      window.innerHeight + window.scrollY + 25 <
      document.documentElement.scrollHeight
    )
      return;
    setLoading(true);
  };

  return (
    <CustomTableWrapper style={WrapperStyle}>
      <CustomStyleTable
        {...props}
        rowClassName={(record: any) =>
          `rxblackBG table-row ${
            dynamicRowClass ? dynamicRowClass(record) : ""
          }`
        }
        dataSource={data}
        rowKey={(record: any) => record.Id}
        // This line is restricting how many rows to be shown but is needed
        // in future when need to enable pagination.
        // pagination={{ hideOnSinglePage: true, pageSize: currentLength }}
        pagination={{ hideOnSinglePage: true, pageSize: data.length }}
        showSorterTooltip={false}
        sticky={true}
        expandable={{
          expandIconColumnIndex: -1,
          expandRowByClick: expandedRowRender ? true : false,
          expandedRowRender: expandedRowRender || null,
          ...props.expandable,
        }}
      />
    </CustomTableWrapper>
  );
}
