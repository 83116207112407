import React from "react";
import { TimedActionProperty } from "../TimedActionBlock.styled";
import {
  Rating,
  TimedActionBlockRatingContainer,
} from "./TimedActionBlockRating.styled";

type Props = {
  rating: number;
};

const TimedActionBlockRating = ({ rating, ...props }: Props) => {
  return (
    <TimedActionProperty>
      <TimedActionBlockRatingContainer>
        <span className="title">Rating</span>
        <Rating ratingNumber={rating}>{rating < 1 ? <></> : rating}</Rating>
      </TimedActionBlockRatingContainer>
    </TimedActionProperty>
  );
};

export default TimedActionBlockRating;
