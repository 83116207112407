import * as React from "react";
import "./ViewScripts.scss";
import { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  UnlockOutlined,
  ExclamationCircleOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Spin, Avatar, Tooltip } from "antd";
import { AllScripts, getScriptWithId } from "../../api/Script/ApiGet";
import { connect, useSelector } from "react-redux";
import {
  deleteScript,
  forkScript,
  updateScript,
} from "../../api/Script/ApiPost";
import ViewScriptsFilter from "./ViewScriptsFilter";
import {
  checkPermission,
  convertMinutesToHHMM,
  getAuthorName,
  isNullOrUndefined,
} from "../../utils/utilityfunctions";
import { AlertType } from "../../models/Enums";
import { SystemMessage } from "../../app/modules";
import { setAlertMessage } from "../../actions/alertMessage";
import {
  EyeOutlinedIcons,
  ForkOutlinedIcons,
  PublishedOutlinedIcons,
  UserOutlinedIcons,
} from "../../IconsTheme/Icons.global";
import { NavigationTitle } from "../../components/GlobalStyledComponents.styled";
import ViewScriptDetails from "./ViewScriptDetails";
import {
  getCurrentDate,
  militaryDateTimeConversion,
} from "../../utils/dateUtils";
import { Phase, Script, Section } from "../../models/ScriptInterfaces";
import {
  OpenErrorNotification,
  OpenWarningNotification,
} from "../../components/Notification/Notification";
import produce from "immer";
import { useHistory } from "react-router";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../components/ConfirmDialog/useConfirmDialog";
import {
  ActionIcon,
  CustomTableContainer,
  HoverIconContainer,
  StyledArchivedScriptEditOutlined,
  StyledEditOutlined,
  StyledHeartOutlined,
  StyledInfoCircleOutlined,
  StyledViewOutlined,
  TitleContainer,
  TitleContainerDiv,
} from "../../components/Table/CustomTable.styled";
import { ReactComponent as Archive } from "../../assets/img/Svg/Archive.svg";
import Icon from "@ant-design/icons/lib/components/Icon";
import { colorPalette } from "../../styles/MainList.styled";
import { every, isEmpty, noop } from "lodash";
import { useAppSelector } from "../../store/hooks";
import User from "../../models/User";
interface Props {
  SystemMessage: SystemMessage;
  setAlertMessage: Function;
}

const ViewScripts = (props: Props) => {
  const history = useHistory();
  const [toggleChecked, setToggleChecked] = useState<boolean>(false);
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<Script[]>([]);
  const [modalId, setModalId] = useState<any>(0);
  const [currentModal, setCurrentModal] = useState<boolean>(false);
  const auth = useAppSelector((state) => state.authReducer);
  const { scriptTableFeature } = useSelector(
    (state: any) => state.FeatureFlagReducer,
  );

  const [viewDetailsModal, setViewDetailsModal] = useState<boolean>(false);
  const [ViewDetails, setViewDetails] = useState<any>({});
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  // Open view Media details panel with Media data.

  const closeModal = () => {
    setViewDetailsModal(false);
    setViewDetails({});
  };
  // This method is not in use anymore. Delete it.
  const showModalForAction = (e: any) => {
    const modalBodyElement = e.target.parentNode.nextElementSibling;
    const clickedScriptPosition = e.target.getBoundingClientRect().bottom;
    const tableBody = document.getElementsByClassName(
      "ant-table-body",
    ) as HTMLCollectionOf<HTMLElement>;
    if (modalBodyElement != null) {
      if (modalBodyElement.className === "modal-body") {
        modalBodyElement.style.display = "block";
        if (tableBody[0].getBoundingClientRect().y < window.innerHeight) {
          tableBody[0].style.minHeight = "70vh";
        }
        if (window.innerHeight - clickedScriptPosition < 300) {
          modalBodyElement.style.bottom = "103%";
          modalBodyElement.style.top = null;
        } else {
          modalBodyElement.style.top = "103%";
          modalBodyElement.style.bottom = null;
        }
        setCurrentModal(true);
        setModalId(modalBodyElement.id);
        if (modalId === modalBodyElement.id) {
          if (currentModal) {
            document.getElementById(modalId)!.style.display = "none";
            setCurrentModal(false);
          }
        }
        if (
          modalId != null &&
          modalId !== modalBodyElement.id &&
          document.getElementById(modalId) != null
        ) {
          document.getElementById(modalId)!.style.display = "none";
        }
      }
    }
  };
  const displayOrHideModal = (e: any) => {
    if (modalId != null) {
      if (
        e.target.className !== "modal-body" &&
        document.getElementById(modalId) != null
      ) {
        document.getElementById(modalId)!.style.display = "none";
      }
    }
  };

  useEffect(() => {
    AllScripts(async (data: Script[]) => {
      getSuccess(data);
    }, getError);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSuccess = (data: Script[]) => {
    setResult(
      data
        .sort((a: any, b: any) =>
          new Date(b.UpdatedDate).getTime() < new Date(a.UpdatedDate).getTime()
            ? -1
            : 1,
        )
        .map((i: any) => ({ ...i, key: i.Id })),
    );
    setLoading(false);
  };
  const getError = (data: any) => {
    setLoading(false);
  };

  const deleteScriptHandler = (id: number) => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to delete the script and its related data",
      content:
        "Are you sure you want to permanently delete this script and its related data?",
      okText: "Yes",
      onOk: () => {
        deleteScript(id, deleteSuccess, error);
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: () => {
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };

  const deleteSuccess = (data: any) => {
    console.log(data);
    //message.success(data);
    props.setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: data,
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });

    AllScripts(getSuccess);
  };

  const error = (data: any) => {
    console.log(data);
    setLoading(false);
  };

  const handleForkScript = (scriptId: number) => {
    setLoading(true);
    forkScript(
      scriptId,
      (data: Script) => {
        setLoading(true);
        window.location.href = `ScriptBuilder/${data.Id}`;
      },
      (errorMessage: string) => {
        setLoading(true);
        OpenErrorNotification({ description: errorMessage });
      },
    );
  };

  const openDetailPanel = (script: Script) => {
    setViewDetails(script);
    setViewDetailsModal(true);
  };
  const onEditScenario = (scenarioId: number) => {
    history.push(`/ScriptBuilder/${scenarioId}`);
  };
  const confirmAndChangeScriptStatus = (script: Script) => {
    if (script.Public) {
      OpenWarningNotification({
        description:
          "Scripts must be made private in order to be changed to Draft status.",
      });
      return;
    }

    if (script.IsDraft && !script.CasualtyId) {
      setIsOpenConfirmDialog(true);

      setConfirmDialogProps({
        title: "You must have a casualty setup for the script",
        content: "The script must have an attached casualty to be published.",
        okText: "Setup Casualty",
        onOk: () => {
          setIsOpenConfirmDialog(false);
          history.push(`/ScriptBuilder/${script.Id}?view=select-casualty`);
        },
        cancelText: "Cancel",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "warning",
      });
      return;
    }

    if (script.IsDraft) {
      getScriptWithId(
        script.Id,
        (data: Script) => {
          const hasTitleForAllTimedActions = every(
            data?.Phases,
            (phase: Phase) => {
              return every(
                phase.Sections,
                (section: Section) =>
                  section.SectionType !== "timed actions" ||
                  (section.SectionType === "timed actions" &&
                    !isEmpty(section.Title)),
              );
            },
          );
          if (!hasTitleForAllTimedActions) {
            setIsOpenConfirmDialog(true);
            setConfirmDialogProps({
              type: "error",
              title: "Cannot publish script",
              content: `Some of the sections doesn't have titles, please update them before publishing.`,
              okText: "Okay",
              onOk: () => {
                setIsOpenConfirmDialog(false);
              },
              cancelText: "Close",
              onCancel: () => {
                setIsOpenConfirmDialog(false);
              },
            });
            return;
          }
          setIsOpenConfirmDialog(true);
          setConfirmDialogProps({
            title: "You are going to update the script and its related data",
            content: `Please confirm you want to publish this script?`,
            okText: "Yes",
            onOk: () => {
              UpdateScriptDetail(
                { ...script, IsDraft: !script.IsDraft },
                "IsDraft",
              );
              setIsOpenConfirmDialog(false);
            },
            cancelText: "No",
            onCancel: () => {
              setIsOpenConfirmDialog(false);
            },
            type: "warning",
          });
        },
        noop,
      );
    } else {
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "You are going to update the script and its related data",
        content: `Please confirm you want to unpublish this script?`,
        okText: "Yes",
        onOk: () => {
          UpdateScriptDetail(
            { ...script, IsDraft: !script.IsDraft },
            "IsDraft",
          );
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "warning",
      });
    }
  };

  const confirmAndChangeScriptAccess = (script: Script) => {
    const { Public: isPublic } = script;
    if (script.IsDraft) {
      OpenWarningNotification({
        description: "Scripts must be published in order to be made public.",
      });
      return;
    }

    const accessMessage = isPublic
      ? "private. Other organizations will no longer have access to view it."
      : "public. Other organizations will be able to view it.";

    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to update the script and its related data",
      content: `Please confirm you want to make this script ${accessMessage}`,
      okText: "Yes",
      onOk: () => {
        UpdateScriptDetail({ ...script, Public: !isPublic }, "Public");
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: () => {
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };
  const archiveScenario = (script: Script) => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to update the script and its related data",
      content: `Please confirm you want to ${
        script.IsArchived ? "unarchive" : "archive"
      } this script?`,
      okText: "Yes",
      onOk: () => {
        UpdateScriptDetail(
          { ...script, IsArchived: !script.IsArchived },
          "IsArchived",
        );
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: () => {
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };
  const UpdateScriptDetail = (script: Script, field: keyof Script) => {
    const payload: Script = {
      Id: script.Id,
      Title: script.Title,
      Description: script.Description,
      CreatedDate: getCurrentDate(),
      UpdatedDate: getCurrentDate(),
      Public: script.Public,
      IsReadOnly: script.IsReadOnly,
      Clonable: script.Clonable,
      CasualtyId: script.CasualtyId || 0,
      Casualty: script.Casualty,
      IsDraft: script.IsDraft,
      IsArchived: script.IsArchived,
    };
    updateScript(
      payload,
      (data: Script) => {
        setResult(
          produce((draft: any) => {
            const Script = draft.find((x: any) => x.Id === data.Id);
            if (Script) {
              if (field === "IsArchived") {
                Script["IsArchived"] = data["IsArchived"];
                Script["Public"] = data["Public"];
              } else {
                Script[field] = data[field];
              }
            }
          }),
        );
      },
      (error: any) =>
        OpenErrorNotification({
          description: error,
        }),
    );
  };

  const renderAuthor = (script: Script) => {
    return (
      <>
        <div className="company-info">
          <Tooltip title={auth.company.Name}>
            <Avatar
              className="company-avatar"
              size="large"
              shape="circle"
              src={
                !isNullOrUndefined(auth.company) ? (
                  auth.company?.LogoImageMedia?.Url
                ) : (
                  <UserOutlinedIcons />
                )
              }
              style={{ marginRight: "5px" }}
            />
          </Tooltip>
          <span
            className={`company-name ${script.IsArchived ? "disabled " : ""}`}
          >
            {/* TODO ; Add user title */}
            {!isNullOrUndefined(script.CreatedBy) ? (
              <Tooltip title={getAuthorName(script.CreatedBy)}>
                {getAuthorName(script.CreatedBy)}
              </Tooltip>
            ) : (
              ""
            )}
          </span>
        </div>
      </>
    );
  };
  const renderStatus = (IsDraft: boolean, script: Script) => {
    return (
      <span
        className={`script-status-icon
        ${script.Public || script.IsArchived ? "disabled-icon" : ""}`}
        onClick={() =>
          !script.IsArchived &&
          !script.IsReadOnly &&
          confirmAndChangeScriptStatus(script)
        }
      >
        {script.IsDraft ? (
          <Tooltip title="Draft">
            <ExclamationCircleOutlined style={{ color: "#7D1EF6" }} />
          </Tooltip>
        ) : (
          <Tooltip title="Published">
            <PublishedOutlinedIcons />
          </Tooltip>
        )}
      </span>
    );
  };
  const renderAccess = (Public: boolean, script: Script) => {
    return (
      <span
        className={`access-icon ${!script.Public ? "lock-icon" : "unlock-icon"}
      ${script.IsDraft || script.IsArchived ? "disabled-icon" : ""}
    `}
        onClick={() =>
          !script.IsArchived &&
          !script.IsReadOnly &&
          confirmAndChangeScriptAccess(script)
        }
      >
        {!script.Public ? (
          <Tooltip title="Private">
            <LockOutlined />
          </Tooltip>
        ) : (
          <Tooltip title="Public">
            <UnlockOutlined />
          </Tooltip>
        )}
      </span>
    );
  };
  const renderTime = (estimatedTimeToComplete: number, script: Script) => {
    return (
      <span
        className={`${script.IsArchived ? "disabled " : ""}`}
        style={{ color: "#fff" }}
      >
        {estimatedTimeToComplete
          ? convertMinutesToHHMM(estimatedTimeToComplete)
          : "--:--"}
      </span>
    );
  };
  const renderModifiedTime = (updatedDate: any) => {
    return (
      <span className="time">{militaryDateTimeConversion(updatedDate)}</span>
    );
  };
  const renderActionOptions = (hasPermission: boolean, script: Script) => {
    return (
      <div className={"action-tray"}>
        {(hasPermission || script.IsReadOnly) && (
          <>
            {!script.IsArchived ? (
              <>
                {script.IsReadOnly ? (
                  <>
                    {/* view icon */}
                    <ActionIcon
                      className="action-option"
                      hoverColor={colorPalette.white}
                      onClick={() => onEditScenario(script.Id)}
                    >
                      <span className="tooltip-on-hover">View</span>
                      <EyeOutlinedIcons className="display-on-hover" />
                    </ActionIcon>
                    {/* fork icon */}
                    <ActionIcon
                      className="action-option"
                      hoverColor={colorPalette.white}
                      onClick={() => handleForkScript(script.Id)}
                    >
                      <span className="tooltip-on-hover">Fork</span>
                      <ForkOutlinedIcons className="display-on-hover" />
                    </ActionIcon>
                  </>
                ) : (
                  <>
                    {/* archive/ unarchive icons */}
                    <ActionIcon
                      className="action-option"
                      hoverColor={
                        !script.IsArchived
                          ? colorPalette.error
                          : colorPalette.primary100
                      }
                      onClick={() => archiveScenario(script)}
                    >
                      <span className="tooltip-on-hover">
                        {script.IsArchived ? "Unarchive" : "Archive"}
                      </span>
                      <Icon component={Archive} className="display-on-hover" />
                    </ActionIcon>
                    {/* delete icon */}
                    <ActionIcon
                      className="action-option"
                      hoverColor={colorPalette.error}
                      onClick={() => deleteScriptHandler(script.Id)}
                    >
                      <span className="tooltip-on-hover">Delete</span>
                      <DeleteOutlined className="display-on-hover" />
                    </ActionIcon>
                    {/* fork icon */}
                    <ActionIcon
                      className="action-option"
                      hoverColor={colorPalette.white}
                      onClick={() => handleForkScript(script.Id)}
                    >
                      <span className="tooltip-on-hover">Fork</span>
                      <ForkOutlinedIcons className="display-on-hover" />
                    </ActionIcon>
                    {/* edit icon */}
                    <ActionIcon
                      className="action-option"
                      hoverColor={colorPalette.white}
                      onClick={() => onEditScenario(script.Id)}
                    >
                      <span className="tooltip-on-hover">Edit</span>
                      <EditOutlined className="display-on-hover" />
                    </ActionIcon>
                  </>
                )}
              </>
            ) : (
              // {/* unarchive icons */}
              <ActionIcon
                className="action-option"
                hoverColor={colorPalette.white}
                onClick={() => archiveScenario(script)}
              >
                <span className="tooltip-on-hover">
                  {script.IsArchived ? "Unarchive" : "Archive"}
                </span>
                <Icon component={Archive} className="display-on-hover" />
              </ActionIcon>
            )}
          </>
        )}
      </div>
    );
  };
  const renderTitleColumn = (title: string, script: Script) => {
    return (
      <TitleContainer>
        {(checkPermission(
          auth.user,
          auth.userCompanyId,
          script.CreatedBy?.Id || "",
          script.DataKeyTenantId,
        ) ||
          script.IsReadOnly) &&
        !script.IsArchived ? (
          <TitleContainerDiv
            className="cursor-pointer"
            onClick={() => onEditScenario(script.Id)}
          >
            <div
              className={`title text-white ${
                script.IsReadOnly ? "readOnly" : ""
              }`}
            >
              {script.IsSeed ? (
                <StarFilled
                  style={{
                    marginRight: 8,
                  }}
                />
              ) : null}
              {title}
            </div>
            {script.IsReadOnly ? (
              <StyledViewOutlined className="display-on-hover action-edit" />
            ) : (
              <StyledEditOutlined className="display-on-hover action-edit" />
            )}
          </TitleContainerDiv>
        ) : (
          <TitleContainerDiv>
            <span
              className={`title ${script.IsArchived ? "archived-title" : ""}`}
            >
              {script.IsSeed ? (
                <StarFilled
                  style={{
                    marginRight: 8,
                  }}
                />
              ) : null}
              {title}
            </span>
            <StyledArchivedScriptEditOutlined className="display-on-hover action-edit disabled" />
          </TitleContainerDiv>
        )}
        <HoverIconContainer>
          {scriptTableFeature.tableRow.detailIcon && (
            <span
              className={`detail-link ${script.IsArchived ? "disabled " : ""}`}
              onClick={() => !script.IsArchived && openDetailPanel(script)}
            >
              <Tooltip title="Details">
                <StyledInfoCircleOutlined />
              </Tooltip>
            </span>
          )}
          {scriptTableFeature.tableRow.favoriteIcon && (
            <span
              className={`like-icon onHover-white-icon ${
                script.IsArchived ? "disabled " : ""
              }`}
            >
              <Tooltip title="Favorite">
                <StyledHeartOutlined />
              </Tooltip>
            </span>
          )}
        </HoverIconContainer>
      </TitleContainer>
    );
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "Title",
      key: "title",
      className: "whiteText script-title",
      sorter: (a: any, b: any) => a.Title.localeCompare(b.Title),
      render: (title: any, script: Script) => renderTitleColumn(title, script),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "status",
      className: "whiteText status-icon",
      sorter: (a: any, b: any) => a.IsDraft - b.IsDraft,
      render: (IsDraft: any, Script: Script) => renderStatus(IsDraft, Script),
    },
    {
      title: "Access",
      dataIndex: "Access",
      key: "access",
      className: "whiteText status-icon",
      sorter: (a: any, b: any) => a.Public - b.Public,
      render: (Public: any, Script: Script) => renderAccess(Public, Script),
    },
    {
      title: "~Time",
      dataIndex: "Time",
      key: "EstimatedTimeToCompelete",
      className: "whiteText status-icon",
      sorter: (a: any, b: any) =>
        a.EstimatedTimeToCompelete === b.EstimatedTimeToCompelete,
      render: (EstimatedTimeToCompelete: any, script: Script) =>
        renderTime(EstimatedTimeToCompelete, script),
    },
    {
      title: "Author",
      dataIndex: "CreatedBy",
      key: "CreatedBy",
      className: "whiteText author-column",
      width: "230px",
      sorter: (a: Script, b: Script) =>
        getAuthorName(a.CreatedBy)?.localeCompare(getAuthorName(b.CreatedBy)),
      render: (author: User, script: Script) => {
        return renderAuthor(script);
      },
    },

    {
      title: "Modified",
      dataIndex: "UpdatedDate",
      key: "UpdatedDate",
      className: "whiteText modified-time",
      width: "180px",
      sorter: (a: any, b: any) =>
        new Date(b.UpdatedDate).getTime() > new Date(a.UpdatedDate).getTime(),
      render: (UpdatedDate: any, script: Script) => {
        return renderModifiedTime(UpdatedDate);
      },
    },
    {
      title: "",
      dataIndex: "actions",
      className: "actions",
      render: (actions: any, script: Script) => {
        const hasPermission = checkPermission(
          auth.user,
          auth.userCompanyId,
          script.CreatedBy?.Id || "",
          script.DataKeyTenantId,
        );
        return renderActionOptions(hasPermission, script);
      },
    },
  ];

  const archivedScriptRow = (script: Script) => {
    return script.IsArchived ? "archived-row" : "";
  };

  return (
    <>
      <NavigationTitle className="script-library-title">
        Script Library
      </NavigationTitle>
      <CustomTableContainer onClick={displayOrHideModal}>
        <Spin spinning={loading}>
          <ViewScriptsFilter
            columns={columns}
            data={
              includeArchived
                ? result
                : result.filter((x: Script) => !x.IsArchived)
            }
            setIncludeArchived={setIncludeArchived}
            includeArchived={includeArchived}
            toggleChecked={toggleChecked}
            setToggleChecked={setToggleChecked}
            archivedScriptRow={archivedScriptRow}
          />
          <ViewScriptDetails
            viewDetailsModal={viewDetailsModal}
            onClose={closeModal}
            script={ViewDetails}
          />
        </Spin>
      </CustomTableContainer>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </>
  );
};

function mapToStateToProps(state: any) {
  return {
    auth: state.authReducer,
    SystemMessage: state.SystemMessage,
  };
}

export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(ViewScripts);
