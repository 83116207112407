import axios from "axios";
import { logout } from "../actions/authActions";

export function handleAxiosUnauthorizedRequest() {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response ? error.response.status : null;

      if (status === 401) {
        logout();
        window.location.href = "/";
      }

      return Promise.reject(error);
    },
  );
}
