import { Menu, Row, Button, Col } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import styled from "styled-components";
import {
  colorPalette,
  hexTransparencies,
} from "../../../../../styles/MainList.styled";
import { PaneBody } from "./../styled";
import {
  InsultWithRelation,
  TextParameter,
} from "../../../../../models/InsultInterface";

export const StyledBody = styled(PaneBody)`
  height: calc(100vh - 64px - 55px - 60px - 60px);
  padding: 0px;
  margin: 1px 0;
  ul {
    padding: 0 5px;
    margin: 0;
  }
  .select-injury-insult span.text-primary,
  .select-injury-insult span.text-primary > span.units {
    color: ${"#ffffff" + hexTransparencies[50]};
  }
  .select-injury-insult {
    cursor: pointer;
    .injury-header {
      & > button.ant-btn {
        height: auto;
        width: 40px;
      }
    }
  }
`;

export const StyledFooter = styled.div`
  display: inline-flex;
  width: 100%;
`;

export const StyledListItem = styled.li`
  font-size: 1.125rem;
  background-color: #1f1f1f;
  border-bottom: 1px solid ${"#ffffff" + hexTransparencies[50]};
  margin-bottom: 4px;
  color: #fff;
  padding: 16px 10px;
  cursor: pointer;
  &:hover {
    background-color: #272727;
  }
`;

export const StyledInsultItem = styled(StyledListItem)<{
  $insult?: InsultWithRelation;
}>`
  border-left: 5px solid ${(props) => props.$insult?.Category?.Color};
  display: flex;
  align-items: stretch;
  padding: 0;
  & > div {
    flex: 1;
    padding: 16px 5px;
  }
  &.disabled,
  &.disabled > .ant-btn-icon-only.ant-btn-lg {
    cursor: not-allowed;
    color: ${colorPalette.hoverColor};
  }
  & > .ant-btn-icon-only.ant-btn-lg {
    padding: 10px 5px;
    height: auto;
    width: 50px;
  }
`;

// Applied casualty injury list
export const InjuryWrapper = styled.div`
  margin: 0 5px 15px;
  .injury-header {
    display: flex;
    font-size: 1.125rem;
    background-color: #1f1f1f;
    border-bottom: 1px solid ${"#ffffff" + hexTransparencies[50]};
    margin-bottom: 4px;
    color: #fff;
    align-items: stretch;
    min-height: 3.5rem;
    & > .title-block {
      padding: 0px 10px;
      flex: 1;
      display: flex;
      align-items: center;
      width: 50%;
      & > .anticon {
        display: flex;
        align-items: center;
      }
      & > .title {
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & > .location-param {
      width: 12.5rem;
      border-left: 1px solid ${colorPalette.gray50};
      border-right: 1px solid ${colorPalette.gray50};
      padding: 0px 5px;
      padding-top: 5px;
      overflow: hidden;
      & > div {
        overflow: hidden;
      }
    }
    label {
      display: block;
      margin-bottom: 5px;
      font-size: 0.5rem;
      line-height: 0.5rem;
      color: ${"#ffffff" + hexTransparencies[75]};
    }
    .text-primary {
      font-size: 1rem;
      display: block;
      text-align: center;
    }
    label,
    .text-primary {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    & > button.ant-btn {
      height: 100%;
      padding: 16px 0px;
    }
    & > a.ant-dropdown-trigger {
      height: 100%;
      width: 40px;
      padding: 11px 0px;
    }
    .actions-item {
      &:hover {
        color: #ffffff !important;
      }
    }
    &:hover {
      background-color: #272727;
    }
  }
`;

export const InjuryBlock = styled.div`
  margin-left: 20px;
`;

export const InsultItemRow = styled(Row)<{ $insult?: InsultWithRelation }>`
  flex-wrap: nowrap;
  border-left: 5px solid ${(props) => props.$insult?.Category?.Color};
  align-items: stretch;
  font-size: 1.125rem;
  background-color: #1f1f1f;
  margin-bottom: 4px;
  color: #fff;
  &.withoutInjury {
    cursor: pointer;
    margin: 0 5px 15px;
  }
  .ant-col {
    display: flex;
    padding: 0px 5px;
    overflow: hidden;
    & > div {
      overflow: hidden;
      width: 100%;
    }
    &.insult-title {
      padding: 16px 10px;
      align-items: center;
      & > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    &:not(:first-child) {
      border-left: 1px solid ${colorPalette.gray50};
      padding-top: 5px;
    }
    &.boolean-param {
      min-width: 100px;
    }

    &.number-param,
    &.interval-param,
    &.time-param,
    &.switch-param {
      min-width: 70px;
    }

    &.location-param {
      min-width: 12.5rem;
    }

    .ant-switch {
      margin: 5px 1px;
    }

    label,
    .units {
      display: block;
      margin-bottom: 5px;
      font-size: 0.5rem;
      line-height: 0.5rem;
      color: ${"#ffffff" + hexTransparencies[75]};
    }
    .units {
      text-align: center;
    }
    .text-primary {
      font-size: 1rem;
      display: block;
      text-align: center;
      .checkCircleIcon {
        font-size: 1.25rem;
        margin: 0.2rem 0 0 0;
      }
      span.units {
        display: inline-block;
        margin-left: 0.2rem;
        color: ${colorPalette.primary100};
        font-size: 1rem;
      }
    }
    label,
    .text-primary {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .actions-item {
      &:hover {
        color: #ffffff !important;
      }
    }
  }
  &:hover {
    background-color: #272727;
    .ant-col:not(:first-child) {
      border-left: 1px solid ${colorPalette.hoverColor};
    }
  }
`;

export const TextParamCol = styled(Col)<{
  $insult?: InsultWithRelation;
  parameter: TextParameter;
}>`
  min-width: ${(props) =>
    props.$insult?.Label.toLocaleLowerCase() === "brain injury" &&
    props.parameter.UserFacingLabel.toLocaleLowerCase() === "type"
      ? "12.5rem"
      : "100px"};
`;

export const StyledMenuItem = styled(Menu.Item)`
  color: #fff;
  font-size: 20px;
  width: 220px !important;
  border-bottom: 2px solid ${colorPalette.hoverColor};
  &.ant-dropdown-menu-item-disabled,
  &.ant-dropdown-menu-submenu-title-disabled {
    color: ${colorPalette.hoverColor};
  }
`;

export const StyledDeletedMenuItem = styled(StyledMenuItem)`
  &:hover {
    .anticon-delete {
      color: red;
    }
  }
`;

export const WhiteButton = styled(Button)`
  color: ${"#ffffff" + hexTransparencies[50]};

  &:hover {
    color: #ffffff;
  }
  &:focus {
    color: ${colorPalette.primary100};
  }
`;

export const WhiteDottedButton = styled(Button)`
  color: ${"#ffffff" + hexTransparencies[50]};

  &:hover {
    color: #ffffff;
    border-color: #ffffff;
  }
  &:focus {
    color: ${colorPalette.primary100};
  }
`;

export const StyledSubMenu = styled(SubMenu)`
  position: relative;
  // height: 52px;
  // width: 209px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(255, 255, 255, 0.15);
  background: #161616;
  &:hover {
    background: #222222;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  }

  & > .ant-dropdown-menu-submenu-title {
    height: 52px;
    width: 100%;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.5) !important;
    font-size: 20px;
    &:hover {
      background: #222222;
    }
    .ant-dropdown-menu-submenu-expand-icon .anticon {
      color: #fff !important;
    }
  }
  &.ant-dropdown-menu-submenu-disabled > .ant-dropdown-menu-submenu-title {
    color: #4b4b4b !important;
    background: #161616;
    &:hover {
      background: #222222;
    }
  }
`;

export const MoreOptionAction = styled(Col)`
  padding: 0 !important;

  a.ant-dropdown-link {
    display: block;
    width: 100%;
    height: 100%;

    .actions-item {
      padding: 12px 0px;
    }
  }
`;

export const StyledEditInsult = styled(StyledBody)`
  .text-primary,
  .text-primary > span.units {
    color: ${"#ffffff" + hexTransparencies[50]} !important;
  }
`;

interface SideButtonProps {
  isSelected: boolean;
  disabled: boolean;
}
export const SideButton = styled.div<SideButtonProps>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0.2rem 0 0.2rem;
  cursor: ${(props) => (props.disabled ? `not-allowed` : `pointer`)};
  background-color: ${(props) =>
    props.isSelected ? colorPalette.primary25 : colorPalette.darkerGray};
  color: ${(props) =>
    props.isSelected ? colorPalette.primary100 : colorPalette.gray25};
  border: 1px solid
    ${(props) =>
      props.isSelected ? colorPalette.primary100 : colorPalette.gray25};
  border-radius: 3px;
  &:hover {
    background-color: ${(props) =>
      props.isSelected || props.disabled ? null : colorPalette.gray10};
    color: ${(props) =>
      props.isSelected || props.disabled ? null : colorPalette.white};
    border: 1px solid
      ${(props) =>
        props.isSelected || props.disabled ? null : colorPalette.white};
  }
`;

export const StyledFormRow = styled(Row)`
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
  align-items: center;

  > div {
    margin-right: 5px;
  }

  .sides {
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin-right: 5px;
    padding-top: 1rem;
  }
`;
export const LocationFormRow = styled(StyledFormRow)`
  align-items: center;
  > div {
    padding-right: 0.5rem;
    margin-right: 0px;
    &:last-child {
      padding-right: 0;
    }
  }
`;
