import axios from "axios";

export function setAuthToken(token: string) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export function setCompanyHeader(companyToken: string) {
  if (companyToken) {
    sessionStorage.setItem("companyToken", companyToken);
    axios.defaults.headers.common["CompanyToken"] = companyToken;
  } else {
    delete axios.defaults.headers.common["CompanyToken"];
  }
}
