import { Row, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { getUsersInCompany } from "../../../api/User/ApiGet";
import CustomTable from "../../../components/Table/CustomTable";
import {
  ActionIcon,
  RightSideTableHeader,
  StyledRow,
} from "../../../components/Table/CustomTable.styled";
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  FieldTimeOutlined,
  FilterOutlined,
  MailOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import { Role } from "../User.styled";
import { SettingOutlinedIcons } from "../../../IconsTheme/Icons.global";
import { WhiteButton } from "../../ScriptBuilder/EditComponents/ScriptDetailsView/InjuryTab/InjuryTab.styled";
import { SearchFilter } from "../../../components";
import AddMember from "./AddMember";
import User from "../../../models/User";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../components/Notification/Notification";
import { colorPalette } from "../../../styles/MainList.styled";
import { TitleTD } from "../../ScriptBuilder/EditComponents/ScriptDetailsView/CasualtyTable/styledComponents/StyledTable.styled";
import { AccountStatus } from "../../../models/Enums";
import { acceptUserRequest } from "../../../api/User/ApiPost";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import produce from "immer";
import { useSelector } from "react-redux";
import { deleteUser } from "../../../api/User/ApiDelete";
import { renderFormattedName } from "../../../utils/utilityfunctions";

export default function Users() {
  const adminSimwerxId = process.env.REACT_APP_ADMIN_SIMWERX_ID;

  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [userData, setUserData] = useState<User[]>([]);
  const { manageUsersFeature } = useSelector(
    (state: any) => state.FeatureFlagReducer,
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "LastName",
      key: "LastName",
      className: "whiteText script-title noPadding",
      sorter: (a: any, b: any) => a.LastName.localeCompare(b.LastName),
      render: (LastName: any, data: any) => (
        <TitleTD>
          <div className="title">
            {renderFormattedName(data.FirstName, data.LastName)}
          </div>
          <div className="detailsLinkOnHover">
            <span className={`detail-link w-100 justify-content-center`}>
              Details <ArrowRightOutlined />
            </span>
          </div>
        </TitleTD>
      ),
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      className: "whiteText",
      sorter: (a: any, b: any) => a.Email.localeCompare(b.Email),
      render: (email: any) => <span style={{ color: "#fff" }}>{email}</span>,
    },
    {
      title: "Status",
      dataIndex: "AccountStatus",
      key: "AccountStatus",
      className: "whiteText status-icon",
      sorter: (a: any, b: any) => a.AccountStatus - b.AccountStatus,
      render: (accountStatus: AccountStatus, data: any) =>
        renderStatusRoles(accountStatus, data.Id),
    },
    {
      title: "Roles",
      dataIndex: "Roles",
      key: "roles",
      className: "whiteText",
      sorter: (a: any, b: any) => a.roles.localeCompare(b.roles),
      render: (email: any, data: any) => renderRoles(data),
    },
    {
      title: "",
      dataIndex: "",
      key: "actions",
      className: "actions",
      render: (UpdatedDate: any, data: any) => {
        return renderActionOptions(data);
      },
    },
  ];
  const renderRoles = (data: any) => {
    return (
      <Role type="Admin">Admin</Role>
      /* rest role are commented for now */
      // <>
      //   <Role type="Owner">Owner</Role>
      //   <Role type="Admin">Admin</Role>
      //   <Role type="Instructor">Instructor</Role>
      //   <Role type="Student">Student</Role>
      //   <Role type="Guest">Guest</Role>
      // </>
    );
  };
  const approveRequest = (userId: string) => {
    if (userId?.length) {
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "You are going to invite user.",
        content: "Are you sure you want invite this user?",
        okText: "Yes",
        onOk: () => {
          acceptUserRequest(
            userId,
            (data: User) => {
              setUserData(
                produce((draft) => {
                  const indexToReplace = draft.findIndex(
                    (user) => user.Email === data.Email,
                  );
                  draft[indexToReplace] = data;
                }),
              );
            },
            (errorMessage: string) => {
              OpenErrorNotification({ description: errorMessage });
            },
          );
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "warning",
      });
    }
  };
  const renderStatusRoles = (accountStatus: AccountStatus, userId: string) => {
    if (accountStatus === AccountStatus.Accepted) {
      return (
        <Tooltip title="Accepted">
          <CheckCircleOutlined
            style={{ color: colorPalette.primary100 }}
            className="font-size-2"
          />
        </Tooltip>
      );
    }
    if (accountStatus === AccountStatus.Invited) {
      return (
        <Tooltip title="Invited">
          <MailOutlined
            onClick={() => approveRequest(userId)}
            style={{ color: colorPalette.success }}
            className="font-size-2"
          />
        </Tooltip>
      );
    }
    if (accountStatus === AccountStatus.Requested) {
      return (
        <Tooltip title="Requesting to Join">
          <FieldTimeOutlined
            onClick={() => approveRequest(userId)}
            style={{ color: colorPalette.white }}
            className="font-size-2"
          />
        </Tooltip>
      );
    }
  };
  const renderActionOptions = (data: any) => {
    return (
      <div className="action-tray">
        {/* setting icon */}
        {manageUsersFeature.actionMenu.settingMenu && (
          <ActionIcon className="action-option" hoverColor={colorPalette.white}>
            <span className="tooltip-on-hover">Setting</span>
            <SettingOutlinedIcons className="display-on-hover" />
          </ActionIcon>
        )}

        {/* pause icon */}
        {manageUsersFeature.actionMenu.pauseMenu && (
          <ActionIcon
            className="action-option"
            hoverColor={colorPalette.warning}
          >
            <span className="tooltip-on-hover">Pause</span>
            <PauseOutlined className="display-on-hover" />
          </ActionIcon>
        )}

        {/* delete icon */}
        {manageUsersFeature.actionMenu.deleteMenu && (
          <ActionIcon
            className="action-option"
            hoverColor={colorPalette.error}
            onClick={() => handleDelete(data.Id)}
          >
            <span className="tooltip-on-hover">Delete</span>
            <DeleteOutlined className="display-on-hover" />
          </ActionIcon>
        )}
      </div>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    getUsersInCompany(
      (data: User[]) => {
        const usersToHide =
          process.env.REACT_APP_HIDE_USERS_FROM_TABLE &&
          process.env.REACT_APP_HIDE_USERS_FROM_TABLE.split(",");

        const updatedData = data.filter(
          (user: any) =>
            user.Id !== adminSimwerxId &&
            usersToHide &&
            usersToHide?.length &&
            usersToHide.findIndex((i: string) => i === user.Email) === -1,
        );
        setUserData(updatedData);
        setIsLoading(false);
      },
      (errorMessage: string) => {
        OpenErrorNotification({ description: errorMessage });
        setIsLoading(false);
      },
    );
  }, []);

  const handleSearch = (text?: string) => {
    // don't display script till instructor data available

    // Search guard clause
    if (!text) return userData;
    return userData;
  };

  const handleDelete = (id: string) => {
    if (id) {
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "You are going to delete user.",
        content: "Are you sure you want delete this user?",
        okText: "Yes",
        onOk: () => {
          deleteUser(
            id,
            (successMessage: string) => {
              const updatedData = userData.filter((user) => user.Id !== id);
              setUserData(updatedData);
              OpenSuccessNotification({ description: successMessage });
            },
            (errorMessage: string) => {
              OpenErrorNotification({ description: errorMessage });
            },
          );
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "error",
      });
    }
  };

  return (
    <Spin spinning={isLoading}>
      <StyledRow justify="space-between">
        <Row className="btn-row">
          {manageUsersFeature.tableHeader.filter && (
            <Tooltip title={`Filter`}>
              <WhiteButton
                type="link"
                icon={<FilterOutlined className="icon-lg" />}
                size="large"
              />
            </Tooltip>
          )}
          <SearchFilter
            className="searchbar"
            handleSearch={(value: string) => setSearchInput(value)}
          />
        </Row>
        <RightSideTableHeader>
          <AddMember setUserData={setUserData} />
        </RightSideTableHeader>
      </StyledRow>
      <CustomTable
        WrapperStyle={{ height: `calc(100vh - 65px - 70px - 48px)` }}
        columns={columns}
        data={handleSearch(searchInput)}
      />
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </Spin>
  );
}
