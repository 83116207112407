import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { confirmUserEmail } from "../../api/Auth";

const ConfirmEmail = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();

  useEffect(() => {
    confirmUserEmail(
      {
        Token: params.get("Token") || "",
        UserId: params.get("Id") || "",
      },
      () => {
        history.replace("/?from=confirm-email&status=true");
      },
      (errorMessage: string) => {
        console.error(errorMessage);
        history.replace("/?from=confirm-email&status=false");
      },
    );
  }, [location.search]);

  return <></>;
};

export default ConfirmEmail;
