import React from "react";
import AudioThumbnail from "../../../assets/img/Thumbnails/audio-thumb.png";
import PdfThumbnail from "../../../assets/img/Thumbnails/pdf-thumb.png";
import { isArray } from "is-what";
import { Tooltip } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import { Media } from "../../../models/MediaInterface";
import {
  ImagePreview,
  MediaUploadBlock,
  FilePreviewContainer,
  PreviewList,
  UploadedMedia,
  EditIcon,
  DeleteIcon,
} from "../../FileUpload/FileUpload.styled";

interface Props {
  uploadedMediaItems: Media[];
  handleDeleteMedia: Function;
  onEdit: Function;
}
export default function UploadedMediaBlock({
  uploadedMediaItems,
  handleDeleteMedia,
  onEdit,
}: Props) {
  const getMediaThumbnail = (media: Media) => {
    const mediaType = media.MediaType?.Name;
    switch (mediaType) {
      case "Image":
        return <ImagePreview src={media.Url} alt={media.Name} />;
      case "Audio":
        return <ImagePreview src={AudioThumbnail} alt={media.Name} />;
      case "Video":
        return (
          <div className="video-thumbnail">
            <PlayCircleOutlined />
            <ImagePreview src={media.ThumbnailUrl} alt={media.Name} />
          </div>
        );
      default:
        return <ImagePreview src={PdfThumbnail} alt={media.Name} />;
    }
  };

  const editMedia = (media: Media) => {
    onEdit(media);
  };
  return (
    <MediaUploadBlock>
      <FilePreviewContainer>
        <PreviewList>
          {isArray(uploadedMediaItems) &&
            uploadedMediaItems.map((file, index) => {
              return (
                <UploadedMedia key={index}>
                  <div className="mediaImage">{getMediaThumbnail(file)}</div>
                  <div className="mediaContent">
                    <Tooltip placement="top" title={file?.Title || file.Name}>
                      <span className="mb-3">{file?.Title || file.Name}</span>
                    </Tooltip>
                    <p className="mb-1">{file.Description}</p>
                  </div>
                  <div className="mediaEditDelete">
                    <Tooltip placement="top" title="Edit">
                      <div
                        className="mediaEdit"
                        onClick={() => editMedia(file)}
                      >
                        <EditIcon />
                      </div>
                    </Tooltip>
                    <Tooltip placement="top" title="Delete">
                      <div
                        className="mediaDelete"
                        onClick={() => handleDeleteMedia(file.Id)}
                      >
                        <DeleteIcon />
                      </div>
                    </Tooltip>
                  </div>
                </UploadedMedia>
              );
            })}
        </PreviewList>
      </FilePreviewContainer>
    </MediaUploadBlock>
  );
}
