import React, { useEffect, useState } from "react";
import {
  IndexSpan,
  ListContainer,
  QuantityDiv,
  StyledListTitleItem,
  TitleSpan,
  Ul,
} from "./ShowList.styled";
import { DeleteIcon } from "../../../../IconsTheme/Icons.global";
import {
  EquipmentItem,
  ListItem,
  Phase,
  ScriptList,
} from "../../../../models/ScriptInterfaces";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ScriptListBlock } from "../ListEdit";
import { SetPhases } from "../../MainlistSlice";
import { UpdateScriptListItems } from "../../../../api/Block/ApiPost";
import produce from "immer";
import InlineEditTextArea from "../../../../components/InlineEdit/InlineEditTextArea";
import styled from "styled-components";
import InlineEditNumberInput from "../../../../components/InlineEdit/InlineEditNumberInput";
import { useDebounce } from "../../../../hooks/useDebounce";

interface ShowListprops {
  BlockContent: any;
  getListValue: any;
  object: ScriptListBlock;
  onDeleteItem: any;
}

const ShowList = (props: ShowListprops) => {
  const dispatch = useAppDispatch();
  const {
    currentPhaseIndex,
    currentSectionIndex,
    currentBlockIndex,
    phases,
    ...state
  } = useAppSelector((state) => state.MainlistReducer);
  const isReadOnly: boolean = state.isReadOnly;

  const onDeleteListItemConfirm =
    (listItem: ListItem | EquipmentItem) => () => {
      const listValue = props.getListValue();
      const deleteObj = JSON.parse(JSON.stringify(props.object));
      deleteObj.BlockContent[listValue] = [listItem];
      const replaceObj = JSON.parse(JSON.stringify(props.object));
      replaceObj.BlockContent[listValue] = replaceObj.BlockContent[
        listValue
      ].filter((x: ListItem | EquipmentItem) => x.Id !== listItem.Id);
      props.onDeleteItem(deleteObj, replaceObj);
    };

  interface BeforeCapture {
    draggableId: string;
    mode: "FLUID" | "SNAP";
  }

  const handleOnBeforeCapture = (before: BeforeCapture) => {
    // if the user grabs a section, ensure that it's collapsed
  };

  const onDragEnd = (result: DropResult) => {
    reorderBlockItems(result);
  };
  const reorderBlockItems = (result: DropResult) => {
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination!.index;

    // this is a drag reordering inside a single section

    if (sourceIndex === destinationIndex) {
      return;
    }

    let reorderedList = [...props.object.BlockContent.ListItems!];
    const [removedItem] = reorderedList.splice(sourceIndex, 1);
    reorderedList.splice(destinationIndex, 0, removedItem);

    reorderedList = reorderedList.map((listItem: ListItem, listIndex) => {
      return {
        ...listItem,
        Order: listIndex,
      };
    });
    const newPhases = produce(phases, (draftPhases) => {
      (
        draftPhases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
          currentBlockIndex
        ].BlockContent as ScriptList
      ).ListItems = reorderedList;
    });
    dispatch(SetPhases(newPhases));
    UpdateScriptListItems(
      newPhases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
        currentBlockIndex
      ],
      (response: any) => console.log("Success", response),
      (error: any) => console.log("Error", error),
    );
  };

  const onChangelistItem = (uniqueId: string, value: string) => {
    const id = uniqueId.split("inline_editing_listItem_").length
      ? uniqueId.split("inline_editing_listItem_")[1]
      : 0;

    handleListItemUpdate(Number(id), value, "Title");
  };

  const onChangelistItemQty = (uniqueId: string, value: string) => {
    const id = uniqueId.split("inline_editing_listItem_").length
      ? uniqueId.split("inline_editing_listItem_")[1]
      : 0;

    const newValue = value ? value : "0";

    handleListItemUpdate(Number(id), newValue, "Quantity");
  };

  const handleListItemUpdate = (
    id: number,
    newValue: string,
    fieldToUpdate: string,
  ) => {
    const newPhases = produce(phases, (draftPhases) => {
      const index = draftPhases![currentPhaseIndex].Sections![
        currentSectionIndex
      ].Blocks![currentBlockIndex].BlockContent!.ListItems!.findIndex(
        (s: ListItem) => s.Id === id,
      );
      if (index !== -1) {
        switch (fieldToUpdate) {
          case "Title": {
            draftPhases![currentPhaseIndex].Sections![
              currentSectionIndex
            ].Blocks![currentBlockIndex].BlockContent!.ListItems[index]!.Title =
              newValue;

            break;
          }

          case "Quantity": {
            draftPhases![currentPhaseIndex].Sections![
              currentSectionIndex
            ].Blocks![currentBlockIndex].BlockContent!.ListItems[
              index
            ]!.Quantity = newValue;

            break;
          }
        }
      }
    });
    dispatch(SetPhases(newPhases));
  };

  const onUpdateScriptListItems = () => {
    const currentBlock =
      phases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
        currentBlockIndex
      ];
    UpdateScriptListItems(
      currentBlock,
      (response: any) => console.log("Success", response),
      (error: any) => console.log("Error", error),
    );
  };

  const debounceOnUpdateScriptListItems = useDebounce(onUpdateScriptListItems);

  useEffect(() => {
    debounceOnUpdateScriptListItems();

    return () => {
      debounceOnUpdateScriptListItems.cancel();
    };
  }, [props.BlockContent?.ListItems]);

  const [isHover, setIsHover] = useState({
    position: 0,
    hover: false,
  });

  const mouseEntered = (index: any) => {
    setIsHover({
      position: index,
      hover: true,
    });
  };

  const mouseLeaved = (index: number) => {
    setIsHover({
      position: 0,
      hover: false,
    });
  };

  return (
    <ListContainer>
      {/* Need to use a different condition to check if list item is null or*/}
      {/*undefined */}
      <DragDropContext
        onDragEnd={onDragEnd}
        onBeforeCapture={handleOnBeforeCapture}
      >
        <Droppable droppableId={"droppableForListItem"}>
          {(provided: any, snapshot: any) => (
            <Ul {...provided.droppableProps} ref={provided.innerRef}>
              {props.BlockContent?.ListItems?.map(
                (item: ListItem, index: number) => (
                  <div
                    key={item.Id}
                    id="dragMenuItemsId"
                    className="draggableParentDiv"
                  >
                    <Draggable
                      draggableId={`${item.Id}`}
                      key={item.Id}
                      index={index}
                      isDragDisabled={isReadOnly}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                          }}
                        >
                          <li
                            key={index}
                            onMouseEnter={() => mouseEntered(index)}
                            onMouseLeave={() => mouseLeaved(index)}
                            onClick={() => mouseEntered(index)}
                          >
                            <StyledListTitleItem
                              className="titleIndexDiv"
                              isQuantityEnabled={
                                props.BlockContent?.QuantityEnabled
                              }
                            >
                              <IndexSpan>
                                {props.BlockContent?.IsOrderedList ? (
                                  index + 1
                                ) : (
                                  <span className="unordered">&bull;</span>
                                )}
                              </IndexSpan>
                              <TitleSpan>
                                <InlineEditTextArea
                                  isReadOnly={isReadOnly}
                                  uniqueId={`inline_editing_listItem_${item.Id}`}
                                  value={item.Title}
                                  onChange={onChangelistItem}
                                />
                              </TitleSpan>
                            </StyledListTitleItem>
                            {props.BlockContent?.QuantityEnabled && (
                              <QuantityDiv>
                                &times;&nbsp;
                                <InlineEditNumberInput
                                  uniqueId={`inline_editing_listItem_${item.Id}`}
                                  value={item.Quantity}
                                  onChange={onChangelistItemQty}
                                  maxLength={3}
                                  isReadOnly={isReadOnly}
                                />
                              </QuantityDiv>
                            )}
                            {isReadOnly ? null : (
                              <button
                                onClick={onDeleteListItemConfirm(item)}
                                className="deletebtn"
                              >
                                <DeleteIcon className="delete-icon listItem" />
                              </button>
                            )}
                          </li>
                        </div>
                      )}
                    </Draggable>
                    {provided.placeholder}
                  </div>
                ),
              )}
            </Ul>
          )}
        </Droppable>
      </DragDropContext>
    </ListContainer>
  );
};

export default ShowList;
