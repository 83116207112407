import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Col } from "antd";
import React from "react";
import { SubTaskAAR } from "../../../../../../models/AfterActionReviewInterfaces";
import { colorPalette } from "../../../../../../styles/MainList.styled";
import { convertMinutesToHoursAndMinutes } from "../../../../../../utils/utilityfunctions";
import TimedActionExpandableBlockIcon from "../IconComponent/IconComponent";
import { TimedActionExpandableBlockSubtaskContainer } from "./Subtask.styled";

type Props = {
  content: SubTaskAAR;
};

const TimedActionExpandableBlockSubtask = ({ content, ...props }: Props) => {
  const { Title, StartTime, IsCompleted } = content;

  return (
    <TimedActionExpandableBlockSubtaskContainer>
      <TimedActionExpandableBlockIcon
        icon={
          IsCompleted ? (
            <CheckOutlined style={{ color: colorPalette.primary100 }} />
          ) : (
            <CloseOutlined style={{ color: colorPalette.error }} />
          )
        }
      />
      <Col flex={"auto"} className="title">
        {Title && Title.length > 0 ? (
          <span>{Title}</span>
        ) : (
          <span className="disable-text">--</span>
        )}
      </Col>
      <Col flex={"100px"} className="time">
        {StartTime === 0 ? (
          <span className="disable-text">-- : --</span>
        ) : (
          <span>
            {convertMinutesToHoursAndMinutes(StartTime).hours}
            {" : "}
            {convertMinutesToHoursAndMinutes(StartTime).minutes}
          </span>
        )}
      </Col>
    </TimedActionExpandableBlockSubtaskContainer>
  );
};

export default TimedActionExpandableBlockSubtask;
