import * as React from "react";
import { Component } from "react";
import "@ant-design/compatible/assets/index.css";
import { PlusOutlined } from "@ant-design/icons";
import { Input as AntInput, Table, Drawer } from "antd";
import { ColumnsType } from "antd/es/table";
import { connect } from "react-redux";
import { AlertType } from "../../../models/Enums";
import { SystemMessage } from "../../../app/modules";
import { setAlertMessage } from "../../../actions/alertMessage";
import { CreateRolePlayerPromptType } from "../../../api/Block/ApiPost";
import { RolePlayerPromptType } from "../../../models/ScriptInterfaces";
import {
  StyledForm,
  StyledGreyButton,
} from "../../ScriptBuilder/EditComponents/EditForms/PhaseForm/PhaseForm.styled";
import { FormContainer } from "../../ScriptBuilder/EditComponents/EditForms/RolePlayerForm/PromptForm.styled";
import { CheckOutLinedIcon } from "../../../IconsTheme/Icons.global";
import { FloatingLabel } from "../../../components/FloatingLabel/FloatingLabel.styled";

interface Props {
  RolePlayerPromptType: RolePlayerPromptType[];
  deletePrompt: Function;
  SystemMessage: SystemMessage;
  setAlertMessage: Function;
  refreshPrompt: Function;
}
interface State {
  loading: boolean;
  addModal: boolean;
  Type: string;
  Notes: string;
  Id: number;
  formValues: {
    Notes: "";
    Type: "";
  };
  formErrors: {};
  isTypeFocused: boolean;
  isNotesFocused: boolean;
  lastFocused: any;
  getRolePlayerPromptTypeError: string;
  getNotesError: string;
}

class RolePlayerPromptTypeList extends Component<Props, State> {
  state: State = {
    loading: false,
    addModal: false,
    Type: "",
    Notes: "",
    Id: 0,
    formValues: {
      Type: "",
      Notes: "",
    },
    lastFocused: {
      Type: false,
      Notes: false,
    },
    isTypeFocused: true,
    isNotesFocused: true,
    formErrors: {},
    get getRolePlayerPromptTypeError() {
      return this.formValues.Type;
    },
    get getNotesError() {
      return this.formValues.Notes;
    },
  };
  componentDidMount() {
    this.setState({
      isNotesFocused: false,
      isTypeFocused: false,
      lastFocused: {
        Type: false,
        Notes: false,
      },
    });
  }

  validate = (values: { Type: ""; Notes: "" }) => {
    const errors: any = {};
    if (!values.Type) {
      errors.Type = "Required";
    }
    if (!values.Notes) {
      errors.Notes = "Required";
    }

    return errors;
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.setState({
      formErrors: this.validate(this.state.formValues),
      isTypeFocused: false,
      isNotesFocused: false,
    });
  };
  labelClass = (value: any) => {
    if (value === "Type") {
      return this.state.isTypeFocused || this.state.Type
        ? "label"
        : "label floatingLabel";
    } else {
      return this.state.isNotesFocused || this.state.Notes
        ? "label"
        : "label floatingLabel";
    }
  };
  handleChange = (input: any) => (e: any) => {
    this.setState({ [input]: e.target.value } as any);
    this.setState({
      ...this.state.lastFocused,
      [`${input}`]: true,
    } as any);
    this.setState({ ...this.state.formValues, [input]: e.target.value });
  };

  success = (data: any) => {
    console.log("success");
    this.setState({
      Id: 0,
      Type: "",
      Notes: "",
    });

    this.props.refreshPrompt();
    this.props.setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: "The Casualty was successfully created",
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });
  };
  handleSelect = (e: any) => {
    console.log(e);
    this.setState({ gender: e } as any);
  };

  error = (data: any) => {
    console.log(data);
    this.props.setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An error occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
  };
  addCasualty = () => {
    if (this.state.Type.length > 0) {
      const newObject: RolePlayerPromptType = {
        Id: this.state.Id || 0,
        Type: this.state.Type,
        Notes: this.state.Notes,
      };
      CreateRolePlayerPromptType(newObject, this.success, this.error);
    }

    this.setState({ addModal: false });
  };

  inputState = (input: string) => {
    if (input === "Type") {
      if (this.state.getRolePlayerPromptTypeError) {
        return "errorInput";
      } else if (
        !this.state.isTypeFocused &&
        !this.state.getRolePlayerPromptTypeError &&
        this.state.lastFocused.Type
      ) {
        return "successInput";
      } else {
        return "default";
      }
    } else {
      if (this.state.getNotesError) {
        return "errorInput";
      } else if (
        !this.state.isNotesFocused &&
        !this.state.getNotesError &&
        this.state.lastFocused.Notes
      ) {
        return "successInput";
      } else {
        return "default";
      }
    }
  };
  render() {
    const columns: ColumnsType<RolePlayerPromptType> = [
      {
        title: "Type",
        dataIndex: "Type",
        key: "Type",
        className: "whiteText",
        render: (Type: string) => <span>{Type}</span>,
      },
      {
        title: "Notes",
        dataIndex: "Notes",
        key: "Notes",
        className: "whiteText",
        render: (Notes: string) => <span>{Notes}</span>,
      },
    ];
    return (
      <div data-testid="role-play-prompt-type">
        <StyledGreyButton
          className="ant-btn realx-btn-blue"
          icon={<PlusOutlined style={{ color: "#ffffff" }} />}
          onClick={() => {
            this.setState({ addModal: true });
          }}
        >
          Add a Role Play Prompt Type
        </StyledGreyButton>

        <Table
          dataSource={this.props.RolePlayerPromptType}
          columns={columns}
          rowKey={(record: any) => record.Id}
          pagination={{ pageSize: 10 }}
          rowClassName={(record) => "rxblackBG"}
        />

        <Drawer
          title="Add a Role Play Prompt Type"
          visible={this.state.addModal}
          onClose={this.addCasualty}
          closable
          width="600"
        >
          <FormContainer>
            <StyledForm>
              <FloatingLabel
                isFocused={!this.state.isTypeFocused}
                errors={!this.state.getRolePlayerPromptTypeError}
                lastFocused={this.state.lastFocused["Type"]}
              >
                <label
                  htmlFor="rolePlayerPromptType"
                  className={this.labelClass("Type")}
                >
                  Type
                </label>
                {this.state.getRolePlayerPromptTypeError && (
                  <span className="error">
                    {this.state.getRolePlayerPromptTypeError}
                  </span>
                )}
                {!this.state.isTypeFocused &&
                  !this.state.getRolePlayerPromptTypeError &&
                  this.state.lastFocused["Type"] && (
                    <CheckOutLinedIcon className="checkCircleIcon" />
                  )}
                <AntInput
                  name="rolePlayerPromptType"
                  autoComplete="off"
                  className={this.inputState("Type")}
                  onChange={this.handleChange("Type")}
                  value={this.state.Type}
                  onBlur={this.handleSubmit}
                  onFocus={() => this.setState({ isTypeFocused: true })}
                />
              </FloatingLabel>
              <FloatingLabel
                isFocused={!this.state.isNotesFocused}
                lastFocused={this.state.lastFocused["Notes"]}
                errors={!this.state.getNotesError}
                style={{ marginTop: "30px" }}
              >
                <label htmlFor="notes" className={this.labelClass("Notes")}>
                  Notes
                </label>
                {this.state.getNotesError && (
                  <span className="error">{this.state.getNotesError}</span>
                )}
                {!this.state.isTypeFocused &&
                  !this.state.getRolePlayerPromptTypeError &&
                  this.state.lastFocused["Notes"] && (
                    <CheckOutLinedIcon className="checkCircleIcon" />
                  )}
                <AntInput
                  name="notes"
                  autoComplete="off"
                  className={this.inputState("Notes")}
                  onChange={this.handleChange("Notes")}
                  value={this.state.Notes}
                  onFocus={() => this.setState({ isNotesFocused: true })}
                  onBlur={this.handleSubmit}
                />
                <CheckOutLinedIcon className="checkCircleIcon" />
              </FloatingLabel>
            </StyledForm>
          </FormContainer>
        </Drawer>
      </div>
    );
  }
}
function mapToStateToProps(state: any) {
  return {
    SystemMessage: state.SystemMessage,
  };
}

export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(RolePlayerPromptTypeList);
