import axios from "axios";

const apiPrefix = "/api/1/pfc/Company";

export function DeleteCompanyLogo(callback: Function, errorcallback: Function) {
  axios
    .delete(`${apiPrefix}/logo`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}

export function deleteCompany(
  companyId: string,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`${apiPrefix}/${companyId}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}
