import { createGlobalStyle } from "styled-components";
import { colorPalette } from "./MainList.styled";

const GlobalStyle = createGlobalStyle`
  html {
    overflow-x: hidden;
  }

  * {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }

  #root {
    margin: 0 auto;
  }

  h1 {
    font-size: clamp(1.4rem, 1.8vw, 3.4rem);
  }

  p {
    font-size: clamp(0.75rem, 5.353vh, 1.25rem);
  }


  .ant-btn {
    border-radius: 0;
  }


  // .ant-select-dropdown {
  //   border: 1px solid ${colorPalette.primary100};
  //   background-color: ${colorPalette.primary15};

  //   .rc-virtual-list-holder-inner {
  //     background-color: transparent;

  //     .ant-select-item {
  //       color: ${colorPalette.primary100};
  //       background-color: transparent;
  //       &:hover {
  //         background-color: ${colorPalette.primary25};
  //       }
  //     }
  //   }
  // }

  .ant-select-dropdown {
    background-color: #292929;
    color: #FFFFFF !important;

    .rc-virtual-list {
      .rc-virtual-list-scrollbar{
        .rc-virtual-list-scrollbar-thumb{
          background: ${colorPalette.primary100} !important;
        }
      }
    }

    .rc-virtual-list-holder-inner {
      .ant-select-item-option-content {
        color: #FFFFFF;
      }

      .ant-select-item {
        background-color: transparent;

        &:hover {
          background-color: #464646;
        }
      }

      .ant-select-item-option-selected {
        background-color: #052125 !important;
        font-weight: normal !important;
      }
    }
  }
  .react-grid-item > .react-resizable-handle::after{
    border-color: rgb(22, 22, 22);
    transition: all 200ms linear;
  }
  .react-grid-item:hover > .react-resizable-handle::after{
    border-color: rgb(154, 154, 154);
    width: 10px;
    height: 10px;
  }

  /* Handing Nested Table Styles */
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table{
    margin:0;
  }

  /* When there is a nested table, on hovering expanded content, the content itself should not contain bg */
  .ant-table-expanded-row > td.ant-table-cell{
    background:transparent;
    padding:0;
  }

  .ant-table-expanded-row:hover > td.ant-table-cell{
    background:transparent;
  }
`;

export default GlobalStyle;
