import { PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import React from "react";

interface Props {
  fileList: UploadFile[];
  setFileList: (fileList: UploadFile[]) => void;
  onFileSelect: Function;
}
const UploadMedia = ({ fileList, setFileList, onFileSelect }: Props) => {
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB");
      }
      if (isJpgOrPng && isLt2M) {
        setFileList([file]);
        onFileSelect([file]);
      }
      return false;
    },
    fileList,
  };
  return (
    <>
      <Upload
        name="avatar"
        listType="picture-card"
        multiple={false}
        maxCount={1}
        fileList={fileList}
        {...props}
      >
        {uploadButton}
      </Upload>
    </>
  );
};

export default UploadMedia;
