import styled from "styled-components";
import { Input } from "antd";

const { TextArea } = Input;

export const Form = styled.div`
  width: 100%;
  height: auto;
  margin-top: 35px;
  position: relative;

  .error-label {
    border-bottom: 2px solid red !important;
  }

  textarea:is(:focus, :valid) + .label-name {
    border-bottom: 2px solid rgba(128, 128, 128, 255);
  }

  textarea:is(:focus, :valid) + .label-name .content-name,
  textarea:is(:focus, :valid) + .label-name .error {
    position: absolute;
    top: 0;
  }

  textarea:is(:focus, :valid) + .label-name .content-name {
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  .filled-label {
    border-bottom: 2px solid rgba(128, 128, 128, 255);
  }

  .filled-content {
    /* transform: translateY(-50px); */
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
    top: 0;
  }

  .filled-error {
    /* transform: translateY(-50px); */
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    textarea:is(:focus, :valid) + .label-name .content-name {
      font-size: 1.5em;
      color: rgba(128, 128, 128, 255);
    }

    .filled-content {
      font-size: 1.5em;
    }

    .filled-error {
      font-size: 1.2em;
    }
  }
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  //margin-top: 0.9375rem;
  background-color: rgba(0, 0, 0, 255);
  font-size: 1.25rem;
  padding-top: 1.25rem;
  padding: 20px 0px 5px 0px;
  border: none;

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    font-size: 1.125rem;
  }

  &:focus {
    outline: none;
    box-shadow: none !important;
  }

  &::-webkit-scrollbar {
    display: none;
    width: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background: rgba(38, 38, 38, 255);
    border-radius: 0.3125rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 0.1875rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 1.2em;
  }
`;

export const Label = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 10px;
  position: absolute;

  bottom: 5px;
  left: 0;

  pointer-events: none;
  border-bottom: 2px solid rgba(96, 96, 96, 255);
  transition: all 0.3s ease;
`;

export const TitleInput = styled.input`
  width: 100%;
  min-width: 10rem;
  height: 100%;
  background-color: rgba(0, 0, 0, 255);
  margin-top: 35px;
  font-size: 1.25rem;
  padding-top: 1.25rem;
  border: none;

  &:focus {
    outline: none;
  }
`;

export const LabelSpan = styled.span`
  color: rgba(112, 112, 112, 255);
  font-size: 2em;
  align-items: flex-end;
  position: absolute;
  bottom: 0px;
  left: 0px;

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 1.8em;
  }
`;

export const ErrorSpan = styled.span`
  color: red;
  font-size: 1.4em;
  align-items: flex-start;
  position: absolute;
  bottom: 0px;
  right: 0px;
`;
