import { Row } from "antd";
import * as React from "react";
import { Media } from "../../../../models/MediaInterface";
import { Image } from "antd";
import { StyledImageWrapper } from "./MediaList.styled";
import { isNullOrUndefined } from "../../../../utils/utilityfunctions";
import AudioThumbnail from "../../../../assets/img/Thumbnails/audio-thumb.png";
import PdfThumbnail from "../../../../assets/img/Thumbnails/pdf-thumb.png";

interface Props {
  mediaList: Media[];
  select: Function;
}

const MediaGridView = ({ mediaList, select }: Props) => {
  return (
    <Row>
      <Image.PreviewGroup>
        {mediaList.length
          ? mediaList.map((media: Media) => {
              return (
                <StyledImageWrapper
                  key={media.Id}
                  onClick={() => select(media)}
                >
                  {media.MediaType?.Name === "Image" ? (
                    <Image
                      width={120}
                      src={media.ThumbnailUrl}
                      alt={media.Name}
                      preview={{
                        src: media.Url,
                      }}
                    />
                  ) : media.MediaType?.Name === "Audio" ? (
                    <Image
                      width={120}
                      src={AudioThumbnail}
                      alt={media.Name}
                      preview={{
                        visible: false,
                      }}
                    />
                  ) : (
                    <Image
                      width={120}
                      src={PdfThumbnail}
                      alt={media.Name}
                      preview={{
                        visible: false,
                      }}
                    />
                  )}
                  <span className="filename"> {media.Name} </span>
                </StyledImageWrapper>
              );
            })
          : null}
      </Image.PreviewGroup>
    </Row>
  );
};

export default MediaGridView;
