export const ROOT_ROLE = "Root";
export const USER_ROLE = "User";
export const DEFAULT = "Default";
export const COMPANY_OWNER = "Company Owner";
export const COMPANY_OWNER_LOGIN = "Company Owner Login";
export const COMPANY_ADMIN = "Company Admin";
export const COMPANY_ADMIN_LOGIN = "Company Admin Login";
export const COMPANY_MANAGER = "Company Manager";
export const COMPANY_MANAGER_LOGIN = "Company Manager Login";
export const STUDENT = "Student";
export const STUDENT_LOGIN = "Student Login";
export const REALX_LOGIN = "Realx Login";
