import { Select } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import { UpdateResearchQuestionNumber } from "../../../../../api/ResearchQuestionBlock/ApiPost";
import FloatingLabelInput from "../../../../../components/FloatingLabel/FloatingLabelInput";
import FloatingLabelSelect from "../../../../../components/MuiSelect/FloatingLabelSelect";
import { FormikErrorType } from "../../../../../models/common/FormikErrorType";
import { ResearchQuestionNumberType } from "../../../../../models/Enums";
import { ResearchQuestionNumber } from "../../../../../models/ScriptInterfaces";
import { CheckBoxWrapper } from "./RightEditPanel.styled";
import { BlueCheckboxWithoutCheckedIcon } from "../../../../../styles/Checkbox.styled";
import { isNullOrUndefined } from "../../../../../utils/utilityfunctions";
const { Option } = Select;

interface Props {
  object: ResearchQuestionNumber;
  onChange: any;
  disabled: boolean;
}
type OptionType = {
  key: string;
  value: number;
  label: string;
};

export const DropdownOptions: OptionType[] = [
  {
    key: `number-input-type-${ResearchQuestionNumberType.Input}`,
    value: ResearchQuestionNumberType.Input,
    label: "Input",
  },
  {
    key: `number-input-type-${ResearchQuestionNumberType.Slider}`,
    value: ResearchQuestionNumberType.Slider,
    label: "Slider",
  },
];
export default function NumberQuestionType(props: Props) {
  const focusedStates = {
    inputType: false,
    step: false,
    min: false,
    max: false,
    min2: false,
    max2: false,
  };
  const [isFocused, setIsFocused] = useState(focusedStates);
  const [lastFocused, setLastFocused] = useState(focusedStates);
  const scriptFormik = useFormik({
    initialValues: {
      inputType:
        props?.object?.ResearchQuestionNumberType ||
        ResearchQuestionNumberType.Input,
      step: props?.object?.Step || 0,
      min: props?.object?.MinValue1 || 0,
      max: props?.object?.MaxValue1 || 0,
      min2: props?.object?.MinValue2 || 0,
      max2: props?.object?.MaxValue2 || 0,
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const handleChange = (
    dbField: string,
    value: string | number | boolean,
    field?: string,
  ) => {
    if (props.disabled) return; //disable action callback in read only script
    const Payload = { ...props.object, [dbField]: value };
    props.onChange("ResearchQuestionNumber", Payload);
    UpdateResearchQuestionNumber(
      Payload,
      () => undefined,
      () => undefined,
    );
    if (field) {
      scriptFormik.setFieldValue(field, value);
    }
  };

  const validateInputValue = (dbField: string, isRange?: boolean) => {
    if (props.disabled) return; //disable action callback in read only script
    let min = Number(scriptFormik.values["min"]);
    let max = Number(scriptFormik.values["max"]);
    let min2 = Number(scriptFormik.values["min2"]);
    let max2 = Number(scriptFormik.values["max2"]);
    let isValueChanged = false;
    if (isNullOrUndefined(isRange)) {
      isRange = props?.object?.IsRange || false;
    }
    switch (dbField) {
      case "MinValue1":
        // check max1
        if (min > max) {
          max = min;
          isValueChanged = true;
        }
        break;
      case "MaxValue1":
        // check min1
        if (min > max) {
          min = max;
          isValueChanged = true;
        }
        // check range
        if (isRange) {
          // check min2
          if (min2 < max) {
            min2 = max;
            isValueChanged = true;
          }
          // check max2
          if (max2 < max) {
            max2 = max;
            isValueChanged = true;
          }
        }
        break;
      case "MinValue2":
        // check max2
        if (min2 > max2) {
          max2 = min2;
          isValueChanged = true;
        }
        // check max1
        if (max > min2) {
          max = min2;
          isValueChanged = true;
        }
        // check min1
        if (min > min2) {
          min = min2;
          isValueChanged = true;
        }
        break;
      case "MaxValue2":
        // check min2
        if (min2 > max2) {
          min2 = max2;
          isValueChanged = true;
        }
        // check max1
        if (max > max2) {
          max = max2;
          isValueChanged = true;
        }
        // check min1
        if (min > max2) {
          min = max2;
          isValueChanged = true;
        }
        break;
      default:
        break;
    }
    if (isValueChanged) {
      const Payload = {
        ...props.object,
        MinValue1: min,
        MaxValue1: max,
        MinValue2: min2,
        MaxValue2: max2,
      };
      props.onChange("ResearchQuestionNumber", Payload);
      UpdateResearchQuestionNumber(
        Payload,
        () => undefined,
        () => undefined,
      );
      scriptFormik.setFieldValue("min", min);
      scriptFormik.setFieldValue("max", max);
      scriptFormik.setFieldValue("min2", min2);
      scriptFormik.setFieldValue("max2", max2);
    }
  };
  const handleDualRangeSelectionChange = (value: string | number | boolean) => {
    if (props.disabled) return; //disable action callback in read only script
    handleInitialRangeCalculation(!!value);
  };

  const handleInitialRangeCalculation = (isRange: boolean) => {
    if (props.disabled) return; //disable action callback in read only script
    const minOne = Number(scriptFormik.values["min"]);
    const maxOne = Number(scriptFormik.values["max"]);
    let minTwo = Number(scriptFormik.values["min2"]);
    let maxTwo = Number(scriptFormik.values["max2"]);

    minTwo = maxOne;
    maxTwo = minTwo + (maxOne - minOne);

    const Payload = {
      ...props.object,
      IsRange: isRange,
      MinValue1: minOne,
      MaxValue1: maxOne,
      MinValue2: minTwo,
      MaxValue2: maxTwo,
    };
    props.onChange("ResearchQuestionNumber", Payload);
    UpdateResearchQuestionNumber(
      Payload,
      () => undefined,
      () => undefined,
    );
    scriptFormik.setFieldValue("min", minOne);
    scriptFormik.setFieldValue("max", maxOne);
    scriptFormik.setFieldValue("min2", minTwo);
    scriptFormik.setFieldValue("max2", maxTwo);
  };

  return (
    <>
      <div className="mb-8">
        <FloatingLabelSelect
          label="Number Input Type"
          className="w-100"
          onChange={(e: any) =>
            handleChange("ResearchQuestionNumberType", e, "inputType")
          }
          value={scriptFormik.values["inputType"]}
          disabled={props.disabled}
        >
          {DropdownOptions.map((op: OptionType) => {
            return (
              <Option key={op.key} value={op.value} title="">
                {op.label}
              </Option>
            );
          })}
        </FloatingLabelSelect>
      </div>
      <div className="mb-8 d-inline-flex">
        <FloatingLabelInput
          style={{ width: "180px" }}
          label={"Step"}
          nameForInput={"step"}
          maxLength={10}
          value={scriptFormik.values["step"]}
          onChange={(e: any) => handleChange("Step", e.target.value, "step")}
          scriptFormik={scriptFormik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          isAddNeeded={false}
          step={1}
          precision={2}
          readOnly={props.disabled}
        />
        <div className="d-flex align-items-end">
          <CheckBoxWrapper
            style={{
              padding: `0`,
              marginLeft: `2rem`,
            }}
          >
            <BlueCheckboxWithoutCheckedIcon
              disabled={props.disabled}
              checked={props?.object?.IsRange}
              onChange={() =>
                handleDualRangeSelectionChange(
                  props?.object?.IsRange ? false : true,
                )
              }
            >
              Dual-Range
            </BlueCheckboxWithoutCheckedIcon>
          </CheckBoxWrapper>
        </div>
      </div>
      {props?.object?.IsRange ? (
        <div className="font-size-1 mb-1 text-primary">Range 1</div>
      ) : null}
      <div className="mb-8 d-inline-flex">
        <div style={{ width: "180px" }}>
          <FloatingLabelInput
            label={"Min"}
            nameForInput={"min"}
            minValue={-1000}
            maxValue={1000}
            value={scriptFormik.values["min"]}
            onChange={(e: any) =>
              handleChange("MinValue1", e.target.value, "min")
            }
            scriptFormik={scriptFormik}
            lastFocused={lastFocused}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            isFocused={isFocused}
            isAddNeeded={false}
            step={1}
            precision={2}
            isNumber
            onBlur={() => validateInputValue("MinValue1")}
            readOnly={props.disabled}
          />
        </div>
        <div style={{ width: "180px", marginLeft: `2rem` }}>
          <FloatingLabelInput
            label={"Max"}
            nameForInput={"max"}
            minValue={-1000}
            maxValue={1000}
            value={scriptFormik.values["max"]}
            onChange={(e: any) =>
              handleChange("MaxValue1", e.target.value, "max")
            }
            scriptFormik={scriptFormik}
            lastFocused={lastFocused}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            isFocused={isFocused}
            isAddNeeded={false}
            step={1}
            precision={2}
            isNumber
            onBlur={() => validateInputValue("MaxValue1")}
            readOnly={props.disabled}
          />
        </div>
      </div>
      {props?.object?.IsRange ? (
        <>
          <div className="font-size-1 mb-1 text-primary">Range 2</div>
          <div className="mb-8 d-inline-flex">
            <div style={{ width: "180px" }}>
              <FloatingLabelInput
                label={"Min"}
                nameForInput={"min2"}
                minValue={-1000}
                maxValue={1000}
                value={scriptFormik.values["min2"]}
                onChange={(e: any) =>
                  handleChange("MinValue2", e.target.value, "min2")
                }
                scriptFormik={scriptFormik}
                lastFocused={lastFocused}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
                isAddNeeded={false}
                step={1}
                precision={2}
                isNumber
                onBlur={() => validateInputValue("MinValue2")}
                readOnly={props.disabled}
              />
            </div>
            <div style={{ width: "180px", marginLeft: `2rem` }}>
              <FloatingLabelInput
                label={"Max"}
                nameForInput={"max2"}
                minValue={-1000}
                maxValue={1000}
                value={scriptFormik.values["max2"]}
                onChange={(e: any) =>
                  handleChange("MaxValue2", e.target.value, "max2")
                }
                scriptFormik={scriptFormik}
                lastFocused={lastFocused}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
                isAddNeeded={false}
                step={1}
                precision={2}
                isNumber
                onBlur={() => validateInputValue("MaxValue2")}
                readOnly={props.disabled}
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
