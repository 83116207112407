import axios from "axios";
import { Modal } from "antd";

const apiPrefix = "api/1/pfc/ResearchQuestion";

//create a default error message in case one wasn't provided
function defaultErrorMessage(err: any) {
  console.log(err);
  if (typeof err === "string") {
    Modal.error({
      title: "An Error Occurred details are provided below:",
      content: err,
    });
  } else {
    Modal.error({
      title: "An Error Occurred details are provided below:",
      content: JSON.stringify(err.response.data.ErrorMessage),
    });
  }
}

export function getMasterAndPublicResearchQuestionList(
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .get(`${apiPrefix}`)
    .then((res) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getMasterResearchQuestionList(
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .get(`${apiPrefix}/master`)
    .then((res) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
