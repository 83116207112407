import styled from "styled-components";
import { colorPalette } from "../../../../styles/MainList.styled";

export const NewMedicationContainer = styled.div.attrs((props: any) => ({
  isLabel: props.isLabel,
}))`
  position: relative;
  .new-compound-list {
    .option-block {
      background-color: #1e1e1e;
      min-height: 50px;
      margin-bottom: 1px;
      &:last-child {
        margin-bottom: 0;
      }
      display: flex;
      justify-items: center;
      align-items: center;
      .option-label {
        text-align: right;
        width: 100px;
        padding: 0 10px;
        font-size: 12px;
        line-height: 14px;
        color: ${colorPalette.white};
      }
      .option-input {
        width: calc(100% - 100px);
        & > div {
          padding-top: 0px;
          .ant-select-disabled {
            .ant-select-arrow {
              color: ${colorPalette.darkerGray};
            }
            .ant-select-selection-item {
              color: rgba(255, 255, 255, 0.5);
            }
            .ant-select-selector {
              border-bottom: none;
              &:hover {
                border-bottom: none;
              }
            }
          }
          .ant-select-selector {
            margin-bottom: 0;
            border: none;
            &:hover {
              border-bottom: 1px solid #ffffff;
            }
          }
        }
        .option-value {
          font-size: 1.5rem;
          padding: 0px 11px;
          color: ${colorPalette.white};
          & > .unit {
            font-size: 1rem;
          }
        }
        .dose-input {
          padding-top: 0px;
          width: 80px;
          display: inline-block;
          & > div {
            padding-top: 0;
            .default {
              padding: 0px 10px !important;
            }
            input {
              font-size: 1.5rem;
              min-height: 49px;
            }
          }
          &.duration {
            width: 90px;
            .number-input-with-suffix .number-input-suffix {
              padding-top: 0;
            }
          }
        }
        .dose-unit-input {
          padding-left: 10px;
          padding-top: 0px;
          & > div {
            padding-top: 0;
          }
          width: 80px;
          display: inline-block;
        }
        .action-icon-wrapper {
          float: right;
          clear: both;
          display: inline-flex;
          align-items: center;
          &.edit-mode {
            min-height: 50px;
          }
          .option-info {
            .padding: 0 0.25rem;
          }
          .editIcon {
            width: 40px;
            height: 40px;
            font-size: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              color: ${colorPalette.white};
            }
          }
        }
        &.cursor-disabled {
          .action-icon-wrapper {
            &:hover {
              color: #999;
            }
          }
        }
      }
    }
  }
`;

export const OptionMedicationInput = styled.div.attrs((props: any) => ({
  disabled: props.disabled,
  fullWidth: props.fullWidth || false,
}))`
  width: ${(props) => (props.fullWidth ? "inherit" : "calc(100% - 100px)")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "inherit")};
  & > div {
    padding-top: 0px;
    .ant-select-disabled {
      .ant-select-arrow {
        color: ${colorPalette.darkerGray};
      }
      .ant-select-selection-item {
        color: rgba(255, 255, 255, 0.5);
      }
      .ant-select-selector {
        border-bottom: none;
        &:hover {
          border-bottom: none;
        }
      }
    }
    .ant-select-selector {
      margin-bottom: 0;
      border: none;
      &:hover {
        border-bottom: 1px solid #ffffff;
      }
    }
  }
  .option-value {
    font-size: 1.5rem;
    padding: 0px 11px;
    color: ${colorPalette.white};
    & > .unit {
      font-size: 1rem;
    }
  }
  .dose-input {
    padding-top: 0px;
    width: 80px;
    display: inline-block;
    & > div {
      padding-top: 0;
      .default {
        padding: 0px 10px !important;
      }
      input {
        font-size: 1.5rem;
        min-height: 49px;
      }
    }
    &.duration {
      width: 90px;
      .number-input-with-suffix .number-input-suffix {
        padding-top: 0;
      }
    }
  }
  .dose-unit-input {
    padding-left: 10px;
    padding-top: 0px;
    & > div {
      padding-top: 0;
    }
    width: 80px;
    display: inline-block;
  }
  .action-icon-wrapper {
    float: right;
    clear: both;
    display: inline-flex;
    align-items: center;
    &.edit-mode {
      min-height: 50px;
    }
    .option-info {
      .padding: 0 0.25rem;
    }
    .editIcon {
      width: 40px;
      height: 40px;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        color: ${colorPalette.white};
      }
    }
  }
  &.cursor-disabled {
    .action-icon-wrapper {
      &:hover {
        color: #999;
      }
    }
  }
`;
