import { Modal } from "antd";
import axios from "axios";
import { orderBy } from "lodash";

//create a default error message in case one wasn't provided
function defaultErrorMessage(err: any) {
  console.log(err);
  if (typeof err === "string") {
    Modal.error({
      title: "An Error Occurred details are provided below:",
      content: err,
    });
  } else {
    Modal.error({
      title: "An Error Occurred details are provided below:",
      content: JSON.stringify(err.response.data.ErrorMessage),
    });
  }
}

export const AllSessionAAR = (
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) => {
  axios
    .get(`/api/1/pfc/SessionAAR`)
    .then((res) => {
      //do something

      if (callback != null) {
        const sorted = orderBy(
          res.data.Result,
          [(r) => r.EndDateTime],
          ["desc"],
        );

        callback(sorted);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
};
