import {
  ArrowRightOutlined,
  HeartOutlined,
  EditOutlined,
  PlusCircleOutlined,
  ForkOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import * as React from "react";
import Drawer from "../../components/Drawer/Drawer";
import { Script } from "../../models/ScriptInterfaces";
import {
  HeaderWrapper,
  CommentFieldWrapper,
  StyledDrawer,
  AuthorReviewWrapper,
} from "../../components/Panel/DetailsPanel.styled";
import { StyledBars } from "../../components/SuccessRating/Bars.styled";
import {
  ForkOutlinedIcons,
  ScriptNewTabIcons,
  SendOutlinedIcons,
  ForkBranchIcons,
} from "../../IconsTheme/Icons.global";
import { StarRating } from "./components/StarRating";
import { SuccessRating } from "./components/SuccessRate";
import { ScriptParticipants } from "./components/ScriptParticipants";
import { ScriptAuthor } from "./components/ScriptAuthor";
import { ScriptAverageTime } from "./components/ScriptAvgTime";
import {
  ScriptReviewer,
  AuthorReviewAction,
} from "./components/AuthorReviewNameplate";
import { PhaseTags } from "./components/ScriptPhases";
import { ScriptTags } from "./components/ScriptTags";
import { ScenarioInfo } from "./components/ScenarioInfo";
import { ScenarioComment } from "./components/ScenarioComment";
import { CommentField } from "./components/CommentField";
import styled from "styled-components";
import {
  PhaseContainer,
  PhaseTagContainer,
  ScriptTagContainer,
  StyledArrowRightOutlined,
  TagContainer,
} from "./components/DetailsPanelComponents.styled";

interface Props {
  viewDetailsModal: boolean;
  onClose: any;
  script: Script;
}

const ViewScriptDetails = ({ script, viewDetailsModal, onClose }: Props) => {
  return (
    <>
      <StyledDrawer
        className="custom-css-ant-drawer"
        visible={viewDetailsModal}
        onClose={onClose}
        closable
        width="600"
        height="40"
      >
        <HeaderWrapper className="header-container">
          <StyledArrowRightOutlined onClick={onClose} />
          <>
            <h1 className="text-left text-uppercase font-size-1 script-title">
              {script.Title}
            </h1>
            <span className="header-icons">
              <Tooltip title="Edit">
                <EditOutlined className="edit-icon" />
              </Tooltip>
              <Tooltip title="Favorite">
                <HeartOutlined className="like-icon" />
              </Tooltip>
            </span>
          </>
        </HeaderWrapper>
        <div className="script-detailed-info">
          <StarRating starRating={3} />
          <ScriptAuthor author={"Doe, John"} forks={3} />
          <SuccessRating barRating={85} />
          <ScriptParticipants participants={6} />
          <ScriptAverageTime averageTime="0:32:52" />
          <PhaseContainer>
            <h5>Phases</h5>
            <PhaseTagContainer>
              <PhaseTags tag={"TCCC"} />
              <PhaseTags tag={"EVAC"} />
              <PhaseTags tag={"PCC"} />
            </PhaseTagContainer>
          </PhaseContainer>
          <TagContainer>
            <h5>Tags</h5>
            <ScriptTagContainer>
              <ScriptTags className={"tag-haemorrhage"} tag={"Haemorrhage"} />
              <ScriptTags className={"tag-shock"} tag={"Shock"} />
              <ScriptTags className={"tag-internal"} tag={"Internal"} />
              <ScriptTags className={"plus-sign"} tag={"+"} />
            </ScriptTagContainer>
          </TagContainer>
          <AuthorReviewWrapper className="script-author-reviewers">
            <AuthorReviewAction></AuthorReviewAction>
            <div className="reviewed-by">
              <ScriptReviewer
                author={"Smith, Joe"}
                title={"Doctor"}
                organization={"Organization"}
              />
              <ScriptReviewer
                author={"Doe, Jane"}
                title={"Overseer"}
                organization={"Organization"}
              />
            </div>
          </AuthorReviewWrapper>
          <ScenarioInfo />
          <div className="scenario-comments">
            <h5>Comments</h5>
            <div className="comments">
              <ScenarioComment
                author={"Doe, John"}
                date={"2022/06/01"}
                comment={`This is a comment about the scenario, made by me ;Nathaniel`}
              />
              <ScenarioComment
                author={"Smith, Doe"}
                date={"2021/07/11"}
                comment={`This is a comment about the scenario, made by me. 
                It's much longer than the last one, as it repeats
                unnecessarily. This is a comment about the scenario, made
                by me. It's much longer than the last one, as it repeats
                unnecessarily. This is a comment about the scenario, made
                by me. It's much longer than the last one, as it repeats
                unnecessarily.`}
              />
              <CommentFieldWrapper className="comment-field">
                <CommentField />
              </CommentFieldWrapper>
            </div>
          </div>
        </div>
      </StyledDrawer>
    </>
  );
};
export default ViewScriptDetails;
