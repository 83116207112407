import axios from "axios";
import { defaultErrorCallback } from "./ErrorCallback";
import {
  CreateMiscellaneousTaskBlock,
  UpdateMiscellaneousTaskBlock,
} from "../models/MiscellaneousTaskBlock";

export function createMiscellaneousTaskBlock(
  payload: CreateMiscellaneousTaskBlock,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .post(`/api/2/pfc/miscellaneous-block`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err);
      }
    });
}

export function updateMiscellaneousTaskBlock(
  payload: UpdateMiscellaneousTaskBlock,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(`/api/2/pfc/miscellaneous-block`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err);
      }
    });
}

export function attachMasterMiscellaneousTaskToBlock(
  masterMiscellaneousTaskId: number,
  blockId: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(
      `/api/2/pfc/miscellaneous-block/attach-master?masterMiscellaneousTaskId=${masterMiscellaneousTaskId}&blockId=${blockId}`,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}
