import styled from "styled-components";

export const FormContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 0.625rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    margin-top: 15px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    margin-top: 12px;
  }

  .plusIcon {
    color: #ffffff;
    cursor: pointer;
    margin-left: 0.125rem;
    position: absolute;
    right: 5px;
    bottom: 22px;
  }

  .searchIcon,
  .checkCircleIcon {
    cursor: pointer;
    margin-left: 0.125rem;
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: rgba(30, 182, 205, 255);
  }

  .checkCircleIcon {
    color: green;
    font-size: 1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    .plusIcon {
      right: 9px;
      font-size: 1.75rem;
    }

    .searchIcon {
      font-size: 1.75rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    .plusIcon {
      right: 9px;
      font-size: 1.75rem;
    }
    .searchIcon {
      font-size: 1.75rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xl}) {
    .plusIcon {
      font-size: 1.5625rem;
    }
    .searchIcon {
      font-size: 1.5625rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    .plusIcon {
      font-size: 1.3125rem;
    }
    .searchIcon {
      font-size: 1.3125rem;
    }
  }
`;

export const Form = styled.div`
  height: auto;
  margin-top: 10px;
  position: relative;
  padding: 0.625rem;

  textarea::-webkit-scrollbar {
    display: none;
  }

  .error-label {
    border-bottom: 2px solid red !important;
  }

  textarea:is(:focus, :valid) + .label-name {
    border-bottom: 2px solid rgba(20, 118, 133, 255);
  }

  textarea:is(:focus, :valid) + .label-name .content-name,
  textarea:is(:focus, :valid) + .label-name .error {
    position: absolute;
    top: 0;
  }

  input:focus + .label-name .content-name,
  textarea:focus + .label-name .content-name {
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  .filled-label {
    border-bottom: 2px solid rgba(20, 118, 133, 255);
  }

  .filled-content {
    /* transform: translateY(-50px); */
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  .filled-error {
    /* transform: translateY(-50px); */
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    input:focus + .label-name .content-name,
    texarea:focus + .label-name .content-name {
      font-size: 1.4em;
      color: rgba(128, 128, 128, 255);
    }

    .filled-label {
      border-bottom: 2px solid rgba(20, 118, 133, 255);
    }

    .filled-content {
      /* transform: translateY(-50px); */
      font-size: 1.4em;
      color: rgba(128, 128, 128, 255);
    }

    .filled-error {
      /* transform: translateY(-50px); */
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    input:focus + .label-name .content-name,
    textarea:focus + .label-name .content-name {
      font-size: 1.3em;
      color: rgba(128, 128, 128, 255);
    }

    .filled-label {
      border-bottom: 2px solid rgba(128, 128, 128, 255);
    }

    .filled-content {
      /* transform: translateY(-50px); */
      font-size: 1.3em;
      color: rgba(128, 128, 128, 255);
    }

    .filled-error {
      /* transform: translateY(-50px); */
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    input:focus + .label-name .content-name,
    textarea:focus + .label-name .content-name {
      font-size: 1.3em;
    }
    .filled-content {
      font-size: 1.3em;
    }

    .error {
      font-size: 1em;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    width: 94%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    width: 91%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xxl}) {
    width: 90%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xl}) {
    width: 89%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    width: 85%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    width: 85%;
  }
`;
export const InputContainer = styled.input`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 255);
  margin-top: 2px;
  font-size: 1.25rem;
  padding-top: 1.25rem;
  border: none;
  margin-left: -0.375rem;
  padding-right: 0.8125rem;

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 1.2em;
  }
`;

export const Label = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 1px solid red; */
  font-size: 10px;
  position: absolute;
  /* top: 0.625rem; */
  bottom: 12px;
  left: 3px;
  right: 12px;
  /* background-color: blue; */
  pointer-events: none;
  border-bottom: 2px solid rgba(20, 118, 133, 255);
  transition: all 0.3s ease;
`;

export const ErrorSpan = styled.span`
  color: red;
  font-size: 1.4em;
  align-items: flex-start;
  position: absolute;
  bottom: 0px;
  right: 0px;

  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    font-size: 1.2em;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    font-size: 1.1em;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 0.9em;
  }
`;

export const LabelSpan = styled.span`
  color: rgba(112, 112, 112, 255);
  font-size: 2em;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    font-size: 1.7em;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    font-size: 1.5em;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 1.3em;
  }
`;

export const MainDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 3.4375rem;
  background-color: rgba(0, 0, 0, 255);
  margin-top: 2px;
  font-size: 1.25rem;
  padding-top: 1.25rem;
  border: none;
  margin-left: -0.375rem;
  padding-right: 0.8125rem;

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 1.2em;
  }
`;
