import React from "react";
import Drawer from "../../../../components/Drawer/Drawer";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setTreatmentForUpdate } from "./TreatmentSlice";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import SubTasks from "./TimedActions/SubTasks";
import { ScrollableTimedActionTaskDiv } from "../../../../styles/EditComponentTheme.styled";
import { AdditionalInfoTA } from "../../../../components/TimedActions/AdditionalInfoTA";
import Label from "./TimedActions/Label";
import CategorizationAndRequirements from "../../../../components/TreatmentEdit/CategorizationAndRequirements";
import { useAppSelector } from "../../../../store/hooks";
import { DefineStartTimeTA } from "../../../../components/TimedActions/DefineStartTimeTA";
import { DurationTa } from "../../../../components/TimedActions/DurationTA";
import { IntervalTA } from "../../../../components/TimedActions/IntervalTA";
import { RatingTA } from "../../../../components/TimedActions/RatingTA";
import {
  TreatmentTask,
  UpdateTreatmentTask,
} from "../../../../models/TreatmentTask";
import { deleteMediaForTimedActionTask } from "../../../../api/TimedActionTask";
import produce from "immer";
import { Media } from "../../../../models/MediaInterface";
import { isNullOrUndefined } from "is-what";
import MediaOption from "../../../../components/Media/MediaOption";
import { Tooltip } from "antd";
interface Props {
  title: string;
  formik: any;
  isVisible: boolean;
  onClickOutside: any;
  onClose: any;
}
export default function AddUpdateDrawer({
  title,
  formik,
  isVisible,
  onClickOutside,
  onClose,
}: Props) {
  const { materialRequirements, skillCategorization } = useAppSelector(
    (state) => state.FeatureFlagReducer.scriptBuilder.treatmentBlock,
  );
  const treatment = useAppSelector(
    (state) => state.MasterTreatmentReducer.treatmentForUpdate,
  );
  const focusedStates = {
    question: false,
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);
  const isReadOnly = false;
  const dispatch = useDispatch();
  const onError = (msg: any) => {
    OpenErrorNotification({
      description: msg,
    });
  };
  const handleChange = (
    field: keyof UpdateTreatmentTask,
    value: string | boolean | any,
  ) => {
    const payload = {
      ...treatment,
      [field]: value,
    };
    dispatch(setTreatmentForUpdate(payload));
  };

  const handleIntervalToggle = (
    type: keyof UpdateTreatmentTask,
    value?: number,
  ) => {
    if (type === "IsIntervalEnabled") {
      if (treatment.IsIntervalEnabled) {
        handleChange("IntervalTime", 0);
      }
      handleChange(type, !treatment[type]);
    } else if (type === "IntervalTime") {
      handleChange(type, value);
    }
  };

  const handleOptionToggle = (type: keyof UpdateTreatmentTask) => {
    handleChange(type, !treatment[type]);
  };

  const deleteMediaSuccess = (mediaId: number) => {
    const updatedTreatmentTask = produce(treatment, (draftTreatment) => {
      draftTreatment.AttachedMedias = treatment.AttachedMedias.filter(
        (media: Media) => media.Id !== mediaId,
      );
    });
    dispatch(setTreatmentForUpdate(updatedTreatmentTask));
  };

  const handleDeleteMedia = (mediaId: number) => {
    if (!isNullOrUndefined(treatment.Id)) {
      deleteMediaForTimedActionTask(
        treatment.Id,
        mediaId,
        () => {
          deleteMediaSuccess(mediaId);
        },
        (error: any) => {
          console.error(error);
        },
      );
    } else {
      deleteMediaSuccess(mediaId);
    }
  };

  const getScrollableDivHeight = () => {
    return {
      height: `auto`,
    };
  };

  return (
    <Drawer
      className="custom-css-ant-drawer"
      visible={isVisible}
      onClose={onClickOutside}
      closable
      width="600"
    >
      <h1>
        <ArrowRightOutlined style={{ marginRight: "20px" }} onClick={onClose} />
        {title}
      </h1>
      <div className="p-2">
        <ScrollableTimedActionTaskDiv
          style={getScrollableDivHeight()}
          typeOfEditPanel="treatment"
          className="treatmentContainer"
          id="scrollableDiv"
          phase={false}
        >
          <div className="mb-5">
            <Label
              label={"Title*"}
              field={"title"}
              formik={formik}
              lastFocused={lastFocused}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
            />
          </div>
          <AdditionalInfoTA
            isReadOnly={isReadOnly}
            scriptFormik={formik}
            description={formik.values["description"]}
            additionalNote={formik.values["additionalNote"]}
            onChange={handleChange}
          />
          <div className="toggleOptions">
            <SubTasks
              isReadOnly={isReadOnly}
              obj={treatment as TreatmentTask}
              formik={formik}
              onChange={handleChange}
              onError={onError}
            />
            <IntervalTA
              isReadOnly={isReadOnly}
              handleIntervalToggle={handleIntervalToggle}
              isIntervalEnabled={treatment.IsIntervalEnabled}
              interval={treatment.IntervalTime}
              handleOptionToggle={handleOptionToggle}
              scriptFormik={formik}
            />
            <DurationTa
              isReadOnly={isReadOnly}
              isDurationEnabled={treatment.IsDurationEnabled}
              onChange={handleOptionToggle}
              isDefineDurationTimeEnabled={
                treatment.IsDefineDurationTimeEnabled
              }
            />
            <DefineStartTimeTA
              isReadOnly={isReadOnly}
              handleOptionToggle={handleOptionToggle}
              isDefineStartTimeEnabled={treatment.IsDefineStartTimeEnabled}
            />
            <RatingTA
              isReadOnly={isReadOnly}
              isCustomLabelsEnabled={treatment.IsCustomLabelsEnabled}
              isRatingEnabled={treatment.IsRatingEnabled}
              handleOptionToggle={handleOptionToggle}
            />
            <MediaOption
              isReadOnly={isReadOnly}
              obj={treatment as TreatmentTask}
              type="Treatment"
              handleOptionToggle={handleOptionToggle}
              handleDeleteMedia={handleDeleteMedia}
              onChange={handleChange}
            />
            {skillCategorization && (
              <Tooltip title="Hidden in production">
                <>
                  <CategorizationAndRequirements
                    dropDownTitle={"Skill Categorization"}
                    listItemsTitle={"Skill Type"}
                    viewListLinkTitle={"View Approved Mos Units"}
                    timedAction={"treatment"}
                  />
                </>
              </Tooltip>
            )}
            {materialRequirements && (
              <Tooltip title="Hidden in production">
                <>
                  <CategorizationAndRequirements
                    dropDownTitle={"Material Requirements"}
                    listItemsTitle={"Equipment Availability"}
                    viewListLinkTitle={"View Material List"}
                    timedAction={"treatment"}
                  />
                </>
              </Tooltip>
            )}
          </div>
        </ScrollableTimedActionTaskDiv>
      </div>
    </Drawer>
  );
}
