import * as React from "react";
import { useEffect } from "react";
import {
  Phase,
  SubtaskForDiagnostic,
  SubtaskForMedication,
  Treatment,
} from "../../../../models/ScriptInterfaces";
import { IconColumn } from "../IconColumn";
import { useFormik } from "formik";
import {
  Container,
  FixedDiv,
  ScrollableTimedActionTaskDiv,
} from "../../../../styles/EditComponentTheme.styled";
import { isNullOrUndefined } from "../../../../utils/utilityfunctions";
import { RequiredTA } from "../../../../components/TimedActions/RequiredTA";
import { SubtasksTA } from "../../../../components/TimedActions/SubtasksTA";
import { IntervalTA } from "../../../../components/TimedActions/IntervalTA";
import { DurationTa } from "../../../../components/TimedActions/DurationTA";
import { DefineStartTimeTA } from "../../../../components/TimedActions/DefineStartTimeTA";
import { RatingTA } from "../../../../components/TimedActions/RatingTA";
import { MediaTA } from "../../../../components/TimedActions/MediaTA";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  SetInterval,
  SetUploadedMedia,
  SetOption,
  SetSelectedPhase,
  SetSubTask,
} from "./MedicationSlice";
import {
  SetCurrentTimedActionUploadingMedia,
  SetPhases,
  SetTimedActionMediaDisplay,
} from "../../MainlistSlice";
import { FilterBarContainer } from "../../../../styles/MainList.styled";
import produce from "immer";
import MedicationSearchAdd from "./MedicationSearchAdd";
import NewMedicationForm from "./NewMedicationForm";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../components/ConfirmDialog/useConfirmDialog";
import CategorizationAndRequirements from "../../../../components/TreatmentEdit/CategorizationAndRequirements";
import { useDebounce } from "../../../../hooks/useDebounce";
import { BlockType } from "../../../../models/Enums";
import {
  MedicationTaskBlock,
  UpdateMedicationTaskBlock,
  UpdateMedicationTaskBlockContent,
} from "../../../../models/MedicationTaskBlock";
import { Tooltip } from "antd";
import { DeepKeysOf } from "../../../../models/common/DeepKeysOf";
import { updateMedicationTaskBlock } from "../../../../api/MedicationTaskBlock";
import { MedicationTask } from "../../../../models/MedicationTask";
import {
  CreateTimedActionSubTask,
  TimedActionSubTask,
} from "../../../../models/TimedActionSubTask";
import {
  deleteMediaForTimedActionTask,
  deleteSubtaskForTimedAction,
} from "../../../../api/TimedActionTask";
import { Media } from "../../../../models/MediaInterface";

interface Props {
  object: MedicationTaskBlock;
  handleTimedActionBlockChange: Function;
  onSave: any; //Function
  onDelete: any; //Function
  onCopy: any; //Function
  updateBlockData: any; //Function
  handleSubtaskUpdate: any; //Function
}

type ErrorType = {
  [key: string]: string;
};

const MedicationEdit = (props: Props) => {
  const { materialRequirements, skillCategorization } = useAppSelector(
    (state) => state.FeatureFlagReducer.scriptBuilder.medicationBlock,
  );
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: any) => state.MedicationReducer);
  const MainListState = useAppSelector((state: any) => state.MainlistReducer);
  const phases: Phase[] = MainListState.phases;
  const { isReadOnly, timedActionMediaDisplay } = MainListState;
  const filterBarRef = React.useRef<any>(null);
  const getScrollableDivHeight = () => {
    const height = filterBarRef?.current?.scrollHeight;
    return {
      height: `calc(100vh - 60px - 65px - ${height || 0}px)`,
    };
  };
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  useEffect(() => {
    if (!isNullOrUndefined(props.object)) {
      if (
        !isNullOrUndefined(props.object?.BlockContent?.RequiredInPhaseOffset)
      ) {
        const createdIndex = getIndexFromPhaseId(
          props.object?.BlockContent.CreatedInPhaseId,
        );
        if (createdIndex !== -1) {
          const requiredInPhaseIndex =
            createdIndex + props.object?.BlockContent?.RequiredInPhaseOffset;
          if (phases.length >= requiredInPhaseIndex + 1) {
            if (phases[requiredInPhaseIndex]?.TitleAbbreviated === "") {
              const phaseTitle = `Phase ${requiredInPhaseIndex + 1}`;
              dispatch(SetSelectedPhase(phaseTitle));
            } else {
              dispatch(
                SetSelectedPhase(phases[requiredInPhaseIndex].TitleAbbreviated),
              );
            }
          }
        }
      }

      dispatch(SetUploadedMedia(props.object.BlockContent.UploadedMediaItems));
      dispatch(SetInterval(props.object.BlockContent.IntervalTime));
      setOptionsValue();
    }
  }, [phases]);

  // reset subtask on destroy
  useEffect(() => {
    return () => {
      dispatch(SetSubTask(""));
    };
  }, []);

  const scriptFormik = useFormik({
    initialValues: {
      search: ``,
      description: "",
      additionalNote: "",
      phase: "",
      subTask: "",
      interval: 0,
      compoundUnit: "",
    },
    validate: () => {
      const errors: ErrorType = {};

      return errors;
    },
    validateOnBlur: true,
    onSubmit: () => undefined,
  });

  const onChangeMedicationBlock = (
    changedVariable: DeepKeysOf<UpdateMedicationTaskBlockContent>,
    change: any,
    secondChangedVariable?: DeepKeysOf<UpdateMedicationTaskBlockContent>,
    secondChangedValue?: any,
  ) => {
    props.handleTimedActionBlockChange(
      BlockType.Medication,
      changedVariable,
      change,
      secondChangedVariable,
      secondChangedValue,
    );
  };

  const onTreatmentSave = () => {
    updateMedicationTaskBlock(
      props.object as UpdateMedicationTaskBlock,
      () => undefined,
      () => undefined,
    );
  };

  const debounceSubmitForm = useDebounce(onTreatmentSave);

  useEffect(() => {
    debounceSubmitForm();

    return () => {
      debounceSubmitForm.cancel();
    };
  }, [props.object, scriptFormik.isValid, scriptFormik.values]);

  //set option value on load
  // fix for toggle not working and requires 2-3 clicking
  const setOptionsValue = () => {
    dispatch(
      SetOption({
        ...state.option,
        IsRequired: props.object.BlockContent["IsRequired"],
        IsCritical: props.object.BlockContent["IsCritical"],
        IsSubtasksEnabled:
          props.object?.BlockContent?.TimedActionTaskContent?.IsSubtasksEnabled,
        IsIntervalEnabled:
          props.object?.BlockContent?.TimedActionTaskContent?.IsIntervalEnabled,
        IsDurationEnabled:
          props.object?.BlockContent?.TimedActionTaskContent?.IsDurationEnabled,
        IsDefineDurationTimeEnabled:
          props.object?.BlockContent?.TimedActionTaskContent
            ?.IsDefineDurationTimeEnabled,
        IsDefineStartTimeEnabled:
          props.object?.BlockContent?.TimedActionTaskContent
            ?.IsDefineStartTimeEnabled,
        IsRatingEnabled:
          props.object?.BlockContent?.TimedActionTaskContent?.IsRatingEnabled,
        IsMediaEnabled:
          props.object?.BlockContent?.TimedActionTaskContent?.IsMediaEnabled,
        IsCustomLabelsEnabled:
          props.object?.BlockContent?.TimedActionTaskContent
            ?.IsCustomLabelsEnabled,
      }),
    );
  };

  const onDeleteConfirm = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "Are you sure delete this Block?",
      content: "This cannot be undone",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        props.onDelete(props.object);
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        console.log("Cancel");
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };

  const handlePhaseSelection = (
    phaseTitle: string,
    phaseIndex: number,
    useEff?: boolean,
  ) => {
    if (isNullOrUndefined(useEff)) {
      onChangeMedicationBlock(
        "RequiredInPhaseOffset",
        phaseIndex - MainListState.currentPhaseIndex,
      );
    }
    if (phaseTitle === "") {
      const phaseTitle = `Phase ${phaseIndex + 1}`;
      dispatch(SetSelectedPhase(phaseTitle));
    } else {
      dispatch(SetSelectedPhase(phaseTitle));
    }
  };

  const handleIntervalToggle = (type: keyof MedicationTask, value: number) => {
    if (isReadOnly) return; //disable action callback in read only script
    if (type === "IsIntervalEnabled") {
      if (state.option.IsIntervalEnabled) {
        dispatch(SetInterval(0));
        onChangeMedicationBlock(`TimedActionTaskContent.${type}`, 0);
      }
      dispatch(SetOption({ ...state.option, [type]: !state.option[type] }));
      onChangeMedicationBlock(
        `TimedActionTaskContent.${type}`,
        !state.option[type],
      );
    } else if (type === "IntervalTime") {
      dispatch(SetInterval(value));
      onChangeMedicationBlock(`TimedActionTaskContent.${type}`, value);
    }
  };

  const handleOptionToggle = (type: string, value?: number) => {
    if (isReadOnly) return; //disable action callback in read only script
    if (type === "IsCritical") {
      dispatch(SetOption({ ...state.option, [type]: !state.option[type] }));
      onChangeMedicationBlock(type, !state.option[type]);
    } else if (type === "IsRequired") {
      dispatch(SetOption({ ...state.option, [type]: !state.option[type] }));
      const createdIndex = getIndexFromPhaseId(
        props.object?.BlockContent.CreatedInPhaseId,
      );
      if (
        createdIndex !== -1 &&
        createdIndex <=
          createdIndex + props.object.BlockContent?.RequiredInPhaseOffset
      ) {
        if (phases[createdIndex]?.TitleAbbreviated === "") {
          const phaseTitle = `Phase ${createdIndex + 1}`;
          dispatch(SetSelectedPhase(phaseTitle));
          onChangeMedicationBlock(
            "RequiredInPhaseOffset",
            0,
            type,
            !state.option[type],
          );
        } else {
          dispatch(SetSelectedPhase(phases[createdIndex].TitleAbbreviated));
          onChangeMedicationBlock(
            "RequiredInPhaseOffset",
            0,
            type,
            !state.option[type],
          );
        }
      }
    } else {
      onChangeMedicationBlock(
        `TimedActionTaskContent.${type}`,
        !state.option[type],
      );
    }
  };

  const handleAddSubTask = () => {
    if (isReadOnly) return; //disable action callback in read only script
    const newSubtask: CreateTimedActionSubTask = {
      Order:
        props.object?.BlockContent?.TimedActionTaskContent?.Subtasks?.length,
      Description: state.subtask,
    };
    onChangeMedicationBlock("TimedActionTaskContent.Subtasks", newSubtask);
    dispatch(SetSubTask(""));
  };

  const handleDeleteMedia = (mediaId: number) => {
    if (isReadOnly) return; //disable action callback in read only script
    deleteMediaForTimedActionTask(
      props.object?.BlockContent?.TimedActionTaskContent?.Id,
      mediaId,
      () => {
        DeleteMediaSuccess(mediaId);
      },
      (error: any) => {
        console.error(error);
      },
    );
  };

  const DeleteMediaSuccess = (mediaId: number) => {
    const newPhases = produce(phases, (draftPhases) => {
      draftPhases[MainListState.currentPhaseIndex].Sections[
        MainListState.currentSectionIndex
      ].Blocks[
        MainListState.currentBlockIndex
      ].BlockContent.TimedActionTaskContent.AttachedMedias = draftPhases[
        MainListState.currentPhaseIndex
      ].Sections[MainListState.currentSectionIndex].Blocks[
        MainListState.currentBlockIndex
      ].BlockContent.TimedActionTaskContent.AttachedMedias?.filter(
        (attachedMedia: Media) => attachedMedia.Id !== mediaId,
      );
    });
    dispatch(SetPhases(newPhases));
  };

  const handleSubtaskDelete = (subtaskToDelete: TimedActionSubTask) => {
    if (isReadOnly) return; //disable action callback in read only script

    deleteSubtaskForTimedAction(
      subtaskToDelete.Id,
      () => {
        deleteSubtaskSuccess(subtaskToDelete.Id);
      },
      () => undefined,
    );
  };

  const deleteSubtaskSuccess = (id: number) => {
    const newPhases = produce(phases, (draftPhases) => {
      draftPhases[MainListState.currentPhaseIndex].Sections[
        MainListState.currentSectionIndex
      ].Blocks[
        MainListState.currentBlockIndex
      ].BlockContent.TimedActionTaskContent.Subtasks = draftPhases[
        MainListState.currentPhaseIndex
      ].Sections[MainListState.currentSectionIndex].Blocks[
        MainListState.currentBlockIndex
      ].BlockContent.TimedActionTaskContent.Subtasks?.filter(
        (subTask: SubtaskForDiagnostic) => subTask.Id !== id,
      );
    });
    dispatch(SetPhases(newPhases));
  };

  const getIndexFromPhaseId = (Id: number | undefined) => {
    if (!Id) return -1;
    return phases.findIndex((phase: Phase) => phase.Id === Id);
  };
  const isDisableDeleteCopyBtn = () => {
    const index = getIndexFromPhaseId(
      props.object?.BlockContent.CreatedInPhaseId,
    );
    return MainListState.currentPhaseIndex !== index;
  };
  const handleClickOnAttach = () => {
    dispatch(SetTimedActionMediaDisplay(!timedActionMediaDisplay));
    dispatch(SetCurrentTimedActionUploadingMedia("Medication"));
  };
  return (
    <Container>
      <FixedDiv phase={false}>
        <IconColumn
          type="medication"
          onCopyClick={() => props.onCopy(props.object)}
          onDeleteClick={onDeleteConfirm}
          isDeleteBtnDisabled={isDisableDeleteCopyBtn()}
          isCopyBtnDisabled={isDisableDeleteCopyBtn()}
        />
      </FixedDiv>
      {!isNullOrUndefined(props.object) &&
        !isNullOrUndefined(props.object.BlockContent) &&
        !isNullOrUndefined(
          props.object?.BlockContent?.TimedActionTaskContent,
        ) && (
          <>
            <FilterBarContainer isDiagnostic={false} ref={filterBarRef}>
              <MedicationSearchAdd
                scriptFormik={scriptFormik}
                object={props.object}
                onChange={onChangeMedicationBlock}
                updateBlockData={props.updateBlockData}
              />
            </FilterBarContainer>
            <ScrollableTimedActionTaskDiv
              style={getScrollableDivHeight()}
              className="treatmentContainer"
              typeOfEditPanel="medication"
              id="scrollableDiv"
              phase={false}
              medications={
                props.object?.BlockContent?.MedicationCompounds?.length
              }
            >
              <NewMedicationForm
                blockContent={
                  props.object?.BlockContent?.TimedActionTaskContent
                }
                useCase={
                  props.object?.BlockContent.TimedActionTaskContent.UseCase1 ||
                  props.object?.BlockContent.TimedActionTaskContent.UseCase2
                }
                onChange={onChangeMedicationBlock}
                currentRouteId={
                  props.object?.BlockContent.TimedActionTaskContent
                    .CurrentRouteOfAdmin?.Id
                }
                scriptFormik={scriptFormik}
              />
              <div className="toggleOptions">
                <RequiredTA
                  // createdInPhaseIndex={
                  //   props.object.BlockContent!.CreatedInPhaseIndex
                  // }
                  requiredInPhaseOffset={
                    props.object.BlockContent?.RequiredInPhaseOffset
                  }
                  createdInPhaseId={props.object.BlockContent?.CreatedInPhaseId}
                  currentPhaseId={MainListState.currentPhaseId}
                  currentPhaseIndex={MainListState.currentPhaseIndex}
                  isRequired={props.object.BlockContent?.IsRequired}
                  isCritical={props.object.BlockContent?.IsCritical}
                  phases={phases}
                  handleOptionToggle={handleOptionToggle}
                  handlePhaseSelection={handlePhaseSelection}
                  selectedPhaseValue={state.selectedPhase}
                />
                <SubtasksTA
                  isReadOnly={isReadOnly}
                  object={props.object?.BlockContent?.TimedActionTaskContent}
                  handleOptionToggle={handleOptionToggle}
                  scriptFormik={scriptFormik}
                  handleAddSubtask={handleAddSubTask}
                  subtaskValue={state.subtask}
                  type="Medication"
                  handleChange={(e: string) => dispatch(SetSubTask(e))}
                  handleSubtaskDelete={handleSubtaskDelete}
                  handleSubtaskUpdate={props.handleSubtaskUpdate}
                />
                <IntervalTA
                  isReadOnly={isReadOnly}
                  handleIntervalToggle={handleIntervalToggle}
                  isIntervalEnabled={
                    props.object?.BlockContent.TimedActionTaskContent
                      ?.IsIntervalEnabled
                  }
                  interval={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IntervalTime
                  }
                  handleOptionToggle={handleOptionToggle}
                  scriptFormik={scriptFormik}
                />
                <DurationTa
                  isReadOnly={isReadOnly}
                  isDurationEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsDurationEnabled
                  }
                  onChange={handleOptionToggle}
                  isDefineDurationTimeEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsDefineDurationTimeEnabled
                  }
                />
                <DefineStartTimeTA
                  isReadOnly={isReadOnly}
                  handleOptionToggle={handleOptionToggle}
                  isDefineStartTimeEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsDefineStartTimeEnabled
                  }
                />
                <RatingTA
                  isReadOnly={isReadOnly}
                  isCustomLabelsEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsCustomLabelsEnabled
                  }
                  isRatingEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsRatingEnabled
                  }
                  handleOptionToggle={handleOptionToggle}
                />
                <MediaTA
                  isReadOnly={isReadOnly}
                  isMediaEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsMediaEnabled
                  }
                  handleOptionToggle={handleOptionToggle}
                  medias={
                    props.object?.BlockContent?.TimedActionTaskContent
                      .AttachedMedias
                  }
                  type="Medication"
                  deleteMedia={handleDeleteMedia}
                  onAttach={handleClickOnAttach}
                />
                {skillCategorization && (
                  <Tooltip title={`Hidden in Prod`}>
                    <>
                      <CategorizationAndRequirements
                        dropDownTitle={"Skill Categorization"}
                        listItemsTitle={"Skill Type"}
                        viewListLinkTitle={"View Approved Mos Units"}
                        timedAction={"medication"}
                      />
                    </>
                  </Tooltip>
                )}
                {materialRequirements && (
                  <Tooltip title={`Hidden in Prod`}>
                    <>
                      <CategorizationAndRequirements
                        dropDownTitle={"Material Requirements"}
                        listItemsTitle={"Equipment Availability"}
                        viewListLinkTitle={"View Material List"}
                        timedAction={"medication"}
                      />
                    </>
                  </Tooltip>
                )}
              </div>
            </ScrollableTimedActionTaskDiv>
          </>
        )}
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </Container>
  );
};
export default MedicationEdit;
