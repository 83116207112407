import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AutocompleteOption } from "../../../../components/Autocomplete/Autocomplete";

type initialState = {
  isFocused: {};
  lastFocused: {};
  search: string;
  researchQuestionSearchOptions: AutocompleteOption[];
  isEditingAllowed: boolean;
};

const Focused = {
  search: false,
};
const researchQuestionEditInitialState: initialState = {
  isFocused: Focused,
  lastFocused: Focused,
  search: "",
  researchQuestionSearchOptions: [],
  isEditingAllowed: true,
};

const ResearchQuestionSlice = createSlice({
  name: "ResearchQuestion",
  initialState: researchQuestionEditInitialState,
  reducers: {
    SetIsFocused: (state, action: PayloadAction<{}>) => {
      state.isFocused = { ...action.payload };
    },
    SetIsEditingAllowed: (state, action: PayloadAction<boolean>) => {
      state.isEditingAllowed = action.payload;
    },
    SetLastFocused: (state, action: PayloadAction<{}>) => {
      state.lastFocused = { ...action.payload };
    },
    SetSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    SetResearchQuestionSearchOptions: (
      state,
      action: PayloadAction<AutocompleteOption[]>,
    ) => {
      state.researchQuestionSearchOptions = action.payload;
    },
  },
});

export default ResearchQuestionSlice.reducer;
export const {
  SetLastFocused,
  SetIsFocused,
  SetSearch,
  SetResearchQuestionSearchOptions,
  SetIsEditingAllowed,
} = ResearchQuestionSlice.actions;
