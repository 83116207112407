import * as React from "react";
import { Button, Row, Col, Tooltip, Dropdown } from "antd";
import { FormikProps } from "formik";
import {
  CrownFilled,
  FilterOutlined,
  HeartOutlined,
  ManOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import { colorPalette } from "../../../../../styles/MainList.styled";
import { ScriptFormValues } from "../ScriptView/ScriptView";
import useKeyPress from "react-use-keypress";
import { useEffect, useState } from "react";
import { DataType } from "../types";
import { Sex } from "../../../../../models/Enums";
import { OptionsMenu } from "./OptionsMenu";
import { StyledTable } from "./styledComponents/StyledTable.styled";
import { DisplayWeight } from "../CasualtyForm/CasualtyFormHelperFunctions";
import { Casualty } from "../../../../../models/CasualtyInterfaces";
import { SearchFilter } from "../../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  INSULT_INJURY_SET_APPLIED_INJURIES,
  INSULT_INJURY_SET_APPLIED_INSULTS,
} from "../../../../../store/CasualtyCreator/InsultInjury/actionType";
import { isNullOrUndefined } from "../../../../../utils/utilityfunctions";
import { SetSelectedCasualtyId } from "../../../../../store/ScriptBuilder/scriptBuilderSlice";

interface Props {
  onChange: any;
  casualtyId?: number;
  openEditCasualtyDetails: Function;
  setIsCasultyTableEditMode: Function;
  SetCurrentCasualty: Function;
}

let rowIndex = -1;

export const CasualtyTable = ({
  onChange,
  openEditCasualtyDetails,
  setIsCasultyTableEditMode,
  SetCurrentCasualty,
}: Props) => {
  const { casualties, selectedCasualtyId } = useAppSelector(
    (state) => state.scriptBuilderReducer,
  );
  const dispatch = useAppDispatch();

  const columns: any[] = [
    {
      key: "Icons",
      title: "",
      dataIndex: "Icons",
      render: (
        value: any,
        record: DataType,
        index: number,
      ): React.ReactElement => {
        return (
          <>
            {record.IsMasterCasualty && (
              <Tooltip title="Master">
                <CrownFilled
                  className="font-size-0"
                  style={{ color: colorPalette.warning }}
                />
              </Tooltip>
            )}
            {!record.IsMasterCasualty && (
              //FOR future use when implementing favorite casualty functionality
              //favorite icon
              // <Tooltip title="Make Favorite">
              //   <HeartFilled className="font-size-0" style={{ color: colorPalette.primary100 }} />
              // </Tooltip>
              <Tooltip title="Make Favorite">
                <HeartOutlined className="font-size-0" />
              </Tooltip>
            )}
          </>
        );
      },
      className: "center-text",
    },
    {
      key: "Gender",
      title: "Gender",
      dataIndex: "Gender",
      render: (
        value: any,
        record: DataType,
        index: number,
      ): React.ReactElement => {
        return (
          <>
            {record.Gender === Sex.Male && (
              <ManOutlined style={{ fontSize: "1.5rem" }} />
            )}
            {record.Gender === Sex.Female && (
              <WomanOutlined style={{ fontSize: "1.5rem" }} />
            )}
          </>
        );
      },
      className: "center-text",
      sorter: (a: any, b: any) => a.Gender - b.Gender,
    },
    {
      key: "Age",
      title: "Age",
      dataIndex: "Age",
      className: "center-text",
      sorter: (a: any, b: any) => a.Age - b.Age,
    },
    {
      key: "Weight",
      title: "Weight",
      dataIndex: "Weight",
      render: (
        value: any,
        record: DataType,
        index: number,
      ): React.ReactElement => {
        return <>{`${DisplayWeight(record.Weight, record.WeightUnit)}`}</>;
      },
      className: "center-text",
      sorter: (a: any, b: any) => a.Weight - b.Weight,
    },
    {
      key: "Description",
      title: "Description",
      dataIndex: "Description",
    },
    {
      title: "",
      dataIndex: "Id",
      key: "Id",
      width: 40,
      render: (Id: number, casualty: any) => {
        return (
          <OptionsMenu
            Id={Id}
            casualty={casualty}
            onEditCasualty={handleEditCasualty}
            onDeleteCasualty={handleDeleteCasualty}
          />
        );
      },
    },
  ];
  const [selectedFilter, SetSelectedFilter] = useState<string>("All");
  const [searchedValue, SetSearchedValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  useEffect(() => {
    if (selectedCasualtyId > 0) {
      dispatch(SetSelectedCasualtyId(selectedCasualtyId));
      const casualty: Casualty | undefined = casualties?.find(
        (casualty: Casualty) => casualty.Id === selectedCasualtyId,
      );
      if (!isNullOrUndefined(casualty)) {
        SetCurrentCasualty(casualty);
        dispatch({
          type: INSULT_INJURY_SET_APPLIED_INJURIES,
          data: casualty.Injuries,
        });
        dispatch({
          type: INSULT_INJURY_SET_APPLIED_INSULTS,
          data: casualty.Insults,
        });
      }
    }
  }, [selectedCasualtyId]);

  const handleOpenEditCasualtyDetails = () => {
    setIsCasultyTableEditMode(true);
    openEditCasualtyDetails();
  };

  useKeyPress("Enter", () => {
    if (selectedCasualtyId > 0) {
      handleOpenEditCasualtyDetails();
    }
  });

  useKeyPress("ArrowUp", () => {
    if (rowIndex > 0) {
      rowIndex--;
      dispatch(SetSelectedCasualtyId(casualties[rowIndex]?.Id || 0));
    } else {
      rowIndex = casualties.length - 1;
      dispatch(SetSelectedCasualtyId(casualties[rowIndex]?.Id || 0));
    }
  });

  useKeyPress("ArrowDown", () => {
    if (rowIndex < casualties.length - 1) {
      rowIndex++;
      dispatch(SetSelectedCasualtyId(casualties[rowIndex]?.Id || 0));
    } else {
      rowIndex = 0;
      dispatch(SetSelectedCasualtyId(casualties[rowIndex]?.Id || 0));
    }
  });

  React.useEffect(() => {
    setFilteredData(casualties);
  }, [casualties]);

  // Filter the data
  const filterData = (
    keyword: string,
    data: Array<Casualty>,
    filteredKey = "",
  ) => {
    const selectedFilteredKey = filteredKey || selectedFilter;
    const filteredData = data.filter((row: Casualty) => {
      // gender, weight, description
      const isWeightMatched =
        (!selectedFilteredKey ||
          selectedFilteredKey === "All" ||
          selectedFilteredKey === "Weight") &&
        (String(row.Weight).indexOf(keyword) > -1 ||
          String(`${row.Weight}kg`).indexOf(keyword) > -1);
      const isDescriptionMatched =
        (!selectedFilteredKey ||
          selectedFilteredKey === "All" ||
          selectedFilteredKey === "Description") &&
        String(row.Description).toLocaleLowerCase().indexOf(keyword) > -1;
      const isGenderMatched =
        (!selectedFilteredKey ||
          selectedFilteredKey === "All" ||
          selectedFilteredKey === "Gender") &&
        String(Sex[row.Gender]).toLocaleLowerCase() === keyword;
      return isGenderMatched || isWeightMatched || isDescriptionMatched;
    });
    return filteredData;
  };

  // Handle search field keyUp
  const handleSearch = (keyword: string) => {
    SetSearchedValue(keyword);
    setFilteredData(filterData(keyword.toLocaleLowerCase(), casualties));
  };
  const handleEditCasualty = (casualtyId: number) => {
    dispatch(SetSelectedCasualtyId(casualtyId));
    handleOpenEditCasualtyDetails();
  };

  const handleDeleteCasualty = (casualtyId: number) => {
    console.log("handleDeleteCasualty", casualtyId);
  };
  const onClickFilter = (key: string) => {
    SetSelectedFilter(key);
    const value = key !== "All" ? key : "";
    setFilteredData(
      filterData(searchedValue.toLocaleLowerCase(), casualties, value),
    );
  };
  const getFilterItems = () => {
    const items = [
      {
        key: "All",
        text: "All",
      },
      {
        key: "Gender",
        text: "Gender",
      },
      {
        key: "Weight",
        text: "Weight",
      },
      {
        key: "Description",
        text: "Description",
      },
    ];

    return items.map((item) => ({
      key: item.key,
      label: (
        <a
          className="ant-dropdown-link"
          onClick={() => onClickFilter(item.key)}
          href={undefined}
        >
          {item.text}
        </a>
      ),
    }));
  };
  return (
    <>
      <Row wrap={false} gutter={15} style={{ marginBottom: 8 }} align="bottom">
        <Col>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: getFilterItems(),
              selectable: true,
              selectedKeys: [selectedFilter],
            }}
          >
            <Button
              type="link"
              icon={<FilterOutlined className="icon-lg" />}
              size="large"
            />
          </Dropdown>
        </Col>
        <Col flex="1">
          <SearchFilter handleSearch={handleSearch} />
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col span={24}>
          <StyledTable
            size="small"
            columns={columns}
            dataSource={filteredData}
            rowKey="Id"
            onChange={onChange}
            pagination={{
              hideOnSinglePage: true,
              pageSize:
                filteredData && filteredData.length ? filteredData.length : 10,
            }}
            rowClassName={(record: any, index) => {
              return selectedCasualtyId === record.Id ? "selected" : "";
            }}
            onRow={(record: any, index) => {
              return {
                onClick: (event) => {
                  if (event.detail === 2) {
                    // double click
                    if (selectedCasualtyId !== record.Id) {
                      rowIndex = index || 0;
                      dispatch(SetSelectedCasualtyId(record.Id));
                    }
                    handleOpenEditCasualtyDetails();
                  } else if (event.detail === 1) {
                    // single click
                    if (casualties.length > 0) {
                      if (!(selectedCasualtyId === record.Id)) {
                        rowIndex = index || 0;
                        dispatch(SetSelectedCasualtyId(record.Id));
                      }
                    }
                  }
                },
              };
            }}
          />
        </Col>
      </Row>
    </>
  );
};
