import { CrownFilled, PlusCircleOutlined } from "@ant-design/icons";
import { AutoComplete, Tooltip } from "antd";
import React, { MutableRefObject, useRef } from "react";
import { SearchOutlinedIcons } from "../../IconsTheme/Icons.global";
import FloatingLabelInput from "../FloatingLabel/FloatingLabelInput";
import "./../../styles/AutoComplete.scss";
import type { BaseSelectRef } from "rc-select";
import { colorPalette } from "../../styles/MainList.styled";

export interface AutocompleteOption {
  label: string;
  value: string;
}

interface Props {
  value: string;
  scriptFormik: any;
  handleSearch: Function;
  handleInputSearch: Function;
  onSelect: Function;
  options: AutocompleteOption[];
  isFocused: any;
  lastFocused: any;
  setIsFocused: any;
  setLastFocused: any;
  disabled?: boolean;
  readOnly?: boolean;
}

export default function Autocomplete(prop: Props) {
  const ref = useRef() as MutableRefObject<BaseSelectRef>;

  const handleChange = (value: string) => {
    prop.handleInputSearch(value);
  };
  return (
    <AutoComplete
      className="auto-complete-input"
      onSearch={(value: string) => prop.handleSearch(value)}
      onSelect={(value: string) => prop.onSelect(value)}
      options={prop.options}
      value={prop.value}
      ref={ref}
      popupClassName="auto-complete-dropdown"
      disabled={prop?.disabled || false}
    >
      <FloatingLabelInput
        label={""}
        placeholder={"Search"}
        nameForInput={"search"}
        value={prop.scriptFormik.values.search}
        onChange={handleChange}
        scriptFormik={prop.scriptFormik}
        lastFocused={prop.lastFocused}
        isAddNeeded={true}
        suffixIcon={<SearchOutlinedIcons />}
        setIsFocused={prop.setIsFocused}
        setLastFocused={prop.setLastFocused}
        isFocused={prop.isFocused}
        autoFocus
        disabled={prop?.disabled || false}
      />
    </AutoComplete>
  );
}
