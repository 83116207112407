import { Col, Row } from "antd";
import React from "react";

export default function ComingSoon() {
  return (
    <Row align={"middle"} justify={"center"} style={{ height: "100%" }}>
      <Col span={24}>
        <h3 className="text-center">Coming Soon</h3>
      </Col>
    </Row>
  );
}
