import { Sex } from "../../../../../models/Enums";
import { AppliedInjury, Injury } from "../../../../../models/InjuryInterface";
import {
  AppliedInsultWithRelation,
  BooleanParameter,
  Insult,
  InsultWithRelation,
  IntervalParameter,
  Location,
  NumberParameter,
  TextParameter,
} from "../../../../../models/InsultInterface";
import { isNullOrUndefined } from "../../../../../utils/utilityfunctions";
import { InjuryViewTypes } from "../constants";
import { getSurfaceAreaValue, NumberParameterStaticData } from "./CommonData";

export const CreateUpdateAppliedInjuryPayload = (injuryObj: any) => {
  return injuryObj.Insults.map((insult: AppliedInsultWithRelation) => ({
    id: injuryObj.Id === 0 ? 0 : insult.Id,
    categoryId: insult.Category && insult.Category.Id ? insult.Category.Id : 0,
    label: insult.Label,
    locationId: insult.LocationId,
    copySourceInsultId: insult.CopySourceInsultId,
    appliedLocationSide: insult.AppliedLocationSide,
    textParameters: insult.TextParameters.map((p) => ({
      ...p,
      id: injuryObj.Id === 0 ? 0 : p.Id || 0,
      appliedInsultId: injuryObj.Id === 0 ? 0 : insult.Id || 0,
    })),
    booleanParameters: insult.BooleanParameters.map((p) => ({
      ...p,
      id: injuryObj.Id === 0 ? 0 : p.Id || 0,
      appliedInsultId: injuryObj.Id === 0 ? 0 : insult.Id || 0,
    })),
    numberParameters: insult.NumberParameters.map((p) => ({
      ...p,
      id: injuryObj.Id === 0 ? 0 : p.Id || 0,
      appliedInsultId: injuryObj.Id === 0 ? 0 : insult.Id || 0,
    })),
    intervalParameters: insult.IntervalParameters.map((p) => ({
      ...p,
      id: injuryObj.Id === 0 ? 0 : p.Id || 0,
      appliedInsultId: injuryObj.Id === 0 ? 0 : insult.Id || 0,
    })),
  }));
};

export const UpdateAppliedInsultPayload = (
  insultObj: any,
  InjuryIdForAPI: number,
) => {
  const booleanParameters = insultObj.BooleanParameters
    ? insultObj.BooleanParameters.map((i: BooleanParameter) => ({
        booleanParameterId: i.Id,
        valueToApply: i.ValueToApply,
      }))
    : [];
  const intervalParameters = insultObj.IntervalParameters
    ? insultObj.IntervalParameters.map((i: IntervalParameter) => ({
        intervalParameterId: i.Id,
        lowerValueToApply: i.LowerDefault,
        upperValueToApply: i.UpperDefault,
      }))
    : [];
  const numberParameters = insultObj.NumberParameters
    ? insultObj.NumberParameters.map((i: NumberParameter) => ({
        numberParameterId: i.Id,
        valueToApply: Number(i.ValueToApply),
      }))
    : [];
  const textParameters = insultObj.TextParameters
    ? insultObj.TextParameters.map((i: TextParameter) => ({
        textParameterId: i.Id,
        valueToApply: i.ValueToApply,
      }))
    : [];
  return {
    id: insultObj.Id,
    label: insultObj.Label,
    copySourceInsultId: insultObj.CopySourceInsultId,
    locationId: insultObj.locationIdOverride
      ? insultObj.locationIdOverride
      : null,
    appliedInjuryId: InjuryIdForAPI ? InjuryIdForAPI : null,
    booleanParameterValueOverrides: booleanParameters,
    intervalParameterValueOverrides: intervalParameters,
    numberParameterValueOverrides: numberParameters,
    textParameterValueOverrides: textParameters,
    appliedLocationSide: insultObj.AppliedLocationSide,
  };
};

export const CreateAppliedInsultPayload = (
  insultObj: any,
  InjuryIdForAPI: number,
  casualtyId: number,
) => {
  const booleanParameters = insultObj.BooleanParameters
    ? insultObj.BooleanParameters.map((i: BooleanParameter) => ({
        booleanParameterId: i.Id,
        valueToApply: i.ValueToApply,
      }))
    : [];
  const intervalParameters = insultObj.IntervalParameters
    ? insultObj.IntervalParameters.map((i: IntervalParameter) => ({
        intervalParameterId: i.Id,
        lowerValueToApply: i.LowerDefault,
        upperValueToApply: i.UpperDefault,
      }))
    : [];
  const numberParameters = insultObj.NumberParameters
    ? insultObj.NumberParameters.map((i: NumberParameter) => ({
        numberParameterId: i.Id,
        valueToApply: Number(i.ValueToApply),
      }))
    : [];
  const textParameters = insultObj.TextParameters
    ? insultObj.TextParameters.map((i: TextParameter) => ({
        textParameterId: i.Id,
        valueToApply: i.ValueToApply,
      }))
    : [];
  return {
    casualtyId: casualtyId,
    insultId: insultObj.CopySourceInsultId,
    appliedInjuryId: InjuryIdForAPI ? InjuryIdForAPI : null,
    locationIdOverride: insultObj.locationIdOverride
      ? insultObj.locationIdOverride
      : null,
    booleanParameterValueOverrides: booleanParameters,
    intervalParameterValueOverrides: intervalParameters,
    numberParameterValueOverrides: numberParameters,
    textParameterValueOverrides: textParameters,
    appliedLocationSide: insultObj.AppliedLocationSide,
  };
};

export const SetLocationInInjuryAndParameters = (
  injury: AppliedInjury,
  injuryLocations: Location[],
  allInsults: InsultWithRelation[],
  isEditInjury: boolean,
) => {
  const newInjuryObj = JSON.parse(JSON.stringify(injury));
  if (!newInjuryObj.LocationId && injuryLocations && injuryLocations.length) {
    newInjuryObj.LocationId = injuryLocations[0].Id;
    newInjuryObj.Location = injuryLocations[0];
  }
  newInjuryObj.Insults.forEach((insult: InsultWithRelation) => {
    if (!isEditInjury) {
      insult.CopySourceInsultId = insult.Id;
      insult.Id = 0;
    }
    if (!insult.Locations || !insult.LocationId) {
      const masterInsult = allInsults.find(
        (x: InsultWithRelation) =>
          (x.Id && x.Id == insult.CopySourceInsultId) ||
          (!x.Id && x.CopySourceInsultId === insult.CopySourceInsultId),
      );
      if (
        masterInsult &&
        masterInsult.Locations &&
        masterInsult.Locations.length
      ) {
        insult.Locations = masterInsult.Locations;
        insult.BodyParts = masterInsult.BodyParts;
        insult.LocationId = insult.LocationId
          ? insult.LocationId
          : masterInsult.Locations[0].Id; //first location
      } else {
        insult.Locations = [];
        insult.BodyParts = [];
      }
    }
    insult.BooleanParameters.forEach((param) => {
      if (isNullOrUndefined(param.ValueToApply)) {
        param.ValueToApply = param.Default;
      }
    });
    insult.NumberParameters.forEach((param) => {
      const staticParameterIndex = NumberParameterStaticData.findIndex(
        (p) => p.Label == param.UserFacingLabel,
      );
      if (isNullOrUndefined(param.ValueToApply)) {
        if (staticParameterIndex !== -1) {
          // number with time constraint
          param.ValueToApply =
            NumberParameterStaticData[staticParameterIndex].Default;
        } else {
          param.ValueToApply = param.Default;
        }
      }
    });
    insult.TextParameters.forEach((param) => {
      if (isNullOrUndefined(param.ValueToApply)) {
        param.ValueToApply = param.Default;
      }
    });
    // set surface area value for burn insult's parameter based on location
    if (insult.Label.toLowerCase() === "burn") {
      const SurfaceAreaParam = insult.NumberParameters.find(
        (x: NumberParameter) => x.UserFacingLabel.toLowerCase() === "tbsa",
      );
      if (SurfaceAreaParam) {
        if (
          !isNullOrUndefined(insult.LocationId) &&
          !isNullOrUndefined(insult.Locations)
        ) {
          const location = insult.Locations.find(
            (x) => x.Id === insult.LocationId,
          );
          if (location) {
            const value = getSurfaceAreaValue(
              location?.BodyPart?.Name,
              location?.Detail,
            );
            SurfaceAreaParam.ValueToApply = value;
            SurfaceAreaParam.Default = value;
          }
        }
      }
    }
  });
  return newInjuryObj;
};

export const SetInsultLocations = (
  insult: any,
  allInsults: InsultWithRelation[],
) => {
  const newInsultObj = JSON.parse(JSON.stringify(insult));
  //  need to add locations from master insult
  if (!newInsultObj.Locations || newInsultObj.LocationId) {
    const masterInsult = allInsults.find(
      (x: InsultWithRelation) =>
        (x.Id && x.Id == newInsultObj.CopySourceInsultId) ||
        (!x.Id && x.CopySourceInsultId === newInsultObj.CopySourceInsultId),
    );
    if (masterInsult) {
      newInsultObj.Locations = masterInsult.Locations; // Remove this line new location UI is implemented.
      newInsultObj.BodyParts = masterInsult.BodyParts;
    } else {
      newInsultObj.Locations = []; // Remove this line new location UI is implemented.
      newInsultObj.BodyParts = [];
    }
  }
  return newInsultObj;
};

export const SetCopySourceInsultId = (newInsultObj: any) => {
  const insult = JSON.parse(JSON.stringify(newInsultObj));
  if (!insult.CopySourceInsultId && insult.Id) {
    insult.CopySourceInsultId = insult.Id;
    insult.Id = 0;
  }
  // set surface area value for burn insult's parameter based on location
  if (insult.Label.toLowerCase() === "burn") {
    const SurfaceAreaParam = insult.NumberParameters.find(
      (x: NumberParameter) => x.UserFacingLabel.toLowerCase() === "tbsa",
    );
    if (SurfaceAreaParam) {
      const locationId = insult.LocationId
        ? insult.LocationId
        : insult.Location && insult.Location.Id
          ? insult.Location.Id
          : insult.Locations && insult.Locations.length
            ? insult.Locations[0].Id
            : 0;
      if (locationId) {
        const location = insult.Locations.find(
          (x: Location) => x.Id === locationId,
        );
        if (location) {
          const value = getSurfaceAreaValue(
            location?.BodyPart?.Name,
            location?.Detail,
          );
          SurfaceAreaParam.ValueToApply = value;
          SurfaceAreaParam.Default = value;
        }
      }
    }
  }
  return insult;
};
export const CompareTwoInjuries = (newInjury: Injury, oldInjury: Injury) => {
  const oldInjuryInsultId = newInjury.Insults.map(
    (x: InsultWithRelation) => x.CopySourceInsultId || 0,
  );
  const newInjuryInsultId = oldInjury.Insults.map(
    (x: InsultWithRelation) => x.CopySourceInsultId || 0,
  );
  const difference = newInjuryInsultId.every((x: number) => {
    return oldInjuryInsultId.includes(x);
  });
  if (
    oldInjury.Title !== newInjury.Title ||
    oldInjury.LocationId !== newInjury.LocationId ||
    oldInjury.Insults.length !== newInjury.Insults.length ||
    !difference
  ) {
    return true;
  } else {
    return false;
  }
};

// Hide MIC Level for now. Can be un-hide in future.
/**
 * Determines whether to hide a NumberParameter based on its user-facing label.
 * This method is used to hide parameter columns in insult row and insult item components.
 *
 * @param {NumberParameter} param - The NumberParameter object to evaluate for hiding.
 * @returns {boolean} - Returns true if the parameter should be hidden, false otherwise.
 */
export const hideNumberParam = (param: NumberParameter) => {
  if (
    param.UserFacingLabel?.toLocaleLowerCase() === "mic level" ||
    param.UserFacingLabel?.toLocaleLowerCase() === "degree"
  ) {
    return false;
  } else {
    return true;
  }
};

export const ShowInsultParameter = (param: NumberParameter) => {
  if (
    ["resistance", "mcis", "mic level"].includes(
      param.UserFacingLabel.toLocaleLowerCase(),
    )
  ) {
    return false;
  }
  return true;
};
export const calculateApproxTimeToDeath = (
  CurrentView: string,
  InsultObj: InsultWithRelation,
  Gender: Sex,
  AppliedInsults: AppliedInsultWithRelation[],
  AppliedInjuries: Injury[],
  CurrentInsultFlowRate: number,
) => {
  const flowRateArray: number[] = [];

  AppliedInsults.forEach((x: AppliedInsultWithRelation) => {
    if (
      x.Label.includes("Hemorrhage") &&
      (InjuryViewTypes.EDIT_INSULT !== CurrentView || x.Id !== InsultObj?.Id)
    ) {
      x.NumberParameters.forEach((y: NumberParameter) => {
        if (y.UserFacingLabel === "Flow Rate") {
          flowRateArray.push(y.ValueToApply);
        }
      });
    }
  });
  AppliedInjuries.forEach((x: Injury) => {
    x.Insults.forEach((i: Insult) => {
      if (
        i.Label.includes("Hemorrhage") &&
        (InjuryViewTypes.EDIT_INSULT !== CurrentView || i.Id !== InsultObj?.Id)
      ) {
        i.NumberParameters.forEach((y: NumberParameter) => {
          if (y.UserFacingLabel === "Flow Rate") {
            flowRateArray.push(y.ValueToApply);
          }
        });
      }
    });
  });
  const currentEditedInsultFlowRate =
    InjuryViewTypes.EDIT_INSULT !== CurrentView
      ? 0
      : CurrentInsultFlowRate || 0;
  const totalFlowRate =
    flowRateArray.reduce((x, i) => x + i, 0) + currentEditedInsultFlowRate;
  // The equation is Minutes to Death = (DeadlyLossVolume) * (1sec/ FlowRateValue ml/min)
  // for males,  Deadly Loss Volume = 2400ml (40% of 6L average)
  // for females, Deadly Loss Volume = 1800ml (40% of 4.5L average)
  // Update Deadly Loss Volume in the equation when user toggles male/female.
  const DeadlyLossVolume = Gender === Sex.Male ? 2400 : 1800;
  if (totalFlowRate <= 0) {
    return null;
  }
  const DeathTimeInMin = Number(
    (DeadlyLossVolume * (1 / totalFlowRate)).toFixed(2),
  );
  if (DeathTimeInMin / 60 < 1) {
    return `(~ ${DeathTimeInMin} min to Death)`;
  } else {
    const DeathTimeInHours = (DeathTimeInMin / 60).toFixed(0);
    const RemainingTimeInMinutes = (DeathTimeInMin % 60).toFixed(0);
    return `(~ ${DeathTimeInHours}hr ${
      Number(RemainingTimeInMinutes) > 0 ? RemainingTimeInMinutes + "min" : ""
    } to Death)`;
  }
};
