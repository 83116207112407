import axios from "axios";

// Create injury item
export function CreateInjury(
  data: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/injury/create`, data)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

// Add new insults to the injury.
export function AddInjuryInsults(
  injuryId: number,
  insultArray: number[],
  callback: Function,
  errorcallback: Function,
) {
  insultArray.forEach((insultId: number) => {
    axios
      .post(`/api/1/pfc/injury/Update/AddInsultToInjury`, {
        insultId: insultId,
        injuryId: injuryId,
      })
      .then((res: any) => {
        //do something
        if (callback != null) {
          callback(res.data.Result);
        }
      })
      .catch((err: any) => {
        // catch error
        if (errorcallback != null) {
          errorcallback(err);
        }
      });
  });
}

// Update injury information.
export function UpdateInjury(
  data: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/injury/Update`, data)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

// Remove insults from injuries
export function RemoveInjuryInsults(
  injuryId: number,
  insultId: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/injury/Update/RemoveInsult/`, {
      insultId: insultId,
      injuryId: injuryId,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

// Delete injury
export function deleteInjury(
  id: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`/api/1/pfc/injury/deleteInjury/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

// remove and delete applied injury from casualty
export function RemoveAndDeleteAppliedInjuryFromCasualty(
  AppliedInjuryId: number,
  CasualtyId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/Casualty/RemoveAndDeleteAppliedInjuryFromCasualty`, {
      appliedInjuryId: AppliedInjuryId,
      casualtyId: CasualtyId,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
// add location to insult
export function AddLocationToInsult(
  LocationId: number,
  InsultId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/insult/AddLocationToInsult`, {
      locationId: LocationId,
      InsultId: InsultId,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

// add selected injury to casualty
export function AddInjuryToCasualty(
  CasualtyId: number,
  InjuryId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/casualty/update/AddInjuryToCasualty`, {
      injuryId: InjuryId,
      casualtyId: CasualtyId,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
// Add new insult to the injury.
export function AddInsultToInjury(
  InjuryId: number,
  InsultId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/injury/Update/AddInsultToInjury`, {
      insultId: InsultId,
      injuryId: InjuryId,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
// Delete Insult
export function deleteInsult(
  id: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`/api/1/pfc/insult/deleteInsult/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function RemoveAndDeleteAppliedInsultFromCasualty(
  AppliedInsultId: number,
  CasualtyId: number,
  AppliedInjuryId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/Casualty/RemoveAndDeleteAppliedInsultFromCasualty`, {
      appliedInsultId: AppliedInsultId,
      casualtyId: CasualtyId,
      appliedInjuryId: AppliedInjuryId,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

// create master injury from applied injury
export function CreateMasterFromAppliedInjury(
  AppliedInjuryId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/AppliedInjury/CreateMasterFromAppliedInjury`, {
      appliedInjuryId: AppliedInjuryId,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
