import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setAlertMessage } from "../../../../../actions/alertMessage";
import { Sex } from "../../../../../models/Enums";
import { Injury } from "../../../../../models/InjuryInterface";
import { InsultWithRelation } from "../../../../../models/InsultInterface";
import { useAppSelector } from "../../../../../store/hooks";
import Human2DModel from "./Human2DModel/Human2DModel";
import { ReactComponent as Airway } from "../../../../../assets/img/Svg/Airway.svg";
import { ReactComponent as Circulatory } from "../../../../../assets/img/Svg/Circulatory.svg";
import { ReactComponent as Clothing } from "../../../../../assets/img/Svg/Clothing.svg";
import { ReactComponent as Musculature } from "../../../../../assets/img/Svg/Musculature.svg";
import { ReactComponent as NervousSystemy } from "../../../../../assets/img/Svg/NervousSystem.svg";
import { ReactComponent as Skeletal } from "../../../../../assets/img/Svg/Skeletal.svg";
import { ReactComponent as FlipHorizontalRight } from "../../../../../assets/img/Svg/FlipHorizontalRight.svg";
import { ReactComponent as FlipHorizontalLeft } from "../../../../../assets/img/Svg/FlipHorizontalLeft.svg";
import { InjuryOverlay, TopMenu } from "./DetailsTab.styled";
import Icon, { EditOutlined } from "@ant-design/icons";
import { RiRefreshLine } from "react-icons/ri";
import { LabelData } from "../../../../../models/LabelOverlayInterfaces";
import { Tooltip } from "antd";
import LabelOverlay from "./LabelOverlay/LabelOverlay";
import { LeftPaneViewTypes } from "../constants";
import { Casualty } from "../../../../../models/CasualtyInterfaces";
import { INSULT_INJURY_SET_LABEL_DATA } from "../../../../../store/CasualtyCreator/InsultInjury/actionType";
import { calculateApproxTimeToDeath } from "../InjuryTab/InjuryMapUtills";
import { getInsultLabelDisplayValue } from "../InjuryTab/InsultInjuryUtility";

interface DetailsTabProps {
  editCasualtyInsultInjuries: Function;
  setAlertMessage: Function;
  isModelClothsOn: boolean;
  setIsModelClothsOn: Function;
  leftPaneView: string;
  selectedCasualtyId: number;
  casualty: Casualty;
}

const DetailsTab = ({
  editCasualtyInsultInjuries,
  isModelClothsOn,
  setIsModelClothsOn,
  leftPaneView,
  selectedCasualtyId,
  casualty,
  ...props
}: DetailsTabProps) => {
  const dispatch = useDispatch();
  const { detailsTab } = useAppSelector((state) => state.FeatureFlagReducer);
  const {
    AppliedInsults,
    AppliedInjuries,
    CurrentInsultFlowRate,
    CurrentView,
  } = useAppSelector((state: any) => state.InsultInjury);

  const {
    clothing,
    musculature,
    skeletal,
    airway,
    circulatory,
    nervousSystem,
    riRefreshLine,
  } = useAppSelector((state) => state.FeatureFlagReducer.detailsTab);

  const InsultObj: any = useAppSelector(
    (state: any) => state.InsultInjury.InsultObj,
  );
  const injuryList = useAppSelector(
    (state: any) => state.InsultInjury.FilteredAppliedInjuries,
  );
  const insultList = useAppSelector(
    (state: any) => state.InsultInjury.FilteredAppliedInsults,
  );
  const RightSideCasualtyFormData = useAppSelector(
    (state: any) => state.CasualtyCreator.CasualtyForm,
  );
  const { isReadOnly } = useAppSelector(
    (state: any) => state.scriptBuilderReducer,
  );

  const LabelData = useAppSelector(
    (state: any) => state.InsultInjury.LabelData,
  );

  const [isFront, setIsFront] = useState(true);
  const [isClothsOn, setIsClothsOn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const displayApproxTimeToDeath = () => {
    return calculateApproxTimeToDeath(
      CurrentView,
      InsultObj,
      RightSideCasualtyFormData.Gender,
      AppliedInsults,
      AppliedInjuries,
      parseInt(CurrentInsultFlowRate),
    );
  };
  const getLabelOptions = (insult: InsultWithRelation) => {
    switch (insult.Label) {
      case "Radiation":
        return ["L11", "L12", "R11", "R12"];
      default:
        return insult.Location?.LabelOptions;
    }
  };
  useEffect(() => {
    setIsLoading(true);
    const injuriesLabels =
      injuryList && injuryList.length
        ? injuryList.map((injury: Injury) => ({
            location: injury.Location?.BodyPart?.Name || "",
            title: injury.Title,
            color: "#ffffff",
            insults: injury.Insults,
            labelOptions: injury.Location?.LabelOptions,
          }))
        : [];
    const insultsLabels =
      insultList && insultList.length
        ? insultList.map((insult: InsultWithRelation) => ({
            location: insult.Location?.BodyPart?.Name || "",
            title: getInsultLabelDisplayValue(insult),
            color: insult.Category.Color || "#ffffff",
            insults: [],
            labelOptions: getLabelOptions(insult),
            Severity:
              insult.NumberParameters?.find(
                (n) => n.UserFacingLabel === "Severity",
              )?.ValueToApply || 0,
          }))
        : [];
    dispatch({
      type: INSULT_INJURY_SET_LABEL_DATA,
      data: [...injuriesLabels, ...insultsLabels],
    });
    setIsLoading(false);
  }, [injuryList, insultList, casualty]);

  return (
    <>
      <TopMenu>
        <Tooltip title="Front / Back">
          <div className={"icon"} onClick={() => setIsFront((prev) => !prev)}>
            <Icon
              component={isFront ? FlipHorizontalRight : FlipHorizontalLeft}
            />
          </div>
        </Tooltip>
        {clothing && (
          <Tooltip title={`Coming Soon ${clothing ? "- Hidden in Prod" : ""}`}>
            <div
              className={"icon disabled " + (isClothsOn ? "active" : "")}
              onClick={() => setIsClothsOn((prev) => !prev)}
            >
              <Icon component={Clothing} />
            </div>
          </Tooltip>
        )}
        {musculature && (
          <Tooltip
            title={`Coming Soon ${musculature ? "- Hidden in Prod" : ""}`}
          >
            <div className={"icon disabled"}>
              <Icon component={Musculature} />
            </div>
          </Tooltip>
        )}
        {skeletal && (
          <Tooltip title={`Coming Soon ${skeletal ? "- Hidden in Prod" : ""}`}>
            <div className={"icon disabled"}>
              <Icon component={Skeletal} />
            </div>
          </Tooltip>
        )}
        {airway && (
          <Tooltip title={`Coming Soon ${airway ? "- Hidden in Prod" : ""}`}>
            <div className={"icon disabled"}>
              <Icon component={Airway} />
            </div>
          </Tooltip>
        )}
        {circulatory && (
          <Tooltip
            title={`Coming Soon ${circulatory ? "- Hidden in Prod" : ""}`}
          >
            <div className={"icon disabled"}>
              <Icon component={Circulatory} />
            </div>
          </Tooltip>
        )}
        {nervousSystem && (
          <Tooltip
            title={`Coming Soon ${nervousSystem ? "- Hidden in Prod" : ""}`}
          >
            <div className={"icon disabled"}>
              <Icon component={NervousSystemy} />
            </div>
          </Tooltip>
        )}
        {riRefreshLine && (
          <Tooltip
            title={`Coming Soon ${riRefreshLine ? "- Hidden in Prod" : ""}`}
          >
            <div className={"icon disabled"}>
              <Icon component={RiRefreshLine} />
            </div>
          </Tooltip>
        )}
        <Tooltip title="Time to Death">
          <div className={"text-only"} style={{ width: "auto" }}>
            <span className="pl-2">{displayApproxTimeToDeath()}</span>
          </div>
        </Tooltip>
        {isReadOnly ? null : (
          <Tooltip title="Edit Injuries">
            <div
              className={"icon edit"}
              onClick={() => editCasualtyInsultInjuries()}
            >
              <EditOutlined />
            </div>
          </Tooltip>
        )}
      </TopMenu>
      <InjuryOverlay>
        {detailsTab.labelOverlay && (
          <LabelOverlay isFront={isFront} labelData={LabelData} />
        )}
        <Human2DModel
          modelType={
            leftPaneView === LeftPaneViewTypes.CASUALTY_FORM
              ? RightSideCasualtyFormData.Gender
              : casualty?.Gender || Sex.Male
          }
          isFront={isFront}
          isModelClothsOn={isModelClothsOn}
        />
      </InjuryOverlay>
    </>
  );
};

function mapToStateToProps(state: any) {
  return {
    SystemMessage: state.SystemMessage,
    auth: state.authReducer,
  };
}

export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(DetailsTab);
