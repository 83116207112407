import React, { useEffect, useRef } from "react";
import { Input as AntInput } from "antd";
import { CheckOutLinedIcon } from "../../IconsTheme/Icons.global";
import { InputProps } from "antd/lib/input";
import "../../css/custom.css";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { FloatingLabel } from "./FloatingLabel.styled";

interface FloatingLabelNumberInputProps extends InputProps {
  label: string;
  nameForInput: string;
  value: any;
  setValue: Function; //Function setting the value for input
  type?: string;
  onSave?: any;
  debouncedSave?: any;
  onChange: any;
  scriptFormik: any;
  lastFocused: any;
  isAddNeeded: boolean; // will me removed in future give false for now.
  setIsFocused: any;
  setLastFocused: any;
  isFocused: any;
  isWholeNumber: boolean; //True for integer
  onBlur?: any;
  classNameForContainer?: string;
  maxValue: number;
  minValue: number;
  step?: number; // Value to Increment/Decrement on click of plus and minus btn.
  minutesSuffixNeeded?: boolean;
  handleOptionToggle?: Function;
}

type ErrorType = {
  [key: string]: string;
};

const StyledDiv = styled.div`
  height: 100%;

  &:hover {
    .minus-icon,
    .plus-icon {
      color: #ffffff;
    }
  }

  &:active {
    .minus-icon,
    .plus-icon {
      color: #23d0eb;
    }
  }

  .minus-icon,
  .plus-icon {
    font-size: 25px;
    padding: 6px;
    margin-top: 5px;
  }
`;

const FloatingLabelNumberInput = (props: FloatingLabelNumberInputProps) => {
  const {
    label,
    value: initialValue,
    setValue,
    suffix,
    nameForInput,
    maxLength,
    isAddNeeded,
    onSave,
    scriptFormik,
    onBlur,
    onChange,
    onFocus,
    debouncedSave,
    setIsFocused,
    setLastFocused,
    lastFocused,
    isFocused,
    isWholeNumber,
    minValue,
    maxValue,
    step,
    ...inputProps
  } = props;

  const inputElement = useRef<any | null>(null);
  const [stepNumber, setStepNumber] = React.useState(step || 0);
  const precision = ((step ?? 1 % 1)?.toString().length ?? 1) - 2;

  useEffect(() => {
    if (minValue && Number(minValue) > 0) {
      if (isWholeNumber) {
        setValue(Number(minValue));
      } else {
        setValue(parseFloat(minValue.toString()).toFixed(precision));
      }
    }
  }, [minValue]);

  useEffect(() => {
    if (isWholeNumber && step) {
      setStepNumber(Math.trunc(step));
    } else {
      setStepNumber(step || 0);
    }
  }, [isWholeNumber]);

  const onBlurNumberCheck = () => {
    if (isWholeNumber) {
      if (minValue && Number(props.value) < Number(minValue)) {
        setValue(minValue);
        props.handleOptionToggle &&
          props.handleOptionToggle("IntervalDuration", minValue);
        // props.handleUpdateIntervalOnValueChange(minValue);
      } else if (maxValue && Number(props.value) > Number(maxValue)) {
        setValue(maxValue);
        props.handleOptionToggle &&
          props.handleOptionToggle("IntervalDuration", maxValue);
        // props.handleUpdateIntervalOnValueChange(maxValue);
      }
    } else {
      if (minValue && parseFloat(props.value) < Number(minValue)) {
        setValue(minValue.toFixed(precision));
        props.handleOptionToggle &&
          props.handleOptionToggle(
            "IntervalDuration",
            minValue.toFixed(precision),
          );
        // props.handleUpdateIntervalOnValueChange(minValue.toFixed(precision));
      } else if (maxValue && parseFloat(props.value) > Number(maxValue)) {
        setValue(maxValue.toFixed(precision));
        props.handleOptionToggle &&
          props.handleOptionToggle(
            "IntervalDuration",
            maxValue.toFixed(precision),
          );
        // props.handleUpdateIntervalOnValueChange(maxValue.toFixed(precision));
      } else {
        setValue(parseFloat(props.value).toFixed(precision));
        props.handleOptionToggle &&
          props.handleOptionToggle(
            "IntervalDuration",
            maxValue.toFixed(precision),
          );
        // props.handleUpdateIntervalOnValueChange(parseFloat(props.value).toFixed(precision));
      }
    }
  };

  const labelClass = (value: any) => {
    return props.isFocused[`${props.nameForInput}`] || props.value !== ""
      ? `label ${props.disabled ? "disabled" : ""}`
      : `label floatingLabel ${props.disabled ? "disabled" : ""}`;
  };

  const inputState = (input: string) => {
    if (input === props.nameForInput) {
      if (
        props.scriptFormik.errors[`${input}`] &&
        props.scriptFormik.touched[nameForInput]
      ) {
        return "errorInput";
      } else if (
        !props.isFocused[`${input}`] &&
        !props.scriptFormik.errors[`${input}`] &&
        props.lastFocused[`${input}`] &&
        props.value !== ""
      ) {
        return "successInput";
      } else {
        return "default";
      }
    }
  };

  const handlePlusBtn = () => {
    const formValue = inputElement.current;
    if (formValue && props.step) {
      if (isWholeNumber) {
        if (Number(props.value) < Number(props.maxValue)) {
          setValue(Number(formValue.input.value) + stepNumber);
          props.onChange(Number(formValue.input.value) + stepNumber);
          // props.handleUpdateIntervalOnValueChange(Number(formValue.input.value) + stepNumber);
        }
      } else {
        if (Number(props.value) < Number(props.maxValue)) {
          setValue(
            (Number(formValue.input.value) + stepNumber).toFixed(precision),
          );
          props.onChange(
            (Number(formValue.input.value) + stepNumber).toFixed(precision),
          );
          // props.handleUpdateIntervalOnValueChange((Number(formValue.input.value) + stepNumber).toFixed(precision));
        }
      }
    }
  };

  const handleMinusBtn = () => {
    const formValue = inputElement.current;
    if (formValue && props.step) {
      if (isWholeNumber) {
        if (
          Number(props.value) > 0 &&
          Number(props.value) > Number(props.minValue)
        ) {
          setValue(Number(formValue.input.value) - stepNumber);
          props.onChange(Number(formValue.input.value) - stepNumber);
        }
      } else {
        if (
          Number(props.value) > 0 &&
          Number(props.value) > Number(props.minValue)
        ) {
          setValue(
            (Number(formValue.input.value) - stepNumber).toFixed(precision),
          );
          props.onChange(
            (Number(formValue.input.value) - stepNumber).toFixed(precision),
          );
        }
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    console.log(Number(inputValue));
    const reg = isWholeNumber ? /^[0-9\b]+$/ : /^[0-9\d,]*\.?[0-9\d,]*$/;
    if (reg.test(inputValue) || inputValue === "") {
      if (parseInt(inputValue) > 999) {
        props.onChange(999);
        props.handleOptionToggle &&
          props.handleOptionToggle("IntervalDuration", Number(999));
      } else {
        props.onChange(e.target.value);
        props.handleOptionToggle &&
          props.handleOptionToggle("IntervalDuration", Number(inputValue));
      }
      props.setLastFocused({
        ...props.lastFocused,
        [`${props.nameForInput}`]: true,
      });
      props.scriptFormik.handleChange(e);
    }
  };

  React.useEffect(() => {
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.debouncedSave]);

  return (
    <div style={{ display: "inline-flex", alignItems: "center" }}>
      <StyledDiv onClick={handleMinusBtn}>
        <MinusCircleOutlined className="minus-icon" />
      </StyledDiv>
      <FloatingLabel
        style={{ padding: "0px" }}
        className={`${props.classNameForContainer} intervalMinutesSSuffix`}
        isFocused={!props.isFocused[`${props.nameForInput}`]}
        errors={scriptFormik.errors[`${props.nameForInput}`]}
        lastFocused={props.lastFocused[`${props.nameForInput}`]}
        nameOfInput={props.nameForInput}
      >
        {step ? (
          <label
            style={{
              width: "100%",
              paddingLeft: `${
                props.isFocused[`${props.nameForInput}`] || props.value !== ""
                  ? ""
                  : "8%"
              }`,
            }}
            className={labelClass(`${props.nameForInput}`)}
            htmlFor={props.nameForInput}
          >
            {props.label}
          </label>
        ) : (
          <label
            style={{ width: "100%" }}
            className={labelClass(`${props.nameForInput}`)}
            htmlFor={props.nameForInput}
          >
            {props.label}
          </label>
        )}
        {!!props.scriptFormik.errors[`${props.nameForInput}`] &&
          props.scriptFormik.touched[nameForInput] && (
            <div className="error">
              {props.scriptFormik.errors[`${props.nameForInput}`]}
            </div>
          )}
        {!props.isFocused[`${props.nameForInput}`] &&
          !props.scriptFormik.errors[`${props.nameForInput}`] &&
          props.lastFocused[`${props.nameForInput}`] &&
          props.value !== "" && (
            <div className="">
              <CheckOutLinedIcon className="checkCircleIcon" />
            </div>
          )}
        {step ? (
          <AntInput
            ref={inputElement}
            id={props.nameForInput}
            name={props.nameForInput}
            className={`${inputState(props.nameForInput)} centerText`}
            value={props.value}
            onChange={handleChange}
            style={{ textAlign: `center` }}
            onKeyPress={(e) => {
              if (e.key == "Enter" && props.isAddNeeded) {
                e.preventDefault();
                !props.scriptFormik.errors[`${props.nameForInput}`] &&
                  props.scriptFormik.values[`${props.nameForInput}`] !== "" &&
                  props.onSave();
              }
            }}
            onBlur={(e) => {
              onBlurNumberCheck();
              props.setIsFocused({
                ...props.isFocused,
                [`${props.nameForInput}`]: false,
              });
              props.scriptFormik.handleBlur(e);
              props.onBlur && props.onBlur();
            }}
            onFocus={() =>
              props.setIsFocused({
                ...props.isFocused,
                [`${props.nameForInput}`]: true,
              })
            }
            prefix={
              // <div style={{ padding: "0px 5px" }} onClick={handleMinusBtn}>
              //     <MinusCircleOutlined />
              // </div>
              <></>
            }
            suffix={
              // <div style={{ padding: "0px 5px", display: "flex" }} onClick={handlePlusBtn}>
              //     {props.minutesSuffixNeeded && <span className="minutesSuffix">min</span> }
              //     <PlusCircleOutlined />
              // </div>
              props.minutesSuffixNeeded && (
                <span className="minutesSuffix">min</span>
              )
            }
            {...inputProps}
          />
        ) : (
          <AntInput
            ref={inputElement}
            id={props.nameForInput}
            name={props.nameForInput}
            style={{ textAlign: `center` }}
            className={`${inputState(props.nameForInput)} centerText`}
            value={props.value}
            onChange={handleChange}
            onKeyPress={(e) => {
              if (e.key == "Enter" && props.isAddNeeded) {
                e.preventDefault();
                !props.scriptFormik.errors[`${props.nameForInput}`] &&
                  props.scriptFormik.values[`${props.nameForInput}`] !== "" &&
                  props.onSave();
              }
            }}
            onBlur={(e) => {
              onBlurNumberCheck();
              props.setIsFocused({
                ...props.isFocused,
                [`${props.nameForInput}`]: false,
              });
              props.scriptFormik.handleBlur(e);
              props.onBlur && props.onBlur();
            }}
            onFocus={() =>
              props.setIsFocused({
                ...props.isFocused,
                [`${props.nameForInput}`]: true,
              })
            }
            title=""
            {...inputProps}
          />
        )}
      </FloatingLabel>
      <StyledDiv onClick={handlePlusBtn}>
        <PlusCircleOutlined className="plus-icon" />
      </StyledDiv>
    </div>
  );
};

export default FloatingLabelNumberInput;
