import * as React from "react";
import { useState } from "react";
import { Row, Form } from "antd";
import FloatingLabelInput from "../../../components/FloatingLabel/FloatingLabelInput";
import { useFormik } from "formik";
import { BlueButton } from "../../../styles/Buttons.styled";
import { FormikErrorType } from "../../../models/common/FormikErrorType";
import { FormRow } from "../../ScriptBuilder/EditComponents/ScriptDetailsView/styled";
import { useHistory } from "react-router-dom";
import { OpenErrorNotification } from "../../../components/Notification/Notification";
import AlertBar from "../../../app/AlertBar";
import AuthenticationPageLeftPane from "../../../components/CenterScreenCard/AuthenticationPageLeftPane";
import { ForgotPasswordContainer } from "./ForgotPassword.styled";
import { forgotPassword } from "../../../api/Auth";

export default function ForgotPasswordForm() {
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const focusedStates = {
    email: false,
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);

  const scriptFormik = useFormik({
    initialValues: {
      email: "",
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      const regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      if (!values.email.trim()) {
        errors["email"] = "Required";
      } else if (!regex.test(values.email.trim())) {
        errors["email"] = "Invalid Email";
      }

      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    setLoading(true);
    const formData = { Email: scriptFormik.values.email };
    forgotPassword(formData, successSubmit, (error: any) => {
      const { ErrorCode, ErrorMessage } = error;
      if (ErrorCode === 404) {
        scriptFormik.setErrors({
          email: ErrorMessage,
        });
      } else {
        OpenErrorNotification({ description: ErrorMessage });
      }
      setLoading(false);
    });
  };

  const successSubmit = (res: any) => {
    setLoading(false);
    // redirect to ForgotPasswordSuccess.tsx
    history.replace("/forgot-password/success");
  };
  return (
    <AuthenticationPageLeftPane displayLoginLink title={"Forgot Password"}>
      <ForgotPasswordContainer>
        <AlertBar />
        <Form data-testid="loginForm" onFinish={scriptFormik.handleSubmit}>
          <div className="login-input-column">
            <FormRow>
              <FloatingLabelInput
                label={"Email"}
                nameForInput={"email"}
                type="email"
                data-testid="email"
                value={scriptFormik.values.email}
                onChange={scriptFormik.handleChange}
                scriptFormik={scriptFormik}
                lastFocused={lastFocused}
                isAddNeeded={true}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
                onSave={scriptFormik.handleSubmit}
                lastPassIgnore={false}
              />
            </FormRow>
          </div>

          <Row justify="end" align="middle">
            <Form.Item>
              {loading ? (
                <BlueButton
                  rounded="true"
                  type="primary"
                  className="l2l-btn login-btn"
                  loading
                >
                  Loading
                </BlueButton>
              ) : (
                <BlueButton
                  rounded="true"
                  data-testid="submit-button"
                  htmlType="submit"
                  className="l2l-btn login-btn"
                >
                  Submit
                </BlueButton>
              )}
            </Form.Item>
          </Row>
        </Form>
      </ForgotPasswordContainer>
    </AuthenticationPageLeftPane>
  );
}
