import axios from "axios";
import { BlockType } from "../../models/Enums";
import {
  Block,
  GenericBlock,
  Text as OurText,
} from "../../models/ScriptInterfaces";

export function CreateTextBlock(
  textBlock: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/TextBlock/CreateText`, textBlock)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function CreateCriticalAction(
  filename: any,
  callback: Function,
  errorcallback: Function,
) {
  // There is no Critical Action block controller, because
  // the concept of critical action has been replaced
  // by the client. That is why this function does nothing.
  // Please delete this function once you can do it without breaking the build.
}
export function CreateScriptEquipmentListItems(
  filename: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/EquipmentBlock/CreateScriptEquipmentListItems`, filename)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function CreateScriptListItems(
  filename: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`api/1/pfc/ListBlock/CreateWithItems`, {
      sectionId: filename.SectionId,
      order: filename.Order,
      title: filename.BlockContent.Title,
      blockListType: filename.BlockType,
      displayedAsTwoColumns: filename.BlockContent.DisplayedAsTwoColumns,
      isOrderedList: filename.BlockContent.IsOrderedList,
      quantityEnabled: filename.BlockContent.QuantityEnabled,
      isTitleEnabled: filename.BlockContent.IsTitleEnabled,
      listItems: filename.BlockContent.ListItems,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function RemoveMediaFromTreatment(
  treatmentBlockId: number,
  mediaId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(
      `/api/1/pfc/TreatmentBlock/RemoveMedia?treatmentBlockId=${treatmentBlockId}&mediaId=${mediaId}`,
    )
    .then((res: any) => {
      if (callback != null) {
        callback(res.data.Result.UploadedMediaItems);
      }
    })
    .catch((err: any) => {
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function RemoveMediaFromDiagnostic(
  diagnosticBlockId: number,
  mediaId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(
      `/api/1/pfc/DiagnosticBlock/RemoveMedia?diagnosticBlockId=${diagnosticBlockId}&mediaId=${mediaId}`,
    )
    .then((res: any) => {
      if (callback != null) {
        callback(res.data.Result.UploadedMediaItems);
      }
    })
    .catch((err: any) => {
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function RemoveAndDeleteSubtaskForTreatment(
  request: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/TreatmentBlock/RemoveAndDeleteSubtask`, request)
    .then((res: any) => {
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function CreateRolePlayerPromptType(
  filename: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(
      `/api/1/pfc/RolePlayerPromptType/CreateRolePlayerPromptType`,
      filename,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function CreateCasualtyStatusBlock(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/CasualtyStatusBlock/Create`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function UpdateCasualtyStatusBlock(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/CasualtyStatusBlock/Update`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function AddBlockListItem(
  filename: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/ListBlock/CreateItemAndAddToList`, filename)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function UpdateBlockListItem(
  filename: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/block/UpdateBlockListItem`, filename)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function UpdateTextBlock(
  filename: GenericBlock<OurText>,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/TextBlock/UpdateText`, filename)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function DeleteTextMediaItem(
  id: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`/api/1/pfc/TextBlock/deleteAttachedMedia/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function UpdateTextBlockNonGeneric(
  filename: Block,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/TextBlock/UpdateText`, filename)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function UpdateCriticalAction(
  filename: any,
  callback: Function,
  errorcallback: Function,
) {
  // There is no such thing as a critical action. This method
  // is obsolete.
}
export function UpdateScriptEquipmentListItems(
  filename: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/EquipmentBlock/UpdateScriptEquipmentListItems`, filename)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function UpdateScriptListItems(
  block: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/ListBlock/Update`, block)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function UpdateBlockOrder(
  BlockOrder: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/block/UpdateBlockOrder`, BlockOrder)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function DuplicateBlock(
  DuplicateBlock: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/block/DuplicateBlock`, DuplicateBlock)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function DeleteBlock(
  block: Block,
  callback: Function,
  errorcallback: Function,
) {
  console.log("The block to delete is");
  console.log(block);
  if (block.BlockType === BlockType.Text) {
    axios
      .delete(`/api/1/pfc/TextBlock/deleteBlock/${block.Id}`)
      .then((res: any) => {
        //do something
        if (callback != null) {
          callback(res.data.Result);
        }
      })
      .catch((err: any) => {
        // catch error
        if (errorcallback != null) {
          errorcallback(err);
        }
      });
  } else if (block.BlockType === BlockType.ScriptList) {
    axios
      .delete(`/api/1/pfc/ListBlock/Delete/${block.Id}`)
      .then((res: any) => {
        //do something
        if (callback != null) {
          callback(res.data.Result);
        }
      })
      .catch((err: any) => {
        // catch error
        if (errorcallback != null) {
          errorcallback(err);
        }
      });
  } else if (block.BlockType === BlockType.Diagnostic) {
    axios
      .delete(`/api/2/pfc/diagnostic-block/${block.Id}`)
      .then((res: any) => {
        //do something
        if (callback != null) {
          callback(res.data.Result);
        }
      })
      .catch((err: any) => {
        // catch error
        if (errorcallback != null) {
          errorcallback(err);
        }
      });
  } else if (block.BlockType === BlockType.ResearchQuestion) {
    axios
      .delete(`/api/1/pfc/ResearchQuestionBlock/${block.Id}`)
      .then((res: any) => {
        //do something
        if (callback != null) {
          callback(res.data.Result);
        }
      })
      .catch((err: any) => {
        // catch error
        if (errorcallback != null) {
          errorcallback(err);
        }
      });
  } else if (block.BlockType === BlockType.ScriptEquipmentList) {
    axios
      .delete(`/api/1/pfc/EquipmentBlock/deleteBlock/${block.Id}`)
      .then((res: any) => {
        //do something
        if (callback != null) {
          callback(res.data.Result);
        }
      })
      .catch((err: any) => {
        // catch error
        if (errorcallback != null) {
          errorcallback(err);
        }
      });
  } else if (block.BlockType === BlockType.Medication) {
    axios
      .delete(`/api/2/pfc/medication-block/${block.Id}`)
      .then((res: any) => {
        //do something
        if (callback != null) {
          callback(res.data.Result);
        }
      })
      .catch((err: any) => {
        // catch error
        if (errorcallback != null) {
          errorcallback(err);
        }
      });
  } else if (block.BlockType === BlockType.Treatment) {
    axios
      .delete(`/api/2/pfc/treatment-block/${block.Id}`)
      .then((res: any) => {
        //do something
        if (callback != null) {
          callback(res.data.Result);
        }
      })
      .catch((err: any) => {
        // catch error
        if (errorcallback != null) {
          errorcallback(err);
        }
      });
  } else if (block.BlockType === BlockType.Miscellaneous) {
    axios
      .delete(`/api/2/pfc/miscellaneous-block/${block.Id}`)
      .then((res: any) => {
        //do something
        if (callback != null) {
          callback(res.data.Result);
        }
      })
      .catch((err: any) => {
        // catch error
        if (errorcallback != null) {
          errorcallback(err);
        }
      });
  } else if (block.BlockType === BlockType.CasualtyStatus) {
    axios
      .delete(`/api/1/pfc/CasualtyStatusBlock/Delete/${block.Id}`)
      .then((res: any) => {
        //do something
        if (callback != null) {
          callback(res.data.Result);
        }
      })
      .catch((err: any) => {
        // catch error
        if (errorcallback != null) {
          errorcallback(err);
        }
      });
  }
}

// This method is to delete List Item from the List Block.
export function DeleteListItemFromListBlock(
  listBlockId: number,
  listItemId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/ListBlock/RemoveAndDeleteListItem`, {
      listBlockId: listBlockId,
      listItemId: listItemId,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
