import React from "react";
import { UpdateResearchQuestionButton } from "../../../../../api/ResearchQuestionBlock/ApiPost";
import SwitchWithLabel from "../../../../../components/Switch/SwitchWithLabel";
import { ResearchQuestionButton } from "../../../../../models/ScriptInterfaces";

interface Props {
  object: ResearchQuestionButton;
  onChange: any;
  disabled: boolean;
}
export default function ButtonQuestionType(props: Props) {
  const handleChange = (dbField: string, value: string | boolean) => {
    if (props.disabled) return; //disable action callback in read only script
    const Payload = { ...props.object, [dbField]: value };
    props.onChange("ResearchQuestionButton", Payload);
    UpdateResearchQuestionButton(
      Payload,
      () => undefined,
      () => undefined,
    );
  };
  return (
    <>
      <div
        className="mb-8"
        onClick={() => handleChange("Start", !props.object.Start)}
      >
        <SwitchWithLabel
          rightLabel={"Start"}
          checked={props.object.Start}
          onChange={() => undefined}
          disabled={props.disabled}
        />
      </div>
      <div
        className="mb-8"
        onClick={() => handleChange("Stop", !props.object.Stop)}
      >
        <SwitchWithLabel
          rightLabel={"Stop"}
          checked={props.object.Stop}
          onChange={() => undefined}
          disabled={props.disabled}
        />
      </div>
      <div
        className="mb-8"
        onClick={() => handleChange("Rating", !props.object.Rating)}
      >
        <SwitchWithLabel
          rightLabel={"Rating"}
          checked={props.object.Rating}
          onChange={() => undefined}
          disabled={props.disabled}
        />
      </div>
    </>
  );
}
