import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type initialState = {
  showUserModal: boolean;
};

const headInitialState: initialState = {
  showUserModal: false,
};

const HeadSlice = createSlice({
  name: "Head",
  initialState: headInitialState,
  reducers: {
    SetShowUserModal: (state, action: PayloadAction<boolean>) => {
      console.log("state", state);
      state.showUserModal = action.payload;
    },
  },
});

export default HeadSlice.reducer;
export const { SetShowUserModal } = HeadSlice.actions;
