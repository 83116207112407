import {
  DownOutlinedIcons,
  UpOutlinedIcons,
} from "../../IconsTheme/Icons.global";
import FloatingLabelTextArea from "../FloatingLabel/FloatingLabelTextArea";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreatorsForTreatment } from "../../store/index";
import { FormikProps } from "formik";

interface AdditionalInfoProps {
  scriptFormik: FormikProps<any>;
  description: string;
  additionalNote: string;
  onChange: Function;
  isReadOnly: boolean;
}

export const AdditionalInfoTA = (props: AdditionalInfoProps) => {
  const [isAdditionalItemsShowing, setIsAdditionalItemsShowing] =
    React.useState(false);
  const dispatch = useDispatch();
  const {
    SetLastFocused,
    SetIsFocused,
    updateDescription,
    updateAdditionalNote,
  } = bindActionCreators(actionCreatorsForTreatment, dispatch);
  const state = useSelector((state: any) => state.TreatmentReducer);
  const showAdditionalItems = () => {
    setIsAdditionalItemsShowing(!isAdditionalItemsShowing);
  };

  const handleChange = (type: string, value: string) => {
    if (type === "Description") {
      props.onChange("TimedActionTaskContent.Description", value);
      updateDescription(value);
    } else if (type === "AdditionalNote") {
      props.onChange("TimedActionTaskContent.AdditionalNote", value);
      updateAdditionalNote(value);
    }
  };

  return (
    <div className="additionalItemsContainer">
      <div className="treatmentAdditionalItems" onClick={showAdditionalItems}>
        <span>Additional Info</span>
        {isAdditionalItemsShowing ? (
          <UpOutlinedIcons className="downIcon" />
        ) : (
          <DownOutlinedIcons className="downIcon" />
        )}
      </div>
      {isAdditionalItemsShowing && (
        <div>
          <FloatingLabelTextArea
            label={"Description"}
            textareaName={"description"}
            value={props.description}
            onSave={() => undefined}
            onChange={(e: any) => handleChange("Description", e.target.value)}
            scriptFormik={props.scriptFormik}
            lastFocused={state.lastFocused}
            setIsFocused={SetIsFocused}
            setLastFocused={SetLastFocused}
            style={{ marginBottom: "1rem" }}
            isFocused={state.isFocused}
            readOnly={props.isReadOnly}
          />
          <FloatingLabelTextArea
            label={"Additional Note"}
            textareaName={"additionalNote"}
            value={props.additionalNote}
            onSave={() => undefined}
            onChange={(e: any) =>
              handleChange("AdditionalNote", e.target.value)
            }
            scriptFormik={props.scriptFormik}
            lastFocused={state.lastFocused}
            setIsFocused={SetIsFocused}
            setLastFocused={SetLastFocused}
            isFocused={state.isFocused}
            style={{ marginBottom: "1rem" }}
            readOnly={props.isReadOnly}
          />
        </div>
      )}
    </div>
  );
};
