import * as React from "react";
import styled from "styled-components";
import { StyledPhaseTag } from "./DetailsPanelComponents.styled";

interface PhaseProps {
  tag: string;
}

export const PhaseTags = (props: PhaseProps) => {
  const { tag } = props;
  return <StyledPhaseTag>{tag}</StyledPhaseTag>;
};
