import * as React from "react";
import {
  Block,
  Phase,
  ResearchQuestion,
} from "../../../../models/ScriptInterfaces";
import {
  Container,
  FixedDiv,
  ScrollableDiv,
} from "../../../../styles/EditComponentTheme.styled";
import { IconColumn } from "../IconColumn";
import { FilterBarContainer } from "../../../../styles/MainList.styled";
import { useFormik } from "formik";
import { FormikErrorType } from "../../../../models/common/FormikErrorType";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  SetIsEditingAllowed,
  SetResearchQuestionSearchOptions,
} from "./ResearchQuestionSlice";
import RequiredSwitch from "./RightEditPane/RequiredSwitch";
import TextAreaEdit from "./RightEditPane/TextAreaEdit";
import AnswerTypeSelection from "./RightEditPane/AnswerTypeSelection";
import TextQuestionBlock from "./RightEditPane/TextQuestionBlock";
import { ResearchQuestionType } from "../../../../models/Enums";
import SelectQuestionBlock from "./RightEditPane/SelectQuestionBlock";
import RadioQuestionBlock from "./RightEditPane/RadioQuestionBlock";
import NumberQuestionType from "./RightEditPane/NumberQuestionType";
import { isNullOrUndefined } from "../../../../utils/utilityfunctions";
import ButtonQuestionType from "./RightEditPane/ButtonQuestionType";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../components/ConfirmDialog/useConfirmDialog";
import ResearchQuestionSearchAdd from "./ResearchQuestionSearchAdd";
import { MasterResearchQuestion } from "../../../../models/ResearchQuestionInterfaces";
import { AutocompleteOption } from "../../../../components/Autocomplete/Autocomplete";
import SwitchWithLabel from "../../../../components/Switch/SwitchWithLabel";
import { getMasterAndPublicResearchQuestionList } from "../../../../api/ResearchQuestion/ApiGet";
import {
  AssignMasterResearchQuestionToResearchQuestionBlock,
  UpdateResearchQuestionBlock,
} from "../../../../api/ResearchQuestionBlock/ApiPost";
import produce from "immer";
import { SetPhases } from "../../MainlistSlice";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import { copyResearchQuestion } from "../../../../api/ResearchQuestion/ApiPost";
import { useDebounce } from "../../../../hooks/useDebounce";

export interface ResearchQuestionBlock extends Block {
  BlockContent: ResearchQuestion;
}

interface Props {
  object: ResearchQuestionBlock;
  currentPhaseIndex: number;
  onDelete: any; //Function
  onCopy: any; //Function
}
const ResearchQuestionEdit = (props: Props) => {
  const dispatch = useAppDispatch();
  const { phases, currentPhaseIndex, currentSectionIndex, currentBlockIndex } =
    useAppSelector((state) => state.MainlistReducer);
  const { isReadOnly } = useAppSelector((state: any) => state.MainlistReducer);
  const { isEditingAllowed, researchQuestionSearchOptions } = useAppSelector(
    (state) => state.ResearchQuestionReducer,
  );

  const [rqChangedVariable, setRqChangedVariable] = React.useState("");
  const onChange = (
    changedVariable: keyof ResearchQuestion,
    change: any,
    secondChangedVariable?: keyof ResearchQuestion,
  ) => {
    const newPhases = produce(phases, (draftPhases: Phase[]) => {
      (draftPhases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
        currentBlockIndex
      ].BlockContent![changedVariable] as any) = change;
      if (!isNullOrUndefined(secondChangedVariable)) {
        (draftPhases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
          currentBlockIndex
        ].BlockContent![secondChangedVariable] as any) = change;
      }
    });
    setRqChangedVariable(changedVariable as string);
    dispatch(SetPhases(newPhases));
  };

  const onResearchQuestionSave = () => {
    const ResearchQuestionBlock =
      phases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
        currentBlockIndex
      ];
    if (
      ![
        "ResearchQuestionText",
        "ResearchQuestionSelect",
        "ResearchQuestionRadio",
        "ResearchQuestionNumber",
        "ResearchQuestionButton",
      ].includes(rqChangedVariable as string)
    ) {
      UpdateResearchQuestionBlock(
        ResearchQuestionBlock,
        (data: any) => {
          if (rqChangedVariable === "ResearchQuestionType") {
            const newPhases = produce(phases, (draftPhases: Phase[]) => {
              draftPhases![currentPhaseIndex].Sections![
                currentSectionIndex
              ].Blocks![currentBlockIndex].BlockContent = data.BlockContent;
            });
            dispatch(SetPhases(newPhases));
          }
        },
        (error: any) =>
          OpenErrorNotification({
            description: error.message,
          }),
      );
    }
    setRqChangedVariable("");
  };

  const [isMasterRQ, setIsMasterRQ] = React.useState<boolean>(false);

  const updateMasterResearchQuestions = () => {
    getMasterAndPublicResearchQuestionList((data: MasterResearchQuestion[]) => {
      if (data.length) {
        const researchQuestionOptions: AutocompleteOption[] = data.map(
          (mrq) => ({
            label: mrq.Question,
            value: mrq.Id.toString(),
          }),
        );
        dispatch(SetResearchQuestionSearchOptions(researchQuestionOptions));
      }
    });
  };

  React.useEffect(() => {
    updateMasterResearchQuestions();
    return () => {
      dispatch(SetIsEditingAllowed(false));
    };
  }, []);

  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const scriptFormik = useFormik({
    initialValues: {
      search: props.object?.BlockContent?.Question || "",
      description: props.object?.BlockContent?.Description || "",
      additionalDetails: props.object?.BlockContent?.AdditionalDetails || "",
      researchQuestionType:
        props.object?.BlockContent?.ResearchQuestionType || 0,
    },
    validate: () => {
      const errors: FormikErrorType = {};
      return errors;
    },
    validateOnBlur: true,
    onSubmit: () => {
      onResearchQuestionSave();
    },
  });

  const onDeleteConfirm = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "Are you sure delete this Block?",
      content: "This cannot be undone",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        props.onDelete(props.object);
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        console.log("Cancel");
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };

  const debounceSubmitFormik = useDebounce(scriptFormik.submitForm);

  React.useEffect(() => {
    if (scriptFormik.isValid) {
      debounceSubmitFormik();
    }

    return () => {
      debounceSubmitFormik.cancel();
    };
  }, [props.object, scriptFormik.isValid, scriptFormik.values]);

  const handleChange = (field: string, value: string | boolean) => {
    if (isReadOnly || !isEditingAllowed) return; //disable action callback in read only script
    onChange(field, value);
  };

  const handleIsRequiredChange = () => {
    if (isReadOnly || !isEditingAllowed) return; //disable action callback in read only script
    handleChange("IsRequired", !props.object.BlockContent!.IsRequired);
  };

  const handleIsPublicChange = () => {
    if (isReadOnly || !isEditingAllowed) return; //disable action callback in read only script
    handleChange("IsPublic", !props.object.BlockContent!.IsPublic);
  };

  const onEdit = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "",
      content:
        "All edits here will update the master as well. Do you want to Continue?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch(SetIsEditingAllowed(true));
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };

  const addNew = () => {
    if (isReadOnly) return; //disable action callback in read only script
    if (props.object?.BlockContent?.Id) {
      copyResearchQuestion(
        props.object.BlockContent.Id,
        (researchQuestion: ResearchQuestion) => {
          AssignMasterResearchQuestionToResearchQuestionBlock(
            {
              blockId: props.object.Id,
              researchQuestionId: props.object.BlockContent.Id,
              masterResearchQuestionId: researchQuestion.Id,
            },
            (data: ResearchQuestion) => {
              const newPhases = produce(phases, (draftPhases: Phase[]) => {
                draftPhases![currentPhaseIndex].Sections![
                  currentSectionIndex
                ].Blocks![currentBlockIndex].BlockContent = data;
              });
              dispatch(SetPhases(newPhases));
              updateMasterResearchQuestions();
              dispatch(SetIsEditingAllowed(true));
              setIsMasterRQ(true);
            },
            (errorMessage: string) => {
              OpenErrorNotification({ description: errorMessage });
            },
          );
        },
        (errorMessage: string) => {
          OpenErrorNotification({ description: errorMessage });
        },
        scriptFormik.values["search"],
      );
    }
  };

  const checkIfRQIsMaster = (value: string) => {
    console.log("ABHI", researchQuestionSearchOptions, value);
    if (!isNullOrUndefined(value)) {
      const isFound =
        researchQuestionSearchOptions.findIndex(
          (x) => x.label.trim() === value.trim(),
        ) !== -1;
      setIsMasterRQ(isFound);
    }
  };

  React.useEffect(() => {
    // Research Question is not master then allow editing by default.
    !isMasterRQ && isEditingAllowed
      ? dispatch(SetIsEditingAllowed(true))
      : dispatch(SetIsEditingAllowed(false));
  }, [isMasterRQ]);

  React.useEffect(() => {
    checkIfRQIsMaster(scriptFormik.values.search);
  }, [scriptFormik.values.search, researchQuestionSearchOptions]);

  return (
    <Container>
      <FixedDiv phase={false}>
        <IconColumn
          type="research"
          onDeleteClick={onDeleteConfirm}
          isEditBtnAvailable={true}
          isEditBtnDisabled={isReadOnly || isEditingAllowed}
          onEditClick={onEdit}
          isMasterBtnAvailable
          isMasterBtnDisabled={
            isReadOnly || scriptFormik.values.search?.trim().length === 0
          }
          isMaster={isMasterRQ}
          onMasterBtnClick={addNew}
        />
      </FixedDiv>

      {!isNullOrUndefined(props.object) &&
        !isNullOrUndefined(props.object.BlockContent) && (
          <ScrollableDiv
            id="scrollableDiv"
            phase={false}
            style={{ height: `calc(100vh - 122px)` }}
          >
            <FilterBarContainer isDiagnostic={true}>
              <ResearchQuestionSearchAdd
                formik={scriptFormik}
                object={props.object}
                disabled={isReadOnly}
                setIsMasterRQ={setIsMasterRQ}
                checkIsMaster={checkIfRQIsMaster}
                onChange={handleChange}
              />
            </FilterBarContainer>
            <div
              style={{ padding: `0 11px` }}
              className={!isEditingAllowed ? "readOnly" : ""}
            >
              <div className="mb-8">
                <SwitchWithLabel
                  rightLabel={"Public"}
                  checked={props.object.BlockContent!.IsPublic}
                  onChange={handleIsPublicChange}
                  disabled={isReadOnly || !isEditingAllowed}
                />
              </div>

              <RequiredSwitch
                isEnabled={props.object.BlockContent!.IsRequired}
                onChange={handleIsRequiredChange}
                disabled={isReadOnly || !isEditingAllowed}
              />

              <TextAreaEdit
                onChange={handleChange}
                scriptFormik={scriptFormik}
                label={"Description"}
                DBKey={"Description"}
                field={"description"}
                maxLength={70}
                disabled={isReadOnly || !isEditingAllowed}
              />

              <TextAreaEdit
                onChange={handleChange}
                scriptFormik={scriptFormik}
                label={"Additional Details"}
                DBKey={"AdditionalDetails"}
                field={"additionalDetails"}
                maxLength={70}
                disabled={isReadOnly || !isEditingAllowed}
              />

              <AnswerTypeSelection
                onChange={handleChange}
                value={
                  props.object.BlockContent.ResearchQuestionType ||
                  ResearchQuestionType.Text
                }
                disabled={isReadOnly || !isEditingAllowed}
              />

              {/* Text type question inputs */}
              {!isNullOrUndefined(
                props.object.BlockContent.ResearchQuestionType,
              ) &&
              !isNullOrUndefined(
                props.object.BlockContent.ResearchQuestionText,
              ) &&
              props.object.BlockContent.ResearchQuestionType ===
                ResearchQuestionType.Text ? (
                <TextQuestionBlock
                  object={props.object.BlockContent.ResearchQuestionText}
                  onChange={onChange}
                  disabled={isReadOnly || !isEditingAllowed}
                />
              ) : null}

              {/* Select type question inputs */}
              {!isNullOrUndefined(
                props.object.BlockContent.ResearchQuestionType,
              ) &&
              !isNullOrUndefined(
                props.object.BlockContent.ResearchQuestionSelect,
              ) &&
              props.object.BlockContent.ResearchQuestionType ===
                ResearchQuestionType.Select ? (
                <SelectQuestionBlock
                  object={props.object.BlockContent.ResearchQuestionSelect}
                  onChange={onChange}
                  disabled={isReadOnly || !isEditingAllowed}
                />
              ) : null}

              {/* Radio type question inputs */}
              {!isNullOrUndefined(
                props.object.BlockContent.ResearchQuestionType,
              ) &&
              !isNullOrUndefined(
                props.object.BlockContent.ResearchQuestionRadio,
              ) &&
              props.object.BlockContent.ResearchQuestionType ===
                ResearchQuestionType.Radio ? (
                <RadioQuestionBlock
                  object={props.object.BlockContent.ResearchQuestionRadio}
                  onChange={onChange}
                  disabled={isReadOnly || !isEditingAllowed}
                />
              ) : null}

              {/* Number type question inputs */}
              {!isNullOrUndefined(
                props.object.BlockContent.ResearchQuestionType,
              ) &&
              !isNullOrUndefined(
                props.object.BlockContent.ResearchQuestionNumber,
              ) &&
              props.object.BlockContent.ResearchQuestionType ===
                ResearchQuestionType.Number ? (
                <NumberQuestionType
                  object={props.object.BlockContent.ResearchQuestionNumber}
                  onChange={onChange}
                  disabled={isReadOnly || !isEditingAllowed}
                />
              ) : null}

              {/* Button type question inputs */}
              {!isNullOrUndefined(
                props.object.BlockContent.ResearchQuestionType,
              ) &&
              !isNullOrUndefined(
                props.object.BlockContent.ResearchQuestionButton,
              ) &&
              props.object.BlockContent.ResearchQuestionType ===
                ResearchQuestionType.Button ? (
                <ButtonQuestionType
                  object={props.object.BlockContent.ResearchQuestionButton}
                  onChange={onChange}
                  disabled={isReadOnly || !isEditingAllowed}
                />
              ) : null}
            </div>
          </ScrollableDiv>
        )}
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </Container>
  );
};
export default ResearchQuestionEdit;
