import { Tooltip } from "antd";
import {
  DragIcon,
  ForkOutlinedIcons,
  PrinterOutlinedIcons,
  ShareAltOutlinedIcons,
  UnorderedListOutlinedIcons,
} from "../../../../IconsTheme/Icons.global";
import { MoreOutlined } from "@ant-design/icons";
import { DragMenuItem, DragMenuItems } from "../../SideBar";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { BlockType } from "../../../../models/Enums";
import React from "react";
import { SectionCol } from "../../../../styles/MainList.styled";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { SetOpenOptionsModal } from "../../MainlistSlice";
import { Phase } from "../../../../models/ScriptInterfaces";

interface DragMenuProps {
  isSectionTypeTimedAction: boolean;
  showEditCasualtyPane: Function;
}

export const SideDragMenu = (props: DragMenuProps) => {
  const dispatch = useAppDispatch();
  const { openOptionsModal, collapse, currentPhaseIndex, isTimedSection } =
    useAppSelector((state: any) => state.MainlistReducer);
  const state = useAppSelector((state: any) => state.MainlistReducer);
  const phases: Phase[] = state.phases;
  const handleOptionsModal = (dragItemClicked?: any, e?: any) => {
    // hide option menu when click outside of menu item
    if (dragItemClicked) {
      const el = document.querySelectorAll(".menu-option");
      if (el && el.length && dragItemClicked?.target) {
        for (let i = 0; i < el.length; i++) {
          if (el[i]?.contains(dragItemClicked.target)) {
            return;
          }
        }
      }
    }

    if (dragItemClicked.content !== null) {
      if (dragItemClicked.content === "Options") {
        dispatch(SetOpenOptionsModal(!openOptionsModal));
      }
    }
    if (dragItemClicked.content === undefined) {
      dispatch(SetOpenOptionsModal(!openOptionsModal));
    }
  };
  return (
    <SectionCol
      xl={!collapse ? { span: 1 } : { span: 3 }}
      lg={!collapse ? { span: 2 } : { span: 5 }}
      md={!collapse ? { span: 3 } : { span: 5 }}
      sm={!collapse ? { span: 4 } : { span: 6 }}
      className="DragMenu modal-container"
    >
      {openOptionsModal ? (
        <>
          <Tooltip
            placement="top"
            title=""
            className={"modal-function-dragMenu optionsBorderBottom"}
          >
            <div className="sideMenuItem" onClick={handleOptionsModal}>
              <span className="optionsIcon">
                <UnorderedListOutlinedIcons className="" />
              </span>
              <div className={"drag-item-content"}>Options</div>
            </div>
          </Tooltip>
          <section
            className={"options-modal-body"}
            onClick={handleOptionsModal}
          >
            <Tooltip
              placement="top"
              title=""
              className={"modal-function-dragMenu disabled menu-option"}
            >
              <div>
                <span className="optionsIcon">
                  <ShareAltOutlinedIcons className="disable-icon" />
                </span>
                <div className={"drag-item-content disabled"}>Share</div>
              </div>
            </Tooltip>
            <Tooltip
              placement="top"
              title=""
              className={"modal-function-dragMenu disabled menu-option"}
            >
              <div>
                <span className="optionsIcon">
                  <ForkOutlinedIcons className="disable-icon" />
                </span>
                <div className={"drag-item-content disabled"}>Fork</div>
              </div>
            </Tooltip>
            <Tooltip
              placement="top"
              title=""
              className={"modal-function-dragMenu disabled menu-option"}
            >
              <div>
                <span className="optionsIcon">
                  <PrinterOutlinedIcons className="disable-icon" />
                </span>
                <div className={"drag-item-content disabled"}>Print</div>
              </div>
            </Tooltip>
          </section>
        </>
      ) : (
        <>
          <Tooltip placement="top" title="" className="modal-function-dragMenu">
            <div
              className="sideMenuItem optionsBorderBottom"
              onClick={handleOptionsModal}
            >
              <span className="optionsIcon">
                <UnorderedListOutlinedIcons className="" />
              </span>
              <div className={"drag-item-content"}>Options</div>
            </div>
          </Tooltip>
          <div className="options-list">
            {DragMenuItems.filter(
              (x: DragMenuItem) => x.content.toLocaleLowerCase() !== "options",
            ).map((dragMenuItem: DragMenuItem, index) => {
              return (
                <Droppable
                  droppableId={`dragMenuItem-${index}`}
                  isDropDisabled={true}
                  type={dragMenuItem.droppableType}
                  key={index}
                >
                  {(provided: any, snapshot: any) => (
                    <div ref={provided.innerRef} {...snapshot.isDraggingOver}>
                      <div key={dragMenuItem.id} id="dragMenuItemsId">
                        <Draggable
                          draggableId={`${dragMenuItem.content}.New`}
                          index={index}
                          isDragDisabled={
                            dragMenuItem.BlockType ===
                              BlockType.CriticalAction && !state.isTimedSection
                              ? true
                              : dragMenuItem.disabled
                          }
                        >
                          {(provided, snapshot) => (
                            <React.Fragment>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={(e) =>
                                  handleOptionsModal(dragMenuItem, e)
                                }
                                style={{
                                  ...provided.draggableProps.style,
                                  backgroundColor: "#101010",
                                }}
                              >
                                <Tooltip
                                  placement="top"
                                  title=""
                                  className={
                                    (dragMenuItem.disabled &&
                                      dragMenuItem.content.toLocaleLowerCase() !==
                                        "options") ||
                                    (dragMenuItem.BlockType ===
                                      BlockType.CriticalAction &&
                                      !state.isTimedSection)
                                      ? "modal-function-dragMenu disabled"
                                      : "modal-function-dragMenu"
                                  }
                                >
                                  <div className="sideMenuItem">
                                    <DragIcon className="dragHandle" />
                                    <span
                                      className={
                                        (dragMenuItem.disabled &&
                                          dragMenuItem.content.toLocaleLowerCase() !==
                                            "options") ||
                                        (dragMenuItem.BlockType ===
                                          BlockType.CriticalAction &&
                                          !state.isTimedSection)
                                          ? "drag-item-content disabled"
                                          : "drag-item-content activeContent"
                                      }
                                    >
                                      {dragMenuItem.content}
                                    </span>
                                    <DragIcon className="dragHandle" />
                                  </div>
                                </Tooltip>
                              </div>
                              <div>
                                {snapshot.isDragging && (
                                  <Tooltip
                                    placement="top"
                                    title=""
                                    className={
                                      (dragMenuItem.disabled &&
                                        dragMenuItem.content.toLocaleLowerCase() !==
                                          "options") ||
                                      (dragMenuItem.BlockType ===
                                        BlockType.CriticalAction &&
                                        !state.isTimedSection)
                                        ? "modal-function-dragMenu disabled"
                                        : "modal-function-dragMenu"
                                    }
                                  >
                                    <div className="sideMenuItem">
                                      <DragIcon className="dragHandle hideDragHandle" />
                                      <span
                                        className={
                                          (dragMenuItem.disabled &&
                                            dragMenuItem.content.toLocaleLowerCase() !==
                                              "options") ||
                                          (dragMenuItem.BlockType ===
                                            BlockType.CriticalAction &&
                                            !state.isTimedSection)
                                            ? "drag-item-content disabled"
                                            : "drag-item-content activeContent"
                                        }
                                      >
                                        {dragMenuItem.content}
                                      </span>
                                      <DragIcon className="dragHandle hideDragHandle" />
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                            </React.Fragment>
                          )}
                        </Draggable>
                      </div>
                    </div>
                  )}
                </Droppable>
              );
            })}
          </div>
        </>
      )}
    </SectionCol>
  );
};
