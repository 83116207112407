import Icon, {
  ArrowRightOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import * as React from "react";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../../components/Notification/Notification";
import { ResearchQuestion } from "../../../../models/ScriptInterfaces";
import { ActionIcon } from "../../../../components/Table/CustomTable.styled";
import CustomTable from "../../../../components/Table/CustomTable";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../components/ConfirmDialog/useConfirmDialog";
import { colorPalette } from "../../../../styles/MainList.styled";
import { TitleTD } from "../../../ScriptBuilder/EditComponents/ScriptDetailsView/CasualtyTable/styledComponents/StyledTable.styled";
import { dateTimeConversion } from "../../../../utils/dateUtils";
import { ResearchQuestionType } from "../../../../models/Enums";
import { copyResearchQuestion } from "../../../../api/ResearchQuestion/ApiPost";
import { ReactComponent as Archive } from "../../../../assets/img/Svg/Archive.svg";
import { deleteResearchQuestion } from "../../../../api/ResearchQuestion/ApiDelete";
import { archiveUnarchiveResearchQuestion } from "../../../../api/ResearchQuestion/ApiPut";
import { renderFormattedName } from "../../../../utils/utilityfunctions";
import { useDispatch } from "react-redux";
import {
  onArchiveSuccess,
  onCreateSuccess,
  onDeleteSuccess,
} from "./ResearchQuestionSlice";
interface Props {
  handleSearch: any;
  showDetails: any;
  onEdit: any;
  searchInput: string;
}
export default function ResearchQuestionTable({
  onEdit,
  showDetails,
  handleSearch,
  searchInput,
}: Props) {
  const dispatch = useDispatch();
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const renderActionOptions = (data: ResearchQuestion) => {
    return (
      <div className="action-tray">
        {/* edit icon */}
        <ActionIcon
          className="action-option"
          hoverColor={colorPalette.white}
          onClick={() => onEdit(data)}
        >
          <span className="tooltip-on-hover">Edit</span>
          <EditOutlined className="display-on-hover" />
        </ActionIcon>
        {/* archive/ unarchive icons */}
        <ActionIcon
          className="action-option"
          hoverColor={
            !data.IsArchived ? colorPalette.error : colorPalette.primary100
          }
          onClick={() => archiveQuestion(data)}
        >
          <span className="tooltip-on-hover">
            {data.IsArchived ? "Unarchive" : "Archive"}
          </span>
          <Icon component={Archive} className="display-on-hover" />
        </ActionIcon>
        {/* copy icon */}
        <ActionIcon
          className="action-option"
          hoverColor={colorPalette.white}
          onClick={() => handleCopy(data.Id)}
        >
          <span className="tooltip-on-hover">Copy</span>
          <CopyOutlined className="display-on-hover" />
        </ActionIcon>
        {/* delete icon */}
        <ActionIcon
          className="action-option"
          hoverColor={colorPalette.error}
          onClick={() => handleDelete(data.Id)}
        >
          <span className="tooltip-on-hover">Delete</span>
          <DeleteOutlined className="display-on-hover" />
        </ActionIcon>
      </div>
    );
  };
  const archiveQuestion = (data: ResearchQuestion) => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title:
        "You are going to update the research question and its related data",
      content: `Please confirm you want to ${
        data.IsArchived ? "unarchive" : "archive"
      } this script?`,
      okText: "Yes",
      onOk: () => {
        archiveUnarchiveResearchQuestion(
          data.Id,
          (response: any) => {
            dispatch(onArchiveSuccess(data.Id));
          },
          (errorMessage: string) => {
            OpenErrorNotification({ description: errorMessage });
          },
        );
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: () => {
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };
  const handleCopy = (id: number) => {
    if (id) {
      id &&
        copyResearchQuestion(
          id,
          (response: any) => {
            dispatch(onCreateSuccess(response));
          },
          (errorMessage: string) => {
            OpenErrorNotification({ description: errorMessage });
          },
        );
    }
  };
  const handleDelete = (id: number) => {
    if (id) {
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "You are going to delete research question.",
        content: "Are you sure you want delete this research question?",
        okText: "Yes",
        onOk: () => {
          deleteResearchQuestion(
            id,
            (successMessage: string) => {
              dispatch(onDeleteSuccess(id));
              OpenSuccessNotification({ description: successMessage });
            },
            (errorMessage: string) => {
              OpenErrorNotification({ description: errorMessage });
            },
          );
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "error",
      });
    }
  };
  const renderQuestionType = (type: any) => {
    return <span className="text-white">{ResearchQuestionType[type]}</span>;
  };
  const columns = [
    {
      title: "Research Question",
      dataIndex: "Question",
      key: "Question",
      className: "whiteText script-title noPadding",
      sorter: (a: any, b: any) => a.Question.localeCompare(b.Question),
      render: (question: any, data: any) => (
        <TitleTD onClick={() => showDetails(data)}>
          <div className="title">{question}</div>
          <div className="detailsLinkOnHover" onClick={() => showDetails(data)}>
            <span className={`detail-link w-100 justify-content-center`}>
              Details <ArrowRightOutlined />
            </span>
          </div>
        </TitleTD>
      ),
    },
    {
      title: "Question Type",
      dataIndex: "ResearchQuestionType",
      key: "type",
      className: "whiteText",
      width: "230px",
      sorter: (a: any, b: any) =>
        a.ResearchQuestionType - b.ResearchQuestionType,
      render: (type: any, data: ResearchQuestion) => renderQuestionType(type),
    },
    {
      title: "Created By",
      dataIndex: "CreatedBy",
      key: "CreatedBy",
      className: "whiteText",
      width: "180px",
      sorter: (a: any, b: any) =>
        a.CreatedBy?.FirstName.localeCompare(b.CreatedBy?.FirstName),
      render: (CreatedBy: any, data: ResearchQuestion) => (
        <span className="text-white">
          {data?.CreatedBy?.LastName || data?.CreatedBy?.FirstName
            ? renderFormattedName(
                data?.CreatedBy?.LastName || "",
                data?.CreatedBy?.FirstName || "",
              )
            : "-"}
        </span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "CreatedAt",
      key: "CreatedAt",
      className: "whiteText",
      width: "180px",
      sorter: (a: any, b: any) =>
        new Date(b.CreatedAt).getTime() > new Date(a.CreatedAt).getTime(),
      render: (CreatedAt: any, data: ResearchQuestion) => (
        <span className="text-white">
          {dateTimeConversion(data?.CreatedAt, "yy.MM.dd")}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "actions",
      className: "actions",
      render: (UpdatedDate: any, data: ResearchQuestion) => {
        return renderActionOptions(data);
      },
    },
  ];

  const archivedRow = (question: ResearchQuestion) => {
    return question.IsArchived ? "archived-row" : "";
  };

  return (
    <>
      <CustomTable
        WrapperStyle={{ height: `calc(100vh - 65px - 70px)` }}
        columns={columns}
        data={handleSearch(searchInput)}
        dynamicRowClass={archivedRow}
      />
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </>
  );
}
