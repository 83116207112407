import * as React from "react";
import { Input as AntInput } from "antd";
import { TextAreaProps } from "antd/lib/input/TextArea";
import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";

const { TextArea: AntTextArea } = AntInput;

const StyledAntInput = styled.div`
  padding-top: 22px;
  position: relative;
  .error {
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.3s ease;
    font-size: 0.86rem;
    color: ${colorPalette.error};
  }
  .label {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    font-size: 0.86rem;
    color: rgba(255, 255, 255, 0.5);
    &.floatingLabel {
      font-size: 1.25rem;
      top: 26px;
      left: 11px;
    }
  }
  .ant-input {
    font-size: 1.25rem;
    color: #fff;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #fff;
    box-shadow: none !important;
    background-color: transparent;
    overflow: hidden;
    &:focus {
      background-color: rgba(255, 255, 255, 0.05);
      border-bottom: 1px solid ${colorPalette.primary100};
    }
  }
`;

interface MuiTextProps extends TextAreaProps {
  label?: any;
  value?: any;
  error?: string;
}

const MuiText = (props: MuiTextProps) => {
  const {
    label,
    value: initialValue,
    error,
    onChange,
    onFocus,
    onBlur,
    ...textareaProps
  } = props;
  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState(initialValue);
  const labelClass =
    isFocused || value || props.defaultValue ? "label" : "label floatingLabel";

  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(true);
    onFocus && onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(false);
    onBlur && onBlur(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    onChange && onChange(e);
  };

  return (
    <StyledAntInput>
      <label className={labelClass}>{props.label}</label>
      {!!props.error && <div className="error">{props.error}</div>}
      <AntTextArea
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        {...textareaProps}
      />
    </StyledAntInput>
  );
};

export default MuiText;
