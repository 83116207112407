import { StarFilled, StarOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";
import Stars from "./Stars";

export const StyledStars = styled(Stars)`
   {
    
  }
`;

export const StyledStarsFilled = styled(StarFilled)`
{
    color: ${colorPalette.primary100};
    font-size: 1.125rem;
}`;

export const StyledStarsOutlined = styled(StarOutlined)`
{
    font-size: 1.125rem; 
}`;
