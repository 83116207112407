import { useFormik } from "formik";
import produce from "immer";
import React, { useEffect, useState } from "react";
import {
  DeleteResearchQuestionRadioAnswer,
  UpdateResearchQuestionRadio,
} from "../../../../../api/ResearchQuestionBlock/ApiPost";
import { OpenErrorNotification } from "../../../../../components/Notification/Notification";
import { FormikErrorType } from "../../../../../models/common/FormikErrorType";
import { ResearchQuestionRadioType } from "../../../../../models/Enums";
import {
  ResearchQuestionRadio,
  ResearchQuestionRadioAnswer,
} from "../../../../../models/ScriptInterfaces";
import AddItemToListInput from "./Components/AddItemToListInput";
import ListItems from "./Components/ListItems";
import RadioWithTwoOption from "./Components/RadioWithTwoOption";
import { useDebounce } from "../../../../../hooks/useDebounce";
interface Props {
  object: ResearchQuestionRadio;
  onChange: any;
  disabled: boolean;
}
export default function RadioQuestionBlock(props: Props) {
  const focusedStates = {
    placeholder: false,
    characterLimit: false,
  };
  const [isFocused, setIsFocused] = useState(focusedStates);
  const [lastFocused, setLastFocused] = useState(focusedStates);
  const scriptFormik = useFormik({
    initialValues: {
      ResearchQuestionRadioType:
        props?.object?.ResearchQuestionRadioType ||
        ResearchQuestionRadioType.Vertical,
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      return errors;
    },
    validateOnBlur: true,
    onSubmit: () => {
      onRadioQuestionSave();
    },
  });
  const handleChange = (field: string, value: string) => {
    scriptFormik.setFieldValue(field, value);
    const Payload = { ...props.object, [field]: value };
    updateData(Payload);
  };
  const updateData = (Payload: ResearchQuestionRadio) => {
    props.onChange("ResearchQuestionRadio", Payload);
  };

  const onRadioQuestionSave = () => {
    // Do not do state update here. It will result in infinite loop.
    // Check below useEffect for more information.
    UpdateResearchQuestionRadio(
      props.object,
      () => undefined,
      (error: any) => {
        OpenErrorNotification({ description: error?.message });
      },
    );
  };

  const debounceSubmitFormik = useDebounce(scriptFormik.submitForm);

  useEffect(() => {
    if (scriptFormik.isValid) {
      debounceSubmitFormik();
    }

    return () => {
      debounceSubmitFormik.cancel();
    };
  }, [
    props.object.ResearchQuestionRadioAnswers,
    scriptFormik.isValid,
    scriptFormik.values,
  ]);

  const handleOptionDelete = (id: number) => {
    DeleteResearchQuestionRadioAnswer(
      id,
      () => {
        const Data = props.object?.ResearchQuestionRadioAnswers || [];
        const newList = produce(Data, (draft: any) => {
          return draft.filter((x: any) => x.Id !== id);
        });
        const Payload = {
          ...props.object,
          ResearchQuestionRadioAnswers: newList,
        };
        props.onChange("ResearchQuestionRadio", Payload);
      },
      (error: any) => {
        OpenErrorNotification({
          description: error.message,
        });
      },
    );
  };
  const addOptionToList = (value: string) => {
    const newList = produce(
      props.object?.ResearchQuestionRadioAnswers || [],
      (draft: any) => {
        draft.push({
          Answer: value,
          Id: 0,
          Order: 0,
          ResearchQuestionSelectId: props.object.Id,
        });
      },
    );
    const orderedNewList = newList.map(
      (x: ResearchQuestionRadioAnswer, index: number) => ({
        ...x,
        Order: index,
      }),
    );
    const Payload = {
      ...props.object,
      ResearchQuestionRadioAnswers: orderedNewList,
    };
    UpdateResearchQuestionRadio(
      Payload,
      (data: ResearchQuestionRadio) => {
        updateData(data);
      },
      (error: any) => {
        OpenErrorNotification({ description: error?.message });
      },
    );
  };
  const handleTitleUpdate = (Id: number, value: string) => {
    const Data = props.object?.ResearchQuestionRadioAnswers || [];
    const newList = produce(Data, (draft: any) => {
      const index = draft.findIndex((x: any) => x.Id == Id);
      if (index !== -1) {
        draft[index].Answer = value;
      }
    });
    const Payload = { ...props.object, ResearchQuestionRadioAnswers: newList };
    updateData(Payload);
  };
  const handleOrderUpdate = (
    researchQuestionRadioAnswers: ResearchQuestionRadioAnswer[],
  ) => {
    const Payload = {
      ...props.object,
      ResearchQuestionRadioAnswers: researchQuestionRadioAnswers,
    };
    updateData(Payload);
  };
  return (
    <>
      <div className="mb-5">
        <RadioWithTwoOption
          disabled={props.disabled}
          DBField="ResearchQuestionRadioType"
          onChange={handleChange}
          selectedOption={scriptFormik.values["ResearchQuestionRadioType"]}
          Options={[
            {
              text: "Vertical",
              value: ResearchQuestionRadioType.Vertical,
            },
            {
              text: "Horizontal",
              value: ResearchQuestionRadioType.Horizontal,
            },
          ]}
        />
      </div>
      <div className="mb-8">
        {props.disabled ? null : (
          <AddItemToListInput
            addOptionToList={addOptionToList}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            lastFocused={lastFocused}
            setLastFocused={setLastFocused}
          />
        )}
        <ListItems
          list={props.object?.ResearchQuestionRadioAnswers || []}
          handleOptionDelete={handleOptionDelete}
          handleOrderUpdate={handleOrderUpdate}
          handleTitleUpdate={handleTitleUpdate}
          disabled={props.disabled}
        />
      </div>
    </>
  );
}
