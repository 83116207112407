import styled from "styled-components";
import Panel from "./Panel";
import Drawer from "../../components/Drawer/Drawer";
import { colorPalette } from "../../styles/MainList.styled";

export const StyledDrawer = styled(Drawer)`
   {
    & .script-detailed-info {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      padding: 5px;
      > div {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        height: auto;
        justify-content: space-between;
        > h5,
        .author-review-action h5 {
          color: ${colorPalette.primary100};
          font-size: 1rem;
          width: 120px;
          height: auto;
          letter-spacing: 0.3px;
          display: flex;
          align-items: center;
          text-align: center;
        }
        > div {
          width: 400px;
          padding: 2px 0px;
        }
      }

      & .rating-stars {
        display: flex;
        .rating {
          font-size: 14px;
          margin-top: 0;
          margin-left: 6px;
          height: 16px;
          text-align: center;
        }
      }

      & .author {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        > div {
          display: flex;
          width: 150px;
          height: 35px;
          .avatar-icon,
          .fork-icon {
            width: 30px;
            height: 30px;
            min-height: 30px;
            min-width: 30px;
            vertical-align: middle;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 1.125rem;
          }
          p {
            font-size: 18px;
            height: 30px;
            a {
              color: ${colorPalette.primary100};
            }
            & span {
                margin-left: 13px;
            }
          }
        }
      }
      & .authors-info {
        .avatar-icon {
          border: 1px solid #fff;
          border-radius: 50%;
        }
        p {
          margin-left: 10px;
        }
      }

      & .tag-shock {
        background-color: #ff9901;
      }
      & .tag-internal {
        background-color: #0139ff;
      }
      & .tag-haemorrhage {
        background-color: #cf0000;
      }
     
      & .scenario-comments {
        display: flex;
        flex-direction: column;
        .comments {
          width: 530px;
          height: 370px;
          position: relative;
          & .comment-div {
            min-height: 90px;
            display: flex;
            .comment-author-avatar {
              width: 37px;
              height: 37px;
              border-radius: 20px;
              border: 2px solid #bfbfbf;
            }
            .comment-info {
              margin-left: 10px;
              & p {
                .comment-date {
                  font-size: 10px;
                  margin-left: 8px;
                }
              }
              & .comment {
                width: 470px;
                line-height: 21.09px;
                max-height: 103px;
                overflow: hidden;
                & p {
                  font-size: 16px;
                }
              }
            } 
          }
        }
      }
    }
  }
`;

export const CommentFieldWrapper = styled.div`
{
    & .comment-field {
        position: absolute;
        display: flex;
        width: 550px;
        height: 115px;
        right: 3px;
        padding: 5px 0px;
        margin-top: 8px;
        border-top: 1px solid rgb(255 255 255/0.5);
        flex-direction: row;
        > .ellipse {
            width: 37px;
            height: 37px;
            border: 2px solid #bfbfbf;
            border-radius: 20px;
        }
        & form {
            margin-left: 10px;
            margin-top: 3px;
            height: 98px;
            border-radius: 5px;
            border: 1px solid rgb(255 255 255/0.5);
            textarea {
            width: 500px;
            height: 62px;
            line-height: 21.09px;
            font-size: 16px;
            border: none;
            }
        }
        & .post-comment-icons {
            position: absolute;
            top: 77px;
            left: 60px;
            width: 480px;
            height: 26px;
        }
     }
}`;

export const HeaderWrapper = styled.div`
{
    &.header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      .script-title {
        width: 430px;
        height: 47px;
        display: flex;
        left: 35px;
        align-items: center;
        position: absolute;
      }
      & .header-icons {
        width: 80px;
        height: 45px;
        clear: both;
        display: flex;
        justify-content: space-between;
        font-size: 1.125rem;
        position: absolute;
        right: 0;
        align-items: center;
        .like-icon {
          left: 2.8rem;
        }
        .edit-icon,
        .like-icon {
          width: 35px;
          height: 35px;
          justify-content: center;
          align-items: center;
          display: flex;
          cursor: pointer;
          position: absolute;
        }
      }
    }
}`;

export const AuthorReviewWrapper = styled.div`
{
     &.script-author-reviewers {
        display: flex;
        flex-direction: row;
        .author-review-action {
          width: 120px;
          .author-review-btn {
            width: 116px;
            padding: 4px 2px;
          }
        }
        .reviewed-by {
          display: flex;
          flex-direction: column;
          gap: 3px;

          .author-review-nameplate {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 400px;
            height: 72px;
            border: 0.5px dashed rgb(255 255 255/ 0.3);
            .author-nameplate {
              margin-left: 10px;
              display: flex;
              align-items: center;

              & .author-nameplate-avatar {
                width: 50px;
                height: 50px;
                border-radius: 50px;
                border: 1px solid #fff;
                background-color: rgb(255 255 255/0.1);
              }

              & .author-nameplate-info {
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                height: 62px;
                width: 220px;
                position: relative;
                p {
                  width: 214px;
                  height: 25px;
                  font-size: 16px;
                  position: absolute;
                  left: 2px;
                  .newtab-icon {
                    position: absolute;
                    left: 170px;
                  }
                }
                & .lastname-firstname {
                  top: 6px;
                }
                & .title-organization {
                  top: 35px;
                }
              }

            }
          }
        }
      }
}`;
