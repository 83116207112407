import React, { useEffect, useState } from "react";
import { Form, Row, Tooltip } from "antd";
import FloatingLabelInput from "../../../components/FloatingLabel/FloatingLabelInput";
import { BlueButton } from "../../../styles/Buttons.styled";
import { ResetPasswordContainer } from "./ResetPassword.styled";
import { useFormik } from "formik";
import {
  EyeOutlinedIcons,
  EyeInvisibleOutlinedIcons,
} from "../../../IconsTheme/Icons.global";
import { FormikErrorType } from "../../../models/common/FormikErrorType";
import { useHistory } from "react-router";
import qs from "qs";
import { OpenErrorNotification } from "../../../components/Notification/Notification";
import AlertBar from "../../../app/AlertBar";
import AuthenticationPageLeftPane from "../../../components/CenterScreenCard/AuthenticationPageLeftPane";
import { resetPassword } from "../../../api/Auth";

const ResetPasswordForm = () => {
  const history = useHistory();

  const focusedStates = {
    password: false,
    username: false,
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);

  const formik = useFormik({
    initialValues: {
      email: "",
      token: "",
      password: "",
      confirm_password: "",
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      const passwordRegex = new RegExp(
        `^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$_!%*#?&])[A-Za-z\\d@$_!%*#?&]{8,}$`,
      );
      const alphabetRegex = new RegExp(`[a-zA-Z]`);
      const numberRegex = new RegExp(`\\d`);
      const specialCharRegex = new RegExp(`[@$_!%*#?&]`);
      if (!values.email?.trim()) {
        errors["password"] = "Required";
      }
      if (!values.token?.trim()) {
        errors["token"] = "Required";
      }
      if (!values.password.trim()) {
        errors["password"] = "Required";
      } else if (values.password.trim().length < 8) {
        errors["password"] = "Min. 8 Characters Required";
      } else {
        const isAlphabetAvail = alphabetRegex.test(values.password.trim());
        const isNumberAvail = numberRegex.test(values.password.trim());
        const isSpecialCharAvail = specialCharRegex.test(
          values.password.trim(),
        );
        if (!isAlphabetAvail && !isNumberAvail) {
          errors["password"] = "Min. one alphabet and number required";
        } else if (!isAlphabetAvail && !isSpecialCharAvail) {
          errors["password"] = "Min. one alphabet and special char. required";
        } else if (!isNumberAvail && !isSpecialCharAvail) {
          errors["password"] = "Min. one number and special char. required";
        } else if (!alphabetRegex.test(values.password.trim())) {
          errors["password"] = "Min. One Alphabet Required";
        } else if (!numberRegex.test(values.password.trim())) {
          errors["password"] = "Min. One Number Required";
        } else if (!specialCharRegex.test(values.password.trim())) {
          errors["password"] = "Min. One Special Character Required";
        } else if (!passwordRegex.test(values.password.trim())) {
          errors["password"] = "Please enter strong password";
        }
        if (!values.confirm_password.trim()) {
          errors["confirm_password"] = "Required";
        } else if (values.confirm_password !== values.password) {
          errors["confirm_password"] =
            "Password and Confirm password do not match";
        }
      }
      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      setLoading(true);

      const formData = {
        Email: values.email,
        Password: values.password,
        Token: values.token,
      };

      resetPassword(
        formData,
        () => {
          setLoading(false);
          // redirect to ResetPasswordSuccess.tsx
          history.replace("success");
        },
        (error: any) => {
          // Error
          setLoading(false);
          OpenErrorNotification({ description: error.ErrorMessage });
        },
      );
    },
  });

  useEffect(() => {
    // get query params and decode it using atob.(query params are encoded using base64).
    const queryString = atob(location.search.replace("?", ""));
    const params = qs.parse(queryString, { strictNullHandling: true });

    formik.setFieldValue("email", params["e"]);
    formik.setFieldValue("token", params["c"]?.toString());
  }, [location.search]);

  const displayPasswordIcon = () => {
    return showPassword ? (
      <Tooltip title="Hide Password">
        <EyeOutlinedIcons
          className="visibleIcon px-2"
          onClick={() => setShowPassword(false)}
        />
      </Tooltip>
    ) : (
      <Tooltip title="Show Password">
        <EyeInvisibleOutlinedIcons
          className="px-2"
          onClick={() => setShowPassword(true)}
        />
      </Tooltip>
    );
  };

  const displayConfirmPasswordIcon = () => {
    return showConfirmPassword ? (
      <Tooltip title="Hide Password">
        <EyeOutlinedIcons
          className="visibleIcon px-2"
          onClick={() => setShowConfirmPassword(false)}
        />
      </Tooltip>
    ) : (
      <Tooltip title="Show Password">
        <EyeInvisibleOutlinedIcons
          className="px-2"
          onClick={() => setShowConfirmPassword(true)}
        />
      </Tooltip>
    );
  };

  return (
    <AuthenticationPageLeftPane displayLoginLink title={"Reset Password"}>
      <ResetPasswordContainer>
        <AlertBar />
        <Form onFinish={formik.handleSubmit}>
          <div className="reset-password-form">
            <FloatingLabelInput
              label={"New Password"}
              nameForInput={"password"}
              type={showPassword ? "text" : "password"}
              data-testid="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              scriptFormik={formik}
              lastFocused={lastFocused}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
              isAddNeeded={true}
              suffixIcon={displayPasswordIcon()}
              lastPassIgnore={false}
            />
            <FloatingLabelInput
              label={"Confirm New Password"}
              nameForInput={"confirm_password"}
              type={showConfirmPassword ? "text" : "password"}
              data-testid="confirm_password"
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              scriptFormik={formik}
              lastFocused={lastFocused}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
              isAddNeeded={true}
              suffixIcon={displayConfirmPasswordIcon()}
              lastPassIgnore={false}
            />
          </div>

          <Row className="submit">
            <Form.Item>
              {loading ? (
                <BlueButton
                  rounded="true"
                  type="primary"
                  className="l2l-btn login-btn"
                  loading
                >
                  Loading
                </BlueButton>
              ) : (
                <BlueButton
                  rounded="true"
                  data-testid="submit-button"
                  htmlType="submit"
                  className="l2l-btn login-btn"
                >
                  Submit
                </BlueButton>
              )}
              <br />
            </Form.Item>
          </Row>
        </Form>
      </ResetPasswordContainer>
    </AuthenticationPageLeftPane>
  );
};

export default ResetPasswordForm;
