import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Tooltip } from "antd";
import React from "react";
import {
  InsultWithRelation,
  NumberParameter,
} from "../../../../../../../models/InsultInterface";
import { StyledFormRow, WhiteButton } from "../../InjuryTab.styled";
import FloatingLabelInput from "../../../../../../../components/FloatingLabel/FloatingLabelInput";

type TimeNumberParameterFormProps = {
  formik: any;
  insult: InsultWithRelation;
  param: NumberParameter;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
};

const TimeNumberParameterForm = ({
  formik,
  insult,
  param,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
}: TimeNumberParameterFormProps) => {
  return (
    <StyledFormRow
      align="middle"
      key={`time_number_param_${insult.Label}_${param.UserFacingLabel}`}
    >
      <Col
        span={10}
        sm={24}
        md={11}
        lg={10}
        key={"time_interval_minute_" + param.Id}
        style={{ padding: "5px" }}
      >
        <FloatingLabelInput
          label={param.UserFacingLabel}
          nameForInput={"time_interval_minute_" + param.Id}
          value={formik.values["time_interval_minute_" + param.Id]}
          onChange={() => undefined}
          scriptFormik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          isAddNeeded={true}
          step={1}
          precision={0}
          isNumber
          maxValue={99}
          minValue={0}
          maxLength={2}
          suffix={"min"}
          onSave={formik.submitForm}
        />
      </Col>
      <Col
        span={10}
        sm={24}
        md={11}
        lg={10}
        key={"time_interval_second_" + param.Id}
        style={{ padding: "5px" }}
      >
        <FloatingLabelInput
          label={""}
          nameForInput={"time_interval_second_" + param.Id}
          value={formik.values["time_interval_second_" + param.Id]}
          onChange={() => undefined}
          scriptFormik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          isAddNeeded={true}
          step={1}
          precision={0}
          isNumber
          maxValue={60}
          minValue={0}
          maxLength={2}
          suffix={"sec"}
          onSave={formik.submitForm}
        />
      </Col>
      {param.Description ? (
        <Col flex={1} style={{ paddingLeft: 5 }}>
          <Tooltip title={param.Description}>
            <WhiteButton
              type="link"
              icon={<InfoCircleOutlined className="icon-lg" />}
              size="large"
            />
          </Tooltip>
        </Col>
      ) : null}
    </StyledFormRow>
  );
};

export default TimeNumberParameterForm;
