export const toggleTreatmentOption = (toggleBoolean: {}) => {
  return (dispatch: any) => {
    dispatch({
      type: "option",
      payload: toggleBoolean,
    });
  };
};

export const updateInterval = (interval: number) => {
  return (dispatch: any) => {
    dispatch({
      type: "interval",
      payload: interval,
    });
  };
};

export const updateSubtaskForm = (task: string) => {
  return (dispatch: any) => {
    dispatch({
      type: "subtask",
      payload: task,
    });
  };
};

export const updateDescription = (description: string) => {
  return (dispatch: any) => {
    dispatch({
      type: "description",
      payload: description,
    });
  };
};

export const updateAdditionalNote = (additionalNote: string) => {
  return (dispatch: any) => {
    dispatch({
      type: "additionalNote",
      payload: additionalNote,
    });
  };
};

export const updateSelectedPhase = (selectedPhase: string) => {
  return (dispatch: any) => {
    dispatch({
      type: "selectedPhase",
      payload: selectedPhase,
    });
  };
};
export const updateUploadedMedia = (newUploadedMedia: any[]) => {
  return (dispatch: any) => {
    dispatch({
      type: "uploadedMedia",
      payload: newUploadedMedia,
    });
  };
};

export const SetIsFocused = (isFocusedOption: {}) => {
  return (dispatch: any) => {
    dispatch({
      type: "isFocused",
      payload: isFocusedOption,
    });
  };
};

export const SetLastFocused = (lastFocusedOption: {}) => {
  return (dispatch: any) => {
    dispatch({
      type: "option",
      payload: lastFocusedOption,
    });
  };
};
