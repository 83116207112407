import styled from "styled-components";
import FloatingLabelSelect from "../../../../../components/MuiSelect/FloatingLabelSelect";
import { FormContainer } from "../../EditForms/RolePlayerForm/PromptForm.styled";

export const StyledFormContainer = styled(FormContainer)`
   {
    .ant-btn-link {
      min-width: 40px;
      font-size: 1.125rem;
      .anticon {
        font-size: 1.25rem;
        min-width: 20px;
      }
      &.active {
        color: #fff;
      }
      span:nth-child(2) {
        margin-left: 0.7rem !important;
      }
    }
  }
`;

export const StyledFloatingLabelSelect = styled(FloatingLabelSelect)`
   {
    span.ant-select-selection-item .Abbreviation {
      display: none;
    }
    span.ant-select-selection-item span:last-child {
      padding-left: 0rem;
    }
  }
`;

export const OptionWrapper = styled.div`
   {
    display: flex;
    height: 40px;
    border-bottom: 1px solid #161616;
    span.Abbreviation {
      width: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #161616;
    }
    span:last-child {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding-left: 1rem;
    }
  }
`;

export const LevelOfConsciousnessWrapper = styled.div.attrs((props: any) => ({
  isValue: props.isValue,
}))`
  align-items: ${(props) => (props.isValue ? `center` : `end`)};
  margin-bottom: 2rem;
  display: flex;
`;

export const GlassGowComaScaleWrapper = styled.div`
   {
    margin-bottom: 2rem;
    .scriptBuilderLayout{
      padding-left: 2rem;
      display: grid;
      grid-template-columns: 10vw 70vw 20vw;
      .ant-input-disabled {
        color: rgba(255, 255, 255, 0.5) !important;
      }

      @media (max-width: 1630px) {
        .scriptBuilderLayout{
          grid-template-columns: 250px auto minmax(450, 650);
        }
      }
    }
  }
`;

export const StyledTableWrapper = styled.div`
   {
    padding: 0.5rem 0 0.5rem 2.5rem;

    .item {
      display: flex;
      margin: 0.1rem 0;

      & > div {
        background: #1e1e1e;
        padding: 0.5rem 0.8rem;
        border-left: 1px solid #161616;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .abbreviation {
        font-size: 1rem;
        line-height: 19px;
        color: #ffffff;
        min-width: 40px;
        width: 40px;
      }

      .inline-wrapper {
        padding: 0 0.2rem;
      }
      .note {
        width: 100%;
        min-height: 45px;
        justify-content: flex-start;
        margin-left: 0;
      }
      .value {
        min-height: 45px;
        justify-content: flex-start;
        height: inherit;
      }

      .action {
        padding: 0;
      }
      .inline-editing {
        padding: 0px !important;
        width: 100%;
        input.default,
        textarea.default {
          font-size: 14px;
          padding: 0.2rem 0.6rem !important;
          margin: 0;
        }
      }
      &:hover {
        & > div {
          background: #272727;
        }
      }
    }
  }
`;

export const StyledVitalTableWrapper = styled(StyledTableWrapper)`{
  padding: 0.5rem 0 0.5rem 0;
  .item {
    & > div {
      width: 85px;
      min-width: 85px;
      &:first-child{
        background: #161616;
        border: none;
      }
      &.action{
        min-width:40px;
        width:40px;
      }
    }

}`;
