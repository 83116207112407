import { Select } from "antd";
import * as React from "react";
import { useDispatch } from "react-redux";
import FloatingLabelSelect from "../../../../../../components/MuiSelect/FloatingLabelSelect";
import { Allergy } from "../../../../../../models/CasualtyInterfaces";
import { CASUALTY_CREATOR_UPDATE_CASUALTY_FORM } from "../../../../../../store/CasualtyCreator/actionType";
import { FormRow } from "../../styled";
const { Option } = Select;
type Props = {
  formik: any;
  allAllergies: Allergy[];
  disabled: boolean;
};

const AllergyInput = ({ formik, allAllergies, disabled }: Props) => {
  const dispatch = useDispatch();
  const handleAllergyChange = (values: number[]) => {
    if (disabled) return; //disable action callback in read only script
    formik.setFieldValue("allergies", values);
    dispatch({
      type: CASUALTY_CREATOR_UPDATE_CASUALTY_FORM,
      field: "AllergyIds",
      value: values,
    });
  };
  return (
    <FormRow>
      <FloatingLabelSelect
        mode="multiple"
        label="Allergies"
        className="w-100"
        onChange={handleAllergyChange}
        value={formik.values["allergies"]}
        filterOption={(input, option) => {
          console.log(
            option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
          );
          return option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        disabled={disabled}
      >
        {allAllergies.map((allergy) => {
          return (
            <Option value={allergy.Id} key={allergy.Id} title={allergy.Label}>
              {allergy.Label}
            </Option>
          );
        })}
      </FloatingLabelSelect>
    </FormRow>
  );
};

export default AllergyInput;
