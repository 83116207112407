import React from "react";
import { CasualtyStatus } from "../../../../../models/ScriptInterfaces";
import { StyledTableView } from "./CenterPane.styled";

interface Props {
  BlockContent: CasualtyStatus;
}

type MarchTableObjType = {
  label: string;
  field: string;
};

const MarchTableObj: MarchTableObjType[] = [
  {
    label: "M",
    field: "MassiveHemorrhageDescription",
  },
  {
    label: "A",
    field: "AirwayDescription",
  },
  {
    label: "R",
    field: "RespirationDescription",
  },
  {
    label: "C",
    field: "CirculationDescription",
  },
  {
    label: "H",
    field: "HypothermiaDescription",
  },
];
export default function MarchTable(props: Props) {
  return (
    <StyledTableView>
      <div className="item heading">
        <div></div>
        <div className="notes">STATUS</div>
      </div>
      {MarchTableObj.map((option: MarchTableObjType) => {
        return (
          <div className="item" key={option.field}>
            <div>{option.label}</div>
            <div className="notes word-wrap">
              {props.BlockContent[option.field] || ""}
            </div>
          </div>
        );
      })}
    </StyledTableView>
  );
}
