import FloatingLabelTextArea from "../FloatingLabel/FloatingLabelTextArea";
import * as React from "react";
import { FormikProps } from "formik";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  SetDescription,
  SetIsFocused,
  SetLastFocused,
  SetTaskName,
} from "../../pages/ScriptBuilder/EditComponents/Miscellaneous/MiscellaneousSlice";
import { MiscellaneousTaskBlock } from "../../models/MiscellaneousTaskBlock";

interface AdditionalInfoProps {
  scriptFormik: FormikProps<any>;
  onChange: Function;
  object: MiscellaneousTaskBlock;
  isReadOnly: boolean;
}

export const MiscBlockInputFields = (props: AdditionalInfoProps) => {
  const dispatch = useAppDispatch();

  const state = useAppSelector((state: any) => state.MiscReducer);
  const handleChange = (type: string, value: string) => {
    if (type === "Description") {
      props.onChange("TimedActionTaskContent.Description", value);
      dispatch(SetDescription(value));
    } else if (type === "Name") {
      props.onChange("TimedActionTaskContent.Name", value);
      dispatch(SetTaskName(value));
    }
  };

  return (
    <div className="additionalItemsContainer">
      <FloatingLabelTextArea
        label={"Description"}
        textareaName={"description"}
        value={props.scriptFormik.values["description"]}
        onSave={() => undefined}
        onChange={(e: any) => handleChange("Description", e.target.value)}
        scriptFormik={props.scriptFormik}
        lastFocused={state.lastFocused}
        setIsFocused={(obj: {}) => dispatch(SetIsFocused(obj))}
        setLastFocused={(obj: {}) => dispatch(SetLastFocused(obj))}
        style={{ marginBottom: "1rem" }}
        isFocused={state.isFocused}
        readOnly={props.isReadOnly}
      />
    </div>
  );
};
