import * as React from "react";
import { Component } from "react";
import MenuList from "./MenuList";
import {
  CompanyOwner,
  CompanyAdmin,
  CompanyManager,
  User,
} from "./MenuRoutes/RoleMenus";
import {
  COMPANY_OWNER,
  COMPANY_ADMIN,
  COMPANY_MANAGER,
  COMPANY_MANAGER_LOGIN,
  DEFAULT,
  COMPANY_OWNER_LOGIN,
  COMPANY_ADMIN_LOGIN,
} from "../app/UserMenuTypes";
import "./NavMenu.css";

interface Props {
  logout: any; //function;
  children: any;
  openSupport: Function;
  handleCollapseOpen: Function;
  collapsed: boolean;
}

class SideMenu extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
    };
    this.logout = this.logout.bind(this);
  }

  async componentDidMount() {
    await this.setState({
      loading: false,
    });
  }

  returnRoutes(role: string) {
    switch (role) {
      case COMPANY_OWNER:
        return CompanyOwner;
      case COMPANY_OWNER_LOGIN:
        return CompanyOwner;
      case COMPANY_ADMIN:
        return CompanyAdmin;
      case COMPANY_ADMIN_LOGIN:
        return CompanyAdmin;
      case COMPANY_MANAGER:
        return CompanyManager;
      case COMPANY_MANAGER_LOGIN:
        return CompanyManager;
      case DEFAULT:
        return User;
      default:
        return [];
    }
  }

  async logout(e: any) {
    this.props.logout();
  }

  render() {
    return (
      <div className={"menu-list-container"}>
        <MenuList
          openSupport={this.props.openSupport}
          links={this.returnRoutes(COMPANY_OWNER)}
          logout={this.logout}
          handleCollapseOpen={this.props.handleCollapseOpen}
          collapsed={this.props.collapsed}
        />
      </div>
    );
  }
}

export default SideMenu;
