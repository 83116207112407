import React from "react";
import { StyledVitalTableWrapper } from "../RightEditPanel.styled";
import { WhiteIconButton } from "../../../../../../styles/Buttons.styled";
import {
  EyeInvisibleOutlinedIcons,
  EyeOutlinedIcons,
} from "../../../../../../IconsTheme/Icons.global";
import { Vital } from "../../../../../../models/ScriptInterfaces";
import ItemRow from "./ItemRow";
import { useSelector } from "react-redux";

interface Props {
  vitals: Vital[];
  visible: boolean;
  onChange: Function;
}
export default function VitalsTableEdit(props: Props) {
  const { isReadOnly } = useSelector((state: any) => state.MainlistReducer);
  const onChange = (obj: Vital) => {
    if (isReadOnly) return; //disable action callback in read only script
    const newVitals = JSON.parse(JSON.stringify(props.vitals));
    const index = newVitals.findIndex((i: Vital) => i.Id === obj.Id);
    if (index !== -1) {
      newVitals[index] = obj;
      props.onChange("Vitals", newVitals);
    }
  };
  const toggleVitalTable = () => {
    if (isReadOnly) return; //disable action callback in read only script
    props.onChange("IsVitalDisplayed", !props.visible);
  };
  return (
    <div className="mb-8">
      <WhiteIconButton
        type="link"
        icon={
          props.visible ? (
            <EyeOutlinedIcons className="visibleIcon" />
          ) : (
            <EyeInvisibleOutlinedIcons />
          )
        }
        size="middle"
        className={props.visible ? "active" : ""}
        onClick={toggleVitalTable}
      >
        Vital Table Notation
      </WhiteIconButton>

      {props.visible ? (
        <StyledVitalTableWrapper>
          {props.vitals.map((vital: Vital) => {
            return (
              <ItemRow
                key={vital.Id}
                vital={vital}
                onChange={onChange}
                isReadOnly={isReadOnly}
              />
            );
          })}
        </StyledVitalTableWrapper>
      ) : null}
    </div>
  );
}
