import {
  CloseOutlined,
  DashboardOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { Button, Row, Table, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import { RiRefreshLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { SearchFilter } from "../../components";
import CustomTable from "../../components/Table/CustomTable";
import { StyledRow } from "../../components/Table/CustomTable.styled";
import { WhiteButton } from "../ScriptBuilder/EditComponents/ScriptDetailsView/InjuryTab/InjuryTab.styled";
import Dropmenu from "../ViewScripts/Dropmenu";
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import { useAppSelector } from "../../store/hooks";
import User from "../../models/User";
import { isNullOrUndefined } from "is-what";
import { getAuthorName } from "../../utils/utilityfunctions";

interface PropTypes {
  columns: any[];
  data: any[];
}

const TableRowExpanderIcon = ({
  expanded,
  record,
  expandable,
  onExpand,
}: any) => {
  if (!expandable) {
    return null;
  }
  const ArrowIcon = expanded ? GoChevronDown : GoChevronRight;
  return (
    <Button
      disabled={!expandable}
      type="link"
      icon={<ArrowIcon size={24} color="" />}
      onClick={() => {
        onExpand(record);
      }}
    />
  );
};

const SessionScriptsTable = styled(Table)`
  & .ant-table {
    padding: 0;
  }

  & .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 4px 16px;
  }
`;

export default function SessionTableFilter({ columns, data }: PropTypes) {
  const history = useHistory();
  // filtered authors
  const [instructors, setInstructors] = useState<User[]>([]);
  // Search input
  const [searchInput, setSearchInput] = useState("");
  // Instructor filter
  const [instructorParams, setInstructorParams] = useState<any[]>([]);
  // Breadcrumb string
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
  const LoggedInUser = useAppSelector((state) => state.authReducer.user);
  const { sessionTableFeature } = useSelector(
    (state: any) => state.FeatureFlagReducer,
  );

  // Update instructors list based on data
  useEffect(() => {
    if (!data) return;

    // Create a copy of instructors array
    const instructorsCopy = [...instructors];

    // Iterate over each sessionAARs in data
    data.forEach((sessionAARs) => {
      if (!isNullOrUndefined(sessionAARs.CreatedBy)) {
        // Check if instructor already exists in instructorsCopy
        const existingInstructor = instructorsCopy.find(
          (instructor) => instructor.Id === sessionAARs.CreatedBy?.Id,
        );
        if (isNullOrUndefined(existingInstructor)) {
          // Add instructor to instructorsCopy if not already present
          instructorsCopy.push(sessionAARs.CreatedBy);
        }
      }
    });

    // Sort instructorsCopy by LastName
    instructorsCopy?.sort((a: User, b: User) =>
      a.LastName?.localeCompare(b.LastName),
    );

    // Update state with updated instructorsCopy
    setInstructors(instructorsCopy);

    // Filter logged in instructor from instructors list
    const instructor = instructors.filter(
      (instructor) => instructor.Id === LoggedInUser.Id,
    );
    if (instructor && instructor.length) {
      // Set instructorParams state with filtered instructor
      setInstructorParams(instructor);
    }
  }, [data]);

  useEffect(() => {
    const breadcrumb = [];
    if (instructorParams.length) breadcrumb.push("Instructor");
    setBreadcrumbs(breadcrumb);
  }, [instructorParams]);

  const clearBreadcrumb = useCallback(
    (index: number) => {
      switch (breadcrumbs[index]) {
        case "Instructor":
          setInstructorParams([]);
          break;
        default:
      }
    },
    [breadcrumbs],
  );

  const clearAll = useCallback(() => {
    setSearchInput("");
    setInstructorParams([]);
  }, []);

  const handleSearch = (text?: string) => {
    // don't display script till instructor data available
    if (!instructors?.length) {
      return [];
    }
    // Guard clause for filters
    if (!text && !instructorParams.length) return data;

    const filterByInstructor = data.filter((entry) =>
      instructorParams.length
        ? instructorParams
            .map((instructorParam) => getAuthorName(instructorParam))
            .includes(getAuthorName(entry.CreatedBy))
        : data,
    );

    function hasWord(target: string, query: string) {
      if (!target) return false;
      return target.toLowerCase().indexOf(query.toLowerCase()) > -1;
    }

    // Search guard clause
    if (!text) return filterByInstructor;
    return filterByInstructor.filter((result: any) => {
      if (
        hasWord(result.Title, text.trim()) ||
        hasWord(getAuthorName(result.CreatedBy), text.trim())
      )
        return true;
      return false;
    });
  };

  const expandedRowRender = (data: any) => {
    return (
      <SessionScriptsTable
        loading={false}
        size="small"
        showHeader={false}
        columns={[
          {
            dataIndex: "Title",
            render(value, record, index) {
              return <span style={{ paddingLeft: 64 }}>{value}</span>;
            },
          },
          {
            dataIndex: "Id",
            align: "left",
            width: 250,
            render(value, record, index) {
              return (
                <Tooltip title="View AAR">
                  <Button
                    type="link"
                    icon={<DashboardOutlined color="" />}
                    onClick={() => {
                      history.push(`after-action-review/${value}`);
                    }}
                  />
                </Tooltip>
              );
            },
          },
        ]}
        dataSource={data.ScenarioAAR?.ScriptAARs}
      />
    );
  };

  return (
    <>
      <StyledRow justify="space-between">
        <Row className="btn-row">
          {sessionTableFeature.tableHeader.filter && (
            <Tooltip title={`Filter`}>
              <WhiteButton
                type="link"
                icon={<FilterOutlined className="icon-lg" />}
                size="large"
              />
            </Tooltip>
          )}
          <SearchFilter
            className="searchbar"
            handleSearch={(value: string) => setSearchInput(value)}
          />
          <Dropmenu
            label="Author"
            params={instructorParams}
            contents={instructors}
            disabled={false}
            contentNames={instructors.map((instructor: User) =>
              getAuthorName(instructor),
            )}
            setParams={setInstructorParams}
          />
          {sessionTableFeature.tableHeader.clearAll && (
            <Tooltip placement="bottom" title="Clear All Filters">
              <button
                data-testid="clear-all"
                className="clear-all"
                style={{ cursor: "pointer" }}
                onClick={clearAll}
              >
                <RiRefreshLine />
              </button>
            </Tooltip>
          )}

          <div data-testid="view-script-filter" className="breadcrumb-row">
            {breadcrumbs.map((value: string, index: number) => (
              <span
                className="breadcrumb-filter onHover-white-text onHover-red-icon"
                key={index}
                onClick={() => clearBreadcrumb(index)}
              >
                {value} <CloseOutlined />
              </span>
            ))}
          </div>
        </Row>
      </StyledRow>
      <CustomTable
        size="large"
        columns={columns}
        data={handleSearch(searchInput)}
        expandedRowRender={expandedRowRender}
        expandable={{
          expandIconColumnIndex: 0,
          expandIcon: TableRowExpanderIcon,
        }}
      />
    </>
  );
}
