import { useFormik } from "formik";
import React, { useState } from "react";
import FloatingLabelInput from "../FloatingLabel/FloatingLabelInput";
import { StyledWrapper } from "./InlineEditTextArea.styled";
interface Props {
  uniqueId: string;
  onChange: any;
  value: string;
  valueToDisplay: string;
  maxLength?: number;
  wrapperClassName?: string;
  isReadOnly?: boolean;
}
export default function InlineEditTextInput(props: Props) {
  const {
    uniqueId,
    onChange,
    value,
    valueToDisplay,
    wrapperClassName,
    ...inputProps
  } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const focusedStates = {
    [props.uniqueId]: false,
  };
  const [isFocused, setIsFocused] = useState(focusedStates);
  const [lastFocused, setLastFocused] = useState(focusedStates);

  const scriptFormik = useFormik({
    initialValues: {
      [props.uniqueId]: props.value || "",
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const editValue = () => {
    setIsEditing(true);
    setTimeout(() => {
      document.getElementById(`inline-editing-${[props.uniqueId]}`)?.focus();
    }, 100);
  };
  const onClickInlineEdit = () => {
    !props.isReadOnly && !isEditing && editValue();
  };
  const handleChange = (e: any) => {
    props.onChange(props.uniqueId, e.target.value);
    scriptFormik.setFieldValue(props.uniqueId, e.target.value);
  };
  return (
    <StyledWrapper
      className={
        isEditing ? `inline-wrapper ${wrapperClassName}` : `${wrapperClassName}`
      }
      onClick={onClickInlineEdit}
    >
      {isEditing ? (
        <FloatingLabelInput
          classNameForContainer="inline-editing pt-0"
          label={""}
          nameForInput={`inline-editing-${[props.uniqueId]}`}
          value={scriptFormik.values[props.uniqueId]}
          onSave={scriptFormik.handleBlur}
          onChange={handleChange}
          scriptFormik={scriptFormik}
          isAddNeeded={false}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          lastFocused={lastFocused}
          isFocused={isFocused}
          onBlur={() => setIsEditing(false)}
          {...inputProps}
        />
      ) : (
        props.valueToDisplay || ""
      )}
    </StyledWrapper>
  );
}
