import { setAuthToken, setCompanyHeader } from "../utils/setAuthToken";
import { clearSessionStorage } from "../utils/sessionStorageUtility";
import { reset } from "../store/authSlice";

export function logout() {
  clearSessionStorage(); // clear sessionStorage
  setAuthToken("");
  setCompanyHeader("");
  return async (dispatch: any) => {
    dispatch(reset());
  };
}

export function login(token: string) {
  return async (dispatch: any) => {
    sessionStorage.setItem("token", token);
    setAuthToken(token);
  };
}
