import styled from "styled-components";

export const StyledCasualtyStatusCenterBlock = styled.div`
   {
    width: 100%;
    padding: 10px 30px 10px 8px;
    .casualtyStatusBlockContent {
      display: flex;
      justify-content: flex-start;
      color: #fea40a;
      span {
        display: flex;
        align-items: center;
      }
      div {
        display: flex;
        margin-left: 10px;
      }
    }

    .mainBlockContent {
      margin: 10px 0 10px 20px;
      display: flex;
      p {
        width: 93%;
      }
      .casualtyStatusSideBannerParentDiv {
        margin-right: 10px;

        .casualtyStatusSideBanner {
          width: 5.06px;
          height: 100%;
          background: #fea40a;
          border: none;
        }
      }
    }
  }
`;

export const StyledCasualtyStatusDetails = styled.div`
   {
    width: 100%;
    & > div {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .description {
      word-break: break-word;
      font-weight: 400;
      white-space: pre-line;
    }
    .consciousness,
    .coma-scale {
      margin-bottom: 1rem;
      ul {
        list-style: none;
        display: inline-flex;
        margin: 0;
        & > li.options {
          width: 30px;
          height: 30px;
          background: #272727;
          margin: 0.2rem;
          text-align: center;
          border-radius: 50px;
          &.selected {
            background: rgba(254, 164, 10, 0.1);
            border: 1px solid #fea40a;
            color: #fea40a;
            font-weight: 700;
          }
        }
        & > li.label,
        & > li.value {
          background: #272727;
          margin: 0 0.2rem;
          width: 170px;
          text-align: center;
          font-size: 0.875rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 1200px) {
            width: 150px;
          }
        }
      }
    }

    .coma-scale {
      ul > li.label {
        margin-right: 1px !important;
      }
      ul > li.value {
        margin-left: 0px !important;
        color: #fea40a;
        font-weight: 700;
        font-size: 1.125rem;
        width: 85px;

        @media (max-width: 1200px) {
          width: clamp(2.42vw, 4.15vw, 4.88vw)
        }
      }
      li.options::first-letter {
        font-size: 0.8rem;
      }
    }
  }
`;

export const StyledTableView = styled.div`
   {
    margin-bottom: 1rem;
    & > .item {
      display: flex;
      &.heading {
        & > div {
          border-bottom: 1px solid #fea40a;
          font-size: 0.9rem;
          &.notes {
            padding: 0.5rem 0.8rem;
          }
        }
      }
      & > div {
        width: 85px;
        min-width: 85px;
        background: #1e1e1e;
        padding: 0.5rem 0.2rem;
        border: 1px solid #161616;
        font-weight: 400;
        font-size: 1rem;
        line-height: 20px;
        color: #ffffff;
        text-align: center;
        display: flex;
        align-item: center;
        justify-content: center;
        &.notes {
          width: 100%;
          text-align: left;
          justify-content: flex-start;
          padding: 0.5rem 0.8rem;
        }
      }
    }
  }
`;
