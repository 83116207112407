import * as React from "react";
import { Input as AntInput } from "antd";
import { InputProps } from "antd/lib/input";
import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";

const StyledAntInput = styled(AntInput)`
  &.ant-input-affix-wrapper,
  &.ant-input {
    box-shadow: none;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid ${colorPalette.gray50};
  }
`;

const Input = (props: InputProps) => {
  return <StyledAntInput {...props} />;
};

export default Input;
