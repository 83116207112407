import React, { useEffect, useState } from "react";
import { Col, Tooltip } from "antd";
import AudioThumbnail from "../../../assets/img/Thumbnails/audio-thumb.png";
import PdfThumbnail from "../../../assets/img/Thumbnails/pdf-thumb.png";
import {
  CloseOutlined,
  FileImageOutlined,
  DownloadOutlined,
  PrinterOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import MediaDetails from "./MediaDetails";
import { Media } from "../../../models/MediaInterface";
import useConfirmDialog from "../../ConfirmDialog/useConfirmDialog";
import { getAllMediaCategory } from "../../../api/Media/ApiGet";
import { OpenErrorNotification } from "../../Notification/Notification";
import {
  ImagePreview,
  MediaUploadWrapper,
  StyledRow,
  TopHeadingBlock,
} from "../../FileUpload/FileUpload.styled";
import { WhiteIconButton } from "../../../styles/Buttons.styled";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

interface Props {
  mediaToEdit: Media;
  setMediaToEdit: Function;
  setEditMediaDisplay: Function;
  deleteMedia: Function;
  onEdit: Function;
}
export default function EditMediaDetails({
  mediaToEdit,
  setMediaToEdit,
  deleteMedia,
  setEditMediaDisplay,
  onEdit,
}: Props) {
  const [mediaCategories, setMediaCategories] = useState<any>([]);
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();
  useEffect(() => {
    getAllMediaCategory(
      (data: any) => {
        setMediaCategories(data);
      },
      (error: any) => {
        OpenErrorNotification({
          description: error.message,
        });
      },
    );
  }, []);

  const onClose = () => {
    setEditMediaDisplay(false);
  };

  const getMediaPreview = (media: any) => {
    const mediaType = media.MediaType?.Name;
    switch (mediaType) {
      case "Image":
        return <ImagePreview src={media.Url} alt={media.Name} />;
      case "Audio":
        return <ImagePreview src={AudioThumbnail} alt={media.Name} />;
      case "Video":
        return <video src={media.Url} controls />;
      case "Document":
        if (isFilePdf(media)) {
          return (
            <embed
              src={media.Url}
              type="application/pdf"
              height="100%"
              width="100%"
            ></embed>
          );
        } else {
          return <ImagePreview src={PdfThumbnail} alt={media.Name} />;
        }
      default:
        return <ImagePreview src={PdfThumbnail} alt={media.Name} />;
    }
  };
  const isFilePdf = (media: any) => {
    if (!media.Url) return false;
    const mediaUrlArray = media.Url.split(".");
    const fileExtension: string = mediaUrlArray[mediaUrlArray.length - 1];
    return fileExtension.toLocaleLowerCase() === "pdf";
  };
  const error = (e: any) => {
    console.log(e);
  };
  const onDelete = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to delete the media and its related data",
      content:
        "Do you want to permanently delete this media from your media library?",
      okText: "Yes",
      onOk: () => {
        deleteMedia(mediaToEdit?.Id || 0);
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: () => {
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };
  return (
    <StyledRow style={{ height: "100%" }}>
      <Col
        xxl={{ span: 17 }}
        xl={{ span: 17 }}
        lg={{ span: 16 }}
        md={{ span: 16 }}
        sm={{ span: 17 }}
      >
        <MediaUploadWrapper
          isPdfFile={isFilePdf(mediaToEdit)}
          style={{ height: "100%" }}
        >
          <TopHeadingBlock style={{ padding: 0 }}>
            <div className="blockNameAndIcon"></div>
            <div className="iconsDiv">
              <Tooltip title={"Download"}>
                <WhiteIconButton
                  type="link"
                  size="large"
                  disabled
                  icon={<DownloadOutlined />}
                />
              </Tooltip>
              <Tooltip title={"Print"}>
                <WhiteIconButton
                  type="link"
                  size="large"
                  disabled
                  icon={<PrinterOutlined />}
                />
              </Tooltip>
              <Tooltip title={"Delete"}>
                <WhiteIconButton
                  type="link"
                  size="large"
                  onClick={onDelete}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </div>
          </TopHeadingBlock>
          <div className={`MediaImageDetail`}>
            {getMediaPreview(mediaToEdit)}
          </div>
        </MediaUploadWrapper>
      </Col>
      <Col
        xxl={{ span: 7 }}
        xl={{ span: 7 }}
        lg={{ span: 8 }}
        md={{ span: 8 }}
        sm={{ span: 7 }}
      >
        <MediaUploadWrapper style={{ height: "100%" }}>
          <TopHeadingBlock>
            <div className="blockNameAndIcon">
              <FileImageOutlined className="icon" />
              <span className="text"> MEDIA DETAILS </span>
            </div>
            <div className="iconsDiv">
              <span className="deleteSpan" onClick={onClose}>
                <button className={"StrippedButton"}>
                  <Tooltip title={"Cancel"} placement="left">
                    <CloseOutlined title="" className="iconCD" />
                  </Tooltip>
                </button>
              </span>
            </div>
          </TopHeadingBlock>
          <MediaDetails
            media={mediaToEdit}
            setMedia={setMediaToEdit}
            mediaCategories={mediaCategories}
          />
        </MediaUploadWrapper>
      </Col>

      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </StyledRow>
  );
}
