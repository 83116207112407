import React, { useState } from "react";
import FileUpload from "./MediaUpload/FileUploadComponent";
import EditMediaDetails from "./EditMedia/EditMediaDetails";
import { DiagnosticTask } from "../../models/DiagnosticTask";
import { Media } from "../../models/MediaInterface";
import { MainListRow } from "../../styles/MainList.styled";
import { MediaTA } from "../TimedActions/MediaTA";
import { TreatmentTask } from "../../models/TreatmentTask";
import { MedicationTask } from "../../models/MedicationTask";

interface Props {
  isReadOnly: boolean;
  obj: TreatmentTask | DiagnosticTask | MedicationTask;
  type: string;
  handleOptionToggle: Function;
  handleDeleteMedia: Function;
  onChange: Function;
}
export default function MediaOption({
  isReadOnly,
  obj,
  type,
  handleOptionToggle,
  handleDeleteMedia,
  onChange,
}: Props) {
  const [uploadMediaDisplay, setUploadMediaDisplay] = useState<boolean>(false);
  const [editMediaDisplay, setEditMediaDisplay] = useState<boolean>(false);
  const [mediaToEdit, setMediaToEdit] = useState<any>(null);
  const handleClickOnAttach = () => {
    setUploadMediaDisplay(!uploadMediaDisplay);
    setEditMediaDisplay(false);
  };
  const handleTimedActionBlockChange = (change: any) => {
    const uploadedMediaItems = [...obj.AttachedMedias, ...change];
    onChange("AttachedMedias", uploadedMediaItems);
  };
  const closeModal = () => {
    setUploadMediaDisplay(false);
  };
  const updateEditMedia = (media: Media) => {
    const uploadedMediaItems = [...obj.AttachedMedias];
    const index = uploadedMediaItems.findIndex((m: Media) => m.Id === media.Id);
    if (index !== -1) {
      uploadedMediaItems[index] = media;
    }
    onChange("AttachedMedias", uploadedMediaItems);
    setMediaToEdit(media);
  };
  const onDeleteMedia = (id: number) => {
    handleDeleteMedia(id);
    setEditMediaDisplay(false);
  };
  const openEditMediaView = (media: Media) => {
    setMediaToEdit(media);
    setEditMediaDisplay(true);
  };
  return (
    <>
      <MediaTA
        isReadOnly={isReadOnly}
        isMediaEnabled={obj.IsMediaEnabled}
        handleOptionToggle={handleOptionToggle}
        medias={obj.AttachedMedias}
        type={type}
        deleteMedia={handleDeleteMedia}
        onAttach={handleClickOnAttach}
        onEdit={openEditMediaView}
      />
      {uploadMediaDisplay || editMediaDisplay ? (
        <MainListRow
          style={{
            position: "fixed",
            zIndex: "9999",
            top: 0,
            left: "4rem",
            height: "100vh",
            padding: 0,
            background: "#4b4b4b",
            width: "calc(100% - 4rem)",
          }}
        >
          {editMediaDisplay ? (
            /* edit */
            <EditMediaDetails
              mediaToEdit={mediaToEdit}
              setMediaToEdit={updateEditMedia}
              setEditMediaDisplay={(value: boolean) =>
                setEditMediaDisplay(value)
              }
              deleteMedia={onDeleteMedia}
              onEdit={openEditMediaView}
            />
          ) : (
            /* upload */
            <FileUpload
              accept=".jpg,.png,.jpeg,.wav, .pdf, .doc,.mp4"
              label="Media Upload Gallery"
              onTimedActionBlockChange={handleTimedActionBlockChange}
              uploadedMediaItems={obj.AttachedMedias}
              handleDeleteMedia={handleDeleteMedia}
              closeModal={closeModal}
              onEdit={openEditMediaView}
            />
          )}
        </MainListRow>
      ) : null}
    </>
  );
}
