import React, { useEffect, useState } from "react";
import { Avatar, Row, Spin, Tooltip } from "antd";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";
import { useAppSelector } from "../../../store/hooks";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomTable from "../../../components/Table/CustomTable";
import {
  ActionIcon,
  HoverIconContainer,
  RightSideTableHeader,
  StyledRow,
  TitleContainer,
  TitleContainerDiv,
} from "../../../components/Table/CustomTable.styled";
import {
  ArrowRightOutlined,
  DeleteOutlined,
  FilterOutlined,
  SwapOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { SearchFilter } from "../../../components";
import { WhiteButton } from "../../ScriptBuilder/EditComponents/ScriptDetailsView/InjuryTab/InjuryTab.styled";
import { Role } from "../User.styled";
import AddAccount from "./AddAccount";
import { Company } from "../../../models/Company";
import { getAllCompanies, getCompanyToken } from "../../../api/Company/ApiGet";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../components/Notification/Notification";
import { colorPalette } from "../../../styles/MainList.styled";
import { deleteCompany } from "../../../api/Company/ApiDelete";
import { isNullOrUndefined } from "../../../utils/utilityfunctions";
import { setCompany } from "../../../store/authSlice";
import { setCompanyHeader } from "../../../utils/setAuthToken";
import { setLoading } from "../../CreateMasterObjects/Components/ResearchQuestion/ResearchQuestionSlice";

export default function CompanyManagement() {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useAppSelector((state) => state.authReducer);
  const { manageAccountsFeature } = useAppSelector(
    (state) => state.FeatureFlagReducer,
  );
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);

  const selectCompany = (selectedCompany: Company) => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to change company.",
      content: `Are you sure you want change company to ${selectedCompany.Name}?`,
      okText: "Yes",
      onOk: async () => {
        await changeCompany(selectedCompany);
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: () => {
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };

  const changeCompany = async (selectedCompany: Company) => {
    await getCompanyToken(
      selectedCompany.Id,
      (companyToken: string) => {
        dispatch(setCompany(selectedCompany));
        setCompanyHeader(companyToken);
        history.replace("/");
      },
      (error: any) => {
        OpenErrorNotification({ description: error.ErrorMessage });
      },
    );
  };

  const handleDelete = (id: string) => {
    if (id) {
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "You are going to delete company.",
        content: "Are you sure you want delete this company?",
        okText: "Yes",
        onOk: () => {
          deleteCompany(
            id,
            (successMessage: string) => {
              const updatedData = companies.filter(
                (compnay) => compnay.Id !== id,
              );
              setCompanies(updatedData);
              OpenSuccessNotification({ description: successMessage });
            },
            (errorMessage: string) => {
              OpenErrorNotification({ description: errorMessage });
            },
          );
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "error",
      });
    }
  };

  const renderActionOptions = (data: any) => {
    return (
      <div className="action-tray">
        {/* pause icon */}
        {
          <ActionIcon
            className="action-option"
            hoverColor={colorPalette.warning}
            onClick={() => selectCompany(data)}
          >
            <span className="tooltip-on-hover">Switch Company</span>
            <SwapOutlined className="display-on-hover" />
          </ActionIcon>
        }

        {/* delete icon */}
        {manageAccountsFeature.actionMenu.deleteMenu && (
          <ActionIcon
            className="action-option"
            hoverColor={colorPalette.error}
            onClick={() => handleDelete(data.Id)}
          >
            <span className="tooltip-on-hover">Delete</span>
            <DeleteOutlined className="display-on-hover" />
          </ActionIcon>
        )}
      </div>
    );
  };
  useEffect(() => {
    setIsLoading(true);

    getAllCompanies(
      (data: Company[]) => {
        setCompanies(data);
        setIsLoading(false);
      },
      (errorMessage: string) => {
        OpenErrorNotification({ description: errorMessage });
        setIsLoading(false);
      },
    );
  }, []);

  const columns = [
    {
      title: "Account Name",
      dataIndex: "Name",
      key: "Name",
      className: "whiteText script-title",
      sorter: (a: any, b: any) => a.Name.localeCompare(b.Name),
      render: (LegalEntityName: any, data: Company) => (
        <TitleContainer>
          <TitleContainerDiv>
            {!isNullOrUndefined(data.LogoImageMedia?.Url) ? (
              <Avatar
                className="avatar"
                size="large"
                src={data.LogoImageMedia?.Url}
              ></Avatar>
            ) : (
              <Avatar size="large" icon={<UserOutlined />}></Avatar>
            )}
            <div className="title" style={{ textTransform: "capitalize" }}>
              {LegalEntityName}
            </div>
          </TitleContainerDiv>
          <HoverIconContainer>
            <span className={`detail-link`}>
              Details <ArrowRightOutlined />
            </span>
          </HoverIconContainer>
        </TitleContainer>
      ),
    },
    {
      title: "Users - Hidden in Prod",
      dataIndex: "Users",
      key: "users",
      className: "whiteText",
      sorter: (a: any, b: any) => a.users.localeCompare(b.users),
      render: (email: any) => <span style={{ color: "#fff" }}>45/50</span>,
    },
    {
      title: "Scripts - Hidden in Prod",
      dataIndex: "Scripts",
      key: "scripts",
      className: "whiteText",
      sorter: (a: any, b: any) => a.scripts.localeCompare(b.scripts),
      render: (email: any) => <span style={{ color: "#fff" }}>10/2</span>,
    },
    {
      title: "Status - Hidden in Prod",
      dataIndex: "Status",
      key: "status",
      className: "whiteText status-icon",
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      render: (email: any) => <span style={{ color: "#fff" }}>(Free)</span>,
    },
    {
      title: "Type - Hidden in Prod",
      dataIndex: "Type",
      key: "type",
      className: "whiteText",
      sorter: (a: any, b: any) => a.types.localeCompare(b.types),
      render: (email: any, data: any) => renderTypes(data),
    },
    {
      title: "Hidden in Prod",
      dataIndex: "",
      key: "action",
      className: "actions",
      render: (a: any, data: any) => {
        return renderActionOptions(data);
      },
    },
  ];

  const handleSearch = (text?: string) => {
    const companyData: Company[] = companies;

    function hasWord(target: string, query: string) {
      if (!target) return false;
      return target.toLowerCase().indexOf(query.toLowerCase()) > -1;
    }

    // Search guard clause
    if (!text) return companyData;
    return companyData.filter((company) => {
      if (hasWord(company.Name, text.trim())) return true;
      return false;
    });
  };

  const renderTypes = (data: any) => {
    return (
      <>
        <Role type="Instructor">Training ORG</Role>
        <Role type="Owner">Civilian</Role>
        <Role type="Admin">Military</Role>
      </>
    );
  };

  const selectedRow = (company: Company) => {
    return auth.company.Id === company.Id ? "selected-row" : "";
  };

  return (
    <Spin spinning={isLoading}>
      <StyledRow justify="space-between">
        <Row className="btn-row">
          {manageAccountsFeature.tableHeader.filter && (
            <Tooltip title={`Filter`}>
              <WhiteButton
                type="link"
                icon={<FilterOutlined className="icon-lg" />}
                size="large"
              />
            </Tooltip>
          )}
          <SearchFilter
            className="searchbar"
            handleSearch={(value: string) => setSearchInput(value)}
          />
        </Row>
        <RightSideTableHeader>
          <AddAccount companies={companies} setCompanies={setCompanies} />
        </RightSideTableHeader>
      </StyledRow>
      <CustomTable
        WrapperStyle={{ height: `calc(100vh - 65px - 70px - 48px)` }}
        columns={columns}
        data={handleSearch(searchInput)}
        dynamicRowClass={selectedRow}
      />
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </Spin>
  );
}
