import * as React from "react";
import { Component } from "react";
import { DeleteFilled, EditFilled, EyeOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";
import { Row, Modal, Col, message } from "antd";
import document_icon from "../../../assets/icons/Upload_PDF_Icon.png";
import audio_icon from "../../../assets/icons/Upload_Audio_Icon.png";
import object_icon from "../../../assets/icons/Upload_3D_Icon.png";

interface Props {
  resources: any[];
  deleteFile: Function;
  editResource: Function;
}
interface State {
  loading: boolean;
  showImage: boolean;
  previewImg: string;
  previewType: string;
}

class ResourceList extends Component<Props, State> {
  state: State = {
    loading: false,
    showImage: false,
    previewImg: "",
    previewType: "",
  };
  componentDidMount() {
    console.log(this.props);
  }

  modalToggle = () => {
    this.setState({ showImage: !this.state.showImage });
  };

  preview = (url: string, type: string) => {
    if (
      type === "document/" &&
      url.substring(url.lastIndexOf(".") + 1) !== "pdf"
    ) {
      // if its a word or any other file than pdf, it won't be dispalyed in the viewer, so enabling editing it
      let link: any = document.createElement("a");
      link.download = url;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link = null;
    } else {
      this.setState({ previewImg: url, previewType: type, showImage: true });
    }
  };

  error = (data: any) => {
    console.log(data);
    message.error("An error occured check the console for more information");
  };

  render() {
    console.log("resource list: ", this.props);
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {this.props?.resources?.map((item: any) => (
          <Col
            xs={24}
            sm={8}
            md={6}
            lg={4}
            xl={3}
            style={{
              padding: "5px",
              overflow: "hidden",
              height: "220px",
              display: "inline-block",
            }}
            key={item.id}
          >
            <Row style={{ height: "150px" }}>
              <div className="showImgName">
                <div style={{ marginTop: "30%" }}>
                  <DeleteFilled
                    style={{
                      fontSize: "2.3em",
                      marginRight: "10px",
                      color: "#f00",
                    }}
                    onClick={() =>
                      this.props.deleteFile(item.fileName, item.id)
                    }
                  />
                  <EyeOutlined
                    style={{ fontSize: "2.3em", color: "#0f0" }}
                    onClick={() =>
                      this.preview(item.url, item.resourceType.name)
                    }
                  />
                  <EditFilled
                    style={{
                      fontSize: "2.3em",
                      marginLeft: "10px",
                      color: "#f5e218",
                    }}
                    onClick={() => this.props.editResource(item)}
                  />
                </div>
              </div>
              {
                item.resourceType.name === "Image" ? (
                  <img
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                    src={
                      item.url.substring(0, item.url.lastIndexOf("/") + 1) +
                      "thumbnails/" +
                      item.url.substring(
                        item.url.lastIndexOf("/") + 1,
                        item.url.lastIndexOf(".")
                      ) +
                      "-Thumb.jpg"
                    }
                  />
                ) : item.resourceType.name === "Video" ? (
                  <img
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                    src={
                      item.url.substring(0, item.url.lastIndexOf("/") + 1) +
                      "thumbnails/" +
                      item.url.substring(
                        item.url.lastIndexOf("/") + 1,
                        item.url.lastIndexOf(".")
                      ) +
                      ".jpg"
                    }
                  />
                ) : item.resourceType.name === "Audio" ? (
                  <div
                    className="noDistortImage"
                    style={{ backgroundImage: "url(" + audio_icon + ")" }}
                  ></div>
                ) : /*<img style={{ width: "100%", height: "100%" }} src={audio_icon} />*/
                item.resourceType.name === "Document" ? (
                  <div
                    className="noDistortImage"
                    style={{ backgroundImage: "url(" + document_icon + ")" }}
                  ></div>
                ) : (
                  /*<img style={{ width: "100%", height: "100%" }} src={document_icon} />*/
                  <div
                    className="noDistortImage"
                    style={{ backgroundImage: "url(" + object_icon + ")" }}
                  ></div>
                )
                /*<img style={{ width: "100%", height: "100%" }} src={object_icon} />*/
              }
            </Row>
            <Row>{item.name}</Row>
          </Col>
        ))}
        <Modal
          visible={this.state.showImage}
          title="Preview"
          onCancel={this.modalToggle}
          footer={null}
          width={1200}
          style={{ height: "800px" }}
        >
          <div style={{ width: "100%", height: "600px" }}>
            {this.state.previewType === "image/" ? (
              <img
                alt=""
                style={{ width: "100%", height: "100%" }}
                src={this.state.previewImg}
              />
            ) : this.state.previewType === "video/" ? (
              <video
                key={this.state.previewImg}
                style={{ width: "100%", height: "100%" }}
                controls
              >
                <source src={this.state.previewImg} />
              </video>
            ) : this.state.previewType === "audio/" ? (
              <div
                style={{
                  display: "block",
                  boxSizing: "border-box",
                  padding: "200px 0",
                }}
              >
                <audio
                  key={this.state.previewImg}
                  controls
                  style={{ display: "block", margin: "0 auto" }}
                >
                  <source src={this.state.previewImg} />
                </audio>
              </div>
            ) : (
              <embed
                key={this.state.previewImg}
                src={this.state.previewImg}
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
export default ResourceList;
