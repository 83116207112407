import * as React from "react";
import { Route } from "react-router-dom";
import requiredAuth from "../../utils/requiredAuth";
import requireSigned from "../../utils/requireSigned";
import {
  PathComponent,
  loggedInRoutes,
  ownerRoutes,
  globalRoutes,
  adminRoutes,
  managerRoutes,
  notLoggedInRoutes,
} from "./componentRegistry";

import {
  COMPANY_OWNER,
  COMPANY_OWNER_LOGIN,
  COMPANY_ADMIN,
  COMPANY_ADMIN_LOGIN,
  COMPANY_MANAGER,
  COMPANY_MANAGER_LOGIN,
  STUDENT,
  STUDENT_LOGIN,
  REALX_LOGIN,
  DEFAULT,
} from "../../app/UserMenuTypes";

function loggedInRouteBuilder(pathCompLists: Array<PathComponent>) {
  const Routes: any = [];
  pathCompLists.map((item: PathComponent, index: number) =>
    Routes.push(
      <Route
        key={"routes_" + index}
        exact
        path={item.path}
        component={requiredAuth(item.component)}
      />
    )
  );
  globalRoutes.map((item: PathComponent, index: number) =>
    Routes.push(
      <Route
        key={"global_" + index}
        exact
        path={item.path}
        component={requiredAuth(item.component)}
      />
    )
  );
  //console.log(pathCompLists);
  //console.log(globalRoutes);
  return Routes;
}

function notLoggedInRouteBuilder(pathCompLists: Array<PathComponent>) {
  const Routes: any = [];
  pathCompLists.map((item: PathComponent, index: number) =>
    Routes.push(
      <Route
        key={index}
        exact
        path={item.path}
        component={requireSigned(item.component)}
      />
    )
  );
  globalRoutes.map((item: PathComponent, index: number) =>
    Routes.push(
      <Route
        key={index}
        exact
        path={item.path}
        component={requireSigned(item.component)}
      />
    )
  );
  //console.log(pathCompLists);
  //console.log(globalRoutes);
  return Routes;
}

export function routeSelector(menuChoice: string) {
  console.log("MenuChoice=>  " + menuChoice);
  let routes: any = [];
  switch (menuChoice) {
    case COMPANY_OWNER:
      routes = loggedInRouteBuilder(ownerRoutes);
      break;
    case COMPANY_OWNER_LOGIN:
      routes = loggedInRouteBuilder(ownerRoutes);
      break;
    case COMPANY_ADMIN:
      routes = loggedInRouteBuilder(adminRoutes);
      break;
    case COMPANY_ADMIN_LOGIN:
      routes = loggedInRouteBuilder(adminRoutes);
      break;
    case COMPANY_MANAGER:
      routes = loggedInRouteBuilder(managerRoutes);
      break;
    case COMPANY_MANAGER_LOGIN:
      routes = loggedInRouteBuilder(managerRoutes);
      break;
    case STUDENT:
      routes = loggedInRouteBuilder(managerRoutes);
      break;
    case STUDENT_LOGIN:
      routes = loggedInRouteBuilder(managerRoutes);
      break;
    case DEFAULT:
      routes = loggedInRouteBuilder(loggedInRoutes);
      break;
    case REALX_LOGIN:
      routes = loggedInRouteBuilder(loggedInRoutes);
      break;
    default:
      routes = notLoggedInRouteBuilder(notLoggedInRoutes);
      break;
  }
  return routes;
}
