import styled from "styled-components";
import {
  GrayColorPalette,
  colorPalette,
  primaryColorPalette,
} from "../../styles/MainList.styled";
import { Row, Table } from "antd";
import {
  EditOutlined,
  HeartOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { EyeOutlinedIcons } from "../../IconsTheme/Icons.global";
import { ColorPalette } from "../../styles/ColorPalette";

export const CustomTableWrapper = styled.div`
  max-height: 100%;
  height: calc(100vh - 65px - 70px);
  overflow: auto;
`;

export const StyledRow = styled(Row)`
  padding: 0.75rem 1rem;

  .btn-row {
    gap: 0.5rem;
  }

  .breadcrumb-row {
    height: 40px;
    display: flex;
    align-items: center;

    span {
      font-size: 1.125rem;
    }

    .breadcrumb-filter {
      padding-left: 0.5rem;
      cursor: pointer;
    }
  }

  .include-archived-scripts {
    margin-right: 20px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 85px;
  }

  .create-btn-link {
    display: flex;
    align-items: center;
    .create-btn {
      border-radius: 25px !important;
      height: 40px;
      margin-left: auto;
      display: flex !important;
      align-items: center !important;
      background: transparent !important;
      border: 1px solid #23d0eb !important;
      color: #23d0eb !important;

      &:hover {
        border: 1px solid #23d0eb !important;
        background-color: #052125 !important;
        color: #fff !important;
      }

      svg {
        font-size: 1.25rem;
      }
    }
  }
`;

export const CustomStyleTable = styled(Table)`
  .ant-table-empty colgroup {
    display: none;
  }
  .ant-table-thead {
    background: ${GrayColorPalette.Gray7};
  }
  .ant-table-body {
    overflow: auto hidden;
    table {
      width: auto;
      min-width: 100%;
      table-layout: fixed;
    }
  }
  thead th {
    border: 1px solid ${GrayColorPalette.Gray3};
    border-left: none;
    background: ${GrayColorPalette.Gray7};
    padding: 1.25rem;
    font-size: 1.125rem;
    text-transform: uppercase;
    color: ${GrayColorPalette.Gray2};
    &.ant-table-column-sort {
      background: ${GrayColorPalette.Gray7};
    }
    &:first-child {
      border-left: none;
    }
    &:hover {
      color: ${colorPalette.white};
    }
    &.ant-table-selection-column {
      border: none;
      border-left: 1px solid ${GrayColorPalette.Gray3};
      border-top: 1px solid ${GrayColorPalette.Gray3};
      border-bottom: 1px solid ${GrayColorPalette.Gray3};
      & + th {
        border: none;
        border-right: 1px solid ${GrayColorPalette.Gray3};
        border-top: 1px solid ${GrayColorPalette.Gray3};
        border-bottom: 1px solid ${GrayColorPalette.Gray3};
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    thead th {
      padding: 1.25rem 0.6rem;
      font-size: 1.11rem;
    }
    & .script-title {
      width: 350px !important;
    }
    & .modified-time {
      width: 120px !important;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    thead th {
      font-size: 1.11rem;
      padding: 1.1rem 0.6rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    thead th {
      font-size: 1rem;
      padding: 1.1rem 0.5rem;
    }
    & .script-title {
      width: 150px !important;
      font-size: 1rem;
    }
    & .author-column {
      width: 70px !important;
      font-size: 1rem;
    }
    & .modified-time {
      min-width: 100px !important;
      font-size: 1rem;
    }
  }

  .table-row {
    background: #1e1e1e;
    position: relative;
    & .script-status-icon,
    .access-icon,
    .action-tray,
    .like-icon,
    .detail-link,
    .title {
      cursor: pointer;
      &.readOnly {
        font-style: italic;
        padding-right: 0.5rem;
        color: ${GrayColorPalette.Gray2};
      }
    }
    & > td {
      box-shadow: none;
      border-bottom: 1px solid ${GrayColorPalette.Gray5} !important;
      border-right: 1px solid ${GrayColorPalette.Gray3};
      background: ${GrayColorPalette.Gray6};
      font-size: 1rem;
      padding-top: 0;
      padding-bottom: 0;
      transition: none;
      &:last-child {
        border-right: none;
      }
      &.script-title {
        font-size: 1.125rem;
        align-items: center;
        .display-on-hover,
        .detail-link {
          display: inline-flex;
          font-size: 1.125rem;
          visibility: hidden;
          opacity: 0;
          min-height: 40px;
          width: 40px;
          align-items: center;
        }
        .detail-link {
          float: right;
          visibility: hidden;
          opacity: 0;
          min-height: 40px;
          align-items: center;
          width: 40px;
          font-size: 1.145rem;
        }
        .title {
          display: inline-flex;
          align-items: center;
          min-height: 70px;
          max-height: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .archived-title {
          color: ${GrayColorPalette.Gray2};
          pointer-events: none;
        }
        .like-icon {
          float: right;
          clear: both;
          display: inline-flex;
          min-height: 40px;
          align-items: center;
          width: 40px;
          font-size: 1.125rem;
        }
        &:hover {
          .detail-link {
            visibility: visible;
            opacity: 1;
            &:hover {
              color: ${colorPalette.white};
            }
          }
          .display-on-hover {
            visibility: visible;
            opacity: 1;
            &.action-edit {
              color: ${colorPalette.primary100};
            }
          }
        }
        @media (max-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
          .title {
            /* min-width: 180px; */
            /* min-height: 50px; */
            /* max-width: clamp(480px, 40vw, 250px); */
            align-items: center;
            vertical-align: middle !important;
          }
        }
        @media (max-width: ${({ theme }) => theme.breakpoints?.xl}) {
          .title {
            /* min-width: 115px; */
            width: calc(100% - 40px);
            /* min-height: 28px; */
            /* display: -webkit-box; */
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            /* max-width: clamp(318px, 40vw, 180px); */
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center !important;
            vertical-align: middle !important;
            /* justify-content: center !important; */
            /* min-height: auto; */
          }
        }
        @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
          .title {
            /* min-width: 100px; */
            /* min-height: 30px; */
            /* max-width: clamp(140px, 40vw, 100px); */
          }
        }
      }
      &.noPadding {
        padding: 0 !important;
      }
      &.status-icon {
        width: 8rem;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        .access-icon,
        .script-status-icon {
          font-size: 1.5rem;
          width: 100%;
          height: 100%;
          min-height: 72px;
          display: inline-flex;
          align-items: center;
          .anticon {
            width: 100%;
            height: 100%;
            vertical-align: middle;
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }
          &.lock-icon {
            color: #fea40a;
          }
          &.unlock-icon {
            color: #23d0eb;
          }
        }
      }
      &.modified-time {
        width: 10rem;
      }
      & .company-info {
        display: flex;
        align-items: center;
        .company-name {
          display: flex;
          justify-content: center;
          max-width: 180px;
          min-width: 70px;
          min-height: 28px;
          max-height: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
          align-items: center;
        }
      }
      & > .action-tray {
        font-size: 1.5rem;
        transition: all 0.3s ease;
        justify-content: center;
        align-items: center;
        text-align: center;
        .anticon {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          min-width: 35px;
        }
        @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
          .action-tray {
            font-size: 1.45rem;
            .anticon {
              vertical-align: middle;
              display: inline-block;
              justify-content: center;
              align-items: center;
            }
          }
        }
        @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
          .action-tray {
            width: 210px;
          }
        }
        @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
          .action-tray {
            width: 200px;
          }
        }
        & > span {
          padding: 0 0.15rem;
          text-align: center;
          @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
            &.action-option {
              min-width: 50px;
              height: 100%;
              &:hover {
                .tooltip-on-hover {
                  font-size: 0.8rem;
                }
              }
            }
          }
          @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
            &.action-option {
              height: 100%;
              &:hover {
                .tooltip-on-hover {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }

      .ant-radio-wrapper {
        .ant-radio-checked .ant-radio-inner {
          border-color: ${colorPalette.primary100};
          background: ${primaryColorPalette.primaryRadioBackground};
        }
        .ant-radio-inner {
          width: 18px;
          height: 18px;
          border-radius: 0;
          border-color: ${GrayColorPalette.GrayRadio};
          &::after {
            opacity: 0;
          }
        }
        &:hover {
          color: ${colorPalette.white};
          .ant-radio-inner {
            border-color: ${colorPalette.white};
          }
          .ant-radio-checked .ant-radio-inner {
            border-color: ${colorPalette.primary100};
          }
        }
      }
    }

    &.ant-table-row-selected > td {
      background: ${GrayColorPalette.Gray4};
    }
    &:hover {
      td {
        background: ${GrayColorPalette.Gray5} !important;
      }
      .time {
        display: none;
      }
      .action-tray {
        display: block;
        height: 100%;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    .action-tray {
      width: 210px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    .action-tray {
      width: 200px;
    }
  }
  /* action tray on right on hover - start */
  .actions {
    position: absolute;
    display: none;
    .action-tray {
      min-width: 170px;
    }
  }
  .ant-table-row {
    position: relative;
  }
  .ant-table-row:hover .actions {
    display: block;
    height: -webkit-fill-available;
    right: 1px;
    position: absolute;
  }
  /* action tray on right on hover - stop */

  .selected-row {
    > td {
      background: ${ColorPalette.Gray4};
    }
  }
  .archived-row {
    background: ${GrayColorPalette.Gray4} !important;
    > td {
      background: ${GrayColorPalette.Gray4} !important;
    }
    &:hover {
      > td {
        background: ${GrayColorPalette.Gray3} !important;
      }
    }
  }

  .ant-table-selection-column {
    padding-left: 1rem;
    box-shadow: none !important;
    border-right: none !important;
    .ant-checkbox .ant-checkbox-inner {
      width: 18px;
      height: 18px;
      background-color: transparent;
      border-color: #ffffff40;
      &::after {
        opacity: 0;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background: #052125;
      border-color: #23d0eb;
    }

    &:hover {
      .ant-checkbox-inner {
        border-color: #fff;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        border-color: #23d0eb;
      }
    }
  }
  .ant-table-tbody > tr > td {
    box-shadow: none;
    border-bottom: none;
  }
  .ant-avatar {
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      height: auto;
      width: 100%;
    }
  }

  .ant-table-expanded-row > td,
  .ant-table-expanded-row:hover > td {
    background: #1a1818;
    border-bottom: none;
    color: #ffffff;
    box-shadow: inset -1px 0px black;
  }
  .ant-table-expanded-row:hover > td {
    background: #313131;
  }
`;

export const StyledHeartOutlined = styled(HeartOutlined)`
  width: 40px;
  height: 70px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const RightSideTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainerDiv = styled.div`
  display: flex;
  min-height: 70px;
  align-items: center;
  width: calc(100% - 85px);
`;

export const HoverIconContainer = styled.div`
  width: 85px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    margin-right: 0;
    margin-left: auto;
  }
`;

export const StyledEditOutlined = styled(EditOutlined)`
  width: 40px;
  height: 70px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledViewOutlined = styled(EyeOutlinedIcons)`
  width: 40px;
  height: 70px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledArchivedScriptEditOutlined = styled(EditOutlined)`
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  width: 40px;
  height: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomTableContainer = styled.div`
  padding: 0;
  border-top: 4px solid rgb(38, 38, 38);
  border-left: 4px solid rgb(38, 38, 38);
  height: calc(100vh - 64px);
  overflow: auto;
`;

interface IActionIconProps {
  hoverColor: string;
}
export const ActionIcon = styled.span<IActionIconProps>`
  padding: 0px 0.15rem;
  text-align: center;
  position: relative;
  height: 100%;
  display: inline-block;
  &:hover {
    color: ${(props) => props.hoverColor};
  }
  .tooltip-on-hover {
    display: block;
    visibility: hidden;
    opacity: 0;
    text-align: center;
    font-size: 0.8rem;
  }
  &:hover {
    .tooltip-on-hover {
      visibility: visible;
      opacity: 1;
    }
  }
`;
