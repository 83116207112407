import * as React from "react";
import { Component } from "react";
import { UserOutlined } from "@ant-design/icons";
import { message, Typography, Avatar } from "antd";
import { Auth, SystemMessage } from "./modules";
import AlertBar from "./AlertBar";
import {
  Profile,
  ProfileMenuDiv,
  Seperator,
  StyledHeader,
} from "./Head.styled";
import { ProfileMenuList } from "./MenuRoutes/ProfileMenu";
import { NavLink } from "react-router-dom";
import "./Head.scss";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { SetShowUserModal } from "./HeadSlice";
import {
  isNullOrUndefined,
  renderFormattedName,
} from "../utils/utilityfunctions";
import DownloadRealXBtn from "./DownloadRealXBtn";

const { Text } = Typography;

export interface Props {
  logout: Function;
  resetAlertMessage: Function;
  children: any;
  auth: Auth;
  SystemMessage: SystemMessage;
}

interface State {
  manageCourseModal: boolean;
  manageTagsModal: boolean;
  manageCourses: any[];
  manageCoursesUpdate: any[];
  tagList: any[];
  loading: boolean;
  searchText: string;
  searchedColumn: string;
  tagName: string;
  selectedAccess: string;
  accessLevel: any[];
  updateTag: any;
  barVisible: boolean;
  profileMenuVisible: boolean;
}

const UserInfoLayout = (props: any) => {
  const dispatch = useAppDispatch();
  const { showUserModal } = useAppSelector((state) => state.HeadReducer);
  const { company } = useAppSelector((state) => state.authReducer);
  const handleProfileMenuClick = () => {
    dispatch(SetShowUserModal(!showUserModal));
  };

  const handleNavLinkClick = (e: any, key: any) => {
    switch (key) {
      case "myPreferences":
        // window.location.assign("/myPreferences");
        e.preventDefault();
        break;
      case "privacyPolicy":
        // window.location.assign("/privacyPolicy");
        e.preventDefault();
        break;
      default:
        message.error("Some error occurred.");
        break;
    }
  };

  return (
    <React.Fragment>
      <AlertBar></AlertBar>

      <Profile
        id="Profile"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "-webkit-fill-available",
          padding: "0 1em",
        }}
        onClick={handleProfileMenuClick}
        onBlur={(e) => console.log("blur", e.target)}
      >
        {company.LogoImageMedia?.Url !== "" &&
        !isNullOrUndefined(company.LogoImageMedia?.Url) ? (
          <Avatar
            className="avatar"
            style={{
              height: "3.5rem",
              width: "3.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            size="large"
            src={company.LogoImageMedia?.Url}
          ></Avatar>
        ) : (
          <Avatar
            className="avatar"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            shape="circle"
            size="large"
            icon={<UserOutlined />}
          ></Avatar>
        )}
        <ProfileMenuDiv
          displayProfileMenuProp={showUserModal}
          className="user-dropdown-container"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "0 3rem 0.25rem 3rem",
              lineHeight: "1.8em",
            }}
          >
            <Text
              strong={true}
              className={props.fontColor}
              style={{
                fontSize: "1.25em",
                lineHeight: "1.8rem",
              }}
            >
              {props.auth.company.Name}
            </Text>
            {props.auth.user.FirstName || props.auth.user.LastName ? (
              <Text
                className={props.fontColor}
                style={{
                  fontSize: "1.25em",
                  lineHeight: "1.8rem",
                }}
              >
                {renderFormattedName(
                  props.auth.user.FirstName,
                  props.auth.user.LastName,
                )}
              </Text>
            ) : null}
          </div>
          <Seperator />
          {ProfileMenuList.map((link: any) => (
            <NavLink
              style={{
                width: "-webkit-fill-available",
                padding: "0.25rem 3rem",
                display: "flex",
                fontSize: "1.25em",
                lineHeight: "1.8em",
              }}
              key={link.key}
              to={`/${link.url}`}
              onClick={(e) => {
                link.key === "logout"
                  ? props.logout()
                  : handleNavLinkClick(e, link.key);
              }}
              className={`profile-menu ${link.disabled ? "disabled" : ""}`}
            >
              {link.name}
            </NavLink>
          ))}
          <DownloadRealXBtn />
        </ProfileMenuDiv>
      </Profile>
    </React.Fragment>
  );
};

class Head extends Component<Props, {}> {
  state: State = {
    manageCourseModal: false,
    manageCourses: [],
    manageCoursesUpdate: [],
    manageTagsModal: false,
    tagList: [],
    loading: true,
    searchText: "",
    searchedColumn: "",
    tagName: "",
    selectedAccess: "",
    accessLevel: [
      { id: 0, name: "Public" },
      { id: 1, name: "Internal" },
      { id: 2, name: "Private" },
    ],
    updateTag: {},
    barVisible: false,
    profileMenuVisible: false,
  };

  constructor(props: any) {
    super(props);

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleCloseAlertBar = this.handleCloseAlertBar.bind(this);
  }

  handleCloseAlertBar(e: any) {
    this.props.resetAlertMessage();
  }

  handleMenuClick(e: any) {
    switch (e.key) {
      case "profile":
        window.location.href = "/profile";
        break;
      case "gradeDetails":
        window.location.assign("/grades");
        break;
      case "progress":
        window.location.assign("/progress");
        break;
      case "AdminHome":
        window.location.assign("/home");
        break;
      default:
        message.error("This should not happen.");
        break;
    }
  }

  logout = async () => {
    this.props.logout();
  };
  handleChange = (input: any) => (e: any) => {
    this.setState({ [input]: e.target.value } as any);
  };

  onSelectChange = (e: any) => {
    this.setState({ selectedAccess: e } as any);
  };

  direct = (location: string) => {
    window.location.href = location;
  };

  render() {
    const permissions = ["Create", "Update", "Delete"];
    return (
      <React.Fragment>
        {this.props.auth.isAuthenticated &&
        this.props.auth.user.FirstName !== "content" &&
        this.props.auth.user.LastName !== "shared" ? (
          <React.Fragment>
            {permissions.some((item: any) => permissions.includes(item)) ? (
              <StyledHeader className="rxblackBG tealText" style={{}}>
                <div
                  style={{
                    cursor: "pointer",
                    marginLeft: "auto",
                    width: "fit-content",
                    height: "inherit",
                  }}
                >
                  <UserInfoLayout
                    {...this.props}
                    isVisible={
                      this.props.SystemMessage.systemMessage.message.length > 0
                    }
                    onClick={this.handleCloseAlertBar}
                    fontColor={"whiteText"}
                  ></UserInfoLayout>
                </div>
              </StyledHeader>
            ) : (
              <StyledHeader
                className="rxblackBG tealText"
                style={{
                  zIndex: 1110,
                  height: "inherit",
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    margin: "0",
                    cursor: "pointer",
                  }}
                >
                  <UserInfoLayout
                    {...this.props}
                    isVisible={
                      this.props.SystemMessage.systemMessage.message.length > 0
                    }
                    onClick={this.handleCloseAlertBar}
                    fontColor={"whiteText"}
                  ></UserInfoLayout>
                </div>
              </StyledHeader>
            )}
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Head;
