import React, { MouseEventHandler } from "react";
import { IconDiv } from "../../../styles/EditComponentTheme.styled";
import {
  CompassOutlinedIcons,
  CopyIcon,
  DeleteIcon,
  FilledAddOutLinedIcon,
  FrownOutlinedIcons,
  GroupOutlinedIcons,
  UnorderedListOutlinedIcons,
} from "../../../IconsTheme/Icons.global";
import { ReactComponent as TextIcon } from "../../../assets/img/Svg/Tt.svg";
import Role from "../../../assets/img/Svg/Role.svg";
import { ReactComponent as Rx } from "../../../assets/img/Svg/Rx.svg";
import { ReactComponent as Dx } from "../../../assets/img/Svg/Dx.svg";
import { ReactComponent as Mx } from "../../../assets/img/Svg/Mx.svg";
import { ReactComponent as Tx } from "../../../assets/img/Svg/Tx.svg";
import { Tooltip } from "antd";
import {
  ArrowsAltOutlined,
  CloseOutlined,
  CrownFilled,
  EditOutlined,
  ExperimentOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useAppSelector } from "../../../store/hooks";
import { ColorPalette } from "../../../styles/ColorPalette";

interface BlockType {
  type: string;
  onCopyClick?: any;
  onDeleteClick?: any;
  isDeleteBtnDisabled?: boolean;
  isCopyBtnDisabled?: boolean;
  copyBtnTooltip?: string;

  isUploadBtnAvailable?: boolean;
  isUploadBtnDisabled?: boolean;
  onUploadClick?: any;
  reorderBtnAvailable?: boolean;
  reorderBtnDisabled?: boolean;
  reorderBtnTooltip?: string;
  onReorderClick?: any;
  displayCancelBtn?: boolean;
  onCancelClick?: any;
  isEditBtnAvailable?: boolean;
  isEditBtnDisabled?: boolean;
  onEditClick?: any;
  isMasterBtnAvailable?: boolean;
  isMasterBtnDisabled?: boolean;
  isMaster?: boolean;
  onMasterBtnClick?: MouseEventHandler<HTMLSpanElement>;
}

export const IconColumn = (props: BlockType) => {
  const { isReadOnly } = useAppSelector((state: any) => state.MainlistReducer);

  const isMasterBtn = () => {
    if (props.isMasterBtnDisabled) {
      return (
        <span className="deleteBtn disabled">
          <button className={"StrippedButton disabled"}>
            <CrownFilled className="iconCD disabled" />
          </button>
        </span>
      );
    }

    if (props.isMaster) {
      return (
        <span className="deleteBtn">
          <button style={{ cursor: "default" }} className={"StrippedButton"}>
            <CrownFilled
              className="iconCD"
              style={{
                color: ColorPalette.Orange1,
                cursor: "default",
              }}
            />
          </button>
        </span>
      );
    } else {
      return (
        <Tooltip
          title={
            props.onMasterBtnClick == null ? "Coming Soon" : "Create Master"
          }
        >
          <span className="deleteSpan" onClick={props.onMasterBtnClick}>
            <button
              className={
                props.onEditClick == null ? " StrippedButton" : "StrippedButton"
              }
            >
              <CrownFilled className="iconCD" />
            </button>
          </span>
        </Tooltip>
      );
    }
  };

  return (
    <IconDiv>
      {(() => {
        switch (props.type.toLocaleLowerCase()) {
          case "section":
            return (
              <div className="blockNameAndIcon">
                <GroupOutlinedIcons className="icon" />
                <span className="text"> Section </span>{" "}
              </div>
            );
          case "section_copy_move":
            return (
              <div className="blockNameAndIcon">
                <GroupOutlinedIcons className="icon" />
                <span className="text"> MOVE/COPY SECTION TO PHASE </span>{" "}
              </div>
            );
          case "text":
            return (
              <div className="blockNameAndIcon">
                <TextIcon fill="#ffffff" className="iconText" />
                <span className="text"> TEXT </span>
              </div>
            );
          case "phase":
            return (
              <div className="blockNameAndIcon">
                <FilledAddOutLinedIcon className="icon" />
                <span className="text"> Phase </span>
              </div>
            );
          case "reorder phase":
            return (
              <div className="blockNameAndIcon">
                <FilledAddOutLinedIcon className="icon" />
                <span className="text">Reorder Phases </span>
              </div>
            );
          case "list":
            return (
              <div className="blockNameAndIcon">
                <UnorderedListOutlinedIcons className="icon" />
                <span className="text"> LIST </span>
              </div>
            );
          case "role":
            return (
              <div className="blockNameAndIcon">
                <img src={Role} className="iconText" alt="prompt" />
                <span className="text"> Role Player Instructions </span>
              </div>
            );
          case "action":
            return (
              <div className="blockNameAndIcon">
                <CompassOutlinedIcons className="icon" />
                <span className="text"> Support Action</span>
              </div>
            );
          case "research":
            return (
              <div className="blockNameAndIcon">
                <ExperimentOutlined className="icon" />
                <span className="text"> Research Question </span>
              </div>
            );
          case "casualty":
            return (
              <div className="blockNameAndIcon">
                <FrownOutlinedIcons className="icon" />
                <span className="text"> Casualty </span>
              </div>
            );
          case "casualty_status":
            return (
              <div className="blockNameAndIcon">
                <FrownOutlinedIcons className="icon" />
                <span className="text"> Casualty Status </span>
              </div>
            );
          case "medication":
            return (
              <div className="blockNameAndIcon">
                <Rx className="iconText treatmentIcon" />
                <span className="text"> Medication </span>
              </div>
            );
          case "treatment":
            return (
              <div className="blockNameAndIcon">
                <Tx className="iconText treatmentIcon" />
                <span className="text">Treatment</span>
              </div>
            );
          case "miscellaneous":
            return (
              <div className="blockNameAndIcon">
                <Mx className="iconText treatmentIcon" />
                <span className="text">Miscellaneous</span>
              </div>
            );
          case "diagnostic":
            return (
              <div className="blockNameAndIcon">
                <Dx className="iconText treatmentIcon" />
                <span className="text">Diagnostic</span>
              </div>
            );

          default:
            return <h1> This should not happen </h1>;
        }
      })()}
      {isReadOnly ? null : props.displayCancelBtn && props.onCancelClick ? (
        <div className="iconsDiv">
          <Tooltip
            title={props.onCancelClick == null ? "Coming Soon" : "Cancel"}
          >
            <span className="deleteSpan" onClick={props.onCancelClick}>
              <button
                className={
                  props.onCancelClick == null
                    ? " StrippedButton"
                    : "StrippedButton"
                }
                disabled={props.onCancelClick == null}
              >
                <CloseOutlined title="" className="iconCD" />
              </button>
            </span>
          </Tooltip>
        </div>
      ) : (
        <div className="iconsDiv">
          {props.reorderBtnAvailable ? (
            <Tooltip
              title={
                props.onReorderClick == null
                  ? "Coming Soon"
                  : props.reorderBtnTooltip
                    ? props.reorderBtnTooltip
                    : `Reorder ${props.type}`
              }
            >
              {!props.reorderBtnDisabled ? (
                <span className="deleteSpan" onClick={props.onReorderClick}>
                  <button
                    className={
                      props.onReorderClick == null
                        ? " StrippedButton"
                        : "StrippedButton"
                    }
                    disabled={props.onReorderClick == null}
                  >
                    <ArrowsAltOutlined title="" className="iconCD" />
                  </button>
                </span>
              ) : (
                <span className="deleteBtn disabled">
                  <button
                    className={
                      props.onReorderClick == null
                        ? " StrippedButton disabled"
                        : "StrippedButton disabled"
                    }
                    disabled={props.onReorderClick == null}
                  >
                    <ArrowsAltOutlined title="" className="iconCD disabled" />
                  </button>
                </span>
              )}
            </Tooltip>
          ) : null}

          {props.isUploadBtnAvailable ? (
            !props.isUploadBtnDisabled ? (
              <Tooltip
                title={
                  props.onUploadClick == null
                    ? "Coming Soon"
                    : "Upload Audio File"
                }
              >
                <span className="deleteSpan" onClick={props.onUploadClick}>
                  <button
                    className={
                      props.onUploadClick == null
                        ? " StrippedButton"
                        : "StrippedButton"
                    }
                    disabled={props.onUploadClick == null}
                  >
                    <UploadOutlined title="" className="iconCD" />
                  </button>
                </span>
              </Tooltip>
            ) : (
              <span className="deleteBtn disabled">
                <button
                  className={
                    props.onUploadClick == null
                      ? " StrippedButton disabled"
                      : "StrippedButton disabled"
                  }
                  disabled={props.onUploadClick == null}
                >
                  <UploadOutlined title="" className="iconCD disabled" />
                </button>
              </span>
            )
          ) : null}
          {props.isMasterBtnAvailable ? isMasterBtn() : null}
          {props.isEditBtnAvailable ? (
            !props.isEditBtnDisabled ? (
              <Tooltip
                title={props.onEditClick == null ? "Coming Soon" : "Edit"}
              >
                <span className="deleteSpan" onClick={props.onEditClick}>
                  <button
                    className={
                      props.onEditClick == null
                        ? " StrippedButton"
                        : "StrippedButton"
                    }
                    disabled={props.onEditClick == null}
                  >
                    <EditOutlined title="" className="iconCD" />
                  </button>
                </span>
              </Tooltip>
            ) : (
              <span className="deleteBtn disabled">
                <button
                  className={
                    props.onEditClick == null
                      ? " StrippedButton disabled"
                      : "StrippedButton disabled"
                  }
                  disabled={props.onEditClick == null}
                >
                  <EditOutlined title="" className="iconCD disabled" />
                </button>
              </span>
            )
          ) : null}
          <Tooltip
            title={
              props.isCopyBtnDisabled
                ? props.copyBtnTooltip || ""
                : props.onCopyClick == null
                  ? "Coming Soon"
                  : "Copy"
            }
          >
            {!props.isCopyBtnDisabled ? (
              <span className="deleteSpan" onClick={props.onCopyClick}>
                <button
                  className={
                    props.onCopyClick == null
                      ? " StrippedButton"
                      : "StrippedButton"
                  }
                  disabled={props.onCopyClick == null}
                >
                  <CopyIcon title="" className="iconCD" />
                </button>
              </span>
            ) : (
              <span className="deleteBtn disabled">
                <button
                  className={
                    props.onCopyClick == null
                      ? " StrippedButton disabled"
                      : "StrippedButton disabled"
                  }
                  disabled={props.onCopyClick == null}
                >
                  <CopyIcon title="" className="iconCD disabled" />
                </button>
              </span>
            )}
          </Tooltip>
          {props.isDeleteBtnDisabled ? (
            <span className="deleteBtn disabled">
              <button
                className={
                  props.onCopyClick == null
                    ? "StrippedButton disabled"
                    : "StrippedButton disabled"
                }
                disabled={props.onDeleteClick == null}
                //need to fix mispelling ^^^^
              >
                <DeleteIcon title="" className="iconCD disabled" />
              </button>
            </span>
          ) : (
            <Tooltip title={"Delete"}>
              <span className="deleteSpan" onClick={props.onDeleteClick}>
                <button
                  className={
                    props.onCopyClick == null
                      ? "StrippedButton"
                      : "StrippedButton"
                  }
                  disabled={props.onDeleteClick == null}
                >
                  <DeleteIcon title="" className="iconCD" />
                </button>
              </span>
            </Tooltip>
          )}
        </div>
      )}
    </IconDiv>
  );
};
