import React, { useState } from "react";
import {
  BeforeCapture,
  DragDropContext,
  Draggable,
  Droppable,
} from "react-beautiful-dnd";
import { DeleteIcon } from "../../IconsTheme/Icons.global";
import {
  IndexSpan,
  StyledListTitleItem,
  TitleSpan,
  Ul,
} from "../../pages/ScriptBuilder/EditComponents/ShowList/ShowList.styled";
import InlineEditTextArea from "../InlineEdit/InlineEditTextArea";
import { TimedActionSubTask } from "../../models/TimedActionSubTask";
import { MiscellaneousTask } from "../../models/MiscellaneousTask";
import { DiagnosticTask } from "../../models/DiagnosticTask";

interface Props {
  object: MiscellaneousTask | DiagnosticTask;
  handleSubtaskDelete: any;
  handleSubtaskUpdate: any;
  isReadOnly: boolean;
  onDragEnd: any;
}

export default function SubtasksList(props: Props) {
  const [isHover, setIsHover] = useState({
    position: 0,
    hover: false,
  });

  const mouseEntered = (index: any) => {
    setIsHover({
      position: index,
      hover: true,
    });
  };

  const mouseLeaved = (index: number) => {
    setIsHover({
      position: 0,
      hover: false,
    });
  };

  const handleOnBeforeCapture = (before: BeforeCapture) => {
    // if the user grabs a section, ensure that it's collapsed
    const subtaskId = before.draggableId;
    if (!subtaskId) {
      const targetIndex = props.object?.Subtasks.findIndex(
        (s: any) => s.Id === Number(subtaskId),
      );
      if (targetIndex === -1) {
        console.log(
          "Error: unable to locate subtask within block state object",
        );
        return;
      }
    }
  };
  const onChangeSubtaskDetails = (uniqueId: string, value: string) => {
    const id = uniqueId.split("inline_editing_subtask_").length
      ? uniqueId.split("inline_editing_subtask_")[1]
      : 0;
    props.handleSubtaskUpdate(Number(id), value);
  };
  return (
    <DragDropContext
      onDragEnd={props.onDragEnd}
      onBeforeCapture={handleOnBeforeCapture}
    >
      <Droppable
        isDropDisabled={props.isReadOnly}
        droppableId={`subtask-${props.object.Id}`}
        key={`subtask-${props.object.Id}`}
      >
        {(provided: any, snapshot: any) => (
          <div ref={provided.innerRef} {...snapshot.isDraggingOver}>
            <Ul>
              {Array.isArray(props.object.Subtasks) &&
                props.object.Subtasks?.map(
                  (subtask: TimedActionSubTask, index: number) => {
                    return (
                      <Draggable
                        isDragDisabled={props.isReadOnly}
                        draggableId={`${subtask.Id}`}
                        key={subtask.Id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                            onMouseEnter={() => mouseEntered(index)}
                            onMouseLeave={() => mouseLeaved(index)}
                            onClick={() => mouseEntered(index)}
                          >
                            <StyledListTitleItem className="titleIndexDiv">
                              <IndexSpan>{index + 1}</IndexSpan>
                              <TitleSpan>
                                <InlineEditTextArea
                                  isReadOnly={props.isReadOnly}
                                  uniqueId={`inline_editing_subtask_${subtask.Id}`}
                                  value={subtask.Description}
                                  onChange={onChangeSubtaskDetails}
                                />
                              </TitleSpan>
                            </StyledListTitleItem>
                            {props.isReadOnly ? null : (
                              <button
                                onClick={() =>
                                  props.handleSubtaskDelete(subtask)
                                }
                                className="deletebtn"
                              >
                                <DeleteIcon className="delete-icon listItem always-display-delete" />
                              </button>
                            )}
                          </li>
                        )}
                      </Draggable>
                    );
                  },
                )}
            </Ul>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
