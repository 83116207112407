import React from "react";
import FloatingLabelInput from "../../../../components/FloatingLabel/FloatingLabelInput";
import { FormRow } from "../../User.styled";
type Props = {
  formik: any;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
  handleChange: Function;
};
const fieldName = "username";
const isReadOnly = true;
export default function UserNameInput({
  formik,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
  handleChange,
}: Props) {
  return (
    <FormRow>
      <FloatingLabelInput
        label={"User Name"}
        nameForInput={fieldName}
        type="email"
        value={formik.values[fieldName]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          !isReadOnly && handleChange(fieldName, e.target.value)
        }
        scriptFormik={formik}
        lastFocused={lastFocused}
        isAddNeeded={false}
        setIsFocused={setIsFocused}
        setLastFocused={setLastFocused}
        isFocused={isFocused}
        readOnly={isReadOnly}
      />
    </FormRow>
  );
}
