const Focused = {
  description: false,
};

const casualtyStatusState = {
  isFocused: Focused,
  lastFocused: Focused,
  description: "",
};

export default (
  state = casualtyStatusState,
  action: { type: string; payload: any }
) => {
  if (action.type === "isFocused") {
    return {
      ...state,
      isFocused: { ...action.payload },
    };
  } else if (action.type === "lastFocused") {
    return {
      ...state,
      lastFocused: { ...action.payload },
    };
  } else if (action.type === "description") {
    return {
      ...state,
      description: action.payload,
    };
  } else return state;
};
