import axios from "axios";

//create a default error message in case one wasn't provided
function defaultErrorMessage(err: any) {
  console.log(err);
  if (typeof err === "string") {
    console.log(err);
  } else {
    console.log(JSON.stringify(err.response.data.ErrorMessage));
  }
}

export function deleteResource(
  filename: any,
  id: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/resource/deleteResource`, {
      varOne: filename,
      varTwo: id,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function addSymptomResources(
  payload: {},
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/resource/add-symptom-resources`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function addActionResources(
  payload: {},
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/resource/add-action-resources`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function addSessionResources(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/resource/add-session-resources`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function addClassroomSessionResources(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/resource/add-classroom-session-resources`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function addScenarioResources(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/resource/add-scenario-resources`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function addScenarioStateResources(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/resource/add-scenarioState-resources`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function deleteFile(
  filename: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`/api/1/pfc/resource/deleteFile/`, { data: filename })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function add3dAsset(
  postObj: any,
  callback: Function,
  errorcallback: Function,
) {
  console.log(postObj);
  axios
    .post(`/api/1/pfc/resource/add3dAsset`, postObj)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function addTextAsset(
  postObj: any,
  callback: Function,
  errorcallback: Function,
) {
  console.log(postObj);
  axios
    .post(`/api/1/pfc/resource/addTextAsset`, postObj)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function editTextAsset(
  postObj: any,
  callback: Function,
  errorcallback: Function,
) {
  console.log(postObj);
  axios
    .post(`/api/1/pfc/resource/editTextAsset`, postObj)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function updateResource(
  postobj: any,
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .post(`/api/1/pfc/resource/updateResource`, postobj)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      console.log(err);
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
