import "@ant-design/compatible/assets/index.css";
import {
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  ForkOutlined,
  HeartOutlined,
  LikeOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Menu,
  Modal,
  Row,
  Select,
  TableProps,
  Tooltip,
} from "antd";
import * as React from "react";
import { useState } from "react";
import { GoArchive } from "react-icons/go";
import { connect } from "react-redux";
import { setAlertMessage } from "../../../actions/alertMessage";
import {
  CreateEquipment,
  DeleteEquipment,
  UpdateEquipment,
} from "../../../api/Equipment/ApiPost";
import { SystemMessage } from "../../../app/modules";
import { Input, Table } from "../../../components";
import Drawer from "../../../components/Drawer/Drawer";
import {
  DeleteIcon,
  EditOutlinedIcons,
} from "../../../IconsTheme/Icons.global";
import { Category } from "../../../models/CategoryInterface";
import { AlertType } from "../../../models/Enums";
import { EquipmentItem } from "../../../models/EquipmentInterfaces";
import { filterCategoryByCategoryType } from "../../../utils/utilityfunctions";
import { StyledForm } from "../../ScriptBuilder/EditComponents/EditForms/PhaseForm/PhaseForm.styled";
import { FormContainer } from "../../ScriptBuilder/EditComponents/EditForms/RolePlayerForm/PromptForm.styled";
import { FloatingLabel } from "../../../components/FloatingLabel/FloatingLabel.styled";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";
import { useAppSelector } from "../../../store/hooks";
const { Option } = Select;

export type EquipmentForm = {
  id?: number;
  label: string;
  abbreviation: string;
  description: string;
  categoryId: number;
  nationalStockNumber: string;
  hyperlinkToNationalStockNumber: string;
  approved?: boolean;
};

interface Props {
  auth: any;
  equipmentList: any[];
  categoryList: Category[];
  deleteEquipment: Function;
  SystemMessage: SystemMessage;
  setAlertMessage: Function;
  refreshEquipemnt: Function;
}

const EquipmentList = ({
  auth,
  equipmentList,
  setAlertMessage,
  refreshEquipemnt,
  categoryList,
}: Props) => {
  const { bulkActionBtn } = useAppSelector(
    (state) => state.FeatureFlagReducer.manageContent,
  );
  const [Title, setTitle] = useState<string>("");
  const [Abbreviation, setAbbreviation] = useState<string>("");
  const [Category, setCategory] = useState<any>({});
  const [Description, setDescription] = useState<string>("");
  const [HyperlinkToNationalStockNumber, setHyperlinkToNationalStockNumber] =
    useState<string>("");
  const [NationalStockNumber, setNationalStockNumber] = useState<string>("");

  const [viewDetaislModal, setviewDetaislModal] =
    React.useState<boolean>(false);
  const [addModal, setAddModal] = React.useState<boolean>(false);

  const filteredCategoriesForEquipment: Category[] =
    filterCategoryByCategoryType(categoryList, "equipment");

  const initialCategoryId = filteredCategoriesForEquipment[0]?.Id || 0;

  const initialFormValues = {
    id: 0,
    label: "",
    abbreviation: "",
    categoryId: initialCategoryId,
    description: "",
    nationalStockNumber: "",
    hyperlinkToNationalStockNumber: "",
  };
  const [formValues, setFormValues] =
    useState<EquipmentForm>(initialFormValues);

  const initialFormErrors = {
    label: "",
  };
  const [formErrors, setFormErrors] = useState<any>(initialFormErrors);

  const [modalTitle, setModalTitle] = useState<string>("");

  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const validate = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (event.target.name === "label" && formValues.label.length === 0) {
      setFormErrors({
        label: "Please enter title",
      });
    } else {
      setFormErrors({ label: "" });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (e: number) => {
    setFormValues({ ...formValues, categoryId: e });
  };

  const error = (data: any) => {
    console.log(data);
    setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An error occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
  };

  const success = () => {
    setFormValues(initialFormValues);

    refreshEquipemnt();
    setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: "The equipment is successfully created",
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });
  };

  const resetForm = () => {
    setAddModal(false);
    setFormValues(initialFormValues);
    setFormErrors(initialFormErrors);
  };

  const add = () => {
    const {
      id,
      label,
      abbreviation,
      description,
      nationalStockNumber,
      hyperlinkToNationalStockNumber,
      categoryId,
    } = formValues;

    if (label.length > 0) {
      const newEquipment: EquipmentForm = {
        id: id || 0,
        label: label,
        abbreviation: abbreviation,
        description: description,
        nationalStockNumber: nationalStockNumber,
        hyperlinkToNationalStockNumber: hyperlinkToNationalStockNumber,
        categoryId: categoryId,
      };
      if (newEquipment.id !== 0) {
        UpdateEquipment(newEquipment, success, error);
      } else {
        CreateEquipment(newEquipment, success, error);
      }
    }
    resetForm();
  };

  const closeAddModal = () => {
    resetForm();
    setAddModal(false);
  };

  const deleteEquipment = (id: number) => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to delete the equipment",
      content: "Are you sure you want to delete this equipment permanently",
      okText: "Yes",
      onOk: () => {
        DeleteEquipment(id, success, error);
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: () => {
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };

  const detais = (equipment: EquipmentItem) => {
    setTitle(equipment.Title);
    setAbbreviation(equipment.Abbreviation);
    setCategory(equipment.Category);
    setDescription(equipment.Description);
    setHyperlinkToNationalStockNumber(equipment.HyperlinkToNationalStockNumber);
    setNationalStockNumber(equipment.NationalStockNumber);

    setviewDetaislModal(true);
  };

  const closeDetaisModal = () => {
    setviewDetaislModal(false);
  };

  const edit = (equipment: EquipmentItem, modalTitle: string) => {
    setModalTitle(modalTitle);
    setFormValues({
      id: equipment.Id,
      abbreviation: equipment.Abbreviation,
      categoryId: equipment.CategoryId,
      description: equipment.Description,
      label: equipment.Title,
      hyperlinkToNationalStockNumber: equipment.HyperlinkToNationalStockNumber,
      nationalStockNumber: equipment.NationalStockNumber,
    });

    setAddModal(true);
  };

  const [modalId, setModalId] = useState<any>(0);
  const [currentModal, setCurrentModal] = useState<any>(false);

  const showModalForAction = (e: any) => {
    const modalBodyElement = e.target.parentNode.nextElementSibling;
    const clickedScriptPosition = e.target.getBoundingClientRect().bottom;
    const tableBody = document.getElementsByClassName(
      "ant-table-tbody",
    ) as HTMLCollectionOf<HTMLElement>;
    if (modalBodyElement != null) {
      if (modalBodyElement.className === "modal-body") {
        modalBodyElement.style.display = "block";
        if (tableBody[0].getBoundingClientRect().y < window.innerHeight) {
          tableBody[0].style.minHeight = "70vh";
        }
        if (window.innerHeight - clickedScriptPosition < 300) {
          modalBodyElement.style.bottom = "103%";
          modalBodyElement.style.top = null;
        } else {
          modalBodyElement.style.top = "103%";
          modalBodyElement.style.bottom = null;
        }
        setCurrentModal(true);
        setModalId(modalBodyElement.id);
        if (modalId === modalBodyElement.id) {
          if (currentModal) {
            document.getElementById(modalId)!.style.display = "none";
            setCurrentModal(false);
          }
        }
        if (
          modalId != null &&
          modalId !== modalBodyElement.id &&
          document.getElementById(modalId) != null
        ) {
          document.getElementById(modalId)!.style.display = "none";
        }
      }
    }
  };

  const mockFunction = () => {
    console.log("mock");
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      render: (title: string) => (
        <>
          <span>{title}</span>
          <Row
            className="cell-icon-align-right"
            onClick={(e: any) => e.stopPropagation()}
          >
            <HeartOutlined
              onClick={mockFunction}
              style={{ fontSize: "21px" }}
            />
            {/* <HeartFilled className="color-light-blue" style={{ fontSize: "21px", float: "right" }} /> */}
          </Row>
        </>
      ),
      sorter: (a: any, b: any) => a.Title.localeCompare(b.Title),
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
      className: "whiteText",
      render: (category: any) => <span>{category.Name}</span>,
      sorter: (a: any, b: any) =>
        a.Category.Name.localeCompare(b.Category.Name),
    },
    {
      title: "Abbreviation",
      dataIndex: "Abbreviation",
      key: "Abbreviation",
      className: "whiteText",
      render: (abbriviation: string) => <span>{abbriviation}</span>,
      sorter: (a: any, b: any) =>
        a.Abbreviation !== null
          ? a.Abbreviation.localeCompare(b.Abbreviation)
          : 0,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      className: "whiteText",
      render: () => (
        <Row onClick={(e: any) => e.stopPropagation()}>
          <LikeOutlined
            disabled
            onClick={mockFunction}
            style={{ fontSize: "2rem" }}
            className="color-light-green"
          />
          {/* <InfoCircleOutlined style={{ fontSize: "2rem" }} className="color-yellow" />
          <DislikeOutlined style={{ fontSize: "2rem" }} className="color-red" /> */}
        </Row>
      ),
    },
    {
      title: "Visibility",
      dataIndex: "Visibility",
      key: "Visibility",
      className: "whiteText",
      render: () => (
        <div onClick={(e: any) => e.stopPropagation()}>
          <EyeOutlined
            disabled
            onClick={mockFunction}
            style={{ fontSize: "2rem" }}
            className="color-light-blue"
          />
          {/* <EyeInvisibleOutlined style={{ fontSize: "2rem" }} className="color-grey" /> */}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      className: "whiteText",
      render: (Id: any, equipment: any) => {
        return (
          <Row
            justify="space-between"
            onClick={(e: any) => e.stopPropagation()}
          >
            <Tooltip placement="bottom" title="">
              {auth.user.type === "root" ||
              auth.user.type.includes("root") ||
              equipment.CreatedBy === auth.user.nameid ? (
                <div className={"modal-container"}>
                  <MoreOutlined
                    className="actions-item action-more"
                    onClick={showModalForAction}
                  />
                  <div className={"modal-body"} id={equipment.Id}>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {auth.user.type === "root" ||
                      auth.user.type.includes("root") ||
                      equipment.CreatedBy === auth.user.nameid ? (
                        <div
                          onClick={() => {
                            edit(equipment, "Edit Equipment");
                          }}
                        >
                          <EditOutlined className="actions-item action-edit" />
                          <div className={"tooltip-action-text"}>Edit</div>
                        </div>
                      ) : (
                        <EditOutlinedIcons className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    {/* change properties for forking */}
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {auth.user.type === "root" ||
                      auth.user.type.includes("root") ||
                      equipment.CreatedBy === auth.user.nameid ? (
                        <div
                          onClick={() => {
                            window.location.href = `/CreateScript/${equipment.Id}`;
                          }}
                        >
                          <ForkOutlined className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Fork</div>
                        </div>
                      ) : (
                        <ForkOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {auth.user.type === "root" ||
                      auth.user.type.includes("root") ||
                      equipment.CreatedBy === auth.user.nameid ? (
                        <div
                          onClick={() => {
                            window.location.href = `/CreateScript/${equipment.Id}`;
                          }}
                        >
                          <LikeOutlined className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Approve</div>
                        </div>
                      ) : (
                        <LikeOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {auth.user.type === "root" ||
                      auth.user.type.includes("root") ||
                      equipment.CreatedBy === auth.user.nameid ? (
                        <div
                          onClick={() => {
                            window.location.href = `/CreateScript/${equipment.Id}`;
                          }}
                        >
                          <SearchOutlined className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Review</div>
                        </div>
                      ) : (
                        <SearchOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {auth.user.type === "root" ||
                      auth.user.type.includes("root") ||
                      equipment.CreatedBy === auth.user.nameid ? (
                        <div
                          onClick={() => {
                            window.location.href = `/CreateScript/${equipment.Id}`;
                          }}
                        >
                          <GoArchive className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Archive</div>
                        </div>
                      ) : (
                        <ForkOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {auth.user.type === "root" ||
                      auth.user.type.includes("root") ||
                      equipment.CreatedBy === auth.user.nameid ? (
                        <div onClick={() => deleteEquipment(equipment.Id)}>
                          <DeleteOutlined className="actions-item action-delete" />
                          <div className={"tooltip-action-text"}>Delete</div>
                        </div>
                      ) : (
                        <DeleteIcon className="disable-icon" />
                      )}
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <DeleteIcon className="disable-icon" />
              )}
            </Tooltip>
          </Row>
        );
      },
    },
  ];
  const onChange: TableProps<any>["onChange"] = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any,
  ) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <span className="menu-item">
              <LikeOutlined className="disable-icon" /> &nbsp; Approve
            </span>
          ),
        },
        {
          key: "2",
          label: (
            <span className="menu-item">
              <SearchOutlined className="disable-icon" /> &nbsp; Review
            </span>
          ),
        },
        {
          key: "3",
          label: (
            <span className="menu-item">
              <GoArchive className="disable-icon" /> &nbsp; Archive
            </span>
          ),
        },
        {
          key: "4",
          label: (
            <span className="menu-item">
              <DeleteOutlined className="disable-icon" /> &nbsp; Delete
            </span>
          ),
        },
      ]}
    />
  );

  return (
    <div data-testid="allergy-list" className="allergy-list-container">
      <Row className="flex-container-align-item-right">
        <Button
          className="btn btn-blue"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalTitle("Add Equipment");
            setAddModal(true);
          }}
        >
          Add New
        </Button>
        {bulkActionBtn && (
          <Dropdown className="btn btn-grey" overlay={menu}>
            <Button>Bulk Actions</Button>
          </Dropdown>
        )}
      </Row>

      <Table
        dataSource={equipmentList}
        columns={columns}
        rowKey={(record: any) => record.Id}
        rowClassName={() => "rxblackBG"}
        showSorterTooltip={false}
        pagination={{
          hideOnSinglePage: true,
          pageSize: equipmentList.length,
        }}
        onChange={onChange}
        sortDirections={["ascend", "descend", "ascend"]}
        onRow={(record: any) => {
          return {
            onClick: () => {
              detais(record);
            }, // click row
            onDoubleClick: () => undefined, // double click row
            onContextMenu: () => undefined, // right button click row
            onMouseEnter: () => undefined, // mouse enter row
            onMouseLeave: () => undefined, // mouse leave row
          };
        }}
      />

      <Drawer
        className="custom-css-ant-drawer"
        visible={addModal}
        onClose={add}
        closable
        width="600"
      >
        <h1>
          <ArrowRightOutlined
            style={{ marginRight: "20px" }}
            onClick={closeAddModal}
          />
          {modalTitle}
        </h1>
        <FormContainer>
          <StyledForm>
            {/* <MuiInput
              name="label"
              value={formValues.label}
              label="Title *"
              onChange={handleInputChange}
              onBlur={validate}
              error={formErrors.label}
            />
            <MuiInput
              name="abbreviation"
              value={formValues.abbreviation}
              label="Abbreviation"
              onChange={handleInputChange}
            />
            <MuiText
              name="description"
              value={formValues.description}
              label="Description"
              onChange={handleTextAreaChange}
            />
            <MuiInput
              name="nationalStockNumber"
              value={formValues.nationalStockNumber}
              label="NSN"
              onChange={handleInputChange}
            />
            <MuiInput
              name="hyperlinkToNationalStockNumber"
              value={formValues.hyperlinkToNationalStockNumber}
              label="NSN URL"
              onChange={handleInputChange}
            /> */}
            <FloatingLabel>
              <Input
                value={formValues.label}
                placeholder=" "
                name="label"
                type="text"
                onChange={handleInputChange}
                onBlur={validate}
                style={{ marginBottom: "30px" }}
              />
              <label htmlFor="label">Title *</label>
              {formErrors.label && (
                <span className="text-error">{formErrors.label}</span>
              )}
            </FloatingLabel>
            <FloatingLabel>
              <Input
                value={formValues.abbreviation}
                placeholder=" "
                name="abbreviation"
                type="text"
                onChange={handleInputChange}
                style={{ marginBottom: "30px" }}
              />
              <label htmlFor="abbreviation">Abbreviation</label>
              {formErrors.abbreviation && (
                <span className="text-error">{formErrors.abbreviation}</span>
              )}
            </FloatingLabel>
            <FloatingLabel>
              <Input
                value={formValues.description}
                placeholder=" "
                name="description"
                type="text"
                onChange={handleInputChange}
                style={{ marginBottom: "30px" }}
              />
              <label htmlFor="description">Description</label>
              {formErrors.description && (
                <span className="text-error">{formErrors.description}</span>
              )}
            </FloatingLabel>
            <FloatingLabel>
              <Input
                value={formValues.nationalStockNumber}
                placeholder=" "
                name="nationalStockNumber"
                type="text"
                onChange={handleInputChange}
                style={{ marginBottom: "30px" }}
              />
              <label htmlFor="nationalStockNumber">NSN</label>
              {formErrors.nationalStockNumber && (
                <span className="text-error">
                  {formErrors.nationalStockNumber}
                </span>
              )}
            </FloatingLabel>
            <FloatingLabel>
              <Input
                value={formValues.hyperlinkToNationalStockNumber}
                placeholder=" "
                name="hyperlinkToNationalStockNumber"
                type="text"
                onChange={handleInputChange}
                style={{ marginBottom: "30px" }}
              />
              <label htmlFor="nsnurl">NSN URL</label>
              {formErrors.hyperlinkToNationalStockNumber && (
                <span className="text-error">
                  {formErrors.hyperlinkToNationalStockNumber}
                </span>
              )}
            </FloatingLabel>
            <Form.Item>
              <label htmlFor="category">Category</label>
              <Select
                value={formValues.categoryId}
                onChange={handleSelectChange}
                placeholder="Select a category"
              >
                {filteredCategoriesForEquipment.map((category: Category) => {
                  return (
                    <Option key={category.Id} value={category.Id}>
                      {category.Name}
                    </Option>
                  );
                })}
              </Select>
              {formErrors.category && (
                <span className="text-error">{formErrors.category}</span>
              )}
            </Form.Item>
            {/* <Row style={{ marginTop: "10px" }}>
              <p>Select Category:</p>
              <Select
                allowClear={true}
                value={formValues.categoryId}
                onChange={handleSelectChange}
                style={{ width: "100%" }}
              >
                {filteredCategoriesForEquipment.map((category: Category) => {
                  return (
                    <Option key={category.Id} value={category.Id}>
                      {category.Name}
                    </Option>
                  )
                }
                )}
              </Select>
            </Row> */}
          </StyledForm>
        </FormContainer>
      </Drawer>

      <Drawer
        className="custom-css-ant-drawer"
        visible={viewDetaislModal}
        onClose={closeDetaisModal}
        closable
        width="600"
      >
        <h1>
          <ArrowRightOutlined
            style={{ marginRight: "20px" }}
            onClick={closeDetaisModal}
          />{" "}
          {Title} <HeartOutlined style={{ float: "right" }} />
        </h1>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">Abbreviation</p>
          </Col>
          <Col span={18}>
            <p>{Abbreviation}</p>
          </Col>
        </Row>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">Category</p>
          </Col>
          <Col span={18}>
            <p>{Category.Name}</p>
          </Col>
        </Row>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">NSN </p>
          </Col>
          <Col span={18}>
            <p>
              {NationalStockNumber} &nbsp;{" "}
              <a
                href={HyperlinkToNationalStockNumber}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SelectOutlined
                  className="icon-flipped"
                  style={{ fontSize: "2rem" }}
                />
              </a>
            </p>
          </Col>
        </Row>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">Description</p>
          </Col>
          <Col span={18}>
            <p>{Description}</p>
          </Col>
        </Row>
      </Drawer>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </div>
  );
};

function mapToStateToProps(state: any) {
  return {
    SystemMessage: state.SystemMessage,
    auth: state.authReducer,
  };
}

export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(EquipmentList);
