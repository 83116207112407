import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import thunkMiddleware from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { History } from "history";
import { ApplicationState, reducers } from "./";
import authReducer from "./authSlice";
import SystemMessage from "./SystemMessage";
import TreatmentReducer from "./TreatmentReducer";
import InsultInjury from "./CasualtyCreator/InsultInjury";
import CasualtyCreator from "./CasualtyCreator";
import TextBlockReducer from "./TextBlockReducer";
import MainlistReducer from "../../src/pages/ScriptBuilder/MainlistSlice";
import CasualtyStatusReducer from "./CasualtyStatusReducer";
import MiscReducer from "../pages/ScriptBuilder/EditComponents/Miscellaneous/MiscellaneousSlice";
import DiagnosticReducer from "../../src/pages/ScriptBuilder/EditComponents/DiagnosticEdit/DiagnosticSlice";
import MedicationReducer from "../../src/pages/ScriptBuilder/EditComponents/MedicationEdit/MedicationSlice";
import ResearchQuestionReducer from "../../src/pages/ScriptBuilder/EditComponents/ResearchQuestion/ResearchQuestionSlice";
import scriptBuilderReducer from "../store/ScriptBuilder/scriptBuilderSlice";
import AfterActionReviewReducer from "../pages/Home/AfterActionReview/AfterActionReviewSlice";
import fileUploadReducer from "../components/FileUpload/FileUploadSlice";
import HeadReducer from "../app/HeadSlice";
import DashboardReducer from "../../src/pages/Home/DashboardSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import FeatureFlagReducer from "./FeatureFlagReducer";
import MasterResearchQuestionReducer from "../pages/CreateMasterObjects/Components/ResearchQuestion/ResearchQuestionSlice";
import MasterMiscellaneousReducer from "../pages/CreateMasterObjects/Components/Miscellaneous/MiscellaneousSlice";
import MasterTreatmentReducer from "../pages/CreateMasterObjects/Components/Treatments/TreatmentSlice";
import MasterDiagnosticReducer from "../pages/CreateMasterObjects/Components/Diagnostics/DiagnosticSlice";
import MasterMedicationReducer from "../pages/CreateMasterObjects/Components/Medications/MedicationSlice";

export default function configureStore(
  history: History,
  initialState?: ApplicationState,
) {
  const middleware = [thunk, routerMiddleware(history)];

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  const enhancers = [];
  const windowIfDefined =
    typeof window === "undefined" ? null : (window as any);
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  );
}

const history = require("history").createBrowserHistory();

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [],
};

const MainListPersistConfig = {
  key: "MainList",
  storage: storageSession,
  whitelist: [
    "tempCurrentPhaseIndex",
    "tempActiveComponent",
    "tempCurrentSectionIndex",
    "tempCurrentBlockIndex",
  ],
};

const AuthPersistConfig = {
  key: "Auth",
  storage: storageSession,
  blacklist: ["user", "company"],
};

const rootReducer = combineReducers({
  authReducer: persistReducer(AuthPersistConfig, authReducer),
  DashboardReducer: DashboardReducer,
  SystemMessage: SystemMessage,
  CasualtyCreator: CasualtyCreator,
  InsultInjury: InsultInjury,
  TreatmentReducer: TreatmentReducer,
  TextBlockReducer: TextBlockReducer,
  MainlistReducer: persistReducer(MainListPersistConfig, MainlistReducer),
  CasualtyStatusReducer: CasualtyStatusReducer,
  MiscReducer: MiscReducer,
  ResearchQuestionReducer: ResearchQuestionReducer,
  DiagnosticReducer: DiagnosticReducer,
  MedicationReducer: MedicationReducer,
  HeadReducer: HeadReducer,
  scriptBuilderReducer: scriptBuilderReducer,
  AfterActionReviewReducer: AfterActionReviewReducer,
  FileUploadReducer: fileUploadReducer,
  FeatureFlagReducer: FeatureFlagReducer,
  MasterResearchQuestionReducer: MasterResearchQuestionReducer,
  MasterMiscellaneousReducer: MasterMiscellaneousReducer,
  MasterTreatmentReducer: MasterTreatmentReducer,
  MasterDiagnosticReducer: MasterDiagnosticReducer,
  MasterMedicationReducer: MasterMedicationReducer,
  router: connectRouter(history),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk, thunkMiddleware)),
);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
