import React from "react";
import {
  BooleanParameter,
  InsultWithRelation,
  IntervalParameter,
  NumberParameter,
  TextParameter,
} from "../../../../../../models/InsultInterface";
import { NumberParameterWithTimeInterval } from "../CommonData";
import BooleanParameterForm from "./EditParameters/BooleanParameterForm";
import IntervalParameterForm from "./EditParameters/IntervalParameterForm";
import LocationParameter from "./EditParameters/LocationParameter";
import NumberParameterForm from "./EditParameters/NumberParameterForm";
import TextParameterForm from "./EditParameters/TextParameterForm";
import TimeNumberParameterForm from "./EditParameters/TimeNumberParameterForm";
import { useAppSelector } from "../../../../../../store/hooks";
import { ShowInsultParameter } from "../InjuryMapUtills";
type Props = {
  formik: any;
  insult: InsultWithRelation;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
};

const InsultForm = ({
  insult,
  formik,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
}: Props) => {
  const { BackRedirectScreen } = useAppSelector((state) => state.InsultInjury);
  const injuryObj = useAppSelector((state) => state.InsultInjury.InjuryObj);
  const isLocationDisableForInjuryInsult = !!injuryObj.Title;
  return (
    <form onSubmit={formik.handleSubmit} style={{ paddingTop: "15px" }}>
      {insult.BodyParts &&
      insult.BodyParts.length &&
      !isLocationDisableForInjuryInsult ? (
        <LocationParameter
          formik={formik}
          insult={insult}
          isLocationDisableForInjuryInsult={isLocationDisableForInjuryInsult}
          bodyParts={insult.BodyParts}
        />
      ) : null}

      {insult.TextParameters && insult.TextParameters.length
        ? insult.TextParameters.map((param: TextParameter) => {
            return (
              <TextParameterForm
                key={"text_param_" + param.Id}
                formik={formik}
                param={param}
                insult={insult}
                lastFocused={lastFocused}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
              />
            );
          })
        : null}

      {insult.BooleanParameters && insult.BooleanParameters.length
        ? insult.BooleanParameters.map((param: BooleanParameter) => {
            return (
              <BooleanParameterForm
                key={"boolean_param_" + param.Id}
                formik={formik}
                param={param}
                insult={insult}
              />
            );
          })
        : null}

      {/* number parameter without time interval */}
      {insult.NumberParameters && insult.NumberParameters.length
        ? insult.NumberParameters.filter(
            (param: NumberParameter) =>
              ShowInsultParameter(param) &&
              !NumberParameterWithTimeInterval.includes(param.UserFacingLabel),
          ).map((param: NumberParameter) => {
            return (
              <NumberParameterForm
                key={`number_param_${insult.Label}_${param.UserFacingLabel}`}
                formik={formik}
                param={param}
                insult={insult}
                lastFocused={lastFocused}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
              />
            );
          })
        : null}

      {/* number parameter with time interval */}
      {insult.NumberParameters && insult.NumberParameters.length
        ? insult.NumberParameters.filter((param: NumberParameter) =>
            NumberParameterWithTimeInterval.includes(param.UserFacingLabel),
          ).map((param: NumberParameter) => {
            return (
              <TimeNumberParameterForm
                key={`number_param_${insult.Label}_${param.UserFacingLabel}`}
                formik={formik}
                param={param}
                insult={insult}
                lastFocused={lastFocused}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
              />
            );
          })
        : null}

      {insult.IntervalParameters && insult.IntervalParameters.length
        ? insult.IntervalParameters.map((param: IntervalParameter) => {
            return (
              <IntervalParameterForm
                key={"interval_param_" + param.Id}
                formik={formik}
                param={param}
                insult={insult}
                lastFocused={lastFocused}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
              />
            );
          })
        : null}
    </form>
  );
};

export default InsultForm;
