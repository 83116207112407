import * as React from "react";
import SwitchWithLabel from "../Switch/SwitchWithLabel";
import { MultipleSwitchContainer } from "../../styles/MainList.styled";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreatorsForTreatment } from "../../store/index";
import { FormikProps } from "formik";
import { useEffect } from "react";
import DurationInput from "../../pages/ScriptBuilder/EditComponents/MedicationEdit/MedicationForm/DurationInput";

interface IntervalProps {
  handleIntervalToggle: Function;
  isIntervalEnabled: boolean;
  handleOptionToggle: Function;
  scriptFormik: FormikProps<any>;
  interval: number;
  isReadOnly: boolean;
}

export const IntervalTA = (props: IntervalProps) => {
  const state = useSelector((state: any) => state.TreatmentReducer);
  const dispatch = useDispatch();
  const { updateInterval } = bindActionCreators(
    actionCreatorsForTreatment,
    dispatch,
  );

  useEffect(() => undefined, [state.interval]);

  const handleIntervalDurationChange = (field: string, value: any) => {
    if (props.isReadOnly) return; //disable action callback in read only script
    props.handleIntervalToggle("IntervalTime", value);
    updateInterval(value);
  };

  return (
    <div className="durationContainer">
      <MultipleSwitchContainer
        className="durationToggleContainer"
        isSecondSwitchEnabled={false}
        isFirstSwitchEnabled={props.isIntervalEnabled}
      >
        <div
          className="duration"
          onClick={() => props.handleIntervalToggle("IsIntervalEnabled")}
        >
          <SwitchWithLabel
            isForTreatment={true}
            rightLabel={"Interval"}
            checked={props.isIntervalEnabled}
            onChange={() => undefined}
            disabled={props.isReadOnly}
          />
        </div>
        {props.isIntervalEnabled && (
          <DurationInput
            disabled={props.isReadOnly}
            label={"interval"}
            field={"interval"}
            displayOptionLabel={false}
            value={props.interval}
            maxhours={17}
            onChange={handleIntervalDurationChange}
          />
        )}
      </MultipleSwitchContainer>
    </div>
  );
};
