import produce from "immer";
import {
  CASUALTY_CREATOR_RESET_TO_INITIAL_STATE,
  CASUALTY_CREATOR_SET_CASUALTY_FORM,
  CASUALTY_CREATOR_SET_CURRENT_CASUALTY_CREATOR_TAB,
  CASUALTY_CREATOR_SET_CUSTOM_HEADING,
  CASUALTY_CREATOR_SET_FLAG_TO_DISPLAY_HEADING,
  CASUALTY_CREATOR_UPDATE_CASUALTY_FORM,
} from "./actionType";
import { initialState } from "./initialState";

export default produce((draft, action) => {
  if (!draft) {
    return initialState;
  }
  switch (action.type) {
    case CASUALTY_CREATOR_SET_FLAG_TO_DISPLAY_HEADING: {
      draft.DisplayCustomHeading = action.data;
      break;
    }
    case CASUALTY_CREATOR_SET_CUSTOM_HEADING: {
      draft.CurrentScreenHeading = action.data;
      break;
    }
    case CASUALTY_CREATOR_SET_CURRENT_CASUALTY_CREATOR_TAB: {
      draft.CurrentCasualtyCreatorTab = action.data;
      break;
    }
    case CASUALTY_CREATOR_SET_CASUALTY_FORM: {
      draft.CasualtyForm = action.data;
      break;
    }
    case CASUALTY_CREATOR_UPDATE_CASUALTY_FORM: {
      draft.CasualtyForm[action.field] = action.value;
      break;
    }
    case CASUALTY_CREATOR_RESET_TO_INITIAL_STATE: {
      return initialState;
    }
    default:
      break;
  }
});
