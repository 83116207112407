import * as React from "react";
import { Switch as AntSwitch } from "antd";
import { SwitchProps } from "antd/lib/switch";
import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";

const StyledAntSwitch = styled(AntSwitch)`
  background-color: rgba(255, 255, 255, 0.1);
  background-image: none;
  box-shadow: 0 0 0 1px #fff !important;
  outline: none;
  .ant-switch-handle::before {
    background-color: #fff;
  }
  &.ant-switch-checked {
    box-shadow: 0 0 0 1px ${colorPalette.primary75} !important;
    background-color: ${colorPalette.primary25};
    .ant-switch-handle::before {
      background-color: ${colorPalette.primary100};
    }
  }
`;

const Switch = (props: SwitchProps) => {
  return <StyledAntSwitch {...props} />;
};

export default Switch;
