import { TeamOutlined } from "@ant-design/icons";
import React from "react";
import { StyledTeamBlock } from "./Teams.styled";

type ITeam = {
  UnitLabel: string;
  MosName: string;
  Team: string[];
};
type Props = {
  data: ITeam;
};
export default function Team({ data }: Props) {
  return (
    <StyledTeamBlock>
      <p>{`${data.UnitLabel} -> ${data.MosName}`}</p>
      <ul>
        {data.Team.map((t) => {
          return (
            <li>
              <TeamOutlined /> {t}
            </li>
          );
        })}
      </ul>
    </StyledTeamBlock>
  );
}
