import React from "react";
import { PaneBody } from "../../ScriptBuilder/EditComponents/ScriptDetailsView/styled";
import { RightPaneWrapper, UserFormWrapper } from "../User.styled";
import Collaborators from "./Collaborators/Collaborators";
import Teams from "./Teams/Teams";
import UserForm from "./UserForm/UserForm";
import { useAppSelector } from "../../../store/hooks";
import { Tooltip } from "antd";

export default function UserProfile() {
  const { myTeamsPanel } = useAppSelector(
    (state) => state.FeatureFlagReducer.manageAccountMenu.myProfile,
  );
  return (
    <div className="d-flex">
      <UserFormWrapper>
        <UserForm />
      </UserFormWrapper>
      {myTeamsPanel && (
        <Tooltip title={`${myTeamsPanel ? " Hidden in Prod" : ""}`}>
          <RightPaneWrapper>
            <PaneBody nopadding>
              <Teams />
              <Collaborators />
            </PaneBody>
          </RightPaneWrapper>
        </Tooltip>
      )}
    </div>
  );
}
