import styled from "styled-components";

export const CustomTitle = styled.div`
  font-size: 1.25rem;
  padding: 0 15px;
  color: #ffffff;
  height: 2.9rem;
  display: inline-flex;
  align-items: center;
`;
