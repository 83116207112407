import axios from "axios";
import { defaultErrorCallback } from "./ErrorCallback";

const apiPrefix = "/api/2/pfc/timed-action-task";

export function archiveOrUnarchiveMasterTimedAction(
  masterTimedActionTaskId: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(`${apiPrefix}/master/${masterTimedActionTaskId}/archive`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data.ErrorMessage);
      }
    });
}

export function deleteSubtaskForTimedAction(
  subTaskId: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .delete(`${apiPrefix}/subtask/${subTaskId}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err);
      }
    });
}

export function deleteMediaForTimedActionTask(
  timedActionTaskMediaId: number,
  mediaId: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .delete(
      `${apiPrefix}/attached-media?timedActionTaskId=${timedActionTaskMediaId}&mediaId=${mediaId}`,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}
