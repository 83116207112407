import styled from "styled-components";
import { colorPalette } from "../../../../../styles/MainList.styled";

export const SectionDivStyle = styled.div`
  background-color: ${colorPalette.gray100};
  margin: 2px 0px;
  border: 1px solid transparent;
  border-top: 1px solid ${colorPalette.gray50};
  border-bottom: 2px solid ${colorPalette.gray50};

  .SectionHeading {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    font-size: 1.6em;
    line-height: 1.6em;
    margin: 0 0 8px;
    padding: 0;
  }

  .section-header-text {
    display: flex;
  }

  .section-drag-icon {
    color: ${colorPalette.gray75};
  }
`;

export const SectionLabel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1em;

  .section-drag-icon,
  .section-expand-collapse-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-index-title {
    display: flex;
  }

  .section-drag-icon,
  .hidden-drag-icon {
    margin: 0 5px 8px;
    padding: 0;
  }

  .hidden-drag-icon {
    visibility: hidden;
    height: 0;
  }

  .section-header-text {
    flex-grow: 1;
  }

  .section-number {
    margin: 0;
    padding: 0;
    color: ${colorPalette.primary100};
    font-size: 1em;
  }

  .collapse-icon,
  .expand-icon {
    padding: 15px;

    &:hover {
      color: #ffffff;
    }

    &:active {
      color: #24d2ed;
    }
  }
`;
