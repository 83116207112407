import * as React from "react";
import styled from "styled-components";
import { Divider } from "antd";
import {
  HolderOutlined,
  PlusCircleOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import {
  ScriptNewTabIcons,
  SendOutlinedIcons,
} from "../../../IconsTheme/Icons.global";
import { colorPalette } from "../../../styles/MainList.styled";

export const StyledSuccessRating = styled.div`
{
    display: flex;
    flex-direction: row;

    & .success-rating {
        display: flex;
        flex-direction: row;
        height: 26px;
        p {
        height: 20px;
        font-size: 14px;
        margin-left: 8px;
        }
    }
    & .success-rating div:first-child {
        border-radius: 4px 0px 0px 4px;
    }
    & .success-rating div:last-child {
        border-radius: 0px 4px 4px 0px;
    }
}`;

export const StyledScenarioInfo = styled.div`
{
    .scenario-info-btn {
        height: 26px;
        width: 116px;
        padding: 0px 5px;
    }
}`;

export const StyledDivider = styled(Divider)`
{
    background-color: #C4C4C4;
    width: 200px;
    height: 1px;
    margin-top: 10px         
}`;

export const StyledHolderOutlined = styled(HolderOutlined)`
{
    font-size: 1.125rem;
    color: rgb(255 255 255/0.5);
    margin-left: 10px;        
}`;

export const StyledDetailsNewTabIcon = styled(ScriptNewTabIcons)`
{
    font-size: 1.125rem;
    width: 20px;
    height: 15px;       
}`;

export const StyledDetailsPlusCircleOutlined = styled(PlusCircleOutlined)`
{
    width: 25px;
    height: 25px;
    font-size: 1.125rem;       
}`;

export const StyledDetailsSendOutlinedIcon = styled(SendOutlinedIcons)`
{
    float: right;
    width: 25px;
    height: 25px;
    color: ${colorPalette.primary100};
    font-size: 1.125rem;      
}`;

export const StyledArrowRightOutlined = styled(ArrowRightOutlined)`
{
    float: left;
    font-size: 1.125rem;
    width: 36px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
}`;

export const ScriptReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PhaseContainer = styled.div``;

export const PhaseTagContainer = styled.div`
  display: flex;

  > p + p {
    margin-left: 8px;
  }
`;

export const StyledPhaseTag = styled.p`
{
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 4px 15px;
    min-width: 80px;
}`;

export const TagContainer = styled.div``;

export const ScriptTagContainer = styled.div`
  display: flex;

  > p + p {
    margin-left: 8px;
  }
`;

export const StyledScriptTag = styled.p`
{
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    border-radius: 20px;
    padding: 5px 15px;
    border: none;
    min-width: 80px;
    &.plus-sign {
        min-width: 0px;
        width: 25px;
        height: 25px;
        padding: 0px;
        text-align: center;
        border-radius: 50px;
        background-color: #262626;
        font-size: 16px;
      }
}`;

export const CommentContainer = styled.div``;
