export const ColorPalette = {
  White: "#FFFFFF", // hovered btns & inputs border & text
  Gray1: "#DDDDDD", // filled input border & text
  Gray2: "#9A9A9A", // default input border & text
  Gray3: "#363636",
  Gray4: "#1F1F1F", // disabled, selected list, hovered white btn background
  Gray5: "#1A1A1A", // hovered list, ghost
  Gray6: "#131313", // default list
  Gray7: "#0D0D0D", // background
  Blue1: "#23D0EB", // btn border, .primary, .active, .selected
  Blue2: "#0E4D56", // btn background hover, .primary, .active, .selected
  Blue3: "#052125", // btn background default .primary, .active, .selected
  Blue4: "#08191B", // .primary, .active, .selected
  Green1: "#7AFC17", // btn border, .secondary, .save, .success, .easy, .open
  Green2: "#314F1B", // btn background hover, .secondary, .save, .success, .easy, .open
  Green3: "#203411", // btn background default, .secondary, .save, .success, .easy, .open
  Green4: "#12190D", // .secondary, .save, .success, .easy, .open
  Yellow1: "#E6CF09", // btn border, .moderate1
  Yellow2: "#4F4804", // btn background hover, .moderate1
  Yellow3: "#373202", // btn background default, .moderate1
  Orange1: "#FEA40A", // btn border, .moderate2, .warning, .locked
  Orange2: "#553704", // btn background hover, .moderate2, .warning, .locked
  Orange3: "#301F02", // btn background default, .moderate2, .warning, .locked
  Orange4: "#19140C", // .moderate2, .warning, .locked
  Red1: "#FF4242", // btn border, .error, .delete
  Red2: "#471414", // btn background hover, .error, .delete
  Red3: "#330D0D", // btn background default, .error, .delete
  Red4: "#210606", // .error, .delete
};
