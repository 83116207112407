import * as React from "react";
interface ScriptAverageTime {
  averageTime: string;
}
export const ScriptAverageTime = (props: ScriptAverageTime) => {
  const { averageTime } = props;
  return (
    <div className="script-avg-time">
      <h5>Average Time</h5>
      <div>{averageTime}</div>
    </div>
  );
};
