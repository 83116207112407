import * as React from "react";

interface ParticipantProps {
  participants: number;
}

export const ScriptParticipants = (props: ParticipantProps) => {
  const { participants } = props;
  return (
    <div className="script-participants">
      <h5>Participants</h5>
      <div>{participants}</div>
    </div>
  );
};
