import * as React from "react";
import { FooterWithButtons, GreenButton, GreyButton } from "../styled";
import {
  ArrowLeftOutlined,
  PlusCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { StyledBody, StyledInsultItem, WhiteButton } from "./InjuryTab.styled";
import {
  BodyPart,
  InsultWithRelation,
  Location,
} from "../../../../../models/InsultInterface";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../../../../store/hooks";
import { AppliedInjury } from "../../../../../models/InjuryInterface";
import ConfirmDialog from "../../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../../components/ConfirmDialog/useConfirmDialog";
import { getLocationsBasedOnInsult } from "./InsultInjuryUtility";

type CreateEditInjuryProps = {
  onBack: Function;
  onSave: Function;
  formik: any;
  onSubmitForm: Function;
  handleInsultSelection: Function;
};

const CreateEditInjury = ({
  onBack,
  onSave,
  formik,
  onSubmitForm,
  handleInsultSelection,
}: CreateEditInjuryProps) => {
  const insultList = useSelector(
    (state: any) => state.InsultInjury.FilteredInsults,
  );
  const filteredAppliedInjuries = useAppSelector(
    (state: any) => state.InsultInjury.FilteredAppliedInjuries,
  );
  const displayAllInjuryInsult = useSelector(
    (state: any) => state.InsultInjury.DisplayAllInjuryInsult,
  );

  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const matchInsultWithBioGear = (insultTitle: string) => {
    const MissingFromBioGear = [
      "Fracture",
      "Compartment Syndrome",
      "Chemical Exposure",
      "Large Pneumothorax",
      "Open Pneumothorax",
      "Breath Hold",
      "Forced Exhale",
      "Forced Inhale",
    ];
    return MissingFromBioGear.findIndex((x) => x == insultTitle) !== -1
      ? true
      : false;
  };

  // Checks if an insult belongs to the specified injury location.
  const isInsultBelongsToInjuryLocation = (bodyParts: BodyPart[]) => {
    // If no location is specified, return true
    if (!Number(formik.values.locationId)) return true;

    // Check if any body part has a location with the specified locationId
    if (
      bodyParts.length &&
      bodyParts.some((bodyPart: BodyPart) =>
        bodyPart.Locations.some(
          (location: Location) =>
            location.Id === Number(formik.values.locationId),
        ),
      )
    ) {
      return true;
    }

    return false;
  };

  // Gets filtered insults based on the specified injury location.
  const getFilteredInsults = () => {
    const insultsWithFilteredLocations = insultList.map(
      (insult: InsultWithRelation) => {
        // Create a new object with modified BodyParts
        const modifiedInsult = {
          ...insult,
          BodyParts: insult.BodyParts.map((bodyPart: BodyPart) => ({
            ...bodyPart,
            Locations: getLocationsBasedOnInsult(insult, bodyPart),
          })),
        };

        return modifiedInsult;
      },
    );

    return insultsWithFilteredLocations.filter(
      (x: InsultWithRelation) =>
        displayAllInjuryInsult || isInsultBelongsToInjuryLocation(x.BodyParts),
    );
  };

  const onInsultSelection = (insult: InsultWithRelation) => {
    if (matchInsultWithBioGear(insult.Label)) {
      return;
    }

    filteredAppliedInjuries.map((appliedInjury: AppliedInjury) => {
      // Check if insult already applied to the injury.
      if (
        appliedInjury.Insults.find(
          (appliedInsult: InsultWithRelation) =>
            appliedInsult.Label === insult.Label,
        )
      ) {
        const appliedInsult = structuredClone(
          appliedInjury.Insults.find(
            (appliedInsult: InsultWithRelation) =>
              appliedInsult.Label === insult.Label,
          ),
        );
        if (appliedInsult) appliedInsult.Locations = insult.Locations;
        setIsOpenConfirmDialog(true);
        setConfirmDialogProps({
          title: "Insult",
          content: `${appliedInsult?.Label} already exist. Would you like to edit existing one?`,
          okText: "Yes",
          onOk: () => {
            handleInsultSelection(appliedInsult); // Edit existing Insult.
            setIsOpenConfirmDialog(false);
          },
          cancelText: "No",
          onCancel: () => {
            handleInsultSelection(insult);
            setIsOpenConfirmDialog(false);
          }, // Add new insult.
          type: "warning",
        });
      }
    });
    handleInsultSelection(insult);
  };
  return (
    <>
      <StyledBody
        style={{
          height: `calc(100vh - 64px - 55px - 60px - 60px - 92px - 82px)`,
        }}
      >
        {insultList && insultList.length ? (
          <ul>
            {getFilteredInsults().map((insult: InsultWithRelation) => {
              return (
                <StyledInsultItem
                  key={insult.CopySourceInsultId}
                  className={
                    matchInsultWithBioGear(insult.Label) ||
                    !isInsultBelongsToInjuryLocation(insult.BodyParts)
                      ? `disabled`
                      : ``
                  }
                  onClick={() => onInsultSelection(insult)}
                  $insult={insult}
                >
                  <div>{insult.Label}</div>
                  <Tooltip title={`Add & Edit Insult`}>
                    <WhiteButton
                      type="link"
                      icon={<PlusCircleOutlined />}
                      size="large"
                    ></WhiteButton>
                  </Tooltip>
                </StyledInsultItem>
              );
            })}
          </ul>
        ) : null}
      </StyledBody>
      <FooterWithButtons>
        <GreyButton htmlType="button" onClick={() => onBack()}>
          <ArrowLeftOutlined /> &nbsp; Back
        </GreyButton>
        {!formik.dirty && formik.isValid && (
          <GreyButton htmlType="submit" onClick={() => onSubmitForm()}>
            <SaveOutlined /> &nbsp; Save
          </GreyButton>
        )}
        {!formik.isValid && (
          <GreyButton className="disable-btn" htmlType="submit">
            <SaveOutlined /> &nbsp; Save
          </GreyButton>
        )}
        {formik.dirty && formik.isValid && (
          <GreenButton
            htmlType="submit"
            onClick={() => {
              onSubmitForm();
              onSave();
            }}
          >
            <SaveOutlined /> &nbsp; Save
          </GreenButton>
        )}
      </FooterWithButtons>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </>
  );
};

export default CreateEditInjury;
