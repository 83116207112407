import React from "react";
import { useDispatch } from "react-redux";
import { FormikProps, useFormik } from "formik";
import FloatingLabelTextArea from "../../../../../components/FloatingLabel/FloatingLabelTextArea";
import { useAppSelector } from "../../../../../store/hooks";
import { SetIsFocused, SetLastFocused } from "../ResearchQuestionSlice";

interface Props {
  label: string;
  DBKey: string;
  field: string;
  maxLength: number;
  scriptFormik: FormikProps<any>;
  onChange: Function;
  disabled: boolean;
}

export default function TextAreaEdit({
  label,
  DBKey,
  scriptFormik,
  field,
  maxLength,
  onChange,
  disabled,
}: Props) {
  const dispatch = useDispatch();
  const state = useAppSelector((state: any) => state.ResearchQuestionReducer);

  const handleChange = (e: any) => {
    onChange(DBKey, e.target.value);
  };
  return (
    <div className="mb-8">
      <FloatingLabelTextArea
        label={label}
        textareaName={field}
        value={scriptFormik.values[field]}
        onChange={handleChange}
        scriptFormik={scriptFormik}
        lastFocused={state.lastFocused}
        isFocused={state.isFocused}
        setIsFocused={(obj: {}) => dispatch(SetIsFocused(obj))}
        setLastFocused={(obj: {}) => dispatch(SetLastFocused(obj))}
        onSave={() => null}
        enableLiveCount={true}
        required
        maxLength={maxLength}
        readOnly={disabled}
      />
    </div>
  );
}
