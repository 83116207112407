import React from "react";
import SucessIcon from "../../../assets/icons/SucessIcon.svg";
import { BlueButton } from "../../../styles/Buttons.styled";
import { useHistory } from "react-router";
import AuthenticationPageLeftPane from "../../../components/CenterScreenCard/AuthenticationPageLeftPane";
import { AuthenticationContainer } from "../Authorization.styled";

const ResetPasswordSuccess = () => {
  const history = useHistory();
  return (
    <AuthenticationPageLeftPane displayLoginLink title={"Reset Password"}>
      <AuthenticationContainer>
        <div className="success">
          <img className="success-logo" src={SucessIcon} alt="Success Logo" />
          <h1 className="message">
            Your password has been successfully changed.
          </h1>
          <BlueButton
            rounded="true"
            type="primary"
            onClick={() => {
              history.push("/");
            }}
          >
            Return to login
          </BlueButton>
        </div>
      </AuthenticationContainer>
    </AuthenticationPageLeftPane>
  );
};

export default ResetPasswordSuccess;
