import { Col } from "antd";
import React from "react";
import {
  BodyPart,
  InsultWithRelation,
  NumberParameter,
} from "../../../../../../../models/InsultInterface";
import {
  getSurfaceAreaValue,
  NumberParameterWithFloatValue,
} from "../../CommonData";
import FloatingLabelInput from "../../../../../../../components/FloatingLabel/FloatingLabelInput";

type Props = {
  formik: any;
  param: NumberParameter;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
  insult: InsultWithRelation;
};
export default function SurfaceAreaParemeter({
  formik,
  param,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
  insult,
}: Props) {
  const { BodyParts } = insult;
  const getMaximumValue = () => {
    const locationId = formik.values["locationId"];
    if (!locationId) return 9;
    const selectedLocation = BodyParts?.map((bodyPart) =>
      bodyPart.Locations.find((location) => location.Id === locationId),
    )[0];
    if (!selectedLocation) return 9;
    return getSurfaceAreaValue(
      selectedLocation?.BodyPart?.Name,
      selectedLocation?.Detail,
    );
  };
  const isFloatValue = () => {
    return NumberParameterWithFloatValue.includes(
      param.UserFacingLabel.toLocaleLowerCase(),
    );
  };
  return (
    <>
      <Col span={12} sm={24} md={16} lg={12}>
        <FloatingLabelInput
          label={param.UserFacingLabel}
          nameForInput={`number_param_${insult.Label}_${param.UserFacingLabel}`}
          value={
            formik.values[
              `number_param_${insult.Label}_${param.UserFacingLabel}`
            ]
          }
          onChange={() => undefined}
          scriptFormik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          isAddNeeded={true}
          step={0.1}
          precision={isFloatValue() ? 1 : 0}
          maxValue={getMaximumValue()}
          minValue={0}
          suffix={param.Units ? param.Units : ""}
          onSave={formik.submitForm}
          displayMinMaxRange={true}
          isNumber
        />
      </Col>
    </>
  );
}
