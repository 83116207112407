import "@ant-design/compatible/assets/index.css";
import {
  AppstoreOutlined,
  ArrowRightOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Button, Row, Spin } from "antd";
import { UploadFile } from "antd/es/upload/interface";
import { RcFile } from "antd/lib/upload/interface";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setAlertMessage } from "../../../../actions/alertMessage";
import { getAllMedia } from "../../../../api/Media/ApiGet";
import { uploadMedia } from "../../../../api/Media/ApiPost";
import { SystemMessage } from "../../../../app/modules";
import { Input } from "../../../../components";
import Drawer from "../../../../components/Drawer/Drawer";
import { AlertType } from "../../../../models/Enums";
import { Media } from "../../../../models/MediaInterface";
import { StyledForm } from "../../../ScriptBuilder/EditComponents/EditForms/PhaseForm/PhaseForm.styled";
import { FormContainer } from "../../../ScriptBuilder/EditComponents/EditForms/RolePlayerForm/PromptForm.styled";
import MediaTable from "./MediaTable";
import UploadMedia from "./UploadMedia";
import ViewMediaDetails from "./ViewMediaDetails";
import MediaGridView from "./MediaGridView";
import { FloatingLabel } from "../../../../components/FloatingLabel/FloatingLabel.styled";

type MediaForm = {
  name: string;
};

interface Props {
  auth: any;
  setAlertMessage: Function;
  SystemMessage: SystemMessage;
}

const MediaList = ({ auth, setAlertMessage }: Props) => {
  const initialFormValues = {
    name: "",
  };

  const [view, setView] = React.useState<string>("list");
  const [addModal, setAddModal] = React.useState<boolean>(false);

  const [viewDetailsModal, setViewDetailsModal] = useState<boolean>(false);
  const [ViewDetails, setViewDetails] = useState<any>({});

  const [loading, setLoading] = useState<boolean>(true);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const initialFormErrors = {
    label: "",
  };
  const [formErrors, setFormErrors] = useState<any>(initialFormErrors);

  const [formValues, setFormValues] = useState<MediaForm>(initialFormValues);

  const [mediaList, setMediaList] = useState<Media[]>([]);

  const [modalTitle, setModalTitle] = useState<string>("");

  useEffect(() => {
    refreshAllMedia();
  }, []);
  const refreshAllMedia = () => {
    getAllMedia(getMediaSuccess, getMediaError);
  };
  const getMediaSuccess = (data: any) => {
    setMediaList(data);
    setLoading(false);
  };
  const getMediaError = (data: any) => {
    console.log(data);
    setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An error occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
    setLoading(false);
  };

  const validate = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (event.target.name === "label" && formValues.name.length === 0) {
      setFormErrors({
        label: "Please enter name",
      });
    } else {
      setFormErrors({ label: "" });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const error = (data: any) => {
    console.log(data);
    setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An error occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
    closeAddModal();
  };

  // Display success message with pop-up and resets the form.
  const success = () => {
    setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: "The media is successfully created",
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });
  };

  // Reset the form inputs.
  const resetForm = () => {
    setAddModal(false);
    setFormValues(initialFormValues);
    setFormErrors(initialFormErrors);
    setFileList([]);
  };

  const createMediaSuccess = () => {
    setLoading(false);
    setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: "The media is successfully created",
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });
    closeAddModal();
    refreshAllMedia();
  };

  const getNameFromFileName = (fileName: string) => {
    fileName = fileName.substring(0, fileName.lastIndexOf("."));
    return fileName.replace(/[^a-zA-Z0-9]/g, "_");
  };

  // Add  Media.
  const add = () => {
    let { name } = formValues;
    if (fileList.length > 0) {
      setLoading(true);
      if (!name.trim().length) {
        name = getNameFromFileName(fileList[0].name as string);
      }
      const bodyFormData = new FormData();
      bodyFormData.append("newVersion", "true");
      bodyFormData.append("public", "true");
      bodyFormData.append("file", fileList[0] as RcFile);
      bodyFormData.append("name", name);
      bodyFormData.append("title", name);
      uploadMedia(bodyFormData, createMediaSuccess, error);
    } else {
      closeAddModal();
    }
  };

  const closeAddModal = () => {
    resetForm();
    setAddModal(false);
  };

  // Open view Media details panel with Media data.
  const showDetails = (media: Media) => {
    setViewDetails(media);
    setViewDetailsModal(true);
  };

  const closeDetailsModal = () => {
    setViewDetailsModal(false);
  };
  const onFileSelect = (fileList: UploadFile[]) => {
    setFileList(fileList);
    setFormValues({
      name: getNameFromFileName(fileList[0].name as string),
    });
  };
  const columns = [
    {
      title: "File",
      dataIndex: "ThumbnailUrl",
      key: "ThumbnailUrl",
      render: (ThumbnailUrl: string, media: any) => (
        <>
          {
            <div>
              <img
                src={ThumbnailUrl}
                alt={media.Name}
                style={{ width: "100px" }}
              />
              <span className="ml-2">{media.Name}</span>
            </div>
          }
        </>
      ),
      sorter: (a: any, b: any) => a.Name.localeCompare(b.Name),
    },
  ];
  return (
    <div data-testid="media-list">
      <Spin spinning={loading}>
        <Row className="flex-container-align-item-right">
          <Button
            className="btn btn-blue"
            icon={<AppstoreOutlined />}
            onClick={() => {
              setView("grid");
            }}
          >
            Grid View
          </Button>
          <Button
            className="btn btn-blue"
            icon={<UnorderedListOutlined />}
            onClick={() => {
              setView("list");
            }}
          >
            List View
          </Button>
          <Button
            className="btn btn-blue"
            icon={<PlusOutlined />}
            onClick={() => {
              setModalTitle("Add Media");
              setAddModal(true);
            }}
          >
            Add New
          </Button>
        </Row>

        {view === "grid" ? (
          <MediaGridView mediaList={mediaList} select={() => undefined} />
        ) : (
          <MediaTable
            dataSource={mediaList}
            columns={columns}
            onDetails={showDetails}
          />
        )}

        <Drawer
          className="custom-css-ant-drawer"
          visible={addModal}
          onClose={add}
          closable
          width="600"
        >
          <h1>
            <ArrowRightOutlined
              style={{ marginRight: "20px" }}
              onClick={closeAddModal}
            />
            {modalTitle}
          </h1>
          <FormContainer>
            <StyledForm>
              <FloatingLabel>
                <label htmlFor="name">Name</label>
                <Input
                  value={formValues.name}
                  placeholder=" "
                  name="name"
                  type="text"
                  onChange={handleInputChange}
                  onBlur={validate}
                  style={{ marginBottom: "30px" }}
                />
                {formErrors.name && (
                  <span className="errorMessage">{formErrors.name}</span>
                )}
                <UploadMedia
                  fileList={fileList}
                  setFileList={setFileList}
                  onFileSelect={onFileSelect}
                />
              </FloatingLabel>
            </StyledForm>
          </FormContainer>
        </Drawer>

        {/* View details of perticular Media record. */}
        <ViewMediaDetails
          viewDetailsModal={viewDetailsModal}
          onClose={closeDetailsModal}
          media={ViewDetails}
        />
      </Spin>
    </div>
  );
};

function mapToStateToProps(state: any) {
  return {
    SystemMessage: state.SystemMessage,
    auth: state.authReducer,
  };
}

export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(MediaList);
