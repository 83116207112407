import { SET_ALERT, RESET_ALERT } from "./types";
import { NotificationProps, SystemMessage } from "../models/Management";
import { AlertType } from "../models/Enums";
import { showToast } from "../components/Notification/Notification";
import { TypeOptions } from "react-toastify";

export function setAlert(alert: SystemMessage) {
  return {
    systemMessage: alert.systemMessage,
    type: SET_ALERT,
  };
}
export function resetAlert() {
  return {
    type: RESET_ALERT,
  };
}

export function setAlertMessage(alert: SystemMessage) {
  console.log("alertmessage", alert);
  return async (dispatch: any) => {
    const {
      systemMessage: { description, type: method, position },
    } = alert;

    const notificationProps: NotificationProps = {
      description,
      position,
      type: getNotificationName(method),
    };

    showToast(notificationProps);

    dispatch(setAlert(alert), SET_ALERT);
  };
}

export function resetAlertMessage() {
  console.log("resetalertmessage", alert);
  return async (dispatch: any) => {
    dispatch(resetAlert(), RESET_ALERT);
  };
}

const getNotificationName = (type: AlertType): TypeOptions => {
  switch (type) {
    case AlertType.success:
      return "success";
    case AlertType.info:
      return "info";
    case AlertType.warning:
      return "warning";
    case AlertType.error:
      return "error";
    default:
      return "default";
  }
};
