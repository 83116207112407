import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreatorsForCasualtyStatus } from "../../../../../store/index";
import { WhiteIconButton } from "../../../../../styles/Buttons.styled";
import {
  EyeInvisibleOutlinedIcons,
  EyeOutlinedIcons,
} from "../../../../../IconsTheme/Icons.global";
import { CasualtyStatus } from "../../../../../models/ScriptInterfaces";
import { useFormik } from "formik";
import { Row } from "antd";
import Col from "antd/lib/grid/col";
import { FormikErrorType } from "../../../../../models/common/FormikErrorType";
import { GlassGowComaScaleWrapper } from "./RightEditPanel.styled";
import FloatingLabelInput from "../../../../../components/FloatingLabel/FloatingLabelInput";
interface Props {
  blockContent: CasualtyStatus;
  visible: boolean;
  onChange: Function;
}
export default function GlasgowComaScaleEdit(props: Props) {
  const dispatch = useDispatch();
  const { SetLastFocused, SetIsFocused } = bindActionCreators(
    actionCreatorsForCasualtyStatus,
    dispatch,
  );
  const state = useSelector((state: any) => state.CasualtyStatusReducer);
  const { isReadOnly } = useSelector((state: any) => state.MainlistReducer);
  const scriptFormik = useFormik({
    initialValues: {
      eyes: props.blockContent.GlasgowComaScaleEyes || "",
      verbal: props.blockContent.GlasgowComaScaleVerbal || "",
      motor: props.blockContent.GlasgowComaScaleMotor || "",
      total: props.blockContent.GlasgowComaScaleTotal || "",
    },
    validate: (values) => {
      const errors: FormikErrorType = {};

      if (!values.eyes) {
        errors["eyes"] = "Required";
      } else if (Number(values.eyes) < 1) {
        errors["eyes"] = "Min 1 required";
      } else if (Number(values.eyes) > 4) {
        errors["eyes"] = "Max 4 allowed";
      }
      if (!values.verbal) {
        errors["verbal"] = "Required";
      } else if (Number(values.verbal) < 1) {
        errors["verbal"] = "Min 1 required";
      } else if (Number(values.verbal) > 5) {
        errors["verbal"] = "Max 5 allowed";
      }
      if (!values.motor) {
        errors["motor"] = "Required";
      } else if (Number(values.motor) < 1) {
        errors["motor"] = "Min 1 required";
      } else if (Number(values.motor) > 6) {
        errors["motor"] = "Max 6 allowed";
      }
      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const checkValueValidation = (field: string, value: string | number) => {
    const numberValue = Number(value);
    if (
      field === "GlasgowComaScaleEyes" &&
      (numberValue < 1 || numberValue > 4)
    ) {
      return false;
    } else if (
      field === "GlasgowComaScaleVerbal" &&
      (numberValue < 1 || numberValue > 5)
    ) {
      return false;
    } else if (
      field === "GlasgowComaScaleMotor" &&
      (numberValue < 1 || numberValue > 6)
    ) {
      return false;
    }
    return true;
  };

  const onChange = (field: string, value: string | number) => {
    if (isReadOnly) return; //disable action callback in read only script
    if (!checkValueValidation(field, value)) {
      return true;
    }
    const GlasgowComaScaleEyes =
      Number(
        field === "GlasgowComaScaleEyes"
          ? value
          : props.blockContent.GlasgowComaScaleEyes,
      ) || 1;
    const GlasgowComaScaleVerbal =
      Number(
        field === "GlasgowComaScaleVerbal"
          ? value
          : props.blockContent.GlasgowComaScaleVerbal,
      ) || 1;
    const GlasgowComaScaleMotor =
      Number(
        field === "GlasgowComaScaleMotor"
          ? value
          : props.blockContent.GlasgowComaScaleMotor,
      ) || 1;
    const total = Number(
      GlasgowComaScaleEyes + GlasgowComaScaleVerbal + GlasgowComaScaleMotor,
    );
    props.onChange(field, value, "GlasgowComaScaleTotal", total);
  };
  const toggleGlassGowComaScale = () => {
    if (isReadOnly) return; //disable action callback in read only script
    props.onChange("GlasgowComaScaleIsDisplayed", !props.visible);
  };
  return (
    <GlassGowComaScaleWrapper>
      <WhiteIconButton
        type="link"
        icon={
          props.visible ? (
            <EyeOutlinedIcons className="visibleIcon" />
          ) : (
            <EyeInvisibleOutlinedIcons />
          )
        }
        size="middle"
        className={props.visible ? "active" : ""}
        onClick={toggleGlassGowComaScale}
      >
        Glasgow Coma Scale
      </WhiteIconButton>

      {props.visible ? (
        <Row className="mt-2">
          <Col span={6} className="px-1">
            <FloatingLabelInput
              label={"Eyes"}
              nameForInput={"eyes"}
              maxValue={4}
              minValue={1}
              maxLength={1}
              value={Number(scriptFormik.values["eyes"])}
              onChange={(e: any) =>
                onChange("GlasgowComaScaleEyes", e.target.value)
              }
              scriptFormik={scriptFormik}
              lastFocused={state.lastFocused}
              setIsFocused={SetIsFocused}
              setLastFocused={SetLastFocused}
              isFocused={state.isFocused}
              isAddNeeded={false}
              step={1}
              precision={0}
              isNumber
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6} className="px-1">
            <FloatingLabelInput
              label={"Verbal"}
              nameForInput={"verbal"}
              maxValue={5}
              minValue={1}
              maxLength={1}
              value={Number(scriptFormik.values["verbal"])}
              onChange={(e: any) =>
                onChange("GlasgowComaScaleVerbal", e.target.value)
              }
              scriptFormik={scriptFormik}
              lastFocused={state.lastFocused}
              setIsFocused={SetIsFocused}
              setLastFocused={SetLastFocused}
              isFocused={state.isFocused}
              isAddNeeded={false}
              step={1}
              precision={0}
              isNumber
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6} className="px-1">
            <FloatingLabelInput
              label={"Motor"}
              nameForInput={"motor"}
              maxValue={6}
              minValue={1}
              maxLength={1}
              value={Number(scriptFormik.values["motor"])}
              onChange={(e: any) =>
                onChange("GlasgowComaScaleMotor", e.target.value)
              }
              scriptFormik={scriptFormik}
              lastFocused={state.lastFocused}
              setIsFocused={SetIsFocused}
              setLastFocused={SetLastFocused}
              isFocused={state.isFocused}
              isAddNeeded={false}
              step={1}
              precision={0}
              isNumber
              readOnly={isReadOnly}
            />
          </Col>
          <Col span={6} className="px-1">
            <FloatingLabelInput
              disabled
              label={"Total"}
              nameForInput={"total"}
              maxValue={6}
              minValue={1}
              maxLength={1}
              value={Number(props.blockContent.GlasgowComaScaleTotal || "")}
              onChange={() => null}
              scriptFormik={scriptFormik}
              lastFocused={state.lastFocused}
              setIsFocused={SetIsFocused}
              setLastFocused={SetLastFocused}
              isFocused={state.isFocused}
              isAddNeeded={false}
              step={1}
              precision={0}
              isNumber
            />
          </Col>
        </Row>
      ) : null}
    </GlassGowComaScaleWrapper>
  );
}
