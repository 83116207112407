import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import App from "./app/App";
import registerServiceWorker from "./registerServiceWorker";
import { persistor, store } from "./store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import { isNullOrUndefined } from "is-what";
import { setAuthToken, setCompanyHeader } from "./utils/setAuthToken";
import { logout } from "./actions/authActions";

const history = require("history").createBrowserHistory();

setAuthTokenIfPresent();
disableConsoleLogOnProduction();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root"),
);

registerServiceWorker();

function setAuthTokenIfPresent() {
  if (!isNullOrUndefined(sessionStorage.token)) {
    setAuthToken(sessionStorage.token);
  } else {
    logout();
  }
  if (!isNullOrUndefined(sessionStorage.companyToken)) {
    setCompanyHeader(sessionStorage.companyToken);
  } else {
    logout();
  }
}

function disableConsoleLogOnProduction() {
  if (process.env.NODE_ENV === "production") {
    console.log = () => undefined;
    console.debug = () => undefined;
    console.info = () => undefined;
    console.warn = () => undefined;
    console.error = () => undefined;
  }
}
