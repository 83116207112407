import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
  },
};

export interface BarChartDataset {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
}
interface BarChartData {
  labels: string[];
  datasets: BarChartDataset[];
}
interface Props {
  data: BarChartData;
}
export default function CustomHorizontalBarChart({ data }: Props) {
  return <Bar options={options} data={data} />;
}
