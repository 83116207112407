import * as React from "react";
import {
  DownOutlined,
  MailOutlined,
  SettingOutlined,
  ClockCircleOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import {
  Tag,
  Mentions,
  Steps,
  Button,
  Radio,
  Space,
  Form,
  Input,
  Row,
  Col,
  Typography,
  Menu,
  Dropdown,
  Divider,
  Pagination,
  Select,
  Checkbox,
  DatePicker,
  TimePicker,
  InputNumber,
  Slider,
  Switch,
  TreeSelect,
  Card,
  Table,
  Tabs,
  Timeline,
  Tree,
  Alert,
  Progress,
  Spin,
} from "antd";
import SubMenu from "antd/lib/menu/SubMenu";

const inputProps = {
  style: { width: 128 },
};

const selectProps = {
  ...inputProps,
  options: [
    { value: "light", label: "Light" },
    { value: "bamboo", label: "Bamboo" },
    { value: "little", label: "Little" },
  ],
};

const treeData = [
  {
    value: "little",
    key: "little",
    label: "Little",
    title: "Little",
    children: [
      { value: "light", key: "light", label: "Light", title: "Light" },
      { value: "bamboo", key: "bamboo", label: "Bamboo", title: "Bamboo" },
    ],
  },
];

const carTabListNoTitle = [
  {
    key: "article",
    tab: "article",
  },
  {
    key: "app",
    tab: "app",
  },
  {
    key: "project",
    tab: "project",
  },
];

class StyleTest extends React.PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      current: "1",
    };
  }

  handleClick = (theme: string) => undefined;

  handleMenuClick = (e: any) => {
    console.log("click ", e);
    this.setState({
      current: e.key,
    });
  };

  public render() {
    const treeSelectProps = {
      ...inputProps,
      treeCheckable: true,
      maxTagCount: 2,
      treeData,
    };
    console.log("props", this.props);
    return (
      <React.Fragment>
        <h1>Theme View</h1>
        <Button type="primary" block onClick={() => this.handleClick("dark")}>
          Dark theme
        </Button>
        <Button type="primary" block onClick={() => this.handleClick("light")}>
          Light theme
        </Button>
        <Button type="primary" block onClick={() => this.handleClick("dark")}>
          Default theme
        </Button>
        <Row gutter={16} wrap={false}>
          <Col flex="auto">
            <Space
              direction="vertical"
              split={<Divider />}
              style={{ width: "100%" }}
            >
              {/* Primary Button */}
              <div>
                <Button type="primary">Primary</Button>
                <Button>Default</Button>
                <Button type="dashed">Dashed</Button>
                <Button type="text">Text</Button>
                <Button type="link">Link</Button>
              </div>

              {/* Danger Button */}
              <div>
                <Button danger type="primary">
                  Primary
                </Button>
                <Button danger>Default</Button>
                <Button danger type="dashed">
                  Dashed
                </Button>
                <Button danger type="text">
                  Text
                </Button>
                <Button danger type="link">
                  Link
                </Button>
              </div>

              {/* Ghost Button */}
              <div style={{ background: "rgb(190, 200, 200)" }}>
                <Button type="primary" ghost>
                  Primary
                </Button>
                <Button ghost>Default</Button>
                <Button type="dashed" ghost>
                  Dashed
                </Button>
                <Button type="primary" ghost danger>
                  Primary
                </Button>
                <Button ghost danger>
                  Default
                </Button>
                <Button type="dashed" ghost danger>
                  Dashed
                </Button>
              </div>

              {/* Typography */}
              <div>
                <Typography.Text type="success">Text (success)</Typography.Text>
                <Typography.Text type="warning">Text(warning)</Typography.Text>
                <Typography.Text type="danger">Text(danger)</Typography.Text>
                <Typography.Link>Link</Typography.Link>
                <Typography.Text copyable>Text</Typography.Text>

                {/* Dropdown */}
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item>1st menu item</Menu.Item>
                      <Menu.Item danger>a danger item</Menu.Item>
                    </Menu>
                  }
                >
                  <a
                    href="/#"
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    Hover me <DownOutlined />
                  </a>
                </Dropdown>

                {/* Spin */}
                <Spin />
              </div>

              {/* Menu - horizontal */}
              <Row gutter={16}>
                <Col span={12}>
                  <Menu
                    onClick={this.handleMenuClick}
                    defaultOpenKeys={["sub1"]}
                    selectedKeys={[this.state.current]}
                    mode="horizontal"
                  >
                    <SubMenu
                      key="sub1"
                      icon={<MailOutlined />}
                      title="Navigation One"
                    >
                      <Menu.Item key="1">Option 1</Menu.Item>
                      <Menu.Item key="2">Option 2</Menu.Item>
                      <Menu.Item key="3">Option 3</Menu.Item>
                      <Menu.Item key="4">Option 4</Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="sub2"
                      icon={<AppstoreOutlined />}
                      title="Navigation Two"
                    >
                      <Menu.Item key="5">Option 5</Menu.Item>
                      <Menu.Item key="6">Option 6</Menu.Item>
                      <SubMenu key="sub3" title="Submenu">
                        <Menu.Item key="7">Option 7</Menu.Item>
                        <Menu.Item key="8">Option 8</Menu.Item>
                      </SubMenu>
                    </SubMenu>
                    <SubMenu
                      key="sub4"
                      icon={<SettingOutlined />}
                      title="Navigation Three"
                    >
                      <Menu.Item key="9">Option 9</Menu.Item>
                      <Menu.Item key="10">Option 10</Menu.Item>
                      <Menu.Item key="11">Option 11</Menu.Item>
                      <Menu.Item key="12">Option 12</Menu.Item>
                    </SubMenu>
                  </Menu>
                </Col>
                <Col span={12}>
                  <Menu
                    theme="dark"
                    onClick={this.handleMenuClick}
                    defaultOpenKeys={["sub1"]}
                    selectedKeys={[this.state.current]}
                    mode="inline"
                  >
                    <SubMenu
                      key="sub1"
                      icon={<MailOutlined />}
                      title="Navigation One"
                    >
                      <Menu.Item key="1">Option 1</Menu.Item>
                      <Menu.Item key="2">Option 2</Menu.Item>
                      <Menu.Item key="3">Option 3</Menu.Item>
                      <Menu.Item key="4">Option 4</Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="sub2"
                      icon={<AppstoreOutlined />}
                      title="Navigation Two"
                    >
                      <Menu.Item key="5">Option 5</Menu.Item>
                      <Menu.Item key="6">Option 6</Menu.Item>
                      <SubMenu key="sub3" title="Submenu">
                        <Menu.Item key="7">Option 7</Menu.Item>
                        <Menu.Item key="8">Option 8</Menu.Item>
                      </SubMenu>
                    </SubMenu>
                    <SubMenu
                      key="sub4"
                      icon={<SettingOutlined />}
                      title="Navigation Three"
                    >
                      <Menu.Item key="9">Option 9</Menu.Item>
                      <Menu.Item key="10">Option 10</Menu.Item>
                      <Menu.Item key="11">Option 11</Menu.Item>
                      <Menu.Item key="12">Option 12</Menu.Item>
                    </SubMenu>
                  </Menu>
                </Col>
              </Row>

              {/* Menu - vertical */}
              <Row gutter={16}>
                <Col span={12}>
                  <Menu
                    onClick={this.handleMenuClick}
                    defaultOpenKeys={["sub1"]}
                    selectedKeys={[this.state.current]}
                    mode="inline"
                  >
                    <SubMenu
                      key="sub1"
                      icon={<MailOutlined />}
                      title="Navigation One"
                    >
                      <Menu.Item key="1">Option 1</Menu.Item>
                      <Menu.Item key="2">Option 2</Menu.Item>
                      <Menu.Item key="3">Option 3</Menu.Item>
                      <Menu.Item key="4">Option 4</Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="sub2"
                      icon={<AppstoreOutlined />}
                      title="Navigation Two"
                    >
                      <Menu.Item key="5">Option 5</Menu.Item>
                      <Menu.Item key="6">Option 6</Menu.Item>
                      <SubMenu key="sub3" title="Submenu">
                        <Menu.Item key="7">Option 7</Menu.Item>
                        <Menu.Item key="8">Option 8</Menu.Item>
                      </SubMenu>
                    </SubMenu>
                    <SubMenu
                      key="sub4"
                      icon={<SettingOutlined />}
                      title="Navigation Three"
                    >
                      <Menu.Item key="9">Option 9</Menu.Item>
                      <Menu.Item key="10">Option 10</Menu.Item>
                      <Menu.Item key="11">Option 11</Menu.Item>
                      <Menu.Item key="12">Option 12</Menu.Item>
                    </SubMenu>
                  </Menu>
                </Col>
                <Col span={12}>
                  <Menu
                    theme="dark"
                    onClick={this.handleMenuClick}
                    defaultOpenKeys={["sub1"]}
                    selectedKeys={[this.state.current]}
                    mode="inline"
                  >
                    <SubMenu
                      key="sub1"
                      icon={<MailOutlined />}
                      title="Navigation One"
                    >
                      <Menu.Item key="1">Option 1</Menu.Item>
                      <Menu.Item key="2">Option 2</Menu.Item>
                      <Menu.Item key="3">Option 3</Menu.Item>
                      <Menu.Item key="4">Option 4</Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="sub2"
                      icon={<AppstoreOutlined />}
                      title="Navigation Two"
                    >
                      <Menu.Item key="5">Option 5</Menu.Item>
                      <Menu.Item key="6">Option 6</Menu.Item>
                      <SubMenu key="sub3" title="Submenu">
                        <Menu.Item key="7">Option 7</Menu.Item>
                        <Menu.Item key="8">Option 8</Menu.Item>
                      </SubMenu>
                    </SubMenu>
                    <SubMenu
                      key="sub4"
                      icon={<SettingOutlined />}
                      title="Navigation Three"
                    >
                      <Menu.Item key="9">Option 9</Menu.Item>
                      <Menu.Item key="10">Option 10</Menu.Item>
                      <Menu.Item key="11">Option 11</Menu.Item>
                      <Menu.Item key="12">Option 12</Menu.Item>
                    </SubMenu>
                  </Menu>
                </Col>
              </Row>

              {/* Pagination */}
              <Pagination showQuickJumper defaultCurrent={2} total={500} />

              {/* Steps */}
              <Steps current={1} percent={60}>
                <Steps.Step
                  title="Finished"
                  description="This is a description."
                />
                <Steps.Step
                  title="In Progress"
                  subTitle="Left 00:00:08"
                  description="This is a description."
                />
                <Steps.Step
                  title="Waiting"
                  description="This is a description."
                />
              </Steps>

              {/* Steps - dot */}
              <Steps current={2} status="error" progressDot>
                <Steps.Step
                  title="Finished"
                  description="You can hover on the dot."
                />
                <Steps.Step
                  title="In Progress"
                  description="You can hover on the dot."
                />
                <Steps.Step
                  title="Error"
                  description="You can hover on the dot."
                />
                <Steps.Step
                  title="Waiting"
                  description="You can hover on the dot."
                />
              </Steps>

              {/* Form - Input */}
              <Form>
                <div>
                  <Form.Item>
                    <Input {...inputProps} />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="success">
                    <Input {...inputProps} />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="warning">
                    <Input {...inputProps} />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="error">
                    <Input {...inputProps} />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="validating">
                    <Input {...inputProps} />
                  </Form.Item>
                </div>
              </Form>

              {/* Form - Select */}
              <Form>
                <div>
                  <Form.Item>
                    <Select {...selectProps} />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="success">
                    <Select {...selectProps} />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="warning">
                    <Select {...selectProps} />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="error">
                    <Select {...selectProps} />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="validating">
                    <Select {...selectProps} />
                  </Form.Item>
                </div>
              </Form>

              {/* Form - TreeSelect */}
              <Form>
                <div>
                  <Form.Item>
                    <TreeSelect {...treeSelectProps} />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="success">
                    <TreeSelect {...treeSelectProps} />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="warning">
                    <TreeSelect {...treeSelectProps} />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="error">
                    <TreeSelect {...treeSelectProps} />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="validating">
                    <TreeSelect {...treeSelectProps} />
                  </Form.Item>
                </div>
              </Form>

              {/* Form - InputNumber */}
              <Form>
                <div>
                  <Form.Item>
                    <InputNumber />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="success">
                    <InputNumber />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="warning">
                    <InputNumber />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="error">
                    <InputNumber />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="validating">
                    <InputNumber />
                  </Form.Item>
                </div>
              </Form>

              {/* Form - DatePicker */}
              <Form>
                <div>
                  <Form.Item>
                    <DatePicker />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="success">
                    <DatePicker />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="warning">
                    <DatePicker />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="error">
                    <DatePicker />
                  </Form.Item>
                  <Form.Item hasFeedback validateStatus="validating">
                    <DatePicker />
                  </Form.Item>
                </div>
              </Form>

              <div>
                <Checkbox>Checkbox</Checkbox>

                <Radio.Group defaultValue="bamboo">
                  <Radio value="bamboo">Bamboo</Radio>
                  <Radio value="light">Light</Radio>
                  <Radio value="little">Little</Radio>
                </Radio.Group>

                <Mentions placeholder="Mention by @">
                  <Mentions.Option value="afc163">afc163</Mentions.Option>
                  <Mentions.Option value="zombieJ">zombieJ</Mentions.Option>
                  <Mentions.Option value="yesmeck">yesmeck</Mentions.Option>
                </Mentions>

                <Slider defaultValue={30} style={{ width: 100 }} />

                <Switch defaultChecked />
              </div>

              <div>
                <DatePicker.RangePicker />
                <TimePicker.RangePicker />
              </div>

              <Row gutter={16}>
                <Col span={8}>
                  {/* Card */}
                  <Card
                    style={{ width: "100%" }}
                    tabList={carTabListNoTitle}
                    tabBarExtraContent={<a href="/#">More</a>}
                  />
                </Col>
                <Col span={8}>
                  {/* Table */}
                  <Table
                    size="small"
                    bordered
                    rowSelection={{}}
                    columns={[
                      {
                        title: "Key",
                        dataIndex: "key",
                        filters: [
                          {
                            text: "Little",
                            value: "little",
                          },
                        ],
                        sorter: (a, b) => a.key.length - b.key.length,
                      },
                    ]}
                    dataSource={[
                      {
                        key: "Bamboo",
                      },
                      {
                        key: "Light",
                      },
                      {
                        key: "Little",
                      },
                    ]}
                  />
                </Col>
                <Col span={8}>
                  {/* Table */}
                  <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Tab 1" key="1">
                      Content of Tab Pane 1
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Tab 2" key="2">
                      Content of Tab Pane 2
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Tab 3" key="3">
                      Content of Tab Pane 3
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
              </Row>

              <div>
                <Tag color="success">success</Tag>
                <Tag color="processing">processing</Tag>
                <Tag color="error">error</Tag>
                <Tag color="warning">warning</Tag>
                <Tag color="default">default</Tag>
                <Tag.CheckableTag checked>CheckableTag</Tag.CheckableTag>
              </div>

              <Row gutter={16}>
                <Col span={16}>
                  <Timeline mode="alternate">
                    <Timeline.Item>
                      Create a services site 2015-09-01
                    </Timeline.Item>
                    <Timeline.Item color="gray">
                      Solve initial network problems 2015-09-01
                    </Timeline.Item>
                    <Timeline.Item
                      dot={<ClockCircleOutlined style={{ fontSize: "16px" }} />}
                    >
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt explicabo.
                    </Timeline.Item>
                  </Timeline>
                </Col>

                <Col span={8}>
                  <Tree
                    treeData={treeData}
                    height={200}
                    defaultExpandAll
                    checkable
                  />
                </Col>
              </Row>

              {/* Alert */}
              <Row gutter={16}>
                <Col span={6}>
                  <Alert showIcon message="Success Text" type="success" />
                </Col>
                <Col span={6}>
                  <Alert showIcon message="Info Text" type="info" />
                </Col>
                <Col span={6}>
                  <Alert showIcon message="Warning Text" type="warning" />
                </Col>
                <Col span={6}>
                  <Alert showIcon message="Error Text" type="error" />
                </Col>
              </Row>

              {/* Progress */}
              <Row gutter={16}>
                <Col flex="auto">
                  <Progress percent={30} />
                  <Progress percent={70} status="exception" />
                  <Progress percent={100} />
                </Col>
                <Col flex="none">
                  <Progress type="circle" percent={75} />
                  <Progress type="circle" percent={70} status="exception" />
                  <Progress type="circle" percent={100} />
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default StyleTest;
