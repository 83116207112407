export const INSULT_INJURY_SET_CURRENT_VIEW = `INSULT_INJURY/SET_CURRENT_VIEW`;

export const INSULT_INJURY_SET_DISPLAY_ALL_INJURY_INSULT = `INSULT_INJURY/SET_DISPLAY_ALL_INJURY_INSULT`;

export const INSULT_INJURY_SET_INSULTS = `INSULT_INJURY/SET_INSULTS`;
export const INSULT_INJURY_RESET_INSULTS = `INSULT_INJURY/RESET_INSULTS`;
export const INSULT_INJURY_SET_INJURIES = `INSULT_INJURY/SET_INJURIES`;
export const INSULT_INJURY_ADD_INJURY_TO_INJURIES = `INSULT_INJURY/ADD_INJURY_TO_INJURIES`;
export const INSULT_INJURY_RESET_INJURIES = `INSULT_INJURY/RESET_INJURIES`;
export const INSULT_INJURY_SET_INJURY_LOCATIONS = `INSULT_INJURY/SET_INJURY_LOCATIONS`;
export const INSULT_INJURY_RESET_INJURY_LOCATIONS = `INSULT_INJURY/RESET_INJURY_LOCATIONS`;
// filtered  applied insult, applied injury, injury and insult
export const INSULT_INJURY_SET_FILTERED_ITEMS = `INSULT_INJURY/SET_FILTERED_ITEMS`;
export const INSULT_INJURY_RESET_FILTERED_ITEMS = `INSULT_INJURY/RESET_FILTERED_ITEMS`;
// Applied insults
export const INSULT_INJURY_SET_APPLIED_INSULTS = `INSULT_INJURY/SET_APPLIED_INSULTS`;
export const INSULT_INJURY_ADD_APPLIED_INSULT = `INSULT_INJURY/ADD_APPLIED_INSULT `;
export const INSULT_INJURY_UPDATE_APPLIED_INSULT = `INSULT_INJURY/UPDATE_APPLIED_INSULT `;
export const INSULT_INJURY_REMOVE_APPLIED_INSULT = `INSULT_INJURY/REMOVE_APPLIED_INSULT `;
export const INSULT_INJURY_RESET_APPLIED_INSULTS = `INSULT_INJURY/RESET_APPLIED_INSULTS`;
//Applied injury
export const INSULT_INJURY_SET_APPLIED_INJURIES = `INSULT_INJURY/SET_APPLIED_INJURIES`;
export const INSULT_INJURY_RESET_APPLIED_INJURIES = `INSULT_INJURY/RESET_APPLIED_INJURIES`;
export const INSULT_INJURY_ADD_APPLIED_INJURY = `INSULT_INJURY/ADD_APPLIED_INJURY`;
export const INSULT_INJURY_UPDATE_APPLIED_INJURY = `INSULT_INJURY/UPDATE_APPLIED_INJURY`;
export const INSULT_INJURY_REMOVE_APPLIED_INJURY = `INSULT_INJURY/REMOVE_APPLIED_INJURY`;
export const INSULT_INJURY_ADD_APPLIED_INJURY_INSULT = `INSULT_INJURY/ADD_APPLIED_INJURY_INSULT`;
export const INSULT_INJURY_UPDATE_APPLIED_INJURY_INSULT = `INSULT_INJURY/UPDATE_APPLIED_INJURY_INSULT`;
export const INSULT_INJURY_REMOVE_APPLIED_INJURY_INSULT = `INSULT_INJURY/REMOVE_APPLIED_INJURY_INSULT`;
// Create Master Injury Option
export const INSULT_INJURY_SET_INJURIES_FOR_COMPARISON = `INSULT_INJURY/SET_INJURIES_FOR_COMPARISON`;
export const INSULT_INJURY_ADD_INJURY_FOR_COMPARISON = `INSULT_INJURY/ADD_INJURY_FOR_COMPARISON`;
export const INSULT_INJURY_UPDATE_INJURY_FOR_COMPARISON = `INSULT_INJURY/UPDATE_INJURY_FOR_COMPARISON`;
export const INSULT_INJURY_ADD_INJURY_FOR_CREATE_MASTER_OPTION = `INSULT_INJURY/ADD_INJURY_FOR_CREATE_MASTER_OPTION`;
export const INSULT_INJURY_REMOVE_INJURY_FOR_CREATE_MASTER_OPTION = `INSULT_INJURY/REMOVE_INJURY_FOR_CREATE_MASTER_OPTION`;

// InjuryObj for add/update injury
export const INSULT_INJURY_SET_INJURY_OBJ = `INSULT_INJURY/SET_INJURY_OBJ`;
export const INSULT_INJURY_UPDATE_INJURY_OBJ = `INSULT_INJURY/UPDATE_INJURY_OBJ`;
export const INSULT_INJURY_ADD_UPDATE_INJURY_OBJ_INSULTS = `INSULT_INJURY/ADD_UPDATE_INJURY_OBJ_INSULTS`;
export const INSULT_INJURY_ADD_INJURY_OBJ_INSULT = `INSULT_INJURY/ADD_INJURY_OBJ_INSULT`;
export const INSULT_INJURY_UPDATE_INJURY_OBJ_INSULT = `INSULT_INJURY/UPDATE_INJURY_OBJ_INSULT`;
export const INSULT_INJURY_REMOVE_INJURY_OBJ_INSULT = `INSULT_INJURY/REMOVE_INJURY_OBJ_INSULT`;
export const INSULT_INJURY_RESET_INJURY_OBJ = `INSULT_INJURY/RESET_INJURY_OBJ`;

// selected insult
export const INSULT_INJURY_SET_SELECTED_INSULT_OBJ = `INSULT_INJURY/SET_SELECTED_INSULT_OBJ`;
export const INSULT_INJURY_RESET_SELECTED_INSULT_OBJ = `INSULT_INJURY/RESET_SELECTED_INSULT_OBJ`;
export const INSULT_INJURY_SET_CURRENT_INSULT_FLOW_RATE = `INSULT_INJURY/SET_CURRENT_INSULT_FLOW_RATE`;

// screen history
export const INSULT_INJURY_ADD_SCREEN_VISIT = `INSULT_INJURY/ADD_SCREEN_VISIT`;
export const INSULT_INJURY_REMOVE_SCREEN_VISIT = `INSULT_INJURY/REMOVE_SCREEN_VISIT`;
export const INSULT_INJURY_RESET_SCREEN_VISIT_HISTORY = `INSULT_INJURY/RESET_SCREEN_VISIT_HISTORY`;

export const INSULT_INJURY_SET_BACK_REDIRECT_SCREEN = `INSULT_INJURY/SET_BACK_REDIRECT_SCREEN`;
export const INSULT_INJURY_SET_SAVE_REDIRECT_SCREEN = `INSULT_INJURY/SET_SAVE_REDIRECT_SCREEN`;

export const INSULT_INJURY_RESET_TO_INITIAL_STATE = `INSULT_INJURY/RESET_TO_INITIAL_STATE`;

export const INSULT_INJURY_SET_LABEL_DATA = `INSULT_INJURY/SET_LABEL_DATA`;
export const INSULT_INJURY_RESET_LABEL_DATA = `INSULT_INJURY/RESET_LABEL_DATA`;
