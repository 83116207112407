import React from "react";
import { UpdateResearchQuestionButton } from "../../../../../api/ResearchQuestionBlock/ApiPost";
import { ResearchQuestionButton } from "../../../../../models/ScriptInterfaces";
import { Switch } from "../../../../../components";

interface Props {
  object: ResearchQuestionButton;
  onChange: any;
  disabled: boolean;
}
export default function ButtonQuestionType(props: Props) {
  const handleChange = (dbField: string, value: string | boolean) => {
    if (props.disabled) return; //disable action callback in read only script
    const Payload = { ...props.object, [dbField]: value };
    props.onChange("ResearchQuestionButton", Payload);
    UpdateResearchQuestionButton(
      Payload,
      () => undefined,
      () => undefined,
    );
  };
  return (
    <>
      <div
        style={{ gap: "1rem" }}
        className="mb-8 d-flex align-items-center"
        onClick={() => handleChange("Start", !props.object.Start)}
      >
        <Switch checked={props.object.Start} onChange={() => undefined} />
        <button
          style={{ aspectRatio: `auto` }}
          className={`archived-scripts ${
            props.object.Start ? "text-white" : ""
          }`}
          onClick={() => handleChange("Start", !props.object.Start)}
        >
          Start
        </button>
      </div>
      <div
        style={{ gap: "1rem" }}
        className="mb-8 d-flex align-items-center"
        onClick={() => handleChange("Stop", !props.object.Stop)}
      >
        <Switch checked={props.object.Stop} onChange={() => undefined} />
        <button
          style={{ aspectRatio: `auto` }}
          className={`archived-scripts ${
            props.object.Stop ? "text-white" : ""
          }`}
          onClick={() => handleChange("Stop", !props.object.Stop)}
        >
          Stop
        </button>
      </div>

      <div
        style={{ gap: "1rem" }}
        className="mb-8 d-flex align-items-center"
        onClick={() => handleChange("Rating", !props.object.Rating)}
      >
        <Switch checked={props.object.Rating} onChange={() => undefined} />
        <button
          style={{ aspectRatio: `auto` }}
          className={`archived-scripts ${
            props.object.Rating ? "text-white" : ""
          }`}
          onClick={() => handleChange("Rating", !props.object.Rating)}
        >
          Rating
        </button>
      </div>
    </>
  );
}
