import axios from "axios";

export function CreateCasualty(
  casualtyForCreate: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/casualty/Create`, casualtyForCreate)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function UpdateCasualty(
  casualtyForUpdate: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/casualty/Update`, casualtyForUpdate)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function UpdateCasualtyAllergies(
  casualtyId: number,
  allergyIds: Array<number | undefined>,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/Casualty/AddAllergiesToCasualty`, {
      CasualtyId: casualtyId,
      AllergyIds: allergyIds,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function RemoveAllergyFromCasualty(
  casualtyId: number,
  allergyId: number | undefined,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/casualty/RemoveAllergyFromCasualty`, {
      CasualtyId: casualtyId,
      AllergyId: allergyId,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function deleteCasualty(
  id: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`/api/1/pfc/casualty/delete/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function CreateFromMasterWithRelations(
  casualtyForCreate: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(
      `/api/1/pfc/casualty/CreateFromMasterWithRelations `,
      casualtyForCreate,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function CreateCasualtyWithRelations(
  casualtyForCreate: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/casualty/CreateWithRelations`, casualtyForCreate)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function editCasualtyWithRelations(
  updatedCasualty: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/Casualty/Update`, updatedCasualty)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function CreateMasterCasualtyFromCasualty(
  casualtyId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/Casualty/CreateMaster`, { CasualtyId: casualtyId })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function AddInsultToCasualty(
  casualtyId: number,
  insultId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/casualty/update/AddInsultToCasualty`, {
      CasualtyId: casualtyId,
      InsultId: insultId,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
// create applied insult from master insult with parameter
export function ApplyInsultToCasualty(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/Casualty/ApplyInsultToCasualty `, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
// update applied insult with parameter
export function UpdateAppliedInsultAndAdjustParameters(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(
      `/api/1/pfc/AppliedInsult/UpdateAppliedInsultAndAdjustParameters `,
      payload,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
