import axios from "axios";

const apiPrefix = "/api/1/pfc/User";

export function deleteUser(
  id: string,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`${apiPrefix}/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}
