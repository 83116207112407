import * as React from "react";
import FloatingLabelInput from "../../../../../../components/FloatingLabel/FloatingLabelInput";
import { FormRow } from "../../styled";
type Props = {
  formik: any;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
  disabled: boolean;
  autoFocus?: boolean;
};

const DescriptionInput = ({
  formik,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
  disabled,
  autoFocus,
}: Props) => {
  return (
    <FormRow>
      <FloatingLabelInput
        label={"Description*"}
        nameForInput={"description"}
        value={formik.values["description"]}
        onChange={formik.handleChange}
        scriptFormik={formik}
        lastFocused={lastFocused}
        setIsFocused={setIsFocused}
        setLastFocused={setLastFocused}
        isFocused={isFocused}
        isAddNeeded={false}
        onSave={formik.submitForm}
        disabled={disabled}
        autoFocus={autoFocus}
      />
    </FormRow>
  );
};

export default DescriptionInput;
