import styled from "styled-components";

type LabelOverlayDivProps = {
  isVisible: boolean;
};
export const LabelOverlayDiv = styled.div.attrs(
  (props: LabelOverlayDivProps) => ({})
)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 30% 40% 30%;
  z-index: 100;
  padding: 0px 10px;

  // This css will help in designing Labels as it shows Grid layout if enabled.
  // div {
  //   border: 1px solid red;
  // }
`;

export const LabelContainer = styled.div`
  padding: 2px 0px;
  height: 2.24rem;
  position: relative;
`;
