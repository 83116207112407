import React from "react";
import FloatingLabelText from "../../../../components/FloatingLabel/FloatingLabelTextArea";
import { FormRow } from "../../User.styled";
type Props = {
  formik: any;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
  handleChange: Function;
};
const fieldName = "shortBio";
export default function ShortBioInput({
  formik,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
  handleChange,
}: Props) {
  return (
    <FormRow>
      <FloatingLabelText
        label={"Short Bio"}
        textareaName={fieldName}
        rows={2}
        value={formik.values[fieldName]}
        scriptFormik={formik}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(fieldName, e.target.value)
        }
        isFocused={isFocused}
        lastFocused={lastFocused}
        setIsFocused={setIsFocused}
        setLastFocused={setLastFocused}
        disabled
      />
    </FormRow>
  );
}
