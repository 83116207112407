import styled from "styled-components";

interface I_Title {
  isSetUp?: boolean;
  collapse: boolean;
}

interface I_Span {
  withIcon?: boolean;
  collapse: boolean;
  ishovered: boolean;
  isOption?: boolean;
}

interface I_SideIconDiv {
  collapse: boolean;
}

export const BuildTitle = styled.div<I_Title>`
  width: 100%;
  height: auto;
  color: #ffffff;
  display: flex;
  align-content: center;
  align-items: center;
  text-align: ${({ collapse }) => (collapse ? "start" : " center")};
  margin-top: 50px;
  border: 1px solid #23d0eb;
  background-color: #1e7582;
  margin-bottom: ${({ isSetUp }) => (isSetUp ? "60px" : "0px")};
  font-size: ${({ collapse }) => (collapse ? "0.9375rem" : "0.8125rem")};

  @media (max-width: ${({ theme }) => theme.breakpoints?.xl}) {
    font-size: ${({ collapse }) => (collapse ? "0.9375rem" : "0.625rem")};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    font-size: ${({ collapse }) => (collapse ? "0.9375rem" : "0.8125rem")};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    margin-top: ${({ isSetUp }) => (isSetUp ? "3.125rem" : "0px")};
    margin-bottom: ${({ isSetUp }) => (isSetUp ? "4.0625rem" : "0px")};
    font-size: 1rem;
  }
`;

interface I_DragButton {
  collapse: boolean;
  isOption: boolean;
}

export const DragButtonStyle = styled.div<I_DragButton>`
  text-align: ${({ collapse }) => (collapse ? "start " : " center")};
  box-sizing: border-box;

  div {
    ::before,
    ::after {
      box-sizing: inherit;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xl}) {
    text-align: ${({ collapse }) => (collapse ? "start" : " start")};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    text-align: ${({ collapse }) => (collapse ? "start" : " center")};
    padding-left: ${({ collapse }) => (collapse ? "" : " 40%")};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.mdLg}) {
    height: auto;

    padding-top: ${({ collapse }) => (collapse ? "0.75rem" : " ")};
    padding-bottom: ${({ collapse }) => (collapse ? "0.75rem" : " ")};
    text-align: ${({ collapse }) => (collapse ? "start" : " center")};

    div {
      height: auto;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    height: auto;
    width: 100%;
    margin-bottom: 0.625rem;
    text-align: ${({ collapse }) => (collapse ? "start" : " center")};
    padding: 1px;

    div {
      height: auto;
    }
  }
`;
export const SpanStyle = styled.span<I_Span>`
  font-size: ${({ collapse }) => (collapse ? "0.9375rem" : " 1.5625rem")};
  margin: 0 auto;
  padding-left: 18px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: white;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: ${({ collapse }) => (collapse ? "" : "50%")};
  width: 100%;
  min-height: 52px;

  &:hover {
    background-color: rgba(43, 43, 43, 255);
    box-shadow: inset 0rem -0.0625rem 0rem rgb(255, 255, 255);
    border-bottom: none !important;
  }

  .plusIconSpan {
    display: flex;
    /* padding-left: 47%; */
  }

  .plusIcon {
    font-size: 0.9375rem;
  }

  .spanWihoutIconMode {
    margin-right: 0.3125rem;
    font-size: 1.125rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints?.xxl}) {
    .plusIconSpan {
      padding-left: 70%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xxl}) {
    .plusIconSpan {
      padding-left: 47%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xl}) {
    padding-left: ${({ collapse }) => (collapse ? "" : "0%")};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    .plusIconSpan {
      padding-left: 90%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.mdLg}) {
    font-size: ${({ collapse }) => (collapse ? "1.3125rem" : " 1.5625rem")};
    .plusIconSpan {
      padding-left: 90%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.mdLg}) {
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 2px;
    color: white;
    font-size: ${({ collapse }) => (collapse ? "1.25rem" : " 2.1875rem")};

    .plusIconSpan {
      padding-left: 50%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xs}) {
    .plusIconSpan {
      padding-left: 10%;
    }
  }
`;

export const SideIconDiv = styled.div<I_SideIconDiv>`
  height: 2.1875rem;
  margin-top: 1.875rem;
  color: white;
  font-weight: bolder;
  font-size: 1.375rem;
  text-align: ${({ collapse }) => (collapse ? "end " : " center")};
  padding-right: ${({ collapse }) => (collapse ? "10px" : " ")};
  text-decoration: solid;
`;
