import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { logout } from "../actions/authActions";
import { Layout, Modal } from "antd";
import Head from "./Head";
import SideMenu from "./SideMenu";
import { LayoutProps } from "./modules";
import { StyledDiv } from "./Layout.styled";
import { resetAlertMessage } from "../actions/alertMessage";
import "./Layout.scss";
import SupportForm from "./SupportForm";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { SetShowUserModal } from "./HeadSlice";

const { Content, Sider } = Layout; //Sider
let initialTimeout: NodeJS.Timeout;

const AppLayout = (props: LayoutProps) => {
  const dispatch = useAppDispatch();
  const { showUserModal } = useAppSelector((state) => state.HeadReducer);
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [openSupport, setOpenSupport] = React.useState<boolean>(false);
  const [myTimeOut, setMyTimeOut] = useState<NodeJS.Timeout>(initialTimeout);
  const onCollapse = (collapse: boolean) => {
    setCollapsed(!collapse);
  };
  const openSupportOnClick = (collapse: boolean) => {
    if (openSupport) {
      setOpenSupport(!collapse);
    } else {
      setOpenSupport(collapse);
    }
  };

  useEffect(() => {
    setCollapsed(true);
  }, [openSupport]);
  const handleCollapseOpen = () => {
    setCollapsed(!collapsed);
    document.getElementsByTagName("aside")[0].style.zIndex = "1000";
  };
  const handleMyTimeout = () => {
    setMyTimeOut(setTimeout(handleCollapseOpen, 0));
  };
  const handleCollapseClose = () => {
    clearTimeout(myTimeOut);
    setCollapsed(true);
  };
  const { isAuthenticated } = props.auth;
  console.log("isAuthenticated ", props.auth.isAuthenticated);
  console.log("Environment: ", process.env.NODE_ENV);
  const closeSupportModal = useCallback(() => {
    setOpenSupport(false);
  }, []);

  const displayOrHideUserModal = (e: any) => {
    console.log("e.target.className", e.target.className);
    if (
      (e.target.className !== "user-dropdown-container" ||
        e.target.className === "") &&
      showUserModal
    ) {
      console.log("e.target.className in if statement", e.target.className);
      dispatch(SetShowUserModal(false));
    }
  };

  return (
    <div
      className="sider-pageContent-container"
      onClick={displayOrHideUserModal}
    >
      {isAuthenticated &&
      !window.location.href.includes("/companySelection") &&
      !window.location.href.includes("/management/login") &&
      !window.location.href.includes("/user/login") &&
      !window.location.href.includes("/landing") ? (
        <StyledDiv collapse={collapsed} className="layoutDiv">
          <div>
            <div
              className="sider-container"
              // onMouseEnter={handleMyTimeout}
              onMouseLeave={(e) => handleCollapseClose()}
            >
              <Sider
                collapsible={true}
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                collapsedWidth={70}
                trigger={null}
                width="286px"
                style={{
                  overflow: "auto",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  height: "100%",
                }}
              >
                <SideMenu
                  collapsed={collapsed}
                  openSupport={openSupportOnClick}
                  logout={props.logout}
                  children={props.children}
                  handleCollapseOpen={handleCollapseOpen}
                />
              </Sider>
            </div>
            <Modal
              maskStyle={{
                backgroundColor: `transparent`,
              }}
              title="Technical Support"
              className="support-modal"
              visible={openSupport}
              onCancel={closeSupportModal}
              footer={null}
              style={{ top: 100 }}
            >
              <SupportForm />
            </Modal>
          </div>
          <Layout style={{ minHeight: "100vh" }}>
            <Layout className={""}>
              {props.auth.isAuthenticated ? <Head {...props} /> : null}
              <Content className="pureblackBG">
                <div
                  id="container"
                  className={
                    window.location.href.substring(
                      window.location.href.lastIndexOf("/"),
                    ) === "/" ||
                    window.location.href.toLowerCase().includes("home")
                      ? "fullHeight"
                      : "fullHeight"
                  }
                >
                  {props.children}
                </div>
              </Content>
            </Layout>
          </Layout>
        </StyledDiv>
      ) : (
        <div data-testid="isAuth_false_div">{props.children}</div>
      )}
    </div>
  );
};

function mapToStateToProps(state: any) {
  return {
    auth: state.authReducer,
    SystemMessage: state.SystemMessage,
  };
}

export default connect(
  mapToStateToProps,
  {
    logout,
    resetAlertMessage,
  },
  null,
  { pure: false },
)(AppLayout);
