import * as React from "react";
import { Block, Text } from "../../../models/ScriptInterfaces";
import { isNullOrUndefined } from "../../../utils/utilityfunctions";
import {
  Container,
  FixedDiv,
  ScrollableDiv,
} from "../../../styles/EditComponentTheme.styled";
import { TextMenuColumn } from "./EditFormTheme";
import TextEditForm from "./EditForms/TextEditForm/TextEditForm";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { SetTextDescription, SetTextTitle } from "../MainlistSlice";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";
import { IconColumn } from "./IconColumn";

export interface TextBlock extends Block {
  BlockContent: Text;
}

interface Props {
  object: TextBlock;
  onChange: Function;
  onUpdate: Function;
  onSave: Function;
  onDelete: Function;
  onCopy: Function;
}

const TextEdit = (props: Props) => {
  const dispatch = useAppDispatch();
  const { isReadOnly } = useAppSelector((state: any) => state.MainlistReducer);
  React.useEffect(() => {
    if (!isNullOrUndefined(props.object)) {
      dispatch(SetTextDescription(props.object.BlockContent.Description));
      dispatch(SetTextTitle(props.object.BlockContent.Title));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.object]);

  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const onDeleteConfirm = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "Are you sure you want to delete this Block?",
      content: "This cannot be undone",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        props.onDelete(props.object);
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        console.log("Cancel");
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };
  const onCopyConfirm = () => {
    props.onCopy(props.object);
    setIsOpenConfirmDialog(false);
  };

  const onUpdateColumnCount = (dualColumn: boolean) => {
    if (isReadOnly) return; //disable action callback in read only script
    const newBlock = {
      ...props.object,
      BlockContent: {
        ...props.object.BlockContent,
        DisplayedAsTwoColumns: dualColumn,
      },
    };
    props.onUpdate(newBlock);
  };

  const handleImportantTextToggle = (isImportant: boolean) => {
    if (isReadOnly) return; //disable action callback in read only script
    const newBlock = {
      ...props.object,
      BlockContent: {
        ...props.object.BlockContent,
        IsImportant: isImportant,
      },
    };
    props.onUpdate(newBlock);
  };
  const handleTitleToggle = (isTitleEnabled: boolean) => {
    if (isReadOnly) return; //disable action callback in read only script
    const newBlock = {
      ...props.object,
      BlockContent: {
        ...props.object.BlockContent,
        IsTitleEnabled: isTitleEnabled,
      },
    };
    props.onUpdate(newBlock);
  };

  return (
    <Container>
      <FixedDiv phase={false}>
        <IconColumn
          type="text"
          onCopyClick={onCopyConfirm}
          onDeleteClick={onDeleteConfirm}
        />
      </FixedDiv>
      <ScrollableDiv id="scrollableDiv" phase={false}>
        <TextMenuColumn
          type="text"
          blockContentColumnDisplay={
            props.object?.BlockContent.DisplayedAsTwoColumns
          }
          handleImportantTextToggle={handleImportantTextToggle}
          isImportant={props.object?.BlockContent.IsImportant}
          object={props.object}
          makeDualColumn={onUpdateColumnCount}
          isImportantNeeded={true}
        />
        <TextEditForm
          isTitleEnabled={props.object?.BlockContent.IsTitleEnabled}
          toggleTitle={handleTitleToggle}
          onChange={props.onChange}
          onSave={props.onSave}
        />
      </ScrollableDiv>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </Container>
  );
};
export default TextEdit;
