import { Slider } from "antd";
import React from "react";
import { ResearchQuestionNumber } from "../../../../../models/ScriptInterfaces";
interface Props {
  question: ResearchQuestionNumber;
}
export default function SliderNumberType(props: Props) {
  const getSliderValue = (minVal: number, maxVal: number) => {
    if (!minVal && !maxVal) return 0;
    if (minVal && maxVal) {
      if (maxVal >= minVal) {
        if (maxVal >= 0 && minVal >= 0) {
          return Number(Number((maxVal - minVal) / 2).toFixed(2));
        } else if (minVal < 0) {
          return Number(Number((maxVal + minVal) / 2).toFixed(2));
        } else {
          return Number(Number((maxVal - minVal) / 2).toFixed(2));
        }
      }
      return Number(Number(minVal).toFixed(2));
    }
    if (!minVal && maxVal) return Number(Number((maxVal / 2).toFixed(2)));
    if (minVal && !maxVal) return Number(Number(minVal).toFixed(2));
    return 0;
  };
  const getMinValue = () => {
    if (!props.question.IsRange) {
      return Number(props.question.MinValue1);
    } else {
      const MinValue1 = Number(props.question.MinValue1);
      const MinValue2 = Number(props.question.MinValue2);
      return MinValue1 > MinValue2 ? MinValue2 : MinValue1;
    }
  };
  const getMaxValue = () => {
    if (!props.question.IsRange) {
      return Number(props.question.MaxValue1);
    } else {
      const MaxValue1 = Number(props.question.MaxValue1);
      const MaxValue2 = Number(props.question.MaxValue2);
      return MaxValue1 > MaxValue2 ? MaxValue1 : MaxValue2;
    }
  };
  return (
    <div className="mt-8 mb-2">
      {props.question.IsRange ? (
        // Slider with Range
        <div className="slider-wrapper">
          <span>Min</span>
          <div className="slider-with-range">
            <Slider
              value={[
                getSliderValue(
                  Number(props.question.MinValue1 || 0),
                  Number(props.question.MaxValue1 || 0)
                ),
                getSliderValue(
                  Number(props.question.MinValue2 || 0),
                  Number(props.question.MaxValue2 || 0)
                ),
              ]}
              range
              step={1}
              min={getMinValue()}
              max={getMaxValue()}
            />
            <div className="text-primary font-size-n1 text-center mt-1 range-1">
              {`(${props.question.MinValue1}`} &minus;{" "}
              {`${props.question.MaxValue1})`}
            </div>
            <div className="text-primary font-size-n1 text-center mt-1 range-2">
              {`(${props.question.MinValue2}`} &minus;{" "}
              {`${props.question.MaxValue2})`}
            </div>
          </div>
          <span>Max</span>
        </div>
      ) : (
        // Slider without Range
        <div className="slider-wrapper">
          <span>{Number(props.question.MinValue1)}</span>
          <Slider
            value={getSliderValue(
              Number(props.question.MinValue1 || 0),
              Number(props.question.MaxValue1 || 0)
            )}
            min={Number(props.question.MinValue1)}
            max={Number(props.question.MaxValue1)}
          />
          <span>{Number(props.question.MaxValue1)}</span>
        </div>
      )}
    </div>
  );
}
