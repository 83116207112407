import "@ant-design/compatible/assets/index.css";
import {
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  ForkOutlined,
  HeartOutlined,
  LikeOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Menu,
  Row,
  Select,
  TableProps,
  Tooltip,
} from "antd";
import * as React from "react";
import { useState } from "react";
import { GoArchive } from "react-icons/go";
import { connect } from "react-redux";
import { setAlertMessage } from "../../../actions/alertMessage";
import {
  AddInjuryInsults,
  CreateInjury,
  deleteInjury,
  RemoveInjuryInsults,
  UpdateInjury,
} from "../../../api/Insult/ApiPost";
import { SystemMessage } from "../../../app/modules";
import { Input, Table } from "../../../components";
import Drawer from "../../../components/Drawer/Drawer";
import { MultiSelector } from "../../../components/MultiSelector/MultiSelector";
import {
  DeleteIcon,
  EditOutlinedIcons,
} from "../../../IconsTheme/Icons.global";
import { AlertType } from "../../../models/Enums";
import { Injury } from "../../../models/InjuryInterface";
import { Insult } from "../../../models/InsultInterface";
import { isNullOrUndefined } from "../../../utils/utilityfunctions";
import { StyledForm } from "../../ScriptBuilder/EditComponents/EditForms/PhaseForm/PhaseForm.styled";
import { FormContainer } from "../../ScriptBuilder/EditComponents/EditForms/RolePlayerForm/PromptForm.styled";
import { FloatingLabel } from "../../../components/FloatingLabel/FloatingLabel.styled";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";
import { useAppSelector } from "../../../store/hooks";

const { Option } = Select;

export type InjuryForm = {
  id?: number;
  title: string;
  type: string;
};

interface Props {
  auth: any;
  injuryList: Injury[];
  insultList: Insult[];
  SystemMessage: SystemMessage;
  setAlertMessage: Function;
  refreshInjury: Function;
}

const InjurytList = ({
  auth,
  injuryList,
  insultList,
  setAlertMessage,
  refreshInjury,
}: Props) => {
  const initialValues = {
    Id: 0,
    Title: "",
    Type: "",
    Insults: [],
    AppliedLocationSide: "",
    RenderLocationAs: "-",
  };

  const initialFormValues = {
    id: 0,
    title: "",
    type: "",
  };
  const { bulkActionBtn } = useAppSelector(
    (state) => state.FeatureFlagReducer.manageContent,
  );
  const [addModal, setAddModal] = React.useState<boolean>(false);

  const [viewDetaislModal, setviewDetaislModal] = useState<boolean>(false);
  const [ViewDetails, setViewDetails] = useState<any>(initialValues);
  const [currentInsults, setCurrentInsults] = useState<Insult[]>([]);
  const [selectedInsults, setSelectedinsults] = useState<number[]>([]);
  const [selectedInjry, setSelectedInjry] = useState<Injury>(initialValues);

  const [formValues, setFormValues] = useState<InjuryForm>(initialFormValues);

  const initialFormErrors = {
    label: "",
  };
  const [formErrors, setFormErrors] = useState<any>(initialFormErrors);

  const [modalTitle, setModalTitle] = useState<string>("");

  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const validate = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (event.target.name === "label" && formValues.title.length === 0) {
      setFormErrors({
        label: "Please enter title",
      });
    } else {
      setFormErrors({ label: "" });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  // Handle transition change for multi select input.
  const handleTransitionChange = (e: any) => {
    const insults: Insult[] = [];

    e.forEach((insultId: number) => {
      const foundInsult: any = insultList.find(
        (insult: Insult) => insult.Id === insultId,
      );
      insults.push(foundInsult);
    });
    setCurrentInsults(insults);
    setSelectedinsults(e);
  };

  const error = (data: any) => {
    console.log(data);
    setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An error occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
  };

  // Display success message with pop-up and resets the form.
  const success = () => {
    setFormValues(initialFormValues);

    refreshInjury();
    setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: "The injury is successfully created",
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });
  };

  // Reset the form inputs.
  const resetForm = () => {
    setAddModal(false);
    setFormValues(initialFormValues);
    setFormErrors(initialFormErrors);
    setSelectedinsults([]);
  };

  const updateInjurySuccess = (data: Injury) => {
    const currentInsultIds = currentInsults.map((x: Insult) => x.Id);
    const oldInsultIds = selectedInjry.Insults!.map((x: Insult) => x.Id);
    if (selectedInjry.Id) {
      const filteredAddedinsults = currentInsultIds.filter(
        (id) => !oldInsultIds.includes(id),
      );
      const filteredRemovedInsults = oldInsultIds.filter(
        (id) => !currentInsultIds.includes(id),
      );

      if (filteredAddedinsults.length > 0) {
        AddInjuryInsults(
          selectedInjry.Id!,
          filteredAddedinsults,
          success,
          error,
        );
      }
      console.log(filteredRemovedInsults);
      if (filteredRemovedInsults.length > 0) {
        for (let i = 0; i < filteredRemovedInsults.length; i++) {
          RemoveInjuryInsults(
            selectedInjry.Id!,
            filteredRemovedInsults[i],
            success,
            error,
          );
        }
      }
      success();
    }
  };

  // Will add insults to the injury.
  const createInjurySuccess = (data: Injury) => {
    const insultIds = currentInsults.map((x: Insult) => x.Id);
    AddInjuryInsults(data.Id!, insultIds, success, error);
  };

  // Add / Edit injury.
  const add = () => {
    const { id, title, type } = formValues;

    if (title.length > 0) {
      const newInjury: InjuryForm = {
        id: id || 0,
        title: title,
        type: type,
      };

      if (newInjury.id !== 0) {
        UpdateInjury(newInjury, updateInjurySuccess, error);
      } else {
        if (currentInsults.length > 0) {
          CreateInjury(newInjury, createInjurySuccess, error);
        } else {
          CreateInjury(newInjury, success, error);
        }
      }
    }
    resetForm();
  };

  const closeAddModal = () => {
    resetForm();
    setAddModal(false);
  };

  // Delete injury
  const deleteFullInjury = (id: number) => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to delete the equipment",
      content: "Are you sure you want to delete this equipment permanently",
      okText: "Yes",
      onOk: () => {
        deleteInjury(id, success, error);
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: () => {
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };

  // Open view injury details panel with injury data.
  const detais = (injury: Injury) => {
    setViewDetails({
      Type: injury.Type,
      Title: injury.Title,
      Insults: injury.Insults,
    });

    console.log(ViewDetails);

    setviewDetaislModal(true);
  };

  const closeDetaisModal = () => {
    setviewDetaislModal(false);
  };

  // Open edit injury form with all the data pre populated.
  const edit = (injury: Injury, modalTitle: string) => {
    setModalTitle(modalTitle);
    setFormValues({
      id: injury.Id,
      title: injury.Title,
      type: injury.Type,
    });
    setSelectedInjry(injury);
    setCurrentInsults(injury.Insults || []);
    const currentInsults = injury.Insults!.map((x: Insult) => x.Id);
    if (isNullOrUndefined(currentInsults)) {
      setSelectedinsults([]);
    } else {
      setSelectedinsults(currentInsults as number[]);
    }

    setAddModal(true);
  };

  const [modalId, setModalId] = useState<any>(0);
  const [currentModal, setCurrentModal] = useState<any>(false);

  const showModalForAction = (e: any) => {
    const modalBodyElement = e.target.parentNode.nextElementSibling;
    const clickedScriptPosition = e.target.getBoundingClientRect().bottom;
    const tableBody = document.getElementsByClassName(
      "ant-table-tbody",
    ) as HTMLCollectionOf<HTMLElement>;
    if (modalBodyElement != null) {
      if (modalBodyElement.className === "modal-body") {
        modalBodyElement.style.display = "block";
        if (tableBody[0].getBoundingClientRect().y < window.innerHeight) {
          tableBody[0].style.minHeight = "70vh";
        }
        if (window.innerHeight - clickedScriptPosition < 300) {
          modalBodyElement.style.bottom = "103%";
          modalBodyElement.style.top = null;
        } else {
          modalBodyElement.style.top = "103%";
          modalBodyElement.style.bottom = null;
        }
        setCurrentModal(true);
        setModalId(modalBodyElement.id);
        if (modalId === modalBodyElement.id) {
          if (currentModal) {
            document.getElementById(modalId)!.style.display = "none";
            setCurrentModal(false);
          }
        }
        if (
          modalId != null &&
          modalId !== modalBodyElement.id &&
          document.getElementById(modalId) != null
        ) {
          document.getElementById(modalId)!.style.display = "none";
        }
      }
    }
  };

  const mockFunction = () => {
    console.log("mock");
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      render: (title: string) => (
        <>
          <span>{title}</span>
          <Row
            className="cell-icon-align-right"
            onClick={(e: any) => e.stopPropagation()}
          >
            <HeartOutlined
              onClick={mockFunction}
              style={{ fontSize: "21px" }}
            />
            {/* <HeartFilled className="color-light-blue" style={{ fontSize: "21px", float: "right" }} /> */}
          </Row>
        </>
      ),
      sorter: (a: any, b: any) => a.Title.localeCompare(b.Title),
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      className: "whiteText",
      render: (Type: string) => <span>{Type}</span>,
      sorter: (a: any, b: any) => a.Type.localeCompare(b.Type),
    },
    {
      title: "Insults",
      dataIndex: "Insults",
      key: "Insults",
      className: "whiteText",
      render: (insults: Insult[]) => (
        <>
          {insults.map((insult: Insult, index: number) => (
            <span key={insult.Id}>
              {insult.Label}
              {index !== insults.length - 1 ? ", " : ""}
            </span>
          ))}
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      className: "whiteText",
      render: (Id: any, record: any) => {
        return (
          <Row
            justify="space-between"
            onClick={(e: any) => e.stopPropagation()}
          >
            <Tooltip placement="bottom" title="">
              {auth.user.type === "root" ||
              auth.user.type.includes("root") ||
              record.CreatedBy === auth.user.nameid ? (
                <div className={"modal-container"}>
                  <MoreOutlined
                    className="actions-item action-more"
                    onClick={showModalForAction}
                  />
                  <div className={"modal-body"} id={record.Id}>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {auth.user.type === "root" ||
                      auth.user.type.includes("root") ||
                      record.CreatedBy === auth.user.nameid ? (
                        <div
                          onClick={() => {
                            edit(record, "Edit Equipment");
                          }}
                        >
                          <EditOutlined className="actions-item action-edit" />
                          <div className={"tooltip-action-text"}>Edit</div>
                        </div>
                      ) : (
                        <EditOutlinedIcons className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    {/* change properties for forking */}
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {auth.user.type === "root" ||
                      auth.user.type.includes("root") ||
                      record.CreatedBy === auth.user.nameid ? (
                        <div
                          onClick={() => {
                            window.location.href = `/CreateScript/${record.Id}`;
                          }}
                        >
                          <ForkOutlined className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Fork</div>
                        </div>
                      ) : (
                        <ForkOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {auth.user.type === "root" ||
                      auth.user.type.includes("root") ||
                      record.CreatedBy === auth.user.nameid ? (
                        <div
                          onClick={() => {
                            window.location.href = `/CreateScript/${record.Id}`;
                          }}
                        >
                          <LikeOutlined className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Approve</div>
                        </div>
                      ) : (
                        <LikeOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {auth.user.type === "root" ||
                      auth.user.type.includes("root") ||
                      record.CreatedBy === auth.user.nameid ? (
                        <div
                          onClick={() => {
                            window.location.href = `/CreateScript/${record.Id}`;
                          }}
                        >
                          <SearchOutlined className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Review</div>
                        </div>
                      ) : (
                        <SearchOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {auth.user.type === "root" ||
                      auth.user.type.includes("root") ||
                      record.CreatedBy === auth.user.nameid ? (
                        <div
                          onClick={() => {
                            window.location.href = `/CreateScript/${record.Id}`;
                          }}
                        >
                          <GoArchive className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Archive</div>
                        </div>
                      ) : (
                        <ForkOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {auth.user.type === "root" ||
                      auth.user.type.includes("root") ||
                      record.CreatedBy === auth.user.nameid ? (
                        <div onClick={() => deleteFullInjury(record.Id)}>
                          <DeleteOutlined className="actions-item action-delete" />
                          <div className={"tooltip-action-text"}>Delete</div>
                        </div>
                      ) : (
                        <DeleteIcon className="disable-icon" />
                      )}
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <DeleteIcon className="disable-icon" />
              )}
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const onChange: TableProps<any>["onChange"] = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any,
  ) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <span className="menu-item">
              <LikeOutlined className="disable-icon" /> &nbsp; Approve
            </span>
          ),
        },
        {
          key: "2",
          label: (
            <span className="menu-item">
              <SearchOutlined className="disable-icon" /> &nbsp; Review
            </span>
          ),
        },
        {
          key: "3",
          label: (
            <span className="menu-item">
              <GoArchive className="disable-icon" /> &nbsp; Archive
            </span>
          ),
        },
        {
          key: "4",
          label: (
            <span className="menu-item">
              <DeleteOutlined className="disable-icon" /> &nbsp; Delete
            </span>
          ),
        },
      ]}
    />
  );

  return (
    <div data-testid="allergy-list" className="allergy-list-container">
      <Row className="flex-container-align-item-right">
        <Button
          className="btn btn-blue"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalTitle("Add Injury");
            setAddModal(true);
          }}
        >
          Add New
        </Button>
        {bulkActionBtn && (
          <Dropdown className="btn btn-grey" overlay={menu}>
            <Tooltip title={`${bulkActionBtn ? "- Hidden in Prod" : ""}`}>
              <Button>Bulk Actions</Button>
            </Tooltip>
          </Dropdown>
        )}
      </Row>

      <Table
        dataSource={injuryList}
        columns={columns}
        rowKey={(record: any) => record.Id}
        rowClassName={() => "rxblackBG"}
        showSorterTooltip={false}
        pagination={{
          hideOnSinglePage: true,
          pageSize: injuryList.length,
        }}
        onChange={onChange}
        sortDirections={["ascend", "descend", "ascend"]}
        onRow={(record: any) => {
          return {
            onClick: () => {
              detais(record);
            }, // click row
            onDoubleClick: () => undefined, // double click row
            onContextMenu: () => undefined, // right button click row
            onMouseEnter: () => undefined, // mouse enter row
            onMouseLeave: () => undefined, // mouse leave row
          };
        }}
      />

      <Drawer
        className="custom-css-ant-drawer"
        visible={addModal}
        onClose={add}
        closable
        width="600"
      >
        <h1>
          <ArrowRightOutlined
            style={{ marginRight: "20px" }}
            onClick={closeAddModal}
          />
          {modalTitle}
        </h1>
        <FormContainer>
          <StyledForm>
            <FloatingLabel>
              <Input
                value={formValues.title}
                placeholder=" "
                name="title"
                type="text"
                onChange={handleInputChange}
                onBlur={validate}
                style={{ marginBottom: "30px" }}
              />
              <label htmlFor="title">Title *</label>
              {formErrors.title && (
                <span className="errorMessage">{formErrors.title}</span>
              )}
            </FloatingLabel>
            <FloatingLabel>
              <Input
                value={formValues.type}
                placeholder=" "
                name="type"
                type="text"
                onChange={handleInputChange}
                style={{ marginBottom: "30px" }}
              />
              <label htmlFor="type">type</label>
              {formErrors.type && (
                <span className="errorMessage">{formErrors.type}</span>
              )}
            </FloatingLabel>
            <Form.Item>
              <label htmlFor="allergies">Select Insults</label>
              <MultiSelector
                style={{ width: "100%" }}
                value={selectedInsults}
                mode="multiple"
                onChange={handleTransitionChange}
              >
                {insultList.map((insult: Insult, index: number) => (
                  <Option key={"objectType" + index} value={insult.Id}>
                    {insult.Label}
                  </Option>
                ))}
              </MultiSelector>
              {formErrors.allergies && (
                <span className="allergies">{formErrors.allergies}</span>
              )}
            </Form.Item>
          </StyledForm>
        </FormContainer>
      </Drawer>

      {/* View details of perticular injury record. */}
      <Drawer
        className="custom-css-ant-drawer"
        visible={viewDetaislModal}
        onClose={closeDetaisModal}
        closable
        width="600"
      >
        <h1>
          <ArrowRightOutlined
            style={{ marginRight: "20px" }}
            onClick={closeDetaisModal}
          />{" "}
          {ViewDetails.Title}{" "}
        </h1>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">Type</p>
          </Col>
          <Col span={18}>
            <p>{ViewDetails.Type}</p>
          </Col>
        </Row>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">Insults</p>
          </Col>
          <Col span={18}>
            {ViewDetails.Insults.map((insult: Insult) => {
              return (
                <span key={insult.Id}>
                  {insult.Label}
                  <br />
                </span>
              );
            })}
          </Col>
        </Row>
      </Drawer>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </div>
  );
};

function mapToStateToProps(state: any) {
  return {
    SystemMessage: state.SystemMessage,
    auth: state.authReducer,
  };
}

export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(InjurytList);
