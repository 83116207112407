import React, { useCallback, useEffect, useState } from "react";
import { Row, Tooltip } from "antd";
import { RiRefreshLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { CloseOutlined, FilterOutlined, PlusOutlined } from "@ant-design/icons";
import Dropmenu from "./Dropmenu";
import { SearchFilter } from "../../components";
import CustomTable from "../../components/Table/CustomTable";
import { WhiteButton } from "../ScriptBuilder/EditComponents/ScriptDetailsView/InjuryTab/InjuryTab.styled";
import {
  RightSideTableHeader,
  StyledRow,
} from "../../components/Table/CustomTable.styled";
import Button from "../../components/Button/Button";
import { ReactComponent as Archive } from "../../assets/img/Svg/Archive.svg";
import Icon from "@ant-design/icons/lib/components/Icon";
import { Script } from "../../models/ScriptInterfaces";
import SwitchWithLabel from "../../components/Switch/SwitchWithLabel";
import { useSelector } from "react-redux";
import User from "../../models/User";
import { isNullOrUndefined } from "is-what";

interface PropTypes {
  columns: any[];
  data: Script[];
  setIncludeArchived: Function;
  includeArchived: boolean;
  setToggleChecked: Function;
  toggleChecked: boolean;
  archivedScriptRow: Function;
}

export default function ViewScriptsFilter({
  columns,
  data,
  includeArchived,
  setIncludeArchived,
  setToggleChecked,
  toggleChecked,
  archivedScriptRow,
}: PropTypes) {
  // Search input
  const [searchInput, setSearchInput] = useState("");
  // Difficulty filter
  const [difficultyParams, setDifficultyParams] = useState<any[]>([]);
  // Author filter
  const [authorParams, setAuthorParams] = useState<any[]>([]);
  const [authors, setAuthors] = useState<User[]>([]);
  const [filteredAuthors, setFilteredAuthors] = useState<User[]>([]);
  // MARCH filter
  const [MARCHParams, setMARCHParams] = useState<any[]>([]);
  // Breadcrumb string
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);

  useEffect(() => {
    if (!data) return;

    const authorsCopy = [...authors];

    data.forEach((script) => {
      if (!isNullOrUndefined(script.CreatedBy)) {
        const existingAuthor = authorsCopy.find(
          (author) => author.Id === script.CreatedBy?.Id,
        );
        if (isNullOrUndefined(existingAuthor)) {
          authorsCopy.push(script.CreatedBy);
        }
      }
    });

    authorsCopy?.sort((a: User, b: User) =>
      a.LastName?.localeCompare(b.LastName),
    );

    setAuthors(authorsCopy);
  }, [data]);

  const { scriptTableFeature } = useSelector(
    (state: any) => state.FeatureFlagReducer,
  );

  const getAuthorName = (author: User | undefined) => {
    return !isNullOrUndefined(author)
      ? `${author.LastName} ${author.FirstName}`
      : "";
  };

  useEffect(() => {
    const breadcrumb = [];
    if (MARCHParams.length) breadcrumb.push("MARCH");
    if (difficultyParams.length) breadcrumb.push("Difficulty");
    if (authorParams.length) breadcrumb.push("Instructor");
    setBreadcrumbs(breadcrumb);
  }, [difficultyParams, authorParams, MARCHParams]);

  const clearBreadcrumb = useCallback(
    (index: number) => {
      switch (breadcrumbs[index]) {
        case "MARCH":
          setMARCHParams([]);
          break;
        case "Difficulty":
          setDifficultyParams([]);
          break;
        case "Instructor":
          setAuthorParams([]);
          break;
        default:
      }
    },
    [breadcrumbs],
  );

  const clearAll = useCallback(() => {
    setSearchInput("");
    setAuthorParams([]);
    setDifficultyParams([]);
    setMARCHParams([]);
  }, []);

  const handleSearch = (text?: string) => {
    // don't display script till instructor data available
    if (!data?.length) {
      return [];
    }
    // Guard clause for filters
    if (
      !text &&
      !difficultyParams.length &&
      !authorParams.length &&
      !MARCHParams.length
    )
      return data;

    const filterByAuthor = data.filter((entry) =>
      authorParams.length
        ? authorParams
            .map((authParam) => getAuthorName(authParam))
            .includes(getAuthorName(entry.CreatedBy))
        : data,
    );

    function hasWord(target: string, query: string) {
      if (!target) return false;
      return target.toLowerCase().indexOf(query.toLowerCase()) > -1;
    }

    // Search guard clause
    if (!text) return filterByAuthor;
    return filterByAuthor.filter((result: any) => {
      if (
        hasWord(result.Title, text.trim()) ||
        hasWord(getAuthorName(result.CreatedBy), text.trim())
      )
        return true;
      return false;
    });
  };
  return (
    <>
      <StyledRow justify="space-between">
        <Row className="btn-row">
          {scriptTableFeature.tableHeader.filter && (
            <Tooltip title={`Filter`}>
              <WhiteButton
                type="link"
                icon={<FilterOutlined className="icon-lg" />}
                size="large"
              />
            </Tooltip>
          )}
          <SearchFilter
            className="searchbar"
            handleSearch={(value: string) => setSearchInput(value)}
          />

          {/* <Dropmenu
            label="MARCH"
            params={MARCHParams}
            contents={[0, 1, 2, 3, 4]}
            contentNames={[
              "Massive Hemorrhage",
              "Airway",
              "Respiration",
              "Circulation",
              "Head & Hypothermia",
            ]}
            disabled={true}
            setParams={setMARCHParams}
          />
          <Dropmenu
            label="Difficulty"
            params={difficultyParams}
            contents={[1, 2, 3, 4, 5]}
            disabled={true}
            contentNames={[
              "Easy",
              "Moderate",
              "Difficult",
              "Expert",
              "Impossible",
            ]}
            setParams={setDifficultyParams}
          /> */}
          <Dropmenu
            label="Authors"
            params={authorParams}
            contents={authors}
            disabled={false}
            contentNames={authors.map((author: User) => getAuthorName(author))}
            setParams={setAuthorParams}
          />

          <div data-testid="view-script-filter" className="breadcrumb-row">
            {breadcrumbs.map((value: string, index: number) => (
              <span
                className="breadcrumb-filter onHover-white-text onHover-red-icon"
                key={index}
                onClick={() => clearBreadcrumb(index)}
              >
                {value} <CloseOutlined />
              </span>
            ))}
          </div>
          {scriptTableFeature.tableHeader.clearAll && (
            <Tooltip placement="top" title="Clear All Filters">
              <button
                data-testid="clear-all"
                className="clear-all onHover-red-icon"
                disabled={true}
                onClick={clearAll}
              >
                <RiRefreshLine />
              </button>
            </Tooltip>
          )}
        </Row>
        <RightSideTableHeader>
          <Tooltip
            placement="top"
            title={`${includeArchived ? "Hide" : "Include"} Archived Scripts`}
          >
            <div className="include-archived-scripts">
              <button
                data-testid="clear-all"
                className={`archived-scripts ${
                  includeArchived ? "active " : ""
                }`}
                onClick={() => {
                  setToggleChecked((checked: boolean) => !checked);
                  setIncludeArchived((prev: boolean) => !prev);
                }}
              >
                <Icon component={Archive} />
              </button>

              <SwitchWithLabel
                isForTreatment={false}
                rightLabel={""}
                onChange={() => {
                  setIncludeArchived((prev: boolean) => !prev);
                  setToggleChecked((checked: boolean) => !checked);
                }}
                checked={toggleChecked}
              />
            </div>
          </Tooltip>
          <NavLink
            to="/CreateScript"
            data-testid="create-script-link"
            className="create-btn-link"
          >
            <Button
              type="primary"
              shape={"rounded"}
              leftIcon={<PlusOutlined />}
            >
              Create New
            </Button>
          </NavLink>
        </RightSideTableHeader>
      </StyledRow>
      <CustomTable
        columns={columns}
        data={handleSearch(searchInput)}
        dynamicRowClass={archivedScriptRow}
      />
    </>
  );
}
