import styled from "styled-components";

export const StyledWrapper = styled.div`
   {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    & > .inline-editing {
      width: 100%;
    }

    &.inline-display {
      margin-left: 10px;
    }
  }
`;
