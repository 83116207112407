import Icon, {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import * as React from "react";
import { Treatment } from "../../../../models/ScriptInterfaces";
import { ActionIcon } from "../../../../components/Table/CustomTable.styled";
import CustomTable from "../../../../components/Table/CustomTable";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../components/ConfirmDialog/useConfirmDialog";
import { colorPalette } from "../../../../styles/MainList.styled";
import { ReactComponent as Archive } from "../../../../assets/img/Svg/Archive.svg";
import { renderFormattedName } from "../../../../utils/utilityfunctions";
import { useDispatch } from "react-redux";
import { BlockType } from "../../../../models/Enums";
import {
  onArchiveUnarchiveSuccess,
  onCreateSuccess,
  onDeleteSuccess,
} from "./TreatmentSlice";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../../components/Notification/Notification";
import { archiveUnarchiveTreatment } from "../../../../api/Treatment/ApiPut";
import { copyTreatment } from "../../../../api/Treatment/ApiPost";
import { TreatmentTaskBlock } from "../../../../models/TreatmentTaskBlock";
import { TimedActionTaskSection } from "../../../ScriptBuilder/SectionComponents";
import { TreatmentTask } from "../../../../models/TreatmentTask";
import { deleteTreatmentTask } from "../../../../api/TreatmentTask";
import { archiveOrUnarchiveMasterTimedAction } from "../../../../api/TimedActionTask";
import { useState } from "react";
interface Props {
  handleSearch: any;
  showDetails: any;
  onEdit: any;
  searchInput: string;
}
export default function TreatmentTable({
  onEdit,
  showDetails,
  handleSearch,
  searchInput,
}: Props) {
  const dispatch = useDispatch();
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const renderActionOptions = (data: TreatmentTask) => {
    return !data.IsArchived ? (
      <div className="action-tray">
        {/* edit icon */}
        <ActionIcon
          className="action-option"
          hoverColor={colorPalette.white}
          onClick={() => onEdit(data)}
        >
          <span className="tooltip-on-hover">Edit</span>
          <EditOutlined className="display-on-hover" />
        </ActionIcon>
        {/* copy icon */}
        <ActionIcon
          className="action-option"
          hoverColor={colorPalette.white}
          onClick={() => handleCopy(data.Id)}
        >
          <span className="tooltip-on-hover">Copy</span>
          <CopyOutlined className="display-on-hover" />
        </ActionIcon>
        {/* archive/ unarchive icons */}
        <ActionIcon
          className="action-option"
          hoverColor={
            !data.IsArchived ? colorPalette.error : colorPalette.primary100
          }
          onClick={() => archiveTask(data)}
        >
          <span className="tooltip-on-hover">
            {data.IsArchived ? "Unarchive" : "Archive"}
          </span>
          <Icon component={Archive} className="display-on-hover" />
        </ActionIcon>
        {/* delete icon */}
        <ActionIcon
          className="action-option"
          hoverColor={colorPalette.error}
          onClick={() => handleDelete(data.Id)}
        >
          <span className="tooltip-on-hover">Delete</span>
          <DeleteOutlined className="display-on-hover" />
        </ActionIcon>
      </div>
    ) : (
      <div className="action-tray">
        {/* archive/ unarchive icons */}
        <ActionIcon
          className="action-option"
          hoverColor={
            !data.IsArchived ? colorPalette.error : colorPalette.primary100
          }
          onClick={() => archiveTask(data)}
        >
          <span className="tooltip-on-hover">
            {data.IsArchived ? "Unarchive" : "Archive"}
          </span>
          <Icon component={Archive} className="display-on-hover" />
        </ActionIcon>
        {/* delete icon */}
        <ActionIcon
          className="action-option"
          hoverColor={colorPalette.error}
          onClick={() => handleDelete(data.Id)}
        >
          <span className="tooltip-on-hover">Delete</span>
          <DeleteOutlined className="display-on-hover" />
        </ActionIcon>
      </div>
    );
  };

  const archiveTask = (data: TreatmentTask) => {
    archiveOrUnarchiveMasterTimedAction(
      data.Id,
      () => {
        dispatch(onArchiveUnarchiveSuccess(data.Id));
      },
      (errorMessage: string) => {
        OpenErrorNotification({ description: errorMessage });
      },
    );
  };
  const handleCopy = (id: number) => {
    if (id) {
      id &&
        copyTreatment(
          id,
          (response: TreatmentTask) => {
            dispatch(onCreateSuccess(response));
          },
          (errorMessage: string) => {
            OpenErrorNotification({ description: errorMessage });
          },
        );
    }
  };
  const handleDelete = (id: number) => {
    if (id) {
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "You are going to delete treatment.",
        content: "Are you sure you want delete this treatment?",
        okText: "Yes",
        onOk: () => {
          deleteTreatmentTask(
            id,
            (successMessage: string) => {
              dispatch(onDeleteSuccess(id));
              OpenSuccessNotification({ description: successMessage });
            },
            (errorMessage: string) => {
              OpenErrorNotification({ description: errorMessage });
            },
          );
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "error",
      });
    }
  };
  const renderTask = (data: TreatmentTask) => {
    const block: TreatmentTaskBlock = {
      Id: 0,
      UUID: "0",
      IsGhostBlock: false,
      BlockType: BlockType.Diagnostic,
      Order: 0,
      OrderInNextPhase1: 0,
      OrderInNextPhase2: 0,
      SectionId: 0,
      TimedAction: true,
      BlockContent: {
        Id: 0,
        CreatedInPhaseId: 0,
        IsCritical: false,
        IsEditMasterEnabled: false,
        IsRequired: false,
        RequiredInPhaseOffset: 0,
        TimedActionTaskContent: data,
      },
    };
    return (
      <div
        className={`${
          !data.IsArchived ? "cursor-pointer " : "cursor-disabled "
        }`}
        onClick={() => {
          !data.IsArchived && showDetails(data);
        }}
      >
        <TimedActionTaskSection block={block} type={BlockType.Treatment} />
      </div>
    );
  };
  const columns = [
    {
      title: "Task",
      dataIndex: "",
      key: "type",
      className: "whiteText",
      sorter: (a: any, b: any) => a.Name.localeCompare(b.Name),
      render: (type: any, data: any) => renderTask(data),
    },
    {
      title: "Created By",
      dataIndex: "CreatedByUser",
      key: "CreatedByUser",
      className: "whiteText",
      width: "180px",
      sorter: (a: any, b: any) =>
        a.CreatedByUser?.FirstName.localeCompare(b.CreatedByUser?.FirstName),
      render: (CreatedByUser: any, data: Treatment) => (
        <span className="text-white">
          {data?.CreatedByUser?.LastName || data?.CreatedByUser?.FirstName
            ? renderFormattedName(
                data?.CreatedByUser?.LastName || "",
                data?.CreatedByUser?.FirstName || "",
              )
            : "-"}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "actions",
      className: "actions",
      render: (UpdatedDate: any, data: TreatmentTask) => {
        return renderActionOptions(data);
      },
    },
  ];

  // Need to implement correct disabled css for the Tasks components
  const archivedRow = (data: Treatment) => {
    return data.IsArchived ? "archived-row" : "";
  };

  return (
    <>
      <CustomTable
        WrapperStyle={{ height: `calc(100vh - 65px - 70px)` }}
        columns={columns}
        data={handleSearch(searchInput)}
        dynamicRowClass={archivedRow}
      />
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </>
  );
}
