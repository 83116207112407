import React, { useState, useEffect } from "react";
import { Sex } from "../../../../../models/Enums";
import { GenderFormDiv, IconDiv } from "./CasualtyForm.styled";
import Male from "../../../../../assets/img/Svg/Male.svg";
import MaleGray from "../../../../../assets/img/Svg/MaleGrayIcon.svg";
import Female from "../../../../../assets/img/Svg/Female.svg";
import FemaleGray from "../../../../../assets/img/Svg/FemaleGrayIcon.svg";

interface Props {
  sex: Sex;
  setSex: React.Dispatch<React.SetStateAction<Sex>>;
  onSave: any;
  postSuccess: any;
  error: Function;
  postData: any;
  setPostData: React.Dispatch<any>;
  saveToMale: any;
  saveToFemale: any;
}

const GenderForm = (props: Props) => {
  const [male, setMale] = useState<boolean>(false);
  const [female, setFemale] = useState<boolean>(false);

  useEffect(() => {
    if (props.sex === Sex.Male) {
      setMale(true);
      setFemale(false);
    } else {
      setMale(false);
      setFemale(true);
    }
  }, [props.sex]);

  const setToMale = async () => {
    await props.setSex(Sex.Male);
    setMale(true);
    setFemale(false);

    props.saveToMale();
  };

  const setToFemale = async () => {
    await props.setSex(Sex.Female);
    setFemale(true);

    setMale(false);
    props.saveToFemale();
  };

  return (
    <GenderFormDiv>
      <IconDiv status={male} onClick={setToMale}>
        {male ? (
          <img src={Male} alt="male" />
        ) : (
          <img src={MaleGray} alt="maleGray" />
        )}
      </IconDiv>

      <IconDiv status={female} onClick={setToFemale}>
        {female ? (
          <img src={Female} alt="female" />
        ) : (
          <img src={FemaleGray} alt="femaleGray" />
        )}
      </IconDiv>
    </GenderFormDiv>
  );
};

export default GenderForm;
