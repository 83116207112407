import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import Rx from "../../assets/img/Svg/Rx.svg";
import Tx from "../../assets/img/Svg/Tx.svg";
import Dx from "../../assets/img/Svg/Dx.svg";
import "./SideBar.scss";
import React from "react";
import { BlockType } from "../../models/Enums";
import {
  BuildTitle,
  DragButtonStyle,
  SpanStyle,
} from "../../styles/Sidebar.styled";
import {
  FontSizeOutlinedIcons,
  ExcalmationCircleOutlinedIcons,
  FrownOutlinedIcons,
  GroupOutlinedIcons,
  SoundOutlinedIcons,
  ThunderboltOutlinedIcons,
  UnorderedListOutlinedIcons,
} from "../../IconsTheme/Icons.global";

import { HolderOutlined } from "@ant-design/icons";

export interface TitleProps {
  title: string;
  collapse: boolean;
}

export const TitleHeader = (props: TitleProps) => {
  const [isCollapsed, setIsCollapsed] = useState(props.collapse);

  useEffect(() => {
    setIsCollapsed(props.collapse);
  }, [props.collapse]);

  return (
    <React.Fragment>
      {(() => {
        switch (props.title.toLocaleLowerCase()) {
          case "setup":
            return (
              <BuildTitle isSetUp={true} collapse={isCollapsed}>
                <span>{props.title}</span>
              </BuildTitle>
            );
          default:
            return (
              <BuildTitle isSetUp={false} collapse={isCollapsed}>
                <span>{props.title}</span>
              </BuildTitle>
            );
        }
      })()}
    </React.Fragment>
  );
};

export interface DragButtonProps {
  title: string;
  icon: React.ReactNode;
  iconMode: boolean;
  collapse: boolean;
  disabled?: boolean;
}

export const DragButtons = (props: DragButtonProps) => {
  const [isCollapsed, setIsCollapsed] = useState(props.collapse);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    setIsCollapsed(props.collapse);
  }, [props.collapse]);

  const mouseEntered = () => {
    setIsHover(true);
  };

  const mouseLeaved = () => {
    setIsHover(false);
  };

  return (
    <DragButtonStyle
      onMouseEnter={mouseEntered}
      onMouseLeave={mouseLeaved}
      collapse={props.collapse}
      isOption={props.title === "Options"}
    >
      {!props.collapse ? (
        <div style={{ width: "100%", height: "100%" }}>
          <SpanStyle collapse={isCollapsed} ishovered={isHover}>
            <span> {props.icon} </span>
            <span className="plusIconSpan">
              {" "}
              {!props.disabled && isHover ? (
                <HolderOutlined className="plusIcon" />
              ) : (
                ""
              )}{" "}
            </span>
          </SpanStyle>
        </div>
      ) : (
        <div style={{ width: "100%", height: "100%" }}>
          {(() => {
            switch (props.title.toLocaleLowerCase()) {
              case "casualty":
                return (
                  <SpanStyle collapse={isCollapsed} ishovered={isHover}>
                    <span>
                      {" "}
                      {props.icon} {props.title}{" "}
                    </span>
                    <span className="spanWihoutIconMode">
                      {" "}
                      {!props.disabled && isHover ? (
                        <HolderOutlined />
                      ) : (
                        ""
                      )}{" "}
                    </span>{" "}
                  </SpanStyle>
                );
              case "section":
                return (
                  <SpanStyle collapse={isCollapsed} ishovered={isHover}>
                    <span>{props.title}</span>
                    <span className="spanWihoutIconMode">
                      {" "}
                      {!props.disabled && isHover ? (
                        <HolderOutlined />
                      ) : (
                        ""
                      )}{" "}
                    </span>
                  </SpanStyle>
                );
              case "options":
                return (
                  <SpanStyle
                    collapse={isCollapsed}
                    ishovered={isHover}
                    isOption={true}
                  >
                    <span>
                      {props.icon} {props.title}{" "}
                    </span>
                    <span className="spanWihoutIconMode">
                      {!props.disabled && isHover ? <HolderOutlined /> : ""}{" "}
                    </span>
                  </SpanStyle>
                );
              default:
                return (
                  <SpanStyle collapse={isCollapsed} ishovered={isHover}>
                    <span>
                      {props.title === "Options" ? props.icon : ""}{" "}
                      {props.title}{" "}
                    </span>
                    <span className="spanWihoutIconMode">
                      {" "}
                      {!props.disabled && isHover ? (
                        <HolderOutlined />
                      ) : (
                        ""
                      )}{" "}
                    </span>
                  </SpanStyle>
                );
            }
          })()}
        </div>
      )}
    </DragButtonStyle>
  );
};

export interface Sections {
  id: string;
  order: number;
  items: Item[];
}

export interface Item {
  id: string;
  content: string;
  BlockType: BlockType;
  icon: React.ReactNode;
}

export const ElementItems: Item[] = [
  {
    // index 0
    id: uuidv4() as unknown as string,
    content: "Section",
    BlockType: BlockType.Text,
    icon: <GroupOutlinedIcons />,
  },
  {
    // index 1
    id: uuidv4() as unknown as string,
    content: "Text",
    BlockType: BlockType.Text,
    icon: (
      <FontSizeOutlinedIcons
        style={{
          paddingTop: "2px",
          color: "white",
          height: "90%",
          fontWeight: "bolder",
        }}
      />
    ),
  },
  {
    // index 2
    id: uuidv4() as unknown as string,
    content: "List",
    BlockType: BlockType.ScriptList,
    icon: (
      <UnorderedListOutlinedIcons
        style={{
          paddingTop: "2px",
          color: "white",
          height: "90%",
          fontWeight: "bolder",
        }}
      />
    ),
  },
  {
    // index 3
    id: uuidv4() as unknown as string,
    content: "Casualty",
    BlockType: BlockType.Casualty,
    icon: (
      <FrownOutlinedIcons
        style={{
          paddingTop: "2px",
          color: "white",
          height: "90%",
          fontWeight: "bolder",
        }}
      />
    ),
  },
  {
    // index 4
    id: uuidv4() as unknown as string,
    content: "Treatment",
    BlockType: BlockType.CriticalAction,
    icon: (
      <img
        className="svgIcons"
        style={{ height: "1.5625rem", width: "1.5625rem" }}
        src={Tx}
        alt="treatment"
      />
    ),
  },
  {
    // index 5
    id: uuidv4() as unknown as string,
    content: "Medication",
    BlockType: BlockType.CriticalAction,
    icon: (
      <img
        className="svgIcons"
        style={{ height: "1.5625rem", width: "1.5625rem" }}
        src={Rx}
        alt="medication"
      />
    ),
  },
  {
    // index 6
    id: uuidv4() as unknown as string,
    content: "Diagnostic",
    BlockType: BlockType.CriticalAction,
    icon: (
      <img
        className="svgIcons"
        style={{ height: "1.5625rem", width: "1.5625rem" }}
        src={Dx}
        alt="diagnostic"
      />
    ),
  },
  {
    // index 7
    id: uuidv4() as unknown as string,
    content: "RP Prompt",
    BlockType: BlockType.ScriptRolePlayerPrompt,
    icon: <SoundOutlinedIcons />,
  },
  {
    // index 8
    id: uuidv4() as unknown as string,
    content: "Support Action",
    BlockType: BlockType.SupportAction,
    icon: <GroupOutlinedIcons />,
  },
  {
    // index 9
    id: uuidv4() as unknown as string,
    content: "Research Question",
    BlockType: BlockType.ResearchQuestion,
    icon: <ThunderboltOutlinedIcons />,
  },
  {
    // index 10
    id: uuidv4() as unknown as string,
    content: "Complication",
    BlockType: BlockType.ComplicationList,
    icon: <ExcalmationCircleOutlinedIcons />,
  },
];

export interface DragMenuItem extends Item {
  droppableType: "droppableSubItem" | "droppableItem";
  disabled: boolean;
}

export const SpecialItems: Item[] = [
  {
    id: uuidv4() as unknown as string,
    content: "Section",
    BlockType: BlockType.Text,
    icon: (
      <GroupOutlinedIcons
        style={{
          margin: "0 auto",
          paddingTop: "2px",
          color: "white",
          fontWeight: "bolder",
        }}
      />
    ),
  },
];
export const SetupItems: Item[] = [];

// General Items are added
export const GeneralItems: Item[] = [
  {
    id: uuidv4() as unknown as string,
    content: "Text",
    BlockType: BlockType.Text,
    icon: (
      <FontSizeOutlinedIcons
        style={{
          paddingTop: "2px",
          color: "white",
          height: "90%",
          fontWeight: "bolder",
        }}
      />
    ),
  },
  {
    id: uuidv4() as unknown as string,
    content: "List",
    BlockType: BlockType.ScriptList,
    icon: (
      <UnorderedListOutlinedIcons
        style={{
          paddingTop: "2px",
          color: "white",
          height: "90%",
          fontWeight: "bolder",
        }}
      />
    ),
  },
  {
    id: uuidv4() as unknown as string,
    content: "Casualty Status",
    BlockType: BlockType.Casualty,
    icon: (
      <FrownOutlinedIcons
        style={{
          paddingTop: "1px",
          color: "white",
          height: "90%",
          fontWeight: "bolder",
        }}
      />
    ),
  },
];

export const CriticalItems: Item[] = [
  {
    id: uuidv4() as unknown as string,
    content: "Miscellaneous",
    BlockType: BlockType.CriticalAction,
    icon: (
      <img
        className="svgIcons"
        style={{ height: "1.5625rem", width: "1.5625rem" }}
        src={Tx}
        alt="treatment"
      />
    ),
  },
  {
    id: uuidv4() as unknown as string,
    content: "Treatment",
    BlockType: BlockType.CriticalAction,
    icon: (
      <img
        className="svgIcons"
        style={{ height: "1.5625rem", width: "1.5625rem" }}
        src={Tx}
        alt="treatment"
      />
    ),
  },
  {
    id: uuidv4() as unknown as string,
    content: "Medication",
    BlockType: BlockType.CriticalAction,
    icon: (
      <img
        className="svgIcons"
        style={{ height: "1.5625rem", width: "1.5625rem" }}
        src={Rx}
        alt="medication"
      />
    ),
  },
  {
    id: uuidv4() as unknown as string,
    content: "Diagnostic",
    BlockType: BlockType.CriticalAction,
    icon: (
      <img
        className="svgIcons"
        style={{ height: "1.5625rem", width: "1.5625rem" }}
        src={Dx}
        alt="diagnostic"
      />
    ),
  },
];
export const OtherActionsItems: Item[] = [
  {
    id: uuidv4() as unknown as string,
    content: "Role Player Prompt",
    BlockType: BlockType.ScriptRolePlayerPrompt,
    icon: <SoundOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "Support Action",
    BlockType: BlockType.SupportAction,
    icon: <GroupOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "Research Question",
    BlockType: BlockType.ResearchQuestion,
    icon: <ThunderboltOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "Complication",
    BlockType: BlockType.ComplicationList,
    icon: <ExcalmationCircleOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "Options",
    BlockType: BlockType.Text,
    icon: <UnorderedListOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "Timed Actions",
    BlockType: BlockType.Text,
    icon: <UnorderedListOutlinedIcons />,
  },
];
export const MenuItems: Item[] = [
  {
    id: uuidv4() as unknown as string,
    content: "Section",
    BlockType: BlockType.Text,
    icon: <GroupOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "Text",
    BlockType: BlockType.Text,
    icon: <FontSizeOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "List",
    BlockType: BlockType.ScriptList,
    icon: <UnorderedListOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "Treatment",
    BlockType: BlockType.CriticalAction,
    icon: <GroupOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "Medication",
    BlockType: BlockType.CriticalAction,
    icon: <GroupOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "Diagnostic",
    BlockType: BlockType.CriticalAction,
    icon: <GroupOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "RP Prompt",
    BlockType: BlockType.ScriptRolePlayerPrompt,
    icon: <SoundOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "Support Action",
    BlockType: BlockType.SupportAction,
    icon: <GroupOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "Research Question",
    BlockType: BlockType.ResearchQuestion,
    icon: <ThunderboltOutlinedIcons />,
  },
  {
    id: uuidv4() as unknown as string,
    content: "Complication",
    BlockType: BlockType.ComplicationList,
    icon: <ExcalmationCircleOutlinedIcons />,
  },
];

export const TimedActionItems: DragMenuItem[] = [
  {
    ...CriticalItems[0], // Miscellaneous
    droppableType: "droppableSubItem",
    disabled: true,
  },
  {
    ...CriticalItems[1], // Treatment
    droppableType: "droppableSubItem",
    disabled: false,
  },
  {
    ...CriticalItems[2], // Medication
    droppableType: "droppableSubItem",
    disabled: true,
  },
  {
    ...CriticalItems[3], // Diagnostic
    droppableType: "droppableSubItem",
    disabled: true,
  },
  {
    ...OtherActionsItems[3], // Complication
    droppableType: "droppableSubItem",
    disabled: true,
  },
];

export const DragMenuItems: DragMenuItem[] = [
  {
    ...OtherActionsItems[4], // Options
    droppableType: "droppableItem",
    disabled: true,
  },
  {
    ...SpecialItems[0], // Section
    droppableType: "droppableItem",
    disabled: false,
  },
  {
    ...GeneralItems[0], // Text
    droppableType: "droppableSubItem",
    disabled: false,
  },
  {
    ...GeneralItems[1], // List
    droppableType: "droppableSubItem",
    disabled: false,
  },
  {
    ...GeneralItems[2], // Casualty Status
    droppableType: "droppableSubItem",
    disabled: false,
  },
  {
    ...TimedActionItems[0], // Miscellaneous
    droppableType: "droppableSubItem",
    disabled: false,
  },
  {
    ...TimedActionItems[1], // Treatment
    droppableType: "droppableSubItem",
    disabled: false,
  },
  {
    ...TimedActionItems[2], // Medication
    droppableType: "droppableSubItem",
    disabled: false,
  },
  {
    ...TimedActionItems[3], // Diagnostic
    droppableType: "droppableSubItem",
    disabled: false,
  },
  {
    ...TimedActionItems[4], // Complication
    droppableType: "droppableSubItem",
    disabled: true,
  },
  {
    ...OtherActionsItems[0], // RP Prompt
    droppableType: "droppableSubItem",
    disabled: false,
  },
  {
    ...OtherActionsItems[1], // Support Action
    droppableType: "droppableSubItem",
    disabled: false,
  },
  {
    ...OtherActionsItems[2], // Research Question
    droppableType: "droppableSubItem",
    disabled: false,
  },
  // {
  //   ...OtherActionsItems[5], // Timed Action
  //   droppableType: "droppableSubItem",
  //   disabled: true,
  // },
  //  {
  //    ...OtherActionsItems[3], // Complication
  //    droppableType: "droppableSubItem",
  //  },
];
