import * as React from "react";
import { Switch } from "antd";
import { SwitchProps } from "antd/lib/switch";
import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";
import { isNullOrUndefined } from "../../utils/utilityfunctions";

const StyledAntSwitch = styled.div.attrs((props: any) => ({
  shouldAppearDisabled: props.shouldAppearDisabled,
  isForTreatment: props.isForTreatment,
}))`
  margin: 10px 0px;
  display: ${(props) => (props.isForTreatment ? `flex` : "block")};
  align-items: ${(props) => props.isForTreatment! && `center`};
  justify-content: ${(props) => props.isForTreatment! && `center`};

  &:hover {
    cursor: pointer;

    .label {
      color: #ffffff;
    }

    .ant-switch {
      box-shadow: 0 0 0 1px #ffffff;
    }

    .ant-switch-handle::before {
      background-color: ${(props) =>
        !props.shouldAppearDisabled
          ? `#FFFFFF !important`
          : "#999999 !important"};
    }
  }

  .label {
    font-size: 1.125rem;
    text-transform: uppercase;
    position: relative;
    top: ${(props) => (props.isForTreatment ? "0" : "3px")};
  }

  .label-active {
    color: ${(props) =>
      !props.shouldAppearDisabled
        ? `#FFFFFF !important`
        : "#999999 !important"};
  }

  .left-label {
    margin-right: ${(props) => (props.isForTreatment ? "5px" : "10px")};
  }

  .right-label {
    margin-left: ${(props) => (props.isForTreatment ? "5px" : "10px")};
  }

  .ant-switch {
    background-color: rgba(255, 255, 255, 0.1);
    background-image: none;
    outline: none;
    box-shadow: 0 0 0 1px #ffffff80;

    .ant-switch-handle::before {
      background-color: #ffffff80;
    }

    &:focus {
      box-shadow: 0 0 0 1px #ffffff;
      .ant-switch-handle::before {
        background-color: ${(props) =>
          !props.shouldAppearDisabled
            ? `#FFFFFF !important`
            : "#999999 !important"};
      }
      & + .label {
        color: #ffffff;
      }
    }
  }

  .ant-switch-checked {
    box-shadow: ${(props) =>
      !props.shouldAppearDisabled
        ? `0 0 0 1px ${colorPalette.primary75} !important`
        : `0 0 0 1px #FFFFFF80 !important`};
    background-color: ${(props) =>
      !props.shouldAppearDisabled && `${colorPalette.primary25} !important`};

    &:focus .ant-switch-handle::before,
    .ant-switch-handle::before {
      background-color: ${(props) =>
        !props.shouldAppearDisabled
          ? `${colorPalette.primary100} !important`
          : `#FFFFFF80 !important`};
    }
  }
`;

interface SwitchWithLabelProps extends SwitchProps {
  shouldAppearDisabled?: boolean;
  isForTreatment?: boolean;
  leftLabel?: string;
  rightLabel?: string;
  onChange: any; //Function
}

const SwitchWithLabel = ({
  leftLabel,
  rightLabel,
  onChange,
  ...props
}: SwitchWithLabelProps) => {
  const [shouldAppearDisabled, setShouldAppearDisabled] = React.useState(false);

  React.useEffect(() => {
    if (!isNullOrUndefined(shouldAppearDisabled)) {
      setShouldAppearDisabled(props.shouldAppearDisabled!);
    }
  }, [props.shouldAppearDisabled]);
  return (
    <StyledAntSwitch
      isForTreatment={props.isForTreatment}
      onClick={onChange}
      className={
        shouldAppearDisabled ? "disabled custom-switch" : "custom-switch"
      }
      shouldAppearDisabled={shouldAppearDisabled}
    >
      {leftLabel && (
        <span
          className={
            props.checked ? "left-label label-active label" : "left-label label"
          }
        >
          {leftLabel}
        </span>
      )}
      <Switch {...props} />
      {rightLabel && (
        <span
          className={
            props.checked
              ? "right-label label-active label"
              : "right-label label"
          }
        >
          {rightLabel}
        </span>
      )}
    </StyledAntSwitch>
  );
};

export default SwitchWithLabel;
