import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { InjuryViewTypes } from "./../constants";
import { getAllInsultsWithRelation } from "../../../../../api/Insult/ApiGet";
import {
  Location,
  InsultWithRelation,
  BodyPart,
} from "../../../../../models/InsultInterface";
import SelectInsultInjury from "./SelectInsultInjury";
import CreateEditInjury from "./CreateEditInjury";
import AppliedCasualtyInjury from "./AppliedCasualtyInjuryList/AppliedCasualtyInjuryList";
import { getAllInjuries } from "../../../../../api/Injury/ApiGet";
import { getAllLocations } from "../../../../../api/Location/ApiGet";
import {
  ApplyInsultToCasualty,
  UpdateAppliedInsultAndAdjustParameters,
} from "../../../../../api/Casualty/ApiPost";
import { setAlertMessage } from "../../../../../actions/alertMessage";
import { AlertType } from "../../../../../models/Enums";
import { getCasualtyWithId } from "../../../../../api/Casualty/ApiGet";
import { AppliedInjury, Injury } from "../../../../../models/InjuryInterface";
import { useFormik } from "formik";
import EditInsult from "./EditInsult/EditInsult";
import EditInjury from "./EditInjury";
import { isNullOrUndefined } from "../../../../../utils/utilityfunctions";
import { FormikErrorType } from "../../../../../models/common/FormikErrorType";
import {
  CreateAppliedInjury,
  UpdateAppliedInjury,
} from "../../../../../api/Injury/ApiPost";
import { useSelector, useDispatch } from "react-redux";
import {
  CompareTwoInjuries,
  CreateAppliedInsultPayload,
  CreateUpdateAppliedInjuryPayload,
  SetCopySourceInsultId,
  SetInsultLocations,
  SetLocationInInjuryAndParameters,
  UpdateAppliedInsultPayload,
} from "./InjuryMapUtills";
import {
  INSULT_INJURY_ADD_APPLIED_INJURY,
  INSULT_INJURY_ADD_APPLIED_INJURY_INSULT,
  INSULT_INJURY_ADD_APPLIED_INSULT,
  INSULT_INJURY_ADD_INJURY_FOR_COMPARISON,
  INSULT_INJURY_ADD_INJURY_FOR_CREATE_MASTER_OPTION,
  INSULT_INJURY_ADD_INJURY_OBJ_INSULT,
  INSULT_INJURY_ADD_SCREEN_VISIT,
  INSULT_INJURY_ADD_UPDATE_INJURY_OBJ_INSULTS,
  INSULT_INJURY_REMOVE_SCREEN_VISIT,
  INSULT_INJURY_RESET_INJURY_OBJ,
  INSULT_INJURY_RESET_SCREEN_VISIT_HISTORY,
  INSULT_INJURY_RESET_SELECTED_INSULT_OBJ,
  INSULT_INJURY_RESET_TO_INITIAL_STATE,
  INSULT_INJURY_SET_APPLIED_INJURIES,
  INSULT_INJURY_SET_APPLIED_INSULTS,
  INSULT_INJURY_SET_BACK_REDIRECT_SCREEN,
  INSULT_INJURY_SET_CURRENT_VIEW,
  INSULT_INJURY_SET_INJURIES,
  INSULT_INJURY_SET_INJURIES_FOR_COMPARISON,
  INSULT_INJURY_SET_INJURY_LOCATIONS,
  INSULT_INJURY_SET_INJURY_OBJ,
  INSULT_INJURY_SET_INSULTS,
  INSULT_INJURY_SET_SAVE_REDIRECT_SCREEN,
  INSULT_INJURY_SET_SELECTED_INSULT_OBJ,
  INSULT_INJURY_UPDATE_APPLIED_INJURY,
  INSULT_INJURY_UPDATE_APPLIED_INJURY_INSULT,
  INSULT_INJURY_UPDATE_APPLIED_INSULT,
  INSULT_INJURY_UPDATE_INJURY_FOR_COMPARISON,
  INSULT_INJURY_UPDATE_INJURY_OBJ,
  INSULT_INJURY_UPDATE_INJURY_OBJ_INSULT,
} from "../../../../../store/CasualtyCreator/InsultInjury/actionType";
import InjuryForm from "./InjuryForm";
import InjuryTabHeader from "./InjuryTabHeader";
import { InjuryListViewProps } from "./types";
import ConfirmDialog from "../../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../../components/ConfirmDialog/useConfirmDialog";
import { getAllBodyParts } from "../../../../../api/BodyPart/ApiGet";
import { OpenWarningNotification } from "../../../../../components/Notification/Notification";

// eslint-disable-next-line no-empty-pattern
const InjuryTab = ({
  casualtyId,
  updateCustomHeading,
  onInsultInjuryCancel,
  onSaveNext,
}: InjuryListViewProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [saveInjuryDetailsWithInsult, setSaveInjuryDetailsWithInsult] =
    useState<boolean>(false);
  const [isSaveBtnPressed, setIsSaveBtnPressed] = useState(false);
  const view = useSelector((state: any) => state.InsultInjury.CurrentView);
  const allInsults = useSelector((state: any) => state.InsultInjury.Insults);
  const allInjury = useSelector((state: any) => state.InsultInjury.Injuries);
  const injuryLocations = useSelector(
    (state: any) => state.InsultInjury.InjuryLocations,
  );
  const appliedInjuries = useSelector(
    (state: any) => state.InsultInjury.AppliedInjuries,
  );
  const injuryObj = useSelector((state: any) => state.InsultInjury.InjuryObj); // injury for create/edit
  const selectedInsultObj = useSelector(
    (state: any) => state.InsultInjury.InsultObj,
  ); // for edit insult
  const redirectScreen = useSelector(
    (state: any) => state.InsultInjury.BackRedirectScreen,
  );
  const saveRedirectScreen = useSelector(
    (state: any) => state.InsultInjury.SaveRedirectScreen,
  );
  const screenVisitHistory = useSelector(
    (state: any) => state.InsultInjury.ScreenVisitHistory,
  );
  const appliedInjuriesForComparison = useSelector(
    (state: any) => state.InsultInjury.AppliedInjuriesForComparison,
  ); // for comparison with injury updates

  const { isReadOnly } = useSelector(
    (state: any) => state.scriptBuilderReducer,
  ); // Read-only Script

  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const handleViewChange = (
    view: string,
    title: string,
    updateHistory = true,
  ) => {
    let displayTitle = true;
    if (view === InjuryViewTypes.CASUALTY_INJURIES) {
      // reset history
      dispatch({ type: INSULT_INJURY_RESET_SCREEN_VISIT_HISTORY });
      displayTitle = false;
      // resetInjuryObj();
    } else if (updateHistory) {
      dispatch({ type: INSULT_INJURY_ADD_SCREEN_VISIT, data: view });
    }
    updateCustomHeading(displayTitle, title);
    dispatch({ type: INSULT_INJURY_SET_CURRENT_VIEW, view });
  };
  const handleCancelRedirect = () => {
    const len = screenVisitHistory.length;
    const nextView = screenVisitHistory[len - 2];
    dispatch({ type: INSULT_INJURY_REMOVE_SCREEN_VISIT, index: len - 1 });
    return nextView;
  };
  // injury title
  const focusedStates = {
    injury_title: false,
    locationId: false,
  };
  const [isFocused, setIsFocused] = useState(focusedStates);
  const [lastFocused, setLastFocused] = useState(focusedStates);
  const formik = useFormik({
    initialValues: {
      injury_title: "",
      locationId: null,
      appliedLocationSide: "",
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      // injury_title
      if (!values["injury_title"].trim()) {
        errors["injury_title"] = "Required";
      }
      if (!values["locationId"]) {
        errors["locationId"] = "Required";
      }
      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      dispatch({
        type: INSULT_INJURY_UPDATE_INJURY_OBJ,
        data: {
          Title: values["injury_title"],
          LocationId: Number(values["locationId"]),
          AppliedLocationSide: values["appliedLocationSide"],
        },
      });
      if (injuryObj.Insults?.length > 0) {
        saveInjury();
      }
    },
  });
  const onSave = () => {
    if (injuryObj.Insults.length > 0) {
      redirectAfterSavingInjury();
    } else {
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "Injury",
        content:
          "Injury without Insult will not effect anything. Are you still want to continue.",
        okText: "Yes",
        onOk: () => {
          redirectAfterSavingInjury();
          saveInjury();
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "warning",
      });
    }
  };
  const redirectAfterSavingInjury = () => {
    if (saveRedirectScreen === InjuryViewTypes.CASUALTY_INJURIES) {
      handleViewChange(InjuryViewTypes.CASUALTY_INJURIES, "");
    } else if (redirectScreen === "CREATE_EDIT_INJURY") {
      handleViewChange(InjuryViewTypes.CASUALTY_INJURIES, "");
    } else {
      handleViewChange(InjuryViewTypes.CASUALTY_INJURIES, "");
    }
  };
  const createInjurySuccess = (data: any) => {
    dispatch({ type: INSULT_INJURY_ADD_APPLIED_INJURY, data });
    checkNewInjuryWithMaster(data); // for create master button
    onAddInjuryInsultSuccess();
    resetInjuryObj();
  };

  // This updates the all casualty state to update the labels on the moedel.
  const onAddInjuryInsultSuccess = () => {
    if (isSaveBtnPressed) {
      setIsSaveBtnPressed(false);
    }
  };
  const createAppliedInjury = (injuryInsults: any) => {
    const newInjuryPayload = {
      casualtyId: casualtyId,
      type: injuryObj.Type,
      title: formik.values["injury_title"],
      locationId: Number(formik.values["locationId"]),
      copySourceInjuryId: injuryObj.CopySourceInjuryId,
      insults: injuryInsults,
      appliedLocationSide: formik.values["appliedLocationSide"],
    };
    // create injury
    CreateAppliedInjury(
      newInjuryPayload,
      (data: any) => {
        createInjurySuccess(data);
      },
      error,
    );
  };
  const updateAppliedInjury = (injuryInsults: any) => {
    const updateInjuryPayload = {
      id: injuryObj.Id,
      type: injuryObj.Type,
      title: formik.values["injury_title"],
      locationId: Number(formik.values["locationId"]),
      copySourceInjuryId: injuryObj.CopySourceInjuryId,
      insults: injuryInsults,
      appliedInsultsToDeleteIds: [],
      appliedLocationSide: formik.values["appliedLocationSide"],
    };
    UpdateAppliedInjury(
      updateInjuryPayload,
      (data: any) => {
        updateInjurySuccess(data);
      },
      error,
    );
  };
  const saveInjury = () => {
    if (formik.values["injury_title"].trim()) {
      const injuryInsults = CreateUpdateAppliedInjuryPayload(injuryObj);
      if (injuryObj.Id === 0) {
        createAppliedInjury(injuryInsults);
      } else {
        updateAppliedInjury(injuryInsults); //update injury
      }
    } else {
      console.log("invalid injury title/ location");
    }
  };
  // check injury update and enable create master button
  const checkNewInjuryWithMaster = (newData: any) => {
    const injuryIndex = appliedInjuriesForComparison.findIndex(
      (injury: AppliedInjury) => injury.Id === newData.Id,
    );
    if (injuryIndex !== -1) {
      const oldInjury = appliedInjuriesForComparison[injuryIndex];
      const differenceFound = CompareTwoInjuries(newData, oldInjury);
      if (differenceFound) {
        dispatch({
          type: INSULT_INJURY_ADD_INJURY_FOR_CREATE_MASTER_OPTION,
          Id: newData.Id,
        }); // to enable create master button for newly created injury
      }
      dispatch({
        type: INSULT_INJURY_UPDATE_INJURY_FOR_COMPARISON,
        Id: newData.Id,
        data: newData,
      }); // update injury for future reference related to create master button
    } else {
      if (newData.CopySourceInjuryId) {
        // selected injury from master and added to casualty
        const masterInjury = allInjury.find(
          (x: Injury) => x.Id === newData.CopySourceInjuryId,
        );
        if (masterInjury) {
          const differenceFound = CompareTwoInjuries(newData, masterInjury);
          if (differenceFound) {
            dispatch({
              type: INSULT_INJURY_ADD_INJURY_FOR_COMPARISON,
              data: newData,
            }); // add injury for future reference related to create master button
            dispatch({
              type: INSULT_INJURY_ADD_INJURY_FOR_CREATE_MASTER_OPTION,
              Id: newData.Id,
            }); // to enable create master button for newly created injury
          }
        }
      } else {
        dispatch({
          type: INSULT_INJURY_ADD_INJURY_FOR_COMPARISON,
          data: newData,
        }); // add injury for future reference related to create master button
        dispatch({
          type: INSULT_INJURY_ADD_INJURY_FOR_CREATE_MASTER_OPTION,
          Id: newData.Id,
        }); // to enable create master button for newly created injury
      }
    }
  };
  const updateInjurySuccess = (data: any) => {
    // update injury object
    dispatch({
      type: INSULT_INJURY_UPDATE_INJURY_OBJ,
      data: {
        Title: data.Title,
        LocationId: data.LocationId,
        Location: data.Location ? data.Location : null,
        Insults: data.Insults,
      },
    });
    // update injury object
    updateInjury({
      ...injuryObj,
      Title: data.Title,
      LocationId: data.LocationId,
      Location: data.Location ? data.Location : null,
      Insults: data.Insults,
      NewId: data.Id,
      AppliedLocationSide: data.AppliedLocationSide,
    });
    onAddInjuryInsultSuccess(); // This updates the all casualty state to update the labels on the moedel.
    checkNewInjuryWithMaster(data); // for create master button
    resetInjuryObj();
  };

  const setSaveBackRedirectAction = (save: string, back: string) => {
    if (save && save.trim().length) {
      dispatch({ type: INSULT_INJURY_SET_SAVE_REDIRECT_SCREEN, data: save });
    }
    if (back && save.trim().length) {
      dispatch({ type: INSULT_INJURY_SET_BACK_REDIRECT_SCREEN, data: back });
    }
  };
  const resetInjuryObj = () => {
    dispatch({ type: INSULT_INJURY_RESET_INJURY_OBJ });
  };
  const onBack = () => {
    if (injuryObj.Insults.length > 0 || injuryObj.Id === 0) {
      onCancelCreateEditInjury();
    } else {
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "Injury",
        content:
          "Injury without Insult will not effect anything. Are you still want to continue.",
        okText: "Yes",
        onOk: () => {
          onCancelCreateEditInjury();
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "warning",
      });
    }
  };
  const onCancelCreateEditInjury = () => {
    const nextScreen = handleCancelRedirect();
    if (nextScreen === `EDIT_INJURY`) {
      handleViewChange(InjuryViewTypes.EDIT_INJURY, "EDIT INJURY", false);
    } else if (nextScreen === "SELECT_INSULT_INJURY") {
      handleViewChange(
        InjuryViewTypes.SELECT_INSULT_INJURY,
        "SELECT INSULT or INJURY",
        false,
      );
      resetInjuryObj();
    } else {
      handleViewChange(InjuryViewTypes.CASUALTY_INJURIES, "", false);
    }
  };
  const onCreateInsultSelection = (insult: any) => {
    const newInsultObj = JSON.parse(JSON.stringify(insult));
    // check validation of injury title and location id for injury
    if (
      formik.values["injury_title"].trim() &&
      formik.values["locationId"] &&
      Number(formik.values["locationId"])
    ) {
      const bodyLocation = bodyParts.find(
        (i: BodyPart) =>
          i.Locations.findIndex(
            (location) => location.Id === Number(formik.values["locationId"]),
          ) !== -1,
      );
      const injuryLocation = bodyLocation
        ? bodyLocation.Locations.find(
            (location) => location.Id === Number(formik.values["locationId"]),
          )
        : null;
      dispatch({
        type: INSULT_INJURY_UPDATE_INJURY_OBJ,
        data: {
          Title: formik.values["injury_title"],
          LocationId: formik.values["locationId"],
          Location: injuryLocation || null,
          AppliedLocationSide: formik.values.appliedLocationSide,
        },
      });
      setSaveInjuryDetailsWithInsult(true);
      dispatch({
        type: INSULT_INJURY_SET_SELECTED_INSULT_OBJ,
        data: {
          ...newInsultObj,
          Location: injuryLocation || null,
          AppliedLocationSide: formik.values["appliedLocationSide"] || "",
        },
      });
      setSaveBackRedirectAction("EDIT_INJURY", "CREATE_EDIT_INJURY");
      handleViewChange(InjuryViewTypes.EDIT_INSULT, "EDIT INSULT");
    } else {
      if (!formik.values["injury_title"].trim()) {
        OpenWarningNotification({ description: "Please enter injury title" });
      } else {
        OpenWarningNotification({ description: "Please select Sub-Location" });
      }
      formik.submitForm();
      setSaveInjuryDetailsWithInsult(false);
    }
  };
  // edit insult
  const resetSelectedInsultObj = () => {
    dispatch({ type: INSULT_INJURY_RESET_SELECTED_INSULT_OBJ });
  };
  const onEditInsultCancel = () => {
    const nextScreen = handleCancelRedirect();
    if (nextScreen === "CREATE_EDIT_INJURY") {
      handleViewChange(
        InjuryViewTypes.CREATE_EDIT_INJURY,
        "EDIT INJURY",
        false,
      );
    } else if (nextScreen === "SELECT_INSULT_INJURY") {
      handleViewChange(
        InjuryViewTypes.SELECT_INSULT_INJURY,
        "SELECT INSULT or INJURY",
        false,
      );
      resetSelectedInsultObj();
    } else if (nextScreen === "EDIT_INJURY") {
      handleViewChange(InjuryViewTypes.EDIT_INJURY, "EDIT_INJURY", false);
      resetSelectedInsultObj();
    } else {
      handleViewChange(InjuryViewTypes.CASUALTY_INJURIES, "", false);
    }
  };
  // on add insult success
  const onAddInsultSuccess = () => {
    if (
      redirectScreen === "CREATE_EDIT_INJURY" ||
      redirectScreen === "EDIT_INJURY"
    ) {
      handleViewChange(InjuryViewTypes.EDIT_INJURY, "EDIT INJURY");
    } else {
      if (isSaveBtnPressed) {
        handleViewChange(InjuryViewTypes.CASUALTY_INJURIES, "");
        setIsSaveBtnPressed(false);
      }
    }
  };
  const errorMessage = (message: string) => {
    setLoading(false);
    const msg = "An Error Occured check the console for more information";
    setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: message ? message : msg,
        messageShowTime: 3000,
        position: "top-center",
      },
    });
  };
  const error = (data: any) => {
    errorMessage(data);
    setLoading(false);
  };
  // on add error
  const onAddError = (message: any) => {
    errorMessage(message);
    handleViewChange(InjuryViewTypes.CASUALTY_INJURIES, "");
  };
  const createAppliedInsultSuccess = (
    data: InsultWithRelation,
    InjuryIdForAPI: number,
  ) => {
    if (redirectScreen === `CREATE_EDIT_INJURY`) {
      // for adding insult to injury
      dispatch({
        type: INSULT_INJURY_ADD_APPLIED_INJURY_INSULT,
        Id: InjuryIdForAPI,
        data,
      });
      dispatch({ type: INSULT_INJURY_ADD_INJURY_OBJ_INSULT, data });
    } else {
      // add insult to applied insult
      dispatch({ type: INSULT_INJURY_ADD_APPLIED_INSULT, data });
      const newInsultObj = SetInsultLocations(data, allInsults);
      dispatch({
        type: INSULT_INJURY_SET_SELECTED_INSULT_OBJ,
        data: newInsultObj,
      });
    }
    if (!saveInjuryDetailsWithInsult) {
      onAddInsultSuccess();
    }
    onAddInjuryInsultSuccess();
  };
  const createAppliedInsult = (payload: any, InjuryIdForAPI: number) => {
    ApplyInsultToCasualty(
      payload,
      (data: InsultWithRelation) =>
        createAppliedInsultSuccess(data, InjuryIdForAPI),
      onAddError,
    );
  };
  const updateAppliedInsultSuccess = (
    data: InsultWithRelation,
    InjuryIdForAPI: number,
    insultObj: any,
  ) => {
    // for updating insult in injury
    if (InjuryIdForAPI) {
      dispatch({
        type: INSULT_INJURY_UPDATE_APPLIED_INJURY_INSULT,
        Id: InjuryIdForAPI,
        InsultId: insultObj.Id,
        data,
      });
      if (
        redirectScreen === `CREATE_EDIT_INJURY` ||
        redirectScreen === `EDIT_INJURY`
      ) {
        dispatch({
          type: INSULT_INJURY_UPDATE_INJURY_OBJ_INSULT,
          InsultId: insultObj.Id,
          data,
        });
      }
    } else {
      // update insult to applied insult
      dispatch({
        type: INSULT_INJURY_UPDATE_APPLIED_INSULT,
        Id: insultObj.Id,
        data,
      });
    }
    if (!saveInjuryDetailsWithInsult) {
      onAddInsultSuccess();
    }
    onAddInjuryInsultSuccess();
  };
  const updateAppliedInsult = (
    payload: any,
    InjuryIdForAPI: number,
    insultObj: any,
  ) => {
    UpdateAppliedInsultAndAdjustParameters(
      payload,
      (data: InsultWithRelation) => {
        updateAppliedInsultSuccess(data, InjuryIdForAPI, insultObj);
      },
      onAddError,
    );
  };
  const saveInsultWithParameter = (insultObj: any, injuryId?: number) => {
    /*** NEED TO UPDATE RESPONSE OF INSULT TO SELECTED INSULT OBJECT  AS WELL AS USE TO UPDATE ALL OBJECTS LIKE APPLIED INJURY/INSULTS    **/
    const InjuryIdForAPI = injuryId ? injuryId : injuryObj.Id;
    //update
    if (insultObj.Id && insultObj.CopySourceInsultId) {
      const updateAppliedInsultPayload = UpdateAppliedInsultPayload(
        insultObj,
        InjuryIdForAPI,
      );
      updateAppliedInsult(
        updateAppliedInsultPayload,
        InjuryIdForAPI,
        insultObj,
      );
    } else {
      //add   //for adding selectedInsultObj to casualty
      const newAppliedInsultPayload = CreateAppliedInsultPayload(
        insultObj,
        InjuryIdForAPI,
        casualtyId,
      );
      createAppliedInsult(newAppliedInsultPayload, InjuryIdForAPI);
    }
  };
  const handleEditInsultSave = (insult: any) => {
    dispatch({ type: INSULT_INJURY_SET_SELECTED_INSULT_OBJ, data: insult });
    // if injury is not save then first save injury
    if (
      redirectScreen === "CREATE_EDIT_INJURY" ||
      saveRedirectScreen === "EDIT_INJURY"
    ) {
      // edit injury
      if (injuryObj.Id) {
        saveInsultWithParameter(insult, injuryObj.Id);
      } else {
        dispatch({
          type: INSULT_INJURY_ADD_UPDATE_INJURY_OBJ_INSULTS,
          InsultId: insult.Id,
          CopySourceInsultId: insult.CopySourceInsultId,
          data: insult,
        });
      }
      setSaveBackRedirectAction(
        InjuryViewTypes.CASUALTY_INJURIES,
        "EDIT_INSULT",
      );
      handleViewChange(InjuryViewTypes.EDIT_INJURY, "EDIT INJURY");
    } else if (saveRedirectScreen === "CASUALTY_INJURIES") {
      saveInsultWithParameter(insult);
    } else {
      handleViewChange(InjuryViewTypes.CASUALTY_INJURIES, "");
    }
  };
  const setCopySourceInsultIdInInsults = () => {
    let newInsults = JSON.parse(JSON.stringify(allInsults));
    newInsults = newInsults.map((insult: InsultWithRelation) => {
      return SetCopySourceInsultId(insult);
    });
    dispatch({ type: INSULT_INJURY_SET_INSULTS, data: newInsults });
  };
  const setLocationInInjuries = () => {
    let newInjuries = JSON.parse(JSON.stringify(allInjury));
    newInjuries = newInjuries.map((injury: AppliedInjury) => {
      return SetLocationInInjuryAndParameters(
        injury,
        injuryLocations,
        allInsults,
        false,
      );
    });
    dispatch({ type: INSULT_INJURY_SET_INJURIES, data: newInjuries });
  };
  const handleClickSelectInsultInjury = () => {
    setLocationInInjuries();
    setCopySourceInsultIdInInsults();
    setSaveBackRedirectAction("", InjuryViewTypes.CASUALTY_INJURIES);
    handleViewChange(
      InjuryViewTypes.SELECT_INSULT_INJURY,
      "SELECT INSULT or INJURY",
    );
  };
  const handleClickAddInjury = () => {
    resetInjuryObj(); //reset injury object
    formik.resetForm({
      values: {
        injury_title: "",
        locationId: null,
        appliedLocationSide: "",
      },
      errors: {
        injury_title: "",
        locationId: "",
        appliedLocationSide: "",
      },
    });
    setSaveBackRedirectAction(
      InjuryViewTypes.CASUALTY_INJURIES,
      "SELECT_INSULT_INJURY",
    );
    handleViewChange(InjuryViewTypes.CREATE_EDIT_INJURY, "CREATE INJURY");
  };
  // edit injury
  const onEditInjuryCancel = () => {
    // handleCancelRedirect();
    const nextScreen = handleCancelRedirect();
    if (nextScreen === "SELECT_INSULT_INJURY") {
      handleViewChange(
        InjuryViewTypes.SELECT_INSULT_INJURY,
        "SELECT INSULT or INJURY",
        false,
      );
      resetInjuryObj(); //reset injury object
    } else if (nextScreen === "CREATE_EDIT_INJURY") {
      handleViewChange(
        InjuryViewTypes.CREATE_EDIT_INJURY,
        "CREATE INJURY",
        false,
      );
    } else if (nextScreen === "EDIT_INSULT") {
      setSaveBackRedirectAction("EDIT_INJURY", "EDIT_INJURY");
      handleViewChange(InjuryViewTypes.EDIT_INSULT, "EDIT INSULT", false);
    } else {
      handleViewChange(InjuryViewTypes.CASUALTY_INJURIES, "", false);
    }
  };
  const getLocationSuccess = (data: any) => {
    dispatch({
      type: INSULT_INJURY_SET_INJURY_LOCATIONS,
      data: data.filter(
        (location: Location) => location.LocationType === "Injury",
      ),
    });
  };
  const getInjurySuccess = (data: any) => {
    dispatch({ type: INSULT_INJURY_SET_INJURIES, data });
  };
  const getInsultsSuccess = (data: any) => {
    dispatch({ type: INSULT_INJURY_SET_INSULTS, data });
  };
  const getCasualtyDataById = (data: any) => {
    dispatch({
      type: INSULT_INJURY_SET_APPLIED_INJURIES,
      data: data.Injuries.sort(
        (x: AppliedInjury, y: AppliedInjury) => y.Id - x.Id,
      ),
    });
    dispatch({ type: INSULT_INJURY_SET_APPLIED_INSULTS, data: data.Insults });
    dispatch({
      type: INSULT_INJURY_SET_INJURIES_FOR_COMPARISON,
      data: data.Injuries,
    });
    setLoading(false);
  };

  useEffect(() => {
    // load casualty's injury/insults
    if (casualtyId > 0) {
      getCasualtyWithId(casualtyId, getCasualtyDataById, errorMessage);
      getAllInsultsWithRelation(getInsultsSuccess, error);
      getAllInjuries(getInjurySuccess, error);
      getAllLocations(getLocationSuccess, error);
    }
    return () => {
      dispatch({ type: INSULT_INJURY_RESET_TO_INITIAL_STATE }); // This is the cleanup function
    };
  }, []);

  // update injury details after add/update injury in DB
  const updateInjury = (injury: any) => {
    const index = appliedInjuries.findIndex(
      (c: AppliedInjury) => c.Id === injury.Id,
    );
    if (index !== -1) {
      dispatch({
        type: INSULT_INJURY_UPDATE_APPLIED_INJURY,
        Id: injury.Id,
        data: injury,
      });
    } else {
      // add injury
      dispatch({
        type: INSULT_INJURY_ADD_APPLIED_INJURY,
        data: { ...injury, Id: injury["NewId"] },
      });
    }
  };
  // select insult/injury
  const onSelectInsultInjuryCancel = () => {
    handleViewChange(InjuryViewTypes.CASUALTY_INJURIES, "");
  };
  // Set edit injury form values.
  const setInjuryTitleInForm = (value: string) => {
    formik.setFieldValue("injury_title", value);
  };
  const setLocationInForm = (location: Location | undefined) => {
    if (location) {
      formik.setFieldValue("locationId", location.Id);
      formik.setFieldValue("bodyPartId", location.BodyPart?.Id);
    }
  };
  const setAppliedLocationSideInForm = (locationSide: string) => {
    if (locationSide) {
      formik.setFieldValue("appliedLocationSide", locationSide);
    }
  };
  const setInjuryDataInForm = (injury: Injury) => {
    setInjuryTitleInForm(injury.Title);
    setLocationInForm(injury.Location || undefined);
    setAppliedLocationSideInForm(injury.AppliedLocationSide);
    dispatch({ type: INSULT_INJURY_SET_INJURY_OBJ, data: injury });
  };
  const onInsultSelection = (insult: any) => {
    const newInsultObj = JSON.parse(JSON.stringify(insult));
    dispatch({
      type: INSULT_INJURY_SET_SELECTED_INSULT_OBJ,
      data: newInsultObj,
    });
    setSaveInjuryDetailsWithInsult(false);
    setSaveBackRedirectAction(
      InjuryViewTypes.CASUALTY_INJURIES,
      "SELECT_INSULT_INJURY",
    );
    handleViewChange(InjuryViewTypes.EDIT_INSULT, "EDIT INSULT");
  };
  const onInjurySelection = (injury: Injury) => {
    const newInjuryObj = JSON.parse(JSON.stringify(injury));
    newInjuryObj.CopySourceInjuryId = injury.Id;
    newInjuryObj.Id = 0; // reset injury Id
    setInjuryDataInForm(newInjuryObj);
    setSaveBackRedirectAction(
      InjuryViewTypes.CASUALTY_INJURIES,
      "SELECT_INSULT_INJURY",
    );
    handleViewChange(InjuryViewTypes.EDIT_INJURY, "EDIT INJURY");
  };

  const openEditInjury = (injuryId: number) => {
    if (isReadOnly) return; //disable action callback in read only script
    const selectedInjury = appliedInjuries.find(
      (injury: any) => injury.Id === injuryId,
    );
    if (!isNullOrUndefined(selectedInjury)) {
      let newInjuryObj = JSON.parse(JSON.stringify(selectedInjury));
      newInjuryObj = SetLocationInInjuryAndParameters(
        newInjuryObj,
        injuryLocations,
        allInsults,
        true,
      );
      setInjuryDataInForm(newInjuryObj);
    }
    setCopySourceInsultIdInInsults();
    setSaveBackRedirectAction(
      InjuryViewTypes.CASUALTY_INJURIES,
      InjuryViewTypes.CASUALTY_INJURIES,
    );
    handleViewChange(InjuryViewTypes.EDIT_INJURY, "EDIT INJURY");
  };

  const openCreateEditInjury = (injury: AppliedInjury) => {
    if (!isNullOrUndefined(injury)) {
      setInjuryDataInForm(injury);
    }
    setSaveBackRedirectAction(InjuryViewTypes.CASUALTY_INJURIES, "EDIT_INJURY");
    handleViewChange(InjuryViewTypes.CREATE_EDIT_INJURY, "EDIT INJURY");
  };
  const openEditInsult = async (insult: any, injuryId: number) => {
    if (isReadOnly) return; //disable action callback in read only script
    const newInsultObj = await SetInsultLocations(insult, allInsults);
    //set injury object for passing injury data while saving insult
    if (injuryId) {
      const selectedInjury = appliedInjuries.find(
        (injury: any) => injury.Id === injuryId,
      );
      if (!isNullOrUndefined(selectedInjury)) {
        const newInjuryObj = JSON.parse(JSON.stringify(selectedInjury));
        dispatch({ type: INSULT_INJURY_SET_INJURY_OBJ, data: newInjuryObj });
      }
    } else {
      resetInjuryObj();
    }
    dispatch({
      type: INSULT_INJURY_SET_SELECTED_INSULT_OBJ,
      data: newInsultObj,
    });
    setSaveInjuryDetailsWithInsult(false);
    setSaveBackRedirectAction(
      InjuryViewTypes.CASUALTY_INJURIES,
      InjuryViewTypes.CASUALTY_INJURIES,
    );
    handleViewChange(InjuryViewTypes.EDIT_INSULT, "EDIT INSULT");
  };
  const openEditInsultFromEditInjury = (insult: any) => {
    const newInsultObj = SetInsultLocations(insult, allInsults);
    dispatch({
      type: INSULT_INJURY_SET_SELECTED_INSULT_OBJ,
      data: newInsultObj,
    });
    setSaveBackRedirectAction("EDIT_INJURY", "EDIT_INJURY");
    handleViewChange(InjuryViewTypes.EDIT_INSULT, "EDIT INSULT");
  };

  // Reset injury form so when edit insult form is open it does not show old values.
  useEffect(() => {
    setTimeout(() => {
      view === InjuryViewTypes.CASUALTY_INJURIES && formik.resetForm();
    }, 1000);
  }, [view]);

  const [bodyParts, setBodyParts] = useState<BodyPart[]>([]);
  useEffect(() => {
    getAllBodyParts(
      (data: BodyPart[]) => {
        setBodyParts(data);
        formik.setFieldValue("bodyPartId", data[0].Id);
      },
      (error: any) => {
        console.log(error);
      },
    );
  }, []);

  return (
    <>
      {view === InjuryViewTypes.CREATE_EDIT_INJURY && (
        <InjuryForm
          formik={formik}
          setIsFocused={setIsFocused}
          lastFocused={lastFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          bodyParts={bodyParts}
        />
      )}
      {isReadOnly ? null : (
        <InjuryTabHeader
          onClickSelectInsultInjury={handleClickSelectInsultInjury}
          onClickAddInjury={handleClickAddInjury}
        />
      )}
      <Spin spinning={loading}>
        {view === InjuryViewTypes.CASUALTY_INJURIES && (
          <AppliedCasualtyInjury
            casualtyId={casualtyId}
            currentView={view}
            onEditInjury={openEditInjury}
            onEditInsult={openEditInsult}
            handleClickAddInjury={handleClickSelectInsultInjury}
            onNext={() => onSaveNext()}
            onBack={() => onInsultInjuryCancel()}
          />
        )}
        {view === InjuryViewTypes.SELECT_INSULT_INJURY && (
          <SelectInsultInjury
            casualtyId={casualtyId}
            onInsultSelection={onInsultSelection}
            onInjurySelection={onInjurySelection}
            onBack={onSelectInsultInjuryCancel}
            handleClickAddInjury={handleClickAddInjury}
          />
        )}
        {view === InjuryViewTypes.EDIT_INSULT && (
          <EditInsult
            setIsSaveBtnPressed={setIsSaveBtnPressed}
            insult={selectedInsultObj}
            onBack={onEditInsultCancel}
            onSave={handleEditInsultSave}
          />
        )}
        {view === InjuryViewTypes.CREATE_EDIT_INJURY && (
          <CreateEditInjury
            onSave={onSave}
            formik={formik}
            onSubmitForm={formik.submitForm}
            onBack={onBack}
            handleInsultSelection={onCreateInsultSelection}
          />
        )}
        {view === InjuryViewTypes.EDIT_INJURY && (
          <EditInjury
            casualtyId={casualtyId}
            onBack={() => {
              onEditInjuryCancel();
              resetInjuryObj();
            }}
            onSave={() => {
              saveInjury();
              redirectAfterSavingInjury();
            }}
            handleOpenCreateEditInjury={openCreateEditInjury}
            onEditInsult={openEditInsultFromEditInjury}
          />
        )}
      </Spin>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </>
  );
};
export default InjuryTab;
