import React, { useRef, useState } from "react";
import { uploadMedia } from "../../../../../api/Media/ApiPost";
import { FormField } from "../../../../../components/FileUpload/FileUpload.styled";
import {
  OpenErrorNotification,
  OpenWarningNotification,
} from "../../../../../components/Notification/Notification";
import { IconColumn } from "../../IconColumn";
import { TextBlock } from "../../TextEdit";
import { formatBytes } from "../../../../../utils/utilityfunctions";
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES } from "../../../../../models/Constants";
import ConfirmDialog from "../../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../../components/ConfirmDialog/useConfirmDialog";

interface Props {
  type: string;
  object: TextBlock;
  loading: boolean;
  setLoading: (value: boolean) => void;
  onCopyClick: any;
  onDeleteClick: any;
  updateBlockWithAudio: Function;
}
const maxAudioSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES;
export default function TopFixedHeaderWithUploadIcon({
  type,
  loading,
  setLoading,
  object,
  onCopyClick,
  onDeleteClick,
  updateBlockWithAudio,
}: Props) {
  const fileInputField = useRef<any>(null);
  const [audio, setAudio] = useState({});
  const [audioUrl, setAudioUrl] = useState({});
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const handleUploadBtnClick = () => {
    if (object?.BlockContent.UploadedMediaItems?.length) {
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "",
        content: "This will overwrite your existing audio file. Continue?",
        okText: "Yes",
        onOk: () => {
          openUploadDialog();
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "warning",
      });
    } else {
      openUploadDialog();
    }
  };
  const openUploadDialog = () => {
    fileInputField?.current?.click();
  };

  const handleNewFileUpload = (e: any) => {
    const isEditing = object?.length;
    const { files: newFiles } = e.target;
    const selectedAudio = newFiles[0];
    addNewFiles(selectedAudio);
    if (isEditing) {
      addInputToProfile("isEditing", selectedAudio);
    } else {
      addInputToProfile("", selectedAudio);
    }
  };
  const addNewFiles = (audioSelected: any) => {
    if (audioSelected.size <= maxAudioSizeInBytes) {
      const convertAudioToUURL = URL.createObjectURL(audioSelected);
      setAudio(audioSelected);
      setAudioUrl((prev) => (prev = convertAudioToUURL));
    } else {
      OpenWarningNotification({
        description: `File must smaller than  ${formatBytes(
          maxAudioSizeInBytes,
        )}`,
      });
    }
  };

  const addInputToProfile = (isEditing: any, objectBeforeStateObject: any) => {
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append("newVersion", "true");
    bodyFormData.append("public", "true");
    bodyFormData.append("file", objectBeforeStateObject);
    bodyFormData.append("name", objectBeforeStateObject.name);
    bodyFormData.append("title", objectBeforeStateObject.name);
    uploadMedia(
      bodyFormData,
      (callback: any) => updateBlockWithAudio(callback),
      (error: any) => {
        OpenErrorNotification({
          description: error.message,
        });
        setLoading(false);
      },
    );
  };
  return (
    <>
      <IconColumn
        type={type}
        onCopyClick={onCopyClick}
        onDeleteClick={onDeleteClick}
        isUploadBtnAvailable
        isUploadBtnDisabled={false}
        onUploadClick={handleUploadBtnClick}
      />
      <FormField
        style={{ display: `none` }}
        type="file"
        ref={fileInputField}
        className="profile-aud"
        onChange={handleNewFileUpload}
        title=""
        value=""
        accept=".wav"
        multiple={false}
      ></FormField>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </>
  );
}
