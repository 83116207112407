import React from "react";
import { ResearchQuestionNumberType } from "../../../../../models/Enums";
import { ResearchQuestionNumber } from "../../../../../models/ScriptInterfaces";
import InputNumberType from "./InputNumberType";
import SliderNumberType from "./SliderNumberType";
interface Props {
  scriptFormik: any;
  question: ResearchQuestionNumber;
}
export default function NumberTypeQuestion(props: Props) {
  return (
    <div className="mt-3 mb-2">
      {props.question.ResearchQuestionNumberType ===
      ResearchQuestionNumberType.Input ? (
        <InputNumberType
          scriptFormik={props.scriptFormik}
          question={props.question}
        />
      ) : (
        <SliderNumberType question={props.question} />
      )}
    </div>
  );
}
