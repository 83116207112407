import styled from "styled-components";
import { GrayColorPalette, colorPalette } from "../../styles/MainList.styled";
import { pxToRem } from "../../styles/utils";
import { WhiteIconButton } from "../../styles/Buttons.styled";

export const ButtonsContainer = styled.div`
  height: 40px;
  width: fit-content;
  border-radius: 3px;
  border: 1px solid ${GrayColorPalette.Gray3};
  background-color: ${GrayColorPalette.Gray4};
`;

export const DashboardCardWrapper = styled.div`
  background-color: #161616;
  display: flex;
  color: #fff;
  height: -webkit-fill-available;
  &:hover {
    background: #1f1f1f;
    .ant-btn {
      color: ${colorPalette.white};
    }
  }

  & ${ButtonsContainer} {
    opacity: 0;
    position: absolute;
    z-index: 10;
  }

  &:hover ${ButtonsContainer} {
    opacity: 1;
  }

  & ${ButtonsContainer}.top-right {
    top: 10px;
    right: 10px;
  }

  & .expand-icon {
    opacity: 0;
    position: absolute;
    bottom: 5px;
    right: 5px;
    transform: rotate(90deg);
  }

  &:hover .expand-icon {
    opacity: 1;
  }
`;

export const DashboardCardHeader = styled.div`
  padding: ${pxToRem(10)};
  border-bottom: 2px solid #4f4f4f;
  position: relative;
  p {
    color: #fff;
    margin-bottom: 0.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
  }
  p.title {
    font-size: ${pxToRem(20)};
    line-height: 1.25rem;
    margin-bottom: 5px;
    font-weight: 500;
  }
  p.secondary-text {
    font-size: ${pxToRem(16)};
    line-height: 1.25rem;
    color: ${GrayColorPalette.Gray9};
  }
`;

export const DashboardCardBody = styled.div`
  padding: 0.7rem;
  height: calc(100% - 5rem);
  p {
    font-size: 1rem;
    line-height: 1.2rem;
  }
`;

export const ContentWrap = styled.div`
  width: 100%;
  position: relative;
  .ant-btn {
    color: rgba(255, 255, 255, 0.1);
    color: ${colorPalette.gray25};
  }
`;
