import React, { useEffect } from "react";
import { StyledForm } from "./PhaseForm.styled";
import { FormContainer } from "../RolePlayerForm/PromptForm.styled";
import { useFormik } from "formik";
import FloatingLabelInput from "../../../../../components/FloatingLabel/FloatingLabelInput";
import SwitchWithLabel from "../../../../../components/Switch/SwitchWithLabel";
import SectionList from "./SectionList";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { Phase, Section } from "../../../../../models/ScriptInterfaces";
import { formatTimeParameter } from "../../ScriptDetailsView/InjuryTab/CommonData";
import { OpenWarningNotification } from "../../../../../components/Notification/Notification";
import {
  SetPhaseTitle,
  SetPhaseTitleLong,
  SetPhases,
} from "../../../MainlistSlice";
import { UpdatePhase } from "../../../../../api/Phases/ApiPost";
import produce from "immer";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { isNullOrUndefined } from "../../../../../utils/utilityfunctions";

interface Props {
  loadingToggle: Function;
  postSuccess: Function;
  postError: Function;
}

type ErrorType = {
  [key: string]: string;
};
const PhaseForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const { phases, currentPhaseIndex, isReadOnly } = useAppSelector(
    (state) => state.MainlistReducer,
  );

  const focusedStates = {
    titleAbbreviated: false,
    titleLong: false,
    hrs: false,
    min: false,
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);
  const [isBrief, setIsBrief] = React.useState(
    phases[currentPhaseIndex].IsScriptBrief,
  );

  const onPhaseSave = () => {
    let description = "";
    if (!isNullOrUndefined(phases[currentPhaseIndex].Description)) {
      description = phases[currentPhaseIndex].Description;
    }

    const newPhase: Phase = {
      Sections: [],
      Id: phases[currentPhaseIndex].Id,
      UUID: phases[currentPhaseIndex].UUID,
      TitleAbbreviated: phases[currentPhaseIndex].TitleAbbreviated ?? "",
      TitleLong: phases[currentPhaseIndex].TitleLong ?? "",
      Order: phases[currentPhaseIndex].Order ?? 1,
      ScriptId: phases[currentPhaseIndex].ScriptId,
      Description: description ?? "",
      IsScriptBrief: phases[currentPhaseIndex].IsScriptBrief,
      EstimatedTimeHours:
        parseInt(phases[currentPhaseIndex].EstimatedTimeHours.toString()) ?? 0,
      EstimatedTimeMinutes:
        parseInt(phases[currentPhaseIndex].EstimatedTimeMinutes.toString()) ??
        0,
    };

    UpdatePhase(newPhase, props.postSuccess, props.postError);
  };

  const scriptFormik = useFormik({
    initialValues: {
      titleAbbreviated: "",
      titleLong: "",
      hrs: 0o0,
      min: 0o0,
    },
    validateOnChange: true,
    validate: (values) => {
      const errors: ErrorType = {};
      if (values.titleAbbreviated.trim().length > 40) {
        errors["titleAbbreviated"] = "Max 40 characters";
      }
      if (values.titleLong.trim().length > 70) {
        errors["titleLong"] = "Max 70 characters";
      }

      return errors;
    },
    validateOnBlur: true,
    onSubmit: () => {
      onPhaseSave();
    },
  });

  const debounceSubmitForm = useDebounce(scriptFormik.submitForm);

  useEffect(() => {
    if (scriptFormik.isValid) {
      debounceSubmitForm();
    }

    return () => {
      debounceSubmitForm.cancel();
    };
  }, [scriptFormik.isValid, scriptFormik.values]);

  const onChangeIsBrief = (isBrief: boolean) => {
    try {
      const newPhase = {
        ...phases[currentPhaseIndex],
        IsScriptBrief: isBrief,
      };
      const newPhases = [
        ...phases.slice(0, currentPhaseIndex),
        newPhase,
        ...phases.slice(currentPhaseIndex + 1),
      ];
      dispatch(SetPhases(newPhases));
      UpdatePhase(newPhase, props.postSuccess, props.postError);
    } catch (error) {
      console.log(error);
    }
  };
  const onChangePhaseTitleAbv = (e: any) => {
    try {
      const newPhase = {
        ...phases[currentPhaseIndex],
        TitleAbbreviated: e.target.value,
      };
      dispatch(SetPhaseTitle(e.target.value));
      const newPhases = [
        ...phases.slice(0, currentPhaseIndex),
        newPhase,
        ...phases.slice(currentPhaseIndex + 1),
      ];
      dispatch(SetPhases(newPhases));
    } catch (error) {
      console.log(error);
    }
  };
  const onChangePhaseTitleLong = (e: any) => {
    try {
      const updatedPhases = produce(phases, (draftPhases) => {
        draftPhases[currentPhaseIndex].TitleLong = e.target.value;
      });
      dispatch(SetPhaseTitleLong(e.target.value));
      dispatch(SetPhases(updatedPhases));
    } catch (error) {
      console.log(error);
    }
  };
  const onChangePhaseEstimatedTime = (field: string, value: number) => {
    try {
      const updatedPhases = produce(phases, (draftPhases) => {
        if (field === "EstimatedTimeHours") {
          draftPhases[currentPhaseIndex].EstimatedTimeHours = value;
        } else {
          draftPhases[currentPhaseIndex].EstimatedTimeMinutes = value;
        }
      });
      dispatch(SetPhases(updatedPhases));
    } catch (error) {
      console.log("Error is : ", error);
    }
  };

  const handleBriefToggle = () => {
    if (isReadOnly) return; //disable action callback in read only script
    if (!isBrief) {
      // when isBrief enabled need to check if any section is timed action then display warning message
      // else set phase as a brief
      const isTimedActionSection = phases[currentPhaseIndex].Sections.some(
        (section: Section) =>
          section.SectionType?.toLocaleLowerCase() === "timed actions",
      );
      if (isTimedActionSection) {
        OpenWarningNotification({
          description:
            "This phase cannot become a brief phase because it has a timed action section",
        });
      } else {
        setIsBrief(!isBrief);
        onChangeIsBrief(!isBrief);
      }
    } else {
      setIsBrief(!isBrief);
      onChangeIsBrief(!isBrief);
    }
  };

  return (
    <FormContainer>
      <StyledForm isBrief={phases[currentPhaseIndex].IsScriptBrief}>
        <FloatingLabelInput
          label={"Short Title"}
          nameForInput={"titleAbbreviated"}
          value={phases[currentPhaseIndex].TitleAbbreviated}
          onChange={onChangePhaseTitleAbv}
          scriptFormik={scriptFormik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          maxLength={40}
          isAddNeeded={false}
          style={{ marginBottom: "35px" }}
          onSave={debounceSubmitForm}
          enableLiveCount={true}
          autoFocus
          readOnly={isReadOnly}
        />
        <FloatingLabelInput
          label={"Long Title"}
          nameForInput={"titleLong"}
          value={phases[currentPhaseIndex].TitleLong}
          onChange={onChangePhaseTitleLong}
          scriptFormik={scriptFormik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          maxLength={40}
          isAddNeeded={false}
          style={{ marginBottom: "35px" }}
          onSave={debounceSubmitForm}
          enableLiveCount={true}
          readOnly={isReadOnly}
        />
        <div className="scriptBrief" onClick={handleBriefToggle}>
          <SwitchWithLabel
            rightLabel={"BRIEF"}
            checked={phases[currentPhaseIndex].IsScriptBrief}
            onChange={() => undefined}
            disabled={isReadOnly}
          />
        </div>
        <div>
          <div>
            {phases[currentPhaseIndex].IsScriptBrief && (
              <div className="phaseTimeEstimateContainer">
                <span>Estimated Time To Complete Next Phases</span>
                <FloatingLabelInput
                  label={""}
                  nameForInput={"phaseHours"}
                  maxValue={99}
                  minValue={0}
                  maxLength={2}
                  value={Number(
                    formatTimeParameter(
                      phases[currentPhaseIndex].EstimatedTimeHours,
                    ),
                  )}
                  onChange={(e: any) =>
                    onChangePhaseEstimatedTime(
                      "EstimatedTimeHours",
                      e.target.value,
                    )
                  }
                  scriptFormik={scriptFormik}
                  lastFocused={lastFocused}
                  setIsFocused={setIsFocused}
                  setLastFocused={setLastFocused}
                  isFocused={isFocused}
                  isAddNeeded={false}
                  step={1}
                  precision={0}
                  isNumber
                  readOnly={isReadOnly}
                />
                <FloatingLabelInput
                  label={""}
                  nameForInput={"phaseMinutes"}
                  maxValue={60}
                  minValue={0}
                  maxLength={2}
                  value={Number(
                    formatTimeParameter(
                      phases[currentPhaseIndex].EstimatedTimeMinutes,
                    ),
                  )}
                  onChange={(e: any) =>
                    onChangePhaseEstimatedTime(
                      "EstimatedTimeMinutes",
                      e.target.value,
                    )
                  }
                  scriptFormik={scriptFormik}
                  lastFocused={lastFocused}
                  setIsFocused={setIsFocused}
                  setLastFocused={setLastFocused}
                  isFocused={isFocused}
                  isAddNeeded={false}
                  step={1}
                  precision={0}
                  isNumber
                  readOnly={isReadOnly}
                />
              </div>
            )}
          </div>
          <SectionList
            sections={phases[currentPhaseIndex].Sections || []}
            loadingToggle={props.loadingToggle}
            postSuccess={props.postSuccess}
            postError={props.postError}
            isReadOnly={isReadOnly}
          />
        </div>
      </StyledForm>
    </FormContainer>
  );
};

export default PhaseForm;
