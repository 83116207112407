import styled from "styled-components";

export const TimedActionSubTaskContainer = styled.div`
  display: grid;
  justify-content: center;
  min-width: 40px;
  height: fill-available;

  .failed-number {
    color: #ff4242;
  }

  hr {
    width: fill-available;
  }

  .number {
    padding: 0px 5px;
  }
`;
