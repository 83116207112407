export const SetIsFocused = (isFocusedOption: {}) => {
  return (dispatch: any) => {
    dispatch({
      type: "isFocused",
      payload: isFocusedOption,
    });
  };
};

export const SetLastFocused = (lastFocusedOption: {}) => {
  return (dispatch: any) => {
    dispatch({
      type: "option",
      payload: lastFocusedOption,
    });
  };
};
