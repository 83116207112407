import * as React from "react";
import "./Login.scss";
import LoginForm from "./LoginForm";
import RealxLogo from "../../../assets/img/REALX_VERTICAL.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../components/Notification/Notification";
import AlertBar from "../../../app/AlertBar";
const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    // Confirm email
    if (
      params.get("from") === "confirm-email" &&
      params.get("status") === "true"
    ) {
      OpenSuccessNotification({
        description: "Account verified successfully.",
      });
      history.replace("/");
    }
    if (
      params.get("from") === "confirm-email" &&
      params.get("status") === "false"
    ) {
      OpenErrorNotification({
        description: "Something went wrong. Try again.",
      });
      history.replace("/");
    }
  }, [location.search]);

  return (
    <>
      <AlertBar />
      <div data-testid="login_management">
        <div className="login-register-wrapper login-card">
          <div className="text-center">
            <Link to={`/`}>
              <img src={RealxLogo} alt="Logo" style={{ height: "120px" }} />
            </Link>
            <p className="login-text">Welcome Back!</p>
          </div>
          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default Login;
