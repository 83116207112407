import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getVitalsAARByScriptIdAndTaskStartTime } from "../../../../../api/AfterActionReview/ApiGet";
import {
  TaskAAR,
  VitalAAR,
} from "../../../../../models/AfterActionReviewInterfaces";
import { TaskType } from "../../../../../models/Enums";
import { HomeUrlParams } from "../../../../../models/HomeInterfaces";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { convertMinutesToHoursAndMinutes } from "../../../../../utils/utilityfunctions";
import {
  setActiveBlockId,
  setVitalsAAR,
  setVitalsAARInitial,
} from "../../AfterActionReviewSlice";
import TimedActionExpandableBlock from "../TimedActionExpandableBlock/TimedActionExpandableBlock";
import {
  BlockContainer,
  TimedActionBlockContainer,
  TimedActionBody,
} from "./TimedActionBlock.styled";
import TimedActionBlockDescription from "./TimedActionBlockDescription/TimedActionBlockDescription";
import TimedActionBlockMenu from "./TimedActionBlockMenu/TimedActionBlockMenu";
import TimedActionBlockName from "./TimedActionBlockName/TimedActionBlockName";
import TimedActionBlockRating from "./TimedActionBlockRating/TimedActionBlockRating";
import TimedActionBlockTime from "./TimedActionBlockTime/TimedActionBlockTime";
import TimedActionExpandCollapse from "./TimedActionExpandCollapse/TimedActionExpandCollapse";
import { isEmpty } from "lodash";

type Props = {
  content: TaskAAR;
};

const getName = (type: number) => {
  switch (type) {
    case TaskType.Diagnostic:
      return "Dx";

    case TaskType.Medication:
      return "Rx";

    case TaskType.Miscellaneous:
      return "Mx";

    case TaskType.Treatment:
      return "Tx";

    default:
      return "";
  }
};

const TimedActionBlock = ({ content }: Props) => {
  const {
    Id,
    IsComplete,
    IsFlagged,
    TaskAARType,
    Title,
    RatingAAR,
    DurationTime,
    StartTime,
    SubTaskAARs,
    Comments,
    PerformanceMedias,
  } = content;
  const dispatch = useAppDispatch();
  const { ActiveTaskId: ActiveBlockId } = useAppSelector(
    (state) => state.AfterActionReviewReducer,
  );
  const { id } = useParams<HomeUrlParams>();

  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (!isEmpty(SubTaskAARs) || !isEmpty(Comments)) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }, [SubTaskAARs, Comments]);

  const handleTimedActionBlockClick = () => {
    dispatch(setActiveBlockId(Id));
    if (StartTime) {
      getVitalsAARByScriptIdAndTaskStartTime(
        Number(id),
        StartTime * 60,
        (data: VitalAAR) => dispatch(setVitalsAAR(data)),
        () => dispatch(setVitalsAARInitial()),
      );
    } else {
      dispatch(setVitalsAARInitial());
    }
  };

  return (
    <>
      <BlockContainer onClick={() => handleTimedActionBlockClick()}>
        <TimedActionExpandCollapse
          isEnabled={
            (SubTaskAARs && SubTaskAARs.length) || Comments?.length
              ? true
              : false
          }
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
        <TimedActionBlockContainer
          className={ActiveBlockId === Id ? "task-active" : "task"}
        >
          <TimedActionBody type={TaskAARType}>
            <TimedActionBlockName
              name={getName(TaskAARType)}
              isComplete={IsComplete}
              isFlagged={IsFlagged}
            />
            <TimedActionBlockDescription
              IsFlagged={IsFlagged}
              IsMediaPresent={PerformanceMedias?.length > 0}
              IsCommentPresent={Comments?.length > 0}
              title={Title}
              subTasks={SubTaskAARs}
            />
            <TimedActionBlockRating rating={RatingAAR.Value} />
            <TimedActionBlockTime
              title="Duration"
              hr={convertMinutesToHoursAndMinutes(DurationTime).hours}
              mm={convertMinutesToHoursAndMinutes(DurationTime).minutes}
            />
            <TimedActionBlockTime
              title="Start"
              hr={convertMinutesToHoursAndMinutes(StartTime).hours}
              mm={convertMinutesToHoursAndMinutes(StartTime).minutes}
            />
            <TimedActionBlockMenu />
          </TimedActionBody>
        </TimedActionBlockContainer>
      </BlockContainer>
      <TimedActionExpandableBlock
        onClick={() => handleTimedActionBlockClick()}
        isCollapsed={isCollapsed}
        TaskAARType={TaskAARType}
        content={content}
      />
    </>
  );
};

export default TimedActionBlock;
