import * as React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { Switch, Redirect, withRouter } from "react-router-dom";
import { routeSelector } from "./modules/componentBuilder";
import { COMPANY_OWNER } from "../app/UserMenuTypes";

//export const history = require('history').createBrowserHistory();

// Instead of BrowserRouter, we use the regular router,
// but we pass in a customer history to it.
class AppRouter extends Component<any, {}> {
  render() {
    return (
      <Switch>
        {routeSelector(this.props.auth.isAuthenticated ? COMPANY_OWNER : "")}
        <Redirect from="*" to="/" />
      </Switch>
    );
  }
}

function mapToStateToProps(state: any) {
  return {
    auth: state.authReducer,
  };
}

export default withRouter(connect(mapToStateToProps)(AppRouter));
