import { ModalFuncProps } from "antd";
import React, { useEffect } from "react";
import Button from "../Button/Button";
import { StyledModal } from "./Modal.styled";
interface Props extends ModalFuncProps {
  children?: any;
  showModal: boolean;
  isLoading?: boolean;
}
export default function CustomModal({ isLoading, ...props }: Props) {
  const getFooter = (props: ModalFuncProps) => {
    const {
      okText = "Ok",
      onOk,
      cancelText = "Cancel",
      onCancel,
      open,
    } = props;

    const onMouseLeave = () => {
      document.getElementById("accept-btn");
    };

    const onMouseOver = () => {
      document.getElementById("accept-btn")?.blur();
    };

    useEffect(() => {
      setTimeout(() => {
        onMouseLeave();
      }, 10);
    }, [open]);

    return (
      <>
        <Button
          onClick={onCancel}
          shape={"rounded"}
          type="default"
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
        >
          {cancelText}
        </Button>
        <Button
          loading={isLoading}
          id={"accept-btn"}
          type="primary"
          shape={"rounded"}
          onClick={onOk}
        >
          {okText}
        </Button>
      </>
    );
  };
  return (
    <StyledModal
      title={props.title}
      open={props.showModal}
      footer={getFooter(props)}
      onOk={props.onOk}
      onCancel={props.onCancel}
      maskStyle={{ backgroundColor: "#0D0D0D99", backdropFilter: "blur(4px)" }}
    >
      {props.children}
    </StyledModal>
  );
}
