import * as React from "react";
import { StyledScriptTag } from "./DetailsPanelComponents.styled";

interface ScriptTagProps {
  tag: string;
  className: string;
}

export const ScriptTags = (props: ScriptTagProps) => {
  const { tag, className } = props;
  return <StyledScriptTag className={className}>{tag}</StyledScriptTag>;
};
