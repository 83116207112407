import styled from "styled-components";
import {
  EditOutlinedIcons,
  DeleteIcon as GlobalDeleteIcon,
} from "../../IconsTheme/Icons.global";
import { Row } from "antd";

export const FileUploadContainer = styled.section`
  position: relative;
  margin: 0 0 15px;
  border: 1px dotted lightgray;
  padding: 35px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
`;

export const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
`;

export const InputLabel = styled.label`
  //top: -21px;
  font-size: 13px;
  height: 50px !important;
  border-bottom: 5px solid rgba(255, 255, 255, 0.5);
  color: black;
  //left: 0;
  //position: absolute;
`;

export const DragDropText = styled.p`
  font-weight: bold;
  letter-spacing: 2.2px;
  margin-top: 0;
  text-align: center;
`;

export const StyledRow = styled(Row)`
  position: relative;
  background: rgba(128, 128, 128, 0.1);
  height: calc(100vh - 70px);
  overflow: auto;
  width: 100%;
  .ant-col {
    padding-right: 2px;
    &:last-child {
      padding-right: 0;
    }
  }
`;

export const MediaUploadWrapper = styled.div.attrs((props: any) => ({
  isPdfFile: props.isPdfFile,
}))`
  background: rgb(16, 16, 16) !important;
  height: calc(100vh - 70px);
  .MediaImageDetail {
    width: -webkit-fill-available;
    padding: ${(props) => (props.isPdfFile ? "1rem" : "4rem")};
    height: calc(100vh - 65px - 54px);
    img,
    video {
      width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
    }
  }
`;
export const StyledMediaWrapper = styled.div`
  padding: 1rem;
  height: calc(100vh - 60px - 60px);
  overflow: auto;
`;
export const MediaListWrapper = styled(StyledMediaWrapper)``;
export const MediaUploadBlock = styled(StyledMediaWrapper)``;

export const StyledMediaDetails = styled.div`
  padding: 1rem;
  height: calc(100vh - 60px - 60px);
  overflow: auto;
  .title,
  .description {
    min-height: 40px;
    font-size: 1.25rem;
    color: #fff;
    margin-bottom: 1rem;
    .inline-display {
      padding: 4px 10px;
      margin-left: 0;
      word-break: break-all;
      cursor: pointer;
    }
    .inline-editing {
      padding-top: 0;
    }
  }
  .meta {
    padding: 3rem 1rem;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.875rem;
    & > div {
      p {
        margin-bottom: 0.1rem;
      }
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const TopHeadingBlock = styled.div`
  width: 100%;
  height: auto;
  border-bottom: 5px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  height: 3rem;

  .blockNameAndIcon {
    padding: 0px 0.625rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    .icon {
      font-size: clamp(14px, 1vw, 1.2em);
      margin-right: 0.5rem;
      width: clamp(20px, 10%, 30px);
    }
    .text {
      font-size: clamp(1rem, 1vw, 1.125rem);
      width: 100%;
    }
  }
  .iconsDiv {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    & > span {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      padding: 0 0.625rem;
      font-size: 22px;
      color: grey;
      cursor: pointer;
    }
    &:hover {
      .anticon-close {
        color: #ffffff !important;
      }
    }
  }
`;
export const StyledCheckboxWrapper = styled.div`
  display: flex;
  height: 2rem;
  margin-bottom: 0.1rem;
  justify-content: space-between;
  align-items: center;
`;

export const StyledImageWrapper = styled.div.attrs((props: any) => ({
  selected: props.selected,
}))`
  display: inline-block;
  padding: 0.25rem 0.75rem 0.75rem;
  margin: 0 0.75rem 0.75rem 0;
  position: relative;
  box-shadow: ${(props) =>
    props.selected
      ? "inset 0 0 2px rgba(35, 208, 235, 1)"
      : "inset 0 0 15px rgb(0 0 0 / 10%), inset 0 0 0 1px rgb(0 0 0 / 5%)"};
  background: #131313;
  cursor: pointer;
  width: clamp(240px, 30%, 320px);
  height: 16.9rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    width: clamp(220px, 30vw, 280px);
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    width: clamp(180px, 40vw, 250px);
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    width: clamp(180px, 50vw, 200px);
  }
  .ant-image {
    width: -webkit-fill-available;
    height: 11rem;
    max-height: 100%;
    margin-bottom: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 100%;
      height: auto;
      width: auto;
      max-width: -webkit-fill-available;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  span.filename {
    display: block;
    padding: 2px;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2rem;
    white-space: nowrap;
    overflow: hidden;
  }
  .anticon {
    color: #ffffff40;
  }
  .zoom-icon-btn {
    width: 35px;
    height: 35px;
    z-index: 999;
  }
  &:hover {
    background: #1a1a1a;

    .anticon {
      color: #fff;
    }
    .ant-checkbox-inner {
      border-color: ${(props) => (props.selected ? "#23d0eb" : "#fff")};
    }
  }
`;

export const UploadedMedia = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    flex-wrap: wrap;
    height: 15rem;
    justify-content: center;
  }
  &:hover {
    .mediaEditDelete {
      display: flex;
      .mediaEdit span,
      .mediaDelete span {
        color: #9a9a9a;
      }
    }
  }
  .mediaEditDelete {
    transition: all 0.3s ease;
    display: none;
    flex-direction: column;
    position: absolute;
    right: 0;
    justify-content: space-between;
    height: -webkit-fill-available;
    @media only screen and (max-width: ${({ theme }) =>
        theme.breakpoints?.lg}) {
      left: 0;
      flex-direction: row;
      height: 40px;
    }

    .mediaEdit,
    .mediaDelete {
      cursor: pointer;
      padding: 0.5rem 0;
      span {
        font-size: 24px;
        color: rgba(255, 255, 255, 0.05);
      }
    }
    .mediaEdit {
      &:hover {
        span {
          color: #ffffff;
        }
      }
    }

    .mediaDelete {
      &:hover {
        span {
          color: #ff4242;
        }
      }
    }
  }

  .mediaEdit,
  .mediaDelete,
  .mediaContent {
    padding: 11.5px;
    background: #1e1e1e;
  }

  .mediaContent {
    width: 66%;
    width: calc(100% - 140px);
    margin: 0 1px;
    overflow-wrap: anywhere;
    @media only screen and (max-width: ${({ theme }) =>
        theme.breakpoints?.lg}) {
      width: 100%;
      position: absolute;
      bottom: 0;
    }
    span,
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      color: #ffffff;
    }
    span {
      font-size: 18px;
      line-height: 21px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .mediaImage {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 140px;
    height: inherit;
    @media only screen and (max-width: ${({ theme }) =>
        theme.breakpoints?.lg}) {
      max-height: 140px;
    }
    img {
      width: auto;
      max-width: 100%;
      height: -webkit-fill-available;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      object-fit: contain;
    }
  }
`;

export const DeleteIcon = styled(GlobalDeleteIcon)`
  font-size: 1.25rem !important;
  margin: auto 10px;
  z-index: 1;

  &:hover {
    cursor: pointer;
    color: #ff4242;
  }
`;

export const EditIcon = styled(EditOutlinedIcons)`
  font-size: 1.25rem !important;
  margin: auto 10px;
  z-index: 1;

  &:hover {
    cursor: pointer;
    color: #ffffff;
  }
`;
export const FilePreviewContainer = styled.article.attrs((props: any) => ({
  hasAudio: props.hasAudio,
}))`
  margin-bottom: 35px;

  .delete-media {
    display: inline-flex;
    width: 100%;
    background: #1e1e1e;
    padding: 0.5rem;
    &:hover {
      background: #313131;
    }
  }

  span {
    font-size: 14px;
  }

  audio::-webkit-media-controls-panel {
    border: 1px solid #1e1e1e;
  }

  audio::-webkit-media-controls-play-button {
    background-color: #56aeff;
    color: #56aeff;
    border-radius: 50%;
  }

  .audioFileHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) =>
      props.hasAudio ? "#ffffff" : "rgba(255, 255, 255, 0.5)"};
  }

  .audioFile {
    padding: 10px;
    display: flex;
    width: 100%;

    figure {
      width: 100%;
    }

    audio {
      width: 100%;
      height: 40px;
    }
  }
`;

export const PreviewList = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
`;

export const MediasContainer = styled.div`
  max-height: 100%;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: fill-available;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  margin-bottom: 20px;

  ::-webkit-scrollbar-track {
    background-color: #131313;
  }

  ::-webkit-scrollbar-thumb {
    max-height: 20px;
    background: #23d0eb;
    border-radius: 50px;
  }
`;
export const PlayAudioBlock = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 0.625rem;

  & > div:nth-child(1) {
    width: 91%;
  }

  & > div:nth-child(2) {
    min-width: 35px;
    width: 9%;
  }

  .addAudioIcon {
    height: 60px;
    width: 9% !important;
    display: flex;
    align-items: center;
    font-size: clamp(1rem, 24px, 1.4rem);
    justify-content: center;
    padding-top: 0.5rem;
    color: rgba(112, 112, 112, 255);
    z-index: 10;
    cursor: pointer;

    &:hover {
      color: #fff;
    }
  }
`;

export const AudioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  .uploadButton {
    padding: 5px;
    width: 50%;
    border-radius: 999px;
  }
`;

export const FileMetaData = styled.div.attrs((props: any) => ({
  isImageFile: props.isImageFile,
}))`
  display: ${(props) => (props.isImageFile ? "none" : "flex")};
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background-color: rgba(5, 5, 5, 0.55);

  span {
    overflow-wrap: anywhere;
  }

  aside {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }
`;

export const RemoveFileIcon = styled.i`
  cursor: pointer;

  &:hover {
    transform: scale(1.3);
  }
`;

export const PreviewContainer = styled.section`
  padding: 0.25rem;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;

  &:hover {
    opacity: 0.55;

    ${FileMetaData} {
      display: flex;
    }
  }

  & > div:first-of-type {
    height: 100%;
    position: relative;
  }

  @media only screen and (max-width: 750px) {
    width: 25%;
  }

  @media only screen and (max-width: 500px) {
    width: 50%;
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    padding: 0 0 0.4em;
  }
`;

export const ImagePreview = styled.img`
  border-radius: 6px;
  width: 100%;
  height: 100%;
`;
export const AudioPreview = styled.audio`
  border-radius: 6px;
  width: 100%;
  height: 100%;
`;
