import * as React from "react";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { GreenButton, GreyButton } from "../styled";
import { StyledFooter } from "./styledComponents/StyledFooter.styled";
import { Tooltip } from "antd";

type Props = {
  formik: any;
  onBack: Function;
  disabled: boolean;
};

const CasualtyFormFooter = ({ formik, onBack, disabled }: Props) => {
  return (
    <StyledFooter>
      {/* <Tooltip title={disabled ? "" : "Back to Casualty List"}> */}
      <Tooltip title={disabled ? "" : "Back to Script Details"}>
        <GreyButton htmlType="submit" onClick={() => onBack()}>
          <ArrowLeftOutlined /> Back
        </GreyButton>
      </Tooltip>
      {!formik.dirty && formik.isValid && (
        <GreyButton htmlType="submit" onClick={formik.submitForm}>
          Next
          <ArrowRightOutlined />
        </GreyButton>
      )}
      {!formik.isValid && (
        <GreyButton className="disable-btn" htmlType="submit">
          Next
          <ArrowRightOutlined />
        </GreyButton>
      )}
      {formik.dirty && formik.isValid && (
        <GreenButton htmlType="submit" onClick={formik.submitForm}>
          {!disabled && "Save & "} Next <ArrowRightOutlined />
        </GreenButton>
      )}
    </StyledFooter>
  );
};

export default CasualtyFormFooter;
