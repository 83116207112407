/*
http://192.168.0.212:3000/e/en/projects/RealX-FloatingLabelInput-Component-Documentation

Please refer to the above wiki link for guidance on floating label input

*/
import React, { useRef } from "react";
import useKeyPress from "react-use-keypress";
import { Input as AntInput } from "antd";
import { CheckOutLinedIcon } from "../../IconsTheme/Icons.global";
import { InputProps, InputRef } from "antd/lib/input";
import "../../css/custom.css";
import { FloatingLabel } from "./FloatingLabel.styled";
import { OpenInfoNotification } from "../Notification/Notification";
interface FloatingLabelInputProps extends InputProps {
  inputRef?: React.RefObject<InputRef>;
  label: string;
  nameForInput: string;
  value: string | number;
  type?: string;
  onSave?: any;
  lastPassIgnore?: boolean;
  maxLength?: number;
  enableLiveCount?: boolean;
  minValue?: number;
  maxValue?: number;
  displayMinMaxRange?: boolean;
  debouncedSave?: any;
  onChange: any;
  scriptFormik: any;
  lastFocused: any;
  isAddNeeded: boolean;
  suffixIcon?: any | null;
  setIsFocused: any;
  setLastFocused: any;
  isFocused: any;
  isNumber?: boolean;
  precision?: number;
  onBlur?: any;
  onFocus?: any;
  classNameForContainer?: string;
  nextElementToFocusOnPressEnter?: string; // Name of next form element to focus on pressing enter
  displayErrorMsg?: boolean;
  autocomplete?: string;
  step?: number;
}

type ErrorType = {
  [key: string]: string;
};

const FloatingLabelInput = (props: FloatingLabelInputProps) => {
  const [isTyping, setIsTyping] = React.useState(false);
  const {
    inputRef,
    label,
    value: initialValue,
    suffix,
    nameForInput,
    maxLength,
    minValue,
    maxValue,
    isAddNeeded,
    suffixIcon,
    onSave,
    scriptFormik,
    onBlur,
    onChange,
    onFocus,
    debouncedSave,
    setIsFocused,
    setLastFocused,
    lastFocused,
    isFocused,
    isNumber,
    precision,
    nextElementToFocusOnPressEnter,
    classNameForContainer,
    enableLiveCount,
    displayErrorMsg,
    autocomplete,
    lastPassIgnore,
    displayMinMaxRange,
    step,
    readOnly,
    ...inputProps
  } = props;
  const floatingLabelInputRef = useRef<InputRef>(null);

  // increase value with step on arrow up key press
  useKeyPress("ArrowUp", (e: any) => {
    if (isNumber && isFocused && isFocused[nameForInput]) {
      e.preventDefault();
      let formValue = scriptFormik.values[nameForInput];
      if (formValue == "") {
        formValue = 0;
      }
      if (step && Number(formValue) < Number(maxValue)) {
        const newValue = (Number(formValue) + step).toFixed(precision);
        scriptFormik.setFieldValue(nameForInput, newValue);
        props.onChange({ target: { value: newValue } });
      }
    }
  });

  // decrease value with step on arrow up key press
  useKeyPress("ArrowDown", (e: any) => {
    if (isNumber && isFocused && isFocused[nameForInput]) {
      e.preventDefault();
      let formValue = scriptFormik.values[nameForInput];
      if (formValue == "") {
        formValue = 0;
      }
      if (step && Number(formValue) > Number(minValue)) {
        const newValue = (Number(formValue) - step).toFixed(precision);
        scriptFormik.setFieldValue(nameForInput, newValue);
        props.onChange({ target: { value: newValue } });
      }
    }
  });

  const labelClass = (value: any) => {
    return props.isFocused[`${props.nameForInput}`] ||
      props.value ||
      props.value === 0
      ? `label ${props.disabled ? "disabled" : ""}`
      : `label floatingLabel ${props.disabled ? "disabled" : ""}`;
  };
  const inputState = (input: string) => {
    if (input === props.nameForInput) {
      if (
        props.scriptFormik.errors[`${input}`] &&
        props.scriptFormik.touched[props.nameForInput]
      ) {
        return "errorInput";
      } else if (
        !props.isFocused[`${input}`] &&
        !props.scriptFormik.errors[`${input}`] &&
        props.lastFocused[`${input}`] &&
        props.value !== ""
      ) {
        return "successInput";
      } else {
        return "default";
      }
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    if (props.isNumber) {
      const formattedValue = formatNumber(inputValue);
      const newEvent = {
        ...e,
        target: {
          ...e.target,
          value: formattedValue,
        },
        currentTarget: {
          ...e.currentTarget,
          value: formattedValue,
        },
      };
      props.setLastFocused({
        ...props.lastFocused,
        [`${props.nameForInput}`]: true,
      });
      props.scriptFormik.setFieldValue(props.nameForInput, formattedValue);
      props.onChange(newEvent);
    } else {
      props.setLastFocused({
        ...props.lastFocused,
        [`${props.nameForInput}`]: true,
      });
      props.scriptFormik.handleChange(e);
      props.onChange(e);
    }
  };
  const getAllowedInputRegex = (floatAllowed: number) => {
    const negativeNumberWithFloat = /[^0-9.-]/g;
    const negativeNumberWithoutFloat = /[^0-9-]/g;
    const positiveNumberWithFloat = /[^0-9.]/g;
    const positiveNumberWithoutFloat = /[^0-9]/g;

    //if min less than 0, allows negative input
    if (props.minValue && props.minValue < 0) {
      if (floatAllowed) {
        return negativeNumberWithFloat;
      } else {
        return negativeNumberWithoutFloat;
      }
    } else {
      if (floatAllowed) {
        return positiveNumberWithFloat;
      } else {
        return positiveNumberWithoutFloat;
      }
    }
  };

  const formatNumber = (value: string) => {
    //value = input value
    //options
    const floatAllowed = props.precision ? props.precision : 0;
    const max = props.maxValue ? props.maxValue : 10000000000000;
    const min = props.minValue ? props.minValue : 0;
    const inputAllowed = getAllowedInputRegex(floatAllowed);
    //restricts keystrokes to only allow numbers and a single "." (or "-" if min less than 0)
    const numString = value
      .replace(inputAllowed, "")
      .replace(/(\..*)\./g, "$1");

    //facilitates float
    const numLength = numString.length;
    const decIndex = numString.indexOf(".");
    const position = numLength - decIndex;

    if (value === "-" && min >= 0) {
      OpenInfoNotification({
        description: `Negative numbers are not allowed.`,
      });
    }
    //automatically returns input to max value
    if (Number(numString) > max) {
      OpenInfoNotification({
        description: `The maximum value allowed is ${max}`,
      });
      return max;
    } else if (Number(numString) < min) {
      OpenInfoNotification({
        description: `The minimum value allowed is ${min}`,
      });
      return min;
    } else if (numString.indexOf(".") !== -1 && position > floatAllowed) {
      return parseFloat(numString).toFixed(floatAllowed);
    } else {
      return numString;
    }
  };
  const displayError = () => {
    if (
      (typeof props.displayErrorMsg === "undefined" || props.displayErrorMsg) &&
      !!props.scriptFormik.errors[`${props.nameForInput}`] &&
      props.scriptFormik.touched[props.nameForInput]
    ) {
      return (
        <div className="error">
          {props.scriptFormik.errors[`${props.nameForInput}`]}
        </div>
      );
    }
    return null;
  };
  const displayMinMaxLabel = () => {
    if (
      !props.enableLiveCount &&
      isTyping &&
      props.displayMinMaxRange &&
      !props.scriptFormik.errors[`${props.nameForInput}`] &&
      (typeof props.maxValue === "number" || typeof props.minValue === "number")
    ) {
      return (
        <div className="count">
          {props.minValue ? `Min ${props.minValue}` : null}{" "}
          {props.minValue && props.maxValue ? "-" : null}{" "}
          {props.maxValue ? `Max ${props.maxValue}` : null}
          {(props.minValue || props.maxValue) &&
          props.isAddNeeded &&
          props.suffixIcon
            ? props.suffixIcon
            : null}
        </div>
      );
    }
    return null;
  };
  const handleOnFocus = () => {
    if (props.readOnly) return;
    props.setIsFocused({
      ...props.isFocused,
      [`${props.nameForInput}`]: true,
    });
    props.onFocus && props.onFocus();
  };
  const handleOnBlur = (e: any) => {
    if (props.readOnly) return;
    // const value = Number(e.target.value);
    // props.scriptFormik.setFieldValue(
    //   props.nameForInput,
    //   value > 0 ? value : ""
    // );
    setIsTyping(false);
    props.setIsFocused({
      ...props.isFocused,
      [`${props.nameForInput}`]: false,
    });
    props.setLastFocused({
      ...props.lastFocused,
      [`${props.nameForInput}`]: false,
    });
    props.scriptFormik.handleBlur(e);
    props.onBlur && props.onBlur(e);
  };
  const handleOnKeyPress = (e: any) => {
    if (props.readOnly) return;
    setIsTyping(true);
    if (e.key == "Enter" && props.isAddNeeded) {
      e.preventDefault();
      !props.scriptFormik.errors[`${props.nameForInput}`] &&
        props.scriptFormik.values[`${props.nameForInput}`] !== "" &&
        props.onSave();
    }
    if (e.key == "Enter") {
      e.preventDefault();
      if (
        nextElementToFocusOnPressEnter &&
        document.getElementsByName(nextElementToFocusOnPressEnter).length > 0
      ) {
        props.setIsFocused({
          ...props.isFocused,
          [`${props.nameForInput}`]: false,
        });
        document.getElementsByName(nextElementToFocusOnPressEnter)[0].focus();
      }
    }
  };
  React.useEffect(() => {
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.debouncedSave]);
  return (
    <FloatingLabel
      className={`${props.classNameForContainer} intervalMinutesSSuffix`}
      isFocused={!props.isFocused[`${props.nameForInput}`]}
      errors={scriptFormik.errors[`${props.nameForInput}`]}
      lastFocused={props.lastFocused[`${props.nameForInput}`]}
      nameOfInput={props.nameForInput}
    >
      <label
        className={labelClass(`${props.nameForInput}`)}
        htmlFor={props.nameForInput}
      >
        {props.label}
      </label>
      {displayError()}
      {!props.isFocused[`${props.nameForInput}`] &&
        !props.scriptFormik.errors[`${props.nameForInput}`] &&
        props.lastFocused[`${props.nameForInput}`] &&
        props.value !== "" && (
          <div className="">
            <CheckOutLinedIcon className="checkCircleIcon" />
          </div>
        )}
      {typeof props.value === "string" &&
        props.enableLiveCount &&
        isTyping &&
        props.isFocused[`${props.nameForInput}`] &&
        !props.scriptFormik.errors[`${props.nameForInput}`] &&
        props.value !== "" && (
          <div className="count">
            {props.value.length}/{props.maxLength}
          </div>
        )}
      {displayMinMaxLabel()}
      <AntInput
        ref={inputRef || floatingLabelInputRef}
        id={props.nameForInput}
        name={props.nameForInput}
        type={props.type}
        className={`${inputState(props.nameForInput)} ${
          readOnly ? "read-only" : ""
        } `}
        value={props.value}
        onChange={handleChange}
        data-lpignore={
          props.lastPassIgnore == null ? true : props.lastPassIgnore
        }
        onClick={() => setIsTyping(true)}
        onKeyPress={handleOnKeyPress}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        maxLength={props.maxLength}
        suffix={props.isAddNeeded && props.suffixIcon}
        {...inputProps}
        readOnly={props.readOnly ? true : false}
        autoComplete={props.autocomplete ? props.autocomplete : "off"}
        title=""
      />
    </FloatingLabel>
  );
};

export default FloatingLabelInput;
