import React, { useEffect } from "react";
import { StyledForm } from "../PhaseForm/PhaseForm.styled";
import { useFormik } from "formik";
import { FormContainer } from "../RolePlayerForm/PromptForm.styled";
import {
  EyeInvisibleOutlinedIcons,
  EyeOutlinedIcons,
} from "../../../../../IconsTheme/Icons.global";
import FloatingLabelTextArea from "../../../../../components/FloatingLabel/FloatingLabelTextArea";
import FloatingLabelInput from "../../../../../components/FloatingLabel/FloatingLabelInput";
import { WhiteIconButton } from "../../../../../styles/Buttons.styled";
import { useAppSelector } from "../../../../../store/hooks";
import GenerateText from "../../../../../components/OpenAIComponent";
import {
  GenericBlock,
  Text as OurText,
} from "../../../../../models/ScriptInterfaces";
import { UpdateTextBlock } from "../../../../../api/Block/ApiPost";
import { OpenErrorNotification } from "../../../../../components/Notification/Notification";
import { useDebounce } from "../../../../../hooks/useDebounce";
interface TextFormProps {
  onChange: Function;
  toggleTitle: Function;
  onSave: Function;
  isTitleEnabled: boolean;
}

type ErrorType = {
  [key: string]: string;
};

const TextEditForm = ({ onSave, ...props }: TextFormProps) => {
  const state = useAppSelector((state: any) => state.MainlistReducer);
  const {
    textDescription,
    textTitle,
    isReadOnly,
    currentPhaseIndex,
    currentSectionIndex,
    currentBlockIndex,
  } = useAppSelector((state: any) => state.MainlistReducer);
  const focusedStates = {
    title: "",
    description: "",
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);
  const [isTitleEnabled, setIsTitleEnabled] = React.useState(true);

  const scriptFormik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validate: (values) => {
      const errors: ErrorType = {};
      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      onSave();
    },
  });

  const handleHideTextTitleFunctionality = () => {
    if (isReadOnly) return; //disable action callback in read only script
    setIsTitleEnabled(!isTitleEnabled);
    props.toggleTitle(!isTitleEnabled);
  };

  React.useEffect(() => {
    setIsTitleEnabled(props.isTitleEnabled);
  }, [props.isTitleEnabled]);

  const debounceSubmitForm = useDebounce(scriptFormik.submitForm);

  useEffect(() => {
    if (scriptFormik.isValid) {
      debounceSubmitForm();
    }

    return () => {
      debounceSubmitForm.cancel();
    };
  }, [scriptFormik.isValid, scriptFormik.values]);

  const completedSentenceCB = (text: string) => {
    const customEvent = {
      target: {
        name: "description",
        value: text.trim(),
      },
    };
    props.onChange(customEvent);
    const updateItem =
      state.phases![currentPhaseIndex].Sections![currentSectionIndex]!.Blocks![
        currentBlockIndex
      ];
    const textBlockPayload: GenericBlock<OurText> = {
      ...updateItem,
      BlockContent: {
        ...updateItem.BlockContent,
        Description: text.trim(),
      },
    };
    UpdateTextBlock(
      textBlockPayload,
      (response: any) => {
        console.log(response);
      },
      (error: any) => {
        OpenErrorNotification({
          description: error,
        });
      },
    );
  };

  return (
    <FormContainer>
      <StyledForm>
        <div style={{ marginBottom: "30px" }}>
          <FloatingLabelInput
            autoFocus
            label={"Title"}
            nameForInput={"title"}
            value={textTitle}
            onChange={(e: any) => {
              e.target.value = e.target?.value;
              props.onChange(e);
            }}
            scriptFormik={scriptFormik}
            lastFocused={lastFocused}
            isAddNeeded={false}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            onSave={debounceSubmitForm}
            onBlur={debounceSubmitForm}
            maxLength={70}
            isFocused={isFocused}
            enableLiveCount={true}
            nextElementToFocusOnPressEnter="description"
            readOnly={isReadOnly}
          />
          <div className="" onClick={handleHideTextTitleFunctionality}>
            {isTitleEnabled ? (
              <div>
                <WhiteIconButton
                  type="link"
                  icon={<EyeOutlinedIcons className="visibleIcon" />}
                  size="middle"
                >
                  Visible
                </WhiteIconButton>
              </div>
            ) : (
              <div>
                <WhiteIconButton
                  type="link"
                  icon={<EyeInvisibleOutlinedIcons />}
                  size="middle"
                >
                  Hidden
                </WhiteIconButton>
              </div>
            )}
          </div>
        </div>
        <FloatingLabelTextArea
          onChange={props.onChange}
          scriptFormik={scriptFormik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          label={"Text"}
          textareaName={"description"}
          value={textDescription}
          onSave={debounceSubmitForm}
          onBlur={debounceSubmitForm}
          enableLiveCount={false}
          readOnly={isReadOnly}
        />
        <GenerateText
          input={textDescription}
          maxLength={400}
          completedSentenceCB={completedSentenceCB}
        />
      </StyledForm>
    </FormContainer>
  );
};

export default TextEditForm;
