import React from "react";
import AuthenticationPageLeftPane from "../../../components/CenterScreenCard/AuthenticationPageLeftPane";
import { AuthenticationContainer } from "../Authorization.styled";
import RequestIcon from "../../../assets/icons/Request.svg";

const RequestCompanyAccessSuccess = () => {
  return (
    <AuthenticationPageLeftPane title="Confirm your account" displayLoginLink>
      <AuthenticationContainer>
        <div className="success">
          <img className="success-logo" src={RequestIcon} alt="Mail Logo" />
          <h1 className="message">
            A request has been sent to the account administrator.
            <br />
            <br />
            Look for an email invitation to join the account.
          </h1>
        </div>
      </AuthenticationContainer>
    </AuthenticationPageLeftPane>
  );
};

export default RequestCompanyAccessSuccess;
