import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Casualty } from "../../models/CasualtyInterfaces";

interface ScriptBuilderState {
  casualties: Casualty[];
  selectedCasualtyId: number;
  isReadOnly: boolean;
}

const initialState: ScriptBuilderState = {
  casualties: [],
  selectedCasualtyId: 0,
  isReadOnly: false,
};

export const scriptBuilderSlice = createSlice({
  name: "scriptBuilder",
  initialState,
  reducers: {
    SetCasualties: (state, action: PayloadAction<Casualty[]>) => {
      state.casualties = action.payload;
    },
    AddCasualty: (state, action: PayloadAction<Casualty>) => {
      const updatedCasualties = JSON.parse(JSON.stringify(state.casualties));
      updatedCasualties.unshift(action.payload);
      const sortedCasualty = [
        ...updatedCasualties
          .filter((x: any) => x.IsMasterCasualty)
          .sort((a: any, b: any) => b.Id - a.Id),
        ...updatedCasualties
          .filter((x: any) => !x.IsMasterCasualty)
          .sort((a: any, b: any) => b.Id - a.Id),
      ];
      state.casualties = sortedCasualty;
    },
    UpdateCasualties: (state, action: PayloadAction<Casualty>) => {
      const index = state.casualties.findIndex(
        (x) => x.Id === action.payload.Id,
      );
      if (index !== -1) {
        state.casualties[index] = action.payload;
      }
    },
    SetSelectedCasualtyId: (state, action: PayloadAction<number>) => {
      state.selectedCasualtyId = action.payload;
    },
    SetIsReadOnlyInScriptView: (state, action: PayloadAction<boolean>) => {
      state.isReadOnly = action.payload;
    },
  },
});

export default scriptBuilderSlice.reducer;

export const {
  SetCasualties,
  AddCasualty,
  UpdateCasualties,
  SetSelectedCasualtyId,
  SetIsReadOnlyInScriptView,
} = scriptBuilderSlice.actions;
