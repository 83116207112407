import { FormikHelpers } from "formik/dist/types";
import { BloodType } from "../../../../../models/Enums";

export const convertCmToFeet = (height: number) => {
  const realInch = Math.round(height / 2.54);
  const feet = Math.floor(realInch / 12);
  const inches = Math.round(realInch % 12);
  const heightArray: number[] = [];
  heightArray.push(feet);
  heightArray.push(inches);
  return heightArray;
};

export const convertFeetToCm = (feet: number, inches: number) => {
  const valid = +feet >= 0 && +inches >= 0;
  if (valid) {
    return Math.round((+feet + +inches / 12) * 12 * 2.54);
  }
  return 0;
};

export const DisplayWeight = (weight: string | number, unit: string) => {
  if (unit != "kg") {
    weight = convertPoundToKg(Number(weight));
  }
  return `${Number(weight).toFixed(0)}kg`;
};

export const convertPoundToKg = (weight: number) => {
  const poundToKg = 2.20462262185;
  if (weight > 0) {
    return (weight / poundToKg).toFixed(0);
  }
  return 0;
};

export const convertKgToPound = (weight: number) => {
  const poundToKg = 2.20462262185;
  if (weight > 0) {
    return (weight * poundToKg).toFixed(0);
  }
  return 0;
};

export const getCreateFromMasterWithRelationsPayload = (
  values: any,
  height: number,
  weightUnit: string,
) => {
  return {
    Description: values["description"],
    Gender: values["gender"],
    Height: height,
    Weight: Number(values["weight"]),
    WeightUnit: weightUnit,
    BloodType:
      values["bloodType"] >= BloodType.APositive &&
      values["bloodType"] < BloodType.ONegative
        ? Number(values["bloodType"])
        : BloodType.ABPositive,
    AllergyIds: values["allergies"],
    Age: Number(values["age"]),
    masterCasualtyId: values["Id"],
  };
};

export const getEditOrCreateCasualtyWithRelationsPayload = (
  values: any,
  height: number,
  weightUnit: string,
) => {
  return {
    Id: 0,
    Description: values["description"],
    Gender: values["gender"],
    Height: height,
    Weight: Number(values["weight"]),
    WeightUnit: weightUnit,
    BloodType:
      values["bloodType"] >= BloodType.APositive &&
      values["bloodType"] < BloodType.ONegative
        ? Number(values["bloodType"])
        : BloodType.ABNegative,
    AllergyIds: values["allergies"],
    Age: Number(values["age"]),
    insultIds: [],
    injuryIds: [],
    isMasterCasualty: values["isMasterCasualty"],
  };
};

type CasualtyFormikData = {
  gender: number; // Assuming 0 for male, 1 for female
  age: number;
  weight: number;
  height: number;
};
/**
 * Sets default values for casualty data in a Formik form based on gender.
 *
 * @param {object} formik - Formik object containing values and setFieldValue method.
 * @param {CasualtyFormikData} formik.values - Current values of the casualty form.
 * @param {Function} formik.setFieldValue - Formik's setFieldValue method.
 * @returns {void}
 */
export const setDefaultCasualtyData = (formik: {
  values: CasualtyFormikData;
  setFieldValue: FormikHelpers<CasualtyFormikData>["setFieldValue"];
}) => {
  // Male
  if (
    formik.values.gender === 0 &&
    formik.values.age === 25 &&
    formik.values.weight === 65 &&
    formik.values.height === 170
  ) {
    formik.setFieldValue("age", 25, true);
    formik.setFieldValue("weight", 86, true);
    formik.setFieldValue("height", 182, true);
  }
  // Female
  else if (
    formik.values.gender === 1 &&
    formik.values.age === 25 &&
    formik.values.weight === 86 &&
    formik.values.height === 182
  ) {
    formik.setFieldValue("age", 25, true);
    formik.setFieldValue("weight", 65, true);
    formik.setFieldValue("height", 170, true);
  }
};
