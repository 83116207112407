import * as React from "react";
import { useDispatch } from "react-redux";
import { CASUALTY_CREATOR_UPDATE_CASUALTY_FORM } from "../../../../../../store/CasualtyCreator/actionType";
import { FormRow } from "../../styled";
import FloatingLabelInput from "../../../../../../components/FloatingLabel/FloatingLabelInput";
type Props = {
  formik: any;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
  disabled: boolean;
};

const AgeInput = ({
  formik,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
  disabled,
}: Props) => {
  const dispatch = useDispatch();

  const handleAgeChange = (value: number) => {
    if (disabled) return; //disable action callback in read only script
    dispatch({
      type: CASUALTY_CREATOR_UPDATE_CASUALTY_FORM,
      field: "Age",
      value,
    });
  };
  const displayMessage = () => {
    if (disabled) return; //disable action callback in read only script
    if (formik.errors[`age`] && formik.touched[`age`])
      return <span className="error-msg pl-2">{formik.errors[`age`]}</span>;
    else {
      return <span className="pl-2">(18-65)</span>;
    } //adding arrow function to replace text input
  };

  return (
    <FormRow>
      <div className="age-input">
        <FloatingLabelInput
          label={"Age*"}
          nameForInput={"age"}
          maxValue={99}
          minValue={0}
          maxLength={2}
          suffixIcon={"yrs"}
          value={formik.values["age"]}
          onChange={(e: any) => handleAgeChange(e.target.value)}
          scriptFormik={formik}
          setIsFocused={setIsFocused}
          isFocused={isFocused}
          lastFocused={lastFocused}
          setLastFocused={setLastFocused}
          isAddNeeded={true}
          step={1}
          displayMinMaxRange={false}
          displayErrorMsg={false}
          precision={0}
          isNumber
          disabled={disabled}
        />
      </div>

      {displayMessage()}
    </FormRow>
  );
};

export default AgeInput;
