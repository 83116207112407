import * as React from "react";
import { useDispatch } from "react-redux";
import FloatingLabelInput from "../../../../../../components/FloatingLabel/FloatingLabelInput";
import { CASUALTY_CREATOR_UPDATE_CASUALTY_FORM } from "../../../../../../store/CasualtyCreator/actionType";
import { FormRow } from "../../styled";
import { convertKgToPound } from "../CasualtyFormHelperFunctions";
type Props = {
  formik: any;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
  disabled: boolean;
};

const WeightInput = ({
  formik,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
  disabled,
}: Props) => {
  const dispatch = useDispatch();
  const handleWeightChange = (value: number) => {
    if (disabled) return; //disable action callback in read only script
    dispatch({
      type: CASUALTY_CREATOR_UPDATE_CASUALTY_FORM,
      field: "Weight",
      value,
    });
  };
  const displayMessage = () => {
    if (formik.errors[`weight`] && formik.touched[`weight`]) {
      return <span className="error-msg pl-2">{formik.errors[`weight`]}</span>;
    } else if (formik.values["weight"]) {
      return (
        <span className="pl-2">
          ({convertKgToPound(formik.values["weight"])} lbs)
        </span>
      );
    } else {
      return null;
    }
  };
  return (
    <FormRow>
      <div className="weight-input">
        <FloatingLabelInput
          label={"Weight*"}
          nameForInput={"weight"}
          maxValue={140}
          minValue={0}
          maxLength={3}
          suffixIcon={"kg"}
          value={formik.values["weight"]}
          onChange={(e: any) => handleWeightChange(e.target.value)}
          scriptFormik={formik}
          setIsFocused={setIsFocused}
          isFocused={isFocused}
          lastFocused={lastFocused}
          setLastFocused={setLastFocused}
          isAddNeeded={true}
          step={1}
          displayMinMaxRange={false}
          displayErrorMsg={false}
          precision={0}
          isNumber
          disabled={disabled}
        />
      </div>

      {displayMessage()}
    </FormRow>
  );
};

export default WeightInput;
