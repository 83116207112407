import React, { useEffect, useRef } from "react";
import {
  OpenErrorNotification,
  OpenWarningNotification,
} from "../../../../components/Notification/Notification";
import { Script } from "../../../../models/ScriptInterfaces";
import { createScript, updateScript } from "../../../../api/Script/ApiPost";
import { TitleInput } from "../EditForms/PhaseForm/TextArea.styled";
import {
  HeaderContainer,
  NavigationTitle,
  StyledEditTitleInput,
  StyledScriptAction,
} from "../../../../components/GlobalStyledComponents.styled";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Casualty } from "../../../../models/CasualtyInterfaces";
import { getCurrentDate } from "../../../../utils/dateUtils";
import { FormikProps } from "formik";
import { ScriptFormValues } from "./ScriptView/ScriptView";

interface Props {
  scriptData?: any;
  isReadOnly: boolean;
  scriptTitle: string;
  setScriptTitle: (scriptTitle: string) => void;
  scriptFormik: any;
  postCreateSuccess: any;
  postSuccess: any;
  postError: any;
  displayGoToScript: boolean;
}
export const createScriptObject = (
  allCasualty: Casualty[],
  scriptData: any,
  scriptFormik: FormikProps<ScriptFormValues>,
  casualty?: Casualty,
) => {
  const currentCasualtyId =
    casualty && casualty.Id ? casualty.Id : scriptFormik.values.casualtyId || 0;
  const currentCasualty =
    casualty && casualty.Id
      ? casualty
      : allCasualty.find((x: Casualty) => x.Id === currentCasualtyId);

  const scenarioObject: Script = {
    Id: scriptFormik.values.id,
    Title: scriptFormik.values.title || "New Script",
    Description: scriptFormik.values.description,
    CreatedDate: getCurrentDate(),
    UpdatedDate: getCurrentDate(),
    Public: scriptFormik.values.public,
    IsSeed: scriptFormik.values.isSeed,
    Clonable: scriptFormik.values.clonable,
    CasualtyId: currentCasualtyId,
    Casualty: currentCasualty!,
    IsDraft: scriptFormik.values.casualtyId
      ? scriptData?.IsDraft || false
      : true,
    IsArchived: scriptFormik.values.IsArchived,
  };
  return scenarioObject;
};
export default function ScriptTitleHeader({
  scriptData,
  scriptTitle,
  isReadOnly,
  setScriptTitle,
  scriptFormik,
  postCreateSuccess,
  postError,
  postSuccess,
  displayGoToScript,
}: Props) {
  const inlineBlockRef = useRef<any>(null);
  // To display script title or enable editing mode
  const [enableScriptTitleEdit, setEnableScriptTitleEdit] =
    React.useState(false);

  const handleScriptTitleEditing = () => {
    if (isReadOnly) return; //disable inline editing in read only script
    setEnableScriptTitleEdit(!enableScriptTitleEdit);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("hab");
    e.preventDefault();
    const title = e.target.value;
    scriptFormik.setFieldValue("title", title);
    setScriptTitle(title);
  };
  const displayErrorMessage = (e: any) => {
    OpenErrorNotification({
      description: e,
    });
  };
  const updateScriptTitle = () => {
    const scenarioObject: Script = createScriptObject(
      [],
      scriptData,
      scriptFormik,
    );
    if (scenarioObject.Id !== 0) {
      updateScript(
        scenarioObject,
        () => {
          setEnableScriptTitleEdit(false);
        },
        displayErrorMessage,
      );
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        event?.target &&
        inlineBlockRef &&
        !inlineBlockRef?.current?.contains(event.target)
      ) {
        setEnableScriptTitleEdit(false);
      }
    };
    document.addEventListener("click", (e: any) => handleClickOutside(e), true);
    return () => {
      document.removeEventListener(
        "click",
        (e: any) => handleClickOutside(e),
        true,
      );
    };
  }, []);
  const createNewScript = (e: any, obj?: Script, redirectOrReload = true) => {
    if (scriptFormik.values.id === 0) {
      const scenarioObject: Script = createScriptObject(
        [],
        scriptData,
        scriptFormik,
      );
      if (scenarioObject.Title.length) {
        //Create the scenario in Database
        createScript(
          scenarioObject,
          (data: Script) => postCreateSuccess(data, redirectOrReload),
          postError,
        );
      } else {
        OpenWarningNotification({ description: "Please enter script title" });
      }
    } else {
      let scenarioObject: Script = createScriptObject(
        [],
        scriptData,
        scriptFormik,
      );
      if (obj) scenarioObject = obj;
      updateScript(
        scenarioObject,
        (data: Script) => postSuccess(data, redirectOrReload),
        displayErrorMessage,
      );
    }
  };
  return (
    <NavigationTitle className="script-library-title cursor-pointer text-transform-none">
      <HeaderContainer>
        {isReadOnly ? (
          <span className="title">{scriptTitle}</span>
        ) : (
          <span
            className={`script-title ${
              enableScriptTitleEdit ? "withInput" : ""
            }`}
          >
            <>
              <span
                className={`${enableScriptTitleEdit ? "hide-title" : "title"} ${
                  !displayGoToScript ? "hideGoToScript" : ""
                }`}
                onClick={handleScriptTitleEditing}
              >
                {scriptTitle || "New Script"}
              </span>
              {enableScriptTitleEdit && (
                <StyledEditTitleInput
                  className={
                    enableScriptTitleEdit ? "edit-title" : "hide-edit-title"
                  }
                  ref={inlineBlockRef}
                >
                  <TitleInput
                    className="edit-title-input"
                    value={scriptTitle || ""}
                    placeholder="New Script"
                    autoFocus
                    maxLength={70}
                    onChange={handleInputChange}
                    onBlur={() => updateScriptTitle()}
                    onKeyDown={(e: any) => {
                      if (e.key == "Enter") {
                        e.preventDefault();
                        updateScriptTitle();
                      }
                    }}
                  />
                </StyledEditTitleInput>
              )}
            </>
          </span>
        )}
        {displayGoToScript ? (
          <StyledScriptAction
            className="on-hover cursor-pointer"
            onClick={createNewScript}
          >
            <ArrowRightOutlined className="cursor-pointer arrow-right-anticon" />
            <span className="navigate-to-scriptbuilder">Go to Script</span>
          </StyledScriptAction>
        ) : null}
      </HeaderContainer>
    </NavigationTitle>
  );
}
