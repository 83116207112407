import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";

export const StyledWrapper = styled.div.attrs((props: any) => ({
  errors: props.errors,
  isFocused: props.isFocused,
  lastFocused: props.lastFocused,
  inputName: props.inputName,
  value: props.value,
  nameOfInput: props.nameOfInput,
}))`
  color: ${colorPalette.white};
  position: relative;
  padding-top: 22px;
  .label {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    color: ${colorPalette.gray25};

    &.floatingLabel {
      font-size: 1.25rem;
      top: 30px;
      left: 10px;
    }
  }

  &:hover {
    .label.floatingLabel,
    input::placeholder {
      color: #fff;
    }
  }

  .ant-picker {
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none !important;
    background-color: transparent;
    border-bottom: 1px solid ${colorPalette.gray25};

    &:focus {
      background-color: rgba(255, 255, 255, 0.05);
    }
    &:hover {
      border-bottom: 1px solid ${colorPalette.white};
      .ant-input-suffix,
      .ant-picker-clear {
        color: ${colorPalette.white};
      }
    }

    .ant-picker-input {
      .ant-picker-suffix,
      .ant-picker-clear {
        color: ${colorPalette.gray25};
      }
    }
    &:focus,
    &.ant-picker-focused {
      border-bottom: 1px solid ${colorPalette.primary100};
      & > .ant-picker-input {
        .ant-input-suffix,
        .ant-picker-clear {
          color: ${colorPalette.primary100};
        }
      }
    }
    &.ant-picker-disabled {
      border-bottom: 1px solid rgb(54, 54, 54) !important;
    }
  }

  .ant-picker-input {
    border-radius: 0;
    box-shadow: none !important;
    background-color: transparent;
    padding: 4px 1px !important;
    color: #fff;
    input {
      font-size: 1.25rem;
      color: #fff;
      background-color: transparent;
    }

    &.ant-picker-input-disabled {
      input[disabled] {
        color: rgba(255, 255, 255, 0.5) !important;
      }

      .ant-picker-suffix,
      .ant-picker-clear {
        color: rgb(54, 54, 54) !important;
      }
    }
  }
  .ant-picker-disabled .ant-picker-input .ant-picker-suffix,
  .ant-picker-disabled .ant-picker-input .ant-picker-clear {
    color: rgb(54, 54, 54) !important;
  }
  .ant-picker-input-focused {
    background-color: rgba(255, 255, 255, 0.05);
    border-bottom: 1px solid ${colorPalette.primary100};
    .ant-input-suffix,
    .ant-picker-clear {
      color: ${colorPalette.primary100};
    }
  }
`;
