import React, { useEffect, useState } from "react";
import Autocomplete, {
  AutocompleteOption as AutoCompleteOption,
} from "../../../../components/Autocomplete/Autocomplete";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  SetIsEditingAllowed,
  SetIsFocused,
  SetLastFocused,
} from "./ResearchQuestionSlice";
import { ResearchQuestionBlock } from "./ResearchQuestionEdit";
import { AssignMasterResearchQuestionToResearchQuestionBlock } from "../../../../api/ResearchQuestionBlock/ApiPost";
import { SetPhases } from "../../MainlistSlice";
import produce from "immer";
import { Phase } from "../../../../models/ScriptInterfaces";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";

interface ResearchQuestionSearchAddProps {
  formik: any;
  disabled: boolean;
  object: ResearchQuestionBlock;
  checkIsMaster: Function;
  setIsMasterRQ: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: Function;
}
type RQOptionType = { value: string; label: string };
const ResearchQuestionSearchAdd = ({
  formik,
  disabled,
  object,
  checkIsMaster,
  setIsMasterRQ,
  onChange,
}: ResearchQuestionSearchAddProps) => {
  const dispatch = useAppDispatch();

  const {
    isFocused,
    lastFocused,
    researchQuestionSearchOptions,
    isEditingAllowed,
  } = useAppSelector((state) => state.ResearchQuestionReducer);

  const { phases, currentPhaseIndex, currentSectionIndex, currentBlockIndex } =
    useAppSelector((state) => state.MainlistReducer);

  const [options, setOptions] = useState<RQOptionType[]>([]);
  const [value, setValue] = useState<string>("");

  React.useEffect(() => {
    setValue(object?.BlockContent?.Question);
    checkIsMaster(object?.BlockContent?.Question);
  }, [object]);

  useEffect(() => {
    setOptions(researchQuestionSearchOptions);
    checkIsMaster(object?.BlockContent?.Question);
  }, []);

  const handleInputSearch = (e: any) => {
    if (disabled) return; //disable action callback in read only script
    formik.handleChange(e);
    setValue(e.target.value);
  };

  const onSelect = (value: string) => {
    if (disabled) return; //disable action callback in read only script
    const selectedResearchQuestionSearchOption =
      researchQuestionSearchOptions.find(
        (x: AutoCompleteOption) => x.value == value,
      );

    // Assign master research question to current block.
    if (selectedResearchQuestionSearchOption) {
      AssignMasterResearchQuestionToResearchQuestionBlock(
        {
          blockId: object.Id,
          researchQuestionId: object.BlockContent.Id,
          masterResearchQuestionId: selectedResearchQuestionSearchOption.value,
        },
        (data: any) => {
          // set edit reserch question form values.
          setValue(selectedResearchQuestionSearchOption.label || "");
          formik.setFieldValue("additionalDetails", data?.Description || "");
          formik.setFieldValue("description", data?.AdditionalDetails || "");
          // Update redux state.
          const newPhases = produce(phases, (draftPhases: Phase[]) => {
            draftPhases![currentPhaseIndex].Sections![
              currentSectionIndex
            ].Blocks![currentBlockIndex].BlockContent = data;
          });
          dispatch(SetPhases(newPhases));
          dispatch(SetIsEditingAllowed(false));
          setIsMasterRQ(true);
        },
        (errorMessage: string) => {
          OpenErrorNotification({ description: errorMessage });
        },
      );
    }
  };

  const handleSearch = (value: string) => {
    if (disabled) return; //disable action callback in read only script
    let res: { value: string; label: string }[] = [];
    if (!value || !value.trim()) {
      res = [];
    } else {
      res = researchQuestionSearchOptions
        .filter(
          (researchQuestionSearchOption: AutoCompleteOption) =>
            researchQuestionSearchOption.label
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1,
        )
        .map((researchQuestionSearchOption: AutoCompleteOption) => ({
          key: researchQuestionSearchOption.value,
          value: researchQuestionSearchOption.value,
          label: researchQuestionSearchOption.label,
        }));
    }
    setOptions(res);
    checkIsMaster(value);
    // Update
    isEditingAllowed && onChange("Question", value);
  };

  return (
    <Autocomplete
      value={value}
      scriptFormik={formik}
      handleSearch={handleSearch}
      handleInputSearch={handleInputSearch}
      onSelect={onSelect}
      options={options}
      isFocused={isFocused}
      lastFocused={lastFocused}
      setIsFocused={SetIsFocused}
      setLastFocused={SetLastFocused}
      disabled={disabled}
    />
  );
};

export default ResearchQuestionSearchAdd;
