import * as React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

interface Props {
  history: {};
  location: {};
  match: {};
  staticContext: {};
  isAuthenticated: boolean;
}

export default function (ComposedComponent: any) {
  class Authenticate extends Component<Props, {}> {
    componentDidUpdate(nextProps: any) {
      if (!nextProps.isAuthenticated) {
        return <Redirect to="/" />;
      }
    }

    render() {
      if (!this.props.isAuthenticated) {
        return <Redirect to="/" />;
      } else {
        return <ComposedComponent {...this.props} />;
      }
    }

    public static propTypes = {
      isAuthenticated: PropTypes.bool.isRequired,
    };
  }

  Authenticate.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
  };

  function mapStateToProps(state: any) {
    return {
      isAuthenticated: state.authReducer.isAuthenticated,
    };
  }

  return connect(mapStateToProps)(Authenticate);
}
