import styled from "styled-components";
import { Layout } from "antd";
import { GrayColorPalette } from "../styles/MainList.styled";

interface I_Props {
  displayProfileMenuProp?: boolean;
}

const { Header } = Layout;

export const StyledHeader = styled(Header)`
  padding: 0;
  background: ${GrayColorPalette.Gray7};
`;

export const ProfileMenuDiv = styled.div<I_Props>`
  position: absolute;
  display: ${(props) => (props.displayProfileMenuProp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  right: 0rem;
  top: 68px;
  padding: 2rem;
  background: #141414;
  z-index: 300;
`;

export const Profile = styled.div`
  .avatar {
    img {
      height: auto;
      width: 100%;
    }
  }
`;

export const Seperator = styled.div`
  border: 1px solid #262626;
  width: 100%;
  height: 1px;
`;
