import { useFormik } from "formik";
import React, { useState } from "react";
import FloatingLabelTextArea from "../FloatingLabel/FloatingLabelTextArea";
import { StyledWrapper } from "./InlineEditTextArea.styled";
interface Props {
  uniqueId: string;
  onChange: any;
  value: string;
  wrapperClassName?: string;
  isReadOnly?: boolean;
}
export default function InlineEditTextArea(props: Props) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const focusedStates = {
    [props.uniqueId]: false,
  };
  const [isFocused, setIsFocused] = useState(focusedStates);
  const [lastFocused, setLastFocused] = useState(focusedStates);

  const scriptFormik = useFormik({
    initialValues: {
      [props.uniqueId]: props.value || "",
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const editValue = () => {
    setIsEditing(true);
    setTimeout(() => {
      document.getElementById(`inline-editing-${[props.uniqueId]}`)?.focus();
    }, 100);
  };
  const onClickInlineEdit = () => {
    !props.isReadOnly && !isEditing && editValue();
  };
  const handleChange = (e: any) => {
    props.onChange(props.uniqueId, e.target.value);
    scriptFormik.setFieldValue(props.uniqueId, e.target.value);
  };
  return (
    <>
      <StyledWrapper
        className={
          isEditing
            ? `inline-wrapper ${props.wrapperClassName}`
            : `inline-display ${props.wrapperClassName}`
        }
        onClick={onClickInlineEdit}
      >
        {isEditing ? (
          <FloatingLabelTextArea
            classNameForContainer="inline-editing"
            label={""}
            textareaName={`inline-editing-${[props.uniqueId]}`}
            scriptFormik={scriptFormik}
            value={scriptFormik.values[props.uniqueId]}
            onSave={scriptFormik.handleBlur}
            onChange={handleChange}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            lastFocused={lastFocused}
            isFocused={isFocused}
            enableLiveCount={false}
            onBlur={() => setIsEditing(false)}
          />
        ) : (
          props.value || ""
        )}
      </StyledWrapper>
    </>
  );
}
