import { PlusOutlined } from "@ant-design/icons";
import * as React from "react";
import { WhiteDottedButton } from "../InjuryTab.styled";

type CreateNewInsultInjuryBtnProps = {
  label: string;
  onAddClick: Function;
};

const CreateNewInsultInjuryBtn = ({
  onAddClick,
  label,
}: CreateNewInsultInjuryBtnProps) => {
  const onAddNewClick = () => {
    onAddClick();
  };
  return (
    <WhiteDottedButton
      type="dashed"
      icon={<PlusOutlined />}
      shape="round"
      size="large"
      onClick={onAddNewClick}
      style={{ margin: "5px 10px 10px" }}
    >
      {label}
    </WhiteDottedButton>
  );
};

export default CreateNewInsultInjuryBtn;
