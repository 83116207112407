import axios from "axios";
import { Script } from "../../models/ScriptInterfaces";

export function createScript(
  script: Script,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/script/CreateScriptWithPrepopulatedSectionAndPhase`, {
      Public: script.Public,
      Title: script.Title,
      Description: script.Description,
      Clonable: true,
      CasualtyId: script.CasualtyId,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function updateScript(
  filename: any,
  callback: Function,
  errorcallback: Function,
) {
  console.log(filename);
  axios
    .post(`/api/1/pfc/script/update`, filename)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        const errorMsg = err?.response?.data?.ErrorMessage || err.message;
        errorcallback(errorMsg);
      }
    });
}
export function forkScript(
  scriptId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/Script/${scriptId}/fork`, null)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}
export function deleteScript(
  id: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`/api/1/pfc/script/delete/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
