import { ManOutlined, WomanOutlined } from "@ant-design/icons";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Sex } from "../../../../../../models/Enums";
import { CASUALTY_CREATOR_UPDATE_CASUALTY_FORM } from "../../../../../../store/CasualtyCreator/actionType";
import { FormRow } from "../../styled";
import { GenderButton } from "../styledComponents/GenderButton.styled";

type Props = {
  formik: any;
  disabled: boolean;
};

const GenderInput = ({ formik, disabled }: Props) => {
  const dispatch = useDispatch();

  const handleClickGender = (value: number) => {
    if (disabled) return; //disable action callback in read only script
    formik.setFieldValue("gender", value);
    dispatch({
      type: CASUALTY_CREATOR_UPDATE_CASUALTY_FORM,
      field: "Gender",
      value,
    });
  };

  return (
    <FormRow>
      {formik.errors["gender"]}
      <GenderButton
        size="large"
        className={`${formik.values.gender === Sex.Male ? "selected" : ""}`}
        onClick={() => handleClickGender(Sex.Male)}
        disabled={disabled}
      >
        <ManOutlined /> Male
      </GenderButton>{" "}
      <GenderButton
        size="large"
        className={`ml-2 ${
          formik.values.gender === Sex.Female ? "selected" : ""
        }`}
        onClick={() => handleClickGender(Sex.Female)}
        disabled={disabled}
      >
        <WomanOutlined /> Female
      </GenderButton>
    </FormRow>
  );
};

export default GenderInput;
