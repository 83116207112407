import * as React from "react";
import { StyledStarsFilled, StyledStarsOutlined } from "./Stars.styled";

interface StarRatingProps {
  rating: number;
}

const Stars = (props: StarRatingProps) => {
  const { rating } = props;
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating - fullStars >= 0.5;

  const stars = [];
  const remainingStars = 5 - rating;
  for (let i = 0; i < fullStars; i++) {
    stars.push(<StyledStarsFilled key={i} />);
  }

  if (remainingStars > 0) {
    for (let i = 0; i < remainingStars; i++) {
      stars.push(<StyledStarsOutlined key={fullStars} />);
    }
  }

  return <div className="star-icons">{stars}</div>;
};

export default Stars;
