import axios from "axios";

// Create equipment item
export function CreateEquipment(
  data: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/equipment/create`, data)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function UpdateEquipment(
  data: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/equipment/update`, data)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function DeleteEquipment(
  id: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`/api/1/pfc/equipment/deleteEquipment/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
