import React, { MouseEventHandler } from "react";
import { Col } from "antd";
import { InsultWithRelation } from "../../../../../../../models/InsultInterface";

type InsultLocationProps = {
  insult: InsultWithRelation;
  onEditInsult: MouseEventHandler;
};

const InsultLocation = ({ insult, onEditInsult }: InsultLocationProps) => {
  return (
    <>
      <Col className="location-param" onClick={onEditInsult}>
        <div>
          <label>Location</label>
          <span className="text-primary">
            {insult?.RenderLocationAs || "-"}
          </span>
        </div>
      </Col>
    </>
  );
};

export default InsultLocation;
