import { CloseOutlined } from "@ant-design/icons";
import { Modal, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabCloseButton } from "../../../../../components";
import {
  INSULT_INJURY_RESET_INJURY_OBJ,
  INSULT_INJURY_RESET_SCREEN_VISIT_HISTORY,
  INSULT_INJURY_RESET_SELECTED_INSULT_OBJ,
  INSULT_INJURY_SET_CURRENT_VIEW,
} from "../../../../../store/CasualtyCreator/InsultInjury/actionType";
import {
  InjuryViewTypes,
  LeftPaneCasualtyCreatorViewTypes,
  LeftPaneViewTypes,
} from "../constants";
import ConfirmDialog from "../../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../../components/ConfirmDialog/useConfirmDialog";

interface Props {
  handleCloseCasualtyForm: Function;
  updateCustomHeading: Function;
  leftPaneView: string;
  currentCasualtyCreatorTab: string;
}
export default function TabCloseIconButton({
  leftPaneView,
  currentCasualtyCreatorTab,
  updateCustomHeading,
  handleCloseCasualtyForm,
}: Props) {
  const dispatch = useDispatch();
  const insultInjuryTabView = useSelector(
    (state: any) => state.InsultInjury.CurrentView
  );
  const injuryObj = useSelector((state: any) => state.InsultInjury.InjuryObj); // injury for create/edit
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const handleInjuryClose = () => {
    if (injuryObj.Insults.length > 0 || injuryObj.Id === 0) {
      // update title and change view in injury tab using redux
      handleViewChange();
      // reset data when change tab
      resetStateData();
    } else {
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "Injury",
        content:
          "Injury without Insult will not effect anything. Are you still want to continue.",
        okText: "Yes",
        onOk: () => {
          // update title and change view in injury tab using redux
          handleViewChange();
          // reset data when change tab
          resetStateData();
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "warning",
      });
    }
  };

  const handleViewChange = () => {
    const view = InjuryViewTypes.CASUALTY_INJURIES;
    dispatch({ type: INSULT_INJURY_RESET_SCREEN_VISIT_HISTORY });
    updateCustomHeading(false, "");
    dispatch({ type: INSULT_INJURY_SET_CURRENT_VIEW, view });
  };
  const resetStateData = () => {
    dispatch({ type: INSULT_INJURY_RESET_SELECTED_INSULT_OBJ });
    dispatch({ type: INSULT_INJURY_RESET_INJURY_OBJ });
  };

  const handleClose = () => {
    if (leftPaneView !== LeftPaneViewTypes.CASUALTY_FORM) return;
    // call parent close function for casualty form and applied insult injury tab
    if (
      currentCasualtyCreatorTab !==
        LeftPaneCasualtyCreatorViewTypes.CASUALTY_INSULT_INJURY ||
      insultInjuryTabView === InjuryViewTypes.CASUALTY_INJURIES
    ) {
      handleCloseCasualtyForm();
      return;
    }
    handleInjuryClose();
  };
  return (
    <>
      <Tooltip
        title={
          currentCasualtyCreatorTab !==
            LeftPaneCasualtyCreatorViewTypes.CASUALTY_INSULT_INJURY ||
          insultInjuryTabView === InjuryViewTypes.CASUALTY_INJURIES
            ? `Close Casualty Builder`
            : `Close`
        }
      >
        <TabCloseButton onClick={handleClose}>
          <CloseOutlined />
        </TabCloseButton>
      </Tooltip>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </>
  );
}
