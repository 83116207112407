import produce from "immer";
import React, { useState } from "react";
import { PaneBody } from "../../../../../components/GlobalStyledComponents.styled";
import { Phase } from "../../../../../models/ScriptInterfaces";
import { useAppSelector } from "../../../../../store/hooks";
import {
  GreenSolidButton,
  GreySolidButton,
} from "../../../../../styles/Buttons.styled";
import { BlueCheckboxWithoutCheckedIcon } from "../../../../../styles/Checkbox.styled";
import { isNullOrUndefined } from "../../../../../utils/utilityfunctions";
import {
  StyledListTitleItem,
  TitleSpan,
  Ul,
} from "../../ShowList/ShowList.styled";
import { SectionCheckboxWrapper } from "./Section.EditForm.styled";
interface Props {
  currentPhaseId: number;
  sectionId: number;
  onCopySection: Function;
}
export default function SectionCopyMovePanel({
  currentPhaseId,
  sectionId,
  onCopySection,
}: Props) {
  const state = useAppSelector((state: any) => state.MainlistReducer);
  const phases: Phase[] = state.phases;
  const [selectedPhases, setSelectedPhases] = useState<number[]>([]);
  const handleSave = () => {
    const payload = {
      currentPhaseId: currentPhaseId,
      sectionId: sectionId,
      phaseIds: selectedPhases,
      isActionForMove: true,
    };
    onCopySection(payload, true);
  };

  const updatePhaseSelection = (phaseId: number | undefined) => {
    if (!phaseId) return;
    setSelectedPhases(
      produce((draft: any) => {
        const index = draft.findIndex((i: number) => i === phaseId);
        if (index === -1) {
          draft.push(phaseId);
        } else {
          draft.splice(index, 1);
        }
      })
    );
  };
  return (
    <>
      <PaneBody style={{ height: "calc(100vh - 170px)" }}>
        <Ul>
          {!isNullOrUndefined(phases)
            ? phases.map((phase: Phase, phaseMapIndex: number) => (
                <li key={phase.Id}>
                  <SectionCheckboxWrapper>
                    <BlueCheckboxWithoutCheckedIcon
                      checked={
                        selectedPhases.findIndex((i) => i === phase.Id) !== -1
                      }
                      onClick={() => updatePhaseSelection(phase.Id)}
                    >
                      {phaseMapIndex + 1} &nbsp;- &nbsp;
                      {phase.TitleAbbreviated !== ""
                        ? phase.TitleAbbreviated
                        : `Phase ${phase.Order}`}
                    </BlueCheckboxWithoutCheckedIcon>
                  </SectionCheckboxWrapper>
                </li>
              ))
            : null}
        </Ul>
      </PaneBody>
      <div className="text-center">
        {!selectedPhases.length ? (
          <GreySolidButton
            rounded="true"
            className="disable-btn"
            style={{ width: 180 }}
          >
            Save
          </GreySolidButton>
        ) : (
          <GreenSolidButton rounded onClick={handleSave} style={{ width: 180 }}>
            Save
          </GreenSolidButton>
        )}
      </div>
    </>
  );
}
