import * as React from "react";
import { Phase } from "../../../models/ScriptInterfaces";
import { isNullOrUndefined } from "../../../utils/utilityfunctions";
import "../../../styles/phaseEdit.css";
import {
  Container,
  FixedDiv,
  FixedPhaseDiv,
  ScrollablePhaseDiv,
} from "../../../styles/EditComponentTheme.styled";
import PhaseForm from "./EditForms/PhaseForm/PhaseForm";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import PhaseReorderPanel from "./EditForms/PhaseForm/PhaseReorder";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";
import { IconColumn } from "./IconColumn";
import produce from "immer";
import { v4 as uuidv4 } from "uuid";
import { UpdatePhaseOrder, DeletePhase } from "../../../api/Phases/ApiPost";
import {
  SetCurrentPhaseIndex,
  SetCurrentPhaseId,
  SetActiveKey,
  SetCurrentSectionIndex,
  SetCurrentBlockIndex,
  SetActiveComponent,
  ComponentEdit,
  SetAddBlockShowingId,
  SetPhases,
  populatePhaseWithTreatment,
} from "../MainlistSlice";

interface Props {
  loadingToggle: Function;
  postSuccess: Function;
  postError: Function;
}

const PhaseEdit = (props: Props) => {
  const FirstUUID = uuidv4();
  const dispatch = useAppDispatch();

  const { phases, isDeletePhaseBtnDisabled, currentPhaseIndex, ...state } =
    useAppSelector((state) => state.MainlistReducer);

  const [isDisplayReorderPanel, setIsDisplayReorderPanel] =
    React.useState(false);

  const onPhaseDeleteSuccess = (phaseUUID: string) => (data: any) => {
    const blockIndex =
      phases && phases.findIndex((x: Phase) => x.UUID === phaseUUID);
    const newPhases = produce(phases, (draftPhases) => {
      draftPhases.splice(blockIndex, 1);
      for (let index = 0; index < draftPhases.length; index++) {
        draftPhases[index].Order = index + 1;
      }
    });
    let uuidToSetAsActive = FirstUUID;
    let selectedPhaseId = 0;
    if (newPhases?.length > 0) {
      uuidToSetAsActive = newPhases[0].UUID ?? FirstUUID;
      selectedPhaseId = newPhases[0].Id ?? 0;
    }
    dispatch(SetCurrentPhaseIndex(0));
    dispatch(SetCurrentPhaseId(selectedPhaseId));
    dispatch(SetActiveKey(uuidToSetAsActive));
    dispatch(SetCurrentSectionIndex(0));
    dispatch(SetCurrentBlockIndex(0));
    dispatch(SetActiveComponent(ComponentEdit.PHASE));
    dispatch(SetAddBlockShowingId("")); // to enable add block tray to add new blocks
    dispatch(SetPhases(newPhases));
    UpdatePhaseOrder(newPhases, props.postSuccess, props.postError);

    populatePhaseWithTreatment(newPhases, dispatch, state);
  };

  const onPhaseDelete = (phase: Phase) => {
    phase?.Id &&
      DeletePhase(phase.Id, onPhaseDeleteSuccess(phase.UUID), props.postError);
  };

  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const onDeleteConfirm = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "Are you sure delete this Phase?",
      content: "This cannot be undone",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        onPhaseDelete(phases[currentPhaseIndex]);
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        console.log("Cancel");
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };

  const openReorderPhaseModal = () => {
    console.log("openReorderPhaseModal");
    setIsDisplayReorderPanel(true);
  };

  const hideReorderPhasePane = () => {
    setIsDisplayReorderPanel(false);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {!isNullOrUndefined(phases[currentPhaseIndex]) ? (
        <Container key={`phase_${currentPhaseIndex}`}>
          {!isDisplayReorderPanel ? (
            <>
              <FixedPhaseDiv>
                <IconColumn
                  isDeleteBtnDisabled={isDeletePhaseBtnDisabled}
                  type="Phase"
                  onDeleteClick={onDeleteConfirm}
                  reorderBtnAvailable={true}
                  onReorderClick={openReorderPhaseModal}
                />
              </FixedPhaseDiv>
              <ScrollablePhaseDiv id="scrollableDiv">
                <PhaseForm
                  loadingToggle={props.loadingToggle}
                  postSuccess={props.postSuccess}
                  postError={props.postError}
                />
              </ScrollablePhaseDiv>
            </>
          ) : (
            <>
              <FixedDiv phase={false}>
                <IconColumn
                  type="Reorder Phase"
                  displayCancelBtn={true}
                  onCancelClick={hideReorderPhasePane}
                />
              </FixedDiv>
              <PhaseReorderPanel hideReorderPhasePane={hideReorderPhasePane} />
            </>
          )}
          <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
        </Container>
      ) : null}
    </div>
  );
};

export default PhaseEdit;
