import styled from "styled-components";
import { colorPalette } from "./MainList.styled";

interface scrollPhase {
  phase?: boolean;
  typeRequired?: boolean;
  typeOfEditPanel?: string;
  medications?: number;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border-top: 0 !important;
  background: #161616;
  position: relative;
`;

export const IconDiv = styled.div`
  width: 100%;
  height: auto;
  border-bottom: 5px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
  }

  .treatmentIcon {
    fill: rgba(255, 255, 255, 1);
  }

  .blockNameAndIcon {
    padding: 0.5rem 0.625rem;
    text-transform: uppercase;
    // width: clamp(20px, 10%, 30px);
  }

  .icon {
    font-size: 1.8rem;
    margin-right: 0.5rem;
    color: #ffffff;
  }

  .iconText {
    height: 1.8rem;
    width: 1.8rem;
    margin-right: 0.5rem;
  }

  .text {
    font-size: 1.2rem;
    color: #ffffff;
  }

  .iconCD {
    font-size: 22px;
    color: grey;
    cursor: pointer;
  }

  //.iconsDiv button {
  //  width: 40px;
  //}

  .iconsDiv .deleteSpan,
  .deleteBtn {
    display: flex;
    justify-content: center;
    // padding: 0.625rem;
    padding: clamp(0.2vw, 0.48828125vw, 0.625rem);
  }

  .deleteSpan .anticon-arrows-alt,
  .deleteBtn .anticon-arrows-alt {
    transform: rotate(-136deg);
  }

  .deleteSpan {
    &:hover {
      cursor: pointer;
      .anticon-copy,
      .anticon-upload,
      .anticon-arrows-alt,
      .anticon-close {
        color: #ffffff !important;
      }
      .anticon-delete {
        color: #ff4242 !important;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    .iconsDiv {
      justify-content: space-between;
      //width: 11%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    .iconsDiv {
      justify-content: space-between;
      //width: 13%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xxl}) {
    .iconsDiv {
      //width: 17%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xl}) {
    .iconsDiv {
      //width: 19%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    .iconsDiv {
      //width: 24%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    .iconsDiv {
      //width: 28%;
    }

    .iconCD {
      font-size: 1.25rem;
    }

    .icon {
      font-size: 1rem;
    }

    .text {
      font-size: 0.8125rem;
    }

    .iconText {
      transform: scale(0.8);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    .iconsDiv {
      //width: 31%;
    }
  }
`;

export const TitleColumnDiv = styled.div`
  height: auto;
  background: #262626;
  display: flex;
  flex-direction: column;
  margin: 9px 11px;
  padding: 6px 15px;

  .position_div,
  .display_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .position_div {
    color: ${colorPalette.primary100};

    span {
      font-size: 0.8125rem;
    }
  }

  .display_title {
    color: white;

    span {
      font-size: 1.25rem;
      font-style: bold;
    }
  }

  .title_span {
    max-width: 70%;
    word-wrap: break-word;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    .display_title {
      span {
        font-size: 0.9375rem;
      }
    }

    .title_span {
      line-height: 24pt;
      word-spacing: 3px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    .position_div {
      span {
        font-size: 0.625rem;
      }
    }

    .display_title {
      span {
        font-size: 0.75 m;
      }
    }
  }
`;

export const ListMenuColumnDiv = styled.div`
  width: 100%;
  height: auto;
  padding: 0.625rem;
  margin-bottom: 0.1875rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ListDiv = styled.div`
  width: 40%;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    width: 50%;
  }
`;

export const MenuIconDiv = styled.div.attrs((props: any) => ({
  currentColumnDisplay: props.currentColumnDisplay,
  blockContentOrder: props.blockContentOrder,
}))`
  width: 50%;
  display: flex;
  flex-direction: row;
  height: auto;
  align-items: center;

  button {
    font-style: bold;
    cursor: pointer;
  }

  .importantTextBlockToggle {
    display: flex;

    &:hover {
      cursor: pointer;

      span {
        color: rgba(255, 255, 255, 1);
        transition: color 0.2s ease-in-out;
      }
    }
  }

  .singleDoubleColumn,
  .orderedUnorderedList {
    display: flex;
  }

  .first_button_of_menu {
    margin-right: 0.3125rem;
    font-size: 1.0625rem;
    align-items: center;
    align-content: center;
    align-self: center;
    justify-content: center;
    background-color: ${(props) =>
      !props.currentColumnDisplay ? "#162729" : "rgba(38,38,38,255)"};
    border: ${(props) =>
      !props.currentColumnDisplay
        ? "1px solid #23D0EB"
        : "1px solid rgba(255, 255, 255, 0.5)"};
  }

  .menu_first_icon {
    height: 100%;
    text-align: center;
    align-items: center;
    align-content: center;
    width: 1.75rem;
    padding-bottom: 0.125rem;
  }

  .second_button_of_menu {
    display: flex;
    flex-direction: row;
    width: auto;
    align-items: center;
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: ${(props) =>
      props.currentColumnDisplay ? "#162729" : "rgba(38,38,38,255)"};
    border: ${(props) =>
      props.currentColumnDisplay
        ? "1px solid #23D0EB"
        : "1px solid rgba(255, 255, 255, 0.5)"};

    div {
      font-size: 1.0625rem;
      width: auto;
      height: auto;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .second_button_of_menu:hover {
    background: ${(props) =>
      props.currentColumnDisplay ? "#162729" : "#4C4C4C"};
    border: ${(props) =>
      props.currentColumnDisplay ? "1px solid #23D0EB" : "1px solid #FFFFFF"};
  }

  .first_button_of_menu:hover {
    background: ${(props) =>
      !props.currentColumnDisplay ? "#162729" : "#4C4C4C"};
    border: ${(props) =>
      !props.currentColumnDisplay ? "1px solid #23D0EB" : "1px solid #FFFFFF"};
  }

  .menu_section_icon {
    padding-bottom: 0.125rem;
    width: 1.75rem;
  }

  .menu_third_icon {
    margin-left: 0.25rem;
    padding-bottom: 0.125rem;
  }

  .quantitySwitch {
    padding: 10px;
    display: flex;
  }

  .quantityText {
    margin-left: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    width: 45%;
  }
`;

export const DropDownContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const Select = styled.select`
  background-color: rgba(0, 0, 0, 255);
  color: #bfbfbf;
  border: 1px solid #bfbfbf;
  width: 50%;
  height: 35px;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xl}) {
    width: 70%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    width: 85%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    width: 100%;
  }
`;

export const FixedDiv = styled.div<scrollPhase>`
  height: fit-content;
  width: 100%;
  margin-bottom: 2px;
`;

export const ScrollableDiv = styled.div<scrollPhase>`
  width: 100%;
  overflow-x: hidden;
  height: calc(100vh - 200px);
  overflow-y: scroll;

  ::-webkit-scrollbar {
    height: 100px !important;
  }

  ::-webkit-scrollbar-track {
    background-color: #101010;
    //margin-bottom: 10px;
  }

  ::-webkit-scrollbar-thumb {
    max-height: 20px;
    background: #23d0eb;
    border-radius: 50px;
  }
`;
export const ScrollableTimedActionTaskDiv = styled.div<scrollPhase>`
  width: 100%;
  height: calc(100vh - 60px - 62px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 11px;

  ::-webkit-scrollbar {
    width: 5px !important;
  }

  ::-webkit-scrollbar {
    height: 100px !important;
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #101010;
    //margin-bottom: 10px;
  }

  ::-webkit-scrollbar-thumb {
    max-height: 20px;
    background: #23d0eb;
    border-radius: 50px;
  }

  & .treatmentAdditionalItems {
    display: flex;
    align-items: center;
    padding: 10px 0;
    color: #ffffff;

    &:hover {
      cursor: pointer;
    }

    & .downIcon {
      margin-left: 5px;
    }
  }

  .requiredPlusCritical {
    min-height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .requiredPlusCriticalContainer {
    display: revert !important;
  }

  & .toggleOptions {
    width: 100%;

    & > div:not(.uploaded-media) {
      display: flex;
      padding: 15px 0;
      width: auto;
      min-height: 95px;

      &:hover {
        .text {
          color: rgba(255, 255, 255, 1);
          transition: color 0.2s ease-in-out;
        }
      }
    }

    .text {
      margin-left: 5px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;
    }

    .requiredTreatmentContainer {
      width: 100% !important;
      justify-content: space-between;
      align-items: center;
    }

    .addItemInput {
      display: flex;
      align-items: flex-end;
      padding: 0 10px !important;

      & > div:first-of-type {
        width: 91%;
      }

      textarea {
        padding-left: 5px !important;
      }
    }

    .addTitleIcon {
      height: 60px;
      width: 9% !important;
      display: flex;
      align-items: center;
      font-size: clamp(1rem, 24px, 1.4rem);
      justify-content: center;
      padding-top: 26px;
      color: rgba(112, 112, 112, 255);

      &:hover {
        color: #fff;
        cursor: pointer;
      }
    }

    .durationContainer {
      display: flex;
      flex-direction: column;
      padding: 0px 0px 0px 0 !important;
      border-bottom: 2px solid #101010;
    }

    .mediaContainer {
      grid-template-columns: 1fr !important;
    }
  }

  .additionalItemsContainer {
    border-bottom: 2px solid #101010;
  }

  .treatmentSubTask,
  .startTimeContainer {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .startTimeRating {
    width: 100%;
    padding-top: 22px;
  }

  .mediaContainer {
    flex-direction: column;

    .uploadMedia {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .subTaskSwitchContainer {
      width: 40%;
    }

    .mediaUploadContainer {
      width: 58%;
      gap: 1rem;
      .mediaUpload {
        button {
          padding: 0.25rem 1rem;
        }
      }
    }
  }

  // .emptyCircle {
  //   height: 19px !important;
  //   width: 19px !important;
  //   background: #ffffff !important;
  //   border: 1px solid rgba(255, 255, 255, 0.05) !important;
  // }
`;

export const FixedPhaseDiv = styled.div`
  height: 10%;
  width: 100%;
  margin-bottom: 2px;
`;

export const ScrollablePhaseDiv = styled.div`
  width: 100%;
  overflow-x: hidden;
  height: calc(100vh - 200px);
  overflow-y: scroll;

  ::-webkit-scrollbar {
    height: 100px !important;
  }

  ::-webkit-scrollbar-track {
    background-color: #131313;
    //margin-bottom: 10px;
  }

  ::-webkit-scrollbar-thumb {
    max-height: 20px;
    background: #23d0eb;
    border-radius: 50px;
  }
`;
