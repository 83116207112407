import { Col } from "antd";
import React from "react";
import {
  InsultWithRelation,
  IntervalParameter,
} from "../../../../../../../models/InsultInterface";
import { StyledFormRow } from "../../InjuryTab.styled";
import FloatingLabelInput from "../../../../../../../components/FloatingLabel/FloatingLabelInput";

type IntervalParameterFormProps = {
  formik: any;
  insult: InsultWithRelation;
  param: IntervalParameter;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
};

const IntervalParameterForm = ({
  formik,
  insult,
  param,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
}: IntervalParameterFormProps) => {
  return (
    <StyledFormRow align="middle">
      <Col
        span={10}
        sm={24}
        md={10}
        lg={8}
        key={"interval_param_upper_" + param.Id}
        style={{ padding: "5px" }}
      >
        <FloatingLabelInput
          label={param.UserFacingLabel}
          nameForInput={"interval_param_upper_" + param.Id}
          value={formik.values["interval_param_upper_" + param.Id]}
          onChange={() => undefined}
          scriptFormik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          isAddNeeded={true}
          step={1}
          precision={0}
          isNumber
          maxValue={500}
          minValue={0}
          maxLength={3}
          suffix={param.Units ? param.Units : ""}
          onSave={formik.submitForm}
        />
      </Col>
      <Col
        span={10}
        sm={24}
        md={10}
        lg={8}
        key={"interval_param_lower_" + param.Id}
        style={{ padding: "5px" }}
      >
        <FloatingLabelInput
          label={param.UserFacingLabel}
          nameForInput={"interval_param_lower_" + param.Id}
          value={formik.values["interval_param_lower_" + param.Id]}
          onChange={() => undefined}
          scriptFormik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          isAddNeeded={true}
          step={1}
          precision={0}
          isNumber
          maxValue={59}
          minValue={0}
          maxLength={2}
          suffix={param.Units ? param.Units : ""}
          onSave={formik.submitForm}
        />
      </Col>
    </StyledFormRow>
  );
};

export default IntervalParameterForm;
