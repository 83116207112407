import styled from "styled-components";
import { TaskType } from "../../../../../models/Enums";
import { ColorPalette } from "../../../../../styles/ColorPalette";

export const TimedActionExpandableBlockContainer = styled.div.attrs(
  (props: any) => ({
    isCollapsed: props.isCollapsed,
    type: props.type,
  }),
)`
  position: relative;
  // margin-right: 30px;
  top: -10px;
  left: 40px;
  color: white;
  background: #1e1e1e;
  margin-right: 96px;
  height: ${(props) => (props.isCollapsed ? "0" : "100%")};
  overflow: ${(props) => (props.isCollapsed ? "hidden" : "")};

  &.expandable-block-active > div {
    background-color: ${ColorPalette.Blue4};
  }

  > div {
    box-shadow: ${(props) =>
      props.type === TaskType.Treatment
        ? "inset 5px 0px #23D0EB"
        : props.type === TaskType.Miscellaneous
          ? "inset 5px 0px #7D1EF6"
          : props.type === TaskType.Diagnostic
            ? "inset 5px 0px #FFFFFF"
            : "inset 5px 0px #7AFC17"};

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 50px;
    align-items: stretch;

    .user-icon {
      span {
        margin: 0 auto;
        font-size: 1.5rem;
      }
    }
  }
`;
