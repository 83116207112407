import { AppliedInjury, Injury } from "../../../models/InjuryInterface";
import { Insult, Location } from "../../../models/InsultInterface";
import { LabelData } from "../../../models/LabelOverlayInterfaces";
import { InjuryViewTypes } from "../../../pages/ScriptBuilder/EditComponents/ScriptDetailsView/constants";

export interface initialStateType {
  CurrentView: string;
  FilteredInsults: Insult[];
  FilteredInjuries: Injury[];
  Insults: Insult[];
  Injuries: Injury[];
  InjuryLocations: Location[];
  FilteredAppliedInsults: any[];
  FilteredAppliedInjuries: any[];
  AppliedInsults: any[];
  AppliedInjuries: any[];
  AppliedInjuriesForComparison: AppliedInjury[];
  AppliedInjuryForCreateMaster: number[];
  InjuryObj: AppliedInjury;
  InsultObj: any;
  ScreenVisitHistory: string[];
  BackRedirectScreen: string;
  SaveRedirectScreen: string;
  LabelData: LabelData[];
  DisplayAllInjuryInsult: boolean;
  CurrentInsultFlowRate: number;
}
export const initialState: initialStateType = {
  CurrentView: InjuryViewTypes.CASUALTY_INJURIES,
  FilteredInsults: [],
  FilteredInjuries: [],
  Insults: [],
  Injuries: [],
  InjuryLocations: [],
  FilteredAppliedInsults: [],
  FilteredAppliedInjuries: [],
  AppliedInsults: [],
  AppliedInjuries: [],
  AppliedInjuriesForComparison: [],
  AppliedInjuryForCreateMaster: [],
  InjuryObj: {
    Id: 0,
    Title: "",
    LocationId: 0,
    CopySourceInjuryId: 0,
    Type: "Injury",
    Insults: [],
    AppliedLocationSide: "",
    RenderLocationAs: "-",
  },
  InsultObj: null,
  ScreenVisitHistory: [],
  BackRedirectScreen: "",
  SaveRedirectScreen: "",
  LabelData: [],
  DisplayAllInjuryInsult: false,
  CurrentInsultFlowRate: 0,
};
