import React from "react";
import FloatingLabelTextArea from "../../../../../components/FloatingLabel/FloatingLabelTextArea";
interface Props {
  scriptFormik: any;
  placeholder: string;
}
export default function TextTypeQuestion(props: Props) {
  return (
    <div className="mb-2">
      <FloatingLabelTextArea
        label={""}
        textareaName={"placeholder"}
        value={props.placeholder || "Placeholder"}
        scriptFormik={props.scriptFormik}
        onSave={() => undefined}
        onChange={() => undefined}
        isFocused={[]}
        setIsFocused={() => undefined}
        lastFocused={[]}
        setLastFocused={() => undefined}
        disabled
      />
    </div>
  );
}
