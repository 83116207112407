import * as React from "react";
import SwitchWithLabel from "../Switch/SwitchWithLabel";
import FloatingLabelSelect from "../MuiSelect/FloatingLabelSelect";
import { Select, Tooltip } from "antd";
import {
  BlueCheckbox,
  DropdownForRequiredTimedActionCircle,
  EmptyTriangle,
  RequiredPlusCriticalSwitchContainer,
  TreatmentPhaseDropdownOption,
} from "../../styles/MainList.styled";
import { Phase } from "../../models/ScriptInterfaces";
import { CheckOutLinedIcon } from "../../IconsTheme/Icons.global";
import "../../../src/app/App.less";
import { isNullOrUndefined } from "../../utils/utilityfunctions";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";

interface RequiredProps {
  // createdInPhaseIndex: number;
  createdInPhaseId: number;
  currentPhaseIndex: number;
  requiredInPhaseOffset: number;
  currentPhaseId: number;
  isRequired: boolean;
  isCritical: boolean;
  selectedPhaseValue: string;
  phases: Phase[];
  handleOptionToggle: Function;
  handlePhaseSelection: Function;
}

export const RequiredTA = (props: RequiredProps) => {
  const { isReadOnly } = useAppSelector((state: any) => state.MainlistReducer);
  const [createdPhaseIndex, setCreatedPhaseIndex] = useState<number>(0);
  useEffect(() => {
    if (props.createdInPhaseId) {
      const index = props.phases.findIndex(
        (phase: Phase) => phase.Id === props.createdInPhaseId,
      );
      setCreatedPhaseIndex(index);
    }
  }, []);

  const getPhaseName = (phaseIndex: number) => {
    const phaseName =
      props.phases[phaseIndex]?.TitleAbbreviated !== ""
        ? props.phases[phaseIndex]?.TitleAbbreviated
        : `Phase ${props.phases[phaseIndex]?.Order}`;
    return phaseName?.toLocaleUpperCase();
  };

  return (
    <RequiredPlusCriticalSwitchContainer
      className="requiredTreatmentContainer requiredPlusCriticalContainer"
      isRequiredEnabled={props.isRequired}
      isCriticalEnabled={props.isCritical}
    >
      <div className="requiredPlusCritical">
        <div className="requiredPlusPhaseSelect">
          <Tooltip
            title={
              createdPhaseIndex !== props.currentPhaseIndex
                ? `Required can only be edited in the '${getPhaseName(
                    createdPhaseIndex,
                  )}'`
                : ""
            }
          >
            <div
              onClick={() =>
                createdPhaseIndex === props.currentPhaseIndex &&
                props.handleOptionToggle("IsRequired")
              }
              className={`isRequiured directSwitchContainer ${
                createdPhaseIndex !== props.currentPhaseIndex && "disabled"
              }`}
            >
              <SwitchWithLabel
                disabled={isReadOnly}
                rightLabel={"Required"}
                checked={props.isRequired}
                isForTreatment={true}
                onChange={() => undefined}
                className={`${
                  createdPhaseIndex !== props.currentPhaseIndex && "disabled"
                }`}
              />
            </div>
          </Tooltip>
          {props.isRequired && (
            <FloatingLabelSelect
              className="floatingSelectContainer"
              value={props.selectedPhaseValue}
              disabled={
                isReadOnly || createdPhaseIndex !== props.currentPhaseIndex
              }
            >
              {props.phases.map((phase, phaseIndex) => {
                if (
                  phase.IsScriptBrief ||
                  phaseIndex - props.currentPhaseIndex > 2 ||
                  phaseIndex < createdPhaseIndex
                ) {
                  return (
                    <Select.Option
                      className="disabledOption"
                      value={
                        phase.TitleAbbreviated === ""
                          ? `Phase ${phaseIndex + 1}`
                          : phase.TitleAbbreviated
                      }
                      disabled
                    >
                      <TreatmentPhaseDropdownOption
                        isBrief={phase.IsScriptBrief}
                        className="TreatmentPhaseDropdownOption"
                        onClick={() => undefined}
                      >
                        <div className="disabledOption">
                          {phase.TitleAbbreviated === ""
                            ? `Phase ${phaseIndex + 1}`
                            : phase.TitleAbbreviated}
                        </div>
                      </TreatmentPhaseDropdownOption>
                    </Select.Option>
                  );
                } else {
                  const optionsValue =
                    phase.TitleAbbreviated === ""
                      ? `Phase ${phaseIndex + 1}`
                      : phase.TitleAbbreviated;
                  return (
                    <Select.Option value={optionsValue}>
                      <TreatmentPhaseDropdownOption
                        className="TreatmentPhaseDropdownOption"
                        onClick={() =>
                          createdPhaseIndex === props.currentPhaseIndex &&
                          optionsValue !== props.selectedPhaseValue &&
                          props.handlePhaseSelection(
                            phase.TitleAbbreviated,
                            phaseIndex,
                          )
                        }
                      >
                        <DropdownForRequiredTimedActionCircle>
                          {props.selectedPhaseValue ===
                            phase.TitleAbbreviated ||
                          props.selectedPhaseValue ===
                            `Phase ${phaseIndex + 1}` ? (
                            <div className="requiredPhaseWithCheckmark">
                              <CheckOutLinedIcon
                                className="checkmark"
                                title=""
                              ></CheckOutLinedIcon>
                            </div>
                          ) : (
                            ""
                          )}
                        </DropdownForRequiredTimedActionCircle>
                        <div>
                          {props.selectedPhaseValue ===
                            phase.TitleAbbreviated ||
                          props.selectedPhaseValue ===
                            `Phase ${phaseIndex + 1}` ? (
                            <div className="requiredPhaseWithCheckmark">
                              <span>
                                {phase.TitleAbbreviated === ""
                                  ? `Phase ${phaseIndex + 1}`
                                  : phase.TitleAbbreviated}
                              </span>
                            </div>
                          ) : (
                            <BlueCheckbox>
                              {phase.TitleAbbreviated === ""
                                ? `Phase ${phaseIndex + 1}`
                                : phase.TitleAbbreviated}
                            </BlueCheckbox>
                          )}
                        </div>
                        <DropdownForRequiredTimedActionCircle
                          isOptionEnabled={true}
                        >
                          {props.selectedPhaseValue ===
                            phase.TitleAbbreviated ||
                          props.selectedPhaseValue ===
                            `Phase ${phaseIndex + 1}` ? (
                            <div
                              className="requiredTriangle"
                              style={{ display: "flex" }}
                            >
                              Required&nbsp;
                              <EmptyTriangle
                                background={"#401010"}
                                border={"#FF4242"}
                              />
                            </div>
                          ) : (!isNullOrUndefined(
                              props.phases[phaseIndex + 1],
                            ) &&
                              props.selectedPhaseValue ===
                                props.phases[phaseIndex + 1]!
                                  .TitleAbbreviated) ||
                            props.selectedPhaseValue ===
                              `Phase ${phaseIndex + 2}` ? (
                            <div
                              className="requiredTriangle"
                              style={{ display: "flex" }}
                            >
                              Good&nbsp;
                              <EmptyTriangle
                                background={"#3F2902"}
                                border={"#FEA40A"}
                              />
                            </div>
                          ) : (!isNullOrUndefined(
                              props.phases[phaseIndex + 2],
                            ) &&
                              props.selectedPhaseValue ===
                                props.phases[phaseIndex + 2]!
                                  .TitleAbbreviated) ||
                            props.selectedPhaseValue ===
                              `Phase ${phaseIndex + 3}` ? (
                            <div
                              className="requiredTriangle"
                              style={{ display: "flex" }}
                            >
                              Excellent&nbsp;
                              <EmptyTriangle
                                background={"#052125"}
                                border={"#23D0EB"}
                              />
                            </div>
                          ) : (
                            <EmptyTriangle />
                          )}
                        </DropdownForRequiredTimedActionCircle>
                      </TreatmentPhaseDropdownOption>
                    </Select.Option>
                  );
                }
              })}
            </FloatingLabelSelect>
          )}
        </div>
      </div>
      {props.isRequired && (
        <div
          className="isCritical directSwitchContainer"
          onClick={() => props.handleOptionToggle("IsCritical")}
        >
          <SwitchWithLabel
            disabled={isReadOnly}
            isForTreatment={true}
            rightLabel={"Critical"}
            checked={props.isCritical}
            onChange={() => undefined}
          />
        </div>
      )}
    </RequiredPlusCriticalSwitchContainer>
  );
};
