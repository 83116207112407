import { Select } from "antd";
import React from "react";
import FloatingLabelSelect from "../../../../components/MuiSelect/FloatingLabelSelect";
import { FormRow } from "../../User.styled";
const { Option } = Select;
type Props = {
  formik: any;
  handleChange: Function;
};
const fieldName = "yearsOfExperience";
export default function YearsOfExperienceInput({
  formik,
  handleChange,
}: Props) {
  return (
    <FormRow>
      <FloatingLabelSelect
        label="Years of Experience or Time in Service"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(fieldName, e.target.value)
        }
        value={formik.values[fieldName]}
        disabled
      >
        <Option title="" value={0}>
          -
        </Option>
        <Option title="" value={1}>
          1yr
        </Option>
        <Option title="" value={2}>
          2yrs
        </Option>
        <Option title="" value={3}>
          3yrs
        </Option>
        <Option title="" value={4}>
          4yrs
        </Option>
        <Option title="" value={5}>
          5yrs
        </Option>
        <Option title="" value={6}>
          6yrs
        </Option>
        <Option title="" value={7}>
          7yrs
        </Option>
        <Option title="" value={8}>
          8yrs
        </Option>
        <Option title="" value={9}>
          9yrs
        </Option>
        <Option title="" value={10}>
          10yrs
        </Option>
      </FloatingLabelSelect>
    </FormRow>
  );
}
