import * as React from "react";
import { Component } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Form } from "antd";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Input,
  Button,
  message,
  Checkbox,
  Table,
  Typography,
  Drawer,
} from "antd";
import { canadaDateFormat } from "../../../utils/dateUtils";
import { ColumnsType } from "antd/es/table";

const { TextArea } = Input;
const { Paragraph } = Typography;

interface Columns {
  name: string;
  text: string;
  resourceType: any;
  public: boolean;
  createdAt: string;
  march: string;
  key: number;
}

interface Props {
  resources: Columns[];
  deleteFile: Function;
  AddResource: Function;
}
interface State {
  loading: boolean;
  addModal: boolean;
  name: string;
  resourceTypeId: number;
  textData: string;
  id: number;
  editModal: boolean;
  public: boolean;
  march: any;
  resource: any;
}

class TextList extends Component<Props, State> {
  state: State = {
    loading: false,
    addModal: false,
    name: "",
    resourceTypeId: 0,
    textData: "",
    id: -1,
    editModal: false,
    public: false,
    march: ["0", "0", "0", "0", "0"],
    resource: {},
  };

  handleChange = (input: any) => (e: any) => {
    this.setState({ [input]: e.target.value } as any);
  };

  onCheckPublic = (e: any) => {
    this.setState({ public: e.target.checked });
  };

  success = (data: any) => {
    console.log("success");
  };

  addResource = async () => {
    if (this.state.name === "") {
      this.setState({
        name: "",
        resourceTypeId: 0,
        textData: "",
        addModal: false,
        march: ["0", "0", "0", "0", "0"],
      });
    } else {
      const marchString = this.state.march.join("");
      const march = parseInt(marchString, 2);
      const postObj = {
        Name: this.state.name,
        Text: this.state.textData,
        ResourceTypeId: 2,
        March: march,
      };
      console.log(postObj);
      await this.props.AddResource("add", postObj);
      this.setState({
        name: "",
        resourceTypeId: 0,
        textData: "",
        addModal: false,
        march: ["0", "0", "0", "0", "0"],
      });
    }
  };

  editModal = (resource: any) => {
    const marchString = String("00000" + resource.march.toString(2)).slice(-5);
    const march = Array.from(marchString);
    this.setState({
      resource: resource,
      id: resource.id,
      march: march,
      name: resource.name,
      textData: resource.text,
      public: resource.public,
      editModal: true,
    });
  };
  editResource = async () => {
    const marchString = this.state.march.join("");
    const march = parseInt(marchString, 2);
    const selectedResource = this.state.resource;
    if (
      march !== selectedResource.march ||
      this.state.name !== selectedResource.name ||
      this.state.textData !== selectedResource.text ||
      this.state.public !== selectedResource.public
    ) {
      console.log(march);
      const postObj = {
        Id: this.state.id,
        Name: this.state.name,
        Text: this.state.textData,
        Public: this.state.public,
        ResourceTypeId: 2,
        March: march,
      };
      console.log(postObj);
      await this.props.AddResource("edit", postObj);
      this.setState({
        name: "",
        resourceTypeId: 0,
        textData: "",
        public: false,
        editModal: false,
        march: ["0", "0", "0", "0", "0"],
      });
    } else {
      this.setState({
        name: "",
        resourceTypeId: 0,
        textData: "",
        public: false,
        editModal: false,
        march: ["0", "0", "0", "0", "0"],
      });
    }
  };

  handleMarchChange = (index: number) => {
    console.log(index);
    const march = this.state.march;
    if (march[index] === "0") march[index] = "1";
    else march[index] = "0";
    this.setState({ march: march });
  };

  error = (data: any) => {
    console.log(data);
    message.error("An error occured check the console for more information");
  };

  getMarchArray = (march: any) => {
    const marchString = String("00000" + march.toString(2)).slice(-5);
    const marchArray = Array.from(marchString);
    return (
      <Row>
        <span
          className={
            marchArray[0] === "0"
              ? "marchLetterScenario"
              : "selectedMarchLetterScenario"
          }
        >
          M
        </span>
        <span
          className={
            marchArray[1] === "0"
              ? "marchLetterScenario"
              : "selectedMarchLetterScenario"
          }
        >
          A
        </span>
        <span
          className={
            marchArray[2] === "0"
              ? "marchLetterScenario"
              : "selectedMarchLetterScenario"
          }
        >
          R
        </span>
        <span
          className={
            marchArray[3] === "0"
              ? "marchLetterScenario"
              : "selectedMarchLetterScenario"
          }
        >
          C
        </span>
        <span
          className={
            marchArray[4] === "0"
              ? "marchLetterScenario"
              : "selectedMarchLetterScenario"
          }
        >
          H
        </span>
      </Row>
    );
  };

  render() {
    const columns: ColumnsType<Columns> = [
      {
        title: "Name",
        dataIndex: "name",
        key: "Name",
        className: "whiteText",
        render: (name: string) => <span>{name}</span>,
      },
      {
        title: "Resource Type",
        dataIndex: "resourceType",
        key: "ResourceType",
        className: "whiteText",
        render: (resourceType: any) => <span>{resourceType.name}</span>,
      },
      {
        title: "Text",
        dataIndex: "text",
        key: "Text",
        className: "whiteText",
        render: (Text: string) => (
          <span>
            <Paragraph style={{ color: "#fff" }} ellipsis={{ rows: 2 }}>
              {Text}
            </Paragraph>
          </span>
        ),
      },
      {
        title: "Public",
        dataIndex: "public",
        key: "Public",
        className: "whiteText",
        render: (Public: boolean) => <span>{Public ? "Yes" : "No"}</span>,
      },

      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "CreatedAt",
        className: "whiteText",
        render: (createdAt: string) => (
          <span>{canadaDateFormat(createdAt)}</span>
        ),
      },
      {
        title: "March",
        dataIndex: "march",
        key: "march",
        className: "whiteText",
        render: (march: any) => <div>{this.getMarchArray(march)}</div>,
      },
      {
        title: "Edit",
        dataIndex: "",
        key: "edit",
        className: "whiteText",
        render: (resource: any) => (
          <span>
            <Button
              className="ant-btn realx-btn-blue"
              type="primary"
              onClick={() => this.editModal(resource)}
            >
              Edit
            </Button>
          </span>
        ),
      },
    ];
    return (
      <div>
        <Button
          className="ant-btn realx-btn-blue"
          icon={<PlusOutlined />}
          style={{ marginBottom: "20px", width: "200px" }}
          onClick={() => {
            this.setState({ addModal: true });
          }}
        >
          Add a Text Resource
        </Button>

        <Table<Columns>
          dataSource={this.props.resources}
          columns={columns}
          rowKey={(record: any) => record.id}
          pagination={{ pageSize: 10 }}
          rowClassName={(record) => "rxblackBG"}
        />

        <Drawer
          title="Add a Text Resource"
          visible={this.state.addModal}
          onClose={this.addResource}
          closable
          width="600"
        >
          <Form.Item>
            <label className="whiteText">Name: </label>
            <Input
              value={this.state.name}
              placeholder="Name"
              allowClear
              onChange={this.handleChange("name")}
              style={{ marginBottom: "30px" }}
            />
          </Form.Item>
          <Form.Item>
            <label className="whiteText">Text Resource Data: </label>
            <TextArea
              value={this.state.textData}
              placeholder="textData"
              allowClear
              onChange={this.handleChange("textData")}
              style={{ marginBottom: "30px" }}
              maxLength={500}
              rows={6}
            />
          </Form.Item>
          <Row>
            <Row className={"white-text"}>MARCH Group</Row>
            <Row>
              <span
                onClick={() => this.handleMarchChange(0)}
                className={
                  this.state.march[0] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                M
              </span>
              <span
                onClick={() => this.handleMarchChange(1)}
                className={
                  this.state.march[1] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                A
              </span>
              <span
                onClick={() => this.handleMarchChange(2)}
                className={
                  this.state.march[2] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                R
              </span>
              <span
                onClick={() => this.handleMarchChange(3)}
                className={
                  this.state.march[3] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                C
              </span>
              <span
                onClick={() => this.handleMarchChange(4)}
                className={
                  this.state.march[4] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                H
              </span>
            </Row>
          </Row>
          <Form.Item>
            <Checkbox checked={this.state.public} onChange={this.onCheckPublic}>
              <span className="whiteText">Public</span>
            </Checkbox>
          </Form.Item>
        </Drawer>
        <Drawer
          title="Edit Text Resource"
          visible={this.state.editModal}
          onClose={this.editResource}
          closable
          width="600"
        >
          <Form.Item>
            <label className="whiteText">Name: </label>
            <Input
              value={this.state.name}
              placeholder="Name"
              allowClear
              onChange={this.handleChange("name")}
              style={{ marginBottom: "30px" }}
            />
          </Form.Item>
          <Form.Item>
            <label className="whiteText">Text Resource Data: </label>
            <TextArea
              value={this.state.textData}
              placeholder="textData"
              allowClear
              onChange={this.handleChange("textData")}
              style={{ marginBottom: "30px" }}
              maxLength={500}
              rows={6}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox checked={this.state.public} onChange={this.onCheckPublic}>
              <span className="whiteText">Public</span>
            </Checkbox>
          </Form.Item>
          <Row>
            <Row className={"white-text"}>MARCH Group</Row>
            <Row>
              <span
                onClick={() => this.handleMarchChange(0)}
                className={
                  this.state.march[0] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                M
              </span>
              <span
                onClick={() => this.handleMarchChange(1)}
                className={
                  this.state.march[1] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                A
              </span>
              <span
                onClick={() => this.handleMarchChange(2)}
                className={
                  this.state.march[2] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                R
              </span>
              <span
                onClick={() => this.handleMarchChange(3)}
                className={
                  this.state.march[3] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                C
              </span>
              <span
                onClick={() => this.handleMarchChange(4)}
                className={
                  this.state.march[4] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                H
              </span>
            </Row>
          </Row>
        </Drawer>
      </div>
    );
  }
}
export default TextList;
