import { ChartBorderColors, ChartColors } from "../../models/Constants";
import { ChartType } from "../../models/Enums";

const BarChartLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];
export const BarChartData = {
  labels: BarChartLabels,
  datasets: [
    {
      label: "Level 1",
      data: BarChartLabels.map(() => Math.random() * 1000),
      borderColor: ChartBorderColors.Red,
      backgroundColor: ChartColors.Red,
    },
    {
      label: "Level 2",
      data: BarChartLabels.map(() => Math.random() * 1000),
      borderColor: ChartBorderColors.Yellow,
      backgroundColor: ChartColors.Yellow,
    },
  ],
};

const VerticalBarLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];

export const VerticalBarData = {
  labels: VerticalBarLabels,
  datasets: [
    {
      label: "Team 1",
      data: VerticalBarLabels.map(() => Math.random() * 1000),
      backgroundColor: ChartColors.Red,
    },
    {
      label: "Team 2",
      data: VerticalBarLabels.map(() => Math.random() * 1000),
      backgroundColor: ChartColors.Green,
    },
    {
      label: "Team 3",
      data: VerticalBarLabels.map(() => Math.random() * 1000),
      backgroundColor: ChartColors.Blue,
    },
  ],
};

const PlotLineLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];

export const PlotLineData = {
  labels: PlotLineLabels,
  datasets: [
    {
      label: "SOST 1",
      data: PlotLineLabels.map(() => Math.random() * 1000),
      borderColor: ChartBorderColors.Red,
      backgroundColor: ChartColors.Red,
    },
    {
      label: "JMAU",
      data: PlotLineLabels.map(() => Math.random() * 1000),
      borderColor: ChartBorderColors.Orange,
      backgroundColor: ChartColors.Orange,
    },
    {
      label: "MARSOC",
      data: PlotLineLabels.map(() => Math.random() * 1000),
      borderColor: ChartBorderColors.Yellow,
      backgroundColor: ChartColors.Yellow,
    },
    {
      label: "NSW",
      data: PlotLineLabels.map(() => Math.random() * 1000),
      borderColor: ChartBorderColors.Green,
      backgroundColor: ChartColors.Green,
    },
    {
      label: "USASOC",
      data: PlotLineLabels.map(() => Math.random() * 1000),
      borderColor: ChartBorderColors.Blue,
      backgroundColor: ChartColors.Blue,
    },
    {
      label: "SARC",
      data: PlotLineLabels.map(() => Math.random() * 1000),
      borderColor: ChartBorderColors.Purple,
      backgroundColor: ChartColors.Purple,
    },
  ],
};

const StackedAreaLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];

export const StackedAreaData = {
  labels: StackedAreaLabels,
  datasets: [
    {
      fill: true,
      label: "Dataset 1",
      data: StackedAreaLabels.map(() => Math.random() * 1000),
      borderColor: ChartBorderColors.Red,
      backgroundColor: ChartColors.Red,
    },
    {
      fill: true,
      label: "Dataset 2",
      data: StackedAreaLabels.map(() => Math.random() * 1000),
      borderColor: ChartBorderColors.Yellow,
      backgroundColor: ChartColors.Yellow,
    },
  ],
};

export const PieChartData = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        ChartColors.Red,
        ChartColors.Orange,
        ChartColors.Yellow,
        ChartColors.Green,
        ChartColors.Blue,
        ChartColors.Purple,
      ],
      borderColor: [
        ChartBorderColors.Red,
        ChartBorderColors.Orange,
        ChartBorderColors.Yellow,
        ChartBorderColors.Green,
        ChartBorderColors.Blue,
        ChartBorderColors.Purple,
      ],
      borderWidth: 1,
    },
  ],
};

export const ScatterData = {
  datasets: [
    {
      label: "TCCC",
      data: Array.from({ length: 100 }, () => ({
        x: Math.random() * 1000,
        y: Math.random() * 1000,
      })),
      backgroundColor: ChartColors.Red,
    },
    {
      label: "PCC",
      data: Array.from({ length: 100 }, () => ({
        x: Math.random() * 1000,
        y: Math.random() * 1000,
      })),
      backgroundColor: ChartColors.Yellow,
    },
  ],
};

export const DoughnutChartData = {
  labels: ["Red", "Blue"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19],
      backgroundColor: [ChartColors.Red, ChartColors.Orange],
      borderColor: [ChartBorderColors.Red, ChartBorderColors.Orange],
      borderWidth: 1,
    },
  ],
};
export const DummyWidgetsData = [
  {
    type: ChartType.HorizontalBar,
    title: "Comparative Analysis",
    secondaryText: "Secondary text",
    sm: { i: "0", x: 0, y: 0, w: 3, h: 2 },
  },
  {
    type: ChartType.StackedArea,
    title: "Altitude Affects on Success Rate",
    secondaryText: "Secondary text",
    sm: { i: "1", x: 3, y: 0, w: 3, h: 2 },
  },
  {
    type: ChartType.Pie,
    title: "Critical Actions Mastered",
    secondaryText: "Secondary text",
    sm: { i: "2", x: 6, y: 0, w: 3, h: 2 },
  },
  {
    type: ChartType.Scatter,
    title: "Capability Readiness",
    secondaryText: "Secondary text",
    sm: { i: "3", x: 9, y: 0, w: 3, h: 2 },
  },
  {
    type: ChartType.PlotLine,
    title: "Time in Training",
    secondaryText: "Secondary text",
    sm: { i: "5", x: 0, y: 2, w: 3, h: 2 },
  },

  {
    type: ChartType.Doughnut,
    title: "TCCC vs PCC Training Time",
    secondaryText: "Secondary text",
    sm: { i: "6", x: 3, y: 2, w: 3, h: 2 },
  },
  {
    type: ChartType.VerticalBar,
    title: "Performance Over Time",
    secondaryText: "Secondary text",
    sm: { i: "4", x: 6, y: 2, w: 3, h: 2 },
  },
];
