import * as React from "react";
import { StyledBars } from "../../../components/SuccessRating/Bars.styled";
import { StyledSuccessRating } from "./DetailsPanelComponents.styled";

interface RatingProps {
  barRating: number;
}

export const SuccessRating = (props: RatingProps) => {
  const { barRating } = props;
  return (
    <StyledSuccessRating>
      <h5>Success Rate</h5>
      <div className="success-rating">
        <StyledBars rating={barRating} /> <p>{barRating}%</p>
      </div>
    </StyledSuccessRating>
  );
};
