import * as React from "react";
import { useLocation } from "react-router";
import { connect, useSelector } from "react-redux";
import { setAlertMessage } from "../../../../actions/alertMessage";
import { message, Row } from "antd";
import { useFormik } from "formik";
import { Allergy, Casualty } from "./../../../../models/CasualtyInterfaces";
import { SplitPaneDialog } from "./styled";
import ScriptView from "./ScriptView/ScriptView";
import CasualtyView from "./CasualtyView/CasualtyView";
import { ScriptDetailsViewProps } from "./types";
import { ScriptViewTypes } from "./constants";
import { Script } from "../../../../models/ScriptInterfaces";
import { createScript, updateScript } from "../../../../api/Script/ApiPost";
import { useEffect } from "react";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import ScriptTitleHeader, { createScriptObject } from "./ScriptTitleHeader";

type ErrorType = {
  [key: string]: string;
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ScriptDetailsView = (props: ScriptDetailsViewProps) => {
  const {
    view,
    setView,
    leftPaneView,
    setLeftPaneView,
    casualty,
    SetCurrentCasualty,
    postError,
    postSuccess,
    postCreateSuccess,
    scriptData,
    isCasultyEditMode,
    setIsCasualtyEditMode,
  } = props;
  const { isReadOnly, casualties } = useSelector(
    (state: any) => state.scriptBuilderReducer,
  );
  const [scriptTitle, setScriptTitle] = React.useState("");
  const [allAllergies, setAllAllergies] = React.useState<Allergy[]>([]);
  const query = useQuery();
  const tab = React.useMemo(() => query.get("tab"), [query]);

  const scriptFormik = useFormik({
    initialValues: {
      id: 0,
      public: false,
      isSeed: false,
      title: "",
      description: "",
      casualtyId: 0,
      search: "",
      clonable: false,
      IsArchived: false,
    },
    validate: (values) => {
      const errors: ErrorType = {};

      if (values.title && !values.title.trim()) {
        errors["title"] = "Please enter a Script Title";
      } else if (values.title.length > 70) {
        errors["title"] = "Max 70 chars allowed";
      }

      if (
        values.description.trim().length > 200 ||
        values.description.trim().split("\n").length > 4
      ) {
        errors["description"] =
          "Description must not exceed 200 characters or 4 lines";
      }

      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  useEffect(() => {
    console.log("scriptFormik.values", scriptFormik.values);
  }, [scriptFormik.values]);
  useEffect(() => {
    if (scriptData) {
      console.log(scriptData);
      setScriptTitle(scriptData.Title);
      scriptFormik.values.id = scriptData.Id;
      scriptFormik.values.casualtyId = scriptData.CasualtyId;
      scriptFormik.values.title = scriptData.Title;
      scriptFormik.values.description = scriptData.Description;
      scriptFormik.values.public = scriptData.Public;
      scriptFormik.values.isSeed = scriptData.IsSeed;
      const casualtiesInScript: any = [scriptData.Casualty];
    }
  }, [scriptData]);

  const createNewScript = (
    e: any,
    obj?: Script,
    redirectOrReload = true,
    newCasualty = undefined,
  ) => {
    if (scriptFormik.values.id === 0) {
      const scenarioObject: Script = createScriptObject(
        casualties,
        scriptData,
        scriptFormik,
        newCasualty,
      );
      if (scenarioObject.Title.length) {
        //Create the scenario in Database
        createScript(
          scenarioObject,
          (data: Script) => postCreateSuccess(data, redirectOrReload),
          postError,
        );
      } else {
        message.warning("Please enter script title");
      }
    } else {
      let scenarioObject: Script = createScriptObject(
        casualties,
        scriptData,
        scriptFormik,
        newCasualty,
      );
      if (obj) scenarioObject = obj;
      updateScript(
        scenarioObject,
        (data: Script) => postSuccess(data, redirectOrReload),
        displayErrorMessage,
      );
    }
  };
  const displayErrorMessage = (e: any) => {
    OpenErrorNotification({
      description: e,
    });
  };

  return (
    <SplitPaneDialog className={isReadOnly ? "readOnly withNotification" : ""}>
      <ScriptTitleHeader
        isReadOnly={isReadOnly}
        scriptTitle={scriptTitle}
        setScriptTitle={setScriptTitle}
        scriptFormik={scriptFormik}
        postCreateSuccess={postCreateSuccess}
        postSuccess={postSuccess}
        postError={postError}
        displayGoToScript={true}
      />

      <Row style={{ display: "flex", minHeight: "100%", flexGrow: 1 }}>
        {view === ScriptViewTypes.SCRIPT && (
          <ScriptView
            formik={scriptFormik}
            setView={setView}
            updateScriptTitle={setScriptTitle}
            createUpdateScript={createNewScript}
          />
        )}
        {view === ScriptViewTypes.CASUALTY && (
          <CasualtyView
            createNewScript={createNewScript}
            scriptFormik={scriptFormik}
            setView={setView}
            allAllergies={allAllergies}
            setAllAllergies={setAllAllergies}
            leftPaneView={leftPaneView}
            setLeftPaneView={setLeftPaneView}
            casualty={casualty}
            SetCurrentCasualty={SetCurrentCasualty}
            setScriptTitle={setScriptTitle}
            setIsCasualtyEditMode={setIsCasualtyEditMode}
            isCasultyEditMode={isCasultyEditMode}
          />
        )}
      </Row>
    </SplitPaneDialog>
  );
};

function mapToStateToProps(state: any) {
  return {
    SystemMessage: state.SystemMessage,
  };
}

export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(ScriptDetailsView);
