import React from "react";
import GetSessionCountsQuery from "./GetSessionCountsQuery";
import GetTrainingQualityQuery from "./GetTrainingQualityQuery";
import GetTotalTrainingTime from "./GetTotalTrainingTime";
import GetMedicalCarePerformance from "./GetMedicalCarePerformance";
import GetTaskPerformance from "./GetTaskPerformance";

interface QueryProps {
  formik: any;
  queryId: number;
  StoredProcedureToExecute: string;
}

const QueryQuestion = ({
  formik,
  queryId,
  StoredProcedureToExecute,
}: QueryProps) => {
  switch (StoredProcedureToExecute) {
    case "GetSessionCounts":
      return (
        <GetSessionCountsQuery
          show={formik.values["selectedQueryId"] === queryId}
          formik={formik}
          queryParams={formik.values["queryParams"]}
        />
      );
    case "GetTrainingQuality":
      return (
        <GetTrainingQualityQuery
          show={formik.values["selectedQueryId"] === queryId}
          formik={formik}
          queryParams={formik.values["queryParams"]}
        />
      );
    case "GetTotalTrainingTime":
      return (
        <GetTotalTrainingTime
          show={formik.values["selectedQueryId"] === queryId}
          formik={formik}
          queryParams={formik.values["queryParams"]}
        />
      );
    case "GetMedicalCarePerformance":
      return (
        <GetMedicalCarePerformance
          show={formik.values["selectedQueryId"] === queryId}
          formik={formik}
          queryParams={formik.values["queryParams"]}
        />
      );
    case "GetTaskPerformance":
      return (
        <GetTaskPerformance
          show={formik.values["selectedQueryId"] === queryId}
          formik={formik}
          queryParams={formik.values["queryParams"]}
        />
      );
    default:
      return null;
  }
};

export default QueryQuestion;
