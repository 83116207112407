import React from "react";
import Drawer from "../../../../components/Drawer/Drawer";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import SubTasks from "./TimedActions/SubTasks";
import { AdditionalInfoTA } from "../../../../components/TimedActions/AdditionalInfoTA";
import Label from "./TimedActions/Label";
import { useAppSelector } from "../../../../store/hooks";
import { DefineStartTimeTA } from "../../../../components/TimedActions/DefineStartTimeTA";
import { DurationTa } from "../../../../components/TimedActions/DurationTA";
import { IntervalTA } from "../../../../components/TimedActions/IntervalTA";
import { RatingTA } from "../../../../components/TimedActions/RatingTA";
import {
  MiscellaneousTask,
  UpdateMiscellaneousTask,
} from "../../../../models/MiscellaneousTask";
import { deleteMediaForTimedActionTask } from "../../../../api/TimedActionTask";
import produce from "immer";
import { Media } from "../../../../models/MediaInterface";
import { isNullOrUndefined } from "is-what";
import MediaOption from "../../../../components/Media/MediaOption";
import { setMiscellaneousForUpdate } from "./MiscellaneousSlice";
import CategorizationAndRequirements from "../../../../components/TreatmentEdit/CategorizationAndRequirements";
import { ScrollableTimedActionTaskDiv } from "../../../../styles/EditComponentTheme.styled";
import FloatingLabelTextArea from "../../../../components/FloatingLabel/FloatingLabelTextArea";
import { Tooltip } from "antd";
interface Props {
  title: string;
  formik: any;
  isVisible: boolean;
  onClickOutside: any;
  onClose: any;
}
export default function AddUpdateDrawer({
  title,
  formik,
  isVisible,
  onClickOutside,
  onClose,
}: Props) {
  const { materialRequirements, skillCategorization } = useAppSelector(
    (state) => state.FeatureFlagReducer.scriptBuilder.miscellaneousBlock,
  );
  const miscellaneous = useAppSelector(
    (state) => state.MasterMiscellaneousReducer.miscellaneousForUpdate,
  );
  const focusedStates = {
    question: false,
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);
  const isReadOnly = false;
  const dispatch = useDispatch();
  const onError = (msg: any) => {
    OpenErrorNotification({
      description: msg,
    });
  };
  const handleChange = (
    field: keyof UpdateMiscellaneousTask,
    value: string | boolean | any,
  ) => {
    const payload = {
      ...miscellaneous,
      [field]: value,
    };
    dispatch(setMiscellaneousForUpdate(payload));
  };

  const handleIntervalToggle = (
    type: keyof UpdateMiscellaneousTask,
    value?: number,
  ) => {
    if (type === "IsIntervalEnabled") {
      if (miscellaneous.IsIntervalEnabled) {
        handleChange("IntervalTime", 0);
      }
      handleChange(type, !miscellaneous[type]);
    } else if (type === "IntervalTime") {
      handleChange(type, value);
    }
  };

  const handleOptionToggle = (type: keyof UpdateMiscellaneousTask) => {
    handleChange(type, !miscellaneous[type]);
  };

  const deleteMediaSuccess = (mediaId: number) => {
    const updatedMiscellaneousTask = produce(
      miscellaneous,
      (draftMiscellaneous) => {
        draftMiscellaneous.AttachedMedias = miscellaneous.AttachedMedias.filter(
          (media: Media) => media.Id !== mediaId,
        );
      },
    );
    dispatch(setMiscellaneousForUpdate(updatedMiscellaneousTask));
  };

  const handleDeleteMedia = (mediaId: number) => {
    if (!isNullOrUndefined(miscellaneous.Id)) {
      deleteMediaForTimedActionTask(
        miscellaneous.Id,
        mediaId,
        () => {
          deleteMediaSuccess(mediaId);
        },
        (error: any) => {
          console.error(error);
        },
      );
    } else {
      deleteMediaSuccess(mediaId);
    }
  };

  const getScrollableDivHeight = () => {
    return {
      height: `auto`,
    };
  };

  return (
    <Drawer
      className="custom-css-ant-drawer"
      visible={isVisible}
      onClose={onClickOutside}
      closable
      width="600"
    >
      <h1>
        <ArrowRightOutlined style={{ marginRight: "20px" }} onClick={onClose} />
        {title}
      </h1>
      <div className="p-2">
        <ScrollableTimedActionTaskDiv
          style={getScrollableDivHeight()}
          typeOfEditPanel="miscellaneous"
          className="miscellaneousContainer"
          id="scrollableDiv"
          phase={false}
        >
          <div className="mb-5">
            <Label
              label={"Title*"}
              field={"title"}
              formik={formik}
              lastFocused={lastFocused}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
            />
          </div>
          <FloatingLabelTextArea
            label={"Description"}
            textareaName={"description"}
            value={formik.values["description"]}
            onSave={() => undefined}
            onChange={formik.handleChange}
            scriptFormik={formik}
            lastFocused={lastFocused}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            isFocused={isFocused}
          />
          <div className="toggleOptions">
            <SubTasks
              isReadOnly={isReadOnly}
              obj={miscellaneous as MiscellaneousTask}
              formik={formik}
              onChange={handleChange}
              onError={onError}
            />
            <IntervalTA
              isReadOnly={isReadOnly}
              handleIntervalToggle={handleIntervalToggle}
              isIntervalEnabled={miscellaneous.IsIntervalEnabled}
              interval={miscellaneous.IntervalTime}
              handleOptionToggle={handleOptionToggle}
              scriptFormik={formik}
            />
            <DurationTa
              isReadOnly={isReadOnly}
              isDurationEnabled={miscellaneous.IsDurationEnabled}
              onChange={handleOptionToggle}
              isDefineDurationTimeEnabled={
                miscellaneous.IsDefineDurationTimeEnabled
              }
            />
            <DefineStartTimeTA
              isReadOnly={isReadOnly}
              handleOptionToggle={handleOptionToggle}
              isDefineStartTimeEnabled={miscellaneous.IsDefineStartTimeEnabled}
            />
            <RatingTA
              isReadOnly={isReadOnly}
              isCustomLabelsEnabled={miscellaneous.IsCustomLabelsEnabled}
              isRatingEnabled={miscellaneous.IsRatingEnabled}
              handleOptionToggle={handleOptionToggle}
            />
            <MediaOption
              isReadOnly={isReadOnly}
              obj={miscellaneous as MiscellaneousTask}
              type="Miscellaneous"
              handleOptionToggle={handleOptionToggle}
              handleDeleteMedia={handleDeleteMedia}
              onChange={handleChange}
            />
            {skillCategorization && (
              <Tooltip title="Hidden in production">
                <>
                  <CategorizationAndRequirements
                    dropDownTitle={"Skill Categorization"}
                    listItemsTitle={"Skill Type"}
                    viewListLinkTitle={"View Approved Mos Units"}
                    timedAction={"miscellaneous"}
                  />
                </>
              </Tooltip>
            )}
            {materialRequirements && (
              <Tooltip title="Hidden in production">
                <>
                  <CategorizationAndRequirements
                    dropDownTitle={"Material Requirements"}
                    listItemsTitle={"Equipment Availability"}
                    viewListLinkTitle={"View Material List"}
                    timedAction={"miscellaneous"}
                  />
                </>
              </Tooltip>
            )}
          </div>
        </ScrollableTimedActionTaskDiv>
      </div>
    </Drawer>
  );
}
