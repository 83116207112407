import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { featureFlagState } from "./actionCreators/FeatureFlag/type";

function getFeatureFlagByEnvironment(value: boolean) {
  // value : true  - show feature/ element on page
  // value : false - hide feature/ element on page
  // return process.env.REACT_APP_FEATURE_FLAG === "true" ? value : true;
  return process.env.REACT_APP_FEATURE_FLAG === "true" ? value : true;
}
const initialState: featureFlagState = {
  sessionTableFeature: {
    tableHeader: {
      filter: getFeatureFlagByEnvironment(false),
      clearAll: getFeatureFlagByEnvironment(false),
    },
  },
  scriptTableFeature: {
    tableHeader: {
      filter: getFeatureFlagByEnvironment(false),
      clearAll: getFeatureFlagByEnvironment(false),
    },
    tableRow: {
      detailIcon: getFeatureFlagByEnvironment(false),
      favoriteIcon: getFeatureFlagByEnvironment(false),
    },
    actionMenu: {
      optionMenu: getFeatureFlagByEnvironment(false),
    },
  },
  manageContent: {
    bulkActionBtn: getFeatureFlagByEnvironment(false),
    rolePlayerPromptTypesTab: getFeatureFlagByEnvironment(false),
    casualtiesTab: getFeatureFlagByEnvironment(false),
    equipmentsTab: getFeatureFlagByEnvironment(false),
    injuriesTab: getFeatureFlagByEnvironment(false),
    supportTeamPrompts: getFeatureFlagByEnvironment(false),
  },
  manageUsersFeature: {
    tableHeader: {
      filter: getFeatureFlagByEnvironment(false),
    },
    actionMenu: {
      settingMenu: getFeatureFlagByEnvironment(false),
      pauseMenu: getFeatureFlagByEnvironment(false),
      deleteMenu: getFeatureFlagByEnvironment(true),
    },
  },
  manageAccountMenu: {
    myDashboardTab: getFeatureFlagByEnvironment(false),
    manageRolesTab: getFeatureFlagByEnvironment(false),
    organizationTab: getFeatureFlagByEnvironment(false),
    accountTab: getFeatureFlagByEnvironment(false),
    myProfile: {
      myTeamsPanel: getFeatureFlagByEnvironment(false),
    },
  },
  manageAccountsFeature: {
    tableHeader: {
      filter: getFeatureFlagByEnvironment(false),
    },
    actionMenu: {
      deleteMenu: getFeatureFlagByEnvironment(false),
    },
  },
  scriptDetailsPageFeature: {
    header: {
      heartIcon: getFeatureFlagByEnvironment(false),
      ratings: getFeatureFlagByEnvironment(false),
      forkIcon: getFeatureFlagByEnvironment(false),
    },
    inputs: {
      tags: getFeatureFlagByEnvironment(false),
      author: getFeatureFlagByEnvironment(false),
    },
  },
  detailsTab: {
    labelOverlay: getFeatureFlagByEnvironment(false),
    clothing: getFeatureFlagByEnvironment(false),
    musculature: getFeatureFlagByEnvironment(false),
    skeletal: getFeatureFlagByEnvironment(false),
    airway: getFeatureFlagByEnvironment(false),
    circulatory: getFeatureFlagByEnvironment(false),
    nervousSystem: getFeatureFlagByEnvironment(false),
    riRefreshLine: getFeatureFlagByEnvironment(false),
  },
  scriptBuilder: {
    section: {
      selectSectionTypeOptions: {
        masterMaterials: getFeatureFlagByEnvironment(false),
        approvedMosUnits: getFeatureFlagByEnvironment(false),
      },
    },
    medicationBlock: {
      skillCategorization: getFeatureFlagByEnvironment(false),
      materialRequirements: getFeatureFlagByEnvironment(false),
    },
    treatmentBlock: {
      skillCategorization: getFeatureFlagByEnvironment(false),
      materialRequirements: getFeatureFlagByEnvironment(false),
    },
    diagnosticBlock: {
      skillCategorization: getFeatureFlagByEnvironment(false),
      materialRequirements: getFeatureFlagByEnvironment(false),
    },
    miscellaneousBlock: {
      skillCategorization: getFeatureFlagByEnvironment(false),
      materialRequirements: getFeatureFlagByEnvironment(false),
    },
  },
  casualtyView: {
    casualtyObservations: getFeatureFlagByEnvironment(false),
    casualtyDiagnostics: getFeatureFlagByEnvironment(false),
    casualtyMaster: getFeatureFlagByEnvironment(false),
    casualtyStates: getFeatureFlagByEnvironment(false),
    casualtyChartTab: getFeatureFlagByEnvironment(false),
  },
  injuryTab: {
    filter: getFeatureFlagByEnvironment(false),
  },
  globalIcons: {
    scenariosIcon: getFeatureFlagByEnvironment(false),
  },
  durationTA: {
    defineDurationTime: getFeatureFlagByEnvironment(false),
  },
  startTime: {
    defineStartTime: getFeatureFlagByEnvironment(false),
  },
  ratingWithPainAndShock: {
    customRatings: getFeatureFlagByEnvironment(false),
  },
};

export const featureFlagSlice = createSlice({
  name: "featureFlag",
  initialState,
  reducers: {},
});

export default featureFlagSlice.reducer;
