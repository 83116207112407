import React from "react";
import SwitchWithLabel from "../../../../../components/Switch/SwitchWithLabel";
type Props = {
  isEnabled: boolean;
  onChange: Function;
  disabled: boolean;
};
export default function RequiredSwitch({
  isEnabled,
  onChange,
  disabled,
}: Props) {
  return (
    <div className="mb-8">
      <SwitchWithLabel
        rightLabel={"Required"}
        checked={isEnabled}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}
