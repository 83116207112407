import React, { MouseEventHandler } from "react";
import { Col } from "antd";
import { NumberParameter } from "../../../../../../../models/InsultInterface";
import { isNullOrUndefined } from "../../../../../../../utils/utilityfunctions";
import {
  NumberParameterStaticData,
  formatTimeParameter,
} from "../../CommonData";

type TimeNumberParamProps = {
  parameter: NumberParameter;
  onEditInsult: MouseEventHandler;
};

const TimeNumberParam = ({ parameter, onEditInsult }: TimeNumberParamProps) => {
  const convertSecToMinSec = (seconds: number) => {
    return `${formatTimeParameter(
      Math.floor(seconds / 60)
    )} : ${formatTimeParameter(seconds % 60)}`;
  };
  const getParameterValue = () => {
    let parameterDefault = parameter.Default;
    const staticParameterIndex = NumberParameterStaticData.findIndex(
      (p) => p.Label == parameter.UserFacingLabel
    );
    if (staticParameterIndex !== -1) {
      parameterDefault =
        NumberParameterStaticData[staticParameterIndex].Default;
    }
    const defaultValue = !isNullOrUndefined(parameter.ValueToApply)
      ? parameter.ValueToApply
      : parameterDefault;
    return convertSecToMinSec(defaultValue);
  };

  return (
    <Col className="time-param" onClick={onEditInsult}>
      <div>
        <label>{parameter.UserFacingLabel}</label>

        <span className="text-primary">{getParameterValue()}</span>

        <span className="units">min &nbsp; sec</span>
      </div>
    </Col>
  );
};

export default TimeNumberParam;
