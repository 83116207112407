import axios from "axios";
import { defaultErrorCallback } from "./ErrorCallback";

const apiPrefix = "api/1/pfc/auth";

export async function userLogin(
  requestBody: {
    Email: string;
    Password: string;
  },
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  await axios
    .post(`${apiPrefix}/login`, requestBody)
    .then(async (res: any) => {
      //do something
      if (callback != null) {
        await callback(res.data.Result);
      }
    })
    .catch(async (err: any) => {
      // catch error
      if (errorCallback != null) {
        await errorCallback(err);
      }
    });
}

export function signUp(
  payload: {
    CompanyName: string;
    Email: string;
    Password: string;
    FirstName: string;
    LastName: string;
  },
  callback: Function,
  errorCallback: Function,
) {
  axios
    .post(`${apiPrefix}/sign-up`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback();
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback !== null) {
        errorCallback(err.response.data.ErrorMessage);
      }
    });
}

export function forgotPassword(
  payload: { Email: string },
  callback: Function,
  errorCallback: Function,
) {
  axios
    .post(`${apiPrefix}/forgot-password`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback();
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback !== null) {
        errorCallback(err.response.data);
      }
    });
}

export function resetPassword(
  payload: {
    Email: string;
    Password: string;
    Token: string;
  },
  callback: Function,
  errorCallback: Function,
) {
  axios
    .post(`${apiPrefix}/reset-password`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback();
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback !== null) {
        errorCallback(err.response.data);
      }
    });
}

export function requestAccountAccess(
  payload: {
    Email: string;
    CompanyId: string;
  },
  callback: Function,
  errorCallback: Function,
) {
  axios
    .post(`${apiPrefix}/company/request-access`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback();
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback !== null) {
        errorCallback(err.response.data.ErrorMessage);
      }
    });
}

export function confirmUserEmail(
  payload: {
    UserId: string;
    Token: string;
  },
  callback: Function,
  errorCallback: Function,
) {
  axios
    .post(`${apiPrefix}/user/confirm-email`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback();
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback !== null) {
        errorCallback(err.response.data.ErrorMessage);
      }
    });
}

export function checkIfCompanyExist(
  name: string,
  callback: Function,
  errorcallback: Function = defaultErrorCallback,
) {
  axios
    .get(`${apiPrefix}/company/${name}/is-exist`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      errorcallback && errorcallback(err.response.data);
    });
}

export function acceptCreateUserAccountInvitation(
  payload: {
    Email: string;
    Password: string;
    Token: string;
    FirstName: string;
    LastName: string;
    CompanyId: string;
  },
  callback: Function,
  errorCallback: Function,
) {
  axios
    .post(`${apiPrefix}/user/accept-invitation`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback();
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback !== null) {
        errorCallback(err.response.data.ErrorMessage);
      }
    });
}
