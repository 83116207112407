import React, { useEffect, useState } from "react";
import { QueryQuestionRow, QueryQuestionSelect } from "./Query.styled";
import { Select, Spin } from "antd";
import { QuerySelectOptions } from "../../../../../models/QueryBuilder";
import { getSelectOptions } from "../../../../../api/QueryBuilder/ApiGet";
import { ChartType } from "../../../../../models/Enums";
import { isNullOrUndefined } from "../../../../../utils/utilityfunctions";

interface GetMedicalCarePerformanceProps {
  show: boolean;
  formik: any;
  queryParams: string[];
}

const GetMedicalCarePerformance = ({
  show,
  formik,
  queryParams,
}: GetMedicalCarePerformanceProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (show && queryParams.length === 0) {
      queryParams[2] = "avg";
      queryParams[3] = "rating";
      queryParams[4] = "user";
      queryParams[6] = "";

      formik.setFieldValue("queryParams", queryParams);
      formik.setFieldValue("chartType", ChartType.VerticalBar);

      if (formik.values["selectedQueryId"]) {
        setIsLoading(true);

        getSelectOptions(
          formik.values["selectedQueryId"],
          "tasktype",
          (data: QuerySelectOptions[]) => {
            if (data.length) {
              setSelectOptionsOne(data);
              onSelectChange(0, data[0].Value);
            }

            setTimeout(() => {
              setIsLoading(false);
            }, 1500);
          },
          () => {
            setTimeout(() => {
              setIsLoading(false);
            }, 1500);
          },
        );
      }
    }
  }, [show]);

  const [selectOptionsOne, setSelectOptionsOne] =
    useState<QuerySelectOptions[]>();

  const [selectOptionsTwo, setSelectOptionsTwo] =
    useState<QuerySelectOptions[]>();

  const [selectOptionsFour, setSelectOptionsFour] =
    useState<QuerySelectOptions[]>();

  useEffect(() => {
    if (formik.values["queryParams"][0]) {
      setIsLoading(true);

      getSelectOptions(
        formik.values["selectedQueryId"],
        formik.values["queryParams"][0],
        (data: QuerySelectOptions[]) => {
          if (data.length) {
            setSelectOptionsTwo(data);
            isNullOrUndefined(formik.values.queryParams[1]) &&
              onSelectChange(1, data[0].Value);
          }

          setTimeout(() => {
            setIsLoading(false);
          }, 1500);
        },
        () => {
          setTimeout(() => {
            setIsLoading(false);
          }, 1500);
        },
      );
    }
  }, [formik.values["queryParams"][0]]);

  useEffect(() => {
    if (formik.values["queryParams"][4]) {
      setIsLoading(true);

      getSelectOptions(
        formik.values["selectedQueryId"],
        formik.values["queryParams"][4],
        (data: QuerySelectOptions[]) => {
          if (data.length) {
            setSelectOptionsFour(data);
            isNullOrUndefined(formik.values.queryParams[5]) &&
              onSelectChange(5, data[0].Value);
          }

          setTimeout(() => {
            setIsLoading(false);
          }, 1500);
        },
        () => {
          setTimeout(() => {
            setIsLoading(false);
          }, 1500);
        },
      );
    }
  }, [formik.values["queryParams"][4]]);

  const onSelectChange = (index: number, value: string) => {
    queryParams[index] = value;
    formik.setFieldValue("queryParams", queryParams);
  };

  return (
    <QueryQuestionRow show={show}>
      <Spin spinning={isLoading}>
        <div className="question">
          <span>For &nbsp;</span>
          <QueryQuestionSelect
            style={{ minWidth: "7rem" }}
            value={formik.values["queryParams"][0]}
            onChange={(value) => onSelectChange(0, value)}
          >
            {selectOptionsOne?.map(({ Label, Value }: QuerySelectOptions) => (
              <Select.Option value={Value}>{Label}</Select.Option>
            ))}
          </QueryQuestionSelect>
          <span> labeled &nbsp;</span>
          <QueryQuestionSelect
            style={{ minWidth: "7rem" }}
            value={formik.values["queryParams"][1]}
            onChange={(value) => onSelectChange(1, value)}
          >
            {selectOptionsTwo?.map(({ Label, Value }: QuerySelectOptions) => (
              <Select.Option value={Value}>{Label}</Select.Option>
            ))}
          </QueryQuestionSelect>
          <span>what is the &nbsp;</span>
          <QueryQuestionSelect
            value={formik.values["queryParams"][2]}
            onChange={(value) => onSelectChange(2, value)}
          >
            <Select.Option value="avg">Average</Select.Option>
            <Select.Option value="max">Highest</Select.Option>
            <Select.Option value="min">Lowest</Select.Option>
          </QueryQuestionSelect>
          &nbsp;
          <QueryQuestionSelect
            value={formik.values["queryParams"][3]}
            onChange={(value) => onSelectChange(3, value)}
          >
            <Select.Option value="rating">Rating</Select.Option>
            <Select.Option value="completion">Completion</Select.Option>
            <Select.Option value="duration">Duration</Select.Option>
          </QueryQuestionSelect>
          <span>for each &nbsp;</span>
          <QueryQuestionSelect
            value={formik.values["queryParams"][4]}
            onChange={(value) => onSelectChange(4, value)}
          >
            <Select.Option value="user">User</Select.Option>
            <Select.Option disabled value="student">
              Student
            </Select.Option>
            <Select.Option disabled value="instructor">
              Instructor
            </Select.Option>
            <Select.Option disabled value="team">
              Team
            </Select.Option>
            <Select.Option disabled value="unit">
              Unit
            </Select.Option>
          </QueryQuestionSelect>
          <span>labeled &nbsp;</span>
          <QueryQuestionSelect
            style={{ minWidth: "7rem" }}
            value={formik.values["queryParams"][5]}
            onChange={(value) => onSelectChange(5, value)}
          >
            {selectOptionsFour?.map(({ Label, Value }: QuerySelectOptions) => (
              <Select.Option value={Value}>{Label}</Select.Option>
            ))}
          </QueryQuestionSelect>
          <span>sorted &nbsp;</span>
          <QueryQuestionSelect
            value={formik.values["queryParams"][6]}
            onChange={(value) => onSelectChange(6, value)}
          >
            <Select.Option value="">-</Select.Option>
            <Select.Option value="desc">High to Low</Select.Option>
            <Select.Option value="asc">Low to High</Select.Option>
          </QueryQuestionSelect>
        </div>
      </Spin>
    </QueryQuestionRow>
  );
};

export default GetMedicalCarePerformance;
