import * as React from "react";
import { useState } from "react";
import "./MenuList.scss";
import { NavLink, useLocation } from "react-router-dom";
import { Menu, Tooltip } from "antd";
import logo from "../assets/icons/RealX_Logo.svg";
import RealX from "../assets/icons/RealX_horizontal_white.svg";
import { capitalizeFirstLetter } from "../utils/utilityfunctions";

export default function MenuList(props: any) {
  const location = useLocation();
  const [current, setCurrent] = useState("1");
  const getActiveRouteClassName = (url: string) => {
    console.log(location.pathname);
    const currentPath = window.location.href.toLowerCase();
    const isDefaultPath = location.pathname === "/" && url === "viewscript";
    const isSelectedRoute = currentPath.includes(`/${url}`) || isDefaultPath;
    return isSelectedRoute ? "ant-menu-item-selected menuItem" : "";
  };
  const handleNavLinkClick = (e: any) => {
    e.preventDefault();
  };

  const navMenu = (link: any) => (
    <Menu.Item
      title={null}
      style={{ color: "white" }}
      className={getActiveRouteClassName(link.url.toLowerCase())}
      icon={
        typeof link.icon == "string" ? (
          <span
            className="material-symbols-outlined"
            id="mainNavIcon"
            style={{
              color: "rgba(128,128,128, 0.4)",
              fontSize: "24px",
            }}
          >
            {link.icon}
          </span>
        ) : (
          <link.icon
            className="custom-menu-icon"
            id="mainNavIcon"
            style={{
              color: "rgba(128,128,128, 0.4)",
              fontSize: "24px",
            }}
          />
        )
      }
    >
      <NavLink
        to={`/${link.url}`}
        className={`menu-text ${link.disabled ? "disabled" : ""}`}
        onClick={(e) => {
          link.disabled ? handleNavLinkClick(e) : undefined;
        }}
      >
        {capitalizeFirstLetter(link.name)}
      </NavLink>
    </Menu.Item>
  );

  return (
    <>
      <Menu
        className="sidebar-menu"
        defaultSelectedKeys={["1"]}
        theme="dark"
        mode="inline"
        onClick={(e) => setCurrent(e.key)}
        selectedKeys={[current]}
      >
        <Menu.Item
          title={null}
          style={{ color: "white" }}
          id="realXMenuItem"
          icon={
            <span
              className="material-symbols-outlined"
              id="mainNavIcon"
              style={{ color: "rgba(128,128,128, 0.4)" }}
              onClick={() => {
                console.log("Menu");
              }}
            >
              <div
                style={{ height: "50px", width: "50px" }}
                onClick={props.handleCollapseOpen}
              >
                <img
                  alt="logo"
                  style={{
                    height: "100%",
                    width: "auto",
                    maxWidth: "100%",
                    display: "inline-block",
                    objectFit: "cover",
                    verticalAlign: "text-bottom",
                  }}
                  src={logo}
                />
              </div>
            </span>
          }
        >
          <span onClick={props.handleCollapseOpen}>
            <img
              src={RealX}
              alt="Real-X"
              style={{
                width: "135px",
                verticalAlign: "middle",
              }}
            />
          </span>
        </Menu.Item>
        {props.links.map((link: any, index: any) =>
          props.collapsed ? (
            <Tooltip
              arrowPointAtCenter
              placement="right"
              title={capitalizeFirstLetter(link.name)}
            >
              {navMenu(link)}
            </Tooltip>
          ) : (
            navMenu(link)
          ),
        )}
        {["development", "staging"].includes(
          process.env.REACT_APP_ENVIR?.trim() || "",
        ) ? (
          <div className="centered">Development</div>
        ) : null}
        <Menu.Item
          title={null}
          key="contactUs"
          onClick={() => props.openSupport(true)}
          className="menu-bottom-align menuItem"
          icon={
            <span
              className="material-symbols-outlined"
              style={{ color: "rgba(128,128,128, 0.4)", fontSize: "24px" }}
            >
              phone_enabled
            </span>
          }
        >
          <span className="white-text">Contact Us</span>
        </Menu.Item>
      </Menu>
    </>
  );
}
