import React, { useEffect } from "react";
import {
  TopHeadingBlock,
  StyledRow,
  MediaUploadWrapper,
  ImagePreview,
} from "./FileUpload.styled";
import "../../css/custom.css";
import { Col, Tooltip } from "antd";
import {
  SetEditMediaDisplay,
  SetMediaCategories,
  SetPhases,
} from "../../pages/ScriptBuilder/MainlistSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Block, Phase, Section } from "../../models/ScriptInterfaces";
import AudioThumbnail from "../../assets/img/Thumbnails/audio-thumb.png";
import PdfThumbnail from "../../assets/img/Thumbnails/pdf-thumb.png";
import {
  CloseOutlined,
  FileImageOutlined,
  DownloadOutlined,
  PrinterOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import MediaDetails from "./MediaDetails";
import { WhiteIconButton } from "../../styles/Buttons.styled";
import useConfirmDialog from "../ConfirmDialog/useConfirmDialog";
import { deleteMedia } from "../../api/Media/ApiPost";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { removeMediaFromMediaList } from "./FileUploadSlice";
import produce from "immer";
import { Media } from "../../models/MediaInterface";
import { getAllMediaCategory } from "../../api/Media/ApiGet";
import { OpenErrorNotification } from "../Notification/Notification";

export default function EditMediaDetails() {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.MainlistReducer);
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();
  const phases: Phase[] = state.phases;
  const {
    currentPhaseIndex,
    currentBlockIndex,
    currentSectionIndex,
    mediaToEdit,
    mediaCategories,
  } = state;

  useEffect(() => {
    getAllMediaCategory(
      (data: any) => {
        dispatch(SetMediaCategories(data));
      },
      (error: any) => {
        OpenErrorNotification({
          description: error.message,
        });
      },
    );
  }, []);

  const onClose = () => {
    dispatch(SetEditMediaDisplay(false));
  };

  const getMediaPreview = (media: any) => {
    const mediaType = media.MediaType?.Name;
    switch (mediaType) {
      case "Image":
        return <ImagePreview src={media.Url} alt={media.Name} />;
      case "Audio":
        return <ImagePreview src={AudioThumbnail} alt={media.Name} />;
      case "Video":
        return <video src={media.Url} controls />;
      case "Document":
        if (isFilePdf(media)) {
          return (
            <embed
              src={media.Url}
              type="application/pdf"
              height="100%"
              width="100%"
            ></embed>
          );
        } else {
          return <ImagePreview src={PdfThumbnail} alt={media.Name} />;
        }
      default:
        return <ImagePreview src={PdfThumbnail} alt={media.Name} />;
    }
  };
  const isFilePdf = (media: any) => {
    if (!media.Url) return false;
    const mediaUrlArray = media.Url.split(".");
    const fileExtension: string = mediaUrlArray[mediaUrlArray.length - 1];
    return fileExtension.toLocaleLowerCase() === "pdf";
  };
  const deleteSuccess = () => {
    console.log("deleteSuccess");
    removeMediaFromMediaList(mediaToEdit?.Id || 0);
    const newPhases = produce(phases, (draftPhases: Phase[]) => {
      // delete media from all timed action block which has deleted media
      draftPhases.forEach((phase: Phase) => {
        phase.Sections.forEach((section: Section) => {
          section.Blocks?.forEach((block: Block) => {
            if (block.BlockContent?.UploadedMediaItems?.length) {
              block.BlockContent.UploadedMediaItems =
                block.BlockContent?.UploadedMediaItems.filter(
                  (media: Media) => media.Id !== mediaToEdit?.Id,
                );
            }
          });
        });
      });
    });
    dispatch(SetPhases(newPhases));
    onClose();
  };
  const error = (e: any) => {
    console.log(e);
  };
  const onDelete = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to delete the media and its related data",
      content:
        "Do you want to permanently delete this media from your media library?",
      okText: "Yes",
      onOk: () => {
        deleteMedia(mediaToEdit?.Id || 0, deleteSuccess, error);
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: () => {
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };
  return (
    <StyledRow>
      <Col
        xxl={{ span: 17 }}
        xl={{ span: 17 }}
        lg={{ span: 16 }}
        md={{ span: 16 }}
        sm={{ span: 17 }}
      >
        <MediaUploadWrapper isPdfFile={isFilePdf(mediaToEdit)}>
          <TopHeadingBlock style={{ padding: 0 }}>
            <div className="blockNameAndIcon"></div>
            <div className="iconsDiv">
              <Tooltip title={"Download"}>
                <WhiteIconButton
                  type="link"
                  size="large"
                  disabled
                  icon={<DownloadOutlined />}
                />
              </Tooltip>
              <Tooltip title={"Print"}>
                <WhiteIconButton
                  type="link"
                  size="large"
                  disabled
                  icon={<PrinterOutlined />}
                />
              </Tooltip>
              <Tooltip title={"Delete"}>
                <WhiteIconButton
                  type="link"
                  size="large"
                  onClick={onDelete}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </div>
          </TopHeadingBlock>
          <div className={`MediaImageDetail`}>
            {getMediaPreview(mediaToEdit)}
          </div>
        </MediaUploadWrapper>
      </Col>
      <Col
        xxl={{ span: 7 }}
        xl={{ span: 7 }}
        lg={{ span: 8 }}
        md={{ span: 8 }}
        sm={{ span: 7 }}
      >
        <MediaUploadWrapper>
          <TopHeadingBlock>
            <div className="blockNameAndIcon">
              <FileImageOutlined className="icon" />
              <span className="text"> MEDIA DETAILS </span>
            </div>
            <div className="iconsDiv">
              <span className="deleteSpan" onClick={onClose}>
                <button className={"StrippedButton"}>
                  <Tooltip title={"Cancel"} placement="left">
                    <CloseOutlined title="" className="iconCD" />
                  </Tooltip>
                </button>
              </span>
            </div>
          </TopHeadingBlock>
          <MediaDetails
            currentPhaseIndex={currentPhaseIndex}
            currentSectionIndex={currentSectionIndex}
            currentBlockIndex={currentBlockIndex}
            phases={phases}
            media={mediaToEdit}
            mediaCategories={mediaCategories}
          />
        </MediaUploadWrapper>
      </Col>

      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </StyledRow>
  );
}
