import React from "react";
import FloatingLabelInput from "../../../../../components/FloatingLabel/FloatingLabelInput";
import { FormRow } from "../styled";
import {
  BodyPart,
  InsultWithRelation,
} from "../../../../../models/InsultInterface";
import LocationParameter from "./EditInsult/EditParameters/LocationParameter";
import { useSelector } from "react-redux";

type InjuryFormProps = {
  formik: any;
  setIsFocused: any;
  lastFocused: any;
  setLastFocused: any;
  isFocused: any;
  bodyParts: BodyPart[];
};

// eslint-disable-next-line no-empty-pattern
const InjuryForm = ({
  formik,
  lastFocused,
  setIsFocused,
  setLastFocused,
  isFocused,
  bodyParts,
}: InjuryFormProps) => {
  const injuryObj = useSelector((state: any) => state.InsultInjury.InjuryObj); // injury for create/edit

  const filteredBodyParts = () => {
    return bodyParts.map((bodyPart: BodyPart) => {
      if (!injuryObj.Insults.length) return { ...bodyPart, disabled: false };
      if (
        injuryObj.Insults.every(
          (insult: InsultWithRelation) =>
            insult.BodyParts?.findIndex(
              (part: BodyPart) => part.Id === bodyPart.Id,
            ) !== -1,
        )
      ) {
        return { ...bodyPart, disabled: false };
      }
      return { ...bodyPart, disabled: true };
    });
  };
  return (
    <div style={{ padding: `10px 15px 0`, margin: `5px 0px` }}>
      <form onSubmit={formik.handleSubmit}>
        <FormRow style={{ marginBottom: "15px" }}>
          <FloatingLabelInput
            label={"Injury Title"}
            nameForInput={"injury_title"}
            value={formik.values["injury_title"]}
            onChange={formik.handleChange}
            scriptFormik={formik}
            lastFocused={lastFocused}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            isFocused={isFocused}
            isAddNeeded={false}
            onSave={formik.submitForm}
          />
        </FormRow>
        <FormRow>
          <LocationParameter
            formik={formik}
            bodyParts={filteredBodyParts()}
            isLocationDisableForInjuryInsult={!!injuryObj.Insults.length}
          />
        </FormRow>
      </form>
    </div>
  );
};

export default InjuryForm;
