import styled from "styled-components";
import { Button as antdButton } from "antd";

enum StyleType {
  Primary = "primary",
  Default = "default",
  Danger = "danger",
  Success = "success",
}

interface ButtonStyle {
  color: string;
  emptyStatecolor?: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  activeBackgroundColor: string;
  border: string;
}

interface ShapeStyles {
  [key: string]: ButtonStyle;
}

interface StyleByType {
  [key: string]: ShapeStyles;
}

const styleByType: StyleByType = {
  [StyleType.Primary]: {
    square: {
      color: "#DDDDDD",
      emptyStatecolor: "#DDDDDD",
      backgroundColor: "transparent",
      hoverBackgroundColor: "#08191B",
      activeBackgroundColor: "#052125",
      border: "1px solid #23D0EB",
    },
    rounded: {
      color: "#FFFFFF",
      emptyStatecolor: "#DDDDDD",
      backgroundColor: "transparent",
      hoverBackgroundColor: "rgba(35, 208, 235, 0.15)",
      activeBackgroundColor: "#0E4D56",
      border: "1px solid #23D0EB",
    },
  },
  [StyleType.Default]: {
    square: {
      color: "#DDDDDD",
      emptyStatecolor: "#DDDDDD",
      backgroundColor: "transparent",
      hoverBackgroundColor: "#131313",
      activeBackgroundColor: "#1F1F1F",
      border: "1px solid #FFFFFF",
    },
    rounded: {
      color: "#FFFFFF",
      emptyStatecolor: "#DDDDDD",
      backgroundColor: "transparent",
      hoverBackgroundColor: "rgba(255, 255, 255, 0.15)",
      activeBackgroundColor: "#363636",
      border: "1px solid #FFFFFF",
    },
  },
  [StyleType.Danger]: {
    square: {
      color: "#DDDDDD",
      emptyStatecolor: "#DDDDDD",
      backgroundColor: "transparent",
      hoverBackgroundColor: "#330D0D",
      activeBackgroundColor: "#471414",
      border: "1px solid #FF4242",
    },
    rounded: {
      color: "#FFFFFF",
      emptyStatecolor: "#DDDDDD",
      backgroundColor: "transparent",
      hoverBackgroundColor: "#330D0D",
      activeBackgroundColor: "#471414",
      border: "1px solid #FF4242",
    },
  },
  [StyleType.Success]: {
    square: {
      color: "#DDDDDD",
      emptyStatecolor: "#DDDDDD",
      backgroundColor: "transparent",
      hoverBackgroundColor: "#12190D",
      activeBackgroundColor: "#203411",
      border: "1px solid #7AFC17",
    },
    rounded: {
      color: "#FFFFFF",
      emptyStatecolor: "#DDDDDD",
      backgroundColor: "transparent",
      hoverBackgroundColor: "#12190D",
      activeBackgroundColor: "#203411",
      border: "1px solid #7AFC17",
    },
  },
};

const getButtonStyles = (
  type: StyleType = StyleType.Default,
  shape: "square"
) => {
  const styles = styleByType[type][shape];
  return styles;
};

export const StyledButton = styled(antdButton)<{
  type: StyleType;
  shape?: string;
  disabled?: boolean;
  className?: boolean;
}>`
  min-height: 40px;
  &.ant-btn {
    ${({ shape }) =>
      shape === "rounded" ? `border-radius: 50px;` : "border-radius: 3px;"}
  }

  ${({ type, shape }) => {
    const { color, backgroundColor, emptyStatecolor, border } = getButtonStyles(
      type,
      shape
    );
    return `
      color: ${emptyStatecolor};
      background: ${backgroundColor};
      border: ${border};
    `;
  }}

  &:hover {
    ${({ type, shape }) => {
      const { color, hoverBackgroundColor, border } = getButtonStyles(
        type,
        shape
      );
      return `
        color: ${color};
        background-color: ${hoverBackgroundColor};
        border: ${border};
      `;
    }}
  }

  &:active,
  &:focus {
    ${({ type, shape }) => {
      const { color, activeBackgroundColor, border } = getButtonStyles(
        type,
        shape
      );
      return `
        color: ${color};
        background-color: ${activeBackgroundColor};
        border: ${border};
      `;
    }}
  }

  :not(.disabledValidation).ant-btn[disabled] {
    background-color: transparent;
    border: 1px solid #363636;
    color: #363636;
  }

  &.ant-btn[disabled].disabledValidation {
    ${({ type, shape, disabled, className }) => {
      const { color, backgroundColor, border } = getButtonStyles(type, shape);
      return `
        color: ${color};
        background-color: ${backgroundColor};
        border: ${border};
        opacity: .5
      `;
    }}
  }
};
`;
