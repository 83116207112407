import { useEffect, useRef, useState } from "react";
import markerSDK, { MarkerSdk } from "@marker.io/browser";

export function useMarkerIO() {
  const [markerIOAPI, setMarkerIOAPI] = useState<MarkerSdk | null>(null);

  useEffect(() => {
    const markerIOProjectID = process.env.REACT_APP_MARKER_PROJECT_ID;
    if (!markerIOProjectID) {
      console.debug(
        "Skipping Marker.io setup. Reason: Missing MarkerIO Project ID in ENV",
      );
      return;
    }
    markerSDK
      .loadWidget({
        project: markerIOProjectID, // <- Your unique project ID
      })
      .then(setMarkerIOAPI);
  }, []);

  return { markerIOAPI };
}
