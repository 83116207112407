import React, { useEffect, useState } from "react";
import { QueryQuestionRow, QueryQuestionSelect } from "./Query.styled";
import FloatingLabelNumberInput from "../../../../../components/FloatingLabel/FloatingLabelNumberInput";
import { Select, Spin } from "antd";
import { ChartType } from "../../../../../models/Enums";

interface GetTrainingQualityQueryProps {
  show: boolean;
  formik: any;
  queryParams: string[];
}

const GetTrainingQualityQuery = ({
  show,
  formik,
  queryParams,
}: GetTrainingQualityQueryProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (show && queryParams.length === 0) {
      queryParams[0] = "3";
      queryParams[1] = "desc";

      formik.setFieldValue("queryParams", queryParams);
      formik.setFieldValue("chartType", ChartType.Pie);
    }
  }, [show]);

  const onNumberInputChange = (index: number, value: number) => {
    queryParams[index] = value.toString();
    formik.setFieldValue("queryParams", queryParams);
  };
  const onSelectChangeOne = (index: number, value: string) => {
    queryParams[index] = value;
    formik.setFieldValue("queryParams", queryParams);
  };

  return (
    <QueryQuestionRow show={show}>
      <Spin spinning={isLoading}>
        <div className="question">
          <span>What are the</span>
          <FloatingLabelNumberInput
            classNameForContainer="number"
            label={""}
            nameForInput={""}
            value={formik.values["queryParams"][0]}
            setValue={() => undefined}
            onChange={(value: number) => onNumberInputChange(0, value)}
            scriptFormik={formik}
            lastFocused={[]}
            isFocused={[]}
            isAddNeeded={false}
            setIsFocused={() => undefined}
            setLastFocused={() => undefined}
            isWholeNumber={true}
            maxValue={999}
            minValue={1}
            step={1}
          />
          <QueryQuestionSelect
            value={formik.values["queryParams"][1]}
            onChange={(value) => onSelectChangeOne(1, value)}
          >
            <Select.Option value="desc">Most</Select.Option>
            <Select.Option value="asc">Least</Select.Option>
          </QueryQuestionSelect>
          <QueryQuestionSelect
            disabled
            style={{ minWidth: "6rem", marginLeft: "5px" }}
            value={"played"}
            onChange={undefined}
          >
            <Select.Option value="played">Played</Select.Option>
            <Select.Option value="rated">Rated</Select.Option>
            <Select.Option value="favorited">Favorited</Select.Option>
            <Select.Option value="commited">Commented</Select.Option>
            <Select.Option value="forked">Forked</Select.Option>
            <Select.Option value="shared">Shared</Select.Option>
          </QueryQuestionSelect>
          scripts?
        </div>
      </Spin>
    </QueryQuestionRow>
  );
};

export default GetTrainingQualityQuery;
