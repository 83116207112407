import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Tooltip } from "antd";
import React, { useEffect } from "react";
import FloatingLabelInput from "../../../../../../../components/FloatingLabel/FloatingLabelInput";
import {
  InsultWithRelation,
  TextParameter,
} from "../../../../../../../models/InsultInterface";
import { TextWithDropdownParameterData } from "../../CommonData";
import { StyledFormRow, WhiteButton } from "../../InjuryTab.styled";
import SelectParameterValue from "./SelectParameterValue";

type TextParameterFormProps = {
  formik: any;
  insult: InsultWithRelation;
  param: TextParameter;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
};

const TextParameterForm = ({
  formik,
  insult,
  param,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
}: TextParameterFormProps) => {
  const getStaticTextParameterOptions = (Label: string) => {
    const staticData = TextWithDropdownParameterData.find(
      (x) => x.Label === Label && x.InsultLabel.includes(insult.Label),
    );
    return staticData ? staticData.Options : [];
  };

  useEffect(() => {
    if (
      param.UserFacingLabel?.toLocaleLowerCase() === "type" &&
      insult.Label?.toLocaleLowerCase() === "brain injury"
    ) {
      let side = "Center"; // Diffuse
      switch (formik.values["text_param_" + param.Id]?.toLocaleLowerCase()) {
        case "left focal":
          side = "Left";
          break;
        case "right focal":
          side = "Right";
          break;
        default:
          side = "Center";
      }
      formik.setFieldValue("appliedLocationSide", side);
    }
  }, [formik.values["text_param_" + param.Id]]);

  return (
    <StyledFormRow>
      <Col span={20}>
        {TextWithDropdownParameterData.findIndex(
          (p) =>
            p.Label === param.UserFacingLabel &&
            p.InsultLabel.includes(insult.Label),
        ) !== -1 ? (
          <SelectParameterValue
            label={param.UserFacingLabel}
            field={"text_param_" + param.Id}
            customClassName=""
            formik={formik}
            selectOptions={getStaticTextParameterOptions(
              param.UserFacingLabel,
            ).map((value: string) => ({
              Value: value,
              Label: value,
            }))}
          />
        ) : (
          <FloatingLabelInput
            label={param.UserFacingLabel}
            nameForInput={"text_param_" + param.Id}
            value={formik.values["text_param_" + param.Id]}
            onChange={formik.handleChange}
            scriptFormik={formik}
            lastFocused={lastFocused}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            isFocused={isFocused}
            isAddNeeded={false}
            onSave={formik.submitForm}
          />
        )}
      </Col>
      {param.Description ? (
        <Col flex={1} style={{ paddingLeft: 5 }}>
          <Tooltip title={param.Description}>
            <WhiteButton
              type="link"
              icon={<InfoCircleOutlined className="icon-lg" />}
              size="large"
            />
          </Tooltip>
        </Col>
      ) : null}
    </StyledFormRow>
  );
};

export default TextParameterForm;
