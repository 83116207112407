import "@ant-design/compatible/assets/index.css";
import {
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  ForkOutlined,
  LikeOutlined,
  ManOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Form,
  Input as AntInput,
  Menu,
  Modal,
  Row,
  Select,
  TableProps,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useFormik } from "formik";
import * as React from "react";
import { useState } from "react";
import { GoArchive } from "react-icons/go";
import { connect } from "react-redux";
import { isNullOrUndefined } from "../../../utils/utilityfunctions";
import { setAlertMessage } from "../../../actions/alertMessage";
import {
  CreateCasualty,
  deleteCasualty,
  RemoveAllergyFromCasualty,
  UpdateCasualty,
  UpdateCasualtyAllergies,
} from "../../../api/Casualty/ApiPost";
import { SystemMessage } from "../../../app/modules";
import { Table } from "../../../components";
import {
  CheckOutLinedIcon,
  DeleteIcon,
  EditOutlinedIcons,
} from "../../../IconsTheme/Icons.global";
import { Allergy, Casualty } from "../../../models/CasualtyInterfaces";
import {
  AlertType,
  BloodType,
  BloodTypeCharacter,
  Sex,
} from "../../../models/Enums";
import { AllergyMultiSelector } from "../../ScriptBuilder/EditComponents/EditForms/CasualtyFoms/AgeWeight.styled";
import { StyledForm } from "../../ScriptBuilder/EditComponents/EditForms/PhaseForm/PhaseForm.styled";
import { FormContainer } from "../../ScriptBuilder/EditComponents/EditForms/RolePlayerForm/PromptForm.styled";
import { FloatingLabel } from "../../../components/FloatingLabel/FloatingLabel.styled";
import FloatingLabelInput from "../../../components/FloatingLabel/FloatingLabelInput";
import FloatingLabelSelect from "../../../components/MuiSelect/FloatingLabelSelect";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";
import { useAppSelector } from "../../../store/hooks";

const { Option } = Select;

interface Props {
  auth: any;
  casualty: Casualty[];
  deleteCasualty: Function;
  SystemMessage: SystemMessage;
  setAlertMessage: Function;
  refreshCasualty: Function;
  allAllergies: Allergy[];
}

type ErrorType = {
  [key: string]: string;
};

const CasualtyList = (props: Props) => {
  const { bulkActionBtn } = useAppSelector(
    (state) => state.FeatureFlagReducer.manageContent,
  );
  const [modalTitle, setModalTitle] = useState<string>("");
  const [addModal, setAddModal] = React.useState<boolean>(false);
  const [bloodType] = React.useState<any>("");
  const [gender, setGender] = React.useState<Sex>(Sex.Male);
  const [height, setHeight] = React.useState<any>("");
  const [weight, setWeight] = React.useState<any>("");
  const [age, setAge] = React.useState<any>("");
  const [selectedCasualty, setSelectedCasualty] = React.useState<Casualty>({
    Id: 0,
    Description: "",
    Gender: Sex.Male,
    Height: 0,
    Weight: 0,
    WeightUnit: "kg",
    BloodType: BloodType.APositive,
    Allergies: [],
    Age: 0,
  });
  const [id, setId] = React.useState<number>(0);
  const [currentAllergies, setCurrentAllergies] = React.useState<Allergy[]>([]);
  const [selectedAllergies, setSelectedAllergies] = React.useState<number[]>(
    [],
  );
  const [selectedBloodType, setSelectedBloodType] = React.useState<BloodType>(
    BloodType.APositive,
  );
  const [isHeightFocused, setIsHeightFocused] = useState<boolean>(false);
  const [isWeightFocused, setIsWeightFocused] = useState<boolean>(false);
  const [isAgeFocused, setIsAgeFocused] = useState<boolean>(false);
  const [isDescriptionFocused, setIsDescriptionFocused] =
    useState<boolean>(false);
  const [lastFocused, setLastFocused] = React.useState({});
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();
  const labelClass = (value: any) => {
    if (value == "height") {
      return isHeightFocused || height ? "label" : "label floatingLabel";
    }
    if (value == "weight") {
      return isWeightFocused || weight ? "label" : "label floatingLabel";
    }
    if (value == "age") {
      return isAgeFocused || age ? "label" : "label floatingLabel";
    }
    if (value == "description") {
      return isDescriptionFocused || scriptFormik.values["description"]
        ? "label"
        : "label floatingLabel";
    }
  };
  const scriptFormik = useFormik({
    initialValues: {
      description: "",
      gender: "",
      height: "",
      weight: "",
      age: "",
      bloodType: BloodType.ABPositive,
      allergies: [
        {
          Id: 0,
          Type: "",
          Label: "",
        },
      ],
    },
    validate: (values) => {
      const errors: ErrorType = {};

      if (!values.height.trim()) {
        errors["height"] = "Required";
      }
      if (parseInt(values.height) > 8) {
        errors["height"] = "Cannot exceed 7 feet";
      }
      if (parseInt(values.weight) > 300) {
        errors["weight"] = "Cannot exceed 300";
      }
      if (parseInt(values.age) > 50) {
        errors["age"] = "Cannot exceed 50";
      }
      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [ViewDetaislModal, setviewDetaislModal] =
    React.useState<boolean>(false);
  const [ViewDetails, setViewDetais] = useState<any>(
    scriptFormik.initialValues,
  );

  const resetForm = () => {
    setGender(Sex.Male);
    setHeight(0);
    setWeight(0);
    setAge(0);
    setSelectedAllergies([]);
    scriptFormik.values["description"] = "";
  };

  const closeAddModal = () => {
    setAddModal(false);
  };

  const closeDetaisModal = () => {
    setviewDetaislModal(false);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "height") {
      setHeight(value);
    } else if (name === "weight") {
      setWeight(value);
    } else if (name === "age") {
      setAge(value);
    }
    scriptFormik.handleChange(e);
  };

  const success = (data: any) => {
    setId(0);
    setGender(Sex.Male);
    setHeight(0);
    setWeight(0);
    setAge(0);

    props.refreshCasualty();
    props.setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: "The Casualty was successfully created",
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });
  };

  const error = (data: never) => {
    console.log(data);
    props.setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An error occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
  };
  const CreateCasualtySuccess = (data: Casualty) => {
    const allergyIds = currentAllergies.map((x: Allergy) => x.Id!);
    UpdateCasualtyAllergies(data.Id!, allergyIds, success, error);
  };
  const UpdateCasualtySuccess = (data: Casualty) => {
    const currentAllergyIds = currentAllergies.map((x: Allergy) => x.Id);
    const oldAllergyIds = selectedCasualty.Allergies?.map((x: Allergy) => x.Id);
    console.log("reduce", currentAllergyIds);
    if (selectedCasualty.Id) {
      const filteredAddedAllergies = currentAllergyIds.filter(
        (id) => !oldAllergyIds?.includes(id),
      );
      const filteredRemovedAllergies = oldAllergyIds?.filter(
        (id) => !currentAllergyIds.includes(id),
      );
      console.log(
        "to Remove",
        filteredRemovedAllergies,
        oldAllergyIds,
        filteredRemovedAllergies?.length,
      );
      if (filteredAddedAllergies.length > 0) {
        console.log("Added", filteredAddedAllergies);
        UpdateCasualtyAllergies(
          selectedCasualty.Id!,
          filteredAddedAllergies,
          success,
          error,
        );
      }
      if (filteredRemovedAllergies!.length > 0) {
        console.log("to Remove", filteredRemovedAllergies);
        for (let i = 0; i < filteredRemovedAllergies!.length; i++) {
          RemoveAllergyFromCasualty(
            selectedCasualty.Id!,
            filteredRemovedAllergies![i],
            success,
            error,
          );
        }
      }
      success(data);
    }
    success(data);
  };
  const handleSelect = (e: Sex) => {
    console.log(e);
    setGender(e);
  };

  const add = () => {
    if (
      height !== 0 &&
      weight !== 0 &&
      scriptFormik.values["description"].trim().length > 0
    ) {
      const newCasualty: Casualty = {
        Id: id || 0,
        Description: scriptFormik.values["description"],
        Gender: gender,
        Height: height,
        Weight: weight,
        WeightUnit: "lbs",
        BloodType: selectedBloodType,
        Allergies: currentAllergies,
        Age: age,
      };
      console.log(newCasualty);
      if (id === 0) {
        CreateCasualty(newCasualty, CreateCasualtySuccess, error);
      } else {
        UpdateCasualty(newCasualty, UpdateCasualtySuccess, error);
      }

      resetForm();
    }

    setAddModal(false);
  };

  const deleteFullCasualty = (id: number) => {
    console.log(id);
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to delete the casualty and its related data",
      content: "Are you sure you want to delete this casualty permanently",
      okText: "Yes",
      onOk: () => {
        deleteCasualty(id, success, error);
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: (e) => {
        console.log(e);
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };

  const handleTransitionChange = (e: any) => {
    const allergies: Allergy[] = [];
    e.forEach((allergyId: number) => {
      const foundAllergy: any = props.allAllergies.find(
        (allergy: Allergy) => allergy.Id === allergyId,
      );
      allergies.push(foundAllergy);
    });
    setCurrentAllergies(allergies);
    setSelectedAllergies(e);
  };

  const edit = (casualty: Casualty) => {
    setId(casualty.Id!);
    setGender(casualty.Gender);
    setHeight(casualty.Height);
    setWeight(casualty.Weight);
    setAge(casualty.Age);
    setSelectedBloodType(casualty.BloodType);
    setCurrentAllergies(casualty.Allergies || []);
    setSelectedCasualty(casualty);
    if (casualty.Description != null) {
      scriptFormik.values["description"] = casualty.Description;
    }
    const currentAllergies = casualty.Allergies!.map((x: Allergy) => x.Id);
    console.log(currentAllergies);
    if (isNullOrUndefined(currentAllergies)) {
      setSelectedAllergies([]);
    } else {
      setSelectedAllergies(currentAllergies as number[]);
    }
    setAddModal(true);
  };

  const detais = (casualty: Casualty) => {
    setViewDetais({
      description: casualty.Description,
      age: casualty.Age,
      gender: Sex[casualty.Gender],
      height: casualty.Height,
      weight: casualty.Weight,
      bloodType: BloodTypeCharacter[casualty.BloodType],
      allergies: casualty.Allergies,
    });
    setviewDetaislModal(true);
  };

  const [modalId, setModalId] = useState<any>(0);
  const [currentModal, setCurrentModal] = useState<any>(false);

  const showModalForAction = (e: any) => {
    const modalBodyElement = e.target.parentNode.nextElementSibling;
    const clickedScriptPosition = e.target.getBoundingClientRect().bottom;
    const tableBody = document.getElementsByClassName(
      "ant-table-tbody",
    ) as HTMLCollectionOf<HTMLElement>;
    if (modalBodyElement != null) {
      if (modalBodyElement.className === "modal-body") {
        modalBodyElement.style.display = "block";
        if (tableBody[0].getBoundingClientRect().y < window.innerHeight) {
          tableBody[0].style.minHeight = "70vh";
        }
        if (window.innerHeight - clickedScriptPosition < 300) {
          modalBodyElement.style.bottom = "103%";
          modalBodyElement.style.top = null;
        } else {
          modalBodyElement.style.top = "103%";
          modalBodyElement.style.bottom = null;
        }
        setCurrentModal(true);
        setModalId(modalBodyElement.id);
        if (modalId === modalBodyElement.id) {
          if (currentModal) {
            document.getElementById(modalId)!.style.display = "none";
            setCurrentModal(false);
          }
        }
        if (
          modalId != null &&
          modalId !== modalBodyElement.id &&
          document.getElementById(modalId) != null
        ) {
          document.getElementById(modalId)!.style.display = "none";
        }
      }
    }
  };

  const columns: ColumnsType<Casualty> = [
    {
      title: "Description",
      dataIndex: "Description",
      key: "description",
      className: "whiteText",
      render: (description: string) => <span>{description}</span>,
      sorter: (a: any, b: any) => a.Description.localeCompare(b.Description),
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "Gender",
      className: "whiteText",
      render: (gender: number) => (
        <React.Fragment>
          {gender === 0 ? (
            <span>
              {" "}
              <ManOutlined /> Male{" "}
            </span>
          ) : (
            <span>
              {" "}
              <WomanOutlined /> Female
            </span>
          )}
        </React.Fragment>
      ),
      sorter: (a: any, b: any) => a.Gender - b.Gender,
    },
    {
      title: "Height",
      dataIndex: "Height",
      key: "Height",
      className: "whiteText",
      render: (height: number) => <span>{height}</span>,
      sorter: (a: any, b: any) => a.Height - b.Height,
    },
    {
      title: "Weight",
      dataIndex: "Weight",
      key: "weight",
      className: "whiteText",
      render: (weight: number) => <span>{weight}</span>,
      sorter: (a: any, b: any) => a.Weight - b.Weight,
    },
    {
      title: "Age",
      dataIndex: "Age",
      key: "age",
      className: "whiteText",
      render: (age: number) => <span>{age}</span>,
      sorter: (a: any, b: any) => a.Age - b.Age,
    },
    {
      title: "Blood Type",
      dataIndex: "BloodType",
      key: "BloodType",
      className: "whiteText",
      render: (bloodType: BloodType) => (
        <span>{BloodTypeCharacter[bloodType]}</span>
      ),
      sorter: (a: any, b: any) => a.BloodType - b.BloodType,
    },
    {
      title: "Allergies",
      dataIndex: "Allergies",
      key: "Allergies",
      className: "whiteText",
      render: (allergies: Allergy[]) => (
        <>
          {allergies.map((allergy: Allergy, index: number) => (
            <span key={allergy.Id + "_" + index}>
              {allergy.Type + " | " + allergy.Label}
              {index !== allergies.length - 1 ? ", " : ""}
            </span>
          ))}
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      className: "whiteText",
      render: (Id: number, casualty: any) => {
        return (
          <Row
            justify="space-between"
            onClick={(e: any) => e.stopPropagation()}
          >
            <Tooltip placement="bottom" title="">
              {props.auth.user?.type === "root" ||
              props.auth.user?.type?.includes("root") ||
              casualty.CreatedBy === props.auth.user.nameid ? (
                <div className={"modal-container"}>
                  <MoreOutlined
                    className="actions-item action-more"
                    onClick={showModalForAction}
                  />
                  <div className={"modal-body"} id={casualty.Id}>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {props.auth.user?.type === "root" ||
                      props.auth.user?.type?.includes("root") ||
                      casualty.CreatedBy === props.auth.user.nameid ? (
                        <div
                          onClick={() => {
                            setModalTitle("Edit Casualty");
                            edit(casualty);
                          }}
                        >
                          <EditOutlined className="actions-item action-edit" />
                          <div className={"tooltip-action-text"}>Edit</div>
                        </div>
                      ) : (
                        <EditOutlinedIcons className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    {/* change properties for forking */}
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {props.auth.user?.type === "root" ||
                      props.auth.user?.type?.includes("root") ||
                      casualty.CreatedBy === props.auth.user.nameid ? (
                        <div onClick={() => undefined}>
                          <ForkOutlined className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Fork</div>
                        </div>
                      ) : (
                        <ForkOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {props.auth.user?.type === "root" ||
                      props.auth.user?.type?.includes("root") ||
                      casualty.CreatedBy === props.auth.user.nameid ? (
                        <div onClick={() => undefined}>
                          <LikeOutlined className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Approve</div>
                        </div>
                      ) : (
                        <LikeOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {props.auth.user?.type === "root" ||
                      props.auth.user?.type?.includes("root") ||
                      casualty.CreatedBy === props.auth.user.nameid ? (
                        <div onClick={() => undefined}>
                          <SearchOutlined className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Review</div>
                        </div>
                      ) : (
                        <SearchOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {props.auth.user?.type === "root" ||
                      props.auth.user?.type?.includes("root") ||
                      casualty.CreatedBy === props.auth.user.nameid ? (
                        <div onClick={() => undefined}>
                          <GoArchive className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Archive</div>
                        </div>
                      ) : (
                        <ForkOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {props.auth.user?.type === "root" ||
                      props.auth.user?.type?.includes("root") ||
                      casualty.CreatedBy === props.auth.user.nameid ? (
                        <div onClick={() => deleteFullCasualty(casualty.Id)}>
                          <DeleteOutlined className="actions-item action-delete" />
                          <div className={"tooltip-action-text"}>Delete</div>
                        </div>
                      ) : (
                        <DeleteIcon className="disable-icon" />
                      )}
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <DeleteIcon className="disable-icon" />
              )}
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const onChange: TableProps<any>["onChange"] = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any,
  ) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <span className="menu-item">
              <LikeOutlined className="disable-icon" /> &nbsp; Approve
            </span>
          ),
        },
        {
          key: "2",
          label: (
            <span className="menu-item">
              <SearchOutlined className="disable-icon" /> &nbsp; Review
            </span>
          ),
        },
        {
          key: "3",
          label: (
            <span className="menu-item">
              <GoArchive className="disable-icon" /> &nbsp; Archive
            </span>
          ),
        },
        {
          key: "4",
          label: (
            <span className="menu-item">
              <DeleteOutlined className="disable-icon" /> &nbsp; Delete
            </span>
          ),
        },
      ]}
    />
  );

  return (
    <div data-testid="casualty-list">
      <Row className="flex-container-align-item-right">
        <Button
          className="btn btn-blue"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalTitle("Add Casualty");
            setAddModal(true);
          }}
        >
          Add New
        </Button>
        {bulkActionBtn && (
          <Dropdown className="btn btn-grey" overlay={menu}>
            <Button>Bulk Actions</Button>
          </Dropdown>
        )}
      </Row>

      <Table
        dataSource={props.casualty}
        columns={columns}
        rowKey={(record: any) => record.Id}
        rowClassName={() => "rxblackBG"}
        pagination={{
          hideOnSinglePage: true,
          pageSize: props.casualty.length,
        }}
        onChange={onChange}
        sortDirections={["ascend", "descend", "ascend"]}
        onRow={(record: any) => {
          return {
            onClick: () => {
              detais(record);
            }, // click row
            onDoubleClick: () => undefined, // double click row
            onContextMenu: () => undefined, // right button click row
            onMouseEnter: () => undefined, // mouse enter row
            onMouseLeave: () => undefined, // mouse leave row
          };
        }}
      />

      <Drawer
        className="custom-css-ant-drawer"
        visible={addModal}
        onClose={add}
        closable
        width="600"
      >
        <h1>
          <ArrowRightOutlined
            style={{ marginRight: "20px" }}
            onClick={closeAddModal}
          />
          {modalTitle}
        </h1>
        <FormContainer>
          <StyledForm>
            <Form.Item style={{ marginBottom: "35px" }}>
              <FloatingLabelInput
                label="Description"
                nameForInput="description"
                value={scriptFormik.values["description"]}
                style={{ textTransform: "capitalize" }}
                onChange={handleChange}
                scriptFormik={scriptFormik}
                lastFocused={lastFocused}
                isAddNeeded={false}
                setIsFocused={setIsDescriptionFocused}
                setLastFocused={setLastFocused}
                enableLiveCount={true}
                isFocused={isDescriptionFocused}
              />
            </Form.Item>

            <Form.Item style={{ marginBottom: "35px" }}>
              <FloatingLabelSelect value={gender} onChange={handleSelect}>
                <Select.Option value={Sex.Male}>
                  <ManOutlined /> Male
                </Select.Option>
                <Select.Option value={Sex.Female}>
                  <WomanOutlined /> Female
                </Select.Option>
              </FloatingLabelSelect>
            </Form.Item>
            <Form.Item style={{ marginBottom: "35px" }}>
              <FloatingLabelInput
                label="Height"
                nameForInput="height"
                value={scriptFormik.values["height"]}
                style={{ textTransform: "capitalize" }}
                onChange={handleChange}
                scriptFormik={scriptFormik}
                lastFocused={lastFocused}
                isAddNeeded={false}
                setIsFocused={setIsHeightFocused}
                setLastFocused={setLastFocused}
                enableLiveCount={true}
                isFocused={isHeightFocused}
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: "35px" }}>
              <FloatingLabelInput
                label="Weight"
                nameForInput="weight"
                value={scriptFormik.values["weight"]}
                style={{ textTransform: "capitalize" }}
                onChange={handleChange}
                scriptFormik={scriptFormik}
                lastFocused={lastFocused}
                isAddNeeded={false}
                setIsFocused={setIsWeightFocused}
                setLastFocused={setLastFocused}
                enableLiveCount={true}
                isFocused={isWeightFocused}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: "35px" }}>
              <FloatingLabelInput
                label="Age"
                nameForInput="age"
                value={scriptFormik.values["age"]}
                style={{ textTransform: "capitalize" }}
                onChange={handleChange}
                scriptFormik={scriptFormik}
                lastFocused={lastFocused}
                isAddNeeded={false}
                setIsFocused={setIsAgeFocused}
                setLastFocused={setLastFocused}
                enableLiveCount={true}
                isFocused={isAgeFocused}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: "35px" }}>
              <FloatingLabelSelect
                value={bloodType[selectedBloodType]}
                onChange={setSelectedBloodType}
                placeholder="Blood Type"
              >
                <Select.Option value={BloodType.APositive}>A+</Select.Option>
                <Select.Option value={BloodType.ANegative}>
                  {" "}
                  A&minus;
                </Select.Option>
                <Select.Option value={BloodType.ABPositive}> AB+</Select.Option>
                <Select.Option value={BloodType.ABNegative}>
                  {" "}
                  AB&minus;
                </Select.Option>
                <Select.Option value={BloodType.BPositive}> B+</Select.Option>
                <Select.Option value={BloodType.BNegative}>
                  {" "}
                  B&minus;
                </Select.Option>
                <Select.Option value={BloodType.OPositive}> O+</Select.Option>
                <Select.Option value={BloodType.ONegative}>
                  {" "}
                  O&minus;
                </Select.Option>
              </FloatingLabelSelect>
            </Form.Item>
            <Form.Item style={{ marginBottom: "35px" }}>
              <FloatingLabelSelect
                value={selectedAllergies}
                mode="multiple"
                onChange={handleTransitionChange}
                placeholder="Allergies"
              >
                {props.allAllergies.map(
                  (currentAllergy: Allergy, index: number) => (
                    <Select.Option
                      key={"objectType" + index}
                      value={currentAllergy.Id}
                    >
                      {currentAllergy.Type + " | " + currentAllergy.Label}
                    </Select.Option>
                  ),
                )}
              </FloatingLabelSelect>
            </Form.Item>
          </StyledForm>
        </FormContainer>
      </Drawer>

      {/* View details for the perticular casulty. */}
      <Drawer
        className="custom-css-ant-drawer"
        visible={ViewDetaislModal}
        onClose={closeDetaisModal}
        closable
        width="600"
      >
        <h1>
          <ArrowRightOutlined
            style={{ marginRight: "20px" }}
            onClick={closeDetaisModal}
          />{" "}
          Casualty Details
        </h1>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">Name</p>
          </Col>
          <Col span={18}>
            <p>{ViewDetails.description}</p>
          </Col>
        </Row>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">Age</p>
          </Col>
          <Col span={18}>
            <p>{ViewDetails.age}</p>
          </Col>
        </Row>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">Gender</p>
          </Col>
          <Col span={18}>
            <p>{ViewDetails.gender}</p>
          </Col>
        </Row>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">Height</p>
          </Col>
          <Col span={18}>
            <p>{ViewDetails.height}</p>
          </Col>
        </Row>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">Weight</p>
          </Col>
          <Col span={18}>
            <p>{ViewDetails.weight}</p>
          </Col>
        </Row>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">Blood Type</p>
          </Col>
          <Col span={18}>
            <p>{ViewDetails.bloodType}</p>
          </Col>
        </Row>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">Allergies</p>
          </Col>
          <Col span={18}>
            {ViewDetails.allergies.map((allergy: any) => {
              return (
                <p key={allergy.Id}>{allergy.Type + " " + allergy.Label}</p>
              );
            })}
          </Col>
        </Row>
      </Drawer>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </div>
  );
};

//export default ObjectList;
function mapToStateToProps(state: any) {
  return {
    auth: state.authReducer,
    SystemMessage: state.SystemMessage,
  };
}

export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(CasualtyList);
