import styled from "styled-components";

export const StyledImageWrapper = styled.div`
   {
    display: inline-block;
    padding: 0.75rem;
    margin: 0 0.75rem 0.75rem 0;
    position: relative;
    box-shadow: inset 0 0 15px rgb(0 0 0 / 10%), inset 0 0 0 1px rgb(0 0 0 / 5%);
    background: #131313;
    cursor: pointer;
    width: 220px;
    height: 14rem;
    .ant-image {
      width: -webkit-fill-available;
      height: auto;
      max-height: 100%;
      img {
        max-height: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    span.filename {
      display: block;
      padding: 2px;
      color: #ffffff;
      text-align: center;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
