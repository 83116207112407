import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreatorsForCasualtyStatus } from "../../../../../store";
import { useFormik } from "formik";
import { FormikErrorType } from "../../../../../models/common/FormikErrorType";
import FloatingLabelTextArea from "../../../../../components/FloatingLabel/FloatingLabelTextArea";

interface Props {
  value: string;
  onChange: Function;
}

export default function DescriptionEdit(props: Props) {
  const dispatch = useDispatch();
  const { SetLastFocused, SetIsFocused } = bindActionCreators(
    actionCreatorsForCasualtyStatus,
    dispatch,
  );
  const state = useSelector((state: any) => state.CasualtyStatusReducer);
  const { isReadOnly } = useSelector((state: any) => state.MainlistReducer);

  const scriptFormik = useFormik({
    initialValues: {
      description: props.value || "",
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      if (values.description.trim().length > 500) {
        errors["description"] = "Max 500 characters";
      }
      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const onDescriptionChange = (e: any) => {
    if (isReadOnly) return; //disable action callback in read only script
    props.onChange("Description", e.target.value);
  };
  return (
    <div className="mb-8">
      <FloatingLabelTextArea
        onChange={onDescriptionChange}
        scriptFormik={scriptFormik}
        lastFocused={state.lastFocused}
        setIsFocused={SetIsFocused}
        setLastFocused={SetLastFocused}
        isFocused={state.isFocused}
        label={"Description"}
        textareaName={"description"}
        value={scriptFormik.values["description"]}
        onSave={() => null}
        enableLiveCount={true}
        required
        maxLength={500}
        autoFocus
        readOnly={isReadOnly}
      />
    </div>
  );
}
