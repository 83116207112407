import * as React from "react";
import { BlueButton } from "../../../styles/Buttons.styled";
import { StyledScenarioInfo } from "./DetailsPanelComponents.styled";

export const ScenarioInfo = () => {
  return (
    <StyledScenarioInfo>
      <h5>Used In</h5>
      <div>
        <BlueButton className="scenario-info-btn">View Scenarios</BlueButton>
      </div>
    </StyledScenarioInfo>
  );
};
