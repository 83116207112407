import * as React from "react";
import { useFormik } from "formik";
import { getAllAllergies } from "../../../../../api/Allergy/ApiGet";
import { Allergy, Casualty } from "../../../../../models/CasualtyInterfaces";
import { BloodType } from "../../../../../models/Enums";
import {
  CreateCasualtyWithRelations,
  CreateFromMasterWithRelations,
  editCasualtyWithRelations,
  RemoveAllergyFromCasualty,
  UpdateCasualtyAllergies,
} from "../../../../../api/Casualty/ApiPost";
import { getScriptWithId } from "../../../../../api/Script/ApiGet";
import { useLocation, useParams } from "react-router";
import { getCasualtyWithId } from "../../../../../api/Casualty/ApiGet";
import { StyledForm } from "./styledComponents/StyledForm.styled";
import { CASUALTY_CREATOR_SET_CASUALTY_FORM } from "../../../../../store/CasualtyCreator/actionType";
import { useDispatch } from "react-redux";
import {
  convertCmToFeet,
  convertFeetToCm,
  convertPoundToKg,
  getCreateFromMasterWithRelationsPayload,
  getEditOrCreateCasualtyWithRelationsPayload,
  setDefaultCasualtyData,
} from "./CasualtyFormHelperFunctions";
import { FormikErrorType } from "../../../../../models/common/FormikErrorType";
import CasualtyFormFooter from "./CasualtyFormFooter";
import AllergyInput from "./Inputs/AllergyInput";
import BloodTypeInput from "./Inputs/BloodTypeInput";
import HeightInput from "./Inputs/HeightInput";
import AgeInput from "./Inputs/AgeInput";
import WeightInput from "./Inputs/WeightInput";
import GenderInput from "./Inputs/GenderInput";
import DescriptionInput from "./Inputs/DescriptionInput";
import {
  INSULT_INJURY_SET_APPLIED_INJURIES,
  INSULT_INJURY_SET_APPLIED_INSULTS,
} from "../../../../../store/CasualtyCreator/InsultInjury/actionType";
import { useAppSelector } from "../../../../../store/hooks";

type CasualtyFormProps = {
  setAllAllergies: Function;
  handleSaveCasualtyForm: Function;
  allAllergies: Allergy[];
  casualty: Casualty;
  onBack: Function;
  setScriptTitle: Function;
  setIsCasualtyEditMode: Function;
  isCasultyEditMode: boolean;
  isCasultyTableEditMode: boolean;
  setCasultyBeforeAdd: Function;
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CasualtyForm = ({
  casualty,
  allAllergies,
  setAllAllergies,
  handleSaveCasualtyForm,
  onBack,
  setScriptTitle,
  setIsCasualtyEditMode,
  isCasultyEditMode,
  isCasultyTableEditMode,
  setCasultyBeforeAdd,
}: CasualtyFormProps) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { isReadOnly } = useAppSelector(
    (state: any) => state.scriptBuilderReducer,
  );
  const [heightUnit, setHeightUnit] = React.useState("cm");
  const [weightUnit, setWeightUnit] = React.useState("kg");

  const focusedStates = {
    description: false,
    weight: false,
    age: false,
    height: false,
    inch: false,
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);

  const [casualtyAllergies, setCasualtyAllergies] = React.useState([]);

  const formik = useFormik({
    // Initial values are set for male.
    initialValues: {
      Id: casualty?.Id || 0,
      description: "",
      gender: 0,
      weight: 86,
      age: 25,
      height: 182,
      inch: "",
      bloodType: BloodType.ABPositive,
      allergies: [],
      isMasterCasualty: false,
    },
    validate: (values) => {
      const errors: FormikErrorType = {};

      // description
      if (!values["description"].trim()) {
        errors["description"] = "Required";
      } else if (values["description"].trim().length > 40) {
        errors["description"] = "Max 40 characters";
      }

      // weight
      if (!values["weight"]) {
        errors["weight"] = "Required";
      } else {
        if (weightUnit == "lbs") {
          if (Number(values["weight"]) < 80) {
            errors["weight"] = "Min 80 lbs required";
          } else if (Number(values["weight"]) > 300) {
            errors["weight"] = "Max 300 lbs allowed";
          }
        } else {
          if (Number(values["weight"]) < 36) {
            errors["weight"] = "Min 36kg required";
          } else if (Number(values["weight"]) > 136) {
            errors["weight"] = "Max 136kg allowed";
          }
        }
      }

      // age
      if (!values["age"]) {
        errors["age"] = "Required";
      } else if (Number(values["age"]) <= 17) {
        errors["age"] = "Please enter a vaild age.";
        // } else if (Number(values["age"]) > 1 && Number(values["age"]) < 18) {
        //   errors["age"] = "Pediatric model coming soon!";
        //seems unnecessary; commented out for now
      } else if (Number(values["age"]) > 65) {
        errors["age"] = "Please enter a vaild age.";
      }

      // height
      if (!values["height"]) {
        errors["height"] = "Required";
      }
      if (heightUnit == "feet") {
        // height feet
        if (values["inch"] && !values["height"]) {
          const cm = Number(values["inch"]) * 2.54;
          if (cm < 121) {
            const feetNeeded = Math.ceil((121 - cm) / 30.48); // Calcular los pies faltantes usando el mínimo de 121 cm
            errors["height"] = `Min ${feetNeeded} feet required`;
          }
        } else if (!values["inch"] && values["height"]) {
          const cm = Number(values["height"]) * 30.48;
          if (cm < 121) {
            const inchNeeded = Math.ceil((121 - cm) / 2.54);
            errors["inch"] = `Min ${inchNeeded} inch required`;
          } else if (cm > 250) {
            const inchMax = Math.floor((242 - cm) / 2.54);
            const error =
              inchMax < 1
                ? `7 feet and 11 inches maximum allowed`
                : `Max ${inchMax} inch allowed`;
            errors["inch"] = Number(values["inch"]) > 11 ? error : "";
            errors["height"] = Number(values["height"]) > 7 ? error : "";
          }
        } else {
          const totalCm =
            Number(values["height"]) * 30.48 + Number(values["inch"]) * 2.54;
          if (values["inch"] && Number(values["inch"]) > 12) {
            errors["inch"] = "Max 12 inch allowed";
          } else if (values["inch"] && Number(values["inch"]) < 1) {
            errors["inch"] = "Min 1 inch required";
          } else {
            if (totalCm < 121) {
              const inchNeeded = Math.ceil((121 - totalCm) / 2.54);
              errors["inch"] =
                `Additional ${inchNeeded} inches required to meet minimum height.`;
            } else if (totalCm > 242) {
              const inchMax = Math.floor((242 - totalCm) / 2.54);
              const error =
                inchMax < 1
                  ? `7 feet and 11 inches maximum allowed`
                  : `Max ${inchMax} inch allowed`;
              errors["inch"] = Number(values["inch"]) > 11 ? error : "";
              errors["height"] = Number(values["height"]) > 7 ? error : "";
            }
          }
        }
      } else {
        // height cm
        if (values["height"] && Number(values["height"]) < 121) {
          // Min 4 feet = 48 inches = 121.92 cm
          errors["height"] = "Min 121cm required";
        } else if (
          values["height"] &&
          Number(values["height"]) >= 121 &&
          Number(values["height"]) > 242
        ) {
          // Min 7 feet and 11 inches = 93 inches = 241 cm
          errors["height"] = "Max 241cm allowed";
        }
      }

      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      if (isReadOnly) {
        handleSaveCasualtyForm(null, false);
        return; //disable action callback in read only script
      }
      //set height for payload
      const height =
        heightUnit == "feet"
          ? convertFeetToCm(Number(values["height"]), Number(values["inch"]))
          : Number(values["height"]);

      const casualtyPayload = getEditOrCreateCasualtyWithRelationsPayload(
        values,
        height,
        weightUnit,
      );
      // edit casualty
      // if (values["Id"] && (isCasultyEditMode || isCasultyTableEditMode)) { <- This condition can be useful in future when casualty table is enabled.
      if (values["Id"]) {
        // update casualty
        if (!formik.values.isMasterCasualty) {
          casualtyPayload.Id = values["Id"];
          editCasualtyWithRelations(
            casualtyPayload,
            () => editCasualtySuccess(casualtyPayload),
            error,
          );
        } else {
          // create new casualty from master casualty
          const scriptCasualtyPayload = getCreateFromMasterWithRelationsPayload(
            values,
            height,
            weightUnit,
          );
          CreateFromMasterWithRelations(
            scriptCasualtyPayload,
            CreateCasualtySuccess,
            error,
          );
        }
      } else {
        // create new casualty
        CreateCasualtyWithRelations(
          casualtyPayload,
          CreateCasualtySuccess,
          error,
        );
      }
    },
  });

  // Change default values for new casualty when gender is changed.
  // Values are only changes if the default values for
  // male/female casualty (like age, weight, height) is not modified by user.
  React.useEffect(() => {
    if (pathname.includes("CreateScript")) {
      setDefaultCasualtyData(formik);
    }
  }, [formik.values.gender]);

  const editCasualtySuccess = (updatedCasualty: any) => {
    const currentAllergyIds = formik.values["allergies"];
    const oldAllergyIds = casualtyAllergies?.map((x: Allergy) => x.Id);
    const filteredAddedAllergies = currentAllergyIds?.filter(
      (id) => !oldAllergyIds?.includes(id),
    );
    const filteredRemovedAllergies = oldAllergyIds?.filter((id) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const neverTypeId: never = id;
      return !currentAllergyIds.includes(neverTypeId);
    });
    if (filteredAddedAllergies?.length > 0) {
      UpdateCasualtyAllergies(
        casualty.Id!,
        filteredAddedAllergies,
        () => {
          console.log("Allergies updated");
        },
        () => {
          console.log("Error occured while updating Allergies");
        },
      );
    }
    if (filteredRemovedAllergies?.length > 0) {
      for (let i = 0; i < filteredRemovedAllergies?.length; i++) {
        RemoveAllergyFromCasualty(
          casualty.Id!,
          filteredRemovedAllergies![i],
          () => {
            console.log("Allergies updated");
          },
          () => {
            console.log("Error occured while updating Allergies");
          },
        );
      }
    }
    setIsCasualtyEditMode(false);
    updatedCasualty.Allergies = currentAllergyIds?.map((Id: number) => {
      const index = allAllergies.findIndex((i) => i.Id === Id);
      if (index !== -1) {
        return allAllergies[index];
      } else {
        return null;
      }
    });
    if (isCasultyEditMode && !isCasultyTableEditMode) {
      handleSaveCasualtyForm(updatedCasualty);
    } else {
      handleSaveCasualtyForm(updatedCasualty);
    }
  };

  const CreateCasualtySuccess = (data: any) => {
    handleSaveCasualtyForm(data);
  };
  const error = (data: never) => {
    console.log("error", data);
  };
  const query = useQuery();
  const tab = React.useMemo(() => query.get("tab"), [query]);

  React.useEffect(() => {
    getAllAllergies((data: Allergy[]) => {
      setAllAllergies(data);
    });
  }, []);

  const getSuccess = (data: any) => {
    formik.values.gender = data.Casualty.Gender;
    formik.values.height = data.Casualty.Height.toString();
    formik.values.weight = data.Casualty.Weight.toString();
    formik.values.bloodType = data.Casualty.BloodType.toString();
    formik.values.age = data.Casualty.Age.toString();
  };
  React.useEffect(() => {
    if (tab === "casualty") {
      if (window.location.href.includes("/EditScript")) {
        const locationString = window.location.href;
        const scriptId: any = locationString.match(/\d/g);
        console.log("script id", scriptId!.pop());
        if (scriptId !== null) {
          const mainId = scriptId;
          getScriptWithId(parseInt(mainId.pop()), getSuccess);
        }
      }
    }
  }, [tab]);

  const urlParam: any = useParams();
  React.useEffect(() => {
    if (casualty?.Id !== undefined && casualty.Id > 0) {
      getCasualtyWithId(casualty.Id, updateCasualtyData, error);
      if (urlParam && urlParam.id) {
        getScriptWithId(urlParam.id, getScriptWithIdSuccess, error);
      }
    }
  }, [casualty]);

  const getScriptWithIdSuccess = (data: any) => {
    setScriptTitle(data.Title);
  };

  const updateCasualtyData = (data: any) => {
    const height = convertCmToFeet(data.Height);
    const weight =
      data.WeightUnit === "lbs" ? convertPoundToKg(data.Weight) : data.Weight;
    formik.setValues(
      {
        Id: data.Id,
        description: data.Description || "",
        gender: data.Gender,
        weight: weight,
        age: data.Age,
        height: data.Height,
        inch: height[1].toString(),
        bloodType: data.BloodType,
        allergies: data.Allergies?.map((a: any) => a.Id),
        isMasterCasualty: data.IsMasterCasualty,
      },
      true,
    );
    setWeightUnit("kg");
    setCasualtyAllergies(data.Allergies);
    dispatch({
      type: CASUALTY_CREATOR_SET_CASUALTY_FORM,
      data: { ...data, AllergyIds: data.Allergies?.map((a: any) => a.Id) },
    });
    setCasultyBeforeAdd(data);
    dispatch({
      type: INSULT_INJURY_SET_APPLIED_INJURIES,
      data: data.Injuries,
    });
    dispatch({
      type: INSULT_INJURY_SET_APPLIED_INSULTS,
      data: data.Insults,
    });
  };

  return (
    <>
      <StyledForm onSubmit={formik.handleSubmit}>
        <DescriptionInput
          formik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          disabled={isReadOnly}
          autoFocus
        />

        <GenderInput formik={formik} disabled={isReadOnly} />

        <AgeInput
          formik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          disabled={isReadOnly}
        />

        <WeightInput
          formik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          disabled={isReadOnly}
        />

        <HeightInput
          formik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          heightUnit={heightUnit}
          setHeightUnit={setHeightUnit}
          disabled={isReadOnly}
        />
        <BloodTypeInput formik={formik} disabled={isReadOnly} />

        <AllergyInput
          formik={formik}
          allAllergies={allAllergies}
          disabled={isReadOnly}
        />
      </StyledForm>

      <CasualtyFormFooter
        onBack={onBack}
        formik={formik}
        disabled={isReadOnly}
      />
    </>
  );
};

export default CasualtyForm;
