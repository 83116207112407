import { Button } from "antd";
import styled from "styled-components";
import { colorPalette } from "../../../../../../styles/MainList.styled";

export const GenderButton = styled(Button)`
  &:hover {
    color: #ffffff;
    background: #4d4d4d;
    border: 1px solid #ffffff;
  }
  &.selected {
    color: #ffffff;
    background-color: #04191c;
    border-color: ${colorPalette.primary100};

    &:hover {
      background-color: #1e7582;
    }
  }
`;
