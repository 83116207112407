import React from "react";
import {
  DeleteIcon,
  EditIcon,
  FilePreviewContainer,
  ImagePreview,
  MediaUploadBlock,
  PreviewList,
  UploadedMedia,
} from "./FileUpload.styled";
import { Phase } from "../../models/ScriptInterfaces";
import AudioThumbnail from "../../assets/img/Thumbnails/audio-thumb.png";
import PdfThumbnail from "../../assets/img/Thumbnails/pdf-thumb.png";
import { isArray, isNullOrUndefined } from "is-what";
import { Tooltip } from "antd";
import {
  SetEditMediaDisplay,
  SetMediaToEdit,
  SetPhases,
} from "../../pages/ScriptBuilder/MainlistSlice";
import produce from "immer";
import { useAppDispatch } from "../../store/hooks";
import { Media } from "../../models/MediaInterface";
import { PlayCircleOutlined } from "@ant-design/icons";
import { deleteMediaForTimedActionTask } from "../../api/TimedActionTask";
import { OpenErrorNotification } from "../Notification/Notification";
interface Props {
  UploadedMediaItems: Media[];
  phases: Phase[];
  currentPhaseIndex: number;
  currentSectionIndex: number;
  currentBlockIndex: number;
  blockType: string;
}
export default function UploadedMediaBlock({
  phases,
  currentPhaseIndex,
  currentSectionIndex,
  currentBlockIndex,
  UploadedMediaItems,
  blockType,
}: Props) {
  const dispatch = useAppDispatch();
  const removeFile = (file: Media) => {
    const timedActionTaskId =
      phases[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
        currentBlockIndex
      ].BlockContent.TimedActionTaskContent?.Id;
    deleteMediaForTimedActionTask(
      timedActionTaskId,
      file.Id,
      () => {
        deleteMediaSuccess(file.Id);
      },
      (error: any) => {
        // Media does not exist on timed action task remove it from state.
        if (error.ErrorCode === 404) {
          deleteMediaSuccess(file.Id);
        } else {
          OpenErrorNotification({ description: error.ErrorMessage });
        }
      },
    );
  };
  const getMediaThumbnail = (media: Media) => {
    const mediaType = media.MediaType?.Name;
    switch (mediaType) {
      case "Image":
        return <ImagePreview src={media.Url} alt={media.Name} />;
      case "Audio":
        return <ImagePreview src={AudioThumbnail} alt={media.Name} />;
      case "Video":
        return (
          <div className="video-thumbnail">
            <PlayCircleOutlined />
            <ImagePreview src={media.ThumbnailUrl} alt={media.Name} />
          </div>
        );
      default:
        return <ImagePreview src={PdfThumbnail} alt={media.Name} />;
    }
  };

  const deleteMediaSuccess = (mediaId: number) => {
    const newPhases = produce(phases, (draftPhases) => {
      draftPhases[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
        currentBlockIndex
      ].BlockContent.TimedActionTaskContent.AttachedMedias = draftPhases[
        currentPhaseIndex
      ].Sections[currentSectionIndex].Blocks[
        currentBlockIndex
      ].BlockContent.TimedActionTaskContent.AttachedMedias?.filter(
        (media: Media) => media.Id !== mediaId,
      );
    });
    dispatch(SetPhases(newPhases));
  };

  const editMedia = (media: Media) => {
    dispatch(SetMediaToEdit(media));
    dispatch(SetEditMediaDisplay(true));
  };
  return (
    <MediaUploadBlock>
      <FilePreviewContainer>
        <PreviewList>
          {isArray(UploadedMediaItems) &&
            UploadedMediaItems.map((file, index) => {
              return (
                <UploadedMedia key={index}>
                  <div className="mediaImage">{getMediaThumbnail(file)}</div>
                  <div className="mediaContent">
                    <Tooltip placement="top" title={file?.Title || file.Name}>
                      <span className="mb-3">{file?.Title || file.Name}</span>
                    </Tooltip>
                    <p className="mb-1">{file.Description}</p>
                  </div>
                  <div className="mediaEditDelete">
                    <Tooltip placement="top" title="Edit">
                      <div
                        className="mediaEdit"
                        onClick={() => editMedia(file)}
                      >
                        <EditIcon />
                      </div>
                    </Tooltip>
                    <Tooltip placement="top" title="Delete">
                      <div
                        className="mediaDelete"
                        onClick={() => removeFile(file)}
                      >
                        <DeleteIcon />
                      </div>
                    </Tooltip>
                  </div>
                </UploadedMedia>
              );
            })}
        </PreviewList>
      </FilePreviewContainer>
    </MediaUploadBlock>
  );
}
