import React, { useEffect, useState } from "react";
import {
  Form,
  HeigtContainer,
  HeightLabel,
  LabelSpan,
  LabelErrorSpan,
  Input,
  ErrorSpan,
  SuffixSpan,
  HeightContainerTwo,
} from "./AgeWeight.styled";

interface HeightProps {
  feet: string;
  inches: string;
  handleChange: any;
  onSave: any;
  heightError: any;
}

const HeightForm = (props: HeightProps) => {
  const [classNameForLabel, setClassNameForLabel] =
    useState<string>("label-name");
  useEffect(() => {
    if (props.feet.trim().length > 0) {
      setClassNameForLabel("label-name filled-label");
    } else {
      setClassNameForLabel("label-name");
    }
  }, [props.feet, props.inches]);
  return (
    <Form>
      <HeigtContainer>
        <Input
          type="number"
          name="feet"
          value={props.feet}
          id="heightFormFeetInput"
          max={7}
          min={2}
          onChange={props.handleChange}
          onBlur={props.onSave}
          style={{ width: "100%" }}
        />
        <HeightLabel
          secondContainer={false}
          id="heightLabelForFeet"
          className={classNameForLabel}
          htmlFor="feet"
        >
          <LabelSpan
            id="feetLabelInHeightForm"
            className={
              props.feet.trim().length === 0
                ? "content-name"
                : "content-name filled-content"
            }
          >
            Height
          </LabelSpan>
          <LabelErrorSpan
            style={{ display: "none", color: "red" }}
            id="feetErrorInHeightForm"
            className={
              props.feet.trim().length === 0
                ? "content-name"
                : "content-name filled-content"
            }
          >
            Max ft is 7
          </LabelErrorSpan>

          <ErrorSpan className="error"></ErrorSpan>
          <SuffixSpan>ft</SuffixSpan>
        </HeightLabel>
      </HeigtContainer>

      <HeightContainerTwo>
        <Input
          type="number"
          min={0}
          max={11.99}
          id="heightFormInchesInput"
          name="inches"
          value={props.inches}
          onChange={props.handleChange}
          onBlur={props.onSave}
          style={{ width: "100%" }}
        />

        <HeightLabel
          secondContainer={true}
          className={classNameForLabel}
          id="heightLabelForInches"
          htmlFor="inches"
        >
          <LabelErrorSpan
            style={{ display: "none", color: "red" }}
            id="inchesErrorLabelInHeightForm"
            className={
              props.inches.trim().length === 0
                ? "content-name"
                : "content-name filled-content"
            }
          >
            Max in is 11.99
          </LabelErrorSpan>
          <SuffixSpan>in</SuffixSpan>
        </HeightLabel>
      </HeightContainerTwo>
    </Form>
  );
};

export default HeightForm;
