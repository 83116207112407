import React, { useEffect, useState } from "react";
import AuthenticationPageLeftPane from "../../../components/CenterScreenCard/AuthenticationPageLeftPane";
import { Form, Row, Tooltip } from "antd";
import FloatingLabelInput from "../../../components/FloatingLabel/FloatingLabelInput";
import { BlueButton } from "../../../styles/Buttons.styled";
import { useFormik } from "formik";
import {
  EyeOutlinedIcons,
  EyeInvisibleOutlinedIcons,
} from "../../../IconsTheme/Icons.global";
import { FormikErrorType } from "../../../models/common/FormikErrorType";
import qs from "qs";
import { OpenErrorNotification } from "../../../components/Notification/Notification";
import { useHistory } from "react-router";
import { UserInvitationFormContainer } from "./UserInvitation.styed";
import { acceptCreateUserAccountInvitation } from "../../../api/Auth";
import AlertBar from "../../../app/AlertBar";

const UserInvitationFrom = () => {
  const history = useHistory();

  const focusedStates = {
    password: false,
    username: false,
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);
  const [companyName, setCompanyName] = useState<string>("");

  useEffect(() => {
    // get query params and decode it using atob.(query params are encoded using base64).
    const queryString = atob(location.search.replace("?", ""));
    const params = qs.parse(queryString, { strictNullHandling: true });

    formik.setFieldValue("email", params["e"]);
    formik.setFieldValue("token", params["t"]?.toString());
    formik.setFieldValue("companyId", params["c"]?.toString());

    setCompanyName(params["n"]?.toString() || "");
  }, [location.search]);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      token: "",
      companyId: "",
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      const regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      const passwordRegex = new RegExp(
        `^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$_!%*#?&])[A-Za-z\\d@$_!%*#?&]{8,}$`,
      );
      const alphabetRegex = new RegExp(`[a-zA-Z]`);
      const numberRegex = new RegExp(`\\d`);
      const specialCharRegex = new RegExp(`[@$_!%*#?&]`);
      if (!values.firstname.trim()) {
        errors["firstname"] = "Required";
      }
      if (!values.lastname.trim()) {
        errors["lastname"] = "Required";
      }
      if (!values.email?.trim()) {
        errors["username"] = "Required";
      } else if (!regex.test(values.email.trim())) {
        errors["username"] = "Invalid Email";
      }
      if (!values.password.trim()) {
        errors["password"] = "Required";
      } else if (values.password.trim().length < 8) {
        errors["password"] = "Min. 8 Characters Required";
      } else {
        const isAlphabetAvail = alphabetRegex.test(values.password.trim());
        const isNumberAvail = numberRegex.test(values.password.trim());
        const isSpecialCharAvail = specialCharRegex.test(
          values.password.trim(),
        );
        if (!isAlphabetAvail && !isNumberAvail) {
          errors["password"] = "Min. one alphabet and number required";
        } else if (!isAlphabetAvail && !isSpecialCharAvail) {
          errors["password"] = "Min. one alphabet and special char. required";
        } else if (!isNumberAvail && !isSpecialCharAvail) {
          errors["password"] = "Min. one number and special char. required";
        } else if (!alphabetRegex.test(values.password.trim())) {
          errors["password"] = "Min. One Alphabet Required";
        } else if (!numberRegex.test(values.password.trim())) {
          errors["password"] = "Min. One Number Required";
        } else if (!specialCharRegex.test(values.password.trim())) {
          errors["password"] = "Min. One Special Character Required";
        } else if (!passwordRegex.test(values.password.trim())) {
          errors["password"] = "Please enter strong password";
        }
      }
      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      setLoading(true);
      acceptCreateUserAccountInvitation(
        {
          Email: values["email"],
          Password: values["password"],
          CompanyId: values["companyId"],
          Token: values["token"],
          FirstName: values["firstname"],
          LastName: values["lastname"],
        },
        (data: any) => {
          // redirect to UserInvitationSuccess.tsx
          history.replace("success");
          setLoading(false);
        },
        (errorMessage: string) => {
          OpenErrorNotification({ description: errorMessage });
          setLoading(false);
        },
      );
    },
  });

  const displayPasswordIcon = () => {
    return showPassword ? (
      <Tooltip title="Hide Password">
        <EyeOutlinedIcons
          className="visibleIcon px-2"
          onClick={() => setShowPassword(false)}
        />
      </Tooltip>
    ) : (
      <Tooltip title="Show Password">
        <EyeInvisibleOutlinedIcons
          className="px-2"
          onClick={() => setShowPassword(true)}
        />
      </Tooltip>
    );
  };

  return (
    <AuthenticationPageLeftPane
      title={companyName + " has invited you to join"}
    >
      <AlertBar />
      <UserInvitationFormContainer>
        <Form onFinish={formik.handleSubmit}>
          <div className="registration-form">
            <FloatingLabelInput
              label={"Enter First Name"}
              nameForInput={"firstname"}
              type="text"
              data-testid="firstname"
              value={formik.values.firstname}
              onChange={formik.handleChange}
              scriptFormik={formik}
              lastFocused={lastFocused}
              isAddNeeded={false}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
              lastPassIgnore={false}
            />
            <FloatingLabelInput
              classNameForContainer="mt-2"
              label={"Enter Last Name"}
              nameForInput={"lastname"}
              type="text"
              data-testid="lastname"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              scriptFormik={formik}
              lastFocused={lastFocused}
              isAddNeeded={false}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
              lastPassIgnore={false}
            />
            <FloatingLabelInput
              disabled
              classNameForContainer="mt-2"
              label={"Enter Email"}
              nameForInput={"username"}
              type="email"
              data-testid="username"
              value={formik.values.email}
              onChange={formik.handleChange}
              scriptFormik={formik}
              lastFocused={lastFocused}
              isAddNeeded={false}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
              lastPassIgnore={false}
            />
            <FloatingLabelInput
              classNameForContainer="mt-2"
              label={"Create Password"}
              nameForInput={"password"}
              type={showPassword ? "text" : "password"}
              data-testid="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              scriptFormik={formik}
              lastFocused={lastFocused}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
              isAddNeeded={true}
              suffixIcon={displayPasswordIcon()}
              lastPassIgnore={false}
            />
          </div>

          <Row className="submit">
            <Form.Item>
              {loading ? (
                <BlueButton
                  rounded="true"
                  type="primary"
                  className="l2l-btn login-btn"
                  loading
                >
                  Loading
                </BlueButton>
              ) : (
                <BlueButton
                  rounded="true"
                  data-testid="submit-button"
                  htmlType="submit"
                  className="l2l-btn login-btn"
                >
                  Create account
                </BlueButton>
              )}
              <br />
            </Form.Item>
          </Row>
        </Form>
      </UserInvitationFormContainer>
    </AuthenticationPageLeftPane>
  );
};

export default UserInvitationFrom;
