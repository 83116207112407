import * as React from "react";
import { StyledStars } from "../../../components/Stars/Stars.styled";

interface RatingProps {
  starRating: number;
}

export const StarRating = (props: RatingProps) => {
  const { starRating } = props;
  return (
    <div className="script-rating">
      <h5>Rating</h5>
      <div className="rating-stars">
        <StyledStars rating={starRating} />{" "}
        <p className="rating">{starRating}/5</p>
      </div>
    </div>
  );
};
