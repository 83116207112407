import * as React from "react";
import styled from "styled-components";
import { Col } from "antd";
import { colorPalette } from "../../styles/MainList.styled";

const StyledPanel = styled.div`
  background-color: ${colorPalette.gray75};
`;

const PanelHeader = styled.div`
  border-bottom: 2px solid ${colorPalette.gray50};
  padding: 1.7rem;
  padding-top: 1.4rem;
  padding-bottom: 1rem;
  display: flex;
`;

const PanelHeaderText = styled.div`
  flex-grow: 1;
  padding-right: 1rem;
  h2,
  h3 {
    color: #fff;
    margin: 0;
  }
  h2 {
  }
  h3 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  & > p {
    font-size: 0.8rem;
    margin: 0;
  }
`;
const PanelHeaderIcon = styled.div`
  .anticon {
    font-size: 1.7rem;
    color: ${colorPalette.gray50};
  }
`;

const PanelBody = styled.div`
  padding: 1.2rem;
`;

export const PanelCol = styled(Col)`
  padding: 0.6rem;
`;

interface PanelProps {
  title: string;
  primaryText?: string;
  secondaryText?: string;
  icon?: React.ReactElement<any>;
  children?: any;
}

const Panel = (props: PanelProps) => {
  const { children, title, primaryText, secondaryText, icon } = props;

  return (
    <StyledPanel>
      <PanelHeader>
        <PanelHeaderText>
          {title && <h2>{title}</h2>}
          {primaryText && <h3>{primaryText}</h3>}
          {secondaryText && <p>{secondaryText}</p>}
        </PanelHeaderText>
        <PanelHeaderIcon>{icon ? icon : null}</PanelHeaderIcon>
      </PanelHeader>
      <PanelBody>{children}</PanelBody>
    </StyledPanel>
  );
};

export default Panel;
