import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PhaseAAR,
  VitalAAR,
} from "../../../models/AfterActionReviewInterfaces";

type intialState = {
  PhaseAARs: PhaseAAR[];
  VitalsAAR: VitalAAR;
  ActiveTaskId: number;
};

const vitalsAARInitial: VitalAAR = {
  Id: 0,
  Time: 0,
  HeartRate: 0,
  SystolicArterialPressure: 0,
  DiastolicArterialPressure: 0,
  RespirationRate: 0,
  OxygenSaturation: 0,
  MeanArterialPressure: 0,
  MeanUrineOutput: 0,
  EndTidalCarbonDioxidePressure: 0,
  PainVisualAnalogueScale: 0,
  CoreTemperature: 0,
  ScriptAARId: 0,
};

const afterActionReviewInitialState: intialState = {
  PhaseAARs: [],
  VitalsAAR: vitalsAARInitial,
  ActiveTaskId: 0,
};

const AfterActionReviewSlice = createSlice({
  name: "AfterActionReview",
  initialState: afterActionReviewInitialState,
  reducers: {
    setPhaseAARs: (state, action: PayloadAction<PhaseAAR[]>) => {
      state.PhaseAARs = action.payload;
    },

    setVitalsAAR: (state, action: PayloadAction<VitalAAR>) => {
      state.VitalsAAR = action.payload;
    },

    setActiveBlockId: (state, action: PayloadAction<number>) => {
      state.ActiveTaskId = action.payload;
    },

    setVitalsAARInitial: (state) => {
      state.VitalsAAR = vitalsAARInitial;
    },

    resetActiveTaskId: (state) => {
      state.ActiveTaskId = afterActionReviewInitialState.ActiveTaskId;
    },

    setAfterActionReviewToInitial: () => afterActionReviewInitialState,
  },
});

export default AfterActionReviewSlice.reducer;
export const {
  setPhaseAARs: setPhaseAARs,
  setVitalsAAR: setVitalsAAR,
  setActiveBlockId: setActiveBlockId,
  setVitalsAARInitial: setVitalsAARInitial,
  resetActiveTaskId: resetActiveBlockId,
  setAfterActionReviewToInitial: setAfterActionReviewToInitial,
} = AfterActionReviewSlice.actions;
