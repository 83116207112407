import { ScriptListType, VitalType } from "../../models/Enums";
import {
  CasualtyStatus,
  CriticalAction,
  Diagnostic,
  EquipmentItem,
  ListItem,
  Medication,
  ResearchQuestion,
  RolePlayerPrompt,
  ScriptEquipmentList,
  ScriptList,
  ScriptRolePlayerPrompt,
  SupportAction,
  Text,
  Treatment,
  Vital,
} from "../../models/ScriptInterfaces";

export function createText(standInType: string): Text {
  return {
    UploadedMediaItems: [],
    Id: 0,
    BlockId: 0,
    Description: "",
    DisplayedAsTwoColumns: false,
    Title: "",
    StandInType: standInType,
    IsImportant: false,
    IsTitleEnabled: true,
  };
}

export function createTreatment(
  createdInPhaseId?: number,
  createdInPhase?: number,
): Treatment {
  return {
    IsArchived: false,
    AdditionalNote: "",
    RequiredInPhaseIndex: 0,
    RequiredInPhaseOffset: 0,
    CategoryId: 1003,
    CustomLabelsEnabled: false,
    DefineStartTimeEnabled: false,
    DurationEnabled: false,
    DurationRubricExcellentThreshold: 0,
    DurationRubricPainPerMinute: 0,
    DurationRubricPassThreshold: 0,
    DurationRubricShocksPerMinute: 0,
    ExcellentCustomLabel: "",
    FailCustomLabel: "",
    IntervalEnabled: false,
    IsCognitiveSkill: false,
    IsHospitalRequired: false,
    IsMedicSkill: false,
    IsRuckRequired: false,
    IsTruckRequired: false,
    MediaEnabled: false,
    PassCustomLabel: "",
    RatingEnabled: false,
    StartTimeRubricExcellentThreshold: 0,
    StartTimeRubricPainPerMinute: 0,
    StartTimeRubricPassThreshold: 0,
    StartTimeRubricShocksPerMinute: 0,
    SubtasksEnabled: false,
    CreatedInPhaseIndex: createdInPhase!,
    CreatedInPhaseId: createdInPhaseId!,
    IsRequired: false,
    IsCritical: false,
    Abbreviation: "",
    DefineDurationTimeEnabled: false,
    Subtasks: [],
    UploadedMediaItems: [],
    Description: "",
    Id: 0,
    BlockId: 0,
    Title: "",
  };
}

export function createDiagnostic(
  createdInPhaseId?: number,
  createdInPhase?: number,
): Diagnostic {
  return {
    Label: "",
    Options: "",
    Quality: "",
    RecommendedDefaultFreq: "",
    RequiredInPhaseIndex: 0,
    RequiredInPhaseOffset: 0,
    CategoryId: 801,
    CustomLabelsEnabled: false,
    DefineStartTimeEnabled: false,
    DurationEnabled: false,
    DurationRubricExcellentThreshold: 0,
    DurationRubricPainPerMinute: 0,
    DurationRubricPassThreshold: 0,
    DurationRubricShocksPerMinute: 0,
    ExcellentCustomLabel: "",
    FailCustomLabel: "",
    IntervalEnabled: false,
    IsCognitiveSkill: false,
    IsHospitalRequired: false,
    IsMedicSkill: false,
    IsRuckRequired: false,
    IsTruckRequired: false,
    MediaEnabled: false,
    PassCustomLabel: "",
    RatingEnabled: false,
    StartTimeRubricExcellentThreshold: 0,
    StartTimeRubricPainPerMinute: 0,
    StartTimeRubricPassThreshold: 0,
    StartTimeRubricShocksPerMinute: 0,
    SubtasksEnabled: false,
    CreatedInPhaseIndex: createdInPhase!,
    CreatedInPhaseId: createdInPhaseId!,
    IsRequired: false,
    IsCritical: false,
    Abbreviation: "",
    DefineDurationTimeEnabled: false,
    Subtasks: [],
    UploadedMediaItems: [],
    Description: "",
    Id: 0,
    BlockId: 0,
    Title: "",
    IsArchived: false,
  };
}

export function createMedication(
  createdInPhaseId?: number,
  createdInPhase?: number,
): Medication {
  return {
    UseCase1: "",
    UseCase2: "",
    CurrentRouteOfAdminId: 7,
    RoutesIds: [1],
    MedicationCompounds: [],
    Routes: [],
    Label: "",
    Options: "",
    Quality: "",
    RequiredInPhaseIndex: 0,
    RequiredInPhaseOffset: 0,
    CategoryId: 33,
    CustomLabelsEnabled: false,
    DefineStartTimeEnabled: false,
    DurationEnabled: false,
    DurationRubricExcellentThreshold: 0,
    DurationRubricPainPerMinute: 0,
    DurationRubricPassThreshold: 0,
    DurationRubricShocksPerMinute: 0,
    ExcellentCustomLabel: "",
    FailCustomLabel: "",
    IntervalEnabled: false,
    IsCognitiveSkill: false,
    IsHospitalRequired: false,
    IsMedicSkill: false,
    IsRuckRequired: false,
    IsTruckRequired: false,
    MediaEnabled: false,
    PassCustomLabel: "",
    RatingEnabled: false,
    StartTimeRubricExcellentThreshold: 0,
    StartTimeRubricPainPerMinute: 0,
    StartTimeRubricPassThreshold: 0,
    StartTimeRubricShocksPerMinute: 0,
    SubtasksEnabled: false,
    CreatedInPhaseIndex: createdInPhase!,
    CreatedInPhaseId: createdInPhaseId!,
    IsRequired: false,
    IsCritical: false,
    Abbreviation: "",
    DefineDurationTimeEnabled: false,
    Subtasks: [],
    UploadedMediaItems: [],
    Description: "",
    Id: 0,
    BlockId: 0,
    Title: "",
    // new fields
    MedicationType: "",
    Method: "Infusion",
    PossibleMethodsSemicolonSeparated: "",
    Dose: 0,
    DoseUnit: "",
    DiluentVolume: 0,
    DiluentVolumeUnit: "ml",
    Diluent: "Normal Saline",
    PossibleDiluentsSemicolonSeparated: "",
    DeliveryRate: 0,
    DeliveryRateUnit: "",
    Quantity: 0,
    QuantityUnit: "",
    Concentration: 0,
    ConcentrationUnit: "",
    BagVolume: 0,
    PossibleBagVolumesSemicolonSeparated: "",
    Duration: 0,
    CurrentUseCase: "",
    CurrentMedicationOptionId: 0,
    MedicationOptions: [],
  };
}

export function createSupportAction(): SupportAction {
  return {
    Id: 0,
    BlockId: 0,
    Title: "New Support Action",
    ShowStartTime: true,
    StartTime: "5.00",
    ShowNotificationIcon: true,
  };
}

export function createCriticalAction(): CriticalAction {
  return {
    Id: 0,
    BlockId: 0,
    TypeShort: "",
    TypeLong: "",
    Title: "",
    Route: "",
    DosageNumber: 0,
    DosageUnit: "",
    OnsetDelay: "",
    Multistep: false,
    SubstepListId: 0,
    ShowStartTime: true,
    ShowDurationTime: true,
    ShowRating: true,
    ShowAsBuff: false,
    Recurring: false,
    RecurringInterval: "",
    Start1: "",
    Start2: "",
    Start3: "",
    Duration1: "",
    Duration2: "",
    Duration3: "",
  };
}

export function createScriptEquipmentList(): ScriptEquipmentList {
  return {
    Id: 0,
    BlockId: 0,
    EquipmentItems: [],
  };
}

export function createEquipmentItem(): EquipmentItem {
  return {
    Id: 0,
    Title: "Equipment",
    Description: "EquipmentItem",
    Category: "",
    ManufacturerName: "",
    ProductImage: "",
  };
}

export function createScriptList(): ScriptList {
  return {
    Id: 0,
    BlockId: 0,
    ListItems: [],
    Title: "",
    Type: ScriptListType.New,
    IsOrderedList: false,
    QuantityEnabled: false,
    DisplayedAsTwoColumns: false,
    IsTitleEnabled: true,
  };
}

export function createListItem(): ListItem {
  return {
    Order: 0,
    Id: 0,
    Title: "",
    Quantity: 0,
  };
}

export function createRolePlayerPrompt(): RolePlayerPrompt {
  return {
    Id: 0,
    RolePlayerPromptTypeId: 1,
    Text: "",
    Audio: "",
  };
}

export function createScriptRolePlayerPrompt(): ScriptRolePlayerPrompt {
  return {
    Id: 0,
    BlockId: 0,
    RolePlayerPrompts: [],
  };
}

export function createCasualtyStatus(): CasualtyStatus {
  const Vitals: Vital[] = Object.keys(VitalType)
    .filter((key: any) => isNaN(Number(VitalType[key])))
    .map((i: any) => ({
      Id: 0,
      VitalType: Number(i),
      Value: "",
      ShortNote: "",
      IsVitalVisible:
        VitalType.Pain === Number(i) || VitalType.SI === Number(i),
    }));

  return {
    Id: 0,
    Description: "",
    LevelOfConsciousness: 0,
    LevelOfConsciousnessIsDisplayed: false,
    GlasgowComaScaleIsDisplayed: false,
    GlasgowComaScaleMotor: 1,
    GlasgowComaScaleVerbal: 1,
    GlasgowComaScaleEyes: 1,
    GlasgowComaScaleTotal: 3,

    MARCHIsDisplayed: false,
    MassiveHemorrhageDescription: "",
    AirwayDescription: "",
    RespirationDescription: "",
    CirculationDescription: "",
    HypothermiaDescription: "",
    CustomTableJson: "",
    IsVitalDisplayed: false,
    Vitals,
  };
}

export function createResearchQuestion(): ResearchQuestion {
  return {
    Id: 0,
    ResearchQuestionType: 0,
    IsRequired: false,
    Question: "",
    Description: "",
    AdditionalDetails: "",
  };
}
