import React from "react";
import { StyledMediaDetails } from "./FileUpload.styled";
import { Phase } from "../../models/ScriptInterfaces";
import {
  SetMediaToEdit,
  SetPhases,
} from "../../pages/ScriptBuilder/MainlistSlice";
import produce from "immer";
import { useAppDispatch } from "../../store/hooks";
import InlineEditTextArea from "../InlineEdit/InlineEditTextArea";
import { updateMediaDetails } from "../../api/Media/ApiPost";
import { OpenErrorNotification } from "../Notification/Notification";
import { isNullOrUndefined } from "../../utils/utilityfunctions";
import { Media, MediaCategory } from "../../models/MediaInterface";
import FloatingLabelSelect from "../MuiSelect/FloatingLabelSelect";
import { Select } from "antd";
import { dateTimeConversion } from "../../utils/dateUtils";
interface Props {
  media: Media | null;
  phases: Phase[];
  currentPhaseIndex: number;
  currentSectionIndex: number;
  currentBlockIndex: number;
  mediaCategories: MediaCategory[];
}
export default function MediaDetails({
  phases,
  currentPhaseIndex,
  currentSectionIndex,
  currentBlockIndex,
  media,
  mediaCategories,
}: Props) {
  const dispatch = useAppDispatch();

  const updateMediaSuccess = (response: any, changedField: string) => {
    const newPhases = produce(phases, (draftPhases) => {
      const mediaIndex = draftPhases![currentPhaseIndex].Sections![
        currentSectionIndex
      ].Blocks![currentBlockIndex].BlockContent.UploadedMediaItems.findIndex(
        (media: any) => media.Id === response.Id
      );
      if (mediaIndex !== -1) {
        draftPhases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
          currentBlockIndex
        ].BlockContent.UploadedMediaItems[mediaIndex][changedField] =
          response[changedField];
      }
    });
    dispatch(
      SetMediaToEdit({ ...media, [changedField]: response[changedField] })
    );
    dispatch(SetPhases(newPhases));
  };
  const onTitleChange = (value: string) => {
    handleMediaDetails("Title", value);
  };
  const onDescriptionChange = (value: string) => {
    handleMediaDetails("Description", value);
  };
  const onCategoryChange = (value: string) => {
    handleMediaDetails("MediaCategoryId", value);
  };
  const handleMediaDetails = (changedField: string, value: string) => {
    const Title = changedField === "Title" ? value : media?.Title;
    const Description =
      changedField === "Description" ? value : media?.Description;
    const MediaCategoryId =
      changedField === "MediaCategoryId" ? value : media?.MediaCategoryId;
    updateMediaDetails(
      media?.Id || 0,
      Title || "",
      Description || "",
      MediaCategoryId || "",
      (data: any) => {
        if (!isNullOrUndefined(data)) {
          updateMediaSuccess(data, changedField);
        } else {
          OpenErrorNotification({
            description:
              "There was a problem with updating the file. Please try again later",
          });
        }
      },
      (error: any) => {
        console.log(error);
        OpenErrorNotification({
          description: error,
        });
      }
    );
  };
  return (
    <StyledMediaDetails>
      <div className="title">
        <InlineEditTextArea
          value={`${media?.Title || media?.Name || "Title"}`}
          uniqueId={`media_title_${media?.Id}`}
          onChange={(id: string, value: string) => onTitleChange(value)}
        />
      </div>
      <div className="description">
        <InlineEditTextArea
          value={`${
            media?.Description ? media.Description : "Write description here"
          }`}
          uniqueId={`media_description_${media?.Id}`}
          onChange={(id: string, value: string) => onDescriptionChange(value)}
        />
      </div>
      <div className="category">
        <FloatingLabelSelect
          className="w-100"
          label={"Category"}
          value={media?.MediaCategoryId || null}
          onChange={onCategoryChange}
        >
          {mediaCategories &&
            mediaCategories.length &&
            mediaCategories.map((mediaCategory: MediaCategory) => {
              return (
                <Select.Option
                  key={`${mediaCategory.Name}_${mediaCategory.Id}`}
                  value={mediaCategory.Id}
                >
                  {mediaCategory.Name}
                </Select.Option>
              );
            })}
        </FloatingLabelSelect>
      </div>
      <div className="meta">
        <div>
          <p>{media?.FileName}</p>
        </div>
        <div>
          <p>Height x Width</p>
          <p>Length (if audio/video)</p>
        </div>
        <div>
          <p>
            Upload Date:{" "}
            <b>
              {" "}
              {media?.CreatedAt
                ? dateTimeConversion(media.CreatedAt, "PPP")
                : "-"}
            </b>
          </p>
          <p>
            Upload By: <b>-</b>
          </p>
          {/* <p>Upload By: <b>{media?.CreatedBy}</b></p> */}
        </div>
        <div>
          <p>
            Last Modified:{" "}
            <b>
              {" "}
              {media?.UpdatedAt
                ? dateTimeConversion(media.UpdatedAt, "PPP")
                : "-"}
            </b>
          </p>
          <p>
            Modified By: <b>-</b>
          </p>
        </div>
      </div>
    </StyledMediaDetails>
  );
}
