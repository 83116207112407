import SwitchWithLabel from "../Switch/SwitchWithLabel";
import { WhiteButtonWithBorder } from "../../styles/Buttons.styled";
import {
  DeleteIcon,
  EditOutlinedIcons,
  PaperClipOutlinedIcons,
} from "../../IconsTheme/Icons.global";
import { SwitchContainer, UploadedMedia } from "../../styles/MainList.styled";
import * as React from "react";
import { isNullOrUndefined } from "../../utils/utilityfunctions";
import { useAppDispatch } from "../../store/hooks";
import {
  SetEditMediaDisplay,
  SetMediaToEdit,
} from "../../pages/ScriptBuilder/MainlistSlice";
import { Modal, Tooltip } from "antd";
import AudioThumbnail from "../../assets/img/Thumbnails/audio-thumb.png";
import PdfThumbnail from "../../assets/img/Thumbnails/pdf-thumb.png";
import { PlayCircleOutlined } from "@ant-design/icons";
import { Media } from "../../models/MediaInterface";

const { confirm } = Modal;

interface mediaProps {
  isReadOnly: boolean;
  isMediaEnabled: boolean;
  handleOptionToggle: Function;
  medias: Media[];
  type: string;
  deleteMedia: Function;
  onAttach: Function;
  onEdit?: Function;
}

export const MediaTA = (props: mediaProps) => {
  const dispatch = useAppDispatch();

  const DeleteMedia = (mediaId: number) => {
    props.deleteMedia(mediaId);
  };
  const editMedia = (media: Media) => {
    if (props.onEdit) {
      props.onEdit(media);
    }
    dispatch(SetMediaToEdit(media));
    dispatch(SetEditMediaDisplay(true));
  };
  const getMediaThumbnail = (media: Media) => {
    const mediaType = media.MediaType?.Name;
    switch (mediaType) {
      case "Image":
        return <img src={media.Url} alt={media.Name} />;
      case "Audio":
        return <img src={AudioThumbnail} alt={media.Name} />;
      case "Video":
        return (
          <div className="video-thumbnail">
            <PlayCircleOutlined />
            <img src={media.ThumbnailUrl} alt={media.Name} />
          </div>
        );
      default:
        return <img src={PdfThumbnail} alt={media.Name} />;
    }
  };
  const onClickAttach = () => {
    if (props.isMediaEnabled) {
      props.onAttach();
    }
  };
  return (
    <>
      <SwitchContainer
        isOptionEnabled={props.isMediaEnabled}
        isMediaAdded={
          props.isMediaEnabled &&
          !isNullOrUndefined(props.medias) &&
          props.medias.length
        }
        className="switchContainer mediaContainer durationContainer"
      >
        <div className="uploadMedia">
          <div
            className="subTaskSwitchContainer  duration"
            onClick={() => props.handleOptionToggle("IsMediaEnabled")}
          >
            <SwitchWithLabel
              isForTreatment={true}
              rightLabel={"Media"}
              checked={props.isMediaEnabled}
              onChange={() => undefined}
              disabled={props.isReadOnly}
            />
          </div>
          {!props.isReadOnly && props.isMediaEnabled ? (
            <div className="mediaUploadContainer">
              <div className="mediaUpload">
                <WhiteButtonWithBorder
                  type="link"
                  icon={<PaperClipOutlinedIcons className="visibleIcon" />}
                  size="middle"
                  className="active"
                  onClick={onClickAttach}
                >
                  Attach
                </WhiteButtonWithBorder>
              </div>
              <div className="mediaTypes">
                <span>png, jpeg, mp4, pdf, doc, wav.</span>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </SwitchContainer>
      {props.isMediaEnabled &&
        !isNullOrUndefined(props.medias) &&
        props.medias.map((media, mediaIndex) => (
          <>
            <UploadedMedia key={mediaIndex} className={`uploaded-media`}>
              <div className="mediaImage">{getMediaThumbnail(media)}</div>
              <div className="mediaContent">
                <Tooltip placement="top" title={media.Title || media.Name}>
                  <span className="mb-3">{media.Title || media.Name}</span>
                </Tooltip>
                <p className="mb-2">{media.Description}</p>
              </div>
              {props.isReadOnly ? null : (
                <div className="mediaEditDelete">
                  <Tooltip placement="top" title="Edit">
                    <div className="mediaEdit" onClick={() => editMedia(media)}>
                      <EditOutlinedIcons />
                    </div>
                  </Tooltip>
                  <Tooltip placement="top" title="Delete">
                    <div
                      className="mediaDelete"
                      onClick={() => DeleteMedia(media.Id)}
                    >
                      <DeleteIcon />
                    </div>
                  </Tooltip>
                </div>
              )}
            </UploadedMedia>
            {mediaIndex + 1 === props.medias.length ? (
              <hr style={{ border: `1px solid #101010` }} />
            ) : null}
          </>
        ))}
    </>
  );
};
