import { colorPalette } from "../../../styles/MainList.styled";
import styled from "styled-components";
import { Col, Tabs } from "antd";

export const AfterActionReviewDiv = styled.div`
  background-color: #262626;
  height: calc(100vh - 69px);
`;

export const SectionCol = styled(Col)`
  .sideBar .ant-tabs-nav-wrap {
    justify-content: end;
  }

  .ant-tabs-nav,
  .mainSectionColumn .ant-tabs-nav {
    margin: 0 !important;
  }

  .sideBar .lfNDHe {
    border-top: 0;
  }

  .sideBar .ant-tabs-nav-wrap {
    box-shadow: inset 0 0 0 1px white;
  }

  .sideBar .ant-tabs-nav {
    height: 47px !important;
    transform-style: preserve-3d;
  }

  .ant-tabs-nav-wrap {
    transform-style: preserve-3d;
  }

  .sideBar .ant-tabs-tab-active {
    border-width: 1px 1px 0 !important;
    border-style: solid !important;
    border-color: ${colorPalette.primary100} !important;
    background: rgba(35, 208, 235, 0.25) !important;
    transform: translateZ(-10px);
  }

  .chFOEv,
  .ezSwEj,
  .jWzkRx,
  .iSxpK {
    margin-top: 0 !important;
  }

  .chFOEv,
  .iSxpK {
    height: 47px !important;
  }
`;

export const VitalsCol = styled(Col)``;

export const TabStyle = styled(Tabs)`
  height: 100%;

  .ant-tabs-content-holder {
    height: 100%;
  }

  .ant-tabs-nav-wrap {
    height: 108%;
    background: #161616;
    margin-right: 5px;
    box-shadow: inset 0 -5px 0 0 ${colorPalette.gray25};
  }

  .ant-tabs-content {
    margin-top: 5px;
    height: 100%;
  }

  .ant-tabs-tab + .ant-tabs-tab {
  }

  .ant-tabs-tab {
    border-bottom: 5px solid ${colorPalette.gray25} !important;
    padding: 12px 12px !important;
    min-width: 120px;
    margin-left: 0 !important;
  }

  .ant-tabs-tab:hover {
    color: #fff;
  }

  .ant-tabs-nav {
    background-color: ${colorPalette.gray75};
  }

  .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-tab {
    border-left: none !important;
    border-right: none !important;
    box-shadow: inset -1px 0 0 rgba(128, 128, 128, 0.1);
    border-top: none !important;
    background: #161616 !important;
  }

  .ant-tabs-nav-add {
    margin: 4px;
    border: 1px dashed rgba(255, 255, 255, 0.75) !important;
    border-radius: 53px !important;
    margin-left: 10px !important;
    height: 35px !important;
    min-width: 35px !important;
  }

  .ant-tabs-nav-add:hover {
    background: #4d4d4d;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
  }

  .ant-tabs-tab .anticon {
    margin-right: 0 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: ${colorPalette.primary25} !important;
    border-bottom: 5px solid ${colorPalette.primary100} !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f0f0f0 !important;
  }

  .ant-tabs-tab-btn {
    margin: auto;
  }

  .ant-tabs-content {
    height: 100%;
    width: 100%;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fill-available;
    overflow-y: auto;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    margin-right: 5px;

    ::-webkit-scrollbar-track {
      background-color: #131313;
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      max-height: 20px;
      background: #23d0eb;
      border-radius: 50px;
    }
  }

  .ant-tabs-content::-webkit-scrollbar {
    width: 5px;
  }
`;
