import { Spin } from "antd";
import React, { useState, useEffect } from "react";
import { AllSessionAAR } from "../../api/SessionAAR/ApiGet";
import SessionTableFilter from "../Session/SessionTableFilter";
import { NavigationTitle } from "../../components/GlobalStyledComponents.styled";
import { militaryDateTimeConversion } from "../../utils/dateUtils";
import { CustomTableContainer } from "../../components/Table/CustomTable.styled";
import User from "../../models/User";
import { getAuthorName } from "../../utils/utilityfunctions";

export default function Session() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    AllSessionAAR(getSuccess, getError);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSuccess = (data: any) => {
    setResult(data);
    setLoading(false);
  };

  const getError = (err: any) => {
    console.error(err);
    setLoading(false);
  };

  const error = (err: any) => {
    console.error(err);
    setLoading(false);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "Title",
      key: "title",
      className: "whiteText script-title",
      sorter: (a: any, b: any) => a.Title && a.Title.localeCompare(b.Title),
      render: (title: any) => (
        <span style={{ textTransform: "capitalize", color: "#fff" }}>
          {title}
        </span>
      ),
    },
    {
      title: "Instructor",
      dataIndex: "CreatedBy",
      key: "CreatedBy",
      className: "whiteText",
      width: "200px",
      sorter: (a: any, b: any) =>
        getAuthorName(a.CreatedBy)?.localeCompare(getAuthorName(b.CreatedBy)),
      render: (author: User) => {
        return <span>{getAuthorName(author) || ""}</span>;
      },
    },
    {
      title: "Completed On",
      dataIndex: "EndDateTime",
      key: "EndDateTime",
      className: "whiteText",
      width: "200px",
      sorter: (a: any, b: any) =>
        new Date(b.EndDateTime).getTime() > new Date(a.EndDateTime).getTime(),
      render: (EndDateTime: any) => {
        return <span>{militaryDateTimeConversion(EndDateTime)}</span>;
      },
    },
    {
      title: "Created On",
      dataIndex: "CreatedDateTime",
      key: "CreatedDateTime",
      className: "whiteText",
      width: "200px",
      sorter: (a: any, b: any) =>
        new Date(b.CreatedDateTime).getTime() >
        new Date(a.CreatedDateTime).getTime(),
      render: (CreatedDateTime: any) => {
        return <span>{militaryDateTimeConversion(CreatedDateTime)}</span>;
      },
    },
  ];
  return (
    <>
      <NavigationTitle className="script-library-title">
        Sessions
      </NavigationTitle>
      <CustomTableContainer>
        <Spin spinning={loading}>
          <SessionTableFilter columns={columns} data={result} />
        </Spin>
      </CustomTableContainer>
    </>
  );
}
