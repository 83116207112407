import { ArrowLeftOutlined, PlusCircleOutlined } from "@ant-design/icons";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  AppliedInsultWithRelation,
  InsultWithRelation,
} from "../../../../../models/InsultInterface";
import { AppliedInjury, Injury } from "../../../../../models/InjuryInterface";
import { FooterWithButtons, GreyButton } from "../styled";
import InjuryItem from "./AppliedCasualtyInjuryList/InjuryItem";
import { StyledBody, StyledInsultItem, WhiteButton } from "./InjuryTab.styled";
import CreateNewInsultInjuryBtn from "./AppliedCasualtyInjuryList/CreateNewInsultInjuryButton";
import { Modal, Tooltip } from "antd";
import { InjuryViewTypes } from "../constants";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../../../../store/hooks";
import ConfirmDialog from "../../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../../components/ConfirmDialog/useConfirmDialog";

type SelectInsultInjuryProps = {
  casualtyId: number;
  onInsultSelection: Function;
  onInjurySelection: Function;
  onBack: Function;
  handleClickAddInjury: Function;
};

const SelectInsultInjury = ({
  casualtyId,
  onInsultSelection,
  onInjurySelection,
  onBack,
  handleClickAddInjury,
}: SelectInsultInjuryProps) => {
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();
  const [sortedInjuries, setSortedInjuries] = useState<AppliedInjury[]>([]);
  const injuryList = useSelector(
    (state: any) => state.InsultInjury.FilteredInjuries,
  );
  const insultList = useSelector(
    (state: any) => state.InsultInjury.FilteredInsults,
  );

  const filteredAppliedInjuries = useAppSelector(
    (state: any) => state.InsultInjury.FilteredAppliedInjuries,
  );
  const filteredAppliedInsults = useAppSelector(
    (state: any) => state.InsultInjury.FilteredAppliedInsults,
  );

  const matchInsultWithBioGear = (insultTitle: string) => {
    const MissingFromBioGear = [
      "Fracture",
      "Compartment Syndrome",
      "Chemical Exposure",
      "Large Pneumothorax",
      "Open Pneumothorax",
      "Breath Hold",
      "Forced Exhale",
      "Forced Inhale",
    ];
    return MissingFromBioGear.findIndex((x) => x == insultTitle) !== -1
      ? true
      : false;
  };
  const handleInsultSelection = (insult: InsultWithRelation) => {
    if (matchInsultWithBioGear(insult.Label)) {
      return;
    }
    // Check if insult already applied to the casualty.
    if (
      filteredAppliedInsults.find(
        (appliedInsult: AppliedInsultWithRelation) =>
          appliedInsult.Label === insult.Label,
      )
    ) {
      const appliedInsult: AppliedInsultWithRelation = structuredClone(
        filteredAppliedInsults.find(
          (appliedInsult: AppliedInsultWithRelation) =>
            appliedInsult.Label === insult.Label,
        ),
      );
      appliedInsult.Locations = insult.Locations;
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "Insult",
        content: `${appliedInsult.Label} already exist. Would you like to edit existing one?`,
        okText: "Yes",
        onOk: () => {
          onInsultSelection(appliedInsult); // Edit existing Insult.
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          onInsultSelection(insult);
          setIsOpenConfirmDialog(false);
        }, // Add new insult.
        type: "warning",
      });
    }
    onInsultSelection(insult);
  };
  const handleInjurySelection = (injury: Injury) => {
    console.log("handleInjurySelection", injury);
    console.log("Disable Add Injury Flow");
    onInjurySelection(injury);
  };
  useEffect(() => {
    const injuries = [...injuryList];
    injuries.sort((x, y) => x.Title.localeCompare(y.Title));
    setSortedInjuries(injuries);
    return () => {
      setSortedInjuries([]);
    };
  }, [injuryList]);

  return (
    <>
      <StyledBody className="select-injury-insult">
        {/* insult list */}
        {insultList && insultList.length ? (
          <ul>
            {insultList.map((insult: InsultWithRelation) => {
              return (
                <StyledInsultItem
                  $insult={insult}
                  key={insult.CopySourceInsultId}
                  className={
                    matchInsultWithBioGear(insult.Label) ? `disabled` : ``
                  }
                  onClick={() => handleInsultSelection(insult)}
                >
                  <div>{insult.Label}</div>
                  <Tooltip title={`Add & Edit Insult`}>
                    <WhiteButton
                      type="link"
                      icon={<PlusCircleOutlined />}
                      size="large"
                    ></WhiteButton>
                  </Tooltip>
                </StyledInsultItem>
              );
            })}
          </ul>
        ) : null}
        {/* injury list */}
        {injuryList && injuryList.length ? (
          <ul>
            {sortedInjuries.map((injury: AppliedInjury) => {
              return (
                <InjuryItem
                  className="select-injury-insult"
                  key={injury.Id}
                  casualtyId={casualtyId}
                  injury={injury}
                  currentView={InjuryViewTypes.SELECT_INSULT_INJURY}
                  onEditInjury={() => undefined}
                  onEditInsult={() => undefined}
                  onInjurySelection={handleInjurySelection}
                  handleOpenCreateEditInjury={() => undefined}
                />
              );
            })}
          </ul>
        ) : null}
        <CreateNewInsultInjuryBtn
          onAddClick={handleClickAddInjury}
          label="Add New Injury"
        />
      </StyledBody>
      <FooterWithButtons style={{ backgroundColor: "#131313" }}>
        <Tooltip title="Back to Casualty">
          <GreyButton htmlType="button" onClick={() => onBack()}>
            <ArrowLeftOutlined /> &nbsp; Back
          </GreyButton>
        </Tooltip>
      </FooterWithButtons>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </>
  );
};

export default SelectInsultInjury;
