import * as React from "react";
import { Table as AntTable } from "antd";
import { TableProps } from "antd/lib/table";
import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";

const StyledAntTable = styled(AntTable)`
  .ant-table,
  .ant-table svg {
    color: #fff;
  }

  .ant-table-thead {
    .ant-table-column-sorters {
      display: inline-flex;
      flex-direction: row-reverse;
    }

    .ant-table-column-title {
      margin-left: 5px;
    }
  }

  .ant-table-cell {
    border-bottom: 5px solid ${colorPalette.gray75};
  }

  .ant-table {
    .color-light-green {
      & svg {
        color: ${colorPalette.secondary100};
      }
    }

    .color-red {
      & svg {
        color: ${colorPalette.error};
      }
    }

    .color-yellow {
      & svg {
        color: ${colorPalette.moderate};
      }
    }

    .color-light-blue {
      & svg {
        color: ${colorPalette.primary100};
      }
    }

    .color-grey {
      & svg {
        color: #898989;
      }
    }
  }
`;

const Input = (props: TableProps<any>) => {
  return <StyledAntTable {...props} />;
};

export default Input;
