import axios from "axios";
import { defaultErrorCallback } from "../ErrorCallback";

const apiPrefix = "/api/1/pfc/User";

export function getUsersInCompany(
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .get(`${apiPrefix}/in-current-company/`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorCallback != null) {
        errorCallback(err.response.data.errorMessage);
      }
    });
}

export async function getCurrentLoggedInUserInfo(
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .get(`${apiPrefix}/current-logged-in-user`)
    .then(async (res) => {
      //do something

      if (callback != null) {
        await callback(res.data.Result);
      }
    })
    .catch(async (err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorCallback != null) {
        await errorCallback(err);
      }
    });
}
