import React from "react";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import { StyledStarButton } from "./StarButton.styled";

interface StarButtonProps {
  checked: boolean;
}

const StarButton = ({ checked }: StarButtonProps) => {
  return (
    <StyledStarButton>
      {checked ? (
        <StarFilled className="star-btn active" />
      ) : (
        <StarOutlined className="star-btn" />
      )}
    </StyledStarButton>
  );
};

export default StarButton;
