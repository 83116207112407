import React, { useEffect, useState } from "react";
// import FemaleFrontNude from "../../../../../assets/img/HumanModels/Female/Anatomy_Female_Front_Nude.png";
// import FemaleBackNude from "../../../../../assets/img/HumanModels/Female/Anatomy_Female_Back_Nude.png";
// import FemaleFrontWithCloths from "../../../../../assets/img/HumanModels/Female/Anatomy_Female_Front_Underwear.png";
// import FemaleBackWithCloths from "../../../../../assets/img/HumanModels/Female/Anatomy_Female_Back_Underwear.png";
import FemaleFrontLineArt from "../../../../../../assets/img/HumanModels/Female/Lineart_Female_Front.png";
import FemaleBackLineArt from "../../../../../../assets/img/HumanModels/Female/Lineart_Female_Back.png";
// import MaleFrontNude from "../../../../../assets/img/HumanModels/Male/Anatomy_Male_Front_Nude.png";
// import MaleBacktNude from "../../../../../assets/img/HumanModels/Male/Anatomy_Male_Back_Nude.png";
// import MaleFrontWithCloths from "../../../../../assets/img/HumanModels/Male/Anatomy_Male_Front_Underwear.png";
// import MaleBackWithCloths from "../../../../../assets/img/HumanModels/Male/Anatomy_Male_Back_Underwear.png";
import MaleFrontLineArt from "../../../../../../assets/img/HumanModels/Male/Lineart_Male_Front.png";
import MaleBackLineArt from "../../../../../../assets/img/HumanModels/Male/Lineart_Male_Back.png";
import { Sex } from "../../../../../../models/Enums";
import { connect } from "react-redux";
import { setAlertMessage } from "../../../../../../actions/alertMessage";
import { Human2DModelContainer } from "./Human2DModel.styled";
import { Spin } from "antd";

interface Human2DModelProps {
  modelType: Sex | undefined;
  isFront: boolean;
  isModelClothsOn: boolean;
}

const Human2DModel = ({
  modelType,
  isFront,
  isModelClothsOn,
}: Human2DModelProps) => {
  const [defaulModel, setDefaultModel] = useState("");
  const [defaulAltText, setDefaulAltText] = useState("");

  useEffect(() => {
    switch (modelType) {
      case Sex.Male: {
        if (isFront) {
          // if (isModelClothsOn) {
          //     setDefaultModel(MaleFrontLineArt);
          // } else {
          //     setDefaultModel(MaleBackLineArt);
          // }
          setDefaultModel(MaleFrontLineArt);
        } else {
          // if (isModelClothsOn) {
          //     setDefaultModel(MaleFrontLineArt);
          // } else {
          //     setDefaultModel(MaleBackLineArt);
          // }
          setDefaultModel(MaleBackLineArt);
        }
        setDefaulAltText("Image of male model with cloths on");
        break;
      }
      case Sex.Female: {
        if (isFront) {
          setDefaultModel(FemaleFrontLineArt);
          // if (isModelClothsOn) {
          //     setDefaultModel(FemaleFrontLineArt);
          // } else {
          //     setDefaultModel(FemaleFrontLineArt);
          // }
        } else {
          setDefaultModel(FemaleBackLineArt);
          // if (isModelClothsOn) {
          //     setDefaultModel(FemaleBackLineArt);
          // } else {
          //     setDefaultModel(FemaleBackLineArt);
          // }
        }
        setDefaulAltText("Image of female model with cloths on");
        break;
      }
      default: {
        setDefaultModel("");
        setDefaulAltText("Please select casulty to see Human model");
        break;
      }
    }
  }, [modelType, isFront, isModelClothsOn]);

  return (
    <Human2DModelContainer>
      <img className="human-img" src={defaulModel} alt={defaulAltText} />
    </Human2DModelContainer>
  );
};

function mapToStateToProps(state: any) {
  return {
    SystemMessage: state.SystemMessage,
    auth: state.authReducer,
  };
}

export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(Human2DModel);
