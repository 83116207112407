import React from "react";
import FloatingLabelSelect from "../../../../../components/MuiSelect/FloatingLabelSelect";
import { ResearchQuestionSelectType } from "../../../../../models/Enums";
import {
  ResearchQuestionSelect,
  ResearchQuestionSelectAnswer,
} from "../../../../../models/ScriptInterfaces";
import {
  StyledListTitleItem,
  TitleSpan,
  Ul,
} from "../../ShowList/ShowList.styled";
interface Props {
  scriptFormik: any;
  question: ResearchQuestionSelect;
}
export default function SelectTypeQuestion(props: Props) {
  return (
    <div className="mb-2">
      <FloatingLabelSelect
        label=""
        className="w-100"
        onChange={() => undefined}
        value={props.question.Placeholder || "Placeholder"}
        disabled
      ></FloatingLabelSelect>
      <Ul>
        {props.question.ResearchQuestionSelectAnswers.map(
          (option: ResearchQuestionSelectAnswer, Index: number) => (
            <li key={`option-${option?.Id || Index}`}>
              <StyledListTitleItem className="titleIndexDiv">
                <TitleSpan>
                  {props.question?.ResearchQuestionSelectType ===
                  ResearchQuestionSelectType.MultiSelect ? (
                    <span className="checkbox"></span>
                  ) : null}
                  {option.Answer}
                </TitleSpan>
              </StyledListTitleItem>
            </li>
          )
        )}
      </Ul>
    </div>
  );
}
