import { MultipleSwitchContainer } from "../../styles/MainList.styled";
import SwitchWithLabel from "../Switch/SwitchWithLabel";
import RatingWithPainAndShock from "../TreatmentEdit/RatingWithPainAndShock";
import * as React from "react";
import { useAppSelector } from "../../store/hooks";
import { Tooltip } from "antd";

interface RatingProps {
  isCustomLabelsEnabled: boolean;
  isRatingEnabled: boolean;
  handleOptionToggle: Function;
  isReadOnly: boolean;
}

export const RatingTA = (props: RatingProps) => {
  const { customRatings } = useAppSelector(
    (state) => state.FeatureFlagReducer.ratingWithPainAndShock,
  );
  return (
    <div className="durationContainer ratingCustomLabels">
      <MultipleSwitchContainer
        className="durationToggleContainer"
        isSecondSwitchEnabled={props.isCustomLabelsEnabled}
        isFirstSwitchEnabled={props.isRatingEnabled}
      >
        <div
          className="duration"
          onClick={() => props.handleOptionToggle("IsRatingEnabled")}
        >
          <SwitchWithLabel
            isForTreatment={true}
            rightLabel={"Rating"}
            checked={props.isRatingEnabled}
            onChange={() => undefined}
            disabled={props.isReadOnly}
          />
        </div>
        {props.isRatingEnabled && customRatings && (
          <Tooltip title="Hidden in production" placement="bottom">
            <div
              className="durationTime"
              onClick={() => props.handleOptionToggle("IsCustomLabelsEnabled")}
            >
              <SwitchWithLabel
                isForTreatment={true}
                rightLabel={"Custom Labels"}
                checked={props.isCustomLabelsEnabled}
                onChange={() => undefined}
                disabled={props.isReadOnly}
              />
            </div>
          </Tooltip>
        )}
      </MultipleSwitchContainer>
      {props.isRatingEnabled && (
        <div className="ratings-container">
          <RatingWithPainAndShock
            ratingType={"Excellent"}
            ratingTime={`5`}
            isCustomLabelActive={props.isCustomLabelsEnabled}
            isRatingSwitch={true}
            isReadOnly={props.isReadOnly}
          />
          <RatingWithPainAndShock
            ratingType={"Pass"}
            ratingTime={"10"}
            isCustomLabelActive={props.isCustomLabelsEnabled}
            isRatingSwitch={true}
            isReadOnly={props.isReadOnly}
          />
          <RatingWithPainAndShock
            ratingType={"Fail"}
            ratingTime={"10"}
            isCustomLabelActive={props.isCustomLabelsEnabled}
            isRatingSwitch={true}
            isReadOnly={props.isReadOnly}
          />
          <RatingWithPainAndShock
            ratingType={"No Rating"}
            ratingTime={"-"}
            isCustomLabelActive={props.isCustomLabelsEnabled}
            isRatingSwitch={true}
            isReadOnly={props.isReadOnly}
          />
        </div>
      )}
    </div>
  );
};
