import React, { useEffect } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import SubList from "./SubList";
import { v4 as uuidv4 } from "uuid";
import { isNullOrUndefined } from "../../utils/utilityfunctions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  Block,
  CasualtyStatus,
  CriticalAction,
  Diagnostic,
  EquipmentItem,
  GenericBlock,
  ListItem,
  Medication,
  Phase,
  ResearchQuestion,
  ScriptEquipmentList,
  ScriptList,
  Section,
  SupportAction,
  Text,
  Text as OurText,
  Treatment,
  SubtaskForTreatment,
  SubtaskForDiagnostic,
  SubtaskForMedication,
  SubtaskForMiscellaneous,
  SectionForCreateThunk,
} from "../../models/ScriptInterfaces";
import {
  CreateSection,
  CreateSectionThunk,
  DeleteSection,
  UpdateSection,
  UpdateSectionOrder,
} from "../../api/Section/ApiPost";
import { AlertType, BlockType } from "../../models/Enums";
import {
  createCasualtyStatus,
  createCriticalAction,
  createDiagnostic,
  createMedication,
  createResearchQuestion,
  createScriptEquipmentList,
  createScriptList,
  createSupportAction,
  createText,
  createTreatment,
} from "./CreateDefaultBlockObjects";
import {
  AddBlockListItem,
  CreateCasualtyStatusBlock,
  CreateScriptListItems,
  CreateTextBlock,
  DeleteBlock,
  DeleteListItemFromListBlock,
  DuplicateBlock,
  UpdateBlockOrder,
  UpdateCasualtyStatusBlock,
  UpdateCriticalAction,
  UpdateScriptEquipmentListItems,
  UpdateScriptListItems,
  UpdateTextBlock,
  UpdateTextBlockNonGeneric,
} from "../../api/Block/ApiPost";
import CasualtyEdit from "./EditComponents/CasualtyEdit";
import DiagnosticEdit from "../ScriptBuilder/EditComponents/DiagnosticEdit/DiagnosticEdit";
import TextEdit, { TextBlock } from "./EditComponents/TextEdit";
import SectionEdit from "./EditComponents/SectionEdit";
import RolePlayerEdit from "./EditComponents/RolePlayerEdit";

import MedicationEdit from "./EditComponents/MedicationEdit/MedicationEdit";
import PhaseEdit from "./EditComponents/PhaseEdit";
import SupportActionEdit from "./EditComponents/SupportActionEdit";
import TreatmentEdit from "./EditComponents/Treatment/TreatmentEdit";
import ListEdit, { ScriptListBlock } from "./EditComponents/ListEdit";
import CasualtyStatusEdit, {
  CasualtyStatusBlock,
} from "./EditComponents/CasualtyStatus/CasualtyStatusEdit";
import { Casualty } from "../../models/CasualtyInterfaces";
import "./SideBar.scss";
import { useMediaQuery } from "../../Responsive/UseMediaQuery";
import { MainListRow, SectionCol } from "../../styles/MainList.styled";
import produce from "immer";
import "../../css/custom.css";
import {
  populatePhaseWithTreatment,
  ResetMainList,
  ResetScriptEditorData,
  SetActiveComponent,
  SetActiveDragContentType,
  SetActiveKey,
  SetMasterAllDiagnostics,
  SetAllMasterMedications,
  SetAllMasterTreatments,
  SetCollapse,
  SetCurrentBlockIndex,
  SetCurrentPhaseId,
  SetCurrentPhaseIndex,
  SetCurrentSectionIndex,
  SetIsDeletePhaseBtnDisabled,
  SetIsTimedSection,
  SetPhases,
  SetScriptId,
  SetTextDescription,
  SetTextTitle,
  SetTimedActionMediaDisplay,
  SetAllMasterMiscellaneous,
} from "./MainlistSlice";
import MiscellaneousEdit from "./EditComponents/Miscellaneous/MiscellaneousEdit";
import { SideDragMenu } from "./EditComponents/MainListComponents/ScriptBuilderDragMenu";
import { ScriptBuilderCenter } from "./EditComponents/MainListComponents/ScriptBuilderCenter";
import { OpenErrorNotification } from "../../components/Notification/Notification";
import ResearchQuestionEdit, {
  ResearchQuestionBlock,
} from "./EditComponents/ResearchQuestion/ResearchQuestionEdit";
import {
  indexesOfPhasesWithRelatedGhostTimedActionTaskBlock,
  makeSurePhaseHasTimedActionBlockElseAdd,
} from "../../components/TreatmentEdit/TreatmentGhostLogic";
import {
  CreateResearchQuestionBlock,
  UpdateResearchQuestionBlock,
} from "../../api/ResearchQuestionBlock/ApiPost";
import FileUpload from "../../components/FileUpload/FileUploadComponent";
import EditMediaDetails from "../../components/FileUpload/EditMediaDetails";
import {
  MiscellaneousTaskBlock,
  MiscellaneousTaskBlockContent,
  UpdateMiscellaneousTaskBlockContent,
} from "../../models/MiscellaneousTaskBlock";
import {
  createMiscellaneousTaskBlock,
  updateMiscellaneousTaskBlock,
} from "../../api/MiscellaneousTaskBlock";
import { DeepKeysOf } from "../../models/common/DeepKeysOf";
import { UpdateTimedActionSubTask } from "../../models/TimedActionSubTask";
import {
  createDiagnosticTaskBlock,
  updateDiagnosticTaskBlock,
} from "../../api/DiagnosticTaskBlock";
import {
  DiagnosticTaskBlock,
  DiagnosticTaskBlockContent,
  UpdateDiagnosticTaskBlockContent,
} from "../../models/DiagnosticTaskBlock";
import { DiagnosticTask } from "../../models/DiagnosticTask";
import { getMasterDiagnosticTasks } from "../../api/DiagnosticTask";
import {
  createTreatmentTaskBlock,
  updateTreatmentTaskBlock,
} from "../../api/TreatmentTaskBlock";
import {
  TreatmentTaskBlock,
  TreatmentTaskBlockContent,
  UpdateTreatmentTaskBlockContent,
} from "../../models/TreatmentTaskBlock";
import { getMasterTreatmentTasks } from "../../api/TreatmentTask";
import { TreatmentTask } from "../../models/TreatmentTask";
import {
  createMedicationTaskBlock,
  updateMedicationTaskBlock,
} from "../../api/MedicationTaskBlock";
import { getMasterMedicationTasks } from "../../api/MedicationTask";
import { MedicationTask } from "../../models/MedicationTask";
import {
  MedicationTaskBlock,
  MedicationTaskBlockContent,
  UpdateMedicationTaskBlockContent,
} from "../../models/MedicationTaskBlock";
import { MiscellaneousTask } from "../../models/MiscellaneousTask";
import { getMasterMiscellaneousTasks } from "../../api/MiscellaneousTask";

enum ComponentEdit {
  PHASE,
  SECTION,
  TEXT,
  LIST,
  CASUALTY,
  TREATMENT,
  MEDICATION,
  DIAGNOSTIC,
  RPPROMPT,
  SUPPORTACTION,
  CASUALTYSTATUS,
  MISCELLANEOUS,
  RESEARCHQUESTION,
}

interface Props {
  loadingToggle: Function;
  ScriptId: number;
  Phase: Phase[];
  Casualty: Casualty;
  postError: Function;
  postSuccess: Function;
  setAlertMessage: Function;
  showEditCasualtyPane: Function;
}

const MainLists = (props: Props) => {
  const tabMode = useMediaQuery("(max-width: 768px)");

  const [mouseHoveringBlock, setMouseHoveringBlock] =
    React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const {
    currentPhaseIndex,
    currentPhaseId,
    activeComponent,
    currentSectionIndex,
    currentBlockIndex,
    collapse,
    sectionTitle,
    timedActionMediaDisplay,
    editMediaDisplay,
    isReadOnly,
    phases,
    ...state
  } = useAppSelector((state) => state.MainlistReducer);

  // Reset Redux local storage after component destroys.
  useEffect(() => {
    return () => {
      // This session token is used to scroll to element position after page refresh.
      window.sessionStorage.removeItem("SelectedElementId");
      dispatch(SetActiveComponent(0));
      dispatch(SetCurrentPhaseIndex(0));
      dispatch(SetCurrentPhaseId(0));
      dispatch(SetCurrentSectionIndex(0));
      dispatch(SetCurrentBlockIndex(0));
      dispatch(ResetScriptEditorData());
      dispatch(ResetMainList());
    };
  }, []);

  // Makes sure if only one phase is available disable the delete phase btn.
  useEffect(() => {
    phases.length > 1
      ? dispatch(SetIsDeletePhaseBtnDisabled(false))
      : dispatch(SetIsDeletePhaseBtnDisabled(true));
  }, [phases.length]);

  useEffect(() => {
    dispatch(SetTimedActionMediaDisplay(false));
    populatePhaseWithTreatment(phases || [], dispatch, state);
    dispatch(SetScriptId(props.ScriptId));
  }, []);

  useEffect(() => {
    getMasterMiscellaneousTasks(
      true,
      (data: MiscellaneousTask[]) => {
        dispatch(SetAllMasterMiscellaneous(data));
      },
      apiError,
    );
    getMasterTreatmentTasks(
      true,
      (data: TreatmentTask[]) => {
        dispatch(SetAllMasterTreatments(data));
      },
      apiError,
    );
    getMasterDiagnosticTasks(
      true,
      (data: DiagnosticTask[]) => {
        dispatch(SetMasterAllDiagnostics(data));
      },
      apiError,
    );
    getMasterMedicationTasks(
      true,
      (data: MedicationTask[]) => {
        dispatch(SetAllMasterMedications(data));
      },
      apiError,
    );
    return () => {
      dispatch(SetAllMasterMiscellaneous([]));
      dispatch(SetAllMasterTreatments([]));
      dispatch(SetMasterAllDiagnostics([]));
      dispatch(SetAllMasterMedications([]));
    };
  }, []);

  useEffect(() => {
    dispatch(SetCollapse(!tabMode));
  }, [tabMode]);

  const apiError = (data: string) => {
    console.warn(data);
    props.setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An Error Occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
  };

  const reorderSectionSuccess =
    (newSectionOrder: Section[], newSectionIndex: number) =>
    (...args: any) => {
      const newPhases = produce(phases, (draftPhases: Phase[]) => {
        draftPhases[currentPhaseIndex].Sections = newSectionOrder;
        return draftPhases;
      });
      dispatch(SetPhases(newPhases));

      props.loadingToggle();
      dispatch(SetActiveComponent(ComponentEdit.SECTION));
      dispatch(SetCurrentSectionIndex(newSectionIndex));
    };

  const addNewSectionSuccess =
    (result: DropResult, newSectionId: string) => (newSection: Section) => {
      newSection.UUID = newSectionId;
      window.sessionStorage.setItem("SelectedElementId", newSectionId);
      // introduce the new section to the state
      const newSectionArray: Section[] = [
        ...phases[currentPhaseIndex]!.Sections!,
        newSection,
      ];
      dispatch(
        SetPhases(
          phases.map((phase, phaseIndex) => {
            return phaseIndex !== currentPhaseIndex
              ? phase
              : {
                  ...phase,
                  Sections: newSectionArray,
                };
          }),
        ),
      );
      if (result.destination!.index === newSection.Order) {
        // No reordering necessary, clear the loading symbol.
        props.loadingToggle();
        dispatch(SetActiveComponent(ComponentEdit.SECTION));
        dispatch(SetCurrentSectionIndex(newSection.Order));
        return;
      }
      const reorderedSectionsArray: Section[] = [...newSectionArray];
      const removedItem: Section = reorderedSectionsArray.pop()!;
      reorderedSectionsArray.splice(result.destination!.index, 0, removedItem);
      const newOrderMapping: Section[] = reorderedSectionsArray.map(
        (section: Section, index: number) => {
          return {
            ...section,
            Order: index,
          };
        },
      );

      UpdateSectionOrder(
        newOrderMapping,
        reorderSectionSuccess(newOrderMapping, result.destination!.index),
        props.postError,
      );
    };

  const addNewSection = (result: DropResult) => {
    const newSectionId = uuidv4();

    // new sections have to be inserted at the end.
    // We'll move it to the correct place after creation.
    const newSection: Section = {
      UUID: newSectionId,
      PhaseId: phases[currentPhaseIndex].Id!,
      Order: phases[currentPhaseIndex]!.Sections!.length!,
      Title: "",
      Blocks: [],
      Collapsed: false,
    };
    props.loadingToggle();
    CreateSection(
      newSection,
      addNewSectionSuccess(result, newSectionId),
      props.postError,
    );
  };

  const reorderSections = (result: DropResult) => {
    const startIndex: number = result.source.index;
    const destinationIndex: number = result.destination!.index;
    if (startIndex === destinationIndex) {
      return;
    }

    props.loadingToggle();
    const reorderedSections: Section[] = [
      ...phases[currentPhaseIndex].Sections!,
    ];

    const [removedSection] = reorderedSections.splice(startIndex, 1);
    reorderedSections.splice(destinationIndex, 0, removedSection);
    const newOrderMapping: Section[] = reorderedSections.map(
      (section: Section, index: number) => {
        return {
          ...section,
          Order: index,
        };
      },
    );
    UpdateSectionOrder(
      newOrderMapping,
      reorderSectionSuccess(newOrderMapping, destinationIndex),
      props.postError,
    );
  };

  const handleSectionDrag = (result: DropResult) => {
    const sectionId: string = result.draggableId.split(".")[1];
    if (sectionId === "New") {
      addNewSection(result);
    } else {
      reorderSections(result);
    }
  };
  const createBlock = (
    SectionId: number,
    blockType: BlockType,
    standInType?: string,
  ) => {
    let sectionBlock:
      | Text
      | SupportAction
      | CriticalAction
      | ScriptEquipmentList
      | EquipmentItem
      | ScriptList
      | ListItem
      | ResearchQuestion
      | Treatment
      | CasualtyStatus
      | Diagnostic
      | Medication
      | null = null;

    switch (blockType) {
      case BlockType.CriticalAction:
        sectionBlock = createCriticalAction();
        break;

      case BlockType.ScriptEquipmentList:
        sectionBlock = createScriptEquipmentList();
        break;

      case BlockType.ScriptList:
        sectionBlock = createScriptList();
        break;

      case BlockType.SupportAction:
        sectionBlock = createSupportAction();
        break;

      case BlockType.Text:
        sectionBlock = createText(standInType!);
        break;
      case BlockType.Treatment:
        sectionBlock = createTreatment(currentPhaseId, currentPhaseIndex);
        break;
      case BlockType.CasualtyStatus:
        sectionBlock = createCasualtyStatus();
        break;
      case BlockType.Diagnostic:
        sectionBlock = createDiagnostic(currentPhaseId, currentPhaseIndex);
        break;
      case BlockType.Medication:
        sectionBlock = createMedication(currentPhaseId, currentPhaseIndex);
        break;
      case BlockType.ResearchQuestion:
        sectionBlock = createResearchQuestion();
        break;
    }

    return {
      Id: 0,
      UUID: uuidv4(),
      SectionId: SectionId,
      BlockType: blockType,
      BlockContent: sectionBlock,
      IsGhostBlock: false,
      Order: 0,
    };
  };

  const addNewBlockItem = (
    result: DropResult,
    blockType: BlockType,
    creationFunction: Function,
    standInType?: string,
  ) => {
    const targetSectionUUID = result.destination!.droppableId;
    const targetSectionIndex = phases[currentPhaseIndex].Sections!.findIndex(
      (s) => s.UUID === targetSectionUUID,
    );

    if (targetSectionIndex === -1) {
      console.log(
        "Failed to locate section by UUID.  Data dump: DropResult, blockType, phases.",
        result,
        blockType,
        phases,
      );
      return;
    }

    props.loadingToggle();

    let newBlockItem: any;
    if (blockType === BlockType.Miscellaneous) {
      newBlockItem = {
        SectionId:
          phases[currentPhaseIndex].Sections![targetSectionIndex].Id || 0,
        BlockType: BlockType.Miscellaneous,
        TimedAction: true,
        Order: 0,
        OrderInNextPhase1: 0,
        OrderInNextPhase2: 0,
        BlockContent: {
          CreatedInPhaseId: currentPhaseId,
          IsCritical: false,
          IsEditMasterEnabled: false,
          IsRequired: false,
          RequiredInPhaseOffset: 0,
        },
      };
    } else {
      newBlockItem = createBlock(
        phases[currentPhaseIndex].Sections![targetSectionIndex].Id!,
        blockType,
        standInType,
      );
    }

    const newUUID = newBlockItem.UUID;

    creationFunction(
      newBlockItem,
      (createdBlock: Block) => {
        console.log(
          "This is the block that came from back end: ",
          createdBlock,
        );

        const tempRef = phases[currentPhaseIndex].Sections![targetSectionIndex];
        const newBlocks = tempRef.Blocks
          ? [...tempRef.Blocks, createdBlock]
          : [createdBlock];
        createdBlock.UUID = newUUID;
        createdBlock.Order = newBlocks.length - 1;
        dispatch(
          SetPhases(
            phases.map((phase, phaseIndex) => {
              return phaseIndex !== currentPhaseIndex
                ? phase
                : {
                    ...phase,
                    Sections: phase.Sections!.map((section, sectionIndex) => {
                      return sectionIndex !== targetSectionIndex
                        ? section
                        : {
                            ...section,
                            Blocks: newBlocks,
                          };
                    }),
                  };
            }),
          ),
        );
        const destinationIndex = result.destination!.index;
        if (destinationIndex !== newBlocks.length - 1) {
          let reorderedBlocks = [...newBlocks];
          const removedBlock = reorderedBlocks.pop()!;
          reorderedBlocks.splice(destinationIndex, 0, removedBlock);
          reorderedBlocks = reorderedBlocks.map((block, index) => {
            return {
              ...block,
              Order: index,
            };
          });
          updateBlocksOrders(reorderedBlocks, targetSectionIndex);
        } else {
          updateBlocksOrders(newBlocks, targetSectionIndex);
        }
        props.loadingToggle();
        let component = ComponentEdit.SECTION;
        switch (newBlockItem.BlockType) {
          case BlockType.CriticalAction:
            component = ComponentEdit.MEDICATION;
            break;

          case BlockType.ScriptEquipmentList:
            component = ComponentEdit.LIST;
            break;

          case BlockType.ScriptList:
            component = ComponentEdit.LIST;
            break;

          case BlockType.SupportAction:
            component = ComponentEdit.SUPPORTACTION;
            break;

          case BlockType.Text:
            if (
              !isNullOrUndefined(newBlockItem) &&
              "StandInType" in newBlockItem!.BlockContent!
            ) {
              if (
                newBlockItem.BlockContent!.StandInType === "role player prompt"
              ) {
                component = ComponentEdit.RPPROMPT;
                break;
              } else if (
                newBlockItem.BlockContent!.StandInType === "support action"
              ) {
                component = ComponentEdit.SUPPORTACTION;
                break;
              } else {
                component = ComponentEdit.TEXT;
                break;
              }
            }
            break;

          case BlockType.Casualty:
            component = ComponentEdit.CASUALTY;
            break;

          case BlockType.CasualtyStatus:
            component = ComponentEdit.CASUALTYSTATUS;
            break;

          case BlockType.Treatment:
            component = ComponentEdit.TREATMENT;
            break;
          case BlockType.Diagnostic:
            component = ComponentEdit.DIAGNOSTIC;
            break;
          case BlockType.Miscellaneous:
            component = ComponentEdit.MISCELLANEOUS;
            break;
          case BlockType.Medication:
            component = ComponentEdit.MEDICATION;
            break;
          case BlockType.ResearchQuestion:
            component = ComponentEdit.RESEARCHQUESTION;
            break;
        }
        dispatch(SetCurrentBlockIndex(destinationIndex));
        dispatch(SetCurrentSectionIndex(targetSectionIndex));
        dispatch(SetActiveComponent(component));
      },
      props.postError,
    );
  };

  /**
   * Update Block orders after adding new block using drag & add functionality
   * @param newBlocks
   * @param targetSectionIndex
   */
  const updateBlocksOrders = (newBlocks: any, targetSectionIndex: number) => {
    UpdateBlockOrder(
      newBlocks,
      (res: string) => {
        dispatch(
          SetPhases(
            phases.map((phase, phaseIndex) => {
              return phaseIndex !== currentPhaseIndex
                ? phase
                : {
                    ...phase,
                    Sections: phase.Sections!.map((section, sectionIndex) => {
                      return sectionIndex !== targetSectionIndex
                        ? section
                        : {
                            ...section,
                            Blocks: newBlocks,
                          };
                    }),
                  };
            }),
          ),
        );
      },
      props.postError,
    );
  };

  const getUpdateFunction = (removedBlock: Block) => {
    switch (removedBlock.BlockType) {
      case BlockType.Text:
        return UpdateTextBlockNonGeneric;

      case BlockType.ScriptList:
        return UpdateScriptListItems;

      case BlockType.CriticalAction:
        return UpdateCriticalAction;

      case BlockType.CasualtyStatus:
        return UpdateCasualtyStatusBlock;

      case BlockType.ResearchQuestion:
        return UpdateResearchQuestionBlock;

      case BlockType.Treatment:
        return updateTreatmentTaskBlock;

      case BlockType.Medication:
        return updateMedicationTaskBlock;

      case BlockType.Miscellaneous:
        return updateMiscellaneousTaskBlock;

      case BlockType.Diagnostic:
        return updateDiagnosticTaskBlock;

      default:
        throw new Error(
          "Unhandled blocktype: " + BlockType[removedBlock.BlockType],
        );
    }
  };

  const handleCrossSectionReordering = (result: DropResult) => {
    const sourceSectionUUID = result.source.droppableId;
    const sourceDragIndex = result.source.index;
    const sourceSectionIndex = phases[currentPhaseIndex].Sections!.findIndex(
      (s) => s.UUID === sourceSectionUUID,
    )!;

    const destinationSectionUUID = result.destination!.droppableId;
    const destinationDropIndex = result.destination!.index;
    const destinationSectionIndex = phases[
      currentPhaseIndex
    ].Sections!.findIndex((s) => s.UUID === destinationSectionUUID)!;
    const destinationSection =
      phases[currentPhaseIndex].Sections![destinationSectionIndex];

    let newSourceBlocks: Block[] = [
      ...phases[currentPhaseIndex].Sections![sourceSectionIndex].Blocks!,
    ];
    if (
      destinationSection?.SectionType?.toLocaleLowerCase() !==
        "timed actions" &&
      [
        BlockType.Treatment,
        BlockType.Medication,
        BlockType.Miscellaneous,
        BlockType.Diagnostic,
      ].includes(newSourceBlocks[sourceDragIndex].BlockType)
    ) {
      const [removedBlock] = newSourceBlocks.splice(sourceDragIndex, 1);
      const blockName =
        removedBlock.BlockType === BlockType.Treatment
          ? "Treatment"
          : removedBlock.BlockType === BlockType.Medication
            ? "Medication"
            : removedBlock.BlockType === BlockType.Miscellaneous
              ? "Miscellaneous"
              : "Diagnostic";
      OpenErrorNotification({
        description: `${blockName} blocks can only be dragged into Timed Action Sections`,
      });
      return;
    } else {
      const [removedBlock] = newSourceBlocks.splice(sourceDragIndex, 1);
      newSourceBlocks = newSourceBlocks.map((block, blockIndex) => {
        return {
          ...block,
          Order: blockIndex,
        };
      });

      let newDestinationBlocks: Block[] = [
        ...phases[currentPhaseIndex].Sections![destinationSectionIndex].Blocks!,
      ];
      newDestinationBlocks.splice(destinationDropIndex, 0, removedBlock);
      newDestinationBlocks = newDestinationBlocks.map((block, blockIndex) => {
        return {
          ...block,
          Order: blockIndex,
          SectionId: destinationSection.Id!,
        };
      });

      const updateBlockFunction = getUpdateFunction(removedBlock);
      const blockToUpdate = newDestinationBlocks[destinationDropIndex];

      props.loadingToggle();
      updateBlockFunction(
        blockToUpdate as any,
        (res: string) => {
          UpdateBlockOrder(
            [...newSourceBlocks, ...newDestinationBlocks],
            (res: string) => {
              dispatch(
                SetPhases(
                  phases.map((phase, phaseIndex) => {
                    return phaseIndex !== currentPhaseIndex
                      ? phase
                      : {
                          ...phase,
                          Sections: phase.Sections!.map(
                            (section, sectionIndex) => {
                              if (sectionIndex === sourceSectionIndex) {
                                return {
                                  ...section,
                                  Blocks: newSourceBlocks,
                                };
                              }

                              if (sectionIndex === destinationSectionIndex) {
                                return {
                                  ...section,
                                  Blocks: newDestinationBlocks,
                                };
                              }

                              return section;
                            },
                          ),
                        };
                  }),
                ),
              );
              dispatch(SetCurrentBlockIndex(destinationDropIndex));

              dispatch(SetCurrentSectionIndex(destinationSectionIndex));
              let component = ComponentEdit.SECTION;
              switch (blockToUpdate.BlockType) {
                case BlockType.CriticalAction:
                  component = ComponentEdit.MEDICATION;
                  break;

                case BlockType.ScriptEquipmentList:
                  component = ComponentEdit.LIST;
                  break;

                case BlockType.ScriptList:
                  component = ComponentEdit.LIST;
                  break;

                case BlockType.SupportAction:
                  component = ComponentEdit.SUPPORTACTION;
                  break;

                case BlockType.Text:
                  if (
                    !isNullOrUndefined(blockToUpdate) &&
                    "StandInType" in blockToUpdate!.BlockContent!
                  ) {
                    if (
                      blockToUpdate.BlockContent!.StandInType ===
                      "role player prompt"
                    ) {
                      component = ComponentEdit.RPPROMPT;
                      break;
                    } else if (
                      blockToUpdate.BlockContent!.StandInType ===
                      "support action"
                    ) {
                      component = ComponentEdit.SUPPORTACTION;
                      break;
                    } else {
                      component = ComponentEdit.TEXT;
                      break;
                    }
                  }
                  break;

                case BlockType.Casualty:
                  component = ComponentEdit.CASUALTY;
              }
              dispatch(SetActiveComponent(component));
              props.loadingToggle();
            },
            props.postError,
          );
        },
        props.postError,
      );
    }
  };

  const reorderBlockItems = (result: DropResult) => {
    const sourceSectionUUID = result.source.droppableId;
    const sourceIndex = result.source.index;
    const destinationSectionUUID = result.destination!.droppableId;
    const destinationIndex = result.destination!.index;

    if (sourceSectionUUID === destinationSectionUUID) {
      // this is a drag reordering inside a single section
      if (sourceIndex === destinationIndex) {
        // user dropped it right where they picked it up.  No reordering necessary
        return;
      }

      props.loadingToggle();

      const targetSection: any = phases[currentPhaseIndex].Sections!.find(
        (s) => s.UUID === sourceSectionUUID,
      )!;
      const targetSectionIndex = phases[currentPhaseIndex].Sections!.findIndex(
        (s) => s.UUID === sourceSectionUUID,
      )!;

      let reorderedBlocks = [...targetSection.Blocks!];
      const [removedItem] = reorderedBlocks.splice(sourceIndex, 1);
      reorderedBlocks.splice(destinationIndex, 0, removedItem);
      reorderedBlocks = reorderedBlocks.map(
        (block: Block, blockIndex: number) => {
          if (
            targetSection.SectionType === "timed actions" &&
            (block.BlockType === BlockType.Medication ||
              block.BlockType === BlockType.Treatment ||
              block.BlockType === BlockType.Miscellaneous ||
              block.BlockType === BlockType.Diagnostic)
          ) {
            const createdPhaseIndex = phases.findIndex(
              (phase: Phase) =>
                phase.Id === block.BlockContent.CreatedInPhaseId,
            );
            const Order =
              createdPhaseIndex !== -1 &&
              createdPhaseIndex === currentPhaseIndex
                ? blockIndex
                : block.Order;
            const OrderInNextPhase1 =
              createdPhaseIndex !== -1 &&
              createdPhaseIndex + 1 === currentPhaseIndex
                ? blockIndex
                : block.OrderInNextPhase1;
            const OrderInNextPhase2 =
              createdPhaseIndex !== -1 &&
              createdPhaseIndex + 2 === currentPhaseIndex
                ? blockIndex
                : block.OrderInNextPhase2;
            return {
              ...block,
              Order: Order,
              OrderInNextPhase1: OrderInNextPhase1,
              OrderInNextPhase2: OrderInNextPhase2,
            };
          } else {
            return {
              ...block,
              Order: blockIndex,
            };
          }
        },
      );
      dispatch(
        SetPhases(
          phases.map((phase, phaseIndex) => {
            return phaseIndex !== currentPhaseIndex
              ? phase
              : {
                  ...phase,
                  Sections: phase.Sections!.map((section, sectionIndex) => {
                    return sectionIndex !== targetSectionIndex
                      ? section
                      : {
                          ...section,
                          Blocks: reorderedBlocks,
                        };
                  }),
                };
          }),
        ),
      );

      UpdateBlockOrder(
        reorderedBlocks,
        (res: string) => {
          let component = ComponentEdit.SECTION;
          switch (removedItem.BlockType) {
            case BlockType.CriticalAction:
              component = ComponentEdit.MEDICATION;
              break;

            case BlockType.ScriptEquipmentList:
              component = ComponentEdit.LIST;
              break;

            case BlockType.ScriptList:
              component = ComponentEdit.LIST;
              break;

            case BlockType.SupportAction:
              component = ComponentEdit.SUPPORTACTION;
              break;

            case BlockType.Text:
              if (
                !isNullOrUndefined(removedItem) &&
                "StandInType" in removedItem!.BlockContent!
              ) {
                if (
                  removedItem.BlockContent!.StandInType === "role player prompt"
                ) {
                  component = ComponentEdit.RPPROMPT;
                  break;
                } else if (
                  removedItem.BlockContent!.StandInType === "support action"
                ) {
                  component = ComponentEdit.SUPPORTACTION;
                  break;
                } else {
                  component = ComponentEdit.TEXT;
                  break;
                }
              }
              break;

            case BlockType.Casualty:
              component = ComponentEdit.CASUALTY;
              break;

            case BlockType.CasualtyStatus:
              component = ComponentEdit.CASUALTYSTATUS;
              break;

            case BlockType.Treatment:
              component = ComponentEdit.TREATMENT;
              break;

            case BlockType.Diagnostic:
              component = ComponentEdit.DIAGNOSTIC;
              break;

            case BlockType.Miscellaneous:
              component = ComponentEdit.MISCELLANEOUS;
              break;

            case BlockType.Medication:
              component = ComponentEdit.MEDICATION;
              break;
            case BlockType.ResearchQuestion:
              component = ComponentEdit.RESEARCHQUESTION;
              break;
          }
          dispatch(SetActiveComponent(component));
          const currentBlockIndex = reorderedBlocks.findIndex(
            (i: Block) => i.Id === removedItem.Id,
          );
          dispatch(
            SetCurrentBlockIndex(
              currentBlockIndex !== -1 ? currentBlockIndex : destinationIndex,
            ),
          );
          dispatch(SetCurrentSectionIndex(targetSectionIndex));
          props.loadingToggle();
        },
        () => {
          props.loadingToggle();
          props.postError();
        },
      );
    } else {
      // this is a drag from one section to another.
      handleCrossSectionReordering(result);
    }
  };

  const handleBlockItemDrag = (
    result: DropResult,
    blockType: BlockType,
    creationFunction: Function,
    standInType?: string,
  ) => {
    const itemId: string = result.draggableId.split(".")[1];

    if (itemId === "New") {
      const targetSectionUUID = result.destination?.droppableId;
      const targetSectionIndex = phases[currentPhaseIndex].Sections!.findIndex(
        (s) => s.UUID === targetSectionUUID,
      );
      switch (result.draggableId) {
        case "Treatment.New":
        case "Diagnostic.New":
        case "Miscellaneous.New":
        case "Medication.New":
          if (
            phases[currentPhaseIndex].Sections![
              targetSectionIndex
            ].SectionType?.toLocaleLowerCase() === "timed actions"
          ) {
            addNewBlockItem(result, blockType, creationFunction, standInType);
          } else {
            console.log(
              result.draggableId +
                "block can be dropped only in the Timed Action Section",
            );
          }
          return;

        default:
          addNewBlockItem(result, blockType, creationFunction, standInType);
          return;
      }
    } else {
      reorderBlockItems(result);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (isReadOnly) return;
    dispatch(SetActiveDragContentType(null));
    if (!result.destination) {
      // dropped outside of a drop-enabled area
      return;
    }

    const contentType = result.draggableId.split(".")[0];

    switch (contentType) {
      case "Section":
        handleSectionDrag(result);
        break;

      case "Text":
        handleBlockItemDrag(result, BlockType.Text, CreateTextBlock);
        break;

      case "List":
        handleBlockItemDrag(
          result,
          BlockType.ScriptList,
          CreateScriptListItems,
        );
        break;

      case "Casualty Status":
        handleBlockItemDrag(
          result,
          BlockType.CasualtyStatus,
          CreateCasualtyStatusBlock,
        );
        break;

      case "Treatment":
        handleBlockItemDrag(
          result,
          BlockType.Treatment,
          createTreatmentTaskBlock,
        );
        break;

      case "Medication":
        handleBlockItemDrag(
          result,
          BlockType.Medication,
          createMedicationTaskBlock,
        );
        break;

      case "Diagnostic":
        handleBlockItemDrag(
          result,
          BlockType.Diagnostic,
          createDiagnosticTaskBlock,
        );
        break;
      case "Miscellaneous":
        handleBlockItemDrag(
          result,
          BlockType.Miscellaneous,
          createMiscellaneousTaskBlock,
        );
        break;

      case "Role Player Prompt":
        handleBlockItemDrag(
          result,
          BlockType.Text,
          CreateTextBlock,
          "role player prompt",
        );
        break;

      case "Support Action":
        handleBlockItemDrag(
          result,
          BlockType.Text,
          CreateTextBlock,
          "support action",
        );
        break;

      case "Research Question":
        handleBlockItemDrag(
          result,
          BlockType.ResearchQuestion,
          CreateResearchQuestionBlock,
        );
        break;

      case "blockItem":
        reorderBlockItems(result);
        break;

      default:
        console.log(
          "Unhandled case: " +
            contentType +
            ".  Check for possible spelling errors.",
        );
    }
  };

  const onBlockItemDeleteSuccess = (blockWithoutItem: Block) => (data: any) => {
    const blockIndex = phases![currentPhaseIndex].Sections![
      currentSectionIndex
    ].Blocks?.findIndex((x: Block) => x.UUID === blockWithoutItem.UUID);

    const newPhases = produce(phases, (draftPhases) => {
      draftPhases![currentPhaseIndex].Sections![
        currentSectionIndex
      ].Blocks?.splice(blockIndex!, 1, blockWithoutItem);
    });

    dispatch(SetPhases(newPhases));
    dispatch(SetActiveKey(newPhases[currentPhaseIndex].UUID));
  };

  const onBlockDeleteSuccess =
    (blockUUID: string, block: Block) => (data: any) => {
      const blockIndex = phases![currentPhaseIndex].Sections![
        currentSectionIndex
      ].Blocks?.findIndex((x: Block) => x.UUID === blockUUID);
      const newPhases = produce(phases, (draftPhases) => {
        draftPhases![currentPhaseIndex].Sections![
          currentSectionIndex
        ].Blocks?.splice(blockIndex!, 1);
        if (
          block.BlockType === BlockType.Miscellaneous ||
          block.BlockType === BlockType.Treatment ||
          block.BlockType === BlockType.Diagnostic ||
          block.BlockType === BlockType.Medication
        ) {
          for (let i = currentPhaseIndex; i <= currentPhaseIndex + 2; i++) {
            for (let j = 0; j < draftPhases[i]?.Sections!.length; j++) {
              draftPhases[i]!.Sections![j].Blocks = draftPhases[i]!.Sections![
                j
              ].Blocks!.filter((el) => el.UUID !== block.UUID);
            }
          }
        }
      });
      // move the editing-selection to the parent section
      dispatch(SetActiveComponent(ComponentEdit.SECTION));
      dispatch(SetPhases(newPhases));
      dispatch(SetActiveKey(phases[currentPhaseIndex].UUID));
      props.postSuccess("Block has been deleted successfully");
    };

  const onChangeBlock = (e: any) => {
    if (isReadOnly) return; //disable action callback in read only script
    try {
      if (e.target.name === "description") {
        dispatch(SetTextDescription(e.target.value));
        const newTextBlockDescription = {
          ...phases![currentPhaseIndex].Sections![currentSectionIndex]!.Blocks![
            currentBlockIndex
          ].BlockContent,
          Description: e.target.value,
        };
        const newBlockContent = {
          ...phases![currentPhaseIndex].Sections![currentSectionIndex]!.Blocks![
            currentBlockIndex
          ],
          BlockContent: newTextBlockDescription,
        };
        const newPhases = produce(phases, (draftPhases) => {
          draftPhases![currentPhaseIndex].Sections![
            currentSectionIndex
          ].Blocks![currentBlockIndex] = newBlockContent;
        });
        dispatch(SetPhases(newPhases));
      } else if (e.target.name === "title") {
        dispatch(SetTextTitle(e.target.value));
        const newTextBlockDescription = {
          ...phases![currentPhaseIndex].Sections![currentSectionIndex]!.Blocks![
            currentBlockIndex
          ].BlockContent,
          Title: e.target.value,
        };
        const newBlockContent = {
          ...phases![currentPhaseIndex].Sections![currentSectionIndex]!.Blocks![
            currentBlockIndex
          ],
          BlockContent: newTextBlockDescription,
        };
        const newPhases = produce(phases, (draftPhases) => {
          draftPhases![currentPhaseIndex].Sections![
            currentSectionIndex
          ].Blocks![currentBlockIndex] = newBlockContent;
        });
        dispatch(SetPhases(newPhases));
      } else {
        dispatch(SetPhases(phases));
      }
    } catch (error) {
      console.log("Error is", error);
    }
  };
  const onChangeRolePlayerBlock = (e: any) => {
    if (isReadOnly) return; //disable action callback in read only script
    try {
      const newRolePlayerDescription = {
        ...phases![currentPhaseIndex].Sections![currentSectionIndex]!.Blocks![
          currentBlockIndex
        ].BlockContent,
        Description: e.target.value,
      };
      const newBlockContent = {
        ...phases![currentPhaseIndex].Sections![currentSectionIndex]!.Blocks![
          currentBlockIndex
        ],
        BlockContent: newRolePlayerDescription,
      };
      const newPhases = produce(phases, (draftPhases) => {
        draftPhases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
          currentBlockIndex
        ] = newBlockContent;
      });
      dispatch(SetPhases(newPhases));
    } catch (error) {
      console.log("Error is", error);
    }
  };

  const onBlockSave = () => {
    if (isReadOnly) return; //disable action callback in read only script
    const updateItem =
      phases![currentPhaseIndex].Sections![currentSectionIndex]!.Blocks![
        currentBlockIndex
      ];
    switch (updateItem.BlockType) {
      case BlockType.CriticalAction:
        UpdateCriticalAction(updateItem, props.postSuccess, props.postError);
        break;
      case BlockType.ScriptEquipmentList:
        UpdateScriptEquipmentListItems(
          updateItem,
          props.postSuccess,
          props.postError,
        );
        break;

      case BlockType.ScriptList:
        UpdateScriptListItems(updateItem, props.postSuccess, props.postError);
        break;

      case BlockType.Text: {
        // Defining block content as a text blockType
        if ("DisplayedAsTwoColumns" in updateItem.BlockContent) {
          const textBlockPayload: GenericBlock<OurText> = {
            UUID: updateItem.UUID,
            Id: updateItem.Id,
            SectionId: updateItem.SectionId,
            Order: updateItem.Order,
            BlockType: updateItem.BlockType,
            BlockContent: {
              Id: updateItem.BlockContent.Id,
              BlockId: updateItem.BlockContent.BlockId,
              Description: updateItem.BlockContent.Description,
              Title: updateItem.BlockContent.Title,
              DisplayedAsTwoColumns:
                updateItem.BlockContent.DisplayedAsTwoColumns,
              IsImportant: updateItem.BlockContent.IsImportant,
              IsTitleEnabled: updateItem.BlockContent.IsTitleEnabled,
              StandInType: updateItem.BlockContent.StandInType,
              UploadedMediaItems: updateItem.BlockContent.UploadedMediaItems,
            },
          };
          UpdateTextBlock(textBlockPayload, props.postSuccess, props.postError);
        }
      }
    }
  };

  const onBlockDelete = (block: Block) => {
    DeleteBlock(block, onBlockDeleteSuccess(block.UUID, block), () => {
      OpenErrorNotification({
        description: "Error occurred while deleting block. Try again later.",
      });
      props.postError();
    });
  };

  const onBlockItemDelete = (blockDelete: Block, blockReplace: Block) => {
    if (
      blockDelete.Id &&
      "ListItems" in blockDelete.BlockContent &&
      blockDelete.BlockContent.ListItems[0].Id
    ) {
      DeleteListItemFromListBlock(
        blockDelete.Id,
        blockDelete.BlockContent.ListItems[0].Id,
        onBlockItemDeleteSuccess(blockReplace),
        props.postError,
      );
    } else {
      props.setAlertMessage({
        systemMessage: {
          message: "Error",
          type: AlertType.error,
          description:
            "Error occured while deleting list item from list block.",
          messageShowTime: 3000,
          position: "top-center",
        },
      });
    }
  };

  const onBlockCopySuccess = (blockUUID: string) => (data: any) => {
    data!.UUID = uuidv4();
    const blockIndex = phases![currentPhaseIndex].Sections![
      currentSectionIndex
    ].Blocks?.findIndex((x: Block) => x.UUID === blockUUID);
    const newPhases = produce(phases, (draftPhases) => {
      draftPhases![currentPhaseIndex].Sections![
        currentSectionIndex
      ].Blocks?.splice(blockIndex! + 1, 0, data);
      draftPhases![currentPhaseIndex].Sections![
        currentSectionIndex
      ].Blocks?.forEach((x: Block, index: number) => x.Order === index);
    });
    console.log("NEW PHASSES AFTER COPY", newPhases);
    dispatch(SetPhases(newPhases));

    props.postSuccess("Block has been copied successfully");
  };

  const onBlockCopy = (block: Block) => {
    DuplicateBlock(block, onBlockCopySuccess(block.UUID), props.postError);
  };

  const replaceBlockData = (block: DiagnosticTaskBlock) => {
    const newPhases = produce(phases, (draftPhases) => {
      draftPhases[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
        currentBlockIndex
      ] = block;
    });
    dispatch(SetPhases(newPhases));
  };

  const onListBlockUpdateSuccess =
    (blockUUID: string, originalBlock: Block) => (blockReturn: Block) => {
      dispatch(
        SetPhases(
          produce(phases, (draftPhases) => {
            draftPhases![currentPhaseIndex].Sections![
              currentSectionIndex
            ].Blocks![currentBlockIndex] = {
              UUID: blockUUID,
              Id: originalBlock.Id,
              SectionId: originalBlock.SectionId,
              Order: originalBlock.Order,
              BlockType: originalBlock.BlockType,
              IsGhostBlock: originalBlock.IsGhostBlock,
              BlockContent: originalBlock.BlockContent,
              OrderInNextPhase1: originalBlock.OrderInNextPhase1,
              OrderInNextPhase2: originalBlock.OrderInNextPhase2,
            };
          }),
        ),
      );
    };

  const onListBlockUpdate = (listBlock: Block) => {
    UpdateScriptListItems(
      listBlock,
      onListBlockUpdateSuccess(listBlock.UUID, listBlock),
      props.postError,
    );
  };

  const onTextColumnDisplayUpdate = (textBlock: Block) => {
    const newPhases = produce(phases, (draftPhases) => {
      draftPhases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
        currentBlockIndex
      ] = textBlock;
    });
    dispatch(SetPhases(newPhases));
    if ("DisplayedAsTwoColumns" in textBlock.BlockContent) {
      const textBlockPayload: GenericBlock<OurText> = {
        UUID: textBlock.UUID,
        Id: textBlock.Id,
        SectionId: textBlock.SectionId,
        Order: textBlock.Order,
        BlockType: textBlock.BlockType,
        BlockContent: {
          Id: textBlock.BlockContent.Id,
          BlockId: textBlock.BlockContent.BlockId,
          Description: textBlock.BlockContent.Description,
          Title: textBlock.BlockContent.Title,
          DisplayedAsTwoColumns: textBlock.BlockContent.DisplayedAsTwoColumns,
          IsImportant: textBlock.BlockContent.IsImportant,
          IsTitleEnabled: textBlock.BlockContent.IsTitleEnabled,
          StandInType: textBlock.BlockContent.StandInType,
          UploadedMediaItems: textBlock.BlockContent.UploadedMediaItems,
        },
      };
      UpdateTextBlock(textBlockPayload, props.postSuccess, props.postError);
    }
  };

  const onBlockItemAddSuccess =
    (blockReplace: Block) => async (data: Block) => {
      try {
        data.UUID = blockReplace.UUID;
        const blockIndex = phases![currentPhaseIndex].Sections![
          currentSectionIndex
        ].Blocks?.findIndex((x: Block) => x.UUID === data.UUID);

        const nextPhases = produce(phases, (draftPhases) => {
          draftPhases![currentPhaseIndex].Sections![
            currentSectionIndex
          ].Blocks?.splice(blockIndex!, 1, data);
        });
        dispatch(SetPhases(nextPhases));
        dispatch(SetActiveKey(phases[currentPhaseIndex].UUID));

        // props.postSuccess("Item has been added successfully");
      } catch (error) {
        console.log("Error is", error);
      }
    };

  const onBlockItemAdd = async (blockAdd: Block, blockReplace: Block) => {
    try {
      if (
        "ListItems" in blockAdd!.BlockContent! &&
        !isNullOrUndefined(blockAdd.BlockContent.ListItems)
      ) {
        AddBlockListItem(
          {
            BlockId: blockAdd.Id,
            TitleOfItem:
              blockAdd.BlockContent.ListItems[
                blockAdd.BlockContent.ListItems.length - 1
              ].Title,
            Quantity:
              blockAdd.BlockContent.ListItems[
                blockAdd.BlockContent.ListItems.length - 1
              ].Quantity,
            Order: blockReplace.BlockContent.ListItems.length - 1,
          },
          onBlockItemAddSuccess(blockReplace),
          props.postError,
        );
      } else {
        AddBlockListItem(
          blockAdd,
          onBlockItemAddSuccess(blockReplace),
          props.postError,
        );
      }
    } catch (error) {
      console.log("Error is", error);
    }
  };
  const onListTitleAdd = async (titleAdd: string, titleBlock: Block) => {
    try {
      const newListBlockContentWithTitle = {
        ...phases![currentPhaseIndex].Sections![currentSectionIndex]!.Blocks![
          currentBlockIndex
        ].BlockContent,
        Title: titleAdd,
      };
      const newBlockContent = {
        ...phases![currentPhaseIndex].Sections![currentSectionIndex]!.Blocks![
          currentBlockIndex
        ],
        BlockContent: newListBlockContentWithTitle,
      };
      const newPhases = produce(phases, (draftPhases) => {
        draftPhases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
          currentBlockIndex
        ] = newBlockContent;
      });
      UpdateScriptListItems(
        newPhases![currentPhaseIndex].Sections![currentSectionIndex]!.Blocks![
          currentBlockIndex
        ],
        props.postSuccess,
        props.postError,
      );
      dispatch(SetPhases(newPhases));
    } catch (error) {
      console.log("Error is", error);
    }
  };

  const handleTimedActionBlockChange = (
    blockType: BlockType,
    changedVariable:
      | DeepKeysOf<UpdateMiscellaneousTaskBlockContent>
      | DeepKeysOf<UpdateDiagnosticTaskBlockContent>
      | DeepKeysOf<UpdateTreatmentTaskBlockContent>
      | DeepKeysOf<UpdateMedicationTaskBlockContent>,
    change: any,
    secondChangedVariable?:
      | DeepKeysOf<UpdateMiscellaneousTaskBlockContent>
      | DeepKeysOf<UpdateDiagnosticTaskBlockContent>
      | DeepKeysOf<UpdateTreatmentTaskBlockContent>
      | DeepKeysOf<UpdateMedicationTaskBlockContent>,
    secondChangedValue?: any,
  ) => {
    const newPhases = produce(phases, (draftPhases: Phase[]) => {
      if (changedVariable === "TimedActionTaskContent.Subtasks") {
        draftPhases[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
          currentBlockIndex
        ].BlockContent.TimedActionTaskContent.Subtasks?.push(change);
      } else if (
        blockType === BlockType.Medication &&
        changedVariable === "TimedActionTaskContent.SelectedRoute"
      ) {
        (
          draftPhases[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
            currentBlockIndex
          ].BlockContent as MedicationTaskBlockContent
        ).TimedActionTaskContent.CurrentRouteOfAdmin.Id = change;
      } else if (changedVariable === "TimedActionTaskContent.AttachedMedias") {
        draftPhases[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
          currentBlockIndex
        ].BlockContent.TimedActionTaskContent.AttachedMedias?.push(change);
      } else if (
        changedVariable === "TimedActionTaskContent.BulkAttachedMedias"
      ) {
        draftPhases[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
          currentBlockIndex
        ].BlockContent.TimedActionTaskContent.AttachedMedias = [
          ...draftPhases[currentPhaseIndex].Sections[currentSectionIndex]
            .Blocks[currentBlockIndex].BlockContent.TimedActionTaskContent
            .AttachedMedias,
          ...change,
        ];
      } else {
        if (changedVariable.includes("TimedActionTaskContent")) {
          const propertyName = changedVariable.split(".");
          (draftPhases[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
            currentBlockIndex
          ].BlockContent.TimedActionTaskContent[
            propertyName[propertyName.length - 1]
          ] as any) = change;
        } else {
          (draftPhases[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
            currentBlockIndex
          ].BlockContent[changedVariable] as any) = change;
        }
      }
      if (!isNullOrUndefined(secondChangedVariable)) {
        (draftPhases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
          currentBlockIndex
        ].BlockContent![secondChangedVariable] as any) = !isNullOrUndefined(
          secondChangedValue,
        )
          ? secondChangedValue
          : change;
      }
    });

    const currentBlock =
      newPhases[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
        currentBlockIndex
      ];

    if (changedVariable === "RequiredInPhaseOffset") {
      const newPhasesWithDeletedBlocks = produce(
        newPhases,
        (draftPhases: any) => {
          for (let i = 0; i < draftPhases.length; i++) {
            if ((i > currentPhaseIndex + change) as unknown as number) {
              for (let j = 0; j < draftPhases[i]?.Sections?.length; j++) {
                for (
                  let k = 0;
                  k < draftPhases[i]?.Sections[j]?.Blocks?.length;
                  k++
                ) {
                  if (
                    draftPhases[i]?.Sections[j]?.Blocks[k]?.UUID ===
                    currentBlock?.UUID
                  ) {
                    // deleting block
                    draftPhases[i]?.Sections[j]?.Blocks?.splice(k, 1);
                    k--;
                  }
                }
              }
            }
          }
        },
      );
      if ((change as number) === 0) {
        dispatch(SetPhases(newPhasesWithDeletedBlocks));
      } else {
        // state.phases = newPhasesWithDeletedBlocks;
        // we need to add logic on how to update ghost treatment blocks or delete all ghost treatment blocks and let next function recreate them
        const timedActionInPhase: any =
          newPhasesWithDeletedBlocks[currentPhaseIndex]?.Sections[
            currentSectionIndex
          ]?.Blocks?.[currentBlockIndex];
        const newBlockToCreate: Block = {
          OrderInNextPhase1: 0,
          OrderInNextPhase2: 0,
          IsGhostBlock: timedActionInPhase.IsGhostBlock,
          UUID: timedActionInPhase.UUID,
          Id: timedActionInPhase.Id,
          SectionId: timedActionInPhase.SectionId,
          Order: 0,
          BlockType: timedActionInPhase.BlockType,
          BlockContent: timedActionInPhase.BlockContent,
        };
        const phaseIndexesWhereRequired =
          indexesOfPhasesWithRelatedGhostTimedActionTaskBlock(
            newBlockToCreate.BlockContent as
              | TreatmentTaskBlockContent
              | MiscellaneousTaskBlockContent
              | DiagnosticTaskBlockContent
              | MedicationTaskBlockContent,
            newPhasesWithDeletedBlocks,
          );
        const newPhases = produce(newPhasesWithDeletedBlocks, (draftPhases) => {
          for (let i = 0; i < phaseIndexesWhereRequired.length; i++) {
            draftPhases[phaseIndexesWhereRequired[i]] =
              makeSurePhaseHasTimedActionBlockElseAdd(
                draftPhases[phaseIndexesWhereRequired[i]],
                newBlockToCreate as
                  | MedicationTaskBlock
                  | TreatmentTaskBlock
                  | DiagnosticTaskBlock
                  | MiscellaneousTaskBlock,
              );
            for (
              let j = 0;
              j < draftPhases[phaseIndexesWhereRequired[i]]?.Sections?.length;
              j++
            ) {
              if (
                isNullOrUndefined(
                  draftPhases[phaseIndexesWhereRequired[i]]?.Sections[j]?.Id,
                ) ||
                draftPhases[phaseIndexesWhereRequired[i]]?.Sections[j]?.Id === 0
              ) {
                if (
                  draftPhases[phaseIndexesWhereRequired[i]]?.Sections[j]?.UUID
                ) {
                  const newSectionId = uuidv4();
                  const newSection: SectionForCreateThunk = {
                    PhaseIndex: phaseIndexesWhereRequired[i],
                    SectionIndex: j,
                    UUID: newSectionId,
                    PhaseId: draftPhases[phaseIndexesWhereRequired[i]]?.Id,
                    Order:
                      draftPhases[phaseIndexesWhereRequired[i]]?.Sections
                        ?.length,
                    Title: "",
                    Blocks: [],
                    Collapsed: false,
                    SectionType: "timed actions",
                    IsCriticalActionRequired: true,
                  };
                  dispatch(CreateSectionThunk(newSection));
                } else {
                  const newSection =
                    draftPhases[phaseIndexesWhereRequired[i]]?.Sections[j];
                  dispatch(CreateSectionThunk(newSection));
                }
              }
            }
          }
        });
        dispatch(SetPhases(newPhases));
      }
    } else {
      if (currentBlock.BlockContent.RequiredInPhaseOffset > 0) {
        const updatedPhasesRequired = produce(
          newPhases,
          (draftPhases: Phase[]) => {
            draftPhases.forEach((phase) => {
              if (!isNullOrUndefined(phase)) {
                phase.Sections?.forEach((section) => {
                  if (!isNullOrUndefined(section))
                    section.Blocks?.forEach((block) => {
                      if (block.UUID === currentBlock.UUID) {
                        if (
                          changedVariable === "TimedActionTaskContent.Subtasks"
                        ) {
                          block.BlockContent.TimedActionTaskContent[
                            changedVariable
                          ] =
                            draftPhases[currentPhaseIndex].Sections[
                              currentSectionIndex
                            ].Blocks[
                              currentBlockIndex
                            ].BlockContent.TimedActionTaskContent.Subtasks;
                        } else if (
                          changedVariable ===
                          "TimedActionTaskContent.AttachedMedias"
                        ) {
                          block.BlockContent.TimedActionTaskContent[
                            changedVariable
                          ] =
                            draftPhases[currentPhaseIndex].Sections[
                              currentSectionIndex
                            ].Blocks[
                              currentBlockIndex
                            ].BlockContent.TimedActionTaskContent.AttachedMedias;
                        } else {
                          block.BlockContent.TimedActionTaskContent[
                            changedVariable
                          ] = change;
                        }
                      }
                    });
                });
              }
            });
          },
        );
        dispatch(SetPhases(updatedPhasesRequired));
      } else {
        dispatch(SetPhases(newPhases));
      }
    }
  };

  const handleTimedActionBlockChange_Media = (
    blockType: BlockType,
    changedVariable:
      | keyof DeepKeysOf<UpdateDiagnosticTaskBlockContent>
      | keyof DeepKeysOf<UpdateMedicationTaskBlockContent>
      | keyof DeepKeysOf<UpdateTreatmentTaskBlockContent>
      | keyof DeepKeysOf<UpdateMiscellaneousTaskBlockContent>,
    change: any,
    secondChangedVariable?:
      | keyof DeepKeysOf<UpdateDiagnosticTaskBlockContent>
      | keyof DeepKeysOf<UpdateMedicationTaskBlockContent>
      | keyof DeepKeysOf<UpdateTreatmentTaskBlockContent>
      | keyof DeepKeysOf<UpdateMiscellaneousTaskBlockContent>,
    secondChangedValue?: any,
  ) => {
    handleTimedActionBlockChange(
      blockType,
      changedVariable as
        | DeepKeysOf<UpdateMiscellaneousTaskBlockContent>
        | DeepKeysOf<UpdateTreatmentTaskBlockContent>
        | DeepKeysOf<UpdateDiagnosticTaskBlockContent>
        | DeepKeysOf<UpdateMedicationTaskBlockContent>,
      change,
      secondChangedVariable as
        | DeepKeysOf<UpdateMiscellaneousTaskBlockContent>
        | DeepKeysOf<UpdateTreatmentTaskBlockContent>
        | DeepKeysOf<UpdateDiagnosticTaskBlockContent>
        | DeepKeysOf<UpdateMedicationTaskBlockContent>,
      secondChangedValue,
    );
  };

  const onChangeCasualtyStatus = (
    changedVariable: keyof CasualtyStatus,
    change?: any,
    secondChangedVariable?: keyof CasualtyStatus,
    secondChangeValue?: any,
  ) => {
    const newPhases = produce(phases, (draftPhases) => {
      (draftPhases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
        currentBlockIndex
      ].BlockContent![changedVariable] as any) = change;

      if (!isNullOrUndefined(secondChangedVariable)) {
        (draftPhases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
          currentBlockIndex
        ].BlockContent![secondChangedVariable] as any) = secondChangeValue;
      }
    });
    dispatch(SetPhases(newPhases));
  };
  const onCasualtyStatusSave = () => {
    const casualtyStatus =
      phases![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
        currentBlockIndex
      ];
    UpdateCasualtyStatusBlock(
      casualtyStatus,
      props.postSuccess,
      props.postError,
    );
  };

  const handleCriticalOrAARToggle = (type: string, AARToggle: boolean) => {
    if (type === "AAR") {
      const phasesSectionUpdate = produce(phases, (draftPhases) => {
        draftPhases![currentPhaseIndex].Sections![
          currentSectionIndex
        ]!.IsAARRequired = AARToggle;
      });
      const currentSection: Section = {
        SectionType:
          phases[currentPhaseIndex].Sections![currentSectionIndex].SectionType,
        Id: phases[currentPhaseIndex].Sections![currentSectionIndex].Id,
        UUID: phases[currentPhaseIndex].Sections![currentSectionIndex].UUID,
        Title: phases[currentPhaseIndex].Sections![currentSectionIndex].Title,
        Order: phases[currentPhaseIndex].Sections![currentSectionIndex].Order,
        PhaseId: phases![currentPhaseIndex].Id!,
        IsCriticalActionRequired:
          phases[currentPhaseIndex].Sections![currentSectionIndex]
            .IsCriticalActionRequired,
        IsAARRequired: AARToggle,
        Collapsed: false,
        Blocks: phases[currentPhaseIndex].Sections[currentPhaseIndex].Blocks,
      };
      UpdateSection(currentSection, props.postSuccess, props.postError);
      dispatch(SetPhases(phasesSectionUpdate));
    }
  };

  const handleSectionTypeUpdate = (
    sectionType: string,
    isCritical: boolean,
  ) => {
    const newSection: Section = {
      SectionType: sectionType,
      Id: phases[currentPhaseIndex].Sections![currentSectionIndex].Id,
      UUID: phases[currentPhaseIndex].Sections![currentSectionIndex].UUID,
      Title: phases[currentPhaseIndex].Sections![currentSectionIndex].Title,
      Order: phases[currentPhaseIndex].Sections![currentSectionIndex].Order,
      PhaseId: phases![currentPhaseIndex].Id!,
      Blocks: phases[currentPhaseIndex].Sections![currentSectionIndex].Blocks,
      IsCriticalActionRequired: sectionType === "timed actions",
      IsAARRequired:
        phases[currentPhaseIndex].Sections![currentSectionIndex].IsAARRequired,
      Collapsed: false,
    };
    const phasesSectionUpdate = produce(phases, (draftPhases) => {
      draftPhases![currentPhaseIndex].Sections![currentSectionIndex] =
        newSection;
    });
    dispatch(SetPhases(phasesSectionUpdate));
    dispatch(SetIsTimedSection(sectionType === "timed actions")); // to enable/disable left menu item like enable timed action if section type is timed action and disabled for normal
    UpdateSection(newSection, props.postSuccess, props.postError);
  };

  const onSectionDeleteSuccess = (sectionUUID: string) => (data: any) => {
    const blockIndex = phases![currentPhaseIndex].Sections!.findIndex(
      (x: Section) => x.UUID === sectionUUID,
    );
    const phasesWithoutDeletedSection = produce(phases, (draftPhases) => {
      draftPhases![currentPhaseIndex].Sections!.splice(blockIndex, 1);
    });

    // move the editing-selection to the parent phase
    dispatch(SetActiveComponent(ComponentEdit.PHASE));
    dispatch(SetPhases(phasesWithoutDeletedSection));
    dispatch(SetActiveKey(phases[currentPhaseIndex].UUID));
    populatePhaseWithTreatment(phasesWithoutDeletedSection, dispatch, state);
    props.postSuccess("Section has been deleted successfully");
  };

  const onSectionDelete = (section: Section) => {
    DeleteSection(
      section.Id!,
      onSectionDeleteSuccess(section.UUID),
      props.postError,
    );
  };

  const getEditPanel = () => {
    switch (activeComponent) {
      case ComponentEdit.CASUALTY:
        return (
          <CasualtyEdit
            object={props.Casualty}
            sectionTitle={"Casualty"}
            position={1}
            onChange={onChangeBlock}
            onSave={onBlockSave}
            onDelete={onBlockDelete}
            setAlertMessage={props.setAlertMessage}
            postSuccess={props.postSuccess}
          />
        );
      case ComponentEdit.LIST:
        return (
          <ListEdit
            key={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex]?.Id
            }
            object={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex] as ScriptListBlock
            }
            sectionTitle={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex].Title
            }
            position={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex]?.Order + 1
            }
            onListTitleAdd={onListTitleAdd}
            onChange={onChangeBlock}
            onSave={onBlockSave}
            onUpdate={onListBlockUpdate}
            onDelete={onBlockDelete}
            onDeleteItem={onBlockItemDelete}
            addListItem={onBlockItemAdd}
            onCopy={onBlockCopy}
            updateListItem={onListBlockUpdate}
          />
        );

      case ComponentEdit.PHASE:
        return (
          <PhaseEdit
            key={phases[currentPhaseIndex]?.Id}
            loadingToggle={props.loadingToggle}
            postSuccess={props.postSuccess}
            postError={props.postError}
          />
        );

      case ComponentEdit.RPPROMPT:
        return (
          <RolePlayerEdit
            key={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex]?.Id
            }
            object={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex] as TextBlock
            }
            onUpdate={onTextColumnDisplayUpdate}
            onChange={onChangeRolePlayerBlock}
            onSave={onBlockSave}
            onDelete={onBlockDelete}
            onCopy={onBlockCopy}
          />
        );

      case ComponentEdit.SECTION:
        return (
          <SectionEdit
            key={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex]?.Id
            }
            object={phases[currentPhaseIndex]?.Sections![currentSectionIndex]}
            TitleAbbreviated={phases[currentPhaseIndex]?.TitleAbbreviated}
            postion={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]?.Order +
              1
            }
            phase={phases[currentPhaseIndex]}
            postError={props.postError}
            postSuccess={props.postSuccess}
            onDelete={onSectionDelete}
            sectionTitle={sectionTitle}
            setSectionType={handleSectionTypeUpdate}
            handleCriticalOrAARToggle={handleCriticalOrAARToggle}
          />
        );

      case ComponentEdit.SUPPORTACTION:
        return (
          <SupportActionEdit
            key={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex]?.Id
            }
            object={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex] as TextBlock
            }
            onUpdate={onTextColumnDisplayUpdate}
            onChange={onChangeRolePlayerBlock}
            onSave={onBlockSave}
            onDelete={onBlockDelete}
            onCopy={onBlockCopy}
          />
        );

      case ComponentEdit.TEXT:
        return (
          <TextEdit
            key={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex]?.Id
            }
            object={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex] as TextBlock
            }
            onChange={onChangeBlock}
            onSave={onBlockSave}
            onDelete={onBlockDelete}
            onUpdate={onTextColumnDisplayUpdate}
            onCopy={onBlockCopy}
          />
        );

      case ComponentEdit.TREATMENT:
        return (
          <TreatmentEdit
            key={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex]?.Id
            }
            object={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex] as TreatmentTaskBlock
            }
            currentPhaseIndex={currentPhaseIndex}
            handleTimedActionBlockChange={handleTimedActionBlockChange}
            onSave={onBlockSave}
            onDelete={onBlockDelete}
            onCopy={onBlockCopy}
            updateBlockData={replaceBlockData}
            handleSubtaskUpdate={handleSubtaskUpdate}
          />
        );
      case ComponentEdit.MISCELLANEOUS:
        return (
          <MiscellaneousEdit
            key={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex]?.Id
            }
            object={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex] as MiscellaneousTaskBlock
            }
            currentPhaseIndex={currentPhaseIndex}
            handleTimedActionBlockChange={handleTimedActionBlockChange}
            onSave={onBlockSave}
            onDelete={onBlockDelete}
            onCopy={onBlockCopy}
            updateBlockData={replaceBlockData}
            handleSubtaskUpdate={handleSubtaskUpdate}
          />
        );
      case ComponentEdit.DIAGNOSTIC:
        return (
          <DiagnosticEdit
            key={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex]?.Id
            }
            object={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex] as DiagnosticTaskBlock
            }
            handleTimedActionBlockChange={handleTimedActionBlockChange}
            onSave={onBlockSave}
            onDelete={onBlockDelete}
            onCopy={onBlockCopy}
            updateBlock={replaceBlockData}
            handleSubtaskUpdate={handleSubtaskUpdate}
          />
        );
      case ComponentEdit.MEDICATION:
        return (
          <MedicationEdit
            key={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex]?.Id
            }
            object={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex] as MedicationTaskBlock
            }
            handleTimedActionBlockChange={handleTimedActionBlockChange}
            onSave={onBlockSave}
            onDelete={onBlockDelete}
            onCopy={onBlockCopy}
            updateBlockData={replaceBlockData}
            handleSubtaskUpdate={handleSubtaskUpdate}
          />
        );

      case ComponentEdit.CASUALTYSTATUS:
        return (
          <CasualtyStatusEdit
            key={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex]?.Id
            }
            object={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex] as CasualtyStatusBlock
            }
            currentPhaseIndex={currentPhaseIndex}
            onChange={onChangeCasualtyStatus}
            onDelete={onBlockDelete}
            onSave={onCasualtyStatusSave}
            onCopy={() => undefined}
          />
        );
      case ComponentEdit.RESEARCHQUESTION:
        return (
          <ResearchQuestionEdit
            key={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex]?.Id
            }
            object={
              phases[currentPhaseIndex]?.Sections![currentSectionIndex]
                ?.Blocks![currentBlockIndex] as ResearchQuestionBlock
            }
            currentPhaseIndex={currentPhaseIndex}
            onDelete={onBlockDelete}
            onCopy={() => undefined}
          />
        );
      default:
        console.warn("unhandled case in getEditPanel of Mainlist.tsx");
    }
  };

  const handleMouseHover = (entering: boolean) => () => {
    setMouseHoveringBlock(entering);
  };

  interface BeforeCapture {
    draggableId: string;
    mode: "FLUID" | "SNAP";
  }

  const handleOnBeforeCapture = (before: BeforeCapture) => {
    // if the user grabs a section, ensure that it's collapsed
    const [contentType, UUID] = before.draggableId.split(".");
    dispatch(SetActiveDragContentType(contentType));
    if (contentType === "Section" && UUID !== "New") {
      const targetIndex = phases[currentPhaseIndex].Sections!.findIndex(
        (s) => s.UUID === UUID,
      );
      if (targetIndex === -1) {
        console.log(
          "Error: unable to locate Section within phases state object",
        );
      }
    }
  };

  const customSubList = (
    item: any,
    sectionMapIndex: number,
    columnType: string,
    handleMouseHover: Function,
    selectobject: Function,
    sectionUUID: string,
  ) => {
    return (
      <SubList
        key={item.UUID}
        subItems={item.Blocks!}
        type={item.UUID}
        currentBlockIndex={currentBlockIndex}
        selectobject={selectobject}
        textColumnType={columnType}
        sectionIndex={sectionMapIndex}
        activeComponentIndex={
          currentSectionIndex === sectionMapIndex &&
          activeComponent !== ComponentEdit.SECTION &&
          activeComponent !== ComponentEdit.PHASE
            ? currentBlockIndex
            : -1
        }
        mouseHoveringCB={handleMouseHover}
        currentPhaseIndex={currentPhaseIndex}
        sectionUUID={sectionUUID}
      />
    );
  };

  const handleSubtaskUpdate = (id: number, newValue: string) => {
    const newPhases = produce(phases, (draftPhases) => {
      const index = draftPhases[currentPhaseIndex].Sections[
        currentSectionIndex
      ].Blocks[
        currentBlockIndex
      ].BlockContent.TimedActionTaskContent?.Subtasks.findIndex(
        (subtask: UpdateTimedActionSubTask) => subtask.Id === id,
      );
      if (index !== -1) {
        draftPhases[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
          currentBlockIndex
        ].BlockContent.TimedActionTaskContent.Subtasks[index].Description =
          newValue;
      }
    });
    dispatch(SetPhases(newPhases));
  };

  return (
    <MainListRow className={isReadOnly ? "readOnly withNotification" : ""}>
      {!timedActionMediaDisplay && !editMediaDisplay ? (
        <DragDropContext
          onDragEnd={onDragEnd}
          onBeforeCapture={handleOnBeforeCapture}
        >
          {isReadOnly ? null : (
            <SideDragMenu
              isSectionTypeTimedAction={state.isTimedSection}
              showEditCasualtyPane={props.showEditCasualtyPane}
            />
          )}

          <ScriptBuilderCenter
            postError={props.postError}
            getEditPanel={getEditPanel}
            mouseHoveringBlock={mouseHoveringBlock}
            handleMouseHover={handleMouseHover}
            loadingToggle={props.loadingToggle}
            children={customSubList}
            showEditCasualtyPane={props.showEditCasualtyPane}
          />

          <SectionCol
            xxl={{ span: 9 }}
            xl={{ span: 7 }}
            lg={!collapse ? { span: 8 } : { span: 7 }}
            md={!collapse ? { span: 8 } : { span: 7 }}
            sm={{ span: 7 }}
            flex="1 1 0%"
            className="rightSideBar"
          >
            {getEditPanel()}
          </SectionCol>
        </DragDropContext>
      ) : editMediaDisplay ? (
        <EditMediaDetails />
      ) : (
        <FileUpload
          accept=".jpg,.png,.jpeg,.wav, .pdf, .doc,.mp4"
          label="Media Upload Gallery"
          onTimedActionBlockChange={handleTimedActionBlockChange_Media}
        />
      )}
    </MainListRow>
  );
};

export default MainLists;
