import styled from "styled-components";
import { Select, InputNumber } from "antd";

interface IHeightContainer {
  secondContainer?: boolean;
}

interface ISelector {
  secondSelector?: boolean;
}

export const Form = styled.div`
  width: 100%;
  height: auto;
  margin-top: 10px;
  /* border: 2px solid yellow; */
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .error-label {
    border-bottom: 2px solid red !important;
  }

  input:focus + .label-name {
    border-bottom: 2px solid rgba(128, 128, 128, 255);
  }

  input:focus + .label-name .content-name,
  input:focus + .label-name .error {
    transform: translateY(-25px);
  }

  input:focus + .label-name .content-name {
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  .filled-label {
    border-bottom: 2px solid rgba(128, 128, 128, 255);
  }

  .filled-content {
    transform: translateY(-25px);
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  .filled-error {
    transform: translateY(-25px);
  }

  /* input:valid + .label-name .content-name
  {
    transform: translateY(-25px);
    font-size: 1.6em;
  } */

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    input:focus + .label-name .content-name {
      font-size: 1.3em;
    }

    .filled-content {
      font-size: 1.3em;
    }

    .error {
      font-size: 1.2em;
    }
  }
`;

export const ErrorSpan = styled.span`
  color: red;
  font-size: 1.4em;
  align-items: flex-start;
  position: absolute;
  bottom: 0px;
  right: 0px;
`;

export const LabelSpan = styled.span`
  color: rgba(112, 112, 112, 255);
  font-size: 2em;
  align-items: flex-end;
  position: absolute;
  bottom: 0px;
  left: 0px;

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 1.8em;
  }
`;

export const LabelErrorSpan = styled.span`
  color: rgba(112, 112, 112, 255);
  font-size: 2em;
  align-items: flex-end;
  position: absolute;
  bottom: 0px;
  left: 0px;

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 1.8em;
  }
`;

export const Label = styled.label`
  //height: 92%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 1px solid red; */
  font-size: 10px;
  position: absolute;
  /* top: 0.625rem; */
  bottom: 0;
  left: 0;
  /* right: 0.625rem; */
  /* background-color: blue; */
  pointer-events: none;
  border-bottom: 2px solid rgba(96, 96, 96, 255);
  transition: all 0.3s ease;

  @media (min-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    width: 32%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    width: 25%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xxl}) {
    width: 33%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    width: 36%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    width: 40%;
  }
`;

export const AllergyLabelDiv = styled.div`
  width: 100%;
  height: auto;
  margin-top: 10px;
  /* border: 2px solid yellow; */
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .ant-select-multiple .ant-select-selection-search {
    -webkit-margin-start: 0 !important;
    margin-inline-start: 0 !important;
  }

  .error-label {
    border-bottom: 2px solid red !important;
  }

  input:focus + .label-name {
    border-bottom: 2px solid rgba(128, 128, 128, 255);
  }

  input:focus + .label-name .content-name,
  input:focus + .label-name .error {
    transform: translateY(-25px);
  }

  input:focus + .label-name .content-name {
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  .filled-label {
    border-bottom: 2px solid rgba(128, 128, 128, 255);
  }

  .filled-content {
    transform: translateY(-25px);
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  .filled-error {
    transform: translateY(-25px);
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    input:focus + .label-name .content-name {
      font-size: 1.3em;
    }

    .filled-content {
      font-size: 1.3em;
    }

    .error {
      font-size: 1.2em;
    }
  }
`;

export const AllergyLabel = styled.label`
  //height: 92%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 1px solid red; */
  font-size: 10px;
  position: absolute;
  /* top: 0.625rem; */
  top: 50px;
  left: 5px;
  //top: 100%;
  /* right: 0.625rem; */
  /* background-color: blue; */
  pointer-events: none;
  //border-bottom: 2px solid rgba(96, 96, 96, 255);
  transition: all 0.3s ease;

  &:focus {
    top: 45px !important;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    width: 32%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    width: 25%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xxl}) {
    width: 33%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    width: 36%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    width: 40%;
  }
`;

export const Input = styled.input`
  height: 100%;
  background-color: rgba(0, 0, 0, 255);
  margin-top: 2px;
  font-size: 1.25rem;
  padding-top: 1.25rem;
  border: none;

  @media (min-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    width: 25%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    width: 20%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.xxl}) {
    width: 25%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    width: 26%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    width: 29%;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 1.2em;
  }
`;

export const InputAntdVersion = styled(InputNumber)`
  height: 100% !important;
  background-color: rgba(0, 0, 0, 255) !important;
  margin-top: 2px !important;
  font-size: 1.25rem !important;
  padding-top: 1.25rem !important;
  border: none !important;

  @media (min-width: ${({ theme }) => theme.breakpoints.xxxl}) {
    width: 25%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    width: 20%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.xxl}) {
    width: 25%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    width: 26%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    width: 29%;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 1.2em;
  }
`;

export const InputDiv = styled.div`
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const SuffixSpan = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 1.5em;
  padding-left: 5px;
`;

export const HeigtContainer = styled.span`
  width: 42%;
  height: 100%;
`;

export const HeightLabel = styled.label<IHeightContainer>`
  height: 92%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid red; */
  font-size: 10px;
  position: absolute;
  /* top: 0.625rem; */
  bottom: 0;
  left: 0;
  /* right: 0.625rem; */
  /* background-color: blue; */
  pointer-events: none;
  border-bottom: 2px solid rgba(96, 96, 96, 255);
  transition: all 0.3s ease;
  width: ${({ secondContainer }) => (secondContainer ? "100% " : " 42%")};

  /* @media (min-width : ${({ theme }) => theme.breakpoints?.xxxl})
 {
    width: 32%;
 }

 @media (max-width : ${({ theme }) => theme.breakpoints?.xxxl})
 {
    width: 25%;
 }

 @media (max-width : ${({ theme }) => theme.breakpoints?.xxl})
 {
    width: 33%;
 }
 @media (max-width : ${({ theme }) => theme.breakpoints?.lg})
 {
    width: 36%;
 }

 @media (max-width : ${({ theme }) => theme.breakpoints?.md})
 {
    width: 40%;
 } */
`;

export const HeightContainerTwo = styled.div`
  width: 42%;
  height: 100%;
  position: absolute;
  right: 0;
`;
export const SelectDiv = styled.div`
  width: 100%;
  height: auto;
  margin-top: 10px;
  /* border: 2px solid yellow; */
`;
export const Selector = styled.select<ISelector>`
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 255);
  margin-top: 0.625rem;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid rgba(96, 96, 96, 255);
  font-size: 1.25rem;

  .ant-select-selector {
    background-color: red;
  }
`;

export const MutiSelector = styled(Select)`
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 255) !important;
  margin-top: 1.25rem;
  outline: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 2px solid rgba(96, 96, 96, 255) !important;
  font-size: 1.25rem;

  .ant-select-selection-item:hover svg {
    color: white !important;
  }
`;

export const AllergyMultiSelector = styled(Select)`
  .ant-select-selection-item:hover svg {
    color: white !important;
  }
`;
