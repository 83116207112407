import axios from "axios";
import { defaultErrorCallback } from "../ErrorCallback";

const apiPrefix = "api/1/pfc/Dashboard";

export function getAllWidgets(callback: Function) {
  axios
    .get(`${apiPrefix}/Widget`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      defaultErrorCallback(err);
    });
}
