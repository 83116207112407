import * as React from "react";
import { Component } from "react";
import { DeleteOutlined, EditFilled, EyeOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";
import { Row, Modal, Col } from "antd";
import audio_icon from "../../../assets/icons/Upload_Audio_Icon.png";
import { connect } from "react-redux";
import { AlertType } from "../../../models/Enums";
import { SystemMessage } from "../../../app/modules";
import { setAlertMessage } from "../../../actions/alertMessage";

interface Props {
  resources: any[];
  deleteFile: Function;
  editResource: Function;
  SystemMessage: SystemMessage;
  setAlertMessage: Function;
}
interface State {
  loading: boolean;
  showImage: boolean;
  previewImg: string;
}

class AudioList extends Component<Props, State> {
  state: State = {
    loading: false,
    showImage: false,
    previewImg: "",
  };
  componentDidMount() {
    console.log(this.props);
  }

  modalToggle = () => {
    this.setState({ showImage: !this.state.showImage });
  };

  preview = async (url: string) => {
    await this.setState({ previewImg: url });
    this.setState({ showImage: true });
  };

  error = (data: any) => {
    console.log(data);
    //message.error("An error occured check the console for more information");
    this.props.setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An error occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
  };

  render() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {this.props?.resources?.map((item: any) => (
          <Col
            xs={24}
            sm={8}
            md={6}
            lg={4}
            xl={3}
            style={{
              padding: "5px",
              overflow: "hidden",
              height: "220px",
              display: "inline-block",
            }}
            key={item.id}
          >
            <Row style={{ height: "150px" }}>
              <div className="showImgName">
                <div style={{ marginTop: "30%" }}>
                  <DeleteOutlined
                    style={{
                      fontSize: "2.3em",
                      marginRight: "10px",
                      color: "#f00",
                    }}
                    onClick={() =>
                      this.props.deleteFile(item.fileName, item.id)
                    }
                  />
                  <EyeOutlined
                    style={{ fontSize: "2.3em", color: "#0f0" }}
                    onClick={() => this.preview(item.url)}
                  />
                  <EditFilled
                    style={{
                      fontSize: "2.3em",
                      marginLeft: "10px",
                      color: "#f5e218",
                    }}
                    onClick={() => this.props.editResource(item)}
                  />
                </div>
              </div>
              <div
                className="noDistortImage"
                style={{ backgroundImage: "url(" + audio_icon + ")" }}
              ></div>
              {/*<img style={{ width: "100%", height: "100%" }} src={audio_icon} />*/}
            </Row>
            <Row>{item.name}</Row>
          </Col>
        ))}

        <Modal
          visible={this.state.showImage}
          title="Preview"
          onCancel={this.modalToggle}
          footer={null}
          width={1200}
          style={{ height: "800px" }}
        >
          <div style={{ width: "100%", height: "600px" }}>
            <div
              style={{
                display: "block",
                boxSizing: "border-box",
                padding: "200px 0",
              }}
            >
              <audio
                key={this.state.previewImg}
                controls
                style={{ display: "block", margin: "0 auto" }}
              >
                <source src={this.state.previewImg} />
              </audio>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

// export default AudioList;
function mapToStateToProps(state: any) {
  return {
    SystemMessage: state.SystemMessage,
  };
}

export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(AudioList);
