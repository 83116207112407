import * as React from "react";
import { Dropdown, Modal, Row, Tooltip } from "antd";
import {
  CopyOutlined,
  CrownFilled,
  DeleteOutlined,
  EditOutlined,
  HeartOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import "./Options.scss";
import { deleteCasualty } from "../../../../../api/Casualty/ApiPost";
import { colorPalette } from "../../../../../styles/MainList.styled";
import ConfirmDialog from "../../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../../components/ConfirmDialog/useConfirmDialog";

interface Props {
  Id: number;
  casualty: any;
  onEditCasualty: Function;
  onDeleteCasualty: Function;
}

export const OptionsMenu = (props: Props) => {
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();
  const onEdit = (e: any) => {
    e.preventDefault();
    props.onEditCasualty(props.Id);
  };
  const deleteSuccess = () => {
    props.onDeleteCasualty(props.Id);
  };
  const error = (data: any) => {
    console.log("error", data);
  };
  const onDelete = (e: any) => {
    e.preventDefault();
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to delete the casualty and its related data",
      content: "Are you sure you want to delete this casualty permanently",
      okText: "Yes",
      onOk: () => {
        deleteCasualty(props.Id, deleteSuccess, error);
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: (e) => {
        console.log(e);
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };

  const items = [
    {
      label: (
        <a className="ant-dropdown-link" onClick={onEdit} href={undefined}>
          Edit
        </a>
      ),
      key: `Edit-${props.Id}`,
      icon: <EditOutlined />,
    },
    {
      label: "Duplicate",
      key: `Duplicate-${props.Id}`,
      icon: <CopyOutlined />,
      disabled: true,
    },
    {
      label: "Favorite",
      key: `Favorite-${props.Id}`,
      icon: <HeartOutlined />,
      disabled: true,
    },
    {
      label: "Master",
      key: `Master-${props.Id}`,
      icon: props.casualty.IsMasterCasualty ? (
        <CrownFilled style={{ color: colorPalette.warning }} />
      ) : (
        <CrownFilled />
      ),
      disabled: true,
    },
    {
      label: (
        <a className="ant-dropdown-link" onClick={onDelete} href={undefined}>
          Delete
        </a>
      ),
      key: `Delete-${props.Id}`,
      icon: <DeleteOutlined />,
      disabled: true,
    },
  ];

  return (
    <>
      <Row justify="space-between" onClick={(e: any) => e.stopPropagation()}>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Tooltip title={`Options`}>
            <MoreOutlined className="actions-item action-more" />
          </Tooltip>
        </Dropdown>
      </Row>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </>
  );
};
