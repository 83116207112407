import styled from "styled-components";

export const TimedActionExpandCollapseDiv = styled.div`
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  font-size: 1.5rem;

  &:hover {
    color: #ffffff;
    cursor: pointer;
  }
`;
