import * as React from "react";
import { useEffect } from "react";
import {
  Phase,
  SubtaskForTreatment,
  Treatment,
} from "../../../../models/ScriptInterfaces";
import { IconColumn } from "../IconColumn";
import { useFormik } from "formik";
import {
  Container,
  FixedDiv,
  ScrollableTimedActionTaskDiv,
} from "../../../../styles/EditComponentTheme.styled";
import { FilterBarContainer } from "../../../../styles/MainList.styled";
import CategorizationAndRequirements from "../../../../components/TreatmentEdit/CategorizationAndRequirements";
import { isNullOrUndefined } from "../../../../utils/utilityfunctions";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreatorsForTreatment } from "../../../../store";
import { RequiredTA } from "../../../../components/TimedActions/RequiredTA";
import { AdditionalInfoTA } from "../../../../components/TimedActions/AdditionalInfoTA";
import { useAppSelector } from "../../../../store/hooks";
import {
  SetCurrentTimedActionUploadingMedia,
  SetPhases,
  SetTimedActionMediaDisplay,
} from "../../MainlistSlice";
import produce from "immer";
import TreatmentSearchAdd from "../../../../components/TreatmentEdit/TreatmentSearchAdd";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../components/ConfirmDialog/useConfirmDialog";
import { BlockType } from "../../../../models/Enums";
import { useDebounce } from "../../../../hooks/useDebounce";
import { updateTreatmentTaskBlock } from "../../../../api/TreatmentTaskBlock";
import {
  TreatmentTaskBlock,
  UpdateTreatmentTaskBlock,
} from "../../../../models/TreatmentTaskBlock";
import { MediaTA } from "../../../../components/TimedActions/MediaTA";
import { DefineStartTimeTA } from "../../../../components/TimedActions/DefineStartTimeTA";
import { DurationTa } from "../../../../components/TimedActions/DurationTA";
import { IntervalTA } from "../../../../components/TimedActions/IntervalTA";
import { RatingTA } from "../../../../components/TimedActions/RatingTA";
import { SubtasksTA } from "../../../../components/TimedActions/SubtasksTA";
import { TreatmentTask } from "../../../../models/TreatmentTask";
import {
  CreateTimedActionSubTask,
  TimedActionSubTask,
} from "../../../../models/TimedActionSubTask";
import {
  deleteMediaForTimedActionTask,
  deleteSubtaskForTimedAction,
} from "../../../../api/TimedActionTask";
import { Media } from "../../../../models/MediaInterface";
import { makeTreatmentTaskMaster } from "../../../../api/TreatmentTask";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import { SetSelectedPhase } from "../MedicationEdit/MedicationSlice";
import { TimedActionTaskStatus } from "../../../../models/TimedActionTaskStatus";
import { Tooltip } from "antd";

interface Props {
  object: TreatmentTaskBlock;
  handleTimedActionBlockChange: Function;
  currentPhaseIndex: number;
  onSave: any; //Function
  onDelete: any; //Function
  onCopy: any; //Function
  updateBlockData: Function;
  handleSubtaskUpdate: Function;
}

type ErrorType = {
  [key: string]: string;
};

const TreatmentEdit = (props: Props) => {
  const { materialRequirements, skillCategorization } = useAppSelector(
    (state) => state.FeatureFlagReducer.scriptBuilder.treatmentBlock,
  );
  const dispatch = useDispatch();
  const {
    updateInterval,
    SetLastFocused,
    updateUploadedMedia,
    updateSelectedPhase,
    toggleTreatmentOption,
    updateSubtaskForm,
  } = bindActionCreators(actionCreatorsForTreatment, dispatch);
  const { isEditingAllowed, ...state } = useAppSelector(
    (state) => state.TreatmentReducer,
  );

  const isMasterTreatment =
    props.object?.BlockContent?.TimedActionTaskContent
      ?.TimedActionTaskStatus === TimedActionTaskStatus.CompanyOnly ||
    props.object?.BlockContent?.TimedActionTaskContent
      ?.TimedActionTaskStatus === TimedActionTaskStatus.Master;

  const MainListState = useAppSelector((state) => state.MainlistReducer);
  const phases: Phase[] = MainListState.phases;
  const { isReadOnly, timedActionMediaDisplay } = MainListState;
  const filterBarRef = React.useRef<any>(null);
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();
  const getScrollableDivHeight = () => {
    const height = filterBarRef?.current?.scrollHeight;
    return {
      height: `calc(100vh - 60px - 65px - ${height || 0}px)`,
    };
  };
  useEffect(() => {
    if (!isNullOrUndefined(props.object!)) {
      if (
        !isNullOrUndefined(props.object!.BlockContent!.RequiredInPhaseOffset)
      ) {
        const createdIndex = getIndexFromPhaseId(
          props.object?.BlockContent.CreatedInPhaseId,
        );
        if (createdIndex !== -1) {
          const requiredInPhaseIndex =
            createdIndex + props.object!.BlockContent!.RequiredInPhaseOffset;
          if (phases.length >= requiredInPhaseIndex + 1) {
            if (phases[requiredInPhaseIndex]!.TitleAbbreviated === "") {
              const phaseTitle = `Phase ${requiredInPhaseIndex + 1}`;
              updateSelectedPhase(phaseTitle);
            } else {
              updateSelectedPhase(
                phases[requiredInPhaseIndex].TitleAbbreviated,
              );
            }
          }
        }
      }
      updateUploadedMedia(props.object.BlockContent.UploadedMediaItems);
      updateInterval(props.object.BlockContent.IntervalTime!);
      setOptionsValue();
    }
  }, [phases]);
  console.log("ACtion Created", updateInterval);

  // reset subtask on destroy
  useEffect(() => {
    return () => {
      updateSubtaskForm("");
    };
  }, []);

  const scriptFormik = useFormik({
    initialValues: {
      search: props.object?.BlockContent?.Title || ``,
      description: props.object?.BlockContent?.Description || "",
      additionalNote: props.object?.BlockContent?.AdditionalNote || "",
      phase: "",
      subTask: "",
      interval: 0,
    },
    validate: () => {
      const errors: ErrorType = {};

      return errors;
    },
    validateOnBlur: true,
    onSubmit: () => undefined,
  });

  const onChangeTreatmentBlock = (
    changedVariable: keyof Treatment,
    change: any,
    secondChangedVariable?: keyof Treatment,
    secondChangedValue?: any,
  ) => {
    props.handleTimedActionBlockChange(
      BlockType.Treatment,
      changedVariable,
      change,
      secondChangedVariable,
      secondChangedValue,
    );
  };

  const onTreatmentSave = () => {
    updateTreatmentTaskBlock(
      props.object as UpdateTreatmentTaskBlock,
      () => undefined,
      () => undefined,
    );
  };

  const debounceSubmitForm = useDebounce(onTreatmentSave);

  useEffect(() => {
    debounceSubmitForm();

    return () => {
      debounceSubmitForm.cancel();
    };
  }, [props.object, scriptFormik.isValid, scriptFormik.values]);

  //set option value on load
  // fix for toggle not working and requires 2-3 clicking
  const setOptionsValue = () => {
    toggleTreatmentOption({
      ...state.option,
      IsRequired: props.object.BlockContent["IsRequired"],
      IsCritical: props.object.BlockContent["IsCritical"],
      IsSubtasksEnabled:
        props.object?.BlockContent?.TimedActionTaskContent?.IsSubtasksEnabled,
      IsIntervalEnabled:
        props.object?.BlockContent?.TimedActionTaskContent?.IsIntervalEnabled,
      IsDurationEnabled:
        props.object?.BlockContent?.TimedActionTaskContent?.IsDurationEnabled,
      IsDefineDurationTimeEnabled:
        props.object?.BlockContent?.TimedActionTaskContent
          ?.IsDefineDurationTimeEnabled,
      IsDefineStartTimeEnabled:
        props.object?.BlockContent?.TimedActionTaskContent
          ?.IsDefineStartTimeEnabled,
      IsRatingEnabled:
        props.object?.BlockContent?.TimedActionTaskContent?.IsRatingEnabled,
      IsMediaEnabled:
        props.object?.BlockContent?.TimedActionTaskContent?.IsMediaEnabled,
      IsCustomLabelsEnabled:
        props.object?.BlockContent?.TimedActionTaskContent
          ?.IsCustomLabelsEnabled,
    });
  };

  const onDeleteConfirm = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "Are you sure delete this Block?",
      content: "This cannot be undone",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        props.onDelete(props.object);
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        console.log("Cancel");
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };

  const handlePhaseSelection = (
    phaseTitle: string,
    phaseIndex: number,
    useEff?: boolean,
  ) => {
    if (isNullOrUndefined(useEff)) {
      onChangeTreatmentBlock(
        "RequiredInPhaseOffset",
        phaseIndex - MainListState.currentPhaseIndex,
      );
    }
    if (phaseTitle === "") {
      const phaseTitle = `Phase ${phaseIndex + 1}`;
      updateSelectedPhase(phaseTitle);
    } else {
      updateSelectedPhase(phaseTitle);
    }
  };

  const handleIntervalToggle = (type: keyof TreatmentTask, value: number) => {
    if (isReadOnly) return; //disable action callback in read only script
    if (type === "IsIntervalEnabled") {
      if (state.option.IsIntervalEnabled) {
        updateInterval(0);
        onChangeTreatmentBlock(`TimedActionTaskContent.${type}`, 0);
      }
      toggleTreatmentOption({ ...state.option, [type]: !state.option[type] });
      onChangeTreatmentBlock(
        `TimedActionTaskContent.${type}`,
        !state.option[type],
      );
    } else if (type === "IntervalTime") {
      updateInterval(value);
      onChangeTreatmentBlock(`TimedActionTaskContent.${type}`, value);
    }
  };

  const handleOptionToggle = (type: string) => {
    toggleTreatmentOption({ ...state.option, [type]: !state.option[type] });
    if (type === "IsCritical") {
      onChangeTreatmentBlock(type, !state.option[type]);
    } else if (type === "IsRequired") {
      const createdIndex = getIndexFromPhaseId(
        props.object?.BlockContent.CreatedInPhaseId,
      );
      if (
        createdIndex !== -1 &&
        createdIndex <=
          createdIndex + props.object.BlockContent.RequiredInPhaseOffset
      ) {
        if (phases[createdIndex].TitleAbbreviated === "") {
          const phaseTitle = `Phase ${createdIndex + 1}`;
          dispatch(SetSelectedPhase(phaseTitle));
          onChangeTreatmentBlock(
            "RequiredInPhaseOffset",
            0,
            type,
            !state.option[type],
          );
        } else {
          dispatch(SetSelectedPhase(phases[createdIndex].TitleAbbreviated));
          onChangeTreatmentBlock(
            "RequiredInPhaseOffset",
            0,
            type,
            !state.option[type],
          );
        }
      }
    } else {
      onChangeTreatmentBlock(
        `TimedActionTaskContent.${type}`,
        !state.option[type],
      );
    }
  };

  const handleAddSubTask = (subtask: string) => {
    if (isReadOnly) return; //disable action callback in read only script
    const newSubtask: CreateTimedActionSubTask = {
      Order:
        props.object?.BlockContent?.TimedActionTaskContent?.Subtasks?.length,
      Description: state.subtask,
    };
    onChangeTreatmentBlock("TimedActionTaskContent.Subtasks", newSubtask);
    updateSubtaskForm("");
  };

  const handleDeleteMedia = (mediaId: number) => {
    if (isReadOnly) return; //disable action callback in read only script
    deleteMediaForTimedActionTask(
      props.object?.BlockContent?.TimedActionTaskContent?.Id,
      mediaId,
      () => {
        DeleteMediaSuccess(mediaId);
      },
      (error: any) => {
        console.error(error);
      },
    );
  };

  const DeleteMediaSuccess = (mediaId: number) => {
    const newPhases = produce(phases, (draftPhases) => {
      draftPhases[MainListState.currentPhaseIndex].Sections[
        MainListState.currentSectionIndex
      ].Blocks[
        MainListState.currentBlockIndex
      ].BlockContent.TimedActionTaskContent.AttachedMedias = draftPhases[
        MainListState.currentPhaseIndex
      ].Sections[MainListState.currentSectionIndex].Blocks[
        MainListState.currentBlockIndex
      ].BlockContent.TimedActionTaskContent.AttachedMedias?.filter(
        (attachedMedia: Media) => attachedMedia.Id !== mediaId,
      );
    });
    dispatch(SetPhases(newPhases));
  };

  const handleSubtaskDelete = (subtaskToDelete: TimedActionSubTask) => {
    if (isReadOnly) return; //disable action callback in read only script

    deleteSubtaskForTimedAction(
      subtaskToDelete.Id,
      () => {
        deleteSubtaskSuccess(subtaskToDelete.Id);
      },
      () => undefined,
    );
  };

  const deleteSubtaskSuccess = (id: number) => {
    const newPhases = produce(phases, (draftPhases) => {
      draftPhases[MainListState.currentPhaseIndex].Sections[
        MainListState.currentSectionIndex
      ].Blocks[
        MainListState.currentBlockIndex
      ].BlockContent.TimedActionTaskContent.Subtasks = draftPhases[
        MainListState.currentPhaseIndex
      ].Sections[MainListState.currentSectionIndex].Blocks[
        MainListState.currentBlockIndex
      ].BlockContent.TimedActionTaskContent.Subtasks?.filter(
        (subTask: SubtaskForTreatment) => subTask.Id !== id,
      );
    });
    dispatch(SetPhases(newPhases));
  };

  const getIndexFromPhaseId = (Id: number | undefined) => {
    if (!Id) return -1;
    return phases.findIndex((phase: Phase) => phase.Id === Id);
  };
  const isDisableDeleteCopyBtn = () => {
    const index = getIndexFromPhaseId(
      props.object?.BlockContent.CreatedInPhaseId,
    );
    return MainListState.currentPhaseIndex !== index;
  };
  const handleClickOnAttach = () => {
    dispatch(SetTimedActionMediaDisplay(!timedActionMediaDisplay));
    dispatch(SetCurrentTimedActionUploadingMedia("Treatment"));
  };

  const makeMaster = () => {
    makeTreatmentTaskMaster(
      props.object?.BlockContent?.TimedActionTaskContent?.Id,
      (data: TreatmentTask) => {
        onChangeTreatmentBlock(
          "TimedActionTaskContent.TimedActionTaskStatus",
          data.TimedActionTaskStatus,
        );
      },
      (error: any) => {
        OpenErrorNotification({ description: error.ErrorMessage });
      },
    );
  };

  const onEdit = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "",
      content:
        "All edits here will update the master as well. Do you want to Continue?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch({
          type: "isEditingAllowed",
          payload: true,
        });
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };

  useEffect(() => {
    // Treatment is not master then allow editing by default.
    if (!isMasterTreatment && isEditingAllowed) {
      dispatch({
        type: "isEditingAllowed",
        payload: true,
      });
    } else {
      dispatch({
        type: "isEditingAllowed",
        payload: false,
      });
    }
  }, [isMasterTreatment]);

  return (
    <Container>
      <FixedDiv phase={false}>
        <IconColumn
          type="treatment"
          onCopyClick={() => props.onCopy(props.object)}
          onDeleteClick={onDeleteConfirm}
          isDeleteBtnDisabled={isDisableDeleteCopyBtn()}
          isCopyBtnDisabled={isDisableDeleteCopyBtn()}
          isMasterBtnAvailable
          onMasterBtnClick={makeMaster}
          isMaster={isMasterTreatment}
          isEditBtnAvailable
          isMasterBtnDisabled={isReadOnly}
          isEditBtnDisabled={isReadOnly || isEditingAllowed}
          onEditClick={onEdit}
        />
      </FixedDiv>
      {!isNullOrUndefined(props.object) &&
        !isNullOrUndefined(props.object.BlockContent) &&
        !isNullOrUndefined(
          props.object?.BlockContent?.TimedActionTaskContent,
        ) && (
          <>
            <FilterBarContainer isDiagnostic={false} ref={filterBarRef}>
              <TreatmentSearchAdd
                scriptFormik={scriptFormik}
                object={props.object}
                checkIsMaster={() => undefined}
                updateBlockData={props.updateBlockData}
                onChange={onChangeTreatmentBlock}
              />
              {/* <FilterOutlinedIcons className="icon" /> */}
            </FilterBarContainer>
            <ScrollableTimedActionTaskDiv
              style={getScrollableDivHeight()}
              typeOfEditPanel="treatment"
              className="treatmentContainer"
              id="scrollableDiv"
              phase={false}
            >
              <AdditionalInfoTA
                isReadOnly={isReadOnly}
                scriptFormik={scriptFormik}
                description={
                  props.object?.BlockContent?.TimedActionTaskContent.Description
                }
                additionalNote={
                  props.object?.BlockContent?.TimedActionTaskContent
                    .AdditionalNote
                }
                onChange={onChangeTreatmentBlock}
              />
              <div className="toggleOptions">
                <RequiredTA
                  // createdInPhaseIndex={
                  //   props.object.BlockContent!.CreatedInPhaseIndex
                  // }
                  createdInPhaseId={props.object.BlockContent.CreatedInPhaseId}
                  requiredInPhaseOffset={
                    props.object.BlockContent.RequiredInPhaseOffset
                  }
                  currentPhaseId={MainListState.currentPhaseId}
                  currentPhaseIndex={MainListState.currentPhaseIndex}
                  isRequired={props.object.BlockContent.IsRequired}
                  isCritical={props.object.BlockContent.IsCritical}
                  phases={phases}
                  handleOptionToggle={handleOptionToggle}
                  handlePhaseSelection={handlePhaseSelection}
                  selectedPhaseValue={state.selectedPhase}
                />
                <SubtasksTA
                  isReadOnly={isReadOnly || !isEditingAllowed}
                  object={props.object?.BlockContent?.TimedActionTaskContent}
                  handleOptionToggle={handleOptionToggle}
                  scriptFormik={scriptFormik}
                  handleAddSubtask={handleAddSubTask}
                  subtaskValue={state.subtask}
                  type="Treatment"
                  handleSubtaskDelete={handleSubtaskDelete}
                  handleSubtaskUpdate={props.handleSubtaskUpdate}
                />
                <IntervalTA
                  isReadOnly={isReadOnly || !isEditingAllowed}
                  handleIntervalToggle={handleIntervalToggle}
                  isIntervalEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsIntervalEnabled
                  }
                  interval={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IntervalTime
                  }
                  handleOptionToggle={handleOptionToggle}
                  scriptFormik={scriptFormik}
                />
                <DurationTa
                  isReadOnly={isReadOnly || !isEditingAllowed}
                  isDurationEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsDurationEnabled
                  }
                  onChange={handleOptionToggle}
                  isDefineDurationTimeEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsDefineDurationTimeEnabled
                  }
                />
                <DefineStartTimeTA
                  isReadOnly={isReadOnly || !isEditingAllowed}
                  handleOptionToggle={handleOptionToggle}
                  isDefineStartTimeEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsDefineStartTimeEnabled
                  }
                />
                <RatingTA
                  isReadOnly={isReadOnly || !isEditingAllowed}
                  isCustomLabelsEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsCustomLabelsEnabled
                  }
                  isRatingEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsRatingEnabled
                  }
                  handleOptionToggle={handleOptionToggle}
                />
                <MediaTA
                  isReadOnly={isReadOnly || !isEditingAllowed}
                  isMediaEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsMediaEnabled
                  }
                  handleOptionToggle={handleOptionToggle}
                  medias={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.AttachedMedias
                  }
                  type="Treatment"
                  deleteMedia={handleDeleteMedia}
                  onAttach={handleClickOnAttach}
                />
                {skillCategorization && (
                  <Tooltip title="Hidden in production">
                    <>
                      <CategorizationAndRequirements
                        dropDownTitle={"Skill Categorization"}
                        listItemsTitle={"Skill Type"}
                        viewListLinkTitle={"View Approved Mos Units"}
                        timedAction={"treatment"}
                      />
                    </>
                  </Tooltip>
                )}
                {materialRequirements && (
                  <Tooltip title="Hidden in production">
                    <>
                      <CategorizationAndRequirements
                        dropDownTitle={"Material Requirements"}
                        listItemsTitle={"Equipment Availability"}
                        viewListLinkTitle={"View Material List"}
                        timedAction={"treatment"}
                      />
                    </>
                  </Tooltip>
                )}
              </div>
            </ScrollableTimedActionTaskDiv>
          </>
        )}
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </Container>
  );
};
export default TreatmentEdit;
