import React from "react";
import { Link } from "react-router-dom";
import SIMWERXIcon from "../../assets/img/simwerx_horizontal_white.png";
import { Form, Row } from "antd";
import { ColorPalette } from "../../styles/ColorPalette";
type Props = {
  displayLoginLink?: boolean;
};
export default function AuthFooter({ displayLoginLink }: Props) {
  return (
    <div className="powered-by-block">
      <Row justify="space-between" align="middle">
        <Form.Item className="mb-0">
          <a
            href="https://www.simwerx.com/"
            target={"_blank"}
            className="cursor-pointer"
          >
            <p className="mb-1">Powered by</p>
            <img src={SIMWERXIcon} alt="SIMWERX Logo" />
          </a>
        </Form.Item>
        {displayLoginLink && (
          <Form.Item className="mb-0">
            <p className="mb-1 text-white font-size-n1">
              Already have an account?
            </p>
            <Link className="text-white return-login-link" to={`/`}>
              {"Return to Login"}
            </Link>
          </Form.Item>
        )}
        {!displayLoginLink && (
          <span
            style={{ color: ColorPalette.White, marginTop: "auto" }}
            className="app-build-version"
          >
            {process.env.REACT_APP_BUILD_VERSION || "xx.xx.xx"}
          </span>
        )}
      </Row>
    </div>
  );
}
