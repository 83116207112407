import React from "react";
import Drawer from "../../../../components/Drawer/Drawer";
import { ArrowRightOutlined } from "@ant-design/icons";
import Question from "./Components/Question";
import TextAreaEdit from "../../../ScriptBuilder/EditComponents/ResearchQuestion/RightEditPane/TextAreaEdit";
import AnswerTypeSelection from "../../../ScriptBuilder/EditComponents/ResearchQuestion/RightEditPane/AnswerTypeSelection";
import { ResearchQuestionType } from "../../../../models/Enums";
import { isNullOrUndefined } from "../../../../utils/utilityfunctions";
import { ResearchQuestion } from "../../../../models/ScriptInterfaces";
import TextQuestionBlock from "../../../ScriptBuilder/EditComponents/ResearchQuestion/RightEditPane/TextQuestionBlock";
import SelectQuestionBlock from "../../../ScriptBuilder/EditComponents/ResearchQuestion/RightEditPane/SelectQuestionBlock";
import RadioQuestionBlock from "../../../ScriptBuilder/EditComponents/ResearchQuestion/RightEditPane/RadioQuestionBlock";
import NumberQuestionType from "../../../ScriptBuilder/EditComponents/ResearchQuestion/RightEditPane/NumberQuestionType";
import { useDispatch, useSelector } from "react-redux";
import { updateResearchQuestion } from "../../../../api/ResearchQuestion/ApiPut";
import { onUpdateSuccess, setRQForUpdate } from "./ResearchQuestionSlice";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import ButtonQuestionType from "./Components/ButtonQuestionType";
import { Switch } from "../../../../components";

interface Props {
  title: string;
  formik: any;
  isVisible: boolean;
  onClickOutside: any;
  onClose: any;
}
export default function AddUpdateDrawer({
  title,
  formik,
  isVisible,
  onClickOutside,
  onClose,
}: Props) {
  const researchQuestion = useSelector(
    (state: any) =>
      state.MasterResearchQuestionReducer.researchQuestionForUpdate,
  );
  const focusedStates = {
    question: false,
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);

  const dispatch = useDispatch();
  const onUpdateSuccessCB = (data: ResearchQuestion) => {
    dispatch(setRQForUpdate(data));
    dispatch(onUpdateSuccess(data));
  };
  const onError = (msg: any) => {
    OpenErrorNotification({
      description: msg,
    });
  };
  const handleChange = (field: string, value: string | boolean | any) => {
    const payload = {
      ...researchQuestion,
      question: formik.values["question"],
      [field]: value,
    };
    if (field === "ResearchQuestionType") {
      updateResearchQuestion(payload, onUpdateSuccessCB, onError);
    } else {
      dispatch(setRQForUpdate(payload));
    }
  };
  const handleIsRequiredChange = () => {
    console.log("handleIsRequiredChange");
    handleChange("IsRequired", !researchQuestion!.IsRequired);
  };
  const handleIsPublicChange = () => {
    handleChange("IsPublic", !researchQuestion!.IsPublic);
  };

  return (
    <Drawer
      className="custom-css-ant-drawer"
      visible={isVisible}
      onClose={onClickOutside}
      closable
      width="600"
    >
      <h1>
        <ArrowRightOutlined style={{ marginRight: "20px" }} onClick={onClose} />
        {title}
      </h1>
      <div className="m-2">
        <div className="mb-5">
          <Question
            formik={formik}
            lastFocused={lastFocused}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            isFocused={isFocused}
          />
        </div>
        <div
          style={{ gap: "1rem" }}
          className="mb-8 d-flex align-items-center"
          onClick={handleIsPublicChange}
        >
          <Switch
            checked={researchQuestion!.IsPublic}
            onChange={() => undefined}
          />
          <button
            style={{ aspectRatio: `auto` }}
            className={`archived-scripts ${
              researchQuestion!.IsPublic ? "text-white " : ""
            }`}
            onChange={() => undefined}
          >
            PUBLIC
          </button>
        </div>
        <div
          style={{ gap: "1rem" }}
          className="mb-8 d-flex align-items-center"
          onClick={handleIsRequiredChange}
        >
          <Switch
            checked={researchQuestion!.IsRequired}
            onChange={() => undefined}
          />
          <button
            style={{ aspectRatio: `auto` }}
            className={`archived-scripts ${
              researchQuestion!.IsRequired ? "text-white " : ""
            }`}
            onChange={() => undefined}
          >
            REQUIRED
          </button>
        </div>
        <TextAreaEdit
          onChange={handleChange}
          scriptFormik={formik}
          label={"Description"}
          DBKey={"Description"}
          field={"description"}
          maxLength={70}
          disabled={false}
        />

        <TextAreaEdit
          onChange={handleChange}
          scriptFormik={formik}
          label={"Additional Details"}
          DBKey={"AdditionalDetails"}
          field={"additionalDetails"}
          maxLength={70}
          disabled={false}
        />

        <AnswerTypeSelection
          onChange={handleChange}
          value={
            researchQuestion.ResearchQuestionType || ResearchQuestionType.Text
          }
          disabled={false}
        />

        {/* Text type question inputs */}
        {!isNullOrUndefined(researchQuestion.ResearchQuestionType) &&
        !isNullOrUndefined(researchQuestion.ResearchQuestionText) &&
        researchQuestion.ResearchQuestionType === ResearchQuestionType.Text ? (
          <TextQuestionBlock
            object={researchQuestion.ResearchQuestionText}
            onChange={handleChange}
            disabled={false}
          />
        ) : null}

        {/* Select type question inputs */}
        {!isNullOrUndefined(researchQuestion.ResearchQuestionType) &&
        !isNullOrUndefined(researchQuestion.ResearchQuestionSelect) &&
        researchQuestion.ResearchQuestionType ===
          ResearchQuestionType.Select ? (
          <SelectQuestionBlock
            object={researchQuestion.ResearchQuestionSelect}
            onChange={handleChange}
            disabled={false}
          />
        ) : null}

        {/* Radio type question inputs */}
        {!isNullOrUndefined(researchQuestion.ResearchQuestionType) &&
        !isNullOrUndefined(researchQuestion.ResearchQuestionRadio) &&
        researchQuestion.ResearchQuestionType === ResearchQuestionType.Radio ? (
          <RadioQuestionBlock
            object={researchQuestion.ResearchQuestionRadio}
            onChange={handleChange}
            disabled={false}
          />
        ) : null}

        {/* Number type question inputs */}
        {!isNullOrUndefined(researchQuestion.ResearchQuestionType) &&
        !isNullOrUndefined(researchQuestion.ResearchQuestionNumber) &&
        researchQuestion.ResearchQuestionType ===
          ResearchQuestionType.Number ? (
          <NumberQuestionType
            object={researchQuestion.ResearchQuestionNumber}
            onChange={handleChange}
            disabled={false}
          />
        ) : null}

        {/* Button type question inputs */}
        {!isNullOrUndefined(researchQuestion.ResearchQuestionType) &&
        !isNullOrUndefined(researchQuestion.ResearchQuestionButton) &&
        researchQuestion.ResearchQuestionType ===
          ResearchQuestionType.Button ? (
          <ButtonQuestionType
            object={researchQuestion.ResearchQuestionButton}
            onChange={handleChange}
            disabled={false}
          />
        ) : null}
      </div>
    </Drawer>
  );
}
