import styled from "styled-components";
import { GrayColorPalette } from "../styles/MainList.styled";

interface IsCollapsed {
  collapse: boolean;
}
export const StyledDiv = styled.div<IsCollapsed>`
  .sider-container {
    .ant-layout-sider,
    .sidebar-menu {
      background: ${GrayColorPalette.Gray7};
      &:hover {
        .menu-list-container li span {
          color: rgb(255, 255, 255) !important;
        }
      }
    }
  }
  .sider-container:hover .menu-list-container li span {
    color: ${({ collapse }) =>
      !collapse
        ? `rgb(255,255,255) !important`
        : `rgba(128,128,128,0.5) !important`};
  }

  .sider-container .menu-list-container .ant-menu-item-selected {
    background: ${({ collapse }) =>
      !collapse ? `#4b4b4b !important` : `transparent !important`};
    box-shadow: ${({ collapse }) =>
      !collapse
        ? `inset 0rem -0.0625rem 0rem rgb(255 255 255) !important`
        : `none !important`};
  }

  .sider-container .menu-list-container li:hover span {
    color: rgb(255, 255, 255) !important;
  }
  .ant-menu-dark.ant-menu-vertical li.ant-menu-item {
    padding: 0 24px !important;
  }
  li .ant-menu-item-icon {
    min-width: 24px;
  }
  li .ant-menu-item-icon svg {
    display: flex;
  }
`;
