import axios from "axios";
import React, { useState } from "react";
import { StyledBlueButton } from "../pages/ScriptBuilder/EditComponents/EditForms/RolePlayerForm/RolePlayerForm.styled";
import { ThunderboltOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { OpenErrorNotification } from "./Notification/Notification";

interface Props {
  input: string;
  maxLength: number;
  completedSentenceCB: any;
}
const GenerateText = ({ input, maxLength, completedSentenceCB }: Props) => {
  const [fetchingOpenAIData, setFetchingOpenAIData] = useState(false);
  const fetchData = async (input: string) => {
    setFetchingOpenAIData(true);
    return await axios
      .post("/api/1/pfc/open-ai/completions", {
        message: input,
        maxLength: maxLength || null,
      })
      .then((response) => {
        return response.data.Result;
      })
      .catch(() => {
        OpenErrorNotification({
          type: "error",
          description: "Error occurred while generating text. Try again later.",
        });
      })
      .finally(() => {
        setFetchingOpenAIData(false);
      });
  };
  async function handleClick() {
    try {
      const completedSentence = await fetchData(input);
      completedSentenceCB(completedSentence);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    // placeholder="Type in some words and I'll finish the rest..."
    <>
      {input && input.length > 0 ? (
        <Tooltip
          title={`${fetchingOpenAIData ? "" : "Generate Text"}`}
          placement="right"
        >
          <StyledBlueButton
            style={{
              width: "auto",
              minWidth: "2.5rem",
              maxWidth: "2.5rem",
              height: "2.5rem",
              marginTop: "1rem",
            }}
            onClick={handleClick}
            htmlType="button"
            icon={<ThunderboltOutlined />}
            disabled={fetchingOpenAIData}
          />
          {fetchingOpenAIData && <span className="ml-2">Please Wait...</span>}
        </Tooltip>
      ) : null}
    </>
  );
};
export default GenerateText;
