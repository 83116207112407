import styled from "styled-components";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  overflow: auto;
  padding: 15px;
  .weight-input,
  .age-input,
  .height-input,
  .blood-group-input {
    display: inline-block;
    width: 150px;
  }
  .error-msg {
    color: #ff4242;
  }
`;
