import axios from "axios";
import { defaultErrorCallback } from "../ErrorCallback";

const apiPrefix = "/api/1/pfc/User-Profile";

export function getUserProfile(
  callback: Function,
  errorcallback: Function = defaultErrorCallback,
) {
  axios
    .get(`${apiPrefix}`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err.response.data.errorMessage);
      }
    });
}
