import * as React from "react";
import { useState } from "react";
import { login, logout } from "../../../actions/authActions";
import { connect } from "react-redux";
import { Row, Form, Tooltip } from "antd";
import { Link } from "react-router-dom";
import FloatingLabelInput from "../../../components/FloatingLabel/FloatingLabelInput";
import { useFormik } from "formik";
import { BlueButton } from "../../../styles/Buttons.styled";
import { BlueCheckbox } from "../../../styles/MainList.styled";
import { BottomContainer } from "../Authorization.styled";
import {
  EyeInvisibleOutlinedIcons,
  EyeOutlinedIcons,
} from "../../../IconsTheme/Icons.global";
import { FormRow } from "../../ScriptBuilder/EditComponents/ScriptDetailsView/styled";
import { OpenErrorNotification } from "../../../components/Notification/Notification";
import { FormikErrorType } from "../../../models/common/FormikErrorType";
import AuthFooter from "../AuthFooter";
import { setCompanyHeader } from "../../../utils/setAuthToken";
import AlertBar from "../../../app/AlertBar";
import { userLogin } from "../../../api/Auth";
import { LoginTokens } from "../../../models/LoginTokens";
import { getCurrentLoggedInUserInfo } from "../../../api/User/ApiGet";
import User from "../../../models/User";
import { useAppDispatch } from "../../../store/hooks";
import {
  setCompany,
  setUser,
  setUserCompanyId,
} from "../../../store/authSlice";
import { getCurrentSelectedCompanyInfo } from "../../../api/Company/ApiGet";
import { Company } from "../../../models/Company";

interface Props {
  login: (data: string) => {};
  logout: Function;
}

const LoginForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const focusedStates = {
    username: true,
    password: true,
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);
  const [loading, setLoading] = useState<boolean>(false);

  const displayPasswordIcon = () => {
    return showPassword ? (
      <Tooltip title="Hide Password">
        <EyeOutlinedIcons
          className="visibleIcon"
          onClick={() => setShowPassword(false)}
        />
      </Tooltip>
    ) : (
      <Tooltip title="Show Password">
        <EyeInvisibleOutlinedIcons onClick={() => setShowPassword(true)} />
      </Tooltip>
    );
  };

  const scriptFormik = useFormik({
    initialValues: {
      password: "",
      username: "",
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      const regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      if (!values.password.trim()) {
        errors["password"] = "Required";
      }
      if (!values.username.trim()) {
        errors["username"] = "Required";
      } else if (!regex.test(values.username.trim())) {
        errors["username"] = "Invalid Email";
      }

      return errors;
    },
    validateOnBlur: true,
    onSubmit: () => {
      handleLogin();
    },
  });

  const setErrorMessage = async (data: string) => {
    OpenErrorNotification({
      description: data,
    });
  };

  const error = (data: any) => {
    setErrorMessage(data.response.data.ErrorMessage);
  };

  const authError = async (data: any) => {
    setLoading(false);
    const { ErrorCode, ErrorMessage } = data.response.data;
    if ([401, 404].includes(ErrorCode)) {
      scriptFormik.setErrors({
        username: ErrorCode === 404 ? ErrorMessage : "",
        password: ErrorCode === 401 ? ErrorMessage : "",
      });
    } else {
      error(data);
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    await userLogin(
      {
        Email: scriptFormik.values.username,
        Password: scriptFormik.values.password,
      },
      loginSuccess,
      authError,
    );
    setLoading(false);
  };

  const loginSuccess = async (data: LoginTokens) => {
    props.login(data.Authorization);
    setCompanyHeader(data.CompanyToken);
    await getCurrentLoggedInUserInfo((data: User) => {
      dispatch(setUser(data));
    }, error);
    await getCurrentSelectedCompanyInfo((data: Company) => {
      dispatch(setCompany(data));
      dispatch(setUserCompanyId(data.Id));
    }, error);
  };

  return (
    <div className="login-block" data-testid="login-form">
      <AlertBar></AlertBar>
      <div className="login-form">
        <Form data-testid="loginForm" onFinish={scriptFormik.handleSubmit}>
          <div className="login-input-column">
            <FormRow>
              <FloatingLabelInput
                label={"User Email"}
                nameForInput={"username"}
                type="text"
                data-testid="email"
                value={scriptFormik.values.username}
                onChange={scriptFormik.handleChange}
                scriptFormik={scriptFormik}
                lastFocused={lastFocused}
                isAddNeeded={false}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
                lastPassIgnore={false}
                nextElementToFocusOnPressEnter="password"
              />
            </FormRow>
            <FormRow>
              <FloatingLabelInput
                label={"Password*"}
                nameForInput={"password"}
                type={showPassword ? "text" : "password"}
                data-testid="password"
                lastPassIgnore={false}
                value={scriptFormik.values.password}
                onChange={scriptFormik.handleChange}
                scriptFormik={scriptFormik}
                lastFocused={lastFocused}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
                isAddNeeded={true}
                onSave={scriptFormik.handleSubmit}
                suffixIcon={displayPasswordIcon()}
              />
            </FormRow>
          </div>

          <Row justify="space-between" align="middle">
            <Form.Item>
              <Link
                className="forgot-text forgot-password d-block"
                to={`/forgot-password`}
              >
                Forgot Password?
              </Link>
              <BlueCheckbox checked={false}>
                <em> Remember Me </em>
              </BlueCheckbox>
            </Form.Item>
            <Form.Item>
              {loading ? (
                <BlueButton
                  rounded="true"
                  type="primary"
                  className="l2l-btn login-btn"
                  loading
                >
                  Loading
                </BlueButton>
              ) : (
                <BlueButton
                  rounded="true"
                  data-testid="submit-button"
                  htmlType="submit"
                  className="l2l-btn login-btn"
                >
                  Log In
                </BlueButton>
              )}
              <br />
            </Form.Item>
          </Row>
        </Form>
      </div>
      <BottomContainer className="text-center">
        <p>Don't have an account?</p>
        <BlueButton
          rounded="true"
          data-testid="submit-button"
          htmlType="submit"
          className="l2l-btn  sign-up-btn"
        >
          <Link to={`company/sign-up`}> Sign Up</Link>
        </BlueButton>
      </BottomContainer>
      <AuthFooter />
    </div>
  );
};

function mapToStateToProps(state: any) {
  return {
    auth: state.authReducer,
  };
}

export default connect(mapToStateToProps, {
  login,
  logout,
})(LoginForm);
