import React, { MouseEventHandler } from "react";
import {
  InsultWithRelation,
  TextParameter,
} from "../../../../../../../models/InsultInterface";
import { isNullOrUndefined } from "../../../../../../../utils/utilityfunctions";
import { TextParamCol } from "../../InjuryTab.styled";

type TextParamProps = {
  parameter: TextParameter;
  onEditInsult: MouseEventHandler;
  insult?: InsultWithRelation;
};

const TextParam = ({ parameter, insult, onEditInsult }: TextParamProps) => {
  const getValueToApplyText = (parameter: TextParameter) => {
    return parameter.ValueToApply
      ? parameter.ValueToApply
      : parameter.Default
        ? parameter.Default
        : "-";
  };

  return (
    <TextParamCol
      $insult={insult}
      parameter={parameter}
      className="text-param"
      onClick={onEditInsult}
    >
      <div>
        <label>{parameter.UserFacingLabel}</label>
        <span className="text-primary">
          {!isNullOrUndefined(parameter.ValueToApply)
            ? getValueToApplyText(parameter)
            : "-"}
        </span>
      </div>
    </TextParamCol>
  );
};

export default TextParam;
