import * as React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";

type ColProps = {
  span: number;
  className?: string;
  style?: any;
  data: React.ReactNode;
};

type SplitPaneProps = {
  columns: Array<ColProps>;
};

const StyledCol = styled(Col)`
  & + & {
    border-left: 1px solid ${colorPalette.gray75};
  }
`;

const SplitPane = ({ columns }: SplitPaneProps) => {
  return (
    <Row>
      {columns.map((col, index) => {
        return (
          <StyledCol
            key={index}
            span={col.span}
            className={col.className}
            style={col.style}
          >
            {col.data}
          </StyledCol>
        );
      })}
    </Row>
  );
};

export default SplitPane;
