import React from "react";
import TimedActionExpandableBlockIcon from "../IconComponent/IconComponent";
import {
  TimedActionExpandableBlockCommentAudio,
  TimedActionExpandableBlockCommentContainer,
  TimedActionExpandableBlockCommentText,
} from "./Comment.styled";
import { ReactComponent as MessageIcon } from "../../../../../../assets/img/Svg/Message.svg";
import { AudioOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";

type Props = {
  comment: string;
};

const TimedActionExpandableComment = ({ comment, ...props }: Props) => {
  return (
    <TimedActionExpandableBlockCommentContainer>
      <TimedActionExpandableBlockIcon icon={<Icon component={MessageIcon} />} />
      <TimedActionExpandableBlockCommentText flex={"auto"}>
        {comment && comment.length > 0 ? (
          <span className="comment">{comment}</span>
        ) : (
          <span className="no-comment disable-text">--</span>
        )}
      </TimedActionExpandableBlockCommentText>
      <TimedActionExpandableBlockCommentAudio flex={"100px"}>
        <div>
          <AudioOutlined />
        </div>
      </TimedActionExpandableBlockCommentAudio>
    </TimedActionExpandableBlockCommentContainer>
  );
};

export default TimedActionExpandableComment;
