import React from "react";
import { VitalType } from "../../../../../models/Enums";
import { Vital } from "../../../../../models/ScriptInterfaces";
import { StyledTableView } from "./CenterPane.styled";

interface Props {
  Vitals: Vital[];
}

export default function VitalsTable(props: Props) {
  return (
    <StyledTableView>
      <div className="item heading">
        <div>VITAL SIGN</div>
        <div>VALUE</div>
        <div className="notes">NOTES</div>
      </div>
      {props.Vitals.map((vital: Vital) => {
        return vital.IsVitalVisible ? (
          <div className="item" key={vital.Id}>
            <div>{VitalType[vital.VitalType]}</div>
            <div>{vital.Value}</div>
            <div className="notes word-wrap">{vital.ShortNote || ""}</div>
          </div>
        ) : null;
      })}
    </StyledTableView>
  );
}
