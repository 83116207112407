import { Row } from "antd";
import styled from "styled-components";
import {
  GrayColorPalette,
  colorPalette,
} from "../../../../styles/MainList.styled";

interface InteliQueryHelperContainerRow {
  show: boolean;
}
export const InteliQueryHelperContainerRow = styled(
  Row,
)<InteliQueryHelperContainerRow>`
  color: ${(props) => (props.show ? "white" : "")};
  border: 1px dashed ${colorPalette.darkerGray};
  border-bottom: ${(props) => (props.show ? "none" : "")};
  font-size: 1.25rem;
  align-content: center;
  min-height: 3.125rem;
  padding: 5px;

  &&& {
    background-color: ${(props) => (props.show ? GrayColorPalette.Gray4 : "")};
  }

  &:hover {
    color: ${colorPalette.white};
    background-color: ${GrayColorPalette.Gray5};
  }

  .icon-thunder {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const InteliQueryQuestionContainerRow = styled(
  Row,
)<InteliQueryHelperContainerRow>`
  color: white;
  border: 1px dashed ${colorPalette.darkerGray};
  border-top: ${(props) => (props.show ? "none" : "")};
  font-size: 1.25rem;
  align-content: center;
  padding: ${(props) => (props.show ? "5px" : "0px")};

  &&& {
    background-color: ${(props) => (props.show ? GrayColorPalette.Gray4 : "")};
  }

  &:hover {
    color: ${colorPalette.white};
    background-color: ${GrayColorPalette.Gray5};
  }
`;
