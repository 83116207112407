import React, { useState } from "react";
import {
  DiagnosticTask,
  UpdateDiagnosticTask,
} from "../../../../../models/DiagnosticTask";
import {
  CreateTimedActionSubTask,
  TimedActionSubTask,
} from "../../../../../models/TimedActionSubTask";
import { SubtasksTA } from "../../../../../components/TimedActions/SubtasksTA";
import { deleteSubtaskForTimedAction } from "../../../../../api/TimedActionTask";
import { TimedActionTask } from "../../../../../models/TimedActionTask";
import produce from "immer";

interface Props {
  isReadOnly: boolean;
  obj: DiagnosticTask;
  formik: any;
  onChange: any;
  onError: any;
}
export default function SubTasks({
  isReadOnly,
  obj,
  formik,
  onChange,
  onError,
}: Props) {
  const [task, setTaskValue] = useState<string>("");
  const handleAddTaskInputChange = (value: any) => {
    setTaskValue(value);
  };
  const handleOptionToggle = (
    type: keyof UpdateDiagnosticTask,
    value?: number,
  ) => {
    onChange(type, !obj[type]);
  };

  const handleAddSubTask = () => {
    const newSubtask: CreateTimedActionSubTask = {
      Order: obj.Subtasks[obj.Subtasks?.length - 1]?.Order + 1 || 0,
      Description: task,
    };
    const updatedSubtasks = produce(obj.Subtasks, (draftSubtasks) => {
      draftSubtasks.push({ ...newSubtask, Id: 0 });
    });
    onChange("Subtasks", updatedSubtasks, false);
    setTaskValue("");
  };
  const handleSubtaskUpdate = (id: number, newValue: string) => {
    const subTask = Object.assign([], JSON.parse(JSON.stringify(obj.Subtasks)));
    subTask.forEach((task: TimedActionTask) => {
      if (task.Id === id) {
        task.Description = newValue;
      }
    });
    onChange("Subtasks", subTask);
  };
  const handleSubtaskDelete = (deletedSubtask: TimedActionSubTask) => {
    let newObj;

    if (deletedSubtask.Id) {
      newObj = obj.Subtasks?.filter(
        (subTask: TimedActionSubTask) => subTask.Id !== deletedSubtask.Id,
      );
      deleteSubtaskForTimedAction(
        deletedSubtask.Id,
        (response: any) => console.log(response),
        onError,
      );
    } else {
      newObj = obj.Subtasks?.filter(
        (subTask: TimedActionSubTask) => subTask.Order !== deletedSubtask.Order,
      );
    }

    onChange("Subtasks", newObj, false);
  };
  const handleSubtaskOrderUpdate = (newSubtask: TimedActionSubTask) => {
    onChange("Subtasks", newSubtask);
  };
  return (
    <SubtasksTA
      from={"content-master"}
      isReadOnly={isReadOnly}
      object={obj}
      handleOptionToggle={handleOptionToggle}
      scriptFormik={formik}
      handleAddSubtask={handleAddSubTask}
      subtaskValue={task}
      type="Diagnostic"
      handleChange={handleAddTaskInputChange}
      handleSubtaskDelete={handleSubtaskDelete}
      handleSubtaskUpdate={handleSubtaskUpdate}
      handleSubtaskOrderUpdate={handleSubtaskOrderUpdate}
    />
  );
}
