import React, { useState } from "react";
import Autocomplete from "../../../../components/Autocomplete/Autocomplete";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { SetIsFocused, SetLastFocused } from "./MedicationSlice";
import { attachMasterMedicationTaskToBlock } from "../../../../api/MedicationTaskBlock";
import { MedicationTask } from "../../../../models/MedicationTask";
import {
  MedicationTaskBlock,
  MedicationTaskBlockContent,
} from "../../../../models/MedicationTaskBlock";

interface Props {
  object: MedicationTaskBlock;
  scriptFormik: any;
  onChange: Function;
  updateBlockData: Function;
}

export default function MedicationSearchAdd({
  object,
  scriptFormik,
  onChange,
  updateBlockData,
}: Props) {
  const dispatch = useAppDispatch();
  const { lastFocused, isFocused } = useAppSelector(
    (state) => state.MedicationReducer,
  );
  const { allMasterMedications, isReadOnly } = useAppSelector(
    (state: any) => state.MainlistReducer,
  );
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );
  const [value, setValue] = useState<string>(
    object.BlockContent.TimedActionTaskContent.Name || "",
  );

  const handleSearch = (value: string) => {
    let res: { value: string; label: string }[] = [];
    if (!value || !value.trim()) {
      res = [];
    } else {
      res = allMasterMedications
        .filter(
          (medication: MedicationTask) =>
            medication.Name?.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
            medication.CommonName?.toLowerCase().indexOf(value.toLowerCase()) >
              -1 ||
            medication.UseCase1?.toLowerCase().indexOf(value.toLowerCase()) >
              -1 ||
            medication.UseCase2?.toLowerCase().indexOf(value.toLowerCase()) >
              -1 ||
            medication.UseCase3?.toLowerCase().indexOf(value.toLowerCase()) >
              -1,
        )
        .map((medication: MedicationTask) => ({
          value: medication.Name,
          label: (
            <div
              className={
                medication.CommonName?.length
                  ? "label-with-compound-formula"
                  : "label-without-compound-formula"
              }
            >
              <span>{medication.Name}</span>
              {medication.CommonName?.length ? (
                <span className="compound-formula">
                  &nbsp;({medication.CommonName})
                </span>
              ) : null}
            </div>
          ),
        }));
      // Update
      onChange("TimedActionTaskContent.Name", value);
    }
    setOptions(res);
  };
  const handleInputSearch = (e: any) => {
    scriptFormik.handleChange(e);
    setValue(e.target.value);
  };
  const onSelect = (value: string) => {
    const SelectedMedication = allMasterMedications.find(
      (x: MedicationTask) => x.Name === value,
    );
    setValue(value);
    attachMasterMedicationTaskToBlock(
      SelectedMedication.Id,
      object.Id,
      onUpdateSuccess,
      onError,
    );
  };
  const onUpdateSuccess = (data: MedicationTaskBlockContent) => {
    updateBlockData(data);
  };
  const onError = (error: any) => {
    console.log(error);
  };

  return (
    <Autocomplete
      value={value}
      scriptFormik={scriptFormik}
      handleSearch={handleSearch}
      handleInputSearch={handleInputSearch}
      onSelect={onSelect}
      options={options}
      isFocused={isFocused}
      lastFocused={lastFocused}
      setIsFocused={SetIsFocused}
      setLastFocused={SetLastFocused}
      disabled={isReadOnly}
    />
  );
}
