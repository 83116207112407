import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Block,
  Phase,
  SectionForCreateThunk,
} from "../../models/ScriptInterfaces";
import produce from "immer";
import { CreateGhostTreatmentBlockWhereRequiredOnLoad } from "../../components/TreatmentEdit/TreatmentGhostLogic";
import { v4 as uuidv4 } from "uuid";
import { CreateSectionThunk } from "../../api/Section/ApiPost";
import { Media, MediaCategory } from "../../models/MediaInterface";
import { Casualty } from "../../models/CasualtyInterfaces";
import { TreatmentTask } from "../../models/TreatmentTask";
import { DiagnosticTask } from "../../models/DiagnosticTask";
import { MedicationTask } from "../../models/MedicationTask";
import { MiscellaneousTask } from "../../models/MiscellaneousTask";

export const populatePhaseWithTreatment = (
  phases: Phase[],
  dispatch: any,
  state: any,
): void => {
  const newPhases = produce(phases, (draftPhases) => {
    for (let i = 0; i < draftPhases.length; i++) {
      for (let j = 0; j < draftPhases[i]!.Sections!.length; j++) {
        console.log("BLOCKS", draftPhases[i]!.Sections![j].Blocks);
        console.log("BLOCKS", draftPhases[i]!.Sections![j].Blocks);
        draftPhases[i]!.Sections![j].Blocks = draftPhases[i]!.Sections![
          j
        ].Blocks!.filter((el) => !el.IsGhostBlock);
      }
    }
    draftPhases = CreateGhostTreatmentBlockWhereRequiredOnLoad(draftPhases);
    for (let i = 0; i < draftPhases.length; i++) {
      for (let j = 0; j < draftPhases![i].Sections!.length; j++) {
        console.log(
          "isNullOrUndefined(draftPhases![i].Sections![j].Id)",
          draftPhases![i].Sections![j].Id,
        );
        if (!draftPhases![i].Sections![j].Id) {
          // create section if section id is 0
          const newSectionId = uuidv4();
          const newSection: SectionForCreateThunk = {
            PhaseIndex: i,
            SectionIndex: j,
            UUID: newSectionId,
            PhaseId: draftPhases[i].Id!,
            Order: draftPhases[i]!.Sections!.length!,
            Title: "",
            Blocks: [],
            Collapsed: false,
            SectionType: "timed actions",
            IsCriticalActionRequired: true,
          };
          console.log("How many times is this called");
          dispatch(CreateSectionThunk(newSection));
          draftPhases![i].Sections![j].Id = state.newSectionId;
        }
        // for timed action section's block need to sort it based on order
        if (draftPhases![i].Sections![j].SectionType === "timed actions") {
          draftPhases![i].Sections![j].Blocks!.sort((x: Block, y: Block) => {
            // if block is ghost block then need to use OrderInNextPhase1
            let xOrder = x.Order;
            let yOrder = y.Order;
            const xCreatedPhaseIndex = draftPhases.findIndex(
              (phase: Phase) => phase.Id === x.BlockContent.CreatedInPhaseId,
            );
            const yCreatedPhaseIndex = draftPhases.findIndex(
              (phase: Phase) => phase.Id === y.BlockContent.CreatedInPhaseId,
            );
            if (xCreatedPhaseIndex !== -1 && xCreatedPhaseIndex + 1 === i) {
              xOrder = x.OrderInNextPhase1;
            } else if (
              xCreatedPhaseIndex !== -1 &&
              xCreatedPhaseIndex + 2 === i
            ) {
              xOrder = x.OrderInNextPhase2;
            }
            if (yCreatedPhaseIndex !== -1 && yCreatedPhaseIndex + 1 === i) {
              yOrder = y.OrderInNextPhase1;
            } else if (
              yCreatedPhaseIndex !== -1 &&
              yCreatedPhaseIndex + 2 === i
            ) {
              yOrder = y.OrderInNextPhase2;
            }
            return xOrder - yOrder;
          });
        }
      }
    }
  });
  // to retain current selected phase,section, and block position
  const {
    tempCurrentPhaseIndex,
    tempCurrentSectionIndex,
    tempCurrentBlockIndex,
    tempActiveComponent,
  } = state;
  // check if selected phase is available
  if (tempCurrentPhaseIndex < newPhases.length) {
    dispatch(SetCurrentPhaseIndex(tempCurrentPhaseIndex));
    dispatch(SetActiveKey(newPhases[tempCurrentPhaseIndex]?.UUID));
    dispatch(SetCurrentPhaseId(newPhases[tempCurrentPhaseIndex]?.Id || 0));
    // check if selected section is available
    if (
      tempCurrentSectionIndex <
      newPhases[tempCurrentPhaseIndex]?.Sections?.length
    ) {
      dispatch(SetSectionBlock(newPhases[tempCurrentPhaseIndex].Sections));
      dispatch(SetCurrentSectionIndex(tempCurrentSectionIndex));
      // check if selected block is available
      if (
        tempCurrentBlockIndex <
        newPhases[tempCurrentPhaseIndex]?.Sections[tempCurrentSectionIndex]!
          .Blocks!.length
      ) {
        dispatch(SetCurrentBlockIndex(tempCurrentBlockIndex));
        dispatch(SetActiveComponent(tempActiveComponent));
      } else {
        // select section for right side edit panel
        dispatch(SetActiveComponent(ComponentEdit.SECTION));
      }
    } else {
      // select phase for right side edit panel
      dispatch(SetActiveComponent(ComponentEdit.PHASE));
    }
  } else if (newPhases.length && state.activeKey === FirstUUID) {
    dispatch(SetActiveKey(newPhases[0].UUID));
    dispatch(SetSectionBlock(newPhases[0].Sections));
  }
  dispatch(SetPhases(newPhases!));
};

export enum ComponentEdit {
  PHASE,
  SECTION,
  TEXT,
  LIST,
  CASUALTY,
  TREATMENT,
  MEDICATION,
  DIAGNOSTIC,
  RPPROMPT,
  SUPPORTACTION,
  CASUALTYSTATUS,
  MISCELLANEOUS,
  RESEARCHQUESTION,
}
export type MainListSliceState = {
  casualty: Casualty | null;
  casualtyId: number | null;
  isReadOnly: boolean;
  phases: Phase[];
  openOptionsModal: boolean;
  scriptId: number;
  openTimedActions: boolean;
  activeKey: string;
  currentPhaseIndex: number;
  currentPhaseId: number;
  activeComponent: ComponentEdit;
  currentSectionIndex: number;
  currentBlockIndex: number;
  /* temp variable to save previous position start */
  tempCurrentPhaseIndex: number;
  tempActiveComponent: ComponentEdit;
  tempCurrentSectionIndex: number;
  tempCurrentBlockIndex: number;
  /* temp variable to save previous position end */
  phaseEditValues: {};
  activeDragContentType: string | null;
  sectionBlock: any[] | undefined;
  collapse: boolean;
  textDescription: string;
  textTitle: string;
  sectionTitle: string;
  addBlockShowingId: string;
  isDeletePhaseBtnDisabled: boolean;
  loading: boolean;
  newSectionId: any;
  loadingToggle: boolean;
  allMasterTreatments: TreatmentTask[];
  allMasterDiagnostics: DiagnosticTask[];
  allMasterMedications: MedicationTask[];
  allMasterMiscellaneous: MiscellaneousTask[];
  isTimedSection: boolean;
  timedActionMediaDisplay: boolean;
  editMediaDisplay: boolean;
  mediaToEdit: Media | null;
  currentTimedActionUploadingMedia: string;
  selectedMediaToUploadFromGallery: number[];
  phaseTitle: string;
  phaseTitleLong: string;
  mediaCategories: MediaCategory[];
};

export const FirstUUID = uuidv4();

const initialState: MainListSliceState = {
  casualtyId: null,
  casualty: null,
  isReadOnly: false,
  phases: [],
  openOptionsModal: false,
  scriptId: 0,
  openTimedActions: false,
  activeKey: FirstUUID,
  currentPhaseId: 0,
  currentPhaseIndex: 0,
  activeComponent: ComponentEdit.PHASE,
  currentSectionIndex: 0,
  currentBlockIndex: 0,
  /* temp variable to save previous position start */
  tempCurrentPhaseIndex: 0,
  tempActiveComponent: ComponentEdit.PHASE,
  tempCurrentSectionIndex: 0,
  tempCurrentBlockIndex: 0,
  /* temp variable to save previous position end */
  phaseEditValues: {
    titleAbbreviated: "",
    titleLong: "",
    isScriptBrief: false,
    errors: {
      titleAbbreviated: "",
      titleLong: "",
      isScriptBrief: "",
    },
  },
  activeDragContentType: null,
  sectionBlock: [],
  collapse: false,
  textDescription: "",
  textTitle: "",
  sectionTitle: "",
  addBlockShowingId: "",
  isDeletePhaseBtnDisabled: true,
  loading: false,
  newSectionId: 0,
  loadingToggle: false,
  allMasterTreatments: [],
  allMasterDiagnostics: [],
  allMasterMedications: [],
  allMasterMiscellaneous: [],
  isTimedSection: false,
  timedActionMediaDisplay: false,
  currentTimedActionUploadingMedia: "",
  editMediaDisplay: false,
  mediaToEdit: null,
  selectedMediaToUploadFromGallery: [],
  phaseTitle: "",
  phaseTitleLong: "",
  mediaCategories: [],
};

const MainListSlice = createSlice({
  name: "Phases",
  initialState: initialState,
  reducers: {
    SetCasualty: (state, action: PayloadAction<any>) => {
      state.casualty = action.payload?.Casualty || null;
      state.casualtyId = action.payload?.CasualtyId || null;
    },
    SetPhases: (state, action: PayloadAction<Phase[]>) => {
      console.log("state", state);
      state.phases = action.payload;
    },
    SetIsReadOnly: (state, action: PayloadAction<boolean>) => {
      state.isReadOnly = action.payload;
    },
    SetOpenOptionsModal: (state, action: PayloadAction<boolean>) => {
      state.openOptionsModal = action.payload;
    },
    SetScriptId: (state, action: PayloadAction<number>) => {
      state.scriptId = action.payload;
    },
    SetOpenTimedActions: (state, action: PayloadAction<boolean>) => {
      state.openTimedActions = action.payload;
    },
    SetActiveKey: (state, action: PayloadAction<string>) => {
      state.activeKey = action.payload;
    },
    SetCurrentPhaseIndex: (state, action: PayloadAction<number>) => {
      state.currentPhaseIndex = action.payload;
      state.tempCurrentPhaseIndex = action.payload;
    },
    SetCurrentPhaseId: (state, action: PayloadAction<number>) => {
      state.currentPhaseId = action.payload;
    },
    SetActiveComponent: (state, action: PayloadAction<ComponentEdit>) => {
      state.activeComponent = action.payload;
      state.tempActiveComponent = action.payload;
    },
    SetCurrentSectionIndex: (state, action: PayloadAction<number>) => {
      state.currentSectionIndex = action.payload;
      state.tempCurrentSectionIndex = action.payload;
    },
    SetCurrentBlockIndex: (state, action: PayloadAction<number>) => {
      state.currentBlockIndex = action.payload;
      state.tempCurrentBlockIndex = action.payload;
    },
    SetPhaseEditValues: (state, action: PayloadAction<{}>) => {
      state.phaseEditValues = action.payload;
    },
    SetActiveDragContentType: (state, action: PayloadAction<string | null>) => {
      state.activeDragContentType = action.payload;
    },
    SetSectionBlock: (state, action: PayloadAction<any[] | undefined>) => {
      state.sectionBlock = action.payload;
    },
    SetCollapse: (state, action: PayloadAction<boolean>) => {
      state.collapse = action.payload;
    },
    SetTextDescription: (state, action: PayloadAction<string>) => {
      state.textDescription = action.payload;
    },
    SetTextTitle: (state, action: PayloadAction<string>) => {
      state.textTitle = action.payload;
    },
    SetSectionTitle: (state, action: PayloadAction<string>) => {
      state.sectionTitle = action.payload;
    },
    SetPhaseTitle: (state, action: PayloadAction<string>) => {
      state.phaseTitle = action.payload;
    },
    SetPhaseTitleLong: (state, action: PayloadAction<string>) => {
      state.phaseTitleLong = action.payload;
    },
    SetAddBlockShowingId: (state, action: PayloadAction<string>) => {
      state.addBlockShowingId = action.payload;
    },
    SetIsDeletePhaseBtnDisabled: (state, action: PayloadAction<boolean>) => {
      state.isDeletePhaseBtnDisabled = action.payload;
    },
    SetNewSectionId: (state, action: PayloadAction<any>) => {
      console.log("NEw Section Id", state.newSectionId);
      state.newSectionId = action.payload;
    },
    SetLoadingToggle: (state, action: PayloadAction<boolean>) => {
      console.log("NEw Section Id", state.newSectionId);
      state.loadingToggle = action.payload;
    },
    SetAllMasterMiscellaneous: (state, action: PayloadAction<any>) => {
      state.allMasterMiscellaneous = action.payload;
    },
    AddNewTreatmentInAllMasterMiscellaneous: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.allMasterMiscellaneous.push(action.payload);
    },
    SetAllMasterTreatments: (state, action: PayloadAction<any>) => {
      state.allMasterTreatments = action.payload;
    },
    AddNewTreatmentInAllMasterTreatments: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.allMasterTreatments.push(action.payload);
    },
    SetMasterAllDiagnostics: (state, action: PayloadAction<any>) => {
      state.allMasterDiagnostics = action.payload;
    },
    AddNewDiagnosticInAllMasterDiagnostics: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.allMasterDiagnostics.push(action.payload);
    },
    SetAllMasterMedications: (state, action: PayloadAction<any>) => {
      state.allMasterMedications = action.payload;
    },
    AddNewMedicationInAllMasterMedications: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.allMasterMedications.push(action.payload);
    },
    SetIsTimedSection: (state, action: PayloadAction<boolean>) => {
      state.isTimedSection = action.payload;
    },
    SetTimedActionMediaDisplay: (state, action: PayloadAction<boolean>) => {
      state.timedActionMediaDisplay = action.payload;
    },
    SetSelectedMediaToUploadFromGallery: (
      state,
      action: PayloadAction<number[]>,
    ) => {
      state.selectedMediaToUploadFromGallery = action.payload;
    },
    SetEditMediaDisplay: (state, action: PayloadAction<boolean>) => {
      state.editMediaDisplay = action.payload;
    },
    SetMediaToEdit: (state, action: PayloadAction<any>) => {
      state.mediaToEdit = action.payload;
    },
    SetCurrentTimedActionUploadingMedia: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.currentTimedActionUploadingMedia = action.payload;
    },
    SetMediaCategories: (state, action: PayloadAction<MediaCategory[]>) => {
      state.mediaCategories = action.payload;
    },
    ResetScriptEditorData: (state) => {
      // reset state data to initial values
      state.editMediaDisplay = initialState.editMediaDisplay;
      state.mediaToEdit = initialState.mediaToEdit;
    },
    ResetMainList: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CreateSectionThunk.pending, (state, _action) => {
      state.loading = true;
    }),
      builder.addCase(CreateSectionThunk.fulfilled, (state, action) => {
        console.log("STATE, ACTION", state, action.payload);
        if (action.payload?.newSection && action.payload.response) {
          const phaseIndex = action.payload.newSection.PhaseIndex;
          const sectionIndex = action.payload.newSection.SectionIndex;
          state.phases[phaseIndex].Sections![sectionIndex].Id =
            action.payload.response.Result.Id;
          state.newSectionId = action.payload.response.Result.Id;
        }
        state.loading = false;
      }),
      builder.addCase(CreateSectionThunk.rejected, (state, _action) => {
        state.loading = false;
      });
  },
});

export const GetCurrentPhases = (state: any) => state.phases;

export default MainListSlice.reducer;
export const {
  SetIsReadOnly,
  SetOpenOptionsModal,
  SetCasualty,
  SetPhases,
  SetScriptId,
  SetOpenTimedActions,
  SetActiveKey,
  SetCurrentPhaseIndex,
  SetCurrentPhaseId,
  SetActiveComponent,
  SetCurrentSectionIndex,
  SetPhaseEditValues,
  SetCurrentBlockIndex,
  SetActiveDragContentType,
  SetSectionBlock,
  SetCollapse,
  SetTextDescription,
  SetTextTitle,
  SetSectionTitle,
  SetAddBlockShowingId,
  SetIsDeletePhaseBtnDisabled,
  SetLoadingToggle,
  SetAllMasterMiscellaneous,
  AddNewTreatmentInAllMasterMiscellaneous,
  SetAllMasterTreatments,
  AddNewTreatmentInAllMasterTreatments,
  SetMasterAllDiagnostics,
  AddNewDiagnosticInAllMasterDiagnostics,
  SetAllMasterMedications,
  AddNewMedicationInAllMasterMedications,
  SetIsTimedSection,
  SetTimedActionMediaDisplay,
  SetCurrentTimedActionUploadingMedia,
  SetPhaseTitleLong,
  SetPhaseTitle,
  SetEditMediaDisplay,
  SetMediaToEdit,
  SetSelectedMediaToUploadFromGallery,
  SetMediaCategories,
  ResetScriptEditorData,
  ResetMainList,
} = MainListSlice.actions;
