import "@ant-design/compatible/assets/index.css";
import Icon, { PlusOutlined } from "@ant-design/icons";
import { Row, Spin, Tooltip } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import {
  RightSideTableHeader,
  StyledRow,
} from "../../../../components/Table/CustomTable.styled";
import Button from "../../../../components/Button/Button";
import AddUpdateDrawer from "./AddUpdateDrawer";
import { useFormik } from "formik";
import { FormikErrorType } from "../../../../models/common/FormikErrorType";
import { ReactComponent as Archive } from "../../../../assets/img/Svg/Archive.svg";
import { Switch } from "../../../../components";
import { useDispatch } from "react-redux";
import {
  onCreateSuccess,
  onUpdateSuccess,
  resetMiscellaneousForUpdate,
  setAddUpdateModal,
  setData,
  setLoading,
  setModalTitle,
  setMiscellaneousForUpdate,
} from "./MiscellaneousSlice";
import MiscellaneousTable from "./MiscellaneousTable";
import { useAppSelector } from "../../../../store/hooks";
import {
  createMiscellaneousTask,
  getMasterMiscellaneousTasks,
  updateMiscellaneousTask,
} from "../../../../api/MiscellaneousTask";
import {
  CreateMiscellaneousTask,
  MiscellaneousTask,
  UpdateMiscellaneousTask,
} from "../../../../models/MiscellaneousTask";

export default function Miscellaneouss() {
  const { data, modalTitle, addUpdateModal, loading, miscellaneousForUpdate } =
    useAppSelector((state) => state.MasterMiscellaneousReducer);
  const dispatch = useDispatch();
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const initialValues = {
    id: 0,
    title: "",
    additionalNote: "",
    description: "",
    interval: 0,
  };
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors: FormikErrorType = {};

      // miscellaneous
      if (!values["title"].trim()) {
        errors["title"] = "Required";
      } else if (values["title"].trim().length > 40) {
        errors["title"] = "Max 40 characters";
      }

      return errors;
    },
    onSubmit: (values: any) => {
      addUpdate();
    },
  });
  useEffect(() => {
    refreshData();
    return () => {
      dispatch(setAddUpdateModal(false));
      dispatch(resetMiscellaneousForUpdate(null));
    };
  }, []);
  const refreshData = () => {
    dispatch(setLoading(true));
    getMasterMiscellaneousTasks(false, onSuccess, onError);
  };
  const onSuccess = (data: MiscellaneousTask[]) => {
    dispatch(
      setData(
        data.sort((a: any, b: any) =>
          new Date(b.CreatedAt).getTime() < new Date(a.CreatedAt).getTime()
            ? -1
            : 1,
        ),
      ),
    );
    dispatch(setLoading(false));
  };
  const onError = (msg: any) => {
    OpenErrorNotification({
      description: msg,
    });
    dispatch(setLoading(false));
  };
  // Reset the form inputs.
  const resetForm = () => {
    setTimeout(() => {
      formik.setValues(initialValues, false);
      formik.setErrors({});
      dispatch(resetMiscellaneousForUpdate(null));
    }, 500);
  };
  const onUpdateSuccessCB = (data: MiscellaneousTask) => {
    dispatch(onUpdateSuccess(data));
  };
  const onCreateSuccessCB = (data: MiscellaneousTask) => {
    dispatch(onCreateSuccess(data));
    dispatch(setMiscellaneousForUpdate(data));
    setFormikData(data);
  };

  // Add miscellaneous.
  const payload: UpdateMiscellaneousTask = {
    ...miscellaneousForUpdate,
    Name: formik.values["title"],
    AdditionalNote: formik.values["additionalNote"],
    Description: formik.values["description"],
  };
  const addUpdate = () => {
    if (formik.isValid && formik.values.title?.trim()?.length) {
      // save

      if (formik.values["id"] > 0) {
        // update
        updateMiscellaneousTask(payload, onUpdateSuccessCB, onError);
      } else {
        // create
        createMiscellaneousTask(
          payload as CreateMiscellaneousTask,
          onCreateSuccessCB,
          onError,
        );
      }
      closeAddUpdateModal();
    } else {
      closeAddUpdateModal();
    }
  };

  // Create task before enabling subtasks.
  // This is required because we need task id to add subtasks.
  useEffect(() => {
    if (
      miscellaneousForUpdate.IsSubtasksEnabled &&
      miscellaneousForUpdate.Id === 0 &&
      formik.isValid
    ) {
      createMiscellaneousTask(
        payload as CreateMiscellaneousTask,
        onCreateSuccessCB,
        onError,
      );
    }
  }, [miscellaneousForUpdate.IsSubtasksEnabled]);

  const closeAddUpdateModal = () => {
    dispatch(setAddUpdateModal(false));
    resetForm();
  };

  // Open view miscellaneous details panel with miscellaneous data.
  const showDetails = (miscellaneous: MiscellaneousTask) => {
    dispatch(setModalTitle("View/Edit Miscellaneous"));
    setFormikData(miscellaneous);
    dispatch(setAddUpdateModal(true));
    dispatch(setMiscellaneousForUpdate(miscellaneous));
  };
  const setFormikData = (miscellaneous: MiscellaneousTask) => {
    formik.setValues(
      {
        id: miscellaneous.Id || 0,
        title: miscellaneous.Name || "",
        additionalNote: miscellaneous.AdditionalNote || "",
        description: miscellaneous.Description || "",
        interval: miscellaneous.IntervalTime || 0,
      },
      false,
    );
  };
  const onEdit = (miscellaneous: MiscellaneousTask) => {
    dispatch(setModalTitle("Edit Miscellaneous"));
    setFormikData(miscellaneous);
    dispatch(setAddUpdateModal(true));
    dispatch(setMiscellaneousForUpdate(miscellaneous));
  };
  const onAdd = () => {
    dispatch(setModalTitle("Add Miscellaneous"));
    dispatch(setAddUpdateModal(true));
  };

  const handleSearch = (text?: string) => {
    const filteredDataList = includeArchived
      ? data.filter(
          (x: MiscellaneousTask) => x.Name && x.Name.trim().length > 0,
        )
      : data.filter(
          (x: MiscellaneousTask) =>
            x.Name && x.Name.trim().length > 0 && !x.IsArchived,
        );
    // Search guard clause
    if (!text) return filteredDataList;
    return filteredDataList;
  };

  return (
    <Spin spinning={loading}>
      <StyledRow justify="space-between">
        <Row className="btn-row"></Row>
        <RightSideTableHeader>
          <Tooltip
            placement="top"
            title={`${
              includeArchived ? "Hide" : "Include"
            } Archived Miscellaneouss`}
          >
            <div
              className="include-archived-scripts"
              onClick={() => {
                setIncludeArchived(!includeArchived);
              }}
            >
              <button
                className={`archived-scripts ${
                  includeArchived ? "active " : ""
                }`}
              >
                <Icon component={Archive} />
              </button>
              <Switch checked={includeArchived} onChange={() => undefined} />
            </div>
          </Tooltip>
          <Button
            type="primary"
            shape={"rounded"}
            leftIcon={<PlusOutlined />}
            onClick={onAdd}
          >
            Add Miscellaneous
          </Button>
        </RightSideTableHeader>
      </StyledRow>
      <MiscellaneousTable
        showDetails={showDetails}
        onEdit={onEdit}
        handleSearch={handleSearch}
        searchInput={searchInput}
      />
      <AddUpdateDrawer
        title={modalTitle}
        formik={formik}
        isVisible={addUpdateModal}
        onClickOutside={addUpdate}
        onClose={addUpdate}
      />
    </Spin>
  );
}
