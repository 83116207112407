import "@ant-design/compatible/assets/index.css";
import Icon, { PlusOutlined } from "@ant-design/icons";
import { Row, Spin, Tooltip } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import { getMasterResearchQuestionList } from "../../../../api/ResearchQuestion/ApiGet";
import { ResearchQuestion } from "../../../../models/ScriptInterfaces";
import {
  RightSideTableHeader,
  StyledRow,
} from "../../../../components/Table/CustomTable.styled";
import Button from "../../../../components/Button/Button";
import AddUpdateDrawer from "./AddUpdateDrawer";
import { useFormik } from "formik";
import { FormikErrorType } from "../../../../models/common/FormikErrorType";
import { createResearchQuestion } from "../../../../api/ResearchQuestion/ApiPost";
import { ReactComponent as Archive } from "../../../../assets/img/Svg/Archive.svg";
import { updateResearchQuestion } from "../../../../api/ResearchQuestion/ApiPut";
import { Switch } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  onCreateSuccess,
  onDeleteSuccess,
  onUpdateSuccess,
  resetRQForUpdate,
  setAddUpdateModal,
  setData,
  setLoading,
  setModalTitle,
  setRQForUpdate,
} from "./ResearchQuestionSlice";
import ResearchQuestionTable from "./ResearchQuestionTable";
import { deleteResearchQuestion } from "../../../../api/ResearchQuestion/ApiDelete";

export default function ResearchQuestions() {
  const {
    data,
    modalTitle,
    addUpdateModal,
    loading,
    researchQuestionForUpdate,
  } = useSelector((state: any) => state.MasterResearchQuestionReducer);
  const dispatch = useDispatch();
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const initialValues = {
    id: 0,
    question: "",
    description: "",
    additionalDetails: "",
    researchQuestionType: 0,
  };
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors: FormikErrorType = {};

      // question
      if (!values["question"].trim()) {
        errors["question"] = "Required";
      } else if (values["question"].trim().length > 40) {
        errors["question"] = "Max 40 characters";
      }

      return errors;
    },
    onSubmit: (values: any) => {
      addUpdate();
    },
  });
  useEffect(() => {
    refreshData();
    return () => {
      dispatch(setAddUpdateModal(false));
      dispatch(resetRQForUpdate(null));
    };
  }, []);

  const refreshData = () => {
    dispatch(setLoading(true));
    getMasterResearchQuestionList(onSuccess, onError);
  };
  const onSuccess = (data: any) => {
    dispatch(
      setData(
        data.sort((a: any, b: any) =>
          new Date(b.CreatedAt).getTime() < new Date(a.CreatedAt).getTime()
            ? -1
            : 1,
        ),
      ),
    );
    dispatch(setLoading(false));
  };
  const onError = (msg: any) => {
    OpenErrorNotification({
      description: msg,
    });
    dispatch(setLoading(false));
  };
  // Reset the form inputs.
  const resetForm = () => {
    setTimeout(() => {
      formik.setValues(initialValues, false);
      dispatch(resetRQForUpdate(null));
    }, 100);
  };
  const onUpdateSuccessCB = (data: ResearchQuestion) => {
    dispatch(onUpdateSuccess(data));
  };
  const onCreateSuccessCB = (data: ResearchQuestion) => {
    dispatch(onCreateSuccess(data));
    dispatch(setRQForUpdate(data));
    setFormikData(data);
    dispatch(setModalTitle("Add Question"));
    dispatch(setAddUpdateModal(true));
  };
  // Add research question.
  const addUpdate = () => {
    if (formik.isValid) {
      // save
      if (formik.values["id"] > 0) {
        // update
        const payload = {
          ...researchQuestionForUpdate,
          question: formik.values["question"],
        };
        updateResearchQuestion(payload, onUpdateSuccessCB, onError);
      }
      closeAddUpdateModal();
    } else {
      // delete question if title is empty
      if (
        modalTitle === "Add Question" &&
        formik.values["question"].trim().length === 0
      ) {
        deleteResearchQuestion(
          researchQuestionForUpdate.Id,
          (successMessage: string) => {
            dispatch(onDeleteSuccess(researchQuestionForUpdate.Id));
          },
          (errorMessage: string) => {
            OpenErrorNotification({ description: errorMessage });
          },
        );
      }
      closeAddUpdateModal();
    }
  };

  const closeAddUpdateModal = () => {
    dispatch(setAddUpdateModal(false));
    resetForm();
  };

  // Open view research question details panel with research question data.
  const showDetails = (question: ResearchQuestion) => {
    dispatch(setModalTitle("View/Edit Question"));
    setFormikData(question);
    dispatch(setAddUpdateModal(true));
    dispatch(setRQForUpdate(question));
  };
  const setFormikData = (question: ResearchQuestion) => {
    formik.setValues(
      {
        id: question.Id || 0,
        question: question.Question || "",
        researchQuestionType: question.ResearchQuestionType || 0,
        description: question.Description || "",
        additionalDetails: question.AdditionalDetails || "",
      },
      false,
    );
  };
  const onEdit = (question: ResearchQuestion) => {
    dispatch(setModalTitle("Edit Question"));
    setFormikData(question);
    dispatch(setAddUpdateModal(true));
    dispatch(setRQForUpdate(question));
  };
  const onAdd = () => {
    // add
    const payload = {
      question: "",
      description: "",
      additionalDetails: "",
      isRequired: false,
      researchQuestionType: formik.values["researchQuestionType"],
      isPublic: false,
    };
    createResearchQuestion(payload, onCreateSuccessCB, onError);
  };

  const handleSearch = (text?: string) => {
    const filteredDataList = includeArchived
      ? data.filter(
          (x: ResearchQuestion) => x.Question && x.Question.trim().length > 0,
        )
      : data.filter(
          (x: ResearchQuestion) =>
            x.Question && x.Question.trim().length > 0 && !x.IsArchived,
        );
    // Search guard clause
    if (!text) return filteredDataList;
    return filteredDataList;
  };

  return (
    <Spin spinning={loading}>
      <StyledRow justify="space-between">
        <Row className="btn-row"></Row>
        <RightSideTableHeader>
          <Tooltip
            placement="top"
            title={`${includeArchived ? "Hide" : "Include"} Archived Questions`}
          >
            <div
              className="include-archived-scripts"
              onClick={() => {
                setIncludeArchived(!includeArchived);
              }}
            >
              <button
                className={`archived-scripts ${
                  includeArchived ? "active " : ""
                }`}
              >
                <Icon component={Archive} />
              </button>
              <Switch checked={includeArchived} onChange={() => undefined} />
            </div>
          </Tooltip>
          <Button
            type="primary"
            shape={"rounded"}
            leftIcon={<PlusOutlined />}
            onClick={onAdd}
          >
            Add Question
          </Button>
        </RightSideTableHeader>
      </StyledRow>
      <ResearchQuestionTable
        showDetails={showDetails}
        onEdit={onEdit}
        handleSearch={handleSearch}
        searchInput={searchInput}
      />
      <AddUpdateDrawer
        title={modalTitle}
        formik={formik}
        isVisible={addUpdateModal}
        onClickOutside={addUpdate}
        onClose={addUpdate}
      />
    </Spin>
  );
}
