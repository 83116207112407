import styled from "styled-components";
import { BlueButton } from "../../../../../styles/Buttons.styled";

export const StyledBlueButton = styled(BlueButton)`
   {
    width: 180px;
    border-radius: 25px !important;
    padding: 0.5rem 0.4rem;
    height: auto;
    .anticon {
      font-size: 1rem;
    }
  }
`;
export const GenderButtonWrapper = styled.div`
  button {
    width: 100px;
    margin-right: 1rem;
    border-radius: 3px;
  }
`;
