import axios from "axios";
import { defaultErrorCallback } from "./ErrorCallback";
import {
  CreateTreatmentTask,
  UpdateTreatmentTask,
} from "../models/TreatmentTask";

export function getMasterTreatmentTasks(
  forSearch: boolean,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .get(`/api/2/pfc/treatment/master?forSearch=${forSearch}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function makeTreatmentTaskMaster(
  timedActionTaskId: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(`/api/2/pfc/treatment/${timedActionTaskId}/master`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function createTreatmentTask(
  payload: CreateTreatmentTask,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .post(`/api/2/pfc/treatment/master`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function updateTreatmentTask(
  payload: UpdateTreatmentTask,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(`/api/2/pfc/treatment/master`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function deleteTreatmentTask(
  id: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .delete(`/api/2/pfc/treatment/master/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}
