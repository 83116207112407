import React from "react";
import { StyledTableWrapper } from "../RightEditPanel.styled";
import { WhiteIconButton } from "../../../../../../styles/Buttons.styled";
import {
  EyeInvisibleOutlinedIcons,
  EyeOutlinedIcons,
} from "../../../../../../IconsTheme/Icons.global";
import { CasualtyStatus } from "../../../../../../models/ScriptInterfaces";
import { useFormik } from "formik";
import ItemRow from "./ItemRow";
import { useSelector } from "react-redux";
interface Props {
  blockContent: CasualtyStatus;
  visible: boolean;
  onChange: Function;
}
export default function MarchTableEdit(props: Props) {
  const { isReadOnly } = useSelector((state: any) => state.MainlistReducer);
  const scriptFormik = useFormik({
    initialValues: {
      m_note: props.blockContent.MassiveHemorrhageDescription || "",
      a_note: props.blockContent.AirwayDescription || "",
      r_note: props.blockContent.RespirationDescription || "",
      c_note: props.blockContent.CirculationDescription || "",
      h_note: props.blockContent.HypothermiaDescription || "",
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const onChange = (field: string, value: string) => {
    if (isReadOnly) return; //disable action callback in read only script
    props.onChange(field, value);
  };
  const toggleMarchTable = () => {
    if (isReadOnly) return; //disable action callback in read only script
    props.onChange("MARCHIsDisplayed", !props.visible);
  };
  return (
    <div>
      <WhiteIconButton
        type="link"
        icon={
          props.visible ? (
            <EyeOutlinedIcons className="visibleIcon" />
          ) : (
            <EyeInvisibleOutlinedIcons />
          )
        }
        size="middle"
        className={props.visible ? "active" : ""}
        onClick={toggleMarchTable}
      >
        MARCH Table Notation
      </WhiteIconButton>

      {props.visible ? (
        <StyledTableWrapper>
          <ItemRow
            isReadOnly={isReadOnly}
            field={`m_note`}
            originalKey={"MassiveHemorrhageDescription"}
            formik={scriptFormik}
            label={"M"}
            description={props.blockContent.MassiveHemorrhageDescription}
            onChange={onChange}
          />
          <ItemRow
            isReadOnly={isReadOnly}
            field={`a_note`}
            originalKey={"AirwayDescription"}
            formik={scriptFormik}
            label={"A"}
            description={props.blockContent.AirwayDescription}
            onChange={onChange}
          />
          <ItemRow
            isReadOnly={isReadOnly}
            field={`r_note`}
            originalKey={"RespirationDescription"}
            formik={scriptFormik}
            label={"R"}
            description={props.blockContent.RespirationDescription}
            onChange={onChange}
          />
          <ItemRow
            isReadOnly={isReadOnly}
            field={`c_note`}
            originalKey={"CirculationDescription"}
            formik={scriptFormik}
            label={"C"}
            description={props.blockContent.CirculationDescription}
            onChange={onChange}
          />
          <ItemRow
            isReadOnly={isReadOnly}
            field={`h_note`}
            originalKey={"HypothermiaDescription"}
            formik={scriptFormik}
            label={"H"}
            description={props.blockContent.HypothermiaDescription}
            onChange={onChange}
          />
        </StyledTableWrapper>
      ) : null}
    </div>
  );
}
