import axios from "axios";
import { defaultErrorCallback } from "../ErrorCallback";

const apiPathPrefix = "/api/1/pfc/tablet-build";

// tablet-build latest
export function getLatestTabletBuild(
  callback: Function,
  errorcallback: Function = defaultErrorCallback,
) {
  axios
    .get(`${apiPathPrefix}/latest`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      console.log(err);
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
// get all tablet-builds
export function getAllTabletBuild(
  callback: Function,
  errorcallback: Function = defaultErrorCallback,
) {
  axios
    .get(`${apiPathPrefix}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      console.log(err);
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
