import { useFormik } from "formik";
import produce from "immer";
import React, { useEffect, useState } from "react";
import {
  DeleteResearchQuestionSelectAnswer,
  UpdateResearchQuestionSelect,
} from "../../../../../api/ResearchQuestionBlock/ApiPost";
import { OpenErrorNotification } from "../../../../../components/Notification/Notification";
import { FormikErrorType } from "../../../../../models/common/FormikErrorType";
import { ResearchQuestionSelectType } from "../../../../../models/Enums";
import {
  ResearchQuestionSelect,
  ResearchQuestionSelectAnswer,
} from "../../../../../models/ScriptInterfaces";
import AddItemToListInput from "./Components/AddItemToListInput";
import ListItems from "./Components/ListItems";
import PlaceholderInput from "./Components/PlaceholderInput";
import RadioWithTwoOption from "./Components/RadioWithTwoOption";
import { useDebounce } from "../../../../../hooks/useDebounce";

interface Props {
  object: ResearchQuestionSelect;
  onChange: any;
  disabled: boolean;
}
export default function SelectQuestionBlock(props: Props) {
  const focusedStates = {
    placeholder: false,
    characterLimit: false,
  };
  const [isFocused, setIsFocused] = useState(focusedStates);
  const [lastFocused, setLastFocused] = useState(focusedStates);
  const scriptFormik = useFormik({
    initialValues: {
      placeholder: props?.object?.Placeholder || "",
      ResearchQuestionSelectType:
        props?.object?.ResearchQuestionSelectType ||
        ResearchQuestionSelectType.SingleSelect,
    },
    validate: () => {
      const errors: FormikErrorType = {};
      return errors;
    },
    validateOnBlur: true,
    onSubmit: () => {
      onSelectQuestionBlockSave();
    },
  });
  const handleChange = (field: string, value: string) => {
    scriptFormik.setFieldValue(field, value);
    const Payload = { ...props.object, [field]: value };
    props.onChange("ResearchQuestionSelect", Payload);
  };

  const updateData = (Payload: ResearchQuestionSelect) => {
    props.onChange("ResearchQuestionSelect", Payload);
  };

  const debounceSubmitFormik = useDebounce(scriptFormik.submitForm);

  const onSelectQuestionBlockSave = () => {
    // Do not do state update here. It will result in infinite loop.
    // Check below useEffect for more information.
    UpdateResearchQuestionSelect(
      props.object,
      () => undefined,
      (error: any) => {
        OpenErrorNotification({ description: error?.message });
      },
    );
  };

  useEffect(() => {
    if (scriptFormik.isValid) {
      debounceSubmitFormik();
    }

    return () => debounceSubmitFormik.cancel();
  }, [
    props.object.ResearchQuestionSelectAnswers,
    scriptFormik.isValid,
    scriptFormik.values,
  ]);

  const handleOptionDelete = (id: number) => {
    DeleteResearchQuestionSelectAnswer(
      id,
      () => {
        const Data = props.object?.ResearchQuestionSelectAnswers || [];
        const newList = produce(Data, (draft: any) => {
          return draft.filter((x: any) => x.Id !== id);
        });
        const Payload = {
          ...props.object,
          ResearchQuestionSelectAnswers: newList,
        };
        props.onChange("ResearchQuestionSelect", Payload);
      },
      (error: any) => {
        OpenErrorNotification({
          description: error.message,
        });
      },
    );
  };
  const addOptionToList = (value: string) => {
    const newList = produce(
      props.object?.ResearchQuestionSelectAnswers || [],
      (draft: any) => {
        draft.push({
          Answer: value,
          Id: 0,
          Order: 0,
          ResearchQuestionSelectId: props.object.Id,
        });
      },
    );
    const orderedNewList = newList.map(
      (x: ResearchQuestionSelectAnswer, index: number) => ({
        ...x,
        Order: index,
      }),
    );
    const Payload = {
      ...props.object,
      ResearchQuestionSelectAnswers: orderedNewList,
    };
    UpdateResearchQuestionSelect(
      Payload,
      (data: ResearchQuestionSelect) => {
        updateData(data);
      },
      (error: any) => {
        OpenErrorNotification({ description: error.message });
      },
    );
  };
  const handleTitleUpdate = (Id: number, value: string) => {
    const Data = props.object?.ResearchQuestionSelectAnswers || [];
    const newList = produce(Data, (draft: any) => {
      const index = draft.findIndex((x: any) => x.Id == Id);
      if (index !== -1) {
        draft[index].Answer = value;
      }
    });
    const Payload = { ...props.object, ResearchQuestionSelectAnswers: newList };
    updateData(Payload);
  };
  const handleOrderUpdate = (
    researchQuestionSelectAnswers: ResearchQuestionSelectAnswer[],
  ) => {
    const Payload = {
      ...props.object,
      ResearchQuestionSelectAnswers: researchQuestionSelectAnswers,
    };
    updateData(Payload);
  };
  return (
    <>
      <div className="mb-8">
        <PlaceholderInput
          label={"Placeholder"}
          maxLength={40}
          scriptFormik={scriptFormik}
          DBKey={"Placeholder"}
          field={"placeholder"}
          onChange={handleChange}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          lastFocused={lastFocused}
          setLastFocused={setLastFocused}
          readOnly={props.disabled}
        />
      </div>
      <div className="mb-5">
        <RadioWithTwoOption
          DBField="ResearchQuestionSelectType"
          onChange={handleChange}
          selectedOption={scriptFormik.values["ResearchQuestionSelectType"]}
          Options={[
            {
              text: "Single-Select",
              value: ResearchQuestionSelectType.SingleSelect,
            },
            {
              text: "Multi-Select",
              value: ResearchQuestionSelectType.MultiSelect,
            },
          ]}
          disabled={props.disabled}
        />
      </div>
      <div className="mb-8">
        {props.disabled ? null : (
          <AddItemToListInput
            addOptionToList={addOptionToList}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            lastFocused={lastFocused}
            setLastFocused={setLastFocused}
          />
        )}
        <ListItems
          list={props.object?.ResearchQuestionSelectAnswers || []}
          handleOptionDelete={handleOptionDelete}
          handleOrderUpdate={handleOrderUpdate}
          handleTitleUpdate={handleTitleUpdate}
          disabled={props.disabled}
        />
      </div>
    </>
  );
}
