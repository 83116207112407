import * as React from "react";
import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";

type DetailCardProps = {
  title?: string;
  icon?: React.ReactNode;
  image?: string;
  children?: React.ReactNode;
};

const DetailCardWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.025);
  display: flex;
  border-bottom: 1px solid ${colorPalette.gray25};
  color: #fff;
`;
const DetailCardHeader = styled.div`
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  display: flex;
  .anticon {
    margin-left: auto;
    color: ${colorPalette.gray25};
  }
  h4 {
    color: #fff;
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
`;
const DetailCardBody = styled.div`
  padding-bottom: 0.7rem;
  p {
    font-size: 1rem;
    line-height: 1.2rem;
  }
`;
const ImageWrap = styled.div`
  width: 125px;
  margin: auto;
  img {
    max-width: 100%;
  }
`;
const ContentWrap = styled.div`
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  width: 100%;
`;

const DetailCard = ({ title, image, icon, children }: DetailCardProps) => {
  return (
    <DetailCardWrapper>
      {image && (
        <ImageWrap>
          <img src={image} alt={title} />
        </ImageWrap>
      )}
      <ContentWrap>
        {(title || icon) && (
          <DetailCardHeader>
            <h4>{title}</h4>
            {icon}
          </DetailCardHeader>
        )}
        {children && <DetailCardBody>{children}</DetailCardBody>}
      </ContentWrap>
    </DetailCardWrapper>
  );
};

export default DetailCard;
