import styled from "styled-components";
import { WhiteButtonWithBorder } from "../../../../../styles/Buttons.styled";

export const StyledResearchQuestionCenterBlock = styled.div`
   {
    width: 100%;
    padding: 10px 30px 10px 10px;
    .title-block {
      display: flex;
      justify-content: flex-start;
      color: #ffffff;
      .titleText {
        align-items: center;
      }
      span {
        display: flex;
        align-items: center;
      }
      div {
        display: flex;
        margin-left: 10px;
      }
    }

    .mainBlockContent {
      margin: 10px 10px 10px 20px;
      display: flex;
      p {
        width: 93%;
      }
      .researchQuestionSideBannerParentDiv {
        margin-right: 12px;

        .researchQuestionSideBanner {
          width: 5.06px;
          height: 100%;
          background: #ffffff;
          border: none;
        }
      }
    }
  }
`;

export const StyledResearchQuestionDetails = styled.div`
   {
    width: 100%;
    .question {
      word-break: break-word;
      font-weight: 400;
      white-space: pre-line;
      font-size: 1rem;
      display: flex;
      align-items: center;
    }
    .description {
      font-size: 0.875rem;
      color: #ffffff50;
    }
    // Select Dropdown,input and textarea styling
    .default,
    .ant-select-selection-search-input,
    .ant-select-selection-item,
    .ant-select-arrow,
    .intervalInput .intervalMinutesSSuffix input,
    .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled
      .ant-input[disabled] {
      color: #9a9a9a !important;
      cursor: initial;
    }
    .anticon.anticon-plus-circle,
    .anticon.anticon-minus-circle {
      color: #9a9a9a !important;
    }

    .range-text {
      font-size: 1.125rem;
    }
    .slider-wrapper {
      display: flex;
      & > span {
        color: #9a9a9a;
        padding: 0 0.5rem;
      }
      .ant-slider {
        width: 370px;
        .ant-slider-mark-text.ant-slider-mark-text-active {
          bottom: 1rem;
        }
        &.ant-slider-with-marks {
          margin-bottom: 10px;
        }
      }
      .slider-with-range {
        .range-1 {
          float: left;
        }
        .range-2 {
          float: right;
        }
        clear: both;
      }
    }

    ul {
      margin-bottom: 0;
      .titleIndexDiv {
        padding: 0.5rem 0.8rem;
        .checkbox {
          width: 1rem;
          height: 1rem;
          min-width: 1rem;
          min-height: 1rem;
          border: 1px solid #ffffff;
          margin-right: 1rem;
        }
        .radio {
          width: 1rem;
          height: 1rem;
          min-width: 1rem;
          min-height: 1rem;
          border: 1px solid #ffffff;
          margin-right: 1rem;
          border-radius: 50px;
        }
      }
      li.horizontal-option {
        width: 200px;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
`;
export const StyledWhiteButton = styled(WhiteButtonWithBorder)`
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  width: 160px;
  height: 40px;
  border: 1px solid #ffffff !important;
  background: #202020 !important;
  color: #ffffff !important;
  cursor: pointer !important;
  &:hover {
    background: #202020 !important;
    border: 1px solid #ffffff !important;
    color: #ffffff !important;
  }

  img {
    height: 18px;
    width: 18px;
    margin-right: 8px;
    margin-top: -2px;
  }
`;
