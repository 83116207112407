import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

interface ScatterDataset {
  label: string;
  data: { x: number; y: number }[];
  backgroundColor: string;
}

interface ScatterData {
  datasets: ScatterDataset[];
}

interface Props {
  data: ScatterData;
}
export default function CustomScatterChart({ data }: Props) {
  return <Scatter options={options} data={data} />;
}
