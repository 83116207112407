import React from "react";
import { WhiteIconButton } from "../../../../../../styles/Buttons.styled";
import { Vital } from "../../../../../../models/ScriptInterfaces";
import {
  EyeInvisibleOutlinedIcons,
  EyeOutlinedIcons,
} from "../../../../../../IconsTheme/Icons.global";
import { VitalType } from "../../../../../../models/Enums";
import InlineEditTextInput from "../../../../../../components/InlineEdit/InlineEditTextInput";
import InlineEditTextArea from "../../../../../../components/InlineEdit/InlineEditTextArea";

interface Props {
  vital: Vital;
  onChange: Function;
  isReadOnly: boolean;
}

export default function ItemRow(props: Props) {
  const toggleVital = () => {
    props.onChange({
      ...props.vital,
      IsVitalVisible: !props.vital.IsVitalVisible,
    });
  };
  const onChange = (field: string, value: string) => {
    props.onChange({ ...props.vital, [field]: value });
  };
  return (
    <div className="item">
      <div className="action">
        <WhiteIconButton
          type="link"
          icon={
            props.vital.IsVitalVisible ? (
              <EyeOutlinedIcons className="visibleIcon" />
            ) : (
              <EyeInvisibleOutlinedIcons />
            )
          }
          size="middle"
          onClick={toggleVital}
        ></WhiteIconButton>
      </div>
      <div>{VitalType[props.vital.VitalType]}</div>
      <InlineEditTextInput
        wrapperClassName="value word-wrap cursor-pointer"
        uniqueId={`Value_${props.vital.Id}`}
        onChange={(id: string, value: string) => onChange("Value", value)}
        value={props.vital.Value}
        valueToDisplay={props.vital.Value}
        maxLength={7}
        isReadOnly={props.isReadOnly}
      />
      <InlineEditTextArea
        wrapperClassName="note word-wrap cursor-pointer"
        uniqueId={`ShortNote_${props.vital.Id}`}
        onChange={(id: string, value: string) => onChange("ShortNote", value)}
        value={props.vital.ShortNote}
        isReadOnly={props.isReadOnly}
      />
    </div>
  );
}
