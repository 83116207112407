import Icon, {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import * as React from "react";
import { Medication } from "../../../../models/ScriptInterfaces";
import { ActionIcon } from "../../../../components/Table/CustomTable.styled";
import CustomTable from "../../../../components/Table/CustomTable";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../components/ConfirmDialog/useConfirmDialog";
import { colorPalette } from "../../../../styles/MainList.styled";
import { ReactComponent as Archive } from "../../../../assets/img/Svg/Archive.svg";
import { renderFormattedName } from "../../../../utils/utilityfunctions";
import { useDispatch } from "react-redux";
import { TimedActionTaskSection } from "../../../ScriptBuilder/SectionComponents";
import { BlockType } from "../../../../models/Enums";
import { deleteMedication } from "../../../../api/Medication/ApiDelete";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../../components/Notification/Notification";
import { onArchiveSuccess, onDeleteSuccess } from "./MedicationSlice";
import { archiveUnarchiveMedication } from "../../../../api/Medication/ApiPut";
import { MedicationTaskBlock } from "../../../../models/MedicationTaskBlock";
import { MedicationTask } from "../../../../models/MedicationTask";
import { deleteMedicationTask } from "../../../../api/MedicationTask";
import { archiveOrUnarchiveMasterTimedAction } from "../../../../api/TimedActionTask";
import { useState } from "react";
interface Props {
  handleSearch: any;
  showDetails: any;
  onEdit: any;
  searchInput: string;
}
export default function MedicationTable({
  onEdit,
  showDetails,
  handleSearch,
  searchInput,
}: Props) {
  const dispatch = useDispatch();
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const renderActionOptions = (data: MedicationTask) => {
    return (
      <div className="action-tray">
        {/* edit icon */}
        <ActionIcon
          className="action-option"
          hoverColor={colorPalette.white}
          onClick={() => onEdit(data)}
        >
          <span className="tooltip-on-hover">Edit</span>
          <EditOutlined className="display-on-hover" />
        </ActionIcon>
        {/* archive/ unarchive icons */}
        <ActionIcon
          className="action-option"
          hoverColor={
            !data.IsArchived ? colorPalette.error : colorPalette.primary100
          }
          onClick={() => archiveTask(data)}
        >
          <span className="tooltip-on-hover">
            {data.IsArchived ? "Unarchive" : "Archive"}
          </span>
          <Icon component={Archive} className="display-on-hover" />
        </ActionIcon>
        {/* copy icon */}
        <ActionIcon
          className="action-option"
          hoverColor={colorPalette.white}
          onClick={() => handleCopy(data.Id)}
        >
          <span className="tooltip-on-hover">Copy</span>
          <CopyOutlined className="display-on-hover" />
        </ActionIcon>
        {/* delete icon */}
        <ActionIcon
          className="action-option"
          hoverColor={colorPalette.error}
          onClick={() => handleDelete(data.Id)}
        >
          <span className="tooltip-on-hover">Delete</span>
          <DeleteOutlined className="display-on-hover" />
        </ActionIcon>
      </div>
    );
  };
  const archiveTask = (data: MedicationTask) => {
    archiveOrUnarchiveMasterTimedAction(
      data.Id,
      () => {
        dispatch(onArchiveSuccess(data.Id));
      },
      (errorMessage: string) => {
        OpenErrorNotification({ description: errorMessage });
      },
    );
  };
  const handleCopy = (id: number) => {
    if (id) {
      // copyMedication( <- API do not exist.
      //   id,
      //   (response: any) => {
      //     dispatch(onCreateSuccess(response));
      //   },
      //   (errorMessage: string) => {
      //     OpenErrorNotification({ description: errorMessage });
      //   },
      // );
    }
  };
  const handleDelete = (id: number) => {
    if (id) {
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "You are going to delete medication.",
        content: "Are you sure you want delete this medication?",
        okText: "Yes",
        onOk: () => {
          deleteMedicationTask(
            id,
            (successMessage: string) => {
              dispatch(onDeleteSuccess(id));
              OpenSuccessNotification({ description: successMessage });
            },
            (errorMessage: string) => {
              OpenErrorNotification({ description: errorMessage });
            },
          );
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "error",
      });
    }
  };
  const renderTask = (data: MedicationTask) => {
    const block: MedicationTaskBlock = {
      Id: 0,
      UUID: "0",
      IsGhostBlock: false,
      BlockType: BlockType.Medication,
      Order: 0,
      OrderInNextPhase1: 0,
      OrderInNextPhase2: 0,
      SectionId: 0,
      TimedAction: true,
      BlockContent: {
        Id: 0,
        CreatedInPhaseId: 0,
        IsCritical: false,
        IsEditMasterEnabled: false,
        IsRequired: false,
        RequiredInPhaseOffset: 0,
        TimedActionTaskContent: data,
      },
    };
    return (
      <div className="cursor-pointer" onClick={() => showDetails(data)}>
        <TimedActionTaskSection block={block} type={BlockType.Medication} />
      </div>
    );
  };
  const columns = [
    {
      title: "Task",
      dataIndex: "",
      key: "type",
      className: "whiteText",
      sorter: (a: any, b: any) => a.Label.localeCompare(b.Label),
      render: (type: any, data: any) => renderTask(data),
    },
    {
      title: "Created By",
      dataIndex: "CreatedBy",
      key: "CreatedBy",
      className: "whiteText",
      width: "180px",
      sorter: (a: any, b: any) =>
        a.CreatedBy?.FirstName.localeCompare(b.CreatedBy?.FirstName),
      render: (CreatedBy: any, data: Medication) => (
        <span className="text-white">
          {data?.CreatedBy?.LastName || data?.CreatedBy?.FirstName
            ? renderFormattedName(
                data?.CreatedBy?.LastName || "",
                data?.CreatedBy?.FirstName || "",
              )
            : "-"}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "actions",
      className: "actions",
      render: (UpdatedDate: any, data: MedicationTask) => {
        return renderActionOptions(data);
      },
    },
  ];

  const archivedRow = (data: Medication) => {
    return data.IsArchived ? "archived-row" : "";
  };

  return (
    <>
      <CustomTable
        WrapperStyle={{ height: `calc(100vh - 65px - 70px)` }}
        columns={columns}
        data={handleSearch(searchInput)}
        dynamicRowClass={archivedRow}
      />
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </>
  );
}
