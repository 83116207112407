import produce from "immer";
import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  UpdateSection,
  UpdateSectionOrder,
} from "../../../../../api/Section/ApiPost";
import InlineEditTextInput from "../../../../../components/InlineEdit/InlineEditTextInput";
import { DragIcon } from "../../../../../IconsTheme/Icons.global";
import { Phase, Section } from "../../../../../models/ScriptInterfaces";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { SetPhases } from "../../../MainlistSlice";

interface Props {
  sections: Section[];
  loadingToggle: Function;
  postSuccess: Function;
  postError: Function;
  isReadOnly: boolean;
}
export default function SectionList(props: Props) {
  const MainListState = useAppSelector((state: any) => state.MainlistReducer);
  const phases: Phase[] = MainListState.phases;
  const dispatch = useAppDispatch();

  const onDragEnd = (result: DropResult) => {
    console.log(result);
    if (!result.destination) {
      // dropped outside of a drop-enabled area
      return;
    }

    const sourceSectionUUID = result.source.droppableId;
    const sourceIndex = result.source.index;
    const destinationSectionUUID = result.destination!.droppableId;
    const destinationIndex = result.destination!.index;

    if (sourceSectionUUID === destinationSectionUUID) {
      // this is a drag reordering inside a single phase
      if (sourceIndex === destinationIndex) {
        // user dropped it right where they picked it up.  No reordering necessary
        return;
      }

      props.loadingToggle();

      // ALL sections
      const reorderedSections: Section[] = [
        ...phases[MainListState.currentPhaseIndex].Sections!,
      ];
      // Update Order in section array
      const [removedSection] = reorderedSections.splice(sourceIndex, 1);
      reorderedSections.splice(destinationIndex, 0, removedSection);

      // Assign new reordered section array to store
      const newPhases = produce(phases, (draftPhases) => {
        draftPhases![MainListState.currentPhaseIndex].Sections =
          reorderedSections;
      });
      dispatch(SetPhases(newPhases));

      //Create Payload for update order service
      const newOrderMapping: Section[] = reorderedSections.map(
        (section: Section, index: number) => {
          return {
            ...section,
            Order: index,
          };
        },
      );
      // Update Service Call
      UpdateSectionOrder(
        newOrderMapping,
        reorderSectionSuccess,
        props.postError,
      );
    }
    return;
  };

  /**
   * disable loading On update order success
   */
  const reorderSectionSuccess = () => {
    props.loadingToggle();
  };

  const onTitleSectionChange = (newTitle: string, sectionIndex: number) => {
    const currentPhaseIndex = MainListState.currentPhaseIndex;
    const phasesSectionUpdate = produce(phases, (draftPhases) => {
      (draftPhases![currentPhaseIndex].Sections![sectionIndex]![
        "Title"
      ] as string) = newTitle;
    });
    dispatch(SetPhases(phasesSectionUpdate));

    const newSection: Section = {
      ...phases[currentPhaseIndex].Sections![sectionIndex],
      Title: newTitle,
    };
    UpdateSection(newSection, props.postSuccess, props.postError);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`sections-list`} key={`sections-list`}>
        {(provided: any, snapshot: any) => (
          <div ref={provided.innerRef} {...snapshot.isDraggingOver}>
            <p className="mb-2">SECTIONS</p>
            {props.sections.map((section: Section, sectionIndex: number) => (
              <Draggable
                draggableId={`${section?.Id}`}
                key={`section-${section?.Id || sectionIndex}`}
                index={sectionIndex}
                isDragDisabled={props.isReadOnly}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                    }}
                    className="sectionList"
                    key={sectionIndex}
                  >
                    <DragIcon className="section-drag-icon" />
                    <span className="section-index">{`${
                      sectionIndex + 1
                    } - `}</span>
                    <InlineEditTextInput
                      value={`${section.Title ? section.Title : ""}`}
                      valueToDisplay={`${
                        section.Title ? section.Title : "New Section"
                      }`}
                      uniqueId={`section_title_${section?.Id}`}
                      maxLength={40}
                      onChange={(id: string, value: string) =>
                        onTitleSectionChange(value, sectionIndex)
                      }
                      isReadOnly={props.isReadOnly}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
