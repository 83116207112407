import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NotificationProps } from "../../models/Management";

const defaultToastProps: ToastOptions = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  draggable: true,
  progress: undefined,
};

export const showToast = (notificationProps: NotificationProps) => {
  const { description, position, type } = notificationProps;

  toast(description, {
    ...defaultToastProps,
    position,
    type,
  });
};

export const OpenErrorNotification = (notificationProps: NotificationProps) => {
  const { position = "top-center", type = "error" } = notificationProps;
  showToast({ ...notificationProps, position, type });
};

export const OpenSuccessNotification = (
  notificationProps: NotificationProps
) => {
  const { position = "top-center", type = "success" } = notificationProps;
  showToast({ ...notificationProps, position, type });
};

export const OpenInfoNotification = (notificationProps: NotificationProps) => {
  const { position = "top-center", type = "info" } = notificationProps;
  showToast({ ...notificationProps, position, type });
};

export const OpenWarningNotification = (
  notificationProps: NotificationProps
) => {
  const { position = "top-center", type = "warning" } = notificationProps;
  showToast({ ...notificationProps, position, type });
};
