import axios from "axios";
import { defaultErrorCallback } from "./ErrorCallback";
import {
  CreateMiscellaneousTask,
  UpdateMiscellaneousTask,
} from "../models/MiscellaneousTask";

export function getMasterMiscellaneousTasks(
  forSearch: boolean,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .get(`/api/2/pfc/miscellaneous/master?forSearch=${forSearch}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function makeMiscellaneousTaskMaster(
  timedActionTaskId: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(`/api/2/pfc/miscellaneous/${timedActionTaskId}/master`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function createMiscellaneousTask(
  payload: CreateMiscellaneousTask,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .post(`/api/2/pfc/miscellaneous/master`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function updateMiscellaneousTask(
  payload: UpdateMiscellaneousTask,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(`/api/2/pfc/miscellaneous/master`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function deleteMiscellaneousTask(
  id: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .delete(`/api/2/pfc/miscellaneous/master/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}
