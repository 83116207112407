import { SaveFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import { EditOutlinedIcons } from "../../../../../IconsTheme/Icons.global";
import { FormikErrorType } from "../../../../../models/common/FormikErrorType";
import { isNullOrUndefined } from "../../../../../utils/utilityfunctions";
import FloatingLabelInput from "../../../../../components/FloatingLabel/FloatingLabelInput";
interface Props {
  label: string;
  field: string;
  value: string | number;
  valueToDisplay?: string | number;
  unit: string;
  onChange: Function;
  disabled?: boolean;
  info?: string;
  precision?: number;
}
export default function MedicationOptionWithInput({
  label,
  field,
  value,
  valueToDisplay,
  unit,
  onChange,
  disabled,
  info,
  precision,
}: Props) {
  const [editing, setEditing] = useState(false);
  const focusedStates = {
    [field]: false,
  };
  const [isFocused, setIsFocused] = useState(focusedStates);
  const [lastFocused, setLastFocused] = useState(focusedStates);
  const formik = useFormik({
    initialValues: {
      [field]: value || "",
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const handleChange = (val: number) => {
    formik.setFieldValue(field, val);
    onChange(field, val);
  };
  const onEditing = () => {
    setEditing(true);
    setTimeout(() => {
      document.getElementById(`${field}`)?.focus();
    }, 100);
  };
  const onSave = () => {
    setTimeout(() => {
      setEditing(false);
    }, 300);
  };
  const onClickEditing = () => {
    !disabled && !editing && onEditing();
  };
  return (
    <div className="option-block">
      <div className="option-label">{label}</div>
      <div
        className={disabled ? "option-input cursor-disabled" : "option-input"}
        onClick={onClickEditing}
      >
        {!disabled && editing ? (
          <>
            <div className="dose-input">
              <FloatingLabelInput
                label={""}
                isNumber
                nameForInput={field}
                value={formik.values[field]}
                maxValue={!precision ? 999 : 99999}
                minValue={0}
                maxLength={!precision ? 3 : 5}
                onChange={(e: any) => handleChange(e.target.value)}
                scriptFormik={formik}
                lastFocused={lastFocused}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
                onSave={formik.submitForm}
                isAddNeeded={false}
                step={1}
                precision={precision}
                onBlur={onSave}
              />
            </div>
            {unit ? <span className="pl-2 unit">{unit}</span> : null}
            <span className="action-icon-wrapper edit-mode">
              {info ? <span className="option-info">{info}</span> : null}
              <Tooltip title="Save">
                <SaveFilled className="editIcon" onClick={onSave} />
              </Tooltip>
            </span>
          </>
        ) : (
          <>
            {!isNullOrUndefined(valueToDisplay) ? (
              <span className="option-value">
                {!isNullOrUndefined(valueToDisplay) ? valueToDisplay : value}
              </span>
            ) : (
              <span className="option-value">
                {value}
                <span className="unit">{unit}</span>
              </span>
            )}
            <span className="action-icon-wrapper">
              {info ? <span className="option-info">{info}</span> : null}
              <Tooltip title="Edit">
                <EditOutlinedIcons
                  className={!disabled ? `editIcon` : `disabled editIcon`}
                  onClick={onEditing}
                />
              </Tooltip>
            </span>
          </>
        )}
      </div>
    </div>
  );
}
