import React from "react";
import MailIcon from "../../../assets/icons/Mail.svg";
import AuthenticationPageLeftPane from "../../../components/CenterScreenCard/AuthenticationPageLeftPane";
import { AuthenticationContainer } from "../Authorization.styled";

const UserInvitationSuccess = () => {
  return (
    <AuthenticationPageLeftPane title="Confirm your account" displayLoginLink>
      <AuthenticationContainer>
        <div className="success">
          <img className="success-logo" src={MailIcon} alt="Mail Logo" />
          <h1 className="message">
            Please check you email and click the link to confirm your account.
          </h1>
          <p className="expiry-text">The link expires in 24 hours.</p>
        </div>
      </AuthenticationContainer>
    </AuthenticationPageLeftPane>
  );
};

export default UserInvitationSuccess;
