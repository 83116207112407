import React, { useRef } from "react";
import {
  Insults,
  LabelExtensionContainer,
  Title,
} from "./LabelExtension.styled";
import { Insult } from "../../../../../../../../models/InsultInterface";
import { Tooltip } from "antd";

type Props = {
  title: string;
  insults: Insult[];
  width: number;
  labelPosition: number;
};

const LabelExtension = ({
  title,
  insults,
  width,
  labelPosition,
  ...props
}: Props) => {
  const labelExtensionRef = useRef<HTMLDivElement>(null);

  // This block determines whether to show Expanded div on the top of the Label.
  // If the Expanded div goes out of the viewport hight it shows on the top.
  const labelExtensionHeight = labelExtensionRef.current?.offsetHeight || 0;
  const viewPortHeight = window.innerHeight;
  if (
    labelExtensionHeight + labelPosition + 25 > viewPortHeight &&
    labelExtensionRef.current
  ) {
    labelExtensionRef.current.style.top = `-${labelExtensionHeight - 2}px`;
  } else {
    labelExtensionRef.current && (labelExtensionRef.current.style.top = ``);
  }

  return (
    <>
      <LabelExtensionContainer
        ref={labelExtensionRef}
        className="injury-insult"
      >
        <Title className="title">
          <Tooltip title={title}>
            <span>{title}</span>
          </Tooltip>
        </Title>
        <ul>
          {insults &&
            insults.map((insult) => (
              <Insults
                className="insults"
                color={insult.Category?.Color || "#fff"}
              >
                <span className="insult-label">
                  <Tooltip title={insult.Label}>{insult.Label}</Tooltip>
                </span>
              </Insults>
            ))}
        </ul>
      </LabelExtensionContainer>
    </>
  );
};

export default LabelExtension;
