import { ArrowRightOutlined } from "@ant-design/icons";
import * as React from "react";
import Drawer from "../../../../components/Drawer/Drawer";
import { Media } from "../../../../models/MediaInterface";
interface Props {
  viewDetailsModal: boolean;
  onClose: any;
  media: Media;
}

const ViewMediaDetails = ({ media, viewDetailsModal, onClose }: Props) => {
  return (
    <Drawer
      className="custom-css-ant-drawer"
      visible={viewDetailsModal}
      onClose={onClose}
      closable
      width="600"
    >
      <h1>
        <ArrowRightOutlined style={{ marginRight: "20px" }} onClick={onClose} />{" "}
        {media.Name}{" "}
      </h1>
      {media.Url ? (
        <img src={media.Url} alt={media.Name} style={{ width: "100%" }} />
      ) : null}
    </Drawer>
  );
};
export default ViewMediaDetails;
