import * as React from "react";
import { Block, CasualtyStatus } from "../../../../models/ScriptInterfaces";
import {
  Container,
  FixedDiv,
  ScrollableDiv,
} from "../../../../styles/EditComponentTheme.styled";
import { StyledForm } from "./../EditForms/PhaseForm/PhaseForm.styled";
import LevelOfConsciousnessEdit from "./RightEditPane/LevelOfConsciousnessEdit";
import { StyledFormContainer } from "./RightEditPane/RightEditPanel.styled";
import "./casualtyStatus.scss";
import MarchTableEdit from "./RightEditPane/MarchTableEdit/MarchTableEdit";
import GlasgowComaScaleEdit from "./RightEditPane/GlasgowComaScaleEdit";
import DescriptionEdit from "./RightEditPane/DescriptionEdit";
import VitalsTableEdit from "./RightEditPane/VitalsTableEdit/VitalTableEdit";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../components/ConfirmDialog/useConfirmDialog";
import { IconColumn } from "../IconColumn";
import { useDebounce } from "../../../../hooks/useDebounce";
import { useEffect } from "react";
import { isNullOrUndefined } from "is-what";

export interface CasualtyStatusBlock extends Block {
  BlockContent: CasualtyStatus;
}

interface Props {
  object: CasualtyStatusBlock;
  currentPhaseIndex: number;
  onChange: Function;
  onSave: Function;
  onDelete: Function;
  onCopy: Function;
}
const CasualtyStatusEdit = (props: Props) => {
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const onDeleteConfirm = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "Are you sure delete this Block?",
      content: "This cannot be undone",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        props.onDelete(props.object);
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        console.log("Cancel");
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };

  // Handles save and update calls.
  const debounceOnSave = useDebounce(props.onSave);

  useEffect(() => {
    debounceOnSave();

    return () => {
      debounceOnSave.cancel();
    };
  }, [props.object.BlockContent]);

  return (
    <Container>
      <FixedDiv phase={false}>
        <IconColumn type="casualty_status" onDeleteClick={onDeleteConfirm} />
      </FixedDiv>

      {!isNullOrUndefined(props.object) &&
        !isNullOrUndefined(props.object.BlockContent) && (
          <ScrollableDiv
            id="scrollableDiv"
            phase={false}
            style={{ height: `calc(100vh - 122px)` }}
          >
            <StyledFormContainer key={props.object.BlockContent.Id}>
              <StyledForm>
                <DescriptionEdit
                  value={props.object.BlockContent.Description}
                  onChange={props.onChange}
                />
                <LevelOfConsciousnessEdit
                  value={props.object.BlockContent.LevelOfConsciousness}
                  visible={
                    props.object.BlockContent.LevelOfConsciousnessIsDisplayed
                  }
                  onChange={props.onChange}
                />
                <GlasgowComaScaleEdit
                  blockContent={props.object.BlockContent}
                  visible={
                    props.object.BlockContent.GlasgowComaScaleIsDisplayed
                  }
                  onChange={props.onChange}
                />
                <VitalsTableEdit
                  vitals={props.object.BlockContent.Vitals}
                  visible={props.object.BlockContent.IsVitalDisplayed}
                  onChange={props.onChange}
                />
                <MarchTableEdit
                  blockContent={props.object.BlockContent}
                  visible={props.object.BlockContent.MARCHIsDisplayed}
                  onChange={props.onChange}
                />
              </StyledForm>
            </StyledFormContainer>
          </ScrollableDiv>
        )}
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </Container>
  );
};
export default CasualtyStatusEdit;
