import React from "react";
import FloatingLabelInput from "../../../../components/FloatingLabel/FloatingLabelInput";
import { FormRow } from "../../User.styled";
type Props = {
  formik: any;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
  handleChange: Function;
};
const fieldName = "NRC";
export default function NRCInput({
  formik,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
  handleChange,
}: Props) {
  return (
    <FormRow>
      <FloatingLabelInput
        label={"National Registry Certification (NRC) #"}
        nameForInput={fieldName}
        maxValue={9999999999}
        minValue={0}
        maxLength={10}
        value={formik.values[fieldName]}
        onChange={(e: any) => handleChange(fieldName, e.target.value)}
        scriptFormik={formik}
        setIsFocused={setIsFocused}
        isFocused={isFocused}
        lastFocused={lastFocused}
        setLastFocused={setLastFocused}
        isAddNeeded={false}
        step={1}
        precision={0}
        isNumber
        disabled
      />
    </FormRow>
  );
}
