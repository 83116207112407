import {
  CommentOutlined,
  FlagOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Row, Col } from "antd";
import React from "react";
import { SubTaskAAR } from "../../../../../../models/AfterActionReviewInterfaces";
import TimedActionBlockSubtask from "../TimedActionBlockSubtask/TimedActionBlockSubtask";
import { TimedActionDescription } from "./TimedActionBlockDescription.styled";

type Props = {
  title: string;
  subTasks: SubTaskAAR[];
  IsCommentPresent: boolean;
  IsMediaPresent: boolean;
  IsFlagged: boolean;
};

const TimedActionBlockDescription = ({
  title,
  subTasks,
  IsCommentPresent,
  IsMediaPresent,
  IsFlagged,
}: Props) => {
  return (
    <TimedActionDescription>
      <Row style={{ width: "-webkit-fill-available" }}>
        <Col
          style={{ display: "inline-flex", alignItems: "center" }}
          flex={"auto"}
        >
          <TimedActionBlockSubtask
            total={(subTasks && subTasks.length) || 0}
            failed={
              (subTasks &&
                subTasks.filter((s) => s.IsCompleted === false).length) ||
              0
            }
          />
          <p className="descriptionProperty">{title}</p>
        </Col>
        <Col className="icon-col">
          <Row>
            <VideoCameraOutlined
              className={IsMediaPresent ? "media-present" : "disable-icon"}
            />
          </Row>
          <Row>
            <CommentOutlined
              className={IsCommentPresent ? "comments-present" : "disable-icon"}
            />
          </Row>
          <Row>
            <FlagOutlined
              className={IsFlagged ? "flag-enabled" : "disable-icon"}
            />
          </Row>
        </Col>
      </Row>
    </TimedActionDescription>
  );
};

export default TimedActionBlockDescription;
