import styled from "styled-components";
import { colorPalette } from "../../../styles/MainList.styled";

export const CreateCompanyContainer = styled.div`
  .message-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .message-heading,
    .email {
      color: ${colorPalette.primary100};
    }

    .message {
      color: ${colorPalette.white};
      padding-left: 20px;
    }
  }
`;
