import * as React from "react";
import styled from "styled-components";
import { StyledDivider } from "./DetailsPanelComponents.styled";

interface CommentProps {
  author: string;
  date: string;
  comment: string;
}

export const ScenarioComment = (props: CommentProps) => {
  const { author, date, comment } = props;
  return (
    <div className="comment-div">
      <span className="comment-author-avatar"></span>
      <div className="comment-info">
        <p className="comment-author">
          <span>{author}</span> <span className="comment-date">{date}</span>
        </p>
        <div className="comment">
          <p>
            {comment}
            <StyledDivider />
          </p>
        </div>
      </div>
    </div>
  );
};
