import styled from "styled-components";

export const Human2DModelContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .human-img {
    min-width: 580px;
    height: 100%;
  }
`;
