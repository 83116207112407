import { Row, Spin } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { GetScriptAAR } from "../../../api/ScriptAAR/ApiGet";
import { NavigationTitle } from "../../../components/GlobalStyledComponents.styled";
import {
  ScriptAAR,
  TaskAAR,
} from "../../../models/AfterActionReviewInterfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  AfterActionReviewDiv,
  SectionCol,
  TabStyle,
  VitalsCol,
} from "./AfterActionReview.styled";
import Section from "./Components/Section/Section";
import TimedActionBlock from "./Components/TimedActionBlock/TimedActionBlock";
import VitalsBlock from "./Components/Vitals/VitalsBlock";
import {
  setPhaseAARs,
  setAfterActionReviewToInitial,
  setVitalsAARInitial,
  resetActiveBlockId as resetActiveTaskId,
} from "./AfterActionReviewSlice";
import { VitalType } from "../../../models/Enums";

const AfterActionReview = () => {
  const dispatch = useAppDispatch();
  const { PhaseAARs, VitalsAAR } = useAppSelector(
    (state) => state.AfterActionReviewReducer,
  );

  const [loading, setLoading] = React.useState<boolean>(false);
  const urlParam: any = useParams();

  const onClickPhase = () => {
    dispatch(resetActiveTaskId());
    dispatch(setVitalsAARInitial());
  };

  useEffect(() => {
    // Fetch scriptAAR using scriptAARId.
    setLoading(true);

    urlParam &&
      GetScriptAAR(
        urlParam.id,
        (data: ScriptAAR) => {
          dispatch(setPhaseAARs(data.PhaseAARs));
          setLoading(false);
        },
        (err: any) => {
          console.error(err);
          setLoading(false);
        },
      );

    return () => {
      dispatch(setAfterActionReviewToInitial());
    };
  }, []);

  return (
    <>
      <NavigationTitle className="script-library-title">
        After Action Review
      </NavigationTitle>
      <Spin className="spin" spinning={loading}>
        <AfterActionReviewDiv>
          <Row>
            <SectionCol span={22} className="mainSectionColumn">
              <TabStyle
                hideAdd
                onTabClick={onClickPhase}
                className="mainSectionColumnFirstChild"
              >
                {PhaseAARs &&
                  PhaseAARs.map((PhaseAAR) => (
                    <TabPane
                      key={PhaseAAR.Id}
                      tab={PhaseAAR.TitleAbbreviated}
                      closable={false}
                    >
                      <div
                        style={{
                          height: "calc(100vh - 123px)",
                          marginTop: "1px",
                        }}
                      >
                        {PhaseAAR.SectionAARs &&
                          PhaseAAR.SectionAARs.map(
                            (SectionAAR, SectionAARIndex) => (
                              <Section
                                index={SectionAARIndex + 1}
                                title={SectionAAR.Title}
                              >
                                {SectionAAR.TaskAARs &&
                                  SectionAAR.TaskAARs.map(
                                    (TaskAAR: TaskAAR) => (
                                      <>
                                        <TimedActionBlock content={TaskAAR} />
                                      </>
                                    ),
                                  )}
                              </Section>
                            ),
                          )}
                      </div>
                    </TabPane>
                  ))}
              </TabStyle>
            </SectionCol>
            <VitalsCol span={2}>
              <div style={{ height: "calc(100vh - 70px)" }}>
                <VitalsBlock
                  vitalsType={VitalType.Pain}
                  title="PAIN"
                  unit="INDEX"
                  value1={VitalsAAR.PainVisualAnalogueScale}
                />
                <VitalsBlock
                  vitalsType={VitalType.SI}
                  title="SHOCK"
                  unit="INDEX"
                  value1={
                    VitalsAAR.HeartRate / VitalsAAR.SystolicArterialPressure ||
                    0
                  }
                />
                <VitalsBlock
                  vitalsType={VitalType.HR}
                  title="HR"
                  unit="BPM"
                  value1={VitalsAAR.HeartRate}
                />
                <VitalsBlock
                  vitalsType={VitalType.BP}
                  title="BP"
                  unit="mmHG"
                  value1={VitalsAAR.SystolicArterialPressure}
                  value2={VitalsAAR.DiastolicArterialPressure}
                />
                <VitalsBlock
                  vitalsType={VitalType.MAP}
                  title="MAP"
                  unit="mmHG"
                  value1={VitalsAAR.MeanArterialPressure}
                />
                <VitalsBlock
                  vitalsType={VitalType.RR}
                  title="RESP"
                  unit="rpm"
                  value1={VitalsAAR.RespirationRate}
                />
                <VitalsBlock
                  vitalsType={VitalType.SpO2}
                  title="SPO2"
                  unit="%"
                  value1={VitalsAAR.OxygenSaturation}
                />
                <VitalsBlock
                  vitalsType={VitalType.EtCO2}
                  title="ETCO2"
                  unit="%"
                  value1={VitalsAAR.EndTidalCarbonDioxidePressure}
                />
                <VitalsBlock
                  vitalsType={VitalType.Temp}
                  title="TEMP"
                  unit={<>&#8457;</>}
                  value1={VitalsAAR.CoreTemperature}
                />
                <VitalsBlock
                  vitalsType={VitalType.UO}
                  title="UO"
                  unit="ml/hr"
                  value1={VitalsAAR.MeanUrineOutput}
                />
              </div>
            </VitalsCol>
          </Row>
        </AfterActionReviewDiv>
      </Spin>
    </>
  );
};

export default AfterActionReview;
