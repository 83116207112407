import React, { useRef, useState, useEffect } from "react";
import { LabelData } from "../../../../../../../models/LabelOverlayInterfaces";
import { LabelContainerDiv, LabelDiv } from "./Label.styled";
import LabelExtension from "./LabelExtention/LabelExtention";
import { Tooltip } from "antd";

type Props = {
  label: LabelData;
};

const Label = ({ label, ...props }: Props) => {
  const { title, color, insults } = label;
  const [showTooltip, setShowTooltip] = useState<boolean | null>(null);
  const textRef = useRef<HTMLSpanElement | null>(null);

  const handleWindowResize = () => {
    if (textRef.current) {
      const hasEllipsis =
        textRef.current.scrollWidth > textRef.current.clientWidth;
      setShowTooltip(hasEllipsis);
    }
  };
  useEffect(() => {
    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [title]);

  const [isLabelExtensionVisible, setIsLabelExtensionVisible] = useState(false);
  const labelRef = useRef<HTMLDivElement>(null);

  return (
    <LabelContainerDiv>
      <LabelDiv
        onClick={() => setIsLabelExtensionVisible((prev) => !prev)}
        ref={labelRef}
        color={color}
        isLabelClickable={label.insults.length > 0}
        className="label"
      >
        <Tooltip
          placement="right"
          title={showTooltip ? title.toUpperCase() : ""}
        >
          <span ref={textRef}>{title}</span>
        </Tooltip>
      </LabelDiv>
      {insults && insults.length > 0 && (
        <LabelExtension
          title={title}
          labelPosition={labelRef.current?.getBoundingClientRect().top || 0}
          insults={insults}
          width={labelRef.current?.clientWidth || 0}
        />
      )}
    </LabelContainerDiv>
  );
};

export default Label;
