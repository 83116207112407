import styled from "styled-components";

export const RadioButtonWrapper = styled.div`
  padding: 1rem 0;

  .ant-radio-wrapper {
    font-size: 1.25rem;
    transition: all 0.3s;
    &:not(:first-child) {
      margin-left: 2rem;
    }
    &.ant-radio-wrapper-checked {
      color: #fff;
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: #23d0eb;
      background: #052125;
    }
    .ant-radio-inner {
      width: 18px;
      height: 18px;
      border-radius: 0;
      border-color: #979797;
      &::after {
        opacity: 0;
      }
    }
    &:hover {
      color: #fff;
      .ant-radio-inner {
        border-color: #fff;
      }
      .ant-radio-checked .ant-radio-inner {
        border-color: #23d0eb;
      }
    }
  }
`;
export const CheckBoxWrapper = styled.div`
  padding: 1rem 0;

  .ant-checkbox-wrapper {
    font-size: 1.25rem;
    &:not(:first-child) {
      margin-left: 2rem;
    }
    &:hover {
      color: #fff;
      .ant-checkbox-inner {
        border-color: #fff;
      }
    }
    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
    }
  }
`;

export const AddOptionToListBlock = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 0.625rem 0;

  & > div:nth-child(1) {
    width: 91%;
  }

  & > div:nth-child(2) {
    min-width: 35px;
    width: 9%;
  }

  .addIcon {
    height: 60px;
    width: 9% !important;
    display: flex;
    align-items: center;
    font-size: clamp(1rem, 24px, 1.4rem);
    justify-content: center;
    padding-top: 0.5rem;
    color: rgba(112, 112, 112, 255);
    z-index: 10;
    cursor: pointer;

    &:hover {
      color: #fff;
    }
  }
`;
