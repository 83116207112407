import React, { ReactNode } from "react";
import { SectionDivStyle, SectionLabel } from "./Section.styled";

type Props = {
  index: number;
  title?: string;
  children?: ReactNode;
};

const a = true;
const Section = ({ title, index, children, ...props }: Props) => {
  return (
    <SectionDivStyle>
      <SectionLabel>
        <div className="section-index-title">
          <div className="hidden-drag-icon"></div>
          <p className="section-number">Section {index}</p>
        </div>
        <div className="section-header-text">
          <div className="section-drag-icon"></div>
          <h1 className="SectionHeading">{title || "New Section"}</h1>
        </div>
      </SectionLabel>
      {children}
    </SectionDivStyle>
  );
};

export default Section;
