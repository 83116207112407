import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
  },
};

interface PieChartDataset {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
}

interface PieChartData {
  labels: string[];
  datasets: PieChartDataset[];
}

interface Props {
  data: PieChartData;
}
export default function CustomPieChart({ data }: Props) {
  return <Pie data={data} options={options} />;
}
