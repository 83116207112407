import * as React from "react";
import { Casualty } from "../../../models/CasualtyInterfaces";
import { Container } from "../../../styles/EditComponentTheme.styled";
import { TitleColumn } from "./EditFormTheme";
import CasualtyForm from "./EditForms/CasualtyFoms/CasualtyForm";
import { getAllAllergies } from "../../../api/Allergy/ApiGet";
import { AlertType } from "../../../models/Enums";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";
import { IconColumn } from "./IconColumn";

interface Props {
  object: Casualty;
  sectionTitle: string;
  position: number;
  onChange: Function;
  onSave: any; //Function,
  onDelete: any; //Function,
  setAlertMessage: any;
  postSuccess: any;
}

const CasualtyEdit = (props: Props) => {
  const [allergiesData, setAllergiesData] = React.useState<any>([]);
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const getAllAllergiesSuccess = (data: any) => {
    setAllergiesData(data);
  };

  const error = (data: any) => {
    //display the error method
    props.setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An Error Occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
  };

  React.useEffect(() => {
    getAllAllergies(getAllAllergiesSuccess, error);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteConfirm = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "Are you sure delete this Casualty?",
      content: "This cannot be undone",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        props.onDelete(props.object);
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        setIsOpenConfirmDialog(false);
        console.log("Cancel");
      },
      type: "error",
    });
  };

  return (
    <Container>
      <IconColumn type="casualty" onDeleteClick={onDeleteConfirm} />

      <TitleColumn
        display="Section"
        title={props.sectionTitle}
        position={props.position}
      />

      <CasualtyForm
        allergiesData={allergiesData}
        setAlertMessage={props.setAlertMessage}
        postSuccess={props.postSuccess}
      />
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </Container>
  );
};
export default CasualtyEdit;
