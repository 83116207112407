import { Col } from "antd";
import styled from "styled-components";

export const StyledCol = styled(Col)`
  height: -webkit-fill-available;
  overflow: auto;
  background: #161616;
`;
export const InputWrapper = styled.div`
  width: 50%;
`;
export const QueryBuilderWrapper = styled.div`
  height: calc(100% - 48px - 67px);
  overflow: auto;
  padding: 1.5rem;
  background: #131313;
`;
export const StyledFooter = styled.div`
  background: #131313;
  border-top: 2px solid rgb(38, 38, 38);
  text-align: center;
  .ant-btn {
    min-width: min(35%, 400px);
    max-height: 65px;
    padding: 0.75rem 2rem;
  }
  .save-btn {
    border: 2px solid rgb(122, 252, 23);
  }
  .disable-save-btn {
    border: 2px solid rgba(255, 255, 255, 0.5);

    &:hover {
      background: none;
    }
  }
`;
export const VisualizationPanel = styled.div`
  background: #161616;
`;
export const VisualizationBlock = styled.div`
  .chart-block {
    height: 300px;
  }
`;
