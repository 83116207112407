import { Col, Modal, Row } from "antd";
import styled from "styled-components";
import { TitleHoverIntent } from "../pages/ScriptBuilder/EditComponents/ScriptDetailsView/TitleHoverIntent";
import { colorPalette, hexTransparencies } from "./MainList.styled";
import "./utils.scss";

interface I_FooterColumn {
  start: boolean;
}

export const MainDiv = styled.div`
  .ant-select-item-option-content {
    font-weight: normal !important;
  }

  .ant-select-dropdown
    .rc-virtual-list-holder-inner
    .ant-select-item-option-selected {
    font-weight: normal !important;
  }
  }
`;

export const FooterRow = styled(Row)``;

export const FooterColumn = styled(Col)<I_FooterColumn>`
  display: flex;
  justify-content: ${({ start }) => (start ? "flex-start" : "flex-end")};
`;

export const RowMessage = styled(Row)`
  margin-bottom: 1.875rem;

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    font-size: 1.25rem;
  }
`;
export const RowInput = styled(Row)`
  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    font-size: 1.25rem;

    Input,
    TextArea,
    Select {
      font-size: 1.25rem;
    }
  }
`;
export const StyledModal = styled(Modal)`
  @media (max-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    margin-left: 50% !important;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints?.xxl}) {
    margin-left: 25% !important;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.xxl}) {
    margin-left: 20% !important;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    margin-left: 25% !important;
    width: 40% !important;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    width: 56% !important;
    margin-top: 10% !important;
    margin-left: 30% !important;
  }
`;
