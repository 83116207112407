import { TableProps } from "antd";
import * as React from "react";
import { Table } from "../../../../components";
interface Props {
  dataSource: any;
  columns: any;
  onDetails: Function;
}

const MediaTable = ({ dataSource, columns, onDetails }: Props) => {
  const onChange: TableProps<any>["onChange"] = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    console.log("params", pagination, filters, sorter, extra);
  };
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      rowKey={(record: any) => record.Id}
      rowClassName={() => "rxblackBG"}
      showSorterTooltip={false}
      pagination={{
        hideOnSinglePage: true,
        pageSize: dataSource.length,
      }}
      onChange={onChange}
      sortDirections={["ascend", "descend", "ascend"]}
      onRow={(record: any) => {
        return {
          onClick: () => {
            onDetails(record);
          }, // click row
          onDoubleClick: () => undefined, // double click row
          onContextMenu: () => undefined, // right button click row
          onMouseEnter: () => undefined, // mouse enter row
          onMouseLeave: () => undefined, // mouse leave row
        };
      }}
    />
  );
};
export default MediaTable;
