import { AutoComplete } from "antd";
import React, { useRef } from "react";
import { SearchOutlinedIcons } from "../../IconsTheme/Icons.global";
import FloatingLabelTextArea from "../FloatingLabel/FloatingLabelTextArea";
import "./../../styles/AutoComplete.scss";

interface Option {
  label: string;
  value: string;
}

interface Props {
  value: string;
  scriptFormik: any;
  handleSearch: Function;
  handleInputSearch: Function;
  onSelect: Function;
  options: Option[];
  isFocused: any;
  lastFocused: any;
  setIsFocused: any;
  setLastFocused: any;
  disabled?: boolean;
}

export default function TextAreaAutocomplete(prop: Props) {
  const ref = useRef(null);
  return (
    <AutoComplete
      className="auto-complete-input auto-complete-textarea"
      onSearch={(value: string) => prop.handleSearch(value)}
      onSelect={(value: string) => prop.onSelect(value)}
      options={prop.options}
      value={prop.value}
      ref={ref}
      disabled={prop?.disabled || false}
    >
      <FloatingLabelTextArea
        label={""}
        placeholder={"Search"}
        textareaName={`search`}
        scriptFormik={prop.scriptFormik}
        value={prop.scriptFormik.values.search}
        onChange={(value: string) => prop.handleInputSearch(value)}
        setIsFocused={prop.setIsFocused}
        setLastFocused={prop.setLastFocused}
        isFocused={prop.isFocused}
        lastFocused={prop.lastFocused}
        autoFocus
        suffixIcon={<SearchOutlinedIcons className="searchIcon" />}
        disabled={prop?.disabled || false}
      />
    </AutoComplete>
  );
}
