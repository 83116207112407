import { DownOutlined, UpOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { TimedActionExpandCollapseDiv } from "./TimedActionExpandCollapse.styled";

type Props = {
  isEnabled: boolean;
  isCollapsed: boolean;
  setIsCollapsed: Function;
};

const TimedActionExpandCollapse = ({
  isCollapsed,
  setIsCollapsed,
  isEnabled,
  ...props
}: Props) => {
  const handleCollapse = () => {
    if (isEnabled) setIsCollapsed((prev: boolean) => !prev);
  };

  return (
    <TimedActionExpandCollapseDiv
      className={isEnabled ? "" : "disabled"}
      onClick={handleCollapse}
    >
      {isCollapsed ? <DownOutlined /> : <UpOutlined />}
    </TimedActionExpandCollapseDiv>
  );
};

export default TimedActionExpandCollapse;
