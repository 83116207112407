import React from "react";
import FloatingLabelInput from "../../../../../../../components/FloatingLabel/FloatingLabelInput";
import useKeyPress from "react-use-keypress";
type Props = {
  label: string;
  field: string;
  maxValue: number;
  minValue: number;
  suffixIcon: any;
  formik: any;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
  step: number;
  precision: number;
  onChange: any;
};
export default function FloatingInputWithStep({
  label,
  field,
  minValue,
  maxValue,
  formik,
  suffixIcon,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
  step,
  precision,
  onChange,
}: Props) {
  // increase value with step on arrow up key press
  useKeyPress("ArrowUp", (e: any) => {
    if (isFocused && isFocused[field]) {
      e.preventDefault();
      const formValue = formik.values[field];
      if (formValue && step && Number(formValue) < Number(maxValue)) {
        const newValue = (Number(formValue) + step).toFixed(precision);
        formik.setFieldValue(field, newValue);
      }
    }
  });

  // decrease value with step on arrow up key press
  useKeyPress("ArrowDown", (e: any) => {
    if (isFocused && isFocused[field]) {
      e.preventDefault();
      const formValue = formik.values[field];
      if (formValue && step && Number(formValue) > Number(minValue)) {
        const newValue = (Number(formValue) - step).toFixed(precision);
        formik.setFieldValue(field, newValue);
      }
    }
  });
  const handleChange = (e: any) => {
    formik.handleChange(e);
    onChange(e);
  };
  return (
    <FloatingLabelInput
      label={label}
      isNumber
      nameForInput={field}
      value={formik.values[field]}
      onChange={handleChange}
      scriptFormik={formik}
      lastFocused={lastFocused}
      setIsFocused={setIsFocused}
      setLastFocused={setLastFocused}
      isFocused={isFocused}
      isAddNeeded={true}
      suffixIcon={suffixIcon ? suffixIcon : ""}
      onSave={formik.submitForm}
      maxValue={maxValue}
      minValue={minValue}
      displayMinMaxRange={true}
      precision={precision}
    />
  );
}
