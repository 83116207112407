import * as React from "react";
import { FooterWithButtons, GreenButton, GreyButton } from "../styled";
import { ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";
import { StyledBody } from "./InjuryTab.styled";
import InjuryItem from "./AppliedCasualtyInjuryList/InjuryItem";
import { InjuryViewTypes } from "../constants";
import CreateNewInsultInjuryBtn from "./AppliedCasualtyInjuryList/CreateNewInsultInjuryButton";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { Injury } from "../../../../../models/InjuryInterface";

type EditInjuryProps = {
  casualtyId: number;
  onBack: Function;
  onSave: Function;
  handleOpenCreateEditInjury: Function;
  onEditInsult: Function;
};

const EditInjury = ({
  casualtyId,
  onBack,
  onSave,
  handleOpenCreateEditInjury,
  onEditInsult,
}: EditInjuryProps) => {
  const injuryObj = useSelector((state: any) => state.InsultInjury.InjuryObj); // injury for create/edit
  const masterInjuryList = useSelector(
    (state: any) => state.InsultInjury.Injuries
  );
  return (
    <>
      <StyledBody
        style={{
          padding: `15px 0px`,
          height: `calc(100vh - 64px - 55px - 62px )`,
        }}
      >
        <InjuryItem
          key={injuryObj.Id}
          casualtyId={casualtyId}
          injury={injuryObj}
          currentView={InjuryViewTypes.EDIT_INJURY}
          onEditInjury={() => undefined}
          onEditInsult={onEditInsult}
          onInjurySelection={() => undefined}
          handleOpenCreateEditInjury={handleOpenCreateEditInjury}
          masterInjury={masterInjuryList.find(
            (x: Injury) => x.Id === injuryObj.CopySourceInjuryId
          )}
        />
        <CreateNewInsultInjuryBtn
          onAddClick={() => handleOpenCreateEditInjury(injuryObj)}
          label="Add New Insult"
        />
      </StyledBody>
      <FooterWithButtons>
        <GreyButton htmlType="button" onClick={() => onBack()}>
          <ArrowLeftOutlined /> &nbsp; Back
        </GreyButton>
        {injuryObj.Insults.length < 1 || !injuryObj.LocationId ? (
          <Tooltip
            title={
              injuryObj.Insults.length < 1
                ? `An Injury Must Contain an Insult!`
                : `Please Select Injury Location!`
            }
            color={`#532424`}
          >
            <GreyButton htmlType="submit" className="disable-btn">
              <SaveOutlined /> &nbsp; Save
            </GreyButton>
          </Tooltip>
        ) : null}
        {injuryObj.Insults.length >= 1 && injuryObj.LocationId ? (
          <GreenButton htmlType="submit" onClick={() => onSave(injuryObj)}>
            <SaveOutlined /> &nbsp; Save
          </GreenButton>
        ) : null}
      </FooterWithButtons>
    </>
  );
};

export default EditInjury;
