import axios from "axios";
import { CreatePhaseWithNumberedTitleAndSectionRequestDtm } from "../../models/common/CreatePhaseWithNumberedTitleAndSectionRequestDtm";
import { PhaseWithRelationsDtm } from "../../models/common/PhaseWithRelations";

export function createPhase(
  phase: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/phase/create`, phase)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function createPhaseWithBlankTitleAndSection(
  requestDtm: CreatePhaseWithNumberedTitleAndSectionRequestDtm,
  callback: (x: PhaseWithRelationsDtm) => Promise<void>,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/phase/CreatePhaseWithBlankTitleAndSection`, requestDtm)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function UpdatePhase(
  phase: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/phase/update`, phase)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function UpdatePhaseOrder(
  phase: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/phase/updateOrder`, phase)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function DeletePhase(
  phaseId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`/api/1/pfc/phase/deleteAndDecrementOrders/` + phaseId)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
