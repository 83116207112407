import { Tabs } from "antd";
import styled from "styled-components";
import {
  colorPalette,
  GrayColorPalette,
  hexTransparencies,
} from "../styles/MainList.styled";

export const NavigationTitle = styled.h1`
  position: absolute;
  bottom: 100%;
  width: calc(100% - 10rem);
  color: #fff;
  text-transform: uppercase;
  z-index: 999;
  height: 64px;
  line-height: 64px;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  & > span.title {
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
  }

  & > span.anticon,
  .arrow-right-anticon {
    color: ${"#ffffff" + hexTransparencies[25]};
    transition: all 0.3s ease-in-out;
  }

  &.script-library-title {
    padding: 0 1rem;
  }

  .cursor-pointer > svg {
    width: 30px;
    margin-left: 0.5rem;
  }

  .title {
    visibility: visible;
    opacity: 1;
  }

  .navigate-to-scriptbuilder {
    color: ${colorPalette.primary100};
    font-size: 1rem;
    text-transform: none;
    margin-left: 2px;
    margin-top: 4px;
    width: 95px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 16.41px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  .show-hover-intent {
    position: absolute !important;
    top: 0;
    width: 100%;
    height: 64px;
    transition: all 10s ease-in-out;
    font-size: clamp(1.4375rem, 1.8vw, 3.4375rem);
    z-index: 999;
    display: none;
    align-items: center;
    background-color: ${GrayColorPalette.Gray6};
    text-transform: capitalize;
    @media only screen and (max-width: ${({ theme }) =>
        theme.breakpoints?.lg}) {
      &.show-hover-intent {
        display: inline-flex;
        transition-delay: 10s;
      }
    }
  }
`;

export const HeaderContainer = styled.span`
{
   display: inline-flex;
   white-space: nowrap;
   overflow: hidden;
   & > .script-title {
       position: relative;
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis !important;
       &.withInput{
         min-width: 20rem;
        }
      & > span.title {
        display: block;
        text-transform: none;
        height: -webkit-fill-available;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis !important;
        &.hideGoToScript{
          min-width: 20rem;
        }
      }
      & > span.anticon,
      .arrow-right-anticon {
        color: ${"#ffffff" + hexTransparencies[25]};
        transition: all 0.3s ease-in-out;
      }

      .cursor-pointer > svg {
        width: 30px;
        margin-left: 0.5rem;
      }

      .title {
        visibility: visible;
        opacity: 1;
      }

      .hide-edit-title {
        visibility: hidden;
        opacity: 0;
      }

      .hide-title {
        visibility: hidden;
        opacity: 0;

        .edit-title {
          visibility: visible;
          opacity: 1;
        }
      }
  }
}`;

export const StyledScriptAction = styled.span`
{
    display: flex;
    margin-left: 8px;
    align-items: center;
    height: 64px;
    visibility: visible;
    opacity: 1;
    .arrow-right-anticon {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &:hover {
        .arrow-right-anticon {
            color: #fff;
        }
        .navigate-to-scriptbuilder {
            visibility: visible;
            opacity: 1;
        }
    }

    .hide-arrow {
        visibility: hidden;
        opacity: 0;
    }

}`;

export const StyledEditTitleInput = styled.span`
  position: absolute;
  top: 0;
  height: 64px;
  width: 100%;
  line-height: 64px;
  display: flex;
  align-items: center;
  text-transform: none;
  & .edit-title-input {
    color: #fff;
    height: 100%;
    padding: 0.15rem 0;
    margin: 0 0;
    line-height: 64px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    border-bottom: 1px solid ${colorPalette.gray50};
    font-size: clamp(1.5375rem, 1.8vw, 3.4375rem);
  }
  & .edit-title-input:focus {
    border-bottom: 1px solid ${colorPalette.primary100};
  }
`;

interface PaneBodyProps {
  nopadding: boolean;
}

export const PaneBody = styled.div.attrs((props: PaneBodyProps) => ({
  nopadding: props.nopadding,
}))`
  flex-grow: 1;
  overflow: auto;
  box-sizing: border-box;
  padding: 0px 15px;
  height: calc(
    100vh - 64px - 50px - 5px
  ); // viewport height - main header height - pane header height - panel border
  ${(props) =>
    props.nopadding &&
    `
    padding: 0 !important;`}
`;

export const PaneTabs = styled(Tabs)`
  &.display-custom-title {
    .ant-tabs-nav-wrap,
    .ant-tabs-nav-operations {
      opacity: 0;
      overflow: hidden !important;
      height: 0;
    }
  }

  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  &.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated {
    transition: none;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ant-tabs-nav-list .ant-tabs-tab {
    border-right: 1px solid ${colorPalette.gray75} !important;
    padding: 12px 18px !important;
    text-transform: uppercase;
  }

  .ant-tabs-nav {
    background-color: ${colorPalette.gray100};
    margin: 0;
    border-bottom: 4px solid ${colorPalette.gray25} !important;

    .ant-tabs-nav-wrap {
      overflow: visible;
    }
  }

  .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-tab {
    border: none !important;
    .ant-tabs-tab-btn {
      color: #999;
    }
    &:after {
      content: " ";
      display: block;
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      height: 4px;
      background-color: ${colorPalette.gray25};
      border-right: 1px solid #000;
    }

    &:hover {
      background-color: ${colorPalette.gray50};

      .ant-tabs-tab-btn {
        color: ${colorPalette.white};
      }

      &:after {
        background-color: #fff;
      }
    }
  }

  &.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    z-index: 1000;
    height: 4px;
    bottom: -4px;
  }

  .ant-tabs-nav-add {
    margin: 4px;
    border: 2px dashed ${colorPalette.gray25} !important;
    border-radius: 50% !important;
    text-transform: bold;
  }

  .ant-tabs-tab .anticon {
    margin-right: 0 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: ${colorPalette.primary25} !important;

    &:hover {
      background-color: ${colorPalette.primary50} !important;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f0f0f0 !important;
  }

  .ant-tabs-tab-btn {
    margin: auto;
  }

  &.casualty-form {
    .ant-tabs-nav-list .ant-tabs-tab {
      padding: 0px !important;

      .ant-btn-link {
        padding: 6.4px 18px;
      }
    }
  }
`;
