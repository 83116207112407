import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MiscellaneousTask,
  UpdateMiscellaneousTask,
} from "../../../../models/MiscellaneousTask";
import { UpdateTimedActionSubTask } from "../../../../models/TimedActionSubTask";
import { Media } from "../../../../models/MediaInterface";

export interface miscellaneousState {
  data: MiscellaneousTask[];
  modalTitle: string;
  addUpdateModal: boolean;
  loading: boolean;
  miscellaneousForUpdate: UpdateMiscellaneousTask;
}

const initialState: miscellaneousState = {
  data: [],
  miscellaneousForUpdate: {
    Subtasks: [] as UpdateTimedActionSubTask[],
    AttachedMedias: [] as Media[],
  } as UpdateMiscellaneousTask,
  modalTitle: "",
  addUpdateModal: false,
  loading: true,
};

export const miscellaneousSlice = createSlice({
  name: "miscellaneous",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<MiscellaneousTask[]>) => {
      state.data = action.payload;
    },
    setModalTitle: (state, action: PayloadAction<string>) => {
      state.modalTitle = action.payload;
    },
    setAddUpdateModal: (state, action: PayloadAction<boolean>) => {
      state.addUpdateModal = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    onDeleteSuccess: (state, action: PayloadAction<number>) => {
      state.data = state.data.filter((x) => x.Id !== action.payload);
    },
    onUpdateSuccess: (state, action: PayloadAction<MiscellaneousTask>) => {
      const index = state.data.findIndex((x) => x.Id === action.payload.Id);
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    },
    onArchiveUnarchiveSuccess: (state, action: PayloadAction<number>) => {
      const index = state.data.findIndex((x) => x.Id === action.payload);
      if (index !== -1) {
        state.data[index].IsArchived = !state.data[index].IsArchived;
      }
    },
    onCreateSuccess: (state, action: PayloadAction<MiscellaneousTask>) => {
      state.data.unshift(action.payload);
    },

    resetMiscellaneousForUpdate: (state, action: PayloadAction<null>) => {
      state.miscellaneousForUpdate = {} as UpdateMiscellaneousTask;
    },
    setMiscellaneousForUpdate: (
      state,
      action: PayloadAction<UpdateMiscellaneousTask>,
    ) => {
      state.miscellaneousForUpdate = action.payload;
    },
  },
});

export default miscellaneousSlice.reducer;

export const {
  setData,
  setModalTitle,
  setAddUpdateModal,
  setLoading,
  onUpdateSuccess,
  onCreateSuccess,
  onDeleteSuccess,
  onArchiveUnarchiveSuccess,
  setMiscellaneousForUpdate,
  resetMiscellaneousForUpdate,
} = miscellaneousSlice.actions;
