import axios from "axios";

export function CreateAllergy(
  allergy: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/allergy/Create`, allergy)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function UpdateAllergy(
  allergy: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/allergy/update`, allergy)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function DeleteAllergy(
  allergyId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`/api/1/pfc/allergy/deleteAllergy/${allergyId}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
