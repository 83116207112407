import * as React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";

const StyledButton = styled(Button)`
  border: none;
  height: 46px;
`;

const TabButton = (props: ButtonProps) => {
  const { children } = props;
  return (
    <StyledButton {...props} size="large" type="link">
      {children}
    </StyledButton>
  );
};

export default TabButton;
