import { Col, Select } from "antd";
import React, { useLayoutEffect, useState } from "react";
import FloatingLabelSelect from "../../../../../../../components/MuiSelect/FloatingLabelSelect";
import SwitchWithLabel from "../../../../../../../components/Switch/SwitchWithLabel";
import {
  BooleanParameter,
  InsultWithRelation,
} from "../../../../../../../models/InsultInterface";
import { BooleanParameterWithSwitch } from "../../CommonData";
import { StyledFormRow } from "../../InjuryTab.styled";
const { Option } = Select;
type BooleanParameterFormProps = {
  formik: any;
  insult: InsultWithRelation;
  param: BooleanParameter;
};

const BooleanParameterForm = ({
  formik,
  insult,
  param,
}: BooleanParameterFormProps) => {
  const [hideBooleanParameter, setHideBooleanParameter] = useState(false);

  useLayoutEffect(() => {
    // Hide circumferential boolean input if selected location body part is not Arm or Leg for Burn insult.
    if (insult.Label?.toLocaleLowerCase() === "burn") {
      if (
        param.UserFacingLabel?.toLocaleLowerCase() === "circumferential" &&
        (formik.values["bodyPartId"] === 3 || // bodyPartId = 3 Arm
          formik.values["bodyPartId"] === 8) // bodyPartId = 8 Leg
      ) {
        setHideBooleanParameter(false);
      } else {
        handleToggle(false, param.Id); // Reset boolean input value.
        setHideBooleanParameter(true);
      }
    }
  }, [formik.values["bodyPartId"]]);

  const handleToggle = (value: boolean, BooleanParamId: number) => {
    if (
      param.UserFacingLabel?.toLocaleLowerCase() === "side" &&
      insult.Label?.toLocaleLowerCase() === "tension pneumothorax"
    ) {
      formik.setFieldValue("appliedLocationSide", value ? "Right" : "Left");
    }
    formik.setFieldValue("boolean_param_" + BooleanParamId, value);
  };

  return (
    <>
      {!hideBooleanParameter && (
        <StyledFormRow align="middle" key={"boolean_param_" + param.Id}>
          {BooleanParameterWithSwitch.findIndex(
            (p) =>
              p.Label === param.UserFacingLabel &&
              (!p.InsultLabel.length || p.InsultLabel.includes(insult.Label)),
          ) !== -1 ? (
            <Col span={20} sm={24} md={16} lg={12}>
              <SwitchWithLabel
                leftLabel={param.UserFacingLabel}
                checked={formik.values["boolean_param_" + param.Id]}
                onChange={() =>
                  handleToggle(
                    !formik.values["boolean_param_" + param.Id],
                    param.Id,
                  )
                }
              />
            </Col>
          ) : (
            <Col span={12} sm={24} md={12} lg={8}>
              <FloatingLabelSelect
                label={param.UserFacingLabel}
                className="w-100"
                onChange={(value) => handleToggle(value, param.Id)}
                value={formik.values["boolean_param_" + param.Id]}
              >
                <Option title="" value={false}>
                  {param.FalseUserFacingText}
                </Option>
                <Option title="" value={true}>
                  {param.TrueUserFacingText}
                </Option>
              </FloatingLabelSelect>
            </Col>
          )}
        </StyledFormRow>
      )}
    </>
  );
};

export default BooleanParameterForm;
