export const defaultErrorCallback = (error: any) => {
  // eslint-disable-next-line no-prototype-builtins
  if (error.hasOwnProperty("response")) {
    // eslint-disable-next-line no-prototype-builtins
    if (error.response.hasOwnProperty("data")) {
      console.error(error.response.data);
      if (typeof error.response.data === "object") {
        const keys: Array<string> = Object.values(error.response.data);
        for (let index = 0; index < keys.length; index++) {
          console.log(keys[index]);
        }
      }
    } else {
      console.error(error.response);
    }
  } else {
    console.error(error);
  }
};
