import React from "react";
import Team from "./Team";
import {
  ContentWrap,
  DetailCardBody,
  DetailCardHeader,
  DetailCardWrapper,
} from "./Teams.styled";
import { useAppSelector } from "../../../../store/hooks";

type Props = {
  children?: React.ReactNode;
};

const TeamsData = [
  {
    UnitLabel: "Unit",
    MosName: "MOS Name",
    Team: ["Team Name", "Team Name"],
  },
  {
    UnitLabel: "Unit",
    MosName: "MOS Name",
    Team: ["Team Name"],
  },
];

export default function Teams({ children }: Props) {
  return (
    <DetailCardWrapper>
      <ContentWrap>
        <DetailCardHeader>
          <h3>My Teams</h3>
        </DetailCardHeader>
        <DetailCardBody>
          {TeamsData.map((team) => {
            return <Team data={team} />;
          })}
        </DetailCardBody>
      </ContentWrap>
    </DetailCardWrapper>
  );
}
