import * as React from "react";
import { StyledButton } from "./Button.styled";

const Button = (props: any) => {
  const { children, leftIcon, rightIcon } = props;
  return (
    <StyledButton {...props}>
      {leftIcon} {children} {rightIcon}
    </StyledButton>
  );
};

export default Button;
