import { isNullOrUndefined } from "../utils/utilityfunctions";

// Formatting a user's first name and last name to last name, first name
export function formatName(name: string) {
  //console.log("name", name);
  if (!isNullOrUndefined(name)) {
    const newName = name.split(" ");
    // If user has a middle name we check for the last split
    return `${newName[newName.length - 1]}, ${newName[0]}`;
  } else {
    return "";
  }
}

// (XXX) XXX-XXXX format
export function formatPhoneNumber(phone: string) {
  const clean = ("" + phone).replace(/\D/g, "");
  if (clean.length < 4) return clean;
  if (clean.length < 7) return `(${clean.slice(0, 3)}) ${clean.slice(3)}`;
  return `(${clean.slice(0, 3)}) ${clean.slice(3, 6)}-${clean.slice(6, 10)}`;
}
