import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { FormikErrorType } from "../../../../models/common/FormikErrorType";
import UserNameInput from "./UserNameInput";
import FirstLastNameInput from "./FirstLastNameInput";
import CompanyNameInput from "./CompanyNameInput";
import TitleInput from "./TitleInput";
import ShortBioInput from "./ShortBioInput";
import YearsOfExperienceInput from "./YearsOfExperienceInput";
import NRCInput from "./NRCInput";
import { StyledUserForm } from "../../User.styled";
import { PaneBody } from "../../../ScriptBuilder/EditComponents/ScriptDetailsView/styled";
import ProfileImage from "./ProfileImage";
import NRCExpirationDate from "./NRCExpirationDate";
import { getUserProfile } from "../../../../api/User-Profile/ApiGet";
import User from "../../../../models/User";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import { UpdateUserProfile } from "../../../../api/User-Profile/ApiPost";
import produce from "immer";
import PhoneInput from "./PhoneInput";
import { UpdateCompanyLogo } from "../../../../api/Company/ApiPost";
import { UpdateCompany } from "../../../../api/Company/ApiPost";
import { Media } from "../../../../models/MediaInterface";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/hooks";
import { DeleteCompanyLogo } from "../../../../api/Company/ApiDelete";
import { setCompany, setUser } from "../../../../store/authSlice";
import { Company } from "../../../../models/Company";
interface CompanyData {
  id: string;
  name: string;
  logoImageMedia: Media | null;
}
const defaultCompanyData: CompanyData = {
  id: "",
  name: "",
  logoImageMedia: null,
};
export default function UserForm() {
  const dispatch = useDispatch();
  const focusedStates = {
    username: false,
    password: false,
    firstName: false,
    lastName: false,
    company: false,
    title: false,
    phone: false,
    shortBio: false,
    yearsOfExperience: false,
    NRC: false,
    NRCExpirationDate: false,
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companyData, setCompanyData] =
    useState<CompanyData>(defaultCompanyData);
  const { user, company } = useAppSelector((state) => state.authReducer);
  const formik = useFormik({
    initialValues: {
      Id: 0,
      username: "",
      firstName: "",
      lastName: "",
      company: "",
      title: "",
      phone: "",
      shortBio: "",
      yearsOfExperience: 0,
      NRC: "",
      NRCExpirationDate: "",
    },
    validate: (values) => {
      const errors: FormikErrorType = {};

      // company
      if (values["company"].trim().length === 0) {
        errors["company"] = "Required";
      }
      // phone
      if (values["phone"].trim().length < 10) {
        errors["phone"] = "Min 10 digits required";
      }
      return errors;
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const validateValues = (field: string, value: string) => {
    if (field === "company" && value.trim().length === 0) {
      return false;
    }
    if (field === "phone" && value.trim().length < 10) {
      return false;
    }
    return true;
  };
  const handleChange = (field: string, value: string) => {
    formik.setFieldValue(field, value);
    if (
      ["firstName", "lastName", "company", "title", "phone"].includes(field) &&
      validateValues(field, value)
    ) {
      if (field === "company") {
        updateCompany(value);
      } else {
        const payload = {
          firstName: field == "firstName" ? value : formik.values["firstName"],
          lastName: field == "lastName" ? value : formik.values["lastName"],
          phoneNumber: field == "phone" ? value : formik.values["phone"],
          title: field == "title" ? value : formik.values["title"],
        };
        updateProfile(payload);
      }
    }
  };
  const updateProfile = (payload: any) => {
    UpdateUserProfile(
      payload,
      (response: any) => {
        console.log(response);
      },
      (errorMessage: string) => {
        OpenErrorNotification({ description: errorMessage });
      },
    );
  };
  const updateCompany = (name: string) => {
    UpdateCompany(
      name,
      (response: any) => {
        console.log(response);
      },
      (errorMessage: string) => {
        OpenErrorNotification({ description: errorMessage });
      },
    );
  };
  const updateUserFormikData = (user: User) => {
    formik.setValues(
      {
        Id: 0,
        username: user.Email || "",
        firstName: user.FirstName || "",
        lastName: user.LastName || "",
        company: user?.Company?.Name || "",
        title: user?.Title || "",
        phone: user?.PhoneNumber || "",
        shortBio: "",
        yearsOfExperience: 0,
        NRC: "",
        NRCExpirationDate: "",
      },
      true,
    );
    setCompanyData(
      produce((draft) => {
        draft.id = user?.Company?.Id || "";
        draft.name = user?.Company?.Name || "";
        // draft.logoImage = user?.Company?.Media?.Url || "";
        draft.logoImageMedia = user?.Company?.LogoImageMedia || null;
      }),
    );
  };
  const updateUserProfileURL = (mediaId: number) => {
    if (mediaId) {
      UpdateCompanyLogo(
        mediaId,
        (response: Company) => {
          setCompanyData(
            produce((draft) => {
              draft.logoImageMedia = response.LogoImageMedia || null;
            }),
          );
          dispatch(setCompany(response));
        },
        (errorMessage: string) => {
          OpenErrorNotification({ description: errorMessage });
        },
      );
    }
  };
  const removeCompanyLogo = () => {
    DeleteCompanyLogo(
      (response: any) => {
        setCompanyData(
          produce((draft) => {
            draft.logoImageMedia = response.logoImageMedia || null;
          }),
        );
        dispatch(setCompany({ ...company, LogoImageMedia: {} as Media }));
      },
      (errorMessage: string) => {
        OpenErrorNotification({ description: errorMessage });
      },
    );
  };

  useEffect(() => {
    setIsLoading(true);
    getUserProfile(
      (data: User) => {
        updateUserFormikData(data);
        setIsLoading(false);
      },
      (errorMessage: string) => {
        OpenErrorNotification({ description: errorMessage });
        setIsLoading(false);
      },
    );
  }, []);

  const handleBlur = () => {
    if (company.Name !== formik.values.company) {
      dispatch(setCompany({ ...company, Name: formik.values.company }));
    }
    if (
      user.LastName !== formik.values.lastName ||
      user.FirstName !== formik.values.firstName
    ) {
      dispatch(
        setUser({
          ...user,
          FirstName: formik.values.firstName,
          LastName: formik.values.lastName,
        }),
      );
    }
  };

  return (
    <PaneBody>
      <StyledUserForm onSubmit={formik.handleSubmit}>
        <ProfileImage
          handleChange={updateUserProfileURL}
          removeCompanyLogo={removeCompanyLogo}
          URL={companyData?.logoImageMedia?.Url}
        />

        <UserNameInput
          formik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          handleChange={handleChange}
        />

        <FirstLastNameInput
          formik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <CompanyNameInput
          formik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <TitleInput
          formik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          handleChange={handleChange}
        />

        <PhoneInput
          formik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          handleChange={handleChange}
        />

        <ShortBioInput
          formik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          handleChange={handleChange}
        />

        <YearsOfExperienceInput formik={formik} handleChange={handleChange} />

        <NRCInput
          formik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          handleChange={handleChange}
        />

        <NRCExpirationDate
          formik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          handleChange={handleChange}
        />
      </StyledUserForm>
    </PaneBody>
  );
}
