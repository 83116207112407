import styled from "styled-components";

export const Form = styled.div`
  width: 100%;
  height: auto;
  margin-top: 10px;
  /* border: 2px solid yellow; */
  padding: 0.625rem;
  position: relative;

  .error-label {
    border-bottom: 2px solid red !important;
  }

  input:focus + .label-name {
    border-bottom: 2px solid rgba(128, 128, 128, 255);
  }

  input:focus + .label-name .content-name,
  input:focus + .label-name .error {
    transform: translateY(-25px);
  }

  input:focus + .label-name .content-name {
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  .filled-label {
    border-bottom: 2px solid rgba(128, 128, 128, 255);
  }

  .filled-content {
    transform: translateY(-25px);
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  .filled-error {
    transform: translateY(-25px);
  }

  /* input:valid + .label-name .content-name
  {
    transform: translateY(-25px);
    font-size: 1.6em;
  } */

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    input:focus + .label-name .content-name {
      font-size: 1.3em;
    }
    .filled-content {
      font-size: 1.3em;
    }

    .error {
      font-size: 1.2em;
    }
  }
`;

export const Label = styled.label`
  width: 97%;
  height: 92%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 1px solid red; */
  font-size: 10px;
  position: absolute;
  /* top: 0.625rem; */
  bottom: 0.625rem;
  left: 0.625rem;
  right: 0.625rem;
  /* background-color: blue; */
  pointer-events: none;
  border-bottom: 2px solid rgba(96, 96, 96, 255);
  transition: all 0.3s ease;
`;

export const ErrorSpan = styled.span`
  color: red;
  font-size: 1.4em;
  align-items: flex-start;
  position: absolute;
  bottom: 0px;
  right: 0px;
`;

export const LabelSpan = styled.span`
  color: rgba(112, 112, 112, 255);
  font-size: 2em;
  align-items: flex-end;
  position: absolute;
  bottom: 0px;
  left: 0px;

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 1.8em;
  }
`;
export const Input = styled.input`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 255);
  margin-top: 2px;
  font-size: 1.25rem;
  padding-top: 1.25rem;
  border: none;

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 1.2em;
  }
`;

export const SectionCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  justify-content: center;
  .ant-checkbox-wrapper {
    & > span:last-child {
      font-size: 1rem;
      padding-left: 0.6rem;
      color: #fff;
    }
  }
`;
