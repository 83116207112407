import styled from "styled-components";

export const TimedActionBlockTimeContainer = styled.div`
  min-width: 80px;
  height: 100%;
  border-right: 1px solid #161616;
`;

export const Time = styled.div.attrs((props: any) => ({
  time: props.time,
}))`
  color: #23d0eb;
  font-weight: 400;
  font-size: 1.125rem;
  display: grid;
  justify-content: center;
  position: relative;
  top: 10%;
`;
