import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MedicationCompound } from "../../../../models/ScriptInterfaces";

type initialState = {
  isFocused: {};
  lastFocused: {};
  option: {};
  selectedPhase: string;
  interval: number;
  subtask: string;
  search: string;
  medication1Dosage: number;
  medication2Dosage: number;
  medication1Unit: string;
  medication2Unit: string;
  uploadedMedia: any[];
};

const medicationOptions = {
  IsCritical: false,
  IsRequired: false,
  SubtasksEnabled: false,
  IntervalEnabled: false,
  DurationEnabled: false,
  DefineDurationTimeEnabled: false,
  DefineStartTimeEnabled: false,
  RatingEnabled: false,
  MediaEnabled: false,
  CustomLabelsEnabled: false,
  IntervalTime: 0,
};

const Focused = {
  description: false,
  interval: false,
  subtask: false,
  search: false,
};
const medicationEditInitialState: initialState = {
  isFocused: Focused,
  lastFocused: Focused,
  option: medicationOptions,
  selectedPhase: "",
  interval: 0,
  subtask: "",
  search: "",
  medication1Dosage: 0,
  medication2Dosage: 0,
  medication1Unit: "",
  medication2Unit: "",
  uploadedMedia: [],
};

const MedicationSlice = createSlice({
  name: "Medication",
  initialState: medicationEditInitialState,
  reducers: {
    SetIsFocused: (state, action: PayloadAction<{}>) => {
      console.log("state", state);
      state.isFocused = { ...action.payload };
    },
    SetLastFocused: (state, action: PayloadAction<{}>) => {
      state.lastFocused = { ...action.payload };
    },
    SetOption: (state, action: PayloadAction<{}>) => {
      state.option = { ...action.payload };
    },
    SetSelectedPhase: (state, action: PayloadAction<string>) => {
      state.selectedPhase = action.payload;
    },
    SetSubTask: (state, action: PayloadAction<string>) => {
      state.subtask = action.payload;
    },
    SetInterval: (state, action: PayloadAction<number>) => {
      state.interval = action.payload;
    },
    SetSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    SetMedication1Dosage: (state, action: PayloadAction<number>) => {
      state.medication1Dosage = action.payload;
    },
    SetMedication2Dosage: (state, action: PayloadAction<number>) => {
      state.medication2Dosage = action.payload;
    },
    SetMedication1Unit: (state, action: PayloadAction<string>) => {
      state.medication1Unit = action.payload;
    },
    SetMedication2Unit: (state, action: PayloadAction<string>) => {
      state.medication2Unit = action.payload;
    },
    SetUploadedMedia: (state, action: PayloadAction<any[]>) => {
      state.uploadedMedia = action.payload;
    },
  },
});

export default MedicationSlice.reducer;
export const {
  SetSelectedPhase,
  SetOption,
  SetSubTask,
  SetLastFocused,
  SetInterval,
  SetIsFocused,
  SetUploadedMedia,
  SetMedication1Dosage,
  SetMedication1Unit,
  SetMedication2Unit,
} = MedicationSlice.actions;
