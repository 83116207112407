import styled from "styled-components";
import { Select, Button, Switch, Checkbox } from "antd";
import { colorPalette } from "../../../../../styles/MainList.styled";

const { Option } = Select;

export const StyledForm = styled.form.attrs((props: any) => ({
  isBrief: props.isBrief,
}))`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-top: 8px;

  .titleInput {
    display: flex;
    align-items: flex-end;

    > div {
      width: 100%;
    }
  }

  .phaseTimeEstimateContainer {
    display: flex;
    align-items: flex-end;
    margin-bottom: 35px;

    & > span {
      width: 40%;
    }

    & > div {
      margin-left: 5px;
      width: 25%;
    }

    & > div:first-of-type {
      ::after {
        content: "hrs";
        position: absolute;
        bottom: 2px;
        right: 0.5em;
        transition: all 0.05s ease-in-out;
      }

      :hover::after,
      :focus-within::after {
        right: 0.5em;
      }

      /* handle Firefox (arrows always shown) */
      @supports (-moz-appearance: none) {
        ::after {
          right: 0.5em;
        }
      }
    }

    .intervalMinutesSSuffix {
      ::after {
        content: "min";
        position: absolute;
        bottom: 2px;
        right: 0.5em;
        transition: all 0.05s ease-in-out;
      }

      :hover::after,
      :focus-within::after {
        right: 0.5em;
      }

      /* handle Firefox (arrows always shown) */
      @supports (-moz-appearance: none) {
        ::after {
          right: 0.5em;
        }
      }
    }

    input {
      text-align: center !important;
    }
  }

  .sectionList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: ${colorPalette.gray100};
    margin-bottom: 2px;
    padding: 0;

    &:hover {
      cursor: pointer;
      background: #272727;

      span {
        color: #ffffff;
      }

      .section-drag-icon {
        color: #ffffff;
      }
    }

    & > div {
      padding: 0.5rem;
      color: #ffffff;
      font-size: 1.25rem;

      input.default {
        padding: 0px 1px !important;
      }
    }

    span.section-index {
      width: 40px;
      padding: 0;
      color: #ffffff;
      margin-left: 5px;
      font-size: 1.25rem;
    }

    .section-drag-icon {
      color: ${colorPalette.gray75};
      padding: 0.5rem;
    }
  }

  .ant-select-selection {
    border: 1px solid rgba(255, 255, 255, 0.5) !important;
    background-color: transparent !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: red !important;
    border-color: red !important;
  }

  .sectionTypeText {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 21px;
    display: flex;
    align-items: flex-end;
    color: rgba(255, 255, 255, 0.5);
  }

  .sectionTypeSelect {
    text-transform: capitalize;
  }

  .visibleIcon {
    color: #23d0eb;
  }

  .scriptBrief {
    display: flex;
    width: max-content;
    margin-bottom: 20px;

    &:hover {
      cursor: pointer;

      span {
        color: #ffffff;
      }
    }
  }

  .text {
    margin-left: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: ${(props) =>
      props.isBrief ? "#ffffff" : "rgba(255, 255, 255, 0.5)"};
  }

  .addItemInput {
    display: flex;
    align-items: flex-end;
  }

  .sectionType {
    display: flex;

    & .sectionTypeText {
      width: 30%;
    }

    & .sectionTypeSelect {
      width: 70%;
    }
  }

  .aarToggle {
    padding: 30px 0;
    display: flex;
    width: min-content;

    &:hover {
      cursor: pointer;

      .text {
        color: rgba(255, 255, 255, 1);
        transition: color 0.2s ease-in-out;
      }
    }
  }

  .aarToggle.checked {
    box-shadow: 0 0 0 1px ${colorPalette.primary75};
    background-color: ${colorPalette.primary25};
  }

  .disabledTimedAction {
    &:hover {
      cursor: not-allowed !important;
    }

    cursor: not-allowed !important;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    margin-top: 15px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    margin-top: 12px;
  }
`;

export const StyledSwitch = styled(Switch).attrs((props: any) => ({
  isChecked: props.isChecked,
}))`
  background: ${(props) =>
    props.isChecked ? "#162729" : "rgba(255, 255, 255, 0.1)"};
  box-shadow: ${(props) =>
    props.isChecked
      ? "0 0 0 1px #24D2ED"
      : "0 0 0 1px rgba(255, 255, 255, 0.5)"};
  border-radius: 19px !important;

  &:hover {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
  }

  .ant-switch-handle::before {
    background: ${(props) =>
      props.isChecked ? "#24D2ED" : "rgba(255,255,255, 0.3)"};
  }
`;

export const DisabledTimedAction = styled.div.attrs((props: any) => ({
  isChecked: props.isChecked,
}))`
  display: flex;
  justify-content: space-between;
  cursor: not-allowed !important;

  &:hover {
    cursor: not-allowed !important;
  }
`;

export const SectionTypeSelect = styled(Select)`
  .ant-select-arrow {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .ant-select-selector {
    background: transparent !important;
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
  }

  .ant-checkbox-inner {
    background-color: transparent !important;
  }

  .ant-select-item-option-content {
    width: 100% !important;
    display: flex !important;
    justify-content: space-around !important;
  }
`;

export const SectionTypeOption = styled(Option).attrs((props: any) => ({
  isActive: props.isActive,
}))`
  background: ${(props) =>
    props.isActive ? "#052125 !important" : "#1E1E1E !important"};
`;
export const IconSpan = styled.span.attrs((props: any) => ({
  isType: props.isType,
}))`
  color: ${(props) => (props.isType ? "#24D2ED" : "rgba(255,255,255, 0.3)")};
`;

export const SwitchContainer = styled.div.attrs((props: any) => ({
  isActive: props.isActive,
}))`
  span {
    color: ${(props) =>
      props.isActive ? "rgba(255, 255, 255, 0.5)" : "rgba(255, 255, 255, 1)"};
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    margin-top: 15px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    margin-top: 12px;
  }
`;

export const FormDiv = styled.div`
  width: 100%;
  height: auto;
  margin-top: 10px;
  position: relative;

  .error-label {
    border-bottom: 2px solid red !important;
  }

  input:focus + .label-name {
    border-bottom: 2px solid rgba(128, 128, 128, 255);
  }

  input:focus + .label-name .content-name,
  input:focus + .label-name .error {
    transform: translateY(-25px);
  }

  input:focus + .label-name .content-name {
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  .filled-label {
    border-bottom: 2px solid rgba(128, 128, 128, 255);
  }

  .filled-content {
    transform: translateY(-25px);
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  .filled-error {
    transform: translateY(-25px);
  }

  /* input:valid + .label-name .content-name
  {
    transform: translateY(-25px);
    font-size: 1.6em;
  } */
  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    input:focus + .label-name .content-name {
      font-size: 1.5em;
      color: rgba(128, 128, 128, 255);
    }

    .filled-content {
      font-size: 1.5em;
    }

    .filled-error {
      font-size: 1.1em;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    input:focus + .label-name .content-name {
      font-size: 1.3em;
    }

    .filled-content {
      font-size: 1.3em;
    }

    .error {
      font-size: 1.1em;
    }
  }
`;

export const Label = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 1px solid red; */
  font-size: 10px;
  position: absolute;
  /* top: 0.625rem; */
  bottom: 0;
  left: 0;
  /* right: 0.625rem; */
  /* background-color: blue; */
  pointer-events: none;
  border-bottom: 2px solid rgba(96, 96, 96, 255);
  transition: all 0.3s ease;
`;

export const ErrorSpan = styled.span`
  color: red;
  font-size: 1.4em;
  align-items: flex-start;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const LabelSpan = styled.span`
  color: rgba(112, 112, 112, 255);
  font-size: 2em;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 1.8em;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 255);
  margin-top: 2px;
  font-size: 1.25rem;
  padding-top: 1.25rem;
  border: none;
  padding: 20px 0 5px !important;

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 1.2em;
  }
`;

export const StyledGreyButton = styled(Button)`
  background: #202020;
  border: 1px solid #ffffff;
  color: #ffffff;

  &:hover {
    background: #4d4d4d;
    color: #ffffff;
    border: 1px solid #ffffff;
  }
`;
