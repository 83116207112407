import * as React from "react";
import { Card, Avatar } from "antd";
import { HolderOutlined, CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import GENERIC_AVATAR from "../../assets/img/GENERIC_AVATAR.jpg";
import { AddButton, AuthorNameplate, StyledList } from "./DraggableList.styled";
const { Meta } = Card;

export interface Author {
  nameid: string;
  name: string;
  avatar: string;
  company: string;
}
interface Props {
  authors: Author[];
  disabled: boolean;
  isReadOnly: boolean;
}

const DraggableList = ({ authors, disabled, isReadOnly }: Props) => {
  return (
    <DragDropContext onDragEnd={() => undefined}>
      <Droppable droppableId="selectedAuthorsDroppable">
        {(provided, snapshot) => (
          <StyledList
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              borderBottom: disabled || isReadOnly ? "none" : "1px solid #fff",
              marginBottom: 2,
            }}
          >
            {authors.map((author: Author) => {
              return (
                <Draggable
                  key={author.nameid}
                  draggableId="draggable-0"
                  index={0}
                  isDragDisabled={disabled || isReadOnly}
                >
                  {(provided, snapshot) => (
                    <AuthorNameplate
                      className={disabled || isReadOnly ? "disabled" : ""}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        borderBottom: disabled || isReadOnly ? "none" : "",
                      }}
                    >
                      {disabled ? null : <HolderOutlined />}
                      <Meta
                        avatar={
                          <Avatar
                            size={64}
                            src={author.avatar || GENERIC_AVATAR}
                          />
                        }
                        title={author.name}
                        description={author.company}
                      />
                      {disabled ? null : <CheckOutlined />}
                    </AuthorNameplate>
                  )}
                </Draggable>
              );
            })}
          </StyledList>
        )}
      </Droppable>
      {disabled || isReadOnly ? null : (
        <Droppable droppableId="unselectedAuthorsDroppable">
          {(provided, snapshot) => (
            <StyledList
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ borderTop: "1px solid #fff", marginBottom: "10px" }}
            >
              <Draggable
                key={1}
                draggableId="draggable-1"
                index={0}
                isDragDisabled={disabled}
              >
                {(provided, snapshot) => (
                  <AuthorNameplate
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {disabled ? null : <HolderOutlined />}
                    <Meta
                      avatar={<Avatar size={64} src={GENERIC_AVATAR} />}
                      title="LastName, FirstName"
                      description="Title, Organization"
                    />
                  </AuthorNameplate>
                )}
              </Draggable>
              <Draggable
                key={2}
                draggableId="draggable-2"
                index={1}
                isDragDisabled={disabled}
              >
                {(provided, snapshot) => (
                  <AuthorNameplate
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {disabled ? null : <HolderOutlined />}

                    <Meta
                      avatar={<Avatar size={64} src={GENERIC_AVATAR} />}
                      title="LastName, FirstName"
                      description="Title, Organization"
                    />
                  </AuthorNameplate>
                )}
              </Draggable>
              {disabled ? null : (
                <AddButton shape="circle" size="large" type="dashed">
                  <PlusOutlined />
                </AddButton>
              )}
            </StyledList>
          )}
        </Droppable>
      )}
    </DragDropContext>
  );
};

export default DraggableList;
