import React from "react";
import { ResearchQuestionButton } from "../../../../../models/ScriptInterfaces";
import {
  CircleOutlinedIcons,
  TimerOutlinedIcons,
} from "../../../../../IconsTheme/Icons.global";
import { CheckOutlined } from "@ant-design/icons";
import { StyledWhiteButton } from "./CenterPane.styled";
interface Props {
  question: ResearchQuestionButton;
}
export default function ButtonTypeQuestion(props: Props) {
  return props.question.Start ||
    props.question.Stop ||
    props.question.Rating ? (
    <div className="mb-2 mt-3">
      {props.question.Start ? (
        <StyledWhiteButton
          disabled
          type="link"
          icon={<TimerOutlinedIcons />}
          size="middle"
          onClick={() => undefined}
        >
          Start
        </StyledWhiteButton>
      ) : null}
      {props.question.Stop ? (
        <StyledWhiteButton
          disabled
          type="link"
          icon={<CheckOutlined />}
          size="middle"
          onClick={() => undefined}
        >
          Stop
        </StyledWhiteButton>
      ) : null}

      {props.question.Rating ? (
        <StyledWhiteButton
          disabled
          type="link"
          icon={<CircleOutlinedIcons />}
          size="middle"
          onClick={() => undefined}
        >
          Rating
        </StyledWhiteButton>
      ) : null}
    </div>
  ) : null;
}
