import * as React from "react";
import { Drawer as AntDrawer } from "antd";
import { DrawerProps } from "antd/lib/drawer";
import styled from "styled-components";

const StyledAntDrawer = styled(AntDrawer)`
.ant-drawer-wrapper-body{
  border-left: 2px solid #fff;
}

.ant-drawer-body{

  .details-body{
    padding: 0px 20px 0px 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
 
    .title {
      color: #23D0EB;
    }
  
    p {
      display: inline-flex;
      align-items: center;
    }
  }

  
}

.ant-drawer-body, .ant-drawer-body h1 {
    color: #fff;
  }
}
`;

const Drawer = (props: DrawerProps) => {
  return <StyledAntDrawer {...props} />;
};

export default Drawer;
