import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import RealxLogo from "../../assets/img/REALX_VERTICAL.svg";
import AuthFooter from "../../pages/Authentication/AuthFooter";

interface CenterScreenCardProp {
  title: string;
  displayLoginLink?: boolean;
}

const AuthenticationPageLeftPane = ({
  title,
  displayLoginLink,
  ...props
}: PropsWithChildren<CenterScreenCardProp>) => {
  return (
    <div className="login-register-wrapper login-card">
      <div className="text-center">
        <Link to={`/`}>
          <img src={RealxLogo} alt="Logo" style={{ height: "120px" }} />
        </Link>
        <p className="login-text">{title}</p>
      </div>
      <div className="login-block" data-testid="login-form">
        <div className="login-form">{props.children}</div>
        <AuthFooter displayLoginLink={displayLoginLink} />
      </div>
    </div>
  );
};

export default AuthenticationPageLeftPane;
