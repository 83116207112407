import { Block, Phase, Section } from "../../models/ScriptInterfaces";
import { isNullOrUndefined } from "../../utils/utilityfunctions";
import { v4 as uuidv4 } from "uuid";
import { BlockType } from "../../models/Enums";
import {
  MiscellaneousTaskBlock,
  MiscellaneousTaskBlockContent,
} from "../../models/MiscellaneousTaskBlock";
import {
  DiagnosticTaskBlock,
  DiagnosticTaskBlockContent,
} from "../../models/DiagnosticTaskBlock";
import {
  TreatmentTaskBlock,
  TreatmentTaskBlockContent,
} from "../../models/TreatmentTaskBlock";
import {
  MedicationTaskBlock,
  MedicationTaskBlockContent,
} from "../../models/MedicationTaskBlock";

// returns true if phase has timedActionSection
export const hasTimedActionSection = (phase: Phase) => {
  if (!isNullOrUndefined(phase)) {
    return phase.Sections?.some(
      (section) => section.SectionType === "timed actions",
    );
  } else {
    return false;
  }
};

export const getTimedActionSectionIndex = (phase: Phase): number => {
  return phase.Sections?.findIndex(
    (section) => section.SectionType === "timed actions",
  );
};

export const hasTimedActionBlock = (
  section: Section,
  timedActionTaskBlockUUID: string,
): boolean => {
  if (
    !isNullOrUndefined(section) &&
    !isNullOrUndefined(timedActionTaskBlockUUID)
  ) {
    return section.Blocks?.some(
      (block) => block.UUID === timedActionTaskBlockUUID,
    );
  } else {
    return false;
  }
};

export const phaseHasTimedActionBlock = (
  phase: Phase,
  timedActionTaskBlockUUID: string,
): boolean => {
  if (
    !isNullOrUndefined(phase) &&
    !isNullOrUndefined(timedActionTaskBlockUUID)
  ) {
    return phase.Sections?.some((section: Section) =>
      hasTimedActionBlock(section, timedActionTaskBlockUUID),
    );
  } else {
    return false;
  }
};

export const indexesOfPhasesWithRelatedGhostTimedActionTaskBlock = (
  timedActionTaskContent:
    | TreatmentTaskBlockContent
    | MiscellaneousTaskBlockContent
    | DiagnosticTaskBlockContent
    | MedicationTaskBlockContent,
  phases: Phase[],
): Array<number> => {
  const result: Array<number> = [];
  const createdPhaseIndex = phases.findIndex(
    (phase: Phase) => phase.Id === timedActionTaskContent.CreatedInPhaseId,
  );
  if (createdPhaseIndex !== -1) {
    for (
      let i = createdPhaseIndex + 1;
      i <= createdPhaseIndex + timedActionTaskContent.RequiredInPhaseOffset &&
      i < phases.length;
      i++
    ) {
      result.push(i);
    }
  }
  return result;
};

export const makeSurePhaseHasTimedActionSectionElseAdd = (
  phase: Phase,
): Phase => {
  const newSectionId = uuidv4();
  if (hasTimedActionSection(phase)) {
    return phase;
  } else {
    const newSection: Section = {
      UUID: newSectionId,
      PhaseId: phase?.Id || 0,
      Order: phase?.Sections?.length || 0,
      Title: "",
      Blocks: [],
      Collapsed: false,
      SectionType: "timed actions",
      IsCriticalActionRequired: true,
    };
    phase.Sections?.push(newSection);
    return phase;
  }
};

export const makeSurePhaseHasTimedActionBlockElseAdd = (
  phase: Phase,
  timedActionBlock:
    | MedicationTaskBlock
    | TreatmentTaskBlock
    | DiagnosticTaskBlock
    | MiscellaneousTaskBlock,
): Phase => {
  // const resultingPhase: Phase = makeSurePhaseHasTimedActionSectionElseAdd(phase);
  const newSectionId = uuidv4();
  if (hasTimedActionSection(phase)) {
    console.log("Already has timed action section");
  } else {
    const newSection: Section = {
      UUID: newSectionId,
      Id: 0,
      PhaseId: phase?.Id || 0,
      Order: phase?.Sections?.length || 0,
      Title: "",
      Blocks: [],
      Collapsed: false,
      SectionType: "timed actions",
      IsCriticalActionRequired: true,
    };
    phase.Sections?.push(newSection);
  }
  if (phaseHasTimedActionBlock(phase, timedActionBlock.UUID)) {
    const timedSectionInPhaseIndex = getTimedActionSectionIndex(phase);
    if (timedSectionInPhaseIndex !== -1) {
      const updateSectionBlocksWithNewTimedActionTaskBlock = phase.Sections[
        timedSectionInPhaseIndex
      ]?.Blocks?.map((obj: Block, index: number) => {
        return obj.UUID === timedActionBlock.UUID ? timedActionBlock : obj;
      });
      phase.Sections[timedSectionInPhaseIndex].Blocks =
        updateSectionBlocksWithNewTimedActionTaskBlock;
    }
    return phase;
  } else {
    const timedSectionIndex = getTimedActionSectionIndex(phase);
    const timedActionTaskBlockCopy:
      | MedicationTaskBlock
      | TreatmentTaskBlock
      | DiagnosticTaskBlock
      | MiscellaneousTaskBlock = { ...timedActionBlock };
    timedActionTaskBlockCopy.IsGhostBlock = true;
    if (timedSectionIndex !== -1) {
      phase.Sections[timedSectionIndex].Blocks?.push(timedActionTaskBlockCopy);
    }
    return phase;
  }
};

export const getAllTimedActionBlocks = (
  phases: Phase[],
):
  | TreatmentTaskBlock[]
  | MiscellaneousTaskBlock[]
  | DiagnosticTaskBlock[]
  | MedicationTaskBlock[] => {
  const timedActionTaskBlocks:
    | TreatmentTaskBlock[]
    | MiscellaneousTaskBlock[]
    | DiagnosticTaskBlock[]
    | MedicationTaskBlock[] = [];
  for (let i = 0; i < phases.length; i++) {
    for (let j = 0; j < phases[i]?.Sections?.length; j++) {
      for (let k = 0; k < phases[i]?.Sections[j]?.Blocks?.length; k++) {
        if (
          phases[i].Sections[j].Blocks[k] &&
          (phases[i].Sections[j].Blocks[k].BlockType === BlockType.Treatment ||
            phases[i].Sections[j].Blocks[k].BlockType ===
              BlockType.Miscellaneous ||
            phases[i].Sections[j].Blocks[k].BlockType ===
              BlockType.Diagnostic ||
            phases[i].Sections[j].Blocks[k].BlockType ===
              BlockType.Medication) &&
          phases[i]?.Sections[j]?.Blocks[k]?.BlockContent?.IsRequired
        ) {
          timedActionTaskBlocks.push(
            phases[i]?.Sections[j]?.Blocks[k] as TreatmentTaskBlock &
              MiscellaneousTaskBlock &
              DiagnosticTaskBlock &
              MedicationTaskBlock,
          );
        }
      }
    }
  }
  return timedActionTaskBlocks;
};

export const CreateGhostTreatmentBlockWhereRequiredOnLoad = (
  phases: Phase[],
): Phase[] => {
  const timedActionBlocks:
    | TreatmentTaskBlock[]
    | MiscellaneousTaskBlock[]
    | DiagnosticTaskBlock[]
    | MedicationTaskBlock[] = getAllTimedActionBlocks(phases);

  for (let i = 0; i < timedActionBlocks.length; i++) {
    const phaseIndexesWhereRequired =
      indexesOfPhasesWithRelatedGhostTimedActionTaskBlock(
        timedActionBlocks[i]?.BlockContent as
          | MedicationTaskBlockContent
          | TreatmentTaskBlockContent
          | DiagnosticTaskBlockContent
          | MiscellaneousTaskBlockContent,
        phases,
      );
    for (let j = 0; j < phaseIndexesWhereRequired.length; j++) {
      makeSurePhaseHasTimedActionBlockElseAdd(
        phases[phaseIndexesWhereRequired[j]],
        timedActionBlocks[i],
      );
    }
  }
  return phases;
};
