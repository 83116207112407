import * as React from "react";
import { ExperimentOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  StyledResearchQuestionCenterBlock,
  StyledResearchQuestionDetails,
} from "./CenterPane/CenterPane.styled";
import { ResearchQuestionBlock } from "./ResearchQuestionEdit";
import { Tooltip } from "antd";
import { ResearchQuestionType } from "../../../../models/Enums";
import { isNullOrUndefined } from "../../../../utils/utilityfunctions";
import { useFormik } from "formik";
import TextTypeQuestion from "./CenterPane/TextTypeQuestion";
import SelectTypeQuestion from "./CenterPane/SelectTypeQuestion";
import RadioTypeQuestion from "./CenterPane/RadioTypeQuestion";
import { BlueIconButton } from "../../../../styles/Buttons.styled";
import ImportantIcon from "../../../../assets/icons/important_icon.svg";
import NumberTypeQuestion from "./CenterPane/NumberTypeQuestion";
import ButtonTypeQuestion from "./CenterPane/ButtonTypeQuestion";
export const ResearchQuestionSection = (props: any) => {
  const block = props.block as ResearchQuestionBlock;
  const scriptFormik = useFormik({
    initialValues: {
      placeholder: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });
  return (
    <StyledResearchQuestionCenterBlock>
      <div className="title-block">
        <div className="titleText">
          <ExperimentOutlined className="icon" /> &nbsp; Research Question:
          <Tooltip title={block.BlockContent.AdditionalDetails}>
            <BlueIconButton
              type="link"
              icon={<InfoCircleOutlined />}
              disabled={block.BlockContent.AdditionalDetails.length === 0}
            />
          </Tooltip>
        </div>
      </div>
      <div className="mainBlockContent">
        <div className="researchQuestionSideBannerParentDiv">
          <hr className="researchQuestionSideBanner" />
        </div>
        <StyledResearchQuestionDetails>
          <div
            className={`question word-wrap`}
            style={{
              marginBottom: block.BlockContent.Description !== "" ? 0 : "1rem",
            }}
          >
            {block.BlockContent.IsRequired ? (
              <>
                <img src={ImportantIcon} className="iconText" alt="text" />{" "}
                &nbsp;
              </>
            ) : null}
            {block.BlockContent.Question || "Enter Your Research Question"}
          </div>

          {block.BlockContent.Description !== "" ? (
            <div className="description word-wrap">
              <span>{block.BlockContent.Description}</span>
            </div>
          ) : null}

          {/* Text type question  */}
          {!isNullOrUndefined(block.BlockContent.ResearchQuestionType) &&
          !isNullOrUndefined(block.BlockContent.ResearchQuestionText) &&
          block.BlockContent.ResearchQuestionType ===
            ResearchQuestionType.Text ? (
            <TextTypeQuestion
              scriptFormik={scriptFormik}
              placeholder={block.BlockContent.ResearchQuestionText.Placeholder}
            />
          ) : null}

          {/* Select type question  */}
          {!isNullOrUndefined(block.BlockContent.ResearchQuestionType) &&
          block.BlockContent.ResearchQuestionType ===
            ResearchQuestionType.Select &&
          !isNullOrUndefined(block.BlockContent.ResearchQuestionSelect) ? (
            <SelectTypeQuestion
              scriptFormik={scriptFormik}
              question={block.BlockContent.ResearchQuestionSelect}
            />
          ) : null}

          {/* Radio type question  */}
          {!isNullOrUndefined(block.BlockContent.ResearchQuestionType) &&
          block.BlockContent.ResearchQuestionType ===
            ResearchQuestionType.Radio &&
          !isNullOrUndefined(block.BlockContent.ResearchQuestionRadio) ? (
            <RadioTypeQuestion
              question={block.BlockContent.ResearchQuestionRadio}
            />
          ) : null}

          {/* Number type question  */}
          {!isNullOrUndefined(block.BlockContent.ResearchQuestionType) &&
          block.BlockContent.ResearchQuestionType ===
            ResearchQuestionType.Number &&
          !isNullOrUndefined(block.BlockContent.ResearchQuestionNumber) ? (
            <NumberTypeQuestion
              question={block.BlockContent.ResearchQuestionNumber}
              scriptFormik={scriptFormik}
            />
          ) : null}

          {/* Button type question  */}
          {!isNullOrUndefined(block.BlockContent.ResearchQuestionType) &&
          block.BlockContent.ResearchQuestionType ===
            ResearchQuestionType.Button &&
          !isNullOrUndefined(block.BlockContent.ResearchQuestionButton) ? (
            <ButtonTypeQuestion
              question={block.BlockContent.ResearchQuestionButton}
            />
          ) : null}
        </StyledResearchQuestionDetails>
      </div>
    </StyledResearchQuestionCenterBlock>
  );
};
