import React, { useEffect, useState } from "react";
import { StyledEditInsult } from "../InjuryTab.styled";
import {
  BooleanParameter,
  InsultWithRelation,
  IntervalParameter,
  NumberParameter,
  TextParameter,
} from "../../../../../../models/InsultInterface";
import { useFormik } from "formik";
import produce from "immer";
import { FormikErrorType } from "../../../../../../models/common/FormikErrorType";
import { isNullOrUndefined } from "../../../../../../utils/utilityfunctions";
import {
  NumberParameterWithTimeInterval,
  NumberParameterStaticData,
  TextWithDropdownParameterData,
  BooleanParameterWithSwitch,
} from "../CommonData";
import EditInsultFooter from "./EditInsultFooter";
import InsultRow from "./InsultRow";
import InsultForm from "./InsultForm";
import { useDispatch } from "react-redux";
import { INSULT_INJURY_SET_CURRENT_INSULT_FLOW_RATE } from "../../../../../../store/CasualtyCreator/InsultInjury/actionType";

type EditInsultProps = {
  setIsSaveBtnPressed: Function;
  insult: InsultWithRelation;
  onBack: Function;
  onSave: Function;
};

const EditInsult = ({
  setIsSaveBtnPressed,
  insult,
  onBack,
  onSave,
}: EditInsultProps) => {
  const dispatch = useDispatch();
  const [insultObj, setInsultObj] = useState<InsultWithRelation>(
    JSON.parse(JSON.stringify(insult)),
  );
  const [initialValue, setInitialValue] = useState<any>({});
  const [focusedStates, setFocusedStates] = useState<any>({});
  const [isFocused, setIsFocused] = useState(focusedStates);
  const [lastFocused, setLastFocused] = useState(focusedStates);

  const getFields = (values: any, key: string) => {
    const params = Object.keys(values);
    return params.filter((item: string) => {
      if (item.startsWith(key)) {
        return true;
      }
      return false;
    });
  };
  const convertToSec = (minutes: number, seconds: number) => {
    return minutes * 60 + seconds;
  };
  const getTimeFields = (values: any) => {
    const params = Object.keys(values);
    return params.filter((item: string) => {
      if (item.startsWith("time_interval_minute_")) {
        return true;
      }
      return false;
    });
  };
  const formik = useFormik({
    initialValues: initialValue,
    validate: (values) => {
      const errors: FormikErrorType = {};
      const textFields = getFields(values, "text_param_");
      const numberFields = getFields(values, "number_param_");
      const timeIntervalMinutesFields = getTimeFields(values);
      textFields.forEach((item: string) => {
        if (!values[item] || !values[item].trim()) {
          errors[item] = "Required";
        } else if (values[item].trim().length > 40) {
          errors[item] = "Max 40 characters";
        }
      });
      numberFields.forEach((item: string) => {
        const param = insultObj.NumberParameters.find(
          (x: NumberParameter) => "number_param_" + x.Id == item,
        );
        if (values[item] === null) {
          errors[item] = "Required";
        } else if (param) {
          let minValue = param.Minimum;
          let maxValue = param.Maximum;
          const staticParameterIndex = NumberParameterStaticData.findIndex(
            (p) => p.Label == param.UserFacingLabel,
          );
          if (staticParameterIndex !== -1) {
            minValue = NumberParameterStaticData[staticParameterIndex].Minimum;
            maxValue = NumberParameterStaticData[staticParameterIndex].Maximum;
          }
          if (values[item] < minValue) {
            // check minimum allowed value
            errors[item] = `Minimum ${minValue} Required`;
          } else if (values[item] > maxValue) {
            // check maximum allowed value
            errors[item] = `Maximum ${maxValue} Allowed`;
          }
        }
      });
      timeIntervalMinutesFields.forEach((item: string) => {
        const param = insultObj.NumberParameters.find(
          (x: NumberParameter) => "time_interval_minute_" + x.Id == item,
        );
        if (values[item] === null) {
          errors[item] = "Required";
        } else if (param) {
          let minValue = param.Minimum; //in sec
          let maxValue = param.Maximum; //in sec
          const staticParameterIndex = NumberParameterStaticData.findIndex(
            (p) => p.Label == param.UserFacingLabel,
          );
          if (staticParameterIndex !== -1) {
            minValue = NumberParameterStaticData[staticParameterIndex].Minimum;
            maxValue = NumberParameterStaticData[staticParameterIndex].Maximum;
          }
          const minimumAllowedSec = Math.floor(minValue);
          const totalEnteredSeconds =
            Number(values["time_interval_minute_" + param.Id]) * 60 +
            Number(values["time_interval_second_" + param.Id]);
          const totalAllowedSeconds = Math.floor(maxValue);
          if (minimumAllowedSec > totalAllowedSeconds) {
            errors["time_interval_minute_" + param.Id] = `Min ${
              minimumAllowedSec / 60
            } min`;
          } else if (totalAllowedSeconds < totalEnteredSeconds) {
            errors["time_interval_minute_" + param.Id] = `Maximum ${
              totalAllowedSeconds / 60
            } min`;
          }
          if (
            values["time_interval_second_" + param.Id] &&
            Number(values["time_interval_second_" + param.Id]) >= 60
          ) {
            // check maximum allowed value
            errors["time_interval_second_" + param.Id] = `Maximum 59 sec`;
          }
        }
      });
      return errors;
    },

    onSubmit: (values) => {
      const payload: any = structuredClone(insultObj);
      const textFields = getFields(values, "text_param_");
      const numberFields = getFields(values, "number_param_");
      const booleanFields = getFields(values, "boolean_param_");
      const timeIntervalMinutesFields = getTimeFields(values);
      textFields.forEach((item: string) => {
        const TextIndex = payload.TextParameters.findIndex(
          (x: TextParameter) => "text_param_" + x.Id === item,
        );
        if (TextIndex !== -1) {
          payload.TextParameters[TextIndex].ValueToApply = values[item];
        }
      });
      numberFields.forEach((item: string) => {
        const NumberIndex = payload.NumberParameters.findIndex(
          (x: NumberParameter) =>
            `number_param_${insult.Label}_${x.UserFacingLabel}` === item,
        );
        if (NumberIndex !== -1) {
          payload.NumberParameters[NumberIndex].ValueToApply = values[item];
        }
      });
      timeIntervalMinutesFields.forEach((item: string) => {
        const NumberIndex = payload.NumberParameters.findIndex(
          (x: NumberParameter) => "time_interval_minute_" + x.Id === item,
        );
        if (NumberIndex !== -1) {
          const parameterId = payload.NumberParameters[NumberIndex].Id;
          payload.NumberParameters[NumberIndex].ValueToApply = convertToSec(
            Number(values["time_interval_minute_" + parameterId]),
            Number(values["time_interval_second_" + parameterId]),
          );
        }
      });
      booleanFields.forEach((item: string) => {
        const booleanIndex = payload.BooleanParameters.findIndex(
          (x: BooleanParameter) => "boolean_param_" + x.Id === item,
        );
        if (booleanIndex !== -1) {
          payload.BooleanParameters[booleanIndex].ValueToApply = values[item]
            ? values[item]
            : false;
        }
      });
      payload.AppliedLocationSide = values["appliedLocationSide"];
      payload.locationIdOverride = values["locationId"];
      payload.LocationId = values["locationId"];
      setInsultObj(payload);
      onSave(payload);
    },
  });

  useEffect(() => {
    if (insult.Id > 0) {
      setInsultObj(insult);
    }
  }, [insult]);

  useEffect(() => {
    if (insultObj && insultObj.BodyParts && insultObj.Location?.BodyPart) {
      const key = "bodyPartId";
      const defaultValue = insultObj.Location?.BodyPart.Id;
      setInitialValue(
        produce((draft: any) => {
          draft[key] = defaultValue;
        }),
      );
      setFocusedStates(
        produce((draft: any) => {
          draft[key] = false;
        }),
      );
      if (formik) {
        formik.setFieldValue(key, defaultValue);
        formik.setFieldValue(
          "appliedLocationSide",
          insultObj.AppliedLocationSide || insultObj.Location?.Side,
        );
      }
    }
    if (insultObj && insultObj.Location) {
      const key = "locationId";
      const defaultValue = insultObj.Location.Id;
      setInitialValue(
        produce((draft: any) => {
          draft[key] = defaultValue;
        }),
      );
      setFocusedStates(
        produce((draft: any) => {
          draft[key] = false;
        }),
      );
      if (formik) {
        formik.setFieldValue(key, defaultValue);
      }
    }
    if (insultObj && insultObj.NumberParameters) {
      insultObj.NumberParameters.forEach((param: NumberParameter) => {
        // time interval
        if (NumberParameterWithTimeInterval.includes(param.UserFacingLabel)) {
          const minutesKey = "time_interval_minute_" + param.Id;
          const secondsKey = "time_interval_second_" + param.Id;
          // seconds to minutes
          let parameterDefault = param.Default;
          const staticParameterIndex = NumberParameterStaticData.findIndex(
            (p) => p.Label == param.UserFacingLabel,
          );
          if (staticParameterIndex !== -1) {
            parameterDefault =
              NumberParameterStaticData[staticParameterIndex].Default;
          }
          const defaultValue = param.ValueToApply
            ? param.ValueToApply
            : parameterDefault;
          const minutes = Math.floor(defaultValue / 60);
          const seconds = Math.floor(defaultValue % 60);
          setInitialValue(
            produce((draft: any) => {
              draft[minutesKey] = minutes ? minutes : null;
            }),
          );
          setInitialValue(
            produce((draft: any) => {
              draft[secondsKey] = minutes ? minutes : null;
            }),
          );
          if (formik) {
            formik.setFieldValue(minutesKey, minutes ? minutes : null);
            formik.setFieldValue(secondsKey, seconds ? seconds : null);
          }
          setFocusedStates(
            produce((draft: any) => {
              draft[minutesKey] = false;
            }),
          );
          setFocusedStates(
            produce((draft: any) => {
              draft[secondsKey] = false;
            }),
          );
        } else {
          const key = `number_param_${insult.Label}_${param.UserFacingLabel}`;
          let parameterDefault = param.Default;
          const staticParameterIndex = NumberParameterStaticData.findIndex(
            (p) => p.Label == param.UserFacingLabel,
          );
          if (staticParameterIndex !== -1) {
            if (param.UserFacingLabel.toLocaleLowerCase() === "severity") {
              parameterDefault = param.Default;
            } else {
              parameterDefault =
                NumberParameterStaticData[staticParameterIndex].Default;
            }
          }
          const defaultValue = !isNullOrUndefined(param.ValueToApply)
            ? param.ValueToApply
            : parameterDefault
              ? parameterDefault
              : null;
          setInitialValue(
            produce((draft: any) => {
              draft[key] = defaultValue;
            }),
          );
          setFocusedStates(
            produce((draft: any) => {
              draft[key] = false;
            }),
          );
          if (formik) {
            formik.setFieldValue(key, defaultValue ? defaultValue : 0);
            if (
              insultObj.Label.includes("Hemorrhage") &&
              param.UserFacingLabel.toLocaleLowerCase() === "flow rate"
            ) {
              dispatch({
                type: INSULT_INJURY_SET_CURRENT_INSULT_FLOW_RATE,
                data: defaultValue || 0,
              });
            }
          }
        }
      });
    }
    if (insultObj && insultObj.TextParameters) {
      insultObj.TextParameters.forEach((param: TextParameter) => {
        const key = "text_param_" + param.Id;
        let parameterDefault = param.Default;
        const staticParameterIndex = TextWithDropdownParameterData.findIndex(
          (p) =>
            p.Label == param.UserFacingLabel &&
            p.InsultLabel.includes(insultObj.Label),
        );
        if (staticParameterIndex !== -1) {
          parameterDefault =
            TextWithDropdownParameterData[staticParameterIndex].Default;
        }
        const defaultValue = !isNullOrUndefined(param.ValueToApply)
          ? param.ValueToApply
          : parameterDefault
            ? parameterDefault
            : "";
        setInitialValue(
          produce((draft: any) => {
            draft[key] = defaultValue;
          }),
        );
        if (formik) {
          formik.setFieldValue(key, defaultValue ? defaultValue : "");
        }
        setFocusedStates(
          produce((draft: any) => {
            draft[key] = false;
          }),
        );
      });
    }
    if (insultObj && insultObj.BooleanParameters) {
      insultObj.BooleanParameters.forEach((param: BooleanParameter) => {
        const key = "boolean_param_" + param.Id;
        let parameterDefault = param.Default;
        const staticParameterIndex = BooleanParameterWithSwitch.findIndex(
          (p) =>
            p.Label === param.UserFacingLabel &&
            (!p.InsultLabel.length || p.InsultLabel.includes(insultObj.Label)),
        );
        if (staticParameterIndex !== -1) {
          parameterDefault =
            BooleanParameterWithSwitch[staticParameterIndex].Default;
        }
        const defaultValue = !isNullOrUndefined(param.ValueToApply)
          ? param.ValueToApply
          : parameterDefault
            ? parameterDefault
            : false;
        setInitialValue(
          produce((draft: any) => {
            draft[key] = defaultValue;
          }),
        );
        if (formik) {
          formik.setFieldValue(key, defaultValue ? defaultValue : false);
        }
        setFocusedStates(
          produce((draft: any) => {
            draft[key] = false;
          }),
        );
      });
    }
    if (insultObj && insultObj.IntervalParameters) {
      insultObj.IntervalParameters.forEach((param: IntervalParameter) => {
        const lowerKey = "interval_param_lower_" + param.Id;
        const upperKey = "interval_param_upper_" + param.Id;
        initialValue[lowerKey] = param.LowerDefault ? param.LowerDefault : null;
        initialValue[upperKey] = param.UpperDefault ? param.UpperDefault : null;
        if (formik) {
          formik.setFieldValue(
            lowerKey,
            param.LowerDefault ? param.LowerDefault : 0,
          );
          formik.setFieldValue(
            upperKey,
            param.UpperDefault ? param.UpperDefault : 0,
          );
        }
        setFocusedStates(
          produce((draft: any) => {
            draft[lowerKey] = false;
          }),
        );
        setFocusedStates(
          produce((draft: any) => {
            draft[upperKey] = false;
          }),
        );
      });
    }
  }, [insultObj]);

  return (
    <>
      <StyledEditInsult
        style={{ padding: `15px`, height: `calc(100vh - 64px - 55px - 62px)` }}
      >
        <InsultRow formik={formik} insult={insultObj} />
        <InsultForm
          formik={formik}
          insult={insultObj}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
        />
      </StyledEditInsult>
      <EditInsultFooter
        setIsSaveBtnPressed={setIsSaveBtnPressed}
        formik={formik}
        onBack={onBack}
      />
    </>
  );
};

export default EditInsult;
