import { Checkbox } from "antd";
import styled from "styled-components";

export const BlueCheckboxWithoutCheckedIcon = styled(Checkbox)`
  transition: all 0.3s;
  .ant-checkbox .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    background-color: transparent;
    border-color: #ffffff40;
    &::after {
      opacity: 0;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: #052125;
    border-color: #23d0eb;
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: #fff;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: #23d0eb;
    }
  }
`;
