import { Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/Table/CustomTable";
import {
  ActionIcon,
  RightSideTableHeader,
  StyledRow,
} from "../../../components/Table/CustomTable.styled";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../components/Notification/Notification";
import { colorPalette } from "../../../styles/MainList.styled";
import TabletBuild from "../../../models/Build";
import { deleteTabletBuild } from "../../../api/Media/ApiPost";
import AddBuild from "./AddBuild";
import produce from "immer";
import { dateTimeConversion } from "../../../utils/dateUtils";
import { getAllTabletBuild } from "../../../api/MediaTabletBuild/ApiGet";
import { setTabletBuildToDownload } from "../../../api/MediaTabletBuild/ApiPut";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";
import StarButton from "../../../components/StarButton/StarButton";

export default function BuildList() {
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [buildsData, setBuildsData] = useState<TabletBuild[]>([]);

  const handleOnDelete = (id: number) => {
    deleteTabletBuild(
      id,
      (response: any) => {
        OpenSuccessNotification({ description: response });
        setBuildsData(
          produce((draft: any) =>
            draft.filter((x: TabletBuild) => x.Id !== id),
          ),
        );
        onSelectChange(buildsData[1].Id);
        setIsLoading(false);
      },
      (errorMessage: string) => {
        OpenErrorNotification({ description: errorMessage });
        setIsLoading(false);
      },
    );
  };

  const onDelete = (id: number) => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to delete the build",
      content: "Are you sure you want to permanently delete this build?",
      okText: "Yes",
      onOk: () => {
        handleOnDelete(id);
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: () => {
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };
  const onDownload = (data: TabletBuild) => {
    // download audio file to system
    const link: any = document.createElement("a");
    link.href = data.Url;
    link.setAttribute("download", `REAL-X_${data.Version}.exe`);
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(data.Url);
    }, 0);
  };

  const onSelectChange = (id: number) => {
    const tabletBuild = buildsData.find((tabletBuild) => tabletBuild.Id === id);
    if (tabletBuild && !tabletBuild.IsActiveForDownload) {
      setTabletBuildToDownload(
        id,
        (data: TabletBuild) => {
          setBuildsData(
            produce((draft) => {
              draft.map((tabletBuild) => {
                if (tabletBuild.IsActiveForDownload) {
                  tabletBuild.IsActiveForDownload = false;
                }
              });

              draft.map((tabletBuild) => {
                if (tabletBuild.Id === data.Id) {
                  tabletBuild.IsActiveForDownload = true;
                }
              });
            }),
          );
        },
        (errorMessage: string) => {
          OpenErrorNotification({ description: errorMessage });
        },
      );
    }
  };
  const selectedRow = (tabletBuild: TabletBuild) => {
    return tabletBuild.IsActiveForDownload ? "selected-row" : "";
  };
  const columns = [
    {
      title: "BuildName",
      dataIndex: "BuildName",
      key: "BuildName",
      className: "whiteText script-title cursor-pointer",
      sorter: (a: any, b: any) => a.BuildName.localeCompare(b.BuildName),
      render: (BuildName: any, { Id, IsActiveForDownload }: TabletBuild) => (
        <Row
          style={{ alignItems: "center" }}
          onClick={() => {
            onSelectChange(Id);
          }}
        >
          <Col className="title selection" span={2}>
            <StarButton checked={IsActiveForDownload} />
          </Col>
          <Col span={22}>
            <span className="title text-white">{BuildName}</span>
          </Col>
        </Row>
      ),
    },
    {
      title: "Version",
      dataIndex: "Version",
      key: "Version",
      className: "whiteText",
      sorter: (a: any, b: any) => a.Version - b.Version,
      render: (Version: any) => <span className="text-white">{Version}</span>,
    },
    {
      title: "Size",
      dataIndex: "FileSize",
      key: "FileSize",
      className: "whiteText",
      sorter: (a: any, b: any) => a.FileSize - b.FileSize,
      render: (FileSize: any) => (
        <span className="text-white">{`${parseFloat(FileSize).toFixed(
          2,
        )} MB`}</span>
      ),
    },
    {
      title: "Uploaded",
      dataIndex: "UploadedBy",
      key: "UploadedBy",
      className: "whiteText",
      sorter: (a: any, b: any) =>
        a.UploadedBy?.FirstName.localeCompare(b.UploadedBy?.FirstName),
      render: (UploadedBy: any, data: any) => (
        <span className="text-white">
          {dateTimeConversion(data?.CreatedAt, "yy.MM.dd")} by{" "}
          {data?.UploadedBy?.FirstName} {data?.UploadedBy?.LastName}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "actions",
      className: "actions",
      render: (UpdatedDate: any, data: any) => {
        return renderActionOptions(data);
      },
    },
  ];
  const renderActionOptions = (data: TabletBuild) => {
    return (
      <div className="action-tray">
        {/* download icon */}
        <ActionIcon
          className="action-option"
          hoverColor={colorPalette.primary100}
          onClick={() => onDownload(data)}
        >
          <span className="tooltip-on-hover">Download</span>
          <DownloadOutlined className="display-on-hover" />
        </ActionIcon>

        {/* delete icon */}
        <ActionIcon
          className="action-option"
          hoverColor={colorPalette.error}
          onClick={() => onDelete(data.Id)}
        >
          <span className="tooltip-on-hover">Delete</span>
          <DeleteOutlined className="display-on-hover" />
        </ActionIcon>
      </div>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    getAllTabletBuild(
      (data: TabletBuild[]) => {
        setBuildsData(
          data.sort((a: any, b: any) =>
            new Date(b.CreatedAt).getTime() < new Date(a.CreatedAt).getTime()
              ? -1
              : 1,
          ),
        );
        setIsLoading(false);
      },
      (errorMessage: string) => {
        OpenErrorNotification({ description: errorMessage });
        setIsLoading(false);
      },
    );
  }, []);

  const handleSearch = (text?: string) => {
    // don't display script till instructor data available

    // Search guard clause
    if (!text) return buildsData;
    return buildsData;
  };
  const addNewBuild = (newBuild: TabletBuild) => {
    setBuildsData(
      produce((draftData: any) => {
        draftData.unshift(newBuild);
      }),
    );
  };
  return (
    <Spin spinning={isLoading}>
      <StyledRow justify="space-between">
        <Row className="btn-row" />
        <RightSideTableHeader>
          <AddBuild setLoading={setIsLoading} addNewBuild={addNewBuild} />
        </RightSideTableHeader>
      </StyledRow>
      <CustomTable
        WrapperStyle={{ height: `calc(100vh - 65px - 70px - 48px)` }}
        columns={columns}
        data={handleSearch(searchInput)}
        dynamicRowClass={selectedRow}
      />
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </Spin>
  );
}
