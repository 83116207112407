import { HolderOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  AuthorNameplate,
  StyledList,
} from "../../../../components/DraggableList/DraggableList.styled";
import {
  ContentWrap,
  DetailCardBody,
  DetailCardHeader,
  DetailCardWrapper,
} from "../Teams/Teams.styled";
import GENERIC_AVATAR from "../../../../assets/img/GENERIC_AVATAR.jpg";

type Props = {
  children?: React.ReactNode;
};
const author = [1, 2, 3, 4, 5];
export default function Teams({ children }: Props) {
  return (
    <DetailCardWrapper>
      <ContentWrap>
        <DetailCardHeader>
          <h4>Frequent Collaborators</h4>
        </DetailCardHeader>
        <DetailCardBody>
          <DragDropContext onDragEnd={() => undefined}>
            <Droppable droppableId="unselectedAuthorsDroppable">
              {(provided, snapshot) => (
                <StyledList
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {author.map((a) => (
                    <Draggable key={a} draggableId={`draggable-${a}`} index={a}>
                      {(provided, snapshot) => (
                        <AuthorNameplate
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <HolderOutlined />
                          <Meta
                            avatar={<Avatar size={64} src={GENERIC_AVATAR} />}
                            title="LastName, FirstName"
                            description="Title, Organization"
                          />
                        </AuthorNameplate>
                      )}
                    </Draggable>
                  ))}
                </StyledList>
              )}
            </Droppable>
          </DragDropContext>
        </DetailCardBody>
      </ContentWrap>
    </DetailCardWrapper>
  );
}
