import axios from "axios";
import { defaultErrorCallback } from "../ErrorCallback";

const apiPathPrefix = "/api/1/pfc/tablet-build";

// set latest tablet-build to be downloaded.
export function setTabletBuildToDownload(
  id: number,
  callback: Function,
  errorcallback: Function = defaultErrorCallback,
) {
  axios
    .put(`${apiPathPrefix}/${id}/set-for-download`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      console.log(err);
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err.response.data.errorMessage);
      }
    });
}
