import React, { useEffect, useState } from "react";
import { Col, Row, Tooltip } from "antd";
import { InjuryViewTypes } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { WhiteButton } from "./InjuryTab.styled";
import { FilterOutlined, PlusCircleOutlined } from "@ant-design/icons";
import SearchFilter from "../../../../../components/SearchFilter/SearchFilter";
import { isNullOrUndefined } from "../../../../../utils/utilityfunctions";
import { useAppSelector } from "../../../../../store/hooks";
import {
  INSULT_INJURY_RESET_FILTERED_ITEMS,
  INSULT_INJURY_SET_DISPLAY_ALL_INJURY_INSULT,
  INSULT_INJURY_SET_FILTERED_ITEMS,
} from "../../../../../store/CasualtyCreator/InsultInjury/actionType";
import {
  Insult,
  InsultWithRelation,
} from "../../../../../models/InsultInterface";
import { AppliedInjury, Injury } from "../../../../../models/InjuryInterface";
import {
  EyeInvisibleOutlinedIcons,
  EyeOutlinedIcons,
} from "../../../../../IconsTheme/Icons.global";

type InjuryTabHeaderProps = {
  onClickSelectInsultInjury: Function;
  onClickAddInjury: Function;
};

// eslint-disable-next-line no-empty-pattern
const InjuryTabHeader = ({
  onClickAddInjury,
  onClickSelectInsultInjury,
}: InjuryTabHeaderProps) => {
  const dispatch = useDispatch();

  const { filter } = useAppSelector(
    (state) => state.FeatureFlagReducer.injuryTab,
  );

  const view = useSelector((state: any) => state.InsultInjury.CurrentView);
  const AppliedInsults = useSelector(
    (state: any) => state.InsultInjury.AppliedInsults,
  );
  const AppliedInjuries = useSelector(
    (state: any) => state.InsultInjury.AppliedInjuries,
  );
  const InjuryList = useSelector((state: any) => state.InsultInjury.Injuries);
  const InsultList = useSelector((state: any) => state.InsultInjury.Insults);
  const DisplayAllInjuryInsult = useSelector(
    (state: any) => state.InsultInjury.DisplayAllInjuryInsult,
  );

  const [selectedFilter, setSelectedFilter] = useState<string>("");

  useEffect(() => {
    handleSearch("");
  }, [AppliedInsults, AppliedInjuries, InjuryList, InsultList, view]);

  const handleClickAddInjury = () => {
    onClickAddInjury();
  };
  const handleClickSelectInsultInjury = () => {
    onClickSelectInsultInjury();
  };
  const resetFilteredItems = () => {
    dispatch({
      type: INSULT_INJURY_RESET_FILTERED_ITEMS,
      field: "FilteredAppliedInjuries",
      originalField: "AppliedInjuries",
    });
    dispatch({
      type: INSULT_INJURY_RESET_FILTERED_ITEMS,
      field: "FilteredAppliedInsults",
      originalField: "AppliedInsults",
    });
    dispatch({
      type: INSULT_INJURY_RESET_FILTERED_ITEMS,
      field: "FilteredInsults",
      originalField: "Insults",
    });
    dispatch({
      type: INSULT_INJURY_RESET_FILTERED_ITEMS,
      field: "FilteredInjuries",
      originalField: "Injuries",
    });
  };
  const filterAppliedInsultsData = (
    keyword: string,
    selectedFilteredKey: string,
  ) => {
    const FilteredData = AppliedInsults.filter((row: Insult) => {
      const isInsultNameMatched =
        (!selectedFilteredKey || selectedFilteredKey === "Insult") &&
        String(row.Label).toLocaleLowerCase().indexOf(keyword) > -1;
      return isInsultNameMatched;
    });
    dispatch({
      type: INSULT_INJURY_SET_FILTERED_ITEMS,
      field: "FilteredAppliedInsults",
      data: FilteredData,
    });
  };
  const filterAppliedInjuriesData = (
    keyword: string,
    selectedFilteredKey: string,
  ) => {
    const FilteredData = AppliedInjuries.filter((row: AppliedInjury) => {
      const isInjuryNameMatched =
        (!selectedFilteredKey || selectedFilteredKey === "Injury") &&
        String(row.Title).toLocaleLowerCase().indexOf(keyword) > -1;
      const isInsultNameMatched = row.Insults.some(
        (insult: InsultWithRelation) => {
          return insult.Label.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
        },
      );
      return isInjuryNameMatched || isInsultNameMatched;
    });
    dispatch({
      type: INSULT_INJURY_SET_FILTERED_ITEMS,
      field: "FilteredAppliedInjuries",
      data: FilteredData,
    });
  };
  const filterInsultsData = (keyword: string, selectedFilteredKey: string) => {
    const FilteredData = InsultList.filter((row: InsultWithRelation) => {
      const isInsultNameMatched =
        (!selectedFilteredKey || selectedFilteredKey === "Insult") &&
        String(row.Label).toLocaleLowerCase().indexOf(keyword) > -1;
      return isInsultNameMatched;
    });
    dispatch({
      type: INSULT_INJURY_SET_FILTERED_ITEMS,
      field: "FilteredInsults",
      data: FilteredData,
    });
  };
  const filterInjuriesData = (keyword: string, selectedFilteredKey: string) => {
    const FilteredData = InjuryList.filter((row: Injury) => {
      const isInjuryNameMatched =
        (!selectedFilteredKey || selectedFilteredKey === "Injury") &&
        String(row.Title).toLocaleLowerCase().indexOf(keyword) > -1;
      const isInsultNameMatched = row.Insults.some(
        (insult: InsultWithRelation) => {
          return insult.Label.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
        },
      );
      return isInjuryNameMatched || isInsultNameMatched;
    });
    dispatch({
      type: INSULT_INJURY_SET_FILTERED_ITEMS,
      field: "FilteredInjuries",
      data: FilteredData,
    });
  };
  // Filter the data
  const filterData = (keyword: string, filteredKey?: string) => {
    if (!keyword || !keyword.length || !keyword.trim().length) {
      resetFilteredItems();
      return;
    }
    // filter based on insult and injury for future task
    const selectedFilteredKey = !isNullOrUndefined(filteredKey)
      ? filteredKey
      : selectedFilter;
    if (view === InjuryViewTypes.CREATE_EDIT_INJURY) {
      // filter insults
      filterInsultsData(keyword, selectedFilteredKey);
    } else if (view === InjuryViewTypes.SELECT_INSULT_INJURY) {
      // filter insults
      filterInsultsData(keyword, selectedFilteredKey);
      // filter injury
      filterInjuriesData(keyword, selectedFilteredKey);
    } else {
      // filter applied insults
      filterAppliedInsultsData(keyword, selectedFilteredKey);
      // filter applied injury
      filterAppliedInjuriesData(keyword, selectedFilteredKey);
    }
  };

  // Handle search field keyUp
  const handleSearch = (value: string) => {
    filterData(value);
  };

  const toggleInjuryInsults = () => {
    dispatch({
      type: INSULT_INJURY_SET_DISPLAY_ALL_INJURY_INSULT,
      field: "DisplayAllInjuryInsult",
      data: !DisplayAllInjuryInsult,
    });
  };

  const displayHideShowIcon = () => {
    return DisplayAllInjuryInsult ? (
      <EyeOutlinedIcons className="icon-lg" />
    ) : (
      <EyeInvisibleOutlinedIcons className="icon-lg" />
    );
  };

  const createFilter = () => {
    return (
      <>
        {filter && (
          <Col>
            <Tooltip
              title={`Select Filters ${filter ? "- Hidden in Prod" : ""}`}
            >
              <WhiteButton
                type="link"
                icon={<FilterOutlined className="icon-lg" />}
                size="large"
              />
            </Tooltip>
          </Col>
        )}
      </>
    );
  };

  return (
    <>
      {view !== InjuryViewTypes.EDIT_INSULT &&
        view !== InjuryViewTypes.EDIT_INJURY && (
          <Row
            wrap={false}
            gutter={15}
            style={{ margin: 0, marginBottom: 8, padding: 5 }}
          >
            {createFilter()}
            <Col flex="1">
              <SearchFilter key={view} handleSearch={handleSearch} />
            </Col>
            <Col>
              {view === InjuryViewTypes.CREATE_EDIT_INJURY && (
                <Tooltip title={`All Insults`}>
                  <WhiteButton
                    type="link"
                    icon={displayHideShowIcon()}
                    onClick={toggleInjuryInsults}
                    size="large"
                  />
                </Tooltip>
              )}
              {view !== InjuryViewTypes.SELECT_INSULT_INJURY &&
                view !== InjuryViewTypes.CREATE_EDIT_INJURY && (
                  <Tooltip title={`Add New Insult or Injury`}>
                    <WhiteButton
                      type="link"
                      icon={<PlusCircleOutlined className="icon-lg" />}
                      size="large"
                      onClick={handleClickSelectInsultInjury}
                    ></WhiteButton>
                  </Tooltip>
                )}
              {view === InjuryViewTypes.SELECT_INSULT_INJURY && (
                <Tooltip title={`Create New Injury`}>
                  <WhiteButton
                    type="link"
                    icon={<PlusCircleOutlined className="icon-lg" />}
                    size="large"
                    onClick={handleClickAddInjury}
                  ></WhiteButton>
                </Tooltip>
              )}
            </Col>
          </Row>
        )}
    </>
  );
};

export default InjuryTabHeader;
