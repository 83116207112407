const treatmentOptions = {
  IsCritical: false,
  IsRequired: false,
  SubtasksEnabled: false,
  IntervalEnabled: false,
  DurationEnabled: false,
  DefineDurationTimeEnabled: false,
  DefineStartTimeEnabled: false,
  RatingEnabled: false,
  MediaEnabled: false,
  CustomLabelsEnabled: false,
  IntervalTime: 0,
};

const Focused = {
  search: false,
  description: false,
  additionalNote: false,
  interval: false,
  subtask: false,
};

const treatmentEditState = {
  isFocused: Focused,
  lastFocused: Focused,
  option: treatmentOptions,
  selectedPhase: "",
  interval: 0,
  description: "",
  additionalNote: "",
  subtask: "",
  uploadedMedia: [],
  isEditingAllowed: true,
};

export default (
  state = treatmentEditState,
  action: { type: string; payload: any },
) => {
  if (action.type === "isFocused") {
    return {
      ...state,
      isFocused: { ...action.payload },
    };
  } else if (action.type === "lastFocused") {
    return {
      ...state,
      lastFocused: { ...action.payload },
    };
  } else if (action.type === "option") {
    return {
      ...state,
      option: { ...action.payload },
    };
  } else if (action.type === "selectedPhase") {
    return {
      ...state,
      selectedPhase: action.payload,
    };
  } else if (action.type === "description") {
    return {
      ...state,
      description: action.payload,
    };
  } else if (action.type === "additionalNote") {
    return {
      ...state,
      additionalNote: action.payload,
    };
  } else if (action.type === "interval") {
    return {
      ...state,
      interval: action.payload,
    };
  } else if (action.type === "subtask") {
    return {
      ...state,
      subtask: action.payload,
    };
  } else if (action.type === "uploadedMedia") {
    return {
      ...state,
      uploadedMedia: action.payload,
    };
  } else if (action.type === "isEditingAllowed") {
    return {
      ...state,
      isEditingAllowed: action.payload,
    };
  } else return state;
};
