import { Tooltip } from "antd";
import { FormikProps, useFormik } from "formik";
import React, { useRef } from "react";
import FloatingLabelTextArea from "../../../../../../components/FloatingLabel/FloatingLabelTextArea";
import { PlusCircleOutlinedIcons } from "../../../../../../IconsTheme/Icons.global";
import { AddOptionToListBlock } from "../RightEditPanel.styled";
import { FormikErrorType } from "../../../../../../models/common/FormikErrorType";

type Props = {
  isFocused: any;
  setIsFocused: any;
  lastFocused: any;
  setLastFocused: any;
  addOptionToList: Function;
};
export default function AddItemToListInput({
  isFocused,
  setIsFocused,
  lastFocused,
  setLastFocused,
  addOptionToList,
}: Props) {
  const optionInputRef = useRef<any>(null);

  const scriptFormik = useFormik({
    initialValues: {
      option: "",
    },
    validate: () => {
      const errors: FormikErrorType = {};
      return errors;
    },
    validateOnBlur: true,
    onSubmit: () => undefined,
  });

  const onClickAdd = () => {
    if (scriptFormik.values["option"].trim().length > 0) {
      addOptionToList(scriptFormik.values["option"]);
      scriptFormik.setFieldValue("option", "");
      optionInputRef.current.focus();
    }
  };
  return (
    <AddOptionToListBlock>
      <FloatingLabelTextArea
        label={"Option"}
        textareaName={"option"}
        value={scriptFormik.values["option"]}
        onChange={scriptFormik.handleChange}
        scriptFormik={scriptFormik}
        lastFocused={lastFocused}
        setIsFocused={setIsFocused}
        setLastFocused={setLastFocused}
        isFocused={isFocused}
        enableLiveCount={false}
        onSave={scriptFormik.handleBlur}
        inputRef={optionInputRef}
      />
      <div className="addIcon" onClick={onClickAdd}>
        <Tooltip title="Add Option">
          <PlusCircleOutlinedIcons />
        </Tooltip>
      </div>
    </AddOptionToListBlock>
  );
}
