import axios from "axios";

const apiPrefix = "/api/1/pfc/ResearchQuestion";

export function createResearchQuestion(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`${apiPrefix}`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function copyResearchQuestion(
  id: number,
  callback: Function,
  errorcallback: Function,
  question?: string,
) {
  axios
    .post(`${apiPrefix}/${id}/copy?question=${encodeURI(question || "")}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}
