import * as React from "react";
import {
  CopyOutlined,
  CrownFilled,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, Tooltip } from "antd";
import {
  CreateMasterFromAppliedInjury,
  RemoveAndDeleteAppliedInjuryFromCasualty,
} from "../../../../../../api/Insult/ApiPost";
import {
  InjuryWrapper,
  InjuryBlock,
  StyledMenuItem,
  StyledDeletedMenuItem,
  WhiteButton,
} from "../InjuryTab.styled";
import { InsultWithRelation } from "../../../../../../models/InsultInterface";
import {
  AppliedInjury,
  Injury,
} from "../../../../../../models/InjuryInterface";
import InsultItem from "./InsultItem";
import { InjuryViewTypes } from "../../constants";
import {
  INSULT_INJURY_ADD_INJURY_TO_INJURIES,
  INSULT_INJURY_REMOVE_APPLIED_INJURY,
  INSULT_INJURY_REMOVE_INJURY_FOR_CREATE_MASTER_OPTION,
} from "../../../../../../store/CasualtyCreator/InsultInjury/actionType";
import { useDispatch, useSelector } from "react-redux";
import { isNullOrUndefined } from "../../../../../../utils/utilityfunctions";
import { colorPalette } from "../../../../../../styles/MainList.styled";
import { CompareTwoInjuries } from "../InjuryMapUtills";
import ConfirmDialog from "../../../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../../../components/ConfirmDialog/useConfirmDialog";
import useRenderLocationAs from "../../../../../../hooks/useRenderLocationAs";

type InjuryItemProps = {
  casualtyId: number;
  injury: AppliedInjury;
  currentView: string;
  onEditInjury: Function;
  onEditInsult: Function;
  onInjurySelection: Function;
  handleOpenCreateEditInjury: Function;
  className?: string;
  masterInjury?: Injury;
};

const InjuryItem = ({
  casualtyId,
  injury,
  currentView,
  onEditInjury,
  handleOpenCreateEditInjury,
  onEditInsult,
  onInjurySelection,
  className,
  masterInjury,
}: InjuryItemProps) => {
  const dispatch = useDispatch();
  const { isReadOnly } = useSelector(
    (state: any) => state.scriptBuilderReducer,
  ); // Read-only Script

  const appliedInjuryForCreateMaster = useSelector(
    (state: any) => state.InsultInjury.AppliedInjuryForCreateMaster,
  ); // to enable create master option in injury

  // Get value to render location from BE if the RenderLocationAs is null in the injury.
  let renderLocationAs;
  if (injury && !injury.RenderLocationAs && injury.LocationId) {
    renderLocationAs = useRenderLocationAs(
      injury.LocationId,
      injury.AppliedLocationSide,
    );
  }

  const error = (data: any) => {
    console.log(data);
  };
  // Delete injury
  const deleteSuccess = () => {
    dispatch({ type: INSULT_INJURY_REMOVE_APPLIED_INJURY, Id: injury.Id });
  };

  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const confirmDeleteInjury = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to delete the injury",
      content: "Are you sure you want to delete this injury permanently?",
      okText: "Yes",
      onOk: () => {
        RemoveAndDeleteAppliedInjuryFromCasualty(
          injury.Id,
          casualtyId,
          deleteSuccess,
          error,
        );
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: () => {
        console.log("onCancel");
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };
  const handleInjurySelection = () => {
    if (isReadOnly) return; //disable action callback in read only script
    onInjurySelection(injury);
  };
  const handleEditInjury = () => {
    if (isReadOnly) return; //disable action callback in read only script
    if (currentView === InjuryViewTypes.EDIT_INJURY) {
      handleOpenCreateEditInjury(injury);
    } else {
      onEditInjury(injury.Id);
    }
  };
  const createMasterInjurySuccess = (data: any) => {
    dispatch({
      type: INSULT_INJURY_REMOVE_INJURY_FOR_CREATE_MASTER_OPTION,
      Id: injury.Id,
    });
    if (!isNullOrUndefined(data)) {
      dispatch({ type: INSULT_INJURY_ADD_INJURY_TO_INJURIES, data });
    }
  };
  const createMasterInjury = () => {
    CreateMasterFromAppliedInjury(injury.Id, createMasterInjurySuccess, error);
  };
  const menu = (
    <Menu>
      <StyledMenuItem key="0" onClick={() => handleEditInjury()}>
        <EditOutlined /> &nbsp; Edit
      </StyledMenuItem>
      {currentView === InjuryViewTypes.CASUALTY_INJURIES && (
        <>
          <StyledMenuItem key="1" disabled>
            <CopyOutlined /> &nbsp; Copy
          </StyledMenuItem>

          <StyledMenuItem
            key="2"
            disabled={!appliedInjuryForCreateMaster.includes(injury.Id)}
            onClick={() => createMasterInjury()}
          >
            <CrownFilled /> &nbsp; Create Master
          </StyledMenuItem>

          <StyledDeletedMenuItem key="3" onClick={() => confirmDeleteInjury()}>
            <DeleteOutlined /> &nbsp; Remove
          </StyledDeletedMenuItem>
        </>
      )}
    </Menu>
  );
  const checkWithMasterInjury = () => {
    if (currentView === InjuryViewTypes.SELECT_INSULT_INJURY) {
      return true;
    } else if (!injury.CopySourceInjuryId || isNullOrUndefined(masterInjury)) {
      return false;
    } else {
      return !CompareTwoInjuries(injury, masterInjury); // compare with master
    }
  };
  const onClickEditInjury = () => {
    if (isReadOnly) return; //disable action callback in read only script
    // open edit injury on click of injury row in applied insult/ injury view
    if (currentView === InjuryViewTypes.CASUALTY_INJURIES) {
      onEditInjury(injury.Id);
    }
  };
  return (
    <InjuryWrapper
      className={className}
      onClick={() => handleInjurySelection()}
    >
      <div className="injury-header">
        <div className="title-block" onClick={onClickEditInjury}>
          {checkWithMasterInjury() ? (
            <CrownFilled style={{ color: colorPalette.warning }} />
          ) : (
            <CrownFilled />
          )}
          <Tooltip title={injury.Title}>
            <span className="title">{injury.Title}</span>
          </Tooltip>
        </div>
        <div className="location-param" onClick={onClickEditInjury}>
          <div>
            <label>Location</label>
            <span className="text-primary">
              {injury?.RenderLocationAs || renderLocationAs || "-"}
            </span>
          </div>
        </div>
        {currentView === InjuryViewTypes.SELECT_INSULT_INJURY && (
          <Tooltip title={`Select & Add Injury`}>
            <WhiteButton
              type="link"
              icon={<PlusCircleOutlined />}
              size="middle"
              // onClick={handleInsultSelection}
            ></WhiteButton>
          </Tooltip>
        )}

        {(currentView === InjuryViewTypes.CASUALTY_INJURIES ||
          currentView === InjuryViewTypes.EDIT_INJURY) &&
          !isReadOnly && (
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                href={undefined}
              >
                <Tooltip title={`Options`}>
                  <MoreOutlined className="actions-item action-more" />
                </Tooltip>
              </a>
            </Dropdown>
          )}
      </div>
      {injury && injury.Insults && injury.Insults.length ? (
        <InjuryBlock>
          {injury.Insults.map((insult: InsultWithRelation, index: number) => {
            return (
              <InsultItem
                key={!injury.Id ? insult.CopySourceInsultId : insult.Id}
                index={index}
                insult={insult}
                casualtyId={casualtyId}
                injuryId={injury.Id}
                currentView={currentView}
                onEditInsult={onEditInsult}
                onEditInjury={onClickEditInjury}
                totalAvailableInsult={injury.Insults.length}
              />
            );
          })}
        </InjuryBlock>
      ) : null}
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </InjuryWrapper>
  );
};

export default InjuryItem;
