import styled from "styled-components";

export const TimedActionBlockNameContainer = styled.div`
  color: white;
  border-right: 1px solid #161616;
  flex: 0 0 55px;
  text-align: center;

  .timedaction-name {
    font-size: 0.8rem;
    display: block;
  }

  .timedaction-status {
    font-size: 1.5rem;
    display: block;
  }
`;
