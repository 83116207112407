import React from "react";
import { TimedActionProperty } from "../TimedActionBlock.styled";
import {
  TimedActionBlockTimeContainer,
  Time,
} from "./TimedActionBlockTime.styled";

type Props = {
  title: string;
  hr: string;
  mm: string;
};

const TimedActionBlockTime = ({ title, hr, mm, ...props }: Props) => {
  return (
    <TimedActionProperty>
      <TimedActionBlockTimeContainer>
        <span className="title">{title}</span>
        <Time>
          {hr} : {mm}
        </Time>
      </TimedActionBlockTimeContainer>
    </TimedActionProperty>
  );
};

export default TimedActionBlockTime;
