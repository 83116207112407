import React, { MouseEventHandler } from "react";
import { Col } from "antd";
import { NumberParameter } from "../../../../../../../models/InsultInterface";
import { isNullOrUndefined } from "../../../../../../../utils/utilityfunctions";
import { ShowInsultParameter, hideNumberParam } from "../../InjuryMapUtills";

type NumberParamProps = {
  parameter: NumberParameter;
  onEditInsult: MouseEventHandler;
};

export const NumberParam = ({ parameter, onEditInsult }: NumberParamProps) => {
  return (
    <>
      {hideNumberParam(parameter) && (
        <Col
          key={"number_param_" + parameter.Id}
          className="number-param"
          onClick={onEditInsult}
        >
          <div>
            <label>{parameter.UserFacingLabel}</label>
            {parameter.UserFacingLabel.toLocaleLowerCase() === "severity" ? (
              <span className="text-primary">
                {!isNullOrUndefined(parameter.ValueToApply)
                  ? parameter.ValueToApply * 10 * parameter.Maximum
                  : parameter.Default
                    ? parameter.Default * 10 * parameter.Maximum
                    : "-"}
                {parameter.Units && parameter.Units === "%" ? (
                  <span className="units">{parameter.Units}</span>
                ) : null}
              </span>
            ) : (
              <span className="text-primary">
                {!isNullOrUndefined(parameter.ValueToApply)
                  ? parameter.ValueToApply
                  : parameter.Default
                    ? parameter.Default
                    : "-"}
                {parameter.Units && parameter.Units === "%" ? (
                  <span className="units">{parameter.Units}</span>
                ) : null}
              </span>
            )}
            {parameter.Units && parameter.Units !== "%" ? (
              <span className="units">{parameter.Units}</span>
            ) : null}
          </div>
        </Col>
      )}
    </>
  );
};

export default NumberParam;
