import {
  DashboardIcon,
  ManageAccountIcon,
  ManageContentIcon,
  ScenariosIcon,
  ScriptIcon,
  SessionIcon,
} from "../../IconsTheme/Icons.global";

export const CompanyOwner = [
  {
    name: "Dashboard",
    url: "dashboard",
    key: "dashboard",
    icon: DashboardIcon,
  },
  {
    name: "Sessions",
    url: "sessions",
    key: "sessions",
    icon: SessionIcon,
  },
  // {
  //   name: "Script Builder",
  //   url: "CreateScript",
  //   key: "scriptbuilder",
  //   icon: EditOutlined,
  // },
  {
    name: "Scripts",
    url: "ViewScript",
    key: "ViewScript",
    icon: ScriptIcon,
  },
  // {
  //   name: "Scenarios",
  //   url: "scenarios",
  //   key: "scenarios",
  //   icon: ScenariosIcon,
  //   disabled: true,
  // }, //come back to this
  // {
  //   name: "Reports",
  //   url: "reports",
  //   key: "reports",
  //   icon: "monitoring",
  //   disabled: true
  // },
  // {
  //   name: "Schedule Training",
  //   url: "schedule",
  //   key: "scheduletraining",
  //   icon: SessionIcon,
  //   disabled: true
  // },
  //{
  //    name: 'Create Resources',
  //    url: 'CreateResource',
  //    key: 'CreateResource',
  //    icon: 'FolderAddOutlined',
  //},
  {
    name: "Manage Content",
    url: "create-masters",
    key: "content",
    icon: ManageContentIcon,
  },
  {
    name: "Manage Account",
    url: "account",
    key: "account",
    icon: ManageAccountIcon,
  },
];
export const CompanyAdmin = [
  {
    name: "Dashboard",
    url: "home",
    key: "dashboard",
    icon: DashboardIcon,
  },
  {
    name: "Sessions",
    url: "sessions",
    key: "sessions",
    icon: SessionIcon,
  },
  // {
  //   name: "Script Builder",
  //   url: "CreateScript",
  //   key: "scriptbuilder",
  //   icon: EditOutlined,
  // },
  {
    name: "Scripts",
    url: "ViewScript",
    key: "ViewScript",
    icon: ScriptIcon,
  },
  {
    name: "Scenarios",
    url: "scenarios",
    key: "scenarios",
    icon: ScenariosIcon,
    disabled: true,
  },
  // {
  //   name: "Reports",
  //   url: "reports",
  //   key: "reports",
  //   icon: "monitoring",
  //   disabled: true
  // },
  // {
  //   name: "Schedule Training",
  //   url: "schedule",
  //   key: "scheduletraining",
  //   icon: SessionIcon,
  //   disabled: true
  // },
  //{
  //    name: 'Create Resources',
  //    url: 'CreateResource',
  //    key: 'CreateResource',
  //    icon: 'FolderAddOutlined',
  //},
  {
    name: "Manage Content",
    url: "create-masters",
    key: "content",
    icon: ManageContentIcon,
  },
  {
    name: "Manage Account",
    url: "account",
    key: "account",
    icon: ManageAccountIcon,
  },
];

export const CompanyManager = [
  {
    name: "Dashboard",
    url: "home",
    key: "dashboard",
    icon: DashboardIcon,
  },
  {
    name: "Sessions",
    url: "sessions",
    key: "sessions",
    icon: SessionIcon,
  },
  // {
  //   name: "Script Builder",
  //   url: "CreateScript",
  //   key: "scriptbuilder",
  //   icon: EditOutlined,
  // },
  {
    name: "Scripts",
    url: "ViewScript",
    key: "ViewScript",
    icon: ScriptIcon,
  },
  {
    name: "Scenarios",
    url: "scenarios",
    key: "scenarios",
    icon: ScenariosIcon,
    disabled: true,
  },
  // {
  //   name: "Reports",
  //   url: "reports",
  //   key: "reports",
  //   icon: "monitoring",
  //   disabled: true
  // },
  // {
  //   name: "Schedule Training",
  //   url: "schedule",
  //   key: "scheduletraining",
  //   icon: SessionIcon,
  //   disabled: true
  // },
  //{
  //    name: 'Create Resources',
  //    url: 'CreateResource',
  //    key: 'CreateResource',
  //    icon: 'FolderAddOutlined',
  //},
  {
    name: "Manage Content",
    url: "create-masters",
    key: "content",
    icon: ManageContentIcon,
  },
  {
    name: "Manage Account",
    url: "account",
    key: "account",
    icon: ManageAccountIcon,
  },
];

export const User = [];
