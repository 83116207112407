import { Table } from "antd";
import styled from "styled-components";
import { colorPalette } from "../../../../../../styles/MainList.styled";

export const StyledTable = styled(Table)`
  .ant-table {
    background-color: transparent;
    font-size: 1rem;
    color: #ffffff;
  }
  tbody td {
    transition: all 0.3s ease;
  }
  .ant-table-row.selected,
  .ant-table-row.selected:hover {
    td {
      background: #052125;
      border-color: #23d0eb;
    }
  }
  thead th {
    color: #ffffff;
    border: none;
    background-color: transparent;
  }
  tbody td {
    background: #1e1e1e;
    box-shadow: none;
    border-color: ${colorPalette.gray50};
  }

  tbody tr {
    &:hover {
      cursor: pointer;
    }
  }

  .ant-table-thead {
    .ant-table-column-sorters {
      display: inline-flex;
      flex-direction: row-reverse;
    }
    .ant-table-column-title {
      margin-left: 5px;
    }
  }
  .ant-table-cell:last-child {
    padding: 0px !important;
  }
  .ant-dropdown-trigger {
    height: 100%;
    width: 40px;
    &:hover {
      color: #ffffff !important;
    }
  }
`;

export const TitleTD = styled.div`
  display: flex;
  .title {
    text-transform: capitalize;
    color: rgb(255, 255, 255);
    width: calc(100% - 85px);
    padding: 1rem;
  }
  .detailsLinkOnHover {
    width: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    .detail-link {
      height: 100%;
    }
  }
`;
