import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
  },
};
interface DoughnutChartDataset {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
}
interface DoughnutChartData {
  labels: string[];
  datasets: DoughnutChartDataset[];
}
interface Props {
  data: DoughnutChartData;
}

export default function CustomDoughnutChart({ data }: Props) {
  return <Doughnut data={data} options={options} />;
}
