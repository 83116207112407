import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResearchQuestion } from "../../../../models/ScriptInterfaces";
import { createResearchQuestion } from "../../../ScriptBuilder/CreateDefaultBlockObjects";
const defaultResearchQuestion = createResearchQuestion();
export interface ResearchQuestionState {
  data: ResearchQuestion[];
  modalTitle: string;
  addUpdateModal: boolean;
  loading: boolean;
  researchQuestionForUpdate: ResearchQuestion;
}

const initialState: ResearchQuestionState = {
  data: [],
  researchQuestionForUpdate: defaultResearchQuestion,
  modalTitle: "",
  addUpdateModal: false,
  loading: true,
};

export const researchQuestionSlice = createSlice({
  name: "researchQuestion",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<ResearchQuestion[]>) => {
      state.data = action.payload;
    },
    setModalTitle: (state, action: PayloadAction<string>) => {
      state.modalTitle = action.payload;
    },
    setAddUpdateModal: (state, action: PayloadAction<boolean>) => {
      state.addUpdateModal = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    onDeleteSuccess: (state, action: PayloadAction<number>) => {
      state.data = state.data.filter((x) => x.Id !== action.payload);
    },
    onUpdateSuccess: (state, action: PayloadAction<ResearchQuestion>) => {
      const index = state.data.findIndex((x) => x.Id === action.payload.Id);
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    },
    onArchiveSuccess: (state, action: PayloadAction<number>) => {
      const index = state.data.findIndex((x) => x.Id === action.payload);
      if (index !== -1) {
        state.data[index].IsArchived = !state.data[index].IsArchived;
      }
    },
    onCreateSuccess: (state, action: PayloadAction<ResearchQuestion>) => {
      state.data.unshift(action.payload);
    },
    resetRQForUpdate: (state, action: PayloadAction<null>) => {
      state.researchQuestionForUpdate = defaultResearchQuestion;
    },
    setRQForUpdate: (state, action: PayloadAction<ResearchQuestion>) => {
      state.researchQuestionForUpdate = action.payload;
    },
  },
});

export default researchQuestionSlice.reducer;

export const {
  setData,
  setModalTitle,
  setAddUpdateModal,
  setLoading,
  onUpdateSuccess,
  onCreateSuccess,
  onDeleteSuccess,
  onArchiveSuccess,
  setRQForUpdate,
  resetRQForUpdate,
} = researchQuestionSlice.actions;
