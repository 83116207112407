import FloatingLabelTextArea from "../FloatingLabel/FloatingLabelTextArea";
import * as React from "react";
import { FormikProps } from "formik";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  SetDescription,
  SetIsFocused,
  SetLastFocused,
} from "../../pages/ScriptBuilder/EditComponents/DiagnosticEdit/DiagnosticSlice";
import { DiagnosticTaskBlock } from "../../models/DiagnosticTaskBlock";

interface AdditionalInfoProps {
  scriptFormik: FormikProps<any>;
  onChange: Function;
  object: DiagnosticTaskBlock;
}

export const DiagnosticBlockInput = (props: AdditionalInfoProps) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: any) => state.DiagnosticReducer);

  const handleChange = (type: string, value: string) => {
    props.onChange("TimedActionTaskContent.Description", value);
    dispatch(SetDescription(value));
  };

  return (
    <div className="additionalItemsContainer">
      <FloatingLabelTextArea
        label={"Description"}
        textareaName={"description"}
        value={props.object?.BlockContent?.TimedActionTaskContent?.Description}
        onSave={() => undefined}
        onChange={(e: any) => handleChange("Description", e.target.value)}
        scriptFormik={props.scriptFormik}
        lastFocused={state.lastFocused}
        setIsFocused={(obj: {}) => dispatch(SetIsFocused(obj))}
        setLastFocused={(obj: {}) => dispatch(SetLastFocused(obj))}
        style={{ marginBottom: "1rem" }}
        isFocused={state.isFocused}
      />
    </div>
  );
};
