import styled from "styled-components";
import { GrayColorPalette, colorPalette } from "../../styles/MainList.styled";
import FloatingLabelSelect from "../../components/MuiSelect/FloatingLabelSelect";

export const StyledUserWrapper = styled.div`
  border-top: 4px solid ${colorPalette.gray75};
  border-left: 4px solid ${colorPalette.gray75};
`;

export const RightPaneWrapper = styled.div`
  width: 30%;
  background: #161616;

  @media (max-width: 1211px) {
    width: 35%;
  }
`;

export const UserFormWrapper = styled.div`
  width: 70%;
  @media (max-width: 1211px) {
    width: 65%;
  }
`;

export const StyledUserForm = styled.form`
  width: 450px;
  margin: 0 auto;
  @media (max-width: 1211px) {
    width: 450px;
    margin: 0;
  }
`;

export const FormRow = styled.div`
  margin-bottom: 30px;
  position: relative;
  span.beside-input {
    cursor: pointer;
    color: ${colorPalette.primary100};
    position: absolute;
    top: 40px;
    right: 0;
    &.username {
      right: -120px;
    }
    &.password {
      right: -140px;
      text-decoration: underline;
    }
    &.name {
      right: -170px;
    }
  }
`;

export const RoundProfileBoxWrapper = styled.div`
  position: relative;
  .delete-btn {
    position: absolute;
    bottom: 0;
  }
  .delete-icon {
    cursor: pointer;
    font-size: 1rem;
    &:hover {
      color: ${colorPalette.error};
    }
  }
  span.update-password {
    cursor: pointer;
    color: ${colorPalette.primary100};
    position: absolute;
    bottom: 0;
    right: 0;
    text-decoration: underline;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const RoundProfileBox = styled.div`
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
  background: #454545;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
  &:hover {
    color: #fff;
    .edit-icon {
      display: block;
    }
    img {
      opacity: 0.2;
    }
  }
  .edit-icon {
    display: none;
  }
  .add-icon,
  .edit-icon {
    position: absolute;
    top: 30%;
    left: 30%;
    vertical-align: 0;
    font-size: 3rem;
  }
`;
export const Role = styled.div.attrs((props: any) => ({
  type: props.type,
}))`
  display: inline-flex;
  padding: 0.175rem 0.9375rem;
  justify-content: center;
  align-items: center;
  margin: 0.225rem;
  border-radius: 3.125rem;
  color: ${colorPalette.white};
  text-transform: uppercase;
  ${(props) => {
    if (props.type === "Owner") {
      return `
        border: 1px solid ${colorPalette.Red1};
        background:  ${colorPalette.Red4};
      `;
    } else if (props.type === "Admin") {
      return `
        background: ${colorPalette.Orange4};
        border:  1px solid ${colorPalette.Orange1};
      `;
    } else if (props.type === "Instructor") {
      return `
        background:  ${colorPalette.Blue4};
        border: 1px solid ${colorPalette.primary100};
      `;
    } else if (props.type === "Student") {
      return `
        background:  ${colorPalette.Green4};
        border: 1px solid ${colorPalette.Green1};
      `;
    } else {
      return `
        background: ${GrayColorPalette.Gray6};
        border: 1px solid ${colorPalette.white};
    `;
    }
  }}
`;

export const RoleFloatingLabelSelect = styled(FloatingLabelSelect)`
  .ant-select:not(.ant-select-customize-input).ant-select-disabled
    .ant-select-selector,
  .ant-select:not(.ant-select-customize-input).ant-select-disabled:hover
    .ant-select-selector {
    border-bottom: none;
  }
`;
