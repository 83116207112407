import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DiagnosticTask,
  UpdateDiagnosticTask,
} from "../../../../models/DiagnosticTask";
import { UpdateTimedActionSubTask } from "../../../../models/TimedActionSubTask";
import { Media } from "../../../../models/MediaInterface";

export interface diagnosticState {
  data: DiagnosticTask[];
  modalTitle: string;
  addUpdateModal: boolean;
  loading: boolean;
  diagnosticForUpdate: UpdateDiagnosticTask;
}

const initialState: diagnosticState = {
  data: [],
  diagnosticForUpdate: {
    Subtasks: [] as UpdateTimedActionSubTask[],
    AttachedMedias: [] as Media[],
  } as UpdateDiagnosticTask,
  modalTitle: "",
  addUpdateModal: false,
  loading: true,
};

export const diagnosticSlice = createSlice({
  name: "diagnostic",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<DiagnosticTask[]>) => {
      state.data = action.payload;
    },
    setModalTitle: (state, action: PayloadAction<string>) => {
      state.modalTitle = action.payload;
    },
    setAddUpdateModal: (state, action: PayloadAction<boolean>) => {
      state.addUpdateModal = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    onDeleteSuccess: (state, action: PayloadAction<number>) => {
      state.data = state.data.filter((x) => x.Id !== action.payload);
    },
    onUpdateSuccess: (state, action: PayloadAction<DiagnosticTask>) => {
      const index = state.data.findIndex((x) => x.Id === action.payload.Id);
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    },
    onArchiveSuccess: (state, action: PayloadAction<number>) => {
      const index = state.data.findIndex((x) => x.Id === action.payload);
      if (index !== -1) {
        // state.data[index].IsArchived = !state.data[index].IsArchived;
      }
    },
    onCreateSuccess: (state, action: PayloadAction<DiagnosticTask>) => {
      state.data.unshift(action.payload);
    },

    resetDiagnosticForUpdate: (state, action: PayloadAction<null>) => {
      state.diagnosticForUpdate = initialState.diagnosticForUpdate;
    },
    setDiagnosticForUpdate: (
      state,
      action: PayloadAction<UpdateDiagnosticTask>,
    ) => {
      state.diagnosticForUpdate = action.payload;
    },
  },
});

export default diagnosticSlice.reducer;

export const {
  setData,
  setModalTitle,
  setAddUpdateModal,
  setLoading,
  onUpdateSuccess,
  onCreateSuccess,
  onDeleteSuccess,
  onArchiveSuccess,
  resetDiagnosticForUpdate,
  setDiagnosticForUpdate,
} = diagnosticSlice.actions;
