import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { SectionForCreateThunk } from "../../models/ScriptInterfaces";
//create a default error message in case one wasn't provided
/*function defaultErrorMessage(err: any) {
  console.log(err);
  if (typeof err === "string") {
    console.log(err);
  } else {
    console.log(JSON.stringify(err.response.data.ErrorMessage));
  }
}*/

export function CreateSection(
  filename: any,
  callback: any,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/section/CreateSection`, filename)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export const CreateSectionThunk: any = createAsyncThunk(
  `section/CreateSection`,
  async (newSection: SectionForCreateThunk) => {
    try {
      const result = await axios.post(
        "/api/1/pfc/section/CreateSection",
        newSection,
      );
      const response = result.data;
      console.log("response", response);
      // dispatch(SetNewSectionId(response.Result.Id));
      return { response, newSection };
    } catch (err) {
      console.log("Err", err);
    }
  },
);

export function UpdateSection(
  filename: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/section/Update`, filename)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function UpdateSectionOrder(
  SectionOrder: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/section/UpdateOrder`, SectionOrder)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function CopyOrMoveSection(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/section/CopyOrMove`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
export function DeleteSection(
  id: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`/api/1/pfc/section/delete/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
