import { MultipleSwitchContainer } from "../../styles/MainList.styled";
import SwitchWithLabel from "../Switch/SwitchWithLabel";
import RatingWithPainAndShock from "../TreatmentEdit/RatingWithPainAndShock";
import * as React from "react";
import { useAppSelector } from "../../store/hooks";
import { Tooltip } from "antd";

interface DurationProps {
  isDurationEnabled: boolean;
  onChange: Function;
  isDefineDurationTimeEnabled: boolean;
  isReadOnly: boolean;
}

export const DurationTa = (props: DurationProps) => {
  const { defineDurationTime } = useAppSelector(
    (state) => state.FeatureFlagReducer.durationTA,
  );
  const handleDurationToggle = (type: string) => {
    if (props.isReadOnly) return; //disable action callback in read only script
    props.onChange(type);
  };

  return (
    <div className="durationContainer">
      <MultipleSwitchContainer
        className="durationToggleContainer"
        isSecondSwitchEnabled={props.isDefineDurationTimeEnabled}
        isFirstSwitchEnabled={props.isDurationEnabled}
      >
        <div
          className="duration"
          onClick={() => handleDurationToggle("IsDurationEnabled")}
        >
          <SwitchWithLabel
            isForTreatment={true}
            rightLabel={"Duration"}
            checked={props.isDurationEnabled}
            onChange={() => undefined}
            disabled={props.isReadOnly}
          />
        </div>
        {props.isDurationEnabled && defineDurationTime && (
          <Tooltip title="Hidden in production" placement="bottom">
            <div
              className="durationTime"
              onClick={() =>
                handleDurationToggle("IsDefineDurationTimeEnabled")
              }
            >
              <SwitchWithLabel
                isForTreatment={true}
                rightLabel={"Define Duration Time"}
                checked={props.isDefineDurationTimeEnabled}
                onChange={() => undefined}
                disabled={props.isReadOnly}
              />
            </div>
          </Tooltip>
        )}
      </MultipleSwitchContainer>
      {props.isDurationEnabled &&
        props.isDefineDurationTimeEnabled &&
        defineDurationTime && (
          <Tooltip title="Hidden in production" placement="left">
            <div className="ratings-container">
              <RatingWithPainAndShock
                ratingType={"Excellent"}
                ratingTime={`5`}
                isCustomLabelActive={true}
                isReadOnly={props.isReadOnly}
              />
              <RatingWithPainAndShock
                ratingType={"Pass"}
                ratingTime={"10"}
                isCustomLabelActive={true}
                isReadOnly={props.isReadOnly}
              />
              <RatingWithPainAndShock
                ratingType={"Fail"}
                ratingTime={"10"}
                isCustomLabelActive={true}
                isReadOnly={props.isReadOnly}
              />
              <RatingWithPainAndShock
                isShockOrPain={true}
                shockOrPainText="Shock"
                isCustomLabelActive={true}
                isReadOnly={props.isReadOnly}
              />
              <RatingWithPainAndShock
                isShockOrPain={true}
                shockOrPainText="Pain"
                isCustomLabelActive={true}
                isReadOnly={props.isReadOnly}
              />
            </div>
          </Tooltip>
        )}
    </div>
  );
};
