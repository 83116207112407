import { Row } from "antd";
import React from "react";
import { TimedActionSubTaskContainer } from "./TimedActionBlockSubtask.styled";

type Props = {
  total: number;
  failed: number;
};

const TimedActionBlockSubtask = ({ total, failed, ...props }: Props) => {
  return (
    <TimedActionSubTaskContainer>
      <Row className="number failed-number">{failed}</Row>
      <Row>
        <hr />
      </Row>
      <Row className="number">{total}</Row>
    </TimedActionSubTaskContainer>
  );
};

export default TimedActionBlockSubtask;
