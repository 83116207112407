import { ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";
import React from "react";
import { GreenButton, GreyButton } from "../../styled";
import { StyledFooter } from "../InjuryTab.styled";
import { isNullOrUndefined } from "../../../../../../utils/utilityfunctions";

type Props = {
  formik: any;
  onBack: Function;
  setIsSaveBtnPressed: Function;
};

const EditInsultFooter = ({ formik, onBack, setIsSaveBtnPressed }: Props) => {
  return (
    <StyledFooter>
      <GreyButton htmlType="button" onClick={() => onBack()}>
        <ArrowLeftOutlined /> &nbsp; Back
      </GreyButton>
      {!formik.dirty && formik.isValid && (
        <GreyButton
          htmlType="submit"
          onClick={() => {
            formik.submitForm();
            setIsSaveBtnPressed(true);
          }}
        >
          <SaveOutlined /> Save
        </GreyButton>
      )}
      {(!formik.isValid || isNullOrUndefined(formik.values["locationId"])) && (
        <GreyButton className="disable-btn" htmlType="submit">
          <SaveOutlined /> Save
        </GreyButton>
      )}
      {formik.dirty &&
        formik.isValid &&
        !isNullOrUndefined(formik.values["locationId"]) && (
          <GreenButton
            htmlType="submit"
            onClick={() => {
              formik.submitForm();
              setIsSaveBtnPressed(true);
            }}
          >
            <SaveOutlined /> Save
          </GreenButton>
        )}
    </StyledFooter>
  );
};

export default EditInsultFooter;
