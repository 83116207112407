import * as React from "react";
import { SingularSwitchContainer } from "../../styles/MainList.styled";
import SwitchWithLabel from "../Switch/SwitchWithLabel";
import RatingWithPainAndShock from "../TreatmentEdit/RatingWithPainAndShock";
import { useAppSelector } from "../../store/hooks";
import { Tooltip } from "antd";

interface DefineStartTimeProps {
  handleOptionToggle: Function;
  isDefineStartTimeEnabled: boolean;
  isReadOnly: boolean;
}

export const DefineStartTimeTA = (props: DefineStartTimeProps) => {
  const { defineStartTime } = useAppSelector(
    (state) => state.FeatureFlagReducer.startTime,
  );

  return (
    <>
      {defineStartTime && (
        <div className="durationContainer defineStartTime">
          <SingularSwitchContainer
            className=""
            isSecondSwitchEnabled={false}
            isFirstSwitchEnabled={props.isDefineStartTimeEnabled}
          >
            <Tooltip title="Hidden in production" placement="left">
              <div
                className="defineStartTimeClickableContainer"
                onClick={() =>
                  props.handleOptionToggle("IsDefineStartTimeEnabled")
                }
              >
                <SwitchWithLabel
                  isForTreatment={true}
                  rightLabel={"Define Start Time"}
                  checked={props.isDefineStartTimeEnabled}
                  onChange={() => undefined}
                  disabled={props.isReadOnly}
                />
              </div>
            </Tooltip>
          </SingularSwitchContainer>
          {props.isDefineStartTimeEnabled && (
            <Tooltip title="Hide in production" placement="left">
              <div className="ratings-container">
                <RatingWithPainAndShock
                  ratingType={"Excellent"}
                  ratingTime={`5`}
                  isCustomLabelActive={true}
                  isReadOnly={props.isReadOnly}
                />
                <RatingWithPainAndShock
                  ratingType={"Pass"}
                  ratingTime={"10"}
                  isCustomLabelActive={true}
                  isReadOnly={props.isReadOnly}
                />
                <RatingWithPainAndShock
                  ratingType={"Fail"}
                  ratingTime={"10"}
                  isCustomLabelActive={true}
                  isReadOnly={props.isReadOnly}
                />
                <RatingWithPainAndShock
                  isShockOrPain={true}
                  shockOrPainText="Shock"
                  isCustomLabelActive={true}
                  isReadOnly={props.isReadOnly}
                />
                <RatingWithPainAndShock
                  isShockOrPain={true}
                  shockOrPainText="Pain"
                  isCustomLabelActive={true}
                  isReadOnly={props.isReadOnly}
                />
              </div>
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
};
