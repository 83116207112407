import React, { useRef, useEffect } from "react";
import { ModalFuncProps } from "antd/lib/modal";
import { ModalConfirm } from "./ConfirmDialog.styled";

import {
  ExclamationCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Button from "../Button/Button";

type typeOfIcon =
  | "info"
  | "success"
  | "error"
  | "warn"
  | "warning"
  | "confirm"
  | undefined;

const ConfirmDialog = (props: ModalFuncProps) => {
  const { content, open, type } = props;

  const getIcon = (iconType: typeOfIcon) => {
    switch (iconType) {
      case "info":
        return <InfoCircleOutlined style={{ color: "blue" }} />;
      case "error":
        return <ExclamationCircleOutlined style={{ color: "red" }} />;
      case "warning":
        return <WarningOutlined style={{ color: "orange" }} />;
    }
  };

  const getTitle = (type: typeOfIcon) => {
    switch (type) {
      case "info":
        return "PRO TIP";
      case "error":
        return "CRITICAL";
      case "warning":
        return "WARNING";
    }
  };

  const getFooter = (props: ModalFuncProps) => {
    const {
      okText = "Confirm",
      onOk,
      cancelText = "Cancel",
      onCancel,
      open,
    } = props;

    const onMouseLeave = () => {
      document.getElementById("accept-btn");
    };

    const onMouseOver = () => {
      document.getElementById("accept-btn")?.blur();
    };

    useEffect(() => {
      setTimeout(() => {
        onMouseLeave();
      }, 10);
    }, [open]);

    return (
      <>
        {props.onCancel && (
          <Button
            onClick={onCancel}
            shape={"rounded"}
            type="default"
            onMouseLeave={onMouseLeave}
            onMouseOver={onMouseOver}
          >
            {cancelText}
          </Button>
        )}
        <Button
          id={"accept-btn"}
          type="primary"
          shape={"rounded"}
          onClick={onOk}
        >
          {okText}
        </Button>
      </>
    );
  };

  return (
    <ModalConfirm
      className={"ModalDialogRef"}
      open={open}
      closable={false}
      footer={getFooter(props)}
      width={"max-content"}
      style={{ maxWidth: 500 }}
    >
      <div className="ant-modal-confirm-body">
        <div className={`ant-modal-confirm-title ${type}`}>
          {getIcon(type)}
          <span className="title">{getTitle(type)}</span>
        </div>
        <div className="ant-modal-confirm-content">{content}</div>
      </div>
    </ModalConfirm>
  );
};

export default ConfirmDialog;
