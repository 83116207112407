import { Badge, Button, Col, Row } from "antd";
import "antd/dist/antd.css";
import * as React from "react";
import { useEffect, useState } from "react";
import "./sectionComponent.scss";
import { isNullOrUndefined } from "../../utils/utilityfunctions";
import {
  CameraOutlinedIcons,
  CheckOutLinedIcon,
  CompassOutlinedIcons,
  EditOutlinedIcons,
  ExclamationCircleOutlinedIcons,
  FrownOutlinedIcons,
  SoundOutlinedIcons,
  UserOutlinedIcons,
} from "../../IconsTheme/Icons.global";
import {
  ListCol,
  ListItemQtySpan,
  ListRow,
  TextBlockCol,
  ThreeCircles,
  TimedActionBody,
  TimedActionDescription,
  TimedActionMedicationUseCase,
  TimedActionProperty,
} from "../../styles/MainList.styled";
import { CurrentColumnCount } from "./EditComponents/EditFormTheme";
import { TextBlock } from "./EditComponents/TextEdit";
import { ScriptListBlock } from "./EditComponents/ListEdit";
import { BlockType } from "../../models/Enums";
import { ResearchQuestionBlock } from "./EditComponents/ResearchQuestion/ResearchQuestionEdit";
import { MiscellaneousTaskBlock } from "../../models/MiscellaneousTaskBlock";
import { DiagnosticTaskBlock } from "../../models/DiagnosticTaskBlock";
import { TreatmentTaskBlock } from "../../models/TreatmentTaskBlock";
import { MedicationTaskBlock } from "../../models/MedicationTaskBlock";
import { MedicationTask } from "../../models/MedicationTask";
import { Block, Medication } from "../../models/ScriptInterfaces";

export interface HeaderProps {
  ParentComponentType: string;
  ParentComponentName: string;
  Position: number;
}

export const EditHeader = (props: HeaderProps) => {
  return (
    <React.Fragment>
      <div className="">
        <span style={{ margin: "0 auto" }}>{props.ParentComponentType}</span>
        <span style={{ margin: "0 auto" }}>{props.ParentComponentName}</span>
        <span style={{ margin: "0 auto" }}>Position</span>
        <span style={{ margin: "0 auto" }}>{props.Position}</span>
      </div>
    </React.Fragment>
  );
};

export interface Section {
  title: string;
  order: number;
}

export const Section = (props: Section) => {
  return (
    <div className="subListItemContainer">
      <div className="">
        <span style={{ margin: "0 auto" }}>
          Section {props.order} {props.title}
        </span>
      </div>
    </div>
  );
};

interface BlockInterface {
  block: TextBlock | ScriptListBlock | ResearchQuestionBlock | MedicationBlock;
  currentPhaseIndex?: number;
  type?: string;
}

export const TextSection = (props: BlockInterface) => {
  const block = props.block as TextBlock;
  console.log("is title enabled", block.IsTitleEnabled);
  return (
    <TextBlockCol
      className="subListItemContainer"
      important={
        block.BlockContent.IsImportant
          ? !!block.BlockContent.IsImportant
          : undefined
      }
      title={block.BlockContent.Title}
    >
      <div
        className="textInCenterPane"
        id="textInCenterPane"
        onClick={() => console.log("Current columns:", CurrentColumnCount)}
      >
        <div className="importantTextBlockContent">
          <div className="importantText">
            {block.BlockContent.IsImportant ? (
              <>
                <ExclamationCircleOutlinedIcons className="importantExclamation" />
                &nbsp; IMPORTANT:
              </>
            ) : null}
            {block.BlockContent.IsTitleEnabled && (
              <div
                className={`textBlockTitle-${block.BlockContent.Title} mainTextTitle`}
              >
                {block.BlockContent.Title !== "" && block.BlockContent.Title}
              </div>
            )}
          </div>
        </div>
        <div className="mainTextBlockContent">
          {block.BlockContent.IsImportant ? (
            <div className="importantSideBannerParentDiv">
              <hr className="importantSideBanner" />
            </div>
          ) : (
            ""
          )}
          <div
            className="textSectionDescription"
            style={{
              wordBreak: "break-word",
              columnCount: `${
                block.BlockContent.DisplayedAsTwoColumns ? 2 : 1
              }`,
            }}
          >
            {block.BlockContent.Description === "" &&
            block.BlockContent.Title === ""
              ? "New Text"
              : block.BlockContent.Description}
          </div>
        </div>
      </div>
    </TextBlockCol>
  );
};

const DisplayListItem = (
  blockList: any,
  displayedAsTwoColumns: boolean,
  isOrderedList: boolean,
  quantityEnabled: boolean,
  isTitleDisplayed: boolean,
) => {
  const [leftBlockList, setLeftBlockList] = useState<any>([]);
  const [rightBlockList, setRightBlockList] = useState<any>([]);
  useEffect(() => {
    if (displayedAsTwoColumns) {
      if (blockList.length === 1) {
        setLeftBlockList(blockList.slice(0, blockList.length));
        setRightBlockList([]);
      } else if (blockList.length % 2 === 0 && blockList.length !== 1) {
        setLeftBlockList(blockList.slice(0, blockList.length / 2));
        setRightBlockList(blockList.slice(blockList.length / 2));
      } else {
        setLeftBlockList(blockList.slice(0, blockList.length / 2 + 1));
        setRightBlockList(blockList.slice(blockList.length / 2 + 1));
      }
      console.log(blockList);
    } else {
      setLeftBlockList(blockList);
      setRightBlockList([]);
    }
  }, [blockList, displayedAsTwoColumns]);
  return (
    <>
      {isTitleDisplayed || blockList.length > 0 ? (
        blockList.length > 0 ? (
          <ListRow>
            {displayedAsTwoColumns ? (
              <>
                <ListCol span={12}>
                  {leftBlockList.map((item: any, index: any) => (
                    <div key={`${item.Id}${index}`}>
                      {isOrderedList ? (
                        <div key={index} className="orderedList">
                          <div>
                            <span className="indexSpan">
                              {parseInt(blockList.indexOf(item)) + 1}
                            </span>
                            <p className={`listBlockTitle-${item.Id}`}>
                              {item.Title}
                            </p>
                          </div>
                          {quantityEnabled && (
                            <ListItemQtySpan>
                              &times;&nbsp;{item.Quantity}
                            </ListItemQtySpan>
                          )}
                        </div>
                      ) : (
                        <div key={index} className="unorderedList">
                          <div>
                            <span className="indexSpan">&bull;</span>
                            <p className={`listBlockTitle-${item.Id}`}>
                              {item.Title}
                            </p>
                          </div>
                          {quantityEnabled && (
                            <ListItemQtySpan>
                              &times;&nbsp;{item.Quantity}
                            </ListItemQtySpan>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </ListCol>
                <ListCol span={12}>
                  {rightBlockList.map((item: any, index: any) => (
                    <div key={`${item.Id}${index}`}>
                      {isOrderedList ? (
                        <div key={index} className="orderedList">
                          <div>
                            <span className="indexSpan">
                              {parseInt(blockList.indexOf(item)) + 1}
                            </span>
                            <p className={`listBlockTitle-${item.Id}`}>
                              {item.Title}
                            </p>
                          </div>
                          {quantityEnabled && (
                            <ListItemQtySpan>
                              &times;&nbsp;{item.Quantity}
                            </ListItemQtySpan>
                          )}
                        </div>
                      ) : (
                        <div key={index} className="unorderedList">
                          <div>
                            <span className="indexSpan">&bull;</span>
                            <p className={`listBlockTitle-${item.Id}`}>
                              {item.Title}
                            </p>
                          </div>
                          {quantityEnabled && (
                            <ListItemQtySpan>
                              &times;&nbsp;{item.Quantity}
                            </ListItemQtySpan>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </ListCol>
              </>
            ) : (
              <>
                <ListCol span={24} style={{ paddingRight: 0 }}>
                  {leftBlockList.map((item: any, index: any) => (
                    <div key={`${item.Id}${index}`} className="singleColumn">
                      {isOrderedList ? (
                        <div key={index} className="orderedList">
                          <div>
                            <span className="indexSpan">
                              {parseInt(blockList.indexOf(item)) + 1}
                            </span>
                            <p className={`listBlockTitle-${item.Id}`}>
                              {item.Title}
                            </p>
                          </div>
                          {quantityEnabled && (
                            <ListItemQtySpan>
                              &times;&nbsp;{item.Quantity}
                            </ListItemQtySpan>
                          )}
                        </div>
                      ) : (
                        <div key={index} className="unorderedList">
                          <div>
                            <span className="indexSpan">&bull;</span>
                            <p className={`listBlockTitle-${item.Id}`}>
                              {item.Title}
                            </p>
                          </div>
                          {quantityEnabled && (
                            <ListItemQtySpan>
                              &times;&nbsp;{item.Quantity}
                            </ListItemQtySpan>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </ListCol>
              </>
            )}
          </ListRow>
        ) : null
      ) : (
        <span className="emptyListItems">New List</span>
      )}
    </>
  );
};

export const ListSection = (props: BlockInterface) => {
  const block = props.block as ScriptListBlock;
  console.log("props.block.BlockContent.ListItems.Title", block.BlockContent);
  return (
    <div className="subListItemContainer listSection">
      {!isNullOrUndefined(block) ? (
        !isNullOrUndefined(block.BlockContent) ? (
          !isNullOrUndefined(block.BlockContent.ListItems) ? (
            <div>
              <div className="titleAndListItemDiv">
                <span
                  className={`listBlockTitle-${block.BlockContent.Title} listTitle`}
                >
                  {block.BlockContent.IsTitleEnabled &&
                    block.BlockContent.Title !== "" &&
                    block.BlockContent.Title}
                </span>
                <span>
                  {DisplayListItem(
                    block.BlockContent.ListItems,
                    block.BlockContent.DisplayedAsTwoColumns,
                    block.BlockContent.IsOrderedList,
                    block.BlockContent.QuantityEnabled,
                    block.BlockContent.IsTitleEnabled &&
                      block.BlockContent.Title !== "",
                  )}
                </span>
              </div>
            </div>
          ) : (
            0
          )
        ) : (
          0
        )
      ) : (
        0
      )}
    </div>
  );
};

export const ThreeCirclesInTreatmentBlock = () => {
  return (
    <div className="threeCirclesContainer">
      <ThreeCircles bgcolor={"052125"} color={"23D0EB"} />
      <ThreeCircles bgcolor={"3F2902"} color={"FEA40A"} />
      <ThreeCircles bgcolor={"401010"} color={"FF4242"} />
    </div>
  );
};

interface TimedActionTaskSectionInterface {
  block:
    | MiscellaneousTaskBlock
    | DiagnosticTaskBlock
    | TreatmentTaskBlock
    | MedicationTaskBlock;
  type: BlockType;
}
export const TimedActionTaskSection = ({
  block,
  type,
}: TimedActionTaskSectionInterface) => {
  const { BlockContent } = block;
  const { TimedActionTaskContent } = BlockContent;
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const getTime = () => {
    if (!isNullOrUndefined(TimedActionTaskContent.IntervalTime)) {
      setHours(Math.floor(TimedActionTaskContent.IntervalTime / 60));
      setMinutes(Math.floor(TimedActionTaskContent.IntervalTime % 60));
    }
  };

  const defaultRoute = (
    block.BlockContent.TimedActionTaskContent as MedicationTask
  ).CurrentRouteOfAdmin;

  useEffect(() => {
    getTime();
  }, [TimedActionTaskContent.IntervalTime]);

  const timedActionTaskNameSymbols = () => {
    switch (type) {
      case BlockType.Miscellaneous:
        return "Mx";
      case BlockType.Diagnostic:
        return "Dx";
      case BlockType.Treatment:
        return "Tx";
      case BlockType.Medication:
        return "Rx";
      default:
        return "-";
    }
  };
  return (
    <div className="timedActionsContainer">
      <TimedActionBody className="treatmentBody" type={type}>
        <div className="timedActionName">{timedActionTaskNameSymbols()}</div>
        {type === BlockType.Medication ? (
          <TimedActionProperty
            isRequired={
              (block.BlockContent.TimedActionTaskContent as MedicationTask)
                .MedicationCompounds?.length > 0
            }
            style={{ width: "-webkit-fill-available" }}
          >
            <TimedActionMedicationUseCase
              isRequired={
                (block.BlockContent.TimedActionTaskContent as MedicationTask)
                  .UseCase1 ||
                (block.BlockContent.TimedActionTaskContent as MedicationTask)
                  .UseCase2 !== ""
              }
            >
              {(block.BlockContent.TimedActionTaskContent as MedicationTask)
                .UseCase1 ||
                (block.BlockContent.TimedActionTaskContent as MedicationTask)
                  .UseCase2 ||
                ""}
            </TimedActionMedicationUseCase>
            <span className="medicationCompound">
              {(block.BlockContent.TimedActionTaskContent as MedicationTask)
                .MedicationCompounds?.length > 0 ? (
                <>
                  {(block.BlockContent.TimedActionTaskContent as MedicationTask)
                    .Name || ""}
                  <span className="dosagePlusRoute">
                    &nbsp; (
                    {(
                      block.BlockContent
                        .TimedActionTaskContent as MedicationTask
                    ).MedicationCompounds[0].Dosage +
                      (
                        block.BlockContent
                          .TimedActionTaskContent as MedicationTask
                      ).MedicationCompounds[0].DosageUnit}
                    )
                  </span>
                  <span>
                    {(
                      block.BlockContent
                        .TimedActionTaskContent as MedicationTask
                    ).MedicationCompounds?.length > 1 && (
                      <>
                        &nbsp; <span>+</span>
                        &nbsp;{" "}
                        {
                          (
                            block.BlockContent
                              .TimedActionTaskContent as MedicationTask
                          ).MedicationCompounds[1].MedicationAbbreviation
                        }
                        <span className="dosagePlusRoute">
                          &nbsp; (
                          {(
                            block.BlockContent
                              .TimedActionTaskContent as MedicationTask
                          ).MedicationCompounds[1].Dosage +
                            (
                              block.BlockContent
                                .TimedActionTaskContent as MedicationTask
                            ).MedicationCompounds[1].DosageUnit}
                          )
                        </span>
                      </>
                    )}
                  </span>
                  <span className="dosagePlusRoute">
                    &nbsp;{" "}
                    {(
                      block.BlockContent
                        .TimedActionTaskContent as MedicationTask
                    ).RouteOfAdmins?.length > 0 &&
                    !isNullOrUndefined(defaultRoute)
                      ? `- ${defaultRoute.Abbreviation}`
                      : null}
                  </span>
                </>
              ) : (
                "--"
              )}
            </span>
            <span className="bottomSpan"></span>
          </TimedActionProperty>
        ) : (
          <TimedActionDescription
            isRequired={TimedActionTaskContent.Name?.length !== 0}
          >
            <p className="descriptionProperty">
              {TimedActionTaskContent.Name?.length === 0
                ? "--"
                : TimedActionTaskContent.Name}
            </p>
          </TimedActionDescription>
        )}
        <TimedActionProperty
          isRequired={TimedActionTaskContent.IsSubtasksEnabled}
        >
          <span>Subtasks</span>
          <span className="centerSpan">
            {TimedActionTaskContent.IsSubtasksEnabled &&
            !isNullOrUndefined(TimedActionTaskContent.Subtasks)
              ? `${TimedActionTaskContent.Subtasks.length}`
              : "--"}
          </span>
          <span className="bottomSpan"></span>
        </TimedActionProperty>
        <TimedActionProperty isRequired={TimedActionTaskContent.IsMediaEnabled}>
          <span>Media</span>
          <span className="centerSpan">
            {TimedActionTaskContent.IsMediaEnabled ? (
              <Badge
                size="small"
                count={
                  TimedActionTaskContent.AttachedMedias == null
                    ? 0
                    : TimedActionTaskContent.AttachedMedias?.length
                }
                color="#FF4242"
                className="noMediaBadge"
                // showZero
              >
                <CameraOutlinedIcons style={{ fontSize: "25px" }} />
              </Badge>
            ) : (
              "--"
            )}
          </span>
          <span className="bottomSpan"></span>
        </TimedActionProperty>
        <TimedActionProperty
          isRequired={TimedActionTaskContent.IsIntervalEnabled}
        >
          <span>Interval</span>
          {TimedActionTaskContent.IsIntervalEnabled ? (
            <span className="centerSpan" style={{ fontSize: ".85rem" }}>
              {typeof hours === "number" && hours < 10 ? `0${hours}` : hours} :{" "}
              {minutes < 10 ? `0${minutes}` : minutes}
            </span>
          ) : (
            <span className="centerSpan">--</span>
          )}
          {TimedActionTaskContent.IsIntervalEnabled ? (
            <span className="bottomSpan" style={{ fontSize: ".55rem" }}>
              Hrs&nbsp;&nbsp;&nbsp;&nbsp;Mins
            </span>
          ) : (
            <span className="bottomSpan" style={{ fontSize: ".55rem" }}></span>
          )}
        </TimedActionProperty>
        <TimedActionProperty
          isRequired={TimedActionTaskContent.IsDurationEnabled}
        >
          <span>Duration</span>
          <span className="centerSpan">
            {TimedActionTaskContent.IsDurationEnabled ? (
              <div className="checkMarkThreeCircles">
                <CheckOutLinedIcon className="checkmark" />
              </div>
            ) : (
              "--"
            )}
          </span>
          <span className="bottomSpan">
            {TimedActionTaskContent.IsDurationEnabled &&
              TimedActionTaskContent.IsDefineDurationTimeEnabled && (
                <ThreeCirclesInTreatmentBlock />
              )}
          </span>
        </TimedActionProperty>
        <TimedActionProperty
          isRequired={TimedActionTaskContent.IsDefineStartTimeEnabled}
        >
          <span>Start</span>
          <span className="centerSpan">
            {TimedActionTaskContent.IsDefineStartTimeEnabled ? (
              <div className="checkMarkThreeCircles">
                <CheckOutLinedIcon className="checkmark" />
              </div>
            ) : (
              "--"
            )}
          </span>
          <span className="bottomSpan">
            {TimedActionTaskContent.IsDefineStartTimeEnabled && (
              <ThreeCirclesInTreatmentBlock />
            )}
          </span>
        </TimedActionProperty>
        <TimedActionProperty
          isRequired={TimedActionTaskContent.IsRatingEnabled}
        >
          <span>Rating</span>
          <span className="centerSpan">
            {TimedActionTaskContent.IsRatingEnabled ? (
              <div className="checkMarkThreeCircles">
                <CheckOutLinedIcon className="checkmark" />
              </div>
            ) : (
              "--"
            )}
          </span>
          <span className="bottomSpan">
            {TimedActionTaskContent.IsCustomLabelsEnabled && (
              <ThreeCirclesInTreatmentBlock />
            )}
          </span>
        </TimedActionProperty>
      </TimedActionBody>
    </div>
  );
};

interface MedicationBlock extends Block {
  BlockContent: any;
}
// export const MedicationSection = (props: BlockInterface) => {
//   const block = props.block as MedicationBlock;
//   const [hours, setHours] = useState(0);
//   const [minutes, setMinutes] = useState(0);
//   const getTime = () => {
//     if (!isNullOrUndefined(block.BlockContent.IntervalTime)) {
//       setHours(Math.floor(block.BlockContent.IntervalTime / 60));
//       setMinutes(Math.floor(block.BlockContent.IntervalTime % 60));
//     }
//   };
//   useEffect(() => {
//     getTime();
//   }, [block.BlockContent.IntervalTime]);
//   const defaultRoute = block.BlockContent.Routes?.find(
//     (route: any) => route.Id === block.BlockContent.CurrentRouteOfAdminId,
//   );
//   return (
//     <div className="timedActionsContainer">
//       <TimedActionBody className="treatmentBody" type={props.type}>
//         <div className="timedActionName">Rx</div>
//         <TimedActionProperty isRequired={block.BlockContent.SubtasksEnabled}>
//           <span>Subtasks</span>
//           <span className="centerSpan">
//             {block.BlockContent.SubtasksEnabled &&
//             !isNullOrUndefined(block.BlockContent.Subtasks)
//               ? `${block.BlockContent.Subtasks.length}`
//               : "--"}
//           </span>
//           <span className="bottomSpan"></span>
//         </TimedActionProperty>
//         <TimedActionProperty
//           isRequired={block.BlockContent.MedicationCompounds.length > 0}
//           style={{ width: "-webkit-fill-available" }}
//         >
//           <TimedActionMedicationUseCase
//             isRequired={
//               block.BlockContent.UseCase1 || block.BlockContent.UseCase2 !== ""
//             }
//           >
//             {block.BlockContent.UseCase1 || block.BlockContent.UseCase2 || ""}
//           </TimedActionMedicationUseCase>
//           <span className="medicationCompound">
//             {block.BlockContent.MedicationCompounds.length > 0 ? (
//               <>
//                 {block.BlockContent.Label || ""}
//                 <span className="dosagePlusRoute">
//                   &nbsp; (
//                   {block.BlockContent.MedicationCompounds[0].Dosage +
//                     block.BlockContent.MedicationCompounds[0].DosageUnit}
//                   )
//                 </span>
//                 <span>
//                   {block.BlockContent.MedicationCompounds.length > 1 && (
//                     <>
//                       &nbsp; <span>+</span>
//                       &nbsp;{" "}
//                       {
//                         block.BlockContent.MedicationCompounds[1]
//                           .MedicationAbbreviation
//                       }
//                       <span className="dosagePlusRoute">
//                         &nbsp; (
//                         {block.BlockContent.MedicationCompounds[1].Dosage +
//                           block.BlockContent.MedicationCompounds[1].DosageUnit}
//                         )
//                       </span>
//                     </>
//                   )}
//                 </span>
//                 <span className="dosagePlusRoute">
//                   &nbsp;{" "}
//                   {block.BlockContent.Routes.length > 0 &&
//                   defaultRoute !== undefined
//                     ? `- ${defaultRoute.Abbreviation}`
//                     : null}
//                 </span>
//               </>
//             ) : (
//               "--"
//             )}
//           </span>
//           <span className="bottomSpan"></span>
//         </TimedActionProperty>
//         <TimedActionProperty isRequired={block.BlockContent.MediaEnabled}>
//           <span>Media</span>
//           <span className="centerSpan">
//             {block.BlockContent.MediaEnabled ? (
//               <Badge
//                 size="small"
//                 count={
//                   block.BlockContent.UploadedMediaItems == null
//                     ? 0
//                     : block.BlockContent.UploadedMediaItems!.length
//                 }
//                 color="#FF4242"
//                 showZero
//               >
//                 <CameraOutlinedIcons style={{ fontSize: "25px" }} />
//               </Badge>
//             ) : (
//               "--"
//             )}
//           </span>
//           <span className="bottomSpan"></span>
//         </TimedActionProperty>
//         <TimedActionProperty isRequired={block.BlockContent.IntervalEnabled}>
//           <span>Interval</span>
//           {!block.BlockContent.IntervalEnabled ? (
//             <span className="centerSpan">--</span>
//           ) : (
//             <span className="centerSpan" style={{ fontSize: ".85rem" }}>
//               {typeof hours === "number" && hours < 10 ? `0${hours}` : hours} :{" "}
//               {minutes < 10 ? `0${minutes}` : minutes}
//             </span>
//           )}
//           {block.BlockContent.IntervalEnabled ? (
//             <span className="bottomSpan" style={{ fontSize: ".55rem" }}>
//               Hrs&nbsp;&nbsp;&nbsp;&nbsp;Mins
//             </span>
//           ) : (
//             <span className="bottomSpan" style={{ fontSize: ".55rem" }}></span>
//           )}
//         </TimedActionProperty>
//         <TimedActionProperty isRequired={block.BlockContent.DurationEnabled}>
//           <span>Duration</span>
//           <span className="centerSpan">
//             {block.BlockContent.DurationEnabled ? (
//               <div className="checkMarkThreeCircles">
//                 <CheckOutLinedIcon className="checkmark" />
//               </div>
//             ) : (
//               "--"
//             )}
//           </span>
//           <span className="bottomSpan">
//             {block.BlockContent.DurationEnabled &&
//               block.BlockContent.DefineDurationTimeEnabled && (
//                 <ThreeCirclesInTreatmentBlock />
//               )}
//           </span>
//         </TimedActionProperty>
//         <TimedActionProperty
//           isRequired={block.BlockContent.DefineStartTimeEnabled}
//         >
//           <span>Start</span>
//           <span className="centerSpan">
//             {block.BlockContent.DefineStartTimeEnabled ? (
//               <div className="checkMarkThreeCircles">
//                 <CheckOutLinedIcon className="checkmark" />
//               </div>
//             ) : (
//               "--"
//             )}
//           </span>
//           <span className="bottomSpan">
//             {block.BlockContent.DefineStartTimeEnabled && (
//               <ThreeCirclesInTreatmentBlock />
//             )}
//           </span>
//         </TimedActionProperty>
//         <TimedActionProperty isRequired={block.BlockContent.RatingEnabled}>
//           <span>Rating</span>
//           <span className="centerSpan">
//             {block.BlockContent.RatingEnabled ? (
//               <div className="checkMarkThreeCircles">
//                 <CheckOutLinedIcon className="checkmark" />
//               </div>
//             ) : (
//               "--"
//             )}
//           </span>
//           <span className="bottomSpan">
//             {block.BlockContent.CustomLabelsEnabled && (
//               <ThreeCirclesInTreatmentBlock />
//             )}
//           </span>
//         </TimedActionProperty>
//         {/*</div>*/}
//       </TimedActionBody>
//     </div>
//   );
// };

export const PromptSection = (props: BlockInterface) => {
  const block = props.block as TextBlock;
  return (
    <TextBlockCol
      className="subListItemContainer"
      important={
        block.BlockContent.IsImportant
          ? !!block.BlockContent.IsImportant
          : undefined
      }
      title={"Role Player Instructions"}
    >
      <div className="textInCenterPane" id="textInCenterPane">
        <div className="importantTextBlockContent">
          {block.BlockContent.IsImportant && (
            <ExclamationCircleOutlinedIcons className="importantExclamation" />
          )}
          <div className="importantText">
            {block.BlockContent.IsImportant && "IMPORTANT:"}
            <div
              className={`textBlockTitle-RolePlayerInstructions mainTextTitle`}
            >
              <SoundOutlinedIcons className="soundIcon" />
              Role Player Instructions
            </div>
          </div>
        </div>
        <div className="mainTextBlockContent rolePlayerBlock">
          {block.BlockContent.IsImportant ? (
            <div className="importantSideBannerParentDiv">
              <hr className="importantSideBanner" />
            </div>
          ) : (
            ""
          )}
          <div
            className="textSectionDescription"
            style={{
              wordBreak: "break-word",
              columnCount: `${
                block.BlockContent.DisplayedAsTwoColumns ? 2 : 1
              }`,
            }}
          >
            {block.BlockContent.Description !== "" &&
              block.BlockContent.Description}
          </div>
        </div>
      </div>
    </TextBlockCol>
  );
};

export const SupportAction = (props: BlockInterface) => {
  const block = props.block as TextBlock;
  return (
    <TextBlockCol
      className="subListItemContainer"
      important={
        block.BlockContent.IsImportant
          ? !!block.BlockContent.IsImportant
          : undefined
      }
      title={"Support Team Instructions"}
    >
      <div className="textInCenterPane" id="textInCenterPane">
        <div className="importantTextBlockContent">
          {block.BlockContent.IsImportant && (
            <ExclamationCircleOutlinedIcons className="importantExclamation" />
          )}
          <div className="importantText">
            {block.BlockContent.IsImportant && "IMPORTANT:"}
            <div
              className={`textBlockTitle-SupportTeamInstructions mainTextTitle`}
            >
              <CompassOutlinedIcons className="compassIcon" />
              Support Team Instructions
            </div>
          </div>
        </div>
        <div className="mainTextBlockContent supportActionBlock">
          {block.BlockContent.IsImportant ? (
            <div className="importantSideBannerParentDiv">
              <hr className="importantSideBanner" />
            </div>
          ) : (
            ""
          )}
          <div
            className="textSectionDescription supportActionMapPlusDesc"
            style={{
              wordBreak: "break-word",
              columnCount: `${
                block.BlockContent.DisplayedAsTwoColumns ? 2 : 1
              }`,
            }}
          >
            <span>
              {block.BlockContent.Description !== "" &&
                block.BlockContent.Description}
            </span>
          </div>
        </div>
      </div>
    </TextBlockCol>
  );
};

export const CasualtyButtonBar = (props: any) => {
  return (
    <div>
      <div className="">
        <Button icon={<EditOutlinedIcons />}></Button>
        <Button> custom icon goes here</Button>
        <Button icon={<UserOutlinedIcons />}></Button>
        <Button icon={<FrownOutlinedIcons />}></Button>
      </div>
    </div>
  );
};
