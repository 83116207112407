import styled from "styled-components";
import { Tabs, Menu, Row, Col, Tooltip, Checkbox } from "antd";
import { BlockType } from "../models/Enums";

const { TabPane } = Tabs;

interface InterfaceSectionHeading {
  clikedHeading: boolean;
}

export const colorPalette = {
  primary100: "#23d0eb",
  primary75: "#21a3b7",
  primary50: "#1e7582",
  primary25: "#04191C",
  primary15: "#1a2c2e",
  secondary100: "#7afc17",
  secondary75: "#62c418",
  secondary50: "#4a8b18",
  secondary25: "#315219",
  secondary15: "#283c19",
  pch100: "#ffa857",
  pch75: "#c68548",
  pch50: "#8d6138",
  pch25: "#533d29",
  pch15: "#3c2f23",
  mag100: "#750238",
  mag75: "#5f0831",
  mag50: "#470e29",
  mag25: "#301321",
  mag15: "#28161e",
  gray100: "#1c1c1c",
  gray75: "#262626",
  gray50: "#464646",
  darkerGray: "rgba(255,255,255,0.05)",
  gray25: "#9a9a9a",
  gray15: "#cacaca",
  gray10: "#313131",
  success: "#7afc17",
  moderate: "#e6cf09",
  warning: "#fea40a",
  error: "#ff4242",
  hoverColor: "rgba(255, 255, 255, 0.15)",
  hoverColorHex: "#FFFFFF26",
  white: "#ffffff",
  disabledInput: "#363636",
  ratingGreen: "#01FF20",
  Red1: "#FF4242",
  Red4: "#210606",
  Orange1: "#FEA40A",
  Orange4: "#211502",
  Blue4: "#08191B",
  Green1: "#7AFC17",
  Green4: "#12190D",
};

export const primaryColorPalette = {
  primaryRadioBackground: "#052125",
};

export const GrayColorPalette = {
  Gray1: "#DDDDDD", // filled input border & text
  Gray2: "#9A9A9A", // default input, icon
  Gray3: "#363636", // disable input,icon
  Gray4: "#1F1F1F", // selected list, hovered white btn background
  Gray5: "#1A1A1A", // hovered list, ghost
  Gray6: "#131313", // default list
  Gray7: "#0D0D0D", // background
  Gray8: "#898989", // empty rating
  Gray9: "#BDBDBD",
  Gray100: "#1a1a1acc", // login form background
  GrayRadio: "#979797",
};
//Key is the % Transparencies
export const hexTransparencies = {
  100: "FF",
  99: "FC",
  98: "FA",
  97: "F7",
  96: "F5",
  95: "F2",
  94: "F0",
  93: "ED",
  92: "EB",
  91: "E8",
  90: "E6",
  89: "E3",
  88: "E0",
  87: "DE",
  86: "DB",
  85: "D9",
  84: "D6",
  83: "D4",
  82: "D1",
  81: "CF",
  80: "CC",
  79: "C9",
  78: "C7",
  77: "C4",
  76: "C2",
  75: "BF",
  74: "BD",
  73: "BA",
  72: "B8",
  71: "B5",
  70: "B3",
  69: "B0",
  68: "AD",
  67: "AB",
  66: "A8",
  65: "A6",
  64: "A3",
  63: "A1",
  62: "9E",
  61: "9C",
  60: "99",
  59: "96",
  58: "94",
  57: "91",
  56: "8F",
  55: "8C",
  54: "8A",
  53: "87",
  52: "85",
  51: "82",
  50: "80",
  49: "7D",
  48: "7A",
  47: "78",
  46: "75",
  45: "73",
  44: "70",
  43: "6E",
  42: "6B",
  41: "69",
  40: "66",
  39: "63",
  38: "61",
  37: "5E",
  36: "5C",
  35: "59",
  34: "57",
  33: "54",
  32: "52",
  31: "4F",
  30: "4D",
  29: "4A",
  28: "47",
  27: "45",
  26: "42",
  25: "40",
  24: "3D",
  23: "3B",
  22: "38",
  21: "36",
  20: "33",
  19: "30",
  18: "2E",
  17: "2B",
  16: "29",
  15: "26",
  14: "24",
  13: "21",
  12: "1F",
  11: "1C",
  10: "1A",
  9: "17",
  8: "14",
  7: "12",
  6: "0F",
  5: "0D",
  4: "0A",
  3: "08",
  2: "05",
  1: "03",
  0: "00",
};
const highlightedDropzoneBorderColor = colorPalette.primary25;
const highlightedDropzoneBackgroundColor = colorPalette.primary15;
export const DropzoneDivStyle = styled.div.attrs((props: any) => ({
  highlighted: props.highlighted,
}))`
  border: 1px dashed
    ${(props) =>
      props.highlighted ? highlightedDropzoneBorderColor : "transparent"};
  min-height: ${(props) => (props.highlighted ? "90%" : "")};
  background-color: ${(props) =>
    props.highlighted ? highlightedDropzoneBackgroundColor : "transparent"};

  .ant-tabs-nav {
    ${(props) =>
      props.highlighted &&
      `
      background-color: transparent;`}
  }

  .ant-tabs-nav-add {
    ${(props) =>
      props.highlighted &&
      `
      display: none;`}
  }
`;

export const SectionDivStyle = styled.div.attrs((props: any) => ({
  // selectedComponent will be true if the user is interacting with this section or a block within it
  // activeComponent will be true if the user is interacting specifically with a section object
  selectedComponent: props.selectedComponent,
  activeComponent: props.activeComponent,
  blockHovered: props.blockHovered,
  highlighted: props.highlighted,
}))`
  background-color: ${(props) => {
    if (props.highlighted) {
      return highlightedDropzoneBackgroundColor;
    }
    return props.selectedComponent && props.activeComponent
      ? colorPalette.primary25
      : props.selectedComponent
        ? colorPalette.darkerGray
        : colorPalette.gray100;
  }};
  border: 1px solid transparent;
  border-top: 1px solid
    ${(props) =>
      props.selectedComponent && props.activeComponent
        ? colorPalette.primary100
        : props.selectedComponent
          ? "white"
          : colorPalette.gray50};
  border-bottom: 1px solid
    ${(props) =>
      props.selectedComponent && props.activeComponent
        ? colorPalette.primary100
        : props.selectedComponent
          ? "white"
          : colorPalette.gray50};

  ${(props) =>
    props.highlighted &&
    `border: 1px dashed ${highlightedDropzoneBorderColor};`}
  .SectionHeading {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    font-size: 1.2em;
    line-height: 1.2em;
    margin: 0 0 8px;
    padding: 0;
  }

  .sectionDragDropPlusIcon {
    border-radius: 50px;
    font-weight: 700;
    padding: 5px;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    //margin: 14px 30px;
    color: rgba(255, 255, 255, 0.25);
  }

  .section-header-text {
    display: flex;
  }

  .SectionHeading:active {
    text-overflow: clip;

    white-space: pre-wrap;
    height: auto;
  }

  .section-drag-icon {
    color: ${colorPalette.gray75};
  }

  &:hover .section-drag-icon {
    color: ${(props) => (props.blockHovered ? colorPalette.gray75 : "white")};
  }

  &:hover {
    background-color: ${(props) => {
      return props.selectedComponent && props.activeComponent
        ? "rgba(4,25,28, 0.7)"
        : props.selectedComponent
          ? "rgba(70,70,70, 0.7)"
          : "rgba(70,70,70, 0.1)";
    }};
  }

  .expandedAddBlock {
    display: flex;
    //min-width: 300px;
    flex-direction: row;
    background: #1d1d1d;
    border: 1px solid #ffffff;
    border-radius: 50px;
    width: max-content;

    & > div {
      width: 45px;
    }

    &:hover {
      cursor: default;
    }

    @media (max-width: 1150px) {
      width: -webkit-fill-available;
    }
  }

  .disabledReactSvg {
    fill: rgba(255, 255, 255, 0.1);
  }

  .noHoverEffect {
    //width: 45px;
    //height: 45px;
  }

  .addBlockSvgIcons {
    fill: rgba(255, 255, 255, 0.5);

    &:hover {
      fill: rgba(255, 255, 255, 1);
    }
  }

  .expandedAddBlock {
    & > div:nth-child(2) {
      margin-left: 22px;
    }
  }

  .expandedAddBlock > div {
    cursor: pointer;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.05);

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
      color: rgba(255, 255, 255, 1);
    }
  }

  .addBlock {
    margin: 14px 30px;

    &:active {
    }

    &::-webkit-scrollbar-track {
      background-color: #131313;
    }

    &::-webkit-scrollbar-thumb {
      max-height: 20px;
      background: #23d0eb;
      border-radius: 50px;
    }
  }

  .criticalActionSectionStart {
    border-bottom: 1px solid #ffffff;
    margin: 20px 30px 0 30px;
    padding-bottom: 5px;
    color: #ffffff;
  }

  .criticalActionSectionEnd {
    border-top: 1px solid #ffffff;
    margin-bottom: 20px;
    padding-top: 5px;
    color: #ffffff;
  }

  .criticalActionSectionStart,
  .criticalActionSectionEnd,
  .aarReport {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }

  .criticalActionSectionStart,
  .criticalActionSectionEnd {
    margin-left: 30px;
    margin-right: 30px;
  }

  .aarReport {
    color: #23d0eb;
  }
`;

export const ListRow = styled(Row)`
  justify-content: space-between;
`;

export const BriefPhaseHeader = styled.div`
  display: flex;
  padding: 0.8rem 0.4rem;
  justify-content: space-between;
  align-items: center;
  background-color: ${colorPalette.gray100};

  .timerContainer {
    display: flex;
    justify-content: flex-end;

    & > div:nth-child(1) {
      display: flex;
      align-items: center;
      color: #ffffff;
      word-break: break-word;
      width: 40%;
    }

    & > div {
      padding-left: 5px;
    }
  }

  .colon {
    color: #ffffff;
    padding-left: 5px;
    font-size: 1.5rem;
  }

  .timer {
    text-align: center;
  }

  .timer > div {
    color: #ffffff;
    font-size: 1.5rem;
  }
`;
export const RightIconButton = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0.5rem 1rem;
  border-radius: 999px;
  max-height: 40px;

  .arrowIcon {
    margin-left: 0.7rem;
  }
`;

//Center panel
export const MainListRow = styled(Row)`
  justify-content: center;
  padding-top: 0.25%;
  background: rgba(128, 128, 128, 0.1);
  height: calc(100vh - 64px);

  .DragMenu {
    width: -webkit-fill-available;
    background: #101010;
    position: relative;

    & > section {
      position: absolute;
      width: 100%;
    }

    & .optionsBorderBottom {
      max-height: 100% !important;
      border-bottom: 5px solid rgba(255, 255, 255, 0.5);

      & > div > div > div > div > span {
        padding-top: 0.55rem !important;
        padding-bottom: 0.55rem !important;
        min-height: 46px !important;
      }
    }

    & > .options-list > div {
      max-height: 56px !important;

      & div > div > div {
        margin-bottom: 0 !important;

        & > div {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .modal-container .ant-btn-primary {
    border: none !important;
    background-color: transparent !important;
  }

  //.options-modal-body {
  //  height: auto;
  //  width: 209px;
  //  position: absolute;
  //  background-color: #262626;
  //  display: none;
  //  z-index: 1;
  //  right: 0;
  //  //top: 100%
  //}

  // .ant-modal-mask {
  //   background-color: transparent !important;
  // }

  .tooltip-action-text {
    position: absolute;
    height: 23px;
    left: 66px;
    top: calc(50% - 23px / 2 - 1px);
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    cursor: default;
  }

  .modal-function {
    position: relative;
    height: 52px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);
    width: auto !important;
    display: flex;
    align-items: center;
  }

  .modal-function:hover {
    background: rgba(43, 43, 43, 255);
    border-bottom: 2px solid #fff;
  }

  .rightSideBar {
    max-width: none;
  }

  .mainSectionColumn {
    display: block;
    flex: none;
    padding-left: 5px;
    width: 57.5% !important;
    max-width: none;

    @media (max-width: 1630px) {
      width: -webkit-fill-available;
    }
  }

  .DragMenu {
    height: -webkit-fill-available;

    @media (max-width: 1630px) {
      display: none;
    }
  }
  /* hide drag icon in read-only view */
  &.readOnly {
    .sublist-drag-icon,
    .section-drag-icon {
      opacity: 0;
      visibility: hidden;
    }
    .sublist-drag-icon,
    .ant-select-arrow {
      display: none;
    }
    button,
    input,
    select,
    textarea {
      user-select: none;
      cursor: not-allowed;
    }
    .right-label,
    .subtasksToggleContainer:hover {
      cursor: not-allowed;
    }
    .label,
    .ant-radio-disabled + span {
      color: rgb(154, 154, 154) !important;
    }
    .ant-input,
    .ant-select-selection-item {
      color: #ffff !important;
      &.ant-input {
        border-bottom: 1px solid rgb(154, 154, 154) !important;
        &:focus {
          background-color: transparent;
        }
      }
    }
    .auto-complete-input .ant-input[disabled] {
      &.ant-input-disabled {
        border-bottom: none !important;
      }
    }
    .ant-select:not(.ant-select-customize-input).ant-select-disabled
      .ant-select-selector {
      border-bottom: 1px solid rgb(154, 154, 154) !important;
    }
    .ant-switch-loading,
    .ant-switch-disabled {
      opacity: 1 !important;
    }
  }
`;

export const ListCol = styled(Col)`
  padding: 2px 5px;
  border-color: rgba(128, 128, 128, 0.3) !important;

  > div {
    //display: flex;
    //flex-direction: row;
    //justify-content: space-between;
    background: #1e1e1e;
    margin: 4px 0;
    //padding: 5px 10px 0 0;
  }

  .unorderedList {
    display: flex;
    justify-content: space-between;
    min-height: 41px;

    > div {
      display: flex;
    }

    .indexSpan {
      font-size: 1.51rem;
      padding: 0px 11px;
    }
  }

  .orderedList {
    display: flex;
    justify-content: space-between;
    min-height: 41px;

    > div {
      display: flex;
    }
  }

  .singleColumn {
    margin-left: 5px;
    margin-right: 5px;
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 0 !important;
    padding: 5px 10px;
    word-break: break-word;
  }

  .indexSpan,
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1px;
    padding: 5px 10px;
  }

  .indexSpan {
    border-width: 1px 1px !important;
    border-right-style: solid !important;
    border-color: rgba(0, 0, 0, 0.3) !important;
  }

  ul {
    margin-bottom: 0;
  }
`;
export const TextBlockCol = styled(Col).attrs((props: any) => ({
  important: props.important,
  title: props.title,
}))`
  position: relative;

  .importantSideBanner {
    width: 5.06px;
    height: 100%;
    background: #23d0eb;
    border: none;
  }

  .importantExclamation {
    color: #23d0eb;
  }

  .mainTextBlockContent {
    ${(props) =>
      props.important ? `margin: 10px 0px 10px 20px;` : `margin-left: 10px;`};

    &.rolePlayerBlock,
    &.supportActionBlock {
      margin-left: 34px;
    }
    display: flex;

    p {
      width: 93%;
    }
  }

  .textSectionDescription {
    white-space: pre-line;
  }

  .supportActionMapPlusDesc {
    align-items: flex-start;
    display: flex;
  }

  .soundIcon,
  .compassIcon {
    margin-right: 5px;
  }

  .importantSideBannerParentDiv {
    margin-right: 12px;
  }

  .importantTextBlockContent {
    display: flex;
    justify-content: flex-start;
    margin-bottom: ${(props) =>
      props.important && props.title ? "10px" : "0"};

    span {
      display: flex;
      align-items: center;
    }

    div {
      display: flex;
      margin-left: ${(props) => (props.important ? "10px" : "5px")};
    }
  }

  .mainTextTitle {
    word-break: break-word;
    display: ${(props) => (props.title === "" ? "none" : "flex")};
  }
`;
export const RequiredTreatmentDiv = styled.div.attrs((props: any) => ({
  background: props.background,
  border: props.border,
  criticalIcon: props.criticalIcon,
}))`
  // display: flex;
  // align-items: center;
  // justify-content: center;

  .trianglePosition {
    position: absolute;
    top: 31px;
    left: -31.5px;
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 24px solid ${(props) => props.background};
    color: ${(props) => props.criticalIcon};
    cursor: pointer;
    pointer-events: none;
  }

  .triangle {
    height: 1.25rem;
    width: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    overflow: hidden;
    position: absolute;
    top: 31px;
    left: -33px;
    border-radius: 20%;
    cursor: pointer;
    pointer-events: none;
  }
`;
export const TimedActionBody = styled.div.attrs((props: any) => ({
  type: props.type,
}))`
  border-left: ${(props) =>
    props.type === BlockType.Treatment
      ? "5px solid #23D0EB"
      : props.type === BlockType.Miscellaneous
        ? "5px solid #7D1EF6"
        : props.type === BlockType.Diagnostic
          ? "5px solid #FFFFFF"
          : "5px solid #7AFC17"};
  display: flex;
  align-items: center;
  width: 96%;

  & > div:last-of-type {
    margin-right: 0 !important;
  }

  & > div {
    height: 100%;
    margin-right: 1px;
    padding: 10px 0 10px 0;
    border-bottom: ${(props) =>
      props.type === BlockType.Treatment
        ? "1px solid #23D0EB"
        : props.type === BlockType.Miscellaneous
          ? "1px solid #7D1EF6"
          : props.type === BlockType.Diagnostic
            ? "1px solid #FFFFFF"
            : "1px solid #7AFC17"};
  }

  & > span {
    margin-right: 1px;
    background: green;
    height: 100%;
    width: 50%;
    position: absolute;
    //right: 0;
    top: 0;
    bottom: 0;
  }

  .timedActionName {
    color: ${(props) =>
      props.type === BlockType.Treatment
        ? "#23D0EB"
        : props.type === BlockType.Miscellaneous
          ? "#7D1EF6"
          : props.type === BlockType.Diagnostic
            ? "#FFFFFF"
            : "#7AFC17"};
    padding: 0 8px;
    height: -webkit-fill-available;
    display: flex;
    align-items: center;
  }
}
`;
export const TimedActionProperty = styled.div.attrs((props: any) => ({
  isRequired: props.isRequired,
}))`
  display: grid;
  grid-template-rows: inherit;
  grid-template-columns: 1fr;
  height: -webkit-fill-available;
  align-items: center;
  justify-content: space-between;
  min-width: 60px;

  > span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: ${(props) =>
      props.isRequired
        ? "rgba(255, 255, 255, 1)"
        : "rgba(255, 255, 255, 0.05)"};
  }

  .centerSpan {
    justify-content: center;
  }

  .checkmark {
    font-size: 1.5rem;
  }

  > span:first-of-type {
    align-self: flex-start;
  }

  > span:last-of-type {
    justify-content: center;
  }

  .checkMarkThreeCircles {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .threeCirclesContainer {
    display: flex;
  }

  .bottomSpan {
    min-height: 8px;
  }

  .medicationCompound {
    font-family: "Roboto", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    //color: #ffffff;

    .dosagePlusRoute {
      font-size: 0.7rem;
      color: rgba(255, 255, 255, 0.5) !important;
    }
  }
`;

export const ListItemQtySpan = styled.span`
  min-width: 65px;
  justify-content: left !important;
`;

export const TimedActionDescription = styled.div.attrs((props: any) => ({
  isRequired: props.isRequired,
}))`
  display: flex;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  align-items: center;
  justify-content: flex-start;

  .descriptionProperty {
    display: contents;
    padding: 0 19px;
    white-space: pre-line;
    color: ${(props) =>
      props.isRequired
        ? "rgba(255, 255, 255, 1)"
        : "rgba(255, 255, 255, 0.05)"};
  }
`;
export const TimedActionMedicationUseCase = styled.span.attrs((props: any) => ({
  isRequired: props.isRequired,
}))`
  color: ${(props) =>
    props.isRequired
      ? "rgba(255, 255, 255, 1)"
      : "rgba(255, 255, 255, 0.05)"} !important;
`;
export const ThreeCircles = styled.div.attrs((props: any) => ({
  color: props.color,
  bgcolor: props.bgcolor,
}))`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 999px;
  border: ${(props) => `1px solid #${props.color}`};
  background-color: ${(props) => `1px solid #${props.bgcolor}`};
`;

export const SectionHeadingStyle = styled.h1<InterfaceSectionHeading>`
  border: 2px solid red;
  width: 100%;
  height: 58px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:active,
  &:hover {
    height: auto;
    border: 2px solid yellow;
    white-space: pre-wrap;
    text-overflow: clip;
  }
`;
export const SectionCol = styled(Col)`
  .sideBar .ant-tabs-nav-wrap {
    justify-content: end;
  }

  .hideDragHandle {
    visibility: hidden;
  }

  .ant-tabs-nav,
  .mainSectionColumn .ant-tabs-nav {
    margin: 0 !important;
  }

  .addSectionButton {
    border-radius: 999px;
    padding: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 14px 30px;

    span {
      font-size: 20px;
    }

    .addSectionPlusIcon {
      font-size: 15px;
      margin-right: 5px;
    }
  }

  .hiddenDragContent {
    display: none;
  }

  .addSectionButton:hover {
    span {
      color: #ffffff;
    }
  }

  .addSectionButton:active {
    background: #202020;
  }

  .sideBar .lfNDHe {
    border-top: 0;
  }

  .sideBar .ant-tabs-nav-wrap {
    box-shadow: inset 0 0 0 1px white;
  }

  .sideBar .ant-tabs-nav {
    height: 47px !important;
    transform-style: preserve-3d;
  }

  .ant-tabs-nav-wrap {
    transform-style: preserve-3d;
  }

  .sideBar .ant-tabs-tab-active {
    border-width: 1px 1px 0 !important;
    border-style: solid !important;
    border-color: ${colorPalette.primary100} !important;
    background: rgba(35, 208, 235, 0.25) !important;
    transform: translateZ(-10px);
  }

  .chFOEv,
  .ezSwEj,
  .jWzkRx,
  .iSxpK {
    margin-top: 0 !important;
  }

  .chFOEv,
  .iSxpK {
    height: 47px !important;
  }

  .timedAction:hover {
    background-color: rgba(43, 43, 43, 255);
    box-shadow: inset 0rem -0.0625rem 0rem rgb(255, 255, 255);
    border-bottom: none !important;
  }

  .timedAction,
  .timedActions {
    font-size: 0.9375rem;
    margin: 0 auto;
    padding-left: 18px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    min-height: 52px;
    color: white;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .timedActions:hover {
    cursor: pointer;

    .timedActionsIcon {
      visibility: visible;
    }
  }

  .disabledTimedAction {
    color: rgba(255, 255, 255, 0.1) !important;

    .timedActionDropdownIcon {
      color: rgba(255, 255, 255, 0.1) !important;
    }
  }

  .timedActionDropdownIcon {
    padding-right: 10%;
  }

  .timedActionsIcon {
    visibility: hidden;
    font-size: 1.0375rem;
    padding-right: 8px;

    svg {
      position: relative !important;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  .drag-item-content {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    // font-size: clamp(10px, 0.78125vw, 18px);
    line-height: 24px;
    color: #fff;
    cursor: default;
    width: -webkit-fill-available;
    padding-left: 10px;

    @media (max-width: 1200px) {
      font-size: 14px;
    }
  }

  .activeContent:hover {
    cursor: grab;
  }

  .modal-function-dragMenu {
    position: relative;
    height: 50.76px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    @media (max-height: 720px) {
      height: 50px;
    }
  }

  .dragHandle {
    color: rgba(255, 255, 255, 0.25);
  }

  .modal-function-dragMenu:hover {
    background: #4b4b4b;

    .dragHandle {
      color: rgba(255, 255, 255, 1);
    }
  }

  .sideMenuItem {
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);
  }

  .modal-function-dragMenu svg {
    // position: relative !important;
    // left: 21px;
    // right: 161px;
    // top: 12px;
    // bottom: 13px;
    // color: #fff !important;
    // width: 25.71px;
    // height: 26.79px;
  }

  .options-modal-body,
  .options-list {
    height: calc(100% - 55px);
    overflow: hidden;
  }
  .options-modal-body > div {
    max-height: 56px !important;
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);
  }
`;

export const ToolTipStyled = styled(Tooltip)`
  //font-size: 11px;
`;

export const TabStyle = styled(Tabs)`
  height: 100%;

  .ant-tabs-content-holder {
    height: 100%;
  }

  .ant-tabs-nav-wrap {
    height: 108%;
    background: #161616;
    margin-right: 5px;
    box-shadow: inset 0 -5px 0 0 ${colorPalette.gray25};
  }

  .ant-tabs-content {
    margin-top: 5px;
    height: 100%;
  }

  .ant-tabs-tab + .ant-tabs-tab {
  }

  .ant-tabs-tab {
    border-bottom: 5px solid ${colorPalette.gray25} !important;
    padding: 12px 12px !important;
    min-width: 120px;
    margin-left: 0 !important;
    &:first-child,
    &:nth-child(2) {
      min-width: 4rem;
    }
  }

  .ant-tabs-tab:hover {
    color: #fff;
  }

  .ant-tabs-nav {
    background-color: ${colorPalette.gray75};
  }

  .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-tab {
    border-left: none !important;
    border-right: none !important;
    box-shadow: inset -1px 0 0 rgba(128, 128, 128, 0.1);
    border-top: none !important;
    background: #161616 !important;
  }

  .ant-tabs-nav-add {
    margin: 4px;
    border: 1px dashed rgba(255, 255, 255, 0.75) !important;
    border-radius: 53px !important;
    margin-left: 10px !important;
    height: 35px !important;
    min-width: 35px !important;
  }

  .ant-tabs-nav-add:hover {
    background: #4d4d4d;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
  }

  .ant-tabs-tab .anticon {
    margin-right: 0 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: ${colorPalette.primary25} !important;
    border-bottom: 5px solid ${colorPalette.primary100} !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f0f0f0 !important;
  }

  .ant-tabs-tab-btn {
    margin: auto;
  }

  .ant-tabs-content {
    height: 100%;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fill-available;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    margin-right: 5px;

    ::-webkit-scrollbar-track {
      background-color: #131313;
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      max-height: 20px;
      background: #23d0eb;
      border-radius: 50px;
    }
  }

  .ant-tabs-content::-webkit-scrollbar {
    width: 5px;
  }
`;

export const StyledTabPane = styled(TabPane)`
  //height: 100%;
  //height: -moz-available;          /* WebKit-based browsers will ignore this. */
  //height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  //height: fill-available;
  //overflow-y: scroll;
  //position: absolute;
  //width: 100%;
  //width: -moz-available;
  //width: -webkit-fill-available;
  //width: fill-available;
  //
  //
  //::-webkit-scrollbar-track{
  //  background-color: #131313;
  //}
  //
  //::-webkit-scrollbar-thumb {
  //  max-height: 20px;
  //  background: #23D0EB;
  //  border-radius: 50px;
  //}
`;

export const SectionLabel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1em;

  .section-drag-icon,
  .section-expand-collapse-icon {
    //width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-index-title {
    display: flex;
  }

  .section-drag-icon,
  .hidden-drag-icon {
    margin: 0 5px 8px;
    padding: 0;
  }

  .hidden-drag-icon {
    visibility: hidden;
    height: 0;
  }

  .section-header-text {
    flex-grow: 1;
  }

  .section-number {
    margin: 0;
    padding: 0;
    color: ${colorPalette.primary100};
    font-size: 0.6em;
  }

  .collapse-icon,
  .expand-icon {
    padding: 15px;

    &:hover {
      color: #ffffff;
    }

    &:active {
      color: #24d2ed;
    }
  }
`;

export const StyledMenu = styled(Menu.Item)`
  .ant-tooltip {
    display: none !important;
  }
`;

export const SubListItem = styled.div.attrs((props: any) => ({
  activeComponent: props.activeComponent,
}))`
  background-color: ${(props) =>
    props.activeComponent ? colorPalette.primary25 : colorPalette.gray100};
  padding: 10px 10px 10px 2px;
  //     pa  padding: 8px 0;
  // px 2px;
  border: 1px solid
    ${(props) =>
      props.activeComponent ? colorPalette.primary75 : colorPalette.gray50};
  color: white;
  display: flex;
  align-items: center;

  * {
    font-size: 1em;
  }

  .sublist-drag-icon {
    color: ${colorPalette.gray75};
    padding-right: 4px;
    padding-left: 8px;
    min-width: 30px;
  }

  &:hover {
    border: 1px solid
      ${(props) =>
        props.activeComponent ? colorPalette.primary75 : colorPalette.gray25};
    filter: brightness(150%);
    color: #ffffffb3;
  }

  &:hover .sublist-drag-icon {
    color: white;
  }
`;
export const TimedActionSubListItem = styled.div.attrs((props: any) => ({
  activeComponent: props.activeComponent,
}))`
  background-color: ${(props) =>
    props.activeComponent ? colorPalette.primary25 : colorPalette.gray100};
  border: 1px solid
    ${(props) =>
      props.activeComponent ? colorPalette.primary75 : colorPalette.gray50};
  color: white;
  display: flex;
  align-items: center;

  * {
    font-size: 1em;
  }

  .sublist-drag-icon {
    color: ${colorPalette.gray75};
    margin-right: 4px;
  }

  &:hover {
    border: 1px solid
      ${(props) =>
        props.activeComponent ? colorPalette.primary75 : colorPalette.gray25};
    filter: brightness(150%);
    color: #ffffffb3;
  }

  &:hover .sublist-drag-icon {
    color: white;
  }
`;

export const SubListItemWrapper = styled.div`
  padding: 8px 0;
`;
export const SubListDragDiv = styled.div.attrs((props: any) => ({
  clickedPosition: props.clickedPosition,
  clickedBottom: props.clickedBottom,
  isDragging: props.isDragging,
}))`
  .subListItem {
    position: relative;
    > div:last-of-type {
      width: -webkit-fill-available;
    }
    &.timedActionBlock {
      position: relative;
    }
  }

  .required {
    position: absolute;
    top: 27.8px;
    left: -33px;
  }
`;

export const FilterBarContainer = styled.div.attrs((props: any) => ({
  isDiagnostic: props.isDiagnostic,
}))`
  display: flex;
  align-items: flex-start;
  padding: 11px;
  padding-bottom: 1.5rem;
  border-bottom: ${(props) =>
    props.isDiagnostic ? "none" : "2px solid #101010"};

  & > span {
    width: 40px;
    margin-bottom: 5px;
    font-size: 1.5rem;
  }

  & > div {
    width: 100%;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
  }
`;

export const SwitchContainer = styled.div.attrs((props: any) => ({
  isOptionEnabled: props.isOptionEnabled,
  isMediaAdded: props.isMediaAdded,
}))`
  display: grid !important;
  grid-template-rows: inherit;
  grid-template-columns: 0.65fr 1fr;
  align-items: center;

  border-bottom: ${(props) =>
    props.isMediaAdded ? "none !important" : `2px solid #101010`};
  min-height: ${(props) =>
    props.isMediaAdded ? "70px !important" : `inherit`};

  .text {
    color: ${(props) =>
      props.isOptionEnabled ? "#ffffff" : "rgba(255, 255, 255, 0.5)"};
  }

  .intervalMinutesSSuffix {
    ::after {
      content: "min";
      position: absolute;
      bottom: 2px;
      right: 0.5em;
      transition: all 0.05s ease-in-out;
    }

    :hover::after,
    :focus-within::after {
      right: 0.5em;
    }

    /* handle Firefox (arrows always shown) */
    @supports (-moz-appearance: none) {
      ::after {
        right: 0.5em;
      }
    }
  }

  &:hover {
    .mediaUpload {
      button {
        box-shadow: none !important;
      }
    }

    button {
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
    }
  }

  .min {
    display: flex;
    align-items: end;
    margin-bottom: 0 !important;
    font-size: 0.9rem;
    height: 100%;
  }

  .isRequiured,
  .duration,
  .durationTime {
    display: flex;
    align-items: center;
  }

  .isRequiured {
    width: 30%;

    &.disabled > div,
    &.disabled > div:hover {
      cursor: not-allowed;

      .label {
        cursor: not-allowed;
      }
    }
  }

  .floatingSelectContainer {
    width: 60%;
    padding-top: 0 !important;

    .ant-select {
      width: 100%;
    }
  }

  .requiredPlusCritical {
    width: 100%;

    .isCritical {
      margin-top: 1rem;
    }
  }

  .subTaskSwitchContainer,
  .mediaUploadContainer,
  .requiredPlusPhaseSelect {
    display: flex;
  }

  .requiredPlusPhaseSelect {
    min-height: 44px;
    justify-content: space-between;
  }

  .intervalInputContainer {
    display: flex;
    align-items: flex-end;
    width: 40%;

    input {
      text-align: center;
    }

    .minus {
      color: rgba(255, 255, 255, 0.05);
      padding: 3px;

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }

    .plus {
      color: rgba(255, 255, 255, 0.05);
      padding: 3px;

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }

    > div {
      padding-top: 0 !important;
    }
  }

  .intervalInput {
    display: grid;
    grid-template-rows: inherit;
    grid-template-columns: 0.7fr 0.3fr;

    > div:first-of-type {
      padding-top: 0 !important;
    }
  }

  .intervalInputIcon {
    font-size: 1.6rem;
    margin-bottom: 5px;
    //background: rgba(255, 255, 255, 0.1);
  }

  .uploadMedia {
    min-height: 70px;
    display: grid;
    grid-template-rows: inherit;
    grid-template-columns: 1fr 1fr 1fr;
    height: -webkit-fill-available;

    > div:first-of-type {
      height: -webkit-fill-available;

      :hover {
        cursor: pointer;
      }
    }

    .duration:hover {
      span {
        color: #ffffff !important;
      }
    }
  }

  .noMediaBadge {
    display: hidden;
    color: blue;
  }

  .mediaTypes {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
  }

  .intervalContainer {
    width: 100%;
    height: -webkit-fill-available;

    &:hover {
      span {
        color: #ffffff;
      }
      cursor: pointer;
    }
  }
`;
export const UploadedMedia = styled.div`
  display: flex;
  width: 100%;
  max-height: 100px;
  margin-bottom: 5px;

  .mediaEditDelete {
    display: flex;
    flex-direction: column;
    .mediaEdit,
    .mediaDelete {
      height: 50%;
      cursor: pointer;
      span {
        font-size: 24px;
        color: rgba(255, 255, 255, 0.05);
      }
    }
    .mediaEdit {
      &:hover {
        span {
          color: #ffffff;
        }
      }
    }

    .mediaDelete {
      &:hover {
        span {
          color: #ff4242;
        }
      }
    }
  }

  .mediaEdit,
  .mediaDelete,
  .mediaContent {
    padding: 11.5px;
    background: #1e1e1e;
  }

  .mediaContent {
    width: 66%;
    width: calc(100% - 140px - 50px);
    margin: 0 1px;
    overflow-wrap: anywhere;
    span,
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      color: #ffffff;
    }
    span {
      font-size: 18px;
      line-height: 21px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p {
      font-size: 14px;
      line-height: 16px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .mediaImage {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 140px;
    height: inherit;

    img {
      width: auto;
      max-width: -webkit-fill-available;
      height: -webkit-fill-available;
      object-fit: contain;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;
export const RequiredPlusCriticalSwitchContainer = styled.div.attrs(
  (props: any) => ({
    isRequiredEnabled: props.isRequiredEnabled,
    isCriticalEnabled: props.isCriticalEnabled,
  }),
)`
  border-bottom: 2px solid #101010;
  align-items: center;
  display: flex;

  .ant-select-item-option-content {
    display: flex !important;
    justify-content: space-between !important;
  }

  .directSwitchContainer {
    :hover {
      button {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
      }
    }
  }

  .ant-select-selection-item {
    .TreatmentPhaseDropdownOption::before {
      content: "";
      height: 20px !important;
      width: 20px !important;
      background: #401010;
      margin-top: 5px;
      margin-right: 5px;
      border: 1px solid #ff4242 !important;
      border-radius: 999px !important;
    }

    .requiredTriangle {
      display: none !important;
    }
  }

  .isRequiured,
  .duration,
  .durationTime,
  .isCritical {
    display: flex;
    align-items: center;
  }

  .isRequiured {
    width: 30%;

    &.disabled > div,
    &.disabled > div:hover {
      cursor: not-allowed;

      .label {
        cursor: not-allowed;
      }
    }

    .text {
      color: ${(props) =>
        props.isRequiredEnabled ? "#ffffff" : "rgba(255, 255, 255, 0.5)"};
    }
  }

  .isCritical {
    visibility: ${(props) => (props.isRequiredEnabled ? "visible" : "hidden")};
    width: 100%;
    margin-top: 2rem;
    transition: visibility 2s ease-in-out;
    animation: fade-in 2s forwards;

    .text {
      color: ${(props) =>
        props.isCriticalEnabled ? "#ffffff" : "rgba(255, 255, 255, 0.5)"};
    }
  }

  .floatingSelectContainer {
    width: 60%;
    padding-top: 0 !important;

    .ant-select {
      width: 100%;
    }
  }

  .requiredPlusCritical {
    width: 100%;
    height: -webkit-fill-available;
  }

  .subTaskSwitchContainer,
  .mediaUploadContainer,
  .requiredPlusPhaseSelect {
    display: flex;
  }

  .requiredPlusPhaseSelect {
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .phaseDropdownContainer {
    display: flex;
    justify-content: space-between;
  }

  .disabledOption {
    background: #1e1e1e !important;
  }

  .ant-select-selection-item {
    .checkmark {
      display: none !important;
    }
  }
`;
export const MultipleSwitchContainer = styled.div.attrs((props: any) => ({
  isFirstSwitchEnabled: props.isFirstSwitchEnabled,
  isSecondSwitchEnabled: props.isSecondSwitchEnabled,
}))`
  min-height: inherit;
  align-items: center;
  display: grid;
  grid-template-rows: inherit;
  grid-template-columns: 0.65fr 1fr;

  > div > div {
    justify-content: flex-start !important;
  }

  .duration,
  .durationTime {
    display: flex;
    align-items: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;

    &:hover {
      cursor: pointer;
    }
  }

  .duration span {
    color: ${(props) =>
      props.isFirstSwitchEnabled ? "#ffffff" : "rgba(255, 255, 255, 0.5)"};
  }

  .durationTime span {
    color: ${(props) =>
      props.isSecondSwitchEnabled ? "#ffffff" : "rgba(255, 255, 255, 0.5)"};
  }

  .duration:hover,
  .durationTime:hover {
    span {
      color: #ffffff !important;
    }

    button {
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
    }
  }

  .subtasksToggleContainer {
    &:hover {
      cursor: pointer;
    }

    height: -webkit-fill-available;
  }

  .intervalInput {
    .intervalMinutesSSuffix {
      ::after {
        content: "min";
        position: absolute;
        bottom: 2px;
        right: 0.5em;
        transition: all 0.05s ease-in-out;
      }

      :hover::after,
      :focus-within::after {
        right: 0.5em;
      }

      /* handle Firefox (arrows always shown) */
      @supports (-moz-appearance: none) {
        ::after {
          right: 0.5em;
        }
      }
    }
  }
`;
export const SingularSwitchContainer = styled.div.attrs((props: any) => ({
  isFirstSwitchEnabled: props.isFirstSwitchEnabled,
  isSecondSwitchEnabled: props.isSecondSwitchEnabled,
}))`
  min-height: inherit;
  align-items: center;
  display: grid;
  grid-template-rows: inherit;
  grid-template-columns: 0.8fr;

  > div > div {
    justify-content: flex-start !important;
  }

  .duration,
  .durationTime {
    display: flex;
    align-items: center;
  }

  .defineStartTimeClickableContainer {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;

    > div {
      margin: 0;
      width: -webkit-fill-available;
    }
  }
}

.duration span {
  color: ${(props) =>
    props.isFirstSwitchEnabled ? "#ffffff" : "rgba(255, 255, 255, 0.5)"};
}

.durationTime span {
  color: ${(props) =>
    props.isSecondSwitchEnabled ? "#ffffff" : "rgba(255, 255, 255, 0.5)"};
}

.duration:hover,
.durationTime:hover {
  span {
    color: #ffffff !important;
  }

  button {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
  }
}
`;
export const AddSubTask = styled.div.attrs((props: any) => ({
  isOptionEnabled: props.isOptionEnabled,
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const BlueCheckbox = styled(Checkbox).attrs((props: any) => ({
  isOptionEnabled: props.isOptionEnabled,
}))`
  .ant-checkbox .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: #ffffff40;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #24d2ed !important;
    border-color: #24d2ed;
  }
`;
export const GreyCheckbox = styled(Checkbox).attrs((props: any) => ({
  isOptionEnabled: props.isOptionEnabled,
}))`
  cursor: not-allowed;

  input {
    cursor: not-allowed;
  }

  .ant-checkbox .ant-checkbox-inner {
    background-color: #1e1e1e !important;
    border: 1px solid rgba(255, 255, 255, 0.25);
    cursor: not-allowed !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1e1e1e !important;
    border: 1px solid rgba(255, 255, 255, 0.25);
  }
`;
export const EmptyTriangle = styled.div.attrs((props: any) => ({
  background: props.background,
  border: props.border,
}))`
  background: ${(props) => props.background};
  border: ${(props) =>
    props.border === ""
      ? "1px solid rgba(255, 255, 255, 0.5)"
      : `1px solid ${props.border}`};
`;
export const TreatmentPhaseDropdownOption = styled.div.attrs((props: any) => ({
  isOptionEnabled: props.isOptionEnabled,
  isBrief: props.isBrief,
}))`
  display: flex;
  //justify-content: space-between;
  background: ${(props) =>
    props.isBrief && "rgba(255,255,255, 0, .3) !important"};

  .requiredPhaseWithCheckmark {
    display: flex;
    align-items: center;

    .checkmark {
      color: #23d0eb;
      margin-right: 10px;
    }
  }

  .disabledOption {
    color: rgba(255, 255, 255, 0.1);
  }
`;

export const DropdownForRequiredTimedActionCircle = styled.div.attrs(
  (props: any) => ({
    isOptionEnabled: props.isOptionEnabled,
  }),
)`
  display: flex;
  position: ${(props) => props.isOptionEnabled && "absolute"};
  right: 10px;
`;

export const RatingWithPainAndShockContainer = styled.div.attrs(
  (props: any) => ({
    isExcellent: props.isExcellent,
    isPass: props.isPass,
    isFail: props.isFail,
    isShockOrPain: props.isShockOrPain,
    isCustomLabelActive: props.isCustomLabelActive,
    isRatingSwitch: props.isRatingSwitch,
  }),
)`
  display: flex;
  width: 100%;
  padding: 0 !important;
  min-height: 50px;

  .ratingNumber,
  .ratingText,
  .ratingTime,
  .ratingEdit,
  .ratingCheckbox,
  .shockOrPain,
  .graph,
  .minutes {
    border-bottom: 1px solid #101010;
  }

  &:hover {
    .ratingNumber,
    .ratingText,
    .ratingTime,
    .ratingEdit,
    .ratingCheckbox,
    .shockOrPain,
    .graph,
    .minutes {
      filter: brightness(150%);
      color: #ffffffb3;
    }
  }

  .ratingDetails {
    display: flex;
    width: -webkit-fill-available;
    background: rgb(30, 30, 30);
    //remove background color when re-implementing Define Duration Time

    &hover {
      rgba(255, 255, 255, 0.15);
      //remove hover effect when re-implementing Define Duration Time
    }
  }

  // .ratings-container  {
  //   width: 100%;
  //   background: #1e1e1e;
  // }

  .ratingNumber,
  .ratingText,
  .ratingTime,
  .ratingEdit,
  .ratingCheckbox,
  .shockOrPain,
  .graph,
  .minutes {
    background: #1e1e1e;
    padding: 11px;
  }

  .editIcon {
    color: ${(props) => props.isFail && "transparent !important"};
  }

  .ratingNumber {
    visibility: ${(props) => (props.isShockOrPain ? "hidden" : "visible")};
    margin-right: 1px;

    span {
      background: ${(props) =>
        props.isExcellent
          ? "rgba(35, 208, 235, 0.1)"
          : props.isPass
            ? "rgba(254, 164, 10, 0.1)"
            : props.isFail
              ? "rgba(255, 66, 66, 0.1)"
              : "rgba(255, 255, 255, 0.1)"};
      border: ${(props) =>
        props.isExcellent
          ? "2px solid #23D0EB"
          : props.isPass
            ? "2px solid #FEA40A"
            : props.isFail
              ? "2px solid #FF4242"
              : "2px solid rgba(255, 255, 255, 0.5)"};
      color: ${(props) =>
        props.isExcellent
          ? "#23D0EB"
          : props.isPass
            ? "#FEA40A"
            : props.isFail
              ? "#FF4242"
              : "transparent"};
      border-radius: 50%;
      //visibility: ${(props) =>
        props.isExcellent || props.isFail || props.isPass
          ? "visible"
          : "hidden"};
      padding: 5px 10px;
    }
  }

  .ratingText {
    margin-right: ${(props) => (props.isCustomLabelActive ? "1px" : "0px")};
  }

  .ratingText,
  .shockOrPain {
    padding: 11px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .shockOrPain {
    margin-right: 1px;
    width: 40%;
  }

  .graph {
    margin-right: 1px;
    padding: 11px 15px !important;
  }

  .ratingText {
    width: 60%;
  }

  .ratingCheckbox {
    margin-right: 1px;
  }

  .minutes,
  .ratingTime {
    margin-right: 1px;
    padding: 11px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .minutes {
    width: 34%;
    color: #ffffff;
    font-size: 16px;

    span {
      display: contents;
      font-size: 10px;
    }
  }

  .ratingTime {
    width: -webkit-fill-available;
    font-size: 16px;
    color: ${(props) =>
      props.isRatingSwitch
        ? "#ffffff"
        : props.isExcellent
          ? "#23D0EB"
          : props.isPass
            ? "#FEA40A"
            : props.isFail
              ? "#FF4242"
              : "#ffffff"};
  }

  .ratingEdit {
    span {
      font-size: 24px;
      color: rgba(255, 255, 255, 0.05);
    }

    &:hover {
      span {
        color: #ffffff;
      }
    }
  }
`;

export const TreatmentCategoryAndRequirementContainer = styled.div.attrs(
  (props: any) => ({
    isOptionEnabled: props.isOptionEnabled,
  }),
)`
  display: flex;
  flex-direction: column;

  .dropDownHeader {
    display: flex;
    align-items: center;
    padding: 10px 0;
    color: #ffffff;

    &:hover {
      cursor: pointer;
    }

    span {
      font-size: 18px;
      line-height: 21px;
      text-transform: uppercase;
    }

    & .downIcon {
      margin-left: 15px;
    }
  }

  .skillTypeHeader,
  .psychomotorOrCognitive > span,
  .dropDownHeader span,
  .listTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  .toggleVariables {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 34px;
  }

  .skillTypeHeader {
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.5);
  }

  .psychomotorOrCognitive,
  .medicOrNonMedic {
    display: flex;
    align-items: center;

    > span {
      font-size: 18px;
      line-height: 21px;
      color: #ffffff;
    }
  }

  .psychomotorOrCognitive > span {
    margin-left: 15px;
  }

  .medicOrNonMedic > span {
    margin-left: 15px;
  }

  // .circle {
  //   width: 20px;
  //   height: 20px;
  //   border-radius: 50px;
  // }

  .medic {
    background: #23d0eb;
  }

  .nonMedic {
    background: #fea40a;
  }

  .viewList {
    background: #202020;
    border: 1px solid #1e1e1e;
    padding: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      color: #ffffff;

      .listTitle {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .listRightArrowIcon {
    font-size: 18px;
  }

  .listTitle {
    font-size: 18px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.75);
  }

  .equipmentList {
    display: flex;

    img {
      //color: #484848;
      padding: 11px;
    }

    img:hover {
      color: #ffffff;
    }
  }
`;
