import { SaveFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { EditOutlinedIcons } from "../../../../../IconsTheme/Icons.global";
import { FormikErrorType } from "../../../../../models/common/FormikErrorType";
import { convertMinutesToHoursAndMinutes } from "../../../../../utils/utilityfunctions";
import { OptionMedicationInput } from "../NewMedicationForm.styled";
import FloatingLabelInput from "../../../../../components/FloatingLabel/FloatingLabelInput";
interface Props {
  label: string;
  field: string;
  value: number;
  maxhours: number;
  onChange: Function;
  disabled?: boolean;
  info?: string;
  displayOptionLabel?: boolean;
}
export default function DurationInput({
  label,
  field,
  value,
  maxhours,
  onChange,
  disabled,
  info,
  displayOptionLabel,
}: Props) {
  const [editing, setEditing] = useState(false);
  const focusedStates = {
    hrs: false,
    mins: false,
  };
  const [isFocused, setIsFocused] = useState(focusedStates);
  const [lastFocused, setLastFocused] = useState(focusedStates);
  const hrsVal = value ? Math.floor(Number(value) / 60) : "";
  const minsVal = value ? Number(value) % 60 : "";
  const durationInlineBlockRef = useRef<any>(null);
  const formik = useFormik({
    initialValues: {
      hrs: hrsVal || "",
      mins: minsVal || "",
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        event?.target &&
        durationInlineBlockRef &&
        !durationInlineBlockRef?.current?.contains(event.target)
      ) {
        onSave();
      }
    };
    document.addEventListener("click", (e: any) => handleClickOutside(e), true);
    return () => {
      document.removeEventListener(
        "click",
        (e: any) => handleClickOutside(e),
        true,
      );
    };
  }, []);
  const handleChange = (inputField: string, val: string | number) => {
    formik.setFieldValue(field, val);
    const tempDuration =
      inputField === "hrs"
        ? Number(val) * 60 + Number(formik.values["mins"])
        : Number(formik.values["hrs"]) * 60 + Number(val);
    onChange(field, tempDuration);
  };

  const onEditing = (field?: string) => {
    setEditing(true);
    setTimeout(() => {
      document.getElementById(field ? field : `hrs`)?.focus();
    }, 100);
  };
  const onSave = () => {
    setEditing(false);
  };
  const onClickEditing = (field?: string) => {
    !disabled && !editing && onEditing(field || "");
  };
  const getHHMMValue = (value: number) => {
    return value
      ? convertMinutesToHoursAndMinutes(value)
      : {
          hours: "",
          minutes: "",
        };
  };
  const displayHHMMValue = () => {
    const HHMMValue = getHHMMValue(value);
    return (
      <>
        <span className="option-value" onClick={() => onClickEditing("hrs")}>
          {HHMMValue.hours || "00"}
          <span className="unit">hrs</span>
        </span>
        <span className="option-value" onClick={() => onClickEditing("mins")}>
          {HHMMValue.minutes || "00"}
          <span className="unit">min</span>
        </span>
      </>
    );
  };

  return (
    <div className="option-block">
      {displayOptionLabel ? <div className="option-label">{label}</div> : null}
      <OptionMedicationInput
        disabled={disabled}
        fullWidth={!displayOptionLabel}
      >
        {!disabled && editing ? (
          <>
            <div className="d-inline-flex" ref={durationInlineBlockRef}>
              <div className="dose-input duration mr-3" id="hrs-input">
                <FloatingLabelInput
                  label={""}
                  nameForInput={"hrs"}
                  maxValue={maxhours}
                  minValue={0}
                  maxLength={2}
                  suffixIcon={"hrs"}
                  value={formik.values["hrs"]}
                  onChange={(e: any) => handleChange("hrs", e.target.value)}
                  scriptFormik={formik}
                  setIsFocused={setIsFocused}
                  isFocused={isFocused}
                  lastFocused={lastFocused}
                  setLastFocused={setLastFocused}
                  isAddNeeded={true}
                  step={1}
                  displayMinMaxRange={false}
                  displayErrorMsg={true}
                  precision={0}
                  isNumber
                />
              </div>
              <div className="dose-input duration" id="mins-input">
                <FloatingLabelInput
                  label={""}
                  nameForInput={"mins"}
                  maxValue={59}
                  minValue={0}
                  maxLength={2}
                  suffixIcon={"mins"}
                  value={formik.values["mins"]}
                  onChange={(e: any) => handleChange("mins", e.target.value)}
                  scriptFormik={formik}
                  setIsFocused={setIsFocused}
                  isFocused={isFocused}
                  lastFocused={lastFocused}
                  setLastFocused={setLastFocused}
                  isAddNeeded={true}
                  step={1}
                  displayMinMaxRange={false}
                  displayErrorMsg={true}
                  precision={0}
                  isNumber
                />
              </div>
            </div>
            <span className="action-icon-wrapper edit-mode">
              {info ? <span className="option-info">{info}</span> : null}
              <Tooltip title="Save">
                <SaveFilled className="editIcon" onClick={onSave} />
              </Tooltip>
            </span>
          </>
        ) : (
          <>
            {displayHHMMValue()}
            <span className="action-icon-wrapper">
              {info ? <span className="option-info">{info}</span> : null}
              {disabled ? null : (
                <Tooltip title="Edit">
                  <EditOutlinedIcons
                    className={!disabled ? `editIcon` : `disabled editIcon`}
                    onClick={onEditing}
                  />
                </Tooltip>
              )}
            </span>
          </>
        )}
      </OptionMedicationInput>
    </div>
  );
}
