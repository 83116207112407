import { DebounceSettings, debounce } from "lodash";
import { useCallback } from "react";

/**
 * Creates a debounced function that delays invoking func until after wait milliseconds have elapsed since
 * the last time the debounced function was invoked. The debounced function comes with a cancel method to
 * cancel delayed invocations and a flush method to immediately invoke them. Provide an options object to
 * indicate that func should be invoked on the leading and/or trailing edge of the wait timeout. Subsequent
 * calls to the debounced function return the result of the last func invocation.
 *
 * Note: If leading and trailing options are true, func is invoked on the trailing edge of the timeout only
 * if the the debounced function is invoked more than once during the wait timeout.
 * @param callbackFunction The function to debounce.
 * @param wait The number of milliseconds to delay.
 * @param options The options object.
 * @param options.leading Specify invoking on the leading edge of the timeout.
 * @param options.maxWait The maximum time func is allowed to be delayed before it’s invoked.
 * @param options.trailing Specify invoking on the trailing edge of the timeout.
 * @return Returns the new debounced function.
 */
export const useDebounce = (
  callbackFunction: Function,
  wait?: number,
  options?: DebounceSettings,
) =>
  debounce(
    () => {
      callbackFunction && callbackFunction();
    },
    wait || 500,
    { ...options, maxWait: options?.maxWait || 1000 },
  );
