import { useEffect, useState } from "react";
import { ModalFuncProps } from "antd/lib/modal";

interface ConfirmDialogConfiguration {
  (props: ModalFuncProps): void;
}

interface StatesModal {
  isOpenConfirmDialog: boolean;
  setIsOpenConfirmDialog: Function;
  confirmDialogProps: ModalFuncProps;
  setConfirmDialogProps: ConfirmDialogConfiguration;
}

const useConfirmDialog = (): StatesModal => {
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [confirmDialogProps, setConfirmDialogProps] = useState({});

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const el = document.getElementsByClassName("ModalDialogRef");
      if (
        el &&
        el.length &&
        el[0] &&
        event?.target &&
        !el[0]?.contains(event.target)
      ) {
        setIsOpenConfirmDialog(false);
      }
    };
    document.addEventListener("click", (e: any) => handleClickOutside(e), true);
    return () => {
      document.removeEventListener(
        "click",
        (e: any) => handleClickOutside(e),
        true,
      );
    };
  }, []);

  return {
    isOpenConfirmDialog,
    setIsOpenConfirmDialog,
    confirmDialogProps,
    setConfirmDialogProps,
  };
};
export default useConfirmDialog;
