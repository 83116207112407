import TabPane from "antd/lib/tabs/TabPane";
import React from "react";
import {
  PaneBody,
  PaneTabs,
} from "../ScriptBuilder/EditComponents/ScriptDetailsView/styled";
import { StyledUserWrapper } from "./User.styled";
import UserDashboard from "./UserDashboard/UserDashboard";
import UserProfile from "./UserProfile/UserProfile";
import CompanyManagement from "./CompanyManagement/CompanyManagement";
import { isSimwerxUser } from "../../utils/utilityfunctions";
import Users from "./Users/Users";
import { useHistory, useParams } from "react-router";
import BuildList from "./Builds/BuildList";
import { useAppSelector } from "../../store/hooks";
import { Tooltip } from "antd";
export default function User() {
  const history = useHistory();
  const { accountTab, manageRolesTab, myDashboardTab, organizationTab } =
    useAppSelector((state) => state.FeatureFlagReducer.manageAccountMenu);
  const { activeTab } = useParams<any>();
  const defaultTab = "/Account/my-profile";
  const handleOnTabClick = (path: string) => {
    history.push(`/account/${path}`);
  };
  return (
    <StyledUserWrapper>
      <PaneTabs
        className="tab-min-width"
        defaultActiveKey={defaultTab}
        activeKey={activeTab}
        onChange={handleOnTabClick}
      >
        {myDashboardTab && (
          <TabPane
            tab="My Dashboard"
            key="my-dashboard"
            style={{ minWidth: "10vw" }}
          >
            <Tooltip
              title={`Dashboard Tab ${myDashboardTab ? "- Hidden in Prod" : ""}`}
            >
              <PaneBody>
                <UserDashboard />
              </PaneBody>
            </Tooltip>
          </TabPane>
        )}
        <TabPane tab="My profile" key="my-profile">
          <UserProfile />
        </TabPane>
        <TabPane tab="Manage users" key="manage-users">
          <Users />
        </TabPane>
        {manageRolesTab && (
          <TabPane tab="Manage Roles" key="manage-roles">
            <Tooltip
              title={`Manage Roles Tab ${manageRolesTab ? "- Hidden in Prod" : ""}`}
            >
              <PaneBody>Manage Roles Panel</PaneBody>
            </Tooltip>
          </TabPane>
        )}
        {organizationTab && (
          <TabPane tab="organization" key="organization">
            <Tooltip
              title={`Organization Tab ${organizationTab ? "- Hidden in Prod" : ""}`}
            >
              <PaneBody>Organization Panel</PaneBody>
            </Tooltip>
          </TabPane>
        )}
        {accountTab && (
          <TabPane tab="account" key="account">
            <Tooltip
              title={`Account Tab ${accountTab ? "- Hidden in Prod" : ""}`}
            >
              <PaneBody>Account Panel</PaneBody>
            </Tooltip>
          </TabPane>
        )}
        {isSimwerxUser() ? (
          <>
            <TabPane tab="accounts" key="accounts">
              <CompanyManagement />
            </TabPane>
            <TabPane tab="builds" key="builds">
              <BuildList />
            </TabPane>
          </>
        ) : null}
      </PaneTabs>
    </StyledUserWrapper>
  );
}
