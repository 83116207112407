import { Select } from "antd";
import React from "react";
import FloatingLabelSelect from "../../../../../components/MuiSelect/FloatingLabelSelect";
const { Option } = Select;
type Option = {
  text: string;
  value: string | number;
};
interface Props {
  label: string;
  field: string;
  value: string | number;
  unit?: string;
  options: Option[];
  onChange: Function;
  disabled?: boolean;
}
export default function MedicationOptionWithDropdown({
  label,
  field,
  value,
  options,
  onChange,
  unit,
  disabled,
}: Props) {
  const handleChange = (newValue: string | number) => {
    onChange(field, newValue);
  };
  return (
    <div className="option-block">
      <div className="option-label">{label}:</div>
      <div className="option-input">
        <FloatingLabelSelect
          label=""
          className="w-100"
          onChange={handleChange}
          value={value}
          disabled={disabled || options.length <= 1}
        >
          {options.length &&
            options.map((option: Option, index: number) => (
              <Option
                key={`${option.value}_${index}`}
                value={option.value}
                title=""
              >
                {option.text}
              </Option>
            ))}
          {!options.length && value ? (
            <Option key={`${value}_01`} value={value} title="">
              {value}
              {unit ? unit : ""}
            </Option>
          ) : null}
        </FloatingLabelSelect>
      </div>
    </div>
  );
}
