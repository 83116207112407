import React from "react";
import FloatingLabelInput from "../../../../components/FloatingLabel/FloatingLabelInput";
import { FormRow } from "../../User.styled";
type Props = {
  formik: any;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
  handleChange: Function;
  handleBlur: Function;
};
const fieldName = "company";
export default function CompanyNameInput({
  formik,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
  handleChange,
  handleBlur,
}: Props) {
  return (
    <FormRow>
      <FloatingLabelInput
        label={"Company"}
        nameForInput={fieldName}
        type="text"
        value={formik.values[fieldName]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(fieldName, e.target.value)
        }
        onBlur={handleBlur}
        scriptFormik={formik}
        lastFocused={lastFocused}
        isAddNeeded={false}
        setIsFocused={setIsFocused}
        setLastFocused={setLastFocused}
        isFocused={isFocused}
      />
    </FormRow>
  );
}
