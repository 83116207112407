import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CustomModal from "../../../components/Modal/CustomModal";
import { PlusOutlined } from "@ant-design/icons";
import Button from "../../../components/Button/Button";
import FloatingLabelTextArea from "../../../components/FloatingLabel/FloatingLabelTextArea";
import FloatingLabelInput from "../../../components/FloatingLabel/FloatingLabelInput";
import { Col, Row, Select } from "antd";
import { FormikErrorType } from "../../../models/common/FormikErrorType";
import { RoleFloatingLabelSelect } from "../User.styled";
import { validateEmails } from "../../../utils/utilityfunctions";
import _ from "lodash";
import { Company } from "../../../models/Company";
import { CreateCompany } from "../../../api/Company/ApiPost";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../components/Notification/Notification";
import { SendUserInvitations } from "../../../api/User/ApiPost";

interface AddMemberProps {
  companies: Company[];
  setCompanies: React.Dispatch<React.SetStateAction<Company[]>>;
}

export default function AddMember({ companies, setCompanies }: AddMemberProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const focusedStates = {
    company_name: false,
    invite_email: false,
    message: false,
  };
  const [isFocused, setIsFocused] = useState(focusedStates);
  const [lastFocused, setLastFocused] = useState(focusedStates);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      company_name: "",
      invite_email: "",
      message: "",
    },
    validateOnBlur: true,
    validate: (values) => {
      const errors: FormikErrorType = {};

      if (!values.company_name.trim()) {
        errors["company_name"] = "Required";
      }
      if (
        values.invite_email.trim().length > 0 &&
        !validateEmails(values.invite_email.trim().split(","))
      ) {
        errors["invite_email"] = "Invalid Email";
      } else if (
        _.uniq(values.invite_email.toLowerCase().trim().split(",")).length !==
        values.invite_email.toLowerCase().trim().split(",").length
      ) {
        errors["invite_email"] = "Emails should be unique.";
      }

      return errors;
    },
    onSubmit: (values) => {
      setIsLoading(true);
      CreateCompany(
        values.company_name,
        (data: Company) => {
          setCompanies((prev) => [...prev, data]);
          if (!values.invite_email.trim().length) {
            setIsLoading(false);
            setShowModal(false);
            formik.resetForm();
          }
          if (values.invite_email.trim().length) {
            SendUserInvitations(
              {
                Emails: values.invite_email.trim().split(","),
                CompanyId: data.Id,
              },
              () => {
                OpenSuccessNotification({
                  description: "User invitations sent successfully.",
                });
                setIsLoading(false);
                setShowModal(false);
              },
              (errorMessage: string) => {
                OpenErrorNotification({ description: errorMessage });
                setIsLoading(false);
              },
            );
          }
        },
        (errorMessage: string) => {
          setIsLoading(false);
          OpenErrorNotification({ description: errorMessage });
        },
      );
    },
  });

  const handleOk = () => {
    formik.isValid && formik.handleSubmit();
  };

  const handleCancel = () => {
    setIsLoading(false);
    setShowModal(false);
    formik.resetForm();
  };

  useEffect(() => {
    if (
      companies.find(
        (x) =>
          x.Name.toLowerCase() === formik.values.company_name.toLowerCase(),
      )
    ) {
      formik.setFieldError("company_name", "Company already exist.");
    }
  }, [formik.values.company_name]);

  return (
    <>
      <Button
        type="primary"
        shape={"rounded"}
        leftIcon={<PlusOutlined />}
        onClick={() => setShowModal(true)}
      >
        Add Account
      </Button>
      {/* // add user modal */}
      <CustomModal
        isLoading={isLoading}
        title={"Create account & invite members"}
        showModal={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Send"
      >
        <div>
          <Row>
            <Col span={17}>
              <div className="mb-3">
                <FloatingLabelInput
                  type="email"
                  label={"Company Name*"}
                  nameForInput={"company_name"}
                  value={formik.values.company_name}
                  onSave={() => undefined}
                  onChange={formik.handleChange}
                  scriptFormik={formik}
                  lastFocused={lastFocused}
                  setIsFocused={setIsFocused}
                  setLastFocused={setLastFocused}
                  isFocused={isFocused}
                  isAddNeeded={false}
                />
              </div>
            </Col>
            <Col span={7}>
              <RoleFloatingLabelSelect
                className="w-100 p-0 px-1"
                label={""}
                disabled
                value={"demo"}
              >
                <Select.Option value={"demo"} title="">
                  Demo
                </Select.Option>
              </RoleFloatingLabelSelect>
            </Col>
          </Row>
          <Row>
            <Col span={17}>
              <div className="mb-3">
                <FloatingLabelInput
                  type="email"
                  label={"Invite with email"}
                  nameForInput={"invite_email"}
                  value={formik.values.invite_email}
                  onSave={() => undefined}
                  onChange={formik.handleChange}
                  scriptFormik={formik}
                  lastFocused={lastFocused}
                  setIsFocused={setIsFocused}
                  setLastFocused={setLastFocused}
                  isFocused={isFocused}
                  isAddNeeded={false}
                />
                <div className="pt-1">
                  To invite multiple users, separate emails with a comma.
                </div>
              </div>
            </Col>
            <Col span={7}>
              <RoleFloatingLabelSelect
                className="w-100 p-0 px-1"
                label={""}
                disabled
                value={"admin"}
              >
                <Select.Option value={"admin"} title="">
                  Admin
                </Select.Option>
              </RoleFloatingLabelSelect>
            </Col>
          </Row>
          <FloatingLabelTextArea
            label={"Message"}
            textareaName={"message"}
            value={formik.values.message}
            onSave={() => undefined}
            onChange={formik.handleChange}
            scriptFormik={formik}
            lastFocused={lastFocused}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            isFocused={isFocused}
            rows={6}
          />
        </div>
      </CustomModal>
    </>
  );
}
