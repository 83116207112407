import { useFormik } from "formik";
import React, { useState } from "react";
import { FormikErrorType } from "../../../models/common/FormikErrorType";
import { Form, Row } from "antd";
import AuthenticationPageLeftPane from "../../../components/CenterScreenCard/AuthenticationPageLeftPane";
import FloatingLabelInput from "../../../components/FloatingLabel/FloatingLabelInput";
import { BlueButton } from "../../../styles/Buttons.styled";
import { ArrowRightOutlined } from "@ant-design/icons";
import { FormRow } from "../../ScriptBuilder/EditComponents/ScriptDetailsView/styled";
import { useHistory, useLocation } from "react-router";
import { OpenErrorNotification } from "../../../components/Notification/Notification";
import AlertBar from "../../../app/AlertBar";
import { requestAccountAccess } from "../../../api/Auth";

const RequestCompanyAccessForm = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Get the "n" and "c" parameters
  const n = queryParams.get("n");
  const base64EncodedC = queryParams.get("c");
  // Decode the Base64-encoded "c" parameter using atob()
  const decodedC = base64EncodedC ? atob(base64EncodedC).toString() : "";

  const [loading, setLoading] = useState(false);

  const focusedStates = {
    email: false,
  };

  const [isFocused, setIsFocused] = useState(focusedStates);
  const [lastFocused, setLastFocused] = useState(focusedStates);

  const formik = useFormik({
    initialValues: {
      companyId: decodedC,
      email: "",
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      const regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      if (!values.email.trim()) {
        errors["email"] = "Required";
      } else if (!regex.test(values.email.trim())) {
        errors["email"] = "Invalid Email";
      }
      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      setLoading(true);
      requestAccountAccess(
        {
          Email: values.email,
          CompanyId: values.companyId,
        },
        () => {
          setLoading(false);
          history.replace("/company/request-access/success");
        },
        (errorMessage: string) => {
          setLoading(false);
          OpenErrorNotification({ description: errorMessage });
        },
      );
    },
  });

  return (
    <AuthenticationPageLeftPane
      title={`Request Access to ${n}`}
      displayLoginLink
    >
      <AlertBar />
      <Form onFinish={formik.handleSubmit}>
        <FormRow>
          <FloatingLabelInput
            classNameForContainer="mt-2"
            label={"Email*"}
            nameForInput={"email"}
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            scriptFormik={formik}
            lastFocused={lastFocused}
            isAddNeeded={false}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            isFocused={isFocused}
            lastPassIgnore={false}
          />
        </FormRow>
        <Row className="mt-2" justify="end" align="middle">
          <BlueButton
            disabled={!formik.isValid}
            rounded
            htmlType="submit"
            className="l2l-btn login-btn"
            loading={loading}
          >
            Request Access <ArrowRightOutlined />
          </BlueButton>
        </Row>
      </Form>
    </AuthenticationPageLeftPane>
  );
};

export default RequestCompanyAccessForm;
