import * as React from "react";
import { useEffect } from "react";
import {
  Phase,
  SubtaskForMiscellaneous,
} from "../../../../models/ScriptInterfaces";
import { IconColumn } from "../IconColumn";
import { useFormik } from "formik";
import {
  Container,
  FixedDiv,
  ScrollableTimedActionTaskDiv,
} from "../../../../styles/EditComponentTheme.styled";
import CategorizationAndRequirements from "../../../../components/TreatmentEdit/CategorizationAndRequirements";
import { isNullOrUndefined } from "../../../../utils/utilityfunctions";
import { RequiredTA } from "../../../../components/TimedActions/RequiredTA";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  SetInterval,
  SetIsEditingAllowed,
  SetOption,
  SetSelectedPhase,
  SetSubTask,
  SetUploadedMedia,
} from "./MiscellaneousSlice";
import produce from "immer";
import {
  SetCurrentTimedActionUploadingMedia,
  SetPhases,
  SetTimedActionMediaDisplay,
} from "../../MainlistSlice";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../components/ConfirmDialog/useConfirmDialog";
import { BlockType } from "../../../../models/Enums";
import { useDebounce } from "../../../../hooks/useDebounce";
import {
  MiscellaneousTaskBlock,
  UpdateMiscellaneousTaskBlock,
  UpdateMiscellaneousTaskBlockContent,
} from "../../../../models/MiscellaneousTaskBlock";
import { SubtasksTA } from "../../../../components/TimedActions/SubtasksTA";
import { updateMiscellaneousTaskBlock } from "../../../../api/MiscellaneousTaskBlock";
import { DeepKeysOf } from "../../../../models/common/DeepKeysOf";
import { MiscellaneousTask } from "../../../../models/MiscellaneousTask";
import { IntervalTA } from "../../../../components/TimedActions/IntervalTA";
import { MediaTA } from "../../../../components/TimedActions/MediaTA";
import { DurationTa } from "../../../../components/TimedActions/DurationTA";
import { RatingTA } from "../../../../components/TimedActions/RatingTA";
import { DefineStartTimeTA } from "../../../../components/TimedActions/DefineStartTimeTA";
import {
  CreateTimedActionSubTask,
  TimedActionSubTask,
} from "../../../../models/TimedActionSubTask";
import { Media } from "../../../../models/MediaInterface";
import {
  deleteMediaForTimedActionTask,
  deleteSubtaskForTimedAction,
} from "../../../../api/TimedActionTask";
import MiscellaneousSearchAdd from "./MiscellaneousSearchAdd";
import { FilterBarContainer } from "../../../../styles/MainList.styled";
import { makeMiscellaneousTaskMaster } from "../../../../api/MiscellaneousTask";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import { TimedActionTaskStatus } from "../../../../models/TimedActionTaskStatus";
import { MiscBlockInputFields } from "../../../../components/TimedActions/MiscBlockInputFields";
import { Tooltip } from "antd";

interface Props {
  object: MiscellaneousTaskBlock;
  handleTimedActionBlockChange: Function;
  currentPhaseIndex: number;
  onSave: any; //Function
  onDelete: any; //Function
  onCopy: any; //Function
  updateBlockData: Function;
  handleSubtaskUpdate: any; //Function
}

type ErrorType = {
  [key: string]: string;
};

const MiscellaneousEdit = (props: Props) => {
  const isMasterMiscellaneous =
    props.object?.BlockContent?.TimedActionTaskContent
      ?.TimedActionTaskStatus === TimedActionTaskStatus.CompanyOnly ||
    props.object?.BlockContent?.TimedActionTaskContent
      ?.TimedActionTaskStatus === TimedActionTaskStatus.Master;
  const { materialRequirements, skillCategorization } = useAppSelector(
    (state) => state.FeatureFlagReducer.scriptBuilder.miscellaneousBlock,
  );
  const dispatch = useAppDispatch();
  const { isEditingAllowed, ...state } = useAppSelector(
    (state: any) => state.MiscReducer,
  );
  const MainListState = useAppSelector((state) => state.MainlistReducer);
  const phases: Phase[] = MainListState.phases;
  const { isReadOnly, timedActionMediaDisplay } = MainListState;
  const filterBarRef = React.useRef<any>(null);
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  useEffect(() => {
    if (!isNullOrUndefined(props.object)) {
      if (!isNullOrUndefined(props.object.BlockContent.RequiredInPhaseOffset)) {
        const createdIndex = getIndexFromPhaseId(
          props.object?.BlockContent.CreatedInPhaseId,
        );
        if (createdIndex !== -1) {
          const requiredInPhaseIndex =
            createdIndex + props.object.BlockContent.RequiredInPhaseOffset;
          if (phases.length >= requiredInPhaseIndex + 1) {
            if (phases[requiredInPhaseIndex].TitleAbbreviated === "") {
              const phaseTitle = `Phase ${requiredInPhaseIndex + 1}`;
              dispatch(SetSelectedPhase(phaseTitle));
            } else {
              dispatch(
                SetSelectedPhase(phases[requiredInPhaseIndex].TitleAbbreviated),
              );
            }
          }
        }
      }
      dispatch(SetUploadedMedia(props.object.BlockContent.UploadedMediaItems));
      dispatch(SetInterval(props.object.BlockContent.IntervalTime));
      setOptionsValue();
    }
  }, [phases]);

  // reset subtask on destroy
  useEffect(() => {
    return () => {
      dispatch(SetSubTask(""));
    };
  }, []);

  const scriptFormik = useFormik({
    initialValues: {
      search: ``,
      description:
        props.object?.BlockContent?.TimedActionTaskContent?.Description || "",
      taskName: props.object?.BlockContent?.TimedActionTaskContent?.Name || "",
      additionalNote: "",
      phase: "",
      subTask: "",
      interval: 0,
    },
    validate: () => {
      const errors: ErrorType = {};
      return errors;
    },
    validateOnBlur: true,
    onSubmit: () => undefined,
  });

  const onChangeMiscellaneousBlock = (
    changedVariable: DeepKeysOf<UpdateMiscellaneousTaskBlockContent>,
    change: any,
    secondChangedVariable?: DeepKeysOf<UpdateMiscellaneousTaskBlockContent>,
    secondChangedValue?: any,
  ) => {
    props.handleTimedActionBlockChange(
      BlockType.Miscellaneous,
      changedVariable,
      change,
      secondChangedVariable,
      secondChangedValue,
    );
  };

  const onMiscellaneousSave = () => {
    updateMiscellaneousTaskBlock(
      props.object as UpdateMiscellaneousTaskBlock,
      () => undefined,
      () => undefined,
    );
  };

  const debounceSubmitForm = useDebounce(onMiscellaneousSave);

  useEffect(() => {
    debounceSubmitForm();

    return () => {
      debounceSubmitForm.cancel();
    };
  }, [props.object, scriptFormik.isValid, scriptFormik.values]);

  //set option value on load
  // fix for toggle not working and requires 2-3 clicking
  const setOptionsValue = () => {
    dispatch(
      SetOption({
        ...state.option,
        IsRequired: props.object.BlockContent["IsRequired"],
        IsCritical: props.object.BlockContent["IsCritical"],
        IsSubtasksEnabled:
          props.object?.BlockContent?.TimedActionTaskContent?.IsSubtasksEnabled,
        IsIntervalEnabled:
          props.object?.BlockContent?.TimedActionTaskContent?.IsIntervalEnabled,
        IsDurationEnabled:
          props.object?.BlockContent?.TimedActionTaskContent?.IsDurationEnabled,
        IsDefineDurationTimeEnabled:
          props.object?.BlockContent?.TimedActionTaskContent
            ?.IsDefineDurationTimeEnabled,
        IsDefineStartTimeEnabled:
          props.object?.BlockContent?.TimedActionTaskContent
            ?.IsDefineStartTimeEnabled,
        IsRatingEnabled:
          props.object?.BlockContent?.TimedActionTaskContent?.IsRatingEnabled,
        IsMediaEnabled:
          props.object?.BlockContent?.TimedActionTaskContent?.IsMediaEnabled,
        IsCustomLabelsEnabled:
          props.object?.BlockContent?.TimedActionTaskContent
            ?.IsCustomLabelsEnabled,
      }),
    );
  };

  const onDeleteConfirm = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "Are you sure delete this Block?",
      content: "This cannot be undone",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        props.onDelete(props.object);
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        console.log("Cancel");
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };

  const handlePhaseSelection = (
    phaseTitle: string,
    phaseIndex: number,
    useEff?: boolean,
  ) => {
    if (isNullOrUndefined(useEff)) {
      onChangeMiscellaneousBlock(
        "RequiredInPhaseOffset",
        phaseIndex - MainListState.currentPhaseIndex,
      );
    }
    if (phaseTitle === "") {
      const phaseTitle = `Phase ${phaseIndex + 1}`;
      dispatch(SetSelectedPhase(phaseTitle));
    } else {
      dispatch(SetSelectedPhase(phaseTitle));
    }
  };

  const handleIntervalToggle = (
    type: keyof MiscellaneousTask,
    value: number,
  ) => {
    if (isReadOnly) return; //disable action callback in read only script
    if (type === "IsIntervalEnabled") {
      if (state.option.IsIntervalEnabled) {
        dispatch(SetInterval(0));
        onChangeMiscellaneousBlock(`TimedActionTaskContent.${type}`, 0);
      }
      dispatch(SetOption({ ...state.option, [type]: !state.option[type] }));
      onChangeMiscellaneousBlock(
        `TimedActionTaskContent.${type}`,
        !state.option[type],
      );
    } else if (type === "IntervalTime") {
      dispatch(SetInterval(value));
      onChangeMiscellaneousBlock(`TimedActionTaskContent.${type}`, value);
    }
  };

  const handleOptionToggle = (type: string) => {
    dispatch(
      SetOption({
        ...state.option,
        [type]: !state.option[type],
      }),
    );
    if (type === "IsCritical") {
      onChangeMiscellaneousBlock(type, !state.option[type]);
    } else if (type === "IsRequired") {
      const createdIndex = getIndexFromPhaseId(
        props.object?.BlockContent.CreatedInPhaseId,
      );
      if (
        createdIndex !== -1 &&
        createdIndex <=
          createdIndex + props.object.BlockContent.RequiredInPhaseOffset
      ) {
        if (phases[createdIndex].TitleAbbreviated === "") {
          const phaseTitle = `Phase ${createdIndex + 1}`;
          dispatch(SetSelectedPhase(phaseTitle));
          onChangeMiscellaneousBlock(
            "RequiredInPhaseOffset",
            0,
            type,
            !state.option[type],
          );
        } else {
          dispatch(SetSelectedPhase(phases[createdIndex].TitleAbbreviated));
          onChangeMiscellaneousBlock(
            "RequiredInPhaseOffset",
            0,
            type,
            !state.option[type],
          );
        }
      }
    } else {
      onChangeMiscellaneousBlock(
        `TimedActionTaskContent.${type}`,
        !state.option[type],
      );
    }
  };

  const handleAddSubTask = () => {
    if (isReadOnly) return; //disable action callback in read only script
    const newSubtask: CreateTimedActionSubTask = {
      Order:
        props.object?.BlockContent?.TimedActionTaskContent?.Subtasks?.length,
      Description: state.subtask,
    };
    onChangeMiscellaneousBlock("TimedActionTaskContent.Subtasks", newSubtask);
    dispatch(SetSubTask(""));
  };

  const handleDeleteMedia = (mediaId: number) => {
    if (isReadOnly) return; //disable action callback in read only script
    deleteMediaForTimedActionTask(
      props.object?.BlockContent?.TimedActionTaskContent?.Id,
      mediaId,
      () => {
        DeleteMediaSuccess(mediaId);
      },
      (error: any) => {
        console.error(error);
      },
    );
  };

  const DeleteMediaSuccess = (mediaId: number) => {
    const newPhases = produce(phases, (draftPhases) => {
      draftPhases[MainListState.currentPhaseIndex].Sections[
        MainListState.currentSectionIndex
      ].Blocks[
        MainListState.currentBlockIndex
      ].BlockContent.TimedActionTaskContent.AttachedMedias = draftPhases[
        MainListState.currentPhaseIndex
      ].Sections[MainListState.currentSectionIndex].Blocks[
        MainListState.currentBlockIndex
      ].BlockContent.TimedActionTaskContent.AttachedMedias?.filter(
        (attachedMedia: Media) => attachedMedia.Id !== mediaId,
      );
    });
    dispatch(SetPhases(newPhases));
  };

  const handleSubtaskDelete = (subtaskToDelete: TimedActionSubTask) => {
    if (isReadOnly) return; //disable action callback in read only script

    deleteSubtaskForTimedAction(
      subtaskToDelete.Id,
      () => {
        deleteSubtaskSuccess(subtaskToDelete.Id);
      },
      () => undefined,
    );
  };

  const deleteSubtaskSuccess = (id: number) => {
    const newPhases = produce(phases, (draftPhases) => {
      draftPhases[MainListState.currentPhaseIndex].Sections[
        MainListState.currentSectionIndex
      ].Blocks[
        MainListState.currentBlockIndex
      ].BlockContent.TimedActionTaskContent.Subtasks = draftPhases[
        MainListState.currentPhaseIndex
      ].Sections[MainListState.currentSectionIndex].Blocks[
        MainListState.currentBlockIndex
      ].BlockContent.TimedActionTaskContent.Subtasks?.filter(
        (subTask: SubtaskForMiscellaneous) => subTask.Id !== id,
      );
    });
    dispatch(SetPhases(newPhases));
  };

  const getIndexFromPhaseId = (Id: number | undefined) => {
    if (!Id) return -1;
    return phases.findIndex((phase: Phase) => phase.Id === Id);
  };
  const isDisableDeleteCopyBtn = () => {
    const index = getIndexFromPhaseId(
      props.object?.BlockContent.CreatedInPhaseId,
    );
    return MainListState.currentPhaseIndex !== index;
  };
  const handleClickOnAttach = () => {
    dispatch(SetTimedActionMediaDisplay(!timedActionMediaDisplay));
    dispatch(SetCurrentTimedActionUploadingMedia("Miscellaneous"));
  };

  const makeMaster = () => {
    makeMiscellaneousTaskMaster(
      props.object?.BlockContent?.TimedActionTaskContent?.Id,
      (data: MiscellaneousTask) => {
        onChangeMiscellaneousBlock(
          "TimedActionTaskContent.TimedActionTaskStatus",
          data.TimedActionTaskStatus,
        );
      },
      (error: any) => {
        OpenErrorNotification({ description: error.ErrorMessage });
      },
    );
  };

  const onEdit = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "",
      content:
        "All edits here will update the master as well. Do you want to Continue?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch(SetIsEditingAllowed(true));
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };

  useEffect(() => {
    // Diagnostic is not master then allow editing by default.
    !isMasterMiscellaneous && isEditingAllowed
      ? dispatch(SetIsEditingAllowed(true))
      : dispatch(SetIsEditingAllowed(false));
  }, [isMasterMiscellaneous]);

  return (
    <Container>
      <FixedDiv phase={false}>
        <IconColumn
          type="miscellaneous"
          onCopyClick={() => props.onCopy(props.object)}
          onDeleteClick={onDeleteConfirm}
          isDeleteBtnDisabled={isDisableDeleteCopyBtn()}
          isCopyBtnDisabled={isDisableDeleteCopyBtn()}
          isMasterBtnAvailable
          onMasterBtnClick={makeMaster}
          isMaster={isMasterMiscellaneous}
          isEditBtnAvailable
          isMasterBtnDisabled={isReadOnly}
          isEditBtnDisabled={isReadOnly || isEditingAllowed}
          onEditClick={onEdit}
        />
      </FixedDiv>
      {!isNullOrUndefined(props.object) &&
        !isNullOrUndefined(props.object.BlockContent) &&
        !isNullOrUndefined(
          props.object?.BlockContent?.TimedActionTaskContent,
        ) && (
          <>
            <FilterBarContainer isDiagnostic={true} ref={filterBarRef}>
              <MiscellaneousSearchAdd
                scriptFormik={scriptFormik}
                object={props.object}
                checkIsMaster={() => undefined}
                updateBlock={props.updateBlockData}
                onChange={onChangeMiscellaneousBlock}
              />
              {/* <FilterOutlinedIcons className="icon" /> */}
            </FilterBarContainer>
            <ScrollableTimedActionTaskDiv
              className="treatmentContainer"
              typeOfEditPanel="misc"
              id="scrollableDiv"
              phase={false}
            >
              <MiscBlockInputFields
                object={props.object}
                scriptFormik={scriptFormik}
                isReadOnly={isReadOnly || !isEditingAllowed}
                onChange={onChangeMiscellaneousBlock}
              />
              <div className="toggleOptions">
                <RequiredTA
                  createdInPhaseId={props.object.BlockContent.CreatedInPhaseId}
                  requiredInPhaseOffset={
                    props.object.BlockContent.RequiredInPhaseOffset
                  }
                  currentPhaseId={MainListState.currentPhaseId}
                  currentPhaseIndex={MainListState.currentPhaseIndex}
                  isRequired={props.object.BlockContent.IsRequired}
                  isCritical={props.object.BlockContent.IsCritical}
                  phases={phases}
                  handleOptionToggle={handleOptionToggle}
                  handlePhaseSelection={handlePhaseSelection}
                  selectedPhaseValue={state.selectedPhase}
                />
                <SubtasksTA
                  isReadOnly={isReadOnly || !isEditingAllowed}
                  object={props.object?.BlockContent?.TimedActionTaskContent}
                  handleOptionToggle={handleOptionToggle}
                  scriptFormik={scriptFormik}
                  handleAddSubtask={handleAddSubTask}
                  subtaskValue={state.subtask}
                  type="Misc"
                  handleChange={(e: string) => dispatch(SetSubTask(e))}
                  handleSubtaskDelete={handleSubtaskDelete}
                  handleSubtaskUpdate={props.handleSubtaskUpdate}
                />
                <IntervalTA
                  isReadOnly={isReadOnly || !isEditingAllowed}
                  handleIntervalToggle={handleIntervalToggle}
                  isIntervalEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsIntervalEnabled
                  }
                  interval={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IntervalTime
                  }
                  handleOptionToggle={handleOptionToggle}
                  scriptFormik={scriptFormik}
                />
                <DurationTa
                  isReadOnly={isReadOnly || !isEditingAllowed}
                  isDurationEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsDurationEnabled
                  }
                  onChange={handleOptionToggle}
                  isDefineDurationTimeEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsDefineDurationTimeEnabled
                  }
                />
                <DefineStartTimeTA
                  isReadOnly={isReadOnly || !isEditingAllowed}
                  handleOptionToggle={handleOptionToggle}
                  isDefineStartTimeEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsDefineStartTimeEnabled
                  }
                />
                <RatingTA
                  isReadOnly={isReadOnly || !isEditingAllowed}
                  isCustomLabelsEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsCustomLabelsEnabled
                  }
                  isRatingEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsRatingEnabled
                  }
                  handleOptionToggle={handleOptionToggle}
                />
                <MediaTA
                  isReadOnly={isReadOnly || !isEditingAllowed}
                  isMediaEnabled={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.IsMediaEnabled
                  }
                  handleOptionToggle={handleOptionToggle}
                  medias={
                    props.object?.BlockContent?.TimedActionTaskContent
                      ?.AttachedMedias
                  }
                  type="Miscellaneous"
                  deleteMedia={handleDeleteMedia}
                  onAttach={handleClickOnAttach}
                />
                {skillCategorization && (
                  <Tooltip title="Hidden in production">
                    <>
                      <CategorizationAndRequirements
                        dropDownTitle={"Skill Categorization"}
                        listItemsTitle={"Skill Type"}
                        viewListLinkTitle={"View Approved Mos Units"}
                        timedAction={"misc"}
                      />
                    </>
                  </Tooltip>
                )}
                {materialRequirements && (
                  <Tooltip title="Hidden in production">
                    <>
                      <CategorizationAndRequirements
                        dropDownTitle={"Material Requirements"}
                        listItemsTitle={"Equipment Availability"}
                        viewListLinkTitle={"View Material List"}
                        timedAction={"misc"}
                      />
                    </>
                  </Tooltip>
                )}
              </div>
            </ScrollableTimedActionTaskDiv>
          </>
        )}
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </Container>
  );
};
export default MiscellaneousEdit;
