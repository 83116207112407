export enum Session_Mode {
  SelfGuided,
  TeacherGuided,
  Practice,
  Exam,
}

export enum Action_Type {
  animation,
  multipleChoiceQuestion,
  multipleChoiceAnswer,
  binaryChoiceQuestion,
  binaryChoiceAnswer,
  orderChoiceQuestion,
  orderChoiceAnswer,
}

export enum Body_Part {
  head,
  neck,
  chest,
  upperRightArm,
  upperLeftArm,
  elbowRight,
  elbowLeft,
  forearmRight,
  forearmLeft,
  wristRight,
  wristLeft,
  handRight,
  handLeft,
  stomach,
  hip,
  legRight,
  legLeft,
  feetRight,
  feetLeft,
}

export enum AlertType {
  success,
  info,
  warning,
  error,
}

export enum MedRoute {
  PO,
  "IV/IO",
  "IV/IM",
}

export enum Unit {
  mcg,
  mg,
  pill,
  pills,
}

export enum ComplicationType {
  physiological = "Physiological",
  environmental = "Environmental",
}
export enum BlockType {
  Text = 0,
  ScriptEquipmentList = 1,
  ScriptList = 2,
  ScriptRolePlayerPrompt = 3,
  SupportAction = 4,
  ResearchQuestionList = 5, // NOT IN USE //OLD CODE
  CriticalAction = 6, // NOT IN USE //OLD CODE
  ComplicationList = 7, // NOT IN USE //OLD CODE
  Casualty = 8,
  ScriptMedication = 9,
  Treatment = 10,
  Diagnostic = 11,
  CasualtyStatus = 12,
  Miscellaneous = 13,
  Medication = 14,
  ResearchQuestion = 15,
}

export enum SectionType {
  TIMED_ACTIONS = "timed actions",
  NORMAL = "normal",
}

export enum MedicationType {
  SESubstanceCompoundInfusion = "SESubstanceCompoundInfusion",
  SESubstanceInfusion = "SESubstanceInfusion",
  SESubstanceOralDose = "SESubstanceOralDose",
  SESubstanceNasalDose = "SESubstanceNasalDose",
  SESubstanceBolus = "SESubstanceBolus",
}

export enum Sex {
  Male,
  Female,
}

export enum ListTypeDropDown {
  Type = "List Type",
  NEW = "New",
  EQUIPMENT = "Equipment",
  PRIMARY = "Primary Obj",
  SECONDARY = "Secondary Obj",
  CRITICAL = "Critical MileStones",
}

export enum ScriptList {
  New,
  PrimaryObjectives,
  SecondaryObjectives,
  CriticalMilestones,
}

export enum BloodType {
  APositive,
  ANegative,
  ABPositive,
  ABNegative,
  BPositive,
  BNegative,
  OPositive,
  ONegative,
  NotApplicable,
}

export enum BloodTypeCharacter {
  "A+",
  "A-",
  "AB+",
  "AB-",
  "B+",
  "B-",
  "O+",
  "O-",
  "--",
}

export enum ScriptListType {
  New,
  PrimaryObjectives,
  SecondaryObjectives,
  CriticalMilestones,
}

export enum InsultCategory {
  SkinMusculoskeletal,
  Respiratory,
  Circulatory,
  Metabolic,
  CentralNervousSystem,
  InfectionPoisons,
}

export enum Units {
  //1KG = 2.20462262185LB
  KgToLb = 2.20462262185,
}

export enum AvpuScaleCharacter {
  "N/A", //not applicable for no selection
  "A",
  "V",
  "P",
  "U",
}

export enum AvpuScale {
  "Not Applicable", //not applicable for no selection
  "Alert",
  "Voice",
  "Pain",
  "Unresponsive",
}

export enum VitalType {
  Pain,
  SI,
  HR,
  BP,
  MAP,
  RR,
  SpO2,
  EtCO2,
  Temp,
  UO,
}

export enum ResearchQuestionType {
  Text,
  Select,
  Radio,
  Number,
  Button,
}

export enum ResearchQuestionNumberType {
  Input,
  Slider,
}
export enum ResearchQuestionSelectType {
  SingleSelect,
  MultiSelect,
}
export enum ResearchQuestionRadioType {
  Vertical,
  Horizontal,
}

export enum TaskType {
  "Treatment",
  "Diagnostic",
  "Medication",
  "Miscellaneous",
}
export enum ChartType {
  HorizontalBar,
  VerticalBar,
  PlotLine,
  StackedArea,
  Pie,
  Scatter,
  Doughnut,
}

export enum AccountStatus {
  Invited,
  Requested,
  Accepted,
}
