import React from "react";
import Drawer from "../../../../components/Drawer/Drawer";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setMedicationForUpdate } from "./MedicationSlice";
import { Medication } from "../../../../models/ScriptInterfaces";
import CategorizationAndRequirements from "../../../../components/TreatmentEdit/CategorizationAndRequirements";
import { DefineStartTimeTA } from "../../../../components/TimedActions/DefineStartTimeTA";
import { DurationTa } from "../../../../components/TimedActions/DurationTA";
import { IntervalTA } from "../../../../components/TimedActions/IntervalTA";
import { RatingTA } from "../../../../components/TimedActions/RatingTA";
import { useAppSelector } from "../../../../store/hooks";
import { ScrollableTimedActionTaskDiv } from "../../../../styles/EditComponentTheme.styled";
import MediaOption from "../../../../components/Media/MediaOption";
import FloatingLabelInput from "../../../../components/FloatingLabel/FloatingLabelInput";
import SubTasks from "./TimedActions/SubTasks";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import MedicationParamForm from "./TimedActions/MedicationParamForm";
import {
  MedicationTask,
  UpdateMedicationTask,
} from "../../../../models/MedicationTask";
import { Media } from "../../../../models/MediaInterface";
import produce from "immer";
import { deleteMediaForTimedActionTask } from "../../../../api/TimedActionTask";
import { isNullOrUndefined } from "is-what";
import { Tooltip } from "antd";

interface Props {
  title: string;
  formik: any;
  isVisible: boolean;
  onClickOutside: any;
  onClose: any;
}
export default function AddUpdateDrawer({
  title,
  formik,
  isVisible,
  onClickOutside,
  onClose,
}: Props) {
  const isReadOnly = false;
  const { materialRequirements, skillCategorization } = useAppSelector(
    (state) => state.FeatureFlagReducer.scriptBuilder.medicationBlock,
  );
  const medication = useAppSelector(
    (state) => state.MasterMedicationReducer.medicationForUpdate,
  );
  const focusedStates = {
    medication: false,
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);

  const dispatch = useDispatch();

  const onError = (msg: any) => {
    OpenErrorNotification({
      description: msg,
    });
  };

  const handleChange = (
    field: keyof UpdateMedicationTask,
    value: string | boolean | any,
  ) => {
    const payload = {
      ...medication,
      [field]: value,
    };
    dispatch(setMedicationForUpdate(payload));
  };
  const handleIntervalToggle = (type: keyof Medication, value?: number) => {
    if (type === "IsIntervalEnabled") {
      if (medication.IsIntervalEnabled) {
        handleChange("IntervalTime", 0);
      }
      handleChange(type, !medication[type]);
    } else if (type === "IntervalTime") {
      handleChange(type, value);
    }
  };
  const handleOptionToggle = (
    type: keyof UpdateMedicationTask,
    value?: number,
  ) => {
    handleChange(type, !medication[type]);
  };
  const getScrollableDivHeight = () => {
    return {
      height: `auto`,
    };
  };

  const handleDeleteMedia = (mediaId: number) => {
    if (!isNullOrUndefined(medication.Id)) {
      deleteMediaForTimedActionTask(
        medication.Id,
        mediaId,
        () => {
          deleteMediaSuccess(mediaId);
        },
        (error: any) => {
          console.error(error);
        },
      );
    } else {
      deleteMediaSuccess(mediaId);
    }
  };

  const deleteMediaSuccess = (mediaId: number) => {
    const updatedTreatmentTask = produce(medication, (draftMedication) => {
      draftMedication.AttachedMedias = medication.AttachedMedias.filter(
        (media: Media) => media.Id !== mediaId,
      );
    });
    dispatch(setMedicationForUpdate(updatedTreatmentTask));
  };

  return (
    <Drawer
      className="custom-css-ant-drawer"
      visible={isVisible}
      onClose={onClickOutside}
      closable
      width="600"
    >
      <h1>
        <ArrowRightOutlined style={{ marginRight: "20px" }} onClick={onClose} />
        {title}
      </h1>
      <div className="m-2">
        <div className="p-2">
          <ScrollableTimedActionTaskDiv
            style={getScrollableDivHeight()}
            typeOfEditPanel="medication"
            className="treatmentContainer"
            id="scrollableDiv"
            phase={false}
          >
            <div className="mb-5">
              <FloatingLabelInput
                label={"Title"}
                nameForInput={"label"}
                value={formik.values["label"]}
                onChange={formik.handleChange}
                scriptFormik={formik}
                lastFocused={lastFocused}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
                isAddNeeded={false}
                onSave={formik.submitForm}
              />
            </div>
            <MedicationParamForm
              medication={medication}
              useCase={medication.UseCase1 || medication.UseCase2}
              onChange={handleChange}
              currentRouteId={medication?.CurrentRouteOfAdmin?.Id}
              scriptFormik={formik}
            />
            <div className="toggleOptions">
              <SubTasks
                isReadOnly={isReadOnly}
                obj={medication as MedicationTask}
                formik={formik}
                onChange={handleChange}
                onError={onError}
              />
              <IntervalTA
                isReadOnly={isReadOnly}
                handleIntervalToggle={handleIntervalToggle}
                isIntervalEnabled={medication.IsIntervalEnabled}
                interval={medication.IntervalTime}
                handleOptionToggle={handleOptionToggle}
                scriptFormik={formik}
              />
              <DurationTa
                isReadOnly={isReadOnly}
                isDurationEnabled={medication.IsDurationEnabled}
                onChange={handleOptionToggle}
                isDefineDurationTimeEnabled={
                  medication.IsDefineDurationTimeEnabled
                }
              />
              <DefineStartTimeTA
                isReadOnly={isReadOnly}
                handleOptionToggle={handleOptionToggle}
                isDefineStartTimeEnabled={medication?.IsDefineStartTimeEnabled}
              />
              <RatingTA
                isReadOnly={isReadOnly}
                isCustomLabelsEnabled={medication?.IsCustomLabelsEnabled}
                isRatingEnabled={medication?.IsRatingEnabled}
                handleOptionToggle={handleOptionToggle}
              />
              <MediaOption
                isReadOnly={isReadOnly}
                obj={medication as MedicationTask}
                type="Medication"
                handleOptionToggle={handleOptionToggle}
                handleDeleteMedia={handleDeleteMedia}
                onChange={handleChange}
              />
              {skillCategorization && (
                <Tooltip title="Hidden in production">
                  <>
                    <CategorizationAndRequirements
                      dropDownTitle={"Skill Categorization"}
                      listItemsTitle={"Skill Type"}
                      viewListLinkTitle={"View Approved Mos Units"}
                      timedAction={"medication"}
                    />
                  </>
                </Tooltip>
              )}
              {materialRequirements && (
                <Tooltip title="Hidden in production">
                  <>
                    <CategorizationAndRequirements
                      dropDownTitle={"Material Requirements"}
                      listItemsTitle={"Equipment Availability"}
                      viewListLinkTitle={"View Material List"}
                      timedAction={"diagnostic"}
                    />
                  </>
                </Tooltip>
              )}
            </div>
          </ScrollableTimedActionTaskDiv>
        </div>
      </div>
    </Drawer>
  );
}
