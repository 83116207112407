import styled from "styled-components";

export const ListContainer = styled.div`
  //margin-top: 0.1875rem;
  padding: 0 10px !important;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  .draggableParentDiv > div {
    height: inherit !important;
  }
`;
export const ListTitle = styled.span`
  padding-left: 0.625rem;
  background-color: rgba(0, 0, 0, 255);
  color: #808080;
  width: 100%;
  height: auto;
  margin-bottom: 0.0625rem;
  display: flex;
  align-items: flex-end;
`;

export const TitleSpan = styled.span`
  font-size: 1rem;
  width: 100%;
  width: -webkit-fill-available !important;
  padding-right: 0.1875rem;
  padding-top: 2px;
  padding-bottom: 2px;
  height: auto;
  white-space: pre-line;
  word-wrap: break-word;

  textarea,
  input {
    font-size: 1rem !important;
  }

  .inline-editing {
    padding-top: 0 !important;
  }
  .option-value {
    &:not(.inline-wrapper) {
      padding: 0 10px !important;
    }
  }

  // @media (min-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
  //   width: 65%;
  // }
  //
  // @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
  //   width: 58%;
  // }
`;
export const IndexSpan = styled.span`
  font-size: 1rem;
  width: 11.5%;
  max-width: 40px;
  padding: 0 5px;
  word-wrap: break-word;
  border-right: 1px solid #161616;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints?.xxxl}) {
    width: 16.5%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    width: 9.5%;
  }

  .unordered {
    font-size: 1.5rem;
  }
`;
export const QuantityDiv = styled.div`
  font-size: 1rem;
  width: 14%;
  height: auto;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  padding-left: 2px;

  input {
    font-size: 1rem !important;
    padding 4px 0px !important;
  }

  .inline-editing {
    padding-top: 0 !important;
  }
`;

export const StyledListTitleItem = styled.div.attrs((props: any) => ({
  isQuantityEnabled: props.isQuantityEnabled,
}))`
  width: ${(props) => (props.isQuantityEnabled ? "77%" : "91%")};
`;

export const Ul = styled.ul`
  .titleIndexDiv {
    margin-right: 1px;
    //border-right: 1px solid #000000;
    min-height: inherit;
    display: flex;

    span {
      align-items: center;
      display: flex;
      word-break: break-word;
    }
  }

  li {
    width: 100%;
    min-height: 2.8rem;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #1e1e1e;
    font-size: 0.9375rem;
    position: relative;
    margin-bottom: 0.0625rem;
  }

  .always-display-delete {
    color: rgba(255, 255, 255, 0.25) !important;
  }

  li:hover {
    background-color: #313131;

    .deletebtn {
      background-color: #313131;
    }

    .delete-icon {
      color: rgba(255, 255, 255, 0.25) !important;
    }
  }

  .deletebtn {
    background: #1e1e1e;
    border: none;
    min-height: inherit;
    width: 9%;
    min-width: 35px;
    cursor: pointer;
    border-left: 0.5px solid #101010;

    &:hover {
      .delete-icon {
        color: #ff4242;
      }

      .listItem {
        color: #ff4242 !important;
      }
    }
  }

  .delete-icon {
    color: rgba(255, 255, 255, 0.05);
    font-size: 1.25rem;
  }

  .emptyDiv {
    width: 9%;
    border-left: 1px solid #101010;
    min-width: 35px;
    min-height: inherit;
  }
`;
