import React from "react";
import {
  LevelOfConsciousnessWrapper,
  OptionWrapper,
  StyledFloatingLabelSelect,
} from "./RightEditPanel.styled";
import { Select } from "antd";
import { WhiteIconButton } from "../../../../../styles/Buttons.styled";
import {
  EyeInvisibleOutlinedIcons,
  EyeOutlinedIcons,
} from "../../../../../IconsTheme/Icons.global";
import { AvpuScale, AvpuScaleCharacter } from "../../../../../models/Enums";
import { useSelector } from "react-redux";
const { Option } = Select;
interface Props {
  value: number | string;
  visible: boolean;
  onChange: Function;
}
export default function LevelOfConsciousnessEdit(props: Props) {
  const { isReadOnly } = useSelector((state: any) => state.MainlistReducer);
  const onChange = (value: any) => {
    if (isReadOnly) return; //disable action callback in read only script
    props.onChange("LevelOfConsciousness", value);
  };
  const toggleConsciousness = () => {
    if (isReadOnly) return; //disable action callback in read only script
    props.onChange("LevelOfConsciousnessIsDisplayed", !props.visible);
  };
  return (
    <LevelOfConsciousnessWrapper isValue={!props.value ? false : true}>
      <WhiteIconButton
        type="link"
        icon={
          props.visible ? (
            <EyeOutlinedIcons className="visibleIcon" />
          ) : (
            <EyeInvisibleOutlinedIcons />
          )
        }
        size="middle"
        onClick={toggleConsciousness}
      ></WhiteIconButton>
      <StyledFloatingLabelSelect
        label="Level of Consciousness"
        disabled={!props.visible || isReadOnly}
        className="w-100"
        onChange={onChange}
        value={!props.value ? null : props.value}
      >
        {[
          AvpuScaleCharacter["A"],
          AvpuScaleCharacter["V"],
          AvpuScaleCharacter["P"],
          AvpuScaleCharacter["U"],
        ].map((op: number) => {
          return (
            <Option
              key={`consciousness-option-` + op}
              value={op}
              className="consciousness-option"
            >
              <OptionWrapper>
                <span className="Abbreviation">{AvpuScaleCharacter[op]}</span>
                <span>{AvpuScale[op]}</span>
              </OptionWrapper>
            </Option>
          );
        })}
      </StyledFloatingLabelSelect>
    </LevelOfConsciousnessWrapper>
  );
}
