import React from "react";
import { TaskType } from "../../../../../models/Enums";
import TimedActionBlockSubtask from "./Subtask/Subtask";
import { TimedActionExpandableBlockContainer } from "./TimedActionExpandableBlock.styled";
import TimedActionExpandableComment from "./Comment/Comment";
import { TaskAAR } from "../../../../../models/AfterActionReviewInterfaces";
import { useAppSelector } from "../../../../../store/hooks";

type Props = {
  isCollapsed: boolean;
  TaskAARType: TaskType;
  content: TaskAAR;
  onClick: Function;
};

const TimedActionExpandableBlock = ({
  TaskAARType,
  isCollapsed,
  content,
  onClick,
}: Props) => {
  const { Id, Comments, SubTaskAARs } = content;
  const { ActiveTaskId: ActiveBlockId } = useAppSelector(
    (state) => state.AfterActionReviewReducer,
  );

  return (
    <TimedActionExpandableBlockContainer
      className={
        ActiveBlockId === Id ? `expandable-block-active` : `expandable-block`
      }
      type={TaskAARType}
      isCollapsed={isCollapsed}
      onClick={() => onClick()}
    >
      {/* <TimedActionBlockUser /> <- Not implemented yet.
      <TimedActionBlockUser /> */}
      {SubTaskAARs &&
        SubTaskAARs.map((subtaskAAR) => (
          <TimedActionBlockSubtask content={subtaskAAR} />
        ))}
      <TimedActionExpandableComment comment={Comments} />
    </TimedActionExpandableBlockContainer>
  );
};

export default TimedActionExpandableBlock;
