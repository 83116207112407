import { Casualty } from "../../models/CasualtyInterfaces";
import { BloodType, Sex } from "../../models/Enums";
import { LeftPaneCasualtyCreatorViewTypes } from "../../pages/ScriptBuilder/EditComponents/ScriptDetailsView/constants";

export interface CasualtyFormType extends Casualty {
  AllergyIds: number[];
}
export interface initialStateType {
  DisplayCustomHeading: boolean;
  CurrentScreenHeading: string;
  CurrentCasualtyCreatorTab: string;
  CasualtyForm: CasualtyFormType;
}
export const initialState: initialStateType = {
  DisplayCustomHeading: false,
  CurrentScreenHeading: "",
  CurrentCasualtyCreatorTab: LeftPaneCasualtyCreatorViewTypes.CASUALTY_FORM,
  CasualtyForm: {
    Id: 0,
    Gender: Sex.Male,
    Age: 0,
    Weight: 0,
    BloodType: BloodType.ABPositive,
    Allergies: [],
    AllergyIds: [],
    /* not in use for updating right pane details*/
    Height: 121,
    WeightUnit: "kg",
  },
};
