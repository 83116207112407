import produce from "immer";
import { AppliedInjury } from "../../../models/InjuryInterface";
import {
  AppliedInsultWithRelation,
  InsultWithRelation,
} from "../../../models/InsultInterface";
import {
  INSULT_INJURY_ADD_APPLIED_INJURY,
  INSULT_INJURY_ADD_APPLIED_INJURY_INSULT,
  INSULT_INJURY_ADD_APPLIED_INSULT,
  INSULT_INJURY_ADD_INJURY_FOR_COMPARISON,
  INSULT_INJURY_ADD_INJURY_FOR_CREATE_MASTER_OPTION,
  INSULT_INJURY_ADD_INJURY_OBJ_INSULT,
  INSULT_INJURY_ADD_INJURY_TO_INJURIES,
  INSULT_INJURY_ADD_SCREEN_VISIT,
  INSULT_INJURY_ADD_UPDATE_INJURY_OBJ_INSULTS,
  INSULT_INJURY_REMOVE_APPLIED_INJURY,
  INSULT_INJURY_REMOVE_APPLIED_INJURY_INSULT,
  INSULT_INJURY_REMOVE_APPLIED_INSULT,
  INSULT_INJURY_REMOVE_INJURY_FOR_CREATE_MASTER_OPTION,
  INSULT_INJURY_REMOVE_INJURY_OBJ_INSULT,
  INSULT_INJURY_REMOVE_SCREEN_VISIT,
  INSULT_INJURY_RESET_APPLIED_INJURIES,
  INSULT_INJURY_RESET_APPLIED_INSULTS,
  INSULT_INJURY_RESET_FILTERED_ITEMS,
  INSULT_INJURY_RESET_INJURIES,
  INSULT_INJURY_RESET_INJURY_LOCATIONS,
  INSULT_INJURY_RESET_INJURY_OBJ,
  INSULT_INJURY_RESET_INSULTS,
  INSULT_INJURY_RESET_LABEL_DATA,
  INSULT_INJURY_RESET_SCREEN_VISIT_HISTORY,
  INSULT_INJURY_RESET_SELECTED_INSULT_OBJ,
  INSULT_INJURY_RESET_TO_INITIAL_STATE,
  INSULT_INJURY_SET_APPLIED_INJURIES,
  INSULT_INJURY_SET_APPLIED_INSULTS,
  INSULT_INJURY_SET_BACK_REDIRECT_SCREEN,
  INSULT_INJURY_SET_CURRENT_INSULT_FLOW_RATE,
  INSULT_INJURY_SET_CURRENT_VIEW,
  INSULT_INJURY_SET_DISPLAY_ALL_INJURY_INSULT,
  INSULT_INJURY_SET_FILTERED_ITEMS,
  INSULT_INJURY_SET_INJURIES,
  INSULT_INJURY_SET_INJURIES_FOR_COMPARISON,
  INSULT_INJURY_SET_INJURY_LOCATIONS,
  INSULT_INJURY_SET_INJURY_OBJ,
  INSULT_INJURY_SET_INSULTS,
  INSULT_INJURY_SET_LABEL_DATA,
  INSULT_INJURY_SET_SAVE_REDIRECT_SCREEN,
  INSULT_INJURY_SET_SELECTED_INSULT_OBJ,
  INSULT_INJURY_UPDATE_APPLIED_INJURY,
  INSULT_INJURY_UPDATE_APPLIED_INJURY_INSULT,
  INSULT_INJURY_UPDATE_APPLIED_INSULT,
  INSULT_INJURY_UPDATE_INJURY_FOR_COMPARISON,
  INSULT_INJURY_UPDATE_INJURY_OBJ,
  INSULT_INJURY_UPDATE_INJURY_OBJ_INSULT,
} from "./actionType";
import { initialState } from "./initialState";

export default produce((draft, action) => {
  if (!draft) {
    return initialState;
  }
  switch (action.type) {
    case INSULT_INJURY_SET_CURRENT_VIEW: {
      draft.CurrentView = action.view;
      break;
    }
    case INSULT_INJURY_SET_FILTERED_ITEMS: {
      draft[action.field] = action.data;
      break;
    }
    case INSULT_INJURY_RESET_FILTERED_ITEMS: {
      draft[action.field] = draft[action.originalField];
      break;
    }
    case INSULT_INJURY_SET_APPLIED_INSULTS: {
      draft.AppliedInsults = action.data;
      draft.FilteredAppliedInsults = action.data;
      break;
    }
    case INSULT_INJURY_ADD_APPLIED_INSULT: {
      draft.AppliedInsults.unshift(action.data);
      break;
    }
    case INSULT_INJURY_UPDATE_APPLIED_INSULT: {
      const InsultIndexToUpdate = draft.AppliedInsults.findIndex(
        (insult: AppliedInsultWithRelation) => insult.Id === action.Id,
      );
      if (InsultIndexToUpdate !== -1) {
        draft.AppliedInsults[InsultIndexToUpdate] = action.data;
      }
      break;
    }
    case INSULT_INJURY_REMOVE_APPLIED_INSULT: {
      const InsultIndexToDelete = draft.AppliedInsults.findIndex(
        (insult: AppliedInsultWithRelation) => insult.Id === action.Id,
      );
      draft.AppliedInsults.splice(InsultIndexToDelete, 1);
      break;
    }
    case INSULT_INJURY_RESET_APPLIED_INSULTS: {
      draft.AppliedInsults = [];
      draft.FilteredAppliedInsults = [];
      break;
    }
    case INSULT_INJURY_SET_APPLIED_INJURIES: {
      draft.AppliedInjuries = action.data;
      draft.FilteredAppliedInjuries = action.data;
      break;
    }
    case INSULT_INJURY_RESET_APPLIED_INJURIES: {
      draft.AppliedInjuries = [];
      draft.FilteredAppliedInjuries = [];
      break;
    }
    case INSULT_INJURY_ADD_APPLIED_INJURY: {
      draft.AppliedInjuries.unshift(action.data);
      break;
    }
    case INSULT_INJURY_UPDATE_APPLIED_INJURY: {
      const InjuryIndexToUpdate = draft.AppliedInjuries.findIndex(
        (injury: AppliedInjury) => injury.Id === action.Id,
      );
      if (InjuryIndexToUpdate !== -1) {
        draft.AppliedInjuries[InjuryIndexToUpdate] = action.data;
        draft.AppliedInjuries[InjuryIndexToUpdate].Id = action.data["NewId"]
          ? action.data["NewId"]
          : action.data["Id"];
      }
      break;
    }
    case INSULT_INJURY_REMOVE_APPLIED_INJURY: {
      const InjuryIndexToDelete = draft.AppliedInjuries.findIndex(
        (injury: AppliedInjury) => injury.Id === action.Id,
      );
      draft.AppliedInjuries.splice(InjuryIndexToDelete, 1);
      break;
    }
    case INSULT_INJURY_ADD_APPLIED_INJURY_INSULT: {
      const index = draft.AppliedInjuries.findIndex(
        (injury: AppliedInjury) => injury.Id === action.Id,
      );
      if (index !== -1) {
        draft.AppliedInjuries[index].Insults.push(action.data);
      }
      break;
    }
    case INSULT_INJURY_UPDATE_APPLIED_INJURY_INSULT: {
      const injuryIndex = draft.AppliedInjuries.findIndex(
        (injury: AppliedInjury) => injury.Id === action.Id,
      );
      if (injuryIndex !== -1) {
        const insultIndex = draft.AppliedInjuries[
          injuryIndex
        ].Insults.findIndex(
          (x: InsultWithRelation) => x.Id === action.InsultId,
        );
        if (insultIndex !== -1) {
          draft.AppliedInjuries[injuryIndex].Insults[insultIndex] = action.data;
        }
      }
      break;
    }
    case INSULT_INJURY_REMOVE_APPLIED_INJURY_INSULT: {
      const currentInjuryIndex = draft.AppliedInjuries.findIndex(
        (injury: AppliedInjury) => injury.Id === action.Id,
      );
      if (currentInjuryIndex !== -1) {
        const insultIndex = draft.AppliedInjuries[
          currentInjuryIndex
        ].Insults.findIndex(
          (insult: { Id: number }, ind: number) =>
            (action.InsultId && insult.Id === action.InsultId) ||
            (!action.InsultId && ind === action.Index),
        );
        if (insultIndex !== -1) {
          draft.AppliedInjuries[currentInjuryIndex].Insults.splice(
            insultIndex,
            1,
          );
        }
      }
      break;
    }

    case INSULT_INJURY_SET_INJURY_OBJ: {
      draft.InjuryObj = action.data;
      break;
    }

    case INSULT_INJURY_UPDATE_INJURY_OBJ: {
      draft.InjuryObj.Id = action.data.Id ? action.data.Id : draft.InjuryObj.Id;
      draft.InjuryObj.Title = action.data.Title
        ? action.data.Title
        : draft.InjuryObj.Title;
      draft.InjuryObj.LocationId = action.data.LocationId
        ? action.data.LocationId
        : draft.InjuryObj.LocationId;
      draft.InjuryObj.Location =
        action.data.Location !== undefined
          ? action.data.Location || null
          : draft.InjuryObj.Location;
      draft.InjuryObj.AppliedLocationSide = action.data.AppliedLocationSide;
      draft.InjuryObj.Insults = action.data.Insults
        ? action.data.Insults
        : draft.InjuryObj.Insults;
      break;
    }
    case INSULT_INJURY_ADD_UPDATE_INJURY_OBJ_INSULTS: {
      const insultIndex = draft.InjuryObj.Insults.findIndex(
        (i: InsultWithRelation) =>
          (i.Id && i.Id === action.InsultId) ||
          (!action.InsultId &&
            !i.Id &&
            i.CopySourceInsultId === action.CopySourceInsultId),
      );
      if (insultIndex !== -1) {
        draft.InjuryObj.Insults[insultIndex] = action.data;
      } else {
        draft.InjuryObj.Insults.push(action.data);
      }
      break;
    }

    case INSULT_INJURY_ADD_INJURY_OBJ_INSULT: {
      draft.InjuryObj.Insults.push(action.data);
      break;
    }
    case INSULT_INJURY_UPDATE_INJURY_OBJ_INSULT: {
      const updateInjuryObjInsultIndex = draft.InjuryObj.Insults.findIndex(
        (x: InsultWithRelation) => x.Id === action.InsultId,
      );
      if (updateInjuryObjInsultIndex !== -1) {
        draft.InjuryObj.Insults[updateInjuryObjInsultIndex] = action.data;
      }
      break;
    }
    case INSULT_INJURY_REMOVE_INJURY_OBJ_INSULT: {
      const insultIndex = draft.InjuryObj.Insults.findIndex(
        (insult: { Id: number }, ind: number) =>
          (action.InsultId && insult.Id === action.InsultId) ||
          (!action.InsultId && ind === action.Index),
      );
      if (insultIndex !== -1) {
        draft.InjuryObj.Insults.splice(insultIndex, 1);
      }
      break;
    }

    case INSULT_INJURY_RESET_INJURY_OBJ: {
      draft.InjuryObj = {
        Id: 0,
        Title: "",
        LocationId: 0,
        CopySourceInjuryId: 0,
        Type: "Injury",
        Insults: [],
      };
      break;
    }
    case INSULT_INJURY_SET_INJURIES_FOR_COMPARISON: {
      draft.AppliedInjuriesForComparison = action.data;
      break;
    }
    case INSULT_INJURY_ADD_INJURY_FOR_COMPARISON: {
      draft.AppliedInjuriesForComparison.push(action.data);
      break;
    }
    case INSULT_INJURY_UPDATE_INJURY_FOR_COMPARISON: {
      const index = draft.AppliedInjuriesForComparison.findIndex(
        (injury: AppliedInjury) => action.Id === injury.Id,
      );
      if (index !== -1) {
        draft.AppliedInjuriesForComparison[index] = action.data;
      }
      break;
    }
    case INSULT_INJURY_ADD_INJURY_FOR_CREATE_MASTER_OPTION: {
      const index = draft.AppliedInjuryForCreateMaster.findIndex(
        (Id: number) => Id === action.Id,
      );
      if (index === -1) {
        draft.AppliedInjuryForCreateMaster.push(action.Id);
      }
      break;
    }
    case INSULT_INJURY_REMOVE_INJURY_FOR_CREATE_MASTER_OPTION: {
      const index = draft.AppliedInjuryForCreateMaster.findIndex(
        (Id: number) => Id === action.Id,
      );
      if (index !== -1) {
        draft.AppliedInjuryForCreateMaster.splice(index, 1);
      }
      break;
    }
    case INSULT_INJURY_SET_SELECTED_INSULT_OBJ: {
      draft.InsultObj = action.data;
      break;
    }
    case INSULT_INJURY_RESET_SELECTED_INSULT_OBJ: {
      draft.InsultObj = null;
      draft.CurrentInsultFlowRate = 0;
      break;
    }
    case INSULT_INJURY_SET_INSULTS: {
      draft.Insults = action.data;
      draft.FilteredInsults = action.data;
      break;
    }
    case INSULT_INJURY_RESET_INSULTS: {
      draft.Insults = [];
      draft.FilteredInsults = [];
      break;
    }
    case INSULT_INJURY_SET_INJURIES: {
      draft.Injuries = action.data;
      draft.FilteredInjuries = action.data;
      break;
    }
    case INSULT_INJURY_ADD_INJURY_TO_INJURIES: {
      draft.Injuries.push(action.data);
      break;
    }
    case INSULT_INJURY_RESET_INJURIES: {
      draft.Injuries = [];
      draft.FilteredInjuries = [];
      break;
    }
    case INSULT_INJURY_SET_INJURY_LOCATIONS: {
      draft.InjuryLocations = action.data;
      break;
    }
    case INSULT_INJURY_RESET_INJURY_LOCATIONS: {
      draft.InjuryLocations = [];
      break;
    }
    case INSULT_INJURY_ADD_SCREEN_VISIT: {
      draft.ScreenVisitHistory.push(action.data);
      break;
    }
    case INSULT_INJURY_REMOVE_SCREEN_VISIT: {
      draft.ScreenVisitHistory.splice(action.index, 1);
      break;
    }
    case INSULT_INJURY_RESET_SCREEN_VISIT_HISTORY: {
      draft.ScreenVisitHistory = [];
      break;
    }
    case INSULT_INJURY_SET_BACK_REDIRECT_SCREEN: {
      draft.BackRedirectScreen = action.data;
      break;
    }
    case INSULT_INJURY_SET_SAVE_REDIRECT_SCREEN: {
      draft.SaveRedirectScreen = action.data;
      break;
    }
    case INSULT_INJURY_RESET_TO_INITIAL_STATE: {
      return initialState;
    }
    case INSULT_INJURY_SET_LABEL_DATA: {
      draft.LabelData = action.data;
      break;
    }
    case INSULT_INJURY_RESET_LABEL_DATA: {
      draft.LabelData = [];
      break;
    }
    case INSULT_INJURY_SET_DISPLAY_ALL_INJURY_INSULT: {
      draft.DisplayAllInjuryInsult = action.data;
      break;
    }
    case INSULT_INJURY_SET_CURRENT_INSULT_FLOW_RATE: {
      draft.CurrentInsultFlowRate = action.data;
      break;
    }
    default:
      break;
  }
});
