import { hexTransparencies } from "../styles/MainList.styled";

export const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 1073741824; // 1GB

export const ChartColors = {
  Red: "#F94144",
  Blue: "#2D9CDB",
  Yellow: "#F9C75A",
  Green: "#90BE6D",
  Purple: "#7D1EF6",
  Orange: "#F3722C",
};

export const ChartBorderColors = {
  Red: `#F94144${hexTransparencies[50]}`,
  Blue: `#2D9CDB${hexTransparencies[50]}`,
  Yellow: `#F9C75A${hexTransparencies[50]}`,
  Green: `#90BE6D${hexTransparencies[50]}`,
  Purple: `#7D1EF6${hexTransparencies[50]}`,
  Orange: `#F3722C${hexTransparencies[50]}`,
};

export const graphColors = [
  "#FF4242",
  "#FEA40A",
  "#7D1EF6",
  "#23D0EB",
  "#7AFC17",
  "#E6CF09",
];
