import styled from "styled-components";
import { VitalType } from "../../../../../models/Enums";
import { colorPalette } from "../../../../../styles/MainList.styled";

type VitalsBlockContainerProps = {
  vitalsType: VitalType;
  value1: number;
  value2: number;
};
export const VitalsBlockContainer = styled.div<VitalsBlockContainerProps>`
  background: #161616;
  padding: 5px 7px;
  margin-bottom: 1px;
  width: 100%;
  height: calc(10% - 1px);

  color: ${(props) =>
    props.vitalsType === VitalType.Pain
      ? "#ffffff"
      : props.vitalsType === VitalType.SI &&
        props.value1 > 0 &&
        props.value1 < 0.7
      ? "#ffffff"
      : props.vitalsType === VitalType.SI &&
        props.value1 >= 0.7 &&
        props.value1 < 0.8
      ? colorPalette.warning
      : props.vitalsType === VitalType.SI && props.value1 >= 0.8
      ? colorPalette.error
      : props.vitalsType === VitalType.HR &&
        ((props.value1 !== 0 && props.value1 < 30) || props.value1 >= 121)
      ? colorPalette.error
      : props.vitalsType === VitalType.HR &&
        ((props.value1 >= 30 && props.value1 <= 50) ||
          (props.value1 >= 91 && props.value1 <= 120))
      ? colorPalette.warning
      : props.vitalsType === VitalType.BP &&
        (props.value1 > 160 ||
          props.value2 > 140 ||
          (props.value1 < 40 && props.value1 !== 0) ||
          (props.value2 < 40 && props.value2 !== 0))
      ? colorPalette.error
      : props.vitalsType === VitalType.BP &&
        ((props.value1 <= 40 && props.value1 >= 89) ||
          (props.value1 >= 131 && props.value1 <= 139) ||
          (props.value2 >= 40 && props.value2 <= 59) ||
          (props.value2 >= 71 && props.value2 <= 80))
      ? colorPalette.warning
      : props.vitalsType === VitalType.MAP && props.value1 > 101
      ? colorPalette.error
      : props.vitalsType === VitalType.MAP &&
        props.value1 < 69 &&
        props.value1 !== 0
      ? colorPalette.warning
      : props.vitalsType === VitalType.RR &&
        ((props.value1 < 11 && props.value1 !== 0) || props.value1 >= 21)
      ? colorPalette.error
      : props.vitalsType === VitalType.SpO2 &&
        props.value1 <= 67 &&
        props.value1 !== 0
      ? colorPalette.error
      : props.vitalsType === VitalType.SpO2 &&
        props.value1 >= 68 &&
        props.value1 <= 91
      ? colorPalette.warning
      : props.vitalsType === VitalType.EtCO2 &&
        ((props.value1 <= 34 && props.value1 !== 0) || props.value1 > 46)
      ? colorPalette.error
      : props.vitalsType === VitalType.Temp &&
        ((props.value1 <= 93.9 && props.value1 !== 0) || props.value1 >= 100.4)
      ? colorPalette.error
      : props.vitalsType === VitalType.Temp &&
        props.value1 >= 94 &&
        props.value1 <= 97.9
      ? colorPalette.warning
      : props.vitalsType === VitalType.UO &&
        props.value1 > 0 &&
        props.value1 <= 5
      ? colorPalette.error
      : props.vitalsType === VitalType.UO &&
        props.value1 >= 6 &&
        props.value1 <= 20
      ? colorPalette.warning
      : props.vitalsType === VitalType.UO &&
        props.value1 >= 21 &&
        props.value1 <= 90
      ? "#ffffff"
      : props.vitalsType === VitalType.UO &&
        props.value1 >= 91 &&
        props.value1 <= 125
      ? colorPalette.warning
      : props.vitalsType === VitalType.UO && props.value1 >= 126
      ? colorPalette.error
      : "#ffffff"};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    display: flex;
    flex-direction: column;

    .title-text {
      font-size: 0.75rem;
      line-height: 0.875rem;
    }

    .unit {
      font-size: 0.5rem;
      line-height: 0.563rem;
    }
  }

  .value {
    font-size: ${(props) =>
      props.vitalsType === VitalType.SI ? "1.125rem" : "1.6rem"};
    line-height: ${(props) =>
      props.vitalsType === VitalType.SI ? "1.125rem" : "2.5rem"};

    .fraction {
      font-size: ${(props) =>
        props.vitalsType === VitalType.SI ? "1.6rem" : "1.125rem"};
      line-height: ${(props) =>
        props.vitalsType === VitalType.SI ? "2.5rem" : "1.125rem"};
    }
  }

  .value {
    margin-left: auto;
    position: relative;
    top: -10%;
    text-align: end;
    padding-right: 15px;

    &.value-long {
      font-size: 1.4rem;
      padding-right: 15px;
    }
  }
`;
