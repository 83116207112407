import React, { useState } from "react";
import {
  SelectDiv,
  Selector,
  MutiSelector,
  LabelSpan,
  Label,
  ErrorSpan,
  AllergyLabel,
  Form,
  AllergyLabelDiv,
} from "./AgeWeight.styled";
import { BloodType } from "../../../../../models/Enums";

interface SelectProps {
  selectBloodType: any;
  onChangeBloodType: any;
  allergiesData: any;
  selectAllergy: any;
  handleChangeAllergy: any;
  onSave: any;
  onSaveAllergies: any;
}

const SelectOption = (props: SelectProps) => {
  const [classNameForLabel] = useState<string>("label-name");
  const options: any = [];

  for (let i = 0; i < props.allergiesData.length; i++) {
    options.push({
      key: props.allergiesData[i].Id,
      label: props.allergiesData[i].Label,
      value: props.allergiesData[i].Id,
    });
  }
  console.log("THIS IS SELECT ALLERGY", props.selectAllergy);
  return (
    <SelectDiv>
      <Form>
        <Selector
          secondSelector={false}
          value={props.selectBloodType}
          onChange={props.onChangeBloodType}
          onBlur={props.onSave}
        >
          <option value={BloodType.APositive}> A+</option>
          <option value={BloodType.ANegative}> A-</option>
          <option value={BloodType.ABPositive}> AB+</option>
          <option value={BloodType.ABNegative}> AB-</option>
          <option value={BloodType.BPositive}> B+</option>
          <option value={BloodType.BNegative}> B-</option>
          <option value={BloodType.OPositive}> O+</option>
          <option value={BloodType.ONegative}> O-</option>
        </Selector>
        <Label className={classNameForLabel} htmlFor="bloodtype">
          <LabelSpan
            className={
              props.selectBloodType == null
                ? "content-name"
                : "content-name filled-content"
            }
          >
            Blood Type
          </LabelSpan>
          <ErrorSpan className="error"></ErrorSpan>
        </Label>
      </Form>
      <AllergyLabelDiv
        style={{ position: "relative" }}
        className="allergy-label-container"
      >
        {/*<Form>*/}
        <MutiSelector
          mode="multiple"
          style={{
            width: "100%",
            backgroundColor: "rgba(0,0,0,255) !important",
          }}
          value={props.selectAllergy}
          onChange={props.handleChangeAllergy}
          options={options}
          onBlur={props.onSaveAllergies}
        />
        <AllergyLabel className={classNameForLabel} htmlFor="multiselector">
          <LabelSpan
            className={
              props.selectAllergy.length === 0
                ? "content-name"
                : "content-name filled-content"
            }
          >
            Allergy
          </LabelSpan>
          <ErrorSpan className="error"></ErrorSpan>
        </AllergyLabel>
      </AllergyLabelDiv>
    </SelectDiv>
  );
};

export default SelectOption;
