import React, { ReactNode } from "react";
import { VitalType } from "../../../../../models/Enums";
import { VitalsBlockContainer } from "./VitalsBlock.styled";

type Props = {
  vitalsType: VitalType;
  title: string;
  unit: string | ReactNode;
  value1: number;
  value2?: number;
};

function IsNumberNotZero(value: number) {
  if (value <= 0) return "-";
  else return value;
}

const VitalsBlock = ({ vitalsType, title, unit, value1, value2 }: Props) => {
  const vitalsCanHaveFractionalValues = [VitalType.Temp, VitalType.SI];
  const formattedValue1 = vitalsCanHaveFractionalValues.includes(vitalsType)
    ? Number(value1.toFixed(2))
    : Math.round(value1);
  const formattedValue2 = vitalsCanHaveFractionalValues.includes(vitalsType)
    ? Number(value2?.toFixed(2) || 0)
    : Math.round(value2 || 0);

  /**
   * A function that accepts a fractional number and splits it into its integer part and the first digit after the decimal point.
   * @param num - The fractional number to be split.
   * @return An object that contains the integer part and the first fractional digit.
   */
  function splitFractionalNumber(num: number): {
    integerPart: number;
    fractionDigits: number;
  } {
    const integerPart: number = Math.floor(num);
    return {
      integerPart,
      fractionDigits: Math.floor((num - integerPart) * 10),
    };
  }

  return (
    <VitalsBlockContainer
      value1={formattedValue1}
      value2={formattedValue2 || 0}
      vitalsType={vitalsType}
      className={title.toLocaleLowerCase()}
    >
      <div className="title">
        <span className="title-text">{title.toUpperCase()}</span>
        <span className="unit">{unit}</span>
      </div>
      {formattedValue2 ? (
        <div className="value value-long">
          <span>
            {IsNumberNotZero(formattedValue1)}/
            {IsNumberNotZero(formattedValue2)}
          </span>
        </div>
      ) : Number.isInteger(formattedValue1) ? (
        <div className="value">
          <span>{IsNumberNotZero(formattedValue1)}</span>
        </div>
      ) : (
        <div className="value">
          <span>{splitFractionalNumber(formattedValue1).integerPart}</span>
          <span className="fraction">
            .{splitFractionalNumber(formattedValue1).fractionDigits}
          </span>
        </div>
      )}
    </VitalsBlockContainer>
  );
};

export default VitalsBlock;
