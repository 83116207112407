import * as React from "react";
import { Select as AntSelect } from "antd";
import { SelectProps } from "antd/lib/select";
import styled from "styled-components";
import { GrayColorPalette, colorPalette } from "../../styles/MainList.styled";

const StyledAntSelect = styled.div`
  padding-top: 22px;
  position: relative;

  .error {
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.3s ease;
    font-size: 0.86rem;
    color: ${colorPalette.error};
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: normal !important;
  }

  .label {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    font-size: 0.86rem;
    color: ${colorPalette.gray25};

    &.floatingLabel {
      font-size: 1.25rem;
      top: 26px;
      left: 11px;
    }
  }

  .ant-select-selection-search-input {
    font-size: 1.25rem;
  }

  .ant-select-arrow {
    color: ${colorPalette.gray25};
    transition: all 0.5s ease;
  }

  .ant-select-selector {
    min-height: 40px;
    font-size: 1.25rem;
    margin-bottom: 4px;
    border-radius: 0 !important;
  }

  .ant-select-selection-item {
    font-size: 1rem;
    color: #fff;
  }

  .ant-select-multiple {
    &.ant-select-disabled .ant-select-selector {
      background: transparent;
    }
    &.ant-select-focused {
      &.ant-select-open {
        .ant-select-selector::after {
          transform: rotateX(178deg);
          transition: all 0.5s ease;
        }
      }
    }
    .ant-select-selection-item {
      border: none;
      color: ${GrayColorPalette.Gray1};
      background-color: ${GrayColorPalette.Gray6};
      border-radius: 0.3125rem;
      padding-left: 14px;
      padding-right: 14px;
      &:hover {
        background-color: ${GrayColorPalette.Gray4};
        color: ${colorPalette.white};
      }

      .ant-tag-close-icon {
        margin-top: auto;

        .anticon {
          font-size: 0.9rem;
          color: #fff;
        }
      }
    }
    .ant-select-selection-item-remove {
      .anticon-close {
        margin-left: 0.3rem;
        font-size: 0.8rem;
        color: ${GrayColorPalette.Gray3};
        height: -webkit-fill-available;
        vertical-align: middle;
        display: flex;
        align-items: center;
      }
      &:hover {
        .anticon-close {
          color: ${colorPalette.white};
        }
      }
    }
  }

  .ant-select-single {
    .ant-select-selection-item {
      font-size: 1.25rem;
      padding: 4px 0;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-bottom: 1px solid ${colorPalette.gray25};
    box-shadow: none !important;
    background-color: transparent;

    &:hover {
      border-bottom: 1px solid ${colorPalette.white};
    }
    &:focus {
      background-color: rgba(255, 255, 255, 0.05);
      border-bottom: 1px solid ${colorPalette.primary100};
    }
  }
  .ant-select:not(.ant-select-customize-input) {
    &:hover {
      .ant-select-arrow {
        color: ${colorPalette.white};
      }
    }
    &.ant-select-focused {
      .ant-select-selector {
        border-bottom: 1px solid ${colorPalette.primary100};
      }
      &.ant-select-open {
        .ant-select-arrow {
          transform: rotateX(178deg);
          transition: all 0.5s ease;
        }
      }
    }
    &.ant-select-disabled {
      .ant-select-arrow {
        color: ${colorPalette.disabledInput};
      }
      .ant-select-selection-item {
        color: ${colorPalette.disabledInput};
      }
      .ant-select-selector {
        border-bottom: 1px solid ${colorPalette.disabledInput};
        &:hover {
          border-bottom: 1px solid ${colorPalette.disabledInput};
        }
      }
    }
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    color: ${colorPalette.white};
  }

  .ant-select-open {
    background-color: #ffffff0d;

    .ant-select-selection-item {
      color: ${colorPalette.white};
    }
  }
  &:hover {
    .label.floatingLabel {
      color: ${colorPalette.white};
    }
  }
`;

interface FloatingLabelSelectProps extends SelectProps {
  label?: any;
  error?: string;
  className?: string;
  readOnly?: boolean;
}

const FloatingLabelSelect = (props: FloatingLabelSelectProps) => {
  const { label, error, readOnly, ...otherProps } = props;

  const [isFocused, setIsFocused] = React.useState(false);

  const labelClass = (value: any) => {
    if (props.mode === "multiple") {
      return isFocused || props.value?.length > 0
        ? `label ${props.disabled ? "disabled" : ""}`
        : `label floatingLabel ${props.disabled ? "disabled" : ""}`;
    } else {
      return isFocused || props.value !== null
        ? `label ${props.disabled ? "disabled" : ""}`
        : `label floatingLabel ${props.disabled ? "disabled" : ""}`;
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <StyledAntSelect className={`${props.className}`}>
      <label className={labelClass(props.value)}>{label}</label>
      {!!error && <div className="error">{error}</div>}
      <AntSelect
        {...otherProps}
        onFocus={handleFocus}
        onBlur={handleBlur}
        aria-readonly={readOnly ? true : false}
      >
        {props.children}
      </AntSelect>
    </StyledAntSelect>
  );
};

export default FloatingLabelSelect;
