import * as React from "react";
import { Tooltip, Divider, Input } from "antd";
import {
  StyledDetailsPlusCircleOutlined,
  StyledDetailsSendOutlinedIcon,
} from "./DetailsPanelComponents.styled";

const { TextArea } = Input;

export const CommentField = () => {
  return (
    <span className="comment-field">
      <span className="ellipse"></span>
      <form>
        <TextArea
          rows={3}
          bordered={false}
          placeholder="Ask a question or post an update..."
        />
      </form>
      <span className="post-comment-icons">
        <StyledDetailsPlusCircleOutlined />
        <StyledDetailsSendOutlinedIcon />
      </span>
    </span>
  );
};
