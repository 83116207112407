import axios from "axios";
import { CreateWidget } from "../../models/DashboardInterface";

const apiPrefix = "api/1/pfc/Dashboard";

export function createWidget(
  payload: CreateWidget,
  callback: Function,
  errorCallback: Function,
) {
  console.log(payload);
  axios
    .post(`${apiPrefix}/Widget`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      errorCallback(err.response.data.ErrorMessage);
    });
}

export function updateWidgetDimensions(
  payload: {
    Id: number;
    X: number;
    Y: number;
    W: number;
    H: number;
  }[],
  callback: Function,
  errorCallback: Function,
) {
  axios
    .put(`${apiPrefix}/Widget/Dimensions/Bulk`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      errorCallback(err.response.data.ErrorMessage);
    });
}
