import React, { useEffect, useState } from "react";
import { StyledForm } from "../PhaseForm/PhaseForm.styled";
import { useFormik } from "formik";
import { FormContainer } from "../RolePlayerForm/PromptForm.styled";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreatorsForTextOnlyBlocks } from "../../../../../store/index";
import {
  GenericBlock,
  Text,
  Text as OurText,
} from "../../../../../models/ScriptInterfaces";
import FloatingLabelTextArea from "../../../../../components/FloatingLabel/FloatingLabelTextArea";
import { ThunderboltOutlined } from "@ant-design/icons";
import { GenderButtonWrapper, StyledBlueButton } from "./RolePlayerForm.styled";
import { Select } from "antd";
import { generateAudioFromText } from "../../../../../api/Mimic/ApiPost";
import { OpenErrorNotification } from "../../../../../components/Notification/Notification";
import { CustomSolidButton } from "../../../../../styles/Buttons.styled";
import FloatingLabelSelect from "../../../../../components/MuiSelect/FloatingLabelSelect";
import ConfirmDialog from "../../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../../components/ConfirmDialog/useConfirmDialog";
import GenerateText from "../../../../../components/OpenAIComponent";
import { UpdateTextBlock } from "../../../../../api/Block/ApiPost";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { useAppSelector } from "../../../../../store/hooks";
const { Option } = Select;
interface TextFormProps {
  type: string;
  object: Text;
  onChange: Function;
  onSave: Function;
  updateBlockWithAudio: Function;
  setLoading: (value: boolean) => void;
}

type ErrorType = {
  [key: string]: string;
};
const defaultMaleVoiceOption = "en_US/cmu-arctic_low#bdl";
const defaultFemaleVoiceOption = "en_US/m-ailabs_low#mary_ann";
const VoiceOptions = [
  {
    value: "en_US/cmu-arctic_low#bdl",
    label: "Male 1",
    type: "Male",
  },
  {
    value: "en_US/cmu-arctic_low#jmk",
    label: "Male 2",
    type: "Male",
  },
  {
    value: "en_US/cmu-arctic_low#rxr",
    label: "Male 3",
    type: "Male",
  },
  {
    value: "en_US/m-ailabs_low#mary_ann",
    label: "Female 1",
    type: "Female",
  },
  {
    value: "en_US/cmu-arctic_low#eey",
    label: "Female 2",
    type: "Female",
  },
  {
    value: "en_US/cmu-arctic_low#ljm",
    label: "Female 3",
    type: "Female",
  },
];
const WhatToSayForm = (props: TextFormProps) => {
  const dispatch = useDispatch();
  const {
    SetLastFocused,
    SetIsFocused,
    updateWhatToSay,
    updateSupportActionText,
  } = bindActionCreators(actionCreatorsForTextOnlyBlocks, dispatch);
  const state = useAppSelector((state) => state.TextBlockReducer);
  const {
    isReadOnly,
    currentPhaseIndex,
    currentSectionIndex,
    currentBlockIndex,
    phases,
  } = useAppSelector((state: any) => state.MainlistReducer);
  const [gender, setGender] = useState<string>("Female"); // Male || female
  const [voiceOption, setVoiceOption] = useState<string>(
    defaultFemaleVoiceOption,
  );
  const [voiceSpeed, setVoiceSpeed] = useState<number>(1);
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();
  const scriptFormik = useFormik({
    initialValues: {
      whatToSay: props.object?.Description || "",
    },
    validate: (values) => {
      const errors: ErrorType = {};

      if (!values.whatToSay.trim()) {
        errors["whatToSay"] = "Required";
      }
      if (values.whatToSay.trim().length > 500) {
        errors["whatToSay"] = "Max 500 characters";
      }
      return errors;
    },
    validateOnBlur: true,
    onSubmit: () => {
      props.onSave();
    },
  });

  const handleChange = (e: any) => {
    if (isReadOnly) return; //disable action callback in read only script
    if (props.type === "RolePlayer") {
      updateWhatToSay(e.target.value);
    } else {
      updateSupportActionText(e.target.value);
    }
    props.onChange(e);
  };
  const onClickConvertAudio = () => {
    if (props.object?.UploadedMediaItems?.length) {
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "",
        content: "This will overwrite your existing audio file. Continue?",
        okText: "Yes",
        onOk: () => {
          convertAudio();
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "warning",
      });
    } else {
      convertAudio();
    }
  };

  const convertAudio = () => {
    props.setLoading(true);
    const text = scriptFormik.values["whatToSay"];
    generateAudioFromText(
      {
        text,
        voice: voiceOption,
        lengthScale: voiceSpeed, // Speaking rate
      },
      async (data: any) => {
        props.setLoading(false);
        try {
          const blob = await fetch(data.Url).then((r) => r.blob());
          const fileName = data.FileName;
          const url = URL.createObjectURL(blob);
          props.updateBlockWithAudio(data);
          // download audio file to system
          const link: any = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(data.Url);
          }, 0);
        } catch (error: any) {
          OpenErrorNotification({
            description: error.message,
          });
          props.setLoading(false);
        }
      },
      (error: any) => {
        OpenErrorNotification({
          description: error.message,
        });
        props.setLoading(false);
      },
    );
  };
  const handleVoiceChange = (value: string) => {
    setVoiceOption(value);
  };
  const completedSentenceCB = (text: string) => {
    scriptFormik.setFieldValue("whatToSay", text.trim());
    const customEvent = {
      target: {
        value: text.trim(),
      },
    };
    handleChange(customEvent);
    setTimeout(() => {
      const updateItem =
        phases![currentPhaseIndex].Sections![currentSectionIndex]!.Blocks![
          currentBlockIndex
        ];
      const textBlockPayload: GenericBlock<OurText> = {
        ...updateItem,
        BlockContent: {
          ...updateItem.BlockContent,
          Description: text.trim(),
        },
      };
      UpdateTextBlock(
        textBlockPayload,
        (response: any) => {
          console.log(response);
        },
        (error: any) => {
          OpenErrorNotification({
            description: error,
          });
        },
      );
    }, 3000);
  };

  const debounceSubmitForm = useDebounce(scriptFormik.submitForm);

  useEffect(() => {
    if (scriptFormik.isValid) {
      debounceSubmitForm();
    }

    return () => {
      debounceSubmitForm.cancel();
    };
  }, [scriptFormik.values, scriptFormik.isValid]);

  return (
    <FormContainer>
      <StyledForm>
        <div style={{ marginBottom: "30px" }}>
          <FloatingLabelTextArea
            autoFocus
            onChange={handleChange}
            scriptFormik={scriptFormik}
            lastFocused={state.lastFocused}
            setIsFocused={SetIsFocused}
            setLastFocused={SetLastFocused}
            isFocused={state.isFocused}
            label={
              props.type === "RolePlayer"
                ? "Acting Instructions*"
                : "Support Action*"
            }
            textareaName={"whatToSay"}
            value={scriptFormik.values["whatToSay"]}
            onSave={props.onSave}
            enableLiveCount={true}
            // placeholder={`Type in ${props.type === "RolePlayer" ? "role prompt" : "role prompt"} and I'll finish the rest...`}
            required
            maxLength={500}
            readOnly={isReadOnly}
          />

          <GenerateText
            input={scriptFormik.values["whatToSay"]}
            maxLength={400}
            completedSentenceCB={completedSentenceCB}
          />
        </div>
        {isReadOnly ? null : (
          <>
            <p className="mb-4">Customized AI Voice</p>

            <GenderButtonWrapper className="mb-8">
              <CustomSolidButton
                type={`${gender === "Female" ? "primary" : "gray"}`}
                onClick={() => {
                  setGender("Female");
                  setVoiceOption(defaultFemaleVoiceOption);
                }}
              >
                {" "}
                Female
              </CustomSolidButton>
              <CustomSolidButton
                type={`${gender === "Male" ? "primary" : "gray"}`}
                onClick={() => {
                  setGender("Male");
                  setVoiceOption(defaultMaleVoiceOption);
                }}
              >
                {" "}
                Male
              </CustomSolidButton>
            </GenderButtonWrapper>

            <FloatingLabelSelect
              label={"Voice Option"}
              className="mb-4"
              onChange={handleVoiceChange}
              value={voiceOption}
            >
              {VoiceOptions.filter((x) => x.type === gender).map(
                (item: any) => {
                  return (
                    <Option title="" value={item.value} key={item.value}>
                      {item.label}
                    </Option>
                  );
                },
              )}
            </FloatingLabelSelect>

            <FloatingLabelSelect
              label={"Speaking Rate"}
              className="mb-4"
              onChange={(value) => setVoiceSpeed(value)}
              value={voiceSpeed}
            >
              <Option title="" value={1.25}>
                0.75x
              </Option>
              <Option title="" value={1}>
                1x
              </Option>
              <Option title="" value={0.75}>
                1.25x
              </Option>
              <Option title="" value={0.5}>
                1.5x
              </Option>
            </FloatingLabelSelect>

            <StyledBlueButton
              disabled={!scriptFormik.values["whatToSay"].length}
              onClick={onClickConvertAudio}
              htmlType="button"
              icon={<ThunderboltOutlined />}
            >
              Convert to Audio
            </StyledBlueButton>
          </>
        )}
      </StyledForm>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </FormContainer>
  );
};

export default WhatToSayForm;
