import * as React from "react";
import { useState } from "react";
import { Section, Phase } from "../../../models/ScriptInterfaces";
import { isNullOrUndefined } from "../../../utils/utilityfunctions";
import { Container } from "../../../styles/EditComponentTheme.styled";
import { IconColumn } from "./IconColumn";
import SectionEditForm from "./EditForms/SectionEditForm/SectionEditForm";
import {
  ComponentEdit,
  SetActiveComponent,
  SetCurrentBlockIndex,
  SetCurrentSectionIndex,
  SetPhases,
  SetSectionTitle,
} from "../MainlistSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import SectionCopyMovePanel from "./EditForms/SectionEditForm/SectionCopyMovePanel";
import { CopyOrMoveSection } from "../../../api/Section/ApiPost";
import { OpenErrorNotification } from "../../../components/Notification/Notification";
import { v4 as uuidv4 } from "uuid";
import produce from "immer";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";

interface Props {
  object: Section;
  TitleAbbreviated: string;
  postion: number;
  phase: Phase;
  onDelete: any; //Function
  sectionTitle: string;
  handleCriticalOrAARToggle: Function;
  setSectionType: Function;
  postError: Function;
  postSuccess: Function;
}
const CopyTimedActionBtnDisabledTooltip = "Move/Copy Section to Phase";
const CopyMoveSectionTooltip = "Can't Move/Copy Timed Action Section";
const SectionEdit = (props: Props) => {
  const [isDisplayCopyMoveSectionPanel, setIsDisplayCopyMoveSectionPanel] =
    useState(false);
  const dispatch = useAppDispatch();
  const { sectionTitle } = useAppSelector(
    (state: any) => state.MainlistReducer,
  );
  const phases: Phase[] = useAppSelector(
    (state: any) => state.MainlistReducer.phases,
  );
  // const [sectionTitle, setSectionTitle] = useState<boolean>(false);

  const timedSectionInPhase = props.phase?.Sections?.some(
    (section) => section.SectionType === "timed actions",
  );

  const uuidForUseEffect = props.object == null ? null : props.object.UUID;

  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  React.useEffect(() => {
    if (!isNullOrUndefined(props.object)) {
      console.log("OBJECT TITLE FOR SECTION", props.object);
      dispatch(SetSectionTitle(props.object.Title));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuidForUseEffect, sectionTitle]);

  const onDeleteConfirm = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "Are you sure delete this Section?",
      content: "This cannot be undone",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        props.onDelete(props.object);
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        console.log("Cancel");
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };
  const updatePhaseActiveSectionSelection = () => {
    dispatch(SetActiveComponent(ComponentEdit.PHASE));
    dispatch(SetCurrentSectionIndex(0));
    dispatch(SetCurrentBlockIndex(0));
  };
  const handleCopyMoveSection = (
    payload: any,
    closeCopyMoveSectionPane = false,
  ) => {
    CopyOrMoveSection(
      payload,
      (data: any) => {
        const newPhases = produce(phases, (draftPhases: Phase[]) => {
          data.forEach((item: Phase) => {
            // get phase index
            const index = draftPhases.findIndex((x) => x.Id === item.Id);
            if (index !== -1) {
              // create array to add old UUID
              const sectionIdAndUUId = draftPhases[index].Sections.map((x) => ({
                Id: x.Id,
                UUID: x.UUID,
              }));
              // assign new sections from BE to phase
              draftPhases[index].Sections = item.Sections;
              // assign old UUID or create new UUID for new Sections
              draftPhases[index].Sections = draftPhases[index].Sections.map(
                (section) => {
                  const index = sectionIdAndUUId.findIndex(
                    (x) => x.Id == section.Id,
                  );
                  const UUID =
                    index !== -1 ? sectionIdAndUUId[index].UUID : uuidv4();
                  return { ...section, UUID };
                },
              );
            }
          });
        });
        // reset current active section selecting if selected section moved to different phase
        if (!payload.phaseIds.includes(payload.currentPhaseId)) {
          updatePhaseActiveSectionSelection();
        }
        // set new phases to store
        dispatch(SetPhases(newPhases));

        // close edit section Panel
        if (closeCopyMoveSectionPane) {
          hideCopyMoveSectionPane();
        }
      },
      apiError,
    );
  };
  const onCopySection = () => {
    const payload = {
      currentPhaseId: props.object.PhaseId,
      sectionId: props.object.Id,
      phaseIds: [props.object.PhaseId],
      isActionForMove: false,
    };
    handleCopyMoveSection(payload);
  };
  const apiError = () => {
    OpenErrorNotification({
      description: "An Error Occurred check the console for more information",
    });
  };
  const openCopyMoveSectionModal = () => {
    setIsDisplayCopyMoveSectionPanel(true);
  };
  const hideCopyMoveSectionPane = () => {
    setIsDisplayCopyMoveSectionPanel(false);
  };
  return (
    <Container>
      {!isDisplayCopyMoveSectionPanel ? (
        <>
          <IconColumn
            type="Section"
            onDeleteClick={onDeleteConfirm}
            reorderBtnAvailable={true}
            reorderBtnDisabled={props.object.SectionType === "timed actions"}
            reorderBtnTooltip={
              props.object.SectionType === "timed actions"
                ? CopyMoveSectionTooltip
                : CopyTimedActionBtnDisabledTooltip
            }
            onReorderClick={openCopyMoveSectionModal}
            isCopyBtnDisabled={
              !props.object.Id || props.object.SectionType === "timed actions"
            }
            copyBtnTooltip={
              !props.object.Id || props.object.SectionType === "timed actions"
                ? `Can't Copy Timed Action Section`
                : ""
            }
            onCopyClick={onCopySection}
          />
          <SectionEditForm
            handleCriticalOrAARToggle={props.handleCriticalOrAARToggle}
            section={props.object}
            isTimedSectionInPhase={timedSectionInPhase!}
            isPhaseBrief={props.phase?.IsScriptBrief}
            postError={props.postError}
            postSuccess={props.postSuccess}
            sectionTitle={sectionTitle}
            setSectionType={props.setSectionType}
          />
        </>
      ) : (
        <>
          <IconColumn
            type="section_copy_move"
            displayCancelBtn={true}
            onCancelClick={hideCopyMoveSectionPane}
          />
          <SectionCopyMovePanel
            currentPhaseId={props.object.PhaseId}
            sectionId={props.object.Id || 0}
            onCopySection={handleCopyMoveSection}
          />
        </>
      )}
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </Container>
  );
};
export default SectionEdit;
