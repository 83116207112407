import * as React from "react";
import { FrownOutlinedIcons } from "../../../../IconsTheme/Icons.global";
import { CasualtyStatusBlock } from "./CasualtyStatusEdit";
import {
  StyledCasualtyStatusCenterBlock,
  StyledCasualtyStatusDetails,
} from "./CenterPane/CenterPane.styled";
import Description from "./CenterPane/Description";
import GlasgowComaScale from "./CenterPane/GlasgowComaScale";
import LevelOfConsciousness from "./CenterPane/LevelOfConsciousness";
import MarchTable from "./CenterPane/MarchTable";
import VitalsTable from "./CenterPane/VitalsTable";

export const CasualtyStatusSection = (props: any) => {
  const block = props.block as CasualtyStatusBlock;
  const isDisplayCasualtyStatusDetails = () => {
    if (!block.BlockContent) {
      return false;
    }
    return (
      block.BlockContent.Description !== "" ||
      block.BlockContent.LevelOfConsciousnessIsDisplayed ||
      block.BlockContent.GlasgowComaScaleIsDisplayed ||
      block.BlockContent.IsVitalDisplayed ||
      block.BlockContent.MARCHIsDisplayed
    );
  };
  return (
    <StyledCasualtyStatusCenterBlock>
      <div className="casualtyStatusBlockContent">
        <div className="titleText">
          <FrownOutlinedIcons className="icon" /> &nbsp; CASUALTY STATUS
        </div>
      </div>
      {isDisplayCasualtyStatusDetails() ? (
        <div className="mainBlockContent">
          <div className="casualtyStatusSideBannerParentDiv">
            <hr className="casualtyStatusSideBanner" />
          </div>
          <StyledCasualtyStatusDetails>
            {block.BlockContent.Description !== "" ? (
              <Description value={block.BlockContent.Description} />
            ) : null}
            {block.BlockContent.LevelOfConsciousnessIsDisplayed ? (
              <LevelOfConsciousness
                value={block.BlockContent.LevelOfConsciousness}
              />
            ) : null}
            {block.BlockContent.GlasgowComaScaleIsDisplayed ? (
              <GlasgowComaScale BlockContent={block.BlockContent} />
            ) : null}
            {block.BlockContent.IsVitalDisplayed ? (
              <VitalsTable Vitals={block.BlockContent.Vitals} />
            ) : null}
            {block.BlockContent.MARCHIsDisplayed ? (
              <MarchTable BlockContent={block.BlockContent} />
            ) : null}
          </StyledCasualtyStatusDetails>
        </div>
      ) : null}
    </StyledCasualtyStatusCenterBlock>
  );
};
