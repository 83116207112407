import styled from "styled-components";
import { Modal } from "antd";

export const ModalConfirm = styled(Modal)`
  .warning {
    font-size: 1.5rem;
    color: orange;
  }
  .info {
    font-size: 1.5rem;
    color: blue;
  }
  .error {
    font-size: 1.5rem;
    color: red;
  }
  .title {
    padding-left: 0.625rem;
  }

  .ant-modal-content {
    padding: 2.5rem;
  }

  .ant-modal-body {
    padding: 0rem;
  }

  .ant-modal-confirm-content {
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.4375rem;
    color: #ffffff;
  }

  .ant-modal-footer {
    border-top: 0rem;
    padding: 1.875rem 0rem 0rem 0rem;
    button.ant-btn {
      font-size: 1.125rem;
      line-height: 1.3125rem;
      margin-left: 1.25rem !important;
      min-width: 85px;
    }
  }
`;
