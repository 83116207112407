import { useState, useEffect } from "react";
import { getRenderLocationAs } from "../api/Location/ApiGet";

/**
 * Get RenderLocationAS value from BE.
 * @param locationId location id.
 * @param appliedLocationSide applied location side.
 * @returns Location string to be rendered on FE.
 */
export default function useRenderLocationAs(
  locationId: number,
  appliedLocationSide: string,
) {
  const [renderLocationAs, setRenderLocationAs] = useState("-");

  useEffect(() => {
    locationId &&
      getRenderLocationAs(locationId, appliedLocationSide, setRenderLocationAs);
  }, [locationId, appliedLocationSide]);

  return [renderLocationAs];
}
