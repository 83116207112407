import * as React from "react";
import TabButton from "./../TabButton/TabButton";
import { ButtonProps } from "antd/lib/button";
import { colorPalette } from "../../styles/MainList.styled";

import styled from "styled-components";

export const StyledTabButton = styled(TabButton)`
  &:after {
    border-left: 1px solid #000;
    content: " ";
    display: block;
    margin: 0;
    position: absolute;
    height: 4px;
    width: 100%;
    left: 0;
    bottom: -4px;
  }
  &:hover {
    color: #fff;
    background-color: ${colorPalette.gray50};
  }
`;

const TabCloseButton = (props: ButtonProps) => {
  const { children } = props;
  return <StyledTabButton {...props}>{children}</StyledTabButton>;
};

export default TabCloseButton;
