import * as React from "react";
import { SyntheticEvent } from "react";
import "./CreateResourceForm.scss";
import { Auth } from "../../../app/modules";
import { Button, Row, Switch, Upload } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
  LockOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import document_icon from "../../../assets/icons/Upload_PDF_Icon.png";
import audio_icon from "../../../assets/icons/Upload_Audio_Icon.png";
import object_icon from "../../../assets/icons/Upload_3D_Icon.png";

interface PropTypes {
  auth: Auth;
  state: any;
  handleMarchChange: (num: number) => void;
  handleChange: any;
  beforeUpload: any;
  upload: any;
  onCheckPublic: (e: any) => void;
  save: () => void;
}

export default function CreateResourceForm({
  auth,
  state,
  handleMarchChange,
  handleChange,
  beforeUpload,
  upload,
  onCheckPublic,
  save,
}: PropTypes) {
  function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();
    if (state.resourceName) {
      save();
    }
  }

  return (
    <form className="upload-resource w-100" onSubmit={handleSubmit}>
      <div className="upload-wrap">
        {state.resourceType !== "Text" ? (
          <Upload
            className="resource-uploader"
            listType="picture-card"
            showUploadList={false}
            accept="image/*, video/*,audio/*, .pdf, .doc, .docx"
            beforeUpload={beforeUpload()}
            customRequest={upload}
            multiple={true}
            disabled={state.resourceUrl !== ""}
          >
            {state.resourceUrl !== "" ? (
              state.resourceType === "Image" ? (
                <img
                  src={
                    state.resourceUrl.substring(
                      0,
                      state.resourceUrl.lastIndexOf("/") + 1,
                    ) +
                    "thumbnails/" +
                    state.resourceUrl.substring(
                      state.resourceUrl.lastIndexOf("/") + 1,
                      state.resourceUrl.lastIndexOf("."),
                    ) +
                    "-Thumb.jpg"
                  }
                  alt="avatar"
                  className="w-100"
                />
              ) : state.resourceType === "Video" ? (
                <img
                  src={
                    state.resourceUrl.substring(
                      0,
                      state.resourceUrl.lastIndexOf("/") + 1,
                    ) +
                    "thumbnails/" +
                    state.resourceUrl.substring(
                      state.resourceUrl.lastIndexOf("/") + 1,
                      state.resourceUrl.lastIndexOf("."),
                    ) +
                    ".jpg"
                  }
                  alt="avatar"
                  className="w-100"
                />
              ) : state.resourceType === "Audio" ? (
                <img src={audio_icon} alt="avatar" className="w-100" />
              ) : state.resourceType === "Document" ? (
                <img src={document_icon} alt="avatar" className="w-100" />
              ) : (
                <img src={object_icon} alt="avatar" className="w-100" />
              )
            ) : (
              <div>
                {state.uploading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        ) : null}
        {state.resourcePublic ? (
          <UnlockOutlined className="unlock access-icon" />
        ) : (
          <LockOutlined className="lock access-icon" />
        )}
      </div>

      <div className="access-switch w-100">
        <Switch
          checkedChildren="Public"
          unCheckedChildren="Private"
          checked={state.resourcePublic}
          onChange={onCheckPublic}
        />
      </div>

      <div className="upload-resource-form w-100">
        <div className="floating-label">
          <input
            required
            name="name"
            placeholder="Name"
            value={state.resourceName}
            onChange={handleChange("resourceName")}
          />
          <label htmlFor="name">Name</label>
        </div>
        <div className="floating-label">
          <input
            name="tags"
            placeholder="Tags"
            value={state.resourceTag === null ? "" : state.resourceTag}
            onChange={handleChange("resourceTag")}
          />
          <label htmlFor="tags">Tags</label>
        </div>
        <p className="description-text">Categorize by:</p>
        <Row style={{ marginBottom: "1rem" }}>
          <span
            onClick={() => handleMarchChange(0)}
            className={
              state.march[0] === "0" ? "march-filter" : "select-march-filter"
            }
          >
            M
          </span>
          <span
            onClick={() => handleMarchChange(1)}
            className={
              state.march[1] === "0" ? "march-filter" : "select-march-filter"
            }
          >
            A
          </span>
          <span
            onClick={() => handleMarchChange(2)}
            className={
              state.march[2] === "0" ? "march-filter" : "select-march-filter"
            }
          >
            R
          </span>
          <span
            onClick={() => handleMarchChange(3)}
            className={
              state.march[3] === "0" ? "march-filter" : "select-march-filter"
            }
          >
            C
          </span>
          <span
            onClick={() => handleMarchChange(4)}
            className={
              state.march[4] === "0" ? "march-filter" : "select-march-filter"
            }
          >
            H
          </span>
        </Row>
        <div className="floating-label">
          <input
            name="type"
            placeholder="Resource Type"
            value={state.resourceType}
            disabled
          />
          <label htmlFor="type">Resource Type</label>
        </div>
        <div className="floating-label">
          <input
            name="creator"
            placeholder="Creator"
            value={`${auth.user.FirstName} ${auth.user.LastName}`}
            disabled
          />
          <label htmlFor="creator">Creator</label>
        </div>
        <div className="floating-label">
          <input
            name="organization"
            placeholder="Organization"
            value={auth.company.Name}
            disabled
          />
          <label htmlFor="organization">Organization</label>
        </div>
        {state.resourceType === "Text" && (
          <div className="floating-label">
            <textarea
              name="text-resource-data"
              value={state.resourceText}
              placeholder="Text Resource Data"
              onChange={handleChange("resourceText")}
              maxLength={500}
              rows={6}
            />
            <label htmlFor="text-resource-data">Text Resource Data</label>
          </div>
        )}

        <div className="footer-btn">
          <Button
            htmlType="submit"
            className="l2l-btn save-btn"
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
}
