import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Media } from "../../models/MediaInterface";

const initialState = {
  mediaList: Array<Media>(),
};

export const fileUploadSlice = createSlice({
  name: "scriptBuilder",
  initialState,
  reducers: {
    setMediaList: (state, action: PayloadAction<Media[]>) => {
      state.mediaList = action.payload;
    },
    removeMediaFromMediaList: (state, action: PayloadAction<number>) => {
      state.mediaList = state.mediaList.filter((x) => x.Id !== action.payload);
    },
    resetFileUpload: () => initialState,
  },
});

export default fileUploadSlice.reducer;

export const { setMediaList, resetFileUpload, removeMediaFromMediaList } =
  fileUploadSlice.actions;
