import axios from "axios";
import { defaultErrorCallback } from "./ErrorCallback";
import {
  CreateTreatmentTaskBlock,
  UpdateTreatmentTaskBlock,
} from "../models/TreatmentTaskBlock";

export function createTreatmentTaskBlock(
  payload: CreateTreatmentTaskBlock,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .post(`/api/2/pfc/treatment-block`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err);
      }
    });
}

export function updateTreatmentTaskBlock(
  payload: UpdateTreatmentTaskBlock,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(`/api/2/pfc/treatment-block`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err);
      }
    });
}

export function attachMasterTreatmentTaskToBlock(
  masterTreatmentTaskId: number,
  blockId: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(
      `/api/2/pfc/treatment-block/attach-master?masterTreatmentTaskId=${masterTreatmentTaskId}&blockId=${blockId}`,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}
