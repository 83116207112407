import styled from "styled-components";
import { colorPalette } from "../../../../../../styles/MainList.styled";

export const TimedActionDescription = styled.div`
  display: flex;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  align-items: center;
  justify-content: flex-start;
  border-right: 1px solid #161616;

  .descriptionProperty {
    display: contents;
    padding: 0 19px;
    white-space: pre-line;
    color: rgba(255, 255, 255, 1);
  }

  .icon-col {
    font-size: 1.08rem;
    margin: 0px 10px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 62px;
  }

  .media-present,
  .flag-enabled,
  .comments-present {
    color: ${colorPalette.error};
  }
`;
