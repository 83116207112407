import styled from "styled-components";
import { PaneBody } from "../../styled";

export const StyledBody = styled(PaneBody)`
  height: calc(100vh - 64px - 50px - 5px - 60px);
  color: #fff;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
`;
