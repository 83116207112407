import { Select } from "antd";
import * as React from "react";
import { useDispatch } from "react-redux";
import FloatingLabelSelect from "../../../../../../components/MuiSelect/FloatingLabelSelect";
import { BloodType } from "../../../../../../models/Enums";
import { CASUALTY_CREATOR_UPDATE_CASUALTY_FORM } from "../../../../../../store/CasualtyCreator/actionType";
import { FormRow } from "../../styled";
const { Option } = Select;
type Props = {
  formik: any;
  disabled: boolean;
};

const BloodTypeInput = ({ formik, disabled }: Props) => {
  const dispatch = useDispatch();
  const handleBloodTypeChange = (values: string[]) => {
    if (disabled) return; //disable action callback in read only script
    formik.setFieldValue("bloodType", values);
    dispatch({
      type: CASUALTY_CREATOR_UPDATE_CASUALTY_FORM,
      field: "BloodType",
      value: values,
    });
  };
  return (
    <FormRow>
      <div className="blood-group-input">
        <FloatingLabelSelect
          label="Blood Type"
          onChange={handleBloodTypeChange}
          value={formik.values["bloodType"]}
          disabled={disabled}
        >
          <Option title="" value={BloodType.ABPositive}>
            AB+
          </Option>
          <Option title="" value={BloodType.ABNegative}>
            AB&minus;
          </Option>
          <Option title="" value={BloodType.APositive}>
            A+
          </Option>
          <Option title="" value={BloodType.ANegative}>
            A&minus;
          </Option>
          <Option title="" value={BloodType.BPositive}>
            B+
          </Option>
          <Option title="" value={BloodType.BNegative}>
            B&minus;
          </Option>
          <Option title="" value={BloodType.OPositive}>
            O+
          </Option>
          <Option title="" value={BloodType.ONegative}>
            O&minus;
          </Option>
        </FloatingLabelSelect>
      </div>
    </FormRow>
  );
};

export default BloodTypeInput;
