import * as React from "react";
import { SearchOutlined } from "@ant-design/icons";
import FloatingLabelInput from "../FloatingLabel/FloatingLabelInput";
import { useFormik } from "formik";
import { useRef, useState } from "react";
interface SearchFilterProps {
  label?: string;
  value?: any;
  placeholder?: string;
  handleSearch: Function;
  className?: string;
}

const SearchFilter = (props: SearchFilterProps) => {
  const searchRef = useRef<any | null>(null);
  const initialState = {
    search: false,
  };
  const [isFocused, setIsFocused] = useState(initialState);
  const [lastFocused, setLastFocused] = useState(initialState);

  const formik = useFormik({
    initialValues: {
      search: props.value || "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  // Handle search field keyUp
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchRef.current);
    const keyword = e.currentTarget.value;
    searchRef.current = setTimeout(() => {
      props.handleSearch(keyword.toLocaleLowerCase());
    }, 1000);
  };

  return (
    <FloatingLabelInput
      classNameForContainer={`${props.className} pt-0`}
      size="large"
      isAddNeeded={true}
      suffixIcon={
        <SearchOutlined
        // style={{ color: `${isFocused.search ? "#23d0eb" : ""}` }}
        />
      }
      isFocused={isFocused}
      label={""}
      lastFocused={lastFocused}
      nameForInput={"search"}
      onChange={onChange}
      scriptFormik={formik}
      setIsFocused={setIsFocused}
      setLastFocused={setLastFocused}
      value={formik.values.search}
      placeholder={props.placeholder ? props.placeholder : "Search"}
    />
  );
};

export default SearchFilter;
