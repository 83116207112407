import { produce } from "immer";
import * as React from "react";
import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";

type CheckListProps = {
  items: { [key: string]: any }[];
  idKey?: string;
  nameKey?: string;
  className?: string;
  itemClass?: (record: { [key: string]: string }, index: number) => string;
  itemStyle?: (record: { [key: string]: string }) => any;
  initialSelected?: Array<string | number>;
  onChange?: Function;
};

const Check = styled.span`
  border-radius: 50%;
  display: block;
  width: 24px;
  height: 24px;
  border: 1px solid ${colorPalette.gray15};
  position: absolute;
  right: 15px;
  transition: all 0.3s ease;

  &:after {
    content: " ";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: all 0.3s ease;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
`;
const ListItem = styled.li`
  font-size: 1.15rem;
  & > label {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: ${colorPalette.gray75};
    margin-bottom: 4px;
    color: #fff;
    padding: 16px 10px;
    & > input[type="checkbox"] {
      display: none;
    }
    &:hover {
      ${Check} {
        border-color: ${colorPalette.primary100};
      }
    }
  }
  &.selected {
    & > label {
      background-color: ${colorPalette.primary15};
    }
    ${Check} {
      border-color: ${colorPalette.primary100};
      &:after {
        background-color: ${colorPalette.primary100};
      }
    }
  }
`;

const CheckList = ({
  items,
  itemClass,
  itemStyle,
  idKey = "Id",
  nameKey = "Name",
  className = "",
  initialSelected = [],
  onChange,
}: CheckListProps) => {
  const [selected, setSelected] = React.useState<Array<string | number>>([]);
  const [selectedMap, setSelectedMap] = React.useState<{
    [key: string]: boolean;
  }>({});

  React.useEffect(() => {
    if (initialSelected && initialSelected.length) {
      const map: { [key: string]: boolean } = {};
      initialSelected.forEach((id) => {
        map[id] = true;
      });
      setSelected(initialSelected);
      setSelectedMap(map);
    }
  }, [initialSelected]);

  const handleSelectToggle = (e: React.FormEvent<HTMLLabelElement>) => {
    const id = e.currentTarget.dataset["id"];
    if (id !== undefined) {
      let patched;
      if (selectedMap[id]) {
        // unselect item
        patched = produce(selectedMap, (draft) => {
          delete draft[id];
        });
      } else {
        // select item
        patched = produce(selectedMap, (draft) => {
          draft[id] = true;
        });
      }
      setSelectedMap(patched);
      if (onChange) {
        onChange(Object.keys(patched));
      }
    }
  };

  return (
    <List className={className}>
      {items.map((item: { [key: string]: any }, index) => {
        const className = itemClass ? itemClass(item, index) : "";
        const style = itemStyle ? itemStyle(item) : {};
        return (
          <ListItem
            key={item[idKey]}
            className={`${className} ${
              selectedMap[item[idKey]] ? "selected" : ""
            }`}
            style={style}
          >
            <label data-id={item[idKey]} onClick={handleSelectToggle}>
              {item[nameKey]}
              <Check />
            </label>
          </ListItem>
        );
      })}
    </List>
  );
};

export default CheckList;
