import React, { useEffect } from "react";
import {
  IconSpan,
  SectionTypeOption,
  SectionTypeSelect,
  StyledForm,
  StyledSwitch,
  DisabledTimedAction,
} from "../PhaseForm/PhaseForm.styled";
import { FormContainer } from "../RolePlayerForm/PromptForm.styled";
import {
  BlueCheckbox,
  GreyCheckbox,
} from "../../../../../styles/MainList.styled";
import { useFormik } from "formik";
import { StyledAntSelect } from "../../../../../components/MuiSelect/MuiSelect";
import "./SectionEditForm.scss";
import FloatingLabelInput from "../../../../../components/FloatingLabel/FloatingLabelInput";
import { Select as AntSelect, Tooltip } from "antd";
import {
  ThunderboltOutlinedIcon,
  TimerOutlinedIcons,
} from "../../../../../IconsTheme/Icons.global";
import { Phase, Section } from "../../../../../models/ScriptInterfaces";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { SetPhases, SetSectionTitle } from "../../../MainlistSlice";
import produce from "immer";
import { UpdateSection } from "../../../../../api/Section/ApiPost";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { OpenErrorNotification } from "../../../../../components/Notification/Notification";
import { BlockType, SectionType } from "../../../../../models/Enums";

const { Option } = AntSelect;

interface FormProps {
  section: Section;
  isPhaseBrief: boolean;
  isTimedSectionInPhase: boolean;
  handleCriticalOrAARToggle: Function;
  sectionTitle: NonNullable<string>;
  setSectionType: Function;
  postError: Function;
  postSuccess: Function;
}

type ErrorType = {
  [key: string]: string;
};
const SectionEditForm = (props: FormProps) => {
  const dispatch = useAppDispatch();
  const { approvedMosUnits, masterMaterials } = useAppSelector(
    (state) =>
      state.FeatureFlagReducer.scriptBuilder.section.selectSectionTypeOptions,
  );
  const state = useAppSelector((state: any) => state.MainlistReducer);
  const phases: Phase[] = state.phases;
  const { sectionTitle, currentPhaseIndex, currentSectionIndex, isReadOnly } =
    useAppSelector((state: any) => state.MainlistReducer);
  const focusedStates = {
    sectionTitle: false,
  };
  const sectionTypes = {
    normal: false,
    timedAction: false,
    phaseMaterial: false,
    masterMaterial: false,
    approvedMos: false,
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [sectionType, setSectionType] = React.useState(sectionTypes);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);
  const [isCriticalAction, setIsCriticalAction] = React.useState(
    props.section == null ? false : props.section.IsCriticalActionRequired,
  );
  const [requireAAR, setRequireAAR] = React.useState(false);
  const scriptFormik = useFormik({
    initialValues: {
      sectionTitle: "",
    },
    validate: (values) => {
      const errors: ErrorType = {};
      if (values.sectionTitle.trim().length > 40) {
        errors["sectionTitle"] = "Max 40 characters";
      }

      return errors;
    },
    validateOnBlur: true,
    onSubmit: () => {
      onSave();
    },
  });

  const handleCriticalActionToggle = () => {
    setIsCriticalAction(!isCriticalAction);
  };

  const handleAARToggle = () => {
    setRequireAAR(!requireAAR);
    props.handleCriticalOrAARToggle("AAR", !requireAAR);
  };

  const timedActionBlockTypes = [
    BlockType.Medication,
    BlockType.Miscellaneous,
    BlockType.Diagnostic,
    BlockType.Treatment,
  ];
  const handleSetSectionType = (type: any) => {
    try {
      const currentSection =
        phases[currentPhaseIndex].Sections[currentSectionIndex];
      if (
        currentSection?.Blocks?.find((block) =>
          timedActionBlockTypes.includes(block.BlockType),
        ) &&
        type !== "timed actions"
      ) {
        throw new Error(
          `All Timed Actions in this Section must be removed in order to change the Section Type to ${capitalizeWords(type)}.`,
        );
      }
      if (type === "Normal") {
        setSectionType({ ...sectionTypes, normal: !sectionType.normal });
      } else if (type === "timed actions") {
        setSectionType({
          ...sectionTypes,
          timedAction: !sectionType.timedAction,
        });
        handleCriticalActionToggle();
      } else if (type === "phase materials") {
        setSectionType({
          ...sectionTypes,
          phaseMaterial: !sectionType.phaseMaterial,
        });
      } else if (type === "master materials") {
        setSectionType({
          ...sectionTypes,
          masterMaterial: !sectionType.masterMaterial,
        });
      } else if (type === "approved mos units") {
        setSectionType({
          ...sectionTypes,
          approvedMos: !sectionType.approvedMos,
        });
      }
      props.setSectionType(type, isCriticalAction);
    } catch (error) {
      OpenErrorNotification({
        description: (error as Error).message,
        type: "warning",
      });
    }
  };

  const onChange = (property: keyof Section) => (e: any) => {
    if (isReadOnly) return; //disable action callback in read only script
    if (property === "Title") {
      dispatch(SetSectionTitle(e.target.value));
    }
    const phasesSectionUpdate = produce(phases, (draftPhases: Phase[]) => {
      (draftPhases[currentPhaseIndex].Sections[currentSectionIndex][
        property
      ] as any) = e.target.value;
    });
    dispatch(SetPhases(phasesSectionUpdate));
  };
  const capitalizeWords = (str: string) => {
    return str.toLowerCase().split(" ").join(" ");
  };

  const onSave = () => {
    if (isReadOnly) return; //disable action callback in read only script
    const newSection: Section = {
      SectionType:
        phases[currentPhaseIndex].Sections[currentSectionIndex].SectionType,
      Id: phases[currentPhaseIndex].Sections[currentSectionIndex].Id,
      UUID: phases[currentPhaseIndex].Sections[currentSectionIndex].UUID,
      Title: capitalizeWords(
        phases[currentPhaseIndex].Sections[currentSectionIndex].Title,
      ),
      Order: phases[currentPhaseIndex].Sections[currentSectionIndex].Order,
      PhaseId: phases[currentPhaseIndex].Id,
      IsCriticalActionRequired:
        phases[currentPhaseIndex].Sections[currentSectionIndex]
          .IsCriticalActionRequired,
      IsAARRequired:
        phases[currentPhaseIndex].Sections[currentSectionIndex].IsAARRequired,
      Collapsed: false,
      Blocks: phases[currentPhaseIndex].Sections[currentSectionIndex].Blocks,
    };
    UpdateSection(newSection, props.postSuccess, props.postError);
  };

  const debounceSubmitForm = useDebounce(scriptFormik.submitForm);

  useEffect(() => {
    if (scriptFormik.isValid) {
      debounceSubmitForm();
    }

    return () => {
      debounceSubmitForm.cancel();
    };
  }, [scriptFormik.isValid, scriptFormik.values]);

  return (
    <FormContainer>
      <StyledForm isBrief={requireAAR}>
        <FloatingLabelInput
          onChange={onChange("Title")}
          scriptFormik={scriptFormik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          label={"Section Title"}
          nameForInput={"sectionTitle"}
          value={sectionTitle}
          onSave={debounceSubmitForm}
          onBlur={debounceSubmitForm}
          enableLiveCount={true}
          required
          autoFocus
          maxLength={40}
          isAddNeeded={false}
          readOnly={isReadOnly}
        />
        <div className="sectionType">
          <div className="sectionTypeText">Section Type</div>
          <div className="sectionTypeSelect">
            <StyledAntSelect>
              <SectionTypeSelect
                value={
                  props.section == null ? "Normal" : props.section.SectionType
                }
                onChange={(e) => handleSetSectionType(e)}
                disabled={isReadOnly}
              >
                <SectionTypeOption value="Normal" isActive={sectionType.normal}>
                  <div>
                    <BlueCheckbox
                      checked={props.section.SectionType === "Normal"}
                    ></BlueCheckbox>
                    <span>NORMAL</span>
                  </div>
                </SectionTypeOption>
                {!props.isPhaseBrief && props.isTimedSectionInPhase && (
                  <SectionTypeOption
                    isActive={props.isTimedSectionInPhase}
                    value="timed actions"
                    className="disabled"
                    disabled
                  >
                    <Tooltip title="Only one timed action section allowed per phase">
                      <DisabledTimedAction>
                        <div>
                          <GreyCheckbox
                            checked={
                              props.section.SectionType === "timed actions"
                            }
                          ></GreyCheckbox>
                          <span>TIMED ACTIONS</span>
                        </div>
                        {!sectionType.timedAction && (
                          <IconSpan>
                            <TimerOutlinedIcons />
                          </IconSpan>
                        )}
                      </DisabledTimedAction>
                    </Tooltip>
                  </SectionTypeOption>
                )}
                {!props.isPhaseBrief && !props.isTimedSectionInPhase && (
                  <Option value="timed actions">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <BlueCheckbox
                          checked={
                            props.section.SectionType === "timed actions"
                          }
                        ></BlueCheckbox>
                        <span>TIMED ACTIONS</span>
                      </div>
                      {!sectionType.timedAction && (
                        <IconSpan>
                          <TimerOutlinedIcons />
                        </IconSpan>
                      )}
                    </div>
                  </Option>
                )}
                {!props.isPhaseBrief && (
                  <Option value="phase materials">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <BlueCheckbox
                          checked={
                            props.section.SectionType === "phase materials"
                          }
                        ></BlueCheckbox>
                        <span>PHASE MATERIALS</span>
                      </div>
                      {!sectionType.phaseMaterial && (
                        <span>
                          <ThunderboltOutlinedIcon />
                        </span>
                      )}
                    </div>
                  </Option>
                )}
                {masterMaterials && props.isPhaseBrief && (
                  <Option value="master materials">
                    <Tooltip title={`Hidden in Prod`}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <BlueCheckbox
                            checked={
                              props.section.SectionType === "master materials"
                            }
                          ></BlueCheckbox>
                          <span>MASTER MATERIALS</span>
                        </div>
                        {!sectionType.masterMaterial && (
                          <span>
                            <ThunderboltOutlinedIcon />
                          </span>
                        )}
                      </div>
                    </Tooltip>
                  </Option>
                )}
                {approvedMosUnits && (
                  <Option value="approved mos units">
                    <Tooltip title={`Hidden in Prod`}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <BlueCheckbox
                            checked={
                              props.section.SectionType === "approved mos units"
                            }
                          ></BlueCheckbox>
                          <span>APPROVED MOS UNITS</span>
                        </div>
                        {!sectionType.approvedMos && (
                          <span>
                            <ThunderboltOutlinedIcon />
                          </span>
                        )}
                      </div>
                    </Tooltip>
                  </Option>
                )}
              </SectionTypeSelect>
            </StyledAntSelect>
          </div>
        </div>
        {props.section != null &&
          props.section.SectionType === "timed actions" && (
            <div className="aarToggle" onClick={handleAARToggle}>
              <StyledSwitch
                checked={props.section.IsAARRequired}
                isChecked={props.section.IsAARRequired}
                disabled={isReadOnly}
              />
              <span className="text">AAR</span>
            </div>
          )}
      </StyledForm>
    </FormContainer>
  );
};

export default SectionEditForm;
