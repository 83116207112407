import { Button, Card } from "antd";
import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";

export const AddButton = styled(Button)`
  background-color: ${colorPalette.gray75};
  width: 62px;
  height: 62px;
  margin-top: 15px;
  margin-left: 44px;
`;

export const StyledList = styled.div`
  .ant-card {
    border: none;
    border-bottom: 1px solid ${colorPalette.gray50};
    background-color: ${colorPalette.gray100};

    .ant-card-meta-detail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 16px;
      .ant-card-meta-title {
        color: #fff;
        font-size: 1rem;
      }
      .ant-card-meta-description {
        color: #fff;
        font-size: 1rem;
      }
    }

    .ant-avatar > img {
      height: auto;
      max-height: 100%;
    }

    .ant-card-meta-avatar {
      display: flex;
      align-items: center;
      padding-right: 0px;
    }

    .ant-card-meta-detail > div:not(:last-child) {
      margin-bottom: 0px;
    }
  }
`;

export const AuthorNameplate = styled(Card)`
  cursor: grab;
  .ant-card-body {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    .anticon.anticon-holder {
      font-size: 1.5rem;
      color: ${colorPalette.gray50};
      transition: all 0.3s ease;
    }

    .ant-card-meta {
      margin-left: 10px;
    }

    .anticon.anticon-check {
      font-size: 2rem;
      color: ${colorPalette.gray15};
      margin-left: auto;
    }
  }
  &:hover {
    .ant-card-body {
      .anticon.anticon-holder {
        color: ${colorPalette.gray25};
      }
    }
  }
  &.disabled {
    .ant-card-body {
      padding: 1rem 0.625rem;
      .ant-card-meta {
        margin-left: 0;
      }
    }
  }
`;
