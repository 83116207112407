import React, { useState, useEffect } from "react";
import { FormContainer } from "./CasualtyForm.styled";
import AgeForm from "./AgeForm";
import WeightForm from "./WeightForm";
import HeightForm from "./HeightForm";
import SelectOption from "./SelectOption";
import { getCasualtyWithId } from "../../../../../api/Casualty/ApiGet";
import { AlertType } from "../../../../../models/Enums";
import {
  UpdateCasualty,
  UpdateCasualtyAllergies,
  RemoveAllergyFromCasualty,
} from "../../../../../api/Casualty/ApiPost";
import GenderForm from "./GenderForm";
import { Sex } from "../../../../../models/Enums";
import { isNullOrUndefined } from "../../../../../utils/utilityfunctions";
import { Spin } from "antd";

interface CasualtyProps {
  allergiesData: any;
  setAlertMessage: any;
  postSuccess: any;
}

const CasualtyForm = (props: CasualtyProps) => {
  const [data, setData] = useState<any>({
    age: "",
    weight: "",
    height: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [selectBloodType, setSelectBloodType] = useState<any>("");
  const [selectAllergy, setSelectAllergy] = useState<any>([]);
  const [casualtyData, setCasualtyData] = React.useState<any>({});
  const [Id] = React.useState<number>(1);
  const [finalAllergy, setFinalAllergy] = useState<any>([]);
  const [feet, setFeet] = useState<string>("");
  const [inches, setInches] = useState<string>("");
  const [sex, setSex] = useState<Sex>(Sex.Male);
  const [postData, setPostData] = useState<any>({});
  const [heightError, setHeightError] = useState<string>("");

  const error = (data: any) => {
    //display the error method
    props.setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An Error Occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
    setIsLoading(false);
  };

  const getCasualityDataById = (data: any) => {
    setCasualtyData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (Id > 0) {
      setIsLoading(true);
      getCasualtyWithId(Id, getCasualityDataById, error);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(casualtyData).length !== 0) {
      console.log("The casuality Data is changed now", casualtyData);

      // 12 inches is equal to 1 feet
      const feet = (casualtyData.Height / 12).toString();

      const indexDot = feet.indexOf(".");
      const finalfeet = feet.slice(0, indexDot);

      const finalInch = feet.slice(indexDot + 1, indexDot + 3);

      setData({
        ...data,
        age: casualtyData.Age.toString(),
        weight: casualtyData.Weight.toString(),
        height: casualtyData.Height.toString(),
      });
      setFeet(finalfeet);
      setInches(finalInch);
      setSex(casualtyData.Gender);

      setSelectBloodType(casualtyData.BloodType);

      if (casualtyData.Allergies.length !== 0) {
        for (let i = 0; i < casualtyData.Allergies.length; i++) {
          if (selectAllergy[i] !== casualtyData.Allergies[i].Id) {
            selectAllergy.push(casualtyData.Allergies[i].Id);
            console.log("The casuality id is", casualtyData.Allergies[i].Id);
          }
        }
      }

      setFinalAllergy(selectAllergy);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [casualtyData]);

  useEffect(() => {
    if (!isNullOrUndefined(data)) {
      const finalData = {
        Id: Id,
        RolePlayerId: null,
        Gender: sex,
        Height: parseFloat(data.height),
        Weight: parseInt(data.weight),
        FirstName: null,
        LastName: null,
        BloodType: selectBloodType,
        Age: parseInt(data.age),
      };

      setPostData(finalData);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log("target is ", e.target);
    console.log(e.target.name);

    if (name === "age") {
      setData({
        ...data,
        age: value,
      });
    }
    //here

    if (name === "weight") {
      setData({
        ...data,
        weight: value,
      });
    }
  };
  const handleChangeinHeight = (e: any) => {
    const heightFeetTag = document.getElementById(
      "heightLabelForFeet",
    ) as HTMLLabelElement;
    const heightInchesTag = document.getElementById(
      "heightLabelForInches",
    ) as HTMLLabelElement;
    const heightInFeetErrorTag = document.getElementById(
      "feetErrorInHeightForm",
    ) as HTMLLabelElement;
    const heightInInchesErrorTag = document.getElementById(
      "inchesErrorLabelInHeightForm",
    ) as HTMLLabelElement;
    const heightLabel = document.getElementById(
      "feetLabelInHeightForm",
    ) as HTMLLabelElement;
    const feetInput = document.getElementById(
      "heightFormFeetInput",
    ) as HTMLInputElement;
    if (e.target.name === "feet") {
      if (parseInt(e.target.value)! > 7) {
        setHeightError("Height cannot exceed 7 Feet");
        heightInFeetErrorTag.style.display = "block";
        heightFeetTag.style.borderBottom = "2px solid #ff0033";
        heightLabel.style.display = "none";
      } else {
        heightInFeetErrorTag.style.display = "none";
        heightLabel.style.display = "block";
        heightFeetTag.style.borderBottom = "2px solid rgba(96, 96, 96, 255)";
      }
      setFeet(e.target.value);
    }

    if (e.target.name === "inches") {
      if (parseInt(e.target.value)! > 11.99) {
        setHeightError("Height cannot exceed 11.99 Feet");
        heightInInchesErrorTag.style.display = "block";
        heightInchesTag.style.borderBottom = "2px solid #ff0033";
      } else {
        heightInInchesErrorTag.style.display = "none";
        heightInchesTag.style.borderBottom = "2px solid rgba(96, 96, 96, 255)";
      }
      setInches(e.target.value);
    }
  };

  const saveData = () => {
    const finalData = {
      Id: Id,
      RolePlayerId: null,
      Gender: sex,
      Height: parseFloat(data.height),
      Weight: parseInt(data.weight),
      FirstName: null,
      LastName: null,
      BloodType: selectBloodType,
      Age: parseInt(data.age),
    };

    UpdateCasualty(finalData, props.postSuccess, error);
  };

  const saveToMale = () => {
    const finalData = {
      Id: Id,
      RolePlayerId: null,
      Gender: 0,
      Height: parseFloat(data.height),
      Weight: parseInt(data.weight),
      FirstName: null,
      LastName: null,
      BloodType: selectBloodType,
      Age: parseInt(data.age),
    };

    UpdateCasualty(finalData, props.postSuccess, error);
  };

  const saveToFemale = () => {
    const finalData = {
      Id: Id,
      RolePlayerId: null,
      Gender: 1,
      Height: parseFloat(data.height),
      Weight: parseInt(data.weight),
      FirstName: null,
      LastName: null,
      BloodType: selectBloodType,
      Age: parseInt(data.age),
    };

    UpdateCasualty(finalData, props.postSuccess, error);
  };

  const calculateHeight = () => {
    const heightFeetInputTag = document.getElementById(
      "heightFormFeetInput",
    ) as HTMLInputElement;
    const heightInchesInputTag = document.getElementById(
      "heightFormInchesInput",
    ) as HTMLInputElement;
    let finalfeet = 0;
    let finalInch = 0;
    // if (heightInput !== null && parseInt(heightInput.value) >= 11.99) {
    //   console.log("IT IS GREATER THAN 11.99");
    //   heightInput.value = "11.99";
    // }
    if (
      parseInt(heightFeetInputTag.value) > 7 ||
      parseInt(heightInchesInputTag.value) > 11.99
    ) {
      if (feet.trim() !== "" && inches.trim() !== "") {
        finalfeet = parseInt(feet) * 12;
        finalInch = parseInt(inches);

        const finalheight = finalfeet + finalInch;

        setData({
          ...data,
          height: finalheight.toString(),
        });

        saveData();
      } else if (feet.trim() !== "" && inches.trim() === "") {
        finalfeet = parseInt(feet) * 12;

        setData({
          ...data,
          height: finalfeet.toString(),
        });

        saveData();
      } else if (feet.trim() === "" && inches.trim() !== "") {
        finalInch = parseInt(inches);

        setData({
          ...data,
          height: finalInch.toString(),
        });

        saveData();
      }
    }
  };

  const filterArray = () => {
    if (selectAllergy.length > finalAllergy.length) {
      const filterResult = selectAllergy.filter(
        (x: any) => !finalAllergy.includes(x),
      );

      UpdateCasualtyAllergies(Id, filterResult, props.postSuccess, error);
    } else if (selectAllergy.length < finalAllergy.length) {
      const filterResult = finalAllergy.filter(
        (x: any) => !selectAllergy.includes(x),
      );

      //eslint-disable-next-line array-callback-return
      filterResult.map((data: any, index: any) => {
        RemoveAllergyFromCasualty(Id, data, props.postSuccess, error);
      });
    }
  };

  const handleSelectedBloodType = (e: any) => {
    setSelectBloodType(e.target.value);
  };

  const handleChangeAllergy = async (e: any) => {
    setSelectAllergy(e);
  };

  return (
    <Spin spinning={isLoading}>
      <FormContainer>
        <GenderForm
          sex={sex}
          setSex={setSex}
          onSave={saveData}
          postSuccess={props.postSuccess}
          error={error}
          postData={postData}
          setPostData={setPostData}
          saveToMale={saveToMale}
          saveToFemale={saveToFemale}
        />

        <AgeForm Age={data.age} handleChange={handleChange} onSave={saveData} />

        <WeightForm
          Weight={data.weight}
          handleChange={handleChange}
          onSave={saveData}
        />

        <HeightForm
          feet={feet}
          inches={inches}
          handleChange={handleChangeinHeight}
          onSave={calculateHeight}
          heightError={heightError}
        />

        <SelectOption
          selectBloodType={selectBloodType}
          onChangeBloodType={handleSelectedBloodType}
          allergiesData={props.allergiesData}
          selectAllergy={selectAllergy}
          handleChangeAllergy={handleChangeAllergy}
          onSave={saveData}
          onSaveAllergies={filterArray}
        />
      </FormContainer>
    </Spin>
  );
};
export default CasualtyForm;
