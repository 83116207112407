import * as React from "react";
import { FormikProps } from "formik";
import {
  Medication,
  MedicationCompound,
  MedicationCompoundValidation,
  MedicationOption,
  Route,
} from "../../../../../models/ScriptInterfaces";
import {
  isNullOrUndefined,
  metricConversion,
} from "../../../../../utils/utilityfunctions";
import { useAppDispatch } from "../../../../../store/hooks";
import { NewMedicationContainer } from "../../../../ScriptBuilder/EditComponents/MedicationEdit/NewMedicationForm.styled";
import DurationInput from "../../../../ScriptBuilder/EditComponents/MedicationEdit/MedicationForm/DurationInput";
import MedicationOptionWithDropdown from "../../../../ScriptBuilder/EditComponents/MedicationEdit/MedicationForm/MedicationOptionWithDropdown";
import MedicationOptionWithInput from "../../../../ScriptBuilder/EditComponents/MedicationEdit/MedicationForm/MedicationOptionWithInput";
import { MedicationTask } from "../../../../../models/MedicationTask";

interface MedicationparamFormProps {
  useCase: string;
  onChange: Function;
  currentRouteId: number;
  scriptFormik: FormikProps<any>;
  medication: MedicationTask;
}

const MedicationParamForm = ({
  medication,
  useCase,
  onChange,
  currentRouteId,
  scriptFormik,
  ...props
}: MedicationparamFormProps) => {
  const dispatch = useAppDispatch();
  // const state = useAppSelector((state: any) => state.MedicationReducer);
  // const MainListState = useAppSelector((state: any) => state.MainlistReducer);
  // const {
  //   currentPhaseIndex,
  //   activeComponent,
  //   currentSectionIndex,
  //   currentBlockIndex,
  //   isReadOnly,
  // } = MainListState;
  // const phases: Phase[] = MainListState.phases;

  // const getUseCaseOptions = () => {
  //   if (
  //     !blockContent ||
  //     (!blockContent.UseCase1 &&
  //       !blockContent.UseCase2 &&
  //       !blockContent.UseCase3)
  //   )
  //     return [];
  //   const useCases = [
  //     blockContent.UseCase1,
  //     blockContent.UseCase2,
  //     blockContent.UseCase3,
  //   ];
  //   return useCases
  //     .filter((x) => x)
  //     .map((useCase: string) => ({
  //       value: useCase,
  //       text: useCase,
  //     }));
  // };
  // const getPatientTypeOptions = () => {
  //   return [
  //     {
  //       value: "Adult",
  //       text: "Adult",
  //     },
  //   ];
  // };
  const getRouteOptions = () => {
    if (!medication.RouteOfAdmins?.length) {
      return [
        {
          value: 7,
          text: "IV/IO",
        },
      ];
    }
    return medication.RouteOfAdmins?.map((route) => ({
      value: route.Id,
      text: route.Abbreviation,
    }));
  };
  const getMedicationOptions = () => {
    if (medication?.MedicationOptions) return [];
    return medication.MedicationOptions.map((option: MedicationOption) => {
      const label = `${option.Dosage} ${option.DosageUnit} ${option.Label}`;
      return {
        value: option.Id,
        text: label,
      };
    });
  };
  const getDropdownOptionsFromString = (
    val: keyof MedicationTask,
    unit?: string,
  ) => {
    const str = (medication[val] as string) || "";
    return str
      .split(";")
      .filter((x: string) => x)
      .map((x: string) => ({
        value: x.trim(),
        text: x.trim() + `${unit ? unit : ""}`,
      }));
  };
  const handleOnchange = (field: string, value: any, id = 0) => {
    // if (isReadOnly) return; //disable action callback in read only script
    // console.log("handleOnchange", field, value);
    // if (field === "Dosage") {
    //   const phaseWithUpdatedMedicationDosage = produce(phases, (draftPhase) => {
    //     (
    //       draftPhase![currentPhaseIndex].Sections![currentSectionIndex].Blocks![
    //         currentBlockIndex
    //       ].BlockContent! as Medication
    //     ).MedicationCompounds![id].Dosage = value;
    //   });
    //   dispatch(SetPhases(phaseWithUpdatedMedicationDosage));
    //   const medicationBlock =
    //     phaseWithUpdatedMedicationDosage![currentPhaseIndex].Sections![
    //       currentSectionIndex
    //     ].Blocks![currentBlockIndex];
    //   UpdateMedicationBlock(
    //     medicationBlock,
    //     () => undefined,
    //     () => undefined,
    //   );
    // } else {
    onChange(field, value);
    // }
  };
  const getSelectedRouteName = () => {
    if (medication?.CurrentRouteOfAdmin) {
      return (
        medication.CurrentRouteOfAdmin?.Abbreviation?.toLocaleLowerCase() || ""
      );
    } else {
      return "";
    }
  };
  // const showMethodInput = () => {
  //   if (
  //     blockContent?.MedicationType ===
  //       MedicationType.SESubstanceNasalDose ||
  //     getSelectedRouteName() === "inh"
  //   )
  //     return false;
  //   return true;
  // };
  // const showOptionsInput = () => {
  //   if (getSelectedRouteName() === "inh") return true;
  //   if (!blockContent?.Method) return false;
  //   return ["transmucosal", "gastrointestinal"].includes(
  //     blockContent.Method.toLocaleLowerCase(),
  //   );
  // };
  // const showQuantityInput = () => {
  //   if (
  //     blockContent?.MedicationType ===
  //       MedicationType.SESubstanceNasalDose ||
  //     getSelectedRouteName() === "inh"
  //   )
  //     return true;
  //   if (!blockContent?.Method) return false;
  //   return ["transmucosal", "gastrointestinal"].includes(
  //     blockContent.Method.toLocaleLowerCase(),
  //   );
  // };
  // const showDoseInput = () => {
  //   if (
  //     blockContent?.MedicationType ===
  //       MedicationType.SESubstanceNasalDose ||
  //     getSelectedRouteName() === "inh"
  //   )
  //     return true;
  //   if (!blockContent?.Method) return false;
  //   if (
  //     blockContent?.MedicationType ===
  //       MedicationType.SESubstanceInfusion ||
  //     blockContent?.MedicationType === MedicationType.SESubstanceOralDose
  //   )
  //     return true;
  //   return ["bolus", "transmucosal", "gastrointestinal"].includes(
  //     blockContent.Method.toLocaleLowerCase(),
  //   );
  // };
  // const showDiluentVolInput = () => {
  //   if (!blockContent?.Method) return false;
  //   if (
  //     blockContent?.MedicationType === MedicationType.SESubstanceInfusion
  //   )
  //     return true;
  //   return ["bolus"].includes(blockContent.Method.toLocaleLowerCase());
  // };
  // const showDiluentInput = () => {
  //   if (!blockContent?.Method) return false;
  //   if (
  //     blockContent?.MedicationType === MedicationType.SESubstanceInfusion
  //   )
  //     return true;
  //   return ["bolus"].includes(blockContent.Method.toLocaleLowerCase());
  // };
  // const showBagVolInput = () => {
  //   if (!blockContent?.Method) return false;
  //   if (
  //     blockContent?.MedicationType ===
  //     MedicationType.SESubstanceCompoundInfusion
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  // const showDurationInput = () => {
  //   if (!blockContent?.Method) return false;
  //   if (
  //     blockContent?.MedicationType ===
  //       MedicationType.SESubstanceCompoundInfusion ||
  //     blockContent?.MedicationType === MedicationType.SESubstanceInfusion
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  // const showDeliveryRateInput = () => {
  //   if (!blockContent?.Method) return false;
  //   if (
  //     blockContent?.MedicationType ===
  //       MedicationType.SESubstanceCompoundInfusion ||
  //     blockContent?.MedicationType === MedicationType.SESubstanceInfusion
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  const getCalculatedDeliveryRate = () => {
    // const isDurationInputVisible = showDurationInput();
    // const isBagInputVisible = showBagVolInput();
    // const isDiluentInputVisible = showDiluentInput();
    // if (
    //   isDurationInputVisible &&
    //   (isBagInputVisible || isDiluentInputVisible)
    // ) {
    //   const durationVal =
    //     blockContent?.Duration && Number(blockContent?.Duration) > 0
    //       ? Number(blockContent?.Duration)
    //       : 0;
    //   if (!durationVal) {
    //     return 0;
    //   } else if (isDiluentInputVisible && blockContent?.DiluentVolume) {
    //     if (Number(blockContent.DiluentVolume) === 0) {
    //       return 0;
    //     }
    //     return Number(
    //       Number(blockContent?.DiluentVolume / durationVal).toFixed(2),
    //     );
    //   } else if (isBagInputVisible && blockContent?.BagVolume) {
    //     if (Number(blockContent.BagVolume) === 0) {
    //       return 0;
    //     }
    //     return Number(
    //       Number(blockContent?.BagVolume / durationVal).toFixed(2),
    //     );
    //   } else {
    //     return 0;
    //   }
    // }
  };
  const getDiluentVolInfoVal = () => {
    if (
      medication?.MedicationCompounds?.length &&
      medication?.MedicationCompounds[0].Dosage &&
      medication?.DiluentVolume
    ) {
      const dosageUnit =
        medication?.MedicationCompounds[0].DosageUnit.toLocaleLowerCase();
      const dosageVal = medication?.MedicationCompounds[0].Dosage;
      if (Number(dosageVal) === 0 || Number(medication.DiluentVolume) === 0) {
        return "";
      }
      const value: any = Number(dosageVal / medication.DiluentVolume);
      return `${metricConversion(value, dosageUnit, "weight", 2)}/mL`;
    } else {
      return "";
    }
  };
  return (
    <NewMedicationContainer
      isLabel={
        !isNullOrUndefined(medication?.MedicationCompounds) &&
        medication?.MedicationCompounds?.length > 0 &&
        isNullOrUndefined(
          medication?.MedicationCompounds[0]?.MedicationAbbreviation,
        )
      }
    >
      {/* {!isNullOrUndefined(medicationCompounds) &&
        medicationCompounds?.length > 0 ? ( */}
      <div className="new-compound-list">
        {/*
               //  for future 
               //  Now need to hide patient type and use case

               <MedicationOptionWithDropdown
                label={"Patient Type"}
                field={"PatientType"}
                value={"Adult"}
                options={getPatientTypeOptions()}
                onChange={handleOnchange}
              />
              <MedicationOptionWithDropdown
                label={"Use Case"}
                field={"SelectedUseCase"}
                value={blockContent?.UseCase1}
                options={getUseCaseOptions()}
                onChange={handleOnchange}
              /> 
              */}
        <MedicationOptionWithDropdown
          label={"Route"}
          field={"CurrentRouteOfAdminId"}
          value={medication?.CurrentRouteOfAdmin?.Id}
          options={getRouteOptions()}
          onChange={handleOnchange}
          // disabled={isReadOnly}
        />
        {/* {medication?.CurrentRouteOfAdminId ? ( */}
        <>
          <MedicationOptionWithDropdown
            label={"Method"}
            field={"Method"}
            value={medication?.Method}
            options={getDropdownOptionsFromString(
              "PossibleMethodsSemicolonSeparated",
            )}
            onChange={handleOnchange}
            // disabled={isReadOnly}
          />
          {/* {medication?.Method ? ( */}
          <>
            {/* <MedicationOptionWithDropdown
                    label={"Options"}
                    field={"CurrentMedicationOptionId"}
                    value={
                      medication?.CurrentMedicationOptionId || ""
                    }
                    options={getMedicationOptions()}
                    onChange={handleOnchange}
                  // disabled={isReadOnly}
                  /> */}
            {/* Hide Quantity for now*/}
            {/* {showQuantityInput() ? (
                    <MedicationOptionWithDropdown
                      label={"Quantity"}
                      field={"Quantity"}
                      value={blockContent?.Quantity}
                      options={[]}
                      onChange={handleOnchange}
                    />
                  ) : null} */}

            {/* for future when multiple units for dosage */}
            {/* <DoseMedicationOption
                    Dosage={medicationCompound.Dosage}
                    DosageUnit={medicationCompounds
                      .map((medication, index) => medication.DosageUnit)
                      .toString()}
                    Options={medicationCompounds}
                  /> */}

            {!isNullOrUndefined(medication?.MedicationCompounds)
              ? medication?.MedicationCompounds?.map(
                  (medicationCompound, index) => (
                    <MedicationOptionWithInput
                      key={medicationCompound?.Id}
                      label={"Dose"}
                      field={"Dosage"}
                      value={medicationCompound?.Dosage}
                      unit={medicationCompound?.DosageUnit}
                      onChange={(field: string, value: string | number) =>
                        handleOnchange(field, value, index)
                      }
                      precision={2}
                      // disabled={isReadOnly}
                      // info={"(15mg/kg BW x 90kg)"}
                    />
                  ),
                )
              : null}
            <MedicationOptionWithInput
              label={"Diluent Vol"}
              field={"DiluentVolume"}
              value={medication?.DiluentVolume}
              unit={medication?.DiluentVolumeUnit}
              onChange={handleOnchange}
              info={getDiluentVolInfoVal()}
              precision={2}
              // disabled={isReadOnly}
            />
            <MedicationOptionWithDropdown
              label={"Diluent"}
              field={"Diluent"}
              value={medication?.Diluent}
              // options={getDropdownOptionsFromString(
              //   "PossibleDiluentsSemicolonSeparated", <- Yet to be implemented.
              // )}
              options={[]}
              onChange={handleOnchange}
              // disabled={isReadOnly}
            />
            <MedicationOptionWithDropdown
              label={"Bag Vol"}
              field={"BagVolume"}
              unit={"mL"}
              value={medication?.BagVolume}
              options={getDropdownOptionsFromString(
                "PossibleBagVolumesSemicolonSeparated",
                "mL",
              )}
              onChange={handleOnchange}
              // disabled={isReadOnly}
            />
            <DurationInput
              label={"Duration"}
              field={"Duration"}
              maxhours={24}
              displayOptionLabel
              value={medication?.Duration}
              onChange={handleOnchange}
              // disabled={isReadOnly}
            />
            <MedicationOptionWithInput
              label={"Delivery Rate"}
              field={"DeliveryRate"}
              // value={getCalculatedDeliveryRate() || 0}
              value={0}
              // value={blockContent?.DeliveryRate}
              // value={props.blockContent?.DeliveryRate}
              unit={"mL/min"}
              onChange={handleOnchange}
              // info={"(~ min - max)"}
              precision={2}
              disabled
            />
          </>
          {/* ) : null} */}
        </>
        {/* ) : null} */}
      </div>
      {/* ) : null} */}
    </NewMedicationContainer>
  );
};

export default MedicationParamForm;
