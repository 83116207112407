import styled from "styled-components";
import { colorPalette } from "../../../../../../../../styles/MainList.styled";

type LabelExtensionContainerProps = {
  // isVisible: boolean;
};
/* visibility: ${(props) => (props.isVisible ? "visible" : "hidden")}; */
export const LabelExtensionContainer = styled.div<LabelExtensionContainerProps>`
  background-color: ${colorPalette.gray100};
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  border: 1px solid #fff;
  border-radius: 10px;
  position: absolute;
  padding: 0.5rem;
  visibility: hidden;
  top: 0;
  div {
    display: inline-flex;
    align-items: center;
    max-height: 35px;
    white-space: nowrap;
  }
  ul {
    margin: 0;
    padding-left: 1.5rem;
  }
`;

export const Title = styled.div`
  color: #ffff;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

type InsultsProps = {
  color: string;
};
export const Insults = styled.li<InsultsProps>`
  color: ${(props) => props.color};

  .insult-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
