import React from "react";
import DatePickerInput from "../../../../components/DatePickerInput/DatePickerInput";
import FloatingLabelInput from "../../../../components/FloatingLabel/FloatingLabelInput";
import { FormRow } from "../../User.styled";
type Props = {
  formik: any;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
  handleChange: Function;
};
const fieldName = "NRCExpirationDate";
export default function NRCExpirationDate({
  formik,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
  handleChange,
}: Props) {
  const onChange = (date: string) => {
    handleChange(fieldName, date);
  };
  return (
    <FormRow>
      <DatePickerInput
        label={"Next NRC Expiration Date"}
        nameForInput={fieldName}
        value={formik.values[fieldName]}
        onChange={onChange}
        disabled
      />
    </FormRow>
  );
}
