import { Modal } from "antd";
import styled from "styled-components";
import { GrayColorPalette } from "../../styles/MainList.styled";

export const StyledModal = styled(Modal)`
  min-width: 37rem;
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-title {
    font-size: 1.5rem;
    color: #fff;
  }
  .ant-modal-footer {
    border-top: 1px solid ${GrayColorPalette.Gray4};
    padding: 1.2rem 1rem;
    button {
      min-width: 110px;
      font-size: 1.125rem;
    }
  }
  .ant-modal-body {
    min-height: 50vh;
  }
  .ant-modal-close-x {
    color: #ddd;
  }
`;
