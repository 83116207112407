import * as React from "react";
import { FullBars, HalfBars, RemainingBars } from "./Bars.styled";

interface BarRatingProps {
  rating: number;
}

const Bars = (props: BarRatingProps) => {
  const { rating } = props;
  const fullBars = Math.floor(rating / 10);
  const hasHalfBar = rating % 10 !== 0;

  const bars = [];
  const remainingBars = Math.floor((100 - rating) / 10);
  for (let i = 0; i < fullBars; i++) {
    bars.push(<FullBars key={i} />);
  }

  if (hasHalfBar) {
    bars.push(<HalfBars key={fullBars} />);
  }

  if (remainingBars > 0) {
    for (let i = 0; i < remainingBars; i++) {
      bars.push(<RemainingBars key={fullBars + 1} />);
    }
  }

  return (
    <div className="success-bars" style={{ display: "flex" }}>
      {bars}
    </div>
  );
};

export default Bars;
