import { Col } from "antd";
import React, { ReactNode } from "react";
import { TimedActionExpandableBlockIconContainer } from "./IconComponent.styled";

type Props = {
  icon: ReactNode;
};

const TimedActionExpandableBlockIcon = ({ icon, ...props }: Props) => {
  return (
    <TimedActionExpandableBlockIconContainer
      className="user-icon"
      flex={"55px"}
    >
      {icon}
    </TimedActionExpandableBlockIconContainer>
  );
};

export default TimedActionExpandableBlockIcon;
