import * as React from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Col, Dropdown, Menu, Tooltip } from "antd";
import {
  InsultItemRow,
  MoreOptionAction,
  StyledDeletedMenuItem,
  StyledMenuItem,
  StyledSubMenu,
} from "../InjuryTab.styled";
import {
  InsultWithRelation,
  TextParameter,
  NumberParameter,
  BooleanParameter,
  IntervalParameter,
} from "../../../../../../models/InsultInterface";
import { RemoveAndDeleteAppliedInsultFromCasualty } from "../../../../../../api/Insult/ApiPost";
import { InjuryViewTypes } from "../../constants";
import TextParam from "./Parameter/TextParam";
import NumberParam from "./Parameter/NumberParam";
import BooleanParam from "./Parameter/BooleanParam";
import IntervalParam from "./Parameter/IntervalParam";
import InsultLocation from "./Parameter/InsultLocation";
import TimeNumberParam from "./Parameter/TimeNumberParam";
import { NumberParameterWithTimeInterval } from "../CommonData";
import { useDispatch, useSelector } from "react-redux";
import {
  INSULT_INJURY_REMOVE_APPLIED_INJURY_INSULT,
  INSULT_INJURY_REMOVE_APPLIED_INSULT,
  INSULT_INJURY_REMOVE_INJURY_OBJ_INSULT,
} from "../../../../../../store/CasualtyCreator/InsultInjury/actionType";
import ConfirmDialog from "../../../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../../../components/ConfirmDialog/useConfirmDialog";
import { ShowInsultParameter } from "../InjuryMapUtills";
import {
  getInsultLabelDisplayValue,
  hideLocationUIForInsults,
} from "../InsultInjuryUtility";
import { useAppSelector } from "../../../../../../store/hooks";

type InsultOption = {
  Id: number;
  Title: string;
};
type InsultItemProps = {
  insult: InsultWithRelation;
  index: number;
  casualtyId: number;
  injuryId: number;
  totalAvailableInsult: number;
  currentView: string;
  onEditInsult: Function;
  onEditInjury?: Function;
  injuryOption?: Array<InsultOption>;
};

const InsultItem = ({
  casualtyId,
  insult,
  index,
  injuryId,
  totalAvailableInsult,
  currentView,
  onEditInsult,
  onEditInjury,
  injuryOption,
}: InsultItemProps) => {
  const injuryObj = useAppSelector((state) => state.InsultInjury.InjuryObj);
  const isLocationDisableForInjuryInsult = !!injuryObj.Title || !!injuryId;
  const dispatch = useDispatch();
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();
  const { isReadOnly } = useSelector(
    (state: any) => state.scriptBuilderReducer,
  ); // Read-only Script

  // Delete insult
  const deleteSuccess = () => {
    if (currentView === InjuryViewTypes.CASUALTY_INJURIES) {
      if (injuryId) {
        dispatch({
          type: INSULT_INJURY_REMOVE_APPLIED_INJURY_INSULT,
          Id: injuryId,
          InsultId: insult.Id,
          Index: index || -1,
        });
      } else {
        dispatch({ type: INSULT_INJURY_REMOVE_APPLIED_INSULT, Id: insult.Id }); // remove insult from appliedInsult
      }
    } else {
      dispatch({
        type: INSULT_INJURY_REMOVE_INJURY_OBJ_INSULT,
        Id: injuryId,
        InsultId: insult.Id,
        Index: index,
      });
      // remove insult from appliedInjury
      dispatch({
        type: INSULT_INJURY_REMOVE_APPLIED_INJURY_INSULT,
        Id: injuryId,
        InsultId: insult.Id,
        Index: index,
      });
    }
  };
  const error = (data: any) => {
    console.log("error", data);
  };
  const confirmDeleteInjury = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to delete the insult",
      content: "Are you sure you want to delete this insult permanently",
      okText: "Yes",
      onOk: () => {
        if (insult.Id) {
          RemoveAndDeleteAppliedInsultFromCasualty(
            insult.Id,
            casualtyId,
            injuryId,
            deleteSuccess,
            error,
          );
        } else {
          // delete from state
          deleteSuccess();
        }
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: () => {
        console.log("onCancel");
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };

  // join insult with injury
  const joinInsultWithInjury = (injuryId: number, insultId: number) => {
    console.log("joinInsultWithInjury", insultId, injuryId);
    // AddInsultToInjury(injuryId, insultId, () => {
    // }, error);
  };
  // unjoin insult from injury
  const unjoinInsultFromInjury = (injuryId: number, insultId: number) => {
    console.log("removeInsultFromInjury", insultId, injuryId);
    // RemoveInjuryInsults(injuryId, insultId, () => {
    // }, error);
  };
  const menu = (
    <Menu>
      <StyledMenuItem key="0" onClick={() => onEditInsult(insult, injuryId)}>
        <EditOutlined /> &nbsp; Edit
      </StyledMenuItem>
      {currentView === InjuryViewTypes.CASUALTY_INJURIES && (
        <>
          {injuryId ? (
            <StyledMenuItem
              key="1"
              disabled={true}
              onClick={() => unjoinInsultFromInjury(injuryId, insult.Id)}
            >
              <MenuFoldOutlined /> &nbsp; Unjoin from Injury
            </StyledMenuItem>
          ) : (
            <StyledSubMenu
              key="1"
              disabled={true}
              title={
                <>
                  {/* injuryOption && injuryOption.length ? false : */}
                  <MenuUnfoldOutlined /> &nbsp; Join to Injury
                </>
              }
            >
              {injuryOption && injuryOption.length
                ? injuryOption.map((i) => {
                    return (
                      <StyledMenuItem
                        key={"injury_" + i.Id}
                        onClick={() => joinInsultWithInjury(i.Id, insult.Id)}
                      >
                        {" "}
                        {i.Title}
                      </StyledMenuItem>
                    );
                  })
                : null}
            </StyledSubMenu>
          )}
        </>
      )}
      <StyledMenuItem key="2" disabled>
        <CopyOutlined /> &nbsp; Copy
      </StyledMenuItem>

      <StyledDeletedMenuItem
        key="3"
        disabled={
          (currentView === InjuryViewTypes.CASUALTY_INJURIES &&
            !injuryId &&
            totalAvailableInsult === 1) ||
          ((currentView !== InjuryViewTypes.CASUALTY_INJURIES || injuryId) &&
            totalAvailableInsult === 1)
            ? true
            : false
        }
        onClick={() => confirmDeleteInjury()}
      >
        <DeleteOutlined /> &nbsp; Remove
      </StyledDeletedMenuItem>
    </Menu>
  );
  const editInsult = () => {
    if (isReadOnly) return; //disable action callback in read only script
    // on insult row click open edit casualty
    // if view is applied insult/injury
    if (currentView === InjuryViewTypes.CASUALTY_INJURIES) {
      // open edit insult if single insult
      // or open edit injury if insult is associated with injury
      if (!injuryId) {
        onEditInsult(insult, injuryId);
      } else {
        onEditInjury && onEditInjury();
      }
    }
  };

  const showBooleanParameter = (param: BooleanParameter) => {
    // Hide circumferential param column if the selected location is not
    // Leg or Arm.
    if (
      param.UserFacingLabel?.toLocaleLowerCase() === "circumferential" &&
      !(
        insult.Location?.BodyPart?.Name?.toLocaleLowerCase() === "leg" ||
        insult.Location?.BodyPart?.Name?.toLocaleLowerCase() === "arm"
      )
    ) {
      return;
    }

    // Show boolean params.
    return (
      <BooleanParam
        key={"boolean_param_" + param.Id}
        parameter={param}
        insult={insult}
        onEditInsult={editInsult}
      />
    );
  };

  return (
    <InsultItemRow
      $insult={insult}
      className={
        !injuryId && currentView === InjuryViewTypes.CASUALTY_INJURIES
          ? "withoutInjury"
          : ""
      }
    >
      <Col
        flex="auto"
        style={{ width: "178px" }}
        className="insult-title"
        onClick={editInsult}
      >
        <Tooltip title={getInsultLabelDisplayValue(insult)}>
          <span>{getInsultLabelDisplayValue(insult)}</span>
        </Tooltip>
      </Col>
      {insult.BooleanParameters && insult.BooleanParameters.length
        ? insult.BooleanParameters.map((param: BooleanParameter) => {
            return showBooleanParameter(param);
          })
        : null}
      {!hideLocationUIForInsults.includes(insult?.Label?.toLowerCase() || "") &&
        !isLocationDisableForInjuryInsult && (
          <InsultLocation insult={insult} onEditInsult={editInsult} />
        )}
      {insult.TextParameters && insult.TextParameters.length
        ? insult.TextParameters.map((param: TextParameter) => {
            return (
              <TextParam
                key={"text_param_" + param.Id}
                parameter={param}
                onEditInsult={editInsult}
                insult={insult}
              />
            );
          })
        : null}
      {insult.IntervalParameters && insult.IntervalParameters.length
        ? insult.IntervalParameters.map((param: IntervalParameter) => {
            return (
              <IntervalParam
                key={"interval_param_" + param.Id}
                parameter={param}
                onEditInsult={editInsult}
              />
            );
          })
        : null}
      {insult.NumberParameters && insult.NumberParameters.length
        ? insult.NumberParameters.filter((param: NumberParameter) =>
            ShowInsultParameter(param),
          ).map((param: NumberParameter) => {
            return NumberParameterWithTimeInterval.includes(
              param.UserFacingLabel,
            ) ? (
              <TimeNumberParam
                key={`number_param_${insult.Label}_${param.UserFacingLabel}`}
                parameter={param}
                onEditInsult={editInsult}
              />
            ) : (
              <NumberParam
                key={`number_param_${insult.Label}_${param.UserFacingLabel}`}
                parameter={param}
                onEditInsult={editInsult}
              />
            );
          })
        : null}
      {(currentView === InjuryViewTypes.CASUALTY_INJURIES ||
        currentView === InjuryViewTypes.EDIT_INJURY) &&
        !isReadOnly && (
          <MoreOptionAction flex="41px">
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Tooltip title={`Options`}>
                  <MoreOutlined className="actions-item action-more" />
                </Tooltip>
              </a>
            </Dropdown>
          </MoreOptionAction>
        )}
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </InsultItemRow>
  );
};

export default InsultItem;
