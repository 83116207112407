import * as React from "react";
import { FormikProps } from "formik";
import { MedicationOption, Phase } from "../../../../models/ScriptInterfaces";
import {
  isNullOrUndefined,
  metricConversion,
} from "../../../../utils/utilityfunctions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import produce from "immer";
import { SetPhases } from "../../MainlistSlice";
import { NewMedicationContainer } from "./NewMedicationForm.styled";
import MedicationOptionWithDropdown from "./MedicationForm/MedicationOptionWithDropdown";
import MedicationOptionWithInput from "./MedicationForm/MedicationOptionWithInput";
import { MedicationType } from "../../../../models/Enums";
import DurationInput from "./MedicationForm/DurationInput";
import { MedicationTask } from "../../../../models/MedicationTask";
import {
  MedicationTaskBlockContent,
  UpdateMedicationTaskBlock,
} from "../../../../models/MedicationTaskBlock";
import { updateMedicationTaskBlock } from "../../../../api/MedicationTaskBlock";

interface MedicationForm {
  useCase: string;
  onChange: Function;
  currentRouteId: number;
  scriptFormik: FormikProps<any>;
  blockContent: MedicationTask;
}
const NewMedicationForm = (props: MedicationForm) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.MedicationReducer);
  const MainListState = useAppSelector((state) => state.MainlistReducer);
  const {
    currentPhaseIndex,
    activeComponent,
    currentSectionIndex,
    currentBlockIndex,
    isReadOnly,
  } = MainListState;
  const phases: Phase[] = MainListState.phases;

  const getUseCaseOptions = () => {
    if (
      !props.blockContent ||
      (!props.blockContent.UseCase1 &&
        !props.blockContent.UseCase2 &&
        !props.blockContent.UseCase3)
    )
      return [];
    const useCases = [
      props.blockContent.UseCase1,
      props.blockContent.UseCase2,
      props.blockContent.UseCase3,
    ];
    return useCases
      .filter((x) => x)
      .map((useCase: string) => ({
        value: useCase,
        text: useCase,
      }));
  };
  const getPatientTypeOptions = () => {
    return [
      {
        value: "Adult",
        text: "Adult",
      },
    ];
  };
  const getRouteOptions = () => {
    if (!props.blockContent.RouteOfAdmins) return [];
    return props.blockContent.RouteOfAdmins?.map((route) => ({
      value: route.Id,
      text: route.Abbreviation,
    }));
  };
  const getMedicationOptions = () => {
    if (!props.blockContent.MedicationOptions) return [];
    return props.blockContent.MedicationOptions.map(
      (option: MedicationOption) => {
        const label = `${option.Dosage} ${option.DosageUnit} ${option.Label}`;
        return {
          value: option.Id,
          text: label,
        };
      },
    );
  };
  const getDropdownOptionsFromString = (
    val: keyof MedicationTask,
    unit?: string,
  ) => {
    const str = (props.blockContent[val] as string) || "";
    return str
      .split(";")
      .filter((x: string) => x)
      .map((x: string) => ({
        value: x.trim(),
        text: x.trim() + `${unit ? unit : ""}`,
      }));
  };
  const handleOnchange = (field: string, value: any, id = 0) => {
    if (isReadOnly) return; //disable action callback in read only script
    console.log("handleOnchange", field, value);
    if (field === "Dosage") {
      const phaseWithUpdatedMedicationDosage = produce(phases, (draftPhase) => {
        (
          draftPhase[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
            currentBlockIndex
          ].BlockContent as MedicationTaskBlockContent
        ).TimedActionTaskContent.MedicationCompounds[id].Dosage = value;
      });
      dispatch(SetPhases(phaseWithUpdatedMedicationDosage));
      const medicationBlock = phaseWithUpdatedMedicationDosage[
        currentPhaseIndex
      ].Sections[currentSectionIndex].Blocks[
        currentBlockIndex
      ] as UpdateMedicationTaskBlock;
      updateMedicationTaskBlock(
        medicationBlock,
        () => undefined,
        () => undefined,
      );
    } else {
      props.onChange(field, value);
    }
  };
  const getSelectedRouteName = () => {
    if (props.blockContent?.CurrentRouteOfAdmin) {
      return (
        props.blockContent.CurrentRouteOfAdmin?.Abbreviation?.toLocaleLowerCase() ||
        ""
      );
    } else {
      return "";
    }
  };
  const showMethodInput = () => {
    if (
      props.blockContent?.MedicationType ===
        MedicationType.SESubstanceNasalDose ||
      getSelectedRouteName() === "inh"
    )
      return false;
    return true;
  };
  const showOptionsInput = () => {
    if (getSelectedRouteName() === "inh") return true;
    if (!props.blockContent?.Method) return false;
    return ["transmucosal", "gastrointestinal"].includes(
      props.blockContent.Method.toLocaleLowerCase(),
    );
  };
  // const showQuantityInput = () => { <- Will be needed in future.
  //   if (
  //     props.blockContent?.MedicationType ===
  //       MedicationType.SESubstanceNasalDose ||
  //     getSelectedRouteName() === "inh"
  //   )
  //     return true;
  //   if (!props.blockContent?.Method) return false;
  //   return ["transmucosal", "gastrointestinal"].includes(
  //     props.blockContent.Method.toLocaleLowerCase(),
  //   );
  // };
  const showDoseInput = () => {
    if (
      props.blockContent.MedicationType ===
        MedicationType.SESubstanceNasalDose ||
      getSelectedRouteName() === "inh"
    )
      return true;
    if (!props.blockContent.Method) return false;
    if (
      props.blockContent.MedicationType ===
        MedicationType.SESubstanceInfusion ||
      props.blockContent.MedicationType === MedicationType.SESubstanceOralDose
    )
      return true;
    return ["bolus", "transmucosal", "gastrointestinal"].includes(
      props.blockContent.Method.toLocaleLowerCase(),
    );
  };
  const showDiluentVolInput = () => {
    if (!props.blockContent.Method) return false;
    if (
      props.blockContent.MedicationType === MedicationType.SESubstanceInfusion
    )
      return true;
    return ["bolus"].includes(props.blockContent.Method.toLocaleLowerCase());
  };
  const showDiluentInput = () => {
    if (!props.blockContent.Method) return false;
    if (
      props.blockContent.MedicationType === MedicationType.SESubstanceInfusion
    )
      return true;
    return ["bolus"].includes(props.blockContent.Method.toLocaleLowerCase());
  };
  const showBagVolInput = () => {
    if (!props.blockContent.Method) return false;
    if (
      props.blockContent.MedicationType ===
      MedicationType.SESubstanceCompoundInfusion
    ) {
      return true;
    } else {
      return false;
    }
  };
  const showDurationInput = () => {
    if (!props.blockContent.Method) return false;
    if (
      props.blockContent.MedicationType ===
        MedicationType.SESubstanceCompoundInfusion ||
      props.blockContent.MedicationType === MedicationType.SESubstanceInfusion
    ) {
      return true;
    } else {
      return false;
    }
  };
  const showDeliveryRateInput = () => {
    if (!props.blockContent.Method) return false;
    if (
      props.blockContent.MedicationType ===
        MedicationType.SESubstanceCompoundInfusion ||
      props.blockContent.MedicationType === MedicationType.SESubstanceInfusion
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getCalculatedDeliveryRate = () => {
    const isDurationInputVisible = showDurationInput();
    const isBagInputVisible = showBagVolInput();
    const isDiluentInputVisible = showDiluentInput();

    if (
      isDurationInputVisible &&
      (isBagInputVisible || isDiluentInputVisible)
    ) {
      const durationVal =
        props.blockContent.Duration && Number(props.blockContent?.Duration) > 0
          ? Number(props.blockContent?.Duration)
          : 0;
      if (!durationVal) {
        return 0;
      } else if (isDiluentInputVisible && props.blockContent.DiluentVolume) {
        if (Number(props.blockContent.DiluentVolume) === 0) {
          return 0;
        }
        return Number(
          Number(props.blockContent?.DiluentVolume / durationVal).toFixed(2),
        );
      } else if (isBagInputVisible && props.blockContent.BagVolume) {
        if (Number(props.blockContent.BagVolume) === 0) {
          return 0;
        }
        return Number(
          Number(props.blockContent?.BagVolume / durationVal).toFixed(2),
        );
      } else {
        return 0;
      }
    }
  };
  const getDiluentVolInfoVal = () => {
    if (
      props.blockContent.MedicationCompounds?.length &&
      props.blockContent.MedicationCompounds[0].Dosage &&
      props.blockContent?.DiluentVolume
    ) {
      const dosageUnit =
        props.blockContent.MedicationCompounds[0].DosageUnit.toLocaleLowerCase();
      const dosageVal = props.blockContent.MedicationCompounds[0].Dosage;
      if (
        Number(dosageVal) === 0 ||
        Number(props.blockContent.DiluentVolume) === 0
      ) {
        return "";
      }
      const value: any = Number(dosageVal / props.blockContent.DiluentVolume);
      return `${metricConversion(value, dosageUnit, "weight", 2)}/mL`;
    } else {
      return "";
    }
  };
  return (
    <NewMedicationContainer
      isLabel={
        !isNullOrUndefined(props.blockContent.MedicationCompounds) &&
        props.blockContent.MedicationCompounds?.length > 0 &&
        isNullOrUndefined(
          props.blockContent.MedicationCompounds[0].MedicationAbbreviation,
        )
      }
    >
      {!isNullOrUndefined(props.blockContent.MedicationCompounds) &&
      props.blockContent.MedicationCompounds?.length > 0 ? (
        <div
          className="new-compound-list"
          key={props.blockContent.MedicationCompounds[0].Id}
        >
          {/*
               //  for future 
               //  Now need to hide patient type and use case

               <MedicationOptionWithDropdown
                label={"Patient Type"}
                field={"PatientType"}
                value={"Adult"}
                options={getPatientTypeOptions()}
                onChange={handleOnchange}
              />
              <MedicationOptionWithDropdown
                label={"Use Case"}
                field={"SelectedUseCase"}
                value={props.blockContent?.UseCase1}
                options={getUseCaseOptions()}
                onChange={handleOnchange}
              /> 
              */}
          <MedicationOptionWithDropdown
            label={"Route"}
            field={"CurrentRouteOfAdminId"}
            value={props.blockContent.CurrentRouteOfAdmin?.Id}
            options={getRouteOptions()}
            onChange={handleOnchange}
            disabled={isReadOnly}
          />
          {props.blockContent.CurrentRouteOfAdmin ? (
            <>
              {showMethodInput() ? (
                <MedicationOptionWithDropdown
                  label={"Method"}
                  field={"Method"}
                  value={props.blockContent?.Method}
                  options={getDropdownOptionsFromString(
                    "PossibleMethodsSemicolonSeparated",
                  )}
                  onChange={handleOnchange}
                  disabled={isReadOnly}
                />
              ) : null}
              {props.blockContent?.Method || !showMethodInput() ? (
                <>
                  {showOptionsInput() ? (
                    <MedicationOptionWithDropdown
                      label={"Options"}
                      field={"CurrentMedicationOptionId"}
                      // value={
                      //   props.blockContent?.CurrentMedicationOptionId || "" <- yet to be implemented.
                      // }
                      value={0}
                      options={getMedicationOptions()}
                      onChange={handleOnchange}
                      disabled={isReadOnly}
                    />
                  ) : null}
                  {/* Hide Quantity for now*/}
                  {/* {showQuantityInput() ? (
                    <MedicationOptionWithDropdown
                      label={"Quantity"}
                      field={"Quantity"}
                      value={props.blockContent?.Quantity}
                      options={[]}
                      onChange={handleOnchange}
                    />
                  ) : null} */}

                  {/* for future when multiple units for dosage */}
                  {/* <DoseMedicationOption
                    Dosage={medicationCompound.Dosage}
                    DosageUnit={props.medicationCompounds
                      .map((medication, index) => medication.DosageUnit)
                      .toString()}
                    Options={props.medicationCompounds}
                  /> */}

                  {showDoseInput() &&
                  !isNullOrUndefined(props.blockContent.MedicationCompounds)
                    ? props.blockContent.MedicationCompounds?.map(
                        (medicationCompound, index) => (
                          <MedicationOptionWithInput
                            key={medicationCompound.Id}
                            label={"Dose"}
                            field={"Dosage"}
                            value={medicationCompound.Dosage}
                            unit={medicationCompound.DosageUnit}
                            onChange={(field: string, value: string | number) =>
                              handleOnchange(field, value, index)
                            }
                            precision={2}
                            disabled={isReadOnly}
                            // info={"(15mg/kg BW x 90kg)"}
                          />
                        ),
                      )
                    : null}
                  {showDiluentVolInput() ? (
                    <MedicationOptionWithInput
                      label={"Diluent Vol"}
                      field={"DiluentVolume"}
                      value={props.blockContent.DiluentVolume}
                      unit={props.blockContent.DiluentVolumeUnit}
                      onChange={handleOnchange}
                      info={getDiluentVolInfoVal()}
                      precision={2}
                      disabled={isReadOnly}
                    />
                  ) : null}
                  {showDiluentInput() ? (
                    <MedicationOptionWithDropdown
                      label={"Diluent"}
                      field={"Diluent"}
                      value={"Normal Saline"}
                      // value={props.blockContent?.Diluent}
                      // options={getDropdownOptionsFromString(
                      //   "PossibleDiluentsSemicolonSeparated", <- Yet to be implemented.
                      // )}
                      options={[]}
                      onChange={handleOnchange}
                      disabled={isReadOnly}
                    />
                  ) : null}
                  {showBagVolInput() ? (
                    <MedicationOptionWithDropdown
                      label={"Bag Vol"}
                      field={"BagVolume"}
                      unit={"mL"}
                      value={props.blockContent?.BagVolume}
                      options={getDropdownOptionsFromString(
                        "PossibleBagVolumesSemicolonSeparated",
                        "mL",
                      )}
                      onChange={handleOnchange}
                      disabled={true}
                    />
                  ) : null}
                  {showDurationInput() ? (
                    <DurationInput
                      label={"Duration"}
                      field={"Duration"}
                      maxhours={24}
                      displayOptionLabel
                      value={props.blockContent?.Duration}
                      onChange={handleOnchange}
                      disabled={isReadOnly}
                    />
                  ) : null}
                  {showDeliveryRateInput() ? (
                    <MedicationOptionWithInput
                      label={"Delivery Rate"}
                      field={"DeliveryRate"}
                      value={getCalculatedDeliveryRate() || 0}
                      // value={props.blockContent?.DeliveryRate}
                      unit={"mL/min"}
                      onChange={handleOnchange}
                      // info={"(~ min - max)"}
                      precision={2}
                      disabled
                    />
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
        </div>
      ) : null}
    </NewMedicationContainer>
  );
};

export default NewMedicationForm;
