import React, { useRef, useState } from "react";
import {
  FormField,
  TopHeadingBlock,
  StyledRow,
  MediaUploadWrapper,
  MediaListWrapper,
} from "./FileUpload.styled";
import "../../css/custom.css";
import { getAllMedia } from "../../api/Media/ApiGet";
import { AlertType, BlockType } from "../../models/Enums";
import { setAlertMessage } from "../../actions/alertMessage";
import { Col, Spin, Tooltip } from "antd";
import { uploadMedia } from "../../api/Media/ApiPost";
import {
  SetSelectedMediaToUploadFromGallery,
  SetTimedActionMediaDisplay,
} from "../../pages/ScriptBuilder/MainlistSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { formatBytes, isNullOrUndefined } from "../../utils/utilityfunctions";
import { isArray } from "is-what";
import { Phase } from "../../models/ScriptInterfaces";
import {
  CloseOutlined,
  FileImageOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import produce from "immer";
import MediaList from "./MediaList";
import UploadedMediaBlock from "./UploadedMediaBlock";
import { OpenWarningNotification } from "../Notification/Notification";
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES } from "../../models/Constants";
import { resetFileUpload, setMediaList } from "./FileUploadSlice";
import { Media } from "../../models/MediaInterface";

type Props = {
  accept: string;
  type?: string;
  label: string;
  maxFileSizeInBytes?: number;
  multiple?: boolean;
  onTimedActionBlockChange: Function;
};
export default function FileUpload({
  label,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  multiple,
  onTimedActionBlockChange,
  ...otherProps
}: Props) {
  const dispatch = useAppDispatch();

  const { mediaList } = useAppSelector((state) => state.FileUploadReducer);

  const fileInputField = useRef<any>(null);
  const [files, setFiles] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [uploadingLoader, setUploadingLoader] = useState(false);
  const { currentTimedActionUploadingMedia } = useAppSelector(
    (state) => state.MainlistReducer,
  );
  const state = useAppSelector((state) => state.MainlistReducer);

  const phases: Phase[] = state.phases;
  const {
    currentPhaseIndex,
    currentBlockIndex,
    currentSectionIndex,
    selectedMediaToUploadFromGallery,
  } = state;
  const UploadedMediaItems =
    phases[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
      currentBlockIndex
    ].BlockContent.TimedActionTaskContent?.AttachedMedias ||
    phases[currentPhaseIndex].Sections[currentSectionIndex].Blocks[
      currentBlockIndex
    ].BlockContent?.UploadedMediaItems;
  const handleUploadBtnClick = (e: any) => {
    fileInputField?.current?.click();
  };
  React.useEffect(() => {
    refreshAllMedia();

    //Reset redux state.
    return () => {
      dispatch(resetFileUpload());
    };
  }, []);
  const refreshAllMedia = () => {
    getAllMedia(getMediaSuccess, getMediaError);
  };
  const getMediaSuccess = (data: any) => {
    console.log("MEDIA FROM BACKEND", data);
    dispatch(setMediaList(data));
    setLoading(false);
  };
  const getMediaError = (data: any) => {
    console.log(data);
    setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An error occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
    setLoading(false);
  };
  const addNewFiles = (newFiles: any) => {
    for (const file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        files.push(file);
        setFiles((prev) => [...prev]);
        uploadMedias();
      } else {
        OpenWarningNotification({
          description: `File must smaller than  ${formatBytes(
            maxFileSizeInBytes,
          )}`,
        });
      }
    }
  };
  const handleNewFileUpload = (e: any) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      addNewFiles(newFiles);
    }
  };

  const createMediaSuccess = (
    mediaFile: any,
    index: number,
    uploadedMedia: any,
  ) => {
    console.log("callback reached", mediaFile, uploadedMedia);
    const mediaForTimedAction = {
      Id: uploadedMedia.Id,
      Order: index,
      Name: mediaFile.name,
      Description: uploadedMedia.name,
      Url: uploadedMedia.Url,
      ThumbnailUrl: uploadedMedia.ThumbnailUrl,
      FileExtension: mediaFile.type,
      MediaType: uploadedMedia.MediaType,
    };

    onTimedActionBlockChange(
      currentTimedActionUploadingMedia,
      "TimedActionTaskContent.AttachedMedias",
      mediaForTimedAction,
    );

    setMediaList(
      produce(mediaList, (draft: any) => {
        draft.push(mediaForTimedAction);
      }),
    );
    setFiles([]);
    setUploadingLoader(false);
  };

  const uploadMedias = () => {
    let order = 0;
    setUploadingLoader(true);
    for (const file of files as any[]) {
      order++;
      const isMediaInDb = mediaList.find(
        (img: Media) => img.Name === file.Name,
      );
      const bodyFormData = new FormData();
      bodyFormData.append("newVersion", "true");
      bodyFormData.append("public", "true");
      bodyFormData.append("file", file);
      if (isNullOrUndefined(isMediaInDb)) {
        bodyFormData.append("name", file.name);
        bodyFormData.append("title", file.name);
        uploadMedia(
          bodyFormData,
          (callback: any) =>
            createMediaSuccess(file, length === 0 ? 0 : length - 1, callback),
          () => {
            setUploadingLoader(false);
          },
        );
      } else {
        const mediaForTimedAction = {
          Id: file.Id,
          Order: order,
          Name: isNullOrUndefined(file.Name) ? file.name : file.Name,
          Description: "",
          Url: isNullOrUndefined(file.Url)
            ? URL.createObjectURL(file)
            : file.Url,
          ThumbnailUrl: isNullOrUndefined(file.ThumbnailUrl)
            ? URL.createObjectURL(file)
            : file.ThumbnailUrl,
          FileExtension: file.type,
          MediaType: {
            Name: file.MediaType.Name,
          },
        };

        onTimedActionBlockChange(
          currentTimedActionUploadingMedia,
          "TimedActionTaskContent.AttachedMedias",
          mediaForTimedAction,
        );

        setFiles([]);
        setUploadingLoader(false);
      }
    }
  };

  const handleMediaCheckbox = (id: number) => {
    const selectedMediaIds = [...selectedMediaToUploadFromGallery];
    const index = selectedMediaIds.findIndex((i) => i === id);
    if (index !== -1) {
      selectedMediaIds.splice(index, 1);
    } else {
      selectedMediaIds.push(id);
    }
    dispatch(SetSelectedMediaToUploadFromGallery(selectedMediaIds));
  };
  const handleMultipleFileUpload = () => {
    const newFilesToUpload: any[] = [];
    if (isArray(mediaList)) {
      selectedMediaToUploadFromGallery.forEach((id) => {
        const isMediaInFileList = mediaList.find((img: any) => img.Id === id);
        if (!isNullOrUndefined(isMediaInFileList)) {
          newFilesToUpload.push(isMediaInFileList);
        }
      });
      UploadBulkMedia(newFilesToUpload);
      dispatch(SetSelectedMediaToUploadFromGallery([]));
    }
  };
  const UploadBulkMedia = (files: any[]) => {
    const bulkMediaPayload: any[] = [];
    files.forEach((file) => {
      const order = UploadedMediaItems.length - 1;
      const bodyFormData = new FormData();
      bodyFormData.append("newVersion", "true");
      bodyFormData.append("public", "true");
      bodyFormData.append("file", file);
      const mediaForTimedAction = {
        Id: file.Id,
        Order: order,
        Name: isNullOrUndefined(file.Name) ? file.name : file.Name,
        Description: file.Description,
        Url: isNullOrUndefined(file.Url) ? URL.createObjectURL(file) : file.Url,
        ThumbnailUrl: isNullOrUndefined(file.ThumbnailUrl)
          ? URL.createObjectURL(file)
          : file.ThumbnailUrl,
        FileExtension: file.type,
        MediaType: {
          Name: file.MediaType.Name,
        },
      };
      bulkMediaPayload.push(mediaForTimedAction);
    });

    onTimedActionBlockChange(
      currentTimedActionUploadingMedia,
      "TimedActionTaskContent.BulkAttachedMedias",
      bulkMediaPayload,
    );
  };

  const closeMediaPanel = () => {
    dispatch(SetTimedActionMediaDisplay(false));
    dispatch(SetSelectedMediaToUploadFromGallery([]));
  };
  return (
    <StyledRow>
      <Col
        xxl={{ span: 17 }}
        xl={{ span: 17 }}
        lg={{ span: 16 }}
        md={{ span: 16 }}
        sm={{ span: 17 }}
      >
        <MediaUploadWrapper>
          <TopHeadingBlock style={{ padding: 0 }}>
            <div className="blockNameAndIcon"></div>
            <div className="iconsDiv">
              <button
                className={
                  selectedMediaToUploadFromGallery.length
                    ? "ant-tabs-tab btn-hover"
                    : "ant-tabs-tab disabled"
                }
                style={{
                  fontSize: "1em",
                  paddingLeft: "15px",
                  paddingRight: "30px",
                }}
                onClick={handleMultipleFileUpload}
              >
                <PlusOutlined /> ADD SELECTED
              </button>
              <button
                className="ant-tabs-tab btn-hover"
                style={{
                  fontSize: "1em",
                  paddingLeft: "15px",
                  paddingRight: "30px",
                }}
                onClick={handleUploadBtnClick}
              >
                <UploadOutlined /> UPLOAD MEDIA
              </button>

              <form onSubmit={() => undefined}>
                <FormField
                  style={{ display: `none` }}
                  className="d-none"
                  type="file"
                  ref={fileInputField}
                  onChange={handleNewFileUpload}
                  title=""
                  value=""
                  multiple={multiple || false}
                  {...otherProps}
                />
              </form>
            </div>
          </TopHeadingBlock>
          <Spin spinning={loading}>
            <MediaListWrapper>
              <MediaList
                title="Images"
                mediaList={mediaList.filter(
                  (x: Media) => x.MediaType?.Name === "Image",
                )}
                selectedMediaId={selectedMediaToUploadFromGallery}
                onMediaCheckToggle={handleMediaCheckbox}
              />
              <MediaList
                title="Video"
                mediaList={mediaList.filter(
                  (x: Media) => x.MediaType?.Name === "Video",
                )}
                selectedMediaId={selectedMediaToUploadFromGallery}
                onMediaCheckToggle={handleMediaCheckbox}
              />
              <MediaList
                title="Audio"
                mediaList={mediaList.filter(
                  (x: Media) => x.MediaType?.Name === "Audio",
                )}
                selectedMediaId={selectedMediaToUploadFromGallery}
                onMediaCheckToggle={handleMediaCheckbox}
              />
              <MediaList
                title="Document"
                mediaList={mediaList.filter(
                  (x: Media) => x.MediaType?.Name === "Document",
                )}
                selectedMediaId={selectedMediaToUploadFromGallery}
                onMediaCheckToggle={handleMediaCheckbox}
              />
            </MediaListWrapper>
          </Spin>
        </MediaUploadWrapper>
      </Col>
      <Col
        xxl={{ span: 7 }}
        xl={{ span: 7 }}
        lg={{ span: 8 }}
        md={{ span: 8 }}
        sm={{ span: 7 }}
      >
        <MediaUploadWrapper>
          <TopHeadingBlock>
            <div className="blockNameAndIcon">
              <FileImageOutlined className="icon" />
              <span className="text"> Attached media </span>{" "}
            </div>
            <div className="iconsDiv">
              <span className="deleteSpan" onClick={closeMediaPanel}>
                <button className={"StrippedButton"}>
                  <Tooltip title={"Cancel"} placement="left">
                    <CloseOutlined title="" className="iconCD" />
                  </Tooltip>
                </button>
              </span>
            </div>
          </TopHeadingBlock>
          <Spin spinning={uploadingLoader}>
            <UploadedMediaBlock
              currentPhaseIndex={currentPhaseIndex}
              currentSectionIndex={currentSectionIndex}
              currentBlockIndex={currentBlockIndex}
              UploadedMediaItems={UploadedMediaItems}
              phases={phases}
              blockType={currentTimedActionUploadingMedia}
            />
          </Spin>
        </MediaUploadWrapper>
      </Col>
    </StyledRow>
  );
}
