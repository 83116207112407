import axios from "axios";
import { defaultErrorCallback } from "./ErrorCallback";
import {
  CreateDiagnosticTaskBlock,
  UpdateDiagnosticTaskBlock,
} from "../models/DiagnosticTaskBlock";

export function createDiagnosticTaskBlock(
  payload: CreateDiagnosticTaskBlock,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .post(`/api/2/pfc/diagnostic-block`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err);
      }
    });
}

export function updateDiagnosticTaskBlock(
  payload: UpdateDiagnosticTaskBlock,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(`/api/2/pfc/diagnostic-block`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err);
      }
    });
}

export function attachMasterDiagnosticTaskToBlock(
  masterDiagnosticTaskId: number,
  blockId: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(
      `/api/2/pfc/diagnostic-block/attach-master?masterDiagnosticTaskId=${masterDiagnosticTaskId}&blockId=${blockId}`,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}
