import Select from "antd/lib/select";
import React from "react";
import FloatingLabelSelect from "../../../../../../../components/MuiSelect/FloatingLabelSelect";
import { useAppSelector } from "../../../../../../../store/hooks";
import { InjuryViewTypes } from "../../../constants";
import { Tooltip } from "antd";
const { Option } = Select;

type SelectOptionType = {
  Value: string | number;
  Label: string | number;
  Disabled?: boolean;
};
type SelectParameterValueProps = {
  label: string;
  field: string;
  customClassName: string;
  selectOptions: SelectOptionType[];
  formik: any;
  OnChange?: Function;
  disabled?: boolean;
};

const SelectParameterValue = ({
  label,
  field,
  customClassName,
  selectOptions,
  formik,
  disabled,
  OnChange,
}: SelectParameterValueProps) => {
  const { BackRedirectScreen } = useAppSelector((state) => state.InsultInjury);

  const handleValueChange = (value: number) => {
    formik.setFieldValue(field, value);
    OnChange && OnChange(value);
  };

  return selectOptions && selectOptions.length ? (
    <FloatingLabelSelect
      disabled={disabled ? true : false}
      label={label}
      className={customClassName ? customClassName : ""}
      onChange={handleValueChange}
      value={formik.values[field]}
    >
      {selectOptions.map((item: SelectOptionType) => {
        return (
          <Option
            className={item?.Disabled ? `disabled-insult-location-option` : ""}
            title={""}
            value={item.Value}
            key={item.Value}
            disabled={item.Disabled || false}
          >
            <Tooltip
              title={
                item?.Disabled
                  ? "Location is not compatible with the injury location."
                  : ""
              }
              placement="right"
            >
              {item.Label}
            </Tooltip>
          </Option>
        );
      })}
    </FloatingLabelSelect>
  ) : (
    <></>
  );
};

export default SelectParameterValue;
