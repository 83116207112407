import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";

export const BottomContainer = styled.div`
  {
    p {
      color: #fff;
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const AuthenticationContainer = styled.div`
  .success {
    display: flex;
    -webkit-box-pack: center;
    flex-direction: column;
    align-items: center;

    button {
      min-width: 10vw;
      align-self: end;
    }

    .success-logo {
      width: 100px;
      height: auto;
    }

    .message {
      color: ${colorPalette.white};
      margin-top: 30px;
      text-align: center;
    }

    .expiry-text {
      color: ${colorPalette.primary100};
    }
  }
`;
