import React, { useState } from "react";
import { useFormik } from "formik";
import CustomModal from "../../../components/Modal/CustomModal";
import { PlusOutlined } from "@ant-design/icons";
import Button from "../../../components/Button/Button";
import FloatingLabelTextArea from "../../../components/FloatingLabel/FloatingLabelTextArea";
import FloatingLabelInput from "../../../components/FloatingLabel/FloatingLabelInput";
import { Col, Row, Select } from "antd";
import { FormikErrorType } from "../../../models/common/FormikErrorType";
import { RoleFloatingLabelSelect } from "../User.styled";
import User from "../../../models/User";
import { SendUserInvitations } from "../../../api/User/ApiPost";
import { OpenErrorNotification } from "../../../components/Notification/Notification";
import { validateEmails } from "../../../utils/utilityfunctions";
import _ from "lodash";

interface AddMemberProps {
  setUserData: React.Dispatch<React.SetStateAction<User[]>>;
}

export default function AddMember({ setUserData }: AddMemberProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const focusedStates = {
    invite_email: false,
    message: false,
  };
  const [isFocused, setIsFocused] = useState(focusedStates);
  const [lastFocused, setLastFocused] = useState(focusedStates);
  const formik = useFormik({
    initialValues: {
      invite_email: "",
      message: "",
    },
    validateOnBlur: true,
    validate: (values) => {
      const errors: FormikErrorType = {};

      if (!values.invite_email.trim()) {
        errors["invite_email"] = "Required";
      } else if (!validateEmails(values.invite_email.trim().split(","))) {
        errors["invite_email"] = "Invalid Email";
      } else if (
        _.uniq(values.invite_email.toLowerCase().trim().split(",")).length !==
        values.invite_email.toLowerCase().trim().split(",").length
      ) {
        errors["invite_email"] = "Emails should be unique.";
      }

      return errors;
    },
    onSubmit: (values) => {
      setIsLoading(true);
      SendUserInvitations(
        {
          Emails: values["invite_email"].split(","),
        },
        (data: User[]) => {
          setUserData((prev) => [...prev, ...data]);
          setShowModal(false);
          setIsLoading(false);
          formik.resetForm();
        },
        (errorMessage: string) => {
          OpenErrorNotification({ description: errorMessage });
          setIsLoading(false);
        },
      );
    },
  });
  const handleOk = () => {
    formik.isValid && formik.handleSubmit();
  };
  const handleCancel = () => {
    setIsLoading(false);
    setShowModal(false);
    formik.resetForm();
  };
  return (
    <>
      <Button
        type="primary"
        shape={"rounded"}
        leftIcon={<PlusOutlined />}
        onClick={() => setShowModal(true)}
      >
        Add Member
      </Button>
      {/* // add user modal */}
      <CustomModal
        isLoading={isLoading}
        title={"Invite Member"}
        showModal={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Send"
      >
        <div>
          <Row>
            <Col span={17}>
              <div className="mb-3">
                <FloatingLabelInput
                  type="email"
                  label={"Invite with email"}
                  nameForInput={"invite_email"}
                  value={formik.values.invite_email}
                  onSave={() => undefined}
                  onChange={formik.handleChange}
                  scriptFormik={formik}
                  lastFocused={lastFocused}
                  setIsFocused={setIsFocused}
                  setLastFocused={setLastFocused}
                  isFocused={isFocused}
                  isAddNeeded={false}
                />
                <div className="pt-1">
                  To invite multiple users, separate emails with a comma.
                </div>
              </div>
            </Col>
            <Col span={7}>
              <RoleFloatingLabelSelect
                className="w-100 p-0 px-1"
                label={""}
                disabled
                value={"admin"}
              >
                <Select.Option value={"admin"} title="">
                  Admin
                </Select.Option>
              </RoleFloatingLabelSelect>
            </Col>
          </Row>
          <FloatingLabelTextArea
            label={"Message"}
            textareaName={"message"}
            value={formik.values.message}
            onSave={() => undefined}
            onChange={formik.handleChange}
            scriptFormik={formik}
            lastFocused={lastFocused}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            isFocused={isFocused}
            rows={6}
          />
        </div>
      </CustomModal>
    </>
  );
}
