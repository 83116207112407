import axios from "axios";
import { defaultErrorCallback } from "../ErrorCallback";

// Get all the Injuries.
export function getAllLocations(
  callback: Function,
  errorcallback: Function = defaultErrorCallback,
) {
  axios
    .get(`/api/1/pfc/Location/all`)
    .then((res) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
/**
 * Get RenderLocationAs value for insult/injury.
 * @param locationId
 * @param appliedLocationSide
 * @param callback
 * @param errorCallback
 */
export function getRenderLocationAs(
  locationId: number,
  appliedLocationSide: string,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .get(
      `/api/1/pfc/Location/render-location-as?locationId=${locationId}&appliedLocationSide=${appliedLocationSide || ""}`,
    )
    .then((res) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorCallback != null) {
        errorCallback(err);
      }
    });
}
