import React from "react";
import { PlusOutlined } from "@ant-design/icons";

import { Fragment } from "react";
import {
  NavigationTitle,
  PaneTabs,
} from "../../components/GlobalStyledComponents.styled";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { SetEditWidget } from "./DashboardSlice";
import Overview from "./Overview";
import CustomQueryBuilder from "./QueryBuilder/QueryBuilder";
import Button from "../../components/Button/Button";
import { ContainerDiv } from "./Dashboard.styled";
const Dashboard = () => {
  const state = useAppSelector((state: any) => state.DashboardReducer);
  const dispatch = useAppDispatch();

  return (
    <>
      <NavigationTitle className="script-library-title">
        Dashboard
      </NavigationTitle>
      {!state.editWidgetDisplay ? (
        <Fragment>
          <PaneTabs
            tabBarExtraContent={
              <Button
                type="primary"
                shape="rounded"
                style={{ margin: "9px 8px", minHeight: 37 }}
                icon={<PlusOutlined />}
                onClick={() => dispatch(SetEditWidget(true))}
              >
                Add Chart
              </Button>
            }
            items={[
              {
                label: "Overview",
                key: "overview",
                children: (
                  <ContainerDiv>
                    <Overview />
                  </ContainerDiv>
                ),
              },
            ]}
          />
        </Fragment>
      ) : (
        <CustomQueryBuilder />
      )}
    </>
  );
};

export default Dashboard;
