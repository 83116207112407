import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { UpdateResearchQuestionText } from "../../../../../api/ResearchQuestionBlock/ApiPost";
import FloatingLabelInput from "../../../../../components/FloatingLabel/FloatingLabelInput";
import { FormikErrorType } from "../../../../../models/common/FormikErrorType";
import { ResearchQuestionText } from "../../../../../models/ScriptInterfaces";
import PlaceholderInput from "./Components/PlaceholderInput";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { OpenErrorNotification } from "../../../../../components/Notification/Notification";
interface Props {
  object: ResearchQuestionText;
  onChange: any;
  disabled: boolean;
}
export default function TextQuestionBlock(props: Props) {
  const focusedStates = {
    placeholder: false,
    characterLimit: false,
  };
  const [isFocused, setIsFocused] = useState(focusedStates);
  const [lastFocused, setLastFocused] = useState(focusedStates);
  const scriptFormik = useFormik({
    initialValues: {
      placeholder: props?.object?.Placeholder || "",
      characterLimit: props?.object?.CharacterLimit || "",
    },
    validate: () => {
      const errors: FormikErrorType = {};
      return errors;
    },
    validateOnBlur: true,
    onSubmit: () => {
      onTextQuestionSave();
    },
  });
  const handleChange = (field: string, value: string | number) => {
    // scriptFormik.setFieldValue(field, value);
    const Payload = { ...props.object, [field]: value };
    props.onChange("ResearchQuestionText", Payload);
  };

  const debounceSubmitFormik = useDebounce(scriptFormik.submitForm);

  const onTextQuestionSave = () => {
    UpdateResearchQuestionText(
      props.object,
      () => undefined,
      (error: any) => {
        OpenErrorNotification({ description: error.message });
      },
    );
  };

  useEffect(() => {
    if (scriptFormik.isValid) {
      debounceSubmitFormik();
    }

    return () => {
      debounceSubmitFormik.cancel();
    };
  }, [props.object, scriptFormik.isValid, scriptFormik.values]);

  return (
    <>
      <div className="mb-8">
        <PlaceholderInput
          label={"Placeholder"}
          maxLength={40}
          scriptFormik={scriptFormik}
          DBKey={"Placeholder"}
          field={"placeholder"}
          onChange={handleChange}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          lastFocused={lastFocused}
          setLastFocused={setLastFocused}
          readOnly={props.disabled}
        />
      </div>
      <div className="mb-8">
        <FloatingLabelInput
          style={{ width: "180px" }}
          label={"Character Limit"}
          nameForInput={"characterLimit"}
          maxValue={500}
          minValue={0}
          maxLength={3}
          value={Number(scriptFormik.values["characterLimit"])}
          onChange={(e: any) => handleChange("CharacterLimit", e.target.value)}
          scriptFormik={scriptFormik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          isAddNeeded={false}
          step={1}
          precision={0}
          isNumber
          readOnly={props.disabled}
        />
      </div>
    </>
  );
}
