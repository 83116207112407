import { DragOutlined, ExpandAltOutlined } from "@ant-design/icons";
import * as React from "react";
import { SettingOutlinedIcons } from "../../IconsTheme/Icons.global";
import {
  IDashboardWidget,
  SetEditWidget,
  SetWidgetToEdit,
} from "../../pages/Home/DashboardSlice";
import { useAppDispatch } from "../../store/hooks";
import { WhiteIconButton } from "../../styles/Buttons.styled";
import {
  ButtonsContainer,
  ContentWrap,
  DashboardCardBody,
  DashboardCardHeader,
  DashboardCardWrapper,
} from "./DashboardCard.styled";

type DetailCardProps = {
  widget: IDashboardWidget;
  style?: any;
  title?: string;
  secondaryText?: string;
  children?: React.ReactNode;
};

const DashboardCard = ({
  widget,
  title,
  secondaryText,
  children,
  style,
}: DetailCardProps) => {
  const dispatch = useAppDispatch();
  const onSettingEdit = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(SetWidgetToEdit(widget));
    dispatch(SetEditWidget(true));
  };
  return (
    <DashboardCardWrapper style={style}>
      <ContentWrap>
        <ButtonsContainer className="top-right">
          <WhiteIconButton
            type="link"
            icon={<DragOutlined className="visibleIcon" />}
            size="large"
            className="drag-icon"
          />
          <WhiteIconButton
            type="link"
            icon={<SettingOutlinedIcons className="visibleIcon" />}
            size="large"
            className="setting-icon"
            onClick={onSettingEdit}
          />
        </ButtonsContainer>
        {title && (
          <DashboardCardHeader>
            <p className="title">{title}</p>
            <p className="secondary-text">{secondaryText}&nbsp;</p>
          </DashboardCardHeader>
        )}
        {children && <DashboardCardBody>{children}</DashboardCardBody>}
        <WhiteIconButton
          type="link"
          icon={<ExpandAltOutlined className="visibleIcon" />}
          size="small"
          className="expand-icon"
        />
      </ContentWrap>
    </DashboardCardWrapper>
  );
};

export default DashboardCard;
