// spell Requirements in title of file.
import React, { Component } from "react";
import { connect } from "react-redux";
import { TreatmentCategoryAndRequirementContainer } from "../../styles/MainList.styled";
import {
  ArrowRightOutlinedIcon,
  DownOutlinedIcons,
  UpOutlinedIcons,
} from "../../IconsTheme/Icons.global";
import Psychomotor from "../../assets/icons/Psyschomotor.png";
import MedicCognitive from "../../assets/icons/MedicCognitive.png";
import Hospital from "../../assets/icons/Hospital2.png";
import Ruck from "../../assets/icons/Ruck2.png";
import Truck from "../../assets/icons/Truck2.png";

function mapStateToProps(state: any) {
  return {};
}

interface CategoryOrRequirement {
  dropDownTitle: string;
  listItemsTitle: string;
  viewListLinkTitle: string;
  timedAction: string;
}

const CategorizationAndRequirements = (props: CategoryOrRequirement) => {
  const [openDropDown, setOpenDropDown] = React.useState(false);
  const [isSkillCategorization, setIsSkillCategorization] =
    React.useState(false);
  const [isPsychomotor, setIsPsychomotor] = React.useState(false);
  const [isMedic, setIsMedic] = React.useState(true);

  const showDropDown = () => {
    setOpenDropDown(!openDropDown);
  };

  const togglePsychomotor = () => {
    setIsPsychomotor(!isPsychomotor);
  };
  const toggleMedic = () => {
    setIsMedic(!isMedic);
  };

  React.useEffect(() => {
    if (props.dropDownTitle === "Skill Categorization") {
      setIsSkillCategorization(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TreatmentCategoryAndRequirementContainer>
      <div className="dropDownHeader" onClick={showDropDown}>
        <span>{props.dropDownTitle}</span>
        {openDropDown ? (
          <UpOutlinedIcons className="downIcon" />
        ) : (
          <DownOutlinedIcons className="downIcon" />
        )}
      </div>
      {openDropDown && (
        <div>
          <span className="skillTypeHeader">{props.listItemsTitle}</span>
          {isSkillCategorization ? (
            <div className="toggleVariables">
              <div className="psychomotorOrCognitive">
                <img
                  src={
                    props.timedAction === "treatment"
                      ? Psychomotor
                      : props.timedAction === "misc"
                      ? Psychomotor
                      : props.timedAction === "diagnostic"
                      ? MedicCognitive
                      : Psychomotor
                  }
                  alt="psychomotor"
                />
                <span>
                  {props.timedAction === "treatment"
                    ? "Psychomotor"
                    : props.timedAction === "misc"
                    ? "Psychomotor"
                    : props.timedAction === "diagnostic"
                    ? "Cognitive"
                    : "Psychomotor"}
                </span>
                {/*<Button type="link" size="large" icon={<SwapOutlinedIcons />} onClick={togglePsychomotor}></Button>*/}
              </div>
              <div className="medicOrNonMedic">
                {isMedic ? (
                  <div className="circle medic" />
                ) : (
                  <div className="circle nonMedic" />
                )}
                <span>Medic</span>
                {/*<Button type="link" size="large" icon={<SwapOutlinedIcons />} onClick={toggleMedic}></Button>*/}
              </div>
            </div>
          ) : (
            <div className="equipmentList">
              <img src={Ruck} alt="Ruck" />
              <img src={Truck} alt="Truck" />
              <img src={Hospital} alt="Hospital" />
            </div>
          )}
          <div className="viewList">
            <span className="listTitle">{props.viewListLinkTitle}</span>
            <ArrowRightOutlinedIcon className="listRightArrowIcon" />
          </div>
        </div>
      )}
    </TreatmentCategoryAndRequirementContainer>
  );
};

export default connect(mapStateToProps)(CategorizationAndRequirements);
