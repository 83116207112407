import axios from "axios";
import { defaultErrorCallback } from "../ErrorCallback";

const apiPrefix = "/api/1/pfc/Company";

export function getAllCompanies(
  callback: Function,
  errorcallback: Function = defaultErrorCallback,
) {
  axios
    .get(`${apiPrefix}`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err.response.data.errorMessage);
      }
    });
}

export async function getCurrentSelectedCompanyInfo(
  callback: Function,
  errorcallback: Function = defaultErrorCallback,
) {
  axios
    .get(`${apiPrefix}/currently-selected-company`)
    .then(async (res) => {
      //do something

      if (callback != null) {
        await callback(res.data.Result);
      }
    })
    .catch(async (err) => {
      // catch error
      errorcallback && (await errorcallback(err));
    });
}

export async function getCompanyToken(
  companyId: string,
  callback: Function,
  errorcallback: Function = defaultErrorCallback,
) {
  axios
    .get(`${apiPrefix}/${companyId}/token`)
    .then(async (res) => {
      //do something

      if (callback != null) {
        await callback(res.data.Result);
      }
    })
    .catch(async (err) => {
      // catch error
      errorcallback && (await errorcallback(err.response.data));
    });
}
