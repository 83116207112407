import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { useRef } from "react";
import { RoundProfileBox, RoundProfileBoxWrapper } from "../../User.styled";
import { WhiteButton } from "../../../ScriptBuilder/EditComponents/ScriptDetailsView/InjuryTab/InjuryTab.styled";
import { FormField } from "../../../../components/FileUpload/FileUpload.styled";
import { uploadMedia } from "../../../../api/Media/ApiPost";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
// import image from "./../../../../assets/img/Diagnostics/XRAY1.png";
type Props = {
  URL: string | null | undefined;
  handleChange: Function;
  removeCompanyLogo: Function;
};

export default function ProfileImage({
  handleChange,
  URL,
  removeCompanyLogo,
}: Props) {
  const fileInputField = useRef<any>(null);
  const removeProfilePicture = () => {
    removeCompanyLogo();
  };
  const openUploadDialog = () => {
    fileInputField?.current?.click();
  };
  const handleBuildUpload = (e: any) => {
    const { files: newFiles } = e.target;
    const newFile = newFiles[0];
    const bodyFormData = new FormData();
    bodyFormData.append("newVersion", "true");
    bodyFormData.append("public", "true");
    bodyFormData.append("file", newFile);
    bodyFormData.append("name", newFile.name);
    bodyFormData.append("title", newFile.name);
    uploadMedia(
      bodyFormData,
      (response: any) => {
        handleChange(response?.Id);
      },
      (error: any) => {
        OpenErrorNotification({
          description: error.message,
        });
      },
    );
  };
  return (
    <RoundProfileBoxWrapper className="mb-5">
      <RoundProfileBox onClick={() => openUploadDialog()}>
        {URL ? (
          <>
            <img src={URL} alt={"profile picture"} />
            <EditOutlined className="edit-icon" />
          </>
        ) : (
          <Tooltip title="Add">
            <PlusOutlined className="add-icon" />
          </Tooltip>
        )}

        <FormField
          style={{ display: `none` }}
          type="file"
          ref={fileInputField}
          className="profile-aud"
          onChange={handleBuildUpload}
          title=""
          value=""
          accept="image/*"
          multiple={false}
        ></FormField>
      </RoundProfileBox>

      {/* {URL ? ( */}
      <WhiteButton
        className="delete-btn"
        type="link"
        size="large"
        onClick={() => removeProfilePicture()}
      >
        <DeleteOutlined className="delete-icon font-size-1" />
      </WhiteButton>
      {/* ) : null} */}
      <span className="update-password disabled">Update Password</span>
    </RoundProfileBoxWrapper>
  );
}
