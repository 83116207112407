import axios from "axios";

const apiPrefix = "/api/1/pfc/User";

export function SendUserInvitations(
  payload: {
    Emails: string[];
    CompanyId?: string;
  },
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`${apiPrefix}/send-invites`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}

export function acceptUserRequest(
  userId: string,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`${apiPrefix}/accept-request/${userId}`, null)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}
