import { QueryBuilder, formatQuery } from "react-querybuilder";
import { QueryBuilderAntD } from "@react-querybuilder/antd";
import { CloseOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Select, Tooltip } from "antd";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import {
  StyledRow,
  TopHeadingBlock,
} from "../../../components/FileUpload/FileUpload.styled";
import FloatingLabelInput from "../../../components/FloatingLabel/FloatingLabelInput";
import FloatingLabelSelect from "../../../components/MuiSelect/FloatingLabelSelect";
import { ChartType } from "../../../models/Enums";
import { FormikErrorType } from "../../../models/common/FormikErrorType";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  GreenButton,
  GreyButton,
} from "../../ScriptBuilder/EditComponents/ScriptDetailsView/styled";
import { SetEditWidget, SetWidgetToEdit } from "../DashboardSlice";
import {
  InputWrapper,
  QueryBuilderWrapper,
  StyledCol,
  StyledFooter,
  VisualizationBlock,
  VisualizationPanel,
} from "./QueryBuilder.styled";
import VisualizationChart, { visualizations } from "./VisualizationChart";
import "./query-builder.scss";

import type { Field, OptionList, RuleGroupType } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.scss";
import { deleteDashboardWidget } from "../../../api/Dashboard/ApiDelete";
import { createWidget } from "../../../api/Dashboard/ApiPost";
import { updateWidget } from "../../../api/Dashboard/ApiPut";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../components/Notification/Notification";
import { WhiteIconButton } from "../../../styles/Buttons.styled";
import { isNullOrUndefined } from "../../../utils/utilityfunctions";
import InteliQueryHelper from "./InteliQueryHelper/InteliQueryHelper";

export interface ExecuteQueryResult {
  labels: string[];
  datasets: Dataset[];
}

export interface Dataset {
  label: string;
  data: number[];
}

const organizationList: OptionList = [
  { name: "BSD", label: "Bit Space Development" },
  { name: "Simwerx", label: "Simwerx" },
];
const defaultFields: Field[] = [
  {
    name: "single-organization",
    label: "Single Organization",
    valueEditorType: "select",
    values: organizationList,
  },
  {
    name: "multiple-organization",
    label: "Multiple Organization",
    valueEditorType: "multiselect",
    values: organizationList,
  },
];

const initialQuery: RuleGroupType = {
  combinator: "and",
  rules: [],
};
export default function CustomQueryBuilder() {
  const dispatch = useAppDispatch();

  const { widgetToEdit } = useAppSelector((state) => state.DashboardReducer);
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const [fields, setFields] = useState<Field[]>(defaultFields);
  const [query, setQuery] = useState(initialQuery);

  useEffect(() => {
    return () => {
      dispatch(SetWidgetToEdit(null));
    };
  }, []);

  const focusedStates = {
    title: false,
    subtitle: false,
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);
  const [visualizationType, setVisualizationType] = React.useState<any>("");

  const formik = useFormik({
    initialValues: {
      id: !isNullOrUndefined(widgetToEdit) ? widgetToEdit.id : 0,
      title: !isNullOrUndefined(widgetToEdit) ? widgetToEdit.title : "",
      subtitle: !isNullOrUndefined(widgetToEdit)
        ? widgetToEdit.secondaryText
        : "",
      selectedQueryId: !isNullOrUndefined(widgetToEdit)
        ? widgetToEdit.queryBuilderId
        : 0,
      queryParams: !isNullOrUndefined(widgetToEdit)
        ? widgetToEdit.queryParameters.split(";")
        : [],
      chartType: !isNullOrUndefined(widgetToEdit)
        ? widgetToEdit.type
        : ChartType.VerticalBar,
    },

    validate: (values) => {
      const errors: FormikErrorType = {};

      // title
      if (!values["title"].trim()) {
        errors["title"] = "Required";
        // } else if (values["title"].trim().length > 40) {
        //   errors["title"] = "Max 40 characters";
        // }
      } else if (values["title"].trim().length > 50) {
        errors["title"] = "Max 50 characters";
      }
      // subtitle
      if (values["subtitle"].trim().length > 50) {
        errors["subtitle"] = "Max 50 characters";
      }
      if (values["selectedQueryId"] <= 0) {
        errors["selectedQueryId"] = "Select query";
      }
      return errors;
    },
    onSubmit: (values) => {
      if (values["id"] > 0) {
        updateWidget(
          {
            Id: values["id"],
            Title: values["title"],
            ChartType: values["chartType"],
            QueryBuilderId: values["selectedQueryId"],
            SecondaryText: values["subtitle"],
            QueryParameters: values["queryParams"].join(";"),
          },
          () => {
            onClose();
          },
          (errorMessage: string) => {
            OpenErrorNotification({
              description: errorMessage,
            });
          },
        );
      } else {
        createWidget(
          {
            Title: values["title"],
            ChartType: values["chartType"],
            QueryBuilderId: values["selectedQueryId"],
            SecondaryText: values["subtitle"],
            QueryParameters: values["queryParams"].join(";"),
          },
          () => {
            onClose();
          },
          (errorMessage: string) => {
            OpenErrorNotification({
              description: errorMessage,
            });
          },
        );
      }
    },
  });

  const onChange = useCallback((q) => {
    setQuery(q);
    const newFields: Field[] = [
      { name: "username", label: "User Name" },
      { name: "createdAt", label: "Created At", inputType: "date" },
    ];
    setFields([...defaultFields, ...newFields]);
  }, []);

  const onClose = () => {
    dispatch(SetEditWidget(false));
  };
  const handleVisualizationChange = (value: any) => {
    setVisualizationType(value);
  };
  const onDelete = () => {
    if (widgetToEdit?.id) {
      setIsOpenConfirmDialog(true);
      setConfirmDialogProps({
        title: "You are going to delete widget.",
        content: "Are you sure you want delete this widget?",
        okText: "Yes",
        onOk: () => {
          deleteDashboardWidget(
            widgetToEdit?.id || 0,
            (successMessage: string) => {
              OpenSuccessNotification({ description: successMessage });
              onClose();
            },
            (errorMessage: string) => {
              OpenErrorNotification({ description: errorMessage });
            },
          );
          setIsOpenConfirmDialog(false);
        },
        cancelText: "No",
        onCancel: () => {
          setIsOpenConfirmDialog(false);
        },
        type: "error",
      });
    }
  };
  return (
    <div>
      <StyledRow>
        <StyledCol
          xxl={{ span: 17 }}
          xl={{ span: 17 }}
          lg={{ span: 16 }}
          md={{ span: 16 }}
          sm={{ span: 17 }}
        >
          <TopHeadingBlock>
            <div className="blockNameAndIcon">
              <span className="text"> Query Builder </span>
            </div>
            <div className="iconsDiv">
              <Tooltip title={"Delete"}>
                <WhiteIconButton
                  className="onHover-red-icon"
                  onClick={onDelete}
                  type="link"
                  size="large"
                  disabled={!widgetToEdit?.id}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </div>
          </TopHeadingBlock>
          <QueryBuilderWrapper>
            <InputWrapper>
              <FloatingLabelInput
                style={{ marginBottom: "1rem" }}
                label={"Query Title*"}
                nameForInput={"title"}
                value={formik.values["title"]}
                onChange={formik.handleChange}
                scriptFormik={formik}
                lastFocused={lastFocused}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
                isAddNeeded={false}
                maxLength={40}
                onSave={formik.submitForm}
                enableLiveCount
              />
              <FloatingLabelInput
                label={"Subtitle"}
                nameForInput={"subtitle"}
                value={formik.values["subtitle"]}
                onChange={formik.handleChange}
                scriptFormik={formik}
                lastFocused={lastFocused}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
                isAddNeeded={false}
                maxLength={50}
                onSave={formik.submitForm}
                enableLiveCount
              />
            </InputWrapper>
            {/* <QueryBuilderAntD>
              <QueryBuilder
                fields={fields}
                query={query}
                onQueryChange={onChange}
                addRuleToNewGroups
                showNotToggle
                controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
              />
            </QueryBuilderAntD>
            <div className="query-builder-result">
              <div>
                SQL where:{" "}
                <pre>
                  <code>{formatQuery(query, "sql")}</code>
                </pre>
              </div>
            </div> */}
            <InteliQueryHelper formik={formik} />
          </QueryBuilderWrapper>
          <StyledFooter>
            {formik.isValid ? (
              <GreenButton
                className="save-btn"
                htmlType="submit"
                onClick={formik.submitForm}
              >
                <SaveOutlined /> Save
              </GreenButton>
            ) : (
              <GreyButton className="disable-save-btn disabled">
                <SaveOutlined /> Save
              </GreyButton>
            )}
          </StyledFooter>
        </StyledCol>
        <StyledCol
          xxl={{ span: 7 }}
          xl={{ span: 7 }}
          lg={{ span: 8 }}
          md={{ span: 8 }}
          sm={{ span: 7 }}
        >
          <VisualizationPanel>
            <TopHeadingBlock>
              <div className="blockNameAndIcon">
                <span className="text"> VISUALIZATION SELECTOR </span>
              </div>
              <div className="iconsDiv">
                <Tooltip title={"Cancel"}>
                  <span className="deleteSpan" onClick={onClose}>
                    <button className={"StrippedButton"}>
                      <CloseOutlined title="" className="iconCD" />
                    </button>
                  </span>
                </Tooltip>
              </div>
            </TopHeadingBlock>
            <VisualizationBlock>
              <div className="px-3 py-3">
                <FloatingLabelSelect
                  className="w-100"
                  label={"Select Visualization"}
                  value={visualizationType}
                  onChange={handleVisualizationChange}
                >
                  {Object.values(visualizations).map((v) => {
                    return (
                      <Select.Option key={v.title} value={v.value}>
                        {v.title}
                      </Select.Option>
                    );
                  })}
                </FloatingLabelSelect>
              </div>
              <VisualizationChart
                visualizationType={visualizationType}
                onChange={handleVisualizationChange}
                chartTitle={formik.values["title"]}
                chartSubtitle={formik.values["subtitle"]}
              />
            </VisualizationBlock>
          </VisualizationPanel>
        </StyledCol>
      </StyledRow>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </div>
  );
}
