import React, { Component } from "react";
import { connect } from "react-redux";
import {
  RatingWithPainAndShockContainer,
  BlueCheckbox,
} from "../../styles/MainList.styled";
import {
  EditOutlinedIcons,
  Graph45Degree,
  GraphCurvedUpward,
} from "../../IconsTheme/Icons.global";
import { useAppSelector } from "../../store/hooks";
import { Tooltip } from "antd";

function mapStateToProps(state: any) {
  return {};
}

interface RatingProps {
  ratingType?: NonNullable<string>;
  ratingTime?: NonNullable<string>;
  isShockOrPain?: boolean;
  shockOrPainText?: string;
  isRatingSwitch?: boolean;
  isCustomLabelActive?: boolean;
  isReadOnly?: boolean;
}

const RatingWithPainAndShock = (props: RatingProps) => {
  const { customRatings } = useAppSelector(
    (state) => state.FeatureFlagReducer.ratingWithPainAndShock,
  );
  const ratingOptions = {
    excellent: false,
    pass: false,
    fail: false,
  };
  const [showLinear, setShowLinear] = React.useState(false);

  let ratingForExcellent: any = false;
  let ratingForPass: any = false;
  let ratingForFail: any = false;

  if (props.ratingType === "Excellent") {
    ratingForExcellent = true;
    ratingForPass = false;
    ratingForFail = false;
  } else if (props.ratingType === "Pass") {
    ratingForPass = true;
    ratingForExcellent = false;
    ratingForFail = false;
  } else if (props.ratingType === "Fail") {
    ratingForFail = true;
    ratingForPass = false;
    ratingForExcellent = false;
  } else {
    ratingForFail = false;
    ratingForPass = false;
    ratingForExcellent = false;
  }

  const getRatingNumber = (ratingType: any) => {
    if (ratingType === "Excellent") {
      return 3;
    } else if (ratingType === "Pass") {
      return 2;
    } else if (ratingType === "Fail") {
      return 1;
    } else {
      return 1;
    }
  };

  const getSign = (ratingType: any) => {
    if (ratingType === "Excellent") {
      return <>&lt;</>;
    } else if (ratingType === "Pass") {
      return <>&lt;</>;
    } else if (ratingType === "Fail") {
      return <>&gt;</>;
    } else {
      return;
    }
  };
  return (
    <RatingWithPainAndShockContainer
      isRatingSwitch={props.isRatingSwitch}
      isExcellent={ratingForExcellent}
      isPass={ratingForPass}
      isFail={ratingForFail}
      isShockOrPain={props.isShockOrPain}
      isCustomLabelActive={props.isCustomLabelActive}
    >
      <div className="ratingDetails">
        <div className="ratingNumber">
          <span>{getRatingNumber(props.ratingType)}</span>
        </div>
        {!props.isShockOrPain && (
          <div className="ratingText">{props.ratingType}</div>
        )}
        <div>
          {customRatings && (
            <Tooltip title={`Hidden in Prod`}>
              {!props.isShockOrPain && props.isCustomLabelActive && (
                <div className="ratingTime">
                  {!props.isRatingSwitch && getSign(props.ratingType)}&nbsp;
                  {!props.isRatingSwitch && props.ratingTime}&nbsp;
                  {(!props.isRatingSwitch && ratingForPass) ||
                    ratingForFail ||
                    (ratingForExcellent && "min")}
                  {props.isRatingSwitch && "No Custom Labels"}
                  {/* disable custom labels next */}
                </div>
              )}
            </Tooltip>
          )}
        </div>
        {!props.isCustomLabelActive && <div className="ratingTime"></div>}
        {props.isShockOrPain && (
          <div className="ratingCheckbox">
            <BlueCheckbox />
          </div>
        )}
        {props.isShockOrPain && (
          <div className="shockOrPain">{props.shockOrPainText}</div>
        )}
        {props.isShockOrPain && (
          <div onClick={() => setShowLinear(!showLinear)} className="graph">
            {showLinear ? <Graph45Degree /> : <GraphCurvedUpward />}
          </div>
        )}
        {props.isShockOrPain && (
          <div className="minutes">
            &#43;&nbsp;1&nbsp;<span>&#47;&nbsp;min</span>
          </div>
        )}
      </div>
      {props.isCustomLabelActive &&
        (props.isReadOnly ? null : (
          <div className="ratingEdit">
            <EditOutlinedIcons className="editIcon" />
          </div>
        ))}
    </RatingWithPainAndShockContainer>
  );
};

export default connect(mapStateToProps)(RatingWithPainAndShock);
