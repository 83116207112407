import styled from "styled-components";

export const TimedActionBlockRatingContainer = styled.div`
  min-width: 60px;
  height: 100%;
  border-right: 1px solid #161616;
`;

export const Rating = styled.div.attrs((props: any) => ({
  ratingNumber: props.ratingNumber,
}))`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-content: center;
  position: relative;
  top: 10%;
  left: 20%;
  border: ${(props) =>
    props.ratingNumber == 1
      ? "2px solid #FF4242"
      : props.ratingNumber == 2
      ? "2px solid #FEA40A"
      : props.ratingNumber == 3
      ? "2px solid #23D0EB"
      : "2px solid #ffffff1a"};
  background: ${(props) =>
    props.ratingNumber == 1
      ? "#FF42421A"
      : props.ratingNumber == 2
      ? "#FEA40A1A"
      : props.ratingNumber == 3
      ? "#23D0EB1A"
      : "#ffffff1a"};
  color: ${(props) =>
    props.ratingNumber == 1
      ? "#FF4242"
      : props.ratingNumber == 2
      ? "#FEA40A"
      : props.ratingNumber == 3
      ? "#23D0EB"
      : "#ffffff"};
`;
