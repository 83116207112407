import * as React from "react";
import styled from "styled-components";

type FractionProps = {
  numerator: string;
  denominator: string;
  className?: string;
  style?: any;
};

const FractionWrapper = styled.div`
  border-color: #fff;
  font-size: 1em;
  line-height: 1.2em;
  .bar {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
`;

const Fraction = ({
  numerator,
  denominator,
  className,
  style,
}: FractionProps) => {
  return (
    <FractionWrapper className={className} style={style}>
      <div className="numerator">{numerator}</div>
      <div className="bar"></div>
      <div className="denominator">{denominator}</div>
    </FractionWrapper>
  );
};

export default Fraction;
