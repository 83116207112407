import { Form, Row, Tooltip } from "antd";
import React, { useState } from "react";
import AuthenticationPageLeftPane from "../../../components/CenterScreenCard/AuthenticationPageLeftPane";
import { FormikErrorType } from "../../../models/common/FormikErrorType";
import { useFormik } from "formik";
import FloatingLabelInput from "../../../components/FloatingLabel/FloatingLabelInput";
import {
  EyeOutlinedIcons,
  EyeInvisibleOutlinedIcons,
} from "../../../IconsTheme/Icons.global";
import { BlueButton } from "../../../styles/Buttons.styled";
import { OpenErrorNotification } from "../../../components/Notification/Notification";
import { useHistory, useLocation } from "react-router";
import AlertBar from "../../../app/AlertBar";
import { signUp } from "../../../api/Auth";

const UserSignUpForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyName = searchParams.get("c");

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const focusedStates = {
    firstName: false,
    lastName: false,
    email: false,
    password: false,
  };

  const [isFocused, setIsFocused] = useState(focusedStates);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [lastFocused, setLastFocused] = useState(focusedStates);

  const formik = useFormik({
    initialValues: {
      companyName: companyName || "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      const regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      const passwordRegex = new RegExp(
        `^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$_!%*#?&])[A-Za-z\\d@$_!%*#?&]{8,}$`,
      );
      const alphabetRegex = new RegExp(`[a-zA-Z]`);
      const numberRegex = new RegExp(`\\d`);
      const specialCharRegex = new RegExp(`[@$_!%*#?&]`);
      if (!values.companyName.trim()) {
        errors["companyName"] = "Required";
      }
      if (!values.firstName.trim()) {
        errors["firstName"] = "Required";
      }
      if (!values.lastName.trim()) {
        errors["lastName"] = "Required";
      }
      if (!values.email.trim()) {
        errors["email"] = "Required";
      } else if (!regex.test(values.email.trim())) {
        errors["email"] = "Invalid Email";
      }
      if (!values.password.trim()) {
        errors["password"] = "Required";
      } else if (values.password.trim().length < 8) {
        errors["password"] = "Min. 8 Characters Required";
      } else {
        const isAlphabetAvail = alphabetRegex.test(values.password.trim());
        const isNumberAvail = numberRegex.test(values.password.trim());
        const isSpecialCharAvail = specialCharRegex.test(
          values.password.trim(),
        );
        if (!isAlphabetAvail && !isNumberAvail) {
          errors["password"] = "Min. one alphabet and number required";
        } else if (!isAlphabetAvail && !isSpecialCharAvail) {
          errors["password"] = "Min. one alphabet and special char. required";
        } else if (!isNumberAvail && !isSpecialCharAvail) {
          errors["password"] = "Min. one number and special char. required";
        } else if (!alphabetRegex.test(values.password.trim())) {
          errors["password"] = "Min. One Alphabet Required";
        } else if (!numberRegex.test(values.password.trim())) {
          errors["password"] = "Min. One Number Required";
        } else if (!specialCharRegex.test(values.password.trim())) {
          errors["password"] = "Min. One Special Character Required";
        } else if (!passwordRegex.test(values.password.trim())) {
          errors["password"] = "Please enter strong password";
        }
      }
      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      setLoading(true);
      signUp(
        {
          CompanyName: values.companyName,
          Email: values.email,
          Password: values.password,
          FirstName: values.firstName,
          LastName: values.lastName,
        },
        () => {
          setLoading(false);
          history.replace("/accept-invitation/success");
        },
        (errorMessage: string) => {
          setLoading(false);
          OpenErrorNotification({ description: errorMessage });
        },
      );
    },
  });

  const displayPasswordIcon = () => {
    return showPassword ? (
      <Tooltip title="Hide Password">
        <EyeOutlinedIcons
          className="visibleIcon px-2"
          onClick={() => setShowPassword(false)}
        />
      </Tooltip>
    ) : (
      <Tooltip title="Show Password">
        <EyeInvisibleOutlinedIcons
          className="px-2"
          onClick={() => setShowPassword(true)}
        />
      </Tooltip>
    );
  };

  return (
    <AuthenticationPageLeftPane title="Create your account" displayLoginLink>
      <AlertBar />
      <Form onFinish={formik.handleSubmit}>
        <FloatingLabelInput
          label={"First Name*"}
          nameForInput={"firstName"}
          type="text"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          scriptFormik={formik}
          lastFocused={lastFocused}
          isAddNeeded={false}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          lastPassIgnore={false}
        />
        <FloatingLabelInput
          classNameForContainer="mt-2"
          label={"Last Name*"}
          nameForInput={"lastName"}
          type="text"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          scriptFormik={formik}
          lastFocused={lastFocused}
          isAddNeeded={false}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          lastPassIgnore={false}
        />
        <FloatingLabelInput
          classNameForContainer="mt-2"
          label={"Email*"}
          nameForInput={"email"}
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          scriptFormik={formik}
          lastFocused={lastFocused}
          isAddNeeded={false}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          lastPassIgnore={false}
        />
        <FloatingLabelInput
          classNameForContainer="mt-2"
          label={"Password*"}
          nameForInput={"password"}
          type={showPassword ? "text" : "password"}
          data-testid="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          scriptFormik={formik}
          lastFocused={lastFocused}
          setIsFocused={setIsFocused}
          setLastFocused={setLastFocused}
          isFocused={isFocused}
          isAddNeeded={true}
          suffixIcon={displayPasswordIcon()}
          lastPassIgnore={false}
        />
        <Row className="mt-2" justify="end" align="middle">
          <BlueButton
            disabled={!formik.isValid}
            rounded={true}
            htmlType="submit"
            className="l2l-btn login-btn"
            loading={loading}
          >
            Create Account
          </BlueButton>
        </Row>
      </Form>
    </AuthenticationPageLeftPane>
  );
};

export default UserSignUpForm;
