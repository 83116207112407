import * as React from "react";
import { useState } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  ForkOutlined,
  HeartOutlined,
  LikeOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Input as AntInput,
  Row,
  Tooltip,
  Button,
  Col,
  Dropdown,
  Menu,
} from "antd";
import { Table } from "../../../components";
import Drawer from "../../../components/Drawer/Drawer";
import { GoArchive } from "react-icons/go";
import { connect } from "react-redux";
import { AlertType } from "../../../models/Enums";
import { setAlertMessage } from "../../../actions/alertMessage";
import {
  CreateAllergy,
  UpdateAllergy,
  DeleteAllergy,
} from "../../../api/Allergy/ApiPost";
import {
  CheckOutLinedIcon,
  DeleteIcon,
  EditOutlinedIcons,
} from "../../../IconsTheme/Icons.global";
import { Allergy } from "../../../models/CasualtyInterfaces";
import {
  StyledForm,
  StyledGreyButton,
} from "../../ScriptBuilder/EditComponents/EditForms/PhaseForm/PhaseForm.styled";
import { FormContainer } from "../../ScriptBuilder/EditComponents/EditForms/RolePlayerForm/PromptForm.styled";
import { useFormik } from "formik";
import { FloatingLabel } from "../../../components/FloatingLabel/FloatingLabel.styled";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";
import { SystemMessage } from "../../../models/Management";
import { useAppSelector } from "../../../store/hooks";

interface Props {
  auth: any;
  AllergyList: any[];
  deleteAllergy: Function;
  SystemMessage: SystemMessage;
  setAlertMessage: Function;
  refreshAllergy: Function;
}

type ErrorType = {
  [key: string]: string;
};

const AllergyList = (props: Props) => {
  const { bulkActionBtn } = useAppSelector(
    (state) => state.FeatureFlagReducer.manageContent,
  );
  const [Id, setId] = React.useState<number>(0);
  const [Type, setType] = React.useState<string>("");
  const [Label, setLabel] = React.useState<string>("");
  const [addModal, setAddModal] = React.useState<boolean>(false);
  const [isTypeFocused, setIsTypeFocused] = React.useState<boolean>(false);
  const [isLabelFocused, setIsLabelFocused] = React.useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [viewDetaislModal, setviewDetaislModal] =
    React.useState<boolean>(false);
  const [modalId, setModalId] = useState<any>(0);
  const [currentModal, setCurrentModal] = useState<any>(false);
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const labelClass = (value: any) => {
    if (value == "type") {
      return isTypeFocused || Type ? "label" : "label floatingLabel";
    } else {
      return isLabelFocused || Type ? "label" : "label floatingLabel";
    }
  };

  const scriptFormik = useFormik({
    initialValues: {
      type: "",
      label: "",
    },
    validate: (values) => {
      const errors: ErrorType = {};
      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "type") {
      setType(value);
    } else {
      setLabel(value);
    }
    scriptFormik.handleChange(e);
  };

  const handleSubmit = (e: any) => {
    setIsTypeFocused(false);
    setIsLabelFocused(false);
    scriptFormik.handleBlur(e);
  };

  const successOnCreate = (data: any) => {
    console.log("success");
    setId(0);
    setType("");
    setLabel("");

    props.refreshAllergy();
    props.setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: "The allergy was successfully created.",
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });
  };

  const successOnUpdate = (data: any) => {
    console.log("success");
    setId(0);
    setType("");
    setLabel("");

    props.refreshAllergy();
    props.setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: "The allergy was successfully updated.",
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });
  };

  const successOnDelete = (data: any) => {
    console.log("success");
    setId(0);
    setType("");
    setLabel("");

    props.refreshAllergy();
    props.setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: "The allergy was successfully deleted.",
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });
  };

  const error = (data: any) => {
    console.log(data);
    props.setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An error occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
  };

  const detais = (allergy: Allergy) => {
    setId(allergy.Id!);
    setType(allergy.Type);
    setLabel(allergy.Label);
    setviewDetaislModal(true);
  };

  const closeDetaisModal = () => {
    setviewDetaislModal(false);
  };

  const edit = (allergy: Allergy, modalTitle: string) => {
    setId(allergy.Id!);
    setType(allergy.Type);
    setLabel(allergy.Label);
    setModalTitle(modalTitle);
    setAddModal(true);
  };

  const addAllergy = () => {
    if (Label.length > 0 && Type.length > 0) {
      const newAllergy: any = {
        Id: Id || 0,
        Type: Type,
        Label: Label,
      };

      if (Id !== 0) {
        UpdateAllergy(newAllergy, successOnUpdate, error);
      } else {
        CreateAllergy(newAllergy, successOnCreate, error);
      }
    }

    setAddModal(false);
  };

  const closeAddModal = () => {
    setAddModal(false);
  };

  const deleteAllergy = (id: number) => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to delete the Allergy",
      content: "Are you sure you want to delete this allergy permanently",
      okText: "Yes",
      onOk: () => {
        DeleteAllergy(id, successOnDelete, error);
        setIsOpenConfirmDialog(false);
      },
      cancelText: "No",
      onCancel: () => {
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };
  const mockFunction = () => undefined;

  const showModalForAction = (e: any) => {
    const modalBodyElement = e.target.parentNode.nextElementSibling;
    const clickedScriptPosition = e.target.getBoundingClientRect().bottom;
    const tableBody = document.getElementsByClassName(
      "ant-table-tbody",
    ) as HTMLCollectionOf<HTMLElement>;
    if (modalBodyElement != null) {
      if (modalBodyElement.className === "modal-body") {
        modalBodyElement.style.display = "block";
        if (tableBody[0].getBoundingClientRect().y < window.innerHeight) {
          tableBody[0].style.minHeight = "70vh";
        }
        if (window.innerHeight - clickedScriptPosition < 300) {
          modalBodyElement.style.bottom = "103%";
          modalBodyElement.style.top = null;
        } else {
          modalBodyElement.style.top = "103%";
          modalBodyElement.style.bottom = null;
        }
        setCurrentModal(true);
        setModalId(modalBodyElement.id);
        if (modalId === modalBodyElement.id) {
          if (currentModal) {
            document.getElementById(modalId)!.style.display = "none";
            setCurrentModal(false);
          }
        }
        if (
          modalId != null &&
          modalId !== modalBodyElement.id &&
          document.getElementById(modalId) != null
        ) {
          document.getElementById(modalId)!.style.display = "none";
        }
      }
    }
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      render: (type: string) => (
        <>
          <span>{type}</span>
          <Row
            className="cell-icon-align-right"
            onClick={(e: any) => e.stopPropagation()}
          >
            <HeartOutlined
              onClick={mockFunction}
              style={{ fontSize: "21px" }}
            />
            {/* <HeartFilled className="color-light-blue" style={{ fontSize: "21px", float: "right" }} /> */}
          </Row>
        </>
      ),
      sorter: (a: any, b: any) => a.Type.localeCompare(b.name),
    },
    {
      title: "Label",
      dataIndex: "Label",
      key: "Label",
      className: "whiteText",
      render: (label: string) => <span>{label}</span>,
      sorter: (a: any, b: any) => a.Label.localeCompare(b.name),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      className: "whiteText",
      render: (label: string) => (
        <Row onClick={(e: any) => e.stopPropagation()}>
          <LikeOutlined
            onClick={mockFunction}
            style={{ fontSize: "2rem" }}
            className="color-light-green"
          />
          {/* <InfoCircleOutlined style={{ fontSize: "2rem" }} className="color-yellow" />
          <DislikeOutlined style={{ fontSize: "2rem" }} className="color-red" /> */}
        </Row>
      ),
      sorter: (a: any, b: any) => a.Label.localeCompare(b.name),
    },
    {
      title: "Visibility",
      dataIndex: "Visibility",
      key: "Visibility",
      className: "whiteText",
      render: (label: string) => (
        <div onClick={(e: any) => e.stopPropagation()}>
          <EyeOutlined
            onClick={mockFunction}
            style={{ fontSize: "2rem" }}
            className="color-light-blue"
          />
          {/* <EyeInvisibleOutlined style={{ fontSize: "2rem" }} className="color-grey" /> */}
        </div>
      ),
      sorter: (a: any, b: any) => a.Label.localeCompare(b.name),
    },
    {
      title: "Actions",
      dataIndex: "Id",
      key: "Id",
      className: "whiteText",
      render: (Id: number, allergy: any) => {
        return (
          <Row
            justify="space-between"
            onClick={(e: any) => e.stopPropagation()}
          >
            <Tooltip placement="bottom" title="">
              {props.auth.user?.type === "root" ||
              props.auth.user?.type?.includes("root") ||
              allergy.CreatedBy === props.auth.user.nameid ? (
                <div className={"modal-container"}>
                  <MoreOutlined
                    className="actions-item action-more"
                    onClick={showModalForAction}
                  />
                  <div className={"modal-body"} id={allergy.Id}>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {props.auth.user?.type === "root" ||
                      props.auth.user?.type?.includes("root") ||
                      allergy.CreatedBy === props.auth.user.nameid ? (
                        <div
                          onClick={() => {
                            edit(allergy, "Edit Allergy");
                          }}
                        >
                          <EditOutlined className="actions-item action-edit" />
                          <div className={"tooltip-action-text"}>Edit</div>
                        </div>
                      ) : (
                        <EditOutlinedIcons className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    {/* change properties for forking */}
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {props.auth.user?.type === "root" ||
                      props.auth.user?.type?.includes("root") ||
                      allergy.CreatedBy === props.auth.user.nameid ? (
                        <div
                          onClick={() => {
                            window.location.href = `/CreateScript/${allergy.Id}`;
                          }}
                        >
                          <ForkOutlined className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Fork</div>
                        </div>
                      ) : (
                        <ForkOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {props.auth.user?.type === "root" ||
                      props.auth.user?.type?.includes("root") ||
                      allergy.CreatedBy === props.auth.user.nameid ? (
                        <div
                          onClick={() => {
                            window.location.href = `/CreateScript/${allergy.Id}`;
                          }}
                        >
                          <LikeOutlined className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Approve</div>
                        </div>
                      ) : (
                        <LikeOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {props.auth.user?.type === "root" ||
                      props.auth.user?.type?.includes("root") ||
                      allergy.CreatedBy === props.auth.user.nameid ? (
                        <div
                          onClick={() => {
                            window.location.href = `/CreateScript/${allergy.Id}`;
                          }}
                        >
                          <SearchOutlined className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Review</div>
                        </div>
                      ) : (
                        <SearchOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {props.auth.user?.type === "root" ||
                      props.auth.user?.type?.includes("root") ||
                      allergy.CreatedBy === props.auth.user.nameid ? (
                        <div
                          onClick={() => {
                            window.location.href = `/CreateScript/${allergy.Id}`;
                          }}
                        >
                          <GoArchive className="actions-item action-fork disable-icon" />
                          <div className={"tooltip-action-text"}>Archive</div>
                        </div>
                      ) : (
                        <ForkOutlined className="actions-item disable-icon" />
                      )}
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title=""
                      className={"modal-function"}
                    >
                      {props.auth.user?.type === "root" ||
                      props.auth.user?.type?.includes("root") ||
                      allergy.CreatedBy === props.auth.user.nameid ? (
                        <div onClick={() => deleteAllergy(allergy.Id)}>
                          <DeleteOutlined className="actions-item action-delete" />
                          <div className={"tooltip-action-text"}>Delete</div>
                        </div>
                      ) : (
                        <DeleteIcon className="disable-icon" />
                      )}
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <DeleteIcon className="disable-icon" />
              )}
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <span className="menu-item">
              <LikeOutlined /> &nbsp; Approve
            </span>
          ),
        },
        {
          key: "2",
          label: (
            <span className="menu-item">
              <SearchOutlined /> &nbsp; Review
            </span>
          ),
        },
        {
          key: "3",
          label: (
            <span className="menu-item">
              <GoArchive /> &nbsp; Archive
            </span>
          ),
        },
        {
          key: "4",
          label: (
            <span className="menu-item">
              <DeleteOutlined /> &nbsp; Delete
            </span>
          ),
        },
      ]}
    />
  );

  return (
    <div data-testid="allergy-list" className="allergy-list-container">
      <Row className="flex-container-align-item-right">
        <StyledGreyButton
          className="btn btn-blue"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalTitle("Add Allergy");
            setAddModal(true);
          }}
        >
          Add New
        </StyledGreyButton>
        {bulkActionBtn && (
          <Dropdown className="btn btn-grey" overlay={menu}>
            <Button>Bulk Actions</Button>
          </Dropdown>
        )}
      </Row>
      <Table
        dataSource={props.AllergyList}
        columns={columns}
        rowKey={(record: any) => record.Id}
        rowClassName={(record) => "rxblackBG"}
        showSorterTooltip={false}
        pagination={{
          hideOnSinglePage: true,
          pageSize: props.AllergyList.length,
        }}
        onRow={(record: any, rowIndex: any) => {
          return {
            onClick: (event) => {
              detais(record);
            }, // click row
            onDoubleClick: (event) => undefined, // double click row
            onContextMenu: (event) => undefined, // right button click row
            onMouseEnter: (event) => undefined, // mouse enter row
            onMouseLeave: (event) => undefined, // mouse leave row
          };
        }}
      />

      <Drawer
        className="custom-css-ant-drawer"
        visible={addModal}
        onClose={addAllergy}
        closable
        width="600"
      >
        <h1>
          <ArrowRightOutlined
            style={{ marginRight: "20px" }}
            onClick={closeAddModal}
          />
          {modalTitle}
        </h1>
        <FormContainer>
          <StyledForm>
            <FloatingLabel
              isFocused={!isTypeFocused}
              errors={!scriptFormik.errors["type"]}
              style={{ marginBottom: "30px" }}
            >
              <label className={labelClass("type")} htmlFor="type">
                Type
              </label>
              {!!scriptFormik.errors["type"] && (
                <div className="error">{scriptFormik.errors["type"]}</div>
              )}
              <AntInput
                name="type"
                onChange={handleChange}
                onFocus={() => setIsTypeFocused(true)}
                value={Type}
                onBlur={handleSubmit}
                autoFocus
              />
              <CheckOutLinedIcon className="checkCircleIcon" />
            </FloatingLabel>
            <FloatingLabel
              isFocused={!isLabelFocused}
              errors={!scriptFormik.errors["label"]}
              style={{ marginBottom: "30px" }}
            >
              <label className={labelClass("label")} htmlFor="label">
                Label
              </label>
              {!!scriptFormik.errors["label"] && (
                <div className="error">{scriptFormik.errors["label"]}</div>
              )}
              <AntInput
                name="label"
                onChange={handleChange}
                onFocus={() => setIsLabelFocused(true)}
                value={Label}
                onBlur={handleSubmit}
              />
              <CheckOutLinedIcon className="checkCircleIcon" />
            </FloatingLabel>
          </StyledForm>
        </FormContainer>
      </Drawer>
      <Drawer
        className="custom-css-ant-drawer"
        visible={viewDetaislModal}
        onClose={closeDetaisModal}
        closable
        width="600"
      >
        <h1>
          <ArrowRightOutlined
            style={{ marginRight: "20px" }}
            onClick={closeDetaisModal}
          />{" "}
          {Type} <HeartOutlined style={{ float: "right" }} />
        </h1>
        <Row className="details-body">
          <Col span={6}>
            <p className="title">Label</p>
          </Col>
          <Col span={18}>
            <p>{Label}</p>
          </Col>
        </Row>
      </Drawer>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </div>
  );
};

function mapToStateToProps(state: any) {
  return {
    SystemMessage: state.SystemMessage,
    auth: state.authReducer,
  };
}

export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(AllergyList);
