import { MoreOutlined } from "@ant-design/icons";
import React from "react";
import { TimedActionProperty } from "../TimedActionBlock.styled";
import { TimedActionBlockMenuContainer } from "./TimedActionBlockMenuContainer.styled";

const TimedActionBlockMenu = () => {
  return (
    <TimedActionProperty>
      <TimedActionBlockMenuContainer>
        <MoreOutlined />
      </TimedActionBlockMenuContainer>
    </TimedActionProperty>
  );
};

export default TimedActionBlockMenu;
