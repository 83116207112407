import React, { useEffect, useState } from "react";
import {
  InteliQueryHelperContainerRow,
  InteliQueryQuestionContainerRow,
} from "./InteliQueryBuilderHelper.styled";
import { Col } from "antd";
import { ThunderboltOutlined } from "@ant-design/icons";
import { getAllQueries } from "../../../../api/QueryBuilder/ApiGet";
import QueryQuestion from "./QueryQuestion/QueryQuestion";
import styled from "styled-components";

interface Query {
  Id: number;
  Question: string;
  QueryCategory: string;
  StoredProcedureToExecute: string;
}

interface InteliQueryHelperProps {
  formik: any;
}

const QueryLibraryTitle = styled.h1`
  border-bottom: 1px solid #363636;
  font-weight: 500;
  font-size: 24px;
  line-height: 2;
  margin-top: 40px;
  margin-bottom: 10px;
  color: white;
`;

const InteliQueryHelper = ({ formik }: InteliQueryHelperProps) => {
  const [queries, setQuesries] = useState<Query[]>();

  useEffect(() => {
    getAllQueries(setQuesries);
  }, []);

  const selectQuery = (query: Query) => {
    // Deselect query question when again clicked.
    if (formik.values["selectedQueryId"] === query.Id) {
      formik.setFieldValue("selectedQueryId", 0);
      formik.setFieldValue("title", "");
    }
    //  Select query question when clicked.
    else {
      formik.setFieldValue("selectedQueryId", query.Id);
      formik.setFieldValue("title", query.Question);
      formik.setFieldValue("queryParams", []);
    }
    formik.setFieldValue("subtitle", "");
  };
  return (
    <>
      <QueryLibraryTitle>Query Library:</QueryLibraryTitle>
      {queries?.map((query: Query) => (
        <>
          <InteliQueryHelperContainerRow
            className="cursor-pointer"
            show={formik.values["selectedQueryId"] === query.Id}
            key={`query-${query.Id}`}
            onClick={() => {
              selectQuery(query);
            }}
          >
            <Col className="icon-thunder" span={1}>
              <ThunderboltOutlined />
            </Col>
            <Col span={23}>
              <label className="cursor-pointer">{query.Question}</label>
            </Col>
          </InteliQueryHelperContainerRow>
          <InteliQueryQuestionContainerRow
            show={formik.values["selectedQueryId"] === query.Id}
            key={`query-question-${query.Id}`}
          >
            <Col span={1}></Col>
            <Col span={23}>
              <QueryQuestion
                formik={formik}
                queryId={query.Id}
                StoredProcedureToExecute={query.StoredProcedureToExecute}
              />
            </Col>
          </InteliQueryQuestionContainerRow>
        </>
      ))}
    </>
  );
};

export default InteliQueryHelper;
