import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { UpdatePhaseOrder } from "../../../../../api/Phases/ApiPost";
import { PaneBody } from "../../../../../components/GlobalStyledComponents.styled";
import { OpenErrorNotification } from "../../../../../components/Notification/Notification";
import { DragIcon } from "../../../../../IconsTheme/Icons.global";
import { Phase } from "../../../../../models/ScriptInterfaces";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  GreenSolidButton,
  GreySolidButton,
} from "../../../../../styles/Buttons.styled";
import { isNullOrUndefined } from "../../../../../utils/utilityfunctions";
import {
  ComponentEdit,
  SetActiveComponent,
  SetActiveKey,
  SetCurrentBlockIndex,
  SetCurrentPhaseId,
  SetCurrentPhaseIndex,
  SetCurrentSectionIndex,
  SetPhases,
  populatePhaseWithTreatment,
} from "../../../MainlistSlice";
import {
  IndexSpan,
  StyledListTitleItem,
  TitleSpan,
  Ul,
} from "../../ShowList/ShowList.styled";
interface Props {
  hideReorderPhasePane: any;
}
export default function PhaseReorderPanel({ hideReorderPhasePane }: Props) {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: any) => state.MainlistReducer);
  const phases: Phase[] = state.phases;
  const [isOrderChange, setIsOrderChange] = useState<boolean>(false);
  const [orderedPhases, setOrderedPhases] = useState<Phase[]>([]);
  useEffect(() => {
    setOrderedPhases(phases);
    return () => {
      setOrderedPhases([]);
    };
  }, [phases]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination!.index;

    if (sourceIndex === destinationIndex) {
      return;
    }

    let reorderedList = [...orderedPhases!];
    const [removedItem] = reorderedList.splice(sourceIndex, 1);
    reorderedList.splice(destinationIndex, 0, removedItem);

    reorderedList = reorderedList.map((phase: Phase, phaseIndex) => {
      return {
        ...phase,
        Order: phaseIndex + 1,
      };
    });

    setOrderedPhases(reorderedList);
    setIsOrderChange(true);
  };
  const apiError = () => {
    OpenErrorNotification({
      description: "An Error Occurred check the console for more information",
    });
  };
  const handleSave = () => {
    UpdatePhaseOrder(
      orderedPhases,
      () => {
        dispatch(SetPhases(orderedPhases));
        // This session token is used to scroll to element position after page refresh.
        window.sessionStorage.removeItem("SelectedElementId");
        const currentPhaseIndex = orderedPhases.findIndex(
          (x) => x.UUID === state.activeKey,
        );
        dispatch(SetActiveKey(state.activeKey));
        dispatch(SetCurrentSectionIndex(0));
        dispatch(SetCurrentBlockIndex(0));
        dispatch(SetCurrentPhaseIndex(currentPhaseIndex));
        dispatch(SetCurrentPhaseId(orderedPhases[currentPhaseIndex]?.Id || 0));
        dispatch(SetActiveComponent(ComponentEdit.PHASE));
        populatePhaseWithTreatment(orderedPhases, dispatch, state);
        hideReorderPhasePane();
      },
      apiError,
    );
  };

  return (
    <>
      <PaneBody style={{ height: "calc(100vh - 170px)" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"droppableForPhaseItem"}>
            {(provided: any, snapshot: any) => (
              <Ul {...provided.droppableProps} ref={provided.innerRef}>
                {!isNullOrUndefined(orderedPhases)
                  ? orderedPhases.map((phase: Phase, phaseMapIndex: number) => (
                      <Draggable
                        draggableId={`phase_${phase.Id}`}
                        key={phase.Id}
                        index={phaseMapIndex}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <li>
                              <StyledListTitleItem
                                className="titleIndexDiv"
                                isQuantityEnabled={false}
                              >
                                <IndexSpan style={{ borderRight: "none" }}>
                                  <DragIcon />
                                </IndexSpan>
                                <TitleSpan style={{ padding: "0.5rem" }}>
                                  {phaseMapIndex + 1} &nbsp;- &nbsp;
                                  {phase.TitleAbbreviated !== ""
                                    ? phase.TitleAbbreviated
                                    : `Phase ${phaseMapIndex + 1}`}
                                </TitleSpan>
                              </StyledListTitleItem>
                            </li>
                          </div>
                        )}
                      </Draggable>
                    ))
                  : null}
                {provided.placeholder}
              </Ul>
            )}
          </Droppable>
        </DragDropContext>
      </PaneBody>
      <div className="text-center">
        {!isOrderChange && (
          <GreySolidButton
            rounded="true"
            className="disable-btn"
            style={{ width: 180 }}
          >
            Save
          </GreySolidButton>
        )}
        {isOrderChange && (
          <GreenSolidButton rounded onClick={handleSave} style={{ width: 180 }}>
            Save
          </GreenSolidButton>
        )}
      </div>
    </>
  );
}
