import React, { useState, useEffect } from "react";
import {
  Form,
  Label,
  LabelSpan,
  ErrorSpan,
  Input,
  SuffixSpan,
} from "./AgeWeight.styled";

interface Weightprops {
  Weight: any;
  handleChange: any;
  onSave: any;
}

const WeightForm = (props: Weightprops) => {
  const [classNameForLabel, setClassNameForLabel] =
    useState<string>("label-name");

  useEffect(() => {
    if (props.Weight.trim().length > 0) {
      setClassNameForLabel("label-name filled-label");
    } else {
      setClassNameForLabel("label-name");
    }
  }, [props.Weight]);

  return (
    <Form>
      <Input
        type="number"
        name="weight"
        value={props.Weight}
        onChange={props.handleChange}
        onBlur={props.onSave}
      />

      <Label className={classNameForLabel} htmlFor="weight">
        <LabelSpan
          className={
            props.Weight.trim().length === 0
              ? "content-name"
              : "content-name filled-content"
          }
        >
          {" "}
          Weight*{" "}
        </LabelSpan>
        <ErrorSpan className="error"></ErrorSpan>
        <SuffixSpan>lbs</SuffixSpan>
      </Label>
    </Form>
  );
};

export default WeightForm;
