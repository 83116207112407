import styled from "styled-components";
import { ColorPalette as ColorPalette } from "../../styles/ColorPalette";

export const StyledStarButton = styled.span`
  .star-btn {
    font-size: 1.25rem;
    &:hover {
      color: ${ColorPalette.White};
    }
    &.active {
      color: ${ColorPalette.Blue1};
    }
  }
`;
