import React from "react";
import { Select } from "antd";
import FloatingLabelSelect from "../../../../../components/MuiSelect/FloatingLabelSelect";
import { ResearchQuestionType } from "../../../../../models/Enums";
const { Option } = Select;
interface Props {
  value: number | string;
  onChange: Function;
  disabled: boolean;
}
export type AnswerTypeOption = {
  key: string;
  value: number;
  label: string;
};
export const AnswerTypeDropdownOptions: AnswerTypeOption[] = [
  {
    key: `answer-type-${ResearchQuestionType.Text}`,
    value: ResearchQuestionType.Text,
    label: "Text",
  },
  {
    key: `answer-type-${ResearchQuestionType.Select}`,
    value: ResearchQuestionType.Select,
    label: "Select",
  },
  {
    key: `answer-type-${ResearchQuestionType.Number}`,
    value: ResearchQuestionType.Number,
    label: "Number",
  },
  {
    key: `answer-type-${ResearchQuestionType.Radio}`,
    value: ResearchQuestionType.Radio,
    label: "Radio Buttons",
  },
  {
    key: `answer-type-${ResearchQuestionType.Button}`,
    value: ResearchQuestionType.Button,
    label: "Button",
  },
];

export default function AnswerTypeSelection(props: Props) {
  const onChange = (value: any) => {
    props.onChange("ResearchQuestionType", value);
  };
  return (
    <div className="mb-8">
      <FloatingLabelSelect
        label="Answer Type"
        className="w-100"
        onChange={onChange}
        value={props.value}
        disabled={props.disabled}
      >
        {AnswerTypeDropdownOptions.map((op: AnswerTypeOption) => {
          return (
            <Option key={op.key} value={op.value} title="">
              {op.label}
            </Option>
          );
        })}
      </FloatingLabelSelect>
    </div>
  );
}
