import React from "react";
import { LabelData } from "../../../../../../models/LabelOverlayInterfaces";
import { LabelContainer, LabelOverlayDiv } from "./LablelOverlay.styled";
import Label from "./Label/Label";

type Props = {
  labelData: LabelData[];
  isFront: boolean;
};

type Grid = {
  id: string;
  element: React.JSX.Element;
  class: string;
  isOccupied: boolean;
};

const LabelOverlay = ({ labelData, isFront, ...props }: Props) => {
  let grid: Grid[] = [];

  // Adds Label container to form grid layout.
  const addLabelContainerToGrid = (id: string, className: string) => {
    const newLabelElement = (
      <LabelContainer key={id} id={id} className={className}></LabelContainer>
    );

    grid.push({
      id,
      element: newLabelElement,
      class: className,
      isOccupied: false,
    });
  };

  // This handles switching the label sides when the
  // image flips it's side.
  const numberOfGridRows = 13;
  if (isFront) {
    for (let i = 1; i < numberOfGridRows; i++) {
      addLabelContainerToGrid(`R${i}`, "front right-label");
      addLabelContainerToGrid(`C${i}`, "front center-label");
      addLabelContainerToGrid(`L${i}`, "front left-label");
    }
  } else {
    for (let i = 0; i < numberOfGridRows; i++) {
      addLabelContainerToGrid(`L${i}`, "back left-label");
      addLabelContainerToGrid(`C${i}`, "back center-label");
      addLabelContainerToGrid(`R${i}`, "back right-label");
    }
  }

  // Handles how the labels are inserted in the LabelContainer.
  // labelData - contains data that will be used to position the label and display content of the label.
  // labelOption - gives us option of ids where labels needs to be inserted.
  labelData?.forEach((label) => {
    label.labelOptions?.every((labelOption) => {
      let labelContainer;

      // handles when the location for label is selected as Center.
      if (labelOption.toUpperCase().startsWith("C")) {
        const l_Id = labelOption.replace("C", "L");
        const r_Id = labelOption.replace("C", "R");
        if (!grid.find((x) => x.id === l_Id)?.isOccupied) {
          labelContainer = grid.find((x) => x.id === l_Id);
        } else if (!grid.find((x) => x.id === r_Id)?.isOccupied) {
          labelContainer = grid.find((x) => x.id === r_Id);
        }
      }
      // When location for the label is selected as Left or Right.
      else {
        labelContainer = grid.find((x) => x.id === labelOption);
      }

      // If the label container is not occupied insert the Label to the LabelContainer.
      if (labelContainer && !labelContainer.isOccupied) {
        const updatedGrid = [...grid];
        updatedGrid[grid.indexOf(labelContainer)] = {
          id: labelContainer.id,
          element: (
            <LabelContainer
              key={labelContainer.id}
              id={labelContainer.id}
              className={labelContainer.class}
            >
              <Label label={label} />
            </LabelContainer>
          ),
          class: labelContainer.class,
          isOccupied: true,
        };
        grid = updatedGrid;
      } else {
        // If the label container is occupied re run the label.labelOptions?.every()
        // to look for other available id for the label.
        return true;
      }
    });
  });

  return (
    <>
      <LabelOverlayDiv id="label-overlay">
        {grid.map((x) => x.element)}
      </LabelOverlayDiv>
    </>
  );
};

export default LabelOverlay;
