import * as React from "react";
import styled from "styled-components";
import { CheckOutLinedIcon } from "../../../IconsTheme/Icons.global";
import CustomStackedAreaChart from "../../../components/Charts/AreaChart/CustomStackedAreaChart";
import CustomHorizontalBarChart from "../../../components/Charts/BarChart/CustomHorizontalBarChart";
import CustomVerticalBarChart from "../../../components/Charts/BarChart/CustomVerticalBarChart";
import CustomDoughnutChart from "../../../components/Charts/DoughnutChart/CustomDoughnutChart";
import CustomPlotLineChart from "../../../components/Charts/LineChart/CustomPlotLineChart";
import CustomPieChart from "../../../components/Charts/PieChart/CustomPieChart";
import CustomScatterChart from "../../../components/Charts/ScatterChart/CustomScatterChart";
import { Stack } from "../../../components/core/stack";
import { ChartType } from "../../../models/Enums";
import { ColorPalette } from "../../../styles/ColorPalette";
import {
  BarChartData,
  DoughnutChartData,
  PieChartData,
  PlotLineData,
  ScatterData,
  StackedAreaData,
  VerticalBarData,
} from "../DashboardChartData";
interface Props {
  visualizationType: ChartType | null;
  chartTitle: string;
  chartSubtitle: string;
  onChange: (selected: ChartType) => void;
}

export type SelectionCardProps = {
  title: React.ReactNode;
  selected: boolean;
  onToggle: (selected: boolean) => void;
  chartTitle: string;
  chartSubtitle: string;
};

const SelectionStatus = styled.div<{ selected?: boolean }>`
  width: 30px;
  height: 30px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.selected ? ColorPalette.Blue1 : ColorPalette.White};
  border-radius: 30px;
  display: grid;
  place-items: center;

  & svg {
    fill: ${ColorPalette.Blue1};
    font-size: 20px;
  }
`;

const SelectionCardStyled = styled.div<{ selected?: boolean }>`
  padding: 20px;
  border-width: 0;
  border-bottom-width: ${(props) => (props.selected ? "1px" : "0px")};
  border-color: ${ColorPalette.Blue1};
  border-style: solid;
  outline: transparent;
  cursor: pointer;

  background-color: ${(props) =>
    props.selected ? ColorPalette.Blue4 : ColorPalette.Gray6};

  &:hover {
    background-color: ${ColorPalette.Gray4};
  }

  & .title-section {
    height: 60px;

    & .title {
      color: ${ColorPalette.White};
      font-size: 20px;
      line-height: 1.25;
    }
  }

  & .body {
    padding: 20px 60px 0px 60px;

    & .title {
      font-size: 24px;
      line-height: 1.25;
      color: ${ColorPalette.White};
      font-weight: 500;
    }
    & .sub-title {
      font-size: 20px;
      line-height: 1.25;
      color: ${ColorPalette.Gray2};
      padding-bottom: 6px;
      border-bottom: 1.24px solid ${ColorPalette.Gray2};
    }
  }
`;

const SelectionCard = ({
  title,
  children,
  selected,
  onToggle,
  chartTitle,
  chartSubtitle,
}: React.PropsWithChildren<SelectionCardProps>) => {
  return (
    <SelectionCardStyled
      tabIndex={0}
      onClick={(e) => {
        onToggle(!selected);
      }}
    >
      <Stack className="title-section" align="center" justify="space-between">
        <div className="title">{title}</div>
        <SelectionStatus selected={selected}>
          {selected ? <CheckOutLinedIcon /> : null}
        </SelectionStatus>
      </Stack>
      <div className="body">
        <Stack direction="column" gap="6px">
          <div className="title">{chartTitle}</div>
          <div className="sub-title">{chartSubtitle}</div>
          <div style={{ minHeight: 350 }}>{children}</div>
        </Stack>
      </div>
    </SelectionCardStyled>
  );
};

export const visualizations: Record<
  ChartType,
  { component: any; sampleData: any; title: string; value: ChartType }
> = {
  [ChartType.HorizontalBar]: {
    value: ChartType.HorizontalBar,
    title: "Bar Graph - Horizontal",
    component: CustomHorizontalBarChart,
    sampleData: BarChartData,
  },
  [ChartType.VerticalBar]: {
    value: ChartType.VerticalBar,
    title: "Bar Graph - Vertical",
    component: CustomVerticalBarChart,
    sampleData: VerticalBarData,
  },
  [ChartType.PlotLine]: {
    value: ChartType.PlotLine,
    title: "Simple Plot",
    component: CustomPlotLineChart,
    sampleData: PlotLineData,
  },
  [ChartType.StackedArea]: {
    value: ChartType.StackedArea,
    title: "Area Chart",
    component: CustomStackedAreaChart,
    sampleData: StackedAreaData,
  },
  [ChartType.Pie]: {
    value: ChartType.Pie,
    title: "Pie Chart",
    component: CustomPieChart,
    sampleData: PieChartData,
  },
  [ChartType.Scatter]: {
    value: ChartType.Scatter,
    title: "Scatter Plot",
    component: CustomScatterChart,
    sampleData: ScatterData,
  },
  [ChartType.Doughnut]: {
    value: ChartType.Doughnut,
    title: "Radial Gauge",
    component: CustomDoughnutChart,
    sampleData: DoughnutChartData,
  },
};

export default function VisualizationChart({
  visualizationType,
  onChange,
  chartTitle,
  chartSubtitle,
}: Props) {
  return (
    <div className="chart-block">
      {Object.entries(visualizations).map(
        ([visType, { title, component: K, sampleData, value }]) => {
          console.log(visType);

          return (
            <SelectionCard
              key={visType}
              title={title}
              chartTitle={chartTitle}
              chartSubtitle={chartSubtitle}
              selected={visualizationType?.toString() === visType}
              onToggle={() => onChange(value)}
            >
              <K data={sampleData} />
            </SelectionCard>
          );
        },
      )}
    </div>
  );
}
