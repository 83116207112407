import axios from "axios";
import { UpdateWidget } from "../../models/DashboardInterface";

const apiPrefix = "api/1/pfc/Dashboard";

export function updateWidget(
  payload: UpdateWidget,
  callback: Function,
  errorCallback: Function,
) {
  console.log(payload);
  axios
    .put(`${apiPrefix}/Widget`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      errorCallback(err.response.data.ErrorMessage);
    });
}
