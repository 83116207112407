import * as React from "react";
import { Col, Row, Tooltip } from "antd";
import { FormikProps } from "formik";
import {
  ArrowLeftOutlined,
  EditOutlined,
  FrownOutlined,
  HistoryOutlined,
  EyeOutlined,
  PictureOutlined,
  PlusOutlined,
  ArrowRightOutlined,
  CrownOutlined,
} from "@ant-design/icons";
import { PaneHeader, PaneBody, PaneTabs, GreyButton } from "../styled";
import {
  colorPalette,
  hexTransparencies,
} from "../../../../../styles/MainList.styled";
import { Allergy, Casualty } from "../../../../../models/CasualtyInterfaces";
import { CasualtyTable } from "../CasualtyTable/CasualtyTable";
import {
  ScriptViewTypes,
  LeftPaneViewTypes,
  LeftPaneCasualtyCreatorViewTypes,
} from "../constants";
import CasualtyForm from "../CasualtyForm/CasualtyForm";
import InjuryTab from "../InjuryTab";
import { ScriptFormValues } from "../ScriptView/ScriptView";
import { BloodType, Sex } from "../../../../../models/Enums";
import { useEffect } from "react";
import { StyledTabButton } from "../../../../../components/TabCloseButton/TabCloseButton";
import DetailsTab from "../DetailsTab/DetailsTab";
import "./CasualtyView.scss";
import CreateMasterCasualty from "../CreateMasterCasuatlyTab/CreateMasterCasualty";
import { CustomTitle } from "./StyledComponents/CustomTitle.styled";
import { PaneDiv } from "./StyledComponents/PaneDiv.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  CASUALTY_CREATOR_RESET_TO_INITIAL_STATE,
  CASUALTY_CREATOR_SET_CASUALTY_FORM,
  CASUALTY_CREATOR_SET_CURRENT_CASUALTY_CREATOR_TAB,
  CASUALTY_CREATOR_SET_CUSTOM_HEADING,
  CASUALTY_CREATOR_SET_FLAG_TO_DISPLAY_HEADING,
} from "../../../../../store/CasualtyCreator/actionType";
import TabCloseIconButton from "./TabCloseIconButton";
import { useAppSelector } from "../../../../../store/hooks";
import {
  INSULT_INJURY_RESET_APPLIED_INJURIES,
  INSULT_INJURY_RESET_APPLIED_INSULTS,
  INSULT_INJURY_SET_APPLIED_INJURIES,
  INSULT_INJURY_SET_APPLIED_INSULTS,
} from "../../../../../store/CasualtyCreator/InsultInjury/actionType";
import {
  AddCasualty,
  SetSelectedCasualtyId,
  UpdateCasualties,
} from "../../../../../store/ScriptBuilder/scriptBuilderSlice";

const { TabPane } = PaneTabs;

const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
  console.log("params", pagination, filters, sorter, extra);
};

interface CasualtyViewProps {
  setView: Function;
  scriptFormik: FormikProps<ScriptFormValues>;
  allAllergies: Allergy[];
  setAllAllergies: Function;
  leftPaneView: string;
  setLeftPaneView: Function;
  casualty: Casualty;
  SetCurrentCasualty: Function;
  createNewScript: Function;
  setScriptTitle: Function;
  setIsCasualtyEditMode: Function;
  isCasultyEditMode: boolean;
}

const CasualtyView = ({
  scriptFormik,
  setView,
  allAllergies,
  setAllAllergies,
  leftPaneView,
  setLeftPaneView,
  casualty,
  SetCurrentCasualty,
  createNewScript,
  setScriptTitle,
  setIsCasualtyEditMode,
  isCasultyEditMode,
}: CasualtyViewProps) => {
  const dispatch = useDispatch();
  const { casualties, selectedCasualtyId, isReadOnly } = useAppSelector(
    (state) => state.scriptBuilderReducer,
  );
  const {
    casualtyObservations,
    casualtyDiagnostics,
    casualtyMaster,
    casualtyStates,
    casualtyChartTab,
  } = useAppSelector((state) => state.FeatureFlagReducer.casualtyView);
  const [isModelClothsOn, setIsModelClothsOn] = React.useState(true);

  const handleIsModelClothsOn = () => {
    setIsModelClothsOn((prev) => !prev);
  };

  const displayCustomHeading = useSelector(
    (state: any) => state.CasualtyCreator.DisplayCustomHeading,
  );
  const currentScreenHeading = useSelector(
    (state: any) => state.CasualtyCreator.CurrentScreenHeading,
  );
  const currentCasualtyCreatorTab = useSelector(
    (state: any) => state.CasualtyCreator.CurrentCasualtyCreatorTab,
  );
  const RightSideCasualtyFormData = useSelector(
    (state: any) => state.CasualtyCreator.CasualtyForm,
  );

  const [isCasultyTableEditMode, setIsCasultyTableEditMode] =
    React.useState(false);
  const [casultyBeforeAdd, setCasultyBeforeAdd] = React.useState<Casualty>();

  /** responsive center and right panel */
  const getWindowWidth = () => {
    return window.innerWidth;
  };
  const [windowSize, setWindowSize] = React.useState(getWindowWidth());

  useEffect(() => {
    if (scriptFormik && scriptFormik.values && scriptFormik.values.casualtyId) {
      dispatch(SetSelectedCasualtyId(scriptFormik.values.casualtyId));
    }
    function handleWindowResize() {
      setWindowSize(getWindowWidth());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      dispatch({ type: CASUALTY_CREATOR_RESET_TO_INITIAL_STATE }); //reset state
    };
  }, []);
  useEffect(() => {
    if (scriptFormik && scriptFormik.values && scriptFormik.values.casualtyId) {
      dispatch(SetSelectedCasualtyId(scriptFormik.values.casualtyId));
    }
  }, [scriptFormik.values.casualtyId]);
  const handleClickViewScriptDetails = () => {
    setView(ScriptViewTypes.SCRIPT);
  };

  const handleCustomHeading = (flag: boolean, title: string) => {
    dispatch({
      type: CASUALTY_CREATOR_SET_FLAG_TO_DISPLAY_HEADING,
      data: flag,
    });
    dispatch({ type: CASUALTY_CREATOR_SET_CUSTOM_HEADING, data: title });
  };
  const setCurrentCasualtyFormTab = (tab: string) => {
    dispatch({
      type: CASUALTY_CREATOR_SET_CURRENT_CASUALTY_CREATOR_TAB,
      data: tab,
    });
  };
  const handleCloseCasualtyForm = (): void => {
    if (isReadOnly) {
      //disable casualty table view for read-only script
      handleClickViewScriptDetails();
      return;
    }
    //disable casualty table view for read-only script
    handleClickViewScriptDetails();
    return;
    // setLeftPaneView(LeftPaneViewTypes.CASUALTY_TABLE);
    // handleCustomHeading(false, "");
    // setCurrentCasualtyFormTab(LeftPaneCasualtyCreatorViewTypes.CASUALTY_FORM);
    // SetCurrentCasualty(casultyBeforeAdd);
    // dispatch({
    //   type: INSULT_INJURY_SET_APPLIED_INJURIES,
    //   data: (casultyBeforeAdd && casultyBeforeAdd.Injuries) || [],
    // });
    // dispatch({
    //   type: INSULT_INJURY_SET_APPLIED_INSULTS,
    //   data: (casultyBeforeAdd && casultyBeforeAdd.Insults) || [],
    // });
  };

  const handleSaveCasualtyForm = (data: any, saveData = true) => {
    if (saveData) {
      scriptFormik.setFieldValue("casualtyId", data.Id);
      const index = casualties.findIndex((x: Casualty) => x.Id === data.Id);
      if (index !== -1) {
        dispatch(UpdateCasualties(data));
      } else {
        dispatch(AddCasualty(data));
        createNewScript(undefined, null, false, data);
      }
      SetCurrentCasualty(data);
    }
    handleCustomHeading(false, "");
    setCurrentCasualtyFormTab(
      LeftPaneCasualtyCreatorViewTypes.CASUALTY_INSULT_INJURY,
    );

    if (isCasultyEditMode) {
      setIsCasualtyEditMode(true);
    }
  };
  const addCasualty = () => {
    setCasultyBeforeAdd(casualty);
    // New Casualty
    const newCasualty: Casualty = {
      Id: 0,
      Gender: Sex.Male,
      WeightUnit: "kg",
      Height: 121,
      Weight: 0,
      Age: 0,
      BloodType: BloodType.ABPositive,
      IsMasterCasualty: false,
    };
    setIsCasultyTableEditMode(false);
    SetCurrentCasualty(newCasualty);
    dispatch({
      type: CASUALTY_CREATOR_SET_CASUALTY_FORM,
      data: {
        ...newCasualty,
        AllergyIds: [],
      },
    });

    dispatch({ type: INSULT_INJURY_RESET_APPLIED_INJURIES });
    dispatch({ type: INSULT_INJURY_RESET_APPLIED_INSULTS });
    showCasualtyForm();
  };
  const showCasualtyForm = () => {
    setLeftPaneView(LeftPaneViewTypes.CASUALTY_FORM);
    setCurrentCasualtyFormTab(LeftPaneCasualtyCreatorViewTypes.CASUALTY_FORM);
  };
  const handleTabClick = (key: string) => {
    // disabled tab for new casualty and master casualty
    if (
      (key === LeftPaneCasualtyCreatorViewTypes.CASUALTY_INSULT_INJURY ||
        key === LeftPaneCasualtyCreatorViewTypes.CREATE_MASTER_CASUALTY) &&
      casualty &&
      (!casualty?.Id || casualty.IsMasterCasualty)
    ) {
      return true;
    }
    setCurrentCasualtyFormTab(key);
  };
  const handleOpenEditCasualtyDetails = () => {
    const index = casualties.findIndex((x) => x.Id === selectedCasualtyId);
    if (index !== -1) {
      SetCurrentCasualty(casualties[index]);
      handleCustomHeading(false, "");
      showCasualtyForm();
      setIsCasultyTableEditMode(true);
      setCasultyBeforeAdd(casualties[index]);
    }
  };
  const openCasualtyInEditMode = (view: string) => {
    // disable edit functionality  for new casualty
    if (RightSideCasualtyFormData.Id === 0) {
      return;
    }
    const index = casualties.findIndex((x) => x.Id === selectedCasualtyId);
    if (index !== -1) {
      SetCurrentCasualty(casualties[index]);
      handleCustomHeading(false, "");
      setLeftPaneView(LeftPaneViewTypes.CASUALTY_FORM);
      setCurrentCasualtyFormTab(view);
      setIsCasultyTableEditMode(true);
      setCasultyBeforeAdd(casualties[index]);
    }
  };
  const editCasualtyDetails = () => {
    openCasualtyInEditMode(LeftPaneCasualtyCreatorViewTypes.CASUALTY_FORM);
  };
  const editCasualtyInsultInjuries = () => {
    openCasualtyInEditMode(
      LeftPaneCasualtyCreatorViewTypes.CASUALTY_INSULT_INJURY,
    );
  };
  const editCasualtyState = () => {
    openCasualtyInEditMode(LeftPaneCasualtyCreatorViewTypes.CASUALTY_STATS);
  };
  const editCasualtyObservations = () => {
    openCasualtyInEditMode(
      LeftPaneCasualtyCreatorViewTypes.CASUALTY_OBSERVATIONS,
    );
  };
  const editCasualtyDiagnostics = () => {
    openCasualtyInEditMode(
      LeftPaneCasualtyCreatorViewTypes.CASUALTY_DIAGNOSTICS,
    );
  };
  const handleInsultInjuryCancel = () => {
    setCurrentCasualtyFormTab(LeftPaneCasualtyCreatorViewTypes.CASUALTY_FORM);
    setIsCasultyTableEditMode(true);
  };
  const handleCreateMasterCasualtyCancel = () => {
    setCurrentCasualtyFormTab(LeftPaneCasualtyCreatorViewTypes.CASUALTY_FORM);
    setIsCasultyTableEditMode(true);
  };
  const handleCreateMasterCasualty = (data: any) => {
    dispatch(AddCasualty(data));
    setCurrentCasualtyFormTab(LeftPaneCasualtyCreatorViewTypes.CASUALTY_FORM);
  };
  const handleInsultInjurySave = () => {
    createNewScript();
  };
  const getLeftPaneView = (view: string) => {
    switch (view) {
      case LeftPaneViewTypes.CASUALTY_TABLE:
        return (
          <>
            <PaneHeader className="pane-header">
              <Row justify="start" align="middle">
                <Col>
                  <CustomTitle>Casualty List</CustomTitle>
                </Col>
                {isReadOnly ? null : (
                  <Col
                    style={{
                      borderLeft: `1px solid ${colorPalette.gray75}`,
                      marginLeft: "auto",
                    }}
                  >
                    <button
                      className="ant-tabs-tab btn-hover"
                      style={{
                        fontSize: "1em",
                        paddingLeft: "15px",
                        paddingRight: "30px",
                      }}
                      onClick={addCasualty}
                    >
                      <PlusOutlined /> Add Casualty
                    </button>
                  </Col>
                )}
                <Col></Col>
              </Row>
            </PaneHeader>
            <PaneBody
              id="pane-body-height-with-save-button"
              style={{ paddingTop: "15px" }}
            >
              <CasualtyTable
                SetCurrentCasualty={SetCurrentCasualty}
                onChange={onChange}
                casualtyId={scriptFormik.values.casualtyId}
                openEditCasualtyDetails={handleOpenEditCasualtyDetails}
                setIsCasultyTableEditMode={setIsCasultyTableEditMode}
              />
            </PaneBody>
            <div style={{ display: "inline-flex" }}>
              <Tooltip title="Back to Script Details">
                <GreyButton
                  style={{
                    borderRight: `1px solid #FFFFFF${hexTransparencies[10]}`,
                    textAlign: "left",
                  }}
                  htmlType="button"
                  onClick={handleClickViewScriptDetails}
                >
                  <ArrowLeftOutlined /> Back
                </GreyButton>
              </Tooltip>

              {!selectedCasualtyId ? (
                <GreyButton
                  className="disable-btn"
                  htmlType="submit"
                  style={{ width: "100%", textAlign: "right" }}
                >
                  {!isReadOnly && "Save & "} Next <ArrowRightOutlined />
                </GreyButton>
              ) : null}
              {selectedCasualtyId ? (
                <GreyButton
                  style={{
                    borderLeft: `1px solid #FFFFFF${hexTransparencies[10]}`,
                    textAlign: "right",
                  }}
                  htmlType="submit"
                  onClick={handleOpenEditCasualtyDetails}
                >
                  {!isReadOnly && "Save & "} Next <ArrowRightOutlined />
                </GreyButton>
              ) : null}
            </div>
          </>
        );
      case LeftPaneViewTypes.CASUALTY_FORM:
        return (
          <>
            <PaneTabs
              className={
                displayCustomHeading
                  ? `casualty-form display-custom-title`
                  : "casualty-form"
              }
              tabBarExtraContent={{
                right: (
                  <TabCloseIconButton
                    handleCloseCasualtyForm={handleCloseCasualtyForm}
                    leftPaneView={leftPaneView}
                    updateCustomHeading={handleCustomHeading}
                    currentCasualtyCreatorTab={currentCasualtyCreatorTab}
                  />
                ),
                left: displayCustomHeading && (
                  <CustomTitle>{currentScreenHeading}</CustomTitle>
                ),
              }}
              activeKey={currentCasualtyCreatorTab}
              onTabClick={handleTabClick}
            >
              <TabPane
                key={LeftPaneCasualtyCreatorViewTypes.CASUALTY_FORM}
                tab={
                  <Tooltip title={`Casualty Details`}>
                    <StyledTabButton>
                      <EditOutlined className="font-size-0" />
                    </StyledTabButton>
                  </Tooltip>
                }
              >
                <PaneBody
                  style={{ padding: "0px" }}
                  key={currentCasualtyCreatorTab}
                >
                  <CasualtyForm
                    allAllergies={allAllergies}
                    setAllAllergies={setAllAllergies}
                    handleSaveCasualtyForm={handleSaveCasualtyForm}
                    casualty={casualty}
                    onBack={handleCloseCasualtyForm}
                    setScriptTitle={setScriptTitle}
                    setIsCasualtyEditMode={setIsCasualtyEditMode}
                    isCasultyEditMode={isCasultyEditMode}
                    isCasultyTableEditMode={isCasultyTableEditMode}
                    setCasultyBeforeAdd={setCasultyBeforeAdd}
                  />
                </PaneBody>
              </TabPane>
              <TabPane
                key={LeftPaneCasualtyCreatorViewTypes.CASUALTY_INSULT_INJURY}
                tab={
                  <Tooltip title={`Casualty Injuries`}>
                    <StyledTabButton>
                      <FrownOutlined className="font-size-0" />
                    </StyledTabButton>
                  </Tooltip>
                }
              >
                <InjuryTab
                  updateCustomHeading={handleCustomHeading}
                  casualtyId={casualty && casualty.Id ? casualty.Id : 0}
                  onSaveNext={handleInsultInjurySave}
                  onInsultInjuryCancel={handleInsultInjuryCancel}
                />
              </TabPane>
              {casualtyStates && (
                <TabPane
                  key={LeftPaneCasualtyCreatorViewTypes.CASUALTY_STATS}
                  tab={
                    <Tooltip
                      title={`Casualty States ${casualtyStates ? "- Hidden in Prod" : ""}`}
                    >
                      <StyledTabButton>
                        <HistoryOutlined className="font-size-0" />
                      </StyledTabButton>
                    </Tooltip>
                  }
                >
                  <PaneBody>States Tab Content</PaneBody>
                </TabPane>
              )}
              {casualtyObservations && (
                <TabPane
                  key={LeftPaneCasualtyCreatorViewTypes.CASUALTY_OBSERVATIONS}
                  tab={
                    <Tooltip
                      title={`Casualty Observations ${casualtyStates ? "- Hidden in Prod" : ""}`}
                    >
                      <StyledTabButton>
                        <EyeOutlined className="font-size-0" />
                      </StyledTabButton>
                    </Tooltip>
                  }
                >
                  <PaneBody>Observations Tab Content</PaneBody>
                </TabPane>
              )}
              {casualtyDiagnostics && (
                <TabPane
                  key={LeftPaneCasualtyCreatorViewTypes.CASUALTY_DIAGNOSTICS}
                  tab={
                    <Tooltip
                      title={`Casualty Diagnostics ${casualtyDiagnostics ? "- Hidden in Prod" : ""}`}
                    >
                      <StyledTabButton>
                        <PictureOutlined className="font-size-0" />
                      </StyledTabButton>
                    </Tooltip>
                  }
                >
                  <PaneBody>Diagnostics Tab Content</PaneBody>
                </TabPane>
              )}
              {isReadOnly
                ? null
                : casualtyMaster && (
                    <TabPane
                      key={
                        LeftPaneCasualtyCreatorViewTypes.CREATE_MASTER_CASUALTY
                      }
                      tab={
                        <Tooltip
                          title={`Create Master Casualty ${casualtyMaster ? "- Hidden in Prod" : ""}`}
                        >
                          <StyledTabButton>
                            <CrownOutlined className="font-size-0" />
                          </StyledTabButton>
                        </Tooltip>
                      }
                    >
                      <CreateMasterCasualty
                        casualtyId={casualty && casualty.Id ? casualty.Id : 0}
                        onMasterCasualtyCreate={handleCreateMasterCasualty}
                        onCancel={handleCreateMasterCasualtyCancel}
                      />
                    </TabPane>
                  )}
            </PaneTabs>
          </>
        );
    }
  };

  const createChartTab = () => {
    return (
      <>
        {casualtyChartTab && (
          <TabPane tab="Chart" key="2">
            <PaneBody>Chart Tab Content</PaneBody>
          </TabPane>
        )}
      </>
    );
  };

  const getCenterPaneWidth = () => {
    if (document.getElementById("left-pane")) {
      return `calc(100% - ${document.getElementById("left-pane")?.offsetWidth + "px"} - 5px)`;
    }
    return `auto`;
  };
  return (
    <>
      <PaneDiv id="left-pane">{getLeftPaneView(leftPaneView)}</PaneDiv>
      <PaneDiv id="center-pane" style={{ width: getCenterPaneWidth() }}>
        <PaneTabs defaultActiveKey="" className="tab-min-width">
          <TabPane tab="Details" key="1">
            <PaneBody nopadding id="center-pane-detail-panel-body">
              <Row id="center-panel-model">
                <DetailsTab
                  casualty={casualty}
                  leftPaneView={leftPaneView}
                  editCasualtyInsultInjuries={editCasualtyInsultInjuries}
                  selectedCasualtyId={selectedCasualtyId}
                  isModelClothsOn={isModelClothsOn}
                  setIsModelClothsOn={setIsModelClothsOn}
                />
              </Row>
            </PaneBody>
          </TabPane>
          {createChartTab()}
        </PaneTabs>
      </PaneDiv>
    </>
  );
};

export default CasualtyView;
