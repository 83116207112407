import styled from "styled-components";
import { colorPalette } from "../../../../styles/MainList.styled";

export const DetailCardWrapper = styled.div`
  background: #161616;
  padding: 0 1rem;
  border-bottom: 1px solid #292929;
  display: flex;
  color: #fff;
  &:last-child {
    border-bottom: none;
  }
`;

export const DetailCardHeader = styled.div`
  display: flex;
  padding: 1rem 0;
  .anticon {
    margin-left: auto;
    color: ${colorPalette.gray25};
  }
  h4 {
    color: #fff;
    margin: 0;
    font-size: 1.25rem;
    line-height: 1;
  }
`;

export const DetailCardBody = styled.div`
  padding-bottom: 0.7rem;
  p {
    font-size: 1rem;
    line-height: 1.2rem;
  }
`;

export const ContentWrap = styled.div`
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  width: 100%;
`;

export const StyledTeamBlock = styled.div`
   {
    background: #1e1e1e;
    padding: 15px 0px 15px 15px;
    margin-bottom: 1rem;
    ul {
      list-style: none;
      margin-left: 1rem;
      li {
        font-size: 1rem;
        line-height: 19px;
        margin-bottom: 0.5rem;
        .anticon {
          font-size: 1.5rem;
          padding-right: 0.5rem;
        }
      }
    }
  }
`;
