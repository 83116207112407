import { Spin, Tabs, Tooltip } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { setAlertMessage } from "../../actions/alertMessage";
import { getAllAllergies } from "../../api/Allergy/ApiGet";
import { getAllCasualty } from "../../api/Casualty/ApiGet";
import { getAllCategories } from "../../api/Category/ApiGet";
import { getAllEquipment } from "../../api/Equipment/ApiGet";
import { getAllInjuries } from "../../api/Injury/ApiGet";
import { getAllinsults } from "../../api/Insult/ApiGet";
import { Auth, SystemMessage } from "../../app/modules";
import { Allergy, Casualty } from "../../models/CasualtyInterfaces";
import { Category } from "../../models/CategoryInterface";
import { AlertType } from "../../models/Enums";
import { EquipmentItem } from "../../models/EquipmentInterfaces";
import { Injury } from "../../models/InjuryInterface";
import { Insult } from "../../models/InsultInterface";
import { RolePlayerPromptType } from "../../models/ScriptInterfaces";
import AllergyList from "./Components/AllergyList";
import CasualtyList from "./Components/CasualtyList";
import EquipmentList from "./Components/EquipmentList";
import InjuryList from "./Components/InjuryList";
import RolePlayPromptType from "./Components/RolePlayPromptType";
import MediaList from "./Components/MediaList/MediaList";
import "./CreateMasters.scss";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { SetCasualties } from "../../store/ScriptBuilder/scriptBuilderSlice";
import { useHistory, useParams } from "react-router";
import ResearchQuestions from "./Components/ResearchQuestion/ResearchQuestions";
import Treatments from "./Components/Treatments/Treatments";
import Diagnostics from "./Components/Diagnostics/Diagnostics";
import Medications from "./Components/Medications/Medications";
import { NavigationTitle } from "../../components/GlobalStyledComponents.styled";
import Miscellaneous from "./Components/Miscellaneous/Miscellaneous";

const { TabPane } = Tabs;

interface Props {
  auth: Auth;
  SystemMessage: SystemMessage;
  setAlertMessage: Function;
}

const CreateMasters = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    rolePlayerPromptTypesTab,
    equipmentsTab,
    injuriesTab,
    casualtiesTab,
    supportTeamPrompts,
  } = useAppSelector((state) => state.FeatureFlagReducer.manageContent);
  const [activeTabTitle, setActiveTabTitle] = React.useState("Treatments");
  const [loading, setLoading] = React.useState<boolean>(true);
  const [allCasualties, setAllCasualties] = React.useState<Casualty[]>([]);
  const [allRolePlayerPromptType, setAllRolePlayerPromptType] = React.useState<
    RolePlayerPromptType[]
  >([]);
  const [allAllergies, setAllAllergies] = React.useState<Allergy[]>([]);
  const [allEquipments, setAllEquipments] = React.useState<EquipmentItem[]>([]);
  const [allCategories, setAllCategories] = React.useState<Category[]>([]);
  const [allInjuries, setAllInjuries] = React.useState<Injury[]>([]);
  const [allInsults, setAllInsults] = React.useState<Insult[]>([]);
  const history = useHistory();
  const { activeTab } = useParams<any>();
  const defaultTab = "/create-masters/treatments";
  const handleOnTabClick = (path: string) => {
    history.push(`/create-masters/${path}`);
  };

  // Sets title for each table dynamically.
  React.useEffect(() => {
    // Separates words using '-' as separator using active tab key.
    activeTab && setActiveTabTitle(activeTab.replace(/-/g, " "));
  }, [activeTab]);

  React.useEffect(() => {
    refreshCasualty();
    refreshAllergy();
    refreshEquipment();
    refreshCategory();
    refreshInjury();
    refreshInsult();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCasualtySuccess = (data: any) => {
    dispatch(SetCasualties(data));
    setAllCasualties(data);
    setLoading(false);
  };
  const refreshCasualty = () => {
    getAllCasualty(getCasualtySuccess, error);
  };

  const getAllAllergiesSuccess = (data: any) => {
    setAllAllergies(data);
    setLoading(false);
  };
  const refreshAllergy = () => {
    getAllAllergies(getAllAllergiesSuccess, error);
  };

  const getEquipmentSuccess = (data: any) => {
    setAllEquipments(data);
    setLoading(false);
  };
  const refreshEquipment = () => {
    getAllEquipment(getEquipmentSuccess, error);
  };

  const getcategorySuccess = (data: any) => {
    setAllCategories(data);
    setLoading(false);
  };
  const refreshCategory = () => {
    getAllCategories(getcategorySuccess, error);
  };

  const getInjuriesSuccess = (data: any) => {
    setAllInjuries(data);
    setLoading(false);
  };
  const refreshInjury = () => {
    getAllInjuries(getInjuriesSuccess, error);
  };

  const getInsultsSuccess = (data: any) => {
    setAllInsults(data);
    setLoading(false);
  };
  const refreshInsult = () => {
    getAllinsults(getInsultsSuccess, error);
  };

  const error = (data: any) => {
    console.log(data);
    props.setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An error occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
    setLoading(false);
  };

  React.useEffect(() => {
    refreshCasualty();
    refreshAllergy();
    refreshEquipment();
    refreshCategory();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteCasualty = () => undefined;
  const deletePrompt = () => undefined;
  const deleteAllergy = () => undefined;
  const deleteEquipment = () => undefined;
  const getStyle = () => {
    const tabWithCustomTableUI = [
      "research-question",
      "treatments",
      "diagnostics",
      "medications",
    ];
    return !tabWithCustomTableUI.includes(activeTab)
      ? { minHeight: "100vh" }
      : {};
  };
  return (
    <div className="padding-with-greybg create-master-container">
      <NavigationTitle className="script-library-title">
        Manage {activeTabTitle}
      </NavigationTitle>
      <div
        className="rxblackBG"
        style={{
          height: "fit-content",
          overflowY: "auto",
          display: "flex",
          flexWrap: "nowrap",
          flexDirection: "column",
        }}
      >
        <Spin spinning={loading}>
          <Tabs
            defaultActiveKey={defaultTab}
            activeKey={activeTab} // <- give keys like research-questions if the name includes more then one word.
            onChange={handleOnTabClick}
            tabPosition="left"
            style={getStyle()}
          >
            <TabPane tab="Treatments" key="treatments">
              <Treatments />
            </TabPane>
            <TabPane tab="Medications" key="medications">
              <Medications />
            </TabPane>
            <TabPane tab="Diagnostics" key="diagnostics">
              <Diagnostics />
              <TabPane tab="Miscellaneous" key="miscellaneous">
                <Miscellaneous />
              </TabPane>
            </TabPane>
            <TabPane tab="Research Questions" key="research-questions">
              <ResearchQuestions />
            </TabPane>
            {casualtiesTab && (
              <TabPane
                tab={
                  <Tooltip title="Hidden in production" placement="right">
                    Casualties
                  </Tooltip>
                }
                key="casualties"
              >
                <CasualtyList
                  casualty={allCasualties}
                  deleteCasualty={deleteCasualty}
                  refreshCasualty={refreshCasualty}
                  allAllergies={allAllergies}
                />
              </TabPane>
            )}
            {injuriesTab && (
              <TabPane
                tab={<Tooltip title="Hidden in production">Injuries</Tooltip>}
                key="injuries"
              >
                <InjuryList
                  injuryList={allInjuries}
                  insultList={allInsults}
                  refreshInjury={refreshInjury}
                />
              </TabPane>
            )}
            <TabPane tab="Allergies" key="allergies">
              <AllergyList
                AllergyList={allAllergies}
                deleteAllergy={deleteAllergy}
                refreshAllergy={refreshAllergy}
              />
            </TabPane>
            {equipmentsTab && (
              <TabPane
                tab={
                  <Tooltip title="Hidden in production" placement="right">
                    Equipments
                  </Tooltip>
                }
                key="equipments"
              >
                <EquipmentList
                  equipmentList={allEquipments}
                  deleteEquipment={deleteEquipment}
                  refreshEquipemnt={refreshEquipment}
                  categoryList={allCategories}
                />
              </TabPane>
            )}
            <TabPane tab="Media" key="media">
              <MediaList />
            </TabPane>
            {rolePlayerPromptTypesTab && (
              <TabPane
                tab={
                  <Tooltip title="Hidden in production" placement="right">
                    Role Player Prompt Types
                  </Tooltip>
                }
                key="role-player-prompt-types"
              >
                <RolePlayPromptType
                  RolePlayerPromptType={allRolePlayerPromptType}
                  deletePrompt={deletePrompt}
                  refreshPrompt={() => undefined}
                />
              </TabPane>
            )}
            {supportTeamPrompts && (
              <TabPane
                tab={
                  <Tooltip title="Hidden in production" placement="right">
                    Support Team Prompts
                  </Tooltip>
                }
                key="support-team-prompts"
              >
                <RolePlayPromptType
                  RolePlayerPromptType={allRolePlayerPromptType}
                  deletePrompt={deletePrompt}
                  refreshPrompt={() => undefined}
                />
              </TabPane>
            )}
          </Tabs>
        </Spin>
      </div>
    </div>
  );
};

function mapToStateToProps(state: any) {
  return {
    auth: state.authReducer,
    SystemMessage: state.SystemMessage,
  };
}
export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(CreateMasters);
