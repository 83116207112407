import React, { useEffect } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { BlockType } from "../../models/Enums";
import { Block, Phase } from "../../models/ScriptInterfaces";
import {
  ListSection,
  PromptSection,
  SupportAction,
  TextSection,
  TimedActionTaskSection,
} from "./SectionComponents";
import {
  RequiredTreatmentDiv,
  SubListDragDiv,
  SubListItem,
  SubListItemWrapper,
  TimedActionSubListItem,
} from "../../styles/MainList.styled";
import { DragIcon } from "../../IconsTheme/Icons.global";
import { ScriptListBlock } from "./EditComponents/ListEdit";
import { TextBlock } from "./EditComponents/TextEdit";
import { isNullOrUndefined } from "../../utils/utilityfunctions";
import { CasualtyStatusBlock } from "./EditComponents/CasualtyStatus/CasualtyStatusEdit";
import { CasualtyStatusSection } from "./EditComponents/CasualtyStatus/CasualtyStatusCenterPane";
import { ResearchQuestionSection } from "./EditComponents/ResearchQuestion/ResearchQuestionCenterPane";
import { ResearchQuestionBlock } from "./EditComponents/ResearchQuestion/ResearchQuestionEdit";
import { useAppSelector } from "../../store/hooks";
import { MiscellaneousTaskBlock } from "../../models/MiscellaneousTaskBlock";
import { DiagnosticTaskBlock } from "../../models/DiagnosticTaskBlock";
import { TreatmentTaskBlock } from "../../models/TreatmentTaskBlock";
import { MedicationTaskBlock } from "../../models/MedicationTaskBlock";

interface Props {
  type: any;
  subItems: any;
  sectionIndex: number;
  selectobject: Function;
  activeComponentIndex: number;
  textColumnType: string;
  mouseHoveringCB: any;
  currentPhaseIndex?: number;
  currentBlockIndex: number;
  sectionUUID: string;
}

const SubList = (props: Props) => {
  const updatedSubItems = props.subItems.map((item: any) => {
    if ([10, 13, 14, 11].includes(item.BlockType)) {
      return {
        ...item,
        className: "timedActionBlock",
      };
    }
    return item;
  });
  const state = useAppSelector((state: any) => state.MainlistReducer);
  const phases: Phase[] = state.phases;
  const isReadOnly: boolean = state.isReadOnly;
  const getItemDisplay = (item: Block | MiscellaneousTaskBlock) => {
    switch (item.BlockType) {
      case BlockType.ScriptEquipmentList:
        return <ListSection block={item as ScriptListBlock} />;
      case BlockType.ScriptList:
        return <ListSection block={item as ScriptListBlock} />;
      case BlockType.ScriptRolePlayerPrompt:
        return <PromptSection block={item as TextBlock} />;
      case BlockType.Text:
        if ("StandInType" in item.BlockContent) {
          if (item.BlockContent.StandInType === "role player prompt") {
            return <PromptSection block={item as TextBlock} />;
          } else if (item.BlockContent.StandInType === "support action") {
            return <SupportAction block={item as TextBlock} />;
          }
        }
        return <TextSection block={item as TextBlock} />;
      case BlockType.Treatment:
        return (
          <TimedActionTaskSection
            block={item as TreatmentTaskBlock}
            type={BlockType.Treatment}
          />
        );
      case BlockType.Miscellaneous:
        return (
          <TimedActionTaskSection
            block={item as MiscellaneousTaskBlock}
            type={BlockType.Miscellaneous}
          />
        );
      case BlockType.Diagnostic:
        return (
          <TimedActionTaskSection
            block={item as DiagnosticTaskBlock}
            type={BlockType.Diagnostic}
          />
        );
      case BlockType.CasualtyStatus:
        return <CasualtyStatusSection block={item as CasualtyStatusBlock} />;
      case BlockType.Medication:
        return (
          <TimedActionTaskSection
            block={item as MedicationTaskBlock}
            type={BlockType.Medication}
          />
        );
      case BlockType.ResearchQuestion:
        return (
          <ResearchQuestionSection block={item as ResearchQuestionBlock} />
        );
      default:
        console.warn(
          "unhandled case in getEditPanel of getItemDisplay in Sublist.tsx",
        );
        return;
    }
  };

  const getReqDisplaySettings = ({
    currPhase,
    isRequired,
    createdPhase,
    requiredInPhaseOffset,
  }: {
    currPhase: number;
    isRequired: boolean;
    createdPhase: number;
    requiredInPhaseOffset: number;
  }) => {
    const phaseIndex = phases.findIndex(
      (phase: Phase) => phase.Id === createdPhase,
    );

    let phaseValue = -1;
    if (isRequired) {
      phaseValue = phaseIndex + requiredInPhaseOffset - currPhase;
    }
    const getValues = (
      background: string,
      border: string,
      criticalIcon: string,
    ) => ({
      background,
      border,
      criticalIcon,
    });
    switch (phaseValue) {
      case 0:
        return getValues("#401010", "1px solid #FF4242", "#FF4242");
      case 1:
        return getValues("#3F2902", "1px solid #FEA40A", "#FEA40A");
      case 2:
        return getValues("#052125", "1px solid #23D0EB", "#23D0EB");
      default:
        return getValues(
          "transparent",
          "1px solid rgba(255, 255, 255, 0.05)",
          "rgba(255, 255, 255, 0.05)",
        );
    }
  };

  // This handles scroll effect after page refresh.
  useEffect(() => {
    const SelectedElementId =
      window.sessionStorage.getItem("SelectedElementId");
    if (SelectedElementId) {
      const restorePageScroll = () => {
        const element = document.getElementById(SelectedElementId);

        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      };

      // Check if the page has already loaded
      if (document.readyState === "complete") {
        restorePageScroll();
      } else {
        window.addEventListener("load", restorePageScroll);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener("load", restorePageScroll);
      }
    }
  }, []);

  return (
    <Droppable droppableId={props.type} type={`droppableSubItem`}>
      {(provided, snapshot) => (
        <SubListDragDiv
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{ minHeight: "2px" }}
        >
          {props.subItems.map((item: Block, subItemMapIndex: any) =>
            !isNullOrUndefined(item.BlockContent) ? (
              <Draggable
                isDragDisabled={isReadOnly}
                key={item.UUID}
                draggableId={`blockItem.${item.UUID}`}
                index={subItemMapIndex}
              >
                {(provided: any, snapshot) => {
                  const { background, border, criticalIcon } =
                    getReqDisplaySettings({
                      currPhase: props.currentPhaseIndex!,
                      isRequired: item.BlockContent!.IsRequired,
                      createdPhase: item.BlockContent!.CreatedInPhaseId,
                      requiredInPhaseOffset:
                        item.BlockContent!.RequiredInPhaseOffset,
                    });

                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onClick={props.selectobject(
                        item,
                        "BLOCK",
                        props.sectionUUID,
                        subItemMapIndex,
                        props.sectionIndex,
                        item,
                      )}
                      onMouseEnter={props.mouseHoveringCB(true)}
                      onMouseLeave={props.mouseHoveringCB(false)}
                    >
                      <div
                        id={`Block.${item.Id}`}
                        style={{ display: "inline-flex", width: "100%" }}
                        className={`subListItem ${updatedSubItems[0].className === "timedActionBlock" ? "timedActionBlock" : ""}`}
                      >
                        {item.BlockType === BlockType.Treatment && (
                          <RequiredTreatmentDiv
                            className="triangle"
                            background={background}
                            border={border}
                            criticalIcon={criticalIcon}
                          >
                            <span className="trianglePosition">
                              {item.BlockContent.IsCritical ? (
                                "!"
                              ) : (
                                <p style={{ visibility: "hidden" }}>!</p>
                              )}
                            </span>
                          </RequiredTreatmentDiv>
                        )}
                        {item.BlockType === BlockType.Miscellaneous && (
                          <RequiredTreatmentDiv
                            className="triangle"
                            background={background}
                            border={border}
                            criticalIcon={criticalIcon}
                          >
                            <span className="trianglePosition">
                              {item.BlockContent.IsCritical ? (
                                "!"
                              ) : (
                                <p style={{ visibility: "hidden" }}>!</p>
                              )}
                            </span>
                          </RequiredTreatmentDiv>
                        )}
                        {item.BlockType === BlockType.Diagnostic && (
                          <RequiredTreatmentDiv
                            className="triangle"
                            background={background}
                            border={border}
                            criticalIcon={criticalIcon}
                          >
                            <span className="trianglePosition">
                              {item.BlockContent.IsCritical ? (
                                "!"
                              ) : (
                                <p style={{ visibility: "hidden" }}>!</p>
                              )}
                            </span>
                          </RequiredTreatmentDiv>
                        )}
                        {item.BlockType === BlockType.Medication && (
                          <RequiredTreatmentDiv
                            className="triangle"
                            background={background}
                            border={border}
                            criticalIcon={criticalIcon}
                          >
                            <span className="trianglePosition">
                              {item.BlockContent.IsCritical ? (
                                "!"
                              ) : (
                                <p style={{ visibility: "hidden" }}>!</p>
                              )}
                            </span>
                          </RequiredTreatmentDiv>
                        )}
                        <SubListItemWrapper>
                          {item.BlockType !== BlockType.Treatment &&
                          item.BlockType !== BlockType.Miscellaneous &&
                          item.BlockType !== BlockType.Diagnostic &&
                          item.BlockType !== BlockType.Medication ? (
                            <SubListItem
                              activeComponent={
                                props.activeComponentIndex === subItemMapIndex
                              }
                            >
                              <DragIcon className="sublist-drag-icon" />
                              {getItemDisplay(item)}
                            </SubListItem>
                          ) : (
                            <TimedActionSubListItem
                              activeComponent={
                                props.activeComponentIndex === subItemMapIndex
                              }
                            >
                              {getItemDisplay(item)}
                            </TimedActionSubListItem>
                          )}
                        </SubListItemWrapper>
                      </div>
                    </div>
                  );
                }}
              </Draggable>
            ) : (
              ""
            ),
          )}
          {provided.placeholder}
        </SubListDragDiv>
      )}
    </Droppable>
  );
};

export default SubList;
