import axios from "axios";

const apiPrefix = "/api/1/pfc/Company";

export function CreateCompany(
  Name: string,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`${apiPrefix}`, {
      CompanyName: Name,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}
export function UpdateCompanyLogo(
  mediaId: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .patch(`${apiPrefix}/update/logo`, {
      mediaId,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}

export function UpdateCompany(
  companyName: string,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .put(`${apiPrefix}`, {
      companyName,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}
