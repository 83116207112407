import "@ant-design/compatible/assets/index.css";
import Icon, { PlusOutlined } from "@ant-design/icons";
import { Row, Spin, Tooltip } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import {
  RightSideTableHeader,
  StyledRow,
} from "../../../../components/Table/CustomTable.styled";
import Button from "../../../../components/Button/Button";
import AddUpdateDrawer from "./AddUpdateDrawer";
import { useFormik } from "formik";
import { FormikErrorType } from "../../../../models/common/FormikErrorType";
import { ReactComponent as Archive } from "../../../../assets/img/Svg/Archive.svg";
import { Switch } from "../../../../components";
import { useDispatch } from "react-redux";
import {
  onCreateSuccess,
  onUpdateSuccess,
  resetDiagnosticForUpdate,
  setAddUpdateModal,
  setData,
  setDiagnosticForUpdate,
  setLoading,
  setModalTitle,
} from "./DiagnosticSlice";
import DiagnosticTable from "./DiagnosticTable";
import {
  createDiagnosticTask,
  getMasterDiagnosticTasks,
  updateDiagnosticTask,
} from "../../../../api/DiagnosticTask";
import {
  CreateDiagnosticTask,
  DiagnosticTask,
  UpdateDiagnosticTask,
} from "../../../../models/DiagnosticTask";
import { useAppSelector } from "../../../../store/hooks";

export default function Diagnostics() {
  const { data, modalTitle, addUpdateModal, loading, diagnosticForUpdate } =
    useAppSelector((state) => state.MasterDiagnosticReducer);
  const dispatch = useDispatch();
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const initialValues = {
    id: 0,
    label: "",
    description: "",
    interval: 0,
  };
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors: FormikErrorType = {};

      // label
      if (!values["label"].trim()) {
        errors["label"] = "Required";
      } else if (values["label"].trim().length > 40) {
        errors["label"] = "Max 40 characters";
      }

      return errors;
    },
    onSubmit: (values: any) => {
      addUpdate();
    },
  });
  useEffect(() => {
    refreshData();
    return () => {
      dispatch(setAddUpdateModal(false));
      dispatch(resetDiagnosticForUpdate(null));
    };
  }, []);
  const refreshData = () => {
    dispatch(setLoading(true));
    getMasterDiagnosticTasks(false, onSuccess, onError);
  };
  const onSuccess = (data: DiagnosticTask[]) => {
    dispatch(
      setData(
        data.sort((a: any, b: any) =>
          new Date(b.CreatedAt).getTime() < new Date(a.CreatedAt).getTime()
            ? -1
            : 1,
        ),
      ),
    );
    dispatch(setLoading(false));
  };
  const onError = (msg: any) => {
    OpenErrorNotification({
      description: msg,
    });
    dispatch(setLoading(false));
  };
  // Reset the form inputs.
  const resetForm = () => {
    setTimeout(() => {
      formik.setValues(initialValues, false);
      formik.setErrors({});
      dispatch(resetDiagnosticForUpdate(null));
    }, 500);
  };
  const onUpdateSuccessCB = (data: DiagnosticTask) => {
    dispatch(onUpdateSuccess(data));
  };
  const onCreateSuccessCB = (data: DiagnosticTask) => {
    dispatch(onCreateSuccess(data));
    dispatch(setDiagnosticForUpdate(data));
    setFormikData(data);
  };

  // Add diagnostic.
  const payload: UpdateDiagnosticTask = {
    ...diagnosticForUpdate,
    Name: formik.values["label"],
    Description: formik.values["description"],
  };
  const addUpdate = () => {
    if (formik.isValid && formik.values.label?.trim().length) {
      // save
      if (formik.values["id"] > 0) {
        // update
        updateDiagnosticTask(payload, onUpdateSuccessCB, onError);
      } else {
        // create
        createDiagnosticTask(
          payload as CreateDiagnosticTask,
          onCreateSuccessCB,
          onError,
        );
      }
      closeAddUpdateModal();
    } else {
      closeAddUpdateModal();
    }
  };

  // Create task before enabling subtasks.
  // This is required because we need task id to add subtasks.
  useEffect(() => {
    if (
      diagnosticForUpdate.IsSubtasksEnabled &&
      diagnosticForUpdate.Id === 0 &&
      formik.isValid
    ) {
      createDiagnosticTask(
        payload as CreateDiagnosticTask,
        onCreateSuccessCB,
        onError,
      );
    }
  }, [diagnosticForUpdate.IsSubtasksEnabled]);

  const closeAddUpdateModal = () => {
    dispatch(setAddUpdateModal(false));
    resetForm();
  };

  // Open view diagnostic details panel with diagnostic data.
  const showDetails = (diagnostic: DiagnosticTask) => {
    dispatch(setModalTitle("View/Edit Diagnostic"));
    setFormikData(diagnostic);
    dispatch(setAddUpdateModal(true));
    dispatch(setDiagnosticForUpdate(diagnostic));
  };
  const setFormikData = (diagnostic: DiagnosticTask) => {
    formik.setValues(
      {
        id: diagnostic.Id || 0,
        label: diagnostic.Name || "",
        description: diagnostic.Description || "",
        interval: diagnostic.IntervalTime || 0,
      },
      false,
    );
  };
  const onEdit = (diagnostic: DiagnosticTask) => {
    dispatch(setModalTitle("Edit Diagnostic"));
    setFormikData(diagnostic);
    dispatch(setAddUpdateModal(true));
    dispatch(setDiagnosticForUpdate(diagnostic));
  };
  const onAdd = () => {
    dispatch(setModalTitle("Add Diagnostic"));
    dispatch(setAddUpdateModal(true));
  };

  const handleSearch = (text?: string) => {
    const filteredDataList = includeArchived
      ? data.filter((x: DiagnosticTask) => x.Name && x.Name.trim().length > 0)
      : data.filter(
          (x: DiagnosticTask) =>
            x.Name && x.Name.trim().length > 0 && !x.IsArchived,
        );
    // Search guard clause
    if (!text) return filteredDataList;
    return filteredDataList;
  };

  return (
    <Spin spinning={loading}>
      <StyledRow justify="space-between">
        <Row className="btn-row"></Row>
        <RightSideTableHeader>
          <Tooltip
            placement="top"
            title={`${
              includeArchived ? "Hide" : "Include"
            } Archived Diagnostics`}
          >
            <div
              className="include-archived-scripts"
              onClick={() => {
                setIncludeArchived(!includeArchived);
              }}
            >
              <button
                className={`archived-scripts ${
                  includeArchived ? "active " : ""
                }`}
              >
                <Icon component={Archive} />
              </button>
              <Switch checked={includeArchived} onChange={() => undefined} />
            </div>
          </Tooltip>
          <Button
            type="primary"
            shape={"rounded"}
            leftIcon={<PlusOutlined />}
            onClick={onAdd}
          >
            Add Diagnostic
          </Button>
        </RightSideTableHeader>
      </StyledRow>
      <DiagnosticTable
        showDetails={showDetails}
        onEdit={onEdit}
        handleSearch={handleSearch}
        searchInput={searchInput}
      />
      <AddUpdateDrawer
        title={modalTitle}
        formik={formik}
        isVisible={addUpdateModal}
        onClickOutside={addUpdate}
        onClose={addUpdate}
      />
    </Spin>
  );
}
