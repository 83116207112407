import { SwapOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import FloatingLabelInput from "../../../../components/FloatingLabel/FloatingLabelInput";
import { FormRow } from "../../User.styled";
type Props = {
  formik: any;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
  handleChange: Function;
  handleBlur: Function;
};
export default function FirstLastNameInput({
  formik,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
  handleChange,
  handleBlur,
}: Props) {
  return (
    <FormRow>
      <Row>
        <Col span={12}>
          <FloatingLabelInput
            label={"First Name"}
            nameForInput={`firstName`}
            type="text"
            value={formik.values[`firstName`]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange("firstName", e.target.value)
            }
            onBlur={handleBlur}
            scriptFormik={formik}
            lastFocused={lastFocused}
            isAddNeeded={false}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            isFocused={isFocused}
          />
        </Col>
        <Col span={12} className="pl-2">
          <FloatingLabelInput
            label={"Last Name"}
            nameForInput={`lastName`}
            type="text"
            value={formik.values[`lastName`]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange("lastName", e.target.value)
            }
            onBlur={handleBlur}
            scriptFormik={formik}
            lastFocused={lastFocused}
            isAddNeeded={false}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            isFocused={isFocused}
          />
        </Col>
      </Row>
    </FormRow>
  );
}
