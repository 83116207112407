export const NumberParameterWithFloatValue = ["dose", "tbsa", "flow rate"];
export const NumberParameterWithDropdown = ["Severity", "MCIS", "Degree"];
export const NumberParameterWithTimeInterval = ["Half Life"];
export const NumberParameterStaticData = [
  {
    Label: "Severity",
    Default: 0.1,
    Minimum: 0,
    Maximum: 1,
  },
  {
    Label: "MCIS",
    Default: 1,
    Minimum: 1,
    Maximum: 5,
  },
  {
    Label: "Half Life",
    Default: 300, // in sec
    Minimum: 1, // in sec
    Maximum: 5400, // in sec
  },
  {
    Label: "Degree",
    Default: 1,
    Minimum: 1,
    Maximum: 6,
  },
];
export const DegreeParameterOptions = [
  {
    Label: "1 - Damage to Epidermis",
    Value: 1,
  },
  {
    Label: "2 - Moderate Damage to Dermis",
    Value: 2,
  },
  {
    Label: "3 - Severe Damage to Dermis",
    Value: 3,
  },
  {
    Label: "4 - Damage down to Fat Layer",
    Value: 4,
  },
  {
    Label: "5 - Damage down to Muscle Layer",
    Value: 5,
  },
  {
    Label: "6 - Damage down to Bone",
    Value: 6,
  },
];
export const TextWithDropdownParameterData = [
  {
    InsultLabel: ["Brain Injury"],
    Label: "Type",
    Default: "Diffuse",
    Options: ["Diffuse", "Left Focal", "Right Focal"],
  },
];
export const BooleanParameterWithSwitch = [
  {
    InsultLabel: ["Burn"],
    Label: "Circumferential",
    Default: false,
  },
];
export const formatTimeParameter = (value: string | number) => {
  console.log("VALUE BBEING SENT", Number(value));
  if (!value) return "00";
  if (Number(value) < 10) return "0" + Number(value);
  return Number(value);
};

/**
 * Gets the surface area value based on the body part and location names.
 *
 * @param {string} bodyPartName - The name of the body part.
 * @param {string} locationName - The name of the location.
 * @returns {number} - The surface area value for the specified body part and location.
 */
export const getSurfaceAreaValue = (
  bodyPartName: string,
  locationName: string,
): number => {
  // Ensure that bodyPartName and locationName are provided and not empty or whitespace
  if (!bodyPartName.trim() || !locationName.trim()) {
    return 0;
  }

  // Array containing surface area values based on body part and location
  const LocationBasedValues = [
    { bodyPart: "Head", location: "-", value: 9 },
    { bodyPart: "Head", location: "Top", value: 4.5 },
    { bodyPart: "Head", location: "Side", value: 4.5 },
    { bodyPart: "Head", location: "Back", value: 4.5 },
    { bodyPart: "Head", location: "Face", value: 4.5 },
    { bodyPart: "Neck", location: "-", value: 4.5 },
    { bodyPart: "Neck", location: "Front", value: 2.5 },
    { bodyPart: "Neck", location: "Back", value: 2.5 },
    { bodyPart: "Neck", location: "Side", value: 2.5 },
    { bodyPart: "Chest", location: "-", value: 9 },
    { bodyPart: "Chest", location: "Upper", value: 4.5 },
    { bodyPart: "Chest", location: "Lower", value: 4.5 },
    { bodyPart: "Abdomen", location: "-", value: 9 },
    { bodyPart: "Pelvis", location: "-", value: 4.5 },
    { bodyPart: "Pelvis", location: "Hip", value: 2.5 },
    { bodyPart: "Pelvis", location: "Groin", value: 1 },
    { bodyPart: "Pelvis", location: "Buttocks", value: 2.5 },
    { bodyPart: "Back", location: "-", value: 18 },
    { bodyPart: "Back", location: "Upper", value: 9 },
    { bodyPart: "Back", location: "Lower", value: 9 },
    { bodyPart: "Arm", location: "-", value: 4.5 },
    { bodyPart: "Arm", location: "Upper", value: 2.5 },
    { bodyPart: "Arm", location: "Lower", value: 2.5 },
    { bodyPart: "Leg", location: "-", value: 9 },
    { bodyPart: "Leg", location: "Upper", value: 4.5 },
    { bodyPart: "Leg", location: "Lower", value: 4.5 },
  ];

  // Find the location entry in the array
  const locationWithValue = LocationBasedValues.find(
    (item) =>
      item.bodyPart.toLowerCase() === bodyPartName.toLowerCase() &&
      item.location.toLowerCase() === locationName.toLowerCase(),
  );

  // Return the surface area value if found, otherwise, return 0
  return locationWithValue ? locationWithValue.value : 0;
};
