import React, { useRef } from "react";
import { MainDiv } from "./ListEditForm.styled";
import {
  EyeInvisibleOutlinedIcons,
  EyeOutlinedIcons,
  PlusCircleOutlinedIcons,
} from "../../../../../IconsTheme/Icons.global";
import { StyledForm } from "../PhaseForm/PhaseForm.styled";
import { FormContainer } from "../RolePlayerForm/PromptForm.styled";
import { useFormik } from "formik";
import FloatingLabelInput from "../../../../../components/FloatingLabel/FloatingLabelInput";
import FloatingLabelTextArea from "../../../../../components/FloatingLabel/FloatingLabelTextArea";
import { WhiteIconButton } from "../../../../../styles/Buttons.styled";
import SwitchWithLabel from "../../../../../components/Switch/SwitchWithLabel";
import { ScriptListBlock } from "../../ListEdit";
import { ScriptList } from "../../../../../models/ScriptInterfaces";
import { InputRef } from "antd";
import { useAppSelector } from "../../../../../store/hooks";
import { useDebounce } from "../../../../../hooks/useDebounce";

interface ListEditFormProps {
  listItem: string; //list item
  title: string;
  handleChange: any;
  handleQuantityChange: any;
  submit: any;
  quantity: number;
  addTitle: any;
  isQuantityNeeded: boolean;
  toggleQuantity: Function;
  isTitleEnabled: boolean;
  toggleTitle: Function;
  object: ScriptListBlock;
  description: string;
  selectedType: any;
  handleChangeSelection: any;
  category: string;
  manufacturerName: string;
  productImage: string;
}

type ErrorType = {
  [key: string]: string;
};
const ListEditForm = (props: ListEditFormProps) => {
  const state = useAppSelector((state: any) => state.MainlistReducer);
  const { isReadOnly } = state;
  const listItemInputRef = useRef<InputRef>(null);
  const focusedStates = {
    title: "",
    listItem: "",
    quantity: "",
    description: "",
    category: "",
    manufacturerName: "",
    productImage: "",
  };
  const [lastFocused, setLastFocused] = React.useState(focusedStates);
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [isQuantityNeeded, setIsQuantityNeeded] = React.useState(
    props.isQuantityNeeded,
  );
  const [isTitleEnabled, setIsTitleEnabled] = React.useState(true);
  const scriptFormik = useFormik({
    initialValues: {
      title: ``,
      listItem: `${props.listItem}`,
      description: "",
      quantity: 0,
      category: "",
      manufacturerName: "",
      productImage: "",
    },
    validate: (values) => {
      const errors: ErrorType = {};

      if (
        !values.listItem.trim() &&
        props.listItem == "" &&
        props.listItem !== scriptFormik.values.listItem
      ) {
        errors["listItem"] = "Required";
      }
      return errors;
    },
    validateOnBlur: true,
    onSubmit: () => {
      props.addTitle();
    },
  });
  const handleQuantityToggle = () => {
    if (isReadOnly) return; //disable action callback in read only script
    setIsQuantityNeeded(!isQuantityNeeded);
    props.toggleQuantity(!isQuantityNeeded);
  };
  const handleHideTitleFunctionality = () => {
    if (isReadOnly) return; //disable action callback in read only script
    setIsTitleEnabled(!isTitleEnabled);
    props.toggleTitle(!isTitleEnabled);
  };

  const addListItem = () => {
    props.submit();
    listItemInputRef.current?.focus();
  };

  React.useEffect(() => {
    setIsTitleEnabled(props.isTitleEnabled);
  }, [props.isTitleEnabled]);

  const debounceSubmitForm = useDebounce(scriptFormik.submitForm);

  React.useEffect(() => {
    if (scriptFormik.isValid) {
      debounceSubmitForm();
    }

    return () => {
      debounceSubmitForm.cancel();
    };
  }, [scriptFormik.isValid, scriptFormik.values]);
  return (
    <MainDiv isQuantityNeeded={isQuantityNeeded}>
      <FormContainer style={{ padding: "0 0.625rem" }}>
        <StyledForm>
          <div className="titleInput">
            <FloatingLabelInput
              autoFocus
              label={"List Title"}
              nameForInput={"title"}
              value={props.title}
              onChange={(e: any) => {
                e.target.value = e.target?.value;
                props.handleChange(e);
              }}
              scriptFormik={scriptFormik}
              lastFocused={lastFocused}
              isAddNeeded={false}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
              onSave={debounceSubmitForm}
              maxLength={70}
              enableLiveCount={true}
              onBlur={debounceSubmitForm}
              readOnly={isReadOnly}
            />
          </div>
          <div className="">
            {isTitleEnabled ? (
              <WhiteIconButton
                type="link"
                icon={<EyeOutlinedIcons className="visibleIcon" />}
                size="middle"
                onClick={handleHideTitleFunctionality}
              >
                Visible
              </WhiteIconButton>
            ) : (
              <WhiteIconButton
                type="link"
                icon={<EyeInvisibleOutlinedIcons />}
                size="middle"
                onClick={handleHideTitleFunctionality}
              >
                Hidden
              </WhiteIconButton>
            )}
          </div>
          <SwitchWithLabel
            rightLabel="Quantity"
            checked={isQuantityNeeded}
            onChange={handleQuantityToggle}
            disabled={isReadOnly}
          />
          {isReadOnly ? (
            <div className="pb-2"></div>
          ) : (
            <div className="addItemInput">
              {(props.object?.BlockContent as ScriptList)
                ?.DisplayedAsTwoColumns ? (
                <FloatingLabelTextArea
                  label={"List Item"}
                  textareaName={"listItem"}
                  value={props.listItem}
                  onChange={props.handleChange}
                  scriptFormik={scriptFormik}
                  lastFocused={lastFocused}
                  setIsFocused={setIsFocused}
                  setLastFocused={setLastFocused}
                  isFocused={isFocused}
                  enableLiveCount={false}
                  onSave={debounceSubmitForm}
                />
              ) : (
                <FloatingLabelTextArea
                  inputRef={listItemInputRef}
                  label={"List Item"}
                  textareaName={"listItem"}
                  value={props.listItem}
                  onChange={props.handleChange}
                  scriptFormik={scriptFormik}
                  lastFocused={lastFocused}
                  setIsFocused={setIsFocused}
                  setLastFocused={setLastFocused}
                  isFocused={isFocused}
                  enableLiveCount={false}
                  onSave={debounceSubmitForm}
                />
              )}
              {isQuantityNeeded && (
                <FloatingLabelInput
                  classNameForContainer="quantity"
                  style={{ marginBottom: "4px" }}
                  label={"Qty"}
                  nameForInput={"quantity"}
                  maxValue={999}
                  minValue={0}
                  maxLength={3}
                  value={Number(props.quantity !== 0 ? props.quantity : "")}
                  onChange={(e: any) =>
                    props.handleQuantityChange(e.target.value)
                  }
                  scriptFormik={scriptFormik}
                  lastFocused={lastFocused}
                  setIsFocused={setIsFocused}
                  setLastFocused={setLastFocused}
                  isFocused={isFocused}
                  isAddNeeded={false}
                  step={1}
                  precision={0}
                  isNumber
                />
              )}
              <div
                onClick={() => props.listItem !== "" && addListItem()}
                className={
                  props.listItem.length > 0
                    ? `addTitleIcon`
                    : `addTitleIcon disabled`
                }
              >
                <PlusCircleOutlinedIcons />
              </div>
            </div>
          )}
          {props.object?.BlockContent.hasOwnProperty("Complication") ||
          props.object?.BlockContent.hasOwnProperty("EquipmentItems") ? (
            <FloatingLabelTextArea
              onChange={props.handleChange}
              scriptFormik={scriptFormik}
              lastFocused={lastFocused}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
              label={"Description"}
              textareaName={"description"}
              value={props.description}
              onSave={debounceSubmitForm}
              autoSize
              maxLength={151}
              objectBlockContent={props.object?.BlockContent.hasOwnProperty(
                "ListItems",
              )}
            />
          ) : null}

          {props.object?.BlockContent.hasOwnProperty("EquipmentItems") ? (
            <FloatingLabelInput
              onChange={props.handleChange}
              scriptFormik={scriptFormik}
              lastFocused={lastFocused}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
              label={"Category"}
              nameForInput={"category"}
              value={props.listItem}
              onSave={debounceSubmitForm}
              isAddNeeded={true}
              suffixIcon={
                props.object?.BlockContent.hasOwnProperty("ListItems") &&
                !scriptFormik.errors["category"] ? (
                  <div onClick={props.submit} className="plusIcon">
                    <PlusCircleOutlinedIcons onClick={props.submit} />
                  </div>
                ) : null
              }
            />
          ) : null}

          {props.object?.BlockContent.hasOwnProperty("EquipmentItems") ? (
            <FloatingLabelInput
              onChange={props.handleChange}
              scriptFormik={scriptFormik}
              lastFocused={lastFocused}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
              label={"Manufacturer Name"}
              nameForInput={"manufacturerName"}
              value={props.manufacturerName}
              onSave={debounceSubmitForm}
              isAddNeeded={true}
              suffixIcon={
                props.object?.BlockContent.hasOwnProperty("ListItems") &&
                !scriptFormik.errors["manufacturerName"] ? (
                  <div onClick={props.submit} className="plusIcon">
                    <PlusCircleOutlinedIcons onClick={props.submit} />
                  </div>
                ) : null
              }
            />
          ) : null}

          {props.object?.BlockContent.hasOwnProperty("EquipmentItems") ? (
            <FloatingLabelInput
              onChange={props.handleChange}
              scriptFormik={scriptFormik}
              lastFocused={lastFocused}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
              label={"Product Image"}
              nameForInput={"productImage"}
              value={props.manufacturerName}
              onSave={debounceSubmitForm}
              isAddNeeded={true}
              autoFocus
              suffixIcon={
                props.object?.BlockContent.hasOwnProperty("ListItems") &&
                !scriptFormik.errors["manufacturerName"] ? (
                  <div onClick={props.submit} className="plusIcon">
                    <PlusCircleOutlinedIcons onClick={props.submit} />
                  </div>
                ) : null
              }
            />
          ) : null}
        </StyledForm>
      </FormContainer>
    </MainDiv>
  );
};

export default ListEditForm;
