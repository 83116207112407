import axios from "axios";
import { defaultErrorCallback } from "./ErrorCallback";
import {
  CreateMedicationTask,
  UpdateMedicationTask,
} from "../models/MedicationTask";

export function getMasterMedicationTasks(
  forSearch: boolean,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .get(`/api/2/pfc/medication/master?forSearch=${forSearch}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function makeMedicationTaskMaster(
  timedActionTaskId: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(`/api/2/pfc/medication/${timedActionTaskId}/master`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function createMedicationTask(
  payload: CreateMedicationTask,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .post(`/api/2/pfc/medication/master`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function updateMedicationTask(
  payload: UpdateMedicationTask,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(`/api/2/pfc/medication/master`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function deleteMedicationTask(
  id: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .delete(`/api/2/pfc/medication/master/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}
