import { SwapOutlined } from "@ant-design/icons";
import { Button } from "antd";
import * as React from "react";
import { FormRow } from "../../styled";
import FloatingLabelInput from "../../../../../../components/FloatingLabel/FloatingLabelInput";
import { convertCmToFeet } from "../CasualtyFormHelperFunctions";
type Props = {
  formik: any;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
  heightUnit: string;
  setHeightUnit: any;
  disabled: boolean;
};

const HeightInput = ({
  formik,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
  heightUnit,
  setHeightUnit,
  disabled,
}: Props) => {
  // handle height unit change
  const swapHeightUnit = () => {
    if (heightUnit == "cm") {
      setHeightUnit("feet");
      const height = Number(formik.values["height"]);
      if (height > 0) {
        const realFeet = Math.floor(height / 30.48);
        const inches = Math.round((height / 30.48 - realFeet) * 12);
        formik.setFieldValue("inch", inches > 0 ? inches : "");
        setTimeout(() => {
          setHeightValue(realFeet > 0 ? realFeet : "");
        }, 10);
      }
      formik.setFieldValue("height", height > 0 ? height : "");
    } else {
      const feet = Number(formik.values["height"]);
      const inches = Number(formik.values["inch"]);
      const valid = +feet >= 0 || +inches >= 0;
      if (valid) {
        const totalInch = feet * 12 + inches;
        const newHeight = Math.round(totalInch * 2.54);
        setHeightValue(newHeight > 0 ? newHeight : "");
        formik.setFieldValue("inch", "");
      }
      setHeightUnit("cm");
    }
  };

  const setHeightValue = (value: number | string) => {
    setTimeout(() => {
      formik.setFieldValue("height", value);
    }, 10);
  };

  const displayMessage = () => {
    if (formik.errors[`height`]) {
      return <span className="error-msg pl-2">{formik.errors[`height`]}</span>;
    } else if (formik.values["height"]) {
      const height = convertCmToFeet(Number(formik.values["height"]));
      const realFeet = height[0];
      const inches = height[1];
      return (
        <span className="pl-2">
          ({`${realFeet} ft${!inches ? "" : ` and ${inches} inch`}`})
        </span>
      );
    } else {
      return null;
    }
  };
  return (
    <>
      <FormRow>
        <div className="height-input">
          <FloatingLabelInput
            label={"Height*"}
            nameForInput={"height"}
            maxValue={250}
            maxLength={3}
            minValue={0}
            suffixIcon={"cm"}
            value={formik.values["height"]}
            onChange={formik.handleChange}
            scriptFormik={formik}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
            lastFocused={lastFocused}
            setLastFocused={setLastFocused}
            isAddNeeded={true}
            step={1}
            displayMinMaxRange={false}
            displayErrorMsg={false}
            precision={0}
            isNumber
            disabled={disabled}
          />
        </div>
        {/* {heightUnit == "feet" && (
          <div className="height-input ml-4">
            <FloatingLabelInputWithNumberStep
              label={""}
              nameForInput={"inch"}
              suffixIcon={"in"}
              maxValue={11}
              minValue={0}
              maxLength={2}
              formik={formik}
              onChange={() => undefined}
              setIsFocused={setIsFocused}
              isFocused={isFocused}
              lastFocused={lastFocused}
              setLastFocused={setLastFocused}
              displayMinMaxRange={false}
              displayErrorMsg={false}
              precision={0}
              step={1}
            />
          </div>
        )}
        <div className="d-inline-block ml-2">
          <Button
            type="link"
            size="large"
            icon={<SwapOutlined />}
            onClick={swapHeightUnit}
          ></Button>
        </div> */}
        {displayMessage()}
      </FormRow>
    </>
  );
};

export default HeightInput;
