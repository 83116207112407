import styled from "styled-components";
import { Switch } from "antd";

export const FormContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 0.625rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .plusIcon {
    color: #ffffff;
    cursor: pointer;
    margin-left: 0.125rem;
    position: absolute;
    right: 5px;
    bottom: 22px;
  }

  .searchIcon {
    position: absolute;
    right: 0;
    bottom: 0;
    color: rgba(30, 182, 205, 255);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxxl}) {
    .plusIcon {
      right: 9px;
      font-size: 1.75rem;
    }

    .searchIcon {
      font-size: 1.75rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxxl}) {
    .plusIcon {
      right: 9px;
      font-size: 1.75rem;
    }

    .searchIcon {
      font-size: 1.75rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    .plusIcon {
      font-size: 1.5625rem;
    }

    .searchIcon {
      font-size: 1.5625rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    .plusIcon {
      font-size: 1.3125rem;
    }

    .searchIcon {
      font-size: 1.3125rem;
    }
  }
`;

export const Form = styled.div`
  height: auto;
  margin-top: 10px;
  position: relative;
  padding: 0.625rem;

  .error-label {
    border-bottom: 2px solid red !important;
  }

  input:focus + .label-name {
    border-bottom: 2px solid rgba(128, 128, 128, 255);
  }

  input:focus + .label-name .content-name,
  input:focus + .label-name .error {
  }

  input:focus + .label-name .content-name {
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  .filled-label {
    border-bottom: 2px solid rgba(128, 128, 128, 255);
  }

  .filled-content {
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  textarea:is(:focus, :valid) + .label-name {
    border-bottom: 2px solid rgba(128, 128, 128, 255);
  }

  textarea:is(:focus, :valid) + .label-name .content-name,
  textarea:is(:focus, :valid) + .label-name .error {
    position: absolute;
    top: 0;
    font-size: 1.6em;
    color: rgba(128, 128, 128, 255);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    input:focus + .label-name .content-name {
      font-size: 1.4em;
      color: rgba(128, 128, 128, 255);
    }

    textarea:is(:focus, :valid) + .label-name .content-name {
      font-size: 1.4em;
      color: rgba(128, 128, 128, 255);
    }

    .filled-label {
      border-bottom: 2px solid rgba(128, 128, 128, 255);
    }

    .filled-content {
      font-size: 1.4em;
      color: rgba(128, 128, 128, 255);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    input:focus + .label-name .content-name {
      font-size: 1.3em;
      color: rgba(128, 128, 128, 255);
    }

    textarea:is(:focus, :valid) + .label-name .content-name {
      font-size: 1.3em;
      color: rgba(128, 128, 128, 255);
    }

    .filled-label {
      border-bottom: 2px solid rgba(128, 128, 128, 255);
    }

    .filled-content {
      font-size: 1.3em;
      color: rgba(128, 128, 128, 255);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    input:focus + .label-name .content-name {
      font-size: 1.3em;
    }

    .filled-content {
      font-size: 1.3em;
    }

    textarea:is(:focus, :valid) + .label-name .content-name {
      font-size: 1.3em;
    }

    .error {
      font-size: 1em;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxxl}) {
    width: 94%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxxl}) {
    width: 91%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
    width: 90%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 89%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 85%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 85%;
  }
`;
export const InputContainer = styled.input`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 255);
  margin-top: 2px;
  font-size: 1.25rem;
  padding-top: 1.25rem;
  border: none;
  margin-left: -0.375rem;
  padding-right: 0.8125rem;

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 1.2em;
  }
`;

export const Label = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  position: absolute;
  bottom: 12px;
  left: 3px;
  right: 12px;
  pointer-events: none;
  border-bottom: 2px solid rgba(96, 96, 96, 255);
  transition: all 0.3s ease;
`;

export const ErrorSpan = styled.span`
  color: red;
  font-size: 1.4em;
  align-items: flex-start;
  position: absolute;
  bottom: 0px;
  right: 0px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 1.2em;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.1em;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 0.9em;
  }
`;

export const LabelSpan = styled.span`
  color: rgba(112, 112, 112, 255);
  font-size: 2em;
  align-items: flex-end;
  position: absolute;
  bottom: 0px;
  left: 0px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 1.7em;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.5em;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 1.3em;
  }
`;

export const MainDiv = styled.div.attrs((props: any) => ({
  isQuantityNeeded: props.isQuantityNeeded,
}))`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  .titleInput {
    display: flex;
    align-items: flex-end;
  }

  .ant-switch-checked:focus {
    box-shadow: none;
  }

  .addItemInput {
    min-height: 65px;
    display: inline-box;

    & > div:nth-child(1) {
      width: ${(props) => (props.isQuantityNeeded ? "76%" : "91%")};
    }

    & > div:nth-child(2) {
      width: ${(props) => (props.isQuantityNeeded ? "14% !important" : "0")};
      margin-left: ${(props) => (props.isQuantityNeeded ? "1%" : "0")};
    }

    & > div:nth-child(3) {
      min-width: 35px;
      width: 9%;
    }
  }

  .quantitySwitch {
    padding: 30px 0;
    display: flex;
    width: min-content;

    &:hover {
      cursor: pointer;

      button {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
      }

      .quantityText {
        color: rgba(255, 255, 255, 1);
        transition: color 0.2s ease-in-out;
      }
    }
  }

  .quantityText {
    margin-left: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: ${(props) =>
      !props.isQuantityNeeded ? "rgba(255, 255, 255, 0.5)" : "#FFFFFF"};
  }

  .addTitleIcon {
    width: 9% !important;
    display: flex;
    align-items: center;
    font-size: clamp(1rem, 24px, 1.4rem);
    justify-content: center;
    padding-top: 26px;
    color: rgba(112, 112, 112, 255);

    &:hover {
      color: #fff;
      cursor: pointer;
    }
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 3.4375rem;
  background-color: rgba(0, 0, 0, 255);
  margin-top: 2px;
  font-size: 1.25rem;
  padding-top: 1.25rem;
  border: none;
  margin-left: -0.375rem;
  padding-right: 0.8125rem;

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 1.2em;
  }
`;
export const ListSwitch = styled(Switch)``;
