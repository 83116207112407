import React from "react";
import { PlusOutlined, RightOutlined } from "@ant-design/icons";
import _, { first, nth } from "lodash";
import { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { getAllWidgets } from "../../api/Dashboard/ApiGet";
import { updateWidgetDimensions } from "../../api/Dashboard/ApiPost";
import Button from "../../components/Button/Button";
import CustomStackedAreaChart from "../../components/Charts/AreaChart/CustomStackedAreaChart";
import CustomHorizontalBarChart from "../../components/Charts/BarChart/CustomHorizontalBarChart";
import CustomVerticalBarChart from "../../components/Charts/BarChart/CustomVerticalBarChart";
import CustomDoughnutChart from "../../components/Charts/DoughnutChart/CustomDoughnutChart";
import CustomPlotLineChart from "../../components/Charts/LineChart/CustomPlotLineChart";
import CustomPieChart from "../../components/Charts/PieChart/CustomPieChart";
import CustomScatterChart from "../../components/Charts/ScatterChart/CustomScatterChart";
import DashboardCard from "../../components/DashboardCard/DashboardCard";
import {
  ChartBorderColors,
  ChartColors,
  graphColors,
} from "../../models/Constants";
import { Widget } from "../../models/DashboardInterface";
import { ChartType } from "../../models/Enums";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { colorPalette } from "../../styles/MainList.styled";
import { ScatterData } from "./DashboardChartData";
import { IDashboardWidget, SetEditWidget } from "./DashboardSlice";
import { GoArrowRight } from "react-icons/go";
import { ArrowRightOutlinedIcon } from "../../IconsTheme/Icons.global";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function Overview() {
  const dispatch = useAppDispatch();
  const dashboardState = useAppSelector((state: any) => state.DashboardReducer);
  const widgets = dashboardState?.widgets || [];
  const [layouts, setLayouts] = useState<{ [index: string]: any[] }>({
    lg: widgets?.map((item: IDashboardWidget) => {
      return item.sm;
    }),
  });
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>("lg");
  const [compactType, setCompactType] = useState<
    "vertical" | "horizontal" | null | undefined
  >("vertical");
  const [mounted, setMounted] = useState(false);
  const [toolbox, setToolbox] = useState<{ [index: string]: any[] }>({
    lg: [],
  });
  const [widgets1, setWidgets1] = useState<Widget[]>([]);

  useEffect(() => {
    getAllWidgets((data: Widget[]) => {
      setWidgets1(data);
    });
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    setLayouts({
      lg: widgets1?.map(({ WidgetData }: Widget) => {
        return {
          i: WidgetData.Id.toString(),
          x: WidgetData.X,
          y: WidgetData.Y,
          w: WidgetData.W,
          h: WidgetData.H,
        };
      }),
    });
  }, [widgets1]);

  const onBreakpointChange = (breakpoint: any) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  function sumXValues(arr: any[]) {
    let sum = 0; // Initialize a variable to store the sum

    for (const item of arr) {
      sum += item.x; // Add the value of "X" for each item to the sum
    }

    return sum; // Return the total sum of "X" values
  }

  const onLayoutChange = (layout: any, layouts: any) => {
    // Do not call update widget api if all the value for x is 0.
    if (layouts?.lg.length && sumXValues(layouts.lg) !== 0) {
      if (layout && layout.length) {
        updateWidgetDimensions(
          layouts.lg.map((layout: any) => ({
            Id: Number(layout.i),
            H: layout.h,
            W: layout.w,
            X: layout.x,
            Y: layout.y,
          })),
          () => undefined,
          () => undefined,
        );
        setLayouts({ ...layouts });
      }
    }
  };

  const onDrop = (layout: any, layoutItem: any, _ev: any) => {
    alert(
      `Element parameters:\n${JSON.stringify(
        layoutItem,
        ["x", "y", "w", "h"],
        2,
      )}`,
    );
  };

  return (
    <ResponsiveReactGridLayout
      className="layout"
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      containerPadding={[0, 0]}
      layouts={layouts}
      measureBeforeMount={false}
      useCSSTransforms={mounted}
      compactType={compactType}
      preventCollision={!compactType}
      onLayoutChange={onLayoutChange}
      onBreakpointChange={onBreakpointChange}
      onDrop={onDrop}
      isDroppable
      resizeHandles={["se"]}
    >
      {/* Remove this commented code, DashboardChartData once the dashboard functionality is fully implemented.*/}
      {/* {widgets?.map((layout: IDashboardWidget, index: number) => {
          return (
            <div key={index}>
              <DashboardCard
                title={layout?.title}
                secondaryText={layout?.secondaryText}
                widget={layout}
              >
                {layout.type === ChartType.HorizontalBar && (
                  <CustomHorizontalBarChart data={BarChartData} />
                )}
                {layout.type === ChartType.VerticalBar && (
                  <CustomVerticalBarChart data={VerticalBarData} />
                )}
                {layout.type === ChartType.PlotLine && (
                  <CustomPlotLineChart data={PlotLineData} />
                )}
                {layout.type === ChartType.StackedArea && (
                  <CustomStackedAreaChart data={StackedAreaData} />
                )}
                {layout.type === ChartType.Pie && (
                  <CustomPieChart data={PieChartData} />
                )}
                {layout.type === ChartType.Scatter && (
                  <CustomScatterChart data={ScatterData} />
                )}
                {layout.type === ChartType.Doughnut && (
                  <CustomDoughnutChart data={DoughnutChartData} />
                )}
              </DashboardCard>
            </div>
          );
        })} */}
      {widgets1?.map(({ WidgetData, QueryResult }: Widget) => (
        <div key={WidgetData.Id} id={WidgetData.Id.toString()}>
          <DashboardCard
            title={WidgetData.Title}
            secondaryText={WidgetData.SecondaryText}
            widget={{
              id: WidgetData.Id,
              type: WidgetData.ChartType,
              title: WidgetData.Title,
              secondaryText: WidgetData.SecondaryText,
              queryParameters: WidgetData.QueryParameters,
              queryBuilderId: WidgetData.QueryBuilderId,
              sm: {
                i: WidgetData.Id.toString(),
                x: WidgetData.X,
                y: WidgetData.Y,
                w: WidgetData.W,
                h: WidgetData.H,
              },
            }}
          >
            {WidgetData.ChartType === ChartType.HorizontalBar && (
              <CustomHorizontalBarChart
                data={{
                  labels: QueryResult.Labels,
                  datasets: QueryResult.Datasets.map((data) => {
                    return {
                      label: data.Label,
                      borderColor: first(graphColors) || "",
                      backgroundColor: first(graphColors) || "",
                      data: data.Data,
                    };
                  }),
                }}
              />
            )}
            {WidgetData.ChartType === ChartType.VerticalBar && (
              <CustomVerticalBarChart
                data={{
                  labels: QueryResult.Labels,
                  datasets: QueryResult.Datasets.map((data) => {
                    return {
                      label: data.Label,
                      borderColor: first(graphColors) || "",
                      backgroundColor: first(graphColors) || "",
                      data: data.Data,
                    };
                  }),
                }}
              />
            )}
            {WidgetData.ChartType === ChartType.PlotLine && (
              <CustomPlotLineChart
                data={{
                  labels: QueryResult.Labels,
                  datasets: QueryResult.Datasets.map((data) => {
                    return {
                      label: data.Label,
                      borderColor: ChartBorderColors.Red,
                      backgroundColor: ChartColors.Red,
                      data: data.Data,
                    };
                  }),
                }}
              />
            )}
            {WidgetData.ChartType === ChartType.Pie && (
              <CustomPieChart
                data={{
                  labels: QueryResult.Labels,
                  datasets: QueryResult.Datasets.map((data) => {
                    return {
                      label: data.Label,
                      borderColor: QueryResult.Labels.map(
                        (label, idx) => nth(graphColors, idx) || "",
                      ),
                      backgroundColor: QueryResult.Labels.map(
                        (label, idx) => nth(graphColors, idx) || "",
                      ),
                      borderWidth: 1,
                      data: data.Data,
                    };
                  }),
                }}
              />
            )}
            {WidgetData.ChartType === ChartType.Doughnut && (
              <CustomDoughnutChart
                data={{
                  labels: QueryResult.Labels,
                  datasets: QueryResult.Datasets.map((data) => {
                    return {
                      label: data.Label,
                      borderColor: QueryResult.Labels.map(
                        (label, idx) => nth(graphColors, idx) || "",
                      ),
                      backgroundColor: QueryResult.Labels.map(
                        (label, idx) => nth(graphColors, idx) || "",
                      ),
                      data: data.Data,
                      borderWidth: 1,
                    };
                  }),
                }}
              />
            )}
            {WidgetData.ChartType === ChartType.StackedArea && (
              <CustomStackedAreaChart
                data={{
                  labels: QueryResult.Labels,
                  datasets: QueryResult.Datasets.map((data) => {
                    return {
                      label: data.Label,
                      borderColor: first(graphColors) || "",
                      backgroundColor: first(graphColors) || "",
                      data: data.Data,
                      fill: true,
                    };
                  }),
                }}
              />
            )}
            {WidgetData.ChartType === ChartType.Scatter && (
              <CustomScatterChart data={ScatterData} />
            )}
          </DashboardCard>
        </div>
      ))}
    </ResponsiveReactGridLayout>
  );
}
