import * as React from "react";
import { Modal, Spin } from "antd";
import { Block } from "../../../models/ScriptInterfaces";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Container,
  FixedDiv,
  ScrollableDiv,
} from "../../../styles/EditComponentTheme.styled";
import { TextBlock } from "./TextEdit";
import { UpdateTextBlock } from "../../../api/Block/ApiPost";
import produce from "immer";
import { SetPhases } from "../MainlistSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useState } from "react";
import TopFixedHeaderWithUploadIcon from "./EditForms/RolePlayerForm/TopFixedHeaderWithUploadIcon";
import UploadedAudioList from "./EditForms/RolePlayerForm/UploadedAudioList";
import WhatToSayForm from "./EditForms/RolePlayerForm/WhatToSayForm";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";

interface Props {
  object: TextBlock;
  onChange: Function;
  onUpdate: Function;
  onSave: any; //Function
  onDelete: any; //Function
  onCopy: any; //Function,
}

const SupportActionEdit = (props: Props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const state = useAppSelector((state) => state.MainlistReducer);
  const { currentPhaseIndex, currentSectionIndex, currentBlockIndex } =
    useAppSelector((state) => state.MainlistReducer);
  const phases = state.phases;
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  const onDeleteConfirm = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "Are you sure you want to delete this Block?",
      content: "This cannot be undone",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        props.onDelete(props.object);
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        console.log("Cancel");
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };

  const onCopyConfirm = () => {
    props.onCopy(props.object);
    setIsOpenConfirmDialog(false);
  };

  const onBlockWithAudioUpdate = (audioFromBE: any) => {
    setLoading(false);
    const textBlockToUpddate = phases![currentPhaseIndex]!.Sections![
      currentSectionIndex
    ].Blocks![currentBlockIndex] as Block;
    const textBlockPayload = {
      UUID: textBlockToUpddate.UUID,
      Id: textBlockToUpddate.Id,
      SectionId: textBlockToUpddate.SectionId,
      Order: textBlockToUpddate.Order,
      BlockType: textBlockToUpddate.BlockType,
      BlockContent: {
        Id: textBlockToUpddate.BlockContent.Id,
        BlockId: textBlockToUpddate.BlockContent.BlockId,
        Description: textBlockToUpddate.BlockContent.Description,
        Title: textBlockToUpddate.BlockContent.Title,
        DisplayedAsTwoColumns:
          textBlockToUpddate.BlockContent.DisplayedAsTwoColumns,
        IsImportant: textBlockToUpddate.BlockContent.IsImportant,
        IsTitleEnabled: textBlockToUpddate.BlockContent.IsTitleEnabled,
        StandInType: textBlockToUpddate.BlockContent.StandInType,
        UploadedMediaItems: [audioFromBE],
      },
    };
    onTextBlockUpdate(textBlockPayload);
  };
  const onTextBlockUpdate = (textBlockPayload: any) => {
    UpdateTextBlock(
      textBlockPayload,
      (TextBlockFromBe: TextBlock) => {
        const newPhases = produce(phases, (draftPhases) => {
          draftPhases![currentPhaseIndex].Sections![
            currentSectionIndex
          ].Blocks![currentBlockIndex] = TextBlockFromBe;
        });

        dispatch(SetPhases(newPhases));
      },
      () => undefined,
    );
  };

  const DeleteAudioSuccess = (data: any) => {
    onTextBlockUpdate(data);
  };

  return (
    <Container>
      <Spin spinning={loading}>
        <FixedDiv phase={false}>
          <TopFixedHeaderWithUploadIcon
            type={"action"}
            loading={loading}
            setLoading={setLoading}
            object={props.object}
            onCopyClick={onCopyConfirm}
            onDeleteClick={onDeleteConfirm}
            updateBlockWithAudio={onBlockWithAudioUpdate}
          />
        </FixedDiv>

        <ScrollableDiv
          id="scrollableDiv"
          phase={false}
          style={{ height: `calc(100vh - 122px)` }}
        >
          <WhatToSayForm
            type={"SupportAction"}
            setLoading={setLoading}
            object={props.object?.BlockContent}
            onChange={props.onChange}
            onSave={() => {
              props.onSave();
            }}
            updateBlockWithAudio={onBlockWithAudioUpdate}
          />

          <UploadedAudioList
            setLoading={setLoading}
            object={props.object?.BlockContent.UploadedMediaItems}
            onDeleteAudioSuccess={DeleteAudioSuccess}
          />
        </ScrollableDiv>
      </Spin>
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </Container>
  );
};
export default SupportActionEdit;
