import {
  BodyPart,
  BooleanParameter,
  InsultWithRelation,
} from "../../../../../models/InsultInterface";
import { Location } from "../../../../../models/InsultInterface";

// Hide location form UI and location param for below insults.
export const hideLocationUIForInsults = [
  "acute respiratory distress",
  "airway obstruction",
  "apnea",
  "asthma attack",
  "bronchoconstriction",
  "tension pneumothorax",
  "pericardial effusion",
  "cardiac arrest",
  "acute stress",
  "brain injury",
  "radiation",
];

/**
 * Gets the display value for the label of an insult based on insult type.
 *
 * @param {InsultWithRelation} insult - The insult object.
 * @returns {string} - The formatted display value for the insult label.
 */
export const getInsultLabelDisplayValue = (
  insult: InsultWithRelation,
  numberParamValue?: number,
) => {
  if (insult.Label?.toLocaleLowerCase() === "burn") {
    let burnDegree = insult.NumberParameters?.find(
      (n) => n.UserFacingLabel.toLocaleLowerCase() === "degree",
    )?.ValueToApply;

    if (!burnDegree || (numberParamValue && burnDegree !== numberParamValue)) {
      burnDegree = numberParamValue;
    }

    switch (burnDegree) {
      case 1:
        return `${insult.Label} - 1st degree`;
      case 2:
        return `${insult.Label} - 2nd degree`;
      case 3:
        return `${insult.Label} - 3rd degree`;
      case 4:
      case 5:
      case 6:
        return `${insult.Label} - ${burnDegree}th degree`;
      default:
        return insult.Label;
    }
  }
  return insult.Label;
};

/**
 * Determines the locations based on insult type, selected body part, and location details.
 *
 * @param {InsultWithRelation | undefined} insult - The insult object with label and relation.
 * @param {BodyPart | undefined} selectedBodyPart - The selected body part object.
 * @returns {Location[]} - The filtered array of locations based on insult and body part.
 */
export const getLocationsBasedOnInsult = (
  insult: InsultWithRelation | undefined,
  selectedBodyPart: BodyPart | undefined,
): Location[] => {
  const locations = selectedBodyPart?.Locations || [];

  // Helper function to filter locations based on detail
  const filterLocationsByDetail = (
    ...details: (string | ((detail: string) => boolean))[]
  ): Location[] =>
    locations.filter((location) => {
      const detailLowerCase = location.Detail?.toLocaleLowerCase() || "";
      return details.some((detail) =>
        typeof detail === "string"
          ? detail === detailLowerCase
          : detail(detailLowerCase),
      );
    }) || [];

  // Hemorrhage (External), Contusion
  if (
    insult?.Label?.toLowerCase() === "hemorrhage (external)" ||
    insult?.Label?.toLowerCase() === "contusion"
  ) {
    if (selectedBodyPart?.Name?.toLowerCase() === "head") {
      return filterLocationsByDetail((detail) => detail !== "brain");
    } else if (selectedBodyPart?.Name?.toLowerCase() === "chest") {
      return filterLocationsByDetail((detail) =>
        ["-", "upper", "lower"].includes(detail),
      );
    } else if (selectedBodyPart?.Name?.toLowerCase() === "abdomen") {
      return filterLocationsByDetail("-");
    }
  }

  // Hemorrhage (Internal)
  else if (insult?.Label?.toLowerCase() === "hemorrhage (internal)") {
    if (selectedBodyPart?.Name?.toLowerCase() === "head") {
      return filterLocationsByDetail("brain");
    } else if (selectedBodyPart?.Name?.toLowerCase() === "chest") {
      return filterLocationsByDetail("lung", "heart", "aorta", "vena cava");
    } else if (selectedBodyPart?.Name?.toLowerCase() === "abdomen") {
      return filterLocationsByDetail(
        (detail) => !["-", "stomach", "pancreas", "bladder"].includes(detail),
      );
    }
  }

  // Burn
  else if (insult?.Label?.toLowerCase() === "burn") {
    if (selectedBodyPart?.Name?.toLowerCase() === "head") {
      return filterLocationsByDetail((detail) => detail !== "brain");
    } else if (selectedBodyPart?.Name?.toLowerCase() === "chest") {
      return filterLocationsByDetail("-", "upper", "lower");
    } else if (selectedBodyPart?.Name?.toLowerCase() === "abdomen") {
      return filterLocationsByDetail("-");
    }
  }

  // Infection
  else if (insult?.Label?.toLowerCase() === "infection") {
    if (selectedBodyPart?.Name?.toLowerCase() === "head") {
      return filterLocationsByDetail((detail) => detail !== "brain");
    } else if (selectedBodyPart?.Name?.toLowerCase() === "chest") {
      return filterLocationsByDetail("-", "upper", "lower");
    } else if (selectedBodyPart?.Name?.toLowerCase() === "abdomen") {
      return filterLocationsByDetail("-", "large intestine", "small intestine");
    }
  }

  // Pain
  else if (insult?.Label?.toLowerCase() === "pain") {
    if (selectedBodyPart?.Name?.toLowerCase() === "chest") {
      return filterLocationsByDetail("-", "upper", "lower");
    } else if (selectedBodyPart?.Name?.toLowerCase() === "abdomen") {
      return filterLocationsByDetail("-");
    } else if (selectedBodyPart?.Name?.toLowerCase() === "head") {
      return filterLocationsByDetail((detail) => detail !== "brain");
    }
  }

  // Brain Injury
  else if (
    insult?.Label?.toLowerCase() === "brain injury" &&
    selectedBodyPart?.Name?.toLowerCase() === "head"
  ) {
    return filterLocationsByDetail("brain");
  }

  // Acute Stress
  else if (
    insult?.Label?.toLowerCase() === "acute stress" &&
    selectedBodyPart?.Name?.toLowerCase() === "head"
  ) {
    return filterLocationsByDetail("top");
  }

  // Acute Respiratory Distress
  else if (
    insult?.Label?.toLowerCase() === "acute respiratory distress" &&
    selectedBodyPart?.Name?.toLowerCase() === "chest"
  ) {
    return filterLocationsByDetail("upper");
  }

  // Airway Obstruction, Apnea, Asthma Attack, Bronchoconstriction
  else if (
    [
      "airway obstruction",
      "apnea",
      "asthma attack",
      "bronchoconstriction",
    ].includes(insult?.Label?.toLowerCase() || "") &&
    selectedBodyPart?.Name?.toLowerCase() === "neck"
  ) {
    return filterLocationsByDetail("front");
  }

  // Tension Pneumothorax
  else if (
    insult?.Label?.toLowerCase() === "tension pneumothorax" &&
    selectedBodyPart?.Name?.toLowerCase() === "chest"
  ) {
    return filterLocationsByDetail("lung");
  }

  // Cardiac Arrest, Pericardial Effusion
  else if (
    ["cardiac arrest", "pericardial effusion"].includes(
      insult?.Label?.toLowerCase() || "",
    ) &&
    selectedBodyPart?.Name?.toLowerCase() === "chest"
  ) {
    return filterLocationsByDetail("heart");
  }

  // Default: return all locations if no specific conditions are met
  return locations;
};
