import * as React from "react";
import { message, Tooltip } from "antd";
import { FooterWithButtons, GreenButton, GreyButton } from "../styled";
import { StyledBody } from "./StyledComponents/StyledBody.styled";
import { CreateMasterCasualtyFromCasualty } from "../../../../../api/Casualty/ApiPost";

type CreateMasterCasualtyProps = {
  casualtyId: number;
  onMasterCasualtyCreate: Function;
  onCancel: Function;
};

const CreateMasterCasualty = ({
  casualtyId,
  onMasterCasualtyCreate,
  onCancel,
}: CreateMasterCasualtyProps) => {
  const onSuccess = (data: any) => {
    message.success("Casualty has been added as master casualty");
    onMasterCasualtyCreate(data);
  };
  const onError = () => {
    console.log("onError");
  };
  const onSave = () => {
    // save casualty to master
    CreateMasterCasualtyFromCasualty(casualtyId, onSuccess, onError);
  };
  return (
    <>
      <StyledBody>
        <p> Do you want to add this Casualty to your Master Casualty list?</p>
      </StyledBody>
      <FooterWithButtons>
        <Tooltip title="Back to Casualty List">
          <GreyButton htmlType="submit" onClick={() => onCancel()}>
            No
          </GreyButton>
        </Tooltip>
        <GreenButton htmlType="submit" onClick={onSave}>
          Yes
        </GreenButton>
      </FooterWithButtons>
    </>
  );
};

export default CreateMasterCasualty;
