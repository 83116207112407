import { Row } from "antd";
import styled from "styled-components";
import FloatingLabelSelect from "../../../../../components/MuiSelect/FloatingLabelSelect";

interface QueryQuestionRowProps {
  show: boolean;
}
export const QueryQuestionRow = styled(Row)<QueryQuestionRowProps>`
  display: ${(props) => (props.show ? "" : "none")};
  .question {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .number {
      width: 6rem;
    }
  }
`;

export const QueryQuestionSelect = styled(FloatingLabelSelect)`
  padding-top: 3.5px;
  .ant-select-single .ant-select-selection-item {
    padding: 4px 18px 0px 0px;
  }
`;
