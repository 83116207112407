import React, { ChangeEventHandler, useEffect, useState } from "react";
import AuthenticationPageLeftPane from "../../../components/CenterScreenCard/AuthenticationPageLeftPane";
import { Form, Row } from "antd";
import { FormRow } from "../../ScriptBuilder/EditComponents/ScriptDetailsView/styled";
import FloatingLabelInput from "../../../components/FloatingLabel/FloatingLabelInput";
import { useFormik } from "formik";
import { FormikErrorType } from "../../../models/common/FormikErrorType";
import { BlueButton } from "../../../styles/Buttons.styled";
import { ArrowRightOutlined } from "@ant-design/icons";
import { CreateCompanyContainer } from "./SignUp.styled";
import { Company, CompanyCheck } from "../../../models/Company";
import { OpenErrorNotification } from "../../../components/Notification/Notification";
import { CreateCompany } from "../../../api/Company/ApiPost";
import { useHistory } from "react-router";
import AlertBar from "../../../app/AlertBar";
import { useDebounce } from "../../../hooks/useDebounce";
import { checkIfCompanyExist } from "../../../api/Auth";

const CreateCompanyFrom = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<CompanyCheck | null>(null);

  const focusedStates = {
    companyName: false,
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);

  const formik = useFormik({
    initialValues: {
      companyName: "",
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      if (!values.companyName.trim()) {
        errors["companyName"] = "Required";
      }

      return errors;
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      setLoading(true);
      if (company) {
        history.push(
          `/company/request-access?n=${company.Name}&c=${btoa(company.Id)}`,
        );
      } else {
        history.push(`/user/sign-up?c=${values.companyName}`);
      }
    },
  });

  const handleSearchCompany = () => {
    checkIfCompanyExist(
      formik.values.companyName,
      (data: CompanyCheck) => {
        setCompany(data);
      },
      (error: any) => {
        if (error.ErrorCode !== 404) {
          OpenErrorNotification({ description: error.ErrorMessage });
        }
        setCompany(null);
      },
    );
  };

  const debounceCompanySearch = useDebounce(handleSearchCompany);

  useEffect(() => {
    if (formik.isValid) {
      debounceCompanySearch();
    }

    return () => {
      debounceCompanySearch.cancel();
    };
  }, [formik.values]);

  return (
    <AuthenticationPageLeftPane title="Create your account" displayLoginLink>
      <AlertBar />
      <CreateCompanyContainer>
        <Form onFinish={formik.handleSubmit}>
          <FormRow>
            <FloatingLabelInput
              label={"Company Name*"}
              nameForInput={"companyName"}
              value={formik.values.companyName}
              onChange={formik.handleChange}
              scriptFormik={formik}
              lastFocused={lastFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
              setIsFocused={setIsFocused}
              isAddNeeded={false}
            />
          </FormRow>
          <Row justify="end" align="middle">
            {company ? (
              <BlueButton
                rounded={true}
                htmlType="submit"
                className="l2l-btn login-btn"
                loading={loading}
              >
                Request Access <ArrowRightOutlined />
              </BlueButton>
            ) : (
              <BlueButton
                disabled={!formik.isValid}
                rounded={true}
                htmlType="submit"
                className="l2l-btn login-btn"
                loading={loading}
              >
                Next <ArrowRightOutlined />
              </BlueButton>
            )}
          </Row>
          {company && (
            <FormRow className="message-container">
              <h4 className="message-heading">ADDITIONAL OPTIONS:</h4>
              <span className="message">
                You may have already received an email invitation to join the
                company account. Check your Junk and Spam folders.
                <br />
                <br />
                Reach out to{" "}
                <a className="email" href="mailto:test@email.com">
                  {company.OwnerEmail}
                </a>{" "}
                with questions.
                <br />
                <br />
                Use a unique company name to create a new account.
              </span>
            </FormRow>
          )}
        </Form>
      </CreateCompanyContainer>
    </AuthenticationPageLeftPane>
  );
};

export default CreateCompanyFrom;
