import styled from "styled-components";

export const LabelDiv = styled.div.attrs((props: any) => ({
  color: props.color,
  isLabelClickable: props.isLabelClickable,
}))`
  height: 1.89rem;
  width: fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  overflow: hidden;
  white-space: nowrap;
  border: ${(props) => `1px solid ${props.color}`};
  cursor: ${(props) => (props.isLabelClickable ? "pointer" : "")};

  span {
    color: ${(props) => props.color};
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    font-size: clamp(0.5rem, 1vw, 1.125rem);
    padding: 0.5rem;
  }
`;

export const LabelContainerDiv = styled.div`
  &:hover {
    .injury-insult {
      visibility: visible;
    }
  }
`;
