import {
  BriefPhaseHeader,
  DropzoneDivStyle,
  RightIconButton,
  SectionCol,
  SectionDivStyle,
  SectionLabel,
  StyledTabPane,
  TabStyle,
  ToolTipStyled,
} from "../../../../styles/MainList.styled";
import {
  RemoveClickEventHandlerForReadOnlyInput,
  isNullOrUndefined,
} from "../../../../utils/utilityfunctions";
import {
  AddBlockIcons,
  ArrowRightOutlinedIcon,
  ClockCircleOutlinedIcon,
  DragIcon,
  PhasePlusOutlinedIcons,
  PlusOutlinedIcons,
} from "../../../../IconsTheme/Icons.global";
import { v4 as uuidv4 } from "uuid";
import {
  ComponentEdit,
  SetActiveComponent,
  SetActiveKey,
  SetAddBlockShowingId,
  SetCurrentBlockIndex,
  SetCurrentPhaseId,
  SetCurrentPhaseIndex,
  SetCurrentSectionIndex,
  SetIsTimedSection,
  SetPhases,
} from "../../MainlistSlice";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  Block,
  CasualtyStatus,
  CriticalAction,
  Diagnostic,
  EquipmentItem,
  ListItem,
  Medication,
  Phase,
  ResearchQuestion,
  RolePlayerPrompt,
  ScriptEquipmentList,
  ScriptList,
  ScriptRolePlayerPrompt,
  Section,
  SupportAction,
  Text,
  Treatment,
} from "../../../../models/ScriptInterfaces";
import { createPhaseWithBlankTitleAndSection } from "../../../../api/Phases/ApiPost";
import { PhaseWithRelationsDtm } from "../../../../models/common/PhaseWithRelations";
import { SectionWithoutRelationsDtm } from "../../../../models/common/SectionWithoutRelationsDtm";
import { Draggable, Droppable } from "react-beautiful-dnd";
import produce from "immer";
import { BlockType } from "../../../../models/Enums";
import "../../../../css/custom.css";
import "../../SideBar.scss";
import { formatTimeParameter } from "../ScriptDetailsView/InjuryTab/CommonData";
import { Tooltip } from "antd";
import {
  EditOutlined,
  FrownOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import {
  CreateCasualtyStatusBlock,
  CreateScriptListItems,
  CreateTextBlock,
  UpdateBlockOrder,
} from "../../../../api/Block/ApiPost";
import {
  createCasualtyStatus,
  createCriticalAction,
  createDiagnostic,
  createMedication,
  createResearchQuestion,
  createScriptEquipmentList,
  createScriptList,
  createScriptRolePlayerPrompt,
  createSupportAction,
  createText,
  createTreatment,
} from "../../CreateDefaultBlockObjects";
import { CreateSection } from "../../../../api/Section/ApiPost";
import "../../../../css/custom.css";
import { CreateResearchQuestionBlock } from "../../../../api/ResearchQuestionBlock/ApiPost";
import { useHistory } from "react-router";
import { SetIsEditingAllowed } from "../ResearchQuestion/ResearchQuestionSlice";
import { MiscellaneousTask } from "../../../../models/MiscellaneousTask";
import { createMiscellaneousTaskBlock } from "../../../../api/MiscellaneousTaskBlock";
import {
  CreateMiscellaneousTaskBlock,
  MiscellaneousTaskBlock,
} from "../../../../models/MiscellaneousTaskBlock";
import { createDiagnosticTaskBlock } from "../../../../api/DiagnosticTaskBlock";
import {
  CreateDiagnosticTaskBlock,
  DiagnosticTaskBlock,
} from "../../../../models/DiagnosticTaskBlock";
import { DiagnosticTask } from "../../../../models/DiagnosticTask";
import { createTreatmentTaskBlock } from "../../../../api/TreatmentTaskBlock";
import { CreateTreatmentTaskBlock } from "../../../../models/TreatmentTaskBlock";
import { createMedicationTaskBlock } from "../../../../api/MedicationTaskBlock";
import {
  CreateMedicationTaskBlock,
  MedicationTaskBlock,
} from "../../../../models/MedicationTaskBlock";
import { MedicationTask } from "../../../../models/MedicationTask";

interface CenterProps {
  children: any;
  postError: any;
  getEditPanel: Function;
  mouseHoveringBlock: boolean;
  handleMouseHover: Function;
  loadingToggle: Function;
  showEditCasualtyPane: Function;
}

export const ScriptBuilderCenter = (props: CenterProps) => {
  const [columnType] = useState<string>("");
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    scriptId,
    activeKey,
    currentPhaseIndex,
    currentPhaseId,
    activeComponent,
    currentSectionIndex,
    activeDragContentType,
    collapse,
    addBlockShowingId,
    isReadOnly,
  } = useAppSelector((state: any) => state.MainlistReducer);
  const state = useAppSelector((state: any) => state.MainlistReducer);
  const phases: Phase[] = state.phases;

  const selectobject =
    (
      object: Block | Section,
      objType: string,
      sectionUUID: string,
      index: number,
      sectionIndex?: number,
      blockItem?: Block,
    ) =>
    (e: any) => {
      if (isNullOrUndefined(sectionIndex)) {
        sectionIndex = 0;
      }
      const isSectionCollapsed =
        phases![currentPhaseIndex].Sections![sectionIndex].Collapsed;
      e.stopPropagation();

      dispatch(SetAddBlockShowingId(sectionUUID)); // to enable add block tray to add new blocks

      switch (objType) {
        case "BLOCK":
          // eslint-disable-next-line no-case-declarations
          let component = ComponentEdit.SECTION;

          switch (
            phases![currentPhaseIndex].Sections![sectionIndex].Blocks![index]
              .BlockType
          ) {
            case BlockType.CriticalAction:
              component = ComponentEdit.MEDICATION;
              break;

            case BlockType.ScriptEquipmentList:
              component = ComponentEdit.LIST;
              break;

            case BlockType.ScriptList:
              component = ComponentEdit.LIST;
              break;

            case BlockType.Text:
              if (
                !isNullOrUndefined(blockItem) &&
                "StandInType" in blockItem!.BlockContent!
              ) {
                if (
                  blockItem.BlockContent!.StandInType === "role player prompt"
                ) {
                  component = ComponentEdit.RPPROMPT;
                  break;
                } else if (
                  blockItem.BlockContent!.StandInType === "support action"
                ) {
                  component = ComponentEdit.SUPPORTACTION;
                  break;
                } else {
                  component = ComponentEdit.TEXT;
                  break;
                }
              }
              break;
            case BlockType.Casualty:
              component = ComponentEdit.CASUALTY;
              break;
            case BlockType.CasualtyStatus:
              component = ComponentEdit.CASUALTYSTATUS;
              break;

            case BlockType.Treatment:
              component = ComponentEdit.TREATMENT;
              break;
            case BlockType.Miscellaneous:
              component = ComponentEdit.MISCELLANEOUS;
              break;
            case BlockType.Diagnostic:
              component = ComponentEdit.DIAGNOSTIC;
              break;
            case BlockType.Medication:
              component = ComponentEdit.MEDICATION;
              break;
            case BlockType.ResearchQuestion:
              component = ComponentEdit.RESEARCHQUESTION;
              break;
            default:
              break;
          }
          window.sessionStorage.setItem(
            "SelectedElementId",
            `Block.${blockItem?.Id}`,
          );
          dispatch(SetCurrentBlockIndex(index));
          dispatch(SetCurrentSectionIndex(sectionIndex));
          dispatch(SetActiveComponent(component));
          updateTimedActionMenuStatusBasedOnSection(
            phases![currentPhaseIndex].Sections![sectionIndex]!,
          );
          props.getEditPanel();
          return;

        case "SECTION":
          window.sessionStorage.setItem(
            "SelectedElementId",
            `Section.${object?.Id}`,
          );
          updateTimedActionMenuStatusBasedOnSection(object as Section);
          dispatch(SetCurrentSectionIndex(index));
          dispatch(SetActiveComponent(ComponentEdit.SECTION));
          return;

        default:
          return;
      }
    };
  const updateTimedActionMenuStatusBasedOnSection = (section: Section) => {
    if (section?.IsCriticalActionRequired) {
      dispatch(SetIsTimedSection(true));
    } else {
      dispatch(SetIsTimedSection(false));
    }
  };
  const PhaseWithoutRelationsDtmSuccess =
    (activeKey: string) => async (phaseIn: PhaseWithRelationsDtm) => {
      try {
        console.warn("phaseIn is:");
        console.warn(phaseIn);
        const data: Phase = {
          UUID: activeKey,
          Id: phaseIn.Id,
          Sections: [],
          Description: phaseIn.Description,
          Order: phaseIn.Order,
          ScriptId: phaseIn.ScriptId,
          TitleAbbreviated: phaseIn.TitleAbbreviated,
          TitleLong: phaseIn.TitleLong,
          TotalTimeCalculated: phaseIn.TotalTimeCalculated,
          IsScriptBrief: phaseIn.IsScriptBrief,
          EstimatedTimeHours: phaseIn.EstimatedTimeHours,
          EstimatedTimeMinutes: phaseIn.EstimatedTimeMinutes,
          Tags: [],
        };

        const sectionDtm: SectionWithoutRelationsDtm = phaseIn.Sections[0];

        const theFirstSection: Section = {
          UUID: uuidv4(),
          Id: sectionDtm.Id,
          PhaseId: sectionDtm.PhaseId,
          // IsCriticalActionRequired: sectionDtm.I
          // IsAARRequired?: boolean;
          // TotalTimeCalculated?: number;
          // Ther is no need to set the phase property.
          Order: sectionDtm.Order,
          Title: sectionDtm.Title,
          TotalTimeCalculated: sectionDtm.TotalTimeCalculated,
          Blocks: [],
          Collapsed: false,
          SectionType: "normal",
        };

        data?.Sections?.push(theFirstSection);

        const newPhases = produce(phases, (draftPhases: Phase[]) => {
          draftPhases.push(data);
        });
        dispatch(SetPhases(newPhases as unknown as Phase[]));
        const newPhaseIndex = newPhases.length - 1;
        dispatch(SetActiveComponent(ComponentEdit.PHASE));
        dispatch(SetCurrentSectionIndex(0));
        dispatch(SetCurrentPhaseIndex(newPhaseIndex));
        dispatch(SetCurrentPhaseId(newPhases[newPhaseIndex]?.Id || 0));
        dispatch(SetActiveKey(activeKey));
      } catch (error) {
        console.log(
          "Something went wrong reading the data from creating a phase with a section: " +
            error,
        );
      }
    };
  const add = () => {
    const activeKey = uuidv4();

    // add a phase with a title like Phase 1, Phase 2, etc.
    createPhaseWithBlankTitleAndSection(
      { ScriptId: scriptId },
      PhaseWithoutRelationsDtmSuccess(activeKey),
      props.postError,
    );
  };
  const onTabChange = (activeKey: string) => {
    if (activeKey === "edit-details") {
      handleNavigateToScriptDetails();
    } else if (activeKey === "edit-casualty") {
      props.showEditCasualtyPane();
    } else {
      const currentPhaseIndex = phases.findIndex((x) => x.UUID === activeKey);
      dispatch(SetActiveKey(activeKey));
      dispatch(SetCurrentSectionIndex(0));
      dispatch(SetCurrentBlockIndex(0));
      dispatch(SetAddBlockShowingId("")); // to enable add block tray to add new blocks
      dispatch(SetCurrentPhaseIndex(currentPhaseIndex));
      dispatch(SetCurrentPhaseId(phases[currentPhaseIndex]?.Id || 0));
      dispatch(SetActiveComponent(ComponentEdit.PHASE));
    }
  };

  const onTabEdit = (targetKey: any, action: any) => {
    if (action === "add") {
      if (isReadOnly) return; //disable action callback in read only script
      add();
    }
  };
  const MyButton = () => {
    return (
      <div className={`add-phase-btn ${isReadOnly ? "disabled" : ""}`}>
        <PhasePlusOutlinedIcons />
      </div>
    );
  };

  const createBlock = (
    SectionId: number,
    blockType: BlockType,
    standInType?: string,
  ) => {
    let sectionBlock:
      | Text
      | SupportAction
      | CriticalAction
      | ScriptEquipmentList
      | EquipmentItem
      | ScriptList
      | ListItem
      | RolePlayerPrompt
      | ScriptRolePlayerPrompt
      | ResearchQuestion
      | Treatment
      | CasualtyStatus
      | Diagnostic
      | Medication
      | ResearchQuestion
      | null = null;

    switch (blockType) {
      case BlockType.CriticalAction:
        sectionBlock = createCriticalAction();
        break;

      case BlockType.ScriptEquipmentList:
        sectionBlock = createScriptEquipmentList();
        break;

      case BlockType.ScriptList:
        sectionBlock = createScriptList();
        break;

      case BlockType.ScriptRolePlayerPrompt:
        sectionBlock = createScriptRolePlayerPrompt();
        break;

      case BlockType.SupportAction:
        sectionBlock = createSupportAction();
        break;

      case BlockType.Text:
        sectionBlock = createText(standInType!);
        break;
      case BlockType.Treatment:
        sectionBlock = createTreatment(currentPhaseId, currentPhaseIndex);
        break;
      case BlockType.CasualtyStatus:
        sectionBlock = createCasualtyStatus();
        break;
      case BlockType.Diagnostic:
        sectionBlock = createDiagnostic(currentPhaseId, currentPhaseIndex);
        break;
      case BlockType.Medication:
        sectionBlock = createMedication(currentPhaseId, currentPhaseIndex);
        break;
      case BlockType.ResearchQuestion:
        sectionBlock = createResearchQuestion();
        break;

      default:
        break;
    }

    return {
      Id: 0,
      UUID: uuidv4(),
      SectionId: SectionId,
      BlockType: blockType,
      BlockContent: sectionBlock,
      IsGhostBlock: false,
      TimedAction: false,
      Order: 0,
    };
  };
  const createNewTextBlockOnClick = (standInType: string) => {
    const targetSectionUUID =
      phases[currentPhaseIndex].Sections![currentSectionIndex].UUID;
    const targetSectionIndex = phases[currentPhaseIndex].Sections!.findIndex(
      (s) => s.UUID === targetSectionUUID,
    );

    props.loadingToggle();

    const newBlockItem = createBlock(
      phases[currentPhaseIndex].Sections![targetSectionIndex].Id!,
      BlockType.Text,
      standInType,
    );
    const newUUID = newBlockItem.UUID;
    CreateTextBlock(
      newBlockItem,
      (createdBlock: Block) => {
        const tempRef = phases[currentPhaseIndex].Sections![targetSectionIndex];
        const newBlocks = tempRef.Blocks
          ? [...tempRef.Blocks, createdBlock]
          : [createdBlock];
        createdBlock.UUID = newUUID;
        createdBlock.Order = newBlocks.length - 1;
        dispatch(
          SetPhases(
            phases.map((phase, phaseIndex) => {
              return phaseIndex !== currentPhaseIndex
                ? phase
                : {
                    ...phase,
                    Sections: phase.Sections!.map((section, sectionIndex) => {
                      return sectionIndex !== targetSectionIndex
                        ? section
                        : {
                            ...section,
                            Blocks: newBlocks,
                          };
                    }),
                  };
            }),
          ),
        );
        updateBlocksOrders(newBlocks);
        props.loadingToggle();
        let component = ComponentEdit.SECTION;

        if ("StandInType" in newBlockItem!.BlockContent!) {
          if (newBlockItem.BlockContent!.StandInType === "role player prompt") {
            component = ComponentEdit.RPPROMPT;
          } else if (
            newBlockItem.BlockContent!.StandInType === "support action"
          ) {
            component = ComponentEdit.SUPPORTACTION;
          } else {
            component = ComponentEdit.TEXT;
          }
        }
        dispatch(SetCurrentBlockIndex(createdBlock.Order));
        dispatch(SetCurrentSectionIndex(targetSectionIndex));
        dispatch(SetActiveComponent(component));
      },
      props.postError,
    );
  };

  const createNewListBlockOnClick = () => {
    const targetSectionUUID =
      phases[currentPhaseIndex].Sections![currentSectionIndex].UUID;
    const targetSectionIndex = phases[currentPhaseIndex].Sections!.findIndex(
      (s) => s.UUID === targetSectionUUID,
    );

    props.loadingToggle();

    const newBlockItem = createBlock(
      phases[currentPhaseIndex].Sections![targetSectionIndex].Id!,
      BlockType.ScriptList,
    );
    const newUUID = newBlockItem.UUID;
    CreateScriptListItems(
      newBlockItem,
      (createdBlock: Block) => {
        const tempRef = phases[currentPhaseIndex].Sections![targetSectionIndex];
        const newBlocks = tempRef.Blocks
          ? [...tempRef.Blocks, createdBlock]
          : [createdBlock];
        createdBlock.UUID = newUUID;
        createdBlock.Order = newBlocks.length - 1;
        dispatch(
          SetPhases(
            phases.map((phase, phaseIndex) => {
              return phaseIndex !== currentPhaseIndex
                ? phase
                : {
                    ...phase,
                    Sections: phase.Sections!.map((section, sectionIndex) => {
                      return sectionIndex !== targetSectionIndex
                        ? section
                        : {
                            ...section,
                            Blocks: newBlocks,
                          };
                    }),
                  };
            }),
          ),
        );
        updateBlocksOrders(newBlocks);
        props.loadingToggle();
        dispatch(SetCurrentBlockIndex(createdBlock.Order));
        dispatch(SetCurrentSectionIndex(targetSectionIndex));
        dispatch(SetActiveComponent(ComponentEdit.LIST));
      },
      props.postError,
    );
  };

  const createNewTreatmentOnClick = () => {
    const targetSectionUUID =
      phases[currentPhaseIndex].Sections[currentSectionIndex].UUID;
    const targetSectionIndex = phases[currentPhaseIndex].Sections?.findIndex(
      (s) => s.UUID === targetSectionUUID,
    );
    props.loadingToggle();

    const newBlock: CreateTreatmentTaskBlock = {
      SectionId: phases[currentPhaseIndex].Sections[targetSectionIndex].Id || 0,
      BlockType: BlockType.Treatment,
      Order: 0,
      OrderInNextPhase1: 0,
      OrderInNextPhase2: 0,
      BlockContent: {
        CreatedInPhaseId: currentPhaseId,
        IsCritical: false,
        IsEditMasterEnabled: false,
        IsRequired: false,
        RequiredInPhaseOffset: 0,
      },
    };

    createTreatmentTaskBlock(
      newBlock,
      (createdBlock: Block) => {
        const tempRef = phases[currentPhaseIndex].Sections[targetSectionIndex];
        const newBlocks = tempRef.Blocks
          ? [...tempRef.Blocks, createdBlock]
          : [createdBlock];
        createdBlock.UUID = uuidv4();
        createdBlock.Order = newBlocks.length - 1;
        (createdBlock.BlockContent as Treatment).UploadedMediaItems ??= [];
        dispatch(
          SetPhases(
            phases.map((phase, phaseIndex) => {
              return phaseIndex !== currentPhaseIndex
                ? phase
                : {
                    ...phase,
                    Sections: phase.Sections?.map((section, sectionIndex) => {
                      return sectionIndex !== targetSectionIndex
                        ? section
                        : {
                            ...section,
                            Blocks: newBlocks,
                          };
                    }),
                  };
            }),
          ),
        );
        updateBlocksOrders(newBlocks);
        props.loadingToggle();
        dispatch(SetCurrentBlockIndex(createdBlock.Order));
        dispatch(SetCurrentSectionIndex(targetSectionIndex));
        dispatch(SetActiveComponent(ComponentEdit.TREATMENT));
      },
      props.postError,
    );
  };
  const createNewMiscellaneousBlockOnClick = () => {
    const targetSectionUUID =
      phases[currentPhaseIndex].Sections[currentSectionIndex].UUID;
    const targetSectionIndex = phases[currentPhaseIndex].Sections?.findIndex(
      (s) => s.UUID === targetSectionUUID,
    );
    props.loadingToggle();
    const newBlock: CreateMiscellaneousTaskBlock = {
      SectionId: phases[currentPhaseIndex].Sections[targetSectionIndex].Id || 0,
      BlockType: BlockType.Miscellaneous,
      Order: 0,
      OrderInNextPhase1: 0,
      OrderInNextPhase2: 0,
      BlockContent: {
        CreatedInPhaseId: currentPhaseId,
        IsCritical: false,
        IsEditMasterEnabled: false,
        IsRequired: false,
        RequiredInPhaseOffset: 0,
      },
    };
    createMiscellaneousTaskBlock(
      newBlock,
      (createdBlock: MiscellaneousTaskBlock) => {
        const tempRef = phases[currentPhaseIndex].Sections[targetSectionIndex];
        const newBlocks = tempRef.Blocks
          ? [...tempRef.Blocks, createdBlock]
          : [createdBlock];
        createdBlock.UUID = uuidv4();
        createdBlock.Order = newBlocks.length - 1;
        (
          createdBlock.BlockContent.TimedActionTaskContent as MiscellaneousTask
        ).AttachedMedias ??= [];
        dispatch(
          SetPhases(
            phases.map((phase, phaseIndex) => {
              return phaseIndex !== currentPhaseIndex
                ? phase
                : {
                    ...phase,
                    Sections: phase.Sections?.map((section, sectionIndex) => {
                      return sectionIndex !== targetSectionIndex
                        ? section
                        : {
                            ...section,
                            Blocks: newBlocks,
                          };
                    }),
                  };
            }),
          ),
        );
        updateBlocksOrders(newBlocks);
        props.loadingToggle();
        dispatch(SetCurrentBlockIndex(createdBlock.Order));
        dispatch(SetCurrentSectionIndex(targetSectionIndex));
        dispatch(SetActiveComponent(ComponentEdit.MISCELLANEOUS));
      },
      props.postError,
    );
  };
  const createNewDiagnosticBlockOnClick = () => {
    const targetSectionUUID =
      phases[currentPhaseIndex].Sections[currentSectionIndex].UUID;
    const targetSectionIndex = phases[currentPhaseIndex].Sections?.findIndex(
      (s) => s.UUID === targetSectionUUID,
    );
    props.loadingToggle();

    const newBlock: CreateDiagnosticTaskBlock = {
      SectionId: phases[currentPhaseIndex].Sections[targetSectionIndex].Id || 0,
      BlockType: BlockType.Diagnostic,
      Order: 0,
      OrderInNextPhase1: 0,
      OrderInNextPhase2: 0,
      BlockContent: {
        CreatedInPhaseId: currentPhaseId,
        IsCritical: false,
        IsEditMasterEnabled: false,
        IsRequired: false,
        RequiredInPhaseOffset: 0,
      },
    };

    createDiagnosticTaskBlock(
      newBlock,
      (createdBlock: DiagnosticTaskBlock) => {
        const tempRef = phases[currentPhaseIndex].Sections[targetSectionIndex];
        const newBlocks = tempRef.Blocks
          ? [...tempRef.Blocks, createdBlock]
          : [createdBlock];
        createdBlock.UUID = uuidv4();
        createdBlock.Order = newBlocks.length - 1;
        (
          createdBlock.BlockContent.TimedActionTaskContent as DiagnosticTask
        ).AttachedMedias ??= [];
        dispatch(
          SetPhases(
            phases.map((phase, phaseIndex) => {
              return phaseIndex !== currentPhaseIndex
                ? phase
                : {
                    ...phase,
                    Sections: phase.Sections?.map((section, sectionIndex) => {
                      return sectionIndex !== targetSectionIndex
                        ? section
                        : {
                            ...section,
                            Blocks: newBlocks,
                          };
                    }),
                  };
            }),
          ),
        );
        updateBlocksOrders(newBlocks);
        props.loadingToggle();
        dispatch(SetCurrentBlockIndex(createdBlock.Order));
        dispatch(SetCurrentSectionIndex(targetSectionIndex));
        dispatch(SetActiveComponent(ComponentEdit.DIAGNOSTIC));
      },
      props.postError,
    );
  };
  const createNewMedicationBlockOnClick = () => {
    const targetSectionUUID =
      phases[currentPhaseIndex].Sections[currentSectionIndex].UUID;
    const targetSectionIndex = phases[currentPhaseIndex].Sections?.findIndex(
      (s) => s.UUID === targetSectionUUID,
    );

    props.loadingToggle();

    const newBlock: CreateMedicationTaskBlock = {
      SectionId: phases[currentPhaseIndex].Sections[targetSectionIndex].Id || 0,
      BlockType: BlockType.Medication,
      Order: 0,
      OrderInNextPhase1: 0,
      OrderInNextPhase2: 0,
      BlockContent: {
        CreatedInPhaseId: currentPhaseId,
        IsCritical: false,
        IsEditMasterEnabled: false,
        IsRequired: false,
        RequiredInPhaseOffset: 0,
      },
    };
    createMedicationTaskBlock(
      newBlock,
      (createdBlock: MedicationTaskBlock) => {
        const tempRef = phases[currentPhaseIndex].Sections[targetSectionIndex];
        const newBlocks = tempRef.Blocks
          ? [...tempRef.Blocks, createdBlock]
          : [createdBlock];
        createdBlock.UUID = uuidv4();
        createdBlock.Order = newBlocks.length - 1;
        (
          createdBlock.BlockContent.TimedActionTaskContent as MedicationTask
        ).AttachedMedias ??= [];
        dispatch(
          SetPhases(
            phases.map((phase, phaseIndex) => {
              return phaseIndex !== currentPhaseIndex
                ? phase
                : {
                    ...phase,
                    Sections: phase.Sections?.map((section, sectionIndex) => {
                      return sectionIndex !== targetSectionIndex
                        ? section
                        : {
                            ...section,
                            Blocks: newBlocks,
                          };
                    }),
                  };
            }),
          ),
        );
        updateBlocksOrders(newBlocks);
        props.loadingToggle();
        dispatch(SetCurrentBlockIndex(createdBlock.Order));
        dispatch(SetCurrentSectionIndex(targetSectionIndex));
        dispatch(SetActiveComponent(ComponentEdit.MEDICATION));
      },
      props.postError,
    );
  };

  const createNewCasualtyStatus = () => {
    const targetSectionUUID =
      phases[currentPhaseIndex].Sections![currentSectionIndex].UUID;
    const targetSectionIndex = phases[currentPhaseIndex].Sections!.findIndex(
      (s) => s.UUID === targetSectionUUID,
    );

    props.loadingToggle();

    const newBlockItem = createBlock(
      phases[currentPhaseIndex].Sections![targetSectionIndex].Id!,
      BlockType.CasualtyStatus,
    );
    const newUUID = newBlockItem.UUID;
    CreateCasualtyStatusBlock(
      newBlockItem,
      (createdBlock: Block) => {
        console.log(
          "This is the block that came from back end: ",
          createdBlock,
        );

        const tempRef = phases[currentPhaseIndex].Sections![targetSectionIndex];
        const newBlocks = tempRef.Blocks
          ? [...tempRef.Blocks, createdBlock]
          : [createdBlock];
        createdBlock.UUID = newUUID;
        createdBlock.Order = newBlocks.length - 1;
        dispatch(
          SetPhases(
            phases.map((phase, phaseIndex) => {
              return phaseIndex !== currentPhaseIndex
                ? phase
                : {
                    ...phase,
                    Sections: phase.Sections!.map((section, sectionIndex) => {
                      return sectionIndex !== targetSectionIndex
                        ? section
                        : {
                            ...section,
                            Blocks: newBlocks,
                          };
                    }),
                  };
            }),
          ),
        );
        updateBlocksOrders(newBlocks);
        props.loadingToggle();
        dispatch(SetCurrentBlockIndex(createdBlock.Order));
        dispatch(SetCurrentSectionIndex(targetSectionIndex));
        dispatch(SetActiveComponent(ComponentEdit.CASUALTYSTATUS));
      },
      props.postError,
    );
  };
  const createNewResearchQuestioBlockOnClick = () => {
    const targetSectionUUID =
      phases[currentPhaseIndex].Sections![currentSectionIndex].UUID;
    const targetSectionIndex = phases[currentPhaseIndex].Sections!.findIndex(
      (s) => s.UUID === targetSectionUUID,
    );

    props.loadingToggle();

    const newBlockItem = createBlock(
      phases[currentPhaseIndex].Sections![targetSectionIndex].Id!,
      BlockType.ResearchQuestion,
    );
    const newUUID = newBlockItem.UUID;
    CreateResearchQuestionBlock(
      newBlockItem,
      (createdBlock: Block) => {
        console.log(
          "This is the block that came from back end: ",
          createdBlock,
        );

        const tempRef = phases[currentPhaseIndex].Sections![targetSectionIndex];
        const newBlocks = tempRef.Blocks
          ? [...tempRef.Blocks, createdBlock]
          : [createdBlock];
        createdBlock.UUID = newUUID;
        createdBlock.Order = newBlocks.length - 1;
        dispatch(
          SetPhases(
            phases.map((phase, phaseIndex) => {
              return phaseIndex !== currentPhaseIndex
                ? phase
                : {
                    ...phase,
                    Sections: phase.Sections!.map((section, sectionIndex) => {
                      return sectionIndex !== targetSectionIndex
                        ? section
                        : {
                            ...section,
                            Blocks: newBlocks,
                          };
                    }),
                  };
            }),
          ),
        );
        dispatch(SetIsEditingAllowed(true));
        updateBlocksOrders(newBlocks);
        props.loadingToggle();
        dispatch(SetCurrentBlockIndex(createdBlock.Order));
        dispatch(SetCurrentSectionIndex(targetSectionIndex));
        dispatch(SetActiveComponent(ComponentEdit.RESEARCHQUESTION));
      },
      props.postError,
    );
  };
  const addNewSectionOnClickSuccess =
    (newSectionId: string) => (newSection: Section) => {
      newSection.UUID = newSectionId;
      window.sessionStorage.setItem("SelectedElementId", newSectionId);
      // introduce the new section to the state
      const newSectionArray: Section[] = [
        ...phases[currentPhaseIndex]!.Sections!,
        newSection,
      ];
      dispatch(
        SetPhases(
          phases.map((phase, phaseIndex) => {
            return phaseIndex !== currentPhaseIndex
              ? phase
              : {
                  ...phase,
                  Sections: newSectionArray,
                };
          }),
        ),
      );
      props.loadingToggle();
      dispatch(SetActiveComponent(ComponentEdit.SECTION));
      dispatch(SetCurrentSectionIndex(newSection.Order));
      dispatch(SetAddBlockShowingId(newSectionId)); // to enable add block tray to add new blocks
    };

  const createNewSectionOnClick = () => {
    if (isReadOnly) return; //disable action callback in read only script
    const newSectionId = uuidv4();

    // new sections have to be inserted at the end.
    // We'll move it to the correct place after creation.
    const newSection: Section = {
      UUID: newSectionId,
      PhaseId: phases[currentPhaseIndex].Id!,
      Order: phases[currentPhaseIndex]!.Sections!.length!,
      Title: "",
      Blocks: [],
      Collapsed: false,
    };

    props.loadingToggle();
    CreateSection(
      newSection,
      addNewSectionOnClickSuccess(newSectionId),
      props.postError,
    );
  };
  {
    console.log("PHASES IN SCRIPTBUILDER CENTER", phases);
  }

  /**
   * Update Block orders after adding new block using "+ btn"  add functionality
   * @param newBlocks
   */
  const updateBlocksOrders = (newBlocks: any) => {
    UpdateBlockOrder(
      newBlocks,
      (res: string) => {
        console.log(res);
      },
      props.postError,
    );
  };

  // This handles scroll effect after page refresh.
  useEffect(() => {
    const SelectedElementId =
      window.sessionStorage.getItem("SelectedElementId");
    if (SelectedElementId) {
      const restorePageScroll = () => {
        const element = document.getElementById(SelectedElementId);

        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      };

      // Check if the page has already loaded
      if (document.readyState === "complete") {
        restorePageScroll();
      } else {
        window.addEventListener("load", restorePageScroll);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener("load", restorePageScroll);
      }
    }
  }, []);
  const handleNavigateToScriptDetails = () => {
    RemoveClickEventHandlerForReadOnlyInput();
    history.push(`/EditScript/${scriptId}`);
  };
  console.log(props.children.item);
  return (
    <SectionCol
      xxl={{ span: 15 }}
      xl={{ span: 12 }}
      lg={!collapse ? { span: 12 } : { span: 11 }}
      md={{ span: 11 }}
      sm={{ span: 11 }}
      className="mainSectionColumn"
    >
      <TabStyle
        type={isReadOnly ? "line" : "editable-card"}
        onChange={onTabChange}
        onTabClick={onTabChange}
        activeKey={activeKey}
        addIcon={<MyButton />}
        onEdit={onTabEdit}
        className="mainSectionColumnFirstChild"
      >
        <StyledTabPane
          key={"edit-details"}
          data-hover="Edit Script Details"
          tab={
            <ToolTipStyled title="Edit Script Details">
              <EditOutlined className="font-size-0" />
            </ToolTipStyled>
          }
          closable={false}
          destroyInactiveTabPane={true}
        />
        {!state.casualtyId && isReadOnly ? null : (
          <StyledTabPane
            key={"edit-casualty"}
            data-hover="Edit Casualty"
            tab={
              <ToolTipStyled title="Edit Casualty">
                <FrownOutlined className="font-size-0" />
              </ToolTipStyled>
            }
            closable={false}
            destroyInactiveTabPane={true}
          />
        )}
        {!isNullOrUndefined(phases)
          ? phases.map(
              (phase, phaseMapIndex) =>
                !isNullOrUndefined(phase.UUID) && (
                  <StyledTabPane
                    key={phase.UUID}
                    data-hover="Add Phase"
                    tab={
                      <ToolTipStyled
                        placement="bottomRight"
                        title={
                          phase.TitleLong ? <div>{phase.TitleLong}</div> : null
                        }
                        arrowPointAtCenter={true}
                        color="blue"
                      >
                        {phase.TitleAbbreviated !== ""
                          ? phase.TitleAbbreviated
                          : `Phase ${phaseMapIndex + 1}`}
                      </ToolTipStyled>
                    }
                    closable={false}
                    destroyInactiveTabPane={true}
                  >
                    {activeKey === phase.UUID ? (
                      <>
                        <Droppable
                          isDropDisabled={isReadOnly}
                          droppableId={`droppable-${phaseMapIndex}`}
                          type="droppableItem"
                        >
                          {(providedDrop: any, snapshotDrop: any) => (
                            <DropzoneDivStyle
                              width="auto"
                              key={providedDrop.key}
                              id="dropZoneMainSection"
                              parentHeight={0}
                              highlighted={activeDragContentType === "Section"}
                              ref={providedDrop.innerRef}
                              {...providedDrop.droppableProps}
                            >
                              {phases![currentPhaseIndex]?.IsScriptBrief && (
                                <BriefPhaseHeader>
                                  <RightIconButton
                                    className="greyButtonSolidBorder"
                                    onClick={() => props.showEditCasualtyPane()}
                                  >
                                    <div>View Casualty</div>
                                    <div className="arrowIcon">
                                      <ArrowRightOutlinedIcon />
                                    </div>
                                  </RightIconButton>
                                  <div className="timerContainer">
                                    <div>Estimated time to complete</div>
                                    <div className="timer">
                                      <div>
                                        <ClockCircleOutlinedIcon />
                                      </div>
                                      <span>Total</span>
                                    </div>
                                    <div className="timer">
                                      <div>
                                        {formatTimeParameter(
                                          phases![currentPhaseIndex]
                                            ?.EstimatedTimeHours,
                                        )}
                                      </div>
                                      <span>hrs</span>
                                    </div>
                                    <span className="colon">:</span>
                                    <div className="timer">
                                      <div>
                                        {formatTimeParameter(
                                          phases![currentPhaseIndex]
                                            ?.EstimatedTimeMinutes,
                                        )}
                                      </div>
                                      <span>mins</span>
                                    </div>
                                  </div>
                                </BriefPhaseHeader>
                              )}
                              {phase.Sections?.map(
                                (item: Section, sectionMapIndex: number) => {
                                  if (item == undefined) {
                                    return;
                                  } else {
                                    const shouldShowAddBlock =
                                      item.UUID === addBlockShowingId;
                                    return (
                                      <div
                                        className="drag FontSize"
                                        key={item.UUID}
                                        role="sectionDraggable"
                                      >
                                        <Draggable
                                          isDragDisabled={isReadOnly}
                                          key={item.UUID}
                                          draggableId={`Section.${item.UUID}`}
                                          index={sectionMapIndex}
                                        >
                                          {(provided: any, snapshot: any) => (
                                            <SectionDivStyle
                                              highlighted={
                                                !item.Collapsed &&
                                                activeDragContentType !==
                                                  null &&
                                                activeDragContentType !==
                                                  "Phase" &&
                                                activeDragContentType !==
                                                  "Section"
                                              }
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              key={sectionMapIndex}
                                              onClick={selectobject(
                                                item,
                                                "SECTION",
                                                item.UUID /* section UUID */,
                                                sectionMapIndex,
                                              )}
                                              selectedComponent={
                                                sectionMapIndex ===
                                                  currentSectionIndex &&
                                                activeComponent !==
                                                  ComponentEdit.PHASE
                                              }
                                              activeComponent={
                                                activeComponent ===
                                                ComponentEdit.SECTION
                                              }
                                              blockHovered={
                                                props.mouseHoveringBlock
                                              }
                                              id={`Section.${item.Id}`}
                                              name="sectionInPhase"
                                              role="phaseSection"
                                            >
                                              <SectionLabel>
                                                <div className="section-index-title">
                                                  <div className="hidden-drag-icon">
                                                    <DragIcon />
                                                  </div>
                                                  <p className="section-number">
                                                    {`Section ${
                                                      sectionMapIndex + 1
                                                    }`}
                                                  </p>
                                                </div>

                                                <div className="section-header-text">
                                                  <div className="section-drag-icon">
                                                    <DragIcon />
                                                  </div>
                                                  <Tooltip
                                                    title={item.Title}
                                                    placement="top"
                                                    color="blue"
                                                  >
                                                    <h1
                                                      key={uuidv4()}
                                                      className="SectionHeading"
                                                    >
                                                      {item.Title === ""
                                                        ? "New Section"
                                                        : item.Title}
                                                    </h1>
                                                  </Tooltip>
                                                </div>
                                              </SectionLabel>
                                              {item.Collapsed ? (
                                                ""
                                              ) : (
                                                <>
                                                  <div>
                                                    {item.IsCriticalActionRequired &&
                                                      item?.Id ===
                                                        phases[
                                                          currentPhaseIndex
                                                        ].Sections![
                                                          currentSectionIndex
                                                        ]?.Id && (
                                                        <div className="criticalActionSectionStart">
                                                          START
                                                        </div>
                                                      )}
                                                  </div>
                                                  <div className="SectionTitle">
                                                    {props.children(
                                                      item,
                                                      sectionMapIndex,
                                                      columnType,
                                                      props.handleMouseHover,
                                                      selectobject,
                                                      item.UUID /* Section UUID */,
                                                    )}
                                                  </div>
                                                </>
                                              )}
                                              {isReadOnly ? (
                                                // read only block
                                                <div className="addBlock"></div>
                                              ) : (
                                                <div className="addBlock">
                                                  {!item.Collapsed &&
                                                    addBlockShowingId !==
                                                      item.UUID && (
                                                      <div
                                                        id={`section${item.Order}-AddModal`}
                                                      >
                                                        <PlusOutlinedIcons
                                                          className={`sectionDragDropPlusIcon greyButtonDottedBorder ${
                                                            isReadOnly
                                                              ? "disabled"
                                                              : ""
                                                          }`}
                                                          onClick={() => {
                                                            if (isReadOnly)
                                                              return;
                                                            dispatch(
                                                              SetCurrentSectionIndex(
                                                                sectionMapIndex,
                                                              ),
                                                            );
                                                            dispatch(
                                                              SetAddBlockShowingId(
                                                                item.UUID,
                                                              ),
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    )}
                                                  {!item.Collapsed &&
                                                    shouldShowAddBlock && (
                                                      <div>
                                                        <AddBlockIcons
                                                          disabled={isReadOnly}
                                                          className={`expandedAddBlock ${
                                                            isReadOnly
                                                              ? "disabled"
                                                              : ""
                                                          }`}
                                                          isBrief={
                                                            phases[
                                                              currentPhaseIndex
                                                            ].IsScriptBrief
                                                          }
                                                          isSectionTimedAction={
                                                            phases![
                                                              currentPhaseIndex
                                                            ].Sections![
                                                              currentSectionIndex
                                                            ]!
                                                              .IsCriticalActionRequired!
                                                          }
                                                          onClickText={() =>
                                                            createNewTextBlockOnClick(
                                                              "",
                                                            )
                                                          }
                                                          onClickMiscellaneous={
                                                            createNewMiscellaneousBlockOnClick
                                                          }
                                                          onClickList={
                                                            createNewListBlockOnClick
                                                          }
                                                          onClickRPPrompt={() =>
                                                            createNewTextBlockOnClick(
                                                              "role player prompt",
                                                            )
                                                          }
                                                          onClickTreatment={
                                                            createNewTreatmentOnClick
                                                          }
                                                          onClickSupportAction={() =>
                                                            createNewTextBlockOnClick(
                                                              "support action",
                                                            )
                                                          }
                                                          onClickDiagnostic={
                                                            createNewDiagnosticBlockOnClick
                                                          }
                                                          onClickCasualtyStatus={
                                                            createNewCasualtyStatus
                                                          }
                                                          // Disabled for development server
                                                          onClickMedication={
                                                            createNewMedicationBlockOnClick
                                                          }
                                                          onClickResearchQuestion={
                                                            createNewResearchQuestioBlockOnClick
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                </div>
                                              )}
                                              {item.IsCriticalActionRequired &&
                                                item?.UUID ===
                                                  phases[currentPhaseIndex]
                                                    .Sections![
                                                    currentSectionIndex
                                                  ]?.UUID && (
                                                  <div className="criticalActionSectionEnd">
                                                    END{" "}
                                                    {item.IsAARRequired && (
                                                      <span className="aarReport">
                                                        + After Action Report
                                                        Required{" "}
                                                        <LineChartOutlined />
                                                      </span>
                                                    )}
                                                  </div>
                                                )}
                                            </SectionDivStyle>
                                          )}
                                        </Draggable>
                                      </div>
                                    );
                                  }
                                },
                              )}
                              {providedDrop.placeholder}
                            </DropzoneDivStyle>
                          )}
                        </Droppable>
                        {isReadOnly ? null : (
                          <div
                            className={`addSectionButton greyButtonDottedBorder ${
                              isReadOnly ? "disabled" : ""
                            }`}
                            onClick={createNewSectionOnClick}
                          >
                            <PlusOutlinedIcons className="addSectionPlusIcon" />
                            <span>Section</span>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </StyledTabPane>
                ),
            )
          : ""}
      </TabStyle>
    </SectionCol>
  );
};
