import React, { useEffect, useState } from "react";
import {
  Form,
  Label,
  LabelSpan,
  ErrorSpan,
  Input,
  SuffixSpan,
} from "./AgeWeight.styled";

interface AgeProps {
  Age: any;
  handleChange: any;
  onSave: any;
}

const AgeForm = (props: AgeProps) => {
  const [classNameForLabel, setClassNameForLabel] =
    useState<string>("label-name");
  const minAge = 18;
  const maxAge = 65;

  useEffect(() => {
    if (props.Age.trim().length > 0) {
      setClassNameForLabel("label-name filled-label");
    } else {
      setClassNameForLabel("label-name");
    }
  }, [props.Age]);

  return (
    <Form>
      <Input
        type="number"
        name="age"
        value={props.Age}
        onChange={props.handleChange}
        onBlur={props.onSave}
      />

      <Label className={classNameForLabel} htmlFor="age">
        <LabelSpan
          className={
            props.Age.trim().length === 0
              ? "content-name"
              : "content-name filled-content"
          }
        >
          {" "}
          Age*{" "}
        </LabelSpan>
        <ErrorSpan className="error">
          ({minAge}-{maxAge})
        </ErrorSpan>
        <SuffixSpan>yrs</SuffixSpan>
      </Label>
    </Form>
  );
};

export default AgeForm;
