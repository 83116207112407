import React from "react";
import Button from "../components/Button/Button";
import { DownloadOutlined } from "@ant-design/icons";
import { OpenErrorNotification } from "../components/Notification/Notification";
import TabletBuild from "../models/Build";
import { getLatestTabletBuild } from "../api/MediaTabletBuild/ApiGet";

export default function DownloadRealXBtn() {
  const onClickDownload = () => {
    getLatestTabletBuild(
      (data: TabletBuild) => {
        // download audio file to system
        const link: any = document.createElement("a");
        link.href = data.Url;
        link.setAttribute("download", `${data.BuildName}.exe`);
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(data.Url);
        }, 0);
      },
      (error: any) =>
        OpenErrorNotification({
          description: error,
        }),
    );
  };
  return (
    <Button
      type="primary"
      shape={"rounded"}
      leftIcon={<DownloadOutlined />}
      onClick={onClickDownload}
    >
      Download REAL-X
    </Button>
  );
}
