import * as React from "react";
import {
  Block,
  ScriptEquipmentList,
  ScriptList,
} from "../../../models/ScriptInterfaces";
import {
  ComplicationType,
  ListTypeDropDown,
  ScriptListType,
} from "../../../models/Enums";
import {
  Container,
  FixedDiv,
  ScrollableDiv,
} from "../../../styles/EditComponentTheme.styled";
import { ListMenuColumn } from "./EditFormTheme";
import ListEditForm from "./EditForms/ListEditForm/ListEditForm";
import ShowList from "./ShowList/ShowList";
import { isNullOrUndefined } from "../../../utils/utilityfunctions";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../components/ConfirmDialog/useConfirmDialog";
import { useAppSelector } from "../../../store/hooks";
import { IconColumn } from "./IconColumn";

export interface ScriptListBlock extends Block {
  BlockContent: ScriptEquipmentList | ScriptList;
}

interface Props {
  object: ScriptListBlock;
  onChange: Function;
  sectionTitle: string;
  position: number;
  onSave: Function;
  onUpdate: Function;
  onListTitleAdd: Function;
  onDelete: any; //Function
  onDeleteItem: any; //Function
  addListItem: any; //Function
  updateListItem?: any; //Function
  onCopy: Function; //any
}

const ListEdit = (props: Props) => {
  const state = useAppSelector((state: any) => state.MainlistReducer);
  const isReadOnly: boolean = state.isReadOnly;
  //1st hook will be id
  const [id] = React.useState<number>(0);
  //2nd hook will be title
  const [title, setTitle] = React.useState<string>(
    props.object?.BlockContent.Title,
  );
  // 3rd hook will be description
  const [description, setDescription] = React.useState<string>("");
  // 4th hook will be selected type
  const [selectedType, setSelectedType] = React.useState<string>("");
  //5th hook will be category
  const [category, setCategory] = React.useState<string>("");
  // 6th hook will be manufacturer Name
  const [manufacturerName, setManufacturerName] = React.useState<string>("");
  // 7th hook will be product image
  const [productImage, setProductImage] = React.useState<string>("");
  // 8th hook will be edit mode
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [listItem, setListItem] = React.useState("");

  // 9th hook will be list Items
  const [, setListItems] = React.useState<any>([]);

  //10th hook will be for selected item for hooks
  const [selectedValue, SetSelectedValue] = React.useState<ListTypeDropDown>(
    ListTypeDropDown.Type,
  );
  //11th hook will be for selected Type for hooks
  const [, SetSelectedListType] = React.useState<ScriptListType>(
    ScriptListType.New,
  );
  const [quantity, setQuantity] = React.useState(0);

  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();

  React.useEffect(() => {
    if (!isNullOrUndefined(props.object)) {
      setTitle(props.object.BlockContent.Title);
    }

    if (props.object?.BlockContent.hasOwnProperty("List_Items")) {
      const scriptList = props.object!.BlockContent as ScriptList;
      if (scriptList.ListItems.length !== 0) {
        setListItems(scriptList.ListItems);
      }
    }

    if (
      props.object?.BlockContent.hasOwnProperty("List_Items") &&
      props.object.BlockContent.ListItems.length !== 0
    ) {
      setListItems(props.object.BlockContent.ListItems);
    }

    if (
      props.object?.BlockContent.hasOwnProperty("Equipment_Items") &&
      props.object.BlockContent.EquipmentItems.length !== 0
    ) {
      setListItems(props.object.BlockContent.EquipmentItems);
    }

    if (
      props.object?.BlockContent.hasOwnProperty("Complication") &&
      props.object.BlockContent.Complications.length !== 0
    ) {
      setListItems(props.object.BlockContent.Complications);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.object]);

  React.useEffect(() => {
    if (props.object?.BlockContent.hasOwnProperty("ListItems")) {
      SetSelectedListType(props.object.BlockContent.Type);
      console.log("type", props.object.BlockContent.Type);
      switch (props.object.BlockContent.Type) {
        case ScriptListType.New:
          SetSelectedValue(ListTypeDropDown.NEW);
          break;
        case ScriptListType.PrimaryObjectives:
          SetSelectedValue(ListTypeDropDown.PRIMARY);
          break;
        case ScriptListType.SecondaryObjectives:
          SetSelectedValue(ListTypeDropDown.SECONDARY);
          break;
        case ScriptListType.CriticalMilestones:
          SetSelectedValue(ListTypeDropDown.CRITICAL);
          break;
        default:
          console.log("This should not happen");
          break;
      }
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.object?.BlockContent.Type]);

  const handleChange = (e: any) => {
    if (isReadOnly) return; //disable action callback in read only script
    if (e.target.name === "title") {
      setTitle(e.target.value);
    }
    if (e.target.name === "listItem") {
      setListItem(e.target.value);
    } else if (e.target.name === "description") {
      setDescription(e.target.value);
    } else if (e.target.name === "category") {
      setCategory(e.target.value);
    } else if (e.target.name === "manufacturerName") {
      setManufacturerName(e.target.value);
    } else if (e.target.name === "productImage") {
      setProductImage(e.target.value);
    }
  };

  const handleQuantityChange = (value: number) => {
    setQuantity(value);
  };
  const handleQuantityToggle = (showQuantity?: boolean) => {
    const newBlock = {
      ...props.object,
      BlockContent: {
        ...props.object?.BlockContent,
        QuantityEnabled: showQuantity,
      },
    };
    console.log("Updated block shown", newBlock);
    props.onUpdate(newBlock);
  };
  const handleTitleToggle = (showTitle?: boolean) => {
    const newBlock = {
      ...props.object,
      BlockContent: {
        ...props.object?.BlockContent,
        IsTitleEnabled: showTitle,
      },
    };
    props.onUpdate(newBlock);
  };
  const handleChangeSelection = (value: string) => (e: any) => {
    console.log(`selected ${value} ${e}`);
    // this.setState({ [value]: e} as any);
    setSelectedType(e);
  };

  const onDeleteConfirm = () => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "Are you sure delete this Block?",
      content: "This cannot be undone",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        props.onDelete(props.object);
        setIsOpenConfirmDialog(false);
      },
      onCancel() {
        console.log("Cancel");
        setIsOpenConfirmDialog(false);
      },
      type: "error",
    });
  };

  const onListItemAdd = () => {
    // Caused the page to be unclickabble on my Mac but not windows
    //let textarea = document.getElementById("ListEditTitletextarea");
    //textarea.style.height = "3.4375rem";
    const listValue = getListValue();
    let newLI: {
      Id?: number;
      Title: string;
      Quantity?: number;
      Description?: string;
      Type?: string;
      Category?: string;
      ManufacturerName?: string;
      ProductImage?: string;
    } = { Title: "" };
    if (props.object?.BlockContent.hasOwnProperty("ListItems")) {
      newLI = {
        Title: listItem,
        Id: id,
        Quantity: quantity,
      };

      console.log("Title", newLI);
    } else if (props.object?.BlockContent.hasOwnProperty("EquipmentItems")) {
      newLI = {
        Title: listItem,
        Description: description,
        Category: category,
        ManufacturerName: manufacturerName,
        ProductImage: productImage,
        Quantity: quantity,
      };
    }
    const newBlock = JSON.parse(JSON.stringify(props.object));
    newBlock.BlockContent[listValue] = [newLI];
    if (editMode) {
      console.log("edit mode");
      (newLI!["Id"] as any) = id;
      const replaceBlock = JSON.parse(JSON.stringify(props.object));
      const index = replaceBlock.BlockContent[listValue].findIndex(
        (x: any) => x.Id === id,
      );
      replaceBlock.BlockContent[listValue].splice(index, 1, newLI);
      newBlock.BlockContent[listValue][0].Id = id;
      props.updateListItem(newBlock, replaceBlock);
      setEditMode(false);
    } else {
      const replaceBlock = JSON.parse(JSON.stringify(props.object));
      replaceBlock.BlockContent[listValue].push(newLI);
      props.addListItem(newBlock, replaceBlock);
      setListItems((oldArray: any) => [...oldArray, newLI]);
      setListItem("");
      setQuantity(0);
    }
  };
  const onListTitleAdd = (e?: any) => {
    props.onListTitleAdd(title, props.object);
  };
  const onListItemQuantityAdd = (e?: any) => {
    // props.onListItemQuantityAdd(quantity, id, props.object);
  };
  const getListValue = () => {
    console.log(props.object?.BlockContent);
    if (props.object?.BlockContent.hasOwnProperty("ListItems")) {
      return "ListItems";
    } else if (props.object?.BlockContent.hasOwnProperty("Complication")) {
      return "Complication";
    } else {
      return "EquipmentItems";
    }
  };

  const handleSelectedValueChange = (e: any) => {
    SetSelectedValue(e);
    const updated = props.object;
    switch (e) {
      case ListTypeDropDown.NEW:
        updated!.BlockContent.Type = ScriptListType.New;
        console.log("updated", updated);
        props.onSave(updated);
        break;
      case ListTypeDropDown.PRIMARY:
        updated!.BlockContent.Type = ScriptListType.PrimaryObjectives;
        console.log("updated", updated);
        props.onSave(updated);
        break;
      case ListTypeDropDown.SECONDARY:
        updated!.BlockContent.Type = ScriptListType.SecondaryObjectives;
        console.log("updated", updated);
        props.onSave(updated);
        break;
      case ListTypeDropDown.CRITICAL:
        updated!.BlockContent.Type = ScriptListType.CriticalMilestones;
        console.log("updated", updated);
        props.onSave(updated);
        break;
      case ListTypeDropDown.EQUIPMENT:
        console.log("Delete Block and remake");
        break;
      case ComplicationType.physiological:
        break;
      case ComplicationType.environmental:
        break;
      case ListTypeDropDown.Type:
        console.log("default type, this should not happen");
        break;
      default:
        console.log("This should not happen");
        break;
    }
  };
  const onUpdateColumnCount = (dualColumn: boolean) => {
    if (isReadOnly) return; //disable action callback in read only script
    const newBlock = {
      ...props.object,
      BlockContent: {
        ...props.object?.BlockContent,
        DisplayedAsTwoColumns: dualColumn,
      },
    };
    props.onUpdate(newBlock);
  };
  const onUpdateListOrder = (order: boolean) => {
    if (isReadOnly) return; //disable action callback in read only script
    const newBlock = {
      ...props.object,
      BlockContent: {
        ...props.object?.BlockContent,
        IsOrderedList: order,
      },
    };
    props.onUpdate(newBlock);
  };

  const onCopyConfirm = () => {
    props.onCopy(props.object);
    setIsOpenConfirmDialog(false);
  };

  return (
    <Container>
      <FixedDiv phase={false}>
        <IconColumn
          type="list"
          onCopyClick={onCopyConfirm}
          onDeleteClick={onDeleteConfirm}
        />
      </FixedDiv>

      <ScrollableDiv id="scrollableDiv" phase={false}>
        <ListMenuColumn
          blockContentColumnDisplay={
            props.object?.BlockContent.DisplayedAsTwoColumns
          }
          blockContentOrder={props.object?.BlockContent.IsOrderedList}
          makeOrderedList={onUpdateListOrder}
          makeDualColumn={onUpdateColumnCount}
          type={getListValue()}
          onChange={handleSelectedValueChange}
          selectedValue={selectedValue}
        />
        <ListEditForm
          listItem={listItem}
          title={title}
          quantity={quantity}
          handleChange={handleChange}
          handleQuantityChange={handleQuantityChange}
          submit={onListItemAdd}
          addTitle={onListTitleAdd}
          isQuantityNeeded={props.object?.BlockContent.QuantityEnabled}
          isTitleEnabled={props.object?.BlockContent.IsTitleEnabled}
          toggleQuantity={handleQuantityToggle}
          toggleTitle={handleTitleToggle}
          object={props.object}
          description={description}
          selectedType={selectedType}
          handleChangeSelection={handleChangeSelection}
          category={category}
          manufacturerName={manufacturerName}
          productImage={productImage}
        />

        <ShowList
          BlockContent={props.object?.BlockContent}
          getListValue={getListValue}
          object={props.object}
          onDeleteItem={props.onDeleteItem}
        />
      </ScrollableDiv>

      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </Container>
  );
};
export default ListEdit;
