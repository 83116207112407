import React from "react";
import FloatingLabelNumberInput from "../../../../../components/FloatingLabel/FloatingLabelNumberInput";
import { ResearchQuestionNumber } from "../../../../../models/ScriptInterfaces";
interface Props {
  scriptFormik: any;
  question: ResearchQuestionNumber;
}
export default function InputNumberType(props: Props) {
  return (
    <div className="mt-3 mb-2">
      {props.question.IsRange ? (
        // Input with Range
        <div className="d-inline-flex align-items-center">
          <div className="intervalInput">
            <FloatingLabelNumberInput
              isAddNeeded={false}
              step={1}
              label={""}
              placeholder={""}
              nameForInput={"number1"}
              handleOptionToggle={() => undefined}
              value={"Enter Number"}
              setValue={() => undefined}
              onChange={() => undefined}
              scriptFormik={props.scriptFormik}
              lastFocused={[]}
              isFocused={[]}
              setIsFocused={() => undefined}
              setLastFocused={() => undefined}
              maxValue={999}
              style={{ textAlign: "center" }}
              minutesSuffixNeeded={false}
              max={999}
              minValue={0}
              isWholeNumber={true}
              disabled
            />
            <div className="text-primary font-size-n1 text-center mt-1">
              Range 1: {`(${props.question.MinValue1}`} &minus;{" "}
              {`${props.question.MaxValue1})`}
            </div>
          </div>
          <div className="intervalInput ml-8">
            <FloatingLabelNumberInput
              isAddNeeded={false}
              step={1}
              label={""}
              placeholder={""}
              nameForInput={"number2"}
              handleOptionToggle={() => undefined}
              value={"Enter Number"}
              setValue={() => undefined}
              onChange={() => undefined}
              scriptFormik={props.scriptFormik}
              lastFocused={[]}
              isFocused={[]}
              setIsFocused={() => undefined}
              setLastFocused={() => undefined}
              maxValue={999}
              style={{ textAlign: "center" }}
              minutesSuffixNeeded={false}
              max={999}
              minValue={0}
              isWholeNumber={true}
              disabled
            />
            <div className="text-primary font-size-n1 text-center mt-1">
              Range 2: {`(${props.question.MinValue2}`} &minus;{" "}
              {`${props.question.MaxValue2})`}
            </div>
          </div>
        </div>
      ) : (
        // Input without Range
        <div className="d-inline-flex align-items-center">
          <div className="intervalInput">
            <FloatingLabelNumberInput
              isAddNeeded={false}
              step={1}
              label={""}
              placeholder={""}
              nameForInput={"number1"}
              handleOptionToggle={() => undefined}
              value={"Enter Number"}
              setValue={() => undefined}
              onChange={() => undefined}
              scriptFormik={props.scriptFormik}
              lastFocused={[]}
              isFocused={[]}
              setIsFocused={() => undefined}
              setLastFocused={() => undefined}
              maxValue={999}
              style={{ textAlign: "center" }}
              minutesSuffixNeeded={false}
              max={999}
              minValue={0}
              isWholeNumber={true}
              disabled
            />
          </div>
          <div className="range-text text-primary ml-4">
            Range: {`(${props.question.MinValue1}`} &minus;{" "}
            {`${props.question.MaxValue1})`}
          </div>
        </div>
      )}
    </div>
  );
}
