import { DatePicker, DatePickerProps } from "antd";
import React from "react";
import { StyledWrapper } from "./DatePickerInput.styled";

type Props = {
  label: string;
  nameForInput: string;
  value: string;
  onChange: Function;
  disabled?: boolean;
};
export default function DatePickerInput({
  label,
  nameForInput,
  value,
  onChange,
  disabled,
}: Props) {
  const [isFocused, setIsFocused] = React.useState(false);
  const labelClass = () => {
    const isDisabled = disabled ? "disabled" : "";
    return isFocused || value
      ? `label ${isDisabled}`
      : `label floatingLabel ${isDisabled}`;
  };
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
    onChange(dateString);
  };

  return (
    <StyledWrapper>
      <label className={labelClass()} htmlFor={nameForInput}>
        {label}
      </label>
      <DatePicker
        placeholder=""
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled || false}
      />
    </StyledWrapper>
  );
}
