import React, { MouseEventHandler } from "react";
import { Col } from "antd";
import {
  BooleanParameter,
  InsultWithRelation,
} from "../../../../../../../models/InsultInterface";
import { isNullOrUndefined } from "../../../../../../../utils/utilityfunctions";

type BooleanParamProps = {
  parameter: BooleanParameter;
  onEditInsult: MouseEventHandler;
  insult: InsultWithRelation;
};

const BooleanParam = ({
  parameter,
  insult,
  onEditInsult,
}: BooleanParamProps) => {
  return (
    <Col className="boolean-param" onClick={onEditInsult}>
      <div>
        <label>{parameter.UserFacingLabel}</label>
        <span className="text-primary">
          {!isNullOrUndefined(parameter.ValueToApply)
            ? parameter.ValueToApply
              ? parameter.TrueUserFacingText
              : parameter.FalseUserFacingText
            : parameter.Default}
        </span>
      </div>
    </Col>
  );
};

export default BooleanParam;
