import * as React from "react";
import { Component } from "react";
import { Form } from "antd";
import "@ant-design/compatible/assets/index.css";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Row,
  Modal,
  Col,
  Input,
  Select,
  Button,
  Checkbox,
  Upload,
  Table,
  Avatar,
  Drawer,
} from "antd";
import { canadaDateFormat } from "../../../utils/dateUtils";
import { deleteFile } from "../../../api/Resource/ApiPost";
import axios from "axios";
import { ColumnsType } from "antd/es/table";
import { connect } from "react-redux";
import { AlertType } from "../../../models/Enums";
import { SystemMessage } from "../../../app/modules";
import { setAlertMessage } from "../../../actions/alertMessage";

const { Option } = Select;
const { confirm } = Modal;
const token = sessionStorage.token;

interface ObjectList {
  key: number;
  name: string;
  url: string;
  fileName: string;
  march: string;
  createdAt: string;
  resourceType: any;
}
interface Props {
  resources: ObjectList[];
  deleteFile: Function;
  AddResource: Function;
  user: any;
  SystemMessage: SystemMessage;
  setAlertMessage: Function;
}
interface State {
  loading: boolean;
  addModal: boolean;
  name: string;
  url: string;
  fileName: string;
  resourceTypeId: number;
  uploading: boolean;
  ImagePath: string;
  newVersion: boolean;
  checked: boolean;
  march: any;
}

class ObjectList extends Component<Props, State> {
  state: State = {
    loading: false,
    addModal: false,
    name: "",
    url: "",
    fileName: "",
    resourceTypeId: 0,
    uploading: false,
    ImagePath: "",
    newVersion: false,
    checked: false,
    march: ["0", "0", "0", "0", "0"],
  };

  handleChange = (input: any) => (e: any) => {
    this.setState({ [input]: e.target.value } as any);
  };

  handleImageChange = (fileList: any) => {
    const response = fileList.file;
    if (fileList.file.status === "error") {
      this.setState({ uploading: false });
    }
    if (fileList.file.status === "uploading") {
      this.setState({ uploading: true });
      return;
    }
    // eslint-disable-next-line no-prototype-builtins
    if (response.hasOwnProperty("xhr")) {
      if (
        !(this.state.url === null || this.state.url === undefined) &&
        response.response !== this.state.url
      ) {
        const filename = this.state.url.substring(
          this.state.url.lastIndexOf("/") + 1,
        );
        deleteFile(filename, this.success, this.error);
      }
      this.setState({ url: response.response, uploading: false });
    }
    //console.log(this.state.fileList);
  };

  success = (data: any) => {
    console.log("success");
    //this.props.setAlertMessage({
    //    systemMessage: {
    //        message: "Success",
    //        type: AlertType.success,
    //        description: "success",
    //        messageShowTime: 3000,
    //    }
    //});
  };

  beforeUpload = () => async (file: any) => {
    console.log(file);
    const m = await new Promise<void>((resolve: any, reject: any) => {
      console.log(file.type);

      axios
        .get(`api/v1/realx/Resource/check-3dAsset/${file.name}`)
        .then((result) => {
          console.log(result);
          if (!result.data) {
            resolve();
          } else {
            confirm({
              title: "File is already uploaded",
              content: "Would you like to upload the file anyways?",
              okText: "Yes",
              onOk: () => {
                this.setState((state: any) => {
                  return { newVersion: true };
                });
                resolve();
              },
              cancelText: "No",
              onCancel: () => {
                reject(false);
              },
            });
          }
        })
        .catch((error: any) => {
          // message.error("There was a problem with uploading the file. Please try again");
          this.props.setAlertMessage({
            systemMessage: {
              message: "Error",
              type: AlertType.error,
              description:
                "There was a problem with uploading the file. Please try again",
              messageShowTime: 3000,
              position: "top-center",
            },
          });
          console.log(error);
        });
    });
    return m;
  };

  upload = (options: any) => {
    this.setState((state: any) => {
      return { loading: true };
    });
    console.log(options);
    const bodyFormData = new FormData();
    bodyFormData.append("file", options.file);
    bodyFormData.append("newVersion", this.state.newVersion.toString());
    axios({
      method: "post",
      url:
        process.env.REACT_APP_REALXBE + "api/v1/realx/Resource/upload3dAssets",
      data: bodyFormData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type":
          "multipart/form-data; boundary=---------------------------81801171514357",
      },
    })
      .then((response) => {
        //handle success
        console.log(response);
        this.setState({
          url: response.data.url,
          uploading: false,
          newVersion: false,
        });
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  addResource = async () => {
    if (this.state.name === "") {
      this.setState({
        name: "",
        resourceTypeId: 0,
        url: "",
        addModal: false,
        fileName: "",
        march: ["0", "0", "0", "0", "0"],
      });
    } else {
      const marchString = this.state.march.join("");
      const march = parseInt(marchString, 2);
      const postObj = {
        Name: this.state.name,
        FileName: this.state.fileName,
        ResourceTypeId: this.state.resourceTypeId,
        Url: this.state.url,
        March: march,
      };
      console.log(postObj);
      await this.props.AddResource(postObj);
      this.setState({
        name: "",
        fileName: "",
        resourceTypeId: 0,
        url: "",
        addModal: false,
        march: ["0", "0", "0", "0", "0"],
      });
    }
  };

  handleSelect = (e: any) => {
    console.log(e);
    this.setState({ resourceTypeId: e } as any);
  };

  handleSelectIcon = (e: any) => {
    console.log(e);
    this.setState({ url: e } as any);
  };

  onCheck = (e: any) => {
    this.setState({ checked: e.target.checked });
  };

  error = (data: any) => {
    console.log(data);
    // message.error("An error occured check the console for more information");
    this.props.setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An error occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
  };

  handleMarchChange = (index: number) => {
    console.log(index);
    const march = this.state.march;
    if (march[index] === "0") march[index] = "1";
    else march[index] = "0";
    this.setState({ march: march });
  };

  getMarchArray = (march: any) => {
    const marchString = String("00000" + march.toString(2)).slice(-5);
    const marchArray = Array.from(marchString);
    return (
      <Row>
        <span
          className={
            marchArray[0] === "0"
              ? "marchLetterScenario"
              : "selectedMarchLetterScenario"
          }
        >
          M
        </span>
        <span
          className={
            marchArray[1] === "0"
              ? "marchLetterScenario"
              : "selectedMarchLetterScenario"
          }
        >
          A
        </span>
        <span
          className={
            marchArray[2] === "0"
              ? "marchLetterScenario"
              : "selectedMarchLetterScenario"
          }
        >
          R
        </span>
        <span
          className={
            marchArray[3] === "0"
              ? "marchLetterScenario"
              : "selectedMarchLetterScenario"
          }
        >
          C
        </span>
        <span
          className={
            marchArray[4] === "0"
              ? "marchLetterScenario"
              : "selectedMarchLetterScenario"
          }
        >
          H
        </span>
      </Row>
    );
  };

  render() {
    const columns: ColumnsType<ObjectList> = [
      {
        title: "URL",
        dataIndex: "url",
        key: "URL",
        className: "whiteText",
        render: (url: string) => (
          <Avatar shape="square" size={70} src={url} alt="avatar" />
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "Name",
        className: "whiteText",
        render: (name: string) => <span>{name}</span>,
      },
      {
        title: "Resource Type",
        dataIndex: "resourceType",
        key: "ResourceType",
        className: "whiteText",
        render: (resourceType: any) => <span>{resourceType.name}</span>,
      },
      {
        title: "File Name",
        dataIndex: "fileName",
        key: "FileName",
        className: "whiteText",
        render: (fileName: string) => <span>{fileName}</span>,
      },
      {
        title: "March",
        dataIndex: "march",
        key: "march",
        className: "whiteText",
        render: (march: any) => <div>{this.getMarchArray(march)}</div>,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "CreatedAt",
        className: "whiteText",
        render: (createdAt: string) => (
          <span>{canadaDateFormat(createdAt)}</span>
        ),
      },
    ];

    // <LegacyIcon type={this.state.uploading ? 'loading' : 'plus'} /> // from line 334
    const uploadButton = (
      <div className="UploadButtonOrder">
        this.state.uploading ? <LoadingOutlined /> : <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div>
        {this.props.user.type === "root" ||
        this.props.user.type.includes("root") ? (
          <Button
            className="ant-btn realx-btn-blue"
            icon={<PlusOutlined />}
            style={{ marginBottom: "20px", width: "200px" }}
            onClick={() => {
              this.setState({ addModal: true });
            }}
          >
            Add a 3D Asset
          </Button>
        ) : null}
        <Table
          dataSource={this.props.resources}
          columns={columns}
          rowKey={(record: any) => record.id}
          pagination={{ pageSize: 10 }}
          rowClassName={(record) => "rxblackBG"}
        />

        <Drawer
          title="Add a 3D asset"
          visible={this.state.addModal}
          onClose={this.addResource}
          closable
          width="600"
        >
          <Form.Item>
            <label className="whiteText">Name: </label>
            <Input
              value={this.state.name}
              placeholder="Name"
              allowClear
              onChange={this.handleChange("name")}
              style={{ marginBottom: "30px" }}
            />
          </Form.Item>
          <Form.Item>
            <label className="whiteText">Resource Type: </label>
            <Select
              value={this.state.resourceTypeId}
              onChange={this.handleSelect}
              placeholder="Select a resource type"
              style={{ marginLeft: "10px", width: "150px" }}
            >
              <Option value={7}>Animation</Option>
              <Option value={8}>Model</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <label className="whiteText">File: </label>
            <Row>
              <Col span={12}>
                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload()}
                  customRequest={this.upload}
                  onChange={this.handleImageChange}
                  disabled={this.state.uploading || this.state.checked}
                  accept="image/*"
                  className="clear-none"
                >
                  {this.state.url && !this.state.uploading ? (
                    <img
                      src={this.state.url}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Col>
              <Col span={12}>
                <Checkbox onChange={this.onCheck}>
                  <span className="whiteText">Select from default icons</span>
                </Checkbox>
                <Select
                  style={{ width: "100%" }}
                  disabled={!this.state.checked}
                  value={this.state.url}
                  onChange={this.handleSelectIcon}
                  placeholder="Select a default icon"
                >
                  <Option value="https://realxresources.s3.ca-central-1.amazonaws.com/c407e374-2aa2-41be-afac-5f95c1c6a122/Resources/PR_3d.png">
                    <Avatar
                      shape="square"
                      size={40}
                      src="https://realxresources.s3.ca-central-1.amazonaws.com/c407e374-2aa2-41be-afac-5f95c1c6a122/Resources/PR_3d.png"
                      alt="avatar"
                    />
                  </Option>
                  <Option value="https://realxresources.s3.ca-central-1.amazonaws.com/c407e374-2aa2-41be-afac-5f95c1c6a122/Resources/PR_animation.png">
                    <Avatar
                      shape="square"
                      size={40}
                      src="https://realxresources.s3.ca-central-1.amazonaws.com/c407e374-2aa2-41be-afac-5f95c1c6a122/Resources/PR_animation.png"
                      alt="avatar"
                    />
                  </Option>
                </Select>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <label className="whiteText">File Name: </label>
            <Input
              value={this.state.fileName}
              placeholder="File Name"
              allowClear
              onChange={this.handleChange("fileName")}
              style={{ marginBottom: "30px" }}
            />
          </Form.Item>
          <Row>
            <Row className={"white-text"}>MARCH Group</Row>
            <Row>
              <span
                onClick={() => this.handleMarchChange(0)}
                className={
                  this.state.march[0] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                M
              </span>
              <span
                onClick={() => this.handleMarchChange(1)}
                className={
                  this.state.march[1] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                A
              </span>
              <span
                onClick={() => this.handleMarchChange(2)}
                className={
                  this.state.march[2] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                R
              </span>
              <span
                onClick={() => this.handleMarchChange(3)}
                className={
                  this.state.march[3] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                C
              </span>
              <span
                onClick={() => this.handleMarchChange(4)}
                className={
                  this.state.march[4] === "0"
                    ? "marchLetter"
                    : "selectedMarchLetter"
                }
              >
                H
              </span>
            </Row>
          </Row>
        </Drawer>
      </div>
    );
  }
}
//export default ObjectList;
function mapToStateToProps(state: any) {
  return {
    SystemMessage: state.SystemMessage,
  };
}

export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(ObjectList);
