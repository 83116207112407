import { Col } from "antd";
import React, { useEffect, useState } from "react";
import {
  InsultWithRelation,
  NumberParameter,
  Location,
  BodyPart,
} from "../../../../../../../models/InsultInterface";
import { getSurfaceAreaValue } from "../../CommonData";
import { LocationFormRow, SideButton } from "../../InjuryTab.styled";
import SelectParameterValue from "./SelectParameterValue";
import { isNullOrUndefined } from "../../../../../../../utils/utilityfunctions";
import {
  getLocationsBasedOnInsult,
  hideLocationUIForInsults,
} from "../../InsultInjuryUtility";
import { OpenInfoNotification } from "../../../../../../../components/Notification/Notification";
type LocationParameterProps = {
  formik: any;
  insult?: InsultWithRelation;
  bodyParts: BodyPart[];
  isLocationDisableForInjuryInsult?: boolean;
};

const LocationParameter = ({
  formik,
  insult,
  bodyParts,
  isLocationDisableForInjuryInsult,
}: LocationParameterProps) => {
  const [selectedBodyPart, setSelectedBodyPart] = useState<
    BodyPart | undefined
  >(bodyParts[0]);
  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >();
  const [selectedSide, setSelectedSide] = useState(
    insult?.AppliedLocationSide || formik.values["appliedLocationSide"],
  );
  const setLocationSide = (locationSide: string) => {
    if (locationSide) {
      setSelectedSide(locationSide);
      formik.setFieldValue("appliedLocationSide", locationSide);
    } else {
      setSelectedSide("");
      formik.setFieldValue("appliedLocationSide", "");
    }
  };
  const handleLocationChange = (BodyPartId: number) => {
    const bodyPart: BodyPart | undefined = bodyParts.find(
      (x) => x.Id === BodyPartId,
    );

    // for burn insult only
    if (insult?.Label.toLowerCase() === "burn") {
      setLocationBasedSurfaceArea(bodyPart?.Locations[0]);
    }

    setSelectedBodyPart(bodyPart);
    setSelectedLocation(undefined);
    formik.setFieldValue("locationId", bodyPart?.Locations[0]);
  };

  const handleSubLocationChange = (locationId: number) => {
    const location = selectedBodyPart?.Locations.find(
      (x) => x.Id === locationId,
    );

    // for burn insult only
    if (insult?.Label.toLowerCase() === "burn") {
      setLocationBasedSurfaceArea(location);
    }
    setSelectedLocation(location);

    if (location?.SideCanBeLeft) {
      setLocationSide("Left");
    } else if (location?.SideCanBeCenter) {
      setLocationSide("Center");
    } else if (location?.SideCanBeRight) {
      setLocationSide("Right");
    }
  };

  const handleSideSelection = (side: string) => {
    if (isLocationDisableForInjuryInsult) {
      return true; // disable side location input
      // return true; // disable side location input
    }
    setLocationSide(side);
  };

  const setLocationBasedSurfaceArea = (location: Location | undefined) => {
    if (!isNullOrUndefined(location)) {
      const numberParameter = insult?.NumberParameters.find(
        (x: NumberParameter) => x.UserFacingLabel.toLowerCase() === "tbsa",
      );
      if (numberParameter) {
        const value = getSurfaceAreaValue(
          location.BodyPart?.Name,
          location?.Detail,
        );
        formik.setFieldValue(
          `number_param_${insult && insult.Label}_${
            numberParameter.UserFacingLabel
          }`,
          value,
          true,
        );
      }
    }
  };

  const displayMessage = () => {
    if (isLocationDisableForInjuryInsult === true)
      return OpenInfoNotification({
        description:
          "Unable to Change Injury Location once insults have been added.  Please create a new model to add more insults.",
      });
  };

  useEffect(() => {
    if (bodyParts.length) {
      const bodyPart = bodyParts.find(
        (x) => x.Id === formik.values["bodyPartId"],
      );
      if (!isNullOrUndefined(bodyPart)) {
        setSelectedBodyPart(bodyPart);

        const location = bodyPart.Locations?.find(
          (x) => x.Id === formik.values["locationId"],
        );

        if (!isNullOrUndefined(location)) {
          setSelectedLocation(location);
          formik.setFieldValue("locationId", location.Id);
          if (location?.Side && !selectedSide) {
            setLocationSide(location?.Side);
          }
        } else if (bodyPart?.Locations.length) {
          const location = getLocationsBasedOnInsult(
            insult,
            selectedBodyPart,
          )[0];
          setSelectedLocation(location);
          formik.setFieldValue("locationId", location.Id);
          if (location?.Side) {
            setLocationSide(location?.Side);
          }
        }
      } else {
        const bodyPart = bodyParts[0];
        setSelectedBodyPart(bodyPart);
        formik.setFieldValue("bodyPartId", bodyPart?.Id);
      }
    }
  }, [formik.values["bodyPartId"]]);

  return (
    <>
      {!hideLocationUIForInsults.includes(
        insult?.Label?.toLowerCase() || "",
      ) && (
        <LocationFormRow
          className={isLocationDisableForInjuryInsult ? "readOnly" : ""}
        >
          <Col span={9}>
            <SelectParameterValue
              disabled={isLocationDisableForInjuryInsult ? true : false}
              label="Location"
              field="bodyPartId"
              customClassName="w-100"
              formik={formik}
              OnChange={handleLocationChange}
              selectOptions={bodyParts?.map((bodyPart: any) => ({
                Value: bodyPart.Id,
                Label: bodyPart.Name,
                Disabled: bodyPart?.disabled,
              }))}
            />
          </Col>
          <Col span={9}>
            {!isNullOrUndefined(selectedBodyPart) ? (
              <SelectParameterValue
                disabled={
                  isLocationDisableForInjuryInsult ||
                  getLocationsBasedOnInsult(insult, selectedBodyPart)?.length <
                    2
                    ? true
                    : false
                }
                label="Sub-Location"
                field="locationId"
                customClassName="w-100"
                formik={formik}
                OnChange={handleSubLocationChange}
                selectOptions={getLocationsBasedOnInsult(
                  insult,
                  selectedBodyPart,
                ).map((location: Location) => ({
                  Value: location?.Id,
                  Label: location?.Detail,
                }))}
              />
            ) : null}
          </Col>
          <Col span={6}>
            {(selectedLocation?.SideCanBeLeft &&
              selectedLocation.SideCanBeCenter) ||
            (selectedLocation?.SideCanBeCenter &&
              selectedLocation.SideCanBeRight) ||
            (selectedLocation?.SideCanBeRight &&
              selectedLocation.SideCanBeLeft) ||
            (selectedLocation?.SideCanBeLeft &&
              selectedLocation.SideCanBeCenter &&
              selectedLocation.SideCanBeRight) ? (
              <div className="sides">
                {!!selectedLocation?.SideCanBeLeft && (
                  <SideButton
                    disabled={false}
                    onClick={
                      isLocationDisableForInjuryInsult
                        ? () => displayMessage()
                        : () => handleSideSelection("Left")
                    }
                    isSelected={selectedSide === "Left"}
                  >
                    L
                  </SideButton>
                )}
                {!!selectedLocation?.SideCanBeCenter && (
                  <SideButton
                    disabled={false}
                    onClick={
                      isLocationDisableForInjuryInsult
                        ? () => displayMessage()
                        : () => handleSideSelection("Center")
                    }
                    isSelected={selectedSide === "Center"}
                  >
                    C
                  </SideButton>
                )}
                {!!selectedLocation?.SideCanBeRight && (
                  <SideButton
                    disabled={false}
                    onClick={
                      isLocationDisableForInjuryInsult
                        ? () => displayMessage()
                        : () => handleSideSelection("Right")
                    }
                    isSelected={selectedSide === "Right"}
                  >
                    R
                  </SideButton>
                )}
              </div>
            ) : null}
          </Col>
        </LocationFormRow>
      )}
    </>
  );
};

export default LocationParameter;
