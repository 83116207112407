import axios from "axios";

const apiPrefix = "/api/1/pfc/User-Profile";

export function UpdateUserProfile(
  payload: {
    firstName: string;
    lastName: string;
  },
  callback: Function,
  errorcallback: Function,
) {
  axios
    .put(`${apiPrefix}`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}
