import * as React from "react";
import { Component } from "react";
import { DeleteOutlined, EditFilled, EyeOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";
import { Row, Modal, Col, message } from "antd";

interface Props {
  resources: any[];
  deleteFile: Function;
  editResource: Function;
}
interface State {
  loading: boolean;
  showImage: boolean;
  previewImg: string;
}

class VideoList extends Component<Props, State> {
  state: State = {
    loading: false,
    showImage: false,
    previewImg: "",
  };

  componentDidMount() {
    console.log(this.props);
  }

  modalToggle = () => {
    this.setState({ showImage: !this.state.showImage });
  };

  preview = (url: string) => {
    this.setState({ previewImg: url, showImage: true });
  };

  error = (data: any) => {
    console.log(data);
    message.error("An error occured check the console for more information");
  };

  render() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {this.props?.resources?.map((item: any) => (
          <Col
            xs={24}
            sm={8}
            md={6}
            lg={4}
            xl={3}
            style={{
              padding: "5px",
              overflow: "hidden",
              height: "220px",
              display: "inline-block",
            }}
            key={item.id}
          >
            <Row style={{ height: "150px" }}>
              <div className="showImgName">
                <div style={{ marginTop: "30%" }}>
                  <DeleteOutlined
                    style={{
                      fontSize: "2.3em",
                      marginRight: "10px",
                      color: "#f00",
                    }}
                    onClick={() =>
                      this.props.deleteFile(item.fileName, item.id)
                    }
                  />
                  <EyeOutlined
                    style={{ fontSize: "2.3em", color: "#0f0" }}
                    onClick={() => this.preview(item.url)}
                  />
                  <EditFilled
                    style={{
                      fontSize: "2.3em",
                      marginLeft: "10px",
                      color: "#f5e218",
                    }}
                    onClick={() => this.props.editResource(item)}
                  />
                </div>
              </div>
              <img
                alt=""
                style={{ width: "100%", height: "100%" }}
                src={
                  item.url.substring(0, item.url.lastIndexOf("/") + 1) +
                  "thumbnails/" +
                  item.url.substring(
                    item.url.lastIndexOf("/") + 1,
                    item.url.lastIndexOf(".")
                  ) +
                  ".jpg"
                }
              />
            </Row>
            <Row>{item.name}</Row>
          </Col>
        ))}
        <Modal
          visible={this.state.showImage}
          title="Preview"
          onCancel={this.modalToggle}
          footer={null}
          width={1200}
          style={{ height: "800px" }}
        >
          <div style={{ width: "100%", height: "600px" }}>
            <video
              key={this.state.previewImg}
              style={{ width: "100%", height: "100%" }}
              controls
            >
              <source src={this.state.previewImg} />
            </video>
          </div>
        </Modal>
      </div>
    );
  }
}
export default VideoList;
