import React from "react";
import { AvpuScaleCharacter } from "../../../../../models/Enums";

interface Props {
  value: number;
}

export default function LevelOfConsciousness(props: Props) {
  return (
    <div className="consciousness">
      <ul>
        <li className="label">Level of Consciousness:</li>
        {[
          AvpuScaleCharacter["A"],
          AvpuScaleCharacter["V"],
          AvpuScaleCharacter["P"],
          AvpuScaleCharacter["U"],
        ].map((op: number) => {
          return (
            <li
              key={`consciousness-` + op}
              className={`options ${props.value === op ? "selected" : ""}`}
            >
              {AvpuScaleCharacter[op]}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
