export const ProfileMenuList = [
  {
    name: "My Preferences",
    url: "myPreferences",
    key: "myPreferences",
    title: "Coming soon",
    disabled: true,
  },
  {
    name: "Privacy Policy",
    url: "privacyPolicy",
    key: "privacyPolicy",
    title: "Coming soon",
    disabled: true,
  },
  {
    name: "Log Out",
    url: "logout",
    key: "logout",
    title: "Log Out",
    disabled: false,
  },
];
