import { Row } from "antd";
import styled from "styled-components";

export const TimedActionExpandableBlockSubtaskContainer = styled(Row)`
  border-bottom: 1px solid #161616;

  div:not(:first-child) {
    display: flex;
    align-items: center;
  }

  div:nth-child(2) {
    padding: 15px;
    border-right: 1px solid #161616;
  }

  .time {
    justify-content: center;
  }
`;
