import React from "react";
import Login from "../../pages/Authentication/LogIn/Login";
import Counter from "../../components/StyleTest";
import CreateResources from "../../pages/CreateResources/CreateResources";
import CreateMasters from "../../pages/CreateMasterObjects/CreateMasters";
import ScriptBuilder from "../../pages/ScriptBuilder/ScriptBuilder";
import ViewScripts from "../../pages/ViewScripts/ViewScripts";
import User from "../../pages/User/User";
import Session from "../../pages/Session/Session";
import UserInvitationFrom from "../../pages/Authentication/UserInvitation/UserInvitationForm";
import ResetPasswordSuccess from "../../pages/Authentication/ResetPassword/ResetPasswordSuccess";
import UserInvitationSuccess from "../../pages/Authentication/UserInvitation/UserInvitationSuccess";
import ConfirmEmail from "../../pages/Authentication/ConfirmEmail";
import ForgotPasswordSuccess from "../../pages/Authentication/ForgotPassword/ForgetPasswordSuccess";
import ResetPasswordForm from "../../pages/Authentication/ResetPassword/ResetPasswordForm";
import ForgotPasswordForm from "../../pages/Authentication/ForgotPassword/ForgotPasswordForm";
import CreateCompanyFrom from "../../pages/Authentication/SignUp/CreateCompanyForm";
import UserSignUpForm from "../../pages/Authentication/SignUp/UserSignUpForm";
import RequestCompanyAccessForm from "../../pages/Authentication/SignUp/RequestCompanyAccessForm";
import RequestCompanyAccessSuccess from "../../pages/Authentication/SignUp/RequestCompanyAccessSuccess";
import Dashboard from "../../pages/Home/Dashboard";
import AfterActionReview from "../../pages/Home/AfterActionReview/AfterActionReview";
export interface PathComponent {
  path: string;
  component: any;
}
export const notLoggedInRoutes: Array<PathComponent> = [
  { path: "/", component: Login },
  { path: "/login", component: Login },
  { path: "/management/login", component: Login },
  { path: "/company/sign-up", component: CreateCompanyFrom },
  { path: "/company/request-access", component: RequestCompanyAccessForm },
  {
    path: "/company/request-access/success",
    component: RequestCompanyAccessSuccess,
  },
  { path: "/user/sign-up", component: UserSignUpForm },
  { path: "/forgot-password", component: ForgotPasswordForm },
  { path: "/forgot-password/success", component: ForgotPasswordSuccess },
  { path: "/theme-test", component: Counter },
  { path: "/counter", component: Counter },
  { path: "/reset-password/", component: ResetPasswordForm },
  { path: "/reset-password/success", component: ResetPasswordSuccess },
  { path: "/accept-invitation", component: UserInvitationFrom },
  { path: "/accept-invitation/success", component: UserInvitationSuccess },
  { path: "/confirm-email", component: ConfirmEmail },
];
export const loggedInRoutes: Array<PathComponent> = [
  { path: "/", component: ViewScripts }, // This is temporary after demo change this to Home
  { path: "/dashboard", component: Dashboard },
  { path: "/after-action-review/:id", component: AfterActionReview },
  { path: "/createResource", component: CreateResources },
  { path: "/CreateScript/:id?", component: ScriptBuilder },
  { path: "/EditScript/:id", component: ScriptBuilder },
  { path: "/ScriptBuilder/:id", component: ScriptBuilder },
  { path: "/ViewScript", component: ViewScripts },
  { path: "/account/", component: User },
  { path: "/account/:activeTab", component: User },
  { path: "/sessions", component: Session },
];

export const ownerRoutes: Array<PathComponent> = [
  { path: "/", component: ViewScripts }, // This is temporary after demo change this to Home
  { path: "/dashboard", component: Dashboard },
  { path: "/after-action-review/:id", component: AfterActionReview },
  { path: "/createResource", component: CreateResources },
  { path: "/create-masters", component: CreateMasters },
  { path: "/create-masters/:activeTab", component: CreateMasters },
  { path: "/CreateScript/:id?", component: ScriptBuilder },
  { path: "/EditScript/:id", component: ScriptBuilder },
  { path: "/ScriptBuilder/:id", component: ScriptBuilder },
  { path: "/ViewScript", component: ViewScripts },
  { path: "/account/", component: User },
  { path: "/account/:activeTab", component: User },
  { path: "/sessions", component: Session },
];

export const adminRoutes: Array<PathComponent> = [
  { path: "/", component: ViewScripts }, // This is temporary after demo change this to Home
  { path: "/dashboard", component: Dashboard },
  { path: "/after-action-review/:id", component: AfterActionReview },
  { path: "/createResource", component: CreateResources },
  { path: "/create-masters", component: CreateMasters },
  { path: "/create-masters/:activeTab", component: CreateMasters },
  { path: "/CreateScript/:id?", component: ScriptBuilder },
  { path: "/EditScript/:id", component: ScriptBuilder },
  { path: "/ScriptBuilder/:id", component: ScriptBuilder },
  { path: "/ViewScript", component: ViewScripts },
  { path: "/account/", component: User },
  { path: "/account/:activeTab", component: User },
  { path: "/sessions", component: Session },
];
export const managerRoutes: Array<PathComponent> = [
  { path: "/", component: ViewScripts }, // This is temporary after demo change this to Home
  { path: "/dashboard", component: Dashboard },
  { path: "/after-action-review/:id", component: AfterActionReview },
  { path: "/createResource", component: CreateResources },
  { path: "/create-masters", component: CreateMasters },
  { path: "/create-masters/:activeTab", component: CreateMasters },
  { path: "/CreateScript/:id?", component: ScriptBuilder },
  { path: "/EditScript/:id", component: ScriptBuilder },
  { path: "/ScriptBuilder/:id", component: ScriptBuilder },
  { path: "/ViewScript", component: ViewScripts },
  { path: "/account/", component: User },
  { path: "/account/:activeTab", component: User },
  { path: "/sessions", component: Session },
];

export const globalRoutes: Array<PathComponent> = [
  { path: "/counter", component: Counter },
];
