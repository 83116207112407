import React from "react";
import MailIcon from "../../../assets/icons/Mail.svg";
import { useHistory } from "react-router";
import AuthenticationPageLeftPane from "../../../components/CenterScreenCard/AuthenticationPageLeftPane";
import { AuthenticationContainer } from "../Authorization.styled";

const ForgotPasswordSuccess = () => {
  const history = useHistory();
  return (
    <AuthenticationPageLeftPane title="Request Sent!" displayLoginLink>
      <AuthenticationContainer>
        <div className="success">
          <img className="success-logo" src={MailIcon} alt="Mail Logo" />
          <h1 className="message">
            If this account exists, you will receive an email. Please click the
            link in the email to reset your password.
          </h1>
          <p className="expiry-text">The link expires in 10 minutes.</p>
        </div>
      </AuthenticationContainer>
    </AuthenticationPageLeftPane>
  );
};

export default ForgotPasswordSuccess;
