import { Col } from "antd";
import React from "react";
import {
  BooleanParameter,
  InsultWithRelation,
  IntervalParameter,
  NumberParameter,
  TextParameter,
} from "../../../../../../models/InsultInterface";
import {
  formatTimeParameter,
  NumberParameterWithTimeInterval,
} from "../CommonData";
import { InsultItemRow, TextParamCol } from "../InjuryTab.styled";
import { ShowInsultParameter, hideNumberParam } from "../InjuryMapUtills";
import {
  getInsultLabelDisplayValue,
  hideLocationUIForInsults,
} from "../InsultInjuryUtility";
import { useAppSelector } from "../../../../../../store/hooks";
import useRenderLocationAs from "../../../../../../hooks/useRenderLocationAs";
type Props = {
  formik: any;
  insult: InsultWithRelation;
};

const InsultRow = ({ insult, formik }: Props) => {
  const [renderLocationAs] = useRenderLocationAs(
    formik.values["locationId"],
    formik.values["appliedLocationSide"],
  );
  const injuryObj = useAppSelector((state) => state.InsultInjury.InjuryObj);
  const isLocationDisableForInjuryInsult = !!injuryObj.Title;

  const showBooleanParameter = (param: BooleanParameter) => {
    // Hide circumferential param column if the selected location is not
    // Leg or Arm.
    if (
      param.UserFacingLabel?.toLocaleLowerCase() === "circumferential" &&
      !(
        (
          formik.values["bodyPartId"] === 3 || // bodyPartId = 3 Arm
          formik.values["bodyPartId"] === 8
        ) // bodyPartId = 8 Leg
      )
    ) {
      return;
    }

    // Show boolean params.
    return (
      <Col key={"boolean_param_" + param.Id} className="boolean-param">
        <div>
          <label>{param.UserFacingLabel}</label>
          <span className="text-primary">
            {formik.values["boolean_param_" + param.Id]
              ? param.TrueUserFacingText
              : param.FalseUserFacingText}
          </span>
        </div>
      </Col>
    );
  };

  const getNumberparamValue = () => {
    if (insult.Label?.toLocaleLowerCase() === "burn") {
      return formik.values[`number_param_${insult.Label}_Degree`];
    }

    return 0;
  };

  return (
    <InsultItemRow $insult={insult}>
      <Col flex="auto" className="insult-title">
        <span>{getInsultLabelDisplayValue(insult, getNumberparamValue())}</span>
      </Col>
      {insult.BooleanParameters && insult.BooleanParameters.length
        ? insult.BooleanParameters.map((param: BooleanParameter) => {
            return showBooleanParameter(param);
          })
        : null}
      {insult.BodyParts &&
      !hideLocationUIForInsults.includes(insult?.Label?.toLowerCase() || "") &&
      !isLocationDisableForInjuryInsult ? (
        <Col className="location-param">
          <div>
            <label>Location</label>
            <span className="text-primary">{renderLocationAs}</span>
          </div>
        </Col>
      ) : null}
      {insult.TextParameters && insult.TextParameters.length
        ? insult.TextParameters.map((param: TextParameter) => {
            return (
              <TextParamCol
                $insult={insult}
                parameter={param}
                key={"text_param_" + param.Id}
                className="text-param"
              >
                <div>
                  <label>{param.UserFacingLabel}</label>
                  <span className="text-primary">
                    {formik.values["text_param_" + param.Id]
                      ? formik.values["text_param_" + param.Id]
                      : "-"}
                  </span>
                </div>
              </TextParamCol>
            );
          })
        : null}
      {insult.IntervalParameters && insult.IntervalParameters.length
        ? insult.IntervalParameters.map((param: IntervalParameter) => {
            return (
              <Col className="interval-param">
                <div>
                  <label>{param.UserFacingLabel}</label>
                  <span className="text-primary">
                    {param.UpperDefault} {param.LowerDefault}
                  </span>
                </div>
              </Col>
            );
          })
        : null}
      {insult.NumberParameters && insult.NumberParameters.length
        ? insult.NumberParameters.filter(
            (param: NumberParameter) =>
              ShowInsultParameter(param) && hideNumberParam(param),
          ).map((param: NumberParameter) => {
            return NumberParameterWithTimeInterval.includes(
              param.UserFacingLabel,
            ) ? (
              <Col
                key={`number_param_${insult.Label}_${param.UserFacingLabel}`}
                className="time-param"
              >
                <div>
                  <label>{param.UserFacingLabel}</label>
                  <span className="text-prim/ary">
                    {formik.values["time_interval_minute_" + param.Id]
                      ? formatTimeParameter(
                          formik.values["time_interval_minute_" + param.Id],
                        )
                      : "00"}
                    :
                    {formik.values["time_interval_second_" + param.Id]
                      ? formatTimeParameter(
                          formik.values["time_interval_second_" + param.Id],
                        )
                      : "00"}
                  </span>
                  <span className="units">min &nbsp; sec</span>
                </div>
              </Col>
            ) : (
              <Col
                key={`number_param_${insult.Label}_${param.UserFacingLabel}`}
                className="number-param"
              >
                <div>
                  <label>{param.UserFacingLabel}</label>
                  <span className="text-primary">
                    {formik.values[
                      `number_param_${insult.Label}_${param.UserFacingLabel}`
                    ]
                      ? param.UserFacingLabel.toLocaleLowerCase() === "severity"
                        ? formik.values[
                            `number_param_${insult.Label}_${param.UserFacingLabel}`
                          ] *
                          10 *
                          param.Maximum
                        : formik.values[
                            `number_param_${insult.Label}_${param.UserFacingLabel}`
                          ]
                      : 0}
                    {param.Units && param.Units === "%" ? (
                      <span className="units">{param.Units}</span>
                    ) : null}
                  </span>
                  {param.Units && param.Units !== "%" ? (
                    <span className="units">{param.Units}</span>
                  ) : null}
                </div>
              </Col>
            );
          })
        : null}
    </InsultItemRow>
  );
};

export default InsultRow;
