import * as React from "react";
import produce from "immer";
import { Select as AntSelect, Tag } from "antd";
import { SelectProps } from "antd/lib/select";
import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";
import { TagProps } from "antd/lib/tag";
import { CloseOutlined } from "@ant-design/icons";

export const StyledAntSelect = styled.div`
  padding-top: 22px;
  position: relative;
  .error {
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.3s ease;
    font-size: 0.86rem;
    color: ${colorPalette.error};
  }
  .label {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    font-size: 0.86rem;
    color: ${colorPalette.gray25};
    &.floatingLabel {
      font-size: 1.125rem;
      top: 26px;
      left: 11px;
    }
  }
  .ant-select {
  }
  .ant-select-selection-search-input {
    font-size: 1.25rem;
  }
  .ant-select-arrow {
    color: #fff;
  }
  .ant-select-selector {
    min-height: 40px;
    font-size: 1.25rem;
    margin-bottom: 4px;
    border-radius: 0 !important;
  }
  .ant-select-selection-item {
    font-size: 1rem;
    color: #fff;
    .ant-checkbox {
      display: none;
    }
    .anticon-thunderbolt {
      display: none;
    }
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      border: none;
      background-color: ${colorPalette.warning};
      border-radius: 15px;
      padding-left: 14px;
      padding-right: 14px;
      .ant-tag-close-icon {
        margin-top: auto;
        .anticon {
          font-size: 0.9rem;
          color: #fff;
        }
      }
    }
  }
  .ant-select-single {
    .ant-select-selection-item {
      font-size: 1.25rem;
      padding: 4px 0;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-bottom: 1px solid #ffffff;
    box-shadow: none !important;
    background-color: transparent;
    &:focus {
      background-color: #ffffff0d;
      border-bottom: 1px solid ${colorPalette.primary100};
    }
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    color: #fff;
  }

  .ant-select-open {
    background-color: #ffffff0d;
  }

  .ant-checkbox-wrapper {
    color: #ffffff;
  }
`;

interface MuiSelectProps extends SelectProps {
  label?: any;
  value?: any;
  error?: string;
  onBlur?: any;
}

const MuiSelect = (props: MuiSelectProps) => {
  const { label, value: initialValue, error, ...otherProps } = props;

  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState(initialValue || null);
  const labelClass =
    isFocused || value !== null ? "label" : "label floatingLabel";

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    props.onBlur && props.onBlur();
  };

  const handleChange = (value: string, option: any) => {
    setValue(value);
    if (props.onChange) {
      props.onChange(value, option);
    }
  };
  const handleDeselect = (val: string) => {
    setValue(
      produce(value, (draft: string[]) => {
        draft.splice(draft.indexOf(val), 1);
      }),
    );
  };

  interface MuiTagProps extends TagProps {
    label?: any;
  }

  function tagRender({ label, ...otherProps }: MuiTagProps) {
    return (
      <Tag
        {...otherProps}
        title={label}
        className={"ant-select-selection-item"}
        closeIcon={<CloseOutlined title="Delete" />}
      >
        {label}
      </Tag>
    );
  }

  return (
    <StyledAntSelect>
      <label className={labelClass}>{label}</label>
      {!!error && <div className="error">{error}</div>}
      {props.mode && props.mode === "tags" ? (
        <AntSelect
          {...otherProps}
          tagRender={tagRender}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onDeselect={handleDeselect}
          notFoundContent={null}
        ></AntSelect>
      ) : (
        <AntSelect {...otherProps} onChange={handleChange}>
          {props.children}
        </AntSelect>
      )}
    </StyledAntSelect>
  );
};

export default MuiSelect;
