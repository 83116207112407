import React, { useState } from "react";
import {
  ListMenuColumnDiv,
  MenuIconDiv,
  TitleColumnDiv,
} from "../../../styles/EditComponentTheme.styled";
import {
  MenuOutlinedIcons,
  OrderedListOutlinedIcons,
  UnorderedListOutlinedIcons,
} from "../../../IconsTheme/Icons.global";
import { ListTypeDropDown } from "../../../models/Enums";
import { Tooltip } from "antd";
import SwitchWithLabel from "../../../components/Switch/SwitchWithLabel";

interface TitleBlock {
  display: string;
  title: string;
  position: number;
}

interface ListMenuColumnProps {
  type: string;
  blockContentColumnDisplay: boolean;
  blockContentOrder: boolean;
  makeOrderedList: (dual: boolean) => void;
  makeDualColumn: (dual: boolean) => void;
  onChange?: any; //to override function can't be null
  selectedValue?: ListTypeDropDown;
}

interface TextMenuColumnProps {
  type: string;
  blockContentColumnDisplay: boolean;
  makeDualColumn: (dual: boolean) => void;
  handleImportantTextToggle: Function;
  isImportant: boolean;
  object?: any;
  onChange?: any; //to override function can't be null
  selectedValue?: ListTypeDropDown;
  isImportantNeeded: boolean;
}

export const TitleColumn = (props: TitleBlock) => {
  return (
    <TitleColumnDiv>
      <div className="position_div">
        <span> {props.display} </span>
        <span> Position </span>
      </div>
      <div className="display_title">
        <span className="title_span"> {props.title} </span>
        <span> {props.position} </span>
      </div>
    </TitleColumnDiv>
  );
};

export const CurrentColumnCount = 0;

export const TextMenuColumn = (props: TextMenuColumnProps) => {
  const [isImportant, setIsImportant] = React.useState(!props.isImportant);
  const handleToggle = () => {
    setIsImportant(!isImportant);
    props.handleImportantTextToggle(!isImportant);
  };
  return (
    <ListMenuColumnDiv>
      <MenuIconDiv
        currentColumnDisplay={props.blockContentColumnDisplay}
        style={{ justifyContent: "space-between", width: "100%" }}
      >
        <div className="singleDoubleColumn">
          <button
            className="first_button_of_menu"
            onClick={() => {
              props.makeDualColumn(false);
            }}
          >
            <MenuOutlinedIcons
              title="Single Column"
              className="menu_first_icon"
            ></MenuOutlinedIcons>
          </button>
          <button
            className="second_button_of_menu"
            onClick={() => {
              props.makeDualColumn(true);
            }}
          >
            <Tooltip title="Double Column">
              <div>
                <MenuOutlinedIcons
                  title=""
                  className="menu-second-item"
                ></MenuOutlinedIcons>
                <MenuOutlinedIcons
                  title=""
                  className="menu-second-item"
                ></MenuOutlinedIcons>
              </div>
            </Tooltip>
          </button>
        </div>
        {props.isImportantNeeded && (
          <SwitchWithLabel
            leftLabel="Important"
            checked={props.isImportant}
            onChange={handleToggle}
          />
        )}
      </MenuIconDiv>
    </ListMenuColumnDiv>
  );
};

export const ListMenuColumn = (props: ListMenuColumnProps) => {
  const [, setColumnCount] = useState<number>(0);
  return (
    <ListMenuColumnDiv>
      <MenuIconDiv
        currentColumnDisplay={props.blockContentColumnDisplay}
        style={{ justifyContent: "start" }}
      >
        <div className="singleDoubleColumn">
          <button
            className="first_button_of_menu"
            onClick={() => {
              setColumnCount(1);
              props.makeDualColumn(false);
            }}
          >
            <MenuOutlinedIcons
              title="Single Column"
              className="menu_first_icon"
            ></MenuOutlinedIcons>
          </button>
          <button
            className="second_button_of_menu"
            onClick={() => {
              setColumnCount(2);
              props.makeDualColumn(true);
            }}
          >
            <Tooltip title="Double Column">
              <div>
                <MenuOutlinedIcons
                  title=""
                  className="menu-second-item"
                ></MenuOutlinedIcons>
                <MenuOutlinedIcons
                  title=""
                  className="menu-second-item"
                ></MenuOutlinedIcons>
              </div>
            </Tooltip>
          </button>
        </div>
      </MenuIconDiv>
      <MenuIconDiv
        currentColumnDisplay={!props.blockContentOrder}
        style={{ justifyContent: "end" }}
      >
        <div className="orderedUnorderedList">
          <button
            className="first_button_of_menu"
            onClick={() => {
              props.makeOrderedList(true);
            }}
          >
            <OrderedListOutlinedIcons
              title="Ordered List"
              className="menu_first_icon"
            ></OrderedListOutlinedIcons>
          </button>
          <button className="second_button_of_menu">
            <Tooltip title="Unordered List">
              <div
                onClick={() => {
                  props.makeOrderedList(false);
                }}
              >
                <UnorderedListOutlinedIcons
                  title=""
                  className="menu-second-item"
                ></UnorderedListOutlinedIcons>
              </div>
            </Tooltip>
          </button>
        </div>
      </MenuIconDiv>
    </ListMenuColumnDiv>
  );
};
