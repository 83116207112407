import * as React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import { ForkBranchIcons } from "../../../IconsTheme/Icons.global";

interface Author {
  author: string;
  forks: number;
}
export const ScriptAuthor = (props: Author) => {
  const { author, forks } = props;
  return (
    <div className="script-author">
      <h5>Author</h5>
      <div className="author">
        <div className="authors-info">
          <div className="avatar-icon"></div>
          <p>{author}</p>
        </div>
        <div className="script-forks">
          <Tooltip title="Forks">
            <ForkBranchIcons className="fork-icon" />
          </Tooltip>
          <p>
            <a>Forks</a> <span>{forks}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
