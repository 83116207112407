import styled from "styled-components";
import { Button } from "antd";
import { colorPalette, hexTransparencies } from "./MainList.styled";

export const BlueButton = styled(Button).attrs((props: any) => ({
  rounded: props.rounded,
}))`
  ${(props) => props.rounded && `border-radius: 50px !important;`}
  color: #ffffff !important;
  background: #162729;
  border: 1px solid #23d0eb;
  cursor: pointer;

  &:hover {
    background: #254b50;
    border: 1px solid #23d0eb;
    color: #ffffff;
  }
`;

export const BlueButtonReact = styled.button`
  color: #ffffff;
  background: #162729;
  border: 1px solid #23d0eb !important;
  cursor: pointer;

  &:hover {
    background: #254b50;
    border: 1px solid #23d0eb;
    color: #ffffff;
  }
`;

export const GreySolidButton = styled(Button).attrs((props: any) => ({
  rounded: props.rounded,
}))`
  ${(props) => props.rounded && `border-radius: 50px !important;`}
  background: #202020;
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  cursor: pointer;

  &:hover {
    color: #ffffff !important;
    background: #4d4d4d !important;
    border: 1px solid #ffffff !important;
  }
  &.disable-btn {
    border: 1px solid rgba(255, 255, 255, 0.5) !important;
  }
`;

export const GreySolidButtonReact = styled.button`
  background: #202020;
  color: #ffffff;
  border: 1px solid #ffffff !important;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background: #4d4d4d;
    border: 1px solid #ffffff;
  }
`;

export const WhiteIconButton = styled(Button)`
  color: ${"#ffffff" + hexTransparencies[50]};
  padding: 4px 8px;

  &:hover {
    color: #ffffff !important;
  }

  &:focus {
    color: ${"#ffffff" + hexTransparencies[50]};
  }

  span:nth-child(2) {
    margin-left: 4px !important;
  }
`;

export const BlueIconButton = styled(Button)`
   {
    color: #23d0eb;
    &:hover {
      color: #23d0eb !important;
    }

    &:focus {
      color: #23d0eb !important;
    }
  }
`;
export const WhiteButtonWithBorder = styled(Button)`
   {
    border: 1px solid #ffffff;
    background: #202020;
    border-radius: 999px !important;
    color: ${"#ffffff"};
    padding: 4px 8px;
    
    &:active,
    &:focus,
    &:hover {
      background: #4c4c4c;
      border: 1px solid #ffffff;
      color: #ffffff;
    }
`;

export const GreenSolidButton = styled(Button).attrs((props: any) => ({
  rounded: props.rounded,
}))`
  ${(props) => props.rounded && `border-radius: 50px !important;`}
  color: #ffffff !important;
  background: rgba(122, 252, 23, 0.1);
  border: 1px solid #7afc17;
  cursor: pointer;

  &:active,
  &:focus,
  &:hover {
    background: rgba(122, 252, 23, 0.15);
    border: 1px solid #7afc17;
    color: #ffffff;
  }
`;
export const CustomSolidButton = styled(Button).attrs((props: any) => ({
  rounded: props.rounded,
  type: props.type,
}))`
  ${(props) => props.rounded && `border-radius: 50px !important;`}
  cursor: pointer;
  color: ${({ type }) => {
    switch (type) {
      case "primary":
        return "#23d0eb";
      case "gray":
        return "#9A9A9A";
      default:
        return "#ffffff !important;";
    }
  }};
  background-color: ${({ type }) => {
    switch (type) {
      case "primary":
        return "#162729";
      case "gray":
        return "#202020";
      default:
        return "rgba(122, 252, 23, 0.1)";
    }
  }};
  border: ${({ type }) => {
    switch (type) {
      case "primary":
        return "1px solid #23d0eb";
      case "gray":
        return "1px solid #ffffff";
      default:
        return "1px solid #7afc17";
    }
  }};

  &:active,
  &:focus,
  &:hover {
    color: ${({ type }) => {
      switch (type) {
        case "primary":
          return "#23d0eb";
        case "gray":
          return "#9A9A9A";
        default:
          return "#ffffff !important;";
      }
    }};
    background-color: ${({ type }) => {
      switch (type) {
        case "primary":
          return "#254b50";
        case "gray":
          return "#4d4d4d";
        default:
          return "rgba(122, 252, 23, 0.15)";
      }
    }};
    border: ${({ type }) => {
      switch (type) {
        case "primary":
          return "1px solid #23d0eb";
        case "gray":
          return "1px solid #ffffff";
        default:
          return "1px solid #7afc17";
      }
    }};
  }
`;
