import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
  },
};

interface PlotLineDataset {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
}

interface PlotLineData {
  labels: string[];
  datasets: PlotLineDataset[];
}

interface Props {
  data: PlotLineData;
}

export default function CustomPlotLineChart({ data }: Props) {
  return <Line options={options} data={data} />;
}
