import { FormikProps } from "formik";
import React from "react";
import FloatingLabelInput from "../../../../../../components/FloatingLabel/FloatingLabelInput";

type Props = {
  scriptFormik: FormikProps<any>;
  label: string;
  DBKey: string;
  field: string;
  maxLength: number;
  onChange: Function;
  isFocused: any;
  setIsFocused: any;
  lastFocused: any;
  setLastFocused: any;
  readOnly: boolean;
};
export default function PlaceholderInput(props: Props) {
  const handleChange = (e: any) => {
    props.onChange(props.DBKey, e.target.value);
  };
  return (
    <FloatingLabelInput
      label={props.label}
      nameForInput={props.field}
      value={props.scriptFormik.values[props.field]}
      onChange={handleChange}
      scriptFormik={props.scriptFormik}
      lastFocused={props.lastFocused}
      setIsFocused={props.setIsFocused}
      setLastFocused={props.setLastFocused}
      isFocused={props.isFocused}
      maxLength={props.maxLength}
      isAddNeeded={false}
      enableLiveCount={true}
      readOnly={props.readOnly}
    />
  );
}
