import { InfoCircleFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React from "react";
import Panel, { PanelCol } from "../../../../../components/Panel/Panel";
import { PaneBody, PaneTabs } from "../styled";
import ComingSoon from "./ComingSoon";

export default function ScriptViewCenterPane() {
  return (
    <PaneTabs defaultActiveKey="" className="tab-min-width">
      <TabPane tab="Stats" key="1" style={{ minWidth: "10vw" }}>
        <PaneBody>
          <ComingSoon />
          {/* <Row>
            <Col span={12}>
              <Row>
                <PanelCol span={12}>
                  <Panel
                    icon={<InfoCircleFilled />}
                    title="Comparative Analysis"
                    primaryText="Level 1 vs Level 2"
                    secondaryText="Secondary text"
                  >
                    Panel 1
                  </Panel>
                </PanelCol>
                <PanelCol span={12}>
                  <Panel
                    icon={<InfoCircleFilled />}
                    title="Performance Over Time"
                    primaryText="10 wks"
                  >
                    Panel 2
                  </Panel>
                </PanelCol>
              </Row>
              <Row>
                <PanelCol span={24}>
                  <Panel
                    icon={<InfoCircleFilled />}
                    title="Altitude Affects on Success Rate"
                    secondaryText="Secondary text"
                  >
                    Panel 3
                  </Panel>
                </PanelCol>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <PanelCol span={10}>
                  <Panel
                    icon={<InfoCircleFilled />}
                    title="TCCC vs PCC Training Time"
                    secondaryText="Secondary text"
                  >
                    Panel 4
                  </Panel>
                </PanelCol>
                <PanelCol span={14}>
                  <Panel
                    icon={<InfoCircleFilled />}
                    title="Time in Training"
                    secondaryText="Secondary text"
                  >
                    Panel 4
                  </Panel>
                </PanelCol>
              </Row>
              <Row>
                <PanelCol span={10}>
                  <Panel
                    icon={<InfoCircleFilled />}
                    title="Critical Actions Mastered"
                    secondaryText="Secondary text"
                  >
                    Panel 5
                  </Panel>
                </PanelCol>
                <PanelCol span={14}>
                  <Panel
                    icon={<InfoCircleFilled />}
                    title="Capability Readiness"
                    primaryText="5.987,34"
                    secondaryText="Secondary text"
                  >
                    Panel 5
                  </Panel>
                </PanelCol>
              </Row>
            </Col>
          </Row> */}
        </PaneBody>
      </TabPane>
      <TabPane tab="Comments" key="2">
        <PaneBody>
          <ComingSoon />
        </PaneBody>
      </TabPane>
      <TabPane tab="Peer Review" key="3">
        <PaneBody>
          <ComingSoon />
        </PaneBody>
      </TabPane>
      <TabPane tab="Media" key="4">
        <PaneBody>
          <ComingSoon />
        </PaneBody>
      </TabPane>
      <TabPane tab="Training Aids" key="5">
        <PaneBody>
          <ComingSoon />
        </PaneBody>
      </TabPane>
    </PaneTabs>
  );
}
