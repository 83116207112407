import styled from "styled-components";
import { colorPalette } from "../../../../../styles/MainList.styled";

export const TopMenu = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0px 5px;
  margin-top: 2px;

  div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    &:not(.text-only) {
      cursor: pointer;
      &:hover {
        color: #ffffff;
        background: ${colorPalette.hoverColorHex};
        border: 1px solid #fff;
        border-radius: 5px;
      }
    }

    &.active {
      color: ${colorPalette.primary100};
    }
  }

  .icon {
    font-size: 1.5rem;

    &:active {
      color: ${colorPalette.primary100};
    }

    &.edit {
      margin-left: auto;
    }

    &.disabled {
      color: #ffffff1a;
      cursor: not-allowed;
  }
`;

export const InjuryOverlay = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 52px);
  margin-top: 10px;
`;
