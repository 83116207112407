import axios from "axios";

export function CreateResearchQuestionBlock(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(
      `/api/1/pfc/ResearchQuestionBlock/CreateResearchQuestionBlock`,
      payload,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function UpdateResearchQuestionBlock(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(
      `/api/1/pfc/ResearchQuestionBlock/UpdateResearchQuestionBlock`,
      payload,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}

export function UpdateResearchQuestionButton(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(
      `/api/1/pfc/ResearchQuestionButton/UpdateResearchQuestionButton`,
      payload,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function UpdateResearchQuestionNumber(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(
      `/api/1/pfc/ResearchQuestionNumber/UpdateResearchQuestionNumber`,
      payload,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function UpdateResearchQuestionText(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/ResearchQuestionText/UpdateResearchQuestionText`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function UpdateResearchQuestionSelect(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(
      `/api/1/pfc/ResearchQuestionSelect/UpdateResearchQuestionSelect`,
      payload,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function DeleteResearchQuestionSelectAnswer(
  id: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(
      `/api/1/pfc/ResearchQuestionSelect/AnswerForResearchQuestionSelect/${id}`,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function UpdateResearchQuestionRadio(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(
      `/api/1/pfc/ResearchQuestionRadio/UpdateResearchQuestionRadio`,
      payload,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function DeleteResearchQuestionRadioAnswer(
  id: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(
      `/api/1/pfc/ResearchQuestionRadio/AnswerForResearchQuestionRadio/${id}`,
    )
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function AssignMasterResearchQuestionToResearchQuestionBlock(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .post(`/api/1/pfc/ResearchQuestion/assign/master`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}
