import styled from "styled-components";

interface IconsProps {
  status: boolean;
}

export const FormContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
`;

export const GenderFormDiv = styled.div`
  width: 100%;
  height: auto;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  display: flex;
  flex-direction: row;
`;
export const IconDiv = styled.button<IconsProps>`
  width: 35px;
  height: 35px;
  border: ${({ status }) => (status ? "2px solid white " : " 2px solid gray")};
  margin-right: 6px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
`;
