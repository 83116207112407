export const theme = {
  colors: {
    primary: {
      // Blue
      darker: "rgb(26, 44, 46)",
      dark: "rgb(28, 71, 78)",
      main: "rgb(30, 117, 130)",
      light: "rgb(33, 163, 183)",
      lighter: "rgb(35, 208, 235)",
    },
    secondary: {
      // Green
      darker: "rgb(40, 60, 25)",
      dark: "rgb(49, 82, 25)",
      main: "rgb(74, 139, 24)",
      light: "rgb(98, 196, 24)",
      lighter: "rgb(122, 252, 23)",
    },
    peach: {
      darker: "rgb(60, 47, 35)", // Cola
      dark: "rgb(83, 61, 41)", // Deep Bronze
      main: "rgb(141, 97, 56)", // MCKenzie
      light: "rgb(198, 133, 72)", // Peru
      lighter: "rgb(255, 168, 87)", // Texas Rose
    },
    purple: {
      darker: "rgb(40, 22, 30)", // Temptress
      dark: "rgb(48, 19, 33)", // Blackberry
      main: "rgb(71, 14, 41)", // Blackberry light
      light: "rgb(95, 8, 49)", // Tyrian Purple
      lighter: "rgb(117, 2, 56)", // Tyrian Purple light
    },
    gray: {
      darker: "rgb(26, 26, 26)", // Nero
      dark: "rgb(41, 41, 41)", // Nero Light
      main: "rgb(70, 70, 70)", // CharCoal
      light: "rgb(154, 154, 154)", // Nobel
      lighter: "rgb(201, 201, 201)", // Very Light Grey
    },
    notify: {
      sucess: "rgb(122, 252, 23)", // Lawn Green
      moderate: "rgb(230, 207, 9)", // Sunflower
      warning: "rgb(254, 164, 10)", // Dark Tangerine
      error: "rgb(255, 66, 66)", // Coral Red
    },
    label: {
      labelColor: "rgb(201, 201, 201)", // Very Light Grey
      labelRequiredColor: "rgb(201, 201, 201)", // Very Light Grey
    },
    background: {
      layoutBody: "rgb(26, 26, 26)", // Nero
      layoutHeader: "rgb(26, 26, 30)", // Black Russian
      bodyBackground: "rgb(26, 26, 26)", // Nero
      componentBackground: "rgb(26, 26, 26)", // Nero
      popOverBackground: "rgb(26, 26, 26)", // Nero
    },
    hoverColor: "rgba(255,255,255,0.15)",
  },
  borderRadiusBase: "3px",
  breakpoints: {
    xxs: "360px",
    xxsXs: "441px",
    xs: "565px",
    xsSm: "593px",
    sm: "768px",
    mdSm: "821px",
    md: "992px",
    mdLg: "1024px",
    lgLite: "1045px",
    lg: "1200px",
    xl: "1400px",
    xxl: "1550px",
    xxxl: "1850px",
  },
};
