import { isNullOrUndefined } from "./utilityfunctions";
type Item = {
  key: string;
  value: string;
};
export const clearSessionStorage = () => {
  // items to preserve in sessionstorage
  const keysToPreserve = ["DODIdOrEmailPreference"];
  const DataToPreserve: Item[] = [];
  keysToPreserve.forEach((key) => {
    const tempData = getItemFromSessionStorage(key);
    if (!isNullOrUndefined(tempData)) {
      DataToPreserve.push({ key, value: tempData });
    }
  });

  // clear sessionStorage
  sessionStorage.clear();

  // add those item back to sessionStorage
  DataToPreserve.forEach((item: Item) => {
    setItemInSessionStorage(item.key, item.value);
  });
};
export const getItemFromSessionStorage = (key: string) => {
  const value = sessionStorage.getItem(key);
  if (!value) {
    return null;
  }
  return value;
};
export const setItemInSessionStorage = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
};
