import axios from "axios";
import { Modal } from "antd";

//create a default error message in case one wasn't provided
function defaultErrorMessage(err: any) {
  console.log(err);
  if (typeof err === "string") {
    Modal.error({
      title: "An Error Occurred details are provided below:",
      content: err,
    });
  } else {
    Modal.error({
      title: "An Error Occurred details are provided below:",
      content: JSON.stringify(err.response.data.ErrorMessage),
    });
  }
}

export function getInstructorResources(
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .get(`/api/1/pfc/resource/instructor-resources`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getAccessibleResources(
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .get(`/api/1/pfc/resource/accessible-resources`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getSymptomResources(
  id: number,
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .get(`/api/1/pfc/resource/symptom-resources/${id}`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getActionResources(
  id: number,
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .get(`/api/1/pfc/resource/action-resources/${id}`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getSessionResources(
  id: number,
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .get(`/api/1/pfc/resource/session-resources/${id}`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getClassroomSessionResources(
  id: number,
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .get(`/api/1/pfc/resource/classroom-session-resources/${id}`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getScenarioResources(
  id: number,
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .get(`/api/1/pfc/resource/scenario-resources/${id}`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getScenarioStateResources(
  id: number,
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .get(`/api/1/pfc/resource/scenarioState-resources/${id}`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getAllResources(
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .get(`/api/1/pfc/resource/all-resources`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getResourceTypes(
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .get(`/api/1/pfc/resource/resource-types`)
    .then((res) => {
      //do something

      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err) => {
      // catch error
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
