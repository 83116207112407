import styled from "styled-components";
import { TaskType } from "../../../../../models/Enums";
import { colorPalette } from "../../../../../styles/MainList.styled";
import { ColorPalette } from "../../../../../styles/ColorPalette";

export const BlockContainer = styled.div`
  display: inline-flex;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;

  > div:last-of-type {
    width: -webkit-fill-available;
  }

  .task-active {
    background-color: ${ColorPalette.Blue4};
  }
`;

export const TimedActionBlockContainer = styled.div`
  justify-content: flex-start;
  height: 70px;
  width: -webkit-fill-available;
  background-color: ${colorPalette.gray75};
  color: white;
  align-items: center;
  // margin-right: 30px;

  > div {
    height: inherit;
    display: flex;
    align-items: center;
    width: 100%;
  }

  * {
    font-size: 1em;
  }

  .sublist-drag-icon {
    color: ${colorPalette.gray75};
    margin-right: 4px;
  }
`;

export const TimedActionProperty = styled.div`
  padding-left: 2px;
  display: grid;
  grid-template-rows: inherit;
  grid-template-columns: 1fr;
  height: -webkit-fill-available;
  align-items: center;
  justify-content: space-between;

  span {
    &.title {
      margin-left: 2px;
    }
  }
`;

export const TimedActionBody = styled.div.attrs((props: any) => ({
  type: props.type,
}))`
  box-shadow: ${(props) =>
    props.type === TaskType.Treatment
      ? "inset 5px 0px #23D0EB"
      : props.type === TaskType.Miscellaneous
        ? "inset 5px 0px #7D1EF6"
        : props.type === TaskType.Diagnostic
          ? "inset 5px 0px #FFFFFF"
          : "inset 5px 0px #7AFC17"};
  display: flex;
  align-items: center;
  width: 96%;

  & > div:last-of-type {
    margin-right: 0 !important;
  }

  & > div {
    height: 100%;
    border-bottom: ${(props) =>
      props.type === TaskType.Treatment
        ? "1px solid #23D0EB"
        : props.type === TaskType.Miscellaneous
          ? "1px solid #7D1EF6"
          : props.type === TaskType.Diagnostic
            ? "1px solid #FFFFFF"
            : "1px solid #7AFC17"};
  }

  & > span {
    margin-right: 1px;
    background: green;
    height: 100%;
    width: 50%;
    position: absolute;
    //right: 0;
    top: 0;
    bottom: 0;
  }
`;
