import * as React from "react";
import { Input as AntInput } from "antd";
import { InputProps } from "antd/lib/input";
import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";

const StyledAntInput = styled.div`
  padding-top: 22px;
  position: relative;
  .error {
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.3s ease;
    font-size: 0.86rem;
    color: ${colorPalette.error};
  }
  .label {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    color: rgba(255, 255, 255, 0.5);
    &.floatingLabel {
      font-size: 1.25rem;
      top: 26px;
      left: 11px;
    }
  }
  & > .ant-input {
    font-size: 1.25rem;
    color: #fff;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #fff;
    box-shadow: none !important;
    background-color: transparent;
    &:focus {
      background-color: rgba(255, 255, 255, 0.05);
      border-bottom: 1px solid ${colorPalette.primary100};
    }
  }

  .ant-input-affix-wrapper {
    border: none;
    border-radius: 0;
    box-shadow: none !important;
    background-color: transparent;
    border-bottom: 1px solid #fff;
    .ant-input {
      font-size: 1.25rem;
      color: #fff;
      background-color: transparent;
    }
    .ant-input-suffix {
      color: #fff;
    }
  }

  .ant-input-affix-wrapper-focused {
    background-color: rgba(255, 255, 255, 0.05);
    border-bottom: 1px solid ${colorPalette.primary100};
  }
`;

interface MUIInputProps extends InputProps {
  label?: string;
  value?: any;
  error?: string;
}

const MuiInput = (props: MUIInputProps) => {
  const {
    label,
    value: initialValue,
    error,
    onFocus,
    onBlur,
    onChange,
    ...inputProps
  } = props;
  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState(initialValue);
  const labelClass =
    isFocused || value || props.defaultValue ? "label" : "label floatingLabel";

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus && onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur && onBlur(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange && onChange(e);
  };

  return (
    <StyledAntInput>
      <label className={labelClass}>{label}</label>
      {!!error && <div className="error">{error}</div>}
      <AntInput
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        {...inputProps}
      />
    </StyledAntInput>
  );
};

export default MuiInput;
