import {
  AddSubTask,
  MultipleSwitchContainer,
} from "../../styles/MainList.styled";
import SwitchWithLabel from "../Switch/SwitchWithLabel";
import { PlusCircleOutlinedIcons } from "../../IconsTheme/Icons.global";
import { ListContainer } from "../../pages/ScriptBuilder/EditComponents/ShowList/ShowList.styled";
import * as React from "react";
import { FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreatorsForTreatment } from "../../store/index";
import { Phase } from "../../models/ScriptInterfaces";
import { isNullOrUndefined } from "../../utils/utilityfunctions";
import FloatingLabelTextArea from "../FloatingLabel/FloatingLabelTextArea";
import { InputRef } from "antd";
import { useRef } from "react";
import { DropResult } from "react-beautiful-dnd";
import produce from "immer";
import { SetPhases } from "../../pages/ScriptBuilder/MainlistSlice";
import { BlockType } from "../../models/Enums";
import { size, trim } from "lodash";
import { UpdateMiscellaneousTaskBlock } from "../../models/MiscellaneousTaskBlock";
import SubtasksList from "./SubtasksList";
import { updateMiscellaneousTaskBlock } from "../../api/MiscellaneousTaskBlock";
import { UpdateDiagnosticTaskBlock } from "../../models/DiagnosticTaskBlock";
import { updateDiagnosticTaskBlock } from "../../api/DiagnosticTaskBlock";
import { DiagnosticTask } from "../../models/DiagnosticTask";
import { MiscellaneousTask } from "../../models/MiscellaneousTask";
import { updateTreatmentTaskBlock } from "../../api/TreatmentTaskBlock";
import { UpdateTreatmentTaskBlock } from "../../models/TreatmentTaskBlock";
import { TreatmentTask } from "../../models/TreatmentTask";
import { MedicationTask } from "../../models/MedicationTask";
import { updateMedicationTaskBlock } from "../../api/MedicationTaskBlock";
import { UpdateMedicationTaskBlock } from "../../models/MedicationTaskBlock";
interface SubtasksProps {
  object: MiscellaneousTask | DiagnosticTask | TreatmentTask | MedicationTask;
  subtaskValue: string;
  handleOptionToggle: Function;
  scriptFormik: FormikProps<any>;
  handleAddSubtask: Function;
  type: string;
  handleChange?: Function;
  handleSubtaskDelete: any;
  handleSubtaskUpdate: any;
  from?: string; //from master content table
  handleSubtaskOrderUpdate?: any; //from master content table
  isReadOnly: boolean;
}

export const SubtasksTA = (props: SubtasksProps) => {
  const inputRef = useRef<InputRef>(null);
  const state = useSelector((state: any) => state.TreatmentReducer);
  const MainListState = useSelector((state: any) => state.MainlistReducer);
  const phases: Phase[] = MainListState.phases;
  const dispatch = useDispatch();
  const { SetLastFocused, SetIsFocused, updateSubtaskForm } =
    bindActionCreators(actionCreatorsForTreatment, dispatch);

  const handleSubtaskInputUpdate = (e: any) => {
    if (props.type === "Treatment") {
      updateSubtaskForm(e.target.value);
      !isNullOrUndefined(props.handleChange) &&
        props.handleChange(e.target.value);
    } else {
      if (!isNullOrUndefined(props.handleChange)) {
        props.handleChange(e.target.value);
      }
    }
  };

  const hasValidSubTaskValue = !!size(trim(props.subtaskValue));

  const handleAddSubtask = () => {
    if (!isNullOrUndefined(props.subtaskValue) && hasValidSubTaskValue) {
      props.handleAddSubtask(state.subtask);
    }
    inputRef.current?.focus();
  };
  const onDragEnd = (result: DropResult) => {
    console.log(result);
    if (!result.destination) {
      // dropped outside of a drop-enabled area
      return;
    }

    const sourceSectionUUID = result.source.droppableId;
    const sourceIndex = result.source.index;
    const destinationSectionUUID = result.destination.droppableId;
    const destinationIndex = result.destination.index;

    if (sourceSectionUUID === destinationSectionUUID) {
      // this is a drag reordering inside a single section
      if (sourceIndex === destinationIndex) {
        // user dropped it right where they picked it up.  No reordering necessary
        return;
      }
      if (props.from && props.from === "content-master") {
        const subtask = props.object.Subtasks;
        let reorderedSubtasks = [...subtask];
        const [removedItem] = reorderedSubtasks.splice(sourceIndex, 1);
        reorderedSubtasks.splice(destinationIndex, 0, removedItem);

        reorderedSubtasks = reorderedSubtasks.map((subtask, subtaskIndex) => {
          return {
            ...subtask,
            Order: subtaskIndex,
          };
        });
        props.handleSubtaskOrderUpdate(reorderedSubtasks);
        return;
      }
      const targetSubtasks =
        phases[MainListState.currentPhaseIndex].Sections[
          MainListState.currentSectionIndex
        ].Blocks[MainListState.currentBlockIndex].BlockContent
          .TimedActionTaskContent?.Subtasks;

      let reorderedSubtasks = [...targetSubtasks];
      const [removedItem] = reorderedSubtasks.splice(sourceIndex, 1);
      reorderedSubtasks.splice(destinationIndex, 0, removedItem);

      reorderedSubtasks = reorderedSubtasks.map((subtask, subtaskIndex) => {
        return {
          ...subtask,
          Order: subtaskIndex,
        };
      });
      const newPhases = produce(phases, (draftPhases) => {
        draftPhases[MainListState.currentPhaseIndex].Sections[
          MainListState.currentSectionIndex
        ].Blocks[
          MainListState.currentBlockIndex
        ].BlockContent.TimedActionTaskContent.Subtasks = reorderedSubtasks;
      });
      dispatch(SetPhases(newPhases));

      const currentBlock =
        newPhases[MainListState.currentPhaseIndex].Sections[
          MainListState.currentSectionIndex
        ].Blocks[MainListState.currentBlockIndex];

      switch (currentBlock.BlockType) {
        case BlockType.Treatment:
          updateTreatmentTaskBlock(
            currentBlock as UpdateTreatmentTaskBlock,
            () => undefined,
            () => undefined,
          );
          break;
        case BlockType.Diagnostic:
          updateDiagnosticTaskBlock(
            currentBlock as UpdateDiagnosticTaskBlock,
            () => undefined,
            () => undefined,
          );
          break;
        case BlockType.Medication:
          updateMedicationTaskBlock(
            currentBlock as UpdateMedicationTaskBlock,
            () => undefined,
            () => undefined,
          );
          break;
        case BlockType.Miscellaneous:
          updateMiscellaneousTaskBlock(
            currentBlock as UpdateMiscellaneousTaskBlock,
            () => undefined,
            () => undefined,
          );
          break;
        default:
          break;
      }
    }
    return;
  };
  return (
    <div className="durationContainer SubtasksContainer">
      <MultipleSwitchContainer
        className="durationToggleContainer"
        isSecondSwitchEnabled={false}
        isFirstSwitchEnabled={props.object.IsSubtasksEnabled}
      >
        <div
          className="subTaskSwitchContainer subtasksToggleContainer duration"
          style={{ display: "flex" }}
          onClick={() => props.handleOptionToggle("IsSubtasksEnabled")}
        >
          <SwitchWithLabel
            disabled={props.isReadOnly}
            isForTreatment={true}
            rightLabel={"Subtasks"}
            checked={props.object.IsSubtasksEnabled}
            onChange={() => undefined}
          />
        </div>
      </MultipleSwitchContainer>
      {props.object.IsSubtasksEnabled && (
        <AddSubTask>
          {props.isReadOnly ? null : (
            <div className="addItemInput">
              <FloatingLabelTextArea
                inputRef={inputRef}
                onChange={handleSubtaskInputUpdate}
                scriptFormik={props.scriptFormik}
                lastFocused={state.lastFocused}
                setIsFocused={SetIsFocused}
                setLastFocused={SetLastFocused}
                isFocused={state.isFocused}
                label={"Subtask"}
                textareaName={"subtask-description"}
                value={props.subtaskValue}
                onSave={props.scriptFormik.handleBlur}
                enableLiveCount={false}
              />
              <div
                className={
                  hasValidSubTaskValue
                    ? `addTitleIcon`
                    : `addTitleIcon disabled`
                }
                onClick={handleAddSubtask}
              >
                <PlusCircleOutlinedIcons />
              </div>
            </div>
          )}
          <ListContainer>
            <SubtasksList
              isReadOnly={props.isReadOnly}
              object={props.object}
              handleSubtaskDelete={props.handleSubtaskDelete}
              handleSubtaskUpdate={props.handleSubtaskUpdate}
              onDragEnd={onDragEnd}
            />
          </ListContainer>
        </AddSubTask>
      )}
    </div>
  );
};
