import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChartType } from "../../models/Enums";
import { DummyWidgetsData } from "./DashboardChartData";

export interface IDashboardWidget {
  id: number;
  title: string;
  secondaryText: string;
  type: ChartType;
  queryParameters: string;
  queryBuilderId: number;
  sm: any;
}

export type DashboardSliceState = {
  widgets: IDashboardWidget[];
  editWidgetDisplay: boolean;
  widgetToEdit: null | IDashboardWidget;
};

const initialState: DashboardSliceState = {
  // widgets: DummyWidgetsData,
  widgets: [],
  editWidgetDisplay: false,
  widgetToEdit: null,
};

const DashboardSlice = createSlice({
  name: "Dashboard",
  initialState: initialState,
  reducers: {
    SetEditWidget: (state, action: PayloadAction<boolean>) => {
      state.editWidgetDisplay = action.payload;
    },
    SetWidgetToEdit: (state, action: PayloadAction<any>) => {
      state.widgetToEdit = action.payload;
    },
  },
});

export default DashboardSlice.reducer;
export const { SetEditWidget, SetWidgetToEdit } = DashboardSlice.actions;
