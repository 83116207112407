import axios from "axios";
import { defaultErrorCallback } from "../ErrorCallback";

const apiPrefix = "/api/1/pfc/QueryBuilder";

export function getAllQueries(callback: Function) {
  axios
    .get(`${apiPrefix}/Queries`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      defaultErrorCallback(err);
    });
}

export function executeQuery(
  queryId: number,
  parameters: string,
  callback: Function,
) {
  axios
    .get(`${apiPrefix}/${queryId}/Execute?parameters=${parameters}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      defaultErrorCallback(err);
    });
}

export function getSelectOptions(
  queryId: number,
  selectParam: string,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .get(`${apiPrefix}/${queryId}/${selectParam}/SelectOptions`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      defaultErrorCallback(err);
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
