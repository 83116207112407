import { OpenWarningNotification } from "../components/Notification/Notification";
import { Category } from "../models/CategoryInterface";
import User from "../models/User";
import { useAppSelector } from "../store/hooks";
const adamChapmanUuid = process.env.REACT_APP_ADAM_CHAMPMAN_UUID;
export function isNullOrUndefined(value: any): value is null | undefined {
  return value == null || value === undefined;
}

export function filterCategoryByCategoryType(
  categories: Category[],
  categoryType: string,
) {
  const filteredCategories: Category[] = [];

  //eslint-disable-next-line  array-callback-return
  categories.map((category: Category) => {
    if (
      category.CategoryType.toLocaleLowerCase() === categoryType.toLowerCase()
    ) {
      filteredCategories.push(category);
    }
  });

  return filteredCategories;
}

export function convertMinutesToHoursAndMinutes(minutes: number): {
  hours: string;
  minutes: string;
} {
  const hours: number = Math.floor(minutes / 60);
  const remainingMinutes: number = minutes % 60;
  return {
    hours: hours.toString().padStart(2, "0"),
    minutes: remainingMinutes.toString().padStart(2, "0"),
  };
}
/**
 * metricConversion
 * @param value number
 * @param unitType  string (weight, volume)
 * @param float number [0,4]
 */
export function metricConversion(
  value: any,
  currentUnit: string,
  unitType: string,
  float = 0,
) {
  let newValue: any = Number(value);
  switch (unitType) {
    case "volume":
      // liters (l, ml, mcl )
      if (parseFloat(newValue) < 1 && currentUnit === "L") {
        newValue = Number(newValue) * 1000;
        currentUnit = "mL";
      }
      if (parseFloat(newValue) < 1 && currentUnit === "mL") {
        newValue = Number(newValue) * 1000;
        currentUnit = "mcL";
      }
      if (parseFloat(newValue) >= 1000 && currentUnit === "mcL") {
        newValue = Number(newValue) / 1000;
        currentUnit = "mL";
      }
      if (parseFloat(newValue) >= 1000 && currentUnit === "mL") {
        newValue = Number(newValue) / 1000;
        currentUnit = "L";
      }
      newValue = Number(Number(newValue).toFixed(float));
      break;
    case "weight":
      if (parseFloat(newValue) < 1 && currentUnit === "g") {
        newValue = Number(newValue) * 1000;
        currentUnit = "mg";
      }
      if (parseFloat(newValue) < 1 && currentUnit === "mg") {
        newValue = Number(newValue) * 1000;
        currentUnit = "mcg";
      }
      if (parseFloat(newValue) >= 1000 && currentUnit === "mcg") {
        newValue = Number(newValue) / 1000;
        currentUnit = "mg";
      }
      if (parseFloat(newValue) >= 1000 && currentUnit === "mg") {
        newValue = Number(newValue) / 1000;
        currentUnit = "g";
      }
      newValue = Number(Number(newValue).toFixed(float));
      break;

    default:
      break;
  }
  return `(${Number(newValue)}${currentUnit})`;
}
export function formatTimeWithTwoDigit(value: number) {
  return `${value < 10 ? "0" + value : value}`;
}
/**
 *
 * @param value
 * @param currentUnit
 * @param format
 * @returns hh:mm or hh:mm:ss formatted string
 */
export function timeConversion(
  value: any,
  currentUnit: string,
  format: string,
) {
  const newValue: any = Number(value);
  let hours = 0;
  let remainingMinutes = 0;
  let seconds = 0;
  let result = "";
  switch (currentUnit) {
    case "sec":
      if (format === "hh:mm:ss") {
        result = new Date(newValue * 1000).toISOString().slice(11, 19);
      } else {
        result = new Date(newValue * 1000).toISOString().slice(11, 16);
      }
      break;
    case "min":
      hours = Math.floor(newValue / 60);
      remainingMinutes = newValue % 60;
      seconds = remainingMinutes % 60;

      result = hours ? formatTimeWithTwoDigit(hours) : "";
      result = result
        ? `${result}:${formatTimeWithTwoDigit(remainingMinutes)}`
        : `${formatTimeWithTwoDigit(remainingMinutes)}`;
      if (format === "hh:mm:ss") {
        result = result ? `${result}:${seconds}` : `${seconds}`;
      }
      break;
    default:
      break;
  }
  return result;
}

/**
  Takes a number of minutes and converts it to a formatted string "Xhrs:Ymins"
  @param minutes - The number of minutes to be converted
  @returns A string in the format "Xhrs:Ymins" representing the input number of minutes
  *
*/
export function convertMinutesToHHMM(minutes: number) {
  const hours: number = Math.floor(minutes / 60);
  const remainingMinutes: number = minutes % 60;
  const hourStr = hours < 10 ? `0${hours}` : `${hours}`;
  const minuteStr =
    remainingMinutes < 10 ? `0${remainingMinutes}` : `${remainingMinutes}`;
  return `${hourStr}:${minuteStr}`;
}

/**\n * Takes in a number of bytes and returns a formatted string of its equivalent value in different units of storage size
 *
 bytes The number of bytes to convert

 decimals Optional. Number of decimals to round off the results to. Defaults to 2

  returns {string} A formatted string of the equivalent storage size of the provided number of bytes
*/
export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

/**
 * Checks whether the current user is a Simwerx user.
 * @returns {boolean} Returns true if the user is a Simwerx user, false otherwise.
 */
export function isSimwerxUser() {
  const { userCompanyId } = useAppSelector((state) => state.authReducer);
  if (userCompanyId === process.env.REACT_APP_SIMWERX_COMPANY_ID) {
    return true;
  }
  return false;
}

/**
 * Checks whether the current logged in company is Simwerx.
 * @returns {boolean} Returns true if the current logged in company is Simwerx, false otherwise.
 */
export function isCurrentLoggedInCompanySimwerx() {
  const { Id } = useAppSelector((state) => state.authReducer.company);
  if (Id === process.env.REACT_APP_SIMWERX_COMPANY_ID) {
    return true;
  }
  return false;
}

/**
 * Checks if the user has the permission to access company data.
 *
 * @param {any} user - The user object.
 * @param {string} companyId - The ID of the company.
 * @param {string | undefined} CreatedBy - The ID of the user who created the company (optional).
 * @param {string | undefined} DataKeyTenantId - The ID of the tenant (optional).
 * @returns {boolean} - Returns true if the user has the permission, false otherwise.
 */

export function checkPermission(
  user: any,
  companyId: string,
  CreatedBy: string | undefined,
  DataKeyTenantId: string | undefined,
) {
  if (CreatedBy === adamChapmanUuid) {
    return true;
  } else if (CreatedBy === user.nameid) {
    return true;
  } else if (isBelongsToSameOrganization(companyId, DataKeyTenantId)) {
    return true;
  } else {
    return false;
  }
}

/**
 * Checks if the user belongs to the same organization based on the company ID and the tenant ID.
 *
 * @param {string} userCompanyId - The ID of the company to which the user belongs.
 * @param {string | undefined} DataKeyTenantId - The ID of the tenant associated with the organization (optional).
 * @returns {boolean} - Returns true if the user belongs to the same organization, false otherwise.
 */
export function isBelongsToSameOrganization(
  userCompanyId: string,
  DataKeyTenantId: string | undefined,
) {
  if (!DataKeyTenantId) return true;
  if (userCompanyId === DataKeyTenantId) {
    return true;
  }
  return false;
}

// handle read-only input click event
function handleClickEventForReadOnlyInput(event: any) {
  const el = document.querySelectorAll(
    ".readOnly.withNotification .intervalMinutesSSuffix,.readOnly.withNotification .ant-radio,.readOnly.withNotification .ant-select,.readOnly.withNotification .ant-switch,.readOnly.withNotification .custom-switch,.readOnly.withNotification textarea,.readOnly.withNotification .disabled,.readOnly.withNotification .greyButtonSolidBorder",
  );
  if (el && el.length && event?.target) {
    for (let i = 0; i < el.length; i++) {
      if (el[i]?.contains(event.target)) {
        OpenWarningNotification({
          description: `This script is READ-ONLY. You must fork the script to edit it.`,
        });
        break;
      }
    }
  }
}
// add event listener on input of read only script
export function AddClickEventHandlerForReadOnlyInput() {
  console.log("AddClickEventHandlerForReadOnlyInput");
  window.addEventListener("click", handleClickEventForReadOnlyInput);
}
// remove event listener on input of read only script
export function RemoveClickEventHandlerForReadOnlyInput() {
  console.log("RemoveClickEventHandlerForReadOnlyInput");
  window.removeEventListener("click", handleClickEventForReadOnlyInput);
}

/**
 * Validates an array of email addresses.
 * @param {string[]} emailArray - An array of email addresses to validate.
 * @returns {boolean} True if all email addresses are valid, false if at least one is invalid.
 */
export function validateEmails(emailArray: string[]): boolean {
  // Regular expression pattern for basic email format check
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // Iterate through each email in the array
  for (const email of emailArray) {
    // Check if the current email matches the regular expression pattern
    if (!emailRegex.test(email)) {
      // Return false if an invalid email is found
      return false;
    }
  }

  // Return true if all emails pass the validation
  return true;
}

/**
 * Checks if a value exists in an array in a case-insensitive manner.
 * @param {string[]} arr - The array to search in.
 * @param {string} value - The value to search for.
 * @returns {boolean} True if the value is found, false otherwise.
 */
export function containsCaseInsensitive(arr: string[], value: string): boolean {
  const lowerValue = value.toLowerCase();
  return arr.some((item) => item.toLowerCase() === lowerValue);
}

/**
 * capitalizeFirstLetter
 * @param string
 * @returns
 */
export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * renderFormattedName
 * @param firstName
 * @param lastName
 * @returns
 */
export const renderFormattedName = (firstName: string, lastName: string) => {
  const lName = lastName.trim();
  const fName = firstName.trim();
  if (fName && lName) return `${lName} ${fName}`;
  else if (fName) return `${fName}`;
  else if (lName) return `${lName}`;
  else return "- -";
};

/**
 * Get the formatted author name
 * @param author - User object containing author information
 * @returns Formatted author name or an empty string if author is undefined
 */
export const getAuthorName = (author: User | undefined): string => {
  if (!isNullOrUndefined(author)) {
    return `${author?.LastName}, ${author?.FirstName?.slice(0, 1)}`;
  } else {
    return "";
  }
};
