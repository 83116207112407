import styled from "styled-components";

export const CustomSwitchWithIcon = styled.div`
  display: inline-block;
  cursor: pointer;
  min-height: 2.5rem;
  padding: 0.1rem;
  &.disabled {
    cursor: not-allowed;
  }
`;

export const StyledImageWrapper = styled.div`
   {
    height: calc(100vh - 200px);
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
    }
  }
`;
