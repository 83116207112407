import { Radio, RadioChangeEvent } from "antd";
import React from "react";
import { RadioButtonWrapper } from "../RightEditPanel.styled";

type Option = {
  text: string;
  value: number;
};
type Props = {
  DBField: string;
  selectedOption: number;
  Options: Option[];
  onChange: Function;
  disabled: boolean;
};
export default function RadioWithTwoOption({
  DBField,
  selectedOption,
  Options,
  onChange,
  disabled,
}: Props) {
  const handleChange = (e: RadioChangeEvent) => {
    onChange(DBField, e.target.value);
  };
  return (
    <RadioButtonWrapper>
      <Radio.Group
        onChange={handleChange}
        value={selectedOption}
        disabled={disabled}
      >
        {Options.map((option) => (
          <Radio key={`${option.text}_${option.value}`} value={option.value}>
            {option.text}
          </Radio>
        ))}
      </Radio.Group>
    </RadioButtonWrapper>
  );
}
