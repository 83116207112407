import * as React from "react";
import { FormikProps } from "formik";
import "./../ScriptDetailsView.scss";
import { PaneCol } from "../styled";
import ScriptViewCenterPane from "./ScriptViewCenterPane";
import ScriptViewLeftEditPanel from "./ScriptViewLeftEditPanel";

export type ScriptFormValues = {
  id: number;
  public: boolean;
  isSeed: boolean;
  title: string;
  description: string;
  casualtyId: number;
  search: string;
  clonable: boolean;
  IsArchived: boolean;
};

type ScriptViewProps = {
  setView: Function;
  formik: FormikProps<ScriptFormValues>;
  updateScriptTitle: Function;
  createUpdateScript: Function;
};

const ScriptView = ({
  formik,
  setView,
  updateScriptTitle,
  createUpdateScript,
}: ScriptViewProps) => {
  return (
    <>
      <PaneCol span={8}>
        <ScriptViewLeftEditPanel
          formik={formik}
          createUpdateScript={createUpdateScript}
          setView={setView}
          updateScriptTitle={updateScriptTitle}
        />
      </PaneCol>
      <PaneCol span={16}>
        <ScriptViewCenterPane />
      </PaneCol>
    </>
  );
};

export default ScriptView;
