import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type initialState = {
  isFocused: {};
  lastFocused: {};
  option: {};
  selectedPhase: string;
  interval: number;
  description: string;
  subtask: string;
  search: string;
  uploadedMedia: any[];
  isEditingAllowed: boolean;
};

export const diagnosticsOptions = {
  IsCritical: false,
  IsRequired: false,
  SubtasksEnabled: false,
  IntervalEnabled: false,
  DurationEnabled: false,
  DefineDurationTimeEnabled: false,
  DefineStartTimeEnabled: false,
  RatingEnabled: false,
  MediaEnabled: false,
  CustomLabelsEnabled: false,
  IntervalTime: 0,
};

const Focused = {
  taskName: false,
  description: false,
  interval: false,
  subtask: false,
  search: false,
};
const diagnosticEditInitialState: initialState = {
  isFocused: Focused,
  lastFocused: Focused,
  option: diagnosticsOptions,
  selectedPhase: "",
  interval: 0,
  description: "",
  subtask: "",
  search: "",
  uploadedMedia: [],
  isEditingAllowed: true,
};

const DiagnosticSlice = createSlice({
  name: "Diagnostic",
  initialState: diagnosticEditInitialState,
  reducers: {
    SetIsFocused: (state, action: PayloadAction<{}>) => {
      console.log("state", state);
      state.isFocused = { ...action.payload };
    },
    SetLastFocused: (state, action: PayloadAction<{}>) => {
      state.lastFocused = { ...action.payload };
    },
    SetOption: (state, action: PayloadAction<{}>) => {
      state.option = { ...action.payload };
    },
    SetSelectedPhase: (state, action: PayloadAction<string>) => {
      state.selectedPhase = action.payload;
    },
    SetDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    SetSubTask: (state, action: PayloadAction<string>) => {
      state.subtask = action.payload;
    },
    SetInterval: (state, action: PayloadAction<number>) => {
      state.interval = action.payload;
    },
    SetSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    SetUploadedMedia: (state, action: PayloadAction<any[]>) => {
      state.uploadedMedia = action.payload;
    },
    SetIsEditingAllowed: (state, action: PayloadAction<boolean>) => {
      state.isEditingAllowed = action.payload;
    },
  },
});

export default DiagnosticSlice.reducer;
export const {
  SetDescription,
  SetSelectedPhase,
  SetOption,
  SetSubTask,
  SetLastFocused,
  SetInterval,
  SetIsFocused,
  SetUploadedMedia,
  SetIsEditingAllowed,
} = DiagnosticSlice.actions;
