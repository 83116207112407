import { Row } from "antd";
import * as React from "react";
import { Image } from "antd";
import AudioThumbnail from "../../../assets/img/Thumbnails/audio-thumb.png";
import PdfThumbnail from "../../../assets/img/Thumbnails/pdf-thumb.png";
import { PlayCircleOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Media } from "../../../models/MediaInterface";
import { WhiteButton } from "../../../pages/ScriptBuilder/EditComponents/ScriptDetailsView/InjuryTab/InjuryTab.styled";
import { BlueCheckboxWithoutCheckedIcon } from "../../../styles/Checkbox.styled";
import {
  StyledImageWrapper,
  StyledCheckboxWrapper,
} from "../../FileUpload/FileUpload.styled";

interface Props {
  title: string;
  mediaList: Media[];
  selectedMediaId: number[];
  onMediaCheckToggle: Function;
  onEdit: Function;
}

export default function MediaList({
  title,
  mediaList,
  selectedMediaId,
  onMediaCheckToggle,
  onEdit,
}: Props) {
  const editMedia = (e: any, media: Media) => {
    onEdit(media);
    e?.stopPropagation();
  };
  const getMediaThumbnail = (media: Media) => {
    const mediaType = media.MediaType?.Name;
    switch (mediaType) {
      case "Image":
        return <Image src={media.Url} alt={media.Name} preview={false} />;
      case "Audio":
        return <Image src={AudioThumbnail} alt={media.Name} preview={false} />;
      case "Video":
        return (
          <div className="video-thumbnail">
            <PlayCircleOutlined />
            <Image src={media.ThumbnailUrl} alt={media.Name} preview={false} />
          </div>
        );
      default:
        return <Image src={PdfThumbnail} alt={media.Name} preview={false} />;
    }
  };
  return (
    <>
      {mediaList.length ? (
        <p className="text-white font-size-2 mb-1">{title}</p>
      ) : null}
      <Row>
        {mediaList.length
          ? mediaList.map((media: Media) => {
              return (
                <StyledImageWrapper
                  selected={selectedMediaId.includes(media.Id)}
                  key={media.Id}
                  onClick={() => onMediaCheckToggle(media.Id)}
                >
                  <StyledCheckboxWrapper>
                    <BlueCheckboxWithoutCheckedIcon
                      checked={selectedMediaId.includes(media.Id)}
                    />
                    <WhiteButton
                      className="zoom-icon-btn"
                      type="link"
                      icon={<ZoomInOutlined className="font-size-1" />}
                      size="middle"
                      onClick={(e: any) => editMedia(e, media)}
                    ></WhiteButton>
                  </StyledCheckboxWrapper>
                  {getMediaThumbnail(media)}
                  <span className="filename"> {media.Name} </span>
                </StyledImageWrapper>
              );
            })
          : null}
      </Row>
    </>
  );
}
