import axios from "axios";
import { defaultErrorCallback } from "./ErrorCallback";
import {
  CreateDiagnosticTask,
  UpdateDiagnosticTask,
} from "../models/DiagnosticTask";

export function getMasterDiagnosticTasks(
  forSearch: boolean,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .get(`/api/2/pfc/diagnostic/master?forSearch=${forSearch}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function makeDiagnosticTaskMaster(
  timedActionTaskId: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(`/api/2/pfc/diagnostic/${timedActionTaskId}/master`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function createDiagnosticTask(
  payload: CreateDiagnosticTask,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .post(`/api/2/pfc/diagnostic/master`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function updateDiagnosticTask(
  payload: UpdateDiagnosticTask,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .put(`/api/2/pfc/diagnostic/master`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}

export function deleteDiagnosticTask(
  id: number,
  callback: Function,
  errorCallback: Function = defaultErrorCallback,
) {
  axios
    .delete(`/api/2/pfc/diagnostic/master/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorCallback != null) {
        errorCallback(err.response.data);
      }
    });
}
