import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Tooltip } from "antd";
import React from "react";
import {
  InsultWithRelation,
  NumberParameter,
} from "../../../../../../../models/InsultInterface";
import {
  DegreeParameterOptions,
  NumberParameterStaticData,
  NumberParameterWithDropdown,
  NumberParameterWithFloatValue,
} from "../../CommonData";
import { StyledFormRow, WhiteButton } from "../../InjuryTab.styled";
import FlowRateParameter from "./FlowRateParameter";
import SelectParameterValue from "./SelectParameterValue";
import SurfaceAreaParemeter from "./SurfaceAreaParemeter";
import FloatingLabelInput from "../../../../../../../components/FloatingLabel/FloatingLabelInput";

type NumberParameterFormProps = {
  formik: any;
  insult: InsultWithRelation;
  param: NumberParameter;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
};
const NumberParameterForm = ({
  formik,
  insult,
  param,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
}: NumberParameterFormProps) => {
  // Hide MIC Level for now. Can be un-hide in future.
  // To un-hide remove this method.
  const showNumberParam = () => {
    if (param.UserFacingLabel?.toLocaleLowerCase() === "mic level") {
      return false;
    } else {
      return true;
    }
  };

  const getUserFacingLabel = () => {
    if (
      insult.Label?.toLocaleLowerCase() === "radiation" &&
      param.UserFacingLabel?.toLocaleLowerCase() === "rad"
    ) {
      return "Radiation Absorbed Dose (RAD)";
    } else {
      return param.UserFacingLabel;
    }
  };

  const getNumberDropdownOptions = (Label: string) => {
    const staticData = NumberParameterStaticData.find((x) => x.Label === Label);
    if (!staticData) {
      return [];
    }
    // static options with label for burn insult's degree parameter
    if (Label == "Degree" && DegreeParameterOptions.length) {
      return DegreeParameterOptions;
    } else if (Label === "Severity" && param && param.Maximum) {
      const options = [];
      let i = param.Minimum;
      while (i < param.Maximum || i == param.Maximum) {
        options.push({
          Value: i / param.Maximum,
          Label: Math.round(i * 10),
        });
        i = Math.round((i + 0.1) * 10) / 10;
      }
      return options;
    } else {
      // for rest number dropdown
      const options = [];
      let i = staticData.Minimum;
      while (i < staticData.Maximum || i == staticData.Maximum) {
        options.push({
          Value: i,
          Label: i,
        });
        i++;
      }
      return options;
    }
  };
  const isFloatValue = () => {
    return NumberParameterWithFloatValue.includes(
      param.UserFacingLabel.toLocaleLowerCase(),
    );
  };
  return (
    <>
      {param.UserFacingLabel.toLocaleLowerCase() === "flow rate" ? (
        <StyledFormRow style={{ alignItems: "baseline" }}>
          <FlowRateParameter
            userFacingLabel={param.UserFacingLabel + " *"}
            param={param}
            formik={formik}
            lastFocused={lastFocused}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            isFocused={isFocused}
            insult={insult}
          />
        </StyledFormRow>
      ) : param.UserFacingLabel.toLocaleLowerCase() === "tbsa" ? (
        <StyledFormRow align="middle">
          <SurfaceAreaParemeter
            param={param}
            insult={insult}
            formik={formik}
            lastFocused={lastFocused}
            setIsFocused={setIsFocused}
            setLastFocused={setLastFocused}
            isFocused={isFocused}
          />
        </StyledFormRow>
      ) : (
        <>
          {showNumberParam() && (
            <StyledFormRow align="middle">
              {NumberParameterWithDropdown.includes(param.UserFacingLabel) ? (
                <Col
                  span={param.UserFacingLabel == "Degree" ? 16 : 8}
                  sm={22}
                  md={param.UserFacingLabel == "Degree" ? 18 : 10}
                  lg={param.UserFacingLabel == "Degree" ? 16 : 6}
                >
                  <SelectParameterValue
                    label={param.UserFacingLabel}
                    field={`number_param_${insult.Label}_${param.UserFacingLabel}`}
                    customClassName=""
                    formik={formik}
                    selectOptions={getNumberDropdownOptions(
                      param.UserFacingLabel,
                    )}
                  />
                </Col>
              ) : (
                <>
                  <Col span={12} sm={24} md={16} lg={12}>
                    <FloatingLabelInput
                      label={getUserFacingLabel()}
                      nameForInput={`number_param_${insult.Label}_${param.UserFacingLabel}`}
                      value={
                        formik.values[
                          `number_param_${insult.Label}_${param.UserFacingLabel}`
                        ]
                      }
                      onChange={() => undefined}
                      scriptFormik={formik}
                      lastFocused={lastFocused}
                      setIsFocused={setIsFocused}
                      setLastFocused={setLastFocused}
                      isFocused={isFocused}
                      isAddNeeded={true}
                      suffix={param.Units ? param.Units : ""}
                      onSave={formik.submitForm}
                      maxValue={param.Maximum}
                      minValue={param.Minimum}
                      step={1}
                      precision={isFloatValue() ? 2 : 0}
                      displayMinMaxRange={true}
                      isNumber
                      suffixIcon={param.Units ? param.Units : ""}
                    />
                  </Col>
                  {insult.Label?.toLocaleLowerCase() === "radiation" && (
                    <span className="pl-2 mt-5">
                      (
                      {formik.values[
                        `number_param_${insult.Label}_${param.UserFacingLabel}`
                      ] * 0.01}{" "}
                      J/kg)
                    </span>
                  )}
                </>
              )}
              {param.Description ? (
                <Col flex={1} style={{ paddingLeft: 5 }}>
                  <Tooltip title={param.Description}>
                    <WhiteButton
                      type="link"
                      icon={<InfoCircleOutlined className="icon-lg" />}
                      size="large"
                    />
                  </Tooltip>
                </Col>
              ) : null}
            </StyledFormRow>
          )}
        </>
      )}
    </>
  );
};

export default NumberParameterForm;
