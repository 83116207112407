export const ScriptViewTypes = {
  SCRIPT: "SCRIPT",
  CASUALTY: "CASUALTY",
};

export const LeftPaneViewTypes = {
  CASUALTY_FORM: "CASUALTY_FORM",
  CASUALTY_TABLE: "CASUALTY_TABLE",
};

export const InjuryViewTypes = {
  CASUALTY_INJURIES: "CASUALTY_INJURIES",
  SELECT_INSULT_INJURY: "SELECT_INSULT_INJURY",
  EDIT_INSULT: "EDIT_INSULT",
  CREATE_EDIT_INJURY: "CREATE_EDIT_INJURY",
  EDIT_INJURY: "EDIT_INJURY",
};

export const LeftPaneCasualtyCreatorViewTypes = {
  CASUALTY_FORM: "CASUALTY_FORM",
  CASUALTY_INSULT_INJURY: "CASUALTY_INSULT_INJURY",
  CASUALTY_STATS: "CASUALTY_STATS",
  CASUALTY_OBSERVATIONS: "CASUALTY_OBSERVATIONS",
  CASUALTY_DIAGNOSTICS: "CASUALTY_DIAGNOSTICS",
  CREATE_MASTER_CASUALTY: "CREATE_MASTER_CASUALTY",
};
