import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Row } from "antd";
import React from "react";
import { ReactComponent as Flag } from "../../../../../../assets/img/Svg/Flag.svg";
import Icon from "@ant-design/icons/lib/components/Icon";
import { TimedActionBlockNameContainer } from "./TimedActionBlockName.styled";

type Props = {
  name: string;
  isComplete: boolean;
  isFlagged: boolean;
};

const TimedActionBlockName = ({
  name,
  isComplete,
  isFlagged,
  ...props
}: Props) => {
  return (
    <TimedActionBlockNameContainer>
      <Row className="timedaction-name">{name}</Row>
      <Row className="timedaction-status">
        {isFlagged ? (
          <Icon component={Flag} />
        ) : isComplete ? (
          <CheckOutlined style={{ color: "#23D0EB" }} />
        ) : (
          <CloseOutlined style={{ color: "#FF4242" }} />
        )}
      </Row>
    </TimedActionBlockNameContainer>
  );
};

export default TimedActionBlockName;
