import React from "react";
import Icon, {
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileAddOutlined,
  UnorderedListOutlined,
  GroupOutlined,
  FontSizeOutlined,
  FrownOutlined,
  SoundOutlined,
  ThunderboltOutlined,
  PlusOutlined,
  RightOutlined,
  EditOutlined,
  UserOutlined,
  SendOutlined,
  MenuOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  HolderOutlined,
  UpOutlined,
  DownOutlined,
  CheckOutlined,
  WarningOutlined,
  ExperimentOutlined,
  CompassOutlined,
  OrderedListOutlined,
  SyncOutlined,
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ShareAltOutlined,
  ForkOutlined,
  PrinterOutlined,
  ArrowRightOutlined,
  ClockCircleOutlined,
  FilterOutlined,
  ArrowUpOutlined,
  SwapOutlined,
  MinusCircleOutlined,
  CameraOutlined,
  NotificationOutlined,
  PaperClipOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import { Tooltip } from "antd";
import { ReactComponent as Tx } from "../assets/img/Svg/Tx.svg";
import { ReactComponent as Dx } from "../assets/img/Svg/Dx.svg";
import { ReactComponent as Rx } from "../assets/img/Svg/Rx.svg";
import { ReactComponent as Mx } from "../assets/img/Svg/Mx.svg";
import Graph_45_deg from "../assets/icons/Graph_45_deg.png";
import Graph_Curved_Upward from "../assets/icons/Graph_Curved_Upward.png";
import { ReactComponent as Timer } from "../assets/icons/timer.svg";
import { ReactComponent as Circle } from "../assets/icons/circle.svg";
import { ReactComponent as Published } from "../assets/icons/Published.svg";
import { ReactComponent as DashboardMenuIcon } from "../assets/img/Svg/Menu/DashboardIcon.svg";
import { ReactComponent as SessionsMenuIcon } from "../assets/img/Svg/Menu/SessionsIcon.svg";
import { ReactComponent as ManageAccountMenuIcon } from "../assets/img/Svg/Menu/ManageAccountIcon.svg";
import { ReactComponent as ManageContentMenuIcon } from "../assets/img/Svg/Menu/ManageContentIcon.svg";
import { ReactComponent as ScenariosMenuIcon } from "../assets/img/Svg/Menu/ScenariosIcon.svg";
import { ReactComponent as ScriptMenuIcon } from "../assets/img/Svg/Menu/ScriptsIcon.svg";
import { ReactComponent as ScriptNewTabIcon } from "../assets/icons/NewTab.svg";
import { ReactComponent as ForkBranchIcon } from "../assets/icons/Fork_branch_icon.svg";
import { useAppSelector } from "../store/hooks";

interface Props {
  style?: any;
  onClick?: any;
  className?: string;
  id?: string;
  title?: string;
}

interface NewBlocksProps {
  disabled: boolean;
  style?: any;
  onClickText?: any;
  onClickSupportAction?: any;
  onClickRPPrompt?: any;
  onClickMedication?: any;
  onClickMiscellaneous?: any;
  onClickList?: any;
  onClickDiagnostic?: any;
  onClickCasualty?: any;
  onClickTreatment?: any;
  className?: string;
  isBrief: boolean;
  id?: string;
  title?: string;
  isSectionTimedAction: boolean;
  onClickCasualtyStatus?: any;
  onClickResearchQuestion?: any;
}

export const CheckOutLinedIcon = (props: Props) => {
  return (
    <Tooltip title={props.title != null ? props.title : "Saved"}>
      <CheckOutlined
        style={props.style}
        onClick={props.onClick}
        className={props.className}
      />
    </Tooltip>
  );
};
export const CopyIcon = (props: Props) => {
  return (
    <Tooltip title={props.title != null ? props.title : "Copy"}>
      <CopyOutlined
        style={props.style}
        onClick={props.onClick}
        className={props.className}
      />
    </Tooltip>
  );
};

export const DragIcon = (props: Props) => {
  return (
    <HolderOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const CollapseIcon = (props: Props) => {
  return (
    <UpOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const ExpandIcon = (props: Props) => {
  return (
    <Tooltip title="Expand">
      <DownOutlined
        style={props.style}
        onClick={props.onClick}
        className={props.className}
      />
    </Tooltip>
  );
};

export const DeleteIcon = (props: Props) => {
  return (
    <Tooltip title={props.title}>
      <DeleteOutlined
        style={props.style}
        onClick={props.onClick}
        className={props.className}
      />
    </Tooltip>
  );
};

export const ExcalmationCircleOutlinedIcons = (props: Props) => {
  return (
    <ExclamationCircleOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const FilledAddOutLinedIcon = (props: Props) => {
  return (
    <FileAddOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const UnorderedListOutlinedIcons = (props: Props) => {
  return (
    <UnorderedListOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const OrderedListOutlinedIcons = (props: Props) => {
  return (
    <Tooltip title={props.title != null ? props.title : ""}>
      <OrderedListOutlined
        style={props.style}
        onClick={props.onClick}
        className={props.className}
      />
    </Tooltip>
  );
};

export const GroupOutlinedIcons = (props: Props) => {
  return (
    <GroupOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const FontSizeOutlinedIcons = (props: Props) => {
  return (
    <FontSizeOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const FrownOutlinedIcons = (props: Props) => {
  return (
    <FrownOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const SoundOutlinedIcons = (props: Props) => {
  return (
    <SoundOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const ThunderboltOutlinedIcons = (props: Props) => {
  return (
    <ThunderboltOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const PlusOutlinedIcons = (props: Props) => {
  return (
    <PlusOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
      id={props.id}
    />
  );
};

export const AddBlockIcons = (props: NewBlocksProps) => {
  return (
    <div style={props.style} className={props.className}>
      <span className="noHoverEffect" style={{ border: "none" }}></span>
      <Tooltip title="Text" placement="bottom">
        <div
          onClick={!props.disabled && props.onClickText}
          className={`${props.disabled ? "disabled" : ""}`}
        >
          <FontSizeOutlined />
        </div>
      </Tooltip>
      <Tooltip title="List" placement="bottom">
        <div
          onClick={!props.disabled && props.onClickList}
          className={`${props.disabled ? "disabled" : ""}`}
        >
          <UnorderedListOutlined />
        </div>
      </Tooltip>
      <Tooltip title="Casualty Status" placement="bottom">
        <div
          className={`addBlockSvgIcons ${props.disabled ? "disabled" : ""}`}
          onClick={!props.disabled && props.onClickCasualtyStatus}
        >
          <FrownOutlined />
        </div>
      </Tooltip>
      {!props.isBrief && props.isSectionTimedAction && (
        <Tooltip title="Miscellaneous" placement="bottom">
          <div
            className={`addBlockSvgIcons ${props.disabled ? "disabled" : ""}`}
            onClick={!props.disabled && props.onClickMiscellaneous}
          >
            <Mx />
          </div>
        </Tooltip>
      )}
      {!props.isBrief && props.isSectionTimedAction && (
        <Tooltip title="Treatment" placement="bottom">
          <div
            className={`addBlockSvgIcons ${props.disabled ? "disabled" : ""}`}
            onClick={!props.disabled && props.onClickTreatment}
          >
            <Tx />
          </div>
        </Tooltip>
      )}
      {!props.isBrief && props.isSectionTimedAction && (
        <Tooltip title="Medication" placement="bottom">
          <div
            className={`addBlockSvgIcons ${props.disabled ? "disabled" : ""}`}
            onClick={!props.disabled && props.onClickMedication}
          >
            <Rx />
          </div>
        </Tooltip>
      )}
      {!props.isBrief && props.isSectionTimedAction && (
        <Tooltip title="Diagnostic" placement="bottom">
          <div
            className={`addBlockSvgIcons ${props.disabled ? "disabled" : ""}`}
            onClick={!props.disabled && props.onClickDiagnostic}
          >
            <Dx />
          </div>
        </Tooltip>
      )}
      <Tooltip title="Complication">
        <div className="disabled">
          <WarningOutlined />
        </div>
      </Tooltip>
      <Tooltip title="Role Player Prompt" placement="bottom">
        <div
          onClick={!props.disabled && props.onClickRPPrompt}
          className={`addBlockSvgIcons ${props.disabled ? "disabled" : ""}`}
        >
          <SoundOutlined className="" />
        </div>
      </Tooltip>
      <Tooltip title="Support Action" placement="bottom">
        <div
          onClick={!props.disabled && props.onClickSupportAction}
          className={`addBlockSvgIcons ${props.disabled ? "disabled" : ""}`}
        >
          <CompassOutlined />
        </div>
      </Tooltip>
      <Tooltip title="Research Question">
        <div
          className={`${props.disabled ? "disabled" : ""}`}
          onClick={!props.disabled && props.onClickResearchQuestion}
          style={{ marginRight: "22px" }}
        >
          <ExperimentOutlined />
        </div>
      </Tooltip>
      <span className="noHoverEffect" style={{ border: "none" }}></span>
    </div>
  );
};
export const PhasePlusOutlinedIcons = (props: Props) => {
  const { className = " " } = props;
  return (
    <Tooltip title="Add Phase" placement="top">
      <PlusOutlined
        style={props.style}
        onClick={props.onClick}
        className={props.className}
      />
    </Tooltip>
  );
};
export const CompassOutlinedIcons = (props: Props) => {
  const { className = " " } = props;
  return (
    <Tooltip title="Add Phase" placement="top">
      <CompassOutlined
        style={props.style}
        onClick={props.onClick}
        className={props.className}
      />
    </Tooltip>
  );
};

export const EditOutlinedIcons = (props: Props) => {
  return (
    <EditOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const UserOutlinedIcons = (props: Props) => {
  return (
    <UserOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const SendOutlinedIcons = (props: Props) => {
  return (
    <SendOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const MenuOutlinedIcons = (props: Props) => {
  return (
    <Tooltip title={props.title != null ? props.title : ""}>
      <MenuOutlined
        style={props.style}
        onClick={props.onClick}
        className={props.className}
      />
    </Tooltip>
  );
};

export const PlusCircleOutlinedIcons = (props: Props) => {
  return (
    <PlusCircleOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const MinusCircleOutlinedIcons = (props: Props) => {
  return (
    <MinusCircleOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const SearchOutlinedIcons = (props: Props) => {
  return (
    <SearchOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const EyeOutlinedIcons = (props: Props) => {
  return (
    <EyeOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const EyeInvisibleOutlinedIcons = (props: Props) => {
  return (
    <EyeInvisibleOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const ExclamationCircleOutlinedIcons = (props: Props) => {
  return (
    <ExclamationCircleOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const SyncOutlinedIcons = (props: Props) => {
  return (
    <SyncOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const LockOutlinedIcons = (props: Props) => {
  return (
    <LockOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const ThunderboltOutlinedIcon = (props: Props) => {
  return (
    <ThunderboltOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const ArrowRightOutlinedIcon = (props: Props) => {
  return (
    <ArrowRightOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const ClockCircleOutlinedIcon = (props: Props) => {
  return (
    <ClockCircleOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const ShareAltOutlinedIcons = (props: Props) => {
  return (
    <ShareAltOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const ForkOutlinedIcons = (props: Props) => {
  return (
    <ForkOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const PrinterOutlinedIcons = (props: Props) => {
  return (
    <PrinterOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const FilterOutlinedIcons = (props: Props) => {
  return (
    <FilterOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const DownOutlinedIcons = (props: Props) => {
  return (
    <DownOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const UpOutlinedIcons = (props: Props) => {
  return (
    <UpOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const RightOutlinedIcons = (props: Props) => {
  return (
    <RightOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const ArrowUpOutlinedIcons = (props: Props) => {
  return (
    <ArrowUpOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const SwapOutlinedIcons = (props: Props) => {
  return (
    <SwapOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const CameraOutlinedIcons = (props: Props) => {
  return (
    <CameraOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const NotificationOutlinedIcon = (props: Props) => {
  return (
    <NotificationOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const PaperClipOutlinedIcons = (props: Props) => {
  return (
    <PaperClipOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const Graph45Degree = (props: Props) => {
  return (
    <>
      <img src={Graph_45_deg} className="iconText" alt="text" />
    </>
  );
};
export const GraphCurvedUpward = (props: Props) => {
  return (
    <>
      <img src={Graph_Curved_Upward} className="iconText" alt="text" />
    </>
  );
};
export const TimerOutlinedIcons = (props: Props) => {
  return (
    <Icon
      component={Timer}
      alt="text"
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const CircleOutlinedIcons = (props: Props) => {
  return (
    <Icon
      component={Circle}
      alt="text"
      style={{ color: "transparent" }}
      onClick={props.onClick}
      className={props.className}
    />
  );
};
export const MapOutlinedIcons = (props: Props) => {
  return <span className="material-symbols-outlined">map</span>;
};
export const PublishedOutlinedIcons = (props: Props) => {
  return (
    <Icon
      component={Published}
      alt="text"
      style={{ color: "transparent" }}
      onClick={props.onClick}
      className={props.className}
      {...props}
    />
  );
};

export const SettingOutlinedIcons = (props: Props) => {
  return (
    <SettingOutlined
      style={props.style}
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const DashboardIcon = (props: Props) => {
  return (
    <Icon
      component={DashboardMenuIcon}
      alt="text"
      onClick={props.onClick}
      className={`${props.className} sidebar-menu-icon`}
    />
  );
};
export const SessionIcon = (props: Props) => {
  return (
    <Icon
      component={SessionsMenuIcon}
      alt="text"
      onClick={props.onClick}
      className={`${props.className} sidebar-menu-icon`}
    />
  );
};
export const ManageAccountIcon = (props: Props) => {
  return (
    <Icon
      component={ManageAccountMenuIcon}
      alt="text"
      onClick={props.onClick}
      className={`${props.className} sidebar-menu-icon`}
    />
  );
};
export const ManageContentIcon = (props: Props) => {
  return (
    <Icon
      component={ManageContentMenuIcon}
      alt="text"
      onClick={props.onClick}
      className={`${props.className} sidebar-menu-icon`}
    />
  );
};
export const ScenariosIcon = (props: Props) => {
  const { scenariosIcon } = useAppSelector(
    (state) => state.FeatureFlagReducer.globalIcons,
  );
  return (
    <>
      {scenariosIcon && (
        <Tooltip title={`${scenariosIcon ? "- Hidden in Prod" : ""}`}>
          <Icon
            component={ScenariosMenuIcon}
            alt="text"
            onClick={props.onClick}
            className={`${props.className} sidebar-menu-icon`}
          />
        </Tooltip>
        //return to this
      )}
    </>
  );
};

export const ScriptIcon = (props: Props) => {
  return (
    <Icon
      component={ScriptMenuIcon}
      alt="text"
      onClick={props.onClick}
      className={`${props.className} sidebar-menu-icon`}
    />
  );
};

export const ScriptNewTabIcons = (props: Props) => {
  return (
    <Icon
      component={ScriptNewTabIcon}
      alt="newtab"
      onClick={props.onClick}
      className={props.className}
    />
  );
};

export const ForkBranchIcons = (props: Props) => {
  return (
    <Icon
      component={ForkBranchIcon}
      alt="text"
      onClick={props.onClick}
      className={props.className}
    />
  );
};
