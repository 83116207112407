import axios from "axios";
import { Modal } from "antd";

//create a default error message in case one wasn't provided
function defaultErrorMessage(err: any) {
  console.log(err);
  if (typeof err === "string") {
    Modal.error({
      title: "An Error Occurred details are provided below:",
      content: err,
    });
  } else {
    Modal.error({
      title: "An Error Occurred details are provided below:",
      content: JSON.stringify(err.response.data.ErrorMessage),
    });
  }
}

export function uploadMedia(
  bodyFormData: any,
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
  };
  axios
    .post(`/api/1/pfc/Media/upload`, bodyFormData, config)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      console.log(err);
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function updateMediaDetails(
  id: number,
  title: string,
  description: string,
  mediaCategoryId: number | string | null,
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  axios
    .post(`/api/1/pfc/Media/MediaDetails/Update`, {
      id,
      title,
      description,
      mediaCategoryId,
    })
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      console.log(err);
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        const errorMsg = err?.response?.data?.ErrorMessage || err.message;
        errorcallback(errorMsg);
      }
    });
}
// delete media file
export function deleteMedia(
  id: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`/api/1/pfc/Media/deleteMedia/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

// Delete table build
export function deleteTabletBuild(
  id: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .delete(`/api/1/pfc/Media/tablet-build/${id}`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

// Upload table build
export function uploadTabletBuild(
  bodyFormData: any,
  callback: Function,
  errorcallback: Function = defaultErrorMessage,
) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
  };
  axios
    .post(`/api/1/pfc/Media/tablet-build`, bodyFormData, config)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      console.log(err);
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
