import React, { useRef } from "react";
import { Input as AntInput, InputRef } from "antd";
import {
  CheckOutLinedIcon,
  PlusCircleOutlinedIcons,
  SearchOutlinedIcons,
} from "../../IconsTheme/Icons.global";
import { TextAreaProps } from "antd/lib/input/TextArea";
import { useDebounce } from "../useDebounce/useDebounce";
import "../../css/custom.css";
import { FloatingLabel } from "./FloatingLabel.styled";

const { TextArea: AntTextArea } = AntInput;

interface FloatingLabelTextProps extends TextAreaProps {
  inputRef?: React.RefObject<InputRef>;
  label: any;
  textareaName: any;
  value: any;
  onSave?: any;
  maxLength?: number;
  enableLiveCount?: boolean;
  objectBlockContent?: boolean;
  isSearchIconNeeded?: boolean;
  onChange: any;
  scriptFormik: any;
  lastFocused: any;
  setIsFocused: any;
  setLastFocused: any;
  isFocused: any;
  rows?: number;
  classNameForContainer?: string;
  onBlur?: any;
  onFocus?: any;
  suffixIcon?: any | null;
}

type ErrorType = {
  [key: string]: string;
};

const FloatingLabelText = (props: FloatingLabelTextProps) => {
  const floatingLabelTextAreaRef = useRef<InputRef>(null);
  const [isTyping, setIsTyping] = React.useState(false);
  const {
    inputRef,
    label,
    value: initialValue,
    maxLength,
    textareaName,
    onSave,
    scriptFormik,
    onBlur,
    onChange,
    onFocus,
    setIsFocused,
    setLastFocused,
    lastFocused,
    isFocused,
    classNameForContainer,
    enableLiveCount,
    suffixIcon,
    readOnly,
    ...textareaProps
  } = props;
  const labelClass = (value: any) => {
    return props.isFocused[`${props.textareaName}`] || props.value
      ? `label ${props.disabled ? "disabled" : ""}`
      : `label floatingLabel ${props.disabled ? "disabled" : ""}`;
  };
  const debouncedSave = useDebounce(
    props.scriptFormik.values[`${props.textareaName}`],
    100,
    props.onSave,
    !scriptFormik.errors[`${props.textareaName}`],
  );
  const inputState = (input: string) => {
    if (input === props.textareaName) {
      if (
        props.scriptFormik.errors[`${input}`] &&
        props.scriptFormik.touched[textareaName]
      ) {
        return "errorInput";
      } else if (
        !props.isFocused[`${props.textareaName}`] &&
        !props.scriptFormik.errors[`${input}`] &&
        props.lastFocused[`${props.textareaName}`] &&
        props.value !== ""
      ) {
        return "successInput";
      } else {
        return "default";
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!props.rows || e.target.value.split("\n").length <= props.rows) {
      props.setLastFocused({
        ...props.lastFocused,
        [`${props.textareaName}`]: true,
      });
      props.scriptFormik.handleChange(e);
      props.onChange(e);
      return;
    }
  };
  const handleFocus = () => {
    if (props.readOnly) return;
    props.setIsFocused({
      ...props.isFocused,
      [`${props.textareaName}`]: true,
    });
    props.onFocus && props.onFocus();
  };
  const handleOnBlur = (e: any) => {
    if (props.readOnly) return;
    setIsTyping(false);
    props.setIsFocused({
      ...props.isFocused,
      [`${props.textareaName}`]: false,
    });
    props.setLastFocused({
      ...props.lastFocused,
      [`${props.textareaName}`]: false,
    });
    props.scriptFormik.handleBlur(e);
    props.onBlur && props.onBlur();
  };
  React.useEffect(() => {
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSave]);

  return (
    <FloatingLabel
      className={`${props.classNameForContainer}`}
      isFocused={!props.isFocused[`${props.textareaName}`]}
      errors={scriptFormik.errors[`${props.textareaName}`]}
      lastFocused={props.lastFocused[`${props.textareaName}`]}
    >
      <label
        style={{ width: "100%" }}
        className={labelClass(`${props.textareaName}`)}
        htmlFor={`${props.textareaName}`}
      >
        {props.label}
      </label>
      {!!props.scriptFormik.errors[`${props.textareaName}`] &&
        props.scriptFormik.touched[textareaName] && (
          <div className="error">
            {props.scriptFormik.errors[`${props.textareaName}`]}
          </div>
        )}
      {!props.isFocused[`${props.textareaName}`] &&
        !props.scriptFormik.errors[`${props.textareaName}`] &&
        props.lastFocused[`${props.textareaName}`] &&
        props.value !== "" && (
          <div className="">
            <CheckOutLinedIcon className="checkCircleIcon" />
          </div>
        )}
      {typeof props.value === "string" &&
        props.enableLiveCount &&
        isTyping &&
        props.isFocused[`${props.textareaName}`] &&
        !props.scriptFormik.errors[`${props.textareaName}`] &&
        props.value !== "" && (
          <div className="count">
            {props.value.length}/{props.maxLength}
          </div>
        )}
      <AntTextArea
        ref={inputRef || floatingLabelTextAreaRef}
        id={`${props.textareaName}`}
        name={`${props.textareaName}`}
        className={inputState(props.textareaName)}
        value={props.value}
        onClick={() => setIsTyping(true)}
        onChange={handleChange}
        onBlur={handleOnBlur}
        onKeyPress={() => setIsTyping(true)}
        onFocus={handleFocus}
        maxLength={props.maxLength}
        autoComplete="off"
        autoSize
        title=""
        readOnly={readOnly ? true : false}
        {...textareaProps}
      />
      {props.objectBlockContent &&
      !props.scriptFormik.errors[`${props.textareaName}`] ? (
        <PlusCircleOutlinedIcons className="plusIcon" onClick={props.onSave} />
      ) : null}
      {props.isSearchIconNeeded &&
        !props.scriptFormik.errors[`${props.textareaName}`] && (
          <SearchOutlinedIcons className="searchIcon" />
        )}
      {props.suffixIcon &&
        !props.scriptFormik.errors[`${props.textareaName}`] &&
        props.suffixIcon}
    </FloatingLabel>
  );
};

export default FloatingLabelText;
