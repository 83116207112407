const Focused = {
  whatToSay: false,
  howToAct: false,
};

const textOnlyBlocksState = {
  isFocused: Focused,
  lastFocused: Focused,
  whatToSay: "",
  supportAction: "",
  textDescription: "",
  textTitle: "",
};

export default (
  state = textOnlyBlocksState,
  action: { type: string; payload: any }
) => {
  if (action.type === "isFocused") {
    return {
      ...state,
      isFocused: { ...action.payload },
    };
  } else if (action.type === "lastFocused") {
    return {
      ...state,
      lastFocused: { ...action.payload },
    };
  } else if (action.type === "whatToSay") {
    return {
      ...state,
      whatToSay: action.payload,
    };
  } else if (action.type === "supportAction") {
    return {
      ...state,
      supportAction: action.payload,
    };
  } else if (action.type === "textDescription") {
    return {
      ...state,
      textDescription: action.payload,
    };
  } else if (action.type === "textTitle") {
    return {
      ...state,
      textTitle: action.payload,
    };
  } else return state;
};
