import styled from "styled-components";
import { Button, Col, Tabs as AntTabs } from "antd";
import {
  colorPalette,
  hexTransparencies,
} from "../../../../styles/MainList.styled";

export const SplitPaneDialog = styled.div`
  display: flex;
  min-height: calc(100vh - 64px);
  border-top: 4px solid ${colorPalette.gray75};
  border-left: 4px solid ${colorPalette.gray75};
  left: 0;
  right: 0;
  top: 0;
  background-color: ${colorPalette.gray100};
`;

export const GreyButton = styled(Button)`
  background-color: #131313;
  border: none;
  border-top: 2px solid ${"#ffffff" + hexTransparencies[50]};
  min-width: 50%;
  max-height: 60px;
  font-size: 1.5rem;
  color: #fff;
  height: auto;
  padding: 1rem 2rem;
  margin-top: auto;

  &:active,
  &:focus,
  &:hover {
    border-top: 2px solid ${"#ffffff" + hexTransparencies[50]};
    color: #ffffff;
    background-color: #4c4c4c;
  }
`;

export const GreenButton = styled(Button)`
  background-color: #0e2812;
  border: none;
  border-top: 2px solid #7afc17;
  min-width: 50%;
  max-height: 60px;
  font-size: 1.5rem;
  color: #fff;
  height: auto;
  padding: 1rem 2rem;
  margin-top: auto;

  &:active,
  &:focus,
  &:hover {
    border-top: 2px solid #7afc17;
    color: #ffffff;
    background-color: #1d6628;
  }
`;

export const SaveButton = styled(Button)`
  background-color: rgba(0, 255, 0, 0.05);
  min-width: 50%;
  max-height: 60px;
  font-size: 1.5rem;
  color: #fff;
  border-color: #0f0;
  height: auto;
  padding: 1rem 2rem;
  margin-top: auto;

  &:active,
  &:focus,
  &:hover {
    color: #fff;
    border-color: #0f0;
    background-color: rgba(0, 255, 0, 0.2);
  }
`;

export const FormRow = styled.div`
  margin-bottom: 20px;
`;

export const MedicationEditSelectsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .ant-select-single .ant-select-selection-item {
    font-size: 12px;
  }

  .floatingLabel {
    font-size: 11px !important;
  }

  & > div {
    width: 95%;
  }
`;

export const MedicationContainer = styled.div.attrs((props: any) => ({
  isLabel: props.isLabel,
}))`
  background-color: #1e1e1e;
  position: relative;
  margin: 10px;
  margin-right: 10%;

  .compoundPlusRoute {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #161616;
  }

  .routeContainer {
    width: 21%;
  }

  .deleteContainer {
    width: 10%;
  }

  .compoundNameLabel {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 79%;
  }

  .deleteButtonAB {
    position: absolute;
    top: 0;
    right: -36px;
    height: -webkit-fill-available;
    width: 35px;

    & :hover {
      button {
        cursor: pointer;
        background-color: #313131;
      }
    }

    button {
      background: #1e1e1e !important;
      border: none;
      width: inherit;
      height: inherit;
    }
  }

  .compoundPlusRoute {
    display: flex;
    position: relative;
  }

  .medicationLabel {
    font-family: "Roboto", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 9px;
    color: rgba(255, 255, 255, 0.5);
  }

  .medicationCompound {
    font-family: "Roboto", serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${(props) => (props.isLabel ? "20px" : "24px")};
    line-height: 19px;
    color: #ffffff;

    .dosagePlusRoute {
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.5) !important;
    }
  }

  .listCompound {
    height: 100%;
    width: -webkit-fill-available;
    margin-left: 5px;
  }

  .individualCompoundLabel {
    position: absolute;
    top: 10%;
    font-size: 0.8rem !important;
  }

  .compoundListItem {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .compoundSpan {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .compoundListSelect {
    width: 50%;
    margin-left: 20px;
  }

  .compoundList {
    padding-right: 10px;
  }
`;

export const PaneHeader = styled.div`
  border-bottom: 4px solid ${colorPalette.gray25};
`;

interface PaneBodyProps {
  nopadding: boolean;
}

export const PaneBody = styled.div.attrs((props: PaneBodyProps) => ({
  nopadding: props.nopadding,
}))`
  flex-grow: 1;
  overflow: auto;
  box-sizing: border-box;
  padding: 15px;
  padding-top: 30px;
  height: calc(
    100vh - 64px - 50px - 5px
  ); // viewport height - main header height - pane header height - panel border
  ${(props) =>
    props.nopadding &&
    `
    padding: 0 !important;`}

  @media (max-height: 730px) {
    height: calc(
      100vh - 64px - 50px - 5px
    ); // viewport height - main header height - pane header height - panel border
  }
`;

export const PaneCol = styled(Col)`
  border-bottom: 1px solid ${colorPalette.gray75};
  border-right: 1px solid ${colorPalette.gray75};
  display: flex;
  flex-direction: column;

  & + & {
    border-left: none;
  }
`;

export const PaneTabs = styled(AntTabs)`
  &.display-custom-title {
    .ant-tabs-nav-wrap,
    .ant-tabs-nav-operations {
      opacity: 0;
      overflow: hidden !important;
      height: 0;
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ant-tabs-nav-list .ant-tabs-tab {
    border-right: 1px solid ${colorPalette.gray75} !important;
    padding: 12px 18px !important;
    text-transform: uppercase;
  }

  .ant-tabs-nav {
    background-color: ${colorPalette.gray100};
    margin: 0;
    border-bottom: 4px solid ${colorPalette.gray25} !important;

    .ant-tabs-nav-wrap {
      overflow: visible;
    }
  }

  .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-tab {
    border: none !important;
    transition: all 0.3s ease;
    .ant-tabs-tab-btn {
      color: #999;
    }
    &:after {
      content: " ";
      display: block;
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      height: 4px;
      background-color: ${colorPalette.gray25};
      border-right: 1px solid #000;
      transition: all 0.3s ease;
    }

    &:hover {
      background-color: ${colorPalette.gray50};

      .ant-tabs-tab-btn {
        color: ${colorPalette.white};
      }

      &:after {
        background-color: #fff;
      }
    }
  }

  &.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    z-index: 1000;
    height: 4px;
    bottom: -4px;
  }

  .ant-tabs-nav-add {
    margin: 4px;
    border: 2px dashed ${colorPalette.gray25} !important;
    border-radius: 50% !important;
    text-transform: bold;
  }

  .ant-tabs-tab .anticon {
    margin-right: 0 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: ${colorPalette.primary25} !important;

    &:hover {
      background-color: ${colorPalette.primary50} !important;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f0f0f0 !important;
  }

  .ant-tabs-tab-btn {
    margin: auto;
  }

  &.casualty-form {
    .ant-tabs-nav-list .ant-tabs-tab {
      padding: 0px !important;

      .ant-btn-link {
        padding: 6.4px 18px;
      }
    }
  }
`;

// for footer panel with buttons
export const FooterWithButtons = styled.div`
  display: inline-flex;
  width: 100%;
`;
