import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TreatmentTask,
  UpdateTreatmentTask,
} from "../../../../models/TreatmentTask";
import { UpdateTimedActionSubTask } from "../../../../models/TimedActionSubTask";
import { Media } from "../../../../models/MediaInterface";
export interface treatmentState {
  data: TreatmentTask[];
  modalTitle: string;
  addUpdateModal: boolean;
  loading: boolean;
  treatmentForUpdate: UpdateTreatmentTask;
}

const initialState: treatmentState = {
  data: [],
  treatmentForUpdate: {
    Subtasks: [] as UpdateTimedActionSubTask[],
    AttachedMedias: [] as Media[],
  } as UpdateTreatmentTask,
  modalTitle: "",
  addUpdateModal: false,
  loading: true,
};

export const treatmentSlice = createSlice({
  name: "treatment",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<TreatmentTask[]>) => {
      state.data = action.payload;
    },
    setModalTitle: (state, action: PayloadAction<string>) => {
      state.modalTitle = action.payload;
    },
    setAddUpdateModal: (state, action: PayloadAction<boolean>) => {
      state.addUpdateModal = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    onDeleteSuccess: (state, action: PayloadAction<number>) => {
      state.data = state.data.filter((x) => x.Id !== action.payload);
    },
    onUpdateSuccess: (state, action: PayloadAction<TreatmentTask>) => {
      const index = state.data.findIndex((x) => x.Id === action.payload.Id);
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    },
    onArchiveUnarchiveSuccess: (state, action: PayloadAction<number>) => {
      const index = state.data.findIndex((x) => x.Id === action.payload);
      if (index !== -1) {
        state.data[index].IsArchived = !state.data[index].IsArchived;
      }
    },
    onCreateSuccess: (state, action: PayloadAction<TreatmentTask>) => {
      state.data.unshift(action.payload);
    },

    resetTreatmentForUpdate: (state, action: PayloadAction<null>) => {
      state.treatmentForUpdate = {} as UpdateTreatmentTask;
    },
    setTreatmentForUpdate: (
      state,
      action: PayloadAction<UpdateTreatmentTask>,
    ) => {
      state.treatmentForUpdate = action.payload;
    },
  },
});

export default treatmentSlice.reducer;

export const {
  setData,
  setModalTitle,
  setAddUpdateModal,
  setLoading,
  onUpdateSuccess,
  onCreateSuccess,
  onDeleteSuccess,
  onArchiveUnarchiveSuccess,
  setTreatmentForUpdate,
  resetTreatmentForUpdate,
} = treatmentSlice.actions;
