import React from "react";

export const useDebounce = (
  value: any,
  delay: number,
  props: any,
  errors?: any,
) => {
  const [debounceValue, setDebouncedValue] = React.useState(value);
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      if (errors) {
        props && props.errors && props.errors();
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
};
