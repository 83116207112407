import { SET_ALERT, RESET_ALERT } from "../actions/types";
import { AlertType } from "../models/Enums";

const initialState = {
  systemMessage: {
    message: "",
    type: AlertType.warning,
    description: "",
    messageShowTime: 3000,
  },
};
export default (state = initialState, action: any = {}) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        systemMessage: action.systemMessage,
      };
    case RESET_ALERT:
      return {
        systemMessage: {
          message: "",
          type: AlertType.warning,
          description: "",
          messageShowTime: 3000,
        },
      };

    default:
      return state;
  }
};
