import * as React from "react";
import { Component } from "react";
import { DeleteOutlined, EditFilled, EyeOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";
import { Row, Modal, Col, message } from "antd";
import document_icon from "../../../assets/icons/Upload_PDF_Icon.png";

interface Props {
  resources: any[];
  deleteFile: Function;
  editResource: Function;
}
interface State {
  loading: boolean;
  showImage: boolean;
  previewImg: string;
}

class DocumentList extends Component<Props, State> {
  state: State = {
    loading: false,
    showImage: false,
    previewImg: "",
  };
  componentDidMount() {
    console.log(this.props);
  }

  modalToggle = () => {
    this.setState({ showImage: !this.state.showImage });
  };

  preview = (url: string) => {
    if (url.substring(url.lastIndexOf(".") + 1) === "pdf") {
      // if its a word or any other file than pdf, it won't be dispalyed in the viewer, so enabling editing it
      this.setState({ previewImg: url, showImage: true });
    } else {
      let link: any = document.createElement("a");
      link.download = url;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link = null;
    }
  };

  error = (data: any) => {
    console.log(data);
    message.error("An error occured check the console for more information");
  };

  render() {
    return (
      <div>
        {this.props?.resources?.map((item: any) => (
          <Col
            xs={24}
            sm={8}
            md={6}
            lg={4}
            xl={3}
            style={{ padding: "5px", overflow: "hidden", height: "220px" }}
            key={item.id}
          >
            <Row style={{ height: "150px" }}>
              <div className="showImgName">
                <div style={{ marginTop: "30%" }}>
                  <DeleteOutlined
                    style={{
                      fontSize: "2.3em",
                      marginRight: "10px",
                      color: "#f00",
                    }}
                    onClick={() =>
                      this.props.deleteFile(item.fileName, item.id)
                    }
                  />
                  <EyeOutlined
                    style={{ fontSize: "2.3em", color: "#0f0" }}
                    onClick={() => this.preview(item.url)}
                  />
                  <EditFilled
                    style={{
                      fontSize: "2.3em",
                      marginLeft: "10px",
                      color: "#f5e218",
                    }}
                    onClick={() => this.props.editResource(item)}
                  />
                </div>
              </div>
              {/*<img style={{ width: "100%", height: "100%" }} src={document_icon} />*/}
              <div
                className="noDistortImage"
                style={{ backgroundImage: "url(" + document_icon + ")" }}
              ></div>
            </Row>
            <Row>{item.name}</Row>
          </Col>
        ))}
        <Modal
          visible={this.state.showImage}
          title="Preview"
          onCancel={this.modalToggle}
          footer={null}
          width={1200}
          style={{ height: "800px" }}
        >
          <div style={{ width: "100%", height: "600px" }}>
            {
              <embed
                src={this.state.previewImg}
                style={{ width: "100%", height: "100%" }}
              />
            }
          </div>
        </Modal>
      </div>
    );
  }
}
export default DocumentList;
