import * as React from "react";
import { FooterWithButtons, GreyButton } from "../../styled";
import InjuryItem from "./InjuryItem";
import { StyledBody } from "../InjuryTab.styled";
import InsultItem from "./InsultItem";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import CreateNewInsultInjuryBtn from "./CreateNewInsultInjuryButton";
import {
  AppliedInjury,
  Injury,
} from "../../../../../../models/InjuryInterface";
import { InsultWithRelation } from "../../../../../../models/InsultInterface";
import { hexTransparencies } from "../../../../../../styles/MainList.styled";
import { useSelector } from "react-redux";

type AppliedCasualtyInjuryProps = {
  casualtyId: number;
  currentView: string;
  onEditInjury: Function;
  onEditInsult: Function;
  handleClickAddInjury: Function;
  onBack: Function;
  onNext: Function;
};

type InjuryOptionType = {
  Id: number;
  Title: string;
};
const AppliedCasualtyInjuryList = ({
  casualtyId,
  currentView,
  onEditInjury,
  onEditInsult,
  handleClickAddInjury,
  onBack,
  onNext,
}: AppliedCasualtyInjuryProps) => {
  const [injuryOption, setInjuryOption] = React.useState<InjuryOptionType[]>(
    [],
  );

  const injuryList = useSelector(
    (state: any) => state.InsultInjury.FilteredAppliedInjuries,
  );
  const insultList = useSelector(
    (state: any) => state.InsultInjury.FilteredAppliedInsults,
  );
  const masterInjuryList = useSelector(
    (state: any) => state.InsultInjury.Injuries,
  );
  const { isReadOnly } = useSelector(
    (state: any) => state.scriptBuilderReducer,
  ); // Read-only Script

  React.useEffect(() => {
    setInjuryOption(
      injuryList.map((i: Injury) => ({ Id: i.Id, Title: i.Title })),
    );
    return () => {
      setInjuryOption([]);
    };
  }, [injuryList]);

  return (
    <>
      <StyledBody
        style={isReadOnly ? { height: `calc(100vh - 64px - 60px - 60px)` } : {}}
      >
        {injuryList.map((item: AppliedInjury) => {
          return (
            <InjuryItem
              className="cursor-pointer"
              casualtyId={casualtyId}
              key={item.Id}
              injury={item}
              currentView={currentView}
              onEditInjury={onEditInjury}
              onEditInsult={onEditInsult}
              onInjurySelection={() => undefined}
              handleOpenCreateEditInjury={() => undefined}
              masterInjury={masterInjuryList.find(
                (x: Injury) => x.Id === item.CopySourceInjuryId,
              )}
            />
          );
        })}
        {insultList.map((item: InsultWithRelation) => {
          return (
            <InsultItem
              index={0}
              key={item.Id}
              insult={item}
              casualtyId={casualtyId}
              injuryId={0}
              totalAvailableInsult={0}
              currentView={currentView}
              onEditInsult={onEditInsult}
              injuryOption={injuryOption}
            />
          );
        })}
        {isReadOnly ? null : (
          <CreateNewInsultInjuryBtn
            onAddClick={handleClickAddInjury}
            label="Add New Insult or Injury"
          />
        )}
      </StyledBody>
      <FooterWithButtons>
        <GreyButton
          htmlType="button"
          style={{ borderRight: `1px solid #FFFFFF${hexTransparencies[10]}` }}
          onClick={() => onBack()}
        >
          <ArrowLeftOutlined /> &nbsp; Back
        </GreyButton>
        <GreyButton
          htmlType="submit"
          style={{ borderLeft: `1px solid #FFFFFF${hexTransparencies[10]}` }}
          onClick={() => onNext()}
        >
          {isReadOnly ? null : "Save & "} Next &nbsp;
          <ArrowRightOutlined />
        </GreyButton>
      </FooterWithButtons>
    </>
  );
};

export default AppliedCasualtyInjuryList;
