import * as React from "react";
import { GreySolidButton } from "../../../styles/Buttons.styled";
import {
  StyledDetailsNewTabIcon,
  StyledHolderOutlined,
} from "./DetailsPanelComponents.styled";

interface ReviewProps {
  author: string;
  title: string;
  organization: string;
}

export const ScriptReviewer = (props: ReviewProps) => {
  const { author, title, organization } = props;
  return (
    <div className="author-review-nameplate">
      <span className="vector">
        <StyledHolderOutlined />
      </span>
      <div className="author-nameplate">
        <span className="author-nameplate-avatar"></span>
        <div className="author-nameplate-info">
          <p className="lastname-firstname">
            {author}
            <span className="newtab-icon">
              <StyledDetailsNewTabIcon />
            </span>
          </p>
          <p className="title-organization">
            {title}, {organization}
          </p>
        </div>
      </div>
    </div>
  );
};

export const AuthorReviewAction = () => {
  return (
    <div className="author-review-action">
      <h5>Reviewed By</h5>
      <GreySolidButton className="author-review-btn">
        Send for Review
      </GreySolidButton>
    </div>
  );
};
