import styled from "styled-components";
import { colorPalette } from "../../styles/MainList.styled";

export const FloatingLabel = styled.div.attrs((props: any) => ({
  errors: props.errors,
  isFocused: props.isFocused,
  lastFocused: props.lastFocused,
  inputName: props.inputName,
  value: props.value,
  nameOfInput: props.nameOfInput,
}))`
  padding-top: ${(props) =>
    props.className && props.className.includes("pt-0") ? `0px` : `21px`};
  position: relative;
  ::after {
    content: ${(props) => (props.nameOfInput === "interval" ? "min" : "")};
    position: absolute;
    bottom: 2px;
    right: 0.5em;
    transition: all 0.05s ease-in-out;
  }

  :hover::after,
  :focus-within::after {
    right: 0.5em;
  }

  /* handle Firefox (arrows always shown) */
  @supports (-moz-appearance: none) {
    ::after {
      right: 0.5em;
    }
  }

  .plusIcon {
    color: white;
    font-size: clamp(1rem, 5vw, 1.2rem);
  }

  .textInput {
    height: 40.43px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid white;
  }

  .checkCircleIcon {
    display: flex;
    -moz-animation: cssAnimation 0s ease-in 2s forwards;
    -webkit-animation: cssAnimation 0s ease-in 2s forwards;
    -o-animation: cssAnimation 0s ease-in 2s forwards;
    animation: cssAnimation 0s ease-in 2s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    @keyframes cssAnimation {
      to {
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
    @-webkit-keyframes cssAnimation {
      to {
        display: none;
      }
    }
  }

  .error,
  .checkCircleIcon,
  .count {
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.3s ease;
    font-size: 0.86rem;
  }

  .error {
    color: ${colorPalette.error};
  }

  .checkCircleIcon {
    color: ${colorPalette.success} !important;
  }

  .label {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    color: ${colorPalette.gray25};
    font-size: 0.75rem;

    &.floatingLabel {
      font-size: 1.125rem;
      top: 26px;
      left: 10px;
    }
  }

  &:hover {
    .label.floatingLabel,
    input::placeholder {
      color: #fff;
    }
  }

  & > .default,
  .successInput,
  .errorInput {
    font-size: 1.125rem;
    color: #fff;
    border: none;
    border-radius: 0;
    box-shadow: none !important;
    background-color: transparent;
    overflow: hidden;
    padding: 4px 10px;

    &:focus {
      background-color: rgba(255, 255, 255, 0.05);
      border-bottom: 1px solid ${colorPalette.primary100};
    }
  }

  & > .default {
    border-bottom: 1px solid ${colorPalette.gray25};
    &:hover {
      border-bottom: 1px solid #fff;
      .ant-input-suffix {
        color: ${colorPalette.white};
      }
    }

    &:focus,
    &.ant-input-affix-wrapper-focused,
    &.ant-input-number-focused {
      border-bottom: 1px solid ${colorPalette.primary100};
      .ant-input-suffix {
        color: ${colorPalette.primary100};
      }
    }
  }
  & > .successInput {
    border-bottom: 1px solid ${colorPalette.success};
    -moz-animation: borderAnimation 0s ease-in 2s forwards;
    -webkit-animation: borderAnimation 0s ease-in 2s forwards;
    -o-animation: borderAnimation 0s ease-in 2s forwards;
    animation: borderAnimation 0s ease-in 2s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    @keyframes borderAnimation {
      to {
        border-bottom: 1px solid #fff;
      }
    }
    @-webkit-keyframes borderAnimation {
      to {
        border-bottom: 1px solid #fff;
      }
    }
  }

  .centerText input {
    text-align: center !important;
  }

  .minutesSuffix {
    padding-right: 0.5rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .ant-input-number {
    display: inline-flex;
    width: 100%;
    .ant-input-number-input {
      padding: 0;
    }
  }
  .ant-input-affix-wrapper,
  .ant-input-number {
    border-radius: 0;
    box-shadow: none !important;
    background-color: transparent;
    padding: 4px 10px !important;

    .ant-input,
    .ant-input-number-input {
      font-size: 1.125rem;
      color: #fff;
      background-color: transparent;
    }

    .ant-input-suffix {
      color: ${colorPalette.gray25};
    }

    &.ant-input-affix-wrapper-disabled,
    &.ant-input-number-disabled {
      border-bottom: 1px solid ${colorPalette.disabledInput} !important;
      .ant-input[disabled],
      .ant-input-number-input {
        color: ${colorPalette.disabledInput} !important;
      }

      .ant-input-suffix {
        color: ${colorPalette.disabledInput} !important;
      }
    }
  }
  & > .ant-input-disabled {
    border-bottom: 1px solid ${colorPalette.disabledInput} !important;
  }

  .ant-input-affix-wrapper-focused {
    background-color: rgba(255, 255, 255, 0.05);
    border-bottom: 1px solid ${colorPalette.primary100};
  }

  & > .errorInput {
    &,
    &:focus,
    &:hover {
      border-bottom: 1px solid ${colorPalette.error};
    }
  }
  .ant-input-number {
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
`;
export const FloatingNumberInput = styled(FloatingLabel).attrs(
  (props: any) => ({
    errors: props.errors,
    isFocused: props.isFocused,
    lastFocused: props.lastFocused,
    inputName: props.inputName,
    value: props.value,
    nameOfInput: props.nameOfInput,
    disabled: props.disabled,
  }),
)`
  &.number-input-with-suffix {
    .ant-input-number-input-wrap {
      width: calc(100% - 2rem);
    }
    .number-input-suffix {
      position: absolute;
      height: -webkit-fill-available;
      width: 2rem;
      right: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      padding-top: 21px;
      font-size: 1.125rem;
      margin-left: 0.2rem;
      color: ${(props) => {
        if (props.disabled) {
          return colorPalette.disabledInput;
        } else if (props.isFocused) {
          return colorPalette.primary100;
        } else {
          return "inherit";
        }
      }};
    }
    &:hover {
      .number-input-suffix {
        color: ${(props) => {
          if (props.disabled) {
            return colorPalette.disabledInput;
          } else if (props.isFocused) {
            return colorPalette.primary100;
          } else {
            return colorPalette.white;
          }
        }};
      }
    }
  }

  .ant-input-number-focused {
    background-color: rgba(255, 255, 255, 0.05);
    border-bottom: 1px solid ${colorPalette.primary100};
  }

  .ant-input-number-focused {
    background-color: rgba(255, 255, 255, 0.05);
    border-bottom: 1px solid ${colorPalette.primary100};
  }
  & > .errorInput {
    border-bottom: 1px solid ${colorPalette.error};
  }
  .ant-input-suffix {
    user-select: none;
  }
`;
