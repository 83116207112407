import "@ant-design/compatible/assets/index.css";
import Icon, { PlusOutlined } from "@ant-design/icons";
import { Row, Spin, Tooltip } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import { Medication } from "../../../../models/ScriptInterfaces";
import {
  RightSideTableHeader,
  StyledRow,
} from "../../../../components/Table/CustomTable.styled";
import Button from "../../../../components/Button/Button";
import AddUpdateDrawer from "./AddUpdateDrawer";
import { useFormik } from "formik";
import { FormikErrorType } from "../../../../models/common/FormikErrorType";
import { ReactComponent as Archive } from "../../../../assets/img/Svg/Archive.svg";
import { Switch } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  onCreateSuccess,
  onUpdateSuccess,
  resetMedicationForUpdate,
  setAddUpdateModal,
  setData,
  setMedicationForUpdate,
  setLoading,
  setModalTitle,
} from "./MedicationSlice";
import MedicationTable from "./MedicationTable";
import { getAllMedication } from "../../../../api/Medication/ApiGet";
import {
  createMedication,
  updateMedication,
} from "../../../../api/Medication/ApiPost";
import {
  CreateMedicationTask,
  MedicationTask,
  UpdateMedicationTask,
} from "../../../../models/MedicationTask";
import { useAppSelector } from "../../../../store/hooks";
import {
  createMedicationTask,
  getMasterMedicationTasks,
  updateMedicationTask,
} from "../../../../api/MedicationTask";

export default function Medications() {
  const { data, modalTitle, addUpdateModal, loading, medicationForUpdate } =
    useAppSelector((state) => state.MasterMedicationReducer);
  const dispatch = useDispatch();
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const initialValues = {
    id: 0,
    label: "",
  };
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors: FormikErrorType = {};

      // label
      if (!values["label"].trim()) {
        errors["label"] = "Required";
      } else if (values["label"].trim().length > 40) {
        errors["label"] = "Max 40 characters";
      }

      return errors;
    },
    onSubmit: (values: any) => {
      addUpdate();
    },
  });
  useEffect(() => {
    refreshData();
    return () => {
      dispatch(setAddUpdateModal(false));
      dispatch(resetMedicationForUpdate(null));
    };
  }, []);
  const refreshData = () => {
    dispatch(setLoading(true));
    getMasterMedicationTasks(false, onSuccess, onError);
  };
  const onSuccess = (data: MedicationTask[]) => {
    dispatch(
      setData(
        data.sort((a: any, b: any) =>
          new Date(b.CreatedAt).getTime() < new Date(a.CreatedAt).getTime()
            ? -1
            : 1,
        ),
      ),
    );
    dispatch(setLoading(false));
  };
  const onError = (msg: any) => {
    OpenErrorNotification({
      description: msg,
    });
    dispatch(setLoading(false));
  };
  // Reset the form inputs.
  const resetForm = () => {
    setTimeout(() => {
      formik.setValues(initialValues, false);
      formik.setErrors({});
      dispatch(resetMedicationForUpdate(null));
    }, 500);
  };
  const onUpdateSuccessCB = (data: MedicationTask) => {
    dispatch(onUpdateSuccess(data));
  };
  const onCreateSuccessCB = (data: MedicationTask) => {
    dispatch(onCreateSuccess(data));
    dispatch(setMedicationForUpdate(data));
    setFormikData(data);
  };

  // Add medication.
  const payload: UpdateMedicationTask = {
    ...medicationForUpdate,
    Name: formik.values["label"],
  };
  const addUpdate = () => {
    if (formik.isValid && formik.values.label?.trim().length) {
      // save
      if (formik.values["id"] > 0) {
        // update
        updateMedicationTask(payload, onUpdateSuccessCB, onError);
      } else {
        createMedicationTask(
          payload as CreateMedicationTask,
          onCreateSuccessCB,
          onError,
        );
      }
      closeAddUpdateModal();
    } else {
      closeAddUpdateModal();
    }
  };

  const closeAddUpdateModal = () => {
    dispatch(setAddUpdateModal(false));
    resetForm();
  };

  // Open view medication details panel with medication data.
  const showDetails = (medication: MedicationTask) => {
    dispatch(setModalTitle("View/Edit Medication"));
    setFormikData(medication);
    dispatch(setAddUpdateModal(true));
    dispatch(setMedicationForUpdate(medication));
  };
  const setFormikData = (medication: MedicationTask) => {
    formik.setValues(
      {
        id: medication.Id || 0,
        label: medication.Name || "",
      },
      false,
    );
  };
  const onEdit = (medication: MedicationTask) => {
    dispatch(setModalTitle("Edit Medication"));
    setFormikData(medication);
    dispatch(setAddUpdateModal(true));
    dispatch(setMedicationForUpdate(medication));
  };
  const onAdd = () => {
    dispatch(setModalTitle("Add Medication"));
    dispatch(setAddUpdateModal(true));
  };

  const handleSearch = (text?: string) => {
    const filteredDataList = includeArchived
      ? data.filter((x: MedicationTask) => x.Name && x.Name?.trim().length > 0)
      : data.filter(
          (x: MedicationTask) =>
            x.Name && x.Name?.trim().length > 0 && !x.IsArchived,
        );
    // Search guard clause
    if (!text) return filteredDataList;
    return filteredDataList;
  };

  // Create task before enabling subtasks.
  // This is required because we need task id to add subtasks.
  useEffect(() => {
    if (
      medicationForUpdate.IsSubtasksEnabled &&
      medicationForUpdate.Id === 0 &&
      formik.isValid
    ) {
      createMedication(payload, onCreateSuccessCB, onError);
    }
  }, [medicationForUpdate.IsSubtasksEnabled]);

  return (
    <Spin spinning={loading}>
      <StyledRow justify="space-between">
        <Row className="btn-row"></Row>
        <RightSideTableHeader>
          <Tooltip
            placement="top"
            title={`${
              includeArchived ? "Hide" : "Include"
            } Archived Medications`}
          >
            <div
              className="include-archived-scripts"
              onClick={() => {
                setIncludeArchived(!includeArchived);
              }}
            >
              <button
                className={`archived-scripts ${
                  includeArchived ? "active " : ""
                }`}
              >
                <Icon component={Archive} />
              </button>
              <Switch checked={includeArchived} onChange={() => undefined} />
            </div>
          </Tooltip>
          <Button
            type="primary"
            shape={"rounded"}
            leftIcon={<PlusOutlined />}
            onClick={onAdd}
          >
            Add Medication
          </Button>
        </RightSideTableHeader>
      </StyledRow>
      <MedicationTable
        showDetails={showDetails}
        onEdit={onEdit}
        handleSearch={handleSearch}
        searchInput={searchInput}
      />
      <AddUpdateDrawer
        title={modalTitle}
        formik={formik}
        isVisible={addUpdateModal}
        onClickOutside={addUpdate}
        onClose={addUpdate}
      />
    </Spin>
  );
}
