import { Col } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InsultWithRelation,
  NumberParameter,
} from "../../../../../../../models/InsultInterface";
import FloatingInputWithStep from "./FloatingInputWithStep";
import { INSULT_INJURY_SET_CURRENT_INSULT_FLOW_RATE } from "../../../../../../../store/CasualtyCreator/InsultInjury/actionType";
import { calculateApproxTimeToDeath } from "../../InjuryMapUtills";

type Props = {
  userFacingLabel?: string;
  formik: any;
  param: NumberParameter;
  setIsFocused: any;
  isFocused: any;
  lastFocused: any;
  setLastFocused: any;
  insult: InsultWithRelation;
};
export default function FlowRateParameter({
  userFacingLabel,
  formik,
  param,
  setIsFocused,
  isFocused,
  lastFocused,
  setLastFocused,
  insult,
}: Props) {
  const dispatch = useDispatch();
  const RightSideCasualtyFormData = useSelector(
    (state: any) => state.CasualtyCreator.CasualtyForm,
  );
  const { AppliedInsults, AppliedInjuries, CurrentView } = useSelector(
    (state: any) => state.InsultInjury,
  );
  const InsultObj: any = useSelector(
    (state: any) => state.InsultInjury.InsultObj,
  );
  const displayApproxTimeToDeath = () => {
    return calculateApproxTimeToDeath(
      CurrentView,
      InsultObj,
      RightSideCasualtyFormData.Gender,
      AppliedInsults,
      AppliedInjuries,
      parseInt(
        formik.values[`number_param_${insult.Label}_${param.UserFacingLabel}`],
      ),
    );
  };
  const handleChange = (e: any) => {
    dispatch({
      type: INSULT_INJURY_SET_CURRENT_INSULT_FLOW_RATE,
      data: parseInt(e.target.value),
    });
  };
  return (
    <>
      <Col span={9}>
        <FloatingInputWithStep
          label={(userFacingLabel && userFacingLabel) || param.UserFacingLabel}
          field={`number_param_${insult.Label}_${param.UserFacingLabel}`}
          maxValue={param.Maximum}
          minValue={param.Minimum}
          suffixIcon={param.Units ? param.Units : ""}
          formik={formik}
          setIsFocused={setIsFocused}
          isFocused={isFocused}
          lastFocused={lastFocused}
          setLastFocused={setLastFocused}
          step={1}
          precision={0}
          onChange={handleChange}
        />
      </Col>
      <Col span={14}>
        {formik.values[
          `number_param_${insult.Label}_${param.UserFacingLabel}`
        ] > 0 ? (
          <span className="pl-2">{displayApproxTimeToDeath()}</span>
        ) : null}
      </Col>
    </>
  );
}
