import React from "react";
import FloatingLabelInput from "../../../../../components/FloatingLabel/FloatingLabelInput";

interface Props {
  formik: any;
  lastFocused: any;
  setIsFocused: any;
  setLastFocused: any;
  isFocused: any;
}
export default function Question({
  formik,
  lastFocused,
  setIsFocused,
  setLastFocused,
  isFocused,
}: Props) {
  return (
    <FloatingLabelInput
      label={"Question*"}
      nameForInput={"question"}
      value={formik.values["question"]}
      onChange={formik.handleChange}
      scriptFormik={formik}
      lastFocused={lastFocused}
      setIsFocused={setIsFocused}
      setLastFocused={setLastFocused}
      isFocused={isFocused}
      isAddNeeded={false}
      onSave={formik.submitForm}
    />
  );
}
