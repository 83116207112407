import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Company } from "../models/Company";
import User from "../models/User";
import { reducers } from ".";

interface AuthState {
  isAuthenticated: boolean;
  userCompanyId: string;
  user: User;
  company: Company;
}

const initialState: AuthState = {
  isAuthenticated: false,
  userCompanyId: "",
  user: {} as User,
  company: {} as Company,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setCompany: (state, action: PayloadAction<Company>) => {
      state.company = action.payload;
    },
    setUserCompanyId: (state, action: PayloadAction<string>) => {
      state.userCompanyId = action.payload;
    },
    reset: () => initialState,
  },
});

export default authSlice.reducer;

export const { setUser, setCompany, setUserCompanyId, reset } =
  authSlice.actions;
