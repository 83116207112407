import axios from "axios";

const apiPrefix = "/api/1/pfc/ResearchQuestion";

export function archiveUnarchiveResearchQuestion(
  Id: number,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .put(`${apiPrefix}/${Id}/archive`)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function updateResearchQuestion(
  payload: any,
  callback: Function,
  errorcallback: Function,
) {
  axios
    .put(`${apiPrefix}`, payload)
    .then((res: any) => {
      //do something
      if (callback != null) {
        callback(res.data.Result);
      }
    })
    .catch((err: any) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err.response.data.ErrorMessage);
      }
    });
}
