import { Modal } from "antd";
import React from "react";
import { DeleteTextMediaItem } from "../../../../../api/Block/ApiPost";
import {
  DeleteIcon,
  FilePreviewContainer,
} from "../../../../../components/FileUpload/FileUpload.styled";
import { isNullOrUndefined } from "../../../../../utils/utilityfunctions";
import ConfirmDialog from "../../../../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialog from "../../../../../components/ConfirmDialog/useConfirmDialog";
import { Media } from "../../../../../models/MediaInterface";
import { useSelector } from "react-redux";

interface Props {
  setLoading: (value: boolean) => void;
  object: Media[];
  onDeleteAudioSuccess: Function;
}
export default function UploadedAudioList({
  setLoading,
  object,
  onDeleteAudioSuccess,
}: Props) {
  const {
    isOpenConfirmDialog,
    confirmDialogProps,
    setIsOpenConfirmDialog,
    setConfirmDialogProps,
  } = useConfirmDialog();
  const { isReadOnly } = useSelector((state: any) => state.MainlistReducer);

  const DeleteAudio = (mediaId: number) => {
    setIsOpenConfirmDialog(true);
    setConfirmDialogProps({
      title: "You are going to delete the audio file",
      content: "Are you sure you want to delete the audio file permanently?",
      okText: "Yes",
      onOk: () => {
        setLoading(true);
        DeleteTextMediaItem(mediaId, DeleteAudioSuccess, (data: any) => {
          console.error(data);
          setIsOpenConfirmDialog(false);
          setLoading(false);
        });
      },
      cancelText: "No",
      onCancel: () => {
        setIsOpenConfirmDialog(false);
      },
      type: "warning",
    });
  };

  const DeleteAudioSuccess = (data: any) => {
    setIsOpenConfirmDialog(false);
    onDeleteAudioSuccess(data);
    setLoading(false);
  };

  return (
    <>
      {!isNullOrUndefined(object) && object.length
        ? object.map((audioFile: Media, index: number) => (
            <div key={index}>
              <FilePreviewContainer hasAudio={true}>
                <div className="audioFile">
                  <figure>
                    <div className="delete-media">
                      <audio
                        className="audio-controller"
                        controls
                        src={audioFile.Url}
                      >
                        Your browser does not support the
                        <code>audio</code> element.
                      </audio>
                      {isReadOnly ? null : (
                        <DeleteIcon
                          title="Delete audio."
                          onClick={() => DeleteAudio(audioFile.Id)}
                        />
                      )}
                    </div>
                  </figure>
                </div>
              </FilePreviewContainer>
            </div>
          ))
        : null}
      <ConfirmDialog open={isOpenConfirmDialog} {...confirmDialogProps} />
    </>
  );
}
