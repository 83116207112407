import React, { useRef } from "react";
import Button from "../../../components/Button/Button";
import { PlusOutlined } from "@ant-design/icons";
import { FormField } from "../../../components/FileUpload/FileUpload.styled";
import { uploadTabletBuild } from "../../../api/Media/ApiPost";
import { OpenErrorNotification } from "../../../components/Notification/Notification";
interface Props {
  setLoading: any;
  addNewBuild: any;
}
export default function AddBuild({ setLoading, addNewBuild }: Props) {
  const fileInputField = useRef<any>(null);
  const openUploadDialog = () => {
    fileInputField?.current?.click();
  };
  const handleBuildUpload = (e: any) => {
    setLoading(true);
    const { files: newFiles } = e.target;
    const newFile = newFiles[0];
    const bodyFormData = new FormData();
    bodyFormData.append("newVersion", "true");
    bodyFormData.append("public", "true");
    bodyFormData.append("file", newFile);
    bodyFormData.append("name", newFile.name);
    bodyFormData.append("title", newFile.name);
    uploadTabletBuild(
      bodyFormData,
      (response: any) => {
        addNewBuild(response);
        setLoading(false);
      },
      (error: any) => {
        OpenErrorNotification({
          description: error.message,
        });
        setLoading(false);
      },
    );
  };
  return (
    <>
      <Button
        type="primary"
        shape={"rounded"}
        leftIcon={<PlusOutlined />}
        onClick={() => openUploadDialog()}
      >
        Add Build
      </Button>
      <FormField
        style={{ display: `none` }}
        type="file"
        ref={fileInputField}
        className="profile-aud"
        onChange={handleBuildUpload}
        title=""
        value=""
        accept=".exe,.apk,.obb,.txt" // <- Files allowed.
        multiple={false}
      ></FormField>
    </>
  );
}
