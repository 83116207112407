import React from "react";
import { FormikProps } from "formik";
import InlineEditTextArea from "../../../../../../components/InlineEdit/InlineEditTextArea";

interface Props {
  label: string;
  description: string;
  field: string;
  originalKey: string;
  formik: FormikProps<any>;
  onChange: Function;
  isReadOnly: boolean;
}
export default function ItemRow(props: Props) {
  const onChange = (value: string) => {
    props.onChange(props.originalKey, value);
  };
  return (
    <div className="item">
      <div className="abbreviation">{props.label}</div>

      <InlineEditTextArea
        wrapperClassName="note word-wrap cursor-pointer ml-0"
        uniqueId={`ShortNote_${props.originalKey}`}
        onChange={(id: string, value: string) => onChange(value)}
        value={props.description}
        isReadOnly={props.isReadOnly}
      />
    </div>
  );
}
