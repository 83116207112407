import React, { useEffect, useState } from "react";
import TextAreaAutocomplete from "../../../../components/Autocomplete/TextAreaAutocomplete";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { SetIsFocused, SetLastFocused } from "./MiscellaneousSlice";
import {
  MiscellaneousTaskBlock,
  MiscellaneousTaskBlockContent,
} from "../../../../models/MiscellaneousTaskBlock";
import { MiscellaneousTask } from "../../../../models/MiscellaneousTask";
import { attachMasterMiscellaneousTaskToBlock } from "../../../../api/MiscellaneousTaskBlock";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";

interface Props {
  object: MiscellaneousTaskBlock;
  scriptFormik: any;
  checkIsMaster: Function;
  updateBlock: Function;
  onChange: Function;
}

export default function MiscellaneousSearchAdd({
  object,
  scriptFormik,
  checkIsMaster,
  updateBlock,
  onChange,
}: Props) {
  const dispatch = useAppDispatch();
  const { lastFocused, isFocused, isEditingAllowed } = useAppSelector(
    (state) => state.MiscReducer,
  );
  const { allMasterMiscellaneous, isReadOnly } = useAppSelector(
    (state: any) => state.MainlistReducer,
  );
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );
  const [value, setValue] = useState<string>(
    object?.BlockContent.TimedActionTaskContent?.Name || "",
  );

  useEffect(() => {
    checkIsMaster(value);
  }, [object]);

  const handleSearch = (value: string) => {
    let res: { value: string; label: string }[] = [];
    if (!value || !value.trim()) {
      res = [];
    } else {
      res = allMasterMiscellaneous
        .filter(
          (diagnostic: MiscellaneousTask) =>
            diagnostic.Name?.toLowerCase().indexOf(value.toLowerCase()) > -1,
        )
        .map((diagnostic: MiscellaneousTask) => ({
          value: diagnostic.Name,
          label: <span style={{ fontSize: "1rem" }}>{diagnostic.Name}</span>,
        }));
    }
    setOptions(res);
    // Update
    isEditingAllowed && onChange("TimedActionTaskContent.Name", value);
  };
  const handleInputSearch = (e: any) => {
    scriptFormik.handleChange(e);
    setValue(e.target.value);
  };
  const onSelect = (value: string) => {
    const SelectedMiscellaneous = allMasterMiscellaneous.find(
      (x: MiscellaneousTask) => x.Name === value,
    );
    if (
      SelectedMiscellaneous.Id !==
      object.BlockContent.TimedActionTaskContent?.Id
    ) {
      attachMasterMiscellaneousTaskToBlock(
        SelectedMiscellaneous.Id,
        object.Id,
        onUpdateSuccess,
        onError,
      );
    }
  };
  const onUpdateSuccess = (data: MiscellaneousTaskBlockContent) => {
    setValue(data.BlockContent.TimedActionTaskContent?.Name);
    updateBlock(data);
  };
  const onError = (error: any) => {
    OpenErrorNotification({ description: error.ErrorMessage });
  };
  return (
    <TextAreaAutocomplete
      value={value}
      scriptFormik={scriptFormik}
      handleSearch={handleSearch}
      handleInputSearch={handleInputSearch}
      onSelect={onSelect}
      options={options}
      isFocused={isFocused}
      lastFocused={lastFocused}
      setIsFocused={(obj: {}) => dispatch(SetIsFocused(obj))}
      setLastFocused={(obj: {}) => dispatch(SetLastFocused(obj))}
      disabled={isReadOnly}
    />
  );
}
