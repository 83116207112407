import React, { useState, useEffect } from "react";
import {
  FormRow,
  GreenButton,
  GreyButton,
  PaneBody,
  PaneHeader,
} from "../styled";
import { FormikProps } from "formik";
import {
  ArrowRightOutlined,
  HeartOutlined,
  LockOutlined,
  PlusOutlined,
  SaveOutlined,
  StarOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { Button, Rate, Row, Tooltip } from "antd";
import { ScriptFormValues } from "./ScriptView";
import { CustomSwitchWithIcon } from "./CustomSwitchWithIcon.Styled";
import { ReactComponent as VactorLogo } from "../../../../../assets/icons/Vector.svg";
import FloatingLabelInput from "../../../../../components/FloatingLabel/FloatingLabelInput";
import FloatingLabelText from "../../../../../components/FloatingLabel/FloatingLabelTextArea";
import {
  colorPalette,
  hexTransparencies,
} from "../../../../../styles/MainList.styled";
import { DraggableList, MuiSelect, Switch } from "../../../../../components";
import { useSelector, useDispatch } from "react-redux";
import { Author } from "../../../../../components/DraggableList/DraggableList";
import {
  INSULT_INJURY_RESET_APPLIED_INJURIES,
  INSULT_INJURY_RESET_APPLIED_INSULTS,
} from "../../../../../store/CasualtyCreator/InsultInjury/actionType";
import { SetSelectedCasualtyId } from "../../../../../store/ScriptBuilder/scriptBuilderSlice";
import { useAppSelector } from "../../../../../store/hooks";
import { isCurrentLoggedInCompanySimwerx } from "../../../../../utils/utilityfunctions";

interface Props {
  formik: FormikProps<ScriptFormValues>;
  createUpdateScript: Function;
  setView: Function;
  updateScriptTitle: Function;
}

export default function ScriptViewLeftEditPanel({
  formik,
  createUpdateScript,
  setView,
  updateScriptTitle,
}: Props) {
  const dispatch = useDispatch();
  const auth = useAppSelector((state) => state.authReducer);
  const { isReadOnly } = useSelector(
    (state: any) => state.scriptBuilderReducer,
  );
  const { scriptDetailsPageFeature } = useAppSelector(
    (state) => state.FeatureFlagReducer,
  );
  const { forkIcon, heartIcon, ratings } = scriptDetailsPageFeature.header;
  const authors: Author[] = [
    {
      nameid: auth?.user?.Id || "",
      name: `${auth?.user?.FirstName} ${auth?.user?.LastName}` || "",
      avatar: auth?.company?.LogoImageMedia?.Url || "",
      company: auth?.company?.Name || "",
    },
  ];

  const [ratting, setRatting] = useState(3);
  const focusedStates = {
    title: false,
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);

  const handleSelectCasualtyView = () => {
    /*third argument to only save first step details and redirect set to false*/
    createUpdateScript(null, null, false);
  };

  const handleToggleChange = (field: string, value: boolean) => {
    if (isReadOnly) return; //disable action callback in read only script
    formik.setFieldValue(field, value);
  };
  const onScriptTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) return; //disable action callback in read only script
    formik.handleChange(e);
    updateScriptTitle(e.target.value);
  };

  useEffect(() => {
    dispatch(SetSelectedCasualtyId(0));
    dispatch({ type: INSULT_INJURY_RESET_APPLIED_INSULTS });
    dispatch({ type: INSULT_INJURY_RESET_APPLIED_INJURIES });
  }, []);

  return (
    <>
      <PaneHeader>
        <Row
          style={{
            minHeight: "46px",
            display: "flex",
            alignItems: "center",
            padding: "0px 15px 0px 15px",
          }}
        >
          {heartIcon && (
            <div>
              <HeartOutlined
                className="disable-icon"
                style={{ fontSize: "27px" }}
              />
            </div>
          )}
          {ratings && (
            <div style={{ marginLeft: "20px", marginTop: "-5px" }}>
              <Rate
                className="disable-icon"
                style={{ fontSize: "27px", color: colorPalette.primary100 }}
                onChange={setRatting}
                value={ratting}
              />
            </div>
          )}
          {forkIcon && (
            <div style={{ marginLeft: "auto" }}>
              <span>14</span>
              <VactorLogo
                className="disable-icon"
                style={{ width: "22px", marginTop: "5px" }}
              />
            </div>
          )}
        </Row>
      </PaneHeader>
      <PaneBody
        className="d-flex flex-column"
        id="pane-body-height-with-save-button"
      >
        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100%",
          }}
        >
          <FormRow>
            <CustomSwitchWithIcon
              onClick={() =>
                handleToggleChange("public", !formik.values.public)
              }
              className={isReadOnly ? "disabled" : ""}
            >
              <Switch
                checkedChildren="Public"
                unCheckedChildren="Private"
                style={{ width: "70px" }}
                checked={formik.values.public}
                disabled={isReadOnly}
              />{" "}
              {formik.values.public ? (
                <UnlockOutlined
                  style={{
                    fontSize: "1.7rem",
                    position: "relative",
                    top: 6,
                    color: colorPalette.primary100,
                    marginLeft: 5,
                  }}
                />
              ) : (
                <LockOutlined
                  style={{
                    fontSize: "1.7rem",
                    position: "relative",
                    top: 6,
                    color: "#fff",
                    marginLeft: 5,
                  }}
                />
              )}
            </CustomSwitchWithIcon>
            {isCurrentLoggedInCompanySimwerx() ? (
              <CustomSwitchWithIcon
                onClick={() =>
                  handleToggleChange("isSeed", !formik.values.isSeed)
                }
                className={`pull-right ${isReadOnly ? "disabled" : ""}`}
              >
                <Switch
                  checkedChildren="Seed"
                  unCheckedChildren="Seed"
                  style={{ width: "70px" }}
                  checked={formik.values.isSeed}
                  disabled={isReadOnly}
                />{" "}
                {formik.values.isSeed ? (
                  <StarOutlined
                    style={{
                      fontSize: "1.7rem",
                      position: "relative",
                      top: 6,
                      color: colorPalette.primary100,
                      marginLeft: 5,
                    }}
                  />
                ) : (
                  <StarOutlined
                    style={{
                      fontSize: "1.7rem",
                      position: "relative",
                      top: 6,
                      color: "#fff",
                      marginLeft: 5,
                    }}
                  />
                )}
              </CustomSwitchWithIcon>
            ) : null}
          </FormRow>
          <FormRow>
            <FloatingLabelInput
              label="Script Title*"
              nameForInput="title"
              value={formik.values["title"]}
              onChange={onScriptTitleChange}
              scriptFormik={formik}
              lastFocused={lastFocused}
              isAddNeeded={false}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              maxLength={70}
              enableLiveCount={true}
              isFocused={isFocused}
              readOnly={isReadOnly}
            />
          </FormRow>
          <FormRow>
            <FloatingLabelText
              label="Script Description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              textareaName="description"
              value={formik.values["description"]}
              lastFocused={lastFocused}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
              scriptFormik={formik}
              enableLiveCount={true}
              maxLength={200}
              readOnly={isReadOnly}
            />
          </FormRow>
          {scriptDetailsPageFeature.inputs.tags && (
            <Tooltip title={`Hidden in Prod`}>
              <FormRow>
                <MuiSelect label="Tags" mode="tags" disabled={isReadOnly} />
              </FormRow>
            </Tooltip>
          )}
          <FormRow>
            <label>
              {!scriptDetailsPageFeature.inputs.author ? `Author` : `Authors `}
              {isReadOnly || !scriptDetailsPageFeature.inputs.author ? null : (
                <Tooltip title={"Add Author"}>
                  <Button
                    style={{ border: "none" }}
                    icon={<PlusOutlined style={{ fontSize: "20px" }} />}
                  ></Button>
                </Tooltip>
              )}
            </label>
            <DraggableList
              authors={authors}
              disabled={!scriptDetailsPageFeature.inputs.author}
              isReadOnly={isReadOnly}
            />
          </FormRow>
        </form>
      </PaneBody>
      <div>
        {!formik.dirty && formik.isValid && formik.values["title"] !== "" && (
          <GreyButton
            className="responsive-btn"
            onClick={handleSelectCasualtyView}
            style={{
              width: "100%",
              borderLeft: `1px solid #FFFFFF${hexTransparencies[10]}`,
            }}
            htmlType="submit"
          >
            Next <ArrowRightOutlined />
          </GreyButton>
        )}
        {(!formik.isValid || formik.values["title"] === "") && (
          <GreyButton
            className="disable-btn responsive-btn"
            style={{
              width: "100%",
              borderLeft: `1px solid #FFFFFF${hexTransparencies[10]}`,
            }}
            htmlType="submit"
          >
            Next
            <ArrowRightOutlined />
          </GreyButton>
        )}
        {formik.dirty && formik.isValid && formik.values["title"] !== "" && (
          <GreenButton
            className="responsive-btn"
            onClick={handleSelectCasualtyView}
            style={{
              width: "100%",
              borderLeft: `1px solid #FFFFFF${hexTransparencies[10]}`,
            }}
            htmlType="submit"
          >
            <SaveOutlined /> Save &amp; Next <ArrowRightOutlined />
          </GreenButton>
        )}
      </div>
    </>
  );
}
