import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreatorsForTreatment } from "../../store";
import { useAppSelector } from "../../store/hooks";
import TextAreaAutocomplete from "../Autocomplete/TextAreaAutocomplete";
import {
  TreatmentTaskBlock,
  TreatmentTaskBlockContent,
} from "../../models/TreatmentTaskBlock";
import { TreatmentTask } from "../../models/TreatmentTask";
import { attachMasterTreatmentTaskToBlock } from "../../api/TreatmentTaskBlock";

interface Props {
  object: TreatmentTaskBlock;
  scriptFormik: any;
  checkIsMaster: Function;
  updateBlockData: Function;
  onChange: Function;
}

export default function TreatmentSearchAdd({
  object,
  scriptFormik,
  checkIsMaster,
  updateBlockData,
  onChange,
}: Props) {
  const dispatch = useDispatch();
  const { SetLastFocused, SetIsFocused } = bindActionCreators(
    actionCreatorsForTreatment,
    dispatch,
  );
  const { lastFocused, isFocused, isEditingAllowed } = useAppSelector(
    (state) => state.TreatmentReducer,
  );
  const { allMasterTreatments, isReadOnly } = useAppSelector(
    (state: any) => state.MainlistReducer,
  );
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );
  const [value, setValue] = useState<string>(
    object?.BlockContent.TimedActionTaskContent?.Name || "",
  );

  useEffect(() => {
    checkIsMaster(value);
  }, [object]);

  const handleSearch = (value: string) => {
    let res: { value: string; label: string }[] = [];
    if (!value || !value.trim()) {
      res = [];
    } else {
      res = allMasterTreatments
        .filter(
          (treatment: TreatmentTask) =>
            treatment.Name?.toLowerCase().indexOf(value.toLowerCase()) > -1,
        )
        ?.map((treatment: TreatmentTask) => ({
          value: treatment.Name,
          label: <span style={{ fontSize: "1rem" }}>{treatment.Name}</span>,
        }));
    }
    setOptions(res);
    // Update
    isEditingAllowed && onChange("TimedActionTaskContent.Name", value);
  };
  const handleInputSearch = (e: any) => {
    scriptFormik.handleChange(e);
    setValue(e.target.value);
  };
  const onSelect = (value: string) => {
    const SelectedTreatment = allMasterTreatments.find(
      (x: TreatmentTask) => x.Name === value,
    );
    attachMasterTreatmentTaskToBlock(
      SelectedTreatment.Id,
      object.Id,
      onUpdateSuccess,
      onError,
    );
  };
  const onUpdateSuccess = (data: TreatmentTaskBlockContent) => {
    setValue(data.BlockContent.TimedActionTaskContent?.Name);
    updateBlockData(data);
  };
  const onError = (error: any) => {
    console.log(error);
  };

  return (
    <TextAreaAutocomplete
      value={value}
      scriptFormik={scriptFormik}
      handleSearch={handleSearch}
      handleInputSearch={handleInputSearch}
      onSelect={onSelect}
      options={options}
      isFocused={isFocused}
      lastFocused={lastFocused}
      setIsFocused={SetIsFocused}
      setLastFocused={SetLastFocused}
      disabled={isReadOnly}
    />
  );
}
