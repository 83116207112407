import * as React from "react";
import { Component } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Row, Modal, Input, Select, Button, Tabs, Spin, Drawer } from "antd";
import { connect } from "react-redux";
import { Auth, SystemMessage } from "../../app/modules";
import axios from "axios";

import {
  getInstructorResources,
  getAllResources,
} from "../../api/Resource/ApiGet";
import ResourceList from "./Components/ResourceList";
import ObjectList from "./Components/ObjectList";
import AudioList from "./Components/AudioList";
import VideoList from "./Components/VideoList";
import ImageList from "./Components/ImageList";
import DocumentList from "./Components/DocumentList";
import TextList from "./Components/TextList";
import {
  deleteResource,
  add3dAsset,
  addTextAsset,
  editTextAsset,
  updateResource,
} from "../../api/Resource/ApiPost";
import CreateResourceForm from "./Components/CreateResourceForm";
import { AlertType } from "../../models/Enums";
import { setAlertMessage } from "../../actions/alertMessage";

const { TabPane } = Tabs;
const { confirm } = Modal;
const token = sessionStorage.token;
interface Props {
  auth: Auth;
  SystemMessage: SystemMessage;
  setAlertMessage: Function;
}
interface State {
  uploadList: any[];
  imageList: any[];
  videoList: any[];
  audioList: any[];
  objectList: any[];
  documentList: any[];
  textList: any[];
  loading: boolean;
  uploading: boolean;
  newVersion: boolean;
  march: any;
  resourceName: string;
  resourceType: string;
  resourceTag: any;
  resourceUrl: string;
  uploadedResource: any;
  resourcePublic: boolean;
  openUpload: boolean;
  resourceText: any;
}

class CreateResources extends Component<Props, State> {
  state: State = {
    uploadList: [],
    imageList: [],
    videoList: [],
    audioList: [],
    objectList: [],
    documentList: [],
    textList: [],
    loading: true,
    uploading: false,
    newVersion: false,
    march: ["0", "0", "0", "0", "0"],
    resourceName: "",
    resourceType: "",
    resourceTag: null,
    resourceUrl: "",
    uploadedResource: {},
    resourcePublic: false,
    openUpload: false,
    resourceText: null,
  };
  componentDidMount() {
    getInstructorResources(this.getResourcesSuccess, this.error);
  }

  getResourcesSuccess = (data: any) => {
    console.log("resources", data);
    const images = data.filter(
      (item: any) => item.resourceType.name === "Image",
    );
    const videos = data.filter(
      (item: any) => item.resourceType.name === "Video",
    );
    const audios = data.filter(
      (item: any) => item.resourceType.name === "Audio",
    );
    const documents = data.filter(
      (item: any) => item.resourceType.name === "Document",
    );
    const texts = data.filter((item: any) => item.resourceType.name === "Text");
    const objects = data.filter(
      (item: any) =>
        item.resourceType.name === "Animation" ||
        item.resourceType.name === "Model",
    );
    this.setState({
      imageList: images || [],
      videoList: videos || [],
      audioList: audios || [],
      documentList: documents || [],
      uploadList: data || [],
      objectList: objects || [],
      textList: texts || [],
      loading: false,
    });
  };

  error = (data: any) => {
    console.log(data);
    //message.error("An error occured check the console for more information");
    this.props.setAlertMessage({
      systemMessage: {
        message: "Error",
        type: AlertType.error,
        description: "An error occured check the console for more information",
        messageShowTime: 3000,
        position: "top-center",
      },
    });
  };

  beforeUpload = () => async (file: any) => {
    console.log(file);
    const m = await new Promise<void>((resolve: any, reject: any) => {
      console.log(file.type);

      axios
        .get(`api/v1/realx/Resource/check-instructor-resources/${file.name}`)
        .then((result) => {
          console.log(result);
          if (!result.data) {
            resolve();
          } else {
            confirm({
              title: "File is already uploaded",
              content: "Would you like to upload the file anyways?",
              okText: "Yes",
              onOk: () => {
                this.setState((state: any) => {
                  return { newVersion: true };
                });
                resolve();
              },
              cancelText: "No",
              onCancel: () => {
                reject(false);
              },
            });
          }
        })
        .catch((error: any) => {
          //message.error("There was a problem with uploading the file. Please try again later");
          this.props.setAlertMessage({
            systemMessage: {
              message: "Error",
              type: AlertType.error,
              description:
                "There was a problem with uploading the file. Please try again later",
              messageShowTime: 3000,
              position: "top-center",
            },
          });
          console.log(error);
        });
    });
    return m;
  };

  upload = (options: any) => {
    this.setState((state: any) => {
      return { loading: true, uploading: true };
    });
    console.log(options);
    const bodyFormData = new FormData();
    bodyFormData.append("file", options.file);
    bodyFormData.append("companyID", this.props.auth.company.Id);
    bodyFormData.append("newVersion", this.state.newVersion.toString());
    axios({
      method: "post",
      url: process.env.REACT_APP_REALXBE + "api/v1/realx/Resource/upload",
      data: bodyFormData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type":
          "multipart/form-data; boundary=---------------------------81801171514357",
      },
    })
      .then((response) => {
        //handle success
        console.log(response);
        const newItem = response.data.result;
        this.setState({
          uploadedResource: newItem,
          resourceUrl: newItem.url,
          resourceName: newItem.name,
          resourceType: newItem.resourceType.name,
          loading: false,
          uploading: false,
        });

        const uploadList: any = this.state.uploadList;
        uploadList.push(newItem);
        const images = uploadList.filter(
          (item: any) => item.resourceType.name === "Image",
        );
        const videos = uploadList.filter(
          (item: any) => item.resourceType.name === "Video",
        );
        const audios = uploadList.filter(
          (item: any) => item.resourceType.name === "Audio",
        );
        const documents = uploadList.filter(
          (item: any) => item.resourceType.name === "Document",
        );
        const objects = uploadList.filter(
          (item: any) =>
            item.resourceType.name === "Animation" ||
            item.resourceType.name === "Model",
        );
        this.setState({
          uploadList: uploadList,
          imageList: images,
          videoList: videos,
          audioList: audios,
          documentList: documents,
          objectList: objects,
        });
        console.log(uploadList);
        // message.success("Resource uploaded!!");
        this.props.setAlertMessage({
          systemMessage: {
            message: "Success",
            type: AlertType.success,
            description: "Resource uploaded!!",
            messageShowTime: 3000,
            position: "bottom-right",
          },
        });
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  deleteFile = (file: string, id: number) => {
    confirm({
      title: "You are about to delete a file permanently",
      content: "Are you sure you want to delete this file?",
      okText: "Yes",
      onOk: () => {
        this.setState({ loading: true });
        deleteResource(file, id, this.deleteSuccess, this.error);
      },
      cancelText: "No",
      onCancel: () => undefined,
    });
  };

  deleteSuccess = (data: any) => {
    //console.log("delete Success", data);
    //do not change from !=
    const uploadList: any = this.state.uploadList.filter(
      (file: any) => file.id !== data,
    );
    //console.log(uploadList);
    const images = uploadList.filter(
      (item: any) => item.resourceType.name === "Image",
    );
    const videos = uploadList.filter(
      (item: any) => item.resourceType.name === "Video",
    );
    const audios = uploadList.filter(
      (item: any) => item.resourceType.name === "Audio",
    );
    const documents = uploadList.filter(
      (item: any) => item.resourceType.name === "Document",
    );
    const texts = uploadList.filter(
      (item: any) => item.resourceType.name === "Text",
    );
    const objects = uploadList.filter(
      (item: any) =>
        item.resourceType.name === "Animation" ||
        item.resourceType.name === "Model",
    );
    this.setState({
      uploadList: uploadList,
      imageList: images,
      videoList: videos,
      audioList: audios,
      documentList: documents,
      objectList: objects,
      textList: texts,
      loading: false,
    });
  };

  add3dAsset = (postObj: any) => {
    console.log(postObj);
    add3dAsset(postObj, this.add3dAssetSuccess, this.error);
  };

  add3dAssetSuccess = (data: any) => {
    console.log(data);
    const uploadList: any = this.state.uploadList;
    const objectList: any = this.state.objectList;

    objectList.push(data);
    uploadList.push(data);
    this.setState({
      uploadList: uploadList,
      objectList: objectList,
      loading: false,
    });
    //message.success("Resource added");
    this.props.setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: "Resource added!!",
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });
  };

  addTextAsset = (type: string, postObj: any) => {
    console.log(postObj);
    if (type === "add")
      addTextAsset(postObj, this.addTextAssetSuccess, this.error);
    else editTextAsset(postObj, this.editTextAssetSuccess, this.error);
  };

  addTextAssetSuccess = (data: any) => {
    console.log(data);
    const uploadList: any = this.state.uploadList;
    const textList: any = this.state.textList;

    textList.push(data);
    uploadList.push(data);
    this.setState({
      uploadList: uploadList,
      textList: textList,
      loading: false,
    });
    // message.success("Resource added");
    this.props.setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: "Resource added!!",
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });
  };

  editTextAssetSuccess = (data: any) => {
    console.log(data);
    const uploadList: any = this.state.uploadList;
    const textList: any = this.state.textList;
    const uploadIndex = uploadList.findIndex(
      (item: any) => item.id === data.id,
    );
    const textIndex = textList.findIndex((item: any) => item.id === data.id);
    textList[textIndex] = data;
    uploadList[uploadIndex] = data;
    this.setState({
      uploadList: uploadList,
      textList: textList,
      loading: false,
    });
    // message.success("Resource updated");
    this.props.setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: "Resource updated!!",
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });
  };

  handleMarchChange = (index: number) => {
    console.log(index);
    const march = this.state.march;
    if (march[index] === "0") march[index] = "1";
    else march[index] = "0";
    this.setState({ march: march });
  };

  handleChange = (input: any) => (e: any) => {
    this.setState({ [input]: e.target.value } as any);
  };

  onCheckPublic = (e: any) => {
    this.setState({ resourcePublic: !this.state.resourcePublic });
  };

  editToggle = (item: any) => {
    console.log(item);
    const marchString = String("00000" + item.march.toString(2)).slice(-5);
    const march = Array.from(marchString);
    this.setState({
      uploadedResource: item,
      resourceUrl: item.url,
      resourceName: item.name,
      resourceTag: item.tag,
      march: march,
      resourceType: item.resourceType.name,
      resourcePublic: item.public,
      loading: false,
      uploading: false,
      openUpload: true,
      resourceText: item.text,
    });
  };

  editResource = () => {
    if (this.state.uploadedResource.id) {
      const marchString = this.state.march.join("");
      const march = parseInt(marchString, 2);
      const upload = this.state.uploadedResource;
      console.log(upload);
      console.log(this.state.resourceName);
      console.log(this.state.resourceTag);
      console.log(this.state.resourcePublic);
      console.log(march);
      if (
        this.state.resourceName !== upload.name ||
        this.state.resourceTag !== upload.tag ||
        this.state.resourcePublic !== upload.public ||
        march !== upload.march ||
        this.state.resourceText !== upload.text
      ) {
        this.setState({ loading: true });
        const postObj = {
          Id: this.state.uploadedResource.id,
          Name: this.state.resourceName,
          Public: this.state.resourcePublic,
          InstructorId: "Default",
          Tag: this.state.resourceTag,
          March: march,
          Text: this.state.resourceText,
        };
        updateResource(postObj, this.editResourceSuccess, this.error);
      } else {
        this.setState({
          openUpload: false,
          resourceUrl: "",
          resourcePublic: false,
          resourceTag: null,
          resourceName: "",
          resourceType: "",
          uploadedResource: {},
          march: ["0", "0", "0", "0", "0"],
          resourceText: null,
        });
      }
    } else {
      this.setState({
        openUpload: false,
        resourceUrl: "",
        resourcePublic: false,
        resourceTag: null,
        resourceName: "",
        resourceType: "",
        uploadedResource: {},
        march: ["0", "0", "0", "0", "0"],
        resourceText: null,
      });
    }
  };

  editResourceSuccess = (data: any) => {
    // message.success("Resource updated!!");
    this.props.setAlertMessage({
      systemMessage: {
        message: "Success",
        type: AlertType.success,
        description: "Resource updated!!",
        messageShowTime: 3000,
        position: "bottom-right",
      },
    });
    console.log(data);
    const uploadList: any = this.state.uploadList;
    const index = uploadList.findIndex((file: any) => file.id === data.id);
    uploadList[index] = data;
    const images = uploadList.filter(
      (item: any) => item.resourceType.name === "Image",
    );
    const videos = uploadList.filter(
      (item: any) => item.resourceType.name === "Video",
    );
    const audios = uploadList.filter(
      (item: any) => item.resourceType.name === "Audio",
    );
    const documents = uploadList.filter(
      (item: any) => item.resourceType.name === "Document",
    );
    const texts = uploadList.filter(
      (item: any) => item.resourceType.name === "Text",
    );
    const objects = uploadList.filter(
      (item: any) =>
        item.resourceType.name === "Animation" ||
        item.resourceType.name === "Model",
    );
    this.setState({
      uploadList: uploadList,
      imageList: images,
      videoList: videos,
      audioList: audios,
      documentList: documents,
      objectList: objects,
      textList: texts,
      loading: false,
      resourceType: "",
      resourceTag: null,
      resourceName: "",
      uploadedResource: {},
      march: ["0", "0", "0", "0", "0"],
      openUpload: false,
      resourcePublic: false,
      resourceUrl: "",
    });
  };
  render() {
    return (
      <div className="centeredWithPadding">
        <div
          className="rxblackBG"
          style={{
            height: "fit-content",
            overflowY: "auto",
            display: "flex",
            flexWrap: "nowrap",
            flexDirection: "column",
            padding: "14px",
          }}
        >
          <Spin spinning={this.state.loading}>
            <Row
              align="middle"
              justify="space-between"
              style={{ borderBottom: "1px solid #fff" }}
            >
              <h2 className="white-text">Upload Resources</h2>
              <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                <Button
                  className="ant-btn realx-btn-blue"
                  onClick={() => {
                    this.setState({ openUpload: true });
                  }}
                >
                  Upload Resource
                </Button>

                <div>
                  Sort by: <Select style={{ width: "200px" }} />
                </div>
                <div>
                  Search:{" "}
                  <Input
                    type="search"
                    prefix={<SearchOutlined />}
                    style={{ width: "200px" }}
                  />
                </div>
              </div>
            </Row>

            <Tabs defaultActiveKey="1" tabPosition="left">
              <TabPane tab="All" key="1">
                <ResourceList
                  resources={this.state.uploadList}
                  deleteFile={this.deleteFile}
                  editResource={this.editToggle}
                />
              </TabPane>
              <TabPane tab="Images" key="2">
                <ImageList
                  resources={this.state.imageList}
                  deleteFile={this.deleteFile}
                  editResource={this.editToggle}
                />
              </TabPane>
              <TabPane tab="Videos" key="3">
                <VideoList
                  resources={this.state.videoList}
                  deleteFile={this.deleteFile}
                  editResource={this.editToggle}
                />
              </TabPane>
              <TabPane tab="3D Assets" key="4">
                <ObjectList
                  resources={this.state.objectList}
                  deleteFile={this.deleteFile}
                  user={this.props.auth.user}
                  AddResource={this.add3dAsset}
                />
              </TabPane>
              <TabPane tab="Audio" key="5">
                <AudioList
                  resources={this.state.audioList}
                  deleteFile={this.deleteFile}
                  editResource={this.editToggle}
                />
              </TabPane>
              <TabPane tab="Documents" key="6">
                <DocumentList
                  resources={this.state.documentList}
                  deleteFile={this.deleteFile}
                  editResource={this.editToggle}
                />
              </TabPane>
              <TabPane tab="Text Resources" key="7">
                <TextList
                  resources={this.state.textList}
                  deleteFile={this.deleteFile}
                  AddResource={this.addTextAsset}
                />
              </TabPane>
            </Tabs>
            <Drawer
              visible={this.state.openUpload}
              title="Upload Resource"
              closable={true}
              onClose={this.editResource}
              width="500"
            >
              <CreateResourceForm
                auth={this.props.auth}
                state={this.state}
                handleMarchChange={this.handleMarchChange}
                handleChange={this.handleChange}
                beforeUpload={this.beforeUpload}
                upload={this.upload}
                onCheckPublic={this.onCheckPublic}
                save={this.editResource}
              />
            </Drawer>
          </Spin>
        </div>
      </div>
    );
  }
}
function mapToStateToProps(state: any) {
  return {
    auth: state.authReducer,
    SystemMessage: state.SystemMessage,
  };
}
//export default connect(mapToStateToProps)(CreateResources);
export default connect(mapToStateToProps, { setAlertMessage }, null, {
  pure: false,
})(CreateResources);
