import { Col } from "antd";
import styled from "styled-components";

export const TimedActionExpandableBlockCommentContainer = styled.div`
  div:not(:first-child) {
    display: flex;
    align-items: center;
  }

  div:nth-child(2) {
    padding: 15px;
    border-right: 1px solid #161616;
  }
`;

export const TimedActionExpandableBlockCommentAudio = styled(Col)`
  justify-content: center;
  font-size: 1.5rem;
  color: #23d0eb;

  div {
    border: 2px solid #23d0eb;
    background: #23d0eb1a;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

export const TimedActionExpandableBlockCommentText = styled(Col)``;
