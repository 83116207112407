import React from "react";
import { ResearchQuestionRadioType } from "../../../../../models/Enums";
import {
  ResearchQuestionRadio,
  ResearchQuestionRadioAnswer,
} from "../../../../../models/ScriptInterfaces";
import {
  StyledListTitleItem,
  TitleSpan,
  Ul,
} from "../../ShowList/ShowList.styled";
interface Props {
  question: ResearchQuestionRadio;
}
export default function RadioTypeQuestion(props: Props) {
  return (
    <div className="mb-2 mt-3">
      <Ul>
        {props.question.ResearchQuestionRadioAnswers.map(
          (option: ResearchQuestionRadioAnswer, Index: number) => (
            <li
              key={`option-${option?.Id || Index}`}
              className={
                props.question?.ResearchQuestionRadioType ===
                ResearchQuestionRadioType.Horizontal
                  ? "horizontal-option"
                  : ""
              }
            >
              <StyledListTitleItem className="titleIndexDiv">
                <TitleSpan>
                  <span className="radio"></span>
                  {option.Answer}
                </TitleSpan>
              </StyledListTitleItem>
            </li>
          )
        )}
      </Ul>
    </div>
  );
}
