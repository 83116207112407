import React from "react";
import { CasualtyStatus } from "../../../../../models/ScriptInterfaces";

interface Props {
  BlockContent: CasualtyStatus;
}

export default function GlasgowComaScale(props: Props) {
  return (
    <div className="coma-scale">
      <ul>
        <li className="label">Glasgow Coma Scale:</li>
        <li className="value selected">
          {props.BlockContent.GlasgowComaScaleTotal || ""}
        </li>
        <li className="options">
          E{props.BlockContent.GlasgowComaScaleEyes || ""}
        </li>
        <li className="options">
          V{props.BlockContent.GlasgowComaScaleVerbal || ""}
        </li>
        <li className="options">
          M{props.BlockContent.GlasgowComaScaleMotor || ""}
        </li>
      </ul>
    </div>
  );
}
