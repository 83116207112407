import React from "react";
import Drawer from "../../../../components/Drawer/Drawer";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setDiagnosticForUpdate } from "./DiagnosticSlice";
import { OpenErrorNotification } from "../../../../components/Notification/Notification";
import CategorizationAndRequirements from "../../../../components/TreatmentEdit/CategorizationAndRequirements";
import { ScrollableTimedActionTaskDiv } from "../../../../styles/EditComponentTheme.styled";
import Label from "../Treatments/TimedActions/Label";
import FloatingLabelTextArea from "../../../../components/FloatingLabel/FloatingLabelTextArea";
import SubTasks from "./TimedActions/SubTasks";
import { useAppSelector } from "../../../../store/hooks";
import {
  DiagnosticTask,
  UpdateDiagnosticTask,
} from "../../../../models/DiagnosticTask";
import { IntervalTA } from "../../../../components/TimedActions/IntervalTA";
import { DurationTa } from "../../../../components/TimedActions/DurationTA";
import { DefineStartTimeTA } from "../../../../components/TimedActions/DefineStartTimeTA";
import { RatingTA } from "../../../../components/TimedActions/RatingTA";
import { deleteMediaForTimedActionTask } from "../../../../api/TimedActionTask";
import { Media } from "../../../../models/MediaInterface";
import produce from "immer";
import { isNullOrUndefined } from "is-what";
import MediaOption from "../../../../components/Media/MediaOption";
import { Tooltip } from "antd";

interface Props {
  title: string;
  formik: any;
  isVisible: boolean;
  onClickOutside: any;
  onClose: any;
}
export default function AddUpdateDrawer({
  title,
  formik,
  isVisible,
  onClickOutside,
  onClose,
}: Props) {
  const { materialRequirements, skillCategorization } = useAppSelector(
    (state) => state.FeatureFlagReducer.scriptBuilder.diagnosticBlock,
  );
  const diagnostic = useAppSelector(
    (state) => state.MasterDiagnosticReducer.diagnosticForUpdate,
  );
  const focusedStates = {
    description: "",
  };
  const [isFocused, setIsFocused] = React.useState(focusedStates);
  const [lastFocused, setLastFocused] = React.useState(focusedStates);
  const isReadOnly = false;
  const dispatch = useDispatch();

  const onError = (msg: any) => {
    OpenErrorNotification({
      description: msg,
    });
  };
  const handleChange = (
    field: keyof UpdateDiagnosticTask,
    value: string | boolean | any,
  ) => {
    const payload = {
      ...diagnostic,
      [field]: value,
    };
    dispatch(setDiagnosticForUpdate(payload));
  };

  const handleIntervalToggle = (
    type: keyof UpdateDiagnosticTask,
    value?: number,
  ) => {
    if (type === "IsIntervalEnabled") {
      if (diagnostic.IsIntervalEnabled) {
        handleChange("IntervalTime", 0);
      }
      handleChange(type, !diagnostic[type]);
    } else if (type === "IntervalTime") {
      handleChange(type, value);
    }
  };

  const handleOptionToggle = (
    type: keyof UpdateDiagnosticTask,
    value?: number,
  ) => {
    handleChange(type, !diagnostic[type]);
  };

  const deleteMediaSuccess = (mediaId: number) => {
    const updatedDiagnosticTask = produce(diagnostic, (draftDiagnostic) => {
      draftDiagnostic.AttachedMedias = diagnostic.AttachedMedias.filter(
        (media: Media) => media.Id !== mediaId,
      );
    });
    dispatch(setDiagnosticForUpdate(updatedDiagnosticTask));
  };

  const handleDeleteMedia = (mediaId: number) => {
    if (!isNullOrUndefined(diagnostic.Id)) {
      deleteMediaForTimedActionTask(
        diagnostic.Id,
        mediaId,
        () => {
          deleteMediaSuccess(mediaId);
        },
        (error: any) => {
          console.error(error);
        },
      );
    } else {
      deleteMediaSuccess(mediaId);
    }
  };

  const getScrollableDivHeight = () => {
    return {
      height: `auto`,
    };
  };

  return (
    <Drawer
      className="custom-css-ant-drawer"
      visible={isVisible}
      onClose={onClickOutside}
      closable
      width="600"
    >
      <h1>
        <ArrowRightOutlined style={{ marginRight: "20px" }} onClick={onClose} />
        {title}
      </h1>
      <div className="m-2">
        <div className="p-2">
          <ScrollableTimedActionTaskDiv
            style={getScrollableDivHeight()}
            typeOfEditPanel="diagnostic"
            className="treatmentContainer"
            id="scrollableDiv"
            phase={false}
          >
            <div className="mb-5">
              <Label
                label={"Title*"}
                field={"label"}
                formik={formik}
                lastFocused={lastFocused}
                setIsFocused={setIsFocused}
                setLastFocused={setLastFocused}
                isFocused={isFocused}
              />
            </div>
            <FloatingLabelTextArea
              label={"Description"}
              textareaName={"description"}
              value={formik.values["description"]}
              onSave={() => undefined}
              onChange={formik.handleChange}
              scriptFormik={formik}
              lastFocused={lastFocused}
              setIsFocused={setIsFocused}
              setLastFocused={setLastFocused}
              isFocused={isFocused}
            />
            <div className="toggleOptions">
              <SubTasks
                isReadOnly={isReadOnly}
                obj={diagnostic as DiagnosticTask}
                formik={formik}
                onChange={handleChange}
                onError={onError}
              />
              <IntervalTA
                isReadOnly={isReadOnly}
                handleIntervalToggle={handleIntervalToggle}
                isIntervalEnabled={diagnostic.IsIntervalEnabled}
                interval={diagnostic.IntervalTime}
                handleOptionToggle={handleOptionToggle}
                scriptFormik={formik}
              />
              <DurationTa
                isReadOnly={isReadOnly}
                isDurationEnabled={diagnostic.IsDurationEnabled}
                onChange={handleOptionToggle}
                isDefineDurationTimeEnabled={
                  diagnostic.IsDefineDurationTimeEnabled
                }
              />
              <DefineStartTimeTA
                isReadOnly={isReadOnly}
                handleOptionToggle={handleOptionToggle}
                isDefineStartTimeEnabled={diagnostic.IsDefineStartTimeEnabled}
              />
              <RatingTA
                isReadOnly={isReadOnly}
                isCustomLabelsEnabled={diagnostic.IsCustomLabelsEnabled}
                isRatingEnabled={diagnostic.IsRatingEnabled}
                handleOptionToggle={handleOptionToggle}
              />
              <MediaOption
                isReadOnly={isReadOnly}
                obj={diagnostic as DiagnosticTask}
                type="Diagnostic"
                handleOptionToggle={handleOptionToggle}
                handleDeleteMedia={handleDeleteMedia}
                onChange={handleChange}
              />
              {skillCategorization && (
                <Tooltip title="Hidden in production">
                  <>
                    <CategorizationAndRequirements
                      dropDownTitle={"Skill Categorization"}
                      listItemsTitle={"Skill Type"}
                      viewListLinkTitle={"View Approved Mos Units"}
                      timedAction={"diagnostic"}
                    />
                  </>
                </Tooltip>
              )}
              {materialRequirements && (
                <Tooltip title="Hidden in production">
                  <>
                    <CategorizationAndRequirements
                      dropDownTitle={"Material Requirements"}
                      listItemsTitle={"Equipment Availability"}
                      viewListLinkTitle={"View Material List"}
                      timedAction={"diagnostic"}
                    />
                  </>
                </Tooltip>
              )}
            </div>
          </ScrollableTimedActionTaskDiv>
        </div>
      </div>
    </Drawer>
  );
}
