import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Medication } from "../../../../models/ScriptInterfaces";
import { createMedication } from "../../../ScriptBuilder/CreateDefaultBlockObjects";
import {
  MedicationTask,
  UpdateMedicationTask,
} from "../../../../models/MedicationTask";
import { UpdateTimedActionSubTask } from "../../../../models/TimedActionSubTask";
import { Media } from "../../../../models/MediaInterface";
const defaultMedication = createMedication();
export interface medicationState {
  data: MedicationTask[];
  modalTitle: string;
  addUpdateModal: boolean;
  loading: boolean;
  medicationForUpdate: UpdateMedicationTask;
}

const initialState: medicationState = {
  data: [],
  medicationForUpdate: {
    Subtasks: [] as UpdateTimedActionSubTask[],
    AttachedMedias: [] as Media[],
  } as UpdateMedicationTask,
  modalTitle: "",
  addUpdateModal: false,
  loading: true,
};

export const medicationSlice = createSlice({
  name: "medication",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<MedicationTask[]>) => {
      state.data = action.payload;
    },
    setModalTitle: (state, action: PayloadAction<string>) => {
      state.modalTitle = action.payload;
    },
    setAddUpdateModal: (state, action: PayloadAction<boolean>) => {
      state.addUpdateModal = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    onDeleteSuccess: (state, action: PayloadAction<number>) => {
      state.data = state.data.filter((x) => x.Id !== action.payload);
    },
    onUpdateSuccess: (state, action: PayloadAction<MedicationTask>) => {
      const index = state.data.findIndex((x) => x.Id === action.payload.Id);
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    },
    onArchiveSuccess: (state, action: PayloadAction<number>) => {
      const index = state.data.findIndex((x) => x.Id === action.payload);
      if (index !== -1) {
        state.data[index].IsArchived = !state.data[index].IsArchived;
      }
    },
    onCreateSuccess: (state, action: PayloadAction<MedicationTask>) => {
      state.data.unshift(action.payload);
    },

    resetMedicationForUpdate: (state, action: PayloadAction<null>) => {
      state.medicationForUpdate = {
        Subtasks: [] as UpdateTimedActionSubTask[],
        AttachedMedias: [] as Media[],
      } as UpdateMedicationTask;
    },
    setMedicationForUpdate: (state, action: PayloadAction<MedicationTask>) => {
      state.medicationForUpdate = action.payload;
    },
  },
});

export default medicationSlice.reducer;

export const {
  setData,
  setModalTitle,
  setAddUpdateModal,
  setLoading,
  onUpdateSuccess,
  onCreateSuccess,
  onDeleteSuccess,
  onArchiveSuccess,
  resetMedicationForUpdate,
  setMedicationForUpdate,
} = medicationSlice.actions;
