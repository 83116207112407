import styled from "styled-components";
import { colorPalette } from "../../../styles/MainList.styled";

export const ForgotPasswordContainer = styled.div`
  .submit {
    margin-top: 20px;
    justify-content: flex-end;

    button {
      min-width: 10vw;
    }
  }
`;
