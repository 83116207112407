import React, { MouseEventHandler } from "react";
import { Col } from "antd";
import { IntervalParameter } from "../../../../../../../models/InsultInterface";

type IntervalParamProps = {
  parameter: IntervalParameter;
  onEditInsult: MouseEventHandler;
};

const IntervalParam = ({ parameter, onEditInsult }: IntervalParamProps) => {
  return (
    <Col className="interval-param" onClick={onEditInsult}>
      <div>
        <label>{parameter.UserFacingLabel}</label>
        <span className="text-primary">
          {parameter.UpperDefault} {parameter.LowerDefault}
        </span>
      </div>
    </Col>
  );
};

export default IntervalParam;
